import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-annual-tab',
  templateUrl: './view-annual-tab.component.html',
  styleUrls: ['./view-annual-tab.component.css']
})
export class ViewAnnualTabComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() annualCost :any=[];

  constructor(public notifier: NotifierService,) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
    const accountListData = localStorage.getItem("accountIdData");
    const data1 = JSON.parse(accountListData);
    let idList: any = [];
    if (data1['list'].length > 0) {
      idList = data1['list'].sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1;
        }
        if (a.accountName > b.accountName) {
          return 1;
        }
        return 0;
      });
    }

   this.annualCost = this.annualCost.map((item: any) => { 
    const matchedData = idList.find((data:any) => data.accountId === item.id); 
    return { id: matchedData?.accountName + " (" + item.id + ")", cost: item.cost }; 
  }); 

  }

  
  no(){
    this.hideModal.emit({ status: 0 });
    
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}

}
