import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reserved-instances",
  templateUrl: "./reserved-instances.component.html",
  styleUrls: ["./reserved-instances.component.css"],
})
export class ReservedInstancesComponent implements OnInit {
  instances: any = [];

  overview: any = {
    total: () => this.instances.length,
    retired: () => {
      return this.instances.filter((instance: any) => {
        return instance["State"] != "active";
      }).length;
    },
    active: () => {
      return this.instances.filter((instance: any) => {
        return instance["State"] == "active";
      }).length;
    },
    expires_in_30_days: () => {
      return this.instances.filter((instance: any) => {
        return (
          instance["State"] == "active" && instance["DaysExpiration"] <= 30
        );
      }).length;
    },
  };

  loading: boolean = true;
  pagination: any = {
    perPage: 10,
    page: 1,
  };

  pages: any = [10, 50, 100, 200];

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  accountId: string = "";
  regionId: string = "";
  currentMessage: any;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.loadRI();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.loadRI();
      }
    });
  }

  async loadRI() {
    this.loading = true;
    this.instances = [];
    let data = {
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${environment.apiURL}/${this.urlPrefix}/apis/operations/reservedinstances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1" || result.statusCode == 200) {
      this.instances = result["body"]["reservedInstances"]
        .sort((a: any, b: any) => {
          return (
            moment(b["End"], "YYYY-MM-DD HH:mm:ss").valueOf() -
            moment(a["End"], "YYYY-MM-DD HH:mm:ss").valueOf()
          );
        })
        .map((instance: any) => {
          if (instance["Start"]) {
            instance["Start"] = moment(
              instance["Start"],
              "YYYY-MM-DD HH:mm:ss"
            ).format("MMM DD, YYYY hh:mm A");
          }
          if (instance["End"]) {
            instance["End"] = moment(
              instance["End"],
              "YYYY-MM-DD HH:mm:ss"
            ).format("MMM DD, YYYY hh:mm A");
          }
          if (instance["Start"] && instance["End"]) {
            if (instance["State"] != "active") {
              instance["State_Text"] = `${instance["State"]} (${
                instance["State"] != "retired" ? "since" : ""
              }${-1 * instance["DaysExpiration"]} days ago)`;
            } else if (instance["State"] == "active") {
              instance[
                "State_Text"
              ] = `Active (expires in ${instance["DaysExpiration"]} days)`;
            }
          }
          return instance;
        });
    }
    this.loading = false;
  }
}
