import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'launch-upload-bom',
  templateUrl: './error.upload.bom.component.html',
  styleUrls: ['./error.upload.bom.component.css'],
})
export class LaunchUploadBOM implements OnInit, OnDestroy {
  loading = true;
  @Output() hideModel = new EventEmitter<any>();

  ngOnInit(): void {}

  async uploadFile() {
    this.hideModel.emit(true);
  }

  ngOnDestroy(): void {}

  close() {
    this.hideModel.emit(false);
  }
}
