import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "./../../../_services/notifier.service";
import { environment } from "src/environments/environment";

@Component({
  //moduleId: module.id.toString(),
  styleUrls: ["./othercostopt.component.css"],
  templateUrl: "./othercostopt.component.html",
})
export class otherCostOptimizationComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  unusedEBSList: any = [];
  unusedEBSLoading: boolean = true;
  unusedElasticIPsList: any = [];
  unusedElasticIPsLoading: boolean = true;
  stoppedEC2List: any = [];
  stoppedEC2Loading: boolean = true;
  instance: any;
  funRef: any;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;

  unusedEBSHeaders: any = [
    {
      id: "volumeId",
      name: "Volume ID",
      filter: true,
    },
    {
      id: "sizeAllocated",
      name: "Size",
    },
    {
      id: "state",
      name: "State",
      filter: true,
    },
    {
      id: "iops",
      name: "IOPS",
      filter: true,
    },
    {
      id: "volumeType",
      name: "Volume Type",
      filter: true,
    },
    {
      id: "created",
      name: "Created",
      filter: true,
    },
    {
      id: "availabilityZone",
      name: "Availability Zone",
      filter: true,
    },
  ];

  unusedElasticIPs: any = [
    {
      id: "name",
      name: "Name",
      filter: true,
    },
    {
      id: "publicIP",
      name: "Public IP",
      filter: true,
    },
    {
      id: "allocationId",
      name: "Allocation ID",
      filter: true,
    },
  ];

  stoppedEC2Headers: any = [
    {
      id: "instanceId",
      name: "Instance ID",
      filter: true,
    },
    {
      id: "tagName",
      name: "Tag Name",
      filter: true,
    },
    {
      id: "instanceType",
      name: "Instance Type",
      filter: true,
    },
    {
      id: "instanceStatus",
      name: "Instance Status",
    },
    {
      id: "stoppedDate",
      name: "Stopped Date",
    },
    {
      id: "stoppedDaysAgo",
      name: "Stopped Days Ago",
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  selectedCloudPartner: string | null = localStorage.getItem('cp-selected');
  resourceGroup: string = localStorage.getItem("selectedResourceGroup");

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.unusedEBSList = [];
      this.unusedElasticIPsList = [];
      this.stoppedEC2List = [];
      let d = JSON.parse(msg);

      if (d.value == null || d.source != "notifier") {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId" && this.selectedCloudPartner == 'aws') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
    this.funRef = {};
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    // this.loadUnusedEBS();
    if(this.selectedCloudPartner == 'azure') {
      this.loadUnusedEBS();
    }
    this.loadUnusedElasticIPs();
    this.loadStoppedEC2Instances();
  }

  async loadUnusedEBS(nextToken: String = "") {
    if (nextToken == "") {
      this.unusedEBSLoading = true;
      this.unusedEBSList = [];
    }
    let data: any = {
      accountId: this.accountId,
      emailid: this.userId,
      action: "UnusedEBS",
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/opportunities`;

    if(this.selectedCloudPartner == 'azure') {
      apiURL = `${environment.apiURL}/admin/azure/central`;
      data = {
        action: "unattached_disks_to_vms",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (!result) {
      this.unusedEBSLoading = false;
      return false;
    }

    if (result.status == "1" || result.s == "1") {
      if(this.selectedCloudPartner == 'azure') {
        result['unusedEBSList'] = result['unattached_disks_to_vms'];
      }
      this.unusedEBSList = [...this.unusedEBSList, ...result.unusedEBSList];
    } else if (
      nextToken == "" &&
      !result.nextToken &&
      result.error &&
      result.error.toLowerCase() != "data not found"
    ) {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    if (result.nextToken) {
      await this.loadUnusedEBS(result.nextToken);
    } else {
      this.unusedEBSLoading = false;
    }
    return true;
  }

  async loadUnusedElasticIPs(nextToken: String = "") {
    if (nextToken == "") {
      this.unusedElasticIPsLoading = true;
      this.unusedElasticIPsList = [];
    }
    let data: any = {
      accountId: this.accountId,
      emailid: this.userId,
      action: "UnusedElasticIP",
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/opportunities`;

    if(this.selectedCloudPartner == 'azure') {
      apiURL = `${environment.apiURL}/admin/azure/central`;
      data = {
        action: "list_unused_ips",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (!result) {
      this.unusedElasticIPsLoading = false;
      return false;
    }
    if (result.status == "1" || result.s == "1") {
      if(this.selectedCloudPartner == 'azure') {
        result['unusedelasticIPList'] = result['unused_ips'];
      }
      this.unusedElasticIPsList = [
        ...this.unusedElasticIPsList,
        ...result.unusedelasticIPList,
      ];
    } else if (
      nextToken == "" &&
      !result.nextToken &&
      result.error &&
      result.error.toLowerCase() != "data not found"
    ) {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    if (result.nextToken) {
      await this.loadUnusedElasticIPs(result.nextToken);
    } else {
      this.unusedElasticIPsLoading = false;
    }
    return true;
  }

  getVolume(value: any) {
    this.instance = value;
  }

  hideDetails(event: any) {
    this.instance = null;
  }

  async loadStoppedEC2Instances(nextToken: String = "") {
    if (nextToken == "") {
      this.stoppedEC2Loading = true;
      this.stoppedEC2List = [];
    }
    let data: any = {
      accountId: this.accountId,
      emailid: this.userId,
      action: "StoppedEC2",
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/opportunities`;

    if(this.selectedCloudPartner == 'azure') {
      apiURL = `${environment.apiURL}/admin/azure/central`;
      data = {
        action: "list_unused_vms",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (!result) {
      this.stoppedEC2Loading = false;
      return false;
    }

    if (result.status == "1" || result.s == "1") {
      if(this.selectedCloudPartner == 'azure') {
        result['stoppedInstanceList'] = result['unused_vm'];
      }
      this.stoppedEC2List = [
        ...this.stoppedEC2List,
        ...result.stoppedInstanceList,
      ];
    } else if (
      nextToken == "" &&
      !result.nextToken &&
      result.error &&
      result.error.toLowerCase() != "data not found"
    ) {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    if (result.nextToken) {
      await this.loadStoppedEC2Instances(result.nextToken);
    } else {
      this.stoppedEC2Loading = false;
    }
    return true;
  }

  callFunction(name: any, param: any) {}
}
