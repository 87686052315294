import { Component, OnDestroy, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import * as moment from 'moment';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

declare let window: any;

@Component({
  selector: 'app-acm',
  templateUrl: './acm.component.html',
  styleUrls: [
    './acm.component.css',
    'components/request-certificate/request-certificate.component.css',
  ],
})
export class AcmComponent implements OnInit, OnDestroy {
  accountId: string;
  regionId: string;
  offendeList: any = null;
  job: any;
  requestCertificate: any = null;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private navigator: Router
  ) {}

  alternateNamesHeader: any = [
    {
      id: 'alternateName',
      name: 'Alternate Name',
    },
  ];

  selectedCertificate: any = null;

  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;

  overview: any = {
    total: 0,
    active: 0,
    renew_week: 0,
    expired: 0,
    renew_7d: 0,
    renew_30d: 0,
    renew_90d: 0,
    failed: 0,
  };

  keys: any = {
    RSA_1024: '',
    RSA_2048: '',
    RSA_3072: '',
    RSA_4096: '',
    EC_prime256v1: '',
    EC_secp384r1: '',
    EC_secp521r1: '',
  };

  types: any = {
    IMPORTED: '',
    AMAZON_ISSUED: '',
    PRIVATE: '',
  };

  currentMessage: any;

  loading: boolean = false;
  destroyed: boolean = false;

  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  acms: any = [];

  filterApplied: any = {
    status: false,
    key: '',
    value: '',
  };

  selectedARN: string | null = null;

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.activeRoute.queryParams.subscribe((values: any) => {
      if(values['CertificateArn']) {
        this.selectedARN = values['CertificateArn'];
      } else {
        this.selectedARN = null;
      }
    })

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });

    window.addEventListener('scroll', (event: any) => {
      this.moveTop(event);
    });
  }

  pageOffset: number = 0;
  moveTop(event: any) {
    this.pageOffset = window.pageYOffset;
  }

  moveToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  requestCertificateAction(acm?: any) {
    if (acm) {
      this.requestCertificate = {
        action: 'request_certificate',
        region_id: this.regionId,
        account_id: this.accountId,
        domain_name: acm.DomainName,
        validation_method: 'DNS',
        subject_alternate_names: acm.SubjectAlternativeNameSummaries,
      };
    } else {
      this.requestCertificate = {
        action: 'request_certificate',
        region_id: this.regionId,
        account_id: this.accountId,
        domain_name: '',
        validation_method: 'DNS',
        subject_alternate_names: [],
      };
    }
  }

  async describeCertificate(acm: any) {
    this.selectedCertificate = JSON.parse(JSON.stringify(acm));
    delete this.selectedCertificate['details'];
    this.loading = true;
    let data: any = {
      action: 'describe_certificate',
      account_id: this.accountId,
      region_id: this.regionId,
      certificate_arn: this.selectedCertificate['CertificateArn'],
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/operations/acm`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (this.destroyed) {
      return;
    }
    if (result.status == '1' || result.s == '1') {
      this.selectedCertificate['details'] = result['certificate'];
    }

    this.loading = false;
  }

  hideModel(event: any) {
    if (event) {
      this.load(null, event);
    }
    this.requestCertificate = null;
  }

  getDiff(start_date: string, end_date: string) {
    let period = moment(end_date, 'YYYY-MM-DD');
    let sub = moment(start_date, 'YYYY-MM-DD');
    return period.diff(sub, 'days') + 1;
  }

  filterText: string = '';
  filterACMs() {
    if (this.filterText == '' && !this.filterApplied['status']) {
      return this.acms;
    }

    return this.acms.filter((acm: any) => {
      let check: boolean = true;
      if (this.filterApplied['status']) {
        check = acm[this.filterApplied['key']] == this.filterApplied['value'];
      }
      return (
        (acm['DomainName']
          .toLowerCase()
          .indexOf(this.filterText.toLowerCase()) > -1 ||
          acm['SubjectAlternativeNameSummaries'].filter((name: any) => {
            return (
              name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
            );
          }).length) &&
        check
      );
    });
  }

  async load(next_token: any = null, domain_name: string = null) {
    this.loading = true;

    if (!next_token) {
      this.removeFilter();
      this.acms = [];
      this.overview = {
        total: 0,
        active: 0,
        renew_week: 0,
        expired: 0,
        renew_7d: 0,
        renew_30d: 0,
        renew_90d: 0,
        failed: 0,
      };
    }

    let data: any = {
      action: 'list_acms',
      account_id: this.accountId,
      region_id: this.regionId,
      next_token: next_token || undefined,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/operations/acm`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (this.destroyed) {
      return;
    }
    if (result.status == '1' || result.s == '1') {
      result['acms'] = result['acms'].map((acm: any) => {
        acm['id'] = acm['CertificateArn'].split('/')[1];
        if (acm['SubjectAlternativeNameSummaries']) {
          acm['SubjectAlternativeNameSummariesUpdated'] = acm[
            'SubjectAlternativeNameSummaries'
          ].map((name: any) => {
            return { alternateName: name };
          });
        } else {
          acm['SubjectAlternativeNameSummaries'] = [];
        }
        acm['IssuedAtText'] = moment(acm['IssuedAt']).format('MMM DD, YYYY');
        acm['NotAfterText'] = moment(acm['NotAfter']).format('MMM DD, YYYY');
        // if (domain_name && acm['DomainName'] == domain_name) {
        //   this.describeCertificate(acm);
        // }
        if(this.selectedARN && this.selectedARN == acm['CertificateArn']) {
          this.describeCertificate(acm);
        }
        return acm;
      });
      if (next_token) {
        this.acms = [...this.acms, ...result['acms']];
      } else {
        this.acms = result['acms'];
      }
      this.overview['total'] = this.acms.length;
      this.overview['active'] = this.acms.filter((acm: any) => {
        if (acm['Status'] != 'EXPIRED' && acm['Status'] != 'FAILED') {
          acm['active'] = true;
        } else {
          acm['active'] = false;
        }
        return acm['Status'] != 'EXPIRED' && acm['Status'] != 'FAILED';
      }).length;
      this.overview['expired'] = this.acms.filter((acm: any) => {
        if (acm['Status'] == 'EXPIRED') {
          acm['expired'] = true;
        } else {
          acm['expired'] = false;
        }
        return acm['Status'] == 'EXPIRED';
      }).length;
      this.overview['failed'] = this.acms.filter((acm: any) => {
        if (acm['Status'] == 'FAILED') {
          acm['failed'] = true;
        } else {
          acm['failed'] = false;
        }
        return acm['Status'] == 'FAILED';
      }).length;
      this.overview['renew_week'] = this.acms.filter((acm: any) => {
        if (
          this.getDiff(
            moment(acm['NotAfter']).format('YYYY-MM-DD'),
            moment().endOf('week').format('YYYY-MM-DD')
          ) > 0 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 0 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        ) {
          acm['renew_week'] = true;
        } else {
          acm['renew_week'] = false;
        }
        return (
          this.getDiff(
            moment(acm['NotAfter']).format('YYYY-MM-DD'),
            moment().endOf('week').format('YYYY-MM-DD')
          ) > 0 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 0 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        );
      }).length;
      this.overview['renew_7d'] = this.acms.filter((acm: any) => {
        if (
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 0 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) <= 7 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        ) {
          acm['renew_7d'] = true;
        } else {
          acm['renew_7d'] = false;
        }
        return (
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 0 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) <= 7 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        );
      }).length;
      this.overview['renew_30d'] = this.acms.filter((acm: any) => {
        if (
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 7 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) <= 30 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        ) {
          acm['renew_30d'] = true;
        } else {
          acm['renew_30d'] = false;
        }
        return (
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 7 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) <= 30 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        );
      }).length;
      this.overview['renew_90d'] = this.acms.filter((acm: any) => {
        if (
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 30 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) <= 90 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        ) {
          acm['renew_90d'] = true;
        } else {
          acm['renew_90d'] = false;
        }
        return (
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) > 30 &&
          this.getDiff(
            moment().format('YYYY-MM-DD'),
            moment(acm['NotAfter']).format('YYYY-MM-DD')
          ) <= 90 &&
          acm['Status'] != 'EXPIRED' &&
          acm['Status'] != 'FAILED'
        );
      }).length;
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error_message || result.message || result.error,
        'info',
        5000
      );
    }

    // if (!domain_name) {
    this.loading = false;
    // }

    if (result['next_token']) {
      this.load(result['next_token']);
    }

    this.loadGraph();
    this.loadGraphTypes();
  }

  goBack() {
    this.selectedCertificate = null;
    this.selectedARN = null;
    this.navigator.navigate(['/dash/operations/acm'])
  }

  addFilter(key: string, value: any, text: string) {
    this.page = 1;
    this.filterApplied['status'] = true;
    this.filterApplied['key'] = key;
    this.filterApplied['value'] = value;
    this.filterApplied['text'] = text;
    var element = document.querySelector('.table-acm-container');
    var headerOffset = 60;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  removeFilter() {
    this.filterText = '';
    this.filterApplied = {
      status: false,
      key: '',
      value: '',
    };
  }

  loadGraph() {
    var chartDom = document.getElementById('algorithms_graph');
    var myChart = echarts.init(chartDom);

    myChart.on('click', (params: any) => {
      this.addFilter(
        'KeyAlgorithm',
        params['name'].replace('_', '-'),
        'Algorithm'
      );
    });

    let option = {
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: 'Type',
          type: 'pie',
          radius: '50%',
          data: Object.keys(this.keys)
            .filter((key: string) => {
              return (
                this.acms.filter((acm: any) => {
                  return (
                    acm['KeyAlgorithm'] == key ||
                    acm['KeyAlgorithm'] == key.replace('_', '-')
                  );
                }).length > 0
              );
            })
            .map((key: any) => {
              return {
                name: key,
                value: this.acms.filter((acm: any) => {
                  return (
                    acm['KeyAlgorithm'] == key ||
                    acm['KeyAlgorithm'] == key.replace('_', '-')
                  );
                }).length,
              };
            }),

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadGraphTypes() {
    var chartDom = document.getElementById('types_graph');
    var myChart = echarts.init(chartDom);

    myChart.on('click', (params: any) => {
      this.addFilter('Type', params['name'], 'Types');
    });

    let option = {
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: 'Type',
          type: 'pie',
          radius: '50%',
          data: Object.keys(this.types)
            .filter((key: string) => {
              return (
                this.acms.filter((acm: any) => {
                  return (
                    acm['Type'] == key || acm['Type'] == key.replace('_', '-')
                  );
                }).length > 0
              );
            })
            .map((key: any) => {
              return {
                name: key,
                value: this.acms.filter((acm: any) => {
                  return (
                    acm['Type'] == key || acm['Type'] == key.replace('_', '-')
                  );
                }).length,
              };
            }),

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  exportDomains() {
    window.exportToExcel(
      'domains-id',
      `Domains - ${this.selectedCertificate['DomainName']}`,
      'csv'
    );
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    window.removeEventListener('scroll', (event: any) => {
      this.moveTop(event);
    });
  }
}
