import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.css", "./../../warquiz.component.css"],
})
export class QuestionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input("workload") selectedWorkload: any;
  @Input("filter") filter: any;
  @Input("questions") questions: any;
  @Input("pillers") pillers: any;
  @Input("lens") selectedLens: any;
  @Input("piller") selectedPiller: any;
  @Input("indexs") currentQuestionIndexs: any;
  @Input("isMilestone") isMilestone: any;
  @Input("milestone") milestone: any;
  @Input("questionsCode") questionsCode: any;
  @Input("loading") loading: any;
  @Output("changeQuestion") questionChange = new EventEmitter<any>();
  unsavedChanges: any = {
    status: false,
    callback: null,
  };
  noneOfThese: any = {
    status: false,
    callback: null,
  };

  filters: any = {};

  currentMessage: any;

  @Output("removePiller") removePiller: any = new EventEmitter<boolean>();

  currentQuestion: any;

  showSidebar: boolean = true;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  pillersData: any = {
    costOptimization: "Cost Optimization",
    operationalExcellence: "Operational Excellence",
    performance: "Performance",
    reliability: "Reliability",
    security: "Security",
    sustainability: "Sustainability",
  };

  lensesData: any = {
    softwareasaservice: "SaaS Lens",
    wellarchitected: "AWS Well-Architected Framework",
    serverless: "Serverless Lens",
    foundationaltechnicalreview: "FTR Lens",
    "arn:aws:wellarchitected::aws:lens/sap": "SAP Lens",
  };

  reasonsData: any = {
    OUT_OF_SCOPE: "Out of Scope",
    BUSINESS_PRIORITIES: "Business Priorities",
    ARCHITECTURE_CONSTRAINTS: "Architecture Constraints",
    OTHER: "Others",
    NONE: "None",
  };

  initialized: boolean = false;

  whitelabelData: any = {
    pd: {
      appName: "",
      logoDark: "",
      logoLight: "",
    },
  };

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.whitelabelData = this.route.snapshot.data.data;
  }

  ngOnInit(): void {
    // console.log("pillers mapping",this.pillers);
    // console.log("piller data being compared", this.pillersData);

    if(!this.lensesData[this.selectedLens]){
      this.selectedWorkload["profile"] = null;
    }

    this.pillers.forEach((piller: string) => {
      if (!Object.keys(this.pillersData).includes(piller)) {
        this.pillersData[piller] = piller;
      }
      Object.keys;
    });
    this.filters = this.filter;
    this.filters["risk"] = "All";
    console.log("selected workload", this.selectedWorkload);
    if (this.selectedWorkload["profile"]) {
      console.log("inside if considition");
      this.selectedQuestionType = "PRIORITIZED";
    } else {
      this.selectedQuestionType = "NON_PRIORITIZED";
    }
    setTimeout(() => {
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] = 0;
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
      if (!this.milestone && this.currentQuestion["IsApplicable"] == false) {
        this.isMilestone = true;
      } else if (!this.milestone && this.currentQuestion["IsApplicable"]) {
        this.isMilestone = false;
      }
    }, 100);

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (this.initialized) {
        if (d.key == "accountId") {
          let current_question =
            this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
            ];
          this.save(this.currentQuestion);
        } else if (d.key == "regionId") {
          let current_question =
            this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
            ];
          this.save(this.currentQuestion);
        }
      } else {
        this.initialized = true;
      }
    });
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

  removeDummyComments(question: any) {
    question["ChoiceAnswerSummaries"].forEach((choice: any) => {
      choice["errors"] = undefined;
      // choice['edit'] = undefined;
      choice["reason_dummy"] = undefined;
      let swayamChoice = question["SwayamChoices"].find((choice_: any) => {
        return choice_["choiceId"] == choice["ChoiceId"];
      });
      if (
        swayamChoice &&
        swayamChoice["comments"] &&
        swayamChoice["comments"] != ""
      ) {
        choice["Notes"] = swayamChoice["comments"];
      }
    });
    if (
      JSON.stringify(question).includes("dummy_comment") ||
      (question["SelectedChoices"].length > 0 &&
        question["Risk"] != "UNANSWERED" &&
        question["SelectedChoices"][0].endsWith("_no"))
    ) {
      let check = false;
      question["SwayamChoices"] = question["SwayamChoices"].map(
        (choice: any) => {
          if (
            choice["choiceId"].endsWith("_no") &&
            question["SelectedChoices"][0].endsWith("_no") &&
            choice["edit"]
          ) {
            check = true;
          }
          if (choice["dummy_comment"]) {
            choice["comments"] = choice["dummy_comment"];
            choice["dummy_comment"] = undefined;
            choice["edit"] = false;
          }
          return choice;
        }
      );

      if (check) {
        question["SelectedChoices"] = this.currentQuestion["SelectedChoices"];
        question["SwayamChoices"] = this.currentQuestion["SwayamChoices"];
        question["ChoiceAnswerSummaries"] =
          this.currentQuestion["ChoiceAnswerSummaries"];
        question["Choices"] = this.currentQuestion["Choices"];
      }
    }

    let checkNo: boolean = false;
    question["SwayamChoices"] = question["SwayamChoices"].filter(
      (choice: any) => {
        if (!choice["choiceId"].endsWith("_no")) {
          return true;
        } else if (!checkNo) {
          checkNo = true;
          return true;
        } else {
          return false;
        }
      }
    );
  }

  async changePiller(piller: any, prioritized: string = "NON_PRIORITIZED") {
    if (!this.validateQuestion(true)) {
      return false;
    }
    if (this.questions.length == 0 || this.filterQuestions().length == 0) {
      this.selectedPiller = piller;
      this.currentQuestion = null;
    }
    if (this.filterQuestions().length == 0) {
      return false;
    }
    let current_question =
      this.filterQuestions()[
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];
    if (
      this.currentQuestion == null ||
      JSON.stringify(this.currentQuestion) == JSON.stringify(current_question)
    ) {
      if (
        this.selectedWorkload["profile"] &&
        prioritized != this.selectedQuestionType
      ) {
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] = 0;
      }
      this.selectedQuestionType = prioritized;
      this.selectedPiller = piller;
      if (this.filterQuestions().length == 0) {
        this.currentQuestion = null;
        return false;
      }
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
    } else {
      // this.unsavedChanges['status'] = true;
      // this.unsavedChanges['callback'] = () => {
      //   this.changePiller(piller);
      // };
      let current_question =
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      await this.save(this.currentQuestion);
      this.changePiller(piller);
    }

    return true;
  }

  async popupAction(event: string) {
    if (event) {
      if (event == "SAVE") {
        let current_question =
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ];
        await this.save(this.currentQuestion);
        this.unsavedChanges["callback"]();
      } else if (event == "SKIP") {
        this.resetQuestion();
        this.unsavedChanges["callback"]();
      }
      this.unsavedChanges["status"] = false;
    }
  }

  async changeQuestion(index: number) {
    if (!this.validateQuestion(true)) {
      return false;
    }
    let current_question =
      this.filterQuestions()[
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];

    if (
      JSON.stringify(this.currentQuestion) ==
      JSON.stringify(current_question) ||
      this.currentQuestion == null
    ) {
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] =
        index;
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
      if (!this.milestone && this.currentQuestion["IsApplicable"] == false) {
        this.isMilestone = true;
      } else if (!this.milestone && this.currentQuestion["IsApplicable"]) {
        this.isMilestone = false;
      }
    } else {
      // this.unsavedChanges['status'] = true;
      // this.unsavedChanges['callback'] = () => {
      //   this.changeQuestion(index);
      // };
      // let current_question =
      //   this.filterQuestions()[
      //     this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      //   ];
      await this.save(this.currentQuestion);
      this.changeQuestion(index);
    }

    return false;
  }

  changeApplication(question: any) {
    question["IsApplicable"] = !question["IsApplicable"];
    this.isMilestone = !question["IsApplicable"];
    if (!question["IsApplicable"]) {
      let choiceSummary: any = question["ChoiceAnswerSummaries"].filter(
        (choice: any) => {
          return (
            choice["reason_dummy"] != undefined ||
            (choice["reason_dummy"] && choice["reason_dummy"].trim() == "")
          );
        }
      );

      choiceSummary.forEach((choice: any) => {
        let swayamChoice: any = question["SwayamChoices"].find(
          (choice_: any) => {
            return choice_["choiceId"] == choice["ChoiceId"];
          }
        );
        if (swayamChoice) {
          this.resetFix(null, swayamChoice);
        } else {
          this.resetFix(null, choice);
        }
        choice["errors"] = undefined;
        choice["reason_dummy"] = undefined;
        choice["Reason"] = "";
        choice["Status"] = "UNSELECTED";
      });
    }
  }

  async changeFilter(
    filter: string,
    key: string = "questions",
    save_check: boolean = true
  ) {
    if (this.filterQuestions().length == 0) {
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] = 0;
      this.filters[key] = filter;

      if (
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ]
      ) {
        this.currentQuestion = JSON.parse(
          JSON.stringify(
            this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
            ]
          )
        );
      }
    }
    if (this.currentQuestion && !this.validateQuestion(true)) {
      return false;
    } else if (this.currentQuestion) {
      let current_question =
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];

      if (
        JSON.stringify(this.currentQuestion) == JSON.stringify(current_question)
      ) {
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] = 0;
        this.filters[key] = filter;

        if (
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        ) {
          this.currentQuestion = JSON.parse(
            JSON.stringify(
              this.filterQuestions()[
              this.currentQuestionIndexs[this.selectedLens][
              this.selectedPiller
              ]
              ]
            )
          );
        }
      } else if (save_check) {
        let result: boolean = await this.save(this.currentQuestion);
        if (result) {
          this.changeFilter(filter, key);
        }
      } else {
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] = 0;
        if (
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        ) {
          this.currentQuestion = JSON.parse(
            JSON.stringify(
              this.filterQuestions()[
              this.currentQuestionIndexs[this.selectedLens][
              this.selectedPiller
              ]
              ]
            )
          );
        }
      }
    } else {
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] = 0;
      this.filters[key] = filter;

      if (
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ]
      ) {
        this.currentQuestion = JSON.parse(
          JSON.stringify(
            this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
            ]
          )
        );
      }
    }

    if (
      this.filterQuestions().length > 0 &&
      !this.milestone &&
      this.currentQuestion["IsApplicable"] == false
    ) {
      this.isMilestone = true;
    } else if (
      this.filterQuestions().length > 0 &&
      !this.milestone &&
      this.currentQuestion["IsApplicable"]
    ) {
      this.isMilestone = false;
    } else {
      this.isMilestone = true;
    }

    return true;
  }

  async save(question: any) {
    if (this.isMilestone && this.milestone) {
      return false;
    }

    if (
      !this.filterQuestions()[
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ]
    ) {
      return false;
    }
    if (!this.validateQuestion(true)) {
      return false;
    }

    this.loading["saving"] = true;
    this.notifier.loading(true);
    this.removeDummyComments(question);

    let currentLength: number = this.filterQuestions().length;

    let data: any = {
      action: "answer_question",
      aws_workload_id: this.selectedWorkload["aws_id"],
      region_id: this.selectedWorkload["region_id"],
      account_id: this.selectedWorkload["account_id"],
      swayam_workload_id: this.selectedWorkload["id"],
      payload: question,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (this.initialized) {
      this.notifier.loading(false);
    }
    this.loading["saving"] = false;

    if (result.status == "1" || result.s == "1") {
      this.currentQuestion["Risk"] = result["risk"];
      if (
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ] &&
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ]["QuestionId"] == this.currentQuestion["QuestionId"]
      ) {
        this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ]["Risk"] = result["risk"];
      }

      let index: string = question["QuestionId"];

      let question_: any = this.questions.findIndex((que: any) => {
        return que["QuestionId"] == index;
      });

      if (question_ > -1) {
        this.questions[question_] = JSON.parse(JSON.stringify(question));
      }

      let questionsLength: number = this.filterQuestions().length;

      if (currentLength != questionsLength) {
        if (
          questionsLength > 0 &&
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] +
          1 >
          questionsLength
        ) {
          this.currentQuestionIndexs[this.selectedLens][
            this.selectedPiller
          ] -= 1;
        } else if (
          questionsLength > 0 &&
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] +
          1 <
          questionsLength
        ) {
          this.currentQuestionIndexs[this.selectedLens][
            this.selectedPiller
          ] += 1;
        }

        if (
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        ) {
          this.currentQuestion = JSON.parse(
            JSON.stringify(
              this.filterQuestions()[
              this.currentQuestionIndexs[this.selectedLens][
              this.selectedPiller
              ]
              ]
            )
          );
        }
      }

      if (this.filter["questions"] == "Unanswered") {
        this.changeFilter(this.filter["questions"]);
      }

      if (!this.milestone && this.currentQuestion["IsApplicable"] == false) {
        this.isMilestone = true;
      } else if (!this.milestone && this.currentQuestion["IsApplicable"]) {
        this.isMilestone = false;
      }
      // JSON.parse(JSON.stringify(this.currentQuestion)));
      return true;
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
      return false;
    }
  }

  editComment(event: any, comment: any) {
    event.preventDefault();
    event.stopPropagation();
    comment["edit"] = true;
  }

  checkQuestionIsComplianced(question: any) {
    if (question["IsApplicable"] == false) {
      return false;
    }
    if (this.checkIfAnswered(question)) {
      if (question["SwayamChoices"] && question["SwayamChoices"].length > 0) {
        // let check = false;
        let failedOptions = question["SwayamChoices"].filter((choice: any) => {
          return !(
            choice["evidenceStatus"] != undefined ||
            (choice["evidence"] && choice["evidence"].length == 0) ||
            choice["evidence"] == undefined ||
            choice["source"] == "Manual" ||
            (choice["evidence"] &&
              choice["evidence"].length > 0 &&
              choice["evidence"].filter((evidence: any) => {
                return (
                  evidence["status"] || evidence["message"] == "Swayam Findings"
                );
              }).length == choice["evidence"].length)
          );
        });

        let unselectedOption = question["Choices"].filter((choice: any) => {
          return (
            question["SelectedChoices"].indexOf(choice["ChoiceId"]) <= -1 &&
            !choice["ChoiceId"].endsWith("_no") &&
            question["ChoiceAnswerSummaries"].find((choice_: any) => {
              return (
                choice_["ChoiceId"] == choice["ChoiceId"] &&
                choice_["Status"] != "NOT_APPLICABLE"
              );
            })
          );
        });

        return failedOptions.length > 0 || unselectedOption.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  filterQuestions(
    value: any = undefined,
    type: string = undefined,
    answered: boolean = false,
    prioritized: boolean = undefined
  ) {
    return this.questions
      .filter((question: any) => {
        let check: boolean = this.filter["questions"] == "All";
        if (
          this.filter["questions"] == "Unanswered" &&
          !answered &&
          !this.checkIfAnswered(question)
        ) {
          check = true;
        }
        if (
          this.filter["questions"] == "Well-Architected" &&
          !this.checkQuestionIsComplianced(question) &&
          this.checkIfAnswered(question)
        ) {
          check = true;
        }
        if (
          this.filter["questions"] == "Non Compliance" &&
          this.checkQuestionIsComplianced(question) &&
          this.checkIfAnswered(question)
        ) {
          check = true;
        }

        // if(){
        if (prioritized != undefined) {
          if (prioritized && question["QuestionType"] != "PRIORITIZED") {
            return false;
          }

          if (!prioritized && question["QuestionType"] == "PRIORITIZED") {
            return false;
          }
        } else if (this.selectedQuestionType) {
          if (
            this.selectedQuestionType == "PRIORITIZED" &&
            question["QuestionType"] != "PRIORITIZED"
          ) {
            return false;
          }
          if (
            this.selectedQuestionType != "PRIORITIZED" &&
            question["QuestionType"] == "PRIORITIZED"
          ) {
            return false;
          }
        }
      // }

        if (type) {
          if (answered) {
            if (type == "PillarId") {
              return (
                check &&
                question[type] == value &&
                (question["SelectedChoices"].length > 0 ||
                  !question["IsApplicable"]) &&
                question["lensId"] == this.selectedLens
              );
            }
            return (
              check &&
              question[type] == value &&
              question["SelectedChoices"].length > 0
            );
          } else {
            if (type == "PillarId") {
              return (
                check &&
                question[type] == value &&
                question["lensId"] == this.selectedLens
              );
            }
            return check && question[type] == value;
          }
        } else {
          return (
            check &&
            question.PillarId == this.selectedPiller &&
            question.lensId == this.selectedLens
          );
        }
      })
      .filter((question: any) => {
        return (
          this.filters["risk"] == "All" ||
          question["Risk"] == this.filters["risk"]
        );
      });
  }

  getSwayamOption(question: any, choice: any) {
    if (!question["SwayamChoices"] || question["SwayamChoices"].length == 0) {
      return null;
    }

    return (
      question["SwayamChoices"].find((choice_: any) => {
        return choice_["choiceId"] == choice["ChoiceId"];
      }) || null
    );
  }

  getBestPractices(question: any, choice: any) {
    let check: any = question["BestPractices"].find((practice: any) => {
      return practice["choice_id"] == choice["ChoiceId"];
    });
    if (check) {
      return check["best_practices"];
    } else {
      null;
    }
  }

  getOptionSummary(question: any, choice: any) {
    if (
      !question["ChoiceAnswerSummaries"] ||
      question["ChoiceAnswerSummaries"].length == 0
    ) {
      return null;
    }

    return (
      question["ChoiceAnswerSummaries"].find((choice_: any) => {
        return choice_["ChoiceId"] == choice["ChoiceId"];
      }) || null
    );
  }

  preventDefault(event: any, prevent: boolean = true) {
    if (prevent) {
      event.preventDefault();
    }
    event.stopPropagation();
    return true;
  }

  position: any = {
    x: "0px",
    y: "0px",
    overflow: false,
    overflowwidth: "0px",
    pointer: "0px",
    width: "",
    height: "",
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector(".message_back").offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector(".message_back").offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + "px";
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + "px";
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector(".message_back").offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector(".message_back").offsetWidth / 2 +
        "px";
    } else {
      this.position.x = 60 + "px";
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      "px";
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + "px";
    this.position.width =
      event.target.querySelector(".message_grid").offsetWidth + 30 + "px";
    this.position.height =
      event.target.querySelector(".message_grid").offsetHeight + 30 + "px";
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector(".message_grid").offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  selectOption(question: any, i: number, event: any) {
    if (this.isMilestone) {
      return;
    }
    let choice = question.Choices[i];
    let noneoption: any;
    question.Choices.forEach((choice: any) => {
      if (choice.Title == "None of these") {
        noneoption = choice;
      }
      if (choice["reject_reason"]) {
        choice["reject_reason"] = undefined;
      }
    });
    let noneOption = question.SelectedChoices.findIndex((choice: any) => {
      return choice == noneoption.ChoiceId;
    });

    if (noneOption != null && noneOption > -1) {
      question.SelectedChoices.splice(noneOption, 1);
      let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
        return choice_["ChoiceId"] == noneoption.ChoiceId;
      });
      if (summary) {
        summary["Status"] = "UNSELECTED";
      }
    }
    if (choice.Title != "None of these") {
      let answered = question.SelectedChoices.findIndex((selected: any) => {
        return selected == choice["ChoiceId"];
      });
      if (answered != null && answered > -1) {
        if (question["SwayamChoices"]) {
          let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
            return choice_["ChoiceId"] == choice["ChoiceId"];
          });
          if (summary) {
            summary["Status"] = "UNSELECTED";
          }
          question.SelectedChoices.splice(answered, 1);
        } else {
          question.SelectedChoices.splice(answered, 1);
          let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
            return choice_["ChoiceId"] == choice.ChoiceId;
          });
          if (summary) {
            summary["Status"] = "UNSELECTED";
          }
        }
      } else {
        question.SelectedChoices.push(choice.ChoiceId);
        let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
          return choice_["ChoiceId"] == choice.ChoiceId;
        });
        let temp = question["SwayamChoices"].find((cho: any) => {
          return cho["choiceId"] == choice["ChoiceId"];
        });
        if (summary) {
          summary["Status"] = "SELECTED";
        }
        if (temp) {
          temp["decision"] = undefined;
        }
      }
      return;
    }

    if (
      question.SelectedChoices.length == 1 &&
      question.SelectedChoices[0].endsWith("_no")
    ) {
      question.SelectedChoices = [];
    } else {
      this.noneOfThese["status"] = true;
      this.noneOfThese["callback"] = () => {
        this.noneOfTheseAction(question, choice);
      };
    }
    return;
  }

  confirmNoneOfThese(event: any) {
    this.noneOfThese["status"] = false;
    if (event) {
      this.noneOfThese["callback"]();
    }
  }

  noneOfTheseAction(question: any, choice: any) {
    question.SelectedChoices = [choice.ChoiceId];
    question.ChoiceAnswerSummaries.forEach((choice_: any) => {
      if (choice_["ChoiceId"] == choice.ChoiceId) {
        choice_["Status"] = "SELECTED";
      } else {
        choice_["Status"] = "UNSELECTED";
      }
    });
  }

  resetQuestion() {
    let question = this.filterQuestions().find((question: any) => {
      return question["QuestionId"] == this.currentQuestion["QuestionId"];
    });

    let json = JSON.parse(JSON.stringify(this.currentQuestion));

    question["SelectedChoices"] = json["SelectedChoices"];
    question["SwayamChoices"] = json["SwayamChoices"];
    question["ChoiceAnswerSummaries"] = json["ChoiceAnswerSummaries"];
    question["Choices"] = json["Choices"];
  }

  removeRejectReason(event: any, option: any) {
    event.preventDefault();
    event.stopPropagation();
    option["reject_reason"] = undefined;
  }

  submitReason(event: any, question: any, choice: any) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.validateQuestion()) {
      return false;
    }
    let answered = question.SelectedChoices.findIndex((selected: any) => {
      return selected == choice["ChoiceId"];
    });
    question.SelectedChoices.splice(answered, 1);
    choice["reject_reason"] = undefined;

    return false;
  }

  validateOption(event: any, choice: any, bool: boolean) {
    event.preventDefault();
    event.stopPropagation();
    choice["evidenceStatus"] = bool;
  }

  resetEvidence(event: any, evidence: any) {
    event.preventDefault();
    event.stopPropagation();
    evidence["evidenceStatus"] = undefined;
    evidence["decision"] = undefined;
    evidence["comments"] = "";

    let question = this.currentQuestion;
    let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
      return choice_["ChoiceId"] == evidence.choiceId;
    });
    if (summary) {
      summary["Reason"] = "";
      summary["reason_dummy"] = summary["Reason"];
      let question =
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      let answered = question["SelectedChoices"].findIndex((choice_: any) => {
        return choice_ == evidence["choiceId"];
      });
      if (answered != null) {
        summary["Status"] = "SELECTED";
      } else {
        summary["Status"] = "UNSELECTED";
      }
    }
  }

  fixOption(event: any, choice: any, fix: string, key: string = "decision") {
    event.preventDefault();
    event.stopPropagation();
    choice[key] = fix;

    if (key == "decision") {
      choice["comments"] = "";
      let question = this.currentQuestion;
      let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
        return choice_["ChoiceId"] == choice.choiceId;
      });
      if (summary) {
        summary["reason_dummy"] = summary["Reason"] || "";
        let question = this.currentQuestion;
        let answered = question["SelectedChoices"].findIndex((choice_: any) => {
          return choice_ == choice["choiceId"];
        });
        if (fix == "Not Applicable") {
          if (answered > -1) {
            this.currentQuestion["SelectedChoices"].splice(answered, 1);
          }
          summary["Status"] = "NOT_APPLICABLE";
        } else {
          if (answered != null) {
            summary["Status"] = "UNSELECTED";
          } else {
            summary["Status"] = "SELECTED";
          }
        }
        summary["Reason"] = summary["Reason"] || "";
      } else {
        let question = this.currentQuestion;
        let answered = question["SelectedChoices"].findIndex((choice_: any) => {
          return choice_ == choice["choiceId"];
        });
        if (answered > -1) {
          this.currentQuestion.splice(answered, 1);
        }
      }
    } else {
      choice["reason_dummy"] = choice["Reason"] || "";
      choice["Status"] =
        fix == "Not Applicable" ? "NOT_APPLICABLE" : choice["Status"];
    }
  }

  selectedQuestionType: string = "NON_PRIORITIZED";

  resetFix(
    event: any,
    evidence: any,
    key: string = "decision",
    value: string = undefined
  ) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    evidence[key] = value;

    if (key == "decision") {
      evidence["comments"] = "";
      let question = this.currentQuestion;
      let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
        return choice_["ChoiceId"] == evidence.choiceId;
      });
      if (summary) {
        summary["reason_dummy"] = undefined;
        summary["Reason"] = undefined;
        summary["Status"] = "UNSELECTED";
      }
    } else {
      evidence["reason_dummy"] = undefined;
      evidence["Reason"] = undefined;
      evidence["Status"] = "UNSELECTED";
      let question =
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      let summary = question.SwayamChoices.find((choice_: any) => {
        return choice_["choiceId"] == evidence.ChoiceId;
      });

      if (summary) {
        summary["comments"] = "";
        summary["dummy_comment"] = undefined;
      }
    }
  }

  validateQuestion(scroll: boolean = false) {
    let question: any = this.currentQuestion;
    let check: boolean = true;
    let errors: string[] = [];

    if (!question) {
      return true;
    }

    // checkSwayamChoices
    question["ChoiceAnswerSummaries"].forEach((choice: any) => {
      if (choice["Status"] == "NOT_APPLICABLE") {
        if (choice["reason_dummy"] != undefined) {
          // let swayamChoice: any = question['SwayamChoices'].find(
          //   (choice_: any) => {
          //     return choice_['choiceId'] == choice['ChoiceId'];
          //   }
          // );
          // if (swayamChoice) {
          choice["errors"] = {};
          choice["errors"]["reason"] = "Please submit your choice";
          check = false;
          errors.push(choice["ChoiceId"]);
          // }
        }
        if (
          choice["reason_dummy"] != undefined &&
          choice["reason_dummy"].trim() == ""
        ) {
          // let swayamChoice: any = question['SwayamChoices'].find(
          //   (choice_: any) => {
          //     return choice_['choiceId'] == choice['ChoiceId'];
          //   }
          // );
          // if (swayamChoice) {
          choice["errors"] = {};
          choice["errors"]["reason"] = "Please select a reason";
          check = false;
          errors.push(choice["ChoiceId"]);
          // }
        }
      }
    });

    question["ChoiceAnswerSummaries"].forEach((choice: any) => {
      if (errors.indexOf(choice["ChoiceId"]) <= -1) {
        choice["errors"] = undefined;
      }
    });

    if (scroll) {
      setTimeout(() => {
        let elements: any = document.querySelectorAll(".error-message");
        if (elements.length > 0) {
          elements[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }, 100);
    }

    return check;
  }

  resetReason(event: any, choice: any, clear: boolean) {
    event.preventDefault();
    event.stopPropagation();
    // this.validateQuestion();
    if (clear) {
      choice["reason_dummy"] = choice["Reason"];
    } else {
      if (choice["reason_dummy"] != "") {
        choice["Reason"] = choice["reason_dummy"];
        choice["reason_dummy"] = undefined;
      }
      if (!this.validateQuestion()) {
        return false;
      }
    }

    return true;
  }

  checkIfChoiceIsSelected(question: any, choice_id: string) {
    return question["SelectedChoices"].indexOf(choice_id) > -1;
  }

  checkIfAnswered(question: any) {
    return (
      question["SelectedChoices"].length > 0 ||
      !question["IsApplicable"] ||
      question["ChoiceAnswerSummaries"].filter((choice: any) => {
        return choice["Status"] == "NOT_APPLICABLE";
      }).length ==
      question["ChoiceAnswerSummaries"].length - 1
    );
  }

  async next(force: boolean = false) {
    if (!this.validateQuestion(true)) {
      return false;
    }
    let current_question =
      this.filterQuestions()[
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];

    if (
      JSON.stringify(this.currentQuestion) ==
      JSON.stringify(current_question) ||
      force
    ) {
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ] = this.currentQuestion;
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] += 1;
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
      if (!this.milestone && this.currentQuestion["IsApplicable"] == false) {
        this.isMilestone = true;
      } else if (!this.milestone && this.currentQuestion["IsApplicable"]) {
        this.isMilestone = false;
      }
    } else {
      // this.unsavedChanges['status'] = true;
      // this.unsavedChanges['callback'] = () => {
      //   this.next(true);
      // };
      // let current_question =
      //   this.filterQuestions()[
      //     this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      //   ];
      await this.save(this.currentQuestion);
      this.next(true);
    }
    return true;
  }
  async prev(force: boolean = false) {
    if (!this.validateQuestion(true)) {
      return false;
    }
    let current_question =
      this.filterQuestions()[
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];
    if (
      JSON.stringify(this.currentQuestion) ==
      JSON.stringify(current_question) ||
      force
    ) {
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ] = this.currentQuestion;
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] -= 1;
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
      if (!this.milestone && this.currentQuestion["IsApplicable"] == false) {
        this.isMilestone = true;
      } else if (!this.milestone && this.currentQuestion["IsApplicable"]) {
        this.isMilestone = false;
      }
    } else {
      // this.unsavedChanges['status'] = true;
      // this.unsavedChanges['callback'] = () => {
      //   this.prev(true);
      // };
      let current_question =
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      await this.save(this.currentQuestion);
      this.prev(true);
    }
    return true;
  }

  async goBack() {
    if (this.selectedLens && this.selectedPiller && this.selectedWorkload) {
      let current_question =
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      if (
        JSON.stringify(this.currentQuestion) !=
        JSON.stringify(current_question) &&
        this.currentQuestion
      ) {
        // this.unsavedChanges['status'] = true;
        // this.unsavedChanges['callback'] = () => {
        //   this.removePiller.emit(true);
        // };
        let current_question =
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ];
        await this.save(this.currentQuestion);
        this.removePiller.emit(true);
        return;
      }
    }
    this.removePiller.emit(true);
  }

  showComment(event: any, choice: any) {
    event.preventDefault();
    event.stopPropagation();
    choice["show_comment"] = true;
  }

  alterPratice(event: any, practice: any) {
    if (this.isMilestone) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    practice["selected"] = !practice["selected"];
  }

  addComment(event: any, option: any) {
    event.preventDefault();
    event.stopPropagation();
    option["comments"] = option["dummy_comment"];
    option["dummy_comment"] = undefined;
  }

  canDeactivate(): any {
    if (this.selectedPiller && this.selectedLens && this.currentQuestion) {
      let current_question =
        this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      if (
        JSON.stringify(this.currentQuestion) ==
        JSON.stringify(current_question) ||
        this.currentQuestion == null
      ) {
        return true;
      } else {
        return this.save(this.currentQuestion);
      }
    } else {
      return true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["questionsCode"]) {
      if (this.currentQuestion != undefined && this.currentQuestion != null) {
        let current_question =
          this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ];
        if (
          JSON.stringify(this.currentQuestion) ==
          JSON.stringify(current_question) ||
          this.currentQuestion == null
        ) {
          this.questionChange.emit(this.currentQuestion);
        } else {
          // this.unsavedChanges['status'] = true;
          // this.unsavedChanges['callback'] = () => {
          //   return this.questionChange.emit(this.currentQuestion);
          // };
          return this.questionChange.emit(this.currentQuestion);
        }
      }
    }
  }

  ngOnDestroy() {
    this.initialized = false;
    this.currentMessage.unsubscribe();
  }
}
