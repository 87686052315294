<div class="card">
  <h5 class="card-header">
    <span class="title tit-styl">Tags Cost Controls</span>

    <button class="btn btn-primary-soft btn-pstn" (click)="refresh()">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Refresh
    </button>
    <button class="btn btn-success-soft btn-pstn"
      *ngIf="loading_recom == false && currentPage == 'recomondations' && recomondations.length > 0"
      (click)="downloadReport()"><i class="fa fa-download" aria-hidden="true"></i>
      Download</button>
    <button *ngIf="jobDetails && scanAccess" class="btn btn-success-soft btn-pstn" (click)="createJob($event)">
      <i class="fa fa-search" aria-hidden="true"></i>
      Scan
    </button>
  </h5>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#job-details" type="button"
          role="tab" aria-controls="job-details" aria-selected="true" (click)="loadJobDetails()">
          Scan
        </a>
        <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#floating-amis" type="button"
          role="tab" aria-controls="floating-amis" aria-selected="true" (click)="loadrecomondations()">
          Recommendations
        </a>
      </div>
    </nav>
    <div>
      <!--<div style="width: 100%; height: 500px; position: relative;">-->
      <div class="cont-styl">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane active" id="job-details" role="tabpanel" aria-labelledby="job-details-tab">
            <div class="card mrg-top-0">
              <div class="card-body card-dply" *ngIf="jobDetails && !fetching">
                <div class="crd-dsply">
                  <div class="bold_text">Scanned On</div>
                  <div>{{ jobDetails["scandate"] }}</div>
                </div>
                <div class="crd-dsply">
                  <div class="bold_text">Status</div>
                  <div>{{ jobDetails["accstatus"] }}</div>
                </div>
              </div>
              <div *ngIf="!jobDetails && fetching" class="card-body card-dply">
                <span class="center">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" class="mrg-width" />
                </span>
              </div>
              <div *ngIf="!jobDetails && !fetching" class="card-body card-dply">
                <span class='center'>
                  No Scans Found. <span *ngIf="scanAccess"> <span class="btn_" (click)="createJob($event)">Click
                      here</span> to create one.</span></span>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="floating-amis" role="tabpanel" aria-labelledby="floating-amis-tab">
            <div class="card mrg-top-0">
              <!-- <div style="width: 100%; height: 100px; background-color: red;"></div> -->
              <div class="card-body">
                <div class="dv-styl">
                  <div>Potential Saving: </div>
                  <div>
                    <span class="green_text s-pstn" [innerHTML]="getTotalSavings() + ' USD'"></span>
                  </div>
                  <div *ngIf="loading_recom">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="mrg-width">
                  </div>
                </div>
                <div class="ovr-width">
                  <table class="table table-docs table-hover cost-table" id="tags_cost_optimization">
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th *ngFor="let h of ec2CostHeaders">
                          {{ h.name }}
                          <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, recomondations)">
                            <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                            <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                          </span>

                          <span *ngIf="h.filter" class="inputseach">
                            <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </th>
                        <th>More Details</th>
                      </tr>
                    </thead>

                    <tbody [ngStyle]="{
                      display: recomondations.length ? 'table-row-group' : 'none'
                    }" *ngFor="let i of recomondations; let j = index">
                      <tr *ngIf="notifier.filterTableColums(ec2CostHeaders, i)">
                        <td>{{ j + 1 }}</td>
                        <td *ngFor="let h of ec2CostHeaders">
                          <div *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                            <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}"
                              [innerHTML]="loadHTML(i[h.id])">
                            </div>
                          </div>
                          <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click"
                            [innerHTML]="loadHTML(i[h.id])">
                          </div>
                        </td>
                        <td>
                          <div class="failed_ami" (mouseenter)="setPosition($event)">
                            <div class="status_container">
                              <div class="bdr">
                                More Details
                              </div>
                            </div>
                            <div class="failed_ami_message_container"
                              [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0}">
                              <div class="message_back">
                                <div>
                                  <div class="message_grid">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th *ngFor="let h of moreDetailsHeaders">{{h.name}}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div class="showdown"></div>
                              <div class="message_container">
                                <div>
                                  <div class="message_grid">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th *ngFor="let h of moreDetailsHeaders">{{h.name}}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="recomondations.length == 0" [ngStyle]="{
                      display:
                        recomondations.length == 0 ? 'table-row-group' : 'none'
                    }">
                      <tr>
                        <td [attr.colspan]="ec2CostHeaders.length + 3">
                          <div class="txt-cntr">No Recommendations Found</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngIf="nextToken" class="ng-styl">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="w-mrg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentPage == 'recomondations'" class="end-styl">
        * Disclaimer: The Cost Controls Recommendations given below are based on
        the infra usage. The recommended instances may not be the best choice for
        the applications that are running on them. Please try the recommendation on
        test/dev environment. Customers are requested to use these recommendation at
        their own risk. {{ whitelabelData['pd']['appName'] }} and its promoters should not be held responsible for
        any performance degradation either in part or in whole due to the changes
        recommended here.
        <br /><br />
        * Some recommendations are related to usage of discount plans. Please be
        careful before accepting/applying them as the changes can be irreversible
        resulting in a short/long term revenue loss
      </div>
    </div>
  </div>
</div>

<tags-cost-create-job-element *ngIf="createJobTrigger" (hideModal)="hideCreateJob($event)">
</tags-cost-create-job-element>