<div class="container-fluid p-3" >

  <div class="row mb-3">
    <div class="col-6"><span class="underline">Backup</span></div>
    
   </div>


   <div class="row adjust">
    <div class="col p-3 col-xl-6">
      <div class="box">
        <div class="d-flex justify-content-between">
          <!-- <div class="d-flex"> -->
          <span class="table-title">Top 10 Snapshots by Count</span>
          <div *ngIf="!snapSizeLoader">
          <a href="/dash/reports/view?reportType=backup_reports" target="_blank"> <button data-toggle="tooltip" data-placement="up" title="Full Report" class="btn m-2"><i class="fa-solid fa-up-right-from-square"></i></button></a>
         <span *ngIf="!SnapEbsNotFound">
          
          <button *ngIf="!snapLoad" (click)="snapReload()" class="btn m-2"><i class="fa-solid fa-rotate"></i></button>
         
          <span class="m-2" *ngIf="snapLoad"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></span>

          </span>
          <!-- </div> -->
         
          </div>

          <div *ngIf="snapSizeLoader"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></div>
        </div>
        <div *ngIf="SnapEbsNotFound && !createJobScanner && checkJobStatusCounter==1 && !scanInProgress" class="alert-light text-center">
          <span>Data Not Found. Click&nbsp;<span class="pointer" (click)="createJob()">here</span>&nbsp;to
          scan</span>
        </div>
        <div *ngIf="createJobScanner && !scanInProgress" class="alert-light text-center">
          <img src="../assets/img/loading_2.svg" alt="loading scans"
          class="miniloader2"/> &nbsp;<span>Scanning..</span>
        </div>
        <div *ngIf="!createJobScanner && SnapEbsNotFound && checkJobStatusCounter>1 && !scanInProgress" class="alert-light text-center">
          No Data Found!
        </div>
        <div *ngIf="scanInProgress" class="alert-light text-center">
          Scan in Progress
        </div>
          <div *ngIf="!SnapEbsNotFound && !scanInProgress" id="snapGraph"></div>
      </div>
  </div>
  <div class="col p-3 col-xl-6">
      <div class="box">
        <div class="d-flex justify-content-between">
          <!-- <div class="d-flex"> -->
          <span class="table-title">Top 10 Volumes by Size</span>
          <div *ngIf="!volSizeLoader">
          <a href="/dash/operations/ebs" target="_blank"><button data-toggle="tooltip" data-placement="up" title="Full Report" class="btn m-2"><i class="fa-solid fa-up-right-from-square"></i></button></a>  
          <span  *ngIf="!volumeNotFound">
          <button *ngIf="!volLoad" (click)="volReload()" class="btn m-2"><i class="fa-solid fa-rotate"></i></button>
          <span class="m-2" *ngIf="volLoad"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></span>
          </span>
          <!-- </div> -->
          
          </div>

          <div *ngIf="volSizeLoader"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></div>
        </div>
        <!-- <div *ngIf="volumeNotFound" class="p-3 mt-2 pt-0 pb-0 bgc">EBS Volume List Not Found</div> -->
        <!-- <div *ngIf="volumeNotFound" class="alert-light text-center">
          Backup not Found!
        </div>
        <div *ngIf="scanInProgress" class="alert-light text-center">
          Scan in Progress
        </div> -->
        <div *ngIf="SnapEbsNotFound && !createJobScanner && checkJobStatusCounter==1 && !scanInProgress" class="alert-light text-center">
          <span>Data Not Found. Click&nbsp;<span class="pointer" (click)="createJob()">here</span>&nbsp;to
          scan</span>
        </div>
        <div *ngIf="createJobScanner && !scanInProgress" class="alert-light text-center">
          <img src="../assets/img/loading_2.svg" alt="loading scans"
          class="miniloader2"/> &nbsp;<span>Scanning..</span>
        </div>
        <div *ngIf="!createJobScanner && SnapEbsNotFound && checkJobStatusCounter>1 && !scanInProgress" class="alert-light text-center">
          No Data Found!
        </div>
        <div *ngIf="scanInProgress" class="alert-light text-center">
          Scan in Progress
        </div>
          <div *ngIf="!volumeNotFound && !scanInProgress" id="volumeGraph"></div>
      </div>
  </div>
   </div>




   <div class="row">
     <div class="col p-3">
       <div class="box">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
        <span class="table-title">Instances with last snapshot older than 2 days</span>
        
      </div>
      <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!snapNotFound && !snap_loader && !scanInProgress" (click)="viewSnap($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
        <div *ngIf="snap_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
          class="minloader"/></div>
        </div>
        <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead class="blue">
            <tr>
              <td scope="col">S.N.</td>
              <td scope="col">Instance Name</td>
              <td scope="col">Last Snapshot on</td> 
            </tr>
          </thead>
          <tbody *ngIf="!snapNotFound && !scanInProgress">
            
            <tr *ngFor="let i of getDisplaySnapshotData(); let j = index">
              <td scope="row" class="m-4">{{(j+ snap_lastIndex * snap_itemsPerPage)+1}}</td>
              <td>{{i.insName}}</td>
              <td>{{i.formattedDate}}</td>
            </tr>             
          </tbody> 
        </table>
      </div>
        <div *ngIf="snapNotFound" class="alert-light text-center">
         {{snapErrMsg}}
        </div>
        <div *ngIf="scanInProgress" class="alert-light text-center">
          Scan in Progress
        </div>
        <div *ngIf="!snapNotFound && !scanInProgress && !SnapEbsNotFound" class="btnHold">
          <div class="bhold">
            <button class="btn m-2" [disabled]="snap_currentPage === 1" (click)="snap_onPrev()">Previous</button>
            <button class="btn m-2" [disabled]="getDisplaySnapshotData().length < snap_itemsPerPage || instantWithLastBackup.length === 5 || instantWithLastBackup.length < 5 || (snap_currentPage * snap_itemsPerPage) >= instantWithLastBackup.length"  (click)="snap_onNext()">Next</button>
          </div>
        </div>
     </div>
     </div>
     <div class="col p-3 ">
       <div class="box">
         <!-- <span class="table-title">Average Retention Time</span> -->
         <div class="d-flex justify-content-between">
          <div class="d-flex">
          <span class="table-title">Instance wise backup retention</span>
          
      </div>
      
          <div *ngIf="avg_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></div>
         <div *ngIf="!avg_loader">
          <button *ngIf="!avg_loader" class="btn m-2" (click)="sortSnaps()"><i class="fa-sharp fa-solid sorticon" [ngClass]="sortIcon"></i></button>
          <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!avgNotFound && !avg_loader" (click)="viewRet($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>

        </div>
        </div>
        <div class="table-responsive">
         <table class="table table-hover mb-0">
           <thead class="blue pl-4">
             <tr>
               <td scope="col" class="m-4">S.N.</td>
               <td scope="col">Instance Name</td>
               <td scope="col">Instance wise backup since Days</td> 
             </tr>
           </thead>
           <tbody *ngIf="!avgNotFound">
             <tr *ngFor="let i of getDisplayedItems2(); let j = index">
               <td scope="row" class="m-4">{{(j+ lastIndex * itemsPerPage)+1}}</td>
               <!-- <td>{{i.duration.instance_name ?? i.duration.snapshot_name ?? i.name}}</td>
               <td>{{i.duration.retention_time}}</td> -->
               <td>{{i.insName}}</td>
               <td class="text-center">{{i.snap}}</td>
             </tr>             
           </tbody> 
         </table>
        </div>
         <div *ngIf="avgNotFound" class="alert-light text-center">
         {{retenErrMsg}}
        </div>
         <div *ngIf="!avgNotFound" class="btnHold">
          <div class="bhold">
            <button class="btn m-2" [disabled]="currentPage === 1" (click)="onPrev()">Previous</button>
            <button class="btn m-2" [disabled]="getDisplayedItems2().length < itemsPerPage || snapSinceDays.length < 5 || snapSinceDays.length === 5 || (currentPage * itemsPerPage) >=  snapSinceDays.length" (click)="onNext()">Next</button>
          </div>
        </div>
       </div>
     </div>
   </div>






   <div class="row">
    <div class="col p-3 ">
      <div class="box">
        <div class="d-flex justify-content-between">
        <div class="d-flex">
        <span class="table-title">Instances without Backup</span>
        
      </div>
      <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!backupNotFound && !backup_loader" (click)="viewBackup($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
        <div *ngIf="backup_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
          class="minloader"/></div>
        </div>
        <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead class="blue">
            <tr>
              <td scope="col">S.N.</td>
              <td scope="col">Instance Name</td>
              <td scope="col">Instance ID</td> 
            </tr>
          </thead>
          <tbody *ngIf="!backupNotFound">
            <tr *ngFor="let i of getDisplayBackupData(); let j = index">
              <td scope="row" class="m-4">{{(j+ backup_lastIndex * backup_itemsPerPage)+1}}</td>
              <td>{{i.backup.instance_name}}</td>
              <td>{{i.backup.instance_id}}</td>
            </tr>             
          </tbody> 
        </table>
      </div>
        <div *ngIf="backupNotFound" class="alert-light text-center">
          {{backupErrMsg}}
        </div>
        <div  *ngIf="!backupNotFound" class="btnHold">
          <div class="bhold">
            <button class="btn m-2" [disabled]="backup_currentPage === 1" (click)="backup_onPrev()">Previous</button>
            <button class="btn m-2" [disabled]="getDisplayBackupData().length < backup_itemsPerPage || backupList.length < 5 ||backupList.length === 5 || (backup_currentPage * backup_itemsPerPage) >=  backupList.length" (click)="backup_onNext()">Next</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col p-3 ">
      <div class="box">
        <div class="d-flex justify-content-between">
        <div class="d-flex">
        <span class="table-title">Reserved Instances Status</span>
        
      </div>
      <!-- <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!riNotFound && !ri_loader" (click)="viewBackup($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button> -->
      <a *ngIf="!ri_loader" href="/dash/costcontrols/ec2-ri" target="_blank"> <button data-toggle="tooltip" data-placement="up" title="Full Report" class="btn m-2"><i class="fa-solid fa-up-right-from-square"></i></button></a>
        <div *ngIf="ri_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
          class="minloader"/></div>
        </div>
        <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead class="blue">
            <tr>
              <td scope="col">S.N.</td>
              <td scope="col">Instance Name</td>
              <td scope="col">Potential Savings</td> 
            </tr>
          </thead>
          <tbody *ngIf="!riNotFound">
            <tr *ngFor="let i of getDisplayRIData(); let j = index">
              <td scope="row" class="m-4">{{(j+ ri_lastIndex * ri_itemsPerPage)+1}}</td>
              <td>{{ i['instanceName'] }}</td>
              <td><span class="text-success">
                ${{fetchLow(i)}} to ${{fetchHigh(i)}}
              </span></td>
            </tr>             
          </tbody> 
        </table>
      </div>
        <div *ngIf="riNotFound && !riFound && !scanLoader" class="alert-light text-center">
          <span>No Scans Found. Click&nbsp;<span class="pointer" (click)="createScan()">here</span>&nbsp;to
          scan</span>
        </div>
        <div *ngIf="riNotFound && !riFound && scanLoader" class="alert-light text-center">
          <img src="../assets/img/loading_2.svg" alt="loading scans"
            class="miniloader2"/> &nbsp;<span>Scanning..</span>
        </div>

        <div *ngIf="riNotFound && riFound" class="alert-light text-center">
          {{riErrMsg}}
        </div>
        <div *ngIf="!riNotFound"  class="btnHold">
          <div class="bhold">
            <button class="btn m-2" [disabled]="ri_currentPage === 1" (click)="ri_onPrev()">Previous</button>
            <button class="btn m-2" [disabled]="getDisplayRIData().length < ri_itemsPerPage || riData.length < 5 ||riData.length === 5 || (ri_currentPage * ri_itemsPerPage) >=  riData.length" (click)="ri_onNext()">Next</button>
          </div>
        </div>
      </div>
    </div>
   </div>

  




    <div class="row mb-3">
     <div class="col-6"><span class="underline">Security Groups</span></div>
     
    </div>
    <div class="row">
      <!-- <div class="col p-3">
        <div class="box">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <span class="table-title">Security Group Information</span>
            
          </div>
          <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!sgNotFound && !sg_loader" (click)="viewSG($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>

        <div *ngIf="sg_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
          class="minloader"/></div>
        </div>
        <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead class="blue">
            <tr>
              <td scope="col">S.N.</td>
              <td scope="col">Security Group Name</td> 
              <td scope="col">Security Group ID</td>    
            </tr>
          </thead>
          <tbody *ngIf="!sgNotFound">
            <tr *ngFor="let i of getDisplaySGData(); let j = index">
              <td scope="row" class="m-4">{{(j+ sg_lastIndex * sg_itemsPerPage)+1}}</td>
              <td>{{i.sgName.sg_name}}</td>
              <td>{{i.sgID}}</td> 
            </tr>             
          </tbody> 
        </table>
      </div>
        <div *ngIf="sgNotFound" class="alert-light text-center">
         {{sgErrMsg}}
        </div>
        <div *ngIf="!sgNotFound" class="btnHold">
          <div class="bhold">
            <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
            <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage || SGList.length < 5 || SGList.length === 5 || (sg_currentPage * sg_itemsPerPage) >=  SGList.length"  (click)="sg_onNext()">Next</button>
          </div>
        </div>
      </div>
      </div> -->
      
      <div class="col p-3 ">
        <div class="box">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
          <span class="table-title">Security Groups attached to multiple instances</span>
        
        </div>
        <!-- <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!insNotFound && !ins_loader" (click)="viewIns($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button> -->
          <div *ngIf="ins_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></div>
          </div>
          <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead class="blue">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">Security Group Name</td>
                <td scope="col" class="text-center">Number of Instances</td> 
              </tr>
            </thead>
            <tbody *ngIf="!insNotFound">
              <tr *ngFor="let i of getDisplayInstanceData(); let j = index">
                <td scope="row" class="m-4">{{(j+ ins_lastIndex * ins_itemsPerPage)+1}}</td>
                <td> {{i.sg.sgName}} </td>
                <td class="text-center vpcLink" (click)="sendInsData(i.instalist)"> {{i.sg.insCount}}</td>
              </tr>             
            </tbody> 
          </table>
        </div>
          <div *ngIf="insNotFound" class="alert-light text-center">
           {{insErrMsg}}
          </div>
          <div *ngIf="!insNotFound" class="btnHold">
            <div class="bhold">
              <button class="btn m-2" [disabled]="ins_currentPage === 1" (click)="ins_onPrev()">Previous</button>
              <button class="btn m-2" [disabled]="getDisplayInstanceData().length < ins_itemsPerPage || sgwithInsCount.length < 5 || sgwithInsCount.length === 5 || (ins_currentPage * ins_itemsPerPage) >=  sgwithInsCount.length"  (click)="ins_onNext()">Next</button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col p-3 ">
        <div class="box">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
          <span class="table-title">Non Standard Inbound Ports Exposed over the Public Internet</span>
          
        </div>
        <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!openNotFound && !open_loader" (click)="viewPort($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>

          <div *ngIf="open_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></div>
          </div>
          <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead class="blue">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">Security Group Name</td>
                <td scope="col">Port</td> 
              </tr>
            </thead>
            <tbody *ngIf="!openNotFound">
              <tr *ngFor="let i of getDisplayPortsData(); let j = index">
                <td scope="row" class="m-4">{{(j+ open_lastIndex * open_itemsPerPage)+1}}</td>
                <td class="fixed-width-cell">{{i.p.port.sg_name}}</td>
                <td class=" fixed-width-cell">{{i.filteredPorts}}</td>
              </tr>             
            </tbody> 
          </table>
        </div>
          <div *ngIf="openNotFound" class="alert-light text-center">
            {{portErrMsg}}
          </div>
          <div *ngIf="!openNotFound" class="btnHold">
            <div class="bhold">
              <button class="btn m-2" [disabled]="open_currentPage === 1" (click)="open_onPrev()">Previous</button>
              <button class="btn m-2" [disabled]="getDisplayPortsData().length < open_itemsPerPage || openPorts.length < 5 || openPorts.length === 5 || (open_currentPage * open_itemsPerPage) >=  filteredPortData.length"  (click)="open_onNext()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col p-3 ">
        <div class="box">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
          <span class="table-title">Security Groups attached to multiple instances</span>
        
        </div>
        <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!insNotFound && !ins_loader" (click)="viewIns($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
          <div *ngIf="ins_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></div>
          </div>
          <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead class="blue">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">Security Group Name</td>
                <td scope="col" class="text-center">Number of Instances</td> 
              </tr>
            </thead>
            <tbody *ngIf="!insNotFound">
              <tr *ngFor="let i of getDisplayInstanceData(); let j = index">
                <td scope="row" class="m-4">{{(j+ ins_lastIndex * ins_itemsPerPage)+1}}</td>
                <td> {{i.sgName}} </td>
                <td class="text-center"> {{i.insCount}}</td>
              </tr>             
            </tbody> 
          </table>
        </div>
          <div *ngIf="insNotFound" class="alert-light text-center">
           {{insErrMsg}}
          </div>
          <div *ngIf="!insNotFound" class="btnHold">
            <div class="bhold">
              <button class="btn m-2" [disabled]="ins_currentPage === 1" (click)="ins_onPrev()">Previous</button>
              <button class="btn m-2" [disabled]="getDisplayInstanceData().length < ins_itemsPerPage || sgwithInsCount.length < 5 || sgwithInsCount.length === 5 || (ins_currentPage * ins_itemsPerPage) >=  sgwithInsCount.length"  (click)="ins_onNext()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
 
<!-- //backup -->

     <div class="row mb-3">
      <div class="col-6"><span class="underline">VPC</span></div> 
     </div>
     <div class="row">
      <div class="col p-3">
        <div class="box">
        <div class="d-flex justify-content-between">
      <div class="d-flex">
        <span class="table-title">VPCs with Public Subnet</span>
        
      </div>
      <!-- <button class="btn m-2" data-toggle="tooltip" data-placement="up" title="Full Report" *ngIf="!vpcNotFound" (click)="viewVpc($event)"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button> -->
        <div *ngIf="vpc_loader"><img src="../assets/img/loading_2.svg" alt="loading scans"
          class="minloader"/></div>
        </div>
        <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead class="blue">
            <tr>
              <td scope="col">S.N.</td>
              <td scope="col">VPC Name</td>
              <td scope="col">VPC ID</td> 
              <td scope="col">CIDR</td> 
              <td class="text-center text-nowrap" scope="col">Available IPs</td>
              <td class="text-center text-nowrap" scope="col">Total Subnets</td>
              <td class="text-center text-nowrap" scope="col">Public Subnets</td>
              <!-- <td class="text-center text-nowrap" scope="col">Private Subnets</td> -->
              
             
            </tr>
          </thead>
          <tbody *ngIf="!vpcNotFound">
            <tr *ngFor="let i of getDisplayVPCData(); let j = index">
              <td scope="row" class="m-4">{{(j+ vpc_lastIndex * vpc_itemsPerPage)+1}}</td>
              <td>{{i.element.cidr.vpc_name}}</td>
              <td>{{i.element.vpc}}</td>
              <td>{{i.element.cidr.cidr_block}}</td>
              <td class="text-center">{{i.total}}</td>
              <td class="text-center">{{i.element.cidr.no_of_subnets}}</td>
              <td class="text-center" (click)="sendVPCData(i.element.cidr.public_subnets)" [ngClass]="{'vpcLink': i.element.cidr.public_subnets.length != 0}">{{i.element.cidr.public_subnets.length}}</td>
              <!-- <td class="text-center">{{i.element.cidr.private_subnets.length}}</td> -->
             
              
            </tr>             
          </tbody> 
        </table>
      </div>
        <div *ngIf="vpcNotFound" class="alert-light text-center">
          {{vpcErrMsg}}
        </div>
        <div *ngIf="!vpcNotFound" class="btnHold">
          <div class="bhold">
            <button class="btn m-2" [disabled]="vpc_currentPage === 1" (click)="vpc_onPrev()">Previous</button>
            <button class="btn m-2" [disabled]="getDisplayVPCData().length < vpc_itemsPerPage || vpcList.length < 5 || vpcList.length === 5 || (vpc_currentPage * vpc_itemsPerPage) >=  vpcList.length" (click)="vpc_onNext()">Next</button>
          </div>
        </div>
      </div>
      </div>
     </div>
  </div>
  <app-view-sg *ngIf="SGViewTrigger" [SGList]="SGList" (hideModal)="hideViewSG($event)"></app-view-sg>
  <app-view-ports *ngIf="PortViewTrigger"  [PortList]="filteredPortData" (hideModal)="hideViewPort($event)"></app-view-ports>
  <app-view-snap *ngIf="SnapViewTrigger" [ebsList]="instantWithLastBackup" (hideModal)="hideSnap($event)"></app-view-snap>
  <app-view-instances *ngIf="InstViewTrigger" [instanceAttachtedToSG]="instanceAttachtedToSG" (hideModal)="hideIns($event)"></app-view-instances>
  <app-view-retention *ngIf="retenViewTrigger" [averageRetention]="averageRetention" (hideModal)="hideRet($event)"></app-view-retention>
  <app-view-backup  *ngIf="backupViewTrigger" [backupList]="backupList" (hideModal)="hideBackup($event)"></app-view-backup>
  <app-view-vpc *ngIf="vpcViewTrigger" [vpcList]="vpcList" (hideModal)="hideVpc($event)"></app-view-vpc>