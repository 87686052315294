<div class="info-space">
  <div class="info-container">
    <div class="strong-text large-text how-to-relative">How to Update {{ whitelabelData['pd']['appName'] }} CFT in your account?
      <div class="absoute-close" (click)="hideModel.emit(false)">
        <i class="fa fa-times" aria-hidden="true"></i>

      </div>
    </div>
    <div class="main-container">
      <div class="steps-container" [ngStyle]="{'margin-left': getMarginLeft() }">
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/configure-cft/Step+1.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Login into Your AWS Account</div>
            <div>
              Login into your AWS Console or configure your AWS Cli into your environment.
            </div>
          </div>
        </div>
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/configure-cft/Step+2.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Get the Command</div>
            <div>
              Copy and Paste the Command given by {{ whitelabelData['pd']['appName'] }} and press Enter
            </div>
          </div>
        </div>
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/configure-cft/Step+3.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Update Successful</div>
            <div>
              On Successful completion of update, above is the expected output
            </div>
          </div>
        </div>
      </div>
      <div class="steps-indicator" *ngIf="steps > 1">
        <div class="step" *ngFor="let _ of getArray(); let i = index" [ngClass]="{active_step: step == i}"
          (click)="setStep(i)"></div>
      </div>
      <div class="actions">
        <div>
          <button class="btn btn-light" (click)="removeStep()" *ngIf="step > 0">
            Prev
          </button>
        </div>
        <div>
          <button class="btn btn-light" (click)="appendStep()" *ngIf="step < steps - 1">
            Next
          </button>
          <button class="btn btn-light" (click)="hideModel.emit(false)" *ngIf="step >= steps - 1">
            Finish
          </button>
        </div>
      </div>
    </div>
  </div>
</div>