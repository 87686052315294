import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-none-of-these-confirm',
  templateUrl: './none-of-these-confirm.component.html',
  styleUrls: [
    './none-of-these-confirm.component.css',
    './../../../../warquiz.component.css',
  ],
})
export class NoneOfTheseConfirmComponent implements OnInit {
  @Output('popupAction') popupAction: any = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}

  action(action: boolean) {
    this.popupAction.emit(action);
  }
}
