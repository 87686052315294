<div class="start-stop-container">
  <div class="start-stop-main">
    <div class="start-stop-header">
      <div><span class="instancestitle">{{modalTitle}}</span> Instances</div>
      <div><i class="fa fa-times closecursor"  (click)="close()"></i></div>
    </div>
    <div class="start-stop-body">
      <div class="instances_table" *ngIf="!validateOTP">
        <h4>Instances</h4>
        <table class="table">
          <thead>
            <th>Tag Name</th>
            <th>Instance Name</th>
          </thead>
          <tbody>
            <tr *ngFor="let instance of instancesList" [ngClass]="{'tr-disabled': instance.State.includes(command)}">
              <td>
                <div class="start-stop-text">{{instance.ResourceName || '-'}}</div>
              </td>
              <td>
                <div class="start-stop-text start-stop-flex">{{instance.ResourceId || '-'}}
                  <span class="light-alert"
                    *ngIf="instance.State.includes(command) && instance.State == 'running'">Instance
                    Already Running</span>
                  <span class="light-alert"
                    *ngIf="instance.State.includes(command) && instance.State == 'stopped'">Instance
                    Already Stopped</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mb-2">
          <label>Email</label>
          <input type="email" [(ngModel)]="otpEmailID" class="form-control">
        </div>
      </div>
      <div class="validate_otp" *ngIf="validateOTP">
        <h4>OTP Validation</h4>
        <div class="mb-2">
          <label>OTP</label>
          <input type="text" class="form-control" [(ngModel)]="otpText">
        </div>
      </div>
    </div>
    <div class="start-stop-actions">
      <button *ngIf="!validateOTP" class="btn commonbtnstyle" 
        [ngClass]="{'click-disabled': !writeAccess}" [attr.disabled]="!writeAccess ? true : null"
        (click)="sendOTP()">Send
        OTP</button>
      <button *ngIf="validateOTP" class="btn cancelbtnstyle"
        (click)="instanceTriggerAction()" [ngClass]="{'click-disabled': !writeAccess}"
        [attr.disabled]="!writeAccess ? true : null">{{modalTitle}}</button>
      <button class="btn btn-light" (click)="close()">Cancel</button>
    </div>
  </div>
</div>