import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-med-alert',
  templateUrl: './med-alert.component.html',
  styleUrls: ['./med-alert.component.css']
})
export class MedAlertComponent {
  @Output() hideModal = new EventEmitter<any>();
  @Input("seMed") seMed: any;
  @Input("govMed") govMed: any;
  @Input("dsMed") dsMed: any;
  @Input("dcMed") dcMed: any;



  no(){
    this.hideModal.emit({ status: 0 });
    
  }
}
