import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-forcast-tab',
  templateUrl: './view-forcast-tab.component.html',
  styleUrls: ['./view-forcast-tab.component.css']
})
export class ViewForcastTabComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() forcastList :any=[];
  constructor(public notifier: NotifierService,) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }

  no(){
    this.hideModal.emit({ status: 0 }); 
  }

  ngOnDestroy(): void {
    this.notifier.enableScroll();
}

}
