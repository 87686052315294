import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../api/api.service";
import { NotifierService } from "../../_services/notifier.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
// import { CanDeactivateComponent } from 'src/app/_helpers/auth.guard';

declare let window: any;

@Component({
  templateUrl: "./warquiz.component.html",
  styleUrls: ["./warquiz.component.css"],
})
export class WarQuizComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading: any = {
    workloads: true,
    lenses: false,
    questions: false,
    pillars: false,
  };
  milestone: any = null;
  questions: any = [];
  milestones: any = [];
  filterType: string = "ALL";
  selectedPiller: string = null;
  selectedLenses: string = null;
  currentQuestionIndexs: any = {};
  selectedWorkload: any = null;
  selectedWorkloadId: string = null;
  destroyed: boolean = false;
  profile_region: any = [];

  workloads: any = [];

  comments: any = [];

  pillersData: any = {
    costOptimization: "Cost Optimization",
    operationalExcellence: "Operational Excellence",
    performance: "Performance",
    reliability: "Reliability",
    security: "Security",
    sustainability: "Sustainability",
  };

  lensesData: any = {
    softwareasaservice: "SaaS Lens",
    wellarchitected: "AWS Well-Architected Framework",
    serverless: "Serverless Lens",
    foundationaltechnicalreview: "FTR Lens",
    "arn:aws:wellarchitected::aws:lens/sap": "SAP Lens",
  };

  filter: any = {
    questions: "All",
    risk: "All",
  };

  pillers: any = [];
  lenses: any = [];
  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  index: number = 1;
  createWorkload: boolean = false;
  createProfile: boolean = false;
  activeRoute: any;
  selectedWorkloadName: string = null;
  deleteProfile: any = null;
  itemsWithLens: Array<any> = [];
  wokload_region: any = [];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.activeRoute = this.router.queryParams.subscribe((values: any) => {
      if (values["workload_name"]) {
        this.selectedWorkloadName = values["workload_name"];
      } else {
        this.selectedWorkloadName = null;
      }
    });
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (this.showQuestions == true) {
        if (
          (d.key == "accountId" && d.value != this.accountId) ||
          (d.key == "regionId" && d.value != this.regionId)
        ) {
          this.questionsCode = window.uuidv4();
        } else {
          return;
        }
      }

      if (d.key == "accountId") {
        this.accountId = d.value;
        this.refresh(true);
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.refresh(true);
      }
    });
    // console.log("region id", this.regionId);
  }

  deleteProfileHide(event: any) {
    if (event) {
      this.refresh();
    }

    this.deleteProfile = null;
  }

  deleteLensHide(event: any) {
    if (event) {
      this.refresh();
    }

    this.deleteLens = null;
  }

  createLens: any | null = null;
  createLensHide(event: any) {
    if (event) {
      this.refresh();
    }

    this.createLens = null;
  }

  refresh(event: boolean = true, open: boolean = true) {
    let promises: Promise<any>[] = [];
    if (event && this.selectedWorkload && open) {
      this.selectedWorkloadName = this.selectedWorkload["name"];
    }
    promises.push(this.loadProfiles());
    promises.push(this.load(event));
    promises.push(this.loadCustomLens());
    Promise.all(promises).then((values: any) => {
      this.workloads.forEach((workload: any) => {
        this.profiles.forEach((profile: any) => {
          if (profile.workloads.includes(workload["aws_id"])) {
            workload["profile"] = {
              profileName: profile["profileName"],
              profileDescription: profile["profileDescription"],
              profileARN: profile["profileARN"],
            };
          }
        });
        this.customLens.forEach((lens: any) => {
          if (workload.lenses.includes(lens["arn"])) {
            lens.workloads.push(workload.name);
          }
        });
        // this.itemsWithLens = this.customLens.map((item:any) =>{
        //   console.log("customlenses in map",this.customLens);
        //   console.log("workloads in map",this.workloads);
        //   const len = this.workloads.find((l:any) => l.lenses === item.arn).length;
        //   return len > 0 ? { ...item, lensValue: len}:item;
        // });
        this.itemsWithLens = this.customLens.map((item: any) => {
          // console.log("customlenses in map",this.customLens);
          // console.log("workloads in map",this.workloads);
          const matchingWorkloads = this.workloads.find(
            (l: any) => l.lenses === item.arn
          );
          const len = matchingWorkloads ? matchingWorkloads.length : 0;
          return len > 0 ? { ...item, lensValue: len } : item;
        });
        // console.log("customlenses",this.customLens);
        // console.log("workloads",this.workloads);
        // console.log("new array",this.itemsWithLens);
      });
      this.loading["lenses"] = false;
    });
  }

  // handleMouseOver(event: MouseEvent){
  //   if(this.loading["lenses"]){
  //     this.notifier.alert(
  //       "Info",
  //       "",
  //       `Wait for 2 min`,
  //       "info",
  //       5000
  //     );
  //   }
  // }

  addComment(event: any, option: any) {
    event.preventDefault();
    event.stopPropagation();
    option["comments"] = option["dummy_comment"];
    option["dummy_comment"] = undefined;
  }

  user_favicon(
    username: string,
    user_name_parsed_requrired: boolean = false
  ): any {
    var user_favicon_text = "";
    var user_names;
    var username_name_parsed = "";
    if (username == "") {
      return "Anonymous";
    }
    username = username.trim();
    if (!username.includes("@")) {
      user_names = username.split(" ");
    } else {
      user_names = username.split("@")[0].split(".");
    }
    if (user_names.length > 0) {
      user_favicon_text += user_names[0][0].trim();
      username_name_parsed += user_names[0].trim();
      if (user_names.length > 1) {
        if (user_names[user_names.length - 1].length > 0) {
          user_favicon_text += user_names[user_names.length - 1][0].trim();
          username_name_parsed +=
            " " + user_names[user_names.length - 1].trim();
        }
      } else {
        user_favicon_text += " ";
      }
    }
    if (user_name_parsed_requrired) {
      return {
        user_full_name: username_name_parsed.trim(),
        user_favicon_parsed: user_favicon_text.trim(),
      };
    }
    return user_favicon_text.toUpperCase();
  }

  async selectWorkload(workload: any) {
    if (this.loading["profiles"]) {
      return;
    }
    this.selectedWorkload = workload;
    this.selectedLenses = null;
    this.selectedPiller = null;
    this.pillers = [];
    this.lenses = [];
    this.questions = [];
    this.loading["pillars"] = true;
    this.loading["lenses"] = true;
    this.loading["questions"] = true;
    this.loadMilestones();
    if (this.selectedWorkload["lenses"]) {
      this.lenses = this.selectedWorkload["lenses"];
      // console.log("lenses in warquiz",this.lenses);
    } else {
      await this.loadLenses();
    }
    await this.loadPillers();
    this.loadQuestions();
  }

  showCreatePorfile() {
    this.createProfile = true;
    this.profileAction = "createProfile";
  }

  updateProfileObj: any = null;
  updateProfile(profile: any) {
    this.profileAction = "updateProfile";
    this.updateProfileObj = profile;
    this.createProfile = true;
  }

  hideCreateWorkload(event: any) {
    if (event) {
      this.load(true);
    }
    this.createWorkload = false;
  }

  hideCreateProfile(event: any) {
    if (event) {
      this.loadProfiles();
    }
    this.createProfile = false;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

  threadId: string = null;
  async load(refresh: boolean = false) {
    return new Promise(async (resolve: any, reject: any) => {
      this.threadId = window.uuidv4();
      if (refresh) {
        this.loading["workloads"] = true;
        this.workloads = [];
        this.selectedWorkload = null;
        this.selectedLenses = null;
        this.selectedPiller = null;
        this.showQuestions = false;
        this.pillers = [];
        this.lenses = [];
        this.questions = [];
      }

      let data: any = {
        action: "list_workloads",
        account_id: this.accountId,
        region_id: this.regionId,
        id_token: localStorage.getItem("idT"),
      };

      let headers: any = {
        Authorization: localStorage.getItem("t"),
      };

      let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

      let result = await this.apiServer.postDataPromis(apiURL, data, headers);

      if (result.status == "1" || result.s == "1") {
        result.workloads = result.workloads.map((workload: any) => {
          if (this.selectedWorkloadName == workload["name"]) {
            this.selectWorkload(workload);
            this.selectedWorkloadName = null;
          }
          workload.creation_time = moment(workload.creation_time).format(
            "MMM DD, YYYY hh:mm A"
          );
          if (
            workload["ScanStatus"] == undefined ||
            !(
              workload["ScanStatus"] == "COMPLETED" ||
              workload["ScanStatus"] == "Scan Failed"
            )
          ) {
            workload.callsTimerInterval = 10;
            workload.callsCount = 0;
            this.loading[`workload_${workload["id"]}`] = true;
          }
          return workload;
        });
        result.workloads = result.workloads.sort((a: any, b: any) => {
          let dateTemp_a: any = new Date(a["creation_time"]);
          let dateTemp_b: any = new Date(b["creation_time"]);
          return Date.parse(dateTemp_b) - Date.parse(dateTemp_a);
        });
        this.workloads = result.workloads;

        let temp = result.workloads.filter((workload: any) => {
          return (
            workload["ScanStatus"] == undefined ||
            !(
              workload["ScanStatus"] == "COMPLETED" ||
              workload["ScanStatus"] == "Scan Failed"
            )
          );
        });
        if (!this.selectedWorkload) {
          temp.forEach((workload: any) => {
            setTimeout(() => {
              this.loadWorkload(workload, this.threadId);
            }, workload.callsTimerInterval);
          });
        }
      }

      resolve(true);
      this.loading["workloads"] = false;
    });
  }

  profiles: any = [];
  profileAction: string = null;
  async loadProfiles() {
    return new Promise(async (resolve: any, reject: any) => {
      this.loading["profiles"] = true;
      this.profiles = [];
      let data: any = {
        action: "listProfiles",
        accountId: this.accountId,
        id_token: localStorage.getItem("idT"),
      };

      let headers: any = {
        Authorization: localStorage.getItem("t"),
      };

      let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

      let result = await this.apiServer.postDataPromis(apiURL, data, headers);

      if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
        result.profileSummaries
          .sort((a: any, b: any) => {
            return b["createdAt"] - a["createdAt"];
          })
          .map((profile: any) => {
            profile["createdAt"] = moment(profile["createdAt"]).format(
              "MMM DD, YYYY hh:mm A"
            );
            profile["updatedAt"] = moment(profile["updatedAt"]).format(
              "MMM DD, YYYY hh:mm A"
            );
            return profile;
          });
        let promises: Promise<any>[] = [];
        result.profileSummaries.forEach((profile: any) => {
          promises.push(this.fetchProfileDetails(profile));
        });
        Promise.all(promises).then((values: any) => {
          this.profiles = values;
          if (this.regionId) {
            this.profile_region = this.profiles.filter(
              (obj: any) => obj.region_id === this.regionId
            );
            // console.log("filtered workload", this.profile_region);
          } else {
            this.wokload_region = [];
            // console.log("filtered workload", this.profile_region);
          }
          this.loading["profiles"] = false;
          resolve(true);
        });
      } else {
        this.loading["profiles"] = false;
      }
    });
  }

  // getlenscount(lenssName: string):number{
  //   let count = 0;
  //   console.log("workloads", this.workloads);
  //   this.workloads.forEach((item:any) =>{
  //     console.log("inside workload loop");
  //     if(item.lenses.includes(lenssName)){
  //       console.log("incrementing the count", count);
  //       count++;
  //     }
  //   });
  //   return count;
  // }

  async fetchProfileDetails(profile: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let data: any = {
        action: "getProfileDetails",
        accountId: this.accountId,
        profileArn: profile.profileArn,
        id_token: localStorage.getItem("idT"),
      };

      let headers: any = {
        Authorization: localStorage.getItem("t"),
      };

      let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

      let result = await this.apiServer.postDataPromis(apiURL, data, headers);

      if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
        resolve({ ...profile, ...result });
      }
      this.loading["profiles"] = false;
    });
  }

  async loadWorkload(workload: any, thread_id: string) {
    if (workload["callsCount"] > 0) {
      workload["callsTimerInterval"] += 1;
    }
    workload["callsCount"] += 1;
    if (this.selectedWorkload) {
      workload["callsCount"] = 0;
      workload["callsTimerInterval"] = 10;
      return;
    }

    let data: any = {
      action: "describe_workload",
      account_id: this.accountId,
      region_id: this.regionId,
      id_token: localStorage.getItem("idT"),
      workload_id: workload["id"],
    };

    this.loading[`workload_${workload["id"]}`] = true;

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    let checkWorkload: any = this.workloads.find((task: any) => {
      return task["id"] == workload["id"];
    });

    if (
      this.destroyed ||
      this.selectedWorkload ||
      !checkWorkload ||
      thread_id != this.threadId
    ) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      if (result["workloads"] && result["workloads"].length > 0) {
        workload["ScanStatus"] = result["workloads"][0]["ScanStatus"];
        if (
          workload["ScanStatus"] == undefined ||
          !(
            workload["ScanStatus"] == "COMPLETED" ||
            workload["ScanStatus"] == "Scan Failed"
          )
        ) {
          if (workload["callsCount"] > 10) {
            this.notifier.alert(
              "Info",
              "",
              "WAFR Workload Creation is taking longer than usual. Please click on the Refresh button after a couple of minutes.",
              "info",
              -1
            );
            workload["callsCount"] = 0;
            workload["callsTimerInterval"] = 10;
            delete this.loading[`workload_${workload["id"]}`];
          } else {
            setTimeout(() => {
              this.loadWorkload(workload, thread_id);
            }, workload["callsTimerInterval"] * 1000);
          }
        } else {
          delete this.loading[`workload_${workload["id"]}`];
        }
      } else {
        this.notifier.alert(
          "Info",
          "",
          `Failed to load Workload: ${workload["workload_name"]}`,
          "info",
          5000
        );
        delete this.loading[`workload_${workload["id"]}`];
      }
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.message || result.error_message,
        "info",
        5000
      );
      delete this.loading[`workload_${workload["id"]}`];
    }
  }

  async loadPillers() {
    this.loading["pillars"] = true;
    let data: any = {
      action: "list_workload_pillars",
      workload_id: this.selectedWorkload["id"],
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1") {
      this.pillers = result.pillars;

      this.lenses.forEach((lens: string) => {
        if (!this.currentQuestionIndexs[lens]) {
          this.currentQuestionIndexs[lens] = {};
        }
        this.pillers.forEach((piller: string) => {
          this.currentQuestionIndexs[lens][piller] = 0;
        });
      });
    }
    this.loading["pillars"] = false;
  }

  async loadLenses() {
    this.loading["lenses"] = true;
    let data: any = {
      action: "list_workload_lenses",
      workload_id: this.selectedWorkload["id"],
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1") {
      this.lenses = result.lens;
    }

    this.loading["lenses"] = false;
  }

  customLens: any = [];
  deleteLens: any | null = null;
  async loadCustomLens() {
    this.loading["lenses"] = true;
    this.customLens = [];
    let data: any = {
      action: "listCustomLens",
      accountId: this.accountId,
      regionId: this.regionId,
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == "1") {
      this.customLens = [
        { arn: result.profileARN, name: result.lensName, workloads: [] },
      ];
      // console.log("full cusstome lens", this.customLens);
      // console.log("associated workload",this.customLens["workload"]);
      // console.log("associated workload lrnght",this.customLens["workload"].lenght);
    }

    // this.loading["lenses"] = false;
  }

  showCreateCustomLens() {
    this.createLens = true;
  }

  async loadQuestions() {
    this.loading["questions"] = true;
    let data: any = {
      action: "get_workload_questions",
      workload_id: this.selectedWorkload["id"],
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1") {
      let pillers: string[] = [];
      result.questions = result.questions
        .map((question: any) => {
          question["QuestionTypeIndex"] =
            question["QuestionType"] == "PRIORITIZED" ? 1 : 0;
          if (!question["BestPractices"]) {
            question["BestPractices"] = [];
          }
          if (question["Qno"]) {
            let que_no = question["Qno"].split(" ")[1];
            let que_code = question["Qno"].split(" ")[0];
            if (pillers.indexOf(que_code) > -1) {
              que_no = `${pillers.indexOf(que_code)}` + que_no;
            } else {
              pillers.push(que_code);
              que_no = `${pillers.indexOf(que_code)}` + que_no;
            }
            question["Qno_dummy"] = Number(que_no);
          } else {
            question["Qno_dummy"] = 0;
          }
          if (!question["SwayamChoices"]) {
            question["SwayamChoices"] = [];
          }

          // consider previous values also
          if (question["ChoiceAnswerSummaries"].length == 0) {
            question["ChoiceAnswerSummaries"] = question["Choices"].map(
              (choice: any) => {
                return {
                  ChoiceId: choice["ChoiceId"],
                  Status:
                    question["SelectedChoices"].indexOf(choice["ChoiceId"]) > -1
                      ? "SELECTED"
                      : "UNSELECTED",
                  Reason: "",
                  Notes: "",
                  edit: true,
                };
              }
            );
          } else {
            question["ChoiceAnswerSummaries"] = question[
              "ChoiceAnswerSummaries"
            ].map((choice: any) => {
              if (choice["Notes"] == undefined) {
                choice["Notes"] = "";
              }
              if (choice["Notes"].trim() == "") {
                choice["edit"] = true;
              }

              return choice;
            });
          }

          let check = question["SwayamChoices"].find((choice: any) => {
            return choice["Title"] == "None of these";
          });
          if (!check) {
            question["SwayamChoices"].push({
              choiceId: question["Choices"].find((choice: any) => {
                return choice["Title"] == "None of these";
              })["ChoiceId"],
            });
          }

          if (
            question["SwayamChoices"] &&
            question["SwayamChoices"].length > 0
          ) {
            let options: string[] = [];
            question["SwayamChoices"] = question["SwayamChoices"].filter(
              (choice: any) => {
                if (options.indexOf(choice["choiceId"]) > -1) {
                  return false;
                } else {
                  options.push(choice["choiceId"]);
                  return true;
                }
              }
            );
          }

          question["SwayamChoices"] = question["SwayamChoices"].map(
            (choice: any) => {
              if (choice["comments"] == undefined) {
                choice["comments"] = "";
              }
              if (choice["comments"].trim() == "") {
                choice["edit"] = true;
              } else {
                choice["edit"] = false;
              }
              if (choice["evidence"]) {
                choice["evidence"] = choice["evidence"].sort(
                  (a: any, b: any) => {
                    return a["status"] ? -1 : 1;
                  }
                );
              }
              return choice;
            }
          );
          return question;
        })
        .sort((a: any, b: any) => {
          return a["Qno_dummy"] > b["Qno_dummy"] ? 1 : -1;
        });
      this.questions = result.questions;
    }

    this.loading["questions"] = false;
    return true;
  }

  selectLens(lens: string) {
    this.selectedLenses = lens;
  }

  showQuestions: boolean = false;
  questionsCode: string = null;
  currentQuestion: any;
  selectPiller(piller?: string, lens?: string) {
    if (piller) {
      this.selectedPiller = piller;
    }
    if (lens) {
      this.selectedLenses = lens;
    }
    this.showQuestions = true;
    this.questionsCode = window.uuidv4();
  }

  changeQuestion(question: any) {
    this.currentQuestion = question;
  }

  hideQuestions(event: any) {
    this.showQuestions = false;
    this.questionsCode = null;
    this.selectedPiller = null;
    this.currentQuestion = null;
    if (!this.milestone) {
      this.loadQuestions();
    }
    this.filter["questions"] = "All";
  }

  callFunction(name: any, param: any) {
    return;
  }

  milestoneName: string;
  showMileStonePopup: boolean = false;
  async saveMilestone() {
    if (this.milestone) {
      return;
    }
    this.loading["milestone"] = true;
    let data: any = {
      action: "create_milestone",
      milestone_name: "",
      account_id: this.accountId,
      region_id: this.regionId,
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (length > -1 && this.workloads.length != length) {
      return;
    }
    this.loading["milestone"] = false;
  }

  hideMilestonePopup(event: boolean) {
    this.showMileStonePopup = false;
    if (event) {
      this.loadMilestones();
    }
  }

  getMilestoneNames() {
    return this.milestones.map((milestone: any) => {
      return milestone["name"];
    });
  }

  async loadMilestones() {
    this.loading["milestones"] = true;
    let data: any = {
      action: "list_milestones",
      workload_id: this.selectedWorkload["id"],
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1") {
      result.milestones = result.milestones.map((milestone: any) => {
        milestone["creation_time"] = moment(milestone["creation_time"]).format(
          "MMM DD, YYYY hh:mm A"
        );
        this.loadMilestone(milestone);
        return milestone;
      });
      this.milestones = result.milestones;
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.error || result.error_message,
        "info",
        5000
      );
    }
    this.loading["milestones"] = false;
  }

  async loadMilestone(milestone: any) {
    milestone["questions"] = [];
    if (this.milestone) {
      this.loading["questions"] = true;
    }
    this.loading[`milestone_${milestone["id"]}`] = true;
    this.loading["milestone"] = true;
    let data: any = {
      action: "get_milestone_questions",
      workload_id: this.selectedWorkload["id"],
      id_token: localStorage.getItem("idT"),
      milestone_id: milestone["id"],
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1") {
      let pillers: string[] = [];
      result.questions = result.questions
        .map((question: any) => {
          if (!question["BestPractices"]) {
            question["BestPractices"] = [];
          }
          if (question["Qno"]) {
            let que_no = question["Qno"].split(" ")[1];
            let que_code = question["Qno"].split(" ")[0];
            if (pillers.indexOf(que_code) > -1) {
              que_no = `${pillers.indexOf(que_code)}` + que_no;
            } else {
              pillers.push(que_code);
              que_no = `${pillers.indexOf(que_code)}` + que_no;
            }
            question["Qno_dummy"] = Number(que_no);
          } else {
            question["Qno_dummy"] = 0;
          }
          if (!question["SwayamChoices"]) {
            question["SwayamChoices"] = [];
          }

          // consider previous values also
          if (question["ChoiceAnswerSummaries"].length == 0) {
            question["ChoiceAnswerSummaries"] = question["Choices"].map(
              (choice: any) => {
                return {
                  ChoiceId: choice["ChoiceId"],
                  Status:
                    question["SelectedChoices"].indexOf(choice["ChoiceId"]) > -1
                      ? "SELECTED"
                      : "UNSELECTED",
                  Reason: "",
                  Notes: "",
                  edit: true,
                };
              }
            );
          } else {
            question["ChoiceAnswerSummaries"] = question[
              "ChoiceAnswerSummaries"
            ].map((choice: any) => {
              if (choice["Notes"] == undefined) {
                choice["Notes"] = "";
              }
              if (choice["Notes"].trim() == "") {
                choice["edit"] = true;
              }

              return choice;
            });
          }

          let check = question["SwayamChoices"].find((choice: any) => {
            return choice["Title"] == "None of these";
          });
          if (!check) {
            question["SwayamChoices"].push({
              choiceId: question["Choices"].find((choice: any) => {
                return choice["Title"] == "None of these";
              })["ChoiceId"],
            });
          }

          if (
            question["SwayamChoices"] &&
            question["SwayamChoices"].length > 0
          ) {
            let options: string[] = [];
            question["SwayamChoices"] = question["SwayamChoices"].filter(
              (choice: any) => {
                if (options.indexOf(choice["choiceId"]) > -1) {
                  return false;
                } else {
                  options.push(choice["choiceId"]);
                  return true;
                }
              }
            );
          }

          question["SwayamChoices"] = question["SwayamChoices"].map(
            (choice: any) => {
              if (choice["comments"] == undefined) {
                choice["comments"] = "";
              }
              if (choice["comments"].trim() == "") {
                choice["edit"] = true;
              } else {
                choice["edit"] = false;
              }
              if (choice["evidence"]) {
                choice["evidence"] = choice["evidence"].sort(
                  (a: any, b: any) => {
                    return a["status"] ? -1 : 1;
                  }
                );
              }
              return choice;
            }
          );
          return question;
        })
        .sort((a: any, b: any) => {
          return a["Qno_dummy"] > b["Qno_dummy"] ? 1 : -1;
        });
      milestone["questions"] = result["questions"];
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.error || result.error_message,
        "info",
        5000
      );
    }
    delete this.loading[`milestone_${milestone["id"]}`];
    this.loading[`milestone`] = false;
    if (this.milestone) {
      this.loading["questions"] = false;
    }
    return true;
  }
}
