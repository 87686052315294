<div [ngStyle]="{ display: createTicket ? 'none' : 'block' }">
  <h5 class="card-title">Trusted Advisor</h5>
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#cost-optimizing"
        type="button" role="tab" aria-controls="cost-optimizing" aria-selected="true" (click)="loadCostOptimizaing()">
        Cost Optimizing ({{ summary.cost_optimizing || 0 }})
      </button>
      <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#security" type="button"
        role="tab" aria-controls="security" aria-selected="true" (click)="loadSecurity()">
        Security ({{ summary.security || 0 }})
      </button>
      <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#fault-tolerance" type="button"
        role="tab" aria-controls="fault-tolerance" aria-selected="true" (click)="loadFaultTolerance()">
        Fault Tolerance ({{ summary.fault_tolerance || 0 }})
      </button>
      <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#performance" type="button"
        role="tab" aria-controls="performance" aria-selected="true" (click)="loadPerformace()">
        Performance ({{ summary.performance || 0 }})
      </button>
      <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#service-limits" type="button"
        role="tab" aria-controls="service-limits" aria-selected="true" (click)="loadServiceLimits()">
        Service Limits ({{ summary.service_limits || 0 }})
      </button>
    </div>
  </nav>
  <div class="tabSummry">
    <div class="summary_container">
      <div class="card_container savings">
        <div class="text_container">
          <div>$ {{ tabSummary.savings.toFixed(2) || "0.0" }}</div>
          <div><i class="fa fa-usd" aria-hidden="true"></i></div>
        </div>
        <div>
          <div>Potential Monthly Savings</div>
        </div>
      </div>
      <div class="card_container red_back">
        <div class="text_container">
          <div>{{ tabSummary.error || 0 }}</div>
          <div>
            <i class="fa fa-times-circle-o"></i>
          </div>
        </div>
        <div>
          <div>Action Recommended</div>

        </div>
      </div>
      <div class="card_container orange_back">
        <div class="text_container">
          <div>{{ tabSummary.warning || 0 }}</div>
          <div>
            <i class="fa fa-exclamation-triangle"></i>
          </div>
        </div>
        <div>
          <div>Investigation Recommended</div>

        </div>
      </div>
      <div class="card_container green_back">
        <div class="text_container">
          <div>{{ tabSummary.ok || 0 }}</div>
          <div>
            <i class="fa fa-check-circle-o"></i>
          </div>
        </div>
        <div>
          <div>No Problems Detected</div>
        </div>
      </div>
      <div class="card_container grey_back">
        <div class="text_container">
          <div>{{ tabSummary.not_available || 0 }}</div>
          <div>
            <i class="fa fa-minus-circle"></i>
          </div>
        </div>
        <div>
          <div>Excluded Items</div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane active" id="cost-optimizing" role="tabpanel" aria-labelledby="cost-optimizing-tab">
      <div class="card">
        <div class="card-body">
          <div class="form_currentFilter">
            <select (change)="changeFilter($event)" class="form-control changeFilter_form" name=""
              [ngModel]="current_filter">
              <option value="all">All</option>
              <option value="error">Action Recommended</option>
              <option value="warning">Investigation Recommended</option>
              <option value="ok">No Problems Detected</option>
              <option value="not_available">Excluded Items</option>
            </select>
          </div>
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th>S.N.</th>
                <th></th>
                <th *ngFor="let h of headers">{{ h.name }}</th>
                <th>Create Ticket</th>
              </tr>
            </thead>

            <tbody>
              <tr [ngStyle]="{
                  display: filterData(costOptimizingList).length > 0 ? 'table-row' : 'nonw'
                }" *ngFor="let i of filterData(costOptimizingList); let j = index">
                <td>
                  {{ j + 1 }}
                  <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                  <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                </td>
                <td>
                  <i *ngIf="i.status == 'ok'" class="fa fa-check-circle-o green_back"></i>
                  <i *ngIf="i.status == 'not_available'" class="fa fa-minus-circle grey_back"></i>
                  <i *ngIf="i.status == 'error'" class="fa fa-times-circle-o red_back"></i>
                  <i *ngIf="i.status == 'warning'" class="fa fa fa-exclamation-triangle orange_back"></i>
                </td>
                <td *ngFor="let h of headers">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span [innerHTML]="i[h.id]"> </span>
                  </span>
                  <span *ngIf="!h.click">
                    <span *ngIf="h.overflow && !i.showNested" class="ngIf_css">
                      {{ overFlowText(i[h.id]) }}
                    </span>
                    <span *ngIf="!h.overflow || i.showNested" [innerHTML]="i[h.id]">
                    </span>
                  </span>
                </td>
                <td>
                  <span class="btn" (click)="createTicketTrigger(i)">Create Ticket</span>
                </td>
              </tr>
              <tr [ngStyle]="{
                  display: filterData(costOptimizingList).length == 0 ? 'table-row' : 'none'
                }">
                <td [attr.colspan]="headers.length + 3">
                  <div class="No_data">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="security" role="tabpanel" aria-labelledby="security-tab">
      <div class="card">
        <div class="card-body">
          <div class="card_body_security">
            <select (change)="changeFilter($event)" class="form-control form_control_tabPanel" name=""
              [ngModel]="current_filter">
              <option value="all">All</option>
              <option value="error">Action Recommended</option>
              <option value="warning">Investigation Recommended</option>
              <option value="ok">No Problems Detected</option>
              <option value="not_available">Excluded Items</option>
            </select>
          </div>
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th>S.N.</th>
                <th></th>
                <th *ngFor="let h of headers">{{ h.name }}</th>
                <th>Create Ticket</th>
              </tr>
            </thead>

            <tbody>
              <tr [ngStyle]="{
                  display: filterData(securityList).length > 0 ? 'table-row' : 'nonw'
                }" *ngFor="let i of filterData(securityList); let j = index">
                <td>
                  {{ j + 1 }}
                  <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                  <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                </td>
                <td>
                  <i *ngIf="i.status == 'ok'" class="fa fa-check-circle-o green_back"></i>
                  <i *ngIf="i.status == 'not_available'" class="fa fa-minus-circle grey_back"></i>
                  <i *ngIf="i.status == 'error'" class="fa fa-times-circle-o red_back"></i>
                  <i *ngIf="i.status == 'warning'" class="fa fa fa-exclamation-triangle orange_back"></i>
                </td>
                <td *ngFor="let h of headers">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span [innerHTML]="i[h.id]"> </span>
                  </span>
                  <span *ngIf="!h.click">
                    <span *ngIf="h.overflow && !i.showNested" class="overFlowText_css">
                      {{ overFlowText(i[h.id]) }}
                    </span>
                    <span *ngIf="!h.overflow || i.showNested" [innerHTML]="i[h.id]">
                    </span>
                  </span>
                </td>
                <td>
                  <span class="btn" (click)="createTicketTrigger(i)">Create Ticket</span>
                </td>
              </tr>
              <tr [ngStyle]="{
                  display: filterData(securityList).length == 0 ? 'table-row' : 'none'
                }">
                <td [attr.colspan]="headers.length + 3">
                  <div class="no_data211">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="fault-tolerance" role="tabpanel" aria-labelledby="fault-tolerance-tab">
      <div class="card">
        <div class="card-body">
          <div class="tab_Panel222">
            <select (change)="changeFilter($event)" class="form-control changeFilter223" name=""
              [ngModel]="current_filter">
              <option value="all">All</option>
              <option value="error">Action Recommended</option>
              <option value="warning">Investigation Recommended</option>
              <option value="ok">No Problems Detected</option>
              <option value="not_available">Excluded Items</option>
            </select>
          </div>
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th>S.N.</th>
                <th></th>
                <th *ngFor="let h of headers">{{ h.name }}</th>
                <th>Create Ticket</th>
              </tr>
            </thead>

            <tbody>
              <tr [ngStyle]="{
                  display: filterData(faultToleranceList).length > 0 ? 'table-row' : 'nonw'
                }" *ngFor="let i of filterData(faultToleranceList); let j = index">
                <td>
                  {{ j + 1 }}
                  <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                  <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                </td>
                <td>
                  <i *ngIf="i.status == 'ok'" class="fa fa-check-circle-o green_back"></i>
                  <i *ngIf="i.status == 'not_available'" class="fa fa-minus-circle grey_back"></i>
                  <i *ngIf="i.status == 'error'" class="fa fa-times-circle-o red_back"></i>
                  <i *ngIf="i.status == 'warning'" class="fa fa fa-exclamation-triangle orange_back"></i>
                </td>
                <td *ngFor="let h of headers">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span [innerHTML]="i[h.id]"> </span>
                  </span>
                  <span *ngIf="!h.click">
                    <span *ngIf="h.overflow && !i.showNested" class="overFlowText263">
                      {{ overFlowText(i[h.id]) }}
                    </span>
                    <span *ngIf="!h.overflow || i.showNested" [innerHTML]="i[h.id]">
                    </span>
                  </span>
                </td>
                <td>
                  <span class="btn" (click)="createTicketTrigger(i)">Create Ticket</span>
                </td>
              </tr>
              <tr [ngStyle]="{
                  display: filterData(faultToleranceList).length == 0 ? 'table-row' : 'none'
                }">
                <td [attr.colspan]="headers.length + 3">
                  <div class="no_data277">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="performance" role="tabpanel" aria-labelledby="performance-tab">
      <div class="card">
        <div class="card-body">
          <div class="changeFilter288">
            <select (change)="changeFilter($event)" class="form-control form_control289" name=""
              [ngModel]="current_filter">
              <option value="all">All</option>
              <option value="error">Action Recommended</option>
              <option value="warning">Investigation Recommended</option>
              <option value="ok">No Problems Detected</option>
              <option value="not_available">Excluded Items</option>
            </select>
          </div>
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th>S.N.</th>
                <th></th>
                <th *ngFor="let h of headers">{{ h.name }}</th>
                <th>Create Ticket</th>
              </tr>
            </thead>

            <tbody>
              <tr [ngStyle]="{
                  display: filterData(performanceList).length > 0 ? 'table-row' : 'nonw'
                }" *ngFor="let i of filterData(performanceList); let j = index">
                <td>
                  {{ j + 1 }}
                  <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                  <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                </td>
                <td>
                  <i *ngIf="i.status == 'ok'" class="fa fa-check-circle-o green_back"></i>
                  <i *ngIf="i.status == 'not_available'" class="fa fa-minus-circle grey_back"></i>
                  <i *ngIf="i.status == 'error'" class="fa fa-times-circle-o red_back"></i>
                  <i *ngIf="i.status == 'warning'" class="fa fa fa-exclamation-triangle orange_back"></i>
                </td>
                <td *ngFor="let h of headers">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span [innerHTML]="i[h.id]"> </span>
                  </span>
                  <span *ngIf="!h.click">
                    <span *ngIf="h.overflow && !i.showNested" class="overFlowText328">
                      {{ overFlowText(i[h.id]) }}
                    </span>
                    <span *ngIf="!h.overflow || i.showNested" [innerHTML]="i[h.id]">
                    </span>
                  </span>
                </td>
                <td>
                  <span class="btn" (click)="createTicketTrigger(i)">Create Ticket</span>
                </td>
              </tr>
              <tr [ngStyle]="{
                  display: filterData(performanceList).length == 0 ? 'table-row' : 'none'
                }">
                <td [attr.colspan]="headers.length + 3">
                  <div class="No_data343">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="service-limits" role="tabpanel" aria-labelledby="service-limits-tab">
      <div class="card">
        <div class="card-body">
          <div class="form_control354">
            <select (change)="changeFilter($event)" class="form-control changeFilter355" name=""
              [ngModel]="current_filter">
              <option value="all">All</option>
              <option value="error">Action Recommended</option>
              <option value="warning">Investigation Recommended</option>
              <option value="ok">No Problems Detected</option>
              <option value="not_available">Excluded Items</option>
            </select>
          </div>
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th>S.N.</th>
                <th></th>
                <th *ngFor="let h of headers">{{ h.name }}</th>
                <th>Create Ticket</th>
              </tr>
            </thead>

            <tbody>
              <tr [ngStyle]="{
                  display: filterData(serviceLimitList).length > 0 ? 'table-row' : 'nonw'
                }" *ngFor="let i of filterData(serviceLimitList); let j = index">
                <td>
                  {{ j + 1 }}
                  <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                  <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                </td>
                <td>
                  <i *ngIf="i.status == 'ok'" class="fa fa-check-circle-o green_back"></i>
                  <i *ngIf="i.status == 'not_available'" class="fa fa-minus-circle grey_back"></i>
                  <i *ngIf="i.status == 'error'" class="fa fa-times-circle-o red_back"></i>
                  <i *ngIf="i.status == 'warning'" class="fa fa fa-exclamation-triangle orange_back"></i>
                </td>
                <td *ngFor="let h of headers">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span [innerHTML]="i[h.id]"> </span>
                  </span>
                  <span *ngIf="!h.click">
                    <span *ngIf="h.overflow && !i.showNested" class="overFlowText394">
                      {{ overFlowText(i[h.id]) }}
                    </span>
                    <span *ngIf="!h.overflow || i.showNested" [innerHTML]="i[h.id]">
                    </span>
                  </span>
                </td>
                <td>
                  <span class="btn" (click)="createTicketTrigger(i)">Create Ticket</span>
                </td>
              </tr>
              <tr [ngStyle]="{
                  display: filterData(serviceLimitList).length == 0 ? 'table-row' : 'none'
                }">
                <td [attr.colspan]="headers.length + 3">
                  <div class="no_data409">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<create-ticket *ngIf="createTicket" [createTicketStrictTicketType]="true" [createTicketSupportType]="'minfy'"
  [createTicketSubject]="createTicket.name" [createTicketDescription]="createTicket.description"
  (hideCreateTicket)="hideCreateTicket($event)"></create-ticket>