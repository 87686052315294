<div class="card">
  <h5 class="card-header">CFRC Order Report</h5>
  <div class="card-body">
    <div class="order_report_04" *ngIf="this.cfrcData.length > 0">
      <div class="cfrc_period_06">
        CFRC Period : <select class="form-select" [(ngModel)]="selectedRange">
          <option [ngValue]="range" *ngFor="let range of ranges;">{{ range }}</option>
        </select>
      </div>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}
          </th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display' : filteredCFRCs().length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of filteredCFRCs(); let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': filteredCFRCs().length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div class="no_data_found">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>