import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "./../../../_services/notifier.service";
import { environment } from "src/environments/environment";
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./ebs.component.html",
  styleUrls: ["./ebs.component.css"],
})
export class ebsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  ebsNonEncryptedList: any = [];
  ebsEncryptedList: any = [];

  headers: any = [
    {
      id: "volumeId",
      name: "Volume ID",
      filter: true,
    },
    {
      id: "volumeStatus",
      name: "Volume Status",
      sort: { sort: true, direction: "Asc" },
    },
    { id: "snapshotId", name: "Snapshot ID", filter: true },
    {
      id: "instanceId",
      name: "Instance ID",
      show: true,
      sort: { sort: true, direction: "Asc" },
      type: "String",
      filter: true,
      style: { width: 0 },
      click: "showData",
    },
  ];

  azureHeaders: any = [
    {
      id: "volumeId",
      name: "Disk ID",
      filter: true,
    },
    {
      id: "volumeStatus",
      name: "Disk Status",
      sort: { sort: true, direction: "Asc" },
    },
    { id: "snapshotId", name: "Snapshot", filter: true },
    {
      id: "instanceId",
      name: "Attached VM",
      show: true,
      sort: { sort: true, direction: "Asc" },
    },
  ];

  funRef: any;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  resourceGroup: string = localStorage.getItem("selectedResourceGroup");
  selectedCloudPartner: string = null;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
    };

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId" && this.selectedCloudPartner == 'aws') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy() {
    this.currentMessage.unsubscribe();
  }

  async load(nextToken: string = "") {
    this.notifier.loading(true);
    if (nextToken == "") {
      this.ebsEncryptedList = [];
      this.ebsNonEncryptedList = [];
    }
    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      action: "fetchVolumeDetailsWithToken",
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Compliance.Ebs", "Ebs Volume Fetched");
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ebs`;

    if (this.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/central`;
      data = {
        action: "list_managed_disks_based_on_encryption",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.nonEncryptedVolumeList) {
        this.ebsNonEncryptedList =
          [...this.ebsNonEncryptedList, ...result.nonEncryptedVolumeList] || [];
      }
      if (result.encryptedVolumeList) {
        this.ebsEncryptedList =
          [...this.ebsEncryptedList, ...result.encryptedVolumeList] || [];
      }
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    if (result.nextToken) {
      await this.load(result.nextToken);
    }

    this.notifier.loading(false);
  }

  async showData(ref: any, id: any) {
    console.log("showData", id);

    ref.notifier.loading(true);

    let data = {
      accountId: ref.accountId,
      region: ref.regionId,
      action: "fetchInstanceName",
      instanceId: id.instanceId,
      nextToken: "",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Compliance.Ebs", "InstanceName Fetched");
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/support`;
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/operations/ebs`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      //ref.kbDetails = result.page;
      //ref.showDetailKB = true;

      ref.notifier.messageBox(
        "More Instance Info!",
        `<div class="flex-center"><strong>Instance Tag:</strong>  ` +
          result.instanceName +
          "</div>"
      );
    } else {
      //ref.notifier.messageBox("More Instance Info!", "sdasdasdasda");

      ref.notifier.alert("Error", "", result.error, "error", 5000);
    }
    ref.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  showPOPup() {
    this.notifier.messageBox(
      "More Instance Info!",
      '<span style="color:red"> sdasdasdasda <span>'
    );
  }
}
