<div class="vpc_container" *ngIf="vpc">
  <div class="text_bold">
    {{vpc.vpc_id}}
    <span *ngIf="vpc.name">
      ({{vpc.name}})
    </span>
  </div>
  <div class="text_light">
    {{vpc.cidr}}
  </div>
</div>