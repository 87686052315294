<div class="modal show" tabindex="-1" *ngIf="showModal">
  <div class="modal-dialog" [ngStyle]="{'width': maxWidth }">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="close(true)"></button>
      </div>
      <div class="modal-body">
        <div class="card">

          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->
            <table class="table table-docs table-hover" *ngIf="data.length > 0">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of headers">
                    {{h.name}}

                    <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,data)">

                      <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                      <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                    </span>

                    <span *ngIf="h.filter " class="inputseach">
                      <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let i of data; let j = index">
                  <tr *ngIf="notifier.filterTableColums(headers,i)">
                    <td>{{j+1}}</td>
                    <td *ngFor="let h of headers">
                      <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                        <span (click)="callFunction(h.click,i)">
                          {{i[h.id]}}
                        </span>
                      </span>
                      <span *ngIf="!h.click">
                        {{i[h.id]}}
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <h5 class="text-color" *ngIf="data.length == 0">No Data</h5>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="close(true)">Close</button>
      </div>
    </div>
  </div>
</div>