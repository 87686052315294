import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import * as moment from 'moment';

declare let flatpickr: any;

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent implements OnInit {
  @ViewChild('datepicker') attachmentElement: ElementRef;
  @Input('date') date: any;
  @Output('updateEndDate') updateEndDate = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      let start_date = moment().format('DD-MMM-YYYY');
      this.date = start_date;
      this.updateEndDate.emit(this.date);
      flatpickr(this.attachmentElement.nativeElement, {
        dateFormat: 'd-M-Y',
        minDate: new Date(),
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          this.date = dateStr;
          this.updateEndDate.emit(dateStr);
        },
      });
    }, 500);
  }
}
