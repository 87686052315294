import { Component, OnInit, OnDestroy } from "@angular/core";
import * as moment from "moment";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "./../../../_services/notifier.service";

@Component({
  selector: "app-risavings",
  templateUrl: "./risavings.component.html",
  styleUrls: ["./risavings.component.css"],
})
export class RisavingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";

  loading: any = {};
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  destroyed: boolean = false;
  scanDetails: any = null;
  instances: any = [];
  selectedInstance: any = null;
  overview: any = {
    current_ec2: 0.0,
    ri_1yr_no_upfront: 0.0,
    ri_3yr_no_upfront: 0.0,
    spot_price: 0.0,
    partial_run: 0.0,
  };

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }

      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
    window.addEventListener("scroll", (event: any) => {
      this.moveTop(event);
    });
  }

  getSpotRecomLength() {
    return this.instances.filter((instance: any) => {
      return instance["pricing"]["spotPricing"] ? true : false;
    }).length;
  }

  async createScan() {
    if (this.destroyed) {
      return;
    }
    this.instances = [];
    this.loading["scan"] = true;
    this.page = 1;
    let data: any = {
      a: "scan",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2ridev`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
      setTimeout(() => {
        this.load();
      }, 1000);
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
      this.loading["scan"] = false;
    }
  }

  fetchLow(instance: any) {
    return Math.min(
      ...instance.pricing.riPricing.map((ri: any) => {
        return ri["diffPerMonth"];
      })
    ).toFixed(2);
  }
  fetchHigh(instance: any) {
    if (instance["pricing"]["spotPricing"]) {
      return Number(
        Number(instance["pricing"]["spotPricing"]["diffPerMonth"]).toFixed(2)
      );
    }
    return Math.max(
      ...instance.pricing.riPricing.map((ri: any) => {
        return ri["diffPerMonth"];
      })
    ).toFixed(2);
  }

  async load(refresh: boolean = true) {
    if (this.destroyed) {
      return;
    }
    this.page = 1;
    this.overview = {
      current_ec2: 0.0,
      ri_1yr_no_upfront: 0.0,
      ri_3yr_no_upfront: 0.0,
      spot_price: 0.0,
      partial_run: 0.0,
    };
    this.loading["instances"] = true;
    this.instances = [];
    if (refresh) {
      this.scanDetails = null;
    }
    let data: any = {
      a: "fetch",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2ridev`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result["scanStatus"]) {
      this.scanDetails = {
        scanStatus: result["scanStatus"],
        scanDate: moment(result["scanDate"]).format("MMM DD, YYYY hh:mm A"),
      };
    }
    if (result.status == "1" || result.s == "1") {
      if (result.data && result.data.length > 0) {
        this.overview["current_ec2"] = result.data
          .map((instance: any) => {
            if (!instance["pricing"]["odPricing"]) {
              console.log(instance);
            }
            return instance["pricing"]["odPricing"]
              ? Number(instance["pricing"]["odPricing"]["pricePerMonth"])
              : 0.0;
          })
          .reduce(
            (acc: number, current: number) => Number(acc) + Number(current)
          );
        this.overview["partial_run"] = result.data
          .map((instance: any) => {
            if (!instance["pricing"]["odPricing"]["diffPartialRun"]) {
              instance["pricing"]["odPricing"]["diffPartialRun"] =
                instance["pricing"]["odPricing"]["pricePerMonth"] -
                instance["pricing"]["odPricing"]["partialRun"];
            }
            return instance["pricing"]["odPricing"]
              ? Number(instance["pricing"]["odPricing"]["partialRun"])
              : 0.0;
          })
          .reduce(
            (acc: number, current: number) => Number(acc) + Number(current)
          );
        this.overview["ri_1yr_no_upfront"] = result.data
          .map((instance: any) => {
            let inst_ = instance["pricing"]["riPricing"].find((inst: any) => {
              return (
                inst["riPeriod"] == "1yr" &&
                inst["riUpfront"] == "No Upfront" &&
                inst["riClass"] == "standard"
              );
            });
            return inst_ ? Number(inst_["diffPerMonth"]) : 0.0;
          })
          .reduce(
            (acc: number, current: number) => Number(acc) + Number(current)
          );
        this.overview["ri_3yr_no_upfront"] = result.data
          .map((instance: any) => {
            let inst_ = instance["pricing"]["riPricing"].find((inst: any) => {
              return (
                inst["riPeriod"] == "3yr" &&
                inst["riUpfront"] == "No Upfront" &&
                inst["riClass"] == "standard"
              );
            });
            return inst_ ? Number(inst_["diffPerMonth"]) : 0.0;
          })
          .reduce(
            (acc: number, current: number) => Number(acc) + Number(current)
          );
        this.overview["spot_price"] = result.data
          .map((instance: any) => {
            if (
              instance["pricing"]["spotPricing"] &&
              instance["pricing"]["spotPricing"]["priceOn"]
            ) {
              instance["pricing"]["spotPricing"]["priceOn"] = moment(
                instance["pricing"]["spotPricing"]["priceOn"]
              ).format("MMM DD, YYYY hh:mm A");
              instance["pricing"]["spotPricing"]["diffPerc"] =
                (instance["pricing"]["spotPricing"]["diffPerMonth"] /
                  instance["pricing"]["odPricing"]["pricePerMonth"]) *
                100;
            }
            return instance["pricing"]["spotPricing"]
              ? Number(instance["pricing"]["spotPricing"]["pricePerMonth"])
              : 0.0;
          })
          .reduce(
            (acc: number, current: number) => Number(acc) + Number(current)
          );

        this.instances = result["data"];
      }
      if (result.data == undefined && !result.error) {
        this.loading["scan"] = true;
        setTimeout(() => {
          this.load(false);
        }, 5000);
      } else {
        this.loading["scan"] = false;
      }
    } else {
      this.loading["scan"] = false;
    }

    this.loading["instances"] = false;
  }

  pageOffset: number = 0;
  moveTop(event: any) {
    this.pageOffset = window.pageYOffset;
  }

  moveToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
    this.destroyed = true;
    window.removeEventListener("scroll", (event: any) => {
      this.moveTop(event);
    });
  }
}
