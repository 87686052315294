import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-banner-cams",
  templateUrl: "./banner-cams.component.html",
  styleUrls: ["./banner-cams.component.css"],
})
export class BannerCamsComponent implements OnInit {
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;
  @Output("hideModal") hideModal = new EventEmitter<boolean>();

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  stepsUpdate: boolean = false;
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(private apiServer: APIService, private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  loading: any = {
    cur: false,
    load: false,
    add: false,
  };

  response: any = {
    fetch: {},
  };

  step: string = "STATUS_CHECK";
  oldStep: string = null;

  ngOnInit(): void {
    this.load();
  }

  setStep(step: string) {
    this.oldStep = this.step;
    this.step = step;
  }

  async load() {
    this.loading["load"] = true;
    let data: any = {
      a: "fetch",
      accountId: this.accountId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinguser`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    // console.log(result);

    if (result.status == "1" || result.s == "1") {
      // if (
      //   result["curMsg"] ==
      //   "AWS CUR configuration is not found."
      // ) {
      //   result["curStatus"] = true;
      // }
      // if (
      //   result["curMsg"] ==
      //   "please update swayam cft template to latest version."
      // ) {
      //   result["cftMissing"] = 'Please update swayam cft template to latest version.';
      // }
      // if (result["curStatus"] && result["s3Status"]) {
      //   this.hideModal.emit(false);
      //   return;
      // }
      if(result['createdAt'] != '') {
        result['hours'] = moment().diff(moment(result['createdAt']), 'hours');
        if(result['hours'] > 24) {
          this.hideModal.emit();
        }
      }
      this.response["fetch"] = result;
    }

    this.loading["load"] = false;
  }
}
