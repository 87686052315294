<div class="banner-container">
  <div class="review-alert mb-3">
    <div>
      <i class="fa fa-info-circle" aria-hidden="true"></i>
    </div>
    <div class="alert-body">
      <ng-container
        *ngIf="
          response.fetch &&
          response.fetch.curStatus == true &&
          response.fetch.s3Status == true &&
          response['fetch']['hours'] < 24
        "
      >
        <div *ngIf="!loading['load']">
          <div class="alert-title">CUR configured successfully</div>
          <div>It may take 24 hours to populate the data</div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          response.fetch.curStatus == undefined ||
          (response.fetch && response.fetch.curStatus == false) ||
          (response.fetch && response.fetch.s3Status == false)
        "
      >
        <div
          *ngIf="
            !loading['load'] &&
            response?.fetch?.curStatus == false &&
            response?.fetch?.s3Status == false
          "
        >
          <div class="alert-title">{{ response?.fetch?.curMsg }}</div>
          <div>
            Configure CUR in your AWS Account with
            {{ whitelabelData["pd"]["appName"] }} to get near real time billing
            details.
            <span class="nav-link" (click)="stepsUpdate = true">know more</span>
          </div>
        </div>
        <div
          *ngIf="
            !loading['load'] &&
            response?.fetch?.curStatus == true &&
            response?.fetch?.s3Status == false
          "
        >
          <div class="alert-title">CUR Configuration is incomplete</div>
          <div>{{ response.fetch.s3Msg }}</div>
        </div>
        <div *ngIf="loading['load']">
          <div class="alert-title">Checking for CUR Status</div>
        </div>
        <div *ngIf="loading['load']">
          <img
            src="../assets/img/loading_2.svg"
            alt="loading scans"
            class="wid-18"
          />
        </div>
        <div *ngIf="!loading['load']" class="flex-start">
          <span class="nav-link" (click)="load()">
            <i class="fa fa-refresh"></i>
          </span>
          <a
            [attr.href]="response?.fetch?.cft"
            target="_blank"
            *ngIf="
              response?.fetch?.curStatus == false &&
              !response?.fetch?.cftMissing
            "
            class="btn btn-primary-soft"
          >
            Configure CUR
          </a>
          <!-- <div class="flex-end">
            <button (click)="setStep('ENABLE_CUR')" class="btn btn-primary-soft">
              Configure CUR
            </button>
            <button (click)="setStep('CONFIGURE_S3')" class="btn btn-primary-soft">
              Configure S3
            </button>
          </div> -->
        </div>
      </ng-container>
    </div>
  </div>
</div>
<app-enable-cur
  *ngIf="step == 'ENABLE_CUR'"
  [data]="response"
  (hideModal)="setStep(null)"
></app-enable-cur>
<app-s3-configure-cur
  *ngIf="step == 'CONFIGURE_S3'"
  [accountId]="accountId"
  [regionId]="regionId"
  (hideModal)="setStep(null)"
></app-s3-configure-cur>
<app-steps-configure-cft
  *ngIf="stepsUpdate"
  (hideModel)="stepsUpdate = false"
></app-steps-configure-cft>
