<div class="amis_body">
  <div class="amis_more">
    <div>
      <div class="mb-3">
        <label for="" class="ami_id_05">AMI ID</label>
        <launch-select-dropdown [options]="amis" (update)="updateReports($event)" key="ami_id"
          [selected]="instance.image_id" title="AMI">
        </launch-select-dropdown>
        <div class="error-text" *ngIf="instance.errors && instance.errors.image_id && !instance.image_id">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.image_id}}
        </div>
      </div>
      <div class="mb-3">
        <label for="" class="iam_role_14">IAM Role</label>
        <launch-select-dropdown [options]="roles" (update)="updateReports($event)" key="arn"
          [selected]="instance.iam_role" title="Role">
        </launch-select-dropdown>
        <div class="error-text" *ngIf="instance.errors && instance.errors.iam_role && !instance.iam_role">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.iam_role}}
        </div>
      </div>
      <div class="mb-3">
        <label for="" class="instance_type_23">Instance Type</label>
        <div>{{instance.instance_type}}</div>
      </div>
    </div>
  </div>
</div>