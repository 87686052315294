import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let Jhxlsx: any;

@Component({
  selector: "tell-me-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.css", "./../../tell-me.component.css"],
})
export class HistoryComponent implements OnInit, OnChanges {
  @Input("types") types: any;
  @Input("regionId") regionId: any;
  @Input("accountId") accountId: any;
  @Input("names") names: any;
  @Output("hideModal") hideModal: any = new EventEmitter<boolean>();
  scanList: any = {};
  loading: any = {};
  groupByRules: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  currentPage: string = null;

  pagination: any = {};

  pages: any = [10, 50, 100, 200];

  hideExport: boolean = true;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.types = JSON.parse(JSON.stringify(this.types));
    this.names = JSON.parse(JSON.stringify(this.names));
    delete this.names["database"];
    this.currentPage = this.getObjectKeys(this.names)[0];
    let promises: any = [];
    this.getObjectKeys(this.names).forEach((type: string) => {
      this.pagination[type] = {
        perPage: 10,
        page: 1,
      };
      this.scanList[type] = [];
      this.loading[type] = true;
      promises.push(this.load(type, false));
    });
    Promise.all(promises).then((values: any) => {
      this.hideExport = false;
    });
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

  async load(type: string, select: boolean = true) {
    if (select) {
      this.currentPage = type;
    }
    if (this.scanList[type].length > 0) {
      return;
    }
    this.loading[type] = true;
    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      operation: "fetchScan",
      module: this.names[type].replaceAll(" ", ""),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    this.scanList[type] = [];

    if (result.s == "1" || result.status == "1") {
      result["scanList"] = result.scanList
        .sort((a: any, b: any) => {
          let dateTemp_a: any = new Date(a["eventTime"]);
          let dateTemp_b: any = new Date(b["eventTime"]);
          return Date.parse(dateTemp_b) - Date.parse(dateTemp_a);
        })
        .map((event: any) => {
          event["eventTimeStr"] = event["eventTime"];
          event["eventTime"] = event["eventTime"]
            ? moment(event["eventTime"]).format("MMM DD, YYYY hh:mm A")
            : "-";
          event["links"] = [];
          switch (type) {
            case "security":
              if (event["securityGroup"]) {
                event["links"].push({
                  name: "Report",
                  link: "/dash/reports/view",
                  params: { reportType: "sg", sg_id: event["securityGroup"] },
                  target: "_blank",
                });
              }
              break;
            case "governance":
              if (
                event["description"].includes("An EC2 instance is stopped") ||
                event["description"].includes("An instance is launched")
              ) {
                event["links"].push({
                  name: "EC2",
                  link: "/dash/operations/tags",
                  params: { instance_id: event["instanceId"] },
                  target: "_blank",
                });
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: event["instanceId"],
                    resource_type: "EC2",
                    type_of_action:
                      event["description"] == "An EC2 instance is stopped"
                        ? "StopInstances"
                        : "RunInstances",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (
                event["description"].includes("New Security Group is Created")
              ) {
                event["links"].push({
                  name: "Report",
                  link: "/dash/reports/view",
                  params: { reportType: "sg", sg_id: event["securityGroup"] },
                  target: "_blank",
                });
              } else if (
                event["description"].includes("An S3 bucket is made public")
              ) {
                event["links"].push({
                  name: "S3",
                  link: "/dash/compliance/s3",
                  params: {},
                  target: "_blank",
                });
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: event["bucketName"],
                    resource_type: "Others",
                    type_of_action: "PutBucketPublicAccessBlock",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (
                event["description"].includes("An EC2 instance is terminated")
              ) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: event["instanceId"],
                    resource_type: "EC2",
                    type_of_action: "TerminateInstances",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (
                event["description"].includes(
                  "Access key is created for a user"
                )
              ) {
                event["links"].push({
                  name: "Access Key Age Report",
                  link: "/dash/compliance/iam/age_report",
                  params: {},
                  target: "_blank",
                });
              } else if (
                event["description"].includes(
                  "An RDS/Aurora Cluster is launched"
                )
              ) {
                event["links"].push({
                  name: "RDS",
                  link: "/dash/operations/rds",
                  params: {},
                  target: "_blank",
                });
              } else if (
                event["description"].includes("A non-encrypted EBS is launched")
              ) {
                event["links"].push({
                  name: "EBS",
                  link: "/dash/compliance/ebs",
                  params: {},
                  target: "_blank",
                });
              } else if (
                event["description"].includes("Security Group is Modified")
              ) {
                event["links"].push({
                  name: "Report",
                  link: "/dash/reports/view",
                  params: { reportType: "sg", sg_id: event["securityGroup"] },
                  target: "_blank",
                });
              } else if (event['description'].includes('Parameter in Parameter Store Deleted')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: event["securityGroup"],
                    resource_type: "Others",
                    type_of_action: "DeleteParameters",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('Secret in secrets manager Deleted')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: event["securityGroup"],
                    resource_type: "Others",
                    type_of_action: "DeleteSecret",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('Access key is deleted for a user')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "DisableKey",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('Encryption Keys is Modified')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "DisableKey",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('Firewall or Acl is Up')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "AuthorizeSecurityGroupIngress",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('Sharing Status of AMI is Updated')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "ModifyImageAttribute",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('EC2 Instance is Launched Without Encryption')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "RunInstances",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].toLowerCase().includes('rds instance is terminated')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "DeleteDBInstance",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('RDS Launched without Encryption')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "CreateDBCluster",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              } else if (event['description'].includes('Multiple Failed Login Attempts')) {
                event["links"].push({
                  name: "Cloudtrail",
                  link: "/dash/compliance/cloudtrail",
                  params: {
                    resource_name: '-',
                    resource_type: "Others",
                    type_of_action: "ConsoleLogin",
                    date: event["eventTimeStr"],
                  },
                  target: "_blank",
                });
              }
              break;
            case "dailyGovernance":
              if (
                event["description"].includes(
                  "System is lying in the stop state for more than 30 days"
                )
              ) {
                event["links"].push({
                  name: "Stopped EC2",
                  link: "/dash/costopt/other",
                  params: {},
                  target: "_blank",
                });
              } else if (
                event["description"].includes(
                  "if the Swayam security baseline score goes below 98%"
                )
              ) {
                event["links"].push({
                  name: "SSB",
                  link: "/dash/compliance/ssb",
                  params: { job_name: event["scanName"] },
                  target: "_blank",
                });
              } else if (
                event["description"].includes(
                  "The ACM certification has expired"
                )
              ) {
                event["links"].push({
                  name: "ACM",
                  link: "/dash/operations/acm",
                  params: { CertificateArn: event["arn"] },
                  target: "_blank",
                });
              }
              break;
            case "dailySpend":
              if (
                event["description"].includes(
                  "Day-to-day component spend increases by"
                )
              ) {
                let start_date: any = moment(event["eventTimeStr"])
                  .subtract(3, "days")
                  .format("YYYY-MM-DD");
                let end_date: any = moment(event["eventTimeStr"])
                  .add(3, "days")
                  .format("YYYY-MM-DD");
                event["links"].push({
                  name: "Daily Variance Report",
                  link: "/dash/billing/billing-daily",
                  params: {
                    range: "custom_range",
                    custom_range: "week",
                    start_date: start_date,
                    end_date: end_date,
                  },
                  target: "_blank",
                });
              }
              if (
                event["description"].includes(
                  "Monthly spend increased by more than"
                )
              ) {
                let start_date: any = moment(event["eventTimeStr"]).startOf(
                  "month"
                );
                let months_gap: any = moment().diff(start_date, "months");
                let range: string = "last_3_month";
                if (months_gap > 2) {
                  range = "last_6_month";
                }
                console.log(
                  event["eventTimeStr"],
                  start_date.format("YYYY-MM-DD"),
                  moment().format("YYYY-MM-DD"),
                  months_gap
                );

                event["links"].push({
                  name: "Daily Variance Report",
                  link: "/dash/billing/billing-daily",
                  params: {
                    range: range,
                  },
                  target: "_blank",
                });
              }
              break;
            default:
              break;
          }
          return event;
        });
      this.scanList[type] = result["scanList"];
    }

    this.loading[type] = false;
  }

  groupScanList: any = {};
  getGroupItems(type: string) {
    let data: any = [];
    this.scanList[type].forEach((desc: any) => {
      let item: any = data.find((item: any) => {
        return item["description"] == desc["description"];
      });
      if (item) {
        item["count"] += 1;
      } else {
        data.push({
          description: desc["description"],
          count: 1,
          show: false,
        });
      }
    });
    this.groupScanList[type] = data;
  }

  setPage() {
    this.getObjectKeys(this.names).forEach((type: any) => {
      this.pagination[type]["page"] = 1;
    });
    if (this.groupByRules) {
      this.getObjectKeys(this.names).forEach((type: string) => {
        this.getGroupItems(type);
      });
    } else {
      this.groupScanList = {};
    }
  }

  getDescriptionsForType(type: string, description: string) {
    return this.scanList[type].filter((item: any) => {
      return item["description"] === description;
    });
  }

  export() {
    let sheets: any = [];

    this.getObjectKeys(this.names).forEach((type: any) => {
      let dict: any = {
        sheetName: this.names[type],
        data: [],
      };

      let temp: any = [
        { text: "Sl. No." },
        { text: "Description" },
        { text: "Identifier" },
        { text: "Event Time" },
      ];

      if (
        type != "dailySpend" &&
        type != "dailyGovernance" &&
        type != "dailyCost"
      ) {
        temp.push({ text: "Triggered By" });
      }

      if (type == "security") {
        temp.push({ text: "Protocal" });
        temp.push({ text: "Source IP" });
        temp.push({ text: "From IP" });
        temp.push({ text: "To IP" });
      }
      dict["data"].push(temp);

      this.scanList[type].forEach((item: any, index: number) => {
        let check = [
          { text: index + 1 },
          { text: item["description"] || "-" },
          { text: item["identifier"] || "-" },
          { text: item["eventTime"] || "-" },
        ];
        if (
          type != "dailySpend" &&
          type != "dailyGovernance" &&
          type != "dailyCost"
        ) {
          check.push({ text: item["createdBy"] || "-" });
        }
        if (type == "security") {
          check.push({ text: item["protocal"] || "-" });
          check.push({ text: item["sourceIp"] || "-" });
          check.push({ text: item["fromPort"] || "-" });
          check.push({ text: item["toPort"] || "-" });
        }
        dict["data"].push(check);
      });
      sheets.push(dict);
    });

    var options = {
      fileName: `Swayam Tell Me - ${this.accountId}`,
    };

    Jhxlsx.export(sheets, options);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["types"] && this.types.length > 0) {
      this.currentPage = this.types[0];
    }
  }
}
