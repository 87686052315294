<div class="screen">
  <div class="screen-container">
    <div class="screen-body flex-column">
      <ng-container>
        <div class="icon-container">
          <i class="fa fa-paper-plane"></i>
        </div>
        <div class="body-header">
          <h5>Invite User</h5>
        </div>
        <div *ngIf="invite['status'] == 'SENT'">
          <div class="flex-start alert alert-success">
            <i class="fa fa-check-circle"></i>
            <div>{{ invite['message'] }}</div>
          </div>
        </div>
        <div *ngIf="invite['status'] == 'FAILED'">
          <div class="flex-start alert alert-danger">
            <i class="fa fa-times-circle"></i>
            <div>{{ invite['message'] }}</div>
          </div>
        </div>
        <div *ngIf="invite['status'] == 'PENDING' && ut == 'admin'">
          Inviting user will send an invitation link to admin user on their mailbox
        </div>
        <div *ngIf="invite['status'] == 'PENDING' && ut != 'admin'">
          <div class="flex-start alert alert-danger">
            <i class="fa fa-times-circle"></i>
            <div>You are not authorized to perform this action. please contact admin</div>
          </div>
        </div>
      </ng-container>
      <div class="flex-start" *ngIf="!loading">
        <button class="btn btn-primary-soft" (click)="inviteUser()" *ngIf="invite['status'] == 'PENDING' && ut == 'admin'">Invite</button>
        <button class="btn btn-light" (click)="hideModel.emit()">Cancel</button>
      </div>
      <ng-container *ngIf="loading">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
      </ng-container>
    </div>
  </div>
</div>