<h1 class="card-title">Delta Report</h1>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active" id="delta-report-tab" data-bs-toggle="tab" data-bs-target="#delta-report"
      type="button" role="tab" aria-controls="delta-report" aria-selected="true" (click)="load()">
      Delta Report
    </button>
    <button class="nav-link" id="bomvsused-tab" data-bs-toggle="tab" data-bs-target="#bomvsused" type="button"
      role="tab" aria-controls="bomvsused" aria-selected="false" (click)="loadUsageReport()">
      Bom vs Used
    </button>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="delta-report" role="tabpanel" aria-labelledby="delta-report-tab">
    <div class="card propVSactu" >
      <!-- <div class="card-header">
        <span>Additional Instances</span>
      </div> -->

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of deltaReportHeader">{{ h.name }}</th>
            </tr>
          </thead>

          <tbody *ngIf="deltaReportList.length">
            <tr *ngFor="let i of deltaReportList; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of deltaReportHeader">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="deltaReportList.length == 0">
            <tr>
              <td class="nodtaalign" [attr.colspan]="deltaReportHeader.length + 1">
                No Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="bomvsused" role="tabpanel" aria-labelledby="bomvsused-tab">
    <div class="card propVSactu">
      <div class="card-header">
        <span>Proposed vs Actual</span>
      </div>

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of proposedvsactualheaders">{{ h.name }}</th>
            </tr>
          </thead>

          <tbody *ngIf="proposedvsactualList.length > 0">
            <tr *ngFor="let i of proposedvsactualList; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of proposedvsactualheaders">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="proposedvsactualList.length == 0">
            <tr>
              <td class="nodtaalign" [attr.colspan]="proposedvsactualheaders.length + 1">
                No Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card propVSactu">
      <div class="card-header">
        <span>Additional Instances</span>
      </div>

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of additionalInstancesHeader">{{ h.name }}</th>
            </tr>
          </thead>

          <tbody *ngIf="additionalInstancesList.length > 0">
            <tr *ngFor="let i of additionalInstancesList; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of additionalInstancesHeader">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="additionalInstancesList.length == 0">
            <tr>
              <td class="nodtaalign" [attr.colspan]="additionalInstancesHeader.length + 1">
                No Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>