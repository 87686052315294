import { Component, OnDestroy, OnInit, NgZone } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";

declare let window: any;
declare let $: any;
@Component({
  //moduleId: module.id.toString(),
  styleUrls: ["./upload.bom.component.css"],
  templateUrl: "./upload.bom.component.html",
})
export class UploadBOMComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  accountId: any;
  regionId: any;
  currentMessage: any;
  bomFile: any;
  fileExists: boolean = false;
  mappedData: any = [];
  stepCount: number = 1;
  deleteBOM: any;
  selectedBOM: any;
  billingTypes: any = [];

  headerList: any = [];

  headers: any = [
    {
      id: "os",
      name: "OS",
    },
    { id: "instanceType", name: "Instance Type" },
    { id: "cpu", name: "CPU" },
    {
      id: "disk",
      name: "Disk",
    },
    {
      id: "quantity",
      name: "Quantity",
    },
    {
      id: "ram",
      name: "Ram",
    },
    {
      id: "billingType",
      name: "Billing Type",
    },
    {
      id: "usageHours",
      name: "Usage in Hours",
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
      } else if (d.key == "regionId") {
        this.regionId = d.value;
      }
    });
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.addToFileList(docName, fileSize, targetDocBase64),
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async convertFiles(file: any) {
    let promises: any = [];
    this.notifier.loading(true);
    let filePromise = new Promise(async (resolve: any, reject: any) => {
      var fileToLoad = file;

      var fileReader = new FileReader();
      var base64: any;
      let fileName = file.name;
      let fileSize = file.size;
      fileReader.onload = (fileLoadedEvent: any) => {
        base64 = fileLoadedEvent.target.result;
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn(
            fileName,
            fileSize,
            base64
          );
        });
        resolve({ fileName: fileName, fileSize: fileSize, data: base64 });
      };

      fileReader.readAsDataURL(fileToLoad);
      return;
    });
    Promise.all(promises).then((fileContents) => {
      this.notifier.loading(false);
    });
  }

  async getFile(file: any) {
    await this.convertFiles(file);
  }

  niceBytes(x: any) {
    const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  addToFileList(docName: string, fileSize: any, base64Data: any) {
    this.bomFile = {
      fileName: docName,
      fileSize: fileSize,
      data: base64Data,
    };
  }

  uploadFileError(event: any) {
    if (event) {
      this.uploadFile(true);
    }
    this.fileExists = false;
  }

  async setFile(event: any) {
    await this.convertFiles(event.target.files[0]);
  }

  hideModel(event: any) {
    if (event) {
      this.mappedData.push(event);
    }
    this.selectedBOM = null;
  }

  addBOMEntry() {
    this.selectedBOM = {
      instanceType: "",
      os: "",
      cpu: "",
      disk: "",
      quantity: "",
      uuid: window.uuidv4(),
      ram: "",
      billingType: "",
      usageHours: "",
    };
  }

  async uploadFile(replace: boolean = false) {
    this.notifier.loading(true);
    let data = {
      base64: this.bomFile.data,
      fileName: this.bomFile.fileName,
      accountId: this.accountId,
      replaceExisting: replace,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/uploadfile`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.msg == "Already File Exists.") {
        this.fileExists = true;
      } else if (result.msg.toLowerCase() == "y") {
        setTimeout(() => {
          this.fetchHeaders();
        }, 1000);
        this.notifier.alert(
          "Success",
          "",
          "File Uploaded Successfully!!",
          "success",
          5000
        );
      } else {
        this.notifier.alert("Warning", "", result.msg, "error", 5000);
      }
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async fetchHeaders() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: "headersList",
      fileName: this.bomFile.fileName,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      let dt = result.bomHeadersList;
      this.headerList = dt.map((haeder: any) => {
        return { headerKey: haeder, headerValue: "none" };
      });
      this.stepCount = 2;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    return;
  }

  async submitHeaders() {
    this.notifier.loading(true);
    let data = {
      accountId: "398665278814",
      action: "mapHeaders",
      fileName: "SREI_BOM_Details.csv",
      headerMapList: this.headerList
        .filter((header: any) => {
          if (header.headerValue != "none" && header.headerValue != "") {
            return true;
          } else {
            return false;
          }
        })
        .map((header: any) => {
          return {
            headerKey: header.headerValue,
            headerValue: header.headerKey,
          };
        }),
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      setTimeout(async () => {
        await this.fetchMappedData();
        this.stepCount = 3;
      }, 1000);
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  deleteDetail(i: any) {
    this.mappedData.splice(i, 1);
  }

  triggerUpload() {
    $("#bomFile").click();
  }

  async fetchMappedData() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: "fetchBOMMappedDetails",
      fileName: this.bomFile.fileName,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.mappedData = result.BOMDetailsList;
      if (result.msg == "Data not found") {
        this.notifier.alert("Error", "", result.msg, "error", 5000);
      } else {
        await this.fetchBillingTypes();
      }
    } else {
      this.stepCount = 1;
      this.notifier.alert(
        "Error",
        "",
        result.msg + ", Please Try Again",
        "error",
        5000
      );
    }

    this.notifier.loading(false);
  }

  async fetchBillingTypes() {
    this.notifier.loading(true);
    let data = { accountId: this.accountId, action: "fetchBillingType" };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.billingTypes = result.billingTypeList;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }

  async submitBOMDetails() {
    this.notifier.loading(true);
    let data: any = {
      accountId: this.accountId,
      action: "saveBOMDetails",
      bomDetailsList: this.mappedData,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.stepCount = 1;
      this.notifier.alert(
        "Success",
        "",
        result.msg + ', <a href="/dash/bom/view">View BOM Details</a>',
        "success",
        -1
      );
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }
}
