import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { iif } from "rxjs";

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./findings.component.html",
  styleUrls: ["./findings.component.css"],
})
export class FindingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  iamCheckList: any = [];
  resources: any = [];
  resourceSort: string = "All";
  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  resourceGroup = localStorage.getItem("selectedResourceGroup");
  selectedCloudPartner: string = localStorage.getItem("cp-selected");
  currentMessage: any;
  scans: any = [];
  selectedScan: any = null;
  headers: any = [
    {
      id: "Description",
      name: "Description",
      sort: { sort: true, direction: "Asc" },
      filter: true,
    },
    {
      id: "Result",
      name: "Status",
      color: true,
      sort: { sort: true, direction: "Asc" },
    },
    {
      id: "Category",
      name: "Category",
      sort: { sort: true, direction: "Asc" },
    },
    {
      id: "Severity",
      name: "Severity",
      sort: { sort: true, direction: "Asc" },
      alter: true,
    },
    {
      id: "failReason",
      name: "Fail Reason",
      sort: { sort: true, direction: "Asc" },
      filter: true,
    },
  ];
  showOffendesModal = false;
  offendeList: any;
  offendeListHeader: any = [
    {
      id: "offenders",
      name: "Offenders",
      filter: true,
    },
  ];
  offentpopTitle = "Offender List";
  audit_type: string = null;

  findings: any = [
    { audit_type: "iam", title: "IAM" },
    { audit_type: "ec2", title: "EC2" },
    { audit_type: "rds", title: "RDS" },
    { audit_type: "lambda", title: "Lambda" },
    { audit_type: "kms", title: "KMS" },
    { audit_type: "cft", title: "Cloud formation" },
    { audit_type: "dynamo", title: "DynamoDB" },
    { audit_type: "cloudtrail", title: "Cloudtrail" },
    { audit_type: "cloudwatch", title: "Cloudwatch" },
    { audit_type: "cloudfront", title: "Cloudfront" },
    { audit_type: "apigateway", title: "API Gateway" },
    { audit_type: "ses", title: "SES" },
    { audit_type: "s3", title: "S3" },
    { audit_type: "sg", title: "Security Groups" },
    { audit_type: "kube", title: "EKS" },
    { audit_type: "es", title: "Elastic Search" },
    { audit_type: "sns", title: "SNS" },
    { audit_type: "redshift", title: "Redshift" },
    { audit_type: "elb", title: "ELB" },
    { audit_type: "route53", title: "Route 53" },
    // { audit_type: "iam_audit", title: "IAM", cp: 'azure' },
    { audit_type: "vm_audit", title: "VM", cp: "azure" },
    {
      audit_type: "load_balancer_audit",
      title: "Load Balancer",
      cp: "azure",
    },
    { audit_type: "network_audit", title: "Network", cp: "azure" },
    { audit_type: "blob_audit", title: "Blob", cp: "azure" },
    {
      audit_type: "app_service_audit",
      title: "App Service",
      cp: "azure",
    },
    { audit_type: "aks_audit", title: "AKS", cp: "azure" },
    {
      audit_type: "list_all_cosmodb_audit",
      title: "List All CosmoDB",
      cp: "azure",
    },
    { audit_type: "sql_audit", title: "SQL", cp: "azure" },
    { audit_type: "synapse_audit", title: "Synapse", cp: "azure" },
    { audit_type: "dns_audit", title: "DNS", cp: "azure" },
    {
      audit_type: "key_vault_audit",
      title: "Key Vault",
      cp: "azure",
    },
    { audit_type: "monitor_audit", title: "Monitor", cp: "azure" },
    {
      audit_type: "log_alert_audit",
      title: "Log Alert",
      cp: "azure",
    },
    { audit_type: "storage_audit", title: "Storage", cp: "azure" },
    { audit_type: "security_center_audit", title: "Security Center", cp: "azure" },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    public route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.resourceGroup = localStorage.getItem("selectedResourceGroup");

    this.route.queryParams.subscribe((params: any) => {
      this.resourceGroup = localStorage.getItem("selectedResourceGroup");
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (params["audit_type"] && params["audit_type"] != "") {
        this.audit_type = params["audit_type"];
        this.load();
      } else {
        this.audit_type = null;
      }
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.iamCheckList = [];
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }
      let finding: any = this.getFinding();
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId" && finding && finding["cp"] != "azure") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup" && finding && finding["cp"] == "azure") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
  }

  getFinding() {
    let temp = this.findings.find((finding: any) => {
      return finding["audit_type"] == this.audit_type;
    });
    if (temp) {
      return temp;
    } else {
      window.location.href = "/dash/home";
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

  resourceText: string = "";

  preventDefault(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  showResources: boolean = false;
  getResources() {
    if (this.resourceText.trim() == "") {
      return this.resources;
    }
    return this.resources.filter((resource: any) => {
      return (
        resource &&
        resource.name.toLowerCase().indexOf(this.resourceText.toLowerCase()) >
          -1
      );
    });
  }

  closeResources(event: any) {
    if (event) {
      this.resourceSort = event;
      if (event !== "All") {
        this.filter = "Failed";
      }
      this.fetchIAMCheckList();
    }
    this.showResources = false;
  }

  scanDetails: any = null;
  fetching: Boolean = true;
  uuid: string = "";
  notValid: boolean = false;
  async load() {
    this.uuid = window.uuidv4();
    this.resourceSort = "All";
    this.resourceText = "";
    this.showResources = false;
    this.filter = "All";
    this.scans = [];
    this.selectedScan = null;
    this.scanDetails = null;
    this.fetching = true;
    let finding: any = this.getFinding();
    if (finding["cp"] && finding["cp"] != this.selectedCloudPartner) {
      this.notValid = true;
      this.fetching = false;
      return;
    }
    this.notValid = false;
    this.fetching = true;
    (this.filter = "All"), (this.resourceSort = "All");
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/audit/ec2`;
    let data: any;

    if (finding["cp"] != "azure") {
      data = {
        accountId: this.accountId,
        audit_type: `/audit/${finding["audit_type"]}`,
        region_id: this.regionId,
        action: "list_scans",
      };
    } else if (finding["cp"] == "azure") {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/azure/governance`;

      data = {
        audit_type: finding["audit_type"],
        region_id: this.regionId,
        action: "list_scans",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
      };
    }

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result["statusCode"] == 200 || finding["cp"] == "azure") {
      this.scans = result["scans"] || [];
      this.scans = this.scans
        .sort((a: any, b: any) => {
          let dateTemp_a: any = new Date(a["REQUESTED_AT"] || a["STARTED_AT"]);
          let dateTemp_b: any = new Date(b["REQUESTED_AT"] || a["STARTED_AT"]);
          return Date.parse(dateTemp_b) - Date.parse(dateTemp_a);
        })
        .map((scan: any) => {
          scan.scanId = scan.scanId || scan["SCAN_ID"];
          scan["REQUESTED_AT"] = moment(
            scan["REQUESTED_AT"] || scan["STARTED_AT"]
          ).format("MMM DD, 2023 hh:mm A");
          return scan;
        });
      this.scans.forEach((scan: any) => {
        if (scan["SCAN_STATUS"] != "COMPLETE") {
          setTimeout(() => {
            this.loadScan(scan, false, this.uuid);
          }, 100);
        }
      });
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.message || result.error_message,
        "info",
        5000
      );
    }

    this.fetchIAMCheckList();

    this.fetching = false;
  }

  createScan: boolean = false;

  close(event: boolean) {
    if (event) {
      this.load();
    }

    this.createScan = false;
  }

  overview: any = {
    total: 0,
    passed: 0,
    failed: 0,
    failed_high_risk: 0,
    failed_medium_risk: 0,
  };
  destroyed: boolean = false;

  async loadScan(scan: any, reset: boolean = true, uuid: string) {
    if (reset) {
      this.resourceSort = "All";
      this.resourceText = "";
      this.showResources = false;
      this.filter = "All";
      this.selectedScan = scan;
      this.iamCheckList = [];
      this.resources = [];
      this.scanDetails = null;
      this.notifier.loading(true);
      this.fetching = true;
      this.overview = {
        total: 0,
        passed: 0,
        failed: 0,
        failed_high_risk: 0,
        failed_medium_risk: 0,
      };
    }
    let finding: any = this.getFinding();
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/audit/status`;
    let data: any;
    if (finding["cp"] != "azure") {
      data = {
        scanId: (scan.scanIds && scan.scanIds[0]) || scan.scanId,
        audit_type: `/audit/${finding["audit_type"]}`,
        accountId: this.accountId,
      };
    } else if (finding["cp"] == "azure") {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/azure/governance`;
      data = {
        audit_type: finding["audit_type"],
        region_id: this.regionId,
        action: "scan_status",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
        scan_id:
          (scan.scanIds && scan.scanIds[0]) || scan.scanId || scan["SCAN_ID"],
      };
    }

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    if(this.destroyed) {
      return;
    }

    let status = await this.apiServer.postDataPromis(apiURL, data, header);

    if (finding["cp"] == "azure") {
      status = { ...status, ...(status["response"] || {}) };
    }

    if (this.uuid != uuid) {
      return;
    }

    if (!reset) {
      if (status["statusCode"] == 200 || finding["cp"] == "azure") {
        scan["SCAN_STATUS"] = status["scanStatus"] || status["status"];
        if (scan["SCAN_STATUS"] != "COMPLETE") {
          setTimeout(() => {
            this.loadScan(scan, false, uuid);
          }, 5000);
        }
      }
      return;
    }

    if (!this.selectedScan) {
      return;
    }

    status.startedAt = status.startedAt || status["started_at"];
    status.signedURL = status.signedURL || status["signed_url"];
    status["scanStatus"] = status["scanStatus"] || status["status"];
    this.scanDetails = status;
    if (this.scanDetails.startedAt) {
      this.scanDetails.startedAt = moment(this.scanDetails.startedAt).format(
        "DD/MM/YYYY hh:mm A"
      );
    }

    if (status.hasOwnProperty("signedURL") && status.signedURL != "") {
      apiURL = status["signedURL"];

      let offenders = await this.apiServer.getDataPromis(apiURL, {}, {});

      if (!Array.isArray(offenders)) {
        this.iamCheckList = offenders["data"];
        this.scanDetails["resource_found"] =
          offenders["resource_found"] == undefined
            ? true
            : offenders["resource_found"];
      } else {
        this.iamCheckList = offenders;
        this.scanDetails["resource_found"] = true;
      }

      if (finding["cp"] == "azure") {
        this.iamCheckList = this.iamCheckList.map((iam: any) => {
          iam["Description"] = iam["description"];
          iam["Result"] = iam["result"];
          iam["Severity"] = iam["severity"];
          iam["Category"] = iam["category"];
          iam["failReason"] = iam["fail_reason"];
          iam["Offenders"] = iam["offenders"];

          if (iam["Result"] == "true") {
            iam["Offenders"] = [];
          }
          return iam;
        });
      }

      this.overview = {
        total: this.iamCheckList.length,
        passed: this.iamCheckList.filter((iam: any) => {
          return iam.Result == "true";
        }).length,
        failed: this.iamCheckList.filter((iam: any) => {
          return iam.Result == "false" || iam.Result.toLowerCase() == "manual";
        }).length,
        failed_high_risk: this.iamCheckList.filter((iam: any) => {
          return (
            (iam.Severity == "High" || iam.Severity == "Very High") &&
            (iam.Result == "false" || iam.Result.toLowerCase() == "manual")
          );
        }).length,
        failed_medium_risk: this.iamCheckList.filter((iam: any) => {
          return (
            iam.Severity == "Medium" &&
            (iam.Result == "false" || iam.Result.toLowerCase() == "manual")
          );
        }).length,
      };

      this.iamCheckList = this.iamCheckList.map((iam: any) => {
        if (iam["Offenders"]) {
          iam["Offenders"].forEach((offender: any) => {
            let find = this.resources.find((resource: any) => {
              return resource["name"] == offender;
            });
            if (find) {
              find["count"] += 1;
            } else {
              this.resources.push({ name: offender, count: 1 });
            }
          });
        }
        if (
          (iam.Severity == "High" || iam.Severity == "Very High") &&
          (iam.Result == "false" || iam.Result.toLowerCase() == "manual")
        ) {
          iam["index"] = 1;
        }
        if (
          iam.Severity == "Medium" &&
          (iam.Result == "false" || iam.Result.toLowerCase() == "manual")
        ) {
          iam["index"] = 2;
        }
        if (
          iam.Severity == "Low" &&
          (iam.Result == "false" || iam.Result.toLowerCase() == "manual")
        ) {
          iam["index"] = 3;
        }
        if (
          (iam.Severity == "High" || iam.Severity == "Very High") &&
          iam.Result == "true"
        ) {
          iam["index"] = 4;
        }
        if (iam.Severity == "Medium" && iam.Result == "true") {
          iam["index"] = 5;
        }
        if (iam.Severity == "Low" && iam.Result == "true") {
          iam["index"] = 6;
        }
        return iam;
      });
      this.iamCheckList = this.iamCheckList.sort((a: any, b: any) => {
        return a.index - b.index;
      });
      this.notifier.loading(false);
    } else if (status.scanStatus != "COMPLETE" && status.signedURL == "") {
      this.notifier.loading(false);
    } else if (status.scanStatus == "Submitted") {
      this.notifier.loading(false);
    }

    this.fetchIAMCheckList();

    this.fetching = false;
  }

  downloadReport() {
    let element = document.createElement("table");
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${this.headers.map((h: any) => {
              return `<th>${h.name}</th>`;
            })}
            <td>Offenders</td>
          </tr>
        </thead>
        <tbody>
            ${this.iamCheckList.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${this.headers.map((h: any) => {
                    return `<td>
                      ${this.checkforBool(ec2[h.id], h.id)}
                    </td>`;
                  })}
                  <td>
                    ${ec2["Offenders"].join(", ")}
                  </td>
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `${this.getFinding()["title"]} Compliance - ${this.accountId}`,
      "csv"
    );
  }

  callFunction(name: any, param: any) {
    return;
  }

  addFilter(key: string = "All", resource_filter: boolean = false) {
    this.filter = key;
    if (resource_filter) {
      this.resourceSort = "All";
    }
    this.fetchIAMCheckList();
  }

  filter: string = "All";

  removeFilter(resource_filter: boolean = false) {
    this.filter = "All";
    if (resource_filter) {
      this.resourceSort = "All";
    }
    this.fetchIAMCheckList();
  }

  sortedIAMCheckList: any = [];
  fetchIAMCheckList() {
    let data: any = [];
    if (this.filter == "All") {
      data = this.iamCheckList;
    }
    if (this.filter == "Passed") {
      data = this.iamCheckList.filter((iam: any) => {
        return iam["Result"] == "true";
      });
    }
    if (this.filter == "Failed") {
      data = this.iamCheckList.filter((iam: any) => {
        return iam.Result == "false" || iam.Result.toLowerCase() == "manual";
      });
    }
    if (this.filter == "High Risk") {
      data = this.iamCheckList.filter((iam: any) => {
        return (
          (iam.Severity == "High" || iam.Severity == "Very High") &&
          (iam.Result == "false" || iam.Result.toLowerCase() == "manual")
        );
      });
    }
    if (this.filter == "Medium Risk") {
      data = this.iamCheckList.filter((iam: any) => {
        return (
          iam.Severity == "Medium" &&
          (iam.Result == "false" || iam.Result.toLowerCase() == "manual")
        );
      });
    }

    if (this.resourceSort != "All") {
      data = data.filter((iam: any) => {
        return (
          iam["Offenders"] && iam["Offenders"].indexOf(this.resourceSort) > -1
        );
      });
    }

    this.sortedIAMCheckList = data;
  }

  checkforBool(value: any, key: any): String {
    if (key == "Result") {
      return value == "true" || value == true
        ? "Pass"
        : value == "false" || value == false
        ? "Fail"
        : value;
    } else {
      return value;
    }
  }

  showOffendes(offendeList: any) {
    this.showOffendesModal = true;

    var dt = [];
    for (var i = 0; i < offendeList.length; i++) {
      dt.push({ offenders: offendeList[i] });
    }

    this.offendeList = dt;
  }

  hidePop(e: any) {
    this.showOffendesModal = false;
    this.offendeList = [];
  }
}
