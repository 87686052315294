import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { regions } from "src/app/dash/dash.component";

@Component({
  selector: "app-s3-configure-cur",
  templateUrl: "./s3-configure-cur.component.html",
  styleUrls: ["./s3-configure-cur.component.css"],
})
export class S3ConfigureCurComponent implements OnInit {
  @Output("hideModal") hideModal = new EventEmitter<boolean>();
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  details: any = {
    bucketName: "",
    bucketPrefix: "",
    region: "",
  };

  enableCUR: boolean = false;

  regions: any = regions["enabled"];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  loading: boolean = false;

  ngOnInit(): void {
    if (this.regions.length > 0) {
      this.details["region"] = this.regionId;
    }
  }

  errors: any = {
    bucketName: {
      error: false,
      message: "Bucket Name is a mandatory field",
    },
    bucketPrefix: {
      error: false,
      message: "Bucket Prefix is a mandatory field",
    },
    region: {
      error: false,
      message: "Region is a mandatory field",
    },
  };

  validation(): boolean {
    let check: boolean = true;
    Object.keys(this.errors).forEach((error: any) => {
      if (!this.details[error] || this.details[error].trim() == "") {
        this.errors[error]["error"] = true;
        check = false;
      } else {
        this.errors[error]["error"] = false;
      }
    });

    return check;
  }

  error: string = null;

  async validate() {
    this.error = null;
    if (!this.validation()) {
      return;
    }
    this.loading = true;
    let data: any = {
      a: "add",
      accountId: this.accountId,
      bucketName: this.details["bucketName"],
      prefix: this.details["bucketPrefix"],
      region: this.details["region"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinguser`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.hideModal.emit(false);
      this.notifier.alert(
        "Success",
        "",
        "S3 is configured successfully.",
        "success",
        5000
      );
    } else {
      this.error = result.error;
    }

    this.loading = false;
  }
}
