import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../../api/api.service";
import { NotifierService } from "../../../../_services/notifier.service";
import { Router } from "@angular/router";

declare let window: any;
declare let Jhxlsx: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./view.user.component.html",
  styleUrls: ["./view.user.component.css"],
})
export class ViewUserSettingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  userList: any = [];

  funRef: any = {
    selectUser: this.selectUser,
    updateUserActionCaller: this.updateUserActionCaller,
  };

  filterText: string = "";

  selectedUser: any = null;

  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin" ||
    this.isUserAdmin;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;

  loading: boolean = true;

  groupByAdmins: boolean = false;

  userHeaders: any = [
    {
      id: "clientName",
      name: "Client Name",
    },
    { id: "emaiId", name: "Email ID", click: "selectUser" },
    {
      id: "mobileNumber",
      name: "Phone Number",
    },
    {
      id: "accountGroup",
      name: "AWS Account Groups",
    },
  ];

  adminUsersheader: any = [
    {
      id: "userName",
      name: "User Name",
      click: "updateUserActionCaller",
    },
    {
      id: "userId",
      name: "User ID",
    },
    {
      id: "cmpName",
      name: "Company Name",
    },
    {
      id: "phNumber",
      name: "Phone Number",
    },
    {
      id: "accountGroup",
      name: "Account Groups",
    },
  ];

  userTypes: any = {
    userAdmin: "Admin",
    user: "General",
    superAdmin: "Master Admin",
  };

  updateUser: any;
  userAction: string;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private navigator: Router
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  updateUserActionCaller(ref: any, value: any) {
    ref.updateUserAction(value);
  }

  viewAudit(user: any) {
    this.navigator.navigate(['/dash/settings/audittrail'], {queryParams: {user_id: user['userId'] || user['emaiId'], source: 'user_details'}})
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  selectUser(ref: any, value: any) {
    ref.selectedUser = value;
  }

  filteredUserList() {
    return this.userList.filter((user: any) => {
      let check: boolean = true;
      if (this.groupByAdmins) {
        check =
          user["userType"] == "userAdmin" || user["userType"] == "superAdmin";
      }
      return (
        (user["clientName"].toLowerCase().indexOf(this.filterText) > -1 ||
          user["emaiId"].toLowerCase().indexOf(this.filterText) > -1) &&
        check
      );
    });
  }

  export() {
    var dict: any = {
      sheetName: "Budgets",
      data: [],
    };
    let headers: any = [
      { text: "Sl. No." },
      { text: "Client Name" },
      { text: "Email ID" },
      { text: "Phone Number" },
      { text: "AWS Account Groups" },
      { text: "User Type" },
      { text: "Created By" },
    ];
    dict.data.push(headers);
    this.filteredUserList().forEach((user: any, index: number) => {
      let data = [];
      data.push({ text: index + 1 });
      data.push({ text: user["clientName"] });
      data.push({ text: user["emaiId"] });
      data.push({ text: user["mobileNumber"] });
      data.push({
        text:
          user["accountDetailsList"] && user["accountDetailsList"].length > 0
            ? user["accountDetailsList"]
                .map((account: any) => {
                  return account["accountId"];
                })
                .join(", ")
            : "-",
      });
      data.push({ text: this.userTypes[user["userType"]] });
      data.push({ text: user["masterClientId"] });
      dict.data.push(data);
    });
    var options = {
      fileName: `Users - ${this.userId}`,
    };
    Jhxlsx.export([dict], options);
  }

  async sendResendLink(user: any) {
    user["resend"] = true;
    let data: any = {
      action: "resendRegistrationLink",
      clientEmailID: user["emaiId"],
      clientName: user["clientName"],
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/resendlink`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      this.notifier.alert("Success", "", result.msg, "success", -1);
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    user["resend"] = false;
  }

  async load() {
    this.notifier.loading(true);
    this.userList = [];
    this.selectedUser = null;
    this.loading = true;
    let data: any = {
      clientId: this.userId,
      action:
        !this.groupByAdmins && this.ut == "admin" && this.urlPrefix == "client"
          ? "fetchAllUsers"
          : "fetchAllClientUsers",
      ut: this.urlPrefix,
      userType:
        localStorage.getItem("isUserAdmin") == "1"
          ? "userAdmin"
          : localStorage.getItem("ut"),
    };

    if (this.urlPrefix == "admin") {
      data = { userId: this.userId };
    }

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/${
      this.urlPrefix == "client" ? "invite" : "list"
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      if (
        this.ut == "admin" &&
        this.urlPrefix == "client" &&
        this.groupByAdmins
      ) {
        let accounts: any = localStorage.getItem("accountIdData");
        if (accounts) {
          accounts = JSON.parse(accounts)["list"];
        }
        result.cl.unshift({
          clientId: window.uuidv4(),
          clientName: localStorage.getItem("un"),
          clientCmpName: localStorage.getItem("com"),
          emaiId: this.userId,
          mobileNumber: localStorage.getItem("ph"),
          userType: "superAdmin",
          accountDetailsList: accounts
            ? accounts.map((account: any) => {
                return {
                  accountId: account.accountId,
                  accountName: account.accountName,
                };
              })
            : [],
          policyStatus: false,
          mappedAccounts: 0,
          otpReq: localStorage.getItem("otpReq") == "1",
          tagsEnabled: false,
          masterClientId: this.userId,
          accountStatus: "active",
        });
      }

      this.userList = result.cl || result.details;
      this.userList = this.userList.map((user: any) => {
        if (this.urlPrefix == "admin") {
          user["clientName"] = user["userName"];
          user["emaiId"] = user["userId"];
          user["accountGroup"] = user.accountGroup.join(", ");
        } else {
          user["accountGroup"] = user.accountDetailsList
            .map((account: any) => {
              return account.accountId;
            })
            .join(", ");
        }
        return user;
      });
    } else {
      this.notifier.alert("Info", "", result.error || result.m, "info", 5000);
    }

    this.notifier.loading(false);
    this.loading = false;
  }

  updateUserAction(group: any) {
    this.userAction = "update";
    this.updateUser = group;
  }

  async hideUpdateUser(event: any) {
    this.updateUser = null;
    if (event) {
      await this.load();
    }
  }

  createUser() {
    this.userAction = "add";
    if (this.urlPrefix == "client") {
      this.updateUser = {
        clientId: window.uuidv4(),
        clientName: "",
        clientCmpName: localStorage.getItem("com"),
        emaiId: "",
        mobileNumber: "",
        accessType: "readOnly",
        userType: "user",
        accountDetailsList: [],
        otpReq: true,
        policyStatus: false,
      };
    } else {
      this.updateUser = {
        userName: "",
        userId: "",
        phNumber: "",
        cmpName: localStorage.getItem("com"),
        userType: "user",
        accountGroup: [],
      };
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
