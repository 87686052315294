<div class="card">
  <h5 class="card-header flex-start">
    <a [routerLink]="'/dash/reports/home'" class="default-link">
      <button class="btn btn-light flex-start">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span>Reports</span>
      </button>
    </a>
    <span>
      MIS Report
    </span>
  </h5>
  <div class="card-body">
    <div class="card-dply">
      <div>
        <select [(ngModel)]="frequency" (change)="frequencyChange()" class="form-control width-300">
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <div [ngStyle]="{'display': frequency == 'weekly' ? 'block': 'none'}">
        <input autocomplete="off" class="dayDate form-control" type="text" id="dayDate" [(ngModel)]="dateRange" />
      </div>
      <div [ngStyle]="{'display': frequency == 'monthly' ? 'block': 'none'}">
        <input autocomplete="off" class="monthpicker form-control" type="text" id="monthDate" [(ngModel)]="dateRange" />
      </div>
    </div>

    <div class="card border-none">
      <div class="card-header">
        <span>Summary of Daily Tickets Raised</span>
      </div>

      <div class="card-body">
        <div class="crd-dply" *ngIf="tickets.length > 0">
          <button class="btn btn-light btn-styl" (click)="exportTickets()">Export</button>
        </div>
        <table class="table table-docs table-hover" id="ticketsTable">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of ticketHeader">{{ h.name }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let i of tickets; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of ticketHeader">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="div-sty" *ngIf="nextExists">
          <button class="btn btn-light btn-styl" (click)="loadTickets()">Next</button>
        </div>
      </div>
    </div>
    <div class="card border-none" [ngStyle]="{'display': frequency == 'daily' ? 'block' : 'none'}">
      <div class="card-header">
        <span>Daily Backup Report</span>
      </div>

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->
        <div class="crd-dply" *ngIf="instances.length > 0">
          <button class="btn btn-light btn-styl" (click)="exportDailyBackup()">Export</button>
        </div>

        <table class="table table-docs table-hover" id="dailyBackupTable">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of instancesHeader">{{ h.name }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let i of instances; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of instancesHeader">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>