import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";
declare let $: any;
declare let window: any;
@Component({
  selector: "app-cost-anamoly-report",
  templateUrl: "./cost-anamoly-report.component.html",
  styleUrls: ["./cost-anamoly-report.component.css"],
})
export class CostAnamolyReportComponent implements OnInit, OnDestroy {
  @Input("mid") mid: any;
  @Input("lid") lid: any;
  @Output("close") close = new EventEmitter<boolean>();
  customerType: string = localStorage.getItem("customerType");
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  summary: any = [];
  currentPage: string = "cost-variance-report";
  analytics: any;
  activeAccount: any = null;
  startDays: string;
  endDays: string;
  dateRange: string;
  totalBill: number = 0.0;
  billingOf: string = "ec2";
  anomalyReport: any = [];
  funRef: any;
  statusTab: boolean = false;
  accountId: any;
  regionId: any;
  currentMessage: any;
  masterAccounts: any = [];
  downloadAll: any = null;
  isService = true;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    if (this.customerType !== "mp") {
      $("#masterAccountSelect").selectpicker();
    }

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.masterAccounts = [];
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.anomalyReport = [];
        this.masterAccounts = [];
        this.startDays = "";
        this.endDays = "";
        this.dateRange = "";
        this.totalBill = 0.0;
        this.isService = true;
        if (this.customerType !== "mp") {
          setTimeout(() => {
            $("#masterAccountSelect").selectpicker("refresh");
          }, 100);
        }
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;

        this.summary = [];
        this.anomalyReport = [];
        this.masterAccounts = [];
        this.totalBill = 0.0;
        this.dateRange = "";
        this.isService = true;
        if (this.customerType !== "mp") {
          setTimeout(() => {
            $("#masterAccountSelect").selectpicker("refresh");
          }, 100);
        }
        this.load();
      }
    });
  }

  async checkForExistingClient() {
    let data: any = { a: "validate", accountId: this.accountId };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinguser`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.existingCustomer) {
        this.customerType = "C";
      } else {
        this.customerType = "mp";
      }
    }
  }

  async load() {
    this.customerType = localStorage.getItem("customerType");
    this.notifier.loading(true);
    this.analytics = null;
    if (this.customerType == "mp") {
      await this.checkForExistingClient();
    }
    if (this.lid) {
      this.accountId = this.lid;
    }
    await this.fetchMasterAccounts();
  }

  apiCaller(value: any) {
    this.getAnomalyData(value);
    //this.fetchRangeBillData(value);
  }

  async fetchMasterAccounts(caller: boolean = true) {
    if (this.masterAccounts.length > 0 || this.customerType === "mp") {
      this.apiCaller(
        this.customerType !== "mp" ? $("#masterAccountSelect").val() : undefined
      );
      return;
    }
    this.notifier.loading(true);
    let data = {
      lid: [this.accountId],
      userType: this.customerType !== "mp" ? "C" : undefined,
      action: "fetchMasterAccList",
      ut: this.customerType || undefined,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    if (this.customerType == "mp") {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.isService = true;
      this.masterAccounts = result.masterAccList;
      setTimeout(async () => {
        $("#masterAccountSelect").selectpicker("refresh");
        $("#masterAccountSelect").val(this.masterAccounts[0]);
        $("#masterAccountSelect").selectpicker("refresh");
        if (caller) {
          await this.apiCaller(this.masterAccounts[0]);
        }
      }, 100);
    } else {
      this.summary = [];
      this.activeAccount = null;
      this.notifier.alert("Error", "", result.msg, "error", 5000);
      this.isService = false;
      this.notifier.loading(false);
    }
  }

  hide() {
    this.close.emit(true);
  }

  //------------------------------
  async getAnomalydatabyselect(val: any) {
    this.notifier.loading(true);
    let masterid = (val.target as HTMLSelectElement).value;

    let data = {
      a: "getreport",
      lid: this.accountId,
      mid: masterid,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/anomalyreport`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || (result.s == "1" && result.msg == "success")) {
      try {
        this.anomalyReport = result["report"];
        this.totalBill = this.sliceNumber(this.anomalyReport["totalBill"]);
        const [startDate, endDate] =
          this.anomalyReport["dateRange"].split(" - ");
        this.startDays = moment(startDate, "DD/MMM/YYYY").format("DD-MM-YY");
        this.endDays = moment(endDate, "DD/MMM/YYYY").format("DD-MM-YY");
        this.dateRange = `${moment(startDate, "DD/MMM/YYYY").format(
          "MMM D, YYYY"
        )} - ${moment(endDate, "DD/MMM/YYYY").format("MMM D, YYYY")}`;
      } catch (error) {}
      if (this.anomalyReport["services"].length == 0) {
        this.isService = false;
      } else {
        this.isService = true;
      }
    } else {
      this.isService = false;
    }

    this.notifier.loading(false);
  }
  async getAnomalyData(masterid: any) {
    this.notifier.loading(true);
    let data = {
      a: "getreport",
      lid: this.accountId,
      mid: masterid,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/anomalyreport`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || (result.s == "1" && result.msg == "success")) {
      try {
        this.anomalyReport = result["report"];
        this.totalBill = this.sliceNumber(this.anomalyReport["totalBill"]);
        const [startDate, endDate] =
          this.anomalyReport["dateRange"].split(" - ");
        this.startDays = moment(startDate, "DD/MMM/YYYY").format("DD-MM-YY");
        this.endDays = moment(endDate, "DD/MMM/YYYY").format("DD-MM-YY");
        this.dateRange = `${moment(startDate, "DD/MMM/YYYY").format(
          "MMM D, YYYY"
        )} - ${moment(endDate, "DD/MMM/YYYY").format("MMM D, YYYY")}`;
      } catch (error) {}
      if (this.anomalyReport["services"].length == 0) {
        this.isService = false;
      } else {
        this.isService = true;
      }
    } else {
      this.isService = false;
    }

    this.notifier.loading(false);
  }

  sliceNumber(value: number) {
    return Math.floor(value * 100) / 100;
  }

  viewStatusTab() {
    this.statusTab = !this.statusTab;
  }
  closeStatusTab(event: any) {
    this.statusTab = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
