<div class="card">
  <h5 class="card-header">
    <span class="btn btn-light" (click)="close(false)">
      < Back </span>
        Launch Instance
  </h5>
  <div class="card-body">
    <div>
      <div class="steps_container step_container_11">
        <div>
          <div class="step_container">
            <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 0}">

            </div>
            <div>
              <div class="step_icon" (click)="stepCount > 0 && stepCount = 0" [ngClass]="{passed_step: stepCount >= 0}">
                <i class="fa fa-upload" aria-hidden="true"></i>
              </div>
            </div>
            <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 0}">

            </div>
          </div>
          <div class="step_text" [ngClass]="{passed_step: stepCount >= 0}">
            Details
          </div>
        </div>
        <div>
          <div class="step_container">
            <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 1}">

            </div>
            <div>
              <div class="step_icon" (click)="stepCount > 1 && stepCount = 1" [ngClass]="{passed_step: stepCount >= 1}">
                <i class="fa fa-upload" aria-hidden="true"></i>
              </div>
            </div>
            <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 1}">

            </div>
          </div>
          <div class="step_text" [ngClass]="{passed_step: stepCount >= 1}">
            AMI and Instance Type
          </div>
        </div>
        <div>
          <div class="step_container">
            <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 2}">

            </div>
            <div>
              <div class="step_icon" (click)="stepCount > 2 && stepCount = 2" [ngClass]="{passed_step: stepCount >= 2}">
                <i class="fa fa-connectdevelop" aria-hidden="true"></i>
              </div>
            </div>
            <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 2}">

            </div>
          </div>
          <div class="step_text" [ngClass]="{passed_step: stepCount >= 2}">
            Network
          </div>
        </div>
        <div>
          <div class="step_container">
            <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 3}">

            </div>
            <div>
              <div class="step_icon" (click)="stepCount > 3 && stepCount = 3" [ngClass]="{passed_step: stepCount >= 3}">
                <i class="fa fa-usd" aria-hidden="true"></i>
              </div>
            </div>
            <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 3}">

            </div>
          </div>
          <div class="step_text" [ngClass]="{passed_step: stepCount >= 3}">
            Storage
          </div>
        </div>
        <div>
          <div class="step_container">
            <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 4}">

            </div>
            <div>
              <div class="step_icon" [ngClass]="{passed_step: stepCount >= 4}">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
            </div>
            <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 4}">

            </div>
          </div>
          <div class="step_text" [ngClass]="{passed_step: stepCount >= 4}">
            Tags and Key pair
          </div>
        </div>
      </div>
      <div *ngIf="stepCount == 0">
        <div class="step_main_container step_main_container_102">
          <div class="step_header">
            <div class="step_header_104">
              <i class="fa fa-upload" aria-hidden="true"></i>
              <span>Details</span>
            </div>
            <div class="step_1-5_109">
              Step 1 of 5
            </div>
          </div>
          <div class="step_body">
            <details-launch-instance [instance]="instance"></details-launch-instance>
          </div>
          <div class="step_footer">
            <div class="step_footer_116">
              <span class="btn_span"></span>
              <span class="btn_span loader_button upload_Bom_119" (click)="stepCount = 1"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_120" />
                </span>
                <span class="button_text">
                  Next : AMI and Instance Type
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="stepCount == 1">
        <div class="step_main_container stop_main_container_131">
          <div class="step_header">
            <div class="step_header_133">
              <i class="fa fa-upload" aria-hidden="true"></i>
              <span>AMI and Instance Type</span>
            </div>
            <div class="step_2-5_137">
              Step 2 of 5
            </div>
          </div>
          <div class="step_body">
            <amis-launch-instance [instance]="payload" [os]="instance"></amis-launch-instance>
          </div>
          <div class="step_footer">
            <div class="step_footer_145">
              <span class="btn_span loader_button span_loader_147" (click)="stepCount = 0"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span class="button_text">
                  Prev : Details
                </span>
              </span>
              <span class="btn_span loader_button btn_span_loader_152" (click)="validate()"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_153" />
                </span>
                <span class="button_text">
                  Next : Network
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="stepCount == 2">
        <div class="step_main_container step_main_container_164">
          <div class="step_header">
            <div class="network_upload_166">
              <i class="fa fa-upload" aria-hidden="true"></i>
              <span>Network</span>
            </div>
            <div class="step_3_5_170">
              Step 3 of 5
            </div>
          </div>
          <div class="step_body">
            <network-launch-instance [instance]="payload"></network-launch-instance>
          </div>
          <div class="step_footer">
            <div class="step_footer_178">
              <span class="btn_span loader_button btn_span_180" (click)="stepCount = 1"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span class="button_text">
                  Prev : AMI and Instance Type
                </span>
              </span>
              <span class="btn_span loader_button btn_span_loader_186" (click)="validate()"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_187" />
                </span>
                <span class="button_text">
                  Next : Storage
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="stepCount == 3">
        <div class="step_main_container step_container_198">
          <div class="step_header">
            <div class="storage_202">
              <i class="fa fa-upload" aria-hidden="true"></i>
              <span>Storage</span>
            </div>
            <div class="step_4_5_204">
              Step 4 of 5
            </div>
          </div>
          <div class="step_body">
            <storage-launch-instance [instance]="payload"></storage-launch-instance>
          </div>
          <div class="step_footer">
            <div class="step_footer_212">
              <span class="btn_span loader_button network_upload_216" (click)="stepCount = 2"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span class="button_text">
                  Prev : Network
                </span>
              </span>
              <span class="btn_span loader_button network_upload_220" (click)="stepCount = 4"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_222" />
                </span>
                <span class="button_text">
                  Next : Tags and Key Pair
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="stepCount == 4">
        <div class="step_main_container step_main_container_233">
          <div class="step_header">
            <div class="tags_235">
              <i class="fa fa-upload" aria-hidden="true"></i>
              <span>Tags and Key Pair</span>
            </div>
            <div class="step_5_5_239">
              Step 5 of 5
            </div>
          </div>
          <div class="step_body">
            <tagskeypair-launch-instance [instance]="payload"></tagskeypair-launch-instance>
          </div>
          <div class="step_footer">
            <div class="step_footer_274">
              <span class="btn_span loader_button btn_span_249" (click)="stepCount = 3"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span class="button_text">
                  Prev : Storage
                </span>
              </span>
              <span class="btn_span loader_button btn_span_249" (click)="validate()"
                [ngClass]="{dim_button: uplaodBOMLoading}">
                <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_257" />
                </span>
                <span class="button_text">
                  Launch
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<launch-upload-bom *ngIf="launchCheck" (hideModel)="launchTrigger($event)"></launch-upload-bom>