<div class="card">
  <div class="card-header flex-start">
    <button class="btn btn-light flex-start" (click)="hideModel.emit()">
      <i class="fa fa-arrow-left"></i>
      <span>Back</span>
    </button>
    <h5>
      Monitors Activity
    </h5>
  </div>
  <div class="card-body">
    <div class="flex-start" *ngIf="pagination.length > 1">
      <ng-container *ngFor="let page of pagination; let page_index = index">
        <div [ngClass]="{'nav-link': page_index != pagination.length - 1}" (click)="navigate(page, page_index)">
          {{ page['value'] }}
        </div>
        <div *ngIf="page_index < pagination.length - 1">
          <i class="fa fa-angle-right"></i>
        </div>
      </ng-container>
    </div>
    <div class="small-text">*in hours</div>
    <div class="overflow-auto">
      <table class="table table-docs">
        <thead>
          <tr>
            <th *ngFor="let h of headers">
              <span *ngIf="h.click" class="nav-link no-wrap" (click)="changeRange(h.id)">
                {{ h['name'] }}
              </span>
              <span *ngIf="!h.click" class="no-wrap">
                {{ h['name'] }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of activityRows">
            <td *ngFor="let h of headers" class="no-wrap">{{ row[h.id] || '-' }}</td>
          </tr>
          <tr *ngIf="activityRows.length == 0 && !loading">
            <td [attr.colspan]="headers.length" class="center">
              No Activity Found
            </td>
          </tr>
        </tbody>
        <tbody *ngIF="loading">
          <tr>
            <td [attr.colspan]="headers.length" class="center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>