import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import { regions } from 'src/app/dash/dash.component';

declare let $: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './enablemaster.component.html',
  styleUrls: ['./enablemaster.component.css'],
})
export class EnableMasterComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  message: string = '';
  otp: string = '';
  regions: any = regions;
  accountId: any;
  regionId: any;
  currentMessage: any;
  step: number = 0;
  steps: number = 4;
  mai: any;
  an: any;
  bn: any;
  folderName: any;
  region: any = regions[0].id;

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    setTimeout(() => {
      $('#regionsList').selectpicker();
    }, 100);

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.step = 1;
  }

  async validateOTP() {
    this.notifier.loading(true);
    let data = { action: 'verify_otp', otp: this.otp };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/masteraccountoperations`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      await this.masterAccount();
      this.otp = '';
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  reviewAccount() {
    this.step = 2;
  }

  async masterAccount() {
    this.notifier.loading(true);
    let data = {
      otp: this.otp,
      mai: this.mai,
      an: this.an,
      action: 'save_master',
      bn: this.bn,
      folderName: this.folderName,
      region: this.region,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/masteraccountoperations`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.mai = '';
      this.an = '';
      this.bn = '';
      this.folderName = '';
      this.step = 1;
    } else {
      this.step = 1;
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async sendOTP() {
    this.otp = '';
    this.notifier.loading(true);
    let data = { action: 'send_otp' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/masteraccountoperations`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.step = 3;
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
