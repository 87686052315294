import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";

import { DashComponent } from "./dash/dash.component";
import { DashIndexComponent } from "./dash/index.component";

import { DashcComponent } from "./dashc/dash.component";
import { DashcIndexComponent } from "./dashc/index.component";

import { DashClientComponent } from "./client/dash.component";
import { DashClientIndexComponent } from "./client/index.component";

import { headerPrintComponent } from "./component/header/header";

import { NumbersOnly } from "./_directives/dirNumber";
import { DateFormat } from "./_directives/dirDateFormat";

import { createTicketComponent } from "./views/ticket/create.component";
import { viewTicketComponent } from "./views/ticket/view/view.ticket.component";
import { knowledgebaseComponent } from "./views/knowledgebase/knowledgebase.component";

// compliance
import { ebsComponent } from "./views/compliance/ebs/ebs.component";
import { s3Component } from "./views/compliance/s3/s3.component";
import { cloudTrailComponent } from "./views/compliance/cloudtrail/cloudtrail.component";
import { configComponent } from "./views/compliance/config/config.component";
import { iamComponent } from "./views/compliance/iam/iam.component";
import { patchComponent } from "./views/compliance/patchreport/patch.component";

import { securityHubComponent } from "./views/compliance/security_hub/security.hub.component";
import { inspectorComponent } from "./views/compliance/inspector/inspector.component";
import { sgComponent } from "./views/compliance/security_groups/sg.component";
import { tagsComponent } from "./views/compliance/tags/tags.component";
import { advisorComponent } from "./views/compliance/advisor/advisor.component";
import { ec2CostOptimizationComponent } from "./views/costoptimization/ec2costoptimization/ec2costoptimization.component";
import { otherCostOptimizationComponent } from "./views/costoptimization/othercostoptimization/othercostopt.component";

//SLA
import { CvsRComponent } from "./views/sla/createdvsresolved/createdvsresolved.component";
import { FirstResponseSLAComponent } from "./views/sla/firstresponsesla/firstresponsesla.component";
import { ResolutionSLA } from "./views/sla/resolutionsla/resolutionsla.component";

// Monitoring
import { MonitoringComponent } from "./views/monitoring/monitoring.component";

// Operations
import { ec2OperationsComponent } from "./views/operations/ec2/ec2.operations.component";
import { rdsOperationsComponent } from "./views/operations/rds/rds.component";
import { ebsOpsComponent } from "./views/operations/ebs/ebs.operations.component";
import { sgOperationsComponent } from "./views/operations/security_groups/sg.operations.component";

// Anomalies
import { dashboardAnomaliesComponent } from "./views/anomalies/dashboard/dashboard.anomalies.component";
import { triggerHistoryAnomaliesComponent } from "./views/anomalies/trigger_history/trigger_history.anomalies.component";
import { exceptionsAnomaliesComponent } from "./views/anomalies/exceptions/exceptions.anomalies.component";

// BackupReport
import { backupReportComponent } from "./views/backupreport/backupreport.component";
import { RDSBackupReportComponent } from "./views/reports/view/components/rds_backup_reports/rdsbackup-report.component";

// BOM
import { viewBOMComponent } from "./views/BOM/view/view.bom.component";
import { deltaReportBOMComponent } from "./views/BOM/deltareport/deltareport.bom.component";

// Reports
import { viewReportsComponent } from "./views/reports/view/view.reports.component";
import { DiskDetailsReportsComponent } from "./views/reports/view/components/diskdetails/diskdetails.view.reports.component";
import { DailyBackupViewReportsComponent } from "./views/reports/view/components/dailybackup/dailybackup.view.reports.component";
import { InventoryReportsComponent } from "./views/reports/view/components/inventory/inventory.view.reports.component";
import { MISReportsComponent } from "./views/reports/view/components/mis/mis.view.reports.component";
import { SGReportsComponent } from "./views/reports/view/components/sg/sg.view.reports.component";
import { UptimeReportsComponent } from "./views/reports/view/components/uptime/uptime.view.reports.component";

// Billing
import { BillingComponent } from "./views/billing/billing.component";

// Settings
import { auditSettingsComponent } from "./views/settings/audit/audit.settings.component";
import { ViewUserSettingsComponent } from "./views/settings/user/view/view.user.component";
import { ProfileSettingsComponent } from "./views/settings/user/profile/profile.settings.component";
import { InviteUserComponent } from "./views/settings/user/invite/invite.user.component";

import { modalPopupComponent } from "./component/modal/modal";
import { TagsModalComponent } from "./component/modal/tags/tags.modal.component";
import { nTableComponent } from "./component/nTable/nTable";

import { nTableNestedComponent } from "./component/nTableNested/nTable";

// Utils
import { ec2DetailsViewComponent } from "./component/ec2detailsview/ec2.details.view.component";
import { VolumeEC2Component } from "./component/ec2detailsview/components/volume/volume.ec2.components";
import { ReplyTicketPopup } from "./views/ticket/view/components/reply/reply.ticket.component";
import { ConfirmBox } from "./component/confirm/confirm.component";
import { AddKBComponent } from "./component/kb/add/add.kb.component";
import { StartStopInstanceComponent } from "./views/operations/ec2/components/startstop/startstop.ec2.component";
import { TagsOperationsComponent } from "./views/operations/tags/tags.operations.component";
import { CreateJobComponent } from "./views/costoptimization/floatingreport/createjob/createjob.component";
import { CreateAlarmAnomaliesComponent } from "./views/anomalies/dashboard/createalarm/createalarm.anomalies.component";
import { CreateExceptionAnomaliesComponent } from "./views/anomalies/exceptions/updaterule/updaterule.anomalies.component";
import { CreateScanBackupReportComponent } from "./views/backupreport/createscan/createscan.backupreport.component";
import { enableReportsComponent } from "./views/reports/enable/enable.reports.component";

import { UpdateBOMComponent } from "./views/BOM/view/update/update.bom.component";
import { DeleteBOMComponent } from "./views/BOM/view/delete/delete.bom.component";
import { UploadBOMComponent } from "./views/BOM/upload/upload.bom.component";
import { ErrorUploadBOM } from "./views/BOM/upload/error/error.upload.bom.component";
import { DisksEC2Component } from "./component/ec2detailsview/components/disks/disks.component";
import { EC2BillingComponent } from "./views/billing/components/ec2/ec2.billing.component";
import { RDSBillingComponent } from "./views/billing/components/rds/rds.billing.component";
import { CloudFrontBillingComponent } from "./views/billing/components/cloudfront/cloudfront.billing.component";
import { AnalyticsBillingComponent } from "./views/billing/components/analytics/analytics.component";
import { updateEC2Group } from "./views/settings/ec2-group/components/ec2-group.component";
import { ViewEC2GroupComponenet } from "./views/settings/ec2-group/view.ec2-group.component";
import { PoliciesComponenet } from "./views/settings/policies/manage.policies.component";
import { UpdatePoliciesComponent } from "./views/settings/policies/components/update.policies.component";
import { ForgotPasswordComponent } from "./login/forgot_password/forgot.component";
import { RegisterComponent } from "./login/register/register.component";

import { RICoverageCBSComponenet } from "./views/cbs/ricoverage/ricoverage.component";
import { RIPurchasedComponent } from "./views/cbs/ripurchased/ripurchased.component";
import { MinfyRIAccountComponent } from "./views/cbs/minfyriaccount/minfyriaccount.component";
import { OrganizationsCBSComponent } from "./views/cbs/organizations/organizations.component";
import { RIOrderComponent } from "./views/cbs/enableri/riorder.component";
import { EnableRIUpdateComponent } from "./views/cbs/enableri/update/update.enableri.component";
import { ViewCFRCComponent } from "./views/cbs/cfrc/view/cfrc.view.component";
import { EnableCFRCComponent } from "./views/cbs/cfrc/enable/cfrc.enable.component";
import { MasterSummaryComponent } from "./views/cbs/mastersummary/mastersummary.component";
import { EnableMasterComponent } from "./views/cbs/enablemaster/enablemaster.component";
import { DiscountComponent } from "./views/cbs/discount/discount.component";
import { RIOrderBillingComponent } from "./views/billing/ri/billing.ri.component";
import { supportPlanComponent } from "./views/cbs/supportplan/supportplan.component";
import { OnBoardedMasterComponent } from "./views/cbs/onboardedmaster/onboardedmaster.component";
import { AMIsComponent } from "./views/amis/amis.component";
import { CreateScanAMIsReportComponent } from "./views/amis/createscan/createscan.amis.component";
import { StatusAMIsComponent } from "./views/amis/amis_status/amis_status.component";

import { RDSEnableRIUpdateComponent } from "./views/cbs/rdsenableri/update/rdsupdate.enableri.component";
import { RDSRIOrderComponent } from "./views/cbs/rdsenableri/rdsriorder.component";
import { AgeingSnapshotsComponent } from "./views/reports/view/components/ageingsnapshots/ageingsnapsots.component";

import { TagsCostOptimizationCreateJobComponent } from "./views/beta/tagscostoptimization/createjob/createjob.component";

import { BetaEc2CostOptimizationComponent } from "./views/beta/ec2costoptimization/ec2costoptimization.component";
import { BetaEC2CostOptimizationCreateJobComponent } from "./views/beta/ec2costoptimization/createjob/createjob.component";
import { BetaEBSCostOptimizationComponent } from "./views/beta/ebscostoptimization/ebscostoptimization.component";
import { BetaTicketEC2Start } from "./views/beta/ticket/components/startstop/ticket.ec2startstop.component";
import { BetaTagsCostOptimizationComponent } from "./views/beta/tagscostoptimization/tagscostoptimization.component";
import { BetaCreateTicketComponent } from "./views/beta/ticket/create.component";
import { BetaIAMUsersComponent } from "./views/beta/iamusers/iamusers.component";

import { BetaIAMAgeReportComponent } from "./views/beta/iam_age_report/iam_age_report.component";

import { BetaIAMGroupsComponent } from "./views/beta/iam_groups/iam_groups.component";
import { BetaS3AgeReportComponent } from "./views/beta/s3_ageing_report/s3_ageing.component";
import { StartStopv2InstanceComponent } from "./component/startstopv2/startstop.ec2.component";

import { CBSDashComponent } from "./cbsDash/dash.component";

import { UploadBOMv2Component } from "./views/BOMv2/upload/upload.bom.component";
import { SGComplianceCreateJobComponent } from "./views/compliance/security_groups/createjob/createjob.component";
import { BetaDailyBillingComponent } from "./views/beta/billing/billing.component";
import { Step4UploadBOMv2Component } from "./views/BOMv2/upload/components/step4/step4.bomupload.component";
import { Step3UploadBOMv2Component } from "./views/BOMv2/upload/components/step3/step3.uploadbomv2.component";
import { Step5UploadBOMv2Component } from "./views/BOMv2/upload/components/step5/step5.bomupload.component";
import { AMILaunchDropdownComponent } from "./views/BOMv2/upload/components/dropdown/components/ami/ami.dropdown.launch.component";
import { KeyLaunchDropdownComponent } from "./views/BOMv2/upload/components/dropdown/components/keypair/keypair.dropdown.launch.component";
import { RoleLaunchDropdownComponent } from "./views/BOMv2/upload/components/dropdown/components/role/role.dropdown.launch.component";
import { SGLaunchDropdownComponent } from "./views/BOMv2/upload/components/dropdown/components/sg/sg.dropdown.launch.component";
import { SubnetLaunchDropdownComponent } from "./views/BOMv2/upload/components/dropdown/components/subnet/subnet.dropdown.launch.component";
import { VPCLaunchDropdownComponent } from "./views/BOMv2/upload/components/dropdown/components/vpc/vpc.dropdown.launch.component";
import { selectLaunchDropdownComponent } from "./views/BOMv2/upload/components/dropdown/dropdown.launch.component";
import { AMIsLaunchInstanceComponent } from "./views/BOMv2/upload/components/launch/components/amis/amis.launch.component";
import { DetailsLaunchInstanceComponent } from "./views/BOMv2/upload/components/launch/components/details/details.launch.component";
import { LaunchUploadBOM } from "./views/BOMv2/upload/components/launch/components/error/error.upload.bom.component";
import { NetworkLaunchInstanceComponent } from "./views/BOMv2/upload/components/launch/components/network/network.launch.component";
import { StorageLaunchInstanceComponent } from "./views/BOMv2/upload/components/launch/components/storage/storage.launch.component";
import { TagsKeyPairLaunchInstanceComponent } from "./views/BOMv2/upload/components/launch/components/tags_keypair/tagskeypair.launch.component";
import { LaunchInstanceComponent } from "./views/BOMv2/upload/components/launch/launch.instance.component";
import { OSCheckUploadBOM } from "./views/BOMv2/upload/components/step5/oscheck/oscheck.upload.bom.component";
import { viewSchedulertComponent } from "./viewsnew/scheduler/view.scheduler.component";
import { BudgetBIllingComponent } from "./views/billing/budget/budget.component";
import { BillingDashboardComponent } from "./views/billing/dashboard/billingdashboard.component";
import { MetricsComponent } from "./views/beta/ec2costoptimization/metrics/metrics.ec2.component";
import { DatepickerComponent } from "./views/BOMv2/upload/components/step3/components/datepicker/datepicker.component";
import { floatingComponent } from "./views/costoptimization/floatingreport/floating.component";
import { FirstLoginGettingStartedComponent } from "./views/first-login-getting-started/first-login-getting-started.component";
import { AddComponent } from "./views/accounts/aws/add/add.component";
import { AwsComponent } from "./views/accounts/aws/aws.component";
import { PricingComponent } from "./views/pricing/pricing.component";
import { TermsComponent } from "./views/terms/terms.component";
import { AccountsComponent } from "./views/accounts/accounts.component";
import { ExpiredComponent } from "./dash/expired/expired.component";
import { NgxPaginationModule } from "ngx-pagination";
import { FindingsComponent } from "./views/compliance/findings/findings.component";
import { SsbCheckComponentComponent } from "./views/ssb/components/ssb-check-component/ssb-check-component.component";
import { CreateJobSSBReportComponent } from "./views/ssb/components/ssb-create-scan/create-job-ssb.component";
import { SsbComponent } from "./views/ssb/ssb.component";
import { CreateWorkloadComponent } from "./views/warquiz/components/createscan/createscan.amis.component";
import { AuthGuard, canDeactivateGuard } from "./_helpers/auth.guard";
import { ComparePlansComponent } from "./views/pricing/components/compare-plans/compare-plans.component";
import { LensOverviewComponent } from "./views/warquiz/components/lens-overview/lens-overview.component";
import { CreateComponent } from "./views/warquiz/components/milestones/create/create.component";
import { MilestonesComponent } from "./views/warquiz/components/milestones/milestones.component";
import { SaveDialogComponent } from "./views/warquiz/components/questions/components/save-dialog/save-dialog.component";
import { QuestionsComponent } from "./views/warquiz/components/questions/questions.component";
import { PropertiesComponent } from "./views/warquiz/components/workload-overview/properties/properties.component";
import { WorkloadOverviewComponent } from "./views/warquiz/components/workload-overview/workload-overview.component";
import { WarQuizComponent } from "./views/warquiz/warquiz.component";
import { AcmComponent } from "./views/operations/acm/acm.component";
import { RisavingsComponent } from "./views/costoptimization/risavings/risavings.component";
import { InstanceSavingsComponent } from "./views/costoptimization/risavings/components/instance-savings/instance-savings.component";
import { NoneOfTheseConfirmComponent } from "./views/warquiz/components/questions/components/none-of-these-confirm/none-of-these-confirm.component";
import { PriceAnimationComponent } from "./views/billing/components/price-animation/price-animation.component";
import { InfoComponent } from "./views/accounts/aws/add/components/info/info.component";
import { CurFeatureComponent } from "./views/billing/components/cur-feature/cur-feature.component";
import { RequestCertificateComponent } from "./views/operations/acm/components/request-certificate/request-certificate.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { PolicyViewComponent } from "./views/settings/user/invite/components/policy-view/policy-view.component";
import { UserDetailsComponent } from "./views/settings/user/view/components/user-details/user-details.component";
import { DisableComponentComponent } from "./views/settings/user/view/components/disable-component/disable-component.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { CreateCamsComponent } from "./views/cams/create-cams/create-cams.component";
import { ResourcesCamsComponent } from "./views/cams/create-cams/components/resources-cams/resources-cams.component";
import { ProtocolFormCamsComponent } from "./views/cams/create-cams/components/protocol-form-cams/protocol-form-cams.component";
import { BackupReportComponent } from "./views/reports/view/components/backup-report/backup-report.component";
import { ValidateAccountCamsComponent } from "./views/cams/create-cams/components/validate-account-cams/validate-account-cams.component";
import { ViewComponent } from "./views/cams/view/view.component";
import { ViewScannerAlertsComponent } from "./views/cams/view/components/view-scanner-alerts/view-scanner-alerts.component";
import { ViewScannerResultsComponent } from "./views/cams/view/components/view-scanner-results/view-scanner-results.component";
import { EditScannerCamsComponent } from "./views/cams/view/components/edit-scanner-cams/edit-scanner-cams.component";
import { DeleteRoleCamsComponent } from "./views/cams/view/components/delete-role-cams/delete-role-cams.component";
import { DashboardReportsComponent } from "./views/reports/dashboard/dashboard.component";
import { BannerCamsComponent } from "./views/billing/components/cur/banner-cams/banner-cams.component";
import { EnableCurComponent } from "./views/billing/components/cur/banner-cams/components/enable-cur/enable-cur.component";
import { StepsEnableCurComponent } from "./views/billing/components/cur/banner-cams/components/enable-cur/steps-enable-cur/steps-enable-cur.component";
import { S3ConfigureCurComponent } from "./views/billing/components/cur/banner-cams/components/s3-configure-cur/s3-configure-cur.component";
import { TellMeComponent } from "./views/tell-me/tell-me.component";
import { GlobalSearchComponent } from "./dash/components/global-search/global-search.component";
import { CreateScanFindingComponent } from "./views/compliance/findings/components/create-scan-finding/create-scan-finding.component";
import { ThreatResourcesComponent } from "./views/compliance/findings/components/threat-resources/threat-resources.component";
import { ComplianceDashComponent } from "./views/compliance-dash/compliance-dash.component";
import { ViewSGComponent } from "./views/compliance-dash/comp/view-sg/view-sg.component";
import { ViewPortsComponent } from "./views/compliance-dash/comp/view-ports/view-ports.component";
import { ViewSnapComponent } from "./views/compliance-dash/comp/view-snap/view-snap.component";
import { ViewInstancesComponent } from "./views/compliance-dash/comp/view-instances/view-instances.component";
import { ViewRetentionComponent } from "./views/compliance-dash/comp/view-retention/view-retention.component";
import { ViewBackupComponent } from "./views/compliance-dash/comp/view-backup/view-backup.component";
import { ViewVpcComponent } from "./views/compliance-dash/comp/view-vpc/view-vpc.component";
import { CftBannerOptionalComponent } from "./dash/components/cft-banner-optional/cft-banner-optional.component";
import { OsBasedRecommendationsComponent } from "./views/beta/ec2costoptimization/components/os-based-recommendations/os-based-recommendations.component";
import { ProcessorBasedRecommendationsComponent } from "./views/beta/ec2costoptimization/components/processor-based-recommendations/processor-based-recommendations.component";
import { HistoryComponent } from "./views/tell-me/components/history/history.component";
import { SwayamDocsComponent } from "./dash/components/swayam-docs/swayam-docs.component";
import { EMSBannerComponent } from "./dash/components/ems-banner/ems-banner.component";
import { MonitorGroupComponent } from "./views/site24x7/components/monitor/components/monitor-group/monitor-group.component";
import { CftViewerComponent } from "./dash/components/cft-banner-optional/components/cft-viewer/cft-viewer.component";
import { UpdateCftComponent } from "./dash/components/cft-banner-optional/components/update-cft/update-cft.component";
import { NotificationTabComponent } from "./dash/components/notification-tab/notification-tab.component";
import { StepsConfigureCFTComponent } from "./dash/components/steps-configure-cft/steps-enable-cur.component";
import { ITASComponent } from "./views/itas/itas.component";
import { MbrReportComponent } from "./views/reports/view/components/mbr-report/mbr-report.component";
import { LastestMbrReportComponent } from "./views/reports/view/components/latest-mbr-report/mbr-report.component";
import { EnableBuComponent } from "./views/site24x7/components/enable-bu/enable-bu.component";
import { FeatureMissingComponent } from "./views/site24x7/components/feature-missing/feature-missing.component";
import { ListMonitorsComponent } from "./views/site24x7/components/list-monitors/list-monitors.component";
import { ActivateComponent } from "./views/site24x7/components/monitor/activate/activate.component";
import { CreateMonitorComponent } from "./views/site24x7/components/monitor/create-monitor/create-monitor.component";
import { SuspendComponent } from "./views/site24x7/components/monitor/suspend/suspend.component";
import { Site24x7Component } from "./views/site24x7/site24x7.component";
import { CftMissingComponent } from "./views/tell-me/components/cft-missing/cft-missing.component";
import { PhotoViewerComponent } from "./views/tell-me/components/cft-missing/components/photo-viewer/photo-viewer.component";
import { RulesViewerComponent } from "./views/tell-me/components/cft-missing/components/rules-viewer/rules-viewer.component";

import { DeleteBuEmsComponent } from "./views/site24x7/components/list-monitors/delete-bu-ems/delete-bu-ems.component";
import { ViewCostSavingTabComponent } from "./views/dashboard/components/view-cost-saving-tab/view-cost-saving-tab.component";
import { MonitorActivityComponent } from "./views/site24x7/components/monitor-activity/monitor-activity.component";
import { MoreDetailsComponent } from "./views/site24x7/components/enable-bu/components/more-details/more-details.component";
import { PrismaDashComponent } from "./views/prisma-dash/prisma-dash.component";
import { ViewAlertsComponent } from "./views/prisma-dash/components/view-alerts/view-alerts.component";
import { S3Component } from "./views/costoptimization/s3/s3.component";
import { CloudwatchCostControlsComponent } from "./views/costoptimization/cloudwatch-cost-controls/cloudwatch-cost-controls.component";
import { ViewForcastTabComponent } from "./views/dashboard/components/view-forcast-tab/view-forcast-tab.component";
import { ViewTagTabComponent } from "./views/dashboard/components/view-tag-tab/view-tag-tab.component";
import { ViewWinLinTabComponent } from "./views/dashboard/components/view-win-lin-tab/view-win-lin-tab.component";
import { ViewDailyTabComponent } from "./views/dashboard/components/view-daily-tab/view-daily-tab.component";
import { ViewMonthlyTabComponent } from "./views/dashboard/components/view-monthly-tab/view-monthly-tab.component";
import { ViewAnnualTabComponent } from "./views/dashboard/components/view-annual-tab/view-annual-tab.component";
import { MultiAccountTabComponent } from "./views/dashboard/components/multi-account-tab/multi-account-tab.component";
import { ViewOsTabComponent } from "./views/dashboard/components/view-os-tab/view-os-tab.component";
import { ViewS3TabComponent } from "./views/dashboard/components/view-s3-tab/view-s3-tab.component";
import { ViewInsTabComponent } from "./views/dashboard/components/view-ins-tab/view-ins-tab.component";
import { CostAnamolyReportComponent } from "./views/beta/billing/components/cost-anamoly-report/cost-anamoly-report.component";
import { CostAnamolyStatusComponent } from "./views/beta/billing/components/cost-anamoly-status/cost-anamoly-status.component";
import { ConsolidatedEc2ReportComponent } from "./views/reports/view/components/consolidated-ec2-report/consolidated-ec2-report.component";
import { ReservedInstancesComponent } from "./views/costoptimization/reserved-instances/reserved-instances.component";
import { AzureFunctionsComponent } from "./views/azure/azure-functions/azure-functions.component";
import { VmsComponent } from "./views/azure/vms/vms.component";
import { ManagedDisksComponent } from "./views/azure/managed-disks/managed-disks.component";
import { VirtualNetworksComponent } from "./views/azure/virtual-networks/virtual-networks.component";
import { DatabasesComponent } from "./views/azure/databases/databases.component";
import { feedbackComponent } from "./viewsnew/feedback.component";
import { SLADashComponent } from "./views/sla-dash/sla-dash.component";
import { CreateJobFtrComponent } from "./views/ftr/components/create-job-ftr/create-job-ftr.component";
import { FtrCheckComponent } from "./views/ftr/components/ftr-check/ftr-check.component";
import { QuestionsFtrComponent } from "./views/ftr/components/questions-ftr/questions-ftr.component";
import { FtrComponent } from "./views/ftr/ftr.component";
import { RdsComponent } from './views/costoptimization/rds/rds.component';
import { DeleteJobComponent } from './views/ftr/components/delete-job/delete-job.component';

import { RbiComplianceCreateJobComponent } from "./views/rbi-compliance/components/rbi-compliance-create-job/rbi-compliance-create-job.component";
import { RbiComplianceDeleteJobComponent } from "./views/rbi-compliance/components/rbi-compliance-delete-job/rbi-compliance-delete-job.component";
import { RbiComplianceOverviewComponent } from "./views/rbi-compliance/components/rbi-compliance-overview/rbi-compliance-overview.component";
import { RbiComplianceQuestionsComponent } from "./views/rbi-compliance/components/rbi-compliance-questions/rbi-compliance-questions.component";
import { RBICompliance } from "./views/rbi-compliance/rbi-compliance.component";
import { FilesViewerComponent } from './views/rbi-compliance/components/rbi-compliance-questions/components/files-viewer/files-viewer.component';
import { BillingCftMissingComponent } from "./views/billing/cft-missing/cft-missing.component";
import { DownloadDocComponent } from './download-doc/download-doc.component';
import { AzureDashboardComponent } from './views/azure-dashboard/azure-dashboard.component';
import { InventoryBillingComponent } from './views/reports/view/components/inventory-billing/inventory-billing.component';
import { CredentialsComponent } from './views/site24x7/components/credentials/credentials.component';
import { TellMeHistoryComponent } from './views/tell-me-history/tell-me-history.component';
import { GovernanceComponent } from './views/tell-me-history/components/governance/governance.component';
import { DailySpendComponent } from './views/tell-me-history/components/daily-spend/daily-spend.component';
import { DailyGovComponent } from './views/tell-me-history/components/daily-gov/daily-gov.component';
import { DailyCostComponent } from './views/tell-me-history/components/daily-cost/daily-cost.component';
import { PriceDifferenceComponent } from './views/azure-dashboard/components/price-difference/price-difference.component';
import { TicketTimelineComponent } from './views/ticket/view/components/ticket-timeline/ticket-timeline.component';
import { HighAlertComponent } from './views/tell-me-history/components/high-alert/high-alert.component';
import { MedAlertComponent } from './views/tell-me-history/components/med-alert/med-alert.component';
import { LowAlertComponent } from './views/tell-me-history/components/low-alert/low-alert.component';
import { MenuSelectorComponent } from './views/settings/user/invite/components/menu-selector/menu-selector.component';
import { GreenGameCheckComponentComponent } from "./views/greengame/components/greengame-check-component/greengame-check-component.component";
import { GreenGameComponent } from "./views/greengame/greengame.component";
import { CreateJobGreenGameReportComponent } from "./views/greengame/components/greengame-create-scan/create-job-greengame.component";
import { TellMeDocComponent } from "./tellme-doc/download-doc.component";
import { GreenGameRescanComponent } from "./views/greengame/components/greengame-rescan/greengame-rescan.component";
import { RescanpopComponent } from './rescanpop/rescanpop.component';
import { CostOptimizationFTR } from "./views/cost-optimization/ftr.component";
import { CostOptimizationCreateJobFtrComponent } from "./views/cost-optimization/components/create-job-ftr/create-job-ftr.component";
import { CostOptimizationDeleteJobComponent } from "./views/cost-optimization/components/delete-job/delete-job.component";
import { CostOptimizationCheckComponent } from "./views/cost-optimization/components/ftr-check/ftr-check.component";
import { CostOptimizationQuestionsFtrComponent } from "./views/cost-optimization/components/questions-ftr/questions-ftr.component";
import { CreateProfileComponent } from "./views/warquiz/components/createprofile/createscan.amis.component";
import { AssociateProfileComponent } from './views/warquiz/components/workload-overview/associate-profile/associate-profile.component';
import { DisassociateProfileComponent } from './views/warquiz/components/workload-overview/disassociate-profile/disassociate-profile.component';
import { DeleteProfileComponent } from './views/warquiz/components/delete-profile/delete-profile.component';
import { ChecksHomeComponent } from './views/cost-optimization/home/home.component';
import { CreateLensComponent } from './views/warquiz/components/create-lens/create-lens.component';
import { DeleteLensComponent } from './views/warquiz/components/delete-lens/delete-lens.component';
import { SecurityComponent } from "./views/security/security.component";
import { RdsriComponent } from "./views/costoptimization/rdsri/rdsri.component";
import { S3BillingComponent } from "./views/billing/components/s3/cloudfront.billing.component";
import { ConsolidatedBackupReportComponent } from "./views/reports/view/components/consolidated_backup_report/backup-report.component";
import { ConsolidatedStatusAMIsComponent } from "./views/reports/view/components/consolidated_backup_report/amis/amis_status/amis_status.component";
import { ConsolidatedbackupReportComponent } from "./views/reports/view/components/consolidated_backup_report/backupreport/backupreport.component";
import { ConsolidatedCreateScanBackupReportComponent } from "./views/reports/view/components/consolidated_backup_report/backupreport/createscan/createscan.backupreport.component";
import { ConsolidatedAMIsComponent } from "./views/reports/view/components/consolidated_backup_report/amis/amis.component";
import { ConsolidatedCreateScanAMIsReportComponent } from "./views/reports/view/components/consolidated_backup_report/amis/createscan/createscan.amis.component";
@NgModule({
  declarations: [
    CreateAlarmAnomaliesComponent,
    CreateExceptionAnomaliesComponent,
    AppComponent,
    LoginComponent,
    DashComponent,
    DashIndexComponent,
    DashcComponent,
    DashcIndexComponent,
    headerPrintComponent,
    NumbersOnly,
    DateFormat,
    DashClientComponent,
    DashClientIndexComponent,
    createTicketComponent,
    viewTicketComponent,
    knowledgebaseComponent,
    ebsComponent,
    s3Component,
    cloudTrailComponent,
    configComponent,
    iamComponent,
    patchComponent,
    securityHubComponent,
    inspectorComponent,
    sgComponent,
    tagsComponent,
    advisorComponent,
    ec2CostOptimizationComponent,
    otherCostOptimizationComponent,
    floatingComponent,
    ec2OperationsComponent,
    CvsRComponent,
    FirstResponseSLAComponent,
    ResolutionSLA,
    MonitoringComponent,
    rdsOperationsComponent,
    ebsOpsComponent,
    sgOperationsComponent,
    dashboardAnomaliesComponent,
    triggerHistoryAnomaliesComponent,
    exceptionsAnomaliesComponent,
    backupReportComponent,
    RDSBackupReportComponent,
    viewBOMComponent,
    deltaReportBOMComponent,
    viewReportsComponent,
    DiskDetailsReportsComponent,
    DailyBackupViewReportsComponent,
    InventoryReportsComponent,
    MISReportsComponent,
    SGReportsComponent,
    UptimeReportsComponent,
    BillingComponent,
    auditSettingsComponent,
    ViewUserSettingsComponent,
    ProfileSettingsComponent,
    InviteUserComponent,
    modalPopupComponent,
    TagsModalComponent,
    nTableComponent,
    ec2DetailsViewComponent,
    VolumeEC2Component,
    ReplyTicketPopup,
    ConfirmBox,
    AddKBComponent,
    StartStopInstanceComponent,
    TagsOperationsComponent,
    CreateJobComponent,
    CreateScanBackupReportComponent,
    enableReportsComponent,
    UpdateBOMComponent,
    DeleteBOMComponent,
    UploadBOMComponent,
    ErrorUploadBOM,
    nTableNestedComponent,
    DisksEC2Component,
    EC2BillingComponent,
    RDSBillingComponent,
    CloudFrontBillingComponent,
    AnalyticsBillingComponent,
    updateEC2Group,
    ViewEC2GroupComponenet,
    PoliciesComponenet,
    UpdatePoliciesComponent,
    ForgotPasswordComponent,
    RegisterComponent,

    RICoverageCBSComponenet,
    RIPurchasedComponent,
    MinfyRIAccountComponent,
    OrganizationsCBSComponent,
    RIOrderComponent,
    EnableRIUpdateComponent,
    ViewCFRCComponent,
    EnableCFRCComponent,
    MasterSummaryComponent,
    EnableMasterComponent,
    DiscountComponent,
    RIOrderBillingComponent,
    supportPlanComponent,
    OnBoardedMasterComponent,
    AMIsComponent,
    CreateScanAMIsReportComponent,
    StatusAMIsComponent,
    RDSEnableRIUpdateComponent,
    RDSRIOrderComponent,
    AgeingSnapshotsComponent,

    BetaEc2CostOptimizationComponent,
    BetaEBSCostOptimizationComponent,

    TagsCostOptimizationCreateJobComponent,
    BetaTagsCostOptimizationComponent,
    BetaCreateTicketComponent,
    BetaIAMUsersComponent,
    BetaEC2CostOptimizationCreateJobComponent,
    BetaTicketEC2Start,

    BetaIAMAgeReportComponent,

    BetaIAMGroupsComponent,
    BetaS3AgeReportComponent,
    StartStopv2InstanceComponent,
    CBSDashComponent,

    UploadBOMv2Component,
    SGComplianceCreateJobComponent,
    BetaDailyBillingComponent,
    UploadBOMv2Component,
    Step3UploadBOMv2Component,
    Step4UploadBOMv2Component,
    Step5UploadBOMv2Component,
    DatepickerComponent,
    LaunchInstanceComponent,
    AMIsLaunchInstanceComponent,
    selectLaunchDropdownComponent,
    NetworkLaunchInstanceComponent,
    StorageLaunchInstanceComponent,
    TagsKeyPairLaunchInstanceComponent,
    OSCheckUploadBOM,
    DetailsLaunchInstanceComponent,
    LaunchUploadBOM,
    VPCLaunchDropdownComponent,
    SGLaunchDropdownComponent,
    SubnetLaunchDropdownComponent,
    AMILaunchDropdownComponent,
    KeyLaunchDropdownComponent,
    RoleLaunchDropdownComponent,
    viewSchedulertComponent,
    BillingDashboardComponent,
    BudgetBIllingComponent,
    MetricsComponent,
    FirstLoginGettingStartedComponent,
    AddComponent,
    AwsComponent,
    PricingComponent,
    TermsComponent,
    AccountsComponent,
    ExpiredComponent,
    FindingsComponent,
    SsbComponent,
    ConsolidatedStatusAMIsComponent,
    ConsolidatedCreateScanAMIsReportComponent,
    ConsolidatedCreateScanBackupReportComponent,
    ConsolidatedAMIsComponent,
    ConsolidatedbackupReportComponent,
    ConsolidatedBackupReportComponent,
    SsbCheckComponentComponent,
    CreateJobSSBReportComponent,
    WarQuizComponent,
    CreateWorkloadComponent,
    ComparePlansComponent,
    QuestionsComponent,
    WorkloadOverviewComponent,
    LensOverviewComponent,
    MilestonesComponent,
    CreateComponent,
    PropertiesComponent,
    SaveDialogComponent,
    CreateWorkloadComponent,
    AcmComponent,
    RisavingsComponent,
    InstanceSavingsComponent,
    NoneOfTheseConfirmComponent,
    InfoComponent,
    CurFeatureComponent,
    RequestCertificateComponent,
    PolicyViewComponent,
    UserDetailsComponent,
    DisableComponentComponent,
    DashboardComponent,
    PriceAnimationComponent,
    CreateCamsComponent,
    ResourcesCamsComponent,
    ProtocolFormCamsComponent,
    BackupReportComponent,
    ValidateAccountCamsComponent,
    ViewComponent,
    ViewScannerAlertsComponent,
    ViewScannerResultsComponent,
    EditScannerCamsComponent,
    DeleteRoleCamsComponent,
    DashboardReportsComponent,
    BannerCamsComponent,
    EnableCurComponent,
    StepsEnableCurComponent,
    S3ConfigureCurComponent,
    TellMeComponent,
    GlobalSearchComponent,
    CreateScanFindingComponent,
    ThreatResourcesComponent,
    ComplianceDashComponent,
    ViewSGComponent,
    ViewPortsComponent,
    ViewSnapComponent,
    ViewInstancesComponent,
    ViewRetentionComponent,
    ViewBackupComponent,
    ViewVpcComponent,
    CftBannerOptionalComponent,
    OsBasedRecommendationsComponent,
    ProcessorBasedRecommendationsComponent,
    HistoryComponent,
    CftMissingComponent,
    PhotoViewerComponent,
    UpdateCftComponent,
    StepsConfigureCFTComponent,
    RulesViewerComponent,
    CftViewerComponent,
    MbrReportComponent,
    LastestMbrReportComponent,
    NotificationTabComponent,
    ITASComponent,
    SwayamDocsComponent,
    CostAnamolyReportComponent,
    CostAnamolyStatusComponent,
    ViewInsTabComponent,
    ConsolidatedEc2ReportComponent,
    ViewS3TabComponent,
    ViewOsTabComponent,
    Site24x7Component,
    FeatureMissingComponent,
    EnableBuComponent,
    ActivateComponent,
    SuspendComponent,
    ListMonitorsComponent,
    CreateMonitorComponent,
    EMSBannerComponent,
    MonitorGroupComponent,
    DeleteBuEmsComponent,
    MultiAccountTabComponent,
    ViewAnnualTabComponent,
    ViewMonthlyTabComponent,
    ViewDailyTabComponent,
    ViewWinLinTabComponent,
    ViewTagTabComponent,
    ViewForcastTabComponent,
    ViewCostSavingTabComponent,
    MonitorActivityComponent,
    MoreDetailsComponent,
    PrismaDashComponent,
    ViewAlertsComponent,
    S3Component,
    ReservedInstancesComponent,
    CloudwatchCostControlsComponent,
    VmsComponent,
    ManagedDisksComponent,
    VirtualNetworksComponent,
    DatabasesComponent,
    AzureFunctionsComponent,
    feedbackComponent,
    SLADashComponent,
    FtrComponent,
    CreateJobFtrComponent,
    FtrCheckComponent,
    QuestionsFtrComponent,
    RdsComponent,
    DeleteJobComponent,
    RBICompliance,
    RbiComplianceCreateJobComponent,
    RbiComplianceDeleteJobComponent,
    RbiComplianceOverviewComponent,
    RbiComplianceQuestionsComponent,
    FilesViewerComponent,
    BillingCftMissingComponent,
    DownloadDocComponent,
    AzureDashboardComponent,
    InventoryBillingComponent,
    CredentialsComponent,
    AzureDashboardComponent,
    TellMeHistoryComponent,
    GovernanceComponent,
    DailySpendComponent,
    DailyGovComponent,
    DailyCostComponent,
    PriceDifferenceComponent,
    TicketTimelineComponent,
    HighAlertComponent,
    MedAlertComponent,
    LowAlertComponent,
    MenuSelectorComponent,
    GreenGameCheckComponentComponent,
    GreenGameComponent,
    CreateJobGreenGameReportComponent,
    TellMeDocComponent,
    GreenGameRescanComponent,
    RescanpopComponent,
    CostOptimizationFTR,
    CostOptimizationCreateJobFtrComponent,
    CostOptimizationDeleteJobComponent,
    CostOptimizationCheckComponent,
    CostOptimizationQuestionsFtrComponent,
    CreateProfileComponent,
    AssociateProfileComponent,
    DisassociateProfileComponent,
    DeleteProfileComponent,
    ChecksHomeComponent,
    CreateLensComponent,
    DeleteLensComponent,
    SecurityComponent,
    RdsriComponent,
    S3BillingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgSelectModule,
  ],
  providers: [AuthGuard, canDeactivateGuard],
  bootstrap: [AppComponent],
  exports: [NumbersOnly, DateFormat],
})
export class AppModule {}
