import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'subnet-option-launch-select-dropdown',
  templateUrl: './subnet.dropdown.launch.component.html',
  styleUrls: ['./subnet.dropdown.launch.component.css'],
})
export class SubnetLaunchDropdownComponent implements OnInit, OnDestroy {
  @Input() subnet: any;
  ngOnInit(): void {}

  constructor() {}

  ngOnDestroy(): void {}
}
