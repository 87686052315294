import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
  } from "@angular/core";
  import { APIService } from "src/app/api/api.service";
  import { NotifierService } from "src/app/_services/notifier.service";
  import { environment } from "src/environments/environment";
  import * as echarts from "echarts";
  import { Router } from "@angular/router";
  
  declare let Jhxlsx: any;
  
  @Component({
    selector: "app-greengame-rescan",
    templateUrl: "./greengame-rescan.component.html",
    styleUrls: ["./greengame-rescan.component.css"],
  })
  export class GreenGameRescanComponent {
    @Output() hideModal = new EventEmitter<any>();
    @Input("loading") loading: any;
    @Input() accountId: string;
    @Input("job") job: any;
    @Input("check") check: any;
    @Input("regionId") regionId: any;
    @Output("loadJobs") loadJobs: any = new EventEmitter<string>();
    @Output("loadChecks") loadChecks: any = new EventEmitter<string>();
    @Output("re_scan") re_scan: any = new EventEmitter<string>();
    checks: any = [];
    urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
    constructor(
      private notifier: NotifierService,
      private apiService: APIService,
      private navigator: Router
    ) {}

    // async rescan() {
    //     // this.loading["rescan"] = true;
    //     // this.notifier.loading(true);
    //     let data: any = {
    //       action: "rescan",
    //       accId: this.accountId,
    //       scanId: this.job["scanId"],
    //       regions: this.job["regions"],
    //       levelName: 'level1',
    //     };
    
    //     let header: any = {
    //       Authorization: localStorage.getItem("t"),
    //     };
    
    //     let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix == 'admin' ? 'admin' : 'market'}/greengame`;
    
    //     let result: any = await this.apiService.postDataPromis(
    //       apiURL,
    //       data,
    //       header
    //     );
    
    //     this.loading["rescan"] = false;
    

    //     if (result.status == "1" || result.s == "1") {
    //       console.log("success on rescan");
    //      this.notifier.alert("Info", "" , "Rescan has been intiaated", "info" , 5000);
    //      this.load();
    //     } else {
    //       this.notifier.alert("Error", "", result["error_message"]);
    //       console.log("error on rescan");
    //     }
    //     this.notifier.loading(false);
    //   }
      load(event: any = true) {
        if (event) {
          this.loadJobs();
        }
      }

      no() {
        this.navigator.navigate(["/dash/green-game"]);
      }
      yes(){
        this.re_scan();
        this.navigator.navigate(["/dash/green-game"]);
      }
  }

  