<div class="screen">
    <div class="popup-container">
      <div class="popup-header">
        <div class="color-inherit popup-header-bold">
          Delete FTR Job - {{ job['name'] }}
        </div>
      </div>
      <div class="popup-body">
          Are you sure you want to delete the job?
      </div>
      <div class="popup-actions flex-start">
        <button *ngIf="!loading" class="btn btn-danger-soft" (click)="deleteJob()">
          Delete
        </button>
        <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
        <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
      </div>
    </div>
  </div>