<div class="screen">
  <div class="protocol-container">
    <div class="header">
      Delete Role
    </div>
    <div class="body">
      <div class="form-group">
        <label>Role ARN</label>
        <input autocomplete="off" type="text" class="form-control" disabled [value]="roleARN">
      </div>
    </div>
    <div class="footer flex-end">
      <button class="btn btn-danger-soft" (click)="deleteScanner()" *ngIf="!loading">
        Delete
      </button>
      <div *ngIf="loading">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
      </div>
      <button class="btn btn-light" (click)="this.hideModel.emit(false)">
        Cancel
      </button>
    </div>
  </div>
</div>