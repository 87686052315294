import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";
import * as echarts from "echarts";
import { of } from "rxjs";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "cost-optimization-ftr",
  templateUrl: "./ftr.component.html",
  styleUrls: ["./ftr.component.css"],
})
export class CostOptimizationFTR implements OnInit, OnChanges {
  @Input("type") type: any;
  jobs: any = [];
  createJob: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  accountId: string;
  regionId: string;
  loading: any = {
    jobs: false,
    checks: false,
  };
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;
  selectedJob: any;
  selectedJobID: any;
  jobsHeaders: any = [
    {
      id: "name",
      name: "Name",
      link: true,
    },
    {
      id: "cr_time",
      name: "Creation Time",
    },
    {
      id: "total_checks",
      name: "Total Checks",
      center: true,
    },
    {
      id: "perc",
      name: "Score (%)",
      center: true,
    },
    {
      id: "passed",
      name: "#Passed",
      center: true,
    },
    {
      id: "failed",
      name: "#Failed",
      center: true,
    },
    {
      id: "status",
      name: "Status",
    },
    {
      id: "cm_time",
      name: "Completion Time",
    },
  ];
  currentMessage: any;
  customerType: string = localStorage.getItem("customerType");

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router,
    private notifier: NotifierService,
    private title: Title,
  ) {}

  ngOnInit(): void {
  //  console.log("type value in ftr",this.type) 
    this.title.setTitle("Advanced Security Protocol");
    this.router.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length == 0) {
        this.selectedJob = null;
        this.selectedJobID = null;
      }
      if (params["job_id"] && params["job_id"] != "") {
        this.selectedJobID = params["job_id"];
      }
    });

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes["type"]["firstChange"]) {
      this.load();
    }
  }

  masterAccounts: any = [];
  data: any = {
    last_1_month_billing: [
      { date: "Week 1", price: 0 },
      { date: "Week 2", price: 0 },
      { date: "Week 3", price: 0 },
      { date: "Week 4", price: 0 },
    ],
    last_6_months_billing: [],
  };

  async load(event: any = true) {
    this.selectedJob = null;
    if (event) {
      this.data = {
        last_1_month_billing: [
          { date: "Week 1", price: 0 },
          { date: "Week 2", price: 0 },
          { date: "Week 3", price: 0 },
          { date: "Week 4", price: 0 },
        ],
        last_6_months_billing: [],
      };
      this.loadJobs();
      if (
        !this.type?.extras?.last_1_month_billing &&
        !this.type?.extras?.last_6_months_billing
      ) {
        return;
      }
      await this.fetchMasterAccounts();
      if (this.masterAccounts.length > 0) {
        let promises = [];
        for (let i = 0; i < this.masterAccounts.length; i++) {
          const element = this.masterAccounts[i];
          if (this.type?.extras["last_1_month_billing"]) {
            promises.push(this.last1MonthData(element));
          }
          if (this.type?.extras["last_6_months_billing"]) {
            promises.push(this.Last6MonthsData(element));
          }
        }
        Promise.all(promises).then((values: any) => {
          this.loadGraph("last_1_month_billing", "last_1_month_billing_graph");
          this.loadGraph(
            "last_6_months_billing",
            "last_6_months_billing_graph"
          );
        });
      }
    }
  }

  filterServices: any = [
    "Amazon Elastic Compute Cloud",
    "Amazon Relational Database Service",
    "Amazon Elastic Container Service",
    "CloudWatch Events",
    "Amazon Simple Storage Service",
    "AWS Lambda",
    "Amazon CloudFront",
    "Amazon Simple Queue Service",
    "Amazon Simple Email Service",
    "Amazon Simple Notification Service",
    "Amazon Route 53",
    "Elastic Load Balancing",
    "Amazon Elastic File System",
    "Amazon Athena",
    "Amazon API Gateway",
    "Amazon DynamoDB",
    "Amazon Cognito",
    "AWS Key Management Service",
    "Amazon Kinesis",
    "Amazon CloudFront",
  ];

  async fetchMasterAccounts() {
    this.masterAccounts = [];
    let data = {
      lid: [this.accountId],
      userType: this.customerType !== "mp" ? "C" : undefined,
      action: "fetchMasterAccList",
      ut: this.customerType || undefined,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    if (this.customerType == "mp") {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.masterAccounts = result.masterAccList;
    }
  }

  currentWeek(date: number): number {
    let week: number = 0;
    if (date > 0 && date <= 7) {
      week = 1;
    } else if (date >= 8 && date <= 14) {
      week = 2;
    } else if (date >= 15 && date <= 21) {
      week = 3;
    } else if (date >= 22) {
      week = 4;
    }
    return week;
  }

  async last1MonthData(mid: string) {
    return new Promise(async (resolve: any, reject: any) => {
      let data: any = {
        a: "fetchDateWiseTotalCost",
        sd: moment().subtract(2, "month").endOf("month").format("YYYY-MM-DD"),
        ed: moment().startOf("month").format("YYYY-MM-DD"),
        mId: mid,
        lId: this.accountId,
        ut: this.customerType || undefined,
      };

      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchdailybillingdetails`;

      if (this.customerType == "mp") {
        apiURL = `${APIService.API_ENDPOINTV3}/market/billing/daily`;
      }

      let header = {
        Authorization: localStorage.getItem("t"),
      };

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == "1" && result.documentsData.length > 0) {
        result.documentsData
          .sort((a: any, b: any) => {
            return a["billDate"] - b["billDate"];
          })
          .forEach((date: any) => {
            let temp = this.data["last_1_month_billing"].find((date_: any) => {
              return (
                date_["date"] ==
                `Week ${this.currentWeek(
                  Number(moment(date["billDate"]).format("DD"))
                )}`
              );
            });

            date.components.forEach((service: any, index_: number) => {
              if (!this.filterServices.includes(service["name"])) {
                return;
              }
              if (temp) {
                temp["price"] += service["finalTotalBill"];
              }
            });
          });
      }
      resolve(true);
    });
  }

  lastMonth: any = moment().subtract(1, "month").format("MMM YYYY");

  async Last6MonthsData(mid: string) {
    return new Promise(async (resolve: any, reject: any) => {
      let months = [0, 1, 2, 3, 4, 5].map((value: any) => {
        let month: string = moment()
          .subtract(6 - value, "months")
          .format("YYYY-MM");
        return month;
      });
      let data: any = {
        a: "fetchMonthlyTotalCost",
        mId: mid,
        lId: this.accountId,
        ml: months,
      };

      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchdailybillingdetails`;

      if (this.customerType == "mp") {
        apiURL = `${APIService.API_ENDPOINTV3}/market/billing/daily`;
      }

      let header = {
        Authorization: localStorage.getItem("t"),
      };

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == "1" && result.documentsData.length > 0) {
        result.documentsData.forEach((date: any) => {
          let temp = this.data["last_6_months_billing"].find((date_: any) => {
            return date_["date"] == moment(date["billDate"]).format("MMM YY");
          });
          let dict: any = {
            price: 0,
          };
          date.components.forEach((service: any, index: number) => {
            if (index == 0) {
              dict["date"] = moment(date["billDate"]).format("MMM YY");
              dict["timestamp"] = date["billDate"];
            }
            if (!this.filterServices.includes(service["name"])) {
              return;
            }
            dict["price"] += service["finalTotalBill"];
          });
          if (temp) {
            temp["price"] += dict["price"];
          } else {
            this.data["last_6_months_billing"].push(dict);
          }
        });
        this.data["last_6_months_billing"].sort((a: any, b: any) => {
          return a["timestamp"] - b["timestamp"];
        });
      }

      resolve(true);
    });
  }

  loadGraph(data_id: string, element_id: string) {
    let data = this.data[data_id];
    var chartDom = document.getElementById(element_id);
    let chart = echarts.init(chartDom);

    let option = {
      grid: {
        left: "10%",
        right: "10%",
        top: "10%",
        bottom: "10%",
      },
      tooltip: {
        formatter: (params: any) => {
          return `<strong>${params.name}</strong><br> $${params.value}`;
        },
      },
      xAxis: {
        type: "category",
        data: data.map((date: any) => {
          return date["date"];
        }),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value: any) => {
            return `$${value}`;
          },
        },
      },
      series: [
        {
          data: data.map((date: any) => {
            return Number(date["price"].toFixed(2));
          }),
          type: element_id == "last_1_month_billing_graph" ? "bar" : "line",
          smooth: true,
        },
      ],
    };

    option && chart.setOption(option);
  }

  async createJobCaller(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      let header = {
        Authorization: localStorage.getItem("t"),
      };

      let data = {
        action: "create_job",
        account_id: this.accountId,
        region_id: this.regionId,
        name: `SCAN${Math.floor(Math.random() * 10000)}`,
        ...this.type?.other?.payload,
      };

      let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}${this.type.api}`;

      let result = await this.apiService.postDataPromis(apiUrl, data, header);

      if (result.status == "1" || result.s == "1") {
        resolve(true);
      } else {
        this.notifier.alert("Info", "", result.error_message, "info", 5000);
        resolve(false);
      }
    });
  }

  interval: any = null;
  async loadJobs(job_id?: string, show: boolean = true) {
    console.log("type value in list jobs", this.type);
    if (show) {
      this.loading["jobs"] = true;
      this.page = 1;
    }

    if (this.type.scan_required == false) {
      this.notifier.loading(true);
    }

    let data: any = {
      action: "list_jobs",
      account_id: this.accountId,
      region_id: this.regionId,
      ...this.type?.other?.payload,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}${this.type.api}`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      if (result.jobs.length == 0 && this.type.scan_required == false) {
        const job: boolean = await this.createJobCaller();
        if (job) {
          setTimeout(() => {
            this.loadJobs(null, false);
          }, 5000);
        }
        return;
      }
      result.jobs = result.jobs.sort((a: any, b: any) => {
        let date_a: any = new Date(a["cr_time"]);
        let date_b: any = new Date(b["cr_time"]);
        return Date.parse(date_b) - Date.parse(date_a);
      });
      this.jobs = result.jobs.map((job: any) => {
        if (job["status"] == "Completed") {
          job["perc"] = ((job["passed"] / job["total_checks"]) * 100).toFixed(
            2
          );
        } else {
          job["perc"] = "0.0";
        }
        return job;
      });
      let temp: any;
      let check: boolean = false;
      this.jobs.forEach((job: any) => {
        job["cr_time"] = moment(job["cr_time"]).format("MMM DD, YYYY hh:mm A");
        job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
        if (job["status"] == "In progress") {
          check = true;
        }
        if (this.selectedJobID && job.job_id == this.selectedJobID) {
          temp = job;
        }
        if (!job["name"]) {
          job["name"] = job["job_id"];
        }
      });

      if (check) {
        if (
          this.type.slug?.toLowerCase().includes("eks") &&
          temp &&
          temp["status"] != "Completed"
        ) {
          this.interval = setTimeout(() => {
            this.loadJobs(null, false);
          }, 10000);
        } else if (!this.type.slug?.toLowerCase().includes("eks")) {
          this.interval = setTimeout(() => {
            this.loadJobs(null, false);
          }, 10000);
        }
      }

      if (show) {
        if (temp) {
          this.selectedJob = temp;
        } else if (this.type.scan_required == true) {
          this.navigator.navigate(
            ["/dash/security/advanced-security-protocol"],
            {
              fragment: this.type.slug,
            }
          );
        } else if (this.type.scan_required == false) {
          this.selectedJob = this.jobs[0];
          this.navigator.navigate(
            ["/dash/security/advanced-security-protocol"],
            {
              queryParams: { job_id: this.selectedJob.job_id },
              fragment: this.type.slug,
            }
          );
        }
      } else if (this.type.scan_required == false) {
        this.selectedJob = this.jobs[0];
        this.navigator.navigate(["/dash/security/advanced-security-protocol"], {
          queryParams: { job_id: this.selectedJob.job_id },
          fragment: this.type.slug,
        });
      }
    } else {
      this.jobs = [];
    }

    if (this.type.scan_required == false) {
      this.notifier.loading(false);
    }

    this.loading["jobs"] = false;
  }

  deleteJob: string = null;
  async deleteJobCaller(event: any, job: any) {
    event.stopPropagation();
    this.deleteJob = job;
  }

  closeDeletePopup(event: any) {
    if (event) {
      this.load();
    }

    this.deleteJob = null;
  }

  selectJob(job: any) {
    this.selectedJob = job;
    this.selectedJobID = job["job_id"];
    this.navigator.navigate(["/dash/security/advanced-security-protocol"], {
      queryParams: { job_id: this.selectedJobID },
      fragment: this.type.slug,
    });
    return true;
  }

  hideModel(event: string) {
    if (event) {
      this.loadJobs();
    }
    this.createJob = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
