<div class="card carddborder">
  <div class="card-header ec2-style">
    <h5>EC2 Instances</h5>
    <!-- <button class="btn btn-light" style="background-color: #5864FF; color: white;"
      (click)="downloadConsolidatedReport()">Consolidated Infra
      Report</button> -->
  </div>
  <div class="card-body cardborder">

    <div class="drop-down-display">
      <div class="dropdown dropdown-style" [ngStyle]="{'opacity': getStartTriggerInstances().length > 0 ? '1': '0'}"
        *ngIf="writeAccess">
        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
          id="instanceAction">
          Action
        </button>
        <div class="dropdown-menu" aria-labelledby="instanceAction">
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'run')">Start</a>
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'stop')">Stop</a>
        </div>
      </div>
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th></th>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}



            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,instancesList)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th>Tags</th>
          <th *ngIf="writeAccess">Start / Stop</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': instancesList.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of instancesList; let j = index">
        <tr *ngIf="notifier.filterTableColums(headers,i)">
          <td>
            <input autocomplete="off" type="checkbox" name="" id="" [(ngModel)]="i.actionStart">
          </td>
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn light" (click)="callFunction(h.click, i)">
              <span>
                {{i[h.id] || '-'}}
              </span>
            </span>
            <div *ngIf="!h.click">
              <span *ngIf="h.color">
                <span [ngStyle]="{'color': h.color(i[h.id])}">{{i[h.id] || '-'}}</span>
              </span>
              <span *ngIf="!h.color">
                <div *ngIf="h.statusCheck">
                  <div class='status_div' [ngClass]="{'danger': i[h.id] == 'stopped', 'success': i[h.id] == 'running'}">
                    <div class='status_indicator'></div>
                    <div class='status_text'>{{i[h.id] || '-'}}</div>
                  </div>
                </div>
                <span *ngIf="!h.statusCheck">
                  {{i[h.id] || '-'}}
                </span>
              </span>
            </div>
          </td>
          <td><span class="btn btn-light" (click)="showTags(i)">Tags</span> </td>
          <td *ngIf="i.instanceStatus == 'running' && writeAccess">
            <span class="btn btn-light" (click)="singleStartStop($event, i, 'stop')">Stop</span>
          </td>
          <td *ngIf="i.instanceStatus != 'running' && writeAccess">
            <span class="btn btn-light" (click)="singleStartStop($event, i, 'run')">Start</span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': instancesList.length == 0 && !loading ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 4">
            <div class="no-instances-found">No Instances Found in the <span class="bold-text">{{ regionId }}</span>.
              region. You can change the region on the top menu bar</div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td [attr.colspan]="headers.length + 4" class="center">
            <div>
              Looking for instances in <span class="bold-text">{{ regionId }}</span>...
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<tags-modal *ngIf="showTagsModal" modalTitle="Tags" instanceID={{showTagsModal.instanceId}} accountID={{accountId}}
  regionID={{regionId}} serviceName="ec2" (hideModal)="hideMdoal($event)"></tags-modal>
<ec2-details-view *ngIf="instance" [instance]="instance" (hideModal)="hideDetails($event)"
  (instanceStartStop)="instanceStartStop($event)"></ec2-details-view>
<instacne-start-stop *ngIf="command && writeAccess" [modalTitle]="modalTitle" [action]="action" [command]="command"
  [instances]="getStartTriggerInstances()" (hideModal)="hideStartStop()"></instacne-start-stop>