import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../api/api.service';
import { NotifierService } from '../../_services/notifier.service';

@Component({
  //moduleId: module.id.toString(),
  styleUrls: ['./monitoring.component.css'],
  templateUrl: './monitoring.component.html',
})
export class MonitoringComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  monitoringURL: String = '';

  accountId: any;
  regionId: any;
  currentMessage: any;
  detailsOf: any;
  funRef: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    if (!this.accountId) {
      this.notifier.alert('Error', '', 'Missing Account ID', 'error', 5000);
      this.notifier.loading(false);
      return;
    }
    let data = { accountId: this.accountId };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `https://api.swayam.cloud/prod/msopsusers/support?action=jira_sla_report&jira_project_key=SSI&jira_organization_key=Minfy%20Technologies&report_type=overall&start_date=2022-01-01&end_date=2022-01-31&frequency=month`;
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/monitoring/ems`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.monitoringURL = result.site24Url;
      document.getElementById('monitoringIframe').style.display = 'block';
      document
        .getElementById('monitoringIframe')
        .setAttribute('src', this.monitoringURL.toString());
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
      this.monitoringURL = '';
      document.getElementById('monitoringIframe').style.display = 'none';
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any, tickets_type: any) {
    this.funRef[name](this, param, tickets_type);
  }
}
