import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'cft-viewer',
    templateUrl: './cft-viewer.component.html',
    styleUrls: ['./cft-viewer.component.css']
})
export class CftViewerComponent implements OnInit {
    types: any = {
        'AWS::IAM::Role': 'IAM Role',
        'AWS::Serverless::Function': 'Lambda Function'
    }
    json: any = {
        "AWSTemplateFormatVersion": "2010-09-09T00:00:00.000Z",
        "Transform": "AWS::Serverless-2016-10-31",
        "Description": "IAM Role creation for the purpose of Role based authentication in SWAYAM.Cloud project.\n",
        "Parameters": {
            "StackVersion": {
                "Type": "String",
                "Default": "23-05-2023",
                "Description": "Version of the Cloud formation template"
            },
            "RootAdminEmailId": {
                "Type": "String",
                "Description": "Email id of the swayam root admin"
            }
        },
        "Rules": {
            "StackNameVerification": {
                "Assertions": [
                    {
                        "Assert": {
                            "Fn::Equals": [
                                "AWS::StackName",
                                "MinfySwayamStack"
                            ]
                        },
                        "AssertDescription": "Stack name must be `MinfySwayamStack`"
                    }
                ]
            }
        },
        "Resources": {
            "MSOpsSTSRole": {
                "Show": false,
                "Type": "AWS::IAM::Role",
                "Properties": {
                    "AssumeRolePolicyDocument": {
                        "Version": "2012-10-17T00:00:00.000Z",
                        "Statement": [
                            {
                                "Effect": "Allow",
                                "Principal": {
                                    "AWS": [
                                        837682544263
                                    ]
                                },
                                "Action": [
                                    "sts:AssumeRole"
                                ]
                            }
                        ]
                    },
                    "Description": "This role used for the cross account access to assume role and other activities from SWAYAM portal",
                    "ManagedPolicyArns": [
                        "arn:aws:iam::aws:policy/SecurityAudit",
                        "arn:aws:iam::aws:policy/job-function/ViewOnlyAccess",
                        "arn:aws:iam::aws:policy/job-function/SupportUser",
                        "arn:aws:iam::aws:policy/AWSSupportAccess"
                    ],
                    "Path": "/",
                    "Policies": [
                        {
                            "PolicyName": "EC2CustomPolicy",
                            "PolicyDocument": {
                                "Version": "2012-10-17T00:00:00.000Z",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "ec2:CopySnapshot",
                                            "ec2:ModifyInstanceAttribute",
                                            "ec2:DescribeInstanceTypes",
                                            "ec2:DescribeInstances",
                                            "ec2:DescribeRegions",
                                            "ec2:CreateImage",
                                            "ec2:DescribeSnapshots",
                                            "ec2:DescribeVolumeStatus",
                                            "ec2:StartInstances",
                                            "ec2:DescribeVolumes",
                                            "ec2:CreateSnapshot",
                                            "ec2:DescribeInstanceStatus",
                                            "ec2:RebootInstances",
                                            "ec2:DescribeTags",
                                            "ec2:CreateTags",
                                            "ec2:StopInstances",
                                            "ec2:CreateSnapshots",
                                            "ec2:DescribeInstanceTypes",
                                            "ec2:DescribeSubnets",
                                            "ec2:CreateTags",
                                            "ec2:DeleteTags"
                                        ],
                                        "Resource": "*"
                                    }
                                ]
                            }
                        },
                        {
                            "PolicyName": "RDSCustomPolicy",
                            "PolicyDocument": {
                                "Version": "2012-10-17T00:00:00.000Z",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "rds:StartDBCluster",
                                            "rds:DescribeDBSubnetGroups",
                                            "rds:CreateDBSubnetGroup",
                                            "rds:DescribeDBParameterGroups",
                                            "rds:DescribeDBInstances",
                                            "rds:DescribeDBParameters",
                                            "rds:DescribeEvents",
                                            "rds:DescribeDBClusterSnapshotAttributes",
                                            "rds:AddTagsToResource",
                                            "rds:StopDBCluster",
                                            "rds:DescribeDBSnapshots",
                                            "rds:DescribeDBSecurityGroups",
                                            "rds:StopDBInstance",
                                            "rds:DescribeDBSnapshotAttributes",
                                            "rds:StartDBInstance",
                                            "rds:ListTagsForResource",
                                            "rds:CreateDBSnapshot",
                                            "rds:RebootDBInstance",
                                            "rds:CreateDBCluster",
                                            "rds:DescribeDBClusterSnapshots",
                                            "rds:CreateDBClusterSnapshot",
                                            "rds:DescribeDBClusters",
                                            "rds:DescribeAccountAttributes",
                                            "rds:AddTagsToResource",
                                            "rds:RemoveTagsFromResource"
                                        ],
                                        "Resource": "*"
                                    }
                                ]
                            }
                        },
                        {
                            "PolicyName": "Others",
                            "PolicyDocument": {
                                "Version": "2012-10-17T00:00:00.000Z",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "kms:CreateGrant"
                                        ],
                                        "Resource": "*",
                                        "Condition": {
                                            "Bool": {
                                                "kms:GrantIsForAWSResource": true
                                            }
                                        }
                                    },
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "acm:RequestCertificate"
                                        ],
                                        "Resource": "*"
                                    },
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "cloudwatch:GetMetricData",
                                            "s3:GetBucketLocation"
                                        ],
                                        "Resource": "*"
                                    }
                                ]
                            }
                        },
                        {
                            "PolicyName": "AWSBackupPermissions",
                            "PolicyDocument": {
                                "Version": "2012-10-17T00:00:00.000Z",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "backup:ListRecoveryPointsByBackupVault",
                                            "backup:ListBackupVaults"
                                        ],
                                        "Resource": "*"
                                    }
                                ]
                            }
                        },
                        {
                            "PolicyName": "CloudFormationPolicy",
                            "PolicyDocument": {
                                "Version": "2012-10-17T00:00:00.000Z",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "cloudformation:*"
                                        ],
                                        "Resource": [
                                            "arn:aws:cloudformation:*:*:stack/MinfySwayamStack/*",
                                            "arn:aws:cloudformation:*:*:stack/MinfySwayamStack",
                                            "arn:aws:cloudformation:*:aws:transform/Serverless-2016-10-31"
                                        ]
                                    }
                                ]
                            }
                        },
                        {
                            "PolicyName": "CostExplorerPolicy",
                            "PolicyDocument": {
                                "Version": "2012-10-17T00:00:00.000Z",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "ce:GetCostAndUsage",
                                            "ce:GetCostForecast",
                                            "ce:GetRightsizingRecommendation"
                                        ],
                                        "Resource": "*"
                                    }
                                ]
                            }
                        },
                        {
                            "PolicyName": "WARpolicy",
                            "PolicyDocument": {
                                "Version": "2012-10-17T00:00:00.000Z",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": [
                                            "wellarchitected:ListWorkloads",
                                            "wellarchitected:GetAnswer",
                                            "wellarchitected:GetWorkload",
                                            "wellarchitected:CreateMilestone",
                                            "wellarchitected:ListMilestones",
                                            "wellarchitected:GetMilestone",
                                            "wellarchitected:CreateWorkload",
                                            "wellarchitected:UpdateAnswer",
                                            "wellarchitected:ListLenses",
                                            "wellarchitected:ListAnswers",
                                            "wellarchitected:UpdateWorkload",
                                            "backup:ListBackupPlans",
                                            "controltower:ListEnabledControls",
                                            "budgets:ViewBudget",
                                            "kms:ListKeyPolicies",
                                            "cloudtrail:ListTrails",
                                            "devops-guru:DescribeAccountHealth",
                                            "backup:DescribeGlobalSettings",
                                            "macie2:GetBucketStatistics",
                                            "networkmanager:DescribeGlobalNetworks",
                                            "dlm:GetLifecyclePolicies"
                                        ],
                                        "Resource": "*"
                                    }
                                ]
                            }
                        }
                    ],
                    "RoleName": "msopsstsrole",
                    "Tags": [
                        {
                            "Key": "Name",
                            "Value": "Minfy_AutoBot_msopsstsrole"
                        },
                        {
                            "Key": "Purpose",
                            "Value": "Serving SWAYAM portal role based auth"
                        },
                        {
                            "Key": "StackVersion",
                            "Value": "StackVersion"
                        },
                        {
                            "Key": "StackRegion",
                            "Value": "AWS::Region"
                        },
                        {
                            "Key": "RootAdminEmailId",
                            "Value": "RootAdminEmailId"
                        }
                    ]
                }
            },
            "TellMeFunction": {
                "New": true,
                "Show": false,
                "Type": "AWS::Serverless::Function",
                "Properties": {
                    "CodeUri": {
                        "Bucket": "swayam-cft-artifacts-${AWS::Region}",
                        "Key": "AWS/Lambda/Tellme/${StackVersion}/tellme.jar"
                    },
                    "Description": "SwayamTellMeFunction",
                    "FunctionName": "SwayamTellMeFunction",
                    "Handler": "com.swayam.TellMeProcessChecklist::handleRequest",
                    "MemorySize": 512,
                    "Policies": [
                        "AWSLambdaBasicExecutionRole",
                        {
                            "Version": "2012-10-17",
                            "Statement": [
                                {
                                    "Effect": "Allow",
                                    "Action": [
                                        "lambda:InvokeFunction",
                                        "s3:GetObject",
                                        "s3:ListBucket",
                                        "events:DescribeRule",
                                        "events:PutRule"
                                    ],
                                    "Resource": [
                                        "arn:aws:s3:::swayam-role-templates/*",
                                        "arn:aws:s3:::swayam-tellme/*",
                                        "arn:aws:s3:::swayam-tellme",
                                        "arn:aws:lambda:*:*:function:SwayamTellMeFunction",
                                        "arn:aws:events:*:*:rule/MinfySwayamStack-*"
                                    ]
                                }
                            ]
                        },
                        {
                            "Version": "2012-10-17",
                            "Statement": [
                                {
                                    "Effect": "Allow",
                                    "Action": [
                                        "cloudtrail:LookupEvents",
                                        "cloudwatch:GetMetricData"
                                    ],
                                    "Resource": "*"
                                }
                            ]
                        }
                    ],
                    "Runtime": "java11",
                    "Timeout": 300,
                    "Environment": {
                        "Variables": {
                            "RootAdminEmailId": "RootAdminEmailId",
                            "StackVersion": "StackVersion"
                        }
                    },
                    "Events": {
                        "CheckCompliance": {
                            "Type": "Schedule",
                            "Properties": {
                                "Description": "Triggers at every 30 minutes to check the compliance",
                                "Enabled": true,
                                "Schedule": "cron(*/30 * * * ? *)",
                                "RetryPolicy": {
                                    "MaximumRetryAttempts": 0
                                }
                            }
                        }
                    }
                }
            }
        },
        "Outputs": {
            "MSOpsSTSRoleArn": {
                "Description": "Share this role ARn with Dev/concerned Team",
                "Value": "MSOpsSTSRole.Arn"
            },
            "StackVersion": {
                "Description": "Version of the Cloud formation template",
                "Value": "StackVersion"
            },
            "RootAdminEmailId": {
                "Description": "Swayam root account email id",
                "Value": "RootAdminEmailId"
            }
        }
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    getObjectKeys(obj: any): any {
        return Object.keys(obj);
    }

    showResource(resource: string) {
        this.getObjectKeys(this.json['Resources']).forEach((resource_: string) => {
            this.json['Resources'][resource_]['Show'] = resource_ === resource;
        })
    }

    getStatement(policy: any) {
        return policy.hasOwnProperty('Statement') ? policy['Statement'][0] : policy['PolicyDocument']['Statement'][0]
    }

    typeOf(value: any) {
        return typeof value;
    }

}
