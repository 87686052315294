import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";

declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  styleUrls: ["./view.bom.component.css"],
  templateUrl: "./view.bom.component.html",
})
export class viewBOMComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  bomList: any = [];
  selectedBOM: any;
  deleteBOM: any;
  bomAction: string = "update";
  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: "os",
      name: "OS",
    },
    { id: "instanceType", name: "Instance Type" },
    { id: "cpu", name: "CPU" },
    {
      id: "disk",
      name: "Disk",
    },
    {
      id: "quantity",
      name: "Quantity",
    },
    {
      id: "ram",
      name: "Ram",
    },
    {
      id: "billingType",
      name: "Billing Type",
    },
    {
      id: "usageHours",
      name: "Usage in Hours",
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  updateBOMEntry(param: any) {
    this.bomAction = "update";
    this.selectedBOM = param;
  }

  addBOMEntry() {
    this.bomAction = "add";
    this.selectedBOM = {
      instanceType: "",
      os: "",
      cpu: "",
      disk: "",
      quantity: "",
      uuid: window.uuidv4(),
      ram: "",
      billingType: "",
      usageHours: "",
    };
  }

  hideModel(event: any) {
    if (event) {
      this.selectedBOM = event;
    }
    this.selectedBOM = null;
  }

  triggerDeleteEntry(param: any) {
    this.deleteBOM = param;
  }

  hideDeleteModel(event: any) {
    this.deleteBOM = null;
  }

  async load() {
    this.notifier.loading(true);
    let data = { accountId: this.accountId, action: "fetchBOMDetails" };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.bomList = result.BOMDetailsList;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    return;
  }
}
