import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "swayam-update-cft",
  templateUrl: "./update-cft.component.html",
  styleUrls: ["./update-cft.component.css"],
})
export class UpdateCftComponent implements OnInit {
  @Input("account") account: any;
  @Output("hideModel") hideModel: any = new EventEmitter<void>();
  whitelabelData: any = {};

  constructor(private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  ngOnInit(): void {}

  copied: boolean = false;
  async copyToClipboard(code: string) {
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(code);
        this.copied = true;
        setTimeout(()=> {
          this.copied = false;
        }, 3000)
      } catch (e) {
        console.error("Error while copying code", e);
      }
    }
  }

  getDummyResponse() {
    const json: any = {"StackId": `arn:aws:cloudformation:ap-south-1:${this.account['accountId']}:stack/MinfySwayamStack/3d0a6230-ea74-11ed-9b43-0a2e62466838`}
    return JSON.stringify(json, null, 1)
  }
}
