import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
@Component({
  selector: 'app-view-vpc',
  templateUrl: './view-vpc.component.html',
  styleUrls: ['./view-vpc.component.css']
})
export class ViewVpcComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() vpcList :any=[]
  activeIndex: number;
  expandedRows:any = [];
  constructor(public notifier: NotifierService) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }

 

  // toggleCollapsible(index: number): void {
  //   if (this.activeIndex === index) {
  //     this.activeIndex = undefined;
  //   } else {
  //     this.activeIndex = index;
  //   }
  // }

 

  

  toggleSubRow(rowIndex: any): void {
    
   this.expandedRows[rowIndex] = !this.expandedRows[rowIndex];
  }

  nottoggleSubRow(rowIndex: any): void {
    
    
   }
   ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}
