import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { APIService } from './../../api/api.service';
import { NotifierService } from './../../_services/notifier.service';
declare let window: any;
declare let $: any;

@Component({
  selector: 'create-ticket',
  templateUrl: './create.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class createTicketComponent implements OnInit, OnDestroy {
  @ViewChild('attachmenrs') attachmentElement: ElementRef;
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');

  additionalContacts: any = [''];
  ticketSeverity: any = 'low';
  supportType?: any = 'minfy';
  ticketDescription?: any = '';
  ticketSubject?: any = '';
  strictTicketType?: boolean = false;
  @Input() createTicketSubject: string;
  @Input() createTicketDescription: string;
  @Input() createTicketSupportType: string;
  @Input() createTicketStrictTicketType: boolean;
  @Output() hideCreateTicket = new EventEmitter<boolean>();
  attachment_ids: any = [];
  minfyDisabled: boolean = false;
  awsDisabled: boolean = false;
  services: any = [];

  loading = true;

  showDetails = false;
  accountId = '';
  region = '';
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  attachedfileData: any = [];

  categoriesList: any;
  categorySelected: any;
  serviceSelected: any;

  currentMessage: any;

  constructor(
    private apiServer: APIService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    if (this.createTicketStrictTicketType) {
      this.ticketSubject = this.createTicketSubject;
      this.ticketDescription = this.createTicketDescription;
      this.supportType = this.createTicketSupportType;
      this.strictTicketType = this.createTicketStrictTicketType;
    }
    setTimeout(() => {
      $('#serviceCodeSelect').selectpicker();
      $('#categoryCodeSelect').selectpicker();
      $('#serverityCodeSelect').selectpicker();
    }, 100);

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.addToFileList(docName, fileSize, targetDocBase64);
      },
      component: this,
    };

    this.accountId = localStorage.getItem('accountId');
    this.region = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.region = d.value;
        this.load();
      }
    });

    this.loading = true;
    if (!this.writeAccess) {
      this.router.navigate(['/dash/ticket/list']);
      return;
    }
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  load() {
    this.services = [];
    this.serviceSelected = null;
    this.categorySelected = null;
    this.awsDisabled = false;
    this.minfyDisabled = false;
    if (this.supportType == 'aws') {
      this.loadServices();
    }
    this.checkMinfySupport();
  }

  close() {
    this.hideCreateTicket.emit(true);
  }

  async checkMinfySupport() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (selectedAccountData.error) {
      } else {
        const account = selectedAccountData.data;
        if (
          !account.jira_org_id ||
          !account.jira_org_name ||
          !account.jira_project_id ||
          !account.jira_project_key ||
          !account.jira_project_name ||
          !account.site_247_project_id ||
          !account.site_247_project_name
        ) {
          this.minfyDisabled = true;
          this.changeSupportType('aws');
          this.notifier.alert(
            'Info',
            '',
            'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
            'info',
            5000
          );
        } else {
          this.minfyDisabled = false;
        }
      }
    } catch (err) {}
  }

  changeSupportType(support: string) {
    this.supportType = support;
    this.attachedfileData = [];
    this.attachment_ids = [];
    if (support == 'aws' && this.services.length == 0) {
      this.loadServices();
    }
  }

  changeService(event: any) {
    this.categoriesList = this.serviceSelected.categories;
    setTimeout(() => {
      $('#categoryCodeSelect').selectpicker('refresh');
    }, 100);
  }
  async loadServices() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: 'service_codes',
      account_id: this.accountId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.services = result.services;
      this.awsDisabled = false;
      setTimeout(() => {
        $('#serviceCodeSelect').selectpicker('refresh');
      }, 100);
      //this.services.selected = result.services[0];
      //this.categories.options = result.services[0].categories;
      //this.categories.selected = result.services[0].categories[0];
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error,
        //'Business support plan or Enterprise support plan is required for this feature',
        'info',
        5000
      );
      this.awsDisabled = true;
      if (!this.minfyDisabled) {
        this.changeSupportType('minfy');
      }
    }
    this.notifier.loading(false);
  }

  deleteSelectedAdditionalContact(index: number) {
    this.additionalContacts.splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.additionalContacts.length - 1 == index
    ) {
      this.additionalContacts.push('');
    } else if (
      value == '' &&
      value.length == 0 &&
      this.additionalContacts.length - 2 == index &&
      this.additionalContacts[this.additionalContacts.length - 1] == ''
    ) {
      this.additionalContacts.splice(this.additionalContacts.length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.additionalContacts.push('');
  }

  trackByFn() {
    return true;
  }

  triggerFIle() {
    this.attachmentElement.nativeElement.click();
  }

  niceBytes(x: any) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  addToFileList(docName: string, fileSize: any, base64Data: any) {
    let parsedData = base64Data.split('base64,');
    this.attachedfileData.push({
      fileName: docName,
      fileSize: fileSize,
      data: parsedData.length > 1 ? parsedData[1] : parsedData[0],
    });
  }

  deleteSeletedFile(indexx: number) {
    this.attachedfileData.splice(indexx, 1);
    if (this.attachedfileData.length == 0) {
      $('#attachments').val('');
    }
  }

  async convertFiles(fileList: any) {
    let promises = [];
    for (var i = 0; i < fileList.length; i++) {
      let filePromise = new Promise(async (resolve: any, reject: any) => {
        var fileToLoad = fileList[i];

        var fileReader = new FileReader();
        var base64: any;
        let fileName = fileList[i].name;
        let fileSize = fileList[i].size;
        fileReader.onload = (fileLoadedEvent: any) => {
          base64 = fileLoadedEvent.target.result;
          window.angularComponentReference.zone.run(() => {
            window.angularComponentReference.componentFn(
              fileName,
              fileSize,
              base64
            );
          });
          resolve({ fileName: fileName, fileSize: fileSize, data: base64 });
        };

        fileReader.readAsDataURL(fileToLoad);
        return;
      });
      promises.push(filePromise);
    }
    Promise.all(promises).then((fileContents) => {
      this.submitAttachments();
    });
  }

  async getFile(fileLoaded: any) {
    let fileList: any = fileLoaded.target.files;
    let total = 0;
    fileList.forEach((element: any) => {
      total += element.size;
    });
    if (total / (1024 * 1024) > 5) {
      this.notifier.alert(
        'Error',
        '',
        'File Size cant be more than 5 MB.',
        'error',
        5000
      );
      return;
    }
    await this.convertFiles(fileList);
    $('#attachments').val('');
  }

  async submitAttachments() {
    this.notifier.loading(true);

    if (this.supportType == 'minfy') {
      try {
        let selectedAccountData: any = await this.fetchSelectedAccountDetails();
        if (!selectedAccountData.error) {
          const { jira_org_id, jira_project_id } = selectedAccountData.data;
          let data = {
            action: 'jira_attachment',
            attachments: this.attachedfileData.map((file: any) => {
              return { fileName: file.fileName, data: file.data };
            }),
            jira_project_id: jira_project_id,
            jira_organization_id: jira_org_id,
          };
          let header = {
            Authorization: localStorage.getItem('t'),
          };
          //https://api.swayam.cloud/v3/admin/support
          let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == '1' || result.s == '1') {
            this.attachment_ids = result.attachment_ids;
          } else {
            this.notifier.alert('Error', '', result.error, 'error', 5000);
          }
        } else {
          this.notifier.alert(
            'Error',
            '',
            selectedAccountData.msg,
            'error',
            5000
          );
        }
      } catch (err) {}
    } else if (this.supportType == 'aws') {
      let data = {
        action: 'attachment',
        attachments: this.attachedfileData.map((file: any) => {
          return { fileName: file.fileName, data: file.data };
        }),
        account_id: this.accountId,
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.attachment_ids = result.message;
      } else {
        this.notifier.alert('Error', '', result.error, 'error', 5000);
      }
    }
    this.notifier.loading(false);
  }

  async createTicket(event: any) {
    event.preventDefault();
    if (!this.writeAccess) {
      return;
    }
    if (
      (this.supportType == 'minfy' && this.minfyDisabled) ||
      (this.supportType == 'aws' && this.awsDisabled)
    ) {
      return;
    }
    let textData = this.ticketDescription;
    if (this.supportType == 'minfy' && !this.minfyDisabled) {
      try {
        let selectedAccountData: any = await this.fetchSelectedAccountDetails();
        if (!selectedAccountData.error) {
          const {
            jira_org_id,
            jira_project_key,
            jira_project_name,
            site_247_project_id,
            site_247_project_name,
            jira_project_id,
          } = selectedAccountData.data;
          this.notifier.loading(true);

          let data: any = {
            action: 'create',
            account_id: this.accountId,
            severity_code: this.ticketSeverity,
            communication_body: textData, // this.ticketDescription,
            subject: this.ticketSubject,
            attachment_set_id: this.attachment_ids,
            ticket_on: 'jira',
            service_code: ' ',
            category_code: ' ',
            jira_project_id: jira_project_id,
            jira_organization_id: jira_org_id,
            user_email: this.eId,
            site_247_project_name: site_247_project_name,
            site_247_project_id: site_247_project_id,
            jira_project_name: jira_project_name,
            jira_project_key: jira_project_key,
            cc_email_addresses: this.additionalContacts.filter(
              (contact: any) => {
                return contact != '';
              }
            ),
          };
          let header = {
            Authorization: localStorage.getItem('t'),
          };
          //https://api.swayam.cloud/v3/admin/support
          let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == '1' || result.s == '1') {
            this.ticketSubject = '';
            this.ticketDescription = '';
            this.attachment_ids = [];
            this.attachedfileData = [];
            this.additionalContacts = [''];
            this.notifier.alert(
              'Success',
              '',
              `Ticket Created Successfully. Ticket ID: ${result.message.case_id}`,
              'success',
              5000
            );
          } else {
            this.notifier.alert(
              'Error',
              '',
              result.error_message,
              'error',
              5000
            );
          }
        } else {
          this.notifier.alert(
            'Error',
            '',
            selectedAccountData.msg,
            'error',
            5000
          );
        }
      } catch (err) {}
    } else if (this.supportType == 'aws' && !this.awsDisabled) {
      let data = {
        action: 'create',
        account_id: this.accountId,
        service_code: this.serviceSelected.code,
        category_code: this.categorySelected.code,
        severity_code: this.ticketSeverity,
        communication_body: this.ticketDescription,
        subject: this.ticketSubject,
        attachment_set_id: this.attachment_ids,
        cc_email_addresses: this.additionalContacts.filter((contact: any) => {
          return contact != '';
        }),
        ticket_on: 'aws',
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
      } else {
        this.ticketSubject = '';
        this.ticketDescription = '';
        this.attachment_ids = [];
        this.attachedfileData = [];
        this.additionalContacts = [''];
        if (result.message && result.message.case_id) {
          this.notifier.alert(
            'Success',
            '',
            `Ticket Created Successfully. Ticket ID: ${result.message.case_id}`,
            'success',
            5000
          );
        } else if (result.message && !result.message.case_id) {
          this.notifier.alert('Error', '', `${result.message}`, 'error', 5000);
        }
      }
    }
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }
}
