import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "billing-cft-missing",
  templateUrl: "./cft-missing.component.html",
  styleUrls: ["./cft-missing.component.css"],
})
export class BillingCftMissingComponent implements OnInit {
  imageURL: string = null;
  @Input('tellMes') tellMes: any;
  @Input('types') types: any;
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  review: boolean = false;

  constructor(private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  features: any = [
    {
      title: "Monthly Bill",
      description:
        `View and Export Monthly usage reports from ${ this.whitelabelData['pd']['appName'] }`,
      img: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/tell-me/configure-tell-me.png",
      textalign: "left",
    },
    {
      title: "Daily Bill",
      description:
       `View and Export Daily usage reports from ${ this.whitelabelData['pd']['appName'] }`,
      img: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/tell-me/email-tell-me.png",
      textalign: "right",
    },
    {
      title: "Cost Anomaly Report",
      description:
        `Get 90 days historical data of the events/incidents, recorded using ${ this.whitelabelData['pd']['appName'] }`,
      img: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/tell-me/history-tell-me.png",
      textalign: "left",
    },
  ];

  ngOnInit(): void {}
}
