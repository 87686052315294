<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Storage Per Region</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
          <div class="KBBody table-responsive ">

            <ng-container class="table-responsive">
        
         
         
              <table class="table table-hover  m-0 p-0">
                <thead class="blue fixed-header table-header-sticky">
                  <tr>
                    <td scope="col" class="hsticky"></td>
                    <ng-container *ngFor="let header of regionHeader;">
                    <td class="text-center" scope="col">{{header}}</td>
                  </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let inst of StorageTypes;" class="scrollable-body mb-0">
                  <tr class="pointer" (click)="toggleItem(inst)">
                    <td  class="sticky bold"><span><i [class]="inst.isHidden ? 'fa-regular fa-square-minus' : 'fa-regular fa-square-plus'"></i></span>&nbsp;   {{inst.id}} </td>
                    
                  </tr>
                  <ng-container *ngIf=inst.isHidden>
                  <tr *ngFor="let reg of inst.regionData;">
                    <td class="sticky mr-2">{{formatType(reg.type)}}</td>
                    <ng-container *ngFor="let val of reg.values;">
                    <td class="text-center" [ngClass]="{'bold2': val != '0.00 MB'}">{{val}}</td>
                  </ng-container>
                  </tr>
                 
                </ng-container>
              </ng-container>
               
                </tbody>
              </table>
          
          
        </ng-container>

            <!-- <ng-container *ngFor="let st of StorageTypes;" class="scrollable-body mb-0">
                   
              <div class="card mt-1">
                <div class="card-header d-flex justify-content-between">
               <b> {{st.id}} </b> <b class="mright-15">All Regions</b>
              </div>
           <div class="card-body">
            <table class="table table-hover  m-0 p-0">
              <thead class="blue fixed-header">
                <tr>
                  <td scope="col">S.N.</td>
                  <td  scope="col">Service</td> 
                  <td class="text-center" scope="col">Total Size</td>   
                </tr>
              </thead>
              <tbody>
              <tr *ngFor="let tg of st.cost;let j = index">
              <td>{{j+1}}</td>
               <td >{{tg.key}}</td>
               <td class="text-center">{{tg.value}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
            </ng-container> -->
            
              <!-- <div class="btnHold">
                <div class="bhold">
                  <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
                  <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
                  [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
                </div>
              </div> -->
    
    
            <!-- <div class="KBActions">
              <button class="btn btn-light" (click)="no()">Cancel</button>
            </div> -->
          
        </div>
          
</div>

