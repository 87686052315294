<div class="update-popup">
  <div class="update-popup-container">
    <div class="update-popup-header flex-between">
      <div>
        Update CFT
      </div>
      <div>
        <i class="fa fa-times cursor-pointer" (click)="hideModel.emit()"></i>
      </div>
    </div>
    <div class="update-popup-body">
      <div>
        Follow the instructions below to update {{ whitelabelData['pd']['appName'] }} CFT in your AWS Account
      </div>
      <div>
        <ol>
          <li>Login into Your AWS Account</li>
          <li>
            <a href="{{account['cft_latest_url']['Url']}}" target="_blank">Click here</a> to open Cloudshell in your AWS
            Account
          </li>
          <li>
            <div>Run the below command from your AWS Account Cloudshell to update the {{ whitelabelData['pd']['appName'] }} CFT</div>
            <div class="code">
              <code>{{ account['cft_latest_url']['Command'] }}</code>
              <div class="floating-copy" *ngIf="!copied" (click)="copyToClipboard(account['cft_latest_url']['Command'])">
                <i class="fa fa-clone" aria-hidden="true"></i>
              </div>
              <div class="floating-copy copied" *ngIf="copied">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
            </div>
          </li>
          <li>
            <div>On Successful completion of update, below is the expected output</div>
            <div class="code">
              <pre>
                <code [innerHTML]="getDummyResponse()">
                </code>
              </pre>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>