import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "cost-optimization-create-job-ftr",
  templateUrl: "./create-job-ftr.component.html",
  styleUrls: ["./create-job-ftr.component.css"],
})
export class CostOptimizationCreateJobFtrComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Output() hideMadal = new EventEmitter<any>();
  @Input() regionId: string;
  @Input() accountId: string;
  @Input("type") type: any;
  @Input() refresh: ((job_id?: any) => void) | undefined;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = "";

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false, jobId?: any) {
    if (this.refresh && check) {
      this.hideMadal.emit({ check: check, jobId: jobId });
    } 
    else {
      this.hideModal.emit(check);
      this.hideMadal.emit(check);

    }
  
  }

  errors: any = {
    scan_name: {
      is_error: false,
      error: "",
    },
  };
  async createJob() {
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "create_job",
      account_id: this.accountId,
      region_id: this.regionId,
      name: `SCAN${Math.floor(Math.random() * 10000)}`,
      ...this.type?.other?.payload,
    };

    let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}${this.type.api}`;

    let result = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Info", "", result.message, "info", 5000);
      this.no(true, result.job_id);
      if (this.refresh) {
        this.refresh(result.job_id);
      }
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
      this.refresh(result.job_id);
    }
    this.notifier.loading(false);
  }
}
