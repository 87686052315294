<div class="instance_more_details" #instance_more_details>
  <div class="dragger_div">
    <div class="dragger" (mousedown)="initDragEC2($event)">=</div>
    <div class="close_details" (click)="removeDetailsView($event)">x</div>
  </div>
  <div class="detail_header">
    Instance:
    <span class="instance_id_header">{{ instance.instanceId }}</span> (<span class="instance_name_header">{{
      instance.tagName }}</span>)
  </div>
  <div class="details_container">
    <div class="details_tabs_div">
      <div name="details" (click)="changeTab('details')" class="details_tabs_div_tab details_tab"
        *ngIf="checkForTabs('details')" [ngClass]="{ details_active_tab: ec2InstanceDetails.type == 'details' }">
        Details
      </div>
      <div name="security" (click)="changeTab('platform')" class="details_tabs_div_tab platform_tab"
        *ngIf="checkForTabs('platform')" [ngClass]="{
          details_active_tab: ec2InstanceDetails.type == 'platform'
        }">
        Platform
      </div>
      <div name="network" (click)="changeTab('network')" class="details_tabs_div_tab network_tab"
        *ngIf="checkForTabs('network')" [ngClass]="{
          details_active_tab: ec2InstanceDetails.type == 'network'
        }">
        Network
      </div>
      <div name="security" (click)="changeTab('security')" class="details_tabs_div_tab security_tab"
        *ngIf="checkForTabs('security')" [ngClass]="{
          details_active_tab: ec2InstanceDetails.type == 'security'
        }">
        Security
      </div>
      <div name="storage" (click)="changeTab('storage')" *ngIf="checkForTabs('storage')"
        class="details_tabs_div_tab storage_tab"
        [ngClass]="{ details_active_tab: ec2InstanceDetails.type == 'storage' }">
        Volumes
      </div>
      <div name="monitoring" (click)="changeTab('monitoring')" class="details_tabs_div_tab monitoring_tab flex-start"
        *ngIf="checkForTabs('monitoring')" [ngClass]="{
          details_active_tab: ec2InstanceDetails.type == 'monitoring'
        }">
        Monitoring
        <span class="color-inherit" *ngIf="instance['from']">({{instance['from']}})</span>
      </div>
      <div name="storage" (click)="changeTab('tags')" *ngIf="checkForTabs('tags')" class="details_tabs_div_tab tags_tab"
        [ngClass]="{ details_active_tab: ec2InstanceDetails.type == 'tags' }">
        Tags
      </div>
    </div>
    <div class="main_details_page">
      <div class="details_tab_section section_view" *ngIf="ec2InstanceDetails.type == 'details'">
        <div class="grid_tab_card">
          <div class="details_key">Instance ID</div>
          <div class="details_value">
            <span class="instance_id_body">{{ instance.instanceId || '-' }}</span>
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Image ID</div>
          <div class="details_value">
            <span class="instance_id_body">{{ instance.imageID || '-' }}</span>
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Platform</div>
          <div class="details_value">
            <span class="instance_id_body">{{ instance.platform || '-' }}</span>
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Instance Status</div>
          <div class="details_value">
            <div class="instance_status_body instancestatusstyle" [ngClass]="{
                        red: instance.instanceStatus == 'stopped' || instance.state == 'VM stopped' || instance.state == 'VM deallocated',
                        green: instance.instanceStatus == 'running' || instance.state == 'VM running'
                      }">
              {{ instance.instanceStatus || instance.state || '-' }} <span class="black"><span *ngIf="writeAccess">|</span> </span>
              <span class="btn blue p-0" [ngClass]="{'click-disabled': !writeAccess}"
                [attr.disabled]="!writeAccess ? true : null" *ngIf="writeAccess && (instance.instanceStatus == 'running' || instance.state == 'VM running')"
                (click)="stopInstance()">Stop</span>
              <span class="btn blue p-0" [ngClass]="{'click-disabled': !writeAccess}"
                [attr.disabled]="!writeAccess ? true : null" *ngIf="writeAccess && (instance.instanceStatus == 'stopped' || instance.state == 'VM stopped' || instance.state == 'VM deallocated')"
                (click)="startInstance()">Start</span>
            </div>
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Instance Type</div>
          <div class="details_value instance_type_body">
            {{ instance.instanceType || '-' }}
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Tag Name</div>
          <div class="details_value instance_type_body">
            {{ instance.tagName || '-' }}
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Availability Zone</div>
          <div class="details_value instance_az_body">{{ instance.availabilityZone || '-' }}</div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Public IPv4 address</div>
          <div class="details_value instance_public_ip_body">
            {{ instance.publicIp || '-' }}
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Private IPv4 addresses</div>
          <div class="details_value instance_private_ip_body">
            {{ instance.privateIp || '-' }}
          </div>
        </div>
      </div>
      <div class="platform_tab_section section_view" *ngIf="ec2InstanceDetails.type == 'platform'">
        <div class="grid_tab_card">
          <div class="details_key">OS</div>
          <div class="details_value">
            <span class="instance_id_body">{{ (instanceData && instanceData.PlatformDetails) || instance['platform'] || '-' }}</span>
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Architecture</div>
          <div class="details_value">
            <span class="instance_id_body">{{ (instanceData && instanceData.Architecture) || instance['architechture'] || '-' }}</span>
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">Hypervisor</div>
          <div class="details_value">
            <span class="instance_id_body">{{ (instanceData && instanceData.Hypervisor) || instance['hypervisor'] || '-' }}</span>
          </div>
        </div>
      </div>
      <div class="network_tab_section section_view" *ngIf="ec2InstanceDetails.type == 'network'">
        <div class="grid_tab_card">
          <div class="details_key">{{cloudPartner == 'aws'  ? 'VPC ID' : 'VNet ID'}}</div>
          <div class="details_value">
            <span class="instance_id_body">{{ (instanceData && instanceData.VpcId) || instance['VpcId'] }}</span>
          </div>
        </div>
        <div class="grid_tab_card">
          <div class="details_key">{{cloudPartner == 'aws'  ? 'Subnet ID' : 'Security Group'}}</div>
          <div class="details_value">
            <span class="instance_id_body">{{ (instanceData && instanceData.SubnetId) || instance['SubnetId'] }}</span>
          </div>
        </div>
      </div>
      <div class="security_tab_section" style="height: 100%" *ngIf="ec2InstanceDetails.type == 'security'">
        <sg-reports [sgFilter]="sgDetailsList" [filterRequired]="cloudPartner == 'aws' ? true : false" [cloudPartner]="cloudPartner"></sg-reports>
      </div>
      <div class="storage_tab_section" style="height: 100%" *ngIf="ec2InstanceDetails.type == 'storage'">
        <volume-ec2 [instance]="instanceData ? instanceData?.BlockDeviceMappings : instance['BlockDeviceMappings']"></volume-ec2>
      </div>

      <ng-container *ngIf="ec2InstanceDetails.type == 'monitoring'">
        <div class="monitoring_tab_section section_view" style="height: 100%" *ngIf="!instance['hide_matrics']">
          <div class="grid_tab_card">
            <div class="details_key">CPU Utilization</div>
            <div class="details_value instance_type_body" *ngIf="instance.cpu != 'LOADING'">
              <div>{{ instance.cpu != '-' ? instance.cpu + '%': '-' || 0.0 }}</div>
              <div class="instance-progress-bar"
                [ngClass]="{'red-progress': instance.cpu > 65, 'green-progress': instance.cpu < 65}">
                <div class="instance-progress" [attr.style]="'width: ' + instance.cpu + '%'"></div>
              </div>
            </div>
            <div class="details_value instance_type_body" *ngIf="instance.cpu == 'LOADING'">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 15px; margin: 10px;">
            </div>
          </div>
          <div class="grid_tab_card" *ngIf="cloudPartner == 'aws'">
            <div class="details_key">RAM Utilization</div>
            <div class="details_value instance_type_body" *ngIf="instance.ram != 'LOADING'">
              <div>{{ instance.ram != '-' ? instance.ram + '%': '-' || 0.0 }}</div>
              <div class="instance-progress-bar"
                [ngClass]="{'red-progress': instance.ram > 65, 'green-progress': instance.ram < 65}">
                <div class="instance-progress" [attr.style]="'width: ' + instance.ram + '%'"></div>
              </div>
            </div>
            <div class="details_value instance_type_body" *ngIf="instance.ram == 'LOADING'">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 15px; margin: 10px;">
            </div>
          </div>
          <div class="grid_tab_card" *ngIf="cloudPartner == 'aws'">
            <div class="details_key">Disk Utilization</div>
            <div class="details_value instance_type_body" *ngIf="instance.disk != 'LOADING'">
              <div>{{ instance.disk != '-' ? instance.disk + '%': '-' || 0.0 }}</div>
              <div class="instance-progress-bar"
                [ngClass]="{'red-progress': instance.disk > 65, 'green-progress': instance.disk < 65}">
                <div class="instance-progress" [attr.style]="'width: ' + instance.disk + '%'"></div>
              </div>
            </div>
            <div class="details_value instance_type_body" *ngIf="instance.disk == 'LOADING'">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 15px; margin: 10px;">
            </div>
          </div>
          <div class="grid_tab_card" *ngIf="cloudPartner == 'azure'">
            <div class="details_key">Data Disk Utilization</div>
            <div class="details_value instance_type_body" *ngIf="instance.avg_data_disk_utilization != 'LOADING'">
              <div>{{ instance.avg_data_disk_utilization != '-' ? instance.avg_data_disk_utilization + '%': '-' || 0.0 }}</div>
              <div class="instance-progress-bar"
                [ngClass]="{'red-progress': instance.avg_data_disk_utilization > 65, 'green-progress': instance.avg_data_disk_utilization < 65}">
                <div class="instance-progress" [attr.style]="'width: ' + instance.avg_data_disk_utilization + '%'"></div>
              </div>
            </div>
            <div class="details_value instance_type_body" *ngIf="instance.avg_data_disk_utilization == 'LOADING'">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 15px; margin: 10px;">
            </div>
          </div>
          <div class="grid_tab_card" *ngIf="cloudPartner == 'azure'">
            <div class="details_key">OS Disk Utilization</div>
            <div class="details_value instance_type_body" *ngIf="instance.avg_os_disk_utilization != 'LOADING'">
              <div>{{ instance.avg_os_disk_utilization != '-' ? instance.avg_os_disk_utilization + '%': '-' || 0.0 }}</div>
              <div class="instance-progress-bar"
                [ngClass]="{'red-progress': instance.avg_os_disk_utilization > 65, 'green-progress': instance.avg_os_disk_utilization < 65}">
                <div class="instance-progress" [attr.style]="'width: ' + instance.avg_os_disk_utilization + '%'"></div>
              </div>
            </div>
            <div class="details_value instance_type_body" *ngIf="instance.avg_os_disk_utilization == 'LOADING'">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 15px; margin: 10px;">
            </div>
          </div>
        </div>
        <div class="mt-4" *ngIf="checkForBU() && instance['from'] == 'AWS Cloud Watch'">
          <div>* Configure EMS to display additional metrics such as memory and disk usage. This involves
            additional pricing. <span class="nav-link" (click)="addMonitor()">click here</span> to configure the same
          </div>
        </div>
      </ng-container>
      <div class="tags_tab_section" style="height: 100%" *ngIf="ec2InstanceDetails.type == 'tags'">
        <div>
          <div style="display: flex; justify-content: flex-end; padding: 10px;" *ngIf="cloudPartner == 'aws'">
            <button class="btn btn-light" (click)="manageTags()">Manage Tags</button>
          </div>
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th *ngFor="let h of tagsHeader">
                  {{ h.name }}
                  <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, (instanceData ? instanceData.Tags : instance['Tags']))">
                    <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                    <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                  </span>
                  <span *ngIf="h.filter " class="inputseach">
                    <input type="text" [(ngModel)]="h.filterText" />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody [ngStyle]="{'display': (instanceData ? instanceData.Tags : instance['Tags']).length > 0 ? 'table-row-group' : 'none'}">
              <ng-container *ngFor="let i of (instanceData ? instanceData.Tags : instance['Tags']); let j = index">
                <tr *ngIf="notifier.filterTableColums(tagsHeader ,i)">
                  <td>{{j + 1}}</td>
                  <td *ngFor="let h of tagsHeader">
                    <span *ngIf="!h.click">{{h.alter ? h.alter(i, h.id) : i[h.id]}}</span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tbody *ngIf="(instanceData ? instanceData.Tags : instance['Tags']).length == 0 && !loading">
              <tr>
                <td [attr.colspan]=" (tagsHeader).length + 1">
                  <div style="text-align: center;">No Tags Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="loader_section">
    <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px" />
  </div>
</div>
<tags-modal *ngIf="showTagsModal" modalTitle="Tags" instanceID={{instance.instanceId}} accountID={{accountId}}
  regionID={{regionId}} serviceName="ec2" (hideModal)="hideMdoal($event)"></tags-modal>