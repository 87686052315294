import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let window: any;
declare let $: any;
@Component({
  templateUrl: './rdsriorder.component.html',
  styleUrls: ['./rdsriorder.component.css'],
 
})
export class RDSRIOrderComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  riData: any = [];
  updateRI: any;
  updateAction: any = 'update';

  accountId: any;
  regionId: any;
  currentMessage: any;
  unsavedRI: any = [];

  headers: any = [
    {
      id: 'dbInstanceType',
      name: 'DB Instance Type',
    },
    {
      id: 'dbEngine',
      name: 'DB Engine',
    },
    {
      id: 'region',
      name: 'Region',
    },
    {
      id: 'deploymentType',
      name: 'Deployment Type',
    },
    {
      id: 'quantity',
      name: 'Quantity',
    },
    {
      id: 'riType',
      name: 'RI Type',
    },
    {
      id: 'discountPercentage',
      name: 'Discount (%)',
    },
    {
      id: 'riCost',
      name: 'RI Hour Cost',
    },
    {
      id: 'startDate',
      name: 'Start Date',
    },
    {
      id: 'endDate',
      name: 'End Date',
    },
  ];

  funRef: any = {};
  deleteRI: any;
  unsavedUpdateId: number = -1;

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.notifier.loading(true);

    let data = { accountId: this.accountId, action: 'fetch' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/fetchrdsripurchaseorder`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.riData = result.riRdsDetailsList;
    } else {
      this.riData = [];
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  updateTrigger(id: any) {
    this.updateAction = 'update';
    this.updateRI = id;
  }

  deleteUnsavedRI(id: number) {
    this.unsavedRI.splice(id, 1);
  }

  updateUnsavedRI(id: any, index: number) {
    this.updateAction = 'save';
    this.unsavedUpdateId = index;
    this.updateRI = id;
  }

  closeUpdateTrigger(event: any) {
    console.log(event, this.updateAction, this.unsavedUpdateId, this.updateRI);
    if (event) {
      if (this.updateAction == 'update') {
        this.load();
      } else {
        if (this.unsavedUpdateId > -1) {
          this.unsavedRI[this.unsavedUpdateId] = event.riDetailsList[0];
          this.unsavedUpdateId = -1;
        } else {
          this.unsavedRI.push(event.riDetailsList[0]);
        }
      }
    }

    this.updateRI = null;
  }

  async deleteTrigger(id: any) {
    this.notifier.loading(true);
    let data: any = {
      accountId: this.accountId,
      action: 'delete',
      id: id.id,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/fetchrdsripurchaseorder`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  closeDeleteTrigger(event: any) {
    this.deleteRI = null;
  }

  addPurchase() {
    this.updateAction = 'save';
    this.unsavedUpdateId = -1;
    this.updateRI = {
      dbInstanceType: '',
      dbEngine: '',
      deploymentType: 'Single-AZ',
      region: this.regionId,
      riType: 'OnDemand',
      endDate: moment().startOf('day').format('YYYY-MM-DD'),
      startDate: moment().startOf('day').subtract(1).format('YYYY-MM-DD'),
      quantity: 0,
      id: window.uuidv4(),
      discountPercentage: '0',
      riCost: 0,
    };
  }

  async saveDetails() {
    this.notifier.loading(true);
    let data: any = {
      accountId: this.accountId,
      action: 'save',
      riDetailsList: this.unsavedRI.map((ri: any) => {
        Object.keys(ri).forEach((ri_: any) => {
          ri[ri_] = ri[ri_] || 'NA';
        });
        delete ri.id;
        ri.quantity = `${ri.quantity}`;
        return ri;
      }),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string =
      'https://api.swayam.cloud/prod/msopsusers/billing/fetchrdsripurchaseorder';

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.unsavedRI = [];
      this.load();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
