import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-validate-account-cams",
  templateUrl: "./validate-account-cams.component.html",
  styleUrls: ["./validate-account-cams.component.css"],
})
export class ValidateAccountCamsComponent implements OnInit, OnDestroy {
  accountName: string = "";
  roleARN: string = "";
  @Output("hideModel") hideModel: any = new EventEmitter<any>();

  constructor(private apiServer: APIService) {}

  loading: boolean = false;
  destroyed: boolean = false;
  error: string = null;

  async close(event: boolean) {
    this.error = null;
    if (!event) {
      this.hideModel.emit({
        status: event,
        data: event
          ? { accountName: this.accountName, roleARN: this.roleARN }
          : undefined,
      });
      return;
    }

    this.loading = true;
    let data: any = {
      action: "add",
      account_name: this.accountName,
      role_arn: this.roleARN,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/account`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (this.destroyed) return;

    if (result.status == "1" && result.s == "1") {
      this.hideModel.emit({
        status: event,
        data: event
          ? { accountName: this.accountName, roleARN: this.roleARN }
          : undefined,
      });
    } else {
      this.error = result.error_message;
    }

    console.log(result);

    this.loading = false;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed = true;
  }
}
