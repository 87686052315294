import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import { DashService } from "src/app/dash/dash.service";


declare let $: any;
declare let window: any;

@Component({
  selector: "app-greengame",
  templateUrl: "./greengame.component.html",
  styleUrls: ["./greengame.component.css"],
})
export class GreenGameComponent implements OnInit, OnDestroy {
  jobs: any = [];
  createJob: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  urlPre: any = localStorage.getItem("role") == "Admin" ? "admin" : "market";
  acc_cftstatus :boolean = false;
  accountId: string;
  scaninprogress: boolean = false;
  regionId: string;
  eId:string;
  dateRange: any = "";
  loading: any = {
    jobs: false,
  };
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;
  selectedJob: any;
  selectedJobID: any;
  hypen:any = 'Level0';
  curlevel: boolean = false;
  

  jobsHeaders: any = [
    {
      id: "name",
      name: "Name",
      link: true,
    },
    // {
    //   id: "scannedOn",
    //   name: "Scanned On",
    // },
    // {
    //   id: "status",
    //   name: "Status",
    // },
    // {
    //   id: "currentLevel",
    //   name: "Current Level",
    // },
    // {
    //   id: "discountPercentage",
    //   name: "Discount Percentage",
    // },

  ];
  currentMessage: any;

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router,
    private zone: NgZone,
    private notifier: NotifierService,
    private dashService: DashService

  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length == 0) {
        this.selectedJob = null;
        this.selectedJobID = null;
        // if(this.jobs.length > 0){
        //   this.load();
        // //  this.fetchDiffDays();
        // }
        // this.load();
      }
      if (params["job_id"] && params["job_id"] != "") {
        this.selectedJobID = params["job_id"];
      }
    });

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.eId=localStorage.getItem("eId");
    this.dateRange = moment().startOf("month").format("MM-YYYY");
    $("#datetimepicker").val(moment().startOf("month").format("MM-YYYY"));
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
      else if(d.key == "eId"){
        this.eId = d.value;
        this.load();
      }
    });
   
    setTimeout(() => {

      $("#datetimepicker")
        .datepicker({
          autoclose: true,
          minViewMode: 1,
          format: "mm-yyyy",
          orientation: "bottom auto",
          endDate: new Date(),
        })
        .on("changeDate", function (selected: any) {
          $(".datetimepicker").val(
            moment(selected["dates"][0]).format("MM-YYYY")
          );
          window.angularComponentReference.zone.run(() => {
            window.angularComponentReference.component.load();
          });
        });
    }, 100);


      // .on('changeDate', function (selected: any) {
      //   window.angularComponentReference.zone.run(() => {
      //     window.angularComponentReference.componentFn();
      //   });
      // });
      window.angularComponentReference = {
        zone: this.zone,
        componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
          this.load(false);
        },
        component: this,
      };
  }

  load(event: any = true) {
    this.selectedJob = null;
    if (event) {
      let date=  $("#datetimepicker").val();
      this.loadJobs();
      this.dashService.sendNotification('green_game', {'type': 'show_loader', 'date':date});
    }
  }
  

  // diffDays: number;
  // fetchDiffDays(start: any, end: any): number { 
  //   let currentDate: any = end; 
  //   let liveDate: any = start; 
  //   currentDate = new Date(currentDate); 
  //   liveDate = new Date(liveDate); 
  //   let diff = (currentDate.getTime() - liveDate.getTime()) / (1000 * 3600 * 24) + 1; 
  // this.diffDays = diff; return diff; }


  // getSelectedMonthYear() {
  //   let monthYear = moment().startOf("month").format("YYYY-MM");

  //   let dRange = $("#datetimepicker").val();
  //   if (dRange) {
  //     monthYear = moment(dRange, "MM-YYYY").format("YYYY-MM");
  //   }
  //   this.loadJobs(monthYear);
  //   console.log(monthYear);
  //   return monthYear;
  // }
  monthYear: string = null;

  // getCurrentMonth() {
  //   let monthYear: string = moment().startOf("month").format("MM-YYYY");

  //   let dRange = $("#datetimepicker").val();
  //   if (dRange) {
  //     monthYear = dRange;
  //   }
  //   return $("#datetimepicker").val();
  // }


  async loadJobs(job_id?: string) {
    
  //   let selected_acc = localStorage.getItem('accountId');
  //   let detail = (JSON.stringify(localStorage.getItem('accountIdData')));
  //   const details = JSON.parse(detail);
  //   console.log(details,"details");
  //   console.log(typeof(details),"type of details");
  //   console.log(details['list'],"list of details");
  //  let account_details = details.list;
  //  console.log(account_details,"account details");
    // for(let acc of account_details){
    //   if(acc.accountId === selected_acc){
    //    this.acc_cftstatus = acc.cft_status;
    //     break;
    //   }
    // }
  //   console.log("cft ", this.acc_cftstatus);
  //  account_details.forEach((obj:any) =>{
  //     if(obj.accountId === selected_acc){
  //       this.acc_cftstatus = obj.cft_status;
  //     }
  //     else{
  //       console.log("No match in list");
  //     }
  //   } );
  //   console.log("cft ", this.acc_cftstatus);

    // if(this.acc_cftstatus == true){
      if (this.getCurrentAccount()["cft_status"]) {
    let monthYear: string = moment().startOf("month").format("MM-YYYY");
    // console.log(monthYear);
    // console.log(this.dateRange);
    
    this.loading["jobs"] = true;
    this.page = 1;
   
    let dRange = $("#datetimepicker").val();
    if (dRange) {
      monthYear = dRange;
    }
    this.monthYear = dRange;
    let data: any = { "action": "fetch", "accId": this.accountId, "clientId":this.eId, "selectedDate":this.monthYear };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      result.scans = result.scans.sort((a: any, b: any) => {
        let date_a: any = new Date(a["scannedOn"]);
        let date_b: any = new Date(b["scannedOn"]);
        return Date.parse(date_b) - Date.parse(date_a);
      });
      this.jobs = result.scans;
      let temp: any;
      this.jobs.forEach((job: any) => {
        job["scannedOn"] = moment(job["scannedOn"]).format("MMM DD, YYYY hh:mm A");
        job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
        job['discountPercentage'] = parseFloat(job['discountPercentage']).toFixed(2);
        if(job["currentLevel"] != ""){

          this.curlevel = true;
        }
        if (this.selectedJobID && job.job_id == this.selectedJobID) {
          temp = job;
        }
        if (!job["name"]) {
          job["name"] = job["job_id"];
          
        }
        setTimeout(async () => {
              if (job.status == 'scanning') {
                this.load();
              } 
            }, 10000);
        // if (job.status !== 'completed'){
        //   // this.dashService.sendNotification('green_game', {'type': 'show_loader'});

        // }
      });

      if (temp) {
        this.selectedJob = temp;
      } else {
        this.navigator.navigate(["/dash/green-game"]);
      }
      this.loading["jobs"] = false;

    } else {
      // if (result.msg == 'success'){
      //   this.loading["jobs"] = true;
      // }
      // else{
        this.loading["jobs"] = false;
        this.jobs = [];
      // }
   
    }
    // return;
    // }
// if(this.acc_cftstatus == false){
//   this.loading["jobs"] = false;
//         this.jobs = [];
//         return;
// }
    this.loading["jobs"] = false;
  }
  else{
    this.loading["jobs"] = true;
    this.page = 1;
    this.jobs  = [];
    this.loading["jobs"] = false;
  }
  }

  selectJob(job: any) {
    if(job["status"] == 'completed'){
      this.notifier.loading(true);
    this.selectedJob = job;
    this.selectedJobID = job["scanId"];
    this.navigator.navigate(["/dash/green-game"], {
      queryParams: { job_id: this.selectedJobID },
    });
  }
  else{
    this.notifier.alert('Info', '', 'Scan in-progress, Please wait 5 min', 'info', 5000);
  }
    return true;

  }

  getCurrentAccount() {
    let account = localStorage.getItem("accountId");
    if (account) {
      return JSON.parse(localStorage.getItem("accountIdData")).list.find(
        (account_: any) => {
          return account_.accountId == account;
        }
      );
    } else {
      return false;
    }
  }


  hideModel(event: string) {
    // if (event) {
    //   this.navigator.navigate(["/dash/green-game"], {
    //     queryParams: { job_id: event },
    //   });
    //   this.selectedJobID = event;
    //   this.loadJobs();
    // }
    this.navigator.navigate(["/dash/green-game"]);
    // this.selectedJobID = event;
    // this.loadJobs();
    // this.createJob = false;
  }









  diffDays: number;

  startOf: any;
  endOf: any;
  // async fetchDiffDays() {
  //   let currentDate: any = $('.datetimepicker').val();
  //   let liveDate: any = moment(`02-${new Date().getFullYear()}`, 'MM-YYYY');
  //   this.startOf = moment(currentDate, 'MM-YYYY');
  //   this.endOf = moment(currentDate, 'MM-YYYY');
  //   let currentMonth = false;
  //   if (
  //     (new Date().getTime() -
  //       new Date(
  //         moment(currentDate, 'MM-YYYY')
  //           .subtract(1, 'months')
  //           .endOf('month')
  //           .format('MM-DD-YYYY')
  //       ).getTime()) /
  //       (1000 * 3600 * 25) <
  //     moment(currentDate, 'MM-YYYY')
  //       .subtract(1, 'months')
  //       .endOf('month')
  //       .daysInMonth()
  //   ) {
  //     currentMonth = true;
  //   }
  //   this.startOf = this.startOf.startOf('day');
  //   this.startOf = this.startOf.format('DD-MM-YYYY');
  //   if (!currentMonth) {
  //     this.endOf = this.endOf.endOf('month');
  //     this.endOf = this.endOf.format('DD-MM-YYYY');
  //   } else {
  //     this.endOf = moment(
  //       `${new Date().getDate()}-${
  //         new Date().getMonth() + 1
  //       }-${new Date().getFullYear()}`,
  //       'D-M-YYYY'
  //     ).format('DD-MM-YYYY');
  //   }
  //   currentDate = moment(currentDate, 'MM-YYYY');
  //   currentDate = currentDate.endOf('month').valueOf();
  //   currentDate = new Date(currentDate);
  //   liveDate = liveDate.endOf('month').valueOf();
  //   liveDate = new Date(liveDate);
  //   let diff =
  //     (currentDate.getTime() - liveDate.getTime()) / (1000 * 3600 * 24);
  //   this.diffDays = diff;

  //       setTimeout(async () => {
  //     if (this.diffDays > 0) {
  //       await this.loadJobs();
  //     } else {
  //       await this.load();
  //     }
  //   }, 100);

    close(event: any) {

      this.selectedJob = null;
      $("#datetimepicker").val(this.monthYear);
        $("#masterAccountSelect").selectpicker("refresh");
        this.load();
    }



  // }

  levelsMap:{[key: string]: string} ={
    'level1': 'Level 1',
    'level2': 'Level 2',
    'level3': 'Level 3',
    'level4': 'Level 4',
    'level5': 'Level 5',
  }


















  
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
