<div class="modal show" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="close(true)"></button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <label>Ticket ID: </label>
              <span class="margin_left_6">{{ ticket.issue_key || ticket.caseId }}</span>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Attachments</label>
              <div>
                <div class="support_types_container files_container">
                  <div class="support_type_container" *ngFor="let d of attachedfileData; let indexx = index">
                    <div class="support_type_box">
                      <div class="support_type_logo">
                        <i class="fa fa-file-text" aria-hidden="true"></i>
                      </div>
                      <div>
                        <div class="support_type_text">{{ d.fileName }}</div>
                        <div class="support_type_desc">
                          {{ niceBytes(d.fileSize) }}
                        </div>
                      </div>
                      <div class="support_type_remove">
                        <i class="fa fa-times pointer" aria-hidden="true" (click)="deleteSeletedFile(indexx)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="support_type_text attachment_add">
                    <div class="flex-center-align" (click)="triggerFIle()">
                      <div><i class="fa fa-plus" aria-hidden="true"></i></div>
                    </div>
                  </div>
                  <input autocomplete="off" #attachmenrs type="file" name="" multiple id="attachments"
                    class="form-control suppoty_type_text_attachment" (change)="getFile($event)" />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label>Reply</label>
              <textarea [(ngModel)]="replyMessage" class="form-control description_text_area" name="" cols="30"
                rows="10"></textarea>
            </div>
            <div class="mb-3">
              <label class="form-label">Additional Contacts</label>
              <div class="additional_contacts">
                <div class="additional_contact" *ngFor="
                    let contact of additionalContacts;
                    let i = index;
                    trackBy: trackByFn
                  ">
                  <input autocomplete="off" type="text" name="contact_value" class="form-control"
                    *ngIf="additionalContacts.length - 3 < i" (keyup)="checkForLast(contact, i)"
                    [(ngModel)]="additionalContacts[i]" />
                  <input autocomplete="off" type="text" name="contact_value" class="form-control"
                    *ngIf="additionalContacts.length - 3 >= i" [(ngModel)]="additionalContacts[i]" />
                  <div>
                    <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                      *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
                  </div>
                  <div>
                    <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                      *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="replyTicket()"
          [ngClass]="{'click-disabled': !writeAccess}" [attr.disabled]="!writeAccess ? true : null">
          {{modalTitle}}
        </button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close(true)">
          Close
        </button>
      </div>
    </div>
  </div>
</div>