import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-win-lin-tab',
  templateUrl: './view-win-lin-tab.component.html',
  styleUrls: ['./view-win-lin-tab.component.css']
})
export class ViewWinLinTabComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() winTypes :any=[];
  @Input() regionHeader:any=[];
  constructor(public notifier: NotifierService,) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  
  }
  toggleItem(item: any): void { item.isHidden = !item.isHidden; } 

  formatType(type:any):string{
    if(type === 'windows'){
      return 'Windows';
    }else if(type === 'linux'){
      return 'Linux';
    }
    else{
      return '';
    }
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }

  ngOnDestroy(): void {
    this.notifier.enableScroll();
}

}
