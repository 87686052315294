import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-view-alerts',
  templateUrl: './view-alerts.component.html',
  styleUrls: ['./view-alerts.component.css']
})
export class ViewAlertsComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() alertList :any=[]

  constructor() { }

  ngOnInit(): void {

  }

  
  no(){
    this.hideModal.emit({ status: 0 });
  }

}
