<div class="timeline-container-screen">
  <div class="timeline-container">
    <div class="timeline-container-header flex-between">
      <div>
        {{ ticket["issue_key"] }}
      </div>
      <i class="fa fa-times" (click)="hideModel.emit()"></i>
    </div>
    <div class="timeline-container-body">
      <!-- <div class="flex-end">
                    <input type="checkbox" id="timeline_view" [(ngModel)]="timelineView">
                    <div>Timeline view</div>
            </div> -->
      <!-- <ng-container *ngIf="timelineView"> -->
      <ng-container *ngFor="let status of comment_history; let i = index">
        <div
          class="status_container"
          [ngClass]="{
            not_resolved: !resolved
          }"
        >
          <div
            class="flex-1 time-text"
            [ngClass]="{ current: status['line'] == 'dotted' }"
          >
            <div class="white-space-none">
              {{ status["time"] }}
            </div>
          </div>
          <div class="flex-column">
            <div
              class="status-round"
              [ngClass]="{ current: status['line'] == 'dotted' }"
            ></div>
            <ng-container *ngIf="comment_history.length - 1 > i">
              <div class="liner" *ngIf="status['line'] != 'dotted'">
                <div class="flex-column floating-widget">
                  <div class="flex-start diff-time" *ngIf="status['diff']">
                    {{ status["diff"] }}
                  </div>
                </div>
              </div>
              <div
                class="dotted-liner"
                *ngIf="status['line'] == 'dotted'"
              ></div>
            </ng-container>
          </div>
          <div
            class="flex-1"
            [ngClass]="{ current: status['line'] == 'dotted' }"
          >
            <div
              class="status-text"
              [ngClass]="{ current: status['line'] == 'dotted' }"
            >
              {{ status["status"] }}
            </div>
            <div class="reporter-name-text">{{ status["name"] }}</div>
          </div>
        </div>
      </ng-container>
      <!-- </ng-container>
            <ng-container *ngIf="!timelineView">
                <table class="table table-docs">
                    <thead>
                        <tr>
                            <th>
                                Date
                            </th>
                            <th>
                                Response By
                            </th>
                            <th>
                                Time Difference
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let status of comment_history">
                            <td>{{ status['time'] }}</td>
                            <td>{{ status['name'] }}</td>
                            <td>{{ status['diff'] }}</td>
                            <td>{{ status['status'] }}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container> -->
    </div>
  </div>
</div>
