<div class="vpc_container" *ngIf="subnet">
  <div class="flex-space-around">
    <span class="text_bold">
      {{subnet.subnet_id}}
    </span>
    <span *ngIf="subnet.name" class="text_bold">
      {{subnet.name}}
    </span>
  </div>
  <div class="flex-space-around">
    <span class="light_text">
      VPC ID:
      {{subnet.vpc_id}}
    </span>
    <span class="light_text">
      CIDR:
      {{subnet.cidr}}
    </span>
    <span class="light_text">
      Availability Zone:
      {{subnet.az}}
    </span>
  </div>
</div>