import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
@Component({
  selector: 'app-view-instances',
  templateUrl: './view-instances.component.html',
  styleUrls: ['./view-instances.component.css']
})
export class ViewInstancesComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() instanceAttachtedToSG :any=[]
  expandedRows:any = [];
  constructor(public notifier: NotifierService) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }

  toggleSubRow(rowIndex: any): void {
    
    this.expandedRows[rowIndex] = !this.expandedRows[rowIndex];
   }

   ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}
