import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-milestones",
  templateUrl: "./milestones.component.html",
  styleUrls: ["./milestones.component.css", "./../../warquiz.component.css"],
})
export class MilestonesComponent implements OnInit {
  @Input("workloadId") workloadId: string;
  @Input("milestones") milestones: any;
  @Input("loading") loading: any;
  @Output("milestone") milestone = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  fetchTotalAnsweredQuestions(milestone: any) {
    return milestone.questions.filter((question: any) => {
      return question["Risk"] != "UNANSWERED";
    });
  }

  fetchQuestionsRisks(milestone: any, risk: string, PillarId: string = null) {
    return milestone["questions"].filter((question: any) => {
      if (PillarId && question["PillarId"] != PillarId) {
        return false;
      }
      return question["Risk"] == risk.toUpperCase();
    });
  }

  selectMilestone(milestone: any) {
    this.milestone.emit(milestone);
  }
}
