<div class="card" *ngIf="!showHistory">
  <div class="card-header flex-between">
    <h5>
      Monitors
    </h5>
    <div class="flex-start">
      <button class="btn btn-danger-soft flex-start" (click)="deLink = true" *ngIf="checkForBU()">
        <i class="fa fa-trash"></i>
        Delink
      </button>
      <button class="btn btn-primary-soft flex-start" *ngIf="checkForBU()" [attr.disabled]="ut != 'admin' ? true : null" (click)="invite = true">
        <i class="fa fa-paper-plane"></i>
        Invite User
      </button>
      <button class="btn btn-primary-soft flex-start" (click)="refresh()">
        <i class="fa fa-refresh"></i>
        Refresh
      </button>
      <button class="btn btn-primary-soft flex-start" (click)="showHistory = true" *ngIf="checkForBU()">
        <i class="fa fa-history"></i>
        History
      </button>
      <button class="btn btn-primary-soft flex-start" (click)="createMonitor = true" *ngIf="checkForBU()">
        <i class="fa fa-plus"></i>
        Add Monitor
      </button>
    </div>
  </div>
  <div class="card-body">
    <nav *ngIf="checkForBU()">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link" (click)="page = 'monitors'" [ngClass]="{active: page == 'monitors'}" id="monitors-tab"
          data-bs-toggle="tab" data-bs-target="#monitors" type="button" role="tab" aria-controls="monitors"
          aria-selected="true">Installed</button>
        <button class="nav-link" (click)="page = 'pending'" [ngClass]="{active: page == 'pending'}" id="pending-tab"
          data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending"
          aria-selected="false">Pending
          Installations</button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade" [ngClass]="{active: page == 'monitors', show: page == 'monitors'}" id="monitors"
        role="tabpanel" aria-labelledby="monitors-tab">
        <div class="card">
          <div class="card-body">
            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <ng-container *ngFor="let h of monitorsHeader">
                    <th *ngIf="h.show()">
                      {{ h.name }}
                    </th>
                  </ng-container>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let monitor of monitors; let i = index">
                  <td>{{ i + 1 }}</td>
                  <ng-container *ngFor="let h of monitorsHeader">
                    <td *ngIf="h.show()">
                      {{ monitor[h.id] }}
                    </td>
                  </ng-container>
                  <td>
                    <div class="flex-start">
                      <span class="status-indicator"
                        [ngClass]="{'red-fancy': monitor['state'] == 0, 'green-fancy': monitor['state'] == 1}">

                      </span>
                      <span class="status-indicator-text"
                        [ngClass]="{'red-text-fancy': monitor['state'] == 0, 'green-text-fancy': monitor['state'] == 1}">
                        {{ monitor['state'] ? "Active" : 'Paused' }}
                      </span>
                      <ng-container *ngIf="checkForBU()">
                        <span>
                          |
                        </span>
                        <span class="nav-link" (click)="statusChange = monitor"
                          [attr.title]="monitor['state'] ? 'You can pause Monitor at any time, which will stop the service from monitoring this server and billing you. You can restart Monitor whenever you wish.' : null">
                          {{ !monitor['state'] ? 'Activate' : 'Pause' }}
                        </span>
                      </ng-container>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="loading">
                <tr>
                  <td [attr.colspan]="monitorsHeader.length + 2" class="txt-cntr center">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!loading && monitors.length == 0">
                <tr>
                  <td [attr.colspan]="monitorsHeader.length + 2" class="txt-cntr center">
                    <span>No Monitors Found.
                      <ng-container *ngIF="checkForBU()">
                        <span class="nav-link" (click)="createMonitor = true">click here</span> to add
                        monitor
                      </ng-container>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{active: page == 'pending', show: page == 'pending'}" id="pending"
        role="tabpanel" aria-labelledby="pending-tab">
        <div class="card">
          <div class="card-body">
            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <ng-container *ngFor="let h of pendingHeader">
                    <th>
                      {{ h.name }}
                    </th>
                  </ng-container>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let monitor of pending; let i = index">
                  <td>{{ i + 1 }}</td>
                  <ng-container *ngFor="let h of pendingHeader">
                    <td>
                      {{ monitor[h.id] }}
                    </td>
                  </ng-container>
                  <td>
                    <div class="flex-start" *ngIf="!monitor.deleting">
                      <div class="btn btn-success-soft" (click)="showCreateMonitor(monitor)">
                        <i class="fa fa-pencil"></i>
                        Get Script
                      </div>
                      <div class="btn btn-danger-soft" (click)="deletePending(monitor)">
                        <i class="fa fa-trash"></i>
                        Delete
                      </div>
                    </div>
                    <div *ngIf="monitor.deleting">
                      <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="loading">
                <tr>
                  <td [attr.colspan]="pendingHeader.length + 2" class="txt-cntr center">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!loading && pending.length == 0">
                <tr>
                  <td [attr.colspan]="pendingHeader.length + 2" class="txt-cntr center">
                    <span>No Pending Installations.
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<site24x7-monitor-activate *ngIf="statusChange" (hideModel)="hideStateChange($event)"
  [monitor]="statusChange"></site24x7-monitor-activate>

<app-create-monitor *ngIf="createMonitor" [accountId]="accountId" [pending]="pending" [instance]="selectedPending"
  (hideModel)="hideCreateMonitor($event)"></app-create-monitor>

<site24x7-delete-bu-ems *ngIf="deLink" (hideModel)="deLinkCheck($event)"></site24x7-delete-bu-ems>

<site24x7-monitor-activity (hideModel)="showHistory = false" *ngIf="showHistory"></site24x7-monitor-activity>

<site24x7-credentials *ngIf="invite" (hideModel)="invite = false"></site24x7-credentials>