<div class="screen">
  <div class="screen-container">
    <div class="screen-header">
      Consolidated EC2 Report
    </div>
    <div class="screen-body">
      <div class="form-group" [ngStyle]="{display: !link && status ? 'none' : 'block'}">
        <label>Month Year</label>
        <input type="text" [attr.disabled]="loading || status == 'Process initiated' ? true : null"
          class="form-control datetimepickerclass">
      </div>
      <div class="flex-center">
        <div class="loader" *ngIf="!link && status">

        </div>
      </div>
      <div class="flex-center" *ngIf="!link && status">
        <span>{{ status }}</span>
      </div>
    </div>
    <div class="screen-actions flex-end">
      <button class="btn btn-primary-soft" *ngIf="!link && !loading && status == null"
        (click)="requestReport(false)">Request Report</button>
      <ng-container *ngIf="link">
        <button class="btn btn-success-soft" (click)="downloadReport()">Download Report</button>
        <button class="btn btn-primary-soft" (click)="requestReport(true)">Regenerate Report</button>
      </ng-container>
      <img src="../assets/img/loading_2.svg" *ngIf="loading" alt="loading scans" class="wid-16" />
      <button class="btn btn-light" (click)="hideModel.emit()">Cancel</button>
    </div>
  </div>
</div>