import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ftr-delete-job',
  templateUrl: './delete-job.component.html',
  styleUrls: ['./delete-job.component.css']
})
export class DeleteJobComponent {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  @Input('job') job: any;
  @Input('accountId') accountId: any;
  @Input('regionId') regionId: any;

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  loading: boolean = false;


  async deleteJob() {
    this.loading = true;
    let data: any = {
      action: "delete_job",
      account_id: this.accountId,
      region_id: this.regionId,
      job_id: this.job["job_id"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ftr/operations`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.message, "success", 5000);
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);

    }

    this.loading = false;
  }


}
