<div class="card" *ngIf="!add">
  <div class="card-header display_align_center">
    <h5>Accounts - AWS</h5>
    <div *ngIf="accounts.length > 0">
      <button class="btn btn-primary-soft" (click)="addaccount()">
        <i class="fa fa-plus"></i>
        Add Account
      </button>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>CFT Status</th>
        </tr>
      </thead>
      <tbody *ngIf="accounts.length > 0">
        <tr *ngFor="let i of accounts; let j = index" class="cursor_pointer" (click)="updateaccount(i)">
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of headers">
            <span [ngClass]="{'nav-link': h.link}">
              {{ i[h.id] }}
            </span>
          </td>
          <td *ngIf="i['cft_status'] == 'loading'">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="width_15" />
          </td>
          <td *ngIf="i['cft_status'] != 'loading'">
            <i class="fa fa-check-circle green_fancy" *ngIf="i['cft_status'] == 'Configured' && i['cft_current_version'] >= i['cft_latest_version']" aria-hidden="true"></i>
            <i class="fa fa-times-circle" [ngClass]="{'red_fancy': i['cft_status'] == 'Not Configured', 'orange_fancy': i['cft_status'] == 'Configured' && i['cft_current_version'] < i['cft_latest_version']}" *ngIf="i['cft_current_version'] < i['cft_latest_version'] || i['cft_status'] != 'Configured'" aria-hidden="true"></i>
            <a class="nav-link" *ngIf="i['cft_status'] == 'Not Configured'" class="pl-2" (click)="preventDefault($event)"
              href="{{ i['configure_link'] }}"
              target="_blank">Configure <i class="fa fa-external-link" aria-hidden="true"></i></a>
            <span class="nav-link pl-2" *ngIf="i['cft_status'] == 'Configured' && i['cft_current_version'] < i['cft_latest_version']" (click)="updateCFTTrigger($event, i)"
               >Update
            </span>
            <span class="pl-2" *ngIf="i['cft_status'] == 'Configured' && i['cft_current_version'] >= i['cft_latest_version']" (click)="updateCFT = i"
               >Configured
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="accounts.length == 0">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div class="aws_swayam">
              <button class="btn btn-primary-soft" (click)="addaccount()">
                Connect
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-add *ngIf="add || selectedAccount" [action]="action" [selectedAccount]="selectedAccount" [regions]="regions"
  (hide)="hideAdd($event)">
</app-add>

<swayam-update-cft *ngIf="updateCFT" [account]="updateCFT"
                   (hideModel)="updateCFT = null"></swayam-update-cft>