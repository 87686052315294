<div class="screen">
  <div class="popup-container">
    <div class="popup-header">
      <span class="color-inherit">
        {{ monitor['state'] ? 'Pause Monitor' : 'Activate Monitor' }} - {{ monitor['display_name'] }}
      </span>
    </div>
    <div class="popup-body">
      <ng-container *ngIf="!otpSent">
        <div>
          Are you sure to {{ monitor['state'] ? 'pause' : 'acivate' }} the monitoring?
        </div>
      </ng-container>
      <ng-container *ngIf="otpSent">
        <div>OTP</div>
        <div>
          <input type="text" name="" id="" class="form-control" [attr.disabled]="loading ? true : null"
            [(ngModel)]="otpText">
        </div>
        <div class="flex-end nav-link" *ngIf="!loading" (click)="sendOTP()">
          Resend OTP
        </div>
      </ng-container>
    </div>
    <div class="popup-actions flex-start">
      <button class="btn btn-primary-soft" *ngIf="!loading && !otpSent" (click)="sendOTP()">
        {{ monitor['state'] ? 'Pause' : 'Activate' }}
      </button>
      <button class="btn btn-primary-soft" *ngIf="!loading && otpSent" (click)="validateOTP()">
        Submit
      </button>
      <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>