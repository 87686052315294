import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "site24x7-delete-bu-ems",
  templateUrl: "./delete-bu-ems.component.html",
  styleUrls: ["./delete-bu-ems.component.css"],
})
export class DeleteBuEmsComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  displayName: string = "";
  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  ngOnInit(): void {}

  otpSent: boolean = false;
  otpText: string = "";
  requestID: string = "";

  async sendOTP() {
    this.loading = true;
    let data: any = {
      action: "send_common_otp",
      email_id: localStorage.getItem("eId"),
      user_name: localStorage.getItem("un"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.otpSent = true;
      this.requestID = result.request_id;
      this.notifier.alert("Success", "", result.message, "success", 5000);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.loading = false;
  }

  async validateOTP() {
    if (!this.otpText) {
      this.notifier.alert("Info", "", "OTP is required", "info", 5000);
      return;
    }
    this.loading = true;
    let data: any = {
      action: "validate_common_otp",
      email_id: localStorage.getItem("eId"),
      user_name: localStorage.getItem("un"),
      otp: this.otpText,
      request_id: this.requestID,
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      await this.deleteBU();
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.error_message || result.message,
        "info",
        5000
      );
    }

    this.loading = false;
  }
  loading: boolean = false;

  async deleteBU() {
    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }

    account = JSON.parse(account);

    this.loading = true;
    let data: any = {
      action: "delete_bu",
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      origin: "swayam",
      user_id: account["emsUid"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      localStorage.removeItem("site24x7MonitorGroups");
      localStorage.removeItem("emsConfig");
      this.removezaaid();
      this.notifier.alert("Success", "", result.message, "success", 5000);
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }
  }

  async removezaaid() {
    let masterId: string;
    if (this.urlPrefix == "client") {
      masterId = this.isUserAdmin
        ? localStorage.getItem("masterId")
        : localStorage.getItem("eId");
    } else {
      masterId = localStorage.getItem("selectedClientList");
    }
    let data: any = {
      a: "deleteems",
      eId: masterId,
    };
    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/awsaccounts`;

    if (this.urlPrefix == "admin") {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/awsaccounts`;
    }

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    console.log(result);

    this.loading = false;
  }
}
