import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

declare let Jhxlsx: any;
declare let $: any;
declare let window: any;
@Component({
  selector: "reports-inventory-billing",
  templateUrl: "./inventory-billing.component.html",
  styleUrls: ["./inventory-billing.component.css"],
})
export class InventoryBillingComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  @Input("showHeader") showHeader: boolean;
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading: boolean = true;
  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    $(".datetimepicker")
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: "mm-yyyy",
        orientation: "bottom auto",
        endDate: new Date(),
      })
      .on("changeDate", function (selected: any) {
        $(".datetimepicker").val(
          moment(selected["dates"][0]).format("MM-YYYY")
        );
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    $(".datetimepicker").val(
      moment().startOf("month").format("MM-YYYY")
    );

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: () => {
        setTimeout(() => {
          this.load(false);
        }, 100);
      },
      component: this,
    };
  }

  destroyed: boolean = false;
  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

  inventory: any = [];
  nextToken: any = null;
  call: number = 1;
  async load(refresh: boolean = false) {
    this.loading = true;
    if (!this.nextToken || refresh) {
      this.inventory = [];
      this.nextToken = null;
      this.call = 1;
    }
    let data = {
      accId: this.accountId,
      date: $(".datetimepicker").val() || moment().startOf("month").format("MM-YYYY"),
      region: this.regionId,
      nextToken: this.nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2report`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (!result || this.destroyed || this.accountId != data['accId'] || this.regionId != data['region'] || $(".datetimepicker").val() != data['date']) {
      this.loading = false;
      return;
    }

    if (result.status == "1" || result.s == "1" || result.status_code) {
      this.inventory = [
        ...this.inventory,
        ...result.reportList.map((instance: any) => {
          instance["finalTotalCost"] = Number(
            instance["finalTotalCost"]
          ).toFixed(4);
          instance["riFinalCost"] = Number(instance["riFinalCost"]).toFixed(4);
          instance["awsTotalCost"] = Number(instance["awsTotalCost"]).toFixed(
            4
          );
          instance["awsCost"] = Number(instance["awsCost"]).toFixed(4);
          instance["finalTotalCost"] = Number(
            instance["finalTotalCost"]
          ).toFixed(4);
          return instance;
        }),
      ];
    }

    this.nextToken = result.nextToken || null;
    if (result.nextToken && this.call % 10 != 0) {
      this.call += 1;
      await this.load();
    }

    this.loading = false;
  }

  export() {
    let dict: any = {
      sheetName: `Inventory Billing - ${$('.datetimepicker').val()}`,
      data: [
        [
          {
            text: "Sl. No.",
          },
          {
            text: "Name",
          },
          {
            text: "Instance ID",
          },
          {
            text: "Instance Type",
          },
          {
            text: "Billing Type",
          },
          {
            text: "OS",
          },
          {
            text: "CPU",
          },
          {
            text: "RAM",
          },
          {
            text: "Savings Plan Info",
          },
          {
            text: "RI Applied",
          },
          {
            text: "AWS Cost",
          },
          {
            text: "RI Rate",
          },
          {
            text: "Usage Hours",
          },
          {
            text: "RI Final Cost",
          },
          {
            text: "AWS Total Cost",
          },
          {
            text: "Final Total Cost",
          },
        ],
      ],
    };

    this.inventory.forEach((instance: any, index: number) => {
      dict.data.push([
        { text: index + 1 },
        {
          text: instance["instanceName"],
        },
        {
          text: instance["instanceID"],
        },
        {
          text: instance["InstanceType"],
        },
        {
          text: instance["billingType"],
        },
        {
          text: instance["os"],
        },
        {
          text: instance["cpu"],
        },
        {
          text: instance["ram"],
        },
        {
          text: instance["SavingsPlanInfo"],
        },
        {
          text: instance["riApplied"],
        },
        {
          text: instance["awsCost"],
        },
        {
          text: instance["riRate"],
        },
        {
          text: instance["hours"],
        },
        {
          text: instance["riFinalCost"],
        },
        {
          text: instance["awsTotalCost"],
        },
        {
          text: instance["finalTotalCost"],
        },
      ]);
    });

    var options = {
      fileName: `Inventory Billing - ${$('.datetimepicker').val()}`,
    };

    Jhxlsx.export([dict], options);
  }
}
