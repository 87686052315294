<div class="card">
  <div class="card-header">
    <h5>View Discount</h5>
  </div>
  <div class="card-body">
    <div class="card_body_06">
      <button class="btn btn-light refresh_07" (click)="load()">Refresh</button>
    </div>
    <div class="container container_09">
      <form name="discountForm" (submit)="updateDiscount()">
        <div>
          <label>Account ID</label>
          <input autocomplete="off" class="form-control" name="accountID" type="text" [ngModel]="accountId" disabled>
        </div>
        <div>
          <label>Date Range</label>
          <input autocomplete="off" class="form-control" name="dateRange" type="text" id="dateRange"
            [(ngModel)]="dateRange">
        </div>
        <div>
          <label>Discount (%)</label>
          <input autocomplete="off" class="form-control" name="discountRate" type="text" [(ngModel)]="discount">
        </div>
        <div>
          <button class="btn btn-light refresh_07" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>

<style>
  form div {
    margin: 10px 0;
  }
</style>