import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';

@Component({
  selector: 'app-rds',
  templateUrl: './rds.component.html',
  styleUrls: ['./rds.component.css']
})
export class RdsComponent implements OnInit, OnDestroy {
  recommendations: any = [];
  destroyed: boolean = false;
  scanDetails: any = {
    status: "-",
    lastScan: "-",
  };

  overview: any = {
    recommendations: () => {
      return this.recommendations.length;
    },
    current_spent: () => {
      let total: number = 0;
      this.recommendations.forEach((bucket: any) => {
        total += Number(bucket["currentPrice"]);
      });
      return total.toFixed(2);
    },
    potential_savings: () => {
      let total: number = 0;
      this.recommendations.forEach((bucket: any) => {
        total += Number(bucket["savings"]);
      });
      return total.toFixed(2);
    },
  };

  loading: boolean = true;
  pagination: any = {
    perPage: 10,
    page: 1,
  };

  pages: any = [10, 50, 100, 200];

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  accountId: string = "";
  regionId: string = "";
  currentMessage: any;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.loadRecommendations();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.loadRecommendations();
      }
    });
  }

  async loadRecommendations() {
    this.loading = true;
    this.recommendations = [];
    let data = {
      a: "fetch",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/rds`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      this.destroyed ||
      data["accountId"] != this.accountId ||
      data["region"] != this.regionId
    ) {
      return;
    }
    if (result.status == "1" || result.s == "1") {
      if (result["data"]) {
        this.recommendations = result["data"];
      }
      this.scanDetails["status"] = result["scanStatus"];
      this.scanDetails["lastScan"] = result["scanDate"]
        ? moment(result["scanDate"]).format("MMM DD, YYYY hh:mm A")
        : "-";
      if (this.scanDetails["status"] != "Completed") {
        if (data.accountId == this.accountId && data.region == this.regionId) {
          setTimeout(() => {
            if (
              this.accountId == data["accountId"] &&
              this.regionId == data["region"]
            ) {
              this.loadRecommendations();
            }
          }, 10000);
        }
      } else {
        this.loading = false;
      }
    } else {
      this.scanDetails["status"] = "-";
      this.scanDetails["lastScan"] = "-";
      this.loading = false;
    }
  }

  filterText: string = "";
  filteredTable() {
    if (this.filterText == "") return this.recommendations;
    return this.recommendations.filter((rds: any) => {
      return rds["dbName"]
        .toLowerCase()
        .includes(this.filterText.toLowerCase()) || rds["dbInstanceType"]
        .toLowerCase()
        .includes(this.filterText.toLowerCase()) || rds["recommendedType"]
        .toLowerCase()
        .includes(this.filterText.toLowerCase());
    });
  }

  position: any = {
    x: "0px",
    y: "0px",
    overflow: false,
    overflowwidth: "0px",
    pointer: "0px",
    width: "",
    height: "",
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector(".message_back").offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector(".message_back").offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + "px";
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + "px";
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector(".message_back").offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector(".message_back").offsetWidth / 2 +
        "px";
    } else {
      this.position.x = 60 + "px";
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      "px";
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + "px";
    this.position.width =
      event.target.querySelector(".message_grid").offsetWidth + 30 + "px";
    this.position.height =
      event.target.querySelector(".message_grid").offsetHeight + 30 + "px";
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector(".message_grid").offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  async createScan() {
    this.loading = true;
    this.recommendations = [];
    let data = {
      a: "scan",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/rds`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      this.destroyed ||
      data["accountId"] != this.accountId ||
      data["region"] != this.regionId
    ) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert(
        "Success",
        "",
        "Scan created successfully",
        "success",
        5000
      );
      this.scanDetails["status"] = "Scanning";
      this.scanDetails["lastScan"] = moment().format("MMM DD, YYYY hh:mm A");
      setTimeout(() => {
        this.loadRecommendations();
      }, 10000);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

}
