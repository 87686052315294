import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../../../api/api.service';
import { NotifierService } from '../../../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'oscheck-upload-bom',
  templateUrl: './oscheck.upload.bom.component.html',
  styleUrls: ['./oscheck.upload.bom.component.css'],
})
export class OSCheckUploadBOM implements OnInit, OnDestroy {
  loading = true;
  @Output() hideModel = new EventEmitter<any>();
  @Input('os') os: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  async uploadFile() {
    this.hideModel.emit(true);
  }

  ngOnDestroy(): void {}

  close(check: boolean) {
    this.hideModel.emit(check ? 'Single' : false);
  }
}
