<div class="card">
  <h5 class="card-header">
    IAM
    <button class=" btn btn-light title3" *ngIf="iamCheckList.length > 0" (click)="downloadReport()">
      Download</button>
    <button class="btn btn-light refresh_5" (click)="load()">
      Refresh
    </button>
  </h5>
  <div class="card-body">
    <nav class="scan10">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#job-details" type="button"
          role="tab" aria-controls="job-details" aria-selected="true">
          Scan
        </a>
        <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#floating-amis" type="button"
          role="tab" aria-controls="floating-amis" aria-selected="true">
          Findings
        </a>
      </div>
    </nav>
    <div>
      <div class="tabContent23">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane active" id="job-details" role="tabpanel" aria-labelledby="job-details-tab">
            <div class="card">
              <div class="card-body scanDetails27" *ngIf="scanDetails && !fetching">
                <div class="scannedOn28">
                  <div class="bold_text">Scanned On</div>
                  <div>{{ scanDetails["startedAt"] }}</div>
                </div>
                <div class="status32">
                  <div class="bold_text">Status</div>
                  <div>{{ scanDetails["scanStatus"] }}</div>
                </div>
              </div>
              <div *ngIf="!scanDetails && fetching" class="card-body card_body37">
                <span class="center">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" class="center39" />
                </span>
              </div>
              <div *ngIf="!scanDetails && !fetching" class="card-body no_scan42">
                <span class='center'>
                  No Scans Found. </span>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="floating-amis" role="tabpanel" aria-labelledby="floating-amis-tab">
            <div class="card">
              <div class="card-body">
                <table class="table table-docs table-hover">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th *ngFor="let h of headers">
                        {{h.name}}
                        <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,iamCheckList)">

                          <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                          <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                        </span>

                        <span *ngIf="h.filter " class="inputseach">
                          <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </th>
                      <th>Offenders</th>
                    </tr>
                  </thead>

                  <tbody [ngStyle]="{'display': iamCheckList.length > 0 ? 'table-row-group': 'nonw'}"
                    *ngFor="let i of iamCheckList; let j = index">
                    <tr *ngIf="notifier.filterTableColums(headers,i)">
                      <td>{{j+1}}</td>
                      <td *ngFor="let h of headers">
                        <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                          <span (click)="callFunction(h.click,i)">
                            {{i[h.id]}}
                          </span>
                        </span>
                        <span *ngIf="!h.click">
                          <span *ngIf="h.color && !h.alter">
                            <span *ngIf="i[h.id] == 'true'" class="pass86">
                              <span><i class="fa fa-check-circle passGreen85" aria-hidden="true"></i></span>
                              <span class="pass_green86">Pass</span>
                            </span>
                            <span *ngIf="i[h.id] == 'false'" class="fail_span89">
                              <span><i class="fa fa-times-circle fail_true90" aria-hidden="true"></i></span>
                              <span class="fail_span91">Fail</span>
                            </span>
                            <span *ngIf="i[h.id] == 'Manual' || i[h.id] == 'manual'" class="manual_span94">
                              <span><i class="fa fa-times-circle manual_true95" aria-hidden="true"></i></span>
                              <span class="manual_span96">Manual</span>
                            </span>
                            <span *ngIf="i[h.id] == ''">
                              <span>-</span>
                            </span>
                          </span>
                          <span *ngIf="!h.color && !h.alter">
                            {{checkforBool(i[h.id], h.id) || '-'}}
                          </span>
                          <span *ngIf="h.alter">
                            <span *ngIf="i[h.id] == 'Low'" class="low106">
                              <span class="low_span_108"></span>
                              <span class="low_span_109">{{i[h.id]}}</span>
                            </span>
                            <span *ngIf="i[h.id] == 'Medium'" class="medium_Span_111">
                              <span class="medium_Span_112"></span>
                              <span class="medium_Span_113">{{i[h.id]}}</span>
                            </span>
                            <span *ngIf="i[h.id] == 'High'" class="high_span_115">
                              <span class="high_span_116"></span>
                              <span class="high_span_117">{{i[h.id]}}</span>
                            </span>
                          </span>
                        </span>
                      </td>
                      <td> <button class="btn btn-link" *ngIf="i.Result != 'true' && i.Result != true"
                          (click)="showOffendes(i.Offenders)">Offenders</button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody [ngStyle]="{'display': iamCheckList.length == 0 ? 'table-row-group' : 'none'}">
                    <tr>
                      <td [attr.colspan]="headers.length + 2">
                        <div class="no_data_found130">No Data Found</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div class="modal show" tabindex="-1" *ngIf="showOffendesModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Offender List</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="showOffendesModal = false" ></button>
      </div>
      <div class="modal-body">
        <div class="card">
         
          <div class="card-body">-->
<!--<h5 class="card-title">Case Details</h5>-->

<!--<table class="table table-docs table-hover" *ngIf="offendeList.length > 0">
              <thead >
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of offendeListHeader">{{h.name}}</th>
                
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of offendeList; let j = index">
                  <td>{{j+1}}</td>
                  <td *ngFor="let h of offendeListHeader">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span (click)="callFunction(h.click,i)">
                        {{i[h.id]}}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{checkforBool(i[h.id])}}
                    </span>
                  </td>                
                </tr>
              </tbody>
            </table>
            <h5 style="text-align:center; color:#0094ff" *ngIf="offendeList.length == 0" >No Data</h5>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="showOffendesModal = false" >Close</button>      
      </div>
    </div>
  </div>
</div>-->

<modal-popup [headers]="offendeListHeader" [data]="offendeList" [modalTitle]="offentpopTitle"
  [showModal]="showOffendesModal" (hideModal)="hidePop($event)"></modal-popup>