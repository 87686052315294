<div class="card mt-20 border-height">
  <h5 class="card-header">Budgets</h5>
  <div class="card-body">
    <div class="flex-align-end">
      <span>
        <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
      </span>
    </div>
    <table class="table table-docs table-hover" id="budgets_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Email IDs</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of linkedAccountIDs; let j = index"
        [ngStyle]="{'display': linkedAccountIDs.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span class="text-cap" *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i, j)">
            </span>
          </td>
          <td>
            <span class="padding-4" (click)="selectedLinkedAccountID = i">
              Email IDs ({{ i.eid.length }})
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="fetchingLInkedAccountIDs">
        <tr>
          <td [attr.colspan]="headers.length + 3" class="align-center">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="mar-10" />
          </td>
        </tr>
      </tbody>
      <tbody
        [ngStyle]="{'display': !fetchingLInkedAccountIDs && linkedAccountIDs.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div class="align-center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<modal-popup *ngIf="selectedLinkedAccountID" [showModal]="selectedLinkedAccountID"
  (hideModal)="selectedLinkedAccountID = null" [data]="selectedLinkedAccountID.eid" [modalTitle]="'Budget Email IDs'"
  [headers]="[{'id' : 'eid', 'name': 'Email IDs'}]" [maxWidth]="'500px'"></modal-popup>