import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-os-based-recommendations",
  templateUrl: "./os-based-recommendations.component.html",
  styleUrls: [
    "./os-based-recommendations.component.css",
    "./../../ec2costoptimization.component.css",
  ],
})
export class OsBasedRecommendationsComponent implements OnInit, OnChanges {
  @Input("recommendations") recommendations: any;
  @Input("pages") pages: any;
  @Input("jobDetails") jobDetails: any;
  @Input("fetching") fetching: any;
  @Output("scan") scan: any = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  getReason(): string {
    return this.recommendations[0]["recommReason"];
  }

  getTotalSavings() {
    return (
      (
        this.recommendations.reduce(
          (total: number, recommendation: any) =>
            total +
            Number(recommendation.currentPrice) -
            Number(recommendation.recommendedPrice),
          0
        ) || 0
      ).toFixed(2) + " USD"
    );
  }

  pagination: any = {
    page: 1,
    perPage: 10,
  };

  position: any = {
    x: "0px",
    y: "0px",
    overflow: false,
    overflowwidth: "0px",
    pointer: "0px",
    width: "",
    height: "",
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector(".message_back").offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector(".message_back").offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + "px";
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + "px";
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector(".message_back").offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector(".message_back").offsetWidth / 2 +
        "px";
    } else {
      this.position.x = 60 + "px";
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      "px";
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + "px";
    this.position.width =
      event.target.querySelector(".message_grid").offsetWidth + 30 + "px";
    this.position.height =
      event.target.querySelector(".message_grid").offsetHeight + 30 + "px";
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector(".message_grid").offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["recommendations"]) {
      this.recommendations = this.recommendations.map((recommendation: any) => {
        recommendation["potential_savings"] =
          Number(recommendation["currentPrice"]) -
          Number(recommendation["recommendedPrice"]);
        return recommendation;
      });
    }
  }
}
