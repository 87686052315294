import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'volume-ec2',
  templateUrl: './volume.ec2.components.html',
})
export class VolumeEC2Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  @Input() instance: any;
  @Input('instance') volumeSnapList: any;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  funRef: any;
  accountId: any;
  regionId: any;

  volumeHeaders: any = [
    {
      id: 'DeviceName',
      name: 'Device Name',
      click: 'loadSnapsots',
    },
    {
      id: 'VolumeId',
      name: 'Volume ID  ',
    },
    {
      id: 'Status',
      name: 'Status',
    },
    {
      id: 'AttachTime',
      name: 'Attach Time',
    },
    {
      id: 'DeleteOnTermination',
      name: 'Delete On Termination',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.volumeSnapList = this.volumeSnapList.map((volume: any) => {
      volume = { ...volume, ...volume.Ebs };
      return volume;
    });
    console.log(this.volumeSnapList);
  }

  hideNested(event: any) {}

  callFunction(name: any, value: any) {
    this.funRef[name](this, value);
  }
  ngOnDestroy(): void {}
}
