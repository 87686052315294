<div class="card">
  <h5 class="card-header">
    <div class="flex-start">
    <a [routerLink]="'/dash/reports/home'" class="default-link">
      <button class="btn btn-light flex-start">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span>Reports</span>
      </button>
    </a>
    <span>
      RDS Backup Report
    </span>
  </div>

    <div class="flex-end">
      <button class="btn btn-light flex-start" (click)="load()">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        <span>
          Refresh
        </span>
      </button>
      <button *ngIf="currentTab === 'instances' && instances.length > 0" class="btn btn-light flex-start" (click)="export()">
        <i class="fa fa-download" aria-hidden="true"></i>
        <span>
          Export
        </span>
      </button>
      <button class="btn btn-primary-soft flex-start" (click)="createScanTrigger()">
        <i class="fa fa-search" aria-hidden="true"></i>
        <span class="color-inherit">
          Scan
        </span>
      </button>
    </div>
  </h5>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="instances-tab" data-bs-toggle="tab" data-bs-target="#instances"
          type="button" (click)="currentTab = 'instances'" role="tab" aria-controls="instances"
          aria-selected="true">Instances</button>
        <button class="nav-link" id="clusters-tab" data-bs-toggle="tab" data-bs-target="#clusters"
          (click)="currentTab = 'clusters'" type="button" role="tab" aria-controls="clusters"
          aria-selected="false">Clusters</button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="instances" role="tabpanel" aria-labelledby="instances-tab">
        <div class="card-body overflow">
          <table class="table table-docs table-hover" id="backup-report-table">
            <thead>
              <tr>
                <th>S.N.</th>
                <th *ngFor="let h of instanceHeaders">{{ h.name }}</th>
              </tr>
            </thead>

            <ng-container *ngIf="!loading && instances.length > 0">
              <tbody *ngFor="let i of instances; let j = index">
                <tr>
                  <td>
                    <div class="flex-center">
                      <!-- <ng-container *ngIf="expandable">
                        <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                        <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                      </ng-container> -->
                      <span>
                        {{ j + 1 }}
                      </span>
                    </div>
                  </td>
                  <td *ngFor="let h of instanceHeaders">
                    <!-- <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] || '-' }}
                      </span>
                    </span> -->
                    <span [innerHTML]="i[h.id] !== null && i[h.id] !== undefined ? i[h.id] : '-'"></span>
                  </td>
                </tr>
                <!-- <tr *ngIf="i.showNested" class="nestedstyle">
                  <td [attr.colspan]="instanceHeaders.length + 1">
                    <nTable [headers]="snapshotsHeader" [data]="i.snapshots" (hideModal)="hideNested($event)"></nTable>
                  </td>
                </tr> -->
              </tbody>
            </ng-container>
            <tbody *ngIf="loading">
              <tr>
                <td [attr.colspan]="instanceHeaders.length + 1">
                  <div class="flex-center">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
                    <span>
                      Loading..
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!loading && instances.length == 0">
              <tr>
                <td [attr.colspan]="instanceHeaders.length + 1">
                  <div class="flex-center">
                    <span>
                      No Data Found
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="tab-pane fade" id="clusters" role="tabpanel" aria-labelledby="clusters-tab">
        <div class="card-body overflow">
          <table class="table table-docs table-hover" id="backup-report-table">
            <thead>
              <tr>
                <th>S.N.</th>
                <th *ngFor="let h of clusterHeaders">{{ h.name }}</th>
              </tr>
            </thead>

            <ng-container *ngIf="!loading && clusters.length > 0">
              <tbody *ngFor="let i of clusters; let j = index">
                <tr>
                  <td>
                    <!-- <div class="flex-center"> -->
                      <!-- <ng-container *ngIf="expandable">
                        <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                        <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                      </ng-container> -->
                      <span>
                        {{ j + 1 }}
                      </span>
                    <!-- </div> -->
                  </td>
                  <td *ngFor="let h of clusterHeaders">
                    <!-- <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] || '-' }}
                      </span>
                    </span> -->
                    <span [innerHTML]="i[h.id] !== null && i[h.id] !== undefined ? i[h.id] : '-'"></span>
                  </td>
                </tr>
                <tr *ngIf="i.showNested" class="nestedstyle">
                  <td [attr.colspan]="clusterHeaders.length + 1">
                    <nTable [headers]="snapshotsHeader" [data]="i.snapshots" (hideModal)="hideNested($event)"></nTable>
                  </td>
                </tr>
              </tbody>
            </ng-container>
            <tbody *ngIf="loading">
              <tr>
                <td [attr.colspan]="clusterHeaders.length + 1">
                  <div class="flex-center">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
                    <span>
                      Loading..
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!loading && clusters.length == 0">
              <tr>
                <td [attr.colspan]="clusterHeaders.length + 1">
                  <div class="flex-center">
                    <span>
                      No Data Found
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
