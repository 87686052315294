<div class="card">
  <div class="card-header">
    <h5>
      Swayam Docs
    </h5>
  </div>
  <div class="card-body">
    <div class="swayam-docs-container">
      <div>
        <div class="center text-bold">
          DOCUMENTS
        </div>
        <div>
          <div class="max-width-300 flex-between component" (click)="onSelect(component)"
            *ngFor="let component of components; let i = index">
            <div>{{ component['text'] }}</div>
            <div>
              <input type="radio" [attr.checked]="component['check'] || null" id="">
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="prompt">
          <div class="flex-1">
            <input placeholder="Select the document type from left panel" type="text" class="form-control width-100"
              [(ngModel)]="prompt">
          </div>
          <div class="prompt-container flex-end">
            <button class="btn btn-primary-soft" (click)="generateAPI()">
              Generate
            </button>
            <button class="btn btn-success-soft" (click)="exportPDF()">Export</button>
          </div>
        </div>
        <ng-container *ngIf="!loading">
          <div class="document-container flex-center">
            <div class="line"></div>
            <div class="text-bold">Preview</div>
            <div class="line"></div>
          </div>
          <div class="document-container">
            <div *ngIf="checkForSelection('Cover Page')" class="cover-page-container">
              <img src="./assets/img/cover-page.png" alt="">
              <div class="floating-form">
                <div class="top-text">
                  <h1>Statement of Work</h1>
                </div>
                <div class="bottom-text">
                  <h1 class="text-bold">SREI</h1>
                  <h1>Minfy Technologies</h1>
                </div>
                <div class="bottom-text">
                  <h1>{{getCurrentMonthYear()}}</h1>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let type_element of segregateText(displayed, '<br>'); let i = index">
              <ng-container *ngFor="let variable_text of segregateText(type_element, '###')">
                <!-- {{variable_text}} -->
                <div class="display-inline segregator" *ngIf="variable_text != ''"
                  [ngClass]="{'generator': i + 1 == segregateText(displayed, '<br>').length && generating}">
                  <!-- {{type_element}} -->
                  <ng-container *ngIf="variables[getVariable(variable_text)] && variable_text.includes('***')">
                    <input autocomplete="off" *ngIf="variables[getVariable(variable_text)]['type'] == 'string'"
                      [(ngModel)]="variables[getVariable(variable_text)]['value']" type="text"
                      [attr.placeholder]="getVariable(variable_text)">
                    <input autocomplete="off" *ngIf="variables[getVariable(variable_text)]['type'] == 'date'"
                      [(ngModel)]="variables[getVariable(variable_text)]['value']" type="date"
                      [attr.placeholder]="getVariable(variable_text)">
                    <textarea *ngIf="variables[getVariable(variable_text)]['type'] == 'textbox'"
                      [(ngModel)]="variables[getVariable(variable_text)]['value']" name="" id="" cols="50" rows="5"
                      [attr.placeholder]="getVariable(variable_text)"></textarea>
                  </ng-container>
                  <span [innerHTML]="getString(variable_text)">
                  </span>
                </div>
              </ng-container>
              <br>
            </ng-container>
            <div class="signature_container" *ngIf="checkForSelection('Sign')">
              <div *ngIf="getSelectedAdditional('Sign')['data'] == null">
                <div class="select-image width-auto" (click)="triggerUploadImage()">
                  Upload Sign
                </div>
                <input type="file" (change)="setImageDataURL($event)" class="hidden image-uploader-docs">
              </div>
              <div *ngIf="getSelectedAdditional('Sign')['data']" class='sign_image_container'>
                <img src="{{getSelectedAdditional('Sign')['data']}}" alt="">
                <div class="remove-sign" (click)="getSelectedAdditional('Sign')['data'] = null">
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
        <ng-container *ngIf="loading">
          <div class="flex-center">
            <img src="./assets/img/loading_2.svg" class="width-18">
          </div>
        </ng-container>
      </div>
      <div>
        <div class="center text-bold">
          ADDITIONAL COMPONENTS
        </div>
        <div>
          <ng-container *ngFor="let additional of additional; let i = index">
            <div *ngIf="additional['show'] != false" class="max-width-300 flex-between component"
              (click)="additional['check'] = !additional['check']">
              <div>{{ additional['text'] }}</div>
              <div>
                <input type="checkbox" [ngModel]="additional['check']" id="">
              </div>
            </div>

          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>