import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-processor-based-recommendations",
  templateUrl: "./processor-based-recommendations.component.html",
  styleUrls: [
    "./processor-based-recommendations.component.css",
    "./../../ec2costoptimization.component.css",
  ],
})
export class ProcessorBasedRecommendationsComponent implements OnInit {
  @Input("recommendations") recommendations: any;
  @Input("pages") pages: any;
  @Input("jobDetails") jobDetails: any;
  @Input("fetching") fetching: any;
  @Output("scan") scan: any = new EventEmitter<boolean>();

  constructor() {}
  currentPage: string = "a";

  pagination: any = {
    a: { page: 1, perPage: 10 },
    g: { page: 1, perPage: 10 },
  };

  getReason(): string {
    return this.filterRecommendations().length > 0
      ? this.filterRecommendations()[0]["recommReason"]
      : "";
  }

  filterRecommendations() {
    return (
      this.recommendations.filter((recommendation: any) => {
        return recommendation["coreSeries"] == this.currentPage;
      }) || []
    );
  }

  getTotalSavings() {
    return (
      (
        this.filterRecommendations().reduce(
          (total: number, recommendation: any) =>
            total +
            Number(recommendation.currentPrice) -
            Number(recommendation.recommendedPrice),
          0
        ) || 0
      ).toFixed(2) + " USD"
    );
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["recommendations"]) {
      this.recommendations = this.recommendations.map((recommendation: any) => {
        recommendation["potential_savings"] =
          Number(recommendation["currentPrice"]) -
          Number(recommendation["recommendedPrice"]);
        return recommendation;
      });
    }
  }
}
