<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Medium Alert</span>
            <button (click)="no()"  class="sortBtn"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
          <div class="KBBody table-responsive ">

            <!-- <table class="table table-hover  mb-0"> -->
                
              
    
                <!-- <tbody class="scrollable-body"> -->
                 
                   
                   
                  <table class="table table-hover  m-0 p-0">
                    <thead >
                      <tr>
                        <th scope="col">Category</th>
                        <th scope="col">Count</th>
                        
                            
                      </tr>
                    </thead>
                    <tbody>
                    <tr >
                      <td>Security</td>
                      <td>{{seMed}}</td>
                      
                    </tr>
                    <tr >
                        <td>Governance</td>
                        <td>{{govMed}}</td>
                        
                      </tr>
                      <tr >
                        <td>Daily Spend</td>
                        <td>{{dsMed}}</td>
                        
                      </tr>
                      <tr >
                        <td>Daily Cost</td>
                        <td>{{dcMed}}</td>
                        
                      </tr>
                      <tr >
                        <td><b>Total</b></td>
                        <td><b>{{dcMed+dsMed+govMed+seMed}}</b></td>
                        
                      </tr>
                      <!-- <tr >
                        <td>Others</td>
                        <td>5</td>
                        
                      </tr> -->
                    <!-- <tr >
                      <td>{{j+1}}</td>
                      <td>{{mon.cost[1]['key']}}</td>
                      <td>{{mon.cost[1]['value']}}</td>
                    </tr> -->
                  </tbody>
                </table>
             
                 
                  <!-- <ng-container *ngFor="let i of instancesTypes;">
                    <tr *ngFor="let value of i[key]>
                    <td>{{key}}</td>
                   
                  </tr>
                  </ng-container>            -->
                <!-- </tbody>  -->
            <!-- </table> -->
            
              <!-- <div class="btnHold">
                <div class="bhold">
                  <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
                  <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
                  [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
                </div>
              </div> -->
    
    
            <!-- <div class="KBActions">
              <button class="btn btn-light" (click)="no()">Cancel</button>
            </div> -->
          
        </div>
          
</div>




