import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
@Component({
  selector: "tags-modal",
  styleUrls: ["./tags.modal.component.css"],
  templateUrl: "./tags.modal.component.html",
})
export class TagsModalComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  @Input() modalTitle: string;
  @Input() accountID: string;
  @Input() regionID: string;
  @Input() instanceID: string;
  @Input() serviceName: string;
  @Input() list: any;
  @Output() hideModal = new EventEmitter<boolean>();
  maxTags: number = 50;
  funRef: any;
  tags: any = [];
  intervalOptions: any = [
    {
      value: "",
      str: "Backup Duration",
    },
    {
      value: "5",
      str: "5 Minutes",
    },
    {
      value: "15",
      str: "15 Minutes",
    },
    {
      value: "30",
      str: "30 Minutes",
    },
    {
      value: "60",
      str: "1 Hour",
    },
    {
      value: "120",
      str: "2 Hours",
    },
    {
      value: "240",
      str: "4 Hours",
    },
    {
      value: "360",
      str: "6 Hours",
    },
    {
      value: "720",
      str: "12 Hours",
    },
    {
      value: "1440",
      str: "24 Hours",
    },
  ];
  retentionOptions: any = [
    {
      value: "",
      str: "Backup Retention",
    },
    {
      value: "7",
      str: "7 Days",
    },
    {
      value: "14",
      str: "14 Days",
    },
    {
      value: "21",
      str: "21 Days",
    },
    {
      value: "24",
      str: "24 Days",
    },
    {
      value: "30",
      str: "30 Days",
    },
  ];
  backupHeaders: any = [
    {
      id: "duration",
      name: "Minfy Sawyam Backup Interval",
      dropdown: true,
      options: this.intervalOptions,
    },
    {
      id: "retention",
      name: "Minfy Sawyam Backup Retention",
      dropdown: true,
      options: this.retentionOptions,
    },
  ];
  currentMessage: any;

  header: any = [
    {
      id: "key",
      name: "Key",
    },
    {
      id: "value",
      name: "Value",
    },
  ];

  constructor(
    private notifier: NotifierService,
    private api_service: APIService
  ) {}

  ngOnInit(): void {
    if (!this.accountID || !this.regionID) {
      this.accountID = localStorage.getItem("accountId");
      this.regionID = localStorage.getItem("regionId");
    }

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }

      if (d.key == "accountId") {
        this.accountID = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionID = d.value;
        this.load();
      }
    });

    this.funRef = {
      showData: this.showData,
    };
  }
  async load() {
    this.notifier.loading(true);
    await this.loadTags();
    this.notifier.loading(false);
  }

  async loadTags() {
    let header: any = {
      Authorization: localStorage.getItem("t"),
    };
    let data = {
      accountId: this.accountID,
      action: "fetchTags",
      region: this.regionID,
      instanceId: this.instanceID,
      serviceName: this.serviceName,
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/addtags`;

    let result = await this.api_service.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      let dt: any = [
        {
          key: "Usecase",
          value: "",
          type: "Dropdown",
          editable: "VALUE_ONLY",
          removeTag: false,
          options: [
            {
              str: "Usecase",
              value: "",
            },
            {
              str: "Application",
              value: "application",
            },
            {
              str: "Database",
              value: "database",
            },
            {
              str: "Others",
              value: "others",
            },
          ],
        },
        {
          key: "minfy_swayam_backup_retention",
          value: "",
          type: "Dropdown",
          editable: "VALUE_ONLY",
          removeTag: false,
          options: this.retentionOptions,
        },
        {
          key: "minfy_swayam_backup_interval",
          value: "",
          type: "Dropdown",
          editable: "VALUE_ONLY",
          removeTag: false,
          options: this.intervalOptions,
        },
        {
          key: "minfy_swayam_backup",
          value: "no",
          type: "Dropdown",
          editable: "VALUE_ONLY",
          removeTag: false,
          options: [
            {
              str: "Yes",
              value: "yes",
            },
            {
              str: "No",
              value: "no",
            },
          ],
        },
      ];
      result.tagList.forEach((tag: any) => {
        let dict = dt.find((element: any) => {
          return element.key == tag.key;
        });
        if (dict) {
          dict.value = tag.value;
          let dictIndex = dt.findIndex((element: any) => {
            return element.key == tag.key;
          });
          dt[dictIndex] = dict;
        } else {
          let data: any = {
            key: tag.key,
            value: tag.value,
            type: "String",
            editable: "ALL",
            removeTag: true,
          };
          dt.push(data);
        }
      });
      this.tags = dt;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
  }

  callFunction(name: any, id: any) {
    this.funRef[name](this, id);
  }

  showData() {}

  addTag() {
    this.tags.push({
      key: "",
      value: "",
      type: "String",
      editable: "ALL",
      removeTag: true,
    });
  }

  removeTag(id: any) {
    this.tags.splice(id, 1);
  }

  async saveTags() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let header: any = {
      Authorization: localStorage.getItem("t"),
    };
    let data = {
      accountId: this.accountID,
      action: "editTags",
      region: this.regionID,
      instanceId: this.instanceID,
      serviceName: this.serviceName,
      tagList: this.tags,
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/addtags`;

    let result = await this.api_service.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
      this.close(true);
    }
    this.notifier.loading(false);
  }

  close(showModal: any) {
    this.hideModal.emit(showModal);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
