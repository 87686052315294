<div class="card mt-20 crd-bdy">
  <h5 class="card-header">Master Account Details</h5>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Export</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of accounts; let j = index"
        [ngStyle]="{'display': accounts.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i)">
            </span>

          </td>
          <td><span class="btn btn-link" (click)="exportMainData(i)">Download CSV</span></td>

        </tr>
        <tr *ngIf="i.expanded">
          <td [attr.colspan]="headers.length + 2">
            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th *ngFor="let h of childAccountHeaders">{{ h.name }}</th>
                </tr>
              </thead>

              <tbody [ngStyle]="{'display': i.data.length > 0 ? 'table-row-group' : 'none'}">
                <tr *ngFor="let i of i.data; let j = index">
                  <td>{{j + 1}}</td>
                  <td *ngFor="let h of childAccountHeaders">
                    <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
                    <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0"
                      (click)="callFunction(h.click, i)">
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody [ngStyle]="{'display': i.data.length == 0 ? 'table-row-group' : 'none'}">
                <tr>
                  <td [attr.colspan]="childAccountHeaders.length + 1">
                    <div class="txt-cntr">No Data Found</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': accounts.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div class="txt-cntr">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>