<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Delete Entry</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        Are you sure, you want to delete this entry?
      </div>
      <div class="alarm_actions">
        <div class="delandcancelbtn">
          <button class="btn deletebtnstyle" (click)="deleteEntry()" >
            Delete
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>