import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotifierService } from "../../../../_services/notifier.service";
import { APIService } from "../../../../api/api.service";
import { event } from 'jquery';

@Component({
  selector: 'app-multi-account-tab',
  templateUrl: './multi-account-tab.component.html',
  styleUrls: ['./multi-account-tab.component.css']
})
export class MultiAccountTabComponent implements OnInit, OnDestroy {

  @Output() hideModal = new EventEmitter<any>();
  @Output() hideView = new EventEmitter<any>();
  @Output() acctIdList = new EventEmitter<string>();
  @Input() isFirstReload: boolean;
  accountIdsList: any = [];
  selectedAccountIds: any = [];
  accountIdString: any;
  regionId: string = localStorage.getItem("regionId");
  accountId: string = localStorage.getItem("accountId");
  currentMessage: any;
  email: any = '';
  isAllCftEnable=true;
  cftErrMsg: boolean=false;
  idAccessCheckingLoader = true;
  notaccess: boolean=true;
  toggelselectAll=true;
  selectbuttontext = 'fa-solid fa-square-check';
  constructor(
    public notifier: NotifierService,
    private apiServer: APIService,
  ) { }

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountIdsList = [];
        this.selectedAccountIds = [];
        this.email = '';
        this.isAllCftEnable=true;
        this.cftErrMsg=false;
        this.idAccessCheckingLoader = true;
        this.notaccess=true;
        this.toggelselectAll=true;
        this.selectbuttontext = 'Select All';
        this.getAccountList();
      } else if (d.key == "regionId") {
        this.accountIdsList = [];
        this.selectedAccountIds = [];
        this.email = '';
        this.isAllCftEnable=true;
        this.cftErrMsg=false;
        this.idAccessCheckingLoader = true;
        this.notaccess=true;
        this.toggelselectAll=true;
        this.selectbuttontext = 'Select All';
        this.getAccountList();
      }
    });
  }

  async getAccountList() {
  
    const accountList = localStorage.getItem("accountIdData");
    const data = JSON.parse(accountList);
    if (data['list'].length > 0) {
      this.accountIdsList = data['list'].sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1;
        }
        if (a.accountName > b.accountName) {
          return 1;
        }
        return 0;
      });
      //checking for disable account
     
     // this.checkForDisableAccount();

      const countCftFalse =  this.accountIdsList.reduce((count:any, item:any) => 
      { return count + (item.cft_status === false ? 1 : 0); }, 0); 
      if(this.accountIdsList.length == countCftFalse ){
        this.isAllCftEnable=false;
      }
      if(countCftFalse>0){
        this.cftErrMsg=true;
      }
      // const email = 'test7@minfytech.com';
      const email = localStorage.getItem("eId");
      const clientName = this.accountIdsList[0]['companyName'];
      let groupName = localStorage.getItem("selectedAccountGroup");
      if(groupName==null){
        groupName='clientportal';
      }
      let header = {
        Authorization: "Bearer " + localStorage.getItem("t"),
      };

      let apiURL = `https://apis-eks.swayam.cloud/dashboard/dashboard_user_preference/?user_email=${email}&group_name=${groupName}&client_name=${clientName}&action=fetch&user_type=client`;

      await this.apiServer.postDataPromis(apiURL, {}, header).then((data) => {
        try {
         
          this.accountIdString = data['message'];
          this.selectedAccountIds = [...new Set([...this.selectedAccountIds, ...this.accountIdString])];
        
        } catch (error) {
          this.accountIdString = '';
        }
      });


      if(this.accountIdString.length == 0){
        const accountIdArray = this.accountIdsList.filter((item: any) => item.cft_status === true) .map((item: any) => item.accountId); 

        let header = {
          Authorization: "Bearer " + localStorage.getItem("t"),
        };
      
        let apiURL = `https://apis-eks.swayam.cloud/dashboard/dashboard_accounts_validate/?list_of_account_numbers=${accountIdArray}&user_type=client&aws_region=${this.regionId}`;
       
        try {
          await this.apiServer.postDataPromis(apiURL, {}, header).then((data) => {
           
          const disableacc =  Object.entries(
              data['accounts_ids']
            ).map(([key, value]) => {
              return { id: key, cost: value };
            });
          
            const filteredArray = disableacc.filter((item:any) => !item.cost.sts_access || !item.cost.all_access); 
          
            const outputArray = filteredArray.map((item) => item.id); 
      
            this.accountIdsList = this.accountIdsList.map((account:any) => ({ ...account, accessDenied: outputArray.includes(account.accountId) })); 
      
            const countaccessFalse =  this.accountIdsList.reduce((count:any, item:any) => 
            { return count + (item.accessDenied === true ? 1 : 0); }, 0); 
     
            if(this.accountIdsList.length == countaccessFalse){
              this.notaccess=false;
            }else{
              this.notaccess=true;
            }
           
            this.idAccessCheckingLoader = false;
            
          });
        } catch (error) {
     
        }

      }else{
        const accountIdArray = this.accountIdsList.filter((item: any) => item.cft_status === true && !this.accountIdString.includes(item.accountId)) .map((item: any) => item.accountId); 

        let header = {
          Authorization: "Bearer " + localStorage.getItem("t"),
        };
      
        let apiURL = `https://apis-eks.swayam.cloud/dashboard/dashboard_accounts_validate/?list_of_account_numbers=${accountIdArray}&user_type=client&aws_region=${this.regionId}`;
       
        try {
          await this.apiServer.postDataPromis(apiURL, {}, header).then((data) => {
           
          const disableacc =  Object.entries(
              data['accounts_ids']
            ).map(([key, value]) => {
              return { id: key, cost: value };
            });
          
            const filteredArray = disableacc.filter((item:any) => !item.cost.sts_access || !item.cost.all_access); 
            
            const outputArray = filteredArray.map((item) => item.id); 
      
            this.accountIdsList = this.accountIdsList.map((account:any) => ({ ...account, accessDenied: outputArray.includes(account.accountId) })); 
      
            const countaccessFalse =  this.accountIdsList.reduce((count:any, item:any) => 
            { return count + (item.accessDenied === true ? 1 : 0); }, 0); 
     
            if(this.accountIdsList.length == countaccessFalse){
              this.notaccess=false;
            }else{
              this.notaccess=true;
            }
           
            this.idAccessCheckingLoader = false;
         
          });
        } catch (error) {
     
        }
         
      }


      if (this.accountIdString.length != 0) {
     
        this.accountIdsList = this.accountIdsList.map((item: any) => ({ ...item, selected: this.accountIdString.includes(item.accountId) }));
      } else {
     
        this.accountIdsList = this.accountIdsList.map((item: any) => ({ ...item, selected: false }));
        this.initSelectAll();
      }

     
    }

  }

  selectAll(event: any) {
    if(this.toggelselectAll){
      this.toggelselectAll = !this.toggelselectAll;
      this.selectbuttontext = 'fa-solid fa-square-check';
      this.accountIdsList.forEach((acc: any) => {
        if(acc.cft_status===true && acc.accessDenied===false){
        if (acc.selected == true) {
          acc.selected = false;
        }
       // const allAccountIds = this.accountIdsList.map((account: any) => account.accountId);
        this.selectedAccountIds = [];
       // this.accountIdString = this.selectedAccountIds.join(',');
       
      }
      });
   
  }else{
    this.selectbuttontext = 'fa-regular fa-square';
    this.toggelselectAll = !this.toggelselectAll;
    this.accountIdsList.forEach((acc: any) => {
      if(acc.cft_status===true && acc.accessDenied===false){
      if (acc.selected == false) {
        acc.selected = true;
      }
     // const allAccountIds = this.accountIdsList.map((account: any) => account.accountId);
      this.selectedAccountIds = [...new Set([...this.selectedAccountIds, acc.accountId])];
      this.accountIdString = this.selectedAccountIds.join(',');
     
    }
    });
  }
  }

  initSelectAll() {
    this.accountIdsList.forEach((acc: any) => {
      if(acc.cft_status===true && acc.accessDenied===false){
      if (acc.selected == false) {
        acc.selected = true;
      }
     // const allAccountIds = this.accountIdsList.map((account: any) => account.accountId);
      this.selectedAccountIds = [...new Set([...this.selectedAccountIds, acc.accountId])];
      this.accountIdString = this.selectedAccountIds.join(',');

    }
    })
 
  }

  onCheckboxChange(event: any, accountId: string) {
    const account = this.accountIdsList.find((acc: any) => acc.accountId === accountId);
    if (event.target.checked) {
      account.selected = true;
      this.selectedAccountIds.push(accountId);
    }
    else {
      account.selected = false;
      this.selectedAccountIds = this.selectedAccountIds.filter((id: any) => id !== accountId);
    }
    this.accountIdString = this.selectedAccountIds.join(',');
   
  }

  async saveAccPreference() {
 
   // const email = 'test7@minfytech.com';
    const email = localStorage.getItem("eId");
    const clientName = this.accountIdsList[0]['companyName'];
    let groupName = localStorage.getItem("selectedAccountGroup");
    if(groupName==null){
      groupName='clientportal';
    }
    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/dashboard_user_preference/?user_email=${email}&group_name=${groupName}&client_name=${clientName}&list_of_account_numbers=${this.accountIdString}&action=store&user_type=client`;
    
    try {
      await this.apiServer.postDataPromis(apiURL, {}, header).then((data) => {
     
      });
    } catch (error) {

    }
  }
//   async checkForDisableAccount(){
//   const accountIdArray = this.accountIdsList.map((item:any) => item.accountId); 
//   let header = {
//     Authorization: "Bearer " + localStorage.getItem("t"),
//   };

//   let apiURL = `https://apis-eks.swayam.cloud/dashboard/dashboard_accounts_validate/?list_of_account_numbers=${accountIdArray}&user_type=client&aws_region=${this.regionId}`;
 
//   try {
//     await this.apiServer.postDataPromis(apiURL, {}, header).then((data) => {
     
//     const disableacc =  Object.entries(
//         data['accounts_ids']
//       ).map(([key, value]) => {
//         return { id: key, cost: value };
//       });
//       console.log('---<>');
//       console.log(disableacc);
//       const filteredArray = disableacc.filter((item:any) => !item.cost.sts_access || !item.cost.all_access); 
//       console.log(filteredArray);
//       const outputArray = filteredArray.map((item) => item.id); 

//       this.accountIdsList = this.accountIdsList.map((account:any) => ({ ...account, accessDenied: outputArray.includes(account.accountId) })); 

//       const countaccessFalse =  this.accountIdsList.reduce((count:any, item:any) => 
//       { return count + (item.accessDenied === true ? 1 : 0); }, 0); 
// console.log(countaccessFalse);
//       if(this.accountIdsList.length == countaccessFalse){
//         this.notaccess=false;
//       }else{
//         this.notaccess=true;
//       }
//       this.initSelectAll();
//       this.idAccessCheckingLoader = false;
//       console.log(this.accountIdsList);
//     });
//   } catch (error) {
// console.log(error);
//   }

// }
  submitAccId() {
    this.saveAccPreference();
    this.acctIdList.emit(this.selectedAccountIds);
    this.hideModal.emit({ status: 0 });
  }
  cancel(){
    this.hideView.emit({ status: 0 });
  }
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
