import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-view-scanner-results",
  templateUrl: "./view-scanner-results.component.html",
  styleUrls: ["./view-scanner-results.component.css"],
})
export class ViewScannerResultsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input("regionId") regionId: string;
  @Input("accountId") accountId: string;
  @Input("roleARN") roleARN: string;
  @Input("scanner") scanner: any;
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  resources: any = {
    cdn: [],
    elb: {
      targets: [],
      metrics: [],
    },
    ec2: [],
    rds: [],
  };

  loading: boolean = true;

  currentPage: string = "cdn";
  destroyed: boolean = false;

  protocolResource: any = null;

  constructor(private apiServer: APIService) {}

  pagination: any = {
    cdn: {
      page: 1,
      perPage: 10,
    },
    elb_metrics: {
      page: 1,
      perPage: 10,
    },
    elb_targets: {
      page: 1,
      perPage: 10,
    },
    rds: {
      page: 1,
      perPage: 10,
    },
    ec2: {
      page: 1,
      perPage: 10,
    },
  };

  currentMessage: any;

  ngOnInit(): void {}

  getSelectedCount(action: string) {
    return this.resources[action].filter((resource: any) => {
      return resource["selected"];
    })["length"];
  }

  async load() {
    this.loading = true;
    let data: any = {
      action: "get_health_data",
      scanner_id: this.scanner["scanner_id"],
      account_id: this.accountId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/alerts`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      if (result.testscsanner && Object.keys(result.testscsanner).length > 0) {
        this.resources["cdn"] = Object.keys(
          result["testscsanner"]["cloudfront"][`${this.regionId}`][
            "distributions"
          ]
        ).map((cdn_key: any) => {
          return {
            ...result["testscsanner"]["cloudfront"][`${this.regionId}`][
              "distributions"
            ][cdn_key],
            id: cdn_key,
          };
        });
        this.resources["elb"]["metrics"] = Object.keys(
          result["testscsanner"]["elb"][`${this.regionId}`]["metrics"]
        ).map((cdn_key: any) => {
          return {
            ...result["testscsanner"]["elb"][`${this.regionId}`]["metrics"][
              cdn_key
            ],
            id: cdn_key,
          };
        });
        Object.keys(
          result["testscsanner"]["elb"][`${this.regionId}`]["target_groups"]
        ).forEach((cdn_key: any) => {
          Object.keys(
            result["testscsanner"]["elb"][`${this.regionId}`]["target_groups"][
              cdn_key
            ]
          ).forEach((target: any) => {
            result["testscsanner"]["elb"][`${this.regionId}`]["target_groups"][
              cdn_key
            ][target].map((item: any) => {
              this.resources["elb"]["targets"].push({
                ...item,
                ebs_id: cdn_key,
                target: target,
              });
            });
          });
        });
        this.resources["rds"] = Object.keys(
          result["testscsanner"]["rds"][`${this.regionId}`]["metrics"][
            "metrics"
          ]
        ).map((cdn_key: any) => {
          return {
            ...result["testscsanner"]["rds"][`${this.regionId}`]["metrics"][
              cdn_key
            ],
            id: cdn_key,
          };
        });
        this.resources["ec2"] = Object.keys(
          result["testscsanner"]["ec2"][`${this.regionId}`]["instances"]
        ).map((cdn_key: any) => {
          return {
            ...result["testscsanner"]["ec2"][`${this.regionId}`]["instances"][
              cdn_key
            ],
            id: cdn_key,
          };
        });
      }
    }

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["scanner"]) {
      this.load();
    }
  }
}
