import { Component, Input, OnInit } from '@angular/core';

@Component({
  templateUrl: './amis_status.component.html',
  styleUrls: ['./amis_status.component.css'],
  selector: 'amis-status',
})
export class StatusAMIsComponent implements OnInit {
  @Input('data') data: any;
  constructor() {}

  ngOnInit(): void {
    // this.data.status = 'In progress';
  }
}
