import { Component, OnInit, OnDestroy, Input } from '@angular/core';
@Component({
  selector: 'details-launch-instance',
  templateUrl: 'details.launch.component.html',
})
export class DetailsLaunchInstanceComponent implements OnInit, OnDestroy {
  @Input('instance') instance: any;
  headers: any = [
    {
      id: 'instanceName',
      name: 'Instance Type',
    },
    {
      id: 'os',
      name: 'OS',
    },
    {
      id: 'cpu',
      name: 'CPU',
    },
    {
      id: 'ram',
      name: 'RAM',
    },
    {
      id: 'volumeSize',
      name: 'Volume Size',
    },
    {
      id: 'volumeCost',
      name: 'Volume Cost',
    },
  ];
  constructor() {}
  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
