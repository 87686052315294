<div class="card" id="anomalyDiv" [class.hide]="activeMonitor">
  <h5 class="card-header">Anomaly Dashboard</h5>
  <div class="card-body">
    <div class="date-picker display-flex">
      <input autocomplete="off" class="datetimepicker" type="text" [(ngModel)]="dateRange" />
    </div>
    <div class="card" [ngStyle]="{'display': (monitors.length > 0) ? 'block' : 'none'}">
      <h5 class="card-header">Anomaly Bar Chart</h5>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div id="graphdiv" class="visualGraph"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <h5 class="card-header">Anomaly Summary</h5>
      <div class="card-body">
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of monitorsHeader">{{ h.name }}</th>
              <th>Alarm Status</th>
            </tr>
          </thead>

          <tbody [ngStyle]="{'display': monitors.length > 0 ? 'table-row-group': 'none'}">
            <tr *ngFor="let i of monitors; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of monitorsHeader">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
              <td>
                <span *ngIf="i['status'] && writeAccess" (click)="createAlarmTrigger(i)" class="cursor-pointer">
                  <input autocomplete="off" type="checkbox" class="pointer-events" [(ngModel)]="i['status']">
                </span>
                <span *ngIf="!i['status'] && writeAccess" (click)="createAlarmTrigger(i)" class="cursor-pointer">
                  <input autocomplete="off" type="checkbox" class="pointer-events">
                </span>
                <span *ngIf="!writeAccess">{{i['status'] ? 'Enabled' : 'Disabled'}}</span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{'display': monitors.length == 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td [attr.colspan]="monitorsHeader.length + 2">
                <div class="text-align-center">No Monitors Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="activeMonitor && !activeAnomaly && !createTicket">
  <h5 class="card-header">
    <button class="btn btn-light" (click)="goBackToMonitor()">
      < Back</button>
        <span>Monitor: {{ activeMonitor.display_name }}</span>
  </h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of anomaliessHeader">{{ h.name }}</th>
          <th *ngIf="writeAccess">Jira Ticket</th>
          <th *ngIf="writeAccess">Ignore Anomaly</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': anomalies.length > 0 ?'table-row-group' : 'none'}">
        <tr *ngFor="let i of anomalies; let j = index">
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of anomaliessHeader">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>
          <td *ngIf="writeAccess" (click)="raiseTicket(i)" [ngClass]="{'click-disabled': !writeAccess}"
            [attr.disabled]="!writeAccess ? true : null"><span class="btn">Raise Ticket</span></td>
          <td *ngIf="writeAccess" (click)="ignoreAnomaly(i)" [ngClass]="{'click-disabled': !writeAccess}"
            [attr.disabled]="!writeAccess ? true : null"><span class="btn"><i class="fa fa-ban"
                aria-hidden="true"></i></span></td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': anomalies.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="anomaliessHeader.length + 3">
            <div class="text-align-center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="activeMonitor && activeAnomaly">
  <div class="card">
    <h5 class="card-header">
      <button class="btn btn-light" (click)="goBackToAnomaly()">
        < Back</button>
          <span>Ticket - {{ activeAnomaly.display_name }}</span>
    </h5>
    <div class="card-body">
      <div class="card">
        <h5 class="card-header">
          <span>Anomaly Details</span>
        </h5>
        <div class="card-body">
          <div class="panel panel-default">
            <div class="panel-heading font-size-medium"></div>

            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <b>Monitor ID</b>
                  <p id="eventname">
                    {{ anomalyDetail && anomalyDetail.monitor_id }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Monitor Name</b>
                  <p id="eventid">
                    {{ anomalyDetail && anomalyDetail.display_name }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Monitor Type</b>
                  <p id="readonly">
                    {{ anomalyDetail && anomalyDetail.monitor_type }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>AWS ARN</b>
                  <p id="eventsource">
                    {{ anomalyDetail && anomalyDetail.aws_arn }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Region</b>
                  <p id="aws_access_key">
                    {{ anomalyDetail && anomalyDetail.region }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Severity</b>
                  <p id="aws_region">
                    {{ anomalyDetail && anomalyDetail.severity }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Present Value</b>
                  <p id="eventtime">
                    {{ anomalyDetail && anomalyDetail.present_value }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Base Value</b>
                  <p id="username">
                    {{ anomalyDetail && anomalyDetail.base_value }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Attribute Name</b>
                  <p id="eventtime">
                    {{ anomalyDetail && anomalyDetail.attribute_name }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Created Time</b>
                  <p id="username">{{ anomalyDetail && anomalyDetail.time }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Formatted Attribute</b>
                  <p id="eventtime">
                    {{ anomalyDetail && anomalyDetail.formatted_attribute }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Average Presentvalue</b>
                  <p id="username">
                    {{ anomalyDetail && anomalyDetail.attribute_unit }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Deviation</b>
                  <p id="eventtime">
                    {{ anomalyDetail && anomalyDetail.deviation }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Decision</b>
                  <p id="username">
                    {{ anomalyDetail && anomalyDetail.decision }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b>Anomaly Comments</b>
                  <p id="eventtime" [innerHTML]="anomalyDetail && anomalyDetail.anomaly_comment"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<createalarm-anomalies *ngIf="createAlarm" [inputMonitor]="createAlarm" (hideModel)="hideModel($event)">
</createalarm-anomalies>
<create-ticket *ngIf="createTicket" [createTicketStrictTicketType]="true" [createTicketSupportType]="'minfy'"
  [createTicketSubject]="createTicket.subject" [createTicketDescription]="createTicket.description"
  (hideCreateTicket)="hideCreateTicket($event)"></create-ticket>

<style>
  .visualGraph {
    width: 100%;
    height: 400px;
  }



  #anomalyDiv.hide {
    display: none;
  }
</style>