import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let Jhxlsx: any;

@Component({
  selector: "cost-optimization-questions-ftr",
  templateUrl: "./questions-ftr.component.html",
  styleUrls: [
    "./questions-ftr.component.css",
    "./../ftr-check/ftr-check.component.css",
    "./../../ftr.component.css",
  ],
})
export class CostOptimizationQuestionsFtrComponent implements OnInit {
  whitelabelData: any = {
    pd: {
      appName: "",
      logoDark: "",
      logoLight: "",
    },
  };
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    let whitelabelData = localStorage.getItem("whitelabelData");
    if (whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }
  @Input("checks") checksObj: any;
  @Input("filters") filters: any;
  @Input("type") type: any;
  compareChecks: any;
  @Input("job") job: any;
  @Input("checksAcc") checksAccObj: any;
  @Output("hideModal") hideModal: any = new EventEmitter<boolean>();
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  @Input("requirement") requirement: string;
  loading: boolean = false;

  checksAcc: any = [];
  checks: any = [];

  showSidebar: boolean = true;
  selectedRequirement: string;
  accountId: string;
  regionId: string;
  requirementOriginal: string;

  ngOnInit(): void {
    this.compareChecks = JSON.stringify(this.checksObj);
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    if (this.requirement) {
      this.selectedRequirement = this.requirement;
      this.requirementOriginal = JSON.stringify(this.selectedRequirement);
    } else {
      this.selectedRequirement = this.checksAccObj[0]["check_root"];
      this.requirementOriginal = JSON.stringify(this.selectedRequirement);
    }
    this.changeFilter(this.filters["questions"]);
  }

  updateHideDetails(event: any, option: any) {
    event.stopPropagation();
    option["view_details"] = !option["view_details"];
  }

  async changeFilter(filter: string, ui: boolean = false) {
    // if (ui) {
    //   if (this.selectedRequirement && this.filterQuestions().length > 0) {
    //     let check_: boolean = await this.saveQuestions();
    //     if (!check_) {
    //       return false;
    //     }
    //   }
    // }

    this.filters["questions"] = filter;
    if (this.filters["questions"] == "All") {
      this.checks = this.checksObj;
      this.checksAcc = this.checksAccObj;

      // this.selectedRequirement = this.checksAcc[0]["check_root"];
      this.requirementOriginal = JSON.stringify(this.selectedRequirement);
    } 
    else {
      let parents: string[] = [];
      this.checks = this.checksObj.filter((check: any) => {
        let check_: any = false;
        if (this.filters["questions"] == "Unanswered") {
          check_ =
            check["service"] != "manual" && check["status_check"] == false;
        }
        if (this.filters["questions"] == "Answered") {
          check_ =
            check["service"] != "manual" && check["status_check"] == true;
        }
        if (this.filters["questions"] == "Manual") {
          check_ = check["service"] == "manual";
        }
        if (check_) {
          parents.push(check["check_root"]);
        }
        return check_;
      });
      this.checksAcc = this.checksAccObj.filter((check: any) => {
        return parents.includes(check["check_root"]);
      });
      if (this.checksAcc.length > 0) {
        this.selectedRequirement = this.checksAcc[0]["check_root"];
        this.requirementOriginal = JSON.stringify(this.selectedRequirement);
      }
    }
    this.checkForMetaData();
    return true;
  }

  checkForMetaData() {
    if (this.selectedRequirement) {
      let checks: any = this.filterQuestions();
      checks.forEach((check: any) => {
        if (check["check_id"] == "WAFR-002") {
          this.loadSSBJobs(check);
        }
        if (check["check_id"] == "RES-004") {
          this.loadBackupData(check);
        }
      });
      return true;
    } else {
      return null;
    }
  }

  async loadSSBJobs(check: any) {
    check["loading_metadata"] = true;

    let data: any = {
      action: "list_jobs",
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/security/ssb`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      if (result.jobs.length > 0) {
        result.jobs = result.jobs.sort((a: any, b: any) => {
          let date_a: any = new Date(a["cr_time"]);
          let date_b: any = new Date(b["cr_time"]);
          return Date.parse(date_b) - Date.parse(date_a);
        });

        result.jobs.forEach((job: any) => {
          job["cr_time"] = moment(job["cr_time"]).format(
            "MMM DD, YYYY hh:mm A"
          );
          job["cm_time"] = moment(job["cm_time"]).format(
            "MMM DD, YYYY hh:mm A"
          );
          if (!job["name"]) {
            job["name"] = job["job_id"];
          }
        });

        check["metadata"] = [result.jobs[0]];
      } else {
        check["metadata"] = [];
      }
    } else {
      check["metadata"] = [];
    }

    check["loading_metadata"] = false;
  }

  async loadBackupData(check: any, nextToken: string = undefined) {
    check["loading_reason"] = true;

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.items) {
        let data = JSON.parse(JSON.stringify(result.items));
        let momentObjDefault: any = moment().valueOf();
        if (result.items.length == 1) {
          result.items.push(result.items[0]);
        }
        result.items.forEach((item: any) => {
          let momentObj: any = moment(momentObjDefault);
          if (item["ami_avg_retention"] != "NA") {
            let temp: any = item["ami_avg_retention"].split(" ");
            let finalText: string = ``;
            for (let i = 0; i < temp.length / 2; i++) {
              if (Number(temp[i * 2]) == 0) {
                continue;
              }
              finalText += `${temp[i * 2]} ${temp[i * 2 + 1]}`;
              if (i * 2 + 1 < temp.length - 2) {
                finalText += `, `;
              }
              momentObj.add(temp[i * 2], temp[i * 2 + 1]);
            }
            item[
              "ami_avg_retention"
            ] = `<span class='text-bold fw-bold'>${finalText}</span>`;
            item["ami_avg_retention_time_stamp"] = momentObj.valueOf();
          } else {
            item[
              "ami_avg_retention"
            ] = `<span class='text-bold fw-bold'>0 minute</span>`;
            item["ami_avg_retention_time_stamp"] = 0;
          }
          momentObj = null;
        });
        let ami_avg_retention: any = result.items.sort((a: any, b: any) => {
          return (
            a["ami_avg_retention_time_stamp"] -
            b["ami_avg_retention_time_stamp"]
          );
        });

        let ami_since: any = data
          .map((ami: any) => {
            ami["ami_since"] =
              ami["ami_since"] == "NA" ? "0" : ami["ami_since"];
            return ami;
          })
          .sort((a: any, b: any) => {
            return Number(a["ami_since"]) - Number(b["ami_since"]);
          });
        check[
          "reason"
        ] = `As per snapshot status found, the minimum retention time is ${
          ami_avg_retention[0]["ami_avg_retention"]
        } and the maximum is ${
          ami_avg_retention[ami_avg_retention.length - 1]["ami_avg_retention"]
        }. The minimum snapshot retention found is <span class='text-bold fw-bold'>${
          ami_since[0]["ami_since"]
        } days</span> and the maximum found is <span class='text-bold fw-bold'>${
          ami_since[ami_since.length - 1]["ami_since"]
        } days</span>`;
      }
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    check["loading_reason"] = true;
  }

  async changeRequirement(check: any) {
    // if (this.selectedRequirement && this.filterQuestions().length > 0) {
    //   let check_: boolean = await this.saveQuestions();
    //   if (!check_) {
    //     return false;
    //   }
    // }
    this.selectedRequirement = check["check_root"];
    this.requirementOriginal = JSON.stringify(this.selectedRequirement);
    this.checkForMetaData();
    return true;
  }

  filterQuestions() {
    return this.checks.filter((check: any) => {
      return check["check_root"] == this.selectedRequirement;
    });
  }

  sliceOfferders(check: any) {
    if (check.offenders.length < 5) return check["offenders"];
    if (check["show_offenders"]) {
      return check["offenders"];
    } else {
      return check["offenders"].slice(0, 5);
    }
  }

  showOffenders(event: any, option: any) {
    event.stopPropagation();
    option["show_offenders"] = !option["show_offenders"];
  }

  selectOption(check: any, index: number) {
    check["status_check"] = !check["status_check"];
    this.saveQuestion(check, index);
  }

  prevent(event: any) {
    // console.log("clicked in prevent");
    event.stopPropagation();
  }

  showCheck(check: any) {
    if (check["expanded"]) {
      check["expanded"] = false;
      return;
    } else {
      check["expanded"] = true;
    }
  }

  checkauto(service: any): string {
    if (service == "manual") {
      return "Manual";
    } else {
      return "Automatic";
    }
  }

  saveComment(event: any, option: any) {
    event.stopPropagation();

    option["comment"] = option["edit_comment"];
    option["edit_comment"] = undefined;
  }

  editComment(event: any, option: any) {
    event.stopPropagation();

    option["edit_comment"] = option["comment"] || "";
  }

  setEvidence(event: any, check: any, value: boolean) {
    event.stopPropagation();
    check["evidence"] = value;
    check["edit_evidence"] = undefined;
  }

  showBackup: boolean = false;

  DECISIONS: any = {
    ACCEPT: "Accepted",
    REMEDIATE: "Remediate",
    NOT_APPLICABLE: "Not Applicable",
  };
  setDecision(event: any, check: any, value: string) {
    event.stopPropagation();
    check["decision"] = value;
    check["edit_decision"] = undefined;
  }

  resetEvidence(event: any, check: any) {
    event.stopPropagation();
    check["edit_evidence"] = true;
    check["evidence"] = null;
    check["decision"] = null;
  }

  resetDecision(event: any, check: any) {
    event.stopPropagation();
    check["edit_decision"] = true;
    check["decision"] = null;
  }

  // async saveQuestions(): Promise<boolean> {
  //   this.loading = true;
  //   return new Promise((resolve: any, reject: any) => {
  //     let promises: any = [];
  //     this.filterQuestions().forEach((question: any, index: number) => {
  //       if (
  //         question.old_status_check == question.status_check &&
  //         question.old_comment == question.comment
  //       ) {
  //         return;
  //       }
  //       promises.push(this.saveQuestion(question, index));
  //     });
  //     if (promises.length == 0) {
  //       this.notifier.alert(
  //         "Success",
  //         "",
  //         "Updated successfully",
  //         "success",
  //         5000
  //       );
  //       this.loading = false;
  //       resolve(true);
  //       return;
  //     }
  //     Promise.all(promises).then((values: any) => {
  //       if (values.length > 0) {
  //         let temp: any = values.find((value: any) => {
  //           return value["status"] == "0";
  //         });
  //         if (temp) {
  //           this.notifier.alert("Info", "", temp.message, "info", 5000);
  //         } else {
  //           this.notifier.alert(
  //             "Success",
  //             "",
  //             "Updated successfully",
  //             "success",
  //             5000
  //           );
  //         }
  //       }
  //       resolve(true);
  //       this.loading = false;
  //     });
  //   });
  // }

  async saveQuestion(question: any, index: number) {
    if (
      question["evidence"] == false &&
      (!question["comment"] || question["comment"].trim() == "")
    ) {
      if (question["errors"]) {
        question["errors"]["comment"] = true;
      } else {
        question["errors"] = { comment: true };
      }
      document
        .querySelector(".options_container")
        .querySelectorAll(".option")
        [index].querySelector("textarea")
        .scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      return {
        status: "0",
        message: `Check: ${question["title"]}<br><br>Comment is mandatory when evidence is exception`,
      };
    } else {
      if (question["errors"]) {
        question["errors"]["comment"] = false;
      }
    }

    if (question["errors"]) {
      question["errors"]["comment"] = false;
    }
    let data: any = {
      action: "update_check",
      account_id: this.accountId,
      region_id: this.regionId,
      job_id: this.job["job_id"],
      check_id: question["check_id"],
      status_check: question["status_check"],
      status: question["status"],
      comment: question["comment"] || "",
      ...this.type?.other?.payload,
      // decision: question["decision"],
      // evidence: question["evidence"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}${this.type.api}`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      question["old_comment"] = question["comment"];
      question["old_status_check"] = question["status_check"];
      let compareChecks: any = JSON.parse(this.compareChecks);
      let questionIndex: any = compareChecks.findIndex((check: any) => {
        return check["check_id_uuid"] == question["check_id_uuid"];
      });
      if (question) {
        compareChecks[questionIndex] = question;
        this.compareChecks = JSON.stringify(compareChecks);
      }
      this.notifier.alert(
        "Success",
        "",
        "Updated successfully",
        "success",
        5000
      );
      return { status: "1", message: result.message };
    } else {
      return {
        status: "0",
        message: result.error_message || result.error || result.message,
      };
    }
  }

  checkUnAnswered() {
    return this.filterQuestions().filter((check: any) => {
      return check["status"] == "false";
    });
  }

  preventDefault(event: any) {
    event.stopPropagation();
  }

  // async checkAnswered(): Promise<boolean> {
  //   return new Promise((resolve: any, reject: any) => {
  //     let options: any = JSON.parse(this.compareChecks).filter((check: any) => {
  //       return check["check_root"] == this.selectedRequirement;
  //     });
  //     let promises: any = [];

  //     options.forEach((option: any, index: number) => {
  //       option = JSON.parse(JSON.stringify(option));
  //       delete option["edit_evidence"];
  //       delete option["edit_decision"];
  //       delete option["loading_metadata"];
  //       let originalOption: any = this.checksObj.find((check: any) => {
  //         return check["check_id"] == option["check_id"];
  //       });

  //       if (originalOption) {
  //         originalOption = originalOption;
  //         if (
  //           option["evidence"] != originalOption["evidence"] ||
  //           option["decision"] != originalOption["decision"] ||
  //           option["comment"] != originalOption["comment"] ||
  //           option["checkbox"] != originalOption["checkbox"]
  //         ) {
  //           promises.push(this.saveQuestion(originalOption, index));
  //         }
  //       }
  //     });
  //     if (promises.length > 0) {
  //       Promise.all(promises).then((values: any) => {
  //         if (values.length > 0) {
  //           let temp: any = values.find((value: any) => {
  //             return value["status"] == "0";
  //           });
  //           if (temp) {
  //             this.notifier.alert("Info", "", temp.message, "info", 5000);
  //             resolve(false);
  //           } else {
  //             resolve(true);
  //           }
  //         } else {
  //           resolve(true);
  //         }
  //       });
  //     } else {
  //       resolve(true);
  //     }
  //   });
  // }

  async hide(event: boolean) {
    // if (this.selectedRequirement && this.filterQuestions().length > 0) {
    //   let check: any = await this.saveQuestions();
    //   if (!check) {
    //     return false;
    //   }
    // }
    this.hideModal.emit(event);
    return true;
  }
}
