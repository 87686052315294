<div class="amis_body">
  <div class="amis_more">
    <div>
      <div class="mb-3">
        <label for="" class="vpc_id_05">VPC ID</label>
        <launch-select-dropdown [options]="vpcs" (update)="updateReports($event)" key="vpc_id"
          [selected]="instance.vpc_id" title="VPC"></launch-select-dropdown>
        <div class="error-text" *ngIf="instance.errors && instance.errors.vpc_id && !instance.vpc_id">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.vpc_id}}
        </div>
      </div>
      <div class="mb-3">
        <label for="" class="vpc_id_05">Subnet</label>
        <launch-select-dropdown [options]="subnets" (update)="updateReports($event)" key="subnet_id"
          [selected]="instance.subnet_id" title="Subnet">
        </launch-select-dropdown>
        <div class="error-text" *ngIf="instance.errors && instance.errors.subnet_id && !instance.subnet_id">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.subnet_id}}
        </div>
      </div>
      <div class="mb-3">
        <label for="" class="vpc_id_05">Security Group</label>
        <launch-select-dropdown [options]="sgs" key="id" (update)="updateReports($event)"
          [selected]="instance.sg_ids[0]" title="Security Group">
        </launch-select-dropdown>
        <div class="error-text" *ngIf="instance.errors && instance.errors.sg_ids && !instance.sg_ids[0]">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.sg_ids}}
        </div>
      </div>
    </div>
  </div>
</div>