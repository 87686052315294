<div class="container">
  <div class="chat-container">
    <div class="alert alert-warning d-flex justify-content-between">
      <div>
        <div class="logo-hold">
          <div class="head-logo logo-container">
            <img src="./assets/img/swayam_logo_v3.0.svg" alt="">
          </div>
          <div class="status-dot" [ngClass]="{'online': socketOpen, 'offline': !socketOpen}"></div>
        </div>
        <div class="head-title">
          <h5>CloudResolve</h5>
        </div>
      </div>

      <div class="dropdown" [ngClass]="{'disabled':waiting || printingInProgress || isQuesloading}">
        <div class="dropdown-toggle" (click)="toggleDropdown()">
          <span class="drop-opt">{{ selectedOption }}</span>
          <span class="dropdown-icon">&#9662;</span>
        </div>
        <div *ngIf="!printingInProgress" class="dropdown-menu" [class.open]="isDropdownOpen">
          <div class="dropdown-option" *ngFor="let option of options" [class.selected]="option === selectedOption"
            (click)="selectOption(option.key)" (click)="sendDefultMsg(option.id)">
            {{ option.key }}
          </div>
        </div>
      </div>
    </div>
    <div class="chat-box">

      <div class="message-container">

        <!-- <ng-container *ngIf="!isPresent">
          <div class="message received info">


            <div class="message-body" class="logo-container">
              <img src="./assets/img/swayam_logo_v3.0.svg" alt="">
            </div>

            <div class="message-body">
              Currently, this feature is not available in your account. In order to enable this feature for your account, please <span class="link-text" (click)="checkForBillingData($event)" >click here.</span>
            </div>

          </div>
        </ng-container> -->

        <div *ngFor="let message of messages; let i = index" class="message"
          [ngClass]="{'sent': message.sender === 'user', 'received': message.sender === 'bot', 'info': message.info === true}">


          <div class="message-body" *ngIf="message.textDisplay != undefined && message.sender == 'bot'"
            class="logo-container">
            <img src="./assets/img/swayam_logo_v3.0.svg" alt="">
          </div>


          <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.click != true  && message.init!=true && message.injestGenAi!=true && message.doneRefresh!=true && message.wClick != true && message.wInit!=true && message.wfound != true && message.firstMsg != true "
            [ngClass]="{ dimpler: message.animateCompleted == false}" [innerHTML]="message.text"></div>

           <!-- Error Msg if Billing data is not present in collection -->
            <!-- <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.click == true  && message.init!=true"
            [ngClass]="{ dimpler: message.animateCompleted == false }">
            Currently, this feature is not available in your account. In order to enable this feature for your account, please <span class="link-text" (click)="checkForBillingData($event)" >click here.</span>
            </div> -->
            <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.firstMsg == true  && message.init!=true"
            [ngClass]="{ dimpler: message.animateCompleted == false }">
            Billing data is available from {{startYear}} to {{lastYear}}, Would you like to refresh the data? <span class="link-text" (click)="checkForBillingData()" [ngClass]="{'disabled':clickYes}" ><b>Yes</b></span>&nbsp;/&nbsp;<span class="link-text" (click)="refreshPage($event)" [ngClass]="{'disabled':clickYes}" ><b>No</b></span>
            </div>

            <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.click == true  && message.init!=true"
            [ngClass]="{ dimpler: message.animateCompleted == false }">
            Preparing data ingestion for {{startYear}} to {{lastYear}}... 
            </div>

            <!-- Checking billing data is present or not -->
            <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.click != true && message.init==true"
            [ngClass]="{ dimpler: message.animateCompleted == false }">
            Checking Billing Information for <b>{{selectedAccountId}}</b> account...  &nbsp;<img *ngIf="initloader" src="../assets/img/loading_2.svg" alt="loading scans" class="initLoader" />
            <i class="fa-solid fa-circle-check" *ngIf="!initloader"></i>
            </div>

            <!-- Calling stepFunction-->
            <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.click != true && message.init!=true && message.injestGenAi==true"
            [ngClass]="{ dimpler: message.animateCompleted == false }">
            Preparing to ingest latest billing data for this account for GenAI.This activity may take upto 3 minutes.. <br><br>
            <div class="progress-container">
              <div id="progress" class="progress-bar progress-bar-striped progress-blue progress-bar-animated">{{progressValue}}</div>
            </div>
            <!-- <img *ngIf="injestGenAiLoader" src="../assets/img/loading_2.svg" alt="loading scans" class="initLoader" />
            <i class="fa-solid fa-circle-check" *ngIf="!injestGenAiLoader"></i> -->
            </div>

             <!-- Done Refresh the page-->
             <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.click != true && message.init!=true && message.injestGenAi!=true && message.doneRefresh==true"
             [ngClass]="{ dimpler: message.animateCompleted == false }">
             Data is successfully ingested.Please <span class="link-text" (click)="refreshPage($event)" >click here</span> to start using CloudResolve for Billing data from {{startYear}} to {{lastYear}}.
             </div>
             <!-- --------------------WAFR--------------------- -->
             <!-- Error Msg if WAFR data is not present in collection -->
            <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.wClick == true  && message.init!=true"
            [ngClass]="{ dimpler: message.animateCompleted == false }">
            Currently, this feature is not available in your account. In order to enable this feature for your account, please <span class="link-text" (click)="checkForWAFRData($event)" >click here.</span>
            </div>

            <!-- Checking wafr data is present or not -->
            <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.click != true && message.wInit==true"
            [ngClass]="{ dimpler: message.animateCompleted == false }">
            Checking WAFR Information for <b>{{selectedAccountId}}</b> account..  &nbsp;<img *ngIf="initloader" src="../assets/img/loading_2.svg" alt="loading scans" class="initLoader" />
            <i class="fa-solid fa-circle-check" *ngIf="!initloader"></i>
            </div>

             <!-- done check in wafr data  -->
             <div class="message-body" *ngIf="message.textDisplay != undefined && message.isOpt != true && message.wfound == true  && message.init!=true"
             [ngClass]="{ dimpler: message.animateCompleted == false }">
             In your WAFR workload (<b>{{workload_name}}</b>) there are <b>{{fetchTotalAnsweredQuestions().length}}</b> selected questions, If you wish to proceed please <span class="link-text" (click)="checkForWAFRData($event)" >click here.</span>
             </div>

          <div class="iconhold">
            <div class="copyicon" *ngIf="message.sender == 'bot' && message.isCopy==true && message.isCopyDone==false"
              (click)="copyToClipboard(message.downloadText,i)"><i class="fa-regular fa-copy"></i></div>
            <div *ngIf="message.sender == 'bot' && message.isCopy==true && message.isCopyDone==true"><i
                class="fa-solid fa-check"></i>&nbsp;Copied!</div> <br>
            <div class="downloadicon" *ngIf="message.sender == 'bot' && message.isCopy==true"
              (click)="downloadText(message.downloadText)"><i class="fa-solid fa-download"></i></div>
          </div>

          <div *ngIf="message.isOpt==true" class="d-flex flex-wrap opthold">
            <ng-container *ngFor="let item of message.optList">
              <button class="option-recived" [ngClass]="{'disabled':waiting || printingInProgress}"
                (click)="sendQuestion(item.op)">{{item.op}} </button>
            </ng-container>
          </div>

        </div>
        <!-- predefind message -->
        <ng-container *ngIf="socketOpen && welcomeMsg">
          <div class="d-flex flex-wrap catehold" [ngClass]="{'disabled':waiting || printingInProgress || isQuesloading }">
            <span class="option-recived" [class.active]="isManageActive"
              (click)="sendDefultMsg('managed_services_info')">Infrastructure Troubleshooting and Support</span>
            <span class="option-recived" [class.active]="isAccActive" (click)="sendDefultMsg('account_info')">Demo
              Account</span>
            <span class="option-recived" [class.active]="isSwayamActive"
              (click)="sendDefultMsg('minfylabs')">About</span>
            <span class="option-recived" [class.active]="isBillingActive"
              (click)="sendDefultMsg('billing_info')">Billing</span>
              <span class="option-recived" [class.active]="isWafrActive"
              (click)="sendDefultMsg('wafr_info')">WAFR</span>
          </div>
          <div class="message received">


            <div class="message-body" class="logo-container">
              <img src="./assets/img/swayam_logo_v3.0.svg" alt="">
            </div>

            <div class="message-body">
              Hi, Welcome to {{ whitelabelData['pd']['appName'] }}! Please choose from one of the below categories.
            </div>

          </div>
        </ng-container>

        
        <!-- predefind message -->

      </div>
      <div *ngIf="waiting" class="message received">
        <div class="message-body"
        class="logo-container">
        <img src="./assets/img/swayam_logo_v3.0.svg" alt="">
      </div>
        <div class="waiting message-body">
          <div class="wait"></div>
          <div class="wait"></div>
          <div class="wait"></div>
        </div>
      </div>
    </div>
    <div class="text-center"><button *ngIf="printingInProgress" (click)="stopPrinting()" type="button" class="btn btn-light stop-btn"><span><i class="fa-regular fa-circle-stop"></i></span>Stop Responding</button></div>
    <div class="chat-form" [ngClass]="{'disabled':waiting || printingInProgress || isQuesloading }">
      <input type="text" class="chat-input" [(ngModel)]="newMessage" (keydown.enter)="sendMessage()"
        (drop)="onFileDropped($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
        [class.is-dragging-file]="isDraggingFile" placeholder="Type your message...">
      <button type="submit" (click)="sendMessage()" class="chat-submit"><i class="fa-solid fa-paper-plane"></i></button>
    </div>
  </div>
</div>