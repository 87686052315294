import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'sg-option-launch-select-dropdown',
  templateUrl: './sg.dropdown.launch.component.html',
  styleUrls: ['./sg.dropdown.launch.component.css'],
})
export class SGLaunchDropdownComponent implements OnInit, OnDestroy {
  @Input() sg: any;
  ngOnInit(): void {}

  constructor() {}

  ngOnDestroy(): void {}
}
