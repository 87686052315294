<div class="screen">
  <div class="screen-container">
    <div class="screen-body flex-column">
      <ng-container *ngIf="!moreDetails">
        <div class="screen-header">
          <div class="screen-header-text center">
            <span class="swayam-color">{{ whitelabelData['pd']['appName'] }}</span> now offers <span class="site24x7-color">Site</span>24x7 Integration
          </div>
        </div>
        <ng-container *ngIf="!termsRead">
          <div class="center small-text">
            {{ whitelabelData['pd']['appName'] }} now offers integration with Zoho's Site24x7 Enterprise Monitoring System (EMS). This integration
            provides you with advanced server monitoring capabilities including downtime alerts. {{ whitelabelData['pd']['appName'] }} charges for
            these monitors on an hourly basis. You can install
            the
            monitors
            on all your accounts and enable/suspend them on demand. Each monitor in the Enabled state is charged at the
            rate of USD
            0.003 per instance per hour. <span class="nav-link" (click)="moreDetails = true">Click here</span> for
            details
          </div>
        </ng-container>
        <ng-container *ngIf="termsRead && !otpSent">
          <div class="form-group mb-2">
            <label>Company Name</label>
            <input type="text" class="form-control" [attr.disabled]="loading ? true : null"
              placeholder="Company Name..." [(ngModel)]="displayName" id="">
          </div>
        </ng-container>
        <ng-container *ngIf="termsRead && otpSent">
          <div>OTP</div>
          <div class="width-100 pd-0">
            <input type="text" name="" id="" class="form-control" [attr.disabled]="loading ? true : null"
              [(ngModel)]="otpText">
          </div>
          <div class="flex-end nav-link pb-2" *ngIf="!loading" (click)="sendOTP()">
            Resend OTP
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="moreDetails">
        <div class="flex-start mb-3 text-left width-100">
          <button class="flex-start btn btn-light" (click)="moreDetails = false">
            <i class="fa fa-angle-left"></i>
            Back
          </button>
          <span>Calculate Estimated Cost</span>
        </div>
        <site24x7-more-details></site24x7-more-details>
      </ng-container>
    </div>
    <div class="screen-actions flex-center" *ngIf="!moreDetails">
      <ng-container *ngIf="!loading">
        <button class="btn btn-primary-soft" (click)="termsRead = true" *ngIf="!termsRead">Next</button>
        <button class="btn btn-primary-soft" (click)="sendOTP()" *ngIf="termsRead && !otpSent">Enable</button>
        <button class="btn btn-primary-soft" (click)="validateOTP()" *ngIf="termsRead && otpSent">Submit</button>
      </ng-container>
      <ng-container *ngIf="loading">
        <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
      </ng-container>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>