<div>
  <ng-container *ngIf="bom['status'] == 'Process completed'">
    <div class="pricing_strategy_container" *ngFor="let header of selectedHeaders">
      <div class="pricing_sheet_header">
        <div>
          Sheet <span class="font_bold_alter">{{header.key}}</span> mapped to <span
            class="font_bold_alter">{{header.value}}</span>
        </div>
        <div class="datepicker_container"
          *ngIf=" pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length> 0">
          <span>Start Date</span>
          <span>{{formatDate(pricingStrategy[header.key]['start_date'], "DD-MM-YYYY", "DD-MM-YYYY")}}</span>
          <span>End Date</span>
          <span>{{pricingStrategy[header.key]['end_date']}}</span>
        </div>
      </div>
      <div class="pricing_sheet_body">
        <div *ngIf="header.value == 'Exclude'">
          <div class="no_strategy">
            No Pricing Plan
          </div>
        </div>
        <div *ngIf="header.id != 'exclude'">
          <div class="no_strategy"
            *ngIf="!pricingStrategy[header.key] || !pricingStrategy[header.key]['plans'] || pricingStrategy[header.key]['plans'].length == 0">
            No Pricing Plan
          </div>
          <div *ngIf="pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length > 0">
            <div class="strategy_div" *ngFor="let strategy of pricingStrategy[header.key]['plans']; let i = index">
              <div class="strategy_31">
                <div>
                  <div>
                    <label for="">
                      Pricing Plan
                    </label>
                  </div>
                  <span>
                    {{strategy.plan}}
                  </span>
                </div>
                <div *ngIf="strategy.plan == 'OD'">
                  <div>
                    <label for="">
                      Hours per month
                    </label>
                  </div>
                  <span>
                    {{strategy.hours_per_month}}
                  </span>
                </div>
                <div>
                  <div>
                    <label for="">
                      Duration
                    </label>
                  </div>
                  <div *ngIf="strategy.plan == 'OD'" class="strategy_58">
                    {{strategy.duration}} {{strategy.period || 'Days'}}
                  </div>
                  <div *ngIf="strategy.plan != 'OD'">
                    {{getDuration(strategy.plan).duration}}
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </ng-container>
  <ng-container *ngIf="bom['status'] != 'Process completed'">
    <div class="pricing_strategy_container" *ngFor="let header of selectedHeaders">
      <div class="pricing_sheet_header">
        <div>
          Sheet <span class="font_bold_alter">{{header.key}}</span> mapped to <span
            class="font_bold_alter">{{header.value}}</span>
        </div>
        <div class="datepicker_container"
          *ngIf="pricingStrategy[header.key] && pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length> 0">
          <span>Start Date</span>
          <app-datepicker [date]="pricingStrategy[header.key]['start_date']"
            (updateEndDate)="setEndDate($event, header.key)"></app-datepicker>
          <span>End Date</span>
          <span>{{pricingStrategy[header.key]['end_date']}}</span>
        </div>
      </div>
      <div class="pricing_sheet_body">
        <div *ngIf="header.id == 'exclude'">
          <div class="no_strategy">
            No Pricing Strategy required for excluded BOM
          </div>
        </div>
        <div *ngIf="header.id != 'exclude'">
          <div class="no_strategy"
            *ngIf="!pricingStrategy[header.key] || !pricingStrategy[header.key]['plans'] || pricingStrategy[header.key]['plans'].length == 0">
            No Pricing Strategy found for <span class="font_bold_alter">{{header.value}}</span>. <span class="link"
              (click)="addStrategy(header)">Click
              here</span> to add one
          </div>
          <div *ngIf="pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length > 0">
            <div class="strategy_div" *ngFor="let strategy of pricingStrategy[header.key]['plans']; let i = index">
              <div class="link remove_strategy_105" (click)="reomveStrategy(header.key, i)">
                <i class="fa fa-times" aria-hidden="true"></i>
                <span class="remove_106">Remove</span>
              </div>
              <div class="pricing_plan_109">
                <div>
                  <div>
                    <label for="">
                      Pricing Plan
                    </label>
                  </div>
                  <select class="form-select form_select_115" id="" [(ngModel)]="strategy.plan"
                    (change)="setEndDate(this.pricingStrategy[header.key]['start_date'], header.key)">
                    <ng-container *ngFor="let plan of plans">
                      <option *ngIf="plan.plan == 'OD' && checkForOD(header) == undefined" value="{{plan.plan}}">
                        {{plan.plan}}
                      </option>
                      <option *ngIf="plan.plan == 'OD' && strategy.plan == 'OD'" value="{{plan.plan}}">
                        {{plan.plan}}
                      </option>
                      <option *ngIf="plan.plan != 'OD'" value="{{plan.plan}}">{{plan.plan}}</option>
                    </ng-container>
                  </select>
                </div>
                <div *ngIf="strategy.plan == 'OD'">
                  <div>
                    <label for="">
                      Hours per month
                    </label>
                  </div>
                  <input autocomplete="off" type="number" class="form-control"
                    [ngClass]="{'error_input': strategy.error && strategy.error.hours_per_month}"
                    [(ngModel)]="strategy.hours_per_month">
                  <div class="error-text" *ngIf="strategy.error && strategy.error.hours_per_month">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{strategy.error.hours_per_month}}
                  </div>
                </div>
                <div>
                  <div>
                    <label for="">
                      Duration
                    </label>
                  </div>
                  <div *ngIf="strategy.plan == 'OD'" class="strategy_Plan_148">
                    <input autocomplete="off" type="number" class="form-control"
                      [ngClass]="{'error_input': strategy.error && strategy.error.duration}"
                      [(ngModel)]="strategy.duration" (change)="
                      setEndDate(this.pricingStrategy[header.key]['start_date'], header.key)">
                    <select class="form-select form_select_115" id="" [(ngModel)]="strategy.period"
                      (change)="setEndDate(this.pricingStrategy[header.key]['start_date'], header.key)">
                      <option value="Days">Days</option>
                      <option value="Months">Months</option>
                    </select>
                  </div>
                  <div class="error-text" *ngIf="strategy.error && strategy.error.duration">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    {{strategy.error.duration}}
                  </div>
                  <div *ngIf="strategy.plan != 'OD'">
                    <input autocomplete="off" type="text" class="form-control" disabled="true"
                      [attr.value]="getDuration(strategy.plan).duration">
                  </div>
                </div>
              </div>
              <hr>
            </div>
            <div class="link addStrategy_171" (click)="addStrategy(header)">
              <i class="fa fa-plus" aria-hidden="true"></i>
              <span>Add</span>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </ng-container>
</div>