<ng-container *ngIf="!existing || mandatory">
  <div class="cft-banner">
    <div class="flex-between">
      <div class="flex-start gap-20">
        <div>
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
        <div>
          <div class="cft-banner-title" *ngIf="account['cft_status']">
            Update Required
          </div>
          <div class="cft-banner-title" *ngIf="!account['cft_status']">
            Configure CFT
          </div>
          <ng-container *ngIf="account['cft_status']">
            <div class="cft-banner-description" *ngIf="!mandatory">
              {{ whitelabelData['pd']['appName'] }} has launched many innovative features for which it needs additional permissions on your Cloud
              Account.
              <span class="cursor-pointer color-inherit" (click)="stepsUpdate = true">
                <u>
                  Know More
                </u>
              </span>
            </div>
            <div class="cft-banner-description" *ngIf="mandatory">
              This feature needs additional permissions. Please click on Update to enable the same
              <span class="cursor-pointer color-inherit" (click)="stepsUpdate = true">
                <u>
                  Know More
                </u>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!account['cft_status']">
            <div class="cft-banner-description">
              CFT is not configured for this account. Please click Configure to enable the same
              <span class="cursor-pointer color-inherit" (click)="stepsConfigure = true">
                <u>
                  Know More
                </u>
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="account['cft_status']">
        <button class="btn btn-primary-soft border-right-no" (click)="showUpdatePopup = true">Update</button>
        <button class="btn btn-primary-soft border-left-no relative more-actions-button" *ngIf="!mandatory">
          <i class="fa fa-angle-down"></i>
          <div class="overflow-container">
            <div class="line-item" (click)="remindMeLater($event, '1HOUR')">Remind me after 1 Hour</div>
            <div class="line-item" (click)="remindMeLater($event, '1DAY')">Remind me after 1 Day</div>
          </div>
        </button>
      </div>
      <div *ngIf="!account['cft_status']">
        <a class="btn btn-primary-soft border-right-no" href="{{account['cft_latest_url']}}"
          target="_blank">Configure</a>
      </div>
    </div>
  </div>
  <swayam-update-cft *ngIf="showUpdatePopup" [account]="account"
    (hideModel)="showUpdatePopup = false"></swayam-update-cft>
  <app-steps-configure-cft *ngIf="stepsUpdate" (hideModel)="stepsUpdate = false"></app-steps-configure-cft>
  <app-info *ngIf="stepsConfigure" [steps]="[0, 1, 2]" (hideModel)="stepsConfigure = false"></app-info>
</ng-container>