<div class="card mt-20 card_01">
  <h5 class="card-header">RI Order Report</h5>
  <div class="card-body">
    <div class="card_body_04">
      <button class="btn btn-light purchase_details_05" (click)="addPurchase()">Add
        Purchase Details</button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of riData; let j = index"
        [ngStyle]="{'display': riData.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i)">
            </span>

          </td>
          <td><span (click)="updateTrigger(i)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
          <td><span (click)="deleteTrigger(i)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': riData.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div class="no_data_found_35">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="unsaved_43" [ngStyle]="{'display': unsavedRI.length > 0 ? 'block': 'none'}">
  <div class="unsaved_46">
    <div class="unsaved_ri_46">
      <h4>Unsaved RI Order</h4>
      <div class="unsaved_48">
        <button class="btn btn-light purchase_details_05" (click)="addPurchase()">Add
          Purchase Details</button>
      </div>
    </div>
    <div (click)="unsavedRI = []" class="btn btn-light unsaved_53">
      X
    </div>
    <div class="sl_57">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers">{{ h.name }}</th>
            <th>Update</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody *ngFor="let i of unsavedRI; let j = index"
          [ngStyle]="{'display': unsavedRI.length > 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td>{{j + 1}}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
              <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i)">
              </span>

            </td>
            <td><span (click)="updateUnsavedRI(i, j)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
            <td><span (click)="deleteUnsavedRI(j)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': unsavedRI.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 3">
              <div class="no_data_found_35">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="save_details_91">
      <button class="btn btn-light purchase_details_05" (click)="saveDetails()">
        Save Details
      </button>
    </div>
  </div>
</div>

<enableri-update *ngIf="updateRI" [action]="updateAction" [ri]="updateRI" (hideModel)="closeUpdateTrigger($event)">
</enableri-update>