<div class="card">
  <div class="card-header flex-between">
    <h5>Security Groups</h5>
    <div class="flex-start">
      <button class="btn btn-light" (click)="load()">
        <span>
          Refresh
        </span>
      </button>
      <button class="btn btn-primary-soft" (click)="createJob($event)" *ngIf="jobProgress != 'In Progress'">
        <span>
          Scan
        </span>
      </button>
    </div>
  </div>
  <div class="card-body" *ngIf="jobProgress == 'In Progress'">
    <div class="job-progress flex-between">
      <span class="inherit">
        Scan is in Progress. Please wait
      </span>
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="width18" />

    </div>
  </div>
  <div class="card-body" *ngIf="jobProgress != 'In Progress'">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button *ngFor="let tab of sgTabs; let i = index" class="nav-link" [ngClass]="{'active' : i == 0}"
          id="nav-home-tab" [attr.data-bs-toggle]="'tab'" [attr.data-bs-target]="tab.metadata.id_str" type="button"
          role="tab" [attr.aria-controls]="tab.onclick" (click)="getSecurityGroups(tab.onclick)" aria-selected="true"
          [innerHTML]="tab.metadata.str">
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div *ngFor="let tab of sgTabs; let i = index" class="tab-pane " [ngClass]="{'active' : i == 0}"
        id={{tab.onclick}} role="tabpanel" [attr.aria-labelledby]="tab.onclick+'-tab'">
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of headers">
                {{ h.name }}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,sgList)">

                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>

                <span *ngIf="h.filter " class="inputseach">
                  <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody [ngStyle]="{'display': sgList.length > 0 ? 'table-row-group' : 'none'}"
            *ngFor="let i of sgList; let j = index">
            <tr *ngIf="notifier.filterTableColums(headers,i)">
              <td> <i *ngIf="!i.showNested" class="fa fa-caret-right"></i> <i *ngIf="i.showNested"
                  class="fa fa-caret-down"></i> {{ j + 1 }}</td>
              <td *ngFor="let h of headers">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
            <tr *ngIf="i.showNested" class="shownested-height">
              <td [attr.colspan]="headers.length + 1">
                <nTable [headers]="attachedInstancesHeader" [data]="i.attachedInstances"
                  (hideModal)="hideNested($event)"></nTable>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="sgList.length == 0">
            <tr>
              <td [attr.colspan]="headers.length + 1">
                <div class="No-sg-found">No Security Groups Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<sg-create-job-element *ngIf="createJobTrigger" (hideModal)="hideCreateJob($event)">
</sg-create-job-element>