<div class="card">
  <div class="card-header flex-between">
    <h5>RDS Cost Controls</h5>
    <div class="flex-start">
      <ng-container *ngIf="scanDetails">
        <span class="border-right">
          <div class="text-bold">Scanned on</div>
          <div>
            {{ scanDetails["lastScan"] || "-" }}
          </div>
        </span>
        <span>
          <div class="text-bold">Status</div>
          <div>
            {{ scanDetails["status"] || "-" }}
          </div>
        </span>
      </ng-container>
      <button class="btn btn-light" (click)="loadRecommendations()">
        Refresh
      </button>
      <button class="btn btn-primary-soft" (click)="createScan()">Scan</button>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="discovery-section-container">
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            {{ overview["recommendations"]() }}
          </span>
          <img
            *ngIf="loading"
            src="../assets/img/loading_2.svg"
            alt="loading scans"
            class="width-18"
          />
        </div>
        <div class="item-desc">Total Recommendations</div>
      </div>
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            {{ overview["current_spent"]() }}
          </span>
          <img
            *ngIf="loading"
            src="../assets/img/loading_2.svg"
            alt="loading scans"
            class="width-18"
          />
        </div>
        <div class="item-desc">Current Spent on recommended instances</div>
      </div>
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span> {{ overview["potential_savings"]() }} </span>
          <img
            *ngIf="loading"
            src="../assets/img/loading_2.svg"
            alt="loading scans"
            class="width-18"
          />
        </div>
        <div class="item-desc">Potential Savings on recommended instances</div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <ng-container
      *ngIf="
        !loading && scanDetails['status'] != '-' && recommendations.length > 0
      "
    >
      <div class="flex-end">
        <input
          type="text"
          [(ngModel)]="filterText"
          (keyup)="pagination['page'] = 1"
          class="form-control width-250px"
          placeholder="Search..."
        />
      </div>
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>DB Name</th>
            <th>DB Instance Type</th>
            <th>Current Spent</th>
            <th>Recommended DB Instance Type</th>
            <th>Recommended Spent</th>
            <th>Reason</th>
            <th>More Details</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let rds of filteredTable()
                | paginate
                  : {
                      itemsPerPage: pagination['perPage'],
                      currentPage: pagination['page'],
                      totalItems: filteredTable().length
                    };
              let i = index
            "
          >
            <tr>
              <td>
                {{ (pagination["page"] - 1) * pagination["perPage"] + i + 1 }}
              </td>
              <td>{{ rds["dbName"] }}</td>
              <td>{{ rds["dbInstanceType"] }}</td>
              <td>{{ rds["currentPrice"] }}</td>
              <td>{{ rds["recommendedType"] }}</td>
              <td>{{ rds["recommendedPrice"] }}</td>
              <td class="text_align_centre">
                <div class="failed_ami" (mouseenter)="setPosition($event)">
                  <div class="status_container">
                    <div class="black_borderbottom_2">{{rds['Reason']}}</div>
                  </div>
                  <div
                    class="failed_ami_message_container"
                    [ngStyle]="{
                      right: position.x ? position.x : 0,
                      top: position.y ? position.y : 0,
                      height: position.height,
                      width: position.width
                    }"
                  >
                    <div class="message_back">
                      <div>
                        <div
                          class="message_grid"
                          [ngStyle]="{
                            'max-width': position.overflow
                              ? position.overflowwidth
                              : '',
                            'overflow-x': position.overflow ? 'auto' : 'visible'
                          }"
                        >
                          <div class="flex_start_direction">
                            {{ rds["Reason"] }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="display_flex_height20">
                      <div
                        class="showdown"
                        [ngStyle]="{ right: position.pointer }"
                      ></div>
                    </div>
                    <div class="message_container">
                      <div>
                        <div
                          class="message_grid"
                          [ngStyle]="{
                            'max-width': position.overflow
                              ? position.overflowwidth
                              : '',
                            'overflow-x': position.overflow ? 'auto' : 'visible'
                          }"
                        >
                          <div class="flex_start_direction">
                            {{ rds["Reason"] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="loading && !scanDetails">
            <tr>
              <td class="center" [attr.colspan]="'5'">
                <img
                  src="../assets/img/loading_2.svg"
                  alt="loading scans"
                  class="width-18"
                />
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!loading && !scanDetails.status">
            <tr>
              <td class="center" [attr.colspan]="'5'">
                <div class="flex-center">
                  No data found.
                  <span class="nav-link" *ngIf="createScan()">click here</span>
                  to scan
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container
            *ngIf="
              !loading &&
              scanDetails.status &&
              filterText != '' &&
              filteredTable().length == 0
            "
          >
            <tr>
              <td class="center" [attr.colspan]="'5'">
                <div class="flex-center">
                  No search results with search key<strong>{{
                    filterText
                  }}</strong>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container
            *ngIf="
              loading && scanDetails.status && scanDetails.status != 'Completed'
            "
          >
            <tr>
              <td class="center" [attr.colspan]="'5'">
                <div class="flex-center">
                  <div>
                    <img
                      src="../assets/img/loading_2.svg"
                      alt="loading scans"
                      class="width-18"
                    />
                  </div>
                  <div>
                    {{ scanDetails["status"] }}
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select
            class="form-select"
            [(ngModel)]="pagination['perPage']"
            (change)="pagination['page'] = 1"
          >
            <option value="{{ page }}" *ngFor="let page of pages">
              {{ page }}
            </option>
          </select>
        </div>
        <pagination-controls
          class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="pagination['page'] = $event"
        >
        </pagination-controls>
      </div>
    </ng-container>
    <div *ngIf="!loading && scanDetails['status'] == '-'" class="center">
      No Scans Found.
      <span class="nav-link" (click)="createScan()">click here</span>to scan
    </div>
    <div
      *ngIf="
        !loading && scanDetails['status'] != '-' && recommendations.length == 0
      "
      class="center"
    >
      No Data Found.
      <span class="nav-link" (click)="createScan()">click here</span>to scan
    </div>
    <div class="center" *ngIf="loading">
      <img
        src="../assets/img/loading_2.svg"
        alt="loading scans"
        class="width-18"
      />
    </div>
  </div>
</div>
