import { Component, OnInit } from "@angular/core";

@Component({
  selector: "dash-expired",
  templateUrl: "./expired.component.html",
  styleUrls: ["./expired.component.css"],
})
export class ExpiredComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
