import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { APIService } from "../../api/api.service";
import { NotifierService } from "../../_services/notifier.service";
import * as echarts from "echarts";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: 'app-prisma-dash',
  templateUrl: './prisma-dash.component.html',
  styleUrls: ['./prisma-dash.component.css']
})
export class PrismaDashComponent implements OnInit, OnDestroy {
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  currentMessage: any;
  alerts:any=[];
  totalAlerts:any=0;
  alertWithCount:any=[];
  filterAlerts: any=[];
  alerttab=false;
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.load();
  }
  load() {
    this.checkForAlerts();
  }
  async checkForAlerts() {
    let data = {
      action: "get_alerts",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/prisma/central`;
    console.log(apiURL);
    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if(result.status=='1'){
      this.alerts = result['alerts'];
      this.totalAlerts = result['total'];

      console.log(this.alerts);
      console.log(this.totalAlerts);

      const policyTypeCounts: { [key: string]: number } = {};

      this.alerts.forEach((item:any) => {
         const policyType = item.policy.policyType;
         if (policyTypeCounts[policyType]) {
           policyTypeCounts[policyType]++; 
          } else { policyTypeCounts[policyType] = 1; 
          }
         }); 
          
          this.alertWithCount = Object.keys(policyTypeCounts).map((type) => ({ type, count: policyTypeCounts[type], }));


console.log(this.alertWithCount);

      this.loadAlertGraph();
    }



    


  }

  loadAlertGraph() {

    var chartDom = document.getElementById("alertGraph");
    var myChart = echarts.init(chartDom);

    let option = {
      color: [
        "#7eb0d5",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
      ],
      tooltip: {
        trigger: 'item',
       
      },

      series: [
        {
          name: "Total Alert",
          type: "pie",
          radius: ["59%", "65%"],
          // right: "20",
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: "center",
            fontSize: 16, 
            fontWeight: 'bold' ,
            formatter: '{b}\n\n{c}' 
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            {value:this.totalAlerts,name:'Total Alerts'}
          ],
        },
      ],
    };
    option && myChart.setOption(option);
  }

  filterByPolicyType(policyType: any){ 
   this.filterAlerts = this.alerts.filter((item:any) => item.policy && item.policy.policyType === policyType).map((item :any)=> 
    ({ id: item.id, 
      status: item.status, 
      alertTime: item.alertTime, 
      idd: item.resource.id, 
      name: item.resource.name, 
      account: item.resource.account, 
      accountId: item.resource.accountId 
    }));
   this.alerttab=true;
  this.filterAlerts.forEach((item:any) => {
     const currentTime = moment(); 
     const alertTime = moment(item.alertTime); 
     const diffInDays = currentTime.diff(alertTime, 'days'); 
     item.alertTimeFormatted = `${diffInDays} days ago`;
     }); 
   console.log(this.filterAlerts);
   } 

   hidealertTab(event:any){
    this.alerttab=null;
    this.load();
   }
 
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
