import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rbi-compliance',
  templateUrl: './rbi-compliance.component.html',
  styleUrls: ['./rbi-compliance.component.css']
})
export class RBICompliance implements OnInit {
  jobs: any = [];
  createJob: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  accountId: string;
  regionId: string;
  loading: any = {
    jobs: false,
  };
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;
  selectedJob: any;
  selectedJobID: any;
  jobsHeaders: any = [
    {
      id: "name",
      name: "Name",
      link: true,
    },
    {
      id: "cr_time",
      name: "Creation Time",
    },
    {
      id: "total_checks",
      name: "Total Checks",
      center: true
    },
    {
      id: "status",
      name: "Status",
    },
    {
      id: "cm_time",
      name: "Completion Time",
    },
  ];
  currentMessage: any;

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length == 0) {
        this.selectedJob = null;
        this.selectedJobID = null;
      }
      if (params["job_id"] && params["job_id"] != "") {
        this.selectedJobID = params["job_id"];
      }
    });

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  load(event: any = true) {
    this.selectedJob = null;
    if (event) {
      this.loadJobs();
    }
  }

  destroyed: boolean = false;
  async loadJobs(job_id?: string, show: boolean = true) {
    if (show) {
      this.loading["jobs"] = true;
      this.page = 1;
    }

    let data: any = {
      action: "list_jobs",
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/compliance/rbicompliance`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if(this.destroyed) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      result.jobs = result.jobs.sort((a: any, b: any) => {
        let date_a: any = new Date(a["cr_time"]);
        let date_b: any = new Date(b["cr_time"]);
        return Date.parse(date_b) - Date.parse(date_a);
      });
      this.jobs = result.jobs.map((job: any) => {
        if (job["status"] == "Completed") {
          job["perc"] = ((job["passed"] / job["total_checks"]) * 100).toFixed(
            2
          );
        } else {
          job["perc"] = "0.0";
        }
        return job;
      });
      let temp: any;
      let check: boolean = false;
      this.jobs.forEach((job: any) => {
        job["cr_time"] = moment(job["cr_time"]).format("MMM DD, YYYY hh:mm A");
        job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
        if (job["status"] == "In progress") {
          check = true;
        }
        if (this.selectedJobID && job.job_id == this.selectedJobID) {
          temp = job;
        }
        if (!job["name"]) {
          job["name"] = job["job_id"];
        }
      });

      if (check) {
        setTimeout(() => {
          this.loadJobs(null, false);
        }, 10000);
      }

      if (show) {
        if (temp) {
          this.selectedJob = temp;
        } else {
          this.navigator.navigate(["/dash/rbi-compliance"]);
        }
      }
    } else {
      this.jobs = [];
    }

    this.loading["jobs"] = false;
  }

  deleteJob: string = null;
  async deleteJobCaller(event: any, job: any) {
    event.stopPropagation();
    this.deleteJob = job;
  }

  closeDeletePopup(event: any) {
    if(event) {
      this.load();
    }

    this.deleteJob = null;
  }

  selectJob(job: any) {
    this.selectedJob = job;
    this.selectedJobID = job["job_id"];
    this.navigator.navigate(["/dash/rbi-compliance"], {
      queryParams: { job_id: this.selectedJobID },
    });
    return true;
  }

  hideModel(event: string) {
    if (event) {
      this.loadJobs();
    }
    this.createJob = false;
  }

  ngOnDestroy(): void {
    this.destroyed= true;
    this.currentMessage.unsubscribe();
  }
}
