<div class="card">
  <div class="card-header">
    <div class="flex-between">
      <div class="flex-start">
        <button class="btn btn-light" (click)="hide(false)">< Back</button>
        <h5>RBI Compliance - {{ job.name }}</h5>
      </div>
      <div
        class="quiz_actions"
        *ngIf="
          this.selectedRequirement &&
          this.selectedRequirementObj &&
          this.currentQuestion
        "
      >
        <div class="nav-link flex-start" (click)="showFilesPopup()">
          <i class="fa fa-paperclip"></i>
          <div>{{ getFiles().length }} files</div>
        </div>
        <ng-container *ngIf="!loading">
          <button
            class="btn btn-light"
            (click)="
              changeQuestion(selectedRequirementObj['current_index'] - 1)
            "
            *ngIf="selectedRequirementObj['current_index'] != 0"
          >
            Prev
          </button>
          <button
            class="btn btn-light"
            (click)="
              changeQuestion(selectedRequirementObj['current_index'] + 1)
            "
            *ngIf="
              selectedRequirementObj['current_index'] + 1 <
              this.filterQuestions().length
            "
          >
            Next
          </button>
          <button class="btn btn-primary-soft" (click)="saveQuestions()">
            Save
          </button>
        </ng-container>
        <ng-container *ngIf="loading">
          <img
            src="../assets/img/loading_2.svg"
            alt="loading scans"
            class="wid-14"
          />
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="quiz-app">
      <ng-container *ngIf="showSidebar">
        <div class="pillers_container position-relative">
          <div class="padding-center">BFSI RBI Requirement</div>
          <ng-container>
            <div
              class="piller flex-between"
              (click)="changeRequirement(check)"
              [ngClass]="{
                selected: selectedRequirement == check['check_root']
              }"
              *ngFor="let check of checksAcc"
            >
              <span>{{ check["check_root"] }}</span>
              <span class="color-radius-20"
                >{{ check["answered"]() }}/{{ check["count"] }}</span
              >
            </div>
          </ng-container>

          <div class="hideSidebar" (click)="showSidebar = false">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showSidebar">
        <div class="hidden_sidebar" (click)="showSidebar = true">
          <i class="fa fa-bars mar-top-10" aria-hidden="true"></i>
        </div>
      </ng-container>
      <div class="questions_container">
        <ng-container *ngIf="checks.length > 0 && filterQuestions().length > 0">
          <div
            class="question"
            *ngIf="
              this.selectedRequirement &&
              this.selectedRequirementObj &&
              this.currentQuestion
            "
          >
            <div class="question_title flex-start flex-top">
              <span>
                {{ currentQuestion["check_id"] }}.
                {{ currentQuestion["title"] }}
              </span>
            </div>
            <div
              class="reject_margin reject_margin_top ml-24"
              *ngIf="currentQuestion.recomendations"
            >
              <div class="bold">Recommendations</div>
              <div>
                <div *ngFor="let recom of currentQuestion.recomendations">
                  <div class="flex-start">
                    <span
                      [ngClass]="{
                        green_fancy: recom.status == 'true',
                        red_fancy: recom.status == 'false'
                      }"
                    >
                      <i
                        class="fa fa-check-circle"
                        *ngIf="recom.status == 'true'"
                      ></i>
                      <i
                        class="fa fa-times-circle"
                        *ngIf="recom.status == 'false'"
                      ></i>
                    </span>
                    <span class="bold">{{ recom["serice"] }}</span>
                    <span>
                      {{ recom["message"] }}
                      <span
                        class="nav-link"
                        *ngIf="
                          recom['custom_metadata'] &&
                          recom['custom_metadata'].length > 0 &&
                          !recom['show_details']
                        "
                        (click)="recom['show_details'] = true"
                        >View Details</span
                      >
                      <span
                        class="nav-link"
                        *ngIf="
                          (currentQuestion['check_id'] == 'NS-005' ||
                            currentQuestion['check_id'] == 'NS-006') &&
                          !recom['show_details'] &&
                          recom['details'] &&
                          recom['details'].length > 0
                        "
                        (click)="recom['show_details'] = true"
                        >View Details</span
                      >
                    </span>
                  </div>
                  <div
                    *ngIf="
                      currentQuestion['check_id'] == 'NS-005' &&
                      recom['show_details']
                    "
                  >
                    <div
                      class="reject_margin reject_margin_top meta_data_details"
                    >
                      <table border="1">
                        <thead>
                          <tr>
                            <th>VPC ID</th>
                            <th>Subnets</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let vpc of recom['details']">
                            <tr
                              *ngFor="
                                let subnet of vpc['subnets'];
                                let k = index
                              "
                            >
                              <td
                                [attr.rowspan]="vpc['subnets'].length"
                                *ngIf="k == 0"
                              >
                                {{ vpc["vpc_id"] }}
                              </td>
                              <td>{{ subnet }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <span
                      class="nav-link"
                      (click)="recom['show_details'] = false"
                      >Hide Details</span
                    >
                  </div>
                  <div
                    *ngIf="
                      currentQuestion['check_id'] == 'NS-006' &&
                      recom['show_details']
                    "
                  >
                    <div
                      class="reject_margin reject_margin_top meta_data_details"
                      *ngIf="recom['details'][0]['open_ports'].length > 0"
                    >
                      <div class="bold">Open Ports</div>
                      <table border="1">
                        <thead>
                          <tr>
                            <th>Sl. No.</th>
                            <th>Security Group</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let sg of recom['details'][0]['open_ports'];
                              let i = index
                            "
                          >
                            <td>{{ i + 1 }}</td>
                            <td>{{ sg["security_group_id"] }}</td>
                            <td>
                              <span
                                [ngClass]="{
                                  green_fancy: sg.status == 'true',
                                  red_fancy: sg.status == 'false'
                                }"
                              >
                                <i
                                  class="fa fa-check-circle"
                                  *ngIf="sg.status == 'true'"
                                ></i>
                                <i
                                  class="fa fa-times-circle"
                                  *ngIf="sg.status == 'false'"
                                ></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="reject_margin reject_margin_top meta_data_details"
                      *ngIf="recom['details'][0]['outbound_ses'].length > 0"
                    >
                      <div class="bold">Outbound SES</div>
                      <table border="1">
                        <thead>
                          <tr>
                            <th>Sl. No.</th>
                            <th>Security Group</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let sg of recom['details'][0]['outbound_ses'];
                              let i = index
                            "
                          >
                            <td>{{ i + 1 }}</td>
                            <td>{{ sg["security_group_id"] }}</td>
                            <td>
                              <span
                                [ngClass]="{
                                  green_fancy: sg.status == 'true',
                                  red_fancy: sg.status == 'false'
                                }"
                              >
                                <i
                                  class="fa fa-check-circle"
                                  *ngIf="sg.status == 'true'"
                                ></i>
                                <i
                                  class="fa fa-times-circle"
                                  *ngIf="sg.status == 'false'"
                                ></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span
                      class="nav-link"
                      (click)="recom['show_details'] = false"
                      >Hide Details</span
                    >
                  </div>
                  <div
                    *ngIf="
                      recom['show_details'] &&
                      recom['custom_metadata'] &&
                      recom['custom_metadata'].length > 0
                    "
                  >
                    <div
                      class="reject_margin reject_margin_top meta_data_details"
                      *ngIf="
                        recom['custom_metadata'] &&
                        recom['custom_metadata'].length > 0
                      "
                    >
                      <div
                        *ngFor="let table of recom['custom_metadata']"
                        class="mb-2"
                      >
                        <div class="bold">
                          {{ table["tableName"] || "Metadata" }}
                        </div>
                        <div class="table-container">
                          <table border="1">
                            <thead>
                              <tr>
                                <th>Sl. No.</th>
                                <th *ngFor="let h of table['headers']">
                                  {{ h.name }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let i of table['data']; let j = index"
                              >
                                <td>{{ j + 1 }}</td>
                                <td *ngFor="let h of table['headers']">
                                  {{ i[h.id] }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <span
                      class="nav-link"
                      (click)="recom['show_details'] = false"
                      >Hide Details</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                currentQuestion['options'].length == 0
              "
            >
              <div class="reject_margin reject_margin_top">
                <div class="flex-start">
                  <button
                    class="btn btn-primary-soft flex-start"
                    (click)="currentQuestion['applicant_response'] = choice"
                    *ngFor="let choice of currentQuestion.choices"
                  >
                    <i
                      class="fa fa-check-circle"
                      *ngIf="currentQuestion['applicant_response'] == choice"
                    ></i>
                    {{ choice }}
                  </button>
                </div>
                <span
                  class="error-message"
                  *ngIf="
                    currentQuestion['errors'] &&
                    currentQuestion['errors']['applicant_response']
                  "
                  ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  {{ currentQuestion["errors"]["applicant_response"] }}
                </span>
              </div>
              <div class="reject_margin reject_margin_top">
                <div class="bold">
                  Comment
                  <span
                    class="error-message"
                    *ngIf="
                      currentQuestion['errors'] &&
                      currentQuestion['errors']['comment']
                    "
                    ><i
                      class="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>
                    {{ currentQuestion["errors"]["comment"] }}
                  </span>
                </div>
                <div>
                  <textarea
                    [attr.placeholder]="currentQuestion['hint']"
                    class="form-control"
                    [(ngModel)]="currentQuestion['comment']"
                    cols="30"
                    (click)="preventDefault($event)"
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              class="options_container"
              *ngIf="
                currentQuestion['options'] &&
                currentQuestion['options'].length > 0
              "
            >
              <ol type="a">
                <li
                  *ngFor="
                    let option of currentQuestion['options'];
                    let j = index
                  "
                >
                  <div class="option">
                    <div class="main-container">
                      <div class="option_text">
                        {{ option.question }}
                      </div>
                      <div class="reject_margin reject_margin_top">
                        <div
                          class="flex-start"
                          *ngIf="currentQuestion.choices.length > 0"
                        >
                          <button
                            class="btn btn-primary-soft flex-start"
                            (click)="option['applicant_response'] = choice"
                            *ngFor="let choice of currentQuestion.choices"
                          >
                            <i
                              class="fa fa-check-circle"
                              *ngIf="option['applicant_response'] == choice"
                            ></i>
                            {{ choice }}
                          </button>
                        </div>
                        <span
                          class="error-message"
                          *ngIf="
                            option['errors'] &&
                            option['errors']['applicant_response']
                          "
                          ><i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {{ option["errors"]["applicant_response"] }}
                        </span>
                      </div>
                      <div class="reject_margin reject_margin_top">
                        <div class="bold">
                          Comment
                          <span
                            class="error-message"
                            *ngIf="
                              option['errors'] && option['errors']['comment']
                            "
                            ><i
                              class="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            {{ option["errors"]["comment"] }}
                          </span>
                        </div>
                        <div>
                          <textarea
                            [attr.placeholder]="option['hint']"
                            class="form-control"
                            [(ngModel)]="option['comment']"
                            cols="30"
                            (click)="preventDefault($event)"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div class="center" *ngIf="!this.selectedRequirement">
            No checks found in
            <span class="bold">{{ this.selectedRequirement }}</span> with filter
            <span class="bold">{{ filters["questions"] }}</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="checks.length == 0 && filterQuestions().length == 0"
        >
          <div class="center">
            No checks found with filter
            <span class="bold">{{ filters["questions"] }}</span>
          </div>
        </ng-container>
      </div>
      <div
        class="question_switcher"
        *ngIf="
          this.selectedRequirement &&
          this.selectedRequirementObj &&
          this.currentQuestion
        "
      >
        <div class="question_slider">
          <div class="mar-left">QUESTIONS</div>
          <div
            *ngFor="let question of filterQuestions(); let i = index"
            class="question_number"
            (click)="changeQuestion(i)"
          >
            <div
              class="question_no font-bold"
              [attr.data-sevirity]="question['Risk']"
              [ngClass]="{
                absolute_floating: question['Risk'] != 'UNANSWERED',
                red_fancy: question['Risk'] == 'HIGH',
                yellow_fancy: question['Risk'] == 'LOW',
                orange_fancy: question['Risk'] == 'MEDIUM'
              }"
            >
              <span
                [ngClass]="{
                  current_question:
                    i == selectedRequirementObj['current_index'],
                  answerd_question: checkIfAnswered(question),
                  un_answerd_question: !checkIfAnswered(question)
                }"
              >
                {{ i + 1 }}
              </span>
            </div>
            <div class="question_switcher_text_container white-now">
              <span
                class="question_switcher_text"
                title="{{ question.title }}"
                [ngStyle]="{
                  'font-weight':
                    i == selectedRequirementObj['current_index']
                      ? 'bold'
                      : 'normal'
                }"
              >
                {{ question.title }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-files-viewer
  *ngIf="showFilesValid"
  [job]="job"
  [files]="showFiles"
  [question]="currentQuestion"
  [questions]="checksObj"
  [disable]="true"
  (resetFiles)="resetFiles.emit($event)"
  (hideModel)="showFilesValid = false"
></app-files-viewer>
