<div class="screen">
  <div class="protocol-container">
    <div class="header">
      Validate IAM Role
    </div>
    <div class="body">
      <div class="form-group" *ngIf="error">
        <p class="error-message">{{error}}</p>
      </div>
      <div class="form-group">
        <label>Account Name</label>
        <input autocomplete="off" type="text" class="form-control" placeholder="Enter Account Name"
          [(ngModel)]="accountName">
      </div>
      <div class="form-group">
        <label>Role ARN</label>
        <input autocomplete="off" type="text" class="form-control" placeholder="Enter Role ARN" [(ngModel)]="roleARN">
      </div>
    </div>
    <div class="footer flex-end">
      <button class="btn btn-primary-soft" (click)="close(true)" *ngIf="!loading">
        Validate
      </button>
      <div *ngIf="loading">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
      </div>
      <button class="btn btn-light" (click)="close(false)">
        Cancel
      </button>
    </div>
  </div>
</div>