import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-s3-tab',
  templateUrl: './view-s3-tab.component.html',
  styleUrls: ['./view-s3-tab.component.css']
})
export class ViewS3TabComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() StorageTypes :any=[];
  @Input() regionHeader:any=[];

  constructor(public notifier: NotifierService,) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }

  
  no(){
    this.hideModal.emit({ status: 0 });
  }
  toggleItem(item: any): void { item.isHidden = !item.isHidden; } 

  formatType(type:any):string{
    if(type === 'total_size_of_s3'){
      return 'Simple Storage Service (S3)';
    }else if(type === 'total_efs_size'){
      return 'Elastic File System (EFS)';
    }else if(type === 'total_ebs_size'){
      return 'Elastic Block Storage (EBS)';
    }
    else{
      return '';
    }
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}

}
