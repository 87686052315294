import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as echarts from 'echarts';

import * as moment from 'moment';

declare let flatpickr: any;
declare let $: any;
declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './resolutionsla.component.html',
  styleUrls: ['./resolutionsla.component.css'],
})
export class ResolutionSLA implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  frequency: string = 'today';
  jira_org_name: any;
  jira_project_key: any;
  resolutionsla: any = [];
  createTicket: any = null;
  ticketsNextToken: any;

  accountId: any;
  regionId: any;
  currentMessage: any;
  detailsOf: any;
  funRef: any;

  slaSummaryView: boolean = true;
  headers: any = [
    {
      id: 'd',
      name: 'Date',
    },
    {
      id: 'Met',
      name: 'Met',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'loadTickets',
    },
    {
      id: 'Breached',
      name: 'Breached',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'loadTickets',
    },
  ];
  ticketHeaders: any = [
    {
      id: 'ticket_id',
      name: 'Ticket ID',
    },
    {
      id: 'summary',
      name: 'Request Summary',
    },
    {
      id: 'reporter_name',
      name: 'Reporter',
    },
    {
      id: 'assignee',
      name: 'Assignee',
    },
    {
      id: 'issuetype',
      name: 'Request Type',
    },
    {
      id: 'status',
      name: 'Status',
    },
  ];
  tickets: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  selectedCloudPartner: string | null = localStorage.getItem('cp-selected');
  resourceGroup: string = localStorage.getItem("selectedResourceGroup");

  async ngOnInit() {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {
      loadTickets: this.loadTickets,
    };
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { jira_org_name, jira_project_key } = selectedAccountData.data;
        this.jira_org_name = jira_org_name;
        this.jira_project_key = jira_project_key;
      } else {
      }
    } catch (err) {}

    this.currentMessage = this.notifier.currentMessage.subscribe(
      async (msg) => {
        setTimeout(async () => {
          try {
            let selectedAccountData: any =
              await this.fetchSelectedAccountDetails();
            if (!selectedAccountData.error) {
              const { jira_org_name, jira_project_key } =
                selectedAccountData.data;
              this.jira_org_name = jira_org_name;
              this.jira_project_key = jira_project_key;
            } else {
            }
          } catch (err) {}
        }, 100);
        let d = JSON.parse(msg);
        if (d.value == null || d.source != "notifier") {
          return;
        }
        this.selectedCloudPartner = localStorage.getItem("cp-selected");
        if (d.key == 'accountId' && this.selectedCloudPartner == 'aws') {
          this.accountId = d.value;
          this.load();
        } else if (d.key == 'regionId') {
          this.regionId = d.value;
          this.load();
        } else if (d.key == "resourceGroup") {
          this.resourceGroup = d.value;
          this.load();
        }
      }
    );

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  async load() {
    this.ticketsNextToken = null;
    this.notifier.loading(true);
    this.slaSummaryView = true;
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { jira_org_name, jira_project_key } = selectedAccountData.data;
        if (!jira_org_name || !jira_project_key) {
          this.notifier.alert(
            'Info',
            '',
            'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
            'info',
            5000
          );
          this.resolutionsla = [];
          this.loadGraph();
          this.notifier.loading(false);
          return;
        }
        let data: any = {
          project_id: jira_project_key,
          no_days: this.frequency,
          report: 'time_to_resolution',
          org_id: jira_org_name,
        };

        let header = {
          Authorization: localStorage.getItem('t'),
        };
        window.analyticsSendEventClick('Sla.ResolutionSla', 'Sla Fetched');
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/issuesprod`;
        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.met || result.breached) {
          this.resolutionsla = [];
          let d = result;

          let datesAppended: any = {};
          for (let i in d.met.issues.datelist) {
            this.resolutionsla.push({
              d: d.met.issues.datelist[i].date,
              Met: d.met.issues.datelist[i].total,
              Breached: 0,
            });
            datesAppended[i] = { index: this.resolutionsla.length - 1 };
          }

          for (let i in d.breached.issues.datelist) {
            if (datesAppended[i]) {
              this.resolutionsla[datesAppended[i].index]['Breached'] =
                d.breached.issues.datelist[i].total;
            } else {
              this.resolutionsla.push({
                d: d.breached.issues.datelist[i].date,
                Met: 0,
                Breached: d.breached.issues.datelist[i].total,
              });
            }
            datesAppended[i] = { index: this.resolutionsla.length - 1 };
          }

          this.loadGraph();
        } else {
          this.notifier.alert(
            'Error',
            '',
            result.error_message || result.message,
            'error',
            5000
          );
          this.resolutionsla = [];
        }
      } else {
        this.resolutionsla = [];
      }
    } catch (err) {
      this.resolutionsla = [];

      console.log(err);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any, tickets_type: any) {
    this.funRef[name](this, param, tickets_type);
  }

  selectedMonitors: any;
  selectedTicketsType: string;

  loadMoreTickets() {
    this.loadTickets(this, this.selectedMonitors, this.selectedTicketsType);
  }

  async loadTickets(ref: any, id: any, tickets_type: String) {
    ref.notifier.loading(true);
    ref.detailsOf = id.d;
    ref.selectedMonitors = id;
    ref.selectedTicketsType = tickets_type;
    ref.slaSummaryView = false;
    if (ref.ticketsNextToken == 0 || !ref.ticketsNextToken) {
      ref.tickets = [];
    }
    let ticket_type = 'ondate_time_to_resolution_met';
    if (tickets_type == 'Breached') {
      ticket_type = 'ondate_time_to_resolution_breached';
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    try {
      let selectedAccountData: any = await ref.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { jira_org_name, jira_project_key } = selectedAccountData.data;
        if (!jira_org_name || !jira_project_key) {
          this.notifier.alert(
            'Info',
            '',
            'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
            'info',
            5000
          );
          return;
        }
        let data: any = {
          project_id: jira_project_key,
          no_days: id.d,
          report: ticket_type,
          org_id: jira_org_name,
        };
        window.analyticsSendEventClick('Sla.ResolutionSla', 'Tickets Fetched');
        const apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/issuesprod`;

        let result = await ref.apiServer.postDataPromis(apiURL, data, header);
        if (result.issues) {
          ref.tickets = [...ref.tickets, ...result.issues];
        } else {
          ref.notifier.alert(
            'Error',
            '',
            result.error_message || result.message,
            'error',
            5000
          );
        }
        if (result.next_token) {
          ref.ticketsNextToken = result.next_token;
        } else {
          ref.ticketsNextToken = null;
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
    ref.notifier.loading(false);
  }

  createTicketTrigger(ticket: any) {
    this.createTicket = ticket;
    if (this.createTicket.summary) {
      this.createTicket['subject'] = this.createTicket.summary;
    }
  }

  hideCreateTicket(event: any) {
    this.createTicket = null;
  }

  goBack() {
    this.tickets = [];
    this.resolutionsla = [];
    this.load();
  }

  loadGraph() {
    var chartDom = document.getElementById('graphdiv');
    var myChart = echarts.init(chartDom);

    let metData = this.resolutionsla.map((a: any) => a.Met);
    let breachedData = this.resolutionsla.map((a: any) => a.Breached);
    let labelData = this.resolutionsla.map((a: any) => {
      if (this.frequency == 'today') {
        return moment(a.d, 'MMM DD, YYYY, hh:mm A').format('hh:mm A');
      }
      return a.d;
    });

    let option: any = {
      color: ['rgb(0, 227, 150)', 'rgb(0, 143, 251)'],
      grid: { containLabel: true },
      xAxis: {
        type: 'category',
        data: labelData,
        axisLabel: { interval: 0, rotate: 30, name: 'Count' },
      },
      legend: {},
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Met',
          data: metData,
          type: 'line',
        },
        {
          name: 'Breached',
          data: breachedData,
          type: 'line',
        },
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: function (
          pos: any,
          params: any,
          el: any,
          elRect: any,
          size: any
        ) {
          var obj: any = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
        extraCssText: 'width: 170px; white-space:pre-wrap;',
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false,
          },
          brush: {
            type: ['lineX', 'clear'],
          },
        },
      },
    };

    option && myChart.setOption(option);
  }
}
