import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.css"],
})
export class PricingComponent implements OnInit {
  planData: any = {
    standard: {
      name: "Standard",
    },
    advanced: {
      name: "Advanced",
    },
  };

  showPricingComp: boolean = false;

  revewed: boolean = false;

  periods: any = [
    {
      period: "6 Months",
      id: "6",
      selected: false,
    },
    {
      period: "9 Months",
      id: "9",
      selected: false,
    },
    {
      period: "12 Months",
      id: "12",
      selected: false,
    },
  ];

  plans: any = [];

  slice(array: any, count: number) {
    return { array: array.slice(0, count), remaining: array.length - count };
  }

  selectPlan(plan: any) {
    plan["selected"] = true;
    this.plans.forEach((plan_: any) => {
      if (plan_["plan"] != plan["plan"]) {
        plan_["selected"] = false;
      }
    });
  }

  selectPeriod(period: any) {
    period["selected"] = true;
    this.periods.forEach((period_: any) => {
      if (period_["period"] != period["period"]) {
        period_["selected"] = false;
      }
    });
  }

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem("eId")) {
      this.router.navigate(["/login"]);
      return;
    }
    this.loadPlans();
  }

  loading: any = {};
  async loadPlans() {
    this.loading["plans"] = true;
    let data = {
      a: "fetchplans",
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/login`;

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.s == "1" || result.status == "1") {
      this.plans = result.data.map((plan: any) => {
        plan["selected"] = false;
        plan["users"] = plan["plan"] == "standard" ? "10" : "15";
        plan["mList"] = plan["mList"].sort();
        return plan;
      });
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    this.loading["plans"] = false;
  }

  async renewPlan() {
    this.loading["renew"] = true;
    let planType = this.plans.find((plan: any) => {
      return plan["selected"];
    });
    let planPeriod = this.periods.find((plan: any) => {
      return plan["selected"];
    });
    if (!planType) {
      this.notifier.alert("Info", "", "Please select a plan", "info", 5000);
      this.loading["renew"] = false;
      return;
    }
    if (!planPeriod) {
      this.notifier.alert(
        "Info",
        "",
        "Please select a plan peiord",
        "info",
        5000
      );
      this.loading["renew"] = false;
      return;
    }
    let data = {
      a: "renewal",
      eId: localStorage.getItem("eId"),
      planType: planType["plan"],
      planPeriod: planPeriod["id"],
      ut: localStorage.getItem("ut"),
      cid: localStorage.getItem("customerId"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/login`;

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.s == "1" || result.status == "1") {
      localStorage.setItem("t", result.at);
      localStorage.setItem("idT", result.id);
      localStorage.setItem("expires", result.user.expires);
      localStorage.setItem("expiresIn", result.user.expiresIn);
      localStorage.setItem("menuList", JSON.stringify(result.user.mList));
      localStorage.setItem("subPeriod", planPeriod["id"]);
      localStorage.setItem("accountStatus", "active");
      localStorage.setItem("subType", planType["plan"]);
      if (result.user.expires) {
        let period = moment(result.user.expires, "YYYY-MM-DD");
        let diffObj: any;
        if (planPeriod["id"]) {
          let sub = moment(result.user.expires, "YYYY-MM-DD").subtract(
            Number(planPeriod["id"]),
            "months"
          );
          diffObj = period.diff(sub, "days");
        } else {
          diffObj = 15;
        }
        localStorage.setItem("totalPeriod", diffObj);
      }
      window.location.href = "/dash/home";
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    this.loading["renew"] = false;
  }
}
