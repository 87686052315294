<div class="alarm_screen alarmstyle">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 class="entrytexttransform">{{action == 'local' ? 'Add' : action}} Entry</h4>
    </div>
    <div class="alarm_body alarmbodypadding">
      <div class="row">
        <label>OS</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="bom.os">
      </div>
      <div class="row">
        <label>Instance Types</label>
        <select class="form-control" id="instanceTypes" [(ngModel)]="bom.instanceType" data-live-search="true">
          <option *ngFor="let type of instanceTypes" value="{{type}}">{{type}}</option>
        </select>
      </div>
      <div class="row">
        <label>CPU</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="bom.cpu">
      </div>
      <div class="row">
        <label>Disk</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="bom.disk">
      </div>
      <div class="row">
        <label>Quantity</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="bom.quantity">
      </div>
      <div class="row">
        <label>Billing Plan</label>
        <select class="form-control" id="billingTypes" [(ngModel)]="bom.billingType" data-live-search="true">
          <option *ngFor="let type of billingTypes" value="{{type}}">{{type}}</option>
        </select>
      </div>
      <div class="row">
        <label>RAM</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="bom.ram">
      </div>
      <div class="row">
        <label>Usage in Hours</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="bom.usageHours">
      </div>
      <div class="alarm_actions">
        <div class="action-local-add">
          <button class="btn actionbtnn" (click)="updateEntry()">
            {{action == 'local' ? 'Add' : action}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>