<div class="info-space">
  <div class="info-container">
    <div class="strong-text large-text how-to-relative">How to?
      <div class="absoute-close" (click)="hideModel.emit(false)">
        <i class="fa fa-times" aria-hidden="true"></i>

      </div>
    </div>
    <div class="main-container">
      <div class="steps-container" [ngStyle]="{'margin-left': getMarginLeft() }">
        <div class="step-container" *ngIf="checkForStep(0)">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/marketplace.steps/step1.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Create stack</div>
            <div>
              Once we Click on <span class="bold-text">Click here to navigate to console</span>,<br> It will redirect to
              your AWS Account
              and Cloud Formation template
              page will appear as above.<br> All the fields are auto filled, we need to acknowledge by clicking on
              below 3
              check boxes and
              click on create stack button.
            </div>
          </div>
        </div>
        <div class="step-container" *ngIf="checkForStep(1)">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/marketplace.steps/step2.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Stack Creation in Progress</div>
            <div>
              Once we click on Create Stack, Stack will be created and we can see the status as above: <span
                class="bold-text">
                CREATE_IN_PROGRESS
              </span>
            </div>
          </div>
        </div>
        <div class="step-container" *ngIf="checkForStep(2)">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/marketplace.steps/step3.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Stack Created Successfully</div>
            <div>
              We should wait till the status will turn to <span class="bold-text">CREATE_COMPLETE</span> as shown in the
              above screenshot.
            </div>
          </div>
        </div>
        <div class="step-container" *ngIf="checkForStep(3)">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/marketplace.steps/step4.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Get AWS Account ID</div>
            <div>
              Copy the Account ID towards the right corner as shown in the above screenshot and paste in the AWS Account
              ID field.
            </div>
          </div>
        </div>
      </div>
      <div class="steps-indicator" *ngIf="steps.length > 1">
        <div class="step" *ngIf="checkForStep(0)" [ngClass]="{active_step: step == 0}" (click)="setStep(0)"></div>
        <div class="step" *ngIf="checkForStep(1)" [ngClass]="{active_step: step == 1}" (click)="setStep(1)"></div>
        <div class="step" *ngIf="checkForStep(2)" [ngClass]="{active_step: step == 2}" (click)="setStep(2)"></div>
        <div class="step" *ngIf="checkForStep(3)" [ngClass]="{active_step: step == 3}" (click)="setStep(3)"></div>
      </div>
      <div class="actions">
        <div>
          <button class="btn btn-light" (click)="removeStep()" *ngIf="step > 0 && checkForStep(step - 1)">
            Prev
          </button>
        </div>
        <div>
          <button class="btn btn-light" (click)="appendStep()" *ngIf="step < 3 && checkForStep(step + 1)">
            Next
          </button>
          <button class="btn btn-light" (click)="hideModel.emit(false)" *ngIf="step >= 3 || !checkForStep(step + 1)">
            Finish
          </button>
        </div>
      </div>
    </div>
  </div>
</div>