<div class="card-header flex-between">
  <div class="flex-start">
    <button class="btn btn-light flex-start" (click)="hideModal.emit(false)">
      <i class="fa fa-angle-left"></i>
      <span>Back</span>
    </button>
    <h5 class="p-0 m-0">History</h5>
  </div>
  <div class="flex-start">
    <label class="switch">
      <input
        autocomplete="off"
        type="checkbox"
        [(ngModel)]="groupByRules"
        (change)="setPage()"
      />
      <span class="slider round"></span>
    </label>
    <div class="no-wrap">Group By Rules</div>
    <button class="btn btn-success-soft" *ngIf="!hideExport" (click)="export()">
      Export
    </button>
  </div>
</div>
<div class="card-body">
  <div class="history_body">
    <!-- <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link capitalize" [ngClass]="{active: i == 0}" id="{{ type }}-check-tab" data-bs-toggle="tab"
          *ngFor="let type of types; let i = index" [attr.data-bs-target]="'#check' + type " type="button" role="tab"
          [attr.aria-controls]="'check' + type" aria-selected="true" (click)="load(type)">
          {{ type }}
        </button>
      </div>
    </nav>
    <div class="tab-content flex-1" id="nav-tabContent">
      <div *ngFor="let type of types; let i = index" class="tab-pane fade show" [ngClass]="{active: i == 0}"
        id="check{{ type }}" role="tabpanel" [attr.aria-labelledby]="type + '-check-tab'">
        <div class="card-dply" *ngIf="type == currentPage">
          <div class="report-types-flex mb-10 card-postn">
            <table class="table table-docs">
              <thead>
                <tr *ngIf="!groupByRules">
                  <th>Sl. No.</th>
                  <th>Description</th>
                  <th>Identifier</th>
                  <th>Event Time</th>
                  <th>Triggered By</th>
                  <th *ngIf="type == 'security'">Protocol</th>
                  <th *ngIf="type == 'security'">Source IP</th>
                  <th *ngIf="type == 'security'">From IP</th>
                  <th *ngIf="type == 'security'">To IP</th>
                </tr>
                <tr *ngIf="groupByRules">
                  <th>Sl. No.</th>
                  <th>Description</th>
                  <th>Occurrences</th>
                </tr>
              </thead>
              <ng-container *ngIf="!loading[type]">
                <tbody *ngIf="!groupByRules">
                  <tr *ngFor="let event of scanList[type] | paginate
                            : {
                                itemsPerPage: pagination[type]['perPage'],
                                currentPage: pagination[type]['page'],
                                totalItems: scanList[type].length
                              }; let i = index">
                    <td>
                      {{ ((pagination[type]['page'] - 1) * pagination[type]['perPage']) + (i + 1) }}
                    </td>
                    <td>
                      {{ event['description'] }}
                    </td>
                    <td>
                      {{ event['identifier'] }}
                    </td>
                    <td>
                      {{ event['eventTime'] }}
                    </td>
                    <td>
                      {{ event['createdBy'] || '-' }}
                    </td>
                    <td *ngIf="type == 'security'">{{ event['protocal'] || '-' }}</td>
                    <td *ngIf="type == 'security'">{{ event['sourceIp'] || '-' }}</td>
                    <td *ngIf="type == 'security'">{{ event['fromPort'] || '-' }}</td>
                    <td *ngIf="type == 'security'">{{ event['toPort'] || '-' }}</td>
                  </tr>

                </tbody>
                <tbody *ngIf="groupByRules">
                  <ng-container *ngFor="let event of groupScanList[type] | paginate
                              : {
                                  itemsPerPage: pagination[type]['perPage'],
                                  currentPage: pagination[type]['page'],
                                  totalItems: groupScanList[type].length
                                }; let i = index">
                    <tr class="hover-td" [ngClass]="{'active-tr': event['show']}">
                      <td>
                        {{ ((pagination[type]['page'] - 1) * pagination[type]['perPage']) + (i + 1) }}
                      </td>
                      <td>
                        <span class="nav-link" (click)="event['show'] = !event['show']">
                          {{ event['description'] || '-' }}
                        </span>
                      </td>
                      <td>
                        {{ event['count'] }}
                      </td>
                    </tr>
                    <tr *ngIf="event['show']">
                      <td colspan="3">
                        <table class="table table-docs table-hover">
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>Identifier</th>
                              <th>Event Time</th>
                              <th>Triggered By</th>
                              <th *ngIf="type == 'security'">Protocol</th>
                              <th *ngIf="type == 'security'">Source IP</th>
                              <th *ngIf="type == 'security'">From IP</th>
                              <th *ngIf="type == 'security'">To IP</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of getDescriptionsForType(type, event['description']); let j = index">
                              <td>
                                {{ j + 1 }}
                              </td>
                              <td>
                                {{ item['identifier'] }}
                              </td>
                              <td>
                                {{ item['eventTime'] }}
                              </td>
                              <td>
                                {{ item['createdBy'] || '-' }}
                              </td>
                              <td *ngIf="type == 'security'">{{ item['protocal'] || '-' }}</td>
                              <td *ngIf="type == 'security'">{{ item['sourceIp'] || '-' }}</td>
                              <td *ngIf="type == 'security'">{{ item['fromPort'] || '-' }}</td>
                              <td *ngIf="type == 'security'">{{ item['toPort'] || '-' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>

                </tbody>
              </ng-container>
              <tbody *ngIf="loading[type]">
                <tr>
                  <td [attr.colspan]="groupByRules ? 3 : type == 'security' ? 9: 5" class="txt-cntr center">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!loading[type] && scanList[type].length == 0">
                <tr>
                  <td [attr.colspan]="groupByRules ? 3 : type == 'security' ? 9: 5" class="txt-cntr center">
                    <span>No Events Found</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="flex-between">
              <div class="flex-start">
                <span>Per Page</span>
                <select class="form-select width-auto" [(ngModel)]="pagination[type]['perPage']"
                  (change)="pagination[type]['page'] = 1">
                  <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
                </select>
              </div>
              <div class="paginator" *ngIf="currentPage != null && scanList[type].length > 0">
                <div *ngIf="currentPage === 'security' && type === 'security'">
                  <pagination-controls class="pagination security" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="pagination['security']['page'] = $event">
                  </pagination-controls>
                </div>
                <div *ngIf="currentPage === 'governance' && type === 'governance'">
                  <pagination-controls class="pagination governance" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="pagination['governance']['page'] = $event">
                  </pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link capitalize"
          [ngClass]="{ active: i == 0 }"
          id="{{ type }}-tab"
          data-bs-toggle="tab"
          *ngFor="let type of getObjectKeys(types); let i = index"
          [attr.data-bs-target]="'#' + type"
          type="button"
          role="tab"
          [attr.aria-controls]="type"
          aria-selected="true"
          (click)="currentPage = getObjectKeys(types[type]['tabs'])[0]"
        >
          {{ types[type]["name"] }}
        </button>
      </div>
    </nav>
    <div class="align-top">
      <div class="tab-content flex-1" id="nav-tabContent">
        <div
          *ngFor="let type of getObjectKeys(types); let i = index"
          class="tab-pane fade show"
          [ngClass]="{ active: i == 0 }"
          id="{{ type }}"
          role="tabpanel"
          [attr.aria-labelledby]="type + '-tab'"
        >
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                class="nav-link capitalize"
                [ngClass]="{ active: currentPage == tab }"
                id="{{ tab }}-tab"
                data-bs-toggle="tab"
                *ngFor="
                  let tab of getObjectKeys(types[type]['tabs']);
                  let i = index
                "
                [attr.data-bs-target]="'#' + tab"
                type="button"
                role="tab"
                [attr.aria-controls]="tab"
                aria-selected="true"
                (click)="currentPage = tab"
              >
                {{ types[type]["tabs"][tab]["name"] }}
              </button>
            </div>
          </nav>
          <div class="align-top">
            <div class="tab-content flex-1" id="nav-tabContent">
              <div
                *ngFor="
                  let tab of getObjectKeys(types[type]['tabs']);
                  let i = index
                "
                class="tab-pane fade show"
                [ngClass]="{ active: currentPage == tab }"
                id="{{ tab }}"
                role="tabpanel"
                [attr.aria-labelledby]="tab + '-tab'"
              >
                <div class="card border-none">
                  <!--<h5 class="card-header"></h5>-->
                  <div class="card-header">
                    {{ names[tab] }}
                  </div>

                  <div class="card-body">
                    <div class="card-dply">
                      <div class="report-types-flex mb-10 card-postn">
                        <table class="table table-docs">
                          <thead>
                            <tr *ngIf="!groupByRules">
                              <th>Sl. No.</th>
                              <th>Description</th>
                              <th>Identifier</th>
                              <th>Event Time</th>
                              <th *ngIf="type != 'every-day'">Triggered By</th>
                              <th *ngIf="tab == 'security'">Protocol</th>
                              <th *ngIf="tab == 'security'">Source IP</th>
                              <th *ngIf="tab == 'security'">From IP</th>
                              <th *ngIf="tab == 'security'">To IP</th>
                              <th>Links</th>
                            </tr>
                            <tr *ngIf="groupByRules">
                              <th>Sl. No.</th>
                              <th>Description</th>
                              <th>Occurrences</th>
                            </tr>
                          </thead>
                          <ng-container *ngIf="!loading[tab]">
                            <tbody *ngIf="!groupByRules">
                              <tr
                                *ngFor="
                                  let event of scanList[tab]
                                    | paginate
                                      : {
                                          itemsPerPage:
                                            pagination[tab]['perPage'],
                                          currentPage: pagination[tab]['page'],
                                          totalItems: scanList[tab].length
                                        };
                                  let i = index
                                "
                              >
                                <td>
                                  {{
                                    (pagination[tab]["page"] - 1) *
                                      pagination[tab]["perPage"] +
                                      (i + 1)
                                  }}
                                </td>
                                <td>
                                  {{ event["description"] }}
                                </td>
                                <td>
                                  {{ event["identifier"] }}
                                </td>
                                <td>
                                  {{ event["eventTime"] }}
                                </td>
                                <td *ngIf="type != 'every-day'">
                                  {{ event["createdBy"] || "-" }}
                                </td>
                                <td *ngIf="tab == 'security'">
                                  {{ event["protocal"] || "-" }}
                                </td>
                                <td *ngIf="tab == 'security'">
                                  {{ event["sourceIp"] || "-" }}
                                </td>
                                <td *ngIf="tab == 'security'">
                                  {{ event["fromPort"] || "-" }}
                                </td>
                                <td *ngIf="tab == 'security'">
                                  {{ event["toPort"] || "-" }}
                                </td>
                                <td>
                                  <div
                                    class="flex-start"
                                    *ngIf="event['links'].length > 0"
                                  >
                                    <a
                                      [routerLink]="link['link']"
                                      [queryParams]="link['params']"
                                      target="{{link['target']}}"
                                      *ngFor="let link of event['links']"
                                      >{{ link["name"] }}</a
                                    >
                                  </div>
                                  <div *ngIf="event['links'].length == 0">
                                    -
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="groupByRules">
                              <ng-container
                                *ngFor="
                                  let event of groupScanList[tab]
                                    | paginate
                                      : {
                                          itemsPerPage:
                                            pagination[tab]['perPage'],
                                          currentPage: pagination[tab]['page'],
                                          totalItems: groupScanList[tab].length
                                        };
                                  let i = index
                                "
                              >
                                <tr
                                  class="hover-td"
                                  [ngClass]="{ 'active-tr': event['show'] }"
                                >
                                  <td>
                                    {{
                                      (pagination[tab]["page"] - 1) *
                                        pagination[tab]["perPage"] +
                                        (i + 1)
                                    }}
                                  </td>
                                  <td>
                                    <span
                                      class="nav-link"
                                      (click)="event['show'] = !event['show']"
                                    >
                                      {{ event["description"] || "-" }}
                                    </span>
                                  </td>
                                  <td>
                                    {{ event["count"] }}
                                  </td>
                                </tr>
                                <tr *ngIf="event['show']">
                                  <td colspan="3">
                                    <table class="table table-docs table-hover">
                                      <thead>
                                        <tr>
                                          <th>Sl. No.</th>
                                          <th>Identifier</th>
                                          <th>Event Time</th>
                                          <th *ngIf="type != 'every-day'">
                                            Triggered By
                                          </th>
                                          <th *ngIf="tab == 'security'">
                                            Protocol
                                          </th>
                                          <th *ngIf="tab == 'security'">
                                            Source IP
                                          </th>
                                          <th *ngIf="tab == 'security'">
                                            From IP
                                          </th>
                                          <th *ngIf="tab == 'security'">
                                            To IP
                                          </th>
                                          <th>Links</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="
                                            let item of getDescriptionsForType(
                                              tab,
                                              event['description']
                                            );
                                            let j = index
                                          "
                                        >
                                          <td>
                                            {{ j + 1 }}
                                          </td>
                                          <td>
                                            {{ item["identifier"] }}
                                          </td>
                                          <td>
                                            {{ item["eventTime"] }}
                                          </td>
                                          <td *ngIf="type != 'every-day'">
                                            {{ item["createdBy"] || "-" }}
                                          </td>
                                          <td *ngIf="tab == 'security'">
                                            {{ item["protocal"] || "-" }}
                                          </td>
                                          <td *ngIf="tab == 'security'">
                                            {{ item["sourceIp"] || "-" }}
                                          </td>
                                          <td *ngIf="tab == 'security'">
                                            {{ item["fromPort"] || "-" }}
                                          </td>
                                          <td *ngIf="tab == 'security'">
                                            {{ item["toPort"] || "-" }}
                                          </td>
                                          <td>
                                            <div
                                              class="flex-start"
                                              *ngIf="item['links'].length > 0"
                                            >
                                              <a
                                                [routerLink]="link['link']"
                                                [queryParams]="link['params']"
                                                target="{{link['target']}}"
                                                *ngFor="
                                                  let link of item['links']
                                                "
                                                >{{ link["name"] }}</a
                                              >
                                            </div>
                                            <div
                                              *ngIf="item['links'].length == 0"
                                            >
                                              -
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </ng-container>
                          <tbody *ngIf="loading[tab]">
                            <tr>
                              <td
                                [attr.colspan]="
                                  groupByRules
                                    ? 4
                                    : (tab == 'security' ? 10 : 6) -
                                      (type == 'every-day' ? 1 : 0)
                                "
                                class="txt-cntr center"
                              >
                                <img
                                  src="../assets/img/loading_2.svg"
                                  alt="loading scans"
                                  class="width-20px"
                                />
                              </td>
                            </tr>
                          </tbody>
                          <tbody
                            *ngIf="!loading[tab] && scanList[tab].length == 0"
                          >
                            <tr>
                              <td
                                [attr.colspan]="
                                  groupByRules
                                    ? 4
                                    : (tab == 'security' ? 10 : 6) -
                                      (type == 'every-day' ? 1 : 0)
                                "
                                class="txt-cntr center"
                              >
                                <span>No Events Found</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="flex-between" *ngIf="currentPage == tab">
                          <div class="flex-start">
                            <span>Per Page</span>
                            <select
                              class="form-select width-auto"
                              [(ngModel)]="pagination[tab]['perPage']"
                              (change)="pagination[tab]['page'] = 1"
                            >
                              <option
                                value="{{ page }}"
                                *ngFor="let page of pages"
                              >
                                {{ page }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="paginator"
                            *ngIf="scanList[tab].length > 0"
                          >
                            <pagination-controls
                              class="pagination"
                              previousLabel="Prev"
                              nextLabel="Next"
                              (pageChange)="pagination[tab]['page'] = $event"
                            >
                            </pagination-controls>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
