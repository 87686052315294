<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Launch Instance</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        Do you want to launch Instance?

      </div>
      <div class="alarm_actions">
        <div class="alarm_actions_12">
          <button class="btn uploadingFile_13" (click)="uploadFile()">
            Launch
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>