import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-policy-view',
  templateUrl: './policy-view.component.html',
  styleUrls: ['./policy-view.component.css'],
})
export class PolicyViewComponent implements OnInit {
  @Input('policyDetails') policyDetails: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  constructor() {}

  ngOnInit(): void {}

  getAccounts() {
    return this.policyDetails['accountDetailsList']
      ? this.policyDetails['accountDetailsList'].map((account: any) => {
          return `${account.accountId} (${account.accountName})`;
        })
      : '-';
  }

  getServices() {
    return this.policyDetails['mlist'] ? Object.keys(this.policyDetails['mlist']).join(', ') : this.policyDetails['menuList'].join(', ');
  }

  getSelectedTagValues(accountId: string, region: string, key: string): String {
    return this.policyDetails['tagsList'][accountId][region][key].length > 0
      ? this.policyDetails['tagsList'][accountId][region][key].join(', ')
      : '-';
  }

  getObjectKeys(object: Object): string[] {
    return object ? Object.keys(object) : [];
  }
}
