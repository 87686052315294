import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "./../../../_services/notifier.service";
import { environment } from "src/environments/environment";
declare var window: any;
@Component({
  templateUrl: "./sg.component.html",
  styleUrls: ["./sg.component.css"],
})
export class sgComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  sgTabs: any = [];
  sgList: any = [];

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;

  jobDetails: any = null;
  scanAccess: boolean = true;
  funRef: any;

  headers: any = [
    {
      id: "sg_name",
      name: "Security Group Name",
      filter: true,
      click: "loadAttachedInstances",
    },
    {
      id: "sg_id",
      name: "Security Group ID",
      filter: true,
    },
    {
      id: "instance_attched",
      name: "Instances(EC2/RDS)",
    },
  ];

  headersNested: any = [
    {
      id: "sg_name",
      name: "Security Group Name",
      filter: true,
      click: "loadNested",
    },
    {
      id: "sg_id",
      name: "Security Group ID",
      filter: true,
    },
    {
      id: "instance_attched",
      name: "Instances(EC2/RDS)",
    },
  ];

  portwiseheader: any = [
    {
      id: "port_range",
      name: "Port Range",
      click: "loadAttachedInstances",
    },
    {
      id: "total_sgs",
      name: "Total Security Groups",
    },
  ];

  attachedInstancesHeader: any = [
    {
      id: "instance_name",
      name: "Instance Name",
    },
    {
      id: "instance_id",
      name: "Instance ID",
    },
    {
      id: "service",
      name: "Service",
    },
  ];
  currentTab: String;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  selectedCloudPartner: string | null = localStorage.getItem("cp-selected");
  resourceGroup: string = localStorage.getItem("selectedResourceGroup");

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    //this.load();
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId" && this.selectedCloudPartner == "aws") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
    this.funRef = {
      loadAttachedInstances: this.loadAttachedInstances,
      loadNested: this.loadAttachedInstances,
    };

    window.ref = this;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load(loader: boolean = true) {
    this.loading = true;
    if (loader) {
      this.notifier.loading(true);
    }
    let data: any = {
      action: "sg_summary",
      region_name: this.regionId,
      account_id: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/sg`;


    if (this.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/security`;
      data = {
        action: "sg_summary",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
        module: "nsg_complaince",
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    this.loading = false;


    this.sgTabs = [];
    if (result.total == 0) {
    } else if (result.status == "1" && !result.error_message) {
      this.jobProgress = result["job_status"];
      if (result["job_status"] == "In Progress") {
        this.notifier.loading(false);
        setTimeout(() => {
          this.load(false);
        }, 5000);
        return;
      }
      result["total_sg"]["metadata"] = {
        str: `Total SG (${result["total_sg"]["count"]})`,
        id_str: "#" + result["total_sg"]["onclick"],
      };
      result["open_to_world_sg"]["metadata"] = {
        str: `Open To World (SG Wise) (${result["open_to_world_sg"]["count"]})`,
        id_str: "#" + result["open_to_world_sg"]["onclick"],
      };
      result["open_to_world_port"]["metadata"] = {
        str: `Open To World (Port Wise) (${result["open_to_world_port"]["count"]})`,
        id_str: "#" + result["open_to_world_port"]["onclick"],
      };
      result["attached_sg"]["metadata"] = {
        str: `Attached SG (${Number(result["attached_sg"]["count"])}) (${
          result["attached_sg"] && Number(result["attached_sg"]["count"])
            ? (
                (Number(result["attached_sg"]["count"]) /
                  result["total_sg"]["count"]) *
                100
              ).toFixed(2)
            : 0
        }%)`,
        id_str: "#" + result["attached_sg"]["onclick"],
      };
      result["unattached_sg"]["metadata"] = {
        str: `Unattached SG (${Number(result["unattached_sg"]["count"])}) (${
          result["unattached_sg"] && Number(result["unattached_sg"]["count"])
            ? (
                (Number(result["unattached_sg"]["count"]) /
                  result["total_sg"]["count"]) *
                100
              ).toFixed(2)
            : 0
        }%)`,
        id_str: "#" + result["unattached_sg"]["onclick"],
      };
      this.sgTabs.push(result["total_sg"]);
      this.sgTabs.push(result["open_to_world_sg"]);
      this.sgTabs.push(result["open_to_world_port"]);
      this.sgTabs.push(result["attached_sg"]);
      this.sgTabs.push(result["unattached_sg"]);
      if (this.sgTabs.length > 0) {
        this.getSecurityGroups(this.sgTabs[0].onclick);
      }
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.notifier.loading(false);
  }

  async getSecurityGroups(clickID: String) {
    this.currentTab = clickID;
    this.notifier.loading(true);
    let data: any = {
      action: clickID,
      region_name: this.regionId,
      account_id: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/sg`;

    if (this.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/security`;
      data = {
        action: clickID,
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
        module: "nsg_complaince",
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if ((result.status == "1" || result.s == "1") && result[`${clickID}`]) {
      this.sgList = result[`${clickID}`];
    } else {
      this.sgList = [];
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.notifier.loading(false);
  }

  getHeader(tabName?: String): any {
    if (
      (this.currentTab == "total_sg" ||
        this.currentTab == "attached_sg" ||
        this.currentTab == "unattached_sg") &&
      tabName == null
    ) {
      return this.attachedInstancesHeader;
    }
    if (tabName == "open_to_world_sg") {
      return this.headers;
    } else if (tabName == "open_to_world_port") {
      return this.portwiseheader;
    } else if (this.currentTab == "open_to_world_sg") {
      return this.attachedInstancesHeader;
    } else {
      return this.headersNested;
    }
  }

  getHeaderNested() {
    return this.attachedInstancesHeader;
  }

  createJobTrigger: boolean = false;

  createJob(event: any) {
    this.createJobTrigger = true;
  }

  hideCreateJob(event: any) {
    if (event) {
      this.load();
    }
    this.createJobTrigger = null;
  }

  jobProgress: string = null;
  hideNested(event: any) {}

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  async loadAttachedInstances(ref: any, param: any) {
    if (ref.currentTab != "open_to_world_port" && param.showNested) {
      param["showNested"] = false;
      return;
    } else if (ref.currentTab == "open_to_world_port" && param.showNested) {
      param.showNested = false;
      return;
    } else if (ref.currentTab == "open_to_world_port" && !param.showNested) {
      param.showNested = true;
      param.attachedInstances = param.sg_details;
      return;
    }

    if (Number(param.instance_attched) == 0) {
      ref.notifier.alert(
        "Info",
        "",
        "No Attached Instances Found",
        "info",
        5000
      );
      return;
    }
    ref.notifier.loading(true);
    let data: any = {
      action: "attched_instances",
      region_name: ref.regionId,
      account_id: ref.accountId,
      sg_id: param.sg_id,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance/sg`;

    if (ref.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/security`;
      data = {
        action: "attched_instances",
        user_id:
          ref.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : ref.userId,
        subscription_id: ref.accountId,
        resource_group_name: ref.resourceGroup,
        module: "nsg_complaince",
        sg_id: param.sg_id,
        nsg_name: param.sg_id,
      };
    }

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || result.s == "1") {
      //this.attachedInstances = result.instance_details;

      for (let i = 0; i < result.instance_details.length; i++) {
        if (result.instance_details[i].sg_details) {
          result.instance_details[i].nestedData =
            result.instance_details[i].sg_details;
          //result.instance_details[i] = result.instance_details;
        }
      }
      param.attachedInstances = result.instance_details;

      param.showNested = true;
    } else {
      ref.notifier.alert("Info", "", result.error, "info", 5000);
    }

    ref.notifier.loading(false);
  }

  nestedLoader() {
    return this.load3rdLevelData;
  }

  async load3rdLevelData(ref: any, param: any) {
    ref = window.ref;
    if (Number(param.instance_attched) == 0) {
      ref.notifier.alert(
        "Info",
        "",
        "No Attached Instances Found",
        "info",
        5000
      );
      return;
    }
    ref.notifier.loading(true);
    let data: any = {
      action: "attched_instances",
      region_name: ref.regionId,
      account_id: ref.accountId,
      sg_id: param.sg_id,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance/sg`;

    if (ref.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/security`;
      data = {
        action: "attched_instances",
        user_id:
          ref.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : ref.userId,
        subscription_id: ref.accountId,
        resource_group_name: ref.resourceGroup,
        module: "nsg_complaince",
        sg_id: param.sg_id,
        nsg_name: param.sg_id,
      };

      console.log(data);
    }

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || result.s == "1") {
      //this.attachedInstances = result.instance_details;

      for (let i = 0; i < result.instance_details.length; i++) {
        if (result.instance_details[i].sg_details) {
          result.instance_details[i].nestedData =
            result.instance_details[i].sg_details;
          //result.instance_details[i] = result.instance_details;
        }
      }
      param.nestedData = result.instance_details;

      param.showNested = true;
    } else {
      ref.notifier.alert("Info", "", result.error, "info", 5000);
    }

    ref.notifier.loading(false);
  }
}
