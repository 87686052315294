import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

declare let $: any;
declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  selector: "diskdetails-reports",
  templateUrl: "./diskdetails.view.reports.component.html",
  styleUrls: ["./diskdetails.view.reports.component.css"],
})
export class DiskDetailsReportsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  instances: any = [];
  selectedInstance: String;
  diskUtilization: any = [];

  @Input("showHeader") showHeader: boolean;

  accountId: any;
  regionId: any;
  currentMessage: any;

  diskUtilizationHeader: any = [
    {
      id: "disk_name",
      name: "Name",
    },
    {
      id: "disk_file_system",
      name: "File System",
    },
    {
      id: "disk_size",
      name: "Size (MB)",
    },
    {
      id: "disk_used_size",
      name: "Used (MB)",
    },
    {
      id: "disk_free_size",
      name: "Free (MB)",
    },
    {
      id: "disk_used",
      name: "Used (%)",
    },
    {
      id: "disk_free",
      name: "Free(%)",
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      $("#instanceID").selectpicker();
    }, 100);

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }
  async load() {
    this.notifier.loading(true);
    this.diskUtilization = [];
    this.instances = [];
    await this.loadInstances();
    this.notifier.loading(false);
  }

  async loadInstances(nextToken: String = "") {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "listEc2TagsValues",
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
      tagKey: "Name",
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (
        (result.tagDetailsList && result.tagDetailsList.length == 0) ||
        (result.tagKeyList && result.tagKeyList.length == 0)
      ) {
        this.notifier.loading(false);
        return;
      }
      this.instances = [...this.instances, ...result.tagKeyList];
      this.selectedInstance = this.instances[0];
      setTimeout(() => {
        $("#instanceID").selectpicker("refresh");
      }, 100);
      await this.getDiskUtilization(false);
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
      this.notifier.loading(false);
    }
  }

  async getDiskUtilization(event: any) {
    this.notifier.loading(true);
    if (event) {
      this.selectedInstance = event.target.value;
    }
    this.diskUtilization = [];
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let header = {
          Authorization: localStorage.getItem("t"),
        };

        let account: any = localStorage.getItem("emsConfig");

        if (account) {
          account = JSON.parse(account);
        }

        let data = {
          action: "disk_details",
          instance_name: this.selectedInstance,
          zaaid: account ? account["zaid"] : site_247_project_id,
          origin: account ? "swayam" : "minfy",
        };
        let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == "1" || result.s == "1") {
          this.diskUtilization = result.disk_data;
        } else {
          this.notifier.alert("Error", "", result.error_message, "error", 5000);
        }
      } else {
        this.notifier.alert(
          "Error",
          "",
          selectedAccountData.msg,
          "error",
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert("Error", "", err.msg, "error", 5000);
    }
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel("diskTable", "Disk Details", "csv");
  }

  callFunction(id: any, id1: any) {}

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
