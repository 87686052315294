<div class="card" *ngIf="!selectedWorkload">
  <h5 class="card-header">
    <div class="flex-between">
      <div>WAFR</div>
      <div class="flex-start">
        <button class="btn btn-light m-0" (click)="refresh(true)">
          <i class="fa fa-refresh"></i>
          Refresh
        </button>
        <button class="btn btn-primary-soft m-0" (click)="showCreatePorfile()">
          <i class="fa fa-plus" aria-hidden="true"></i> Create Profile
        </button>
        <button
          class="btn btn-primary-soft m-0"
          *ngIf="!loading['lenses'] && customLens.length == 0"
          (click)="showCreateCustomLens()"
        >
          <i class="fa fa-plus" aria-hidden="true"></i> Create Custom Lens
        </button>
        <button
          class="btn btn-primary-soft m-0"
          (click)="createWorkload = true"
        >
          <i class="fa fa-plus" aria-hidden="true"></i> Create Workload
        </button>
      </div>
    </div>
  </h5>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link active"
          id="workloads-tab"
          data-bs-toggle="tab"
          data-bs-target="#workloads"
          type="button"
          role="tab"
          aria-controls="workloads"
          aria-selected="true"
        >
          Workloads
        </button>
        <button
          class="nav-link"
          id="profiles-tab"
          data-bs-toggle="tab"
          data-bs-target="#profiles"
          type="button"
          role="tab"
          aria-controls="profiles"
          aria-selected="false"
        >
          Profiles
        </button>
        <button
          class="nav-link"
          id="custom_lens-tab"
          data-bs-toggle="tab"
          data-bs-target="#custom_lens"
          type="button"
          role="tab"
          aria-controls="custom_lens"
          aria-selected="false"
        >
          Custom Lens
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="workloads"
        role="tabpanel"
        aria-labelledby="workloads-tab"
      >
        <table class="table table-docs">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Owner</th>
              <th>Name</th>
              <th>Last Updated</th>
              <th>Profile Associated</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="workloads.length > 0">
              <tr *ngFor="let workload of workloads; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>{{ accountId }}</td>
                <td
                  *ngIf="
                    workload['ScanStatus'] == 'COMPLETED' &&
                      !loading['profiles'];
                    else showlink
                  "
                  (click)="selectWorkload(workload)"
                >
                  <span class="nav-link cursor-pointer">{{
                    workload.name
                  }}</span>
                </td>

                <ng-template #showlink>
                  <td>
                    {{ workload.name }}
                  </td>
                </ng-template>
                <td>{{ workload.creation_time }}</td>
                <td>
                  <div *ngIf="!loading['profiles']">
                    {{
                      workload["profile"]
                        ? workload["profile"]["profileName"]
                        : "-"
                    }}
                  </div>
                  <div *ngIf="loading['profiles']">
                    <img
                      src="../assets/img/loading_2.svg"
                      alt="loading scans"
                      class="wid-14"
                    />
                  </div>

                </td>
                <td>
                  <div class="flex-start pad-0">
                    <img
                      *ngIf="
                        workload['ScanStatus'] != 'COMPLETED' &&
                        workload['ScanStatus'] != 'Scan Failed'
                      "
                      src="../assets/img/loading_2.svg"
                      alt="loading scans"
                      class="wid-14"
                    />
                    <div>
                      {{ workload["ScanStatus"] }}
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-container
              *ngIf="workloads.length == 0 && !loading['workloads']"
            >
              <tr>
                <td colspan="6" class="text-center">No Workloads found</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="loading['workloads']">
              <tr>
                <td colspan="6" class="text-center">
                  <img
                    src="../assets/img/loading_2.svg"
                    alt="loading scans"
                    class="wid-mar-10"
                  />
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div
        class="tab-pane fade"
        id="profiles"
        role="tabpanel"
        aria-labelledby="profiles-tab"
      >
        <table class="table table-docs">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Name</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Workloads Associated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="profile_region.length > 0">
              <tr *ngFor="let profile of profile_region; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>{{ profile["profileName"] }}</td>
                <td>{{ profile["createdAt"] }}</td>
                <td>{{ profile["updatedAt"] }}</td>
                <td class="center">
                  {{ profile["workloads"].length }}
                </td>
                <td>
                  <div class="flex-start">
                    <span
                      class="btn btn-light"
                      (click)="updateProfile(profile)"
                    >
                      <i class="fa fa-pencil"></i>
                    </span>
                    <span
                      class="btn btn-danger-soft"
                      (click)="deleteProfile = profile"
                    >
                      <i class="fa fa-trash"></i>
                    </span>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-container
              *ngIf="profile_region.length == 0 && !loading['profiles']"
            >
              <tr>
                <td colspan="6" class="text-center">No Profiles found</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="loading['profiles']">
              <tr>
                <td colspan="6" class="text-center">
                  <img
                    src="../assets/img/loading_2.svg"
                    alt="loading scans"
                    class="wid-mar-10"
                  />
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div
        class="tab-pane fade"
        id="custom_lens"
        role="tabpanel"
        aria-labelledby="custom_lens-tab"
      >
        <table class="table table-docs">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>ARN</th>
              <th>Workloads Associated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="profiles.length > 0">
              <tr *ngFor="let lens of itemsWithLens; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>{{ lens["name"] }}</td>
                <td>{{ lens["workloads"].length }}</td>
                <!-- <td>{{lens.lensValue}}</td> -->
                <td>
                  <div class="flex-start">
                    <span
                      class="btn btn-danger-soft"
                      (click)="deleteLens = lens"
                    >
                      <i class="fa fa-trash"></i>
                    </span>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="customLens.length == 0 && !loading['lenses']">
              <tr>
                <td colspan="4" class="text-center">No Custom Lenses found</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="loading['lenses']">
              <tr>
                <td colspan="4" class="text-center">
                  <img
                    src="../assets/img/loading_2.svg"
                    alt="loading scans"
                    class="wid-mar-10"
                  />
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="
    selectedWorkload && !selectedLenses && !selectedPiller && !showQuestions
  "
>
  <div class="card">
    <h5 class="card-header flex-between" *ngIf="!milestone">
      <div>
        <button class="btn btn-light" (click)="refresh(true, false)">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          Workloads
        </button>
        <span>
          {{ selectedWorkload["name"] }}
        </span>
      </div>
    </h5>
    <h5 class="card-header flex-between" *ngIf="milestone">
      <div>
        <button class="btn btn-light" (click)="milestone = null">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          {{ selectedWorkload["name"] }}
        </button>
        <span> Milestones > {{ milestone["name"] }} </span>
      </div>
    </h5>
    <div class="card-body">
      <app-workload-overview
        [lenses]="lenses || []"
        [pillers]="pillers || []"
        [profiles]="profiles || []"
        [questions]="milestone ? milestone['questions'] : questions"
        [workload]="selectedWorkload"
        [loading]="loading"
        (selectLens)="selectLens($event)"
        (showQuestions)="selectPiller(null, $event)"
        (saveMilestone)="showMileStonePopup = true"
        [isMilestone]="milestone ? true : false"
        [milestone]="milestone"
        [milestones]="milestones"
        (milestone)="this.milestone = $event"
        (refresh)="refresh($event)"
      ></app-workload-overview>
    </div>
  </div>
</div>
<div
  *ngIf="
    selectedWorkload && selectedLenses && !selectedPiller && !showQuestions
  "
>
  <div class="card">
    <h5 class="card-header flex-between">
      <div>
        <button class="btn btn-light" (click)="selectedLenses = null">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          {{ milestone ? "Milestone" : "Workload" }} Overview
        </button>
        <span>
          {{ milestone ? milestone["name"] : selectedWorkload["name"] }} -
          {{ lensesData[selectedLenses] || selectedLenses }}
        </span>
      </div>
    </h5>
    <div class="card-body">
      <app-lens-overview
        [lenses]="lenses"
        [pillers]="pillers"
        [questions]="milestone ? milestone.questions : questions"
        [workload]="selectedWorkload"
        [loading]="loading"
        (selectPiller)="selectPiller($event)"
        [selectedLens]="selectedLenses"
        [isMilestone]="milestone != null ? true : false"
        [filter]="filter"
        (showQuestions)="selectPiller($event, null)"
      ></app-lens-overview>
    </div>
  </div>
</div>
<div *ngIf="selectedWorkload && selectedLenses && showQuestions">
  <app-questions
    [indexs]="currentQuestionIndexs"
    [lens]="selectedLenses"
    [pillers]="pillers"
    [questions]="milestone ? milestone.questions : questions"
    [piller]="selectedPiller || pillers[0]"
    [workload]="selectedWorkload"
    [questionsCode]="questionsCode"
    (removePiller)="hideQuestions($event)"
    [isMilestone]="milestone ? true : false"
    [loading]="loading"
    [milestone]="milestone"
    [filter]="filter"
    (changeQuestion)="changeQuestion($event)"
  ></app-questions>
</div>
<create-workload
  *ngIf="createWorkload"
  [accountId]="accountId"
  [regionId]="regionId"
  [customLens]="customLens"
  (hideModal)="hideCreateWorkload($event)"
></create-workload>
<create-profile
  *ngIf="createProfile"
  [accountId]="accountId"
  [regionId]="regionId"
  [profile]="updateProfileObj"
  [action]="profileAction"
  (hideModal)="hideCreateProfile($event)"
></create-profile>

<app-create
  [milestone_names]="getMilestoneNames()"
  *ngIf="showMileStonePopup && selectedWorkload"
  [accountId]="accountId"
  [regionId]="regionId"
  (hideModal)="hideMilestonePopup($event)"
  [workload]="selectedWorkload"
></app-create>

<workload-delete-profile
  *ngIf="deleteProfile"
  [accountId]="accountId"
  [regionId]="regionId"
  [profile]="deleteProfile"
  (hideModel)="deleteProfileHide($event)"
></workload-delete-profile>

<delete-lens
  *ngIf="deleteLens"
  [accountId]="accountId"
  [regionId]="regionId"
  [lens]="deleteLens"
  (hideModel)="deleteLensHide($event)"
></delete-lens>

<create-lens
  *ngIf="createLens"
  [accountId]="accountId"
  [regionId]="regionId"
  (hideModel)="createLensHide($event)"
></create-lens>
