<div class="card">
  <div class="card-header">
    <h5 class="display_align_center">
      <div>
        <button class="btn btn-light" (click)="hideModel(false)">
          < Accounts </button>
            <span *ngIf="action == 'addaccount'">
              Add Account
            </span>
            <span *ngIf="action == 'updateaccount'">
              {{ selectedAccount.accountName }}
            </span>
      </div>
      <span *ngIf="action == 'updateaccount'">
        <button class="btn btn-danger-soft" (click)="deleteAccount()" *ngIf="selectedAccount['status'] == 'active'">
          <span *ngIf="loading['deactivate']">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="width_15" />
          </span>
          Deactivate
        </button>
        <button class="btn btn-success-soft" (click)="deleteAccount()" *ngIf="selectedAccount['status'] == 'inActive'">
          <span *ngIf="loading['deactivate']">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="width_15" />
          </span>
          Activate
        </button>
      </span>
    </h5>
  </div>
  <ng-container *ngIf="action == 'addaccount'">
    <div class="card-body" *ngIf="stepCount == 1">
      <div class="step_container">
        <div class="step_header">
          Create a new Role in the AWS Console
        </div>
        <div class="step_body">
          <!-- <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                type="button" role="tab" aria-controls="nav-home" aria-selected="true"
                (click)="roleType = 'automatic'">Automatic</button>
              <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                (click)="roleType = 'manual'" type="button" role="tab" aria-controls="nav-profile"
                aria-selected="false">Manual</button>
            </div>
          </nav> -->
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="border_top_none">
                <!--<h5 class="card-header"></h5>-->

                <div class="card-body">
                  <!--<h5 class="card-title">Case Details</h5>-->
                  <div>
                    Automatic pre-populates fields required for importing accounts into {{ whitelabelData['pd']['appName'] }}
                    <span class="nav-link" (click)="showInfo = [0, 1, 2]"><i class="fa fa-question-circle"
                        aria-hidden="true"></i>
                    </span>
                  </div>
                  <div>
                    <a *ngIf="!loading['link'] && link" [attr.href]="link" target="_blank">Click here to navigate to
                      console <i class="fa fa-external-link" aria-hidden="true"></i></a>
                    <img src="../assets/img/loading_2.svg" alt="loading scans" *ngIf="loading['link']"
                      class="width_12" />
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div class="border_top_none">
                <!--<h5 class="card-header"></h5>-->
                <div class="card-body">
                  <!--<h5 class="card-title">Case Details</h5>-->
                  <div class="margin_bottom_6px">Open your AWS console</div>
                  <div>
                    When creating your role, verify the following options
                    <ul>
                      <li>Role Type: Another AWS Account</li>
                      <li>Account ID: 12345678901</li>
                      <li>The checkbox for Require external ID is checked</li>
                      <li>External ID: 12sdfd2-124rsdfdg-123wdfsdg-1243dvdg</li>
                      <li>Require MFA option is unchecked</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step_container">
        <div class="step_header">
          Enter the role details below
        </div>
        <div class="step_body">
          <div class="card-body">
            <div class="mb-2">
              Add a name for this Cross-Account Role for future data imports. Navigate to the new Role, copy the Role
              ARN
              and add it
              below.
            </div>
            <div>
              <div class="mb-4">
                <label for="">
                  <div class="flex-start">
                    <span>
                      AWS Account ID
                    </span>
                    <span class="nav-link" (click)="showInfo = [3]"><i class="fa fa-question-circle"
                        aria-hidden="true"></i>
                    </span>
                  </div>
                </label>
                <input autocomplete="off" type="text" [ngClass]="{'error-input': errors['accountId']['error']}"
                  [(ngModel)]="selectedAccount.accountId" (focusout)="checkForValidation('accountId')"
                  class="form-control width_500">
                <span class="error-msg" *ngIf="errors['accountId']['error']">
                  {{errors['accountId']['msg']}}
                </span>
              </div>
              <div class="mb-4">
                <label for="">Name</label>
                <input autocomplete="off" type="text" [ngClass]="{'error-input': errors['accountName']['error']}"
                  [(ngModel)]="selectedAccount.accountName" (focusout)="checkForValidation('accountName')"
                  class="form-control width_500">
                <span class="error-msg" *ngIf="errors['accountName']['error']">
                  {{errors['accountName']['msg']}}
                </span>
              </div>
              <ng-container *ngIf="roleType == 'manual'">
                <div class="mb-4">
                  <label for="">Cross Account Role name</label>
                  <input autocomplete="off" type="text" class="form-control width_500">
                </div>
                <div class="mb-4">
                  <label for="">Role ARN</label>
                  <input autocomplete="off" type="text" class="form-control width_500">
                </div>
                <div class="mb-4">
                  <label for="">External ID</label>
                  <input autocomplete="off" type="text" class="form-control width_500">
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="text_align_right">
        <button class="btn btn-primary-soft"
          (click)="checkForValidations(['accountId', 'accountName']) && stepCount = 2">Next</button>
      </div>
    </div>
    <div class="card-body" *ngIf="stepCount == 2">
      <div class="step_container">
        <div class="step_header">
          Regions ({{ !selectedAccount.customRegions ? regions.length :
          selectedAccount.regions.length}}/{{regions['enabled'].length + regions['disabled'].length}})
          <span class="float-right error-msg" *ngIf="errors['regions'] && errors['regions']['error']">
            {{errors['regions']['msg']}}
          </span>
        </div>
        <div class="step_body">
          <div class="container p-10 center">
            AWS Default Regions
          </div>
          <div class="container gap_8px">
            <!-- <div class="display_justify_space">
              <div>Select All</div>
              <div>
                <label class="switch" (click)="toggleRegions($event)">
                  <input autocomplete="off" type="checkbox" class="pointer_events_none"
                    [attr.checked]="selectedAccount.customRegions == false ? true : null">
                  <span class="slider round"></span>
                </label>
              </div> -->
            <div *ngFor="let continent of getContinents(this.regions['enabled'])" class="continent_container">
              <div class="center text-bold">{{ continent }}</div>
              <ng-container *ngIf="getRegions(continent).length > 0">
                <div *ngFor="let i of getRegions(continent)" class="display_justify_space"
                  (click)="toggleRegion($event, i.id)">
                  <div>{{ i.name }}</div>
                  <div>
                    <label class="switch">
                      <input autocomplete="off" type="checkbox" class="pointer_events_none"
                        [attr.checked]="checkForRegion(i.id) ? true: null">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="container p-10 center">
            Below regions are not enabled by default in AWS. please select if you wish to enable in {{ whitelabelData['pd']['appName'] }}
          </div>
          <div class="container gap_8px">
            <!-- <div class="display_justify_space">
                        <div>Select All</div>
                        <div>
                          <label class="switch" (click)="toggleRegions($event)">
                            <input autocomplete="off" type="checkbox" class="pointer_events_none"
                              [attr.checked]="selectedAccount.customRegions == false ? true : null">
                            <span class="slider round"></span>
                          </label>
                        </div> -->
            <div *ngFor="let continent of getContinents(this.regions['disabled'])" class="continent_container">
              <div class="center text-bold">{{ continent }}</div>
              <ng-container *ngIf="getDisabledRegions(continent).length > 0">
                <div *ngFor="let i of getDisabledRegions(continent)" class="display_justify_space"
                  (click)="toggleRegion($event, i.id)">
                  <div>{{ i.name }}</div>
                  <div>
                    <label class="switch">
                      <input autocomplete="off" type="checkbox" class="pointer_events_none"
                        [attr.checked]="checkForRegion(i.id) ? true: null">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="text_align_right">
          <button class="btn btn-light" (click)="stepCount = 1">Back</button>
          <button class="btn btn-primary-soft" (click)="updateAccount()">
            <span *ngIf="loading['adding']">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="width_15" />
            </span>
            Import
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="action == 'updateaccount'">
    <div class="card-body">
      <div class="step_container">
        <div class="step_header">
          Account Details
        </div>
        <div class="step_body">
          <div class="card-body">
            <div>
              <div class="mb-4">
                <label for="">Account ID</label>
                <input autocomplete="off" type="text" [ngModel]="selectedAccount.accountId" [attr.disabled]="true"
                  class="form-control width_500">
              </div>
              <div class="mb-4">
                <label for="">Name</label>
                <input autocomplete="off" type="text" [ngClass]="{'error-input': errors['accountName']['error']}"
                  [(ngModel)]="selectedAccount.accountName" (focusout)="checkForValidation('accountName')"
                  class="form-control width_500">
                <span class="error-msg" *ngIf="errors['accountName']['error']">
                  {{errors['accountName']['msg']}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="step_header">
          Regions ({{ !selectedAccount.customRegions ? regions.length :
          selectedAccount.regions.length}}/{{regions['enabled'].length + regions['disabled'].length}})
          <span class="float-right error-msg" *ngIf="errors['regions'] && errors['regions']['error']">
            {{errors['regions']['msg']}}
          </span>
        </div>
        <div class="step_body">
          <div class="container p-10 center">
            AWS Default Regions
          </div>
          <div class="container gap_8px">
            <!-- <div class="display_justify_space">
                    <div>Select All</div>
                    <div>
                      <label class="switch" (click)="toggleRegions($event)">
                        <input autocomplete="off" type="checkbox" class="pointer_events_none"
                          [attr.checked]="selectedAccount.customRegions == false ? true : null">
                        <span class="slider round"></span>
                      </label>
                    </div> -->
            <div *ngFor="let continent of getContinents(this.regions['enabled'])" class="continent_container">
              <div class="center text-bold">{{ continent }}</div>
              <ng-container *ngIf="getRegions(continent).length > 0">
                <div *ngFor="let i of getRegions(continent)" class="display_justify_space"
                  (click)="toggleRegion($event, i.id)">
                  <div>{{ i.name }}</div>
                  <div>
                    <label class="switch">
                      <input autocomplete="off" type="checkbox" class="pointer_events_none"
                        [attr.checked]="checkForRegion(i.id) ? true: null">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="container p-10 center">
            Below regions are not enabled by default in AWS. please select if you wish to enable in {{ whitelabelData['pd']['appName'] }}
          </div>
          <div class="container gap_8px">
            <!-- <div class="display_justify_space">
                              <div>Select All</div>
                              <div>
                                <label class="switch" (click)="toggleRegions($event)">
                                  <input autocomplete="off" type="checkbox" class="pointer_events_none"
                                    [attr.checked]="selectedAccount.customRegions == false ? true : null">
                                  <span class="slider round"></span>
                                </label>
                              </div> -->
            <div *ngFor="let continent of getContinents(this.regions['disabled'])" class="continent_container">
              <div class="center text-bold">{{ continent }}</div>
              <ng-container *ngIf="getDisabledRegions(continent).length > 0">
                <div *ngFor="let i of getDisabledRegions(continent)" class="display_justify_space"
                  (click)="toggleRegion($event, i.id)">
                  <div>{{ i.name }}</div>
                  <div>
                    <label class="switch">
                      <input autocomplete="off" type="checkbox" class="pointer_events_none"
                        [attr.checked]="checkForRegion(i.id) ? true: null">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="text_align_right">
            <button class="btn btn-primary-soft"
              (click)="checkForValidations(['accountId', 'accountName' ]) && updateAccount()">
              <span *ngIf="loading['adding']">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="width_15" />
              </span>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-info *ngIf="showInfo && showInfo.length > 0" [steps]="showInfo" (hideModel)="showInfo = null"></app-info>