
<div class="card">
  <div class="card-header">Cost Anomaly Report</div>
  <div class="card-body">

<div class="card_body_style">
  <div [ngStyle]="{display: this.customerType !== 'mp' ? 'block' : 'none'}">
    <span class="font_weight_bold">Master Accounts</span>
    <span>
      <select (change)="getAnomalydatabyselect($event)" class="form-control master_account_style_select" id="masterAccountSelect"
        data-live-search="true" >
        <option [value]="account"  *ngFor="let account of masterAccounts">
          {{ account }}
        </option>
      </select>
    </span>
  </div>
</div>
<!-- <div class="text_align_right" *ngIf="range == 'last_6_month' && loadHeaders().length < 6">
  * Baseline month to view Billing Variance Report is April 2022
</div> -->
<div class="view_billing_variance_style">
  <div *ngIf="isService">
   <span > Date Range:</span> <span class="font_weight_bold">{{dateRange}}</span>
  </div>
  <div></div>
  <div class="view_billing_variance_style_2">
    <div *ngIf="isService">
      Total: <span class="font_weight_bold" >${{totalBill}}</span>
    </div>
    <div>
      <button class="btn btn-primary-soft" (click)="viewStatusTab()">Alert</button>
    </div>
  </div>
</div>
<!-- <div *ngIf="breadCrum.length > 1" class="breadcrums">
  <div *ngFor="let value of breadCrum; let i = index" class="breadCrum_item">
    <span (click)="i + 1 != breadCrum.length && navigate(value)"
      [ngStyle]="{cursor: i + 1 != breadCrum.length && 'pointer', color: i + 1 != breadCrum.length && 'blue'}">
      {{value.value}}
    </span>
    <span class="breadcrums_style" *ngIf="i + 1 != breadCrum.length">
      >
    </span>
  </div>
</div> -->
<div class="daily_bill_style1">
  <table  class="table table-docs table-hover">
    <thead>
      <tr>
        <td class="font_weight_bold">S. N.</td>
        <td class="font_weight_bold sticky">Service Name</td>
        <td class="font_weight_bold">Spend during the period<br>({{startDays}} to {{endDays}})</td>
        <td class="font_weight_bold">Per day mean spend<br>(as of {{endDays}})(USD)</td>
        <td class="font_weight_bold">Per day spend effective<br>{{endDays}}</td>
        <td class="font_weight_bold">New monthly spend based on<br>recent variance (USD)</td>
        <td class="font_weight_bold">Additional spend till end of month<br>(Balance {{anomalyReport['remdays ']}} days)(USD)</td>
        <td class="font_weight_bold">Increase in spend<br>(% age)</td>
       
        
        

      </tr>
    </thead>

    <tbody *ngIf="isService">
      <ng-container *ngFor="let report of anomalyReport['services']; let i = index">
        <tr>
          <td>{{i+1}}</td>
          <td class="sticky">{{report['name']}}</td>
         <td>${{sliceNumber(report['total'])}}</td>
         <td>${{sliceNumber(report['avg'])}}</td>
         <td>${{sliceNumber(report['latestspend'])}}</td>
         <td>${{sliceNumber(report['monthlySpend'])}}</td>
         <td>${{sliceNumber(report['expectedSpend'])}}</td>
         <td [ngClass]="report['variance'] >= 20 ? 'red' : (report['variance'] >= 5 && report['variance'] <= 20) ? 'amber' : 'black'">
          {{sliceNumber(report['variance'])}}
        </td>
        </tr>
         
          </ng-container>
        
       
    </tbody>
    <tbody *ngIf="!isService">
      <tr>
        <td colspan="6"
          class="text_align_center center">
          No Data Found
        </td>
      </tr>
    </tbody>
  </table>

  
  
</div>
  </div>
</div>
<!-- <div *ngIf="!isService" class="text_align_center">No Bills Found</div> -->

<app-cost-anamoly-status *ngIf="statusTab" (hideModal)="closeStatusTab($event)"></app-cost-anamoly-status>

