import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";

@Component({
  selector: "azure-dashboard-price-difference",
  templateUrl: "./price-difference.component.html",
  styleUrls: ["./price-difference.component.css"],
})
export class PriceDifferenceComponent implements OnInit {
  @Input("data") data: any;
  @Input("title") title: any;
  @Output("hideModal") hideModal: any = new EventEmitter<boolean>();
  range: any = {
    start: "",
    end: "",
  };

  ngOnInit(): void {
    setTimeout(() => {
      this.getRange();
    }, 1);
    
  }

  getRange() {
    const priceArray: any = this.data;
    const prices: number[] = priceArray.map((item: any) => parseFloat(item.price));
    const highestPrice: number = Math.max(...prices);
    const lowestPrice: number = Math.min(...prices);
    let nextValueForHighest: number;
    let lastValueForLowest: number;
    if (highestPrice >= 5000) {
      nextValueForHighest = Math.ceil(highestPrice / 1000) * 1000;
    } else if (highestPrice >= 4000) {
      nextValueForHighest = Math.ceil(highestPrice / 500) * 500;
    } else if (highestPrice >= 2000) {
      nextValueForHighest = Math.ceil(highestPrice / 200) * 200;
    } else if(highestPrice >= 500){
      nextValueForHighest = Math.ceil(highestPrice / 100) * 100;
    } else if(highestPrice >= 100){
      nextValueForHighest = Math.ceil(highestPrice / 50) * 50;
    } else {
      nextValueForHighest = Math.ceil(highestPrice / 10) * 10;
    }
    
    if (lowestPrice < 100) {
      lastValueForLowest = 0;
    } else if (lowestPrice < 500) {
      lastValueForLowest = Math.floor(lowestPrice / 50) * 50;
    } else if (lowestPrice < 1000) {
      lastValueForLowest = Math.floor(lowestPrice / 50) * 50;
    } else if (lowestPrice < 2000) {
      lastValueForLowest = Math.floor(lowestPrice / 100) * 100;
    } else if (lowestPrice < 5000) {
      lastValueForLowest = Math.floor(lowestPrice / 200) * 200;
    } else {
      lastValueForLowest = Math.floor(lowestPrice / 1000) * 1000;
    }
    priceArray.forEach((item: any) => {
      const priceValue = parseFloat(item.price);
      if (priceValue <= lastValueForLowest) {
         item['perc'] = 0;
      } else if (priceValue >= nextValueForHighest) {
        item['perc'] = 100;
      } else {
        item['perc'] = (
          ((priceValue - lastValueForLowest) /
            (nextValueForHighest - lastValueForLowest)) *
          100
        );
      }
    });
    this.range["start"] = lastValueForLowest;
    this.range["end"] = nextValueForHighest;
  }
}
