import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site24x7',
  templateUrl: './site24x7.component.html',
  styleUrls: ['./site24x7.component.css']
})
export class Site24x7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
