<div class="enable-cur-screen">
  <div class="enable-cur-container">
    <div class="header-section">
      <div class="title">Resources Under Threat</div>
    </div>
    <div class="features-section">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers" [ngClass]="{center: h.center, white_space: h.center}">
              {{h.name}}
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,resources)">

                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter " class="inputseach">
                <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let resource of resources; let i = index">
            <tr *ngIf="notifier.filterTableColums(headers,resource)" (click)="hideModel.emit(resource.name)"
              class="pointer">
              <td>{{ i + 1 }}</td>
              <td>{{ resource.name }}</td>
              <td class="center">{{ resource.count }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="cur-actions flex-end">
      <button class="btn btn-light" (click)="hideModel.emit(false)">
        Close
      </button>
    </div>
  </div>
</div>