import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "menu-selector",
  templateUrl: "./menu-selector.component.html",
  styleUrls: ["./menu-selector.component.css"],
})
export class MenuSelectorComponent implements OnChanges {
  @Input("menus") menus: any;
  @Input("selected") selected?: any;

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['menus']) {
        if (this.selected) {
          this.menus.forEach((menu: any) => {
            if (
              (!menu["submenus"] && this.selected[menu["label"]]) ||
              (menu["submenus"] &&
                menu["submenus"].length == this.selected[menu["label"]])
            ) {
              menu["selected"] = true;
            }
            if (menu["submenus"]) {
              menu["submenus"].forEach((menu_: any) => {
                if (this.selected[menu["label"]] && this.selected[menu["label"]].indexOf(menu_["label"]) > -1) {
                  menu_["selected"] = true;
                }
              });
            }
          });
      }

        this.menus.forEach((menu: any) => {
          if (this.mandatoryMenus.includes(menu['label'])
          ) {
            menu["selected"] = true;
          }
          if (menu["submenus"]) {
            menu["submenus"].forEach((menu_: any) => {
              if (this.mandatoryMenus.includes(menu['label'])) {
                menu_["selected"] = true;
              }
            });
          }
        });
      }
  }

  mandatoryMenus: any = ['Support', 'Settings'];
  selectedAll: boolean = false;
  selectAllMenus() {
    this.selectedAll = !this.selectedAll;
    this.menus.forEach((menu: any) => {
      if(this.mandatoryMenus.includes(menu['label'])) {
        return;
      }
      menu['selected'] = this.selectedAll;
      if(menu['submenus'] && menu['submenus'].length > 0) {
        menu['submenus'].forEach((submenu: any) => {
          submenu['selected'] = this.selectedAll;
        })
      }
    })
  }

  getSelectedMenusLength(menu: any) {
    return menu["submenus"]
      ? menu["submenus"].filter((menu_: any) => {
          return menu_["selected"];
        }).length
      : 0;
  }

  selectMainMenu(event: any, menu: any) {
    event.preventDefault();
    event.stopPropagation();
    if(this.mandatoryMenus.includes(menu['label'])) {
      return;
    }
    menu["selected"] = !(menu["selected"] || (menu['submenus'] && this.getSelectedMenusLength(menu) == menu['submenus'].length));
    if (menu["submenus"].length > 0) {
      menu["submenus"].forEach((menu_: any) => {
        menu_["selected"] =
          menu["selected"]
      });
    }
  }

  mainMenuClick(menu: any) {
    if (!menu["submenus"] || menu["submenus"].length == 0 && !this.mandatoryMenus.includes(menu['label'])) {
      menu["selected"] = !menu['selected'];
    } else {
      menu["expanded"] = !menu["expanded"];
    }
  }

  subMenuClick(menu: any, submenu: any) {
    if(this.mandatoryMenus.includes(menu['label'])) return;
    submenu['selected'] = !submenu['selected'];
    if(this.getSelectedMenusLength(menu).length == this.menus.length) {
      menu['selected'] = true;
    } else {
      menu['selected'] = false;
    }
  }
}
