import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-resources-cams",
  templateUrl: "./resources-cams.component.html",
  styleUrls: ["./resources-cams.component.css"],
})
export class ResourcesCamsComponent implements OnInit, OnDestroy {
  @Input("regionId") regionId: string;
  @Input("accountId") accountId: string;
  @Input("roleARN") roleARN: string;
  @Input("resources") resources: any;

  loading: any = {
    cloudfront: false,
    elb: false,
    ec2: false,
    rds: false,
  };

  currentPage: string = "cdn";
  destroyed: boolean = false;

  protocolResource: any = null;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  pagination: any = {
    cdn: {
      page: 1,
      perPage: 10,
    },
    elb: {
      page: 1,
      perPage: 10,
    },
    rds: {
      page: 1,
      perPage: 10,
    },
    ec2: {
      page: 1,
      perPage: 10,
    },
  };

  ngOnInit(): void {
    this.fetchResource("cdn");
  }

  getProtocols(resource: any, protocol_required: boolean = true) {
    if (!resource["selected"] || !protocol_required) {
      return;
    }
    resource["protocol_required"] = true;
    this.protocolResource = resource;
    this.protocolResource["alert"] = undefined;
  }

  getSelectedCount(action: string) {
    return this.resources[action].filter((resource: any) => {
      return resource["selected"];
    })["length"];
  }

  catchProtocolPopup(event: any) {
    if (event["status"]) {
      this.protocolResource["alert"] = event["data"];
    } else {
      this.protocolResource["alert"] = undefined;
      if (this.protocolResource["protocol_required"]) {
        this.protocolResource["selected"] = false;
        this.protocolResource["protocol_required"] = undefined;
      }
    }
    this.protocolResource = null;
  }

  async fetchResource(action: string) {
    this.currentPage = action;
    if (!action) {
      this.notifier.alert(
        "Info",
        "",
        "Action is required to load resources",
        "info",
        5000
      );
    }

    if (this.resources[action].length > 0 || this.loading[action]) {
      return;
    }

    this.loading[action] = true;

    let data: any = {
      action: action,
      role_arn: this.roleARN,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/resources`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.resources[action] = result[action];
    }

    this.loading[action] = false;
  }

  elbTargetsHeaders: any = [
    {
      id: "tg_name",
      name: "Name",
    },
    {
      id: "id",
      name: "ID",
    },
    {
      id: "target_type",
      name: "Type",
    },
  ];

  show: any = null;
  async loadELBTargets(elb: any) {
    this.notifier.loading(true);
    if (elb["targets"] && elb["targets"].length > 0) {
      this.show = elb["targets"];
      return;
    }
    this.loading["elb_targets"] = true;

    let data: any = {
      action: "elb_targets",
      role_arn: this.roleARN,
      elb_arn: elb["elb_arn"],
      region_name: this.regionId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/resources`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      elb["targets"] = result["targets"].map((target: any) => {
        target["id"] =
          target["targets"].length > 0 ? target["targets"][0]["id"] : "-";
        return target;
      });
      this.show = elb["targets"];
    }

    this.loading["elb_targets"] = false;

    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }
}
