<div class="expired-screen">
  <div class="expired-container">
    <div class="expired-icon-container">
      <div class="icon-container">
        <img src="./assets/img/expired.png" alt="">
      </div>
    </div>
    <div class="expired-body">
      <h4 class="legend">Subscription Expired</h4>
      <div class="margin-top-bottom">
        Please renew your subscription to continue using Swayam
      </div>
      <div class="flex-center">
        <button class="btn btn-primary-soft">
          <a routerLink="/dash/renewal" class="nav-link">Renew</a>
        </button>
        <button class="btn btn-light">Contact us</button>
      </div>
    </div>
  </div>
</div>