<div>
  <table class="table table-docs">
    <thead>
      <tr>
        <td>Sl. No</td>
        <td *ngFor="let h of headers">
          {{h.name}}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let os of instance; let i = index">
        <td>{{i + 1}}</td>
        <td *ngFor="let h of headers">{{os[h.id]}}</td>
      </tr>
    </tbody>
  </table>
</div>