<div class="screen">
  <div class="popup-container">
    <div class="icon-container-popup">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    </div>
    <div class="popup-description">
      Selecting this will clear all previous entries on this form. <br>Do you want to continue?
    </div>
    <div class="flex-center">
      <button class="btn btn-primary-soft" (click)="this.action(true)">Yes</button>
      <button class="btn btn-light" (click)="this.action(false)">No</button>
    </div>
  </div>
</div>