<section>
  <div class="form-container">
    <div>
      <div class="swayam-name">Swayam Pro</div>
      <div>
        <span>Upgrade to Swayam Pro to get access to all features</span>
      </div>
      <ng-container *ngIf="!revewed">
        <div class="pricing_container" *ngIf="!loading['plans']">
          <div class="pricing_container_item" [ngClass]="{selected: plan.selected, not_selected: !plan.selected}"
            *ngFor="let plan of plans" (click)="selectPlan(plan)">
            <div class="pricing_check border-light-black" *ngIf="!plan['selected']">
            </div>
            <div class="pricing_check" *ngIf="plan['selected']">
              <div class="relative-100"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            </div>
            <div class="plan-title">{{ planData[plan["plan"]]["name"] }}</div>
            <div>
              <span class="plan-rate">${{ plan["rate"] }}</span><span>/Server/Month</span>
            </div>
            <div class="light-text">Includes upto {{ plan["users"] }} users</div>
            <div class="flex_box">
              <div class="flex-start feature-container"
                *ngFor="let m of slice(plan['mList'], 5)['array']; let i = index">
                <div>
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </div>
                <div>
                  {{ m }}
                </div>
              </div>
              <div class="flex-start">
                <span class="display-none">
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </span>
                <div class="light-text">
                  {{ slice(plan["mList"], 5)["remaining"] }}+ more
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pricing_container loading-container" *ngIf="loading['plans']">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-14" />
        </div>
        <div class="center" *ngIf="!loading['plans']">
          <span (click)="showPricingComp = true" class="nav-link">See Detailed Plan Comparison <i
              class="fa fa-angle-double-right" aria-hidden="true"></i>
          </span>
        </div>
        <div class="periods_container" *ngIf="!loading['plans']">
          <div class="header">
            Select Period
          </div>
          <div class="periods-body">
            <div class="period-container" (click)="selectPeriod(period)"
              [ngClass]="{selected: period.selected, not_selected: !period.selected}" *ngFor="let period of periods">
              <div>
                <div>{{ period['period'] }}</div>
              </div>
              <div>
                <div class="pricing_check_relative border-light-black" *ngIf="!period.selected"></div>
                <i class="fa fa-check-circle" aria-hidden="true" *ngIf="period.selected"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6" *ngIf="!loading['plans']">
          <button class="btn btn-primary-soft" *ngIf="!loading['renew']" (click)="renewPlan()">Renew</button>
          <img src="../assets/img/loading_2.svg" alt="loading scans" *ngIf="loading['renew']" class="wid-14" />
        </div>
      </ng-container>
      <div class="alert alert-success mt-6" role=" alert" *ngIf="revewed">
        Plan renewed successfully. Please <a routerLink="/login" class="nav-link">login</a>..
      </div>
    </div>
  </div>
  <div class="center customizedplans" *ngIf="!loading['plans'] && !revewed">
    <p>
      For more customized plans, Please contact
      <span class="nav-link">support@minfytech.com</span>
    </p>
  </div>
</section>

<div class="show-plans-compare-fixed" *ngIf="showPricingComp == true && !revewed">
  <div class="show-plans-compare">
    <div class="relative-container">
      <button class="btn btn-light" (click)="showPricingComp = false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <app-compare-plans [plans]="plans"></app-compare-plans>
    </div>
  </div>
</div>