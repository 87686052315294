<div class="card">
  <h5 class="card-header">
    Virtual Machines
  </h5>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of vmsHeader">{{ h.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vm of vms; let i = index">
          <td>{{ i + 1 }}</td>
          <td *ngFor="let h of vmsHeader">{{ vm[h.id] }}</td>
        </tr>
        <tr *ngIf="loading">
          <td [attr.colspan]="vmsHeader.length + 1" class='center'>
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
          </td>
        </tr>
        <tr *ngIf="!loading && vms.length == 0">
          <td [attr.colspan]="vmsHeader.length + 1" class='center'>
            <span>No Virtuals Machines found in <strong>{{ resourceGroup }}</strong> resource group. You can change the
              resource group on the top panel</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>