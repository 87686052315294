<div class="alert-screen">
    <div class="alert-container">
      <div class="card">
        <div class="card-header flex-between title">
          <div>Tell Me Rules</div>
          <div>
            <i class="fa fa-times cursor-pointer" (click)="hideModel.emit()"></i>
          </div>
        </div>
        <div class="card-body overflow-auto">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                class="nav-link capitalize"
                [ngClass]="{ active: i == 0 }"
                id="{{ type }}-tab"
                data-bs-toggle="tab"
                *ngFor="let type of getObjectKeys(types_v2); let i = index"
                [attr.data-bs-target]="'#' + type"
                type="button"
                role="tab"
                [attr.aria-controls]="type"
                aria-selected="true"
              >
                {{ types_v2[type]["name"] }}
              </button>
            </div>
          </nav>
          <div class="flex-start align-top">
            <div class="tab-content flex-1" id="nav-tabContent">
              <div
                *ngFor="let type of getObjectKeys(types_v2); let i = index"
                class="tab-pane fade show"
                [ngClass]="{ active: i == 0 }"
                id="{{ type }}"
                role="tabpanel"
                [attr.aria-labelledby]="type + '-tab'"
              >
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link capitalize"
                      [ngClass]="{ active: i == 0 }"
                      id="{{ tab }}-tab"
                      data-bs-toggle="tab"
                      *ngFor="
                        let tab of getObjectKeys(types_v2[type]['tabs']);
                        let i = index
                      "
                      [attr.data-bs-target]="'#' + tab"
                      type="button"
                      role="tab"
                      [attr.aria-controls]="tab"
                      aria-selected="true"
                    >
                      {{ types_v2[type]["tabs"][tab]["name"] }}
                    </button>
                  </div>
                </nav>
                <div class="flex-start align-top">
                  <div class="tab-content flex-1" id="nav-tabContent">
                    <div
                      *ngFor="
                        let tab of getObjectKeys(types_v2[type]['tabs']);
                        let i = index
                      "
                      class="tab-pane fade show"
                      [ngClass]="{ active: i == 0 }"
                      id="{{ tab }}"
                      role="tabpanel"
                      [attr.aria-labelledby]="tab + '-tab'"
                    >
                      <ng-container
                        *ngIf="types_v2[type]['tabs'][tab]['name'] == 'Security'"
                      >
                        <div class="card border-none">
                          <!--<h5 class="card-header"></h5>-->
                          <div class="card-header">Network</div>
  
                          <div class="card-body">
                            <div class="card-dply">
                              <div class="report-types-flex mb-10 card-postn">
                                <table class="table table-docs">
                                  <thead>
                                    <tr>
                                      <th clsas="max-width-90">Sl. No.</th>
                                      <th>Rule</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    *ngIf="
                                      tellMes['security'] &&
                                      tellMes['security']['items'].length > 0
                                    "
                                  >
                                    <tr
                                      *ngFor="
                                        let tellme of tellMes['security'][
                                          'items'
                                        ];
                                        let i = index
                                      "
                                    >
                                      <td class="max-width-90">{{ i + 1 }}</td>
                                      <td>
                                        {{tellme['item']}}
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody
                                    *ngIf="
                                      !tellMes['security'] ||
                                      tellMes['security']['items'].length == 0
                                    "
                                  >
                                    <tr>
                                      <td colspan="4" class="center">
                                        No Rules Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card border-none">
                          <!--<h5 class="card-header"></h5>-->
                          <div class="card-header">Database</div>
  
                          <div class="card-body">
                            <div class="card-dply">
                              <div class="report-types-flex mb-10 card-postn">
                                <table class="table table-docs">
                                  <thead>
                                    <tr>
                                      <th class="max-width-90">Sl. No.</th>
                                      <th>Rule</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    *ngIf="
                                      tellMes['database'] &&
                                      tellMes['database']['items'].length > 0
                                    "
                                  >
                                    <tr
                                      *ngFor="
                                        let tellme of tellMes['database'][
                                          'items'
                                        ];
                                        let i = index
                                      "
                                    >
                                      <td class="max-width-90">{{ i + 1 }}</td>
                                      <td>
                                        {{ tellme['item'] }}
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody
                                    *ngIf="
                                      !tellMes['database'] ||
                                      tellMes['database']['items'].length == 0
                                    "
                                  >
                                    <tr>
                                      <td class="center" colspan="4">
                                        No Rules Found
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="types_v2[type]['tabs'][tab]['name'] != 'Security'"
                      >
                        <div class="card border-none">
                          <!--<h5 class="card-header"></h5>-->
  
                          <div class="card-body">
                            <div class="card-dply">
                              <div class="report-types-flex mb-10 card-postn">
                                <table class="table table-docs">
                                  <thead>
                                    <tr>
                                      <th class="max-width-90">Sl. No.</th>
                                      <th>Rule</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="
                                        let tellme of tellMes[tab]['items'];
                                        let i = index
                                      "
                                    >
                                      <td class="max-width-90">{{ i + 1 }}</td>
                                      <td>
                                        {{ tellme['item'] }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  