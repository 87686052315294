import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import SearchData from "../../../data/searchData";
import { APIService } from "src/app/api/api.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.css"],
})
export class GlobalSearchComponent implements OnInit {
  constructor(private apiService: APIService, private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }
  @Output("hideModal") hideModal = new EventEmitter<boolean>();

  searchResults: any = [];
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  searchPrompts: any = [];
  showSearchPrompts: boolean = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.clearForm();
      this.searchPrompts = [
        `How can I create a ticket in ${this.whitelabelData['pd']['appName']}?`,
        `What are some of the salient features regarding maintenance inside ${this.whitelabelData['pd']['appName']}?`,
        `Elaborate some of the key security features provided by ${this.whitelabelData['pd']['appName']}`,
        `Give an overview of the global dashboard and explain its key features`,
        `Enumerate the ways in which ${this.whitelabelData['pd']['appName']} can help me optimize the usage of resources`,
        `Explain the key ways in which ${this.whitelabelData['pd']['appName']} handles Governance`,
        `Explain the core operations that can be performed via ${this.whitelabelData['pd']['appName']}`,
        `Give a list of the services that come under the Database category`,
      ];
    }, 100);
  }
  searchValue: string = "";
  searchData: any = SearchData;

  stopPropogation(event: any, clear: boolean = false) {
    event.stopPropagation();
    if (clear) {
      this.searchValue = "";
    }
  }

  replaceUrls() {
    const paragraph = this.document;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = paragraph.match(urlRegex);
    if (urls) {
      urls.forEach((url: any) => {
        if (url.endsWith(".") || url.endsWith(",")) {
          url = url.slice(0, -1);
        }
        if (!this.document.includes(`>${url}</`)) {
          this.document = this.document.replaceAll(
            url,
            `<a href='${url}' target='_blank'>${url}</a>`
          );
        }
      });
    }
  }

  updatePrompt(event: any, prompt: string, generate: boolean) {
    event.stopPropagation();
    this.searchValue = prompt;
    if (generate) {
      this.lastSearchedValue = null;
      this.generateAPI();
    }
  }

  filterPrompts() {
    if (this.searchValue == "") {
      return this.searchPrompts;
    }
    return this.searchPrompts.filter((prompt: string) => {
      return prompt.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1;
    });
  }

  clearForm(event: any = null) {
    if (event) {
      event.stopPropagation();
    }

    this.searchValue = "";
    this.showAskMe = false;

    let element: any = document.querySelector(".search-input");
    element.focus();
  }

  filterResults() {
    if (this.searchValue == "") {
      return [];
    }
    let count: number = 0;
    return this.searchData["items"].filter((search: any) => {
      let check: boolean = false;
      if (count < 5) {
        for (let i = 0; i < search.keys.length; i++) {
          if (search.keys[i].indexOf(this.searchValue.toLowerCase()) > -1) {
            check = true;
            count = count + 1;
          }
        }
      } else {
        return false;
      }
      return check;
    });
  }

  loading: boolean = false;
  generating: boolean = false;
  displayed: any = "";
  document: any = "";

  format() {
    let tableHTML: string = `<table class='table table-docs'><thead><tr>[[ thead ]]</tr></thaed><tbody>[[ tbody ]]</tbody></table>`;
    let replacable: string = ``;
    let tableStarted: boolean = false;
    this.document.split("\n").forEach((lineItem: any, index: number) => {
      if (
        lineItem.replaceAll(" ", "").startsWith("|--") &&
        lineItem.replaceAll(" ", "").endsWith("--|")
      ) {
        replacable += lineItem + "\n";
        return;
      }
      if (
        lineItem.replaceAll(" ", "").startsWith("|") &&
        lineItem.replaceAll(" ", "").endsWith("|")
      ) {
        if (!tableStarted) {
          lineItem
            .split("|")
            .slice(1, -1)
            .forEach((th: string) => {
              tableHTML = tableHTML.replace(
                "[[ thead ]]",
                `<th>${th.trim()}</th>[[ thead ]]`
              );
            });
        } else {
          tableHTML = tableHTML.replace(
            "[[ tbody ]]",
            `<tr>${lineItem
              .split("|")
              .slice(1, -1)
              .map((td: string) => {
                return `<td>${td.trim()}</td>`;
              })
              .join("")}</tr>[[ tbody ]]`
          );
        }
        replacable += lineItem + "\n";
        tableStarted = true;
      } else {
        if (tableStarted) {
          tableHTML = tableHTML.replace("[[ thead ]]", "");
          tableHTML = tableHTML.replace("[[ tbody ]]", "");
          // console.log(tableHTML);
        }
        if (replacable != "") {
          this.document = this.document.replace(replacable, tableHTML);
          replacable = "";
        }
        tableStarted = false;
        tableHTML = `<table class='table table-docs'><thead><tr>[[ thead ]]</tr></thaed><tbody>[[ tbody ]]</tbody></table>`;
      }
    });
    if (tableStarted) {
      tableHTML = tableHTML.replace("[[ thead ]]", "");
      tableHTML = tableHTML.replace("[[ tbody ]]", "");

      // console.log(tableHTML);
      if (replacable != "") {
        replacable = replacable.slice(0, -1);
        this.document = this.document.replace(replacable, tableHTML);
        replacable = "";
      }
      tableStarted = false;
      tableHTML = `<table><thead><tr>[[ thead ]]</tr></thaed><tbody>[[ tbody ]]</tbody></table>`;
    }
    this.replaceUrls();
    this.document = this.document.replaceAll("\n", "<br>");
    this.generating = true;
    this.generate();
  }

  interval: any = null;
  inputChange() {
    if (this.interval) {
      clearTimeout(this.interval);
    }

    let temp: string = this.searchValue;
    this.interval = setTimeout(() => {
      if (
        temp != this.searchValue ||
        !this.interval ||
        this.searchValue == this.lastSearchedValue
      ) {
        clearTimeout(this.interval);
        return;
      }
      this.generateAPI();
    }, 3000);
  }

  generate() {
    let appended: number = 0;
    this.displayed = "";
    let total: string[] = this.document.trim().split(" ");
    // this.generating = true;
    let temp: string = this.searchValue;
    let interval = setInterval(() => {
      if (
        appended > total.length ||
        !this.generating ||
        temp != this.searchValue
      ) {
        clearInterval(interval);
        this.generating = false;
        return;
      }
      if (total[appended] != undefined) {
        this.displayed += total[appended] + " ";
      }
      appended++;
      let element: any = document.querySelector(".result-generate");
      element.scrollTop = element.scrollHeight;
    }, 50);
  }

  getString(text: string) {
    // console.log(text);

    if (text.includes("***")) {
      return text.slice(text.indexOf("***") + 3);
    } else {
      return text;
    }
  }

  segregateText(text: string, param: string) {
    return text.split(param);
  }

  lastSearchedValue: string = "";
  showAskMe: boolean = false;
  async generateAPI() {
    if (
      this.searchValue.trim().length <= 10 ||
      this.lastSearchedValue == this.searchValue
    ) {
      this.showAskMe = false;
      this.generating = false;
      return;
    }
    this.loading = true;
    this.showAskMe = true;
    this.lastSearchedValue = this.searchValue;
    let apiURL: string = `https://apis-eks.swayam.cloud/global_notification/search/?email_id=ramit.surana%40minfytech.com&category=minfylabs&search_query_input=${this.searchValue.toLowerCase()}&user_type=client`;

    let header: any = {
      Authorization: `Bearer ${localStorage.getItem("t")}`,
    };

    let result: any = await this.apiService.postDataPromis(apiURL, {}, header);

    if (result.doc_url) {
      let s3Object: any = await this.apiService.getDataPromis(
        result.doc_url,
        {},
        {}
      );

      this.document = s3Object["Search_Response"];
      // console.log(this.document);

      this.format();
    }
    this.loading = false;
  }
}
