import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { DashService } from "src/app/dash/dash.service";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./ec2.details.view.component.html",
  selector: "ec2-details-view",
  styleUrls: ["ec2.details.view.component.css"],
})
export class ec2DetailsViewComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild("instance_more_details") instanceView: ElementRef;

  private last: MouseEvent;
  private el: HTMLElement;

  private mouseDown: boolean = false;

  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  p: any = null;
  startY: any;
  startHeight: any;
  @Input() instance?: any;
  @Input() cloudPartner?: any;
  @Input("tabs") inputActiveTabs: string[] = [];
  activeTabs: string[] = [];
  ec2InstanceDetails: any = {
    type: "details",
    data: {},
    loading: true,
  };
  @Output() hideModal = new EventEmitter<boolean>();
  @Output() instanceStartStop = new EventEmitter<any>();
  @Output() addMonitorObj = new EventEmitter<any>();

  funRef: any;
  sgDetailsList: any = null;
  accountId: any;
  regionId: any;
  currentMessage: any;
  filterText = "";
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  tagsHeader: any = [
    {
      id: "Key",
      name: "Key",
    },
    {
      id: "Value",
      name: "Value",
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.instance) {
      this.ec2InstanceDetails = {
        type: "details",
        data: {},
        loading: true,
      };
      this.sgDetailsList = null;
      if (this.pageLoaded && this.cloudPartner == 'aws') {
        this.loadDetails();
        this.loadInstanceMetrics();
      } else {
        this.loading = false;
      }
    }
  }
  pageLoaded: boolean = false;

  resize: any = null;
  ngOnInit(): void {
    if (this.inputActiveTabs) {
      this.activeTabs = this.inputActiveTabs;
      if (this.activeTabs.length > 0) {
        this.changeTab(this.activeTabs[0]);
      }
    }
    this.p = document.querySelector(".instance_more_details");

    this.funRef = {};
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });

    if(this.cloudPartner == 'aws') {
      this.loadDetails();
      this.loadInstanceMetrics();
    } else {
      this.instance['cpu'] = this.instance['avg_cpu_utilization']
      this.loading = false;
    }
    this.pageLoaded = true;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  loading: boolean = true;
  instanceData: any;
  async loadDetails() {
    this.loading = true;
    let selectedClientData = JSON.parse(localStorage.getItem("clientList"));
    let client_id;
    try {
      client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem("selectedClientList");
      })["clientIdList"];
    } catch (err) {
      client_id = localStorage.getItem("clientId");
    }
    let data = {
      action: "list_ec2",
      client_id: client_id,
      account_id: this.accountId,
      region_name: this.regionId,
      module: "operations/ec2-bottom",
      resource_id: this.instance.instanceId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2v2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.instanceData = result.instances[0];
    }

    // if (result.status == '1' || result.s == '1') {
    //   let dt = result.securityGroupLists;
    //   this.sgDetailsList = dt.map((sg: any) => {
    //     return sg.groupId;
    //   });
    // } else {
    //   this.notifier.alert('Info', '', result.error, 'info', 5000);
    // }
    this.loading = false;
  }

  showTagsModal: any;
  hideMdoal(event: any) {
    this.showTagsModal = null;
  }

  manageTags() {
    this.showTagsModal = this.instance;
  }

  changeTab(event: any) {
    this.sgDetailsList = null;
    this.ec2InstanceDetails["type"] = event;
    if (this.ec2InstanceDetails["type"] == "security") {
      this.fetchSGList();
    }
  }

  removeDetailsView(event: any) {
    this.instanceView.nativeElement.classList.add("remove-details-view");
    setTimeout(() => {
      this.hideModal.emit(true);
    }, 200);
  }

  checkForBU() {
    return localStorage.getItem("emsConfig");
  }

  initDragEC2(event: any) {
    this.mouseDown = true;
    let m_pos: any = event.y;
    let h = this.p.offsetHeight;
    this.resize = (e: any) => {
      const dy = m_pos - e.y;
      this.p.style.height = parseInt(h + dy) + "px";
    };

    document.addEventListener("mousemove", this.resize, false);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", this.resize, false);
      },
      false
    );
  }

  checkForTabs(value: string) {
    if (this.activeTabs.indexOf(value) > -1 || this.activeTabs.length == 0) {
      return true;
    }
    return false;
  }

  //@HostListener('mousemove', ['$event'])
  //onMouseMove(event: MouseEvent) {
  //  if (this.mouseDown) {
  //    this.instanceView.nativeElement.style.height =
  //      this.startHeight - event.clientY + this.startY + 'px';
  //  }
  //}

  //@HostListener('mouseup')
  //onMouseUp() {
  //  //this.instanceView.nativeElement.style.userSelect = 'auto';
  //  //this.instanceView.nativeElement.style.transition = '0.2s';
  //  this.mouseDown = false;
  //  document.removeEventListener("mousemove", this.resize, false);
  //}

  async fetchSGList() {
    if(this.cloudPartner == 'aws') {
      this.sgDetailsList = this.instanceData.SecurityGroups.map((sg: any) => {
        return sg.GroupId;
      });
    } else {
      this.sgDetailsList = this.instance.SecurityGroups;
    }
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }

  async loadInstanceMetrics() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const account = selectedAccountData.data;
        if (this.instance.tagName == "") {
          this.instance["hide_matrics"] = true;
          return;
        }

        this.instance["cpu"] = "LOADING";
        this.instance["ram"] = "LOADING";
        this.instance["disk"] = "LOADING";

        let account_: any = localStorage.getItem("emsConfig");

        if (account_) {
          account_ = JSON.parse(account_);
        }

        let data = {
          action: "ec2_metrics",
          account_id: this.accountId,
          region_name: this.regionId,
          origin: account_ ? "swayam" : "minfy",
          zaaid: account_
            ? account_["zaid"]
            : account.site_247_project_id || "123456789",
          instance_name: this.instance.tagName,
          login_user_name: this.userId,
          instance_id: this.instance.instanceId,
        };
        let header = {
          Authorization: localStorage.getItem("t"),
        };
        let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == "1" || result.s == "1") {
          this.instance["cpu"] = result.cpu || "-";
          this.instance["ram"] = result.memory || "-";
          this.instance["disk"] = result.disk || "-";
          this.instance["from"] = result.from || "AWS Cloud Watch";
        } else {
          this.notifier.alert(
            "Info",
            "",
            result.error_message || result.message,
            "info",
            5000
          );
          this.instance["cpu"] = "-";
          this.instance["ram"] = "-";
          this.instance["disk"] = "-";
          this.instance["from"] = "AWS Cloud Watch";
        }
      } else {
        this.instance["hide_matrics"] = true;
      }
    } catch (err) {
      console.log(err);

      this.instance["hide_matrics"] = true;
    }
  }

  addMonitor() {
    this.addMonitorObj.emit(this.instance);
  }

  stopInstance() {
    this.instanceStartStop.emit("stop");
  }

  startInstance() {
    this.instanceStartStop.emit("start");
  }

  async load() {}
}
