import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { Router } from "@angular/router";

declare let Jhxlsx: any;

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.css"],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input("user") user: any;
  @Output("hideModal") hideModal: any = new EventEmitter<boolean>();
  userDetails: any = {};
  userId: string = localStorage.getItem("eId");
  ut: string = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  userList: any = [];

  currentPage: string = "details";

  selectedUser: boolean = false;
  filterText: string = "";

  funRef: any = {
    selectUser: this.selectUser,
  };

  loading: any = {
    details: false,
    users: false,
  };

  userTypes: any = {
    userAdmin: "Admin",
    user: "General",
    superAdmin: "Master Admin",
    undefined: "User",
  };

  updateUser: any = null;

  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin" ||
    this.isUserAdmin;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private navigator: Router
  ) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(JSON.stringify(this.user));
    if (this.userDetails["userType"] != "superAdmin") {
      this.loadUserDetails();
    }
    if (
      this.user["userType"] == "userAdmin" ||
      this.user["userType"] == "superAdmin"
    ) {
      this.loadUsers();
    }
  }

  viewAudit() {
    this.navigator.navigate(['/dash/settings/audittrail'], {queryParams: {user_id: this.userDetails['emaiId'], source: 'user_details'}})
  }

  async sendResendLink(user: any) {
    this.loading["resend"] = true;
    let data: any = {
      action: "resendRegistrationLink",
      clientEmailID: user["emaiId"],
      clientName: user["clientName"],
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/resendlink`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      this.notifier.alert("Success", "", result.msg, "success", -1);
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    this.loading["resend"] = false;
  }

  filteredUserList() {
    if (this.filterText == "") {
      return this.userList;
    } else {
      return this.userList.filter((user: any) => {
        return (
          user["clientName"].toLowerCase().indexOf(this.filterText) > -1 ||
          user["emaiId"].toLowerCase().indexOf(this.filterText) > -1
        );
      });
    }
  }

  async closeModal(event: boolean) {
    // this.selectedUser = false;
    await this.loadUserDetails(false);

    let check: boolean =
      this.updateUser["userType"] != this.userDetails["userType"];
    this.updateUser = null;
    // this.userDetails = JSON.parse(JSON.stringify(this.user));
    if (check) {
      this.selectedUser = false;
      this.userDetails = this.user;
      this.currentPage = "users";
      this.loadUsers();
    } else {
      this.currentPage = "details";
      if (this.userDetails["userType"] == "userAdmin") {
        this.loadUsers();
      }
    }
  }

  setUpdateUser() {
    this.updateUser = JSON.parse(JSON.stringify(this.userDetails));
  }

  async loadUserDetails(clear: boolean = true) {
    if (clear) {
      this.updateUser = null;
    }
    this.loading["details"] = true;
    let data = {
      clientId: this.userDetails["emaiId"],
      action: "fetchSingleClientUsersList",
      masterClientId: this.userDetails["masterClientId"],
      ut: this.urlPrefix,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string;
    if (this.urlPrefix == "admin") {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/invite`;
    } else {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/invite`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      this.userDetails = { ...this.userDetails, ...result.cl[0] };
    } else {
      this.notifier.alert("Info", "", result.msg, "info", 5000);
    }

    this.loading["details"] = false;
  }

  userHeaders: any = [
    {
      id: "clientName",
      name: "Client Name",
    },
    { id: "emaiId", name: "Email ID", click: "selectUser" },
    {
      id: "mobileNumber",
      name: "Phone Number",
    },
    {
      id: "accountGroup",
      name: "AWS Account Groups",
    },
  ];

  adminUsersheader: any = [
    {
      id: "userName",
      name: "User Name",
    },
    {
      id: "userId",
      name: "User ID",
    },
    {
      id: "cmpName",
      name: "Company Name",
    },
    {
      id: "phNumber",
      name: "Phone Number",
    },
    {
      id: "accountGroup",
      name: "Account Groups",
    },
  ];

  export() {
    var dict: any = {
      sheetName: "Budgets",
      data: [],
    };
    let headers: any = [
      { text: "Sl. No." },
      { text: "Client Name" },
      { text: "Email ID" },
      { text: "Phone Number" },
      { text: "AWS Account Groups" },
      { text: "User Type" },
      { text: "Created By" },
    ];
    dict.data.push(headers);
    this.filteredUserList().forEach((user: any, index: number) => {
      let data = [];
      data.push({ text: index + 1 });
      data.push({ text: user["clientName"] });
      data.push({ text: user["emaiId"] });
      data.push({ text: user["mobileNumber"] });
      data.push({
        text:
          user["accountDetailsList"] && user["accountDetailsList"].length > 0
            ? user["accountDetailsList"]
                .map((account: any) => {
                  return account["accountId"];
                })
                .join(", ")
            : "-",
      });
      data.push({ text: this.userTypes[user["userType"]] });
      data.push({ text: user["masterClientId"] });
      dict.data.push(data);
    });
    var options = {
      fileName: `Users - ${this.userId}`,
    };
    Jhxlsx.export([dict], options);
  }

  disableUser: string = null;
  async switchStatus(event: boolean) {
    this.disableUser = null;
    if (!event) {
      return;
    }
    this.loading["status"] = true;
    let data: any = {
      clientId: this.userDetails["emaiId"],
      action: "statusChange",
      ut: this.urlPrefix,
      masterClientId: this.userId,
      accountStatus:
        this.userDetails["accountStatus"] == "active" ? "inActive" : "active",
      userType:
        this.userDetails["userType"] == "superAdmin" ? "admin" : "userAdmin",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/${
      this.urlPrefix == "client" ? "invite" : "list"
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      this.userDetails["accountStatus"] =
        this.userDetails["accountStatus"] == "active" ? "inActive" : "active";
      this.notifier.alert("Success", "", result.m, "success", 5000);
    } else {
      this.notifier.alert("Info", "", result.error || result.m, "info", 5000);
    }

    this.loading["status"] = false;
  }

  async loadUsers() {
    this.userList = [];
    this.loading["users"] = true;
    let data: any = {
      clientId: this.userDetails["emaiId"],
      action: "fetchAllClientUsers",
      ut: this.urlPrefix,
      userType:
        this.userDetails["userType"] == "superAdmin" ? "admin" : "userAdmin",
    };

    if (this.urlPrefix == "admin") {
      data = { userId: this.userId };
    }

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/${
      this.urlPrefix == "client" ? "invite" : "list"
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      this.userList = result.cl || result.details;
      this.userList = this.userList.map((user: any) => {
        if (this.urlPrefix == "admin") {
          user["accountGroup"] = user.accountGroup.join(", ");
        } else {
          user["accountGroup"] = user.accountDetailsList
            .map((account: any) => {
              return account.accountId;
            })
            .join(", ");
        }
        return user;
      });
    }

    this.loading["users"] = false;
  }

  close(event: any) {
    if (this.selectedUser) {
      this.currentPage = "details";
      this.selectedUser = false;
      this.userDetails = this.user;
      if (this.userDetails["userType"] != "superAdmin") {
        this.loadUserDetails();
      }
      this.loadUsers();
      return;
    }
    this.hideModal.emit(event);
  }

  selectUser(ref: any, value: any) {
    ref.selectedUser = true;
    ref.userDetails = value;
    ref.currentPage = "details";

    ref.loadUserDetails();

    if (ref.userDetails["userType"] == "userAdmin") {
      ref.loadUsers();
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  ngOnDestroy(): void {}
}
