import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import * as echarts from "echarts";
import * as moment from "moment";
declare let window: any;

@Component({
  selector: "analytics-billing",
  templateUrl: "analytics.component.html",
  styleUrls: ["./analytics.component.css"],
})
export class AnalyticsBillingComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;

  funRef: any;
  @Input("activeAccount") activeAccount: any;
  @Output("hideModal") hideModal = new EventEmitter<boolean>();
  accountId: any;
  regionId: any;
  currentMessage: any;
  show: boolean = false;
  monthCount: string = "3";
  view: string = "overview";
  graphType: string = "services";
  monthlyData: any = [];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    await this.fetchBillingData();
    await this.fetchSummaryData();
    // await this.loadOverview();

    // this.loadGraphSpendOverview();
  }

  loadGraphSpendAnalyze() {
    let chartDom = document.getElementById("analytics_analyze_graph");
    let myChart = echarts.init(chartDom);
    let option: any = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: this.monthlyData.map((data: any) => {
            return data.monthYear;
          }),
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "Amount",
          type: "bar",
          barWidth: "60%",
          data: this.monthlyData.map((data: any) => {
            return data.totalBill;
          }),
        },
      ],
    };

    option && myChart.setOption(option);
  }

  spendOverViewDate: any;
  loadGraphSpendOverview() {
    let chartDom = document.getElementById("analytics_overview_graph");
    let myChart = echarts.init(chartDom);

    let data: any;
    if (this.graphType == "services") {
      if (!this.serviceWiseCost) {
        data = [];
      } else {
        data =
          this.serviceWiseCost.find((date: any) => {
            return date.monthYear == this.activeAccount.currentMonth;
          }) || this.serviceWiseCost[0];
        if (data && data.serviceList) {
          this.spendOverViewDate = data.monthYear;
          console.log(data);
          data = data.serviceList
            .sort((a: any, b: any) => {
              return Number(a.serviceCost) > Number(b.serviceCost);
            })
            .slice(0, 5);
        } else {
          this.spendOverViewDate = "";
        }
      }
    } else {
      data = [
        {
          serviceCost:
            this.activeAccount.account.totalcost ||
            this.activeAccount.account.totalCost,
          serviceName: `${this.activeAccount.account.accname || ""} (${
            this.activeAccount.account.lid
          })`,
        },
      ];
    }
    let option: any = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
      },
      series: [
        {
          name: "Spend Overview",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "40",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data
            ? data.map((date: any) => {
                return {
                  value: Number(date.serviceCost),
                  name: date.serviceName,
                };
              })
            : [],
        },
      ],
    };

    option && myChart.setOption(option);
  }

  serviceWiseCost: any = [];
  async fetchSummaryData() {
    this.notifier.loading(true);
    let data = {
      lid: this.activeAccount.account.lid,
      monthCount: this.monthCount,
      action: "getServiceWiseTotalCost",
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinganalyticalreports`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.serviceWiseCost = result.billDetailsList;
      this.loadGraphSpendOverview();
      this.loadOverview();
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }

  async fetchBillingData() {
    this.notifier.loading(true);
    let data = {
      lid: this.activeAccount.account.lid,
      monthCount: this.monthCount,
      action: "getTotalCost",
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinganalyticalreports`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.msg == "Data not found") {
        this.monthlyData = [];
      } else {
        this.monthlyData = result.billDetailsList;
      }
      this.loadGraphSpendAnalyze();
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }

  hideModel() {
    this.hideModal.emit();
  }

  loadOverview() {
    console.log(
      '$("#analytics_analyze_current_spend_graph").parent().width()',
      $(".rightcontent").width()
    );

    $("#analytics_analyze_current_spend_graph").width(
      $(".rightcontent").width() - 50
    );

    var chartDom = document.getElementById(
      "analytics_analyze_current_spend_graph"
    );
    var myChart = echarts.init(chartDom);

    let data: any;
    let services: any = {};
    if (!this.serviceWiseCost) {
      data = [];
    } else {
      services = {};
      data = [];
      for (let i = 0; i < this.serviceWiseCost.length; i++) {
        data.push(this.serviceWiseCost[i].monthYear);
        this.serviceWiseCost[i].serviceList
          .sort((a: any, b: any) => {
            return Number(a.serviceCost) > Number(b.serviceCost);
          })
          .slice(0, 5)
          .forEach((element: any) => {
            if (services[element.serviceName]) {
              services[element.serviceName]["serviceCost"].push(
                Number(element.serviceCost)
              );
            } else {
              services[element.serviceName] = {
                serviceName: element.serviceName,
                serviceCost: [Number(element.serviceCost) || 0],
              };
            }
          });
      }
    }

    let option: any = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {},
      xAxis: [
        {
          type: "category",
          data: data,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: Object.keys(services).map((service: any) => {
        return {
          name: services[service].serviceName,
          type: "bar",
          barWidth: 50,
          stack: "Services",
          emphasis: {
            focus: "series",
          },
          data: services[service].serviceCost,
        };
      }),
    };

    option && myChart.setOption(option);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
