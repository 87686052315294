import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";

@Component({
  selector: "app-view-os-tab",
  templateUrl: "./view-os-tab.component.html",
  styleUrls: ["./view-os-tab.component.css"],
})
export class ViewOsTabComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() insList: any = [];
  @Input() regionHeader:any =[];
  isapp2=false;
  constructor(public notifier: NotifierService) {}
  isapp=false;
  ngOnInit(): void {
    this.notifier.disableScroll();
  }

  no() {
    this.hideModal.emit({ status: 0 });
  }

  setHovered(id: number, value: boolean): void {
    const index = this.insList.findIndex((item:any) => item.id === id);
    if (index !== -1) {
      this.insList[index].isHovered = value;
    }
  }
  showapp(){
    this.isapp = !this.isapp;
  }
  showapp2(){
    this.isapp2 = !this.isapp2;
  }

  toggleItem(item: any): void { item.isHidden = !item.isHidden; } 

  formatType(type:any):string{
    if(type === 'intel_instances'){
      return 'Intel';
    }else if(type === 'amd_instances'){
      return 'AMD';
    }else if(type === 'graviton_intances'){
      return 'Graviton';
    }
    else{
      return '';
    }
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
  }
}
