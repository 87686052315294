<div class="confirmContainer">
  <div class="confirmBox">
    <div class="d-flex justify-content-between">
      <span class="table-title">Instances Per Region</span>
      <button class="sortBtn" (click)="no()">
        <i class="fa-solid fa-xmark crossicon"></i>
      </button>
    </div>

    <div class="KBBody table-responsive">
      <!-- <table class="table table-hover  mb-0"> -->

      <!-- <tbody class="scrollable-body"> -->
      <!-- <ng-container
        *ngFor="let inst of instancesTypes"
        class="scrollable-body mb-0"
      >
        <div class="card-header acc">
          <b> {{ inst.id }} </b>
        </div>
        <div *ngIf="inst.instance.length != 0">
          <ng-container *ngFor="let ins of inst.instance">
            <div class="card mt-1">
              <div class="card-header d-flex justify-content-between" *ngIf="ins.key">
                <b> {{ ins.key }} </b> <b class="mright-3">All Regions</b>
              </div>
              <div class="card-body">
                <table class="table table-hover m-0 p-0">
                  <thead class="blue fixed-header">
                    <tr>
                      <td scope="col">S.N.</td>
                      <td scope="col">Instances Type</td>
                      <td class="text-center" scope="col">Instance Count</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of ins.value; let j = index">
                      <td>{{ j + 1 }}</td>
                      <td>{{ item.type }}</td>
                      <td class="text-center">{{ item.size }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="card mt-1" *ngIf="inst.instance.length == 0">
          <div class="card-body">
            <table class="table table-hover m-0 p-0">
              <thead class="blue fixed-header">
                <tr>
                  <td scope="col">S.N.</td>
                  <td scope="col">Instances Type</td>
                  <td class="text-center">Instance Count</td>
                </tr>
              </thead>
              <tbody>
                <tr class="m-2">
                  <td class="text-center" colspan="3">No Data Found!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container> -->



      <ng-container class="table-responsive">
        
         
         
        <table class="table  ble-hover  m-0 p-0">
          <thead class="blue fixed-header table-header-sticky">
            <tr>
              <td scope="col" class="hsticky"></td>
              <ng-container *ngFor="let header of regionHeader;">
              <td class="text-center" scope="col">{{header}}</td>
            </ng-container>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let inst of instancesTypes;" class="scrollable-body mb-0">
            <tr class="pointer" (click)="toggleItem(inst)">
              <td  class="sticky bold"><span><i [class]="inst.isHidden ? 'fa-regular fa-square-minus' : 'fa-regular fa-square-plus'"></i></span>&nbsp;   {{inst.id}} </td>
              
            </tr>
            <ng-container *ngIf=inst.isHidden>
              <ng-container *ngFor="let reg of inst.regionData;">
                <tr class="pointer" (click)="toggleItem(reg)">
                  <td  class="sticky bold sub"><span><i [class]="reg.isHidden ? 'fa-regular fa-square-minus' : 'fa-regular fa-square-plus'"></i></span>&nbsp;   {{reg.type}} </td>
                  
                </tr>
             <ng-container *ngIf=reg.isHidden>
                 <tr *ngFor="let rr of reg.dataValues">
                      <td class="sticky hsub">{{rr.insType}}</td>
                      <ng-container *ngFor="let pp of rr.values">
                        <td class="text-center" [ngClass]="{'bold2': pp != 0}" >{{pp}}</td>
                      </ng-container>
                 </tr>
             </ng-container>
              <!-- <td class="sticky mr-2">{{formatType(reg.type)}}</td>
              <ng-container *ngFor="let val of reg.values;">
              <td class="text-center">{{val}}</td>
            </ng-container> -->
            
          </ng-container>
          </ng-container>
        </ng-container>
         
          </tbody>
        </table>
    
    
  </ng-container>














      <!-- <ng-container *ngFor="let i of instancesTypes;">
                    <tr *ngFor="let value of i[key]>
                    <td>{{key}}</td>
                   
                  </tr>
                  </ng-container>            -->
      <!-- </tbody>  -->
      <!-- </table> -->

      <!-- <div class="btnHold">
                <div class="bhold">
                  <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
                  <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
                  [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
                </div>
              </div> -->

      <!-- <div class="KBActions">
              <button class="btn btn-light" (click)="no()">Cancel</button>
            </div> -->
    </div>
  </div>
</div>
