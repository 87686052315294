<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">
      Delete Lens
    </div>
    <div class="KBBody">
      Are you sure you want to delete the lens <strong>{{ lens['arn'] }}</strong>?
      <div class="mt-2" *ngIf="lens['workloads'].length > 0">
        <div class="alert alert-warning" role="alert">
          Lens is associated with following workloads
          <ul>
            <li *ngFor="let workload of lens['workloads']">
              {{ workload }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="KBActions">
      <button class="btn btn-danger-soft" *ngIf="!loading" (click)="deleteLens()">
        Delete
      </button>
      <button class="btn btn-danger-soft" *ngIf="loading" [attr.disabled]="true">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="width-mar-18"
        />
        Delete
      </button>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>