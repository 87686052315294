import { Component } from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-tell-me-history",
  templateUrl: "./tell-me-history.component.html",
  styleUrls: ["./tell-me-history.component.css"],
})
export class TellMeHistoryComponent {
  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  showact = false;
  showrem = false;
  showFilter = false;

  isSecurityActive = true;
  isGovActive = false;
  isDspendActive = false;
  isDgovActive = false;
  isDcostActive = false;

  highAlertTab = false;
  mediumAlertTab = false;
  lowAlertTab = false;

  securityRes: any = [];
  governanceRes: any = [];
  dailyspendRes: any = [];
  dailygovRes: any = [];
  dailyCostRes: any = [];

  filterdailyspend: any = [];
  filtersecurity: any = [];
  filtergovernance: any = [];
  filtercost: any = [];

  currentPage: number = 1;
  itemsPerPage: number = 10;

  idList: any = [];
  idListSec: any = [];
  idListGov: any = [];
  idListCost: any = [];
  idListDailyGov: any = [];

  isSecurityres = true;
  isGovres = true;
  isDailyCostRes = true;
  isDailySpendRes = true;
  searchKeyword: any = "";
  names: any = {
    security: "Security",
    governance: "Governance",
    database: "Database",
    dailySpend: "Daily Spend",
    dailyGovernance: "Daily Governance",
    dailyCost: "DailyCost",
  };

  securityAlerts: any = {
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  };
  governanceAlerts: any = {
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  };
  dailyspendAlerts: any = {
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  };
  dailycostAlerts: any = {
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  };
  clickComplete = false;
  clickIncomplete = false;
  clickHighAlert = false;
  clickMedAlert = false;
  clickDailyAlert=false;
  clickEveryAlert=false;
  clickLowAlert = false;
  isfre = false;
  iscre = false;
  secAll = false;
  govAll = false;
  dsAll = false;
  dcAll = false;
  openTickets: any;
  loaderPage: any;
  openTicketmsg: string;

  securityDone=false;
  govDone=false;
  spendDone=false;
  
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    // this.openTickets = document.querySelector(".openTicket"); 
    // this.loaderPage = document.querySelector(".main-loader");
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
       this.notifier.loading(true);
        this.accountId = d.value;
        this.isSecurityActive = true;
        this.isGovActive = false;
        this.isDspendActive = false;
        this.isDgovActive = false;
        this.isDcostActive = false;
        this.regionId = localStorage.getItem("regionId");
        this.idList = [];
        this.idListSec = [];
        this.idListGov = [];
        this.idListCost = [];
        this.idListDailyGov = [];
        this.securityDone=false;
        this.govDone=false;
        this.spendDone=false;
        this.load();
      } else if (d.key == "regionId") {
       this.notifier.loading(true);
        this.regionId = d.value;
        this.isSecurityActive = true;
        this.isGovActive = false;
        this.isDspendActive = false;
        this.isDgovActive = false;
        this.isDcostActive = false;
        this.accountId = localStorage.getItem("accountId");
        this.idList = [];
        this.idListSec = [];
        this.idListGov = [];
        this.idListCost = [];
        this.idListDailyGov = [];
        this.securityDone=false;
        this.govDone=false;
        this.spendDone=false;
        this.load();
      }
    });
  }

  load() {
   //this.showLoader();
    this.fetchHistory();
    // this.fetchGov();
    // this.fetchDailySpend();
    this.fetchDailyGov();
    this.fetchDailyCost();
  }

  async fetchHistory() {
    this.securityDone=false;
    this.idListSec = [];
    this.secAll = false;
    this.securityAlerts.high = 0;
    this.securityAlerts.medium = 0;
    this.securityAlerts.low = 0;
    this.securityAlerts.total = 0;
    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      operation: "fetchScanList",
      module: "Security",
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

    try {
      let result: any = await this.apiServer.postDataPromis(
        apiURL,
        data,
        headers
      );
      if (
        result.scanList == "1" ||
        (result.status == "1" && result["scanList"].length !== 0)
      ) {
        this.isSecurityres = true;
        this.securityRes = result["scanList"];
    //    this.filtersecurity = result["scanList"];
        let count = 0;
        this.securityRes.forEach((element: any) => {
          element["check"] = false;
          element["eventTime"] = this.formatDate(element["eventTime"]);
          if (element.createdBy === "") {
            element["createdBy"] = "Swayam";
          }
          // if(element.severity === " "){
          //   element["severity"] = "High";
          // }
          if (element.status === "complete") {
            count = count + 1;
          }
        });

        if (count === this.securityRes.length) {
          this.isSecurityres = false;
        }

        this.securityRes = this.securityRes.filter(
          (item: any) => item.status !== "complete"
        );

        this.securityRes.sort(
          (a: any, b: any) =>
            new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime()
        );
        this.securityRes.forEach((item: any) => {
          if (item.severity && item.status === "incomplete") {
            switch (item.severity.toLowerCase()) {
              case "high":
                this.securityAlerts.high++;
                break;
              case "medium":
                this.securityAlerts.medium++;
                break;
              case "low":
                this.securityAlerts.low++;
                break;
              default:
                break;
            }
            this.securityAlerts.total++;
          }
        });
        this.filtersecurity = this.securityRes;
      } else {
        this.isSecurityres = false;
      }
    } catch (error) {
      this.isSecurityres = false;
    }
    this.securityDone=true;
    this.checkProcessComplete();
  }

  get totalPages(): number {
    return Math.ceil(this.securityRes.length / this.itemsPerPage);
  }
  getItemsForPage(pageNumber: number): any[] {
    const startIndex = (pageNumber - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.securityRes.slice(startIndex, endIndex);
  }

  navigateToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  navigateToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  // getPageNumbers(): number[] {
  //   return Array(this.totalPages)
  //     .fill(0)
  //     .map((_, i) => i + 1);
  // }
  navigateToPage(page: number | '...') { if (page !== '...') { this.currentPage = page as number; } } 
  getPageNumbers(): (number | "...")[] {
    const totalPageCount = this.totalPages;
    const maxVisiblePages = 5;
    let start = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
    let end = Math.min(start + maxVisiblePages - 1, totalPageCount);
    start = Math.max(1, end - maxVisiblePages + 1);
    const pageNumbers: (number | "...")[] = [];
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    if (start > 1) {
      pageNumbers.unshift("...");
    }
    if (end < totalPageCount) {
      pageNumbers.push("...");
    }
    return pageNumbers;
  }

  async fetchGov() {
    this.govDone=false;
    this.idListGov = [];
    this.govAll = false;
    this.governanceAlerts.high = 0;
    this.governanceAlerts.medium = 0;
    this.governanceAlerts.low = 0;
    this.governanceAlerts.total = 0;
    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      operation: "fetchScanList",
      module: "Governance",
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );
    if (
      result.s == "1" ||
      (result.status == "1" && result["scanList"].length !== 0) ||
      this.dailygovRes.length !== 0
    ) {
      this.governanceRes = result["scanList"];
      this.governanceRes = this.governanceRes.concat(this.dailygovRes);

      // console.log(JSON.stringify(this.dailygovRes));
     // this.filtergovernance = this.governanceRes;
      let count = 0;
      this.governanceRes.forEach((element: any) => {
        element["check"] = false;
        element["eventTime"] = this.formatDate(element["eventTime"]);
        if (element.createdBy === "" || element.createdBy === "Swayam") {
          element["createdBy"] = "minfymsopssession";
        }
        // if(element.severity === " "){
        //   element["severity"] ="High";
        // }
        if (element.status === "complete") {
          count = count + 1;
        }
      });
      this.isGovres = true;
      if (count === this.governanceRes.length) {
        this.isGovres = false;
      }
      this.governanceRes = this.governanceRes.filter(
        (item: any) => item.status !== "complete"
      );

      this.governanceRes.sort(
        (a: any, b: any) =>
          new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime()
      );
      this.governanceRes.forEach((item: any) => {
        if (item.severity && item.status === "incomplete") {
          switch (item.severity.toLowerCase()) {
            case "high":
              this.governanceAlerts.high++;
              break;
            case "medium":
              this.governanceAlerts.medium++;
              break;
            case "low":
              this.governanceAlerts.low++;
              break;
            default:
              break;
          }
          this.governanceAlerts.total++;
        }
      });
      this.filtergovernance = this.governanceRes;
    } else {
      this.isGovres = false;
    }
    this.govDone=true;
    this.checkProcessComplete();
  }

  async fetchDailySpend() {
    this.spendDone=false;
    this.idList = [];
    this.dsAll = false;
    this.dailyspendAlerts.high = 0;
    this.dailyspendAlerts.medium = 0;
    this.dailyspendAlerts.low = 0;
    this.dailyspendAlerts.total = 0;
    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      operation: "fetchScanList",
      module: "DailySpend",
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );
    if (
      result.s == "1" ||
      (result.status == "1" && result["scanList"].length !== 0) ||
      this.dailyCostRes !== 0
    ) {
      this.isDailySpendRes = true;
      this.dailyspendRes = result["scanList"];
      this.dailyspendRes = this.dailyspendRes.concat(this.dailyCostRes);
     // this.filterdailyspend = this.dailyspendRes;
      let count = 0;
      this.dailyspendRes.forEach((element: any) => {
        element["check"] = false;
        element["eventTime"] = this.formatDate(element["eventTime"]);
        if (element.createdBy === "" || element.createdBy === "Swayam") {
          element["createdBy"] = "-";
        }
        if (element.status === "complete") {
          count = count + 1;
        }
      });

      if (count === this.dailyspendRes.length) {
        this.isDailySpendRes = false;
      }
      this.dailyspendRes = this.dailyspendRes.filter(
        (item: any) => item.status !== "complete"
      );
      this.dailyspendRes.sort(
        (a: any, b: any) =>
          new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime()
      );
      this.dailyspendRes.forEach((item: any) => {
        if (item.severity && item.status === "incomplete") {
          switch (item.severity.toLowerCase()) {
            case "high":
              this.dailyspendAlerts.high++;
              break;
            case "medium":
              this.dailyspendAlerts.medium++;
              break;
            case "low":
              this.dailyspendAlerts.low++;
              break;
            default:
              break;
          }
          this.dailyspendAlerts.total++;
        }
      });
      this.filterdailyspend = this.dailyspendRes;
    } else {
      this.isDailySpendRes = false;
    }
    this.spendDone=true;
    this.checkProcessComplete();
  }

  async fetchDailyGov() {
    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      operation: "fetchScanList",
      module: "DailyGovernance",
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );
    if (result.s == "1" || result.status == "1") {
      this.dailygovRes = result["scanList"];
      this.dailygovRes.forEach((item: any) => {
        item.type = "dailyGov";

        if (!item.severity || item.severity.trim() === "" || item.identifier.trim() === "") {
          item.status = "complete";
        }
      });
      // this.dailygovRes.forEach((item:any) => { if (!item.severity || item.severity.trim() === '') { item.status = 'complete'; } });

      this.fetchGov();
    }
  }

  async fetchDailyCost() {
    this.idListCost = [];
    this.dcAll = false;
    this.dailycostAlerts.high = 0;
    this.dailycostAlerts.medium = 0;
    this.dailycostAlerts.low = 0;
    this.dailycostAlerts.total = 0;
    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      operation: "fetchScanList",
      module: "DailyCost",
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );
    if (result.s == "1" || result.status == "1") {
      this.dailyCostRes = result["scanList"];
      this.dailyCostRes.forEach((item: any) => {
        item.type = "dailyCost";

        if (!item.severity || item.severity.trim() === "") {
          item.status = "complete";
        }
      });
      this.fetchDailySpend();

      // this.isDailyCostRes = true;
      // this.dailyCostRes = result["scanList"];
      // this.filtercost = result["scanList"];
      // let count = 0;
      // this.dailyCostRes.forEach((element: any) => {
      //   element["check"] = false;
      //   element["eventTime"] = this.formatDate(element["eventTime"]);
      //   if (element.createdBy === "") {
      //     element["createdBy"] = "Swayam";
      //   }
      //   if (element.status === "complete") {
      //     count = count + 1;
      //   }
      // });

      // if (count === this.dailyCostRes.length) {
      //   this.isDailyCostRes = false;
      // }
      // this.dailyCostRes = this.dailyCostRes.filter(
      //   (item: any) => item.status !== "complete"
      // );
      // this.dailyCostRes.forEach((item: any) => {
      //   if (item.severity && item.status === "incomplete") {
      //     switch (item.severity.toLowerCase()) {
      //       case "high":
      //         this.dailycostAlerts.high++;
      //         break;
      //       case "medium":
      //         this.dailycostAlerts.medium++;
      //         break;
      //       case "low":
      //         this.dailycostAlerts.low++;
      //         break;
      //       default:
      //         break;
      //     }
      //     this.dailycostAlerts.total++;
      //   }
      // });
      // this.notifier.loading(false);
    }
    // else {
    //   this.isDailyCostRes = false;
    //   this.notifier.loading(false);
    // }
  }
  showaction() {
    this.showact = !this.showact;
  }
  showreminder() {
    this.showrem = !this.showrem;
  }
  showFilte() {
    this.showFilter = !this.showFilter;
  }

  switchTab(tab: any) {
    this.clickHighAlert = false;
    this.clickMedAlert = false;
    this.clickDailyAlert=false;
    this.clickEveryAlert=false;
    // const securitytab = document.getElementById("security-tab");
    // const govtab = document.getElementById("gov-tab");
    // const dsTab = document.getElementById("ds-tab");
    // const dgTab = document.getElementById("dgov-tab");
    // const dcTab = document.getElementById("dc-tab");
    if (tab === "security") {
      if (!this.isSecurityActive) {
        this.isSecurityActive = true;
        this.isGovActive = false;
        this.isDspendActive = false;
        this.isDcostActive = false;
        this.isDgovActive = false;
        // govtab.classList.remove("active-tab");
        // dsTab.classList.remove("active-tab");
        // dgTab.classList.remove("active-tab");
        // dcTab.classList.remove("active-tab");
        // securitytab.classList.add("active-tab");
      }
    } else if (tab === "governance") {
      if (!this.isGovActive) {
        this.isGovActive = true;
        this.isSecurityActive = false;
        this.isDspendActive = false;
        this.isDcostActive = false;
        this.isDgovActive = false;
        // securitytab.classList.remove("active-tab");
        // dsTab.classList.remove("active-tab");
        // dgTab.classList.remove("active-tab");
        // dcTab.classList.remove("active-tab");
        // govtab.classList.add("active-tab");
      }
    } else if (tab === "dailyspend") {
      if (!this.isDspendActive) {
        this.isDspendActive = true;
        this.isGovActive = false;
        this.isSecurityActive = false;
        this.isDcostActive = false;
        this.isDgovActive = false;
        // securitytab.classList.remove("active-tab");
        // govtab.classList.remove("active-tab");
        // dgTab.classList.remove("active-tab");
        // dcTab.classList.remove("active-tab");
        // dsTab.classList.add("active-tab");
      }
    } else if (tab === "dailygovernance") {
      if (!this.isDgovActive) {
        this.isDgovActive = true;
        this.isDspendActive = false;
        this.isGovActive = false;
        this.isSecurityActive = false;
        this.isDcostActive = false;
        // securitytab.classList.remove("active-tab");
        // govtab.classList.remove("active-tab");
        // dsTab.classList.remove("active-tab");

        // dcTab.classList.remove("active-tab");
        // dgTab.classList.add("active-tab");
      }
    } else if (tab === "dailycost") {
      if (!this.isDcostActive) {
        this.isDcostActive = true;
        this.isDgovActive = false;
        this.isDspendActive = false;
        this.isGovActive = false;
        this.isSecurityActive = false;

        // securitytab.classList.remove("active-tab");
        // govtab.classList.remove("active-tab");
        // dsTab.classList.remove("active-tab");

        // dgTab.classList.remove("active-tab");
        // dcTab.classList.add("active-tab");
      }
    }
  }

  toUpperCase(str: string): string {
    return str.toUpperCase();
  }

  showHighAlert() {
    this.highAlertTab = true;
  }
  hideHighAlert(event: Event) {
    this.highAlertTab = null;
  }
  showMediumAlert() {
    this.mediumAlertTab = true;
  }
  hideMedAlert(event: Event) {
    this.mediumAlertTab = null;
  }
  showLowAlert() {
    this.lowAlertTab = true;
  }
  hideLowAlert(event: Event) {
    this.lowAlertTab = null;
  }

  getIdList(event: any) {
    this.idList = event;
  }

  getIdListdc(event: any) {
    this.idListCost = event;
  }

  getidGov(event: any) {
    this.idListGov = event;
  }

  getIdDailyGov(event: any) {
    this.idListDailyGov = event;
  }

  getIdListSec(item: any) {
    item.check = !item.check;
    if (item.check) {
      this.idListSec.push(item.id);
    } else {
      this.idListSec = this.idListSec.filter((id: any) => id !== item.id);
    }
    // if (!this.idListSec.includes(id)) { this.idListSec.push(id); }
  }

  async markAsComplete() {
    if (this.isDspendActive) {
      if (this.idList.length !== 0 || this.idListCost.length !== 0) {
        this.clickComplete = true;
        this.clickIncomplete = false;
        this.notifier.loading(true);
        this.isDspendActive = false;
        let headers: any = {
          Authorization: localStorage.getItem("t"),
        };
        let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;
        let result: any = [];
        let resultcost: any = [];
        if (this.idList.length !== 0) {
          let data: any = {
            accountId: this.accountId,
            region: this.regionId,
            operation: "updateStatus",
            module: "DailySpend",
            id: this.idList,
          };

          result = await this.apiServer.postDataPromis(apiURL, data, headers);
        }

        if (this.idListCost.length !== 0) {
          let datacost: any = {
            accountId: this.accountId,
            region: this.regionId,
            operation: "updateStatus",
            module: "DailyCost",
            id: this.idListCost,
          };

          resultcost = await this.apiServer.postDataPromis(
            apiURL,
            datacost,
            headers
          );
        }

        this.isDspendActive = true;
        let dsres = false;
        let dcres = false;
        if (this.idList.length !== 0) {
          if (result.s == "1" || result.status == "1") {
            dsres = true;
          }
        }
        if (this.idListCost.length !== 0) {
          if (resultcost.s == "1" || resultcost.status == "1") {
            dcres = true;
          }
        }
        if (dsres || dcres) {
          await this.fetchDailyCost();
          this.notifier.loading(false);
        } else {
          this.notifier.loading(false);
        }
      }
    } else if (this.isSecurityActive) {
      if (this.idListSec.length !== 0) {
        this.clickComplete = true;
        this.clickIncomplete = false;
        this.notifier.loading(true);
        let headers: any = {
          Authorization: localStorage.getItem("t"),
        };

        let data: any = {
          accountId: this.accountId,
          region: this.regionId,
          operation: "updateStatus",
          module: "Security",
          id: this.idListSec,
        };

        let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

        let result: any = await this.apiServer.postDataPromis(
          apiURL,
          data,
          headers
        );
        if (result.s == "1" || result.status == "1") {
          await this.fetchHistory();
          this.notifier.loading(false);
        } else {
          this.notifier.loading(false);
        }
      }
    } else if (this.isGovActive) {
      if (this.idListGov.length !== 0 || this.idListDailyGov.length !== 0) {
        this.clickComplete = true;
        this.clickIncomplete = false;
        this.notifier.loading(true);
        this.isGovActive = false;
        let headers: any = {
          Authorization: localStorage.getItem("t"),
        };
        let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;
        let result: any = [];
        let result2: any = [];
        if (this.idListGov.length !== 0) {
          let data: any = {
            accountId: this.accountId,
            region: this.regionId,
            operation: "updateStatus",
            module: "Governance",
            id: this.idListGov,
          };

          result = await this.apiServer.postDataPromis(apiURL, data, headers);
        }

        if (this.idListDailyGov.length !== 0) {
          let govdata: any = {
            accountId: this.accountId,
            region: this.regionId,
            operation: "updateStatus",
            module: "DailyGovernance",
            id: this.idListDailyGov,
          };

          result2 = await this.apiServer.postDataPromis(
            apiURL,
            govdata,
            headers
          );
        }

        this.isGovActive = true;
        let govres = false;
        let dgovres = false;
        if (this.idListGov.length !== 0) {
          if (result.s == "1" || result.status == "1") {
            govres = true;
          }
        }
        if (this.idListDailyGov.length !== 0) {
          if (result2.s == "1" || result2.status == "1") {
            dgovres = true;
          }
        }

        if (govres || dgovres) {
          await this.fetchDailyGov();
          this.notifier.loading(false);
        } else {
          this.notifier.loading(false);
        }
      }
    } else if (this.isDcostActive) {
      if (this.idList.length !== 0) {
        this.clickComplete = true;
        this.clickIncomplete = false;
        this.notifier.loading(true);
        this.isDcostActive = false;
        let headers: any = {
          Authorization: localStorage.getItem("t"),
        };

        let data: any = {
          accountId: this.accountId,
          region: this.regionId,
          operation: "updateStatus",
          module: "DailyCost",
          id: this.idListCost,
        };

        let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

        let result: any = await this.apiServer.postDataPromis(
          apiURL,
          data,
          headers
        );
        this.isDcostActive = true;
        if (result.s == "1" || result.status == "1") {
          await this.fetchDailySpend();
          this.notifier.loading(false);
        } else {
          this.notifier.loading(false);
        }
      }
    }
  }
  markAsIncomplete() {
    this.clickComplete = false;
    this.clickIncomplete = true;
  }

  filterAlerts(severity: any) {
    this.showFilter = !this.showFilter;
    if (this.isSecurityActive) {
      if (severity == "High") {
        this.clickMedAlert = false;
        this.clickHighAlert = true;
        this.clickLowAlert = false;
        if (this.securityRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) { 
        this.securityRes.sort((a: any, b: any) => {
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "Medium" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "Medium" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      } else if (severity == "Medium") {
        this.clickMedAlert = true;
        this.clickHighAlert = false;
        this.clickLowAlert = false;
        if (this.securityRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.securityRes.sort((a: any, b: any) => {
          // const sw: any = { Low: 1, Medium: 3, High: 2 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "High" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "High" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      }
      else if (severity == "Low") {
        this.clickMedAlert = false;
        this.clickHighAlert = false;
        this.clickLowAlert = true;
        if (this.securityRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.securityRes.sort((a: any, b: any) => {
          // const sw: any = { Low: 3, Medium: 2, High: 1 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "Medium" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "Medium" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      }
    } else if (this.isGovActive) {
      console.log(JSON.stringify(this.governanceRes));
      if (severity == "High") {
        this.clickMedAlert = false;
        this.clickHighAlert = true;
        this.clickLowAlert = false;
        if (this.governanceRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.governanceRes.sort((a: any, b: any) => {
          // const sw: any = { Low: 1, Medium: 2, High: 3 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "Medium" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "Medium" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      } else if (severity == "Medium") {
        this.clickMedAlert = true;
        this.clickHighAlert = false;
        this.clickLowAlert = false;
        if (this.governanceRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.governanceRes.sort((a: any, b: any) => {
          // const sw: any = { Low: 1, Medium: 3, High: 2 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "High" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "High" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      } 
      else if (severity == "Low") {
        this.clickMedAlert = false;
        this.clickHighAlert = false;
        this.clickLowAlert = true;
        if (this.governanceRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.governanceRes.sort((a: any, b: any) => {
       //   const sw: any = { Low: 3, Medium: 2, High: 1 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "Medium" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "Medium" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      }
      else if (severity == "daily") {
        this.clickDailyAlert=true;
        this.clickEveryAlert=false;
        this.governanceRes = this.governanceRes.sort((a: any, b: any) => {
          const typeA = a.type || "";
          const typeB = b.type || "";
          if (typeA === "dailyGov" && typeB !== "dailyGov") {
            return -1;
          } else if (typeA !== "dailyGov" && typeB === "dailyGov") {
            return 1;
          } else {
            return 0;
          }
        });
      } else if (severity == "every") {
        this.clickDailyAlert=false;
        this.clickEveryAlert=true;
        this.governanceRes = this.governanceRes.sort((a:any, b:any) => {
          const typeA = a.type || "";
          const typeB = b.type || "";
          if (!typeA && typeB) {
            return -1;
          } else if (typeA && !typeB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    } else if (this.isDspendActive) {
      if (severity == "High") {
        this.clickMedAlert = false;
        this.clickHighAlert = true;
        this.clickLowAlert = false;
        if (this.dailyspendRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.dailyspendRes.sort((a: any, b: any) => {
          // const sw: any = { Low: 1, Medium: 2, High: 3 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "Medium" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "Medium" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      } else if (severity == "Medium") {
        this.clickMedAlert = true;
        this.clickHighAlert = false;
        this.clickLowAlert = false;
        if (this.dailyspendRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.dailyspendRes.sort((a: any, b: any) => {
          // const sw: any = { Low: 1, Medium: 3, High: 2 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "High" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "High" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      }
      else if (severity == "Low") {
        this.clickMedAlert = false;
        this.clickHighAlert = false;
        this.clickLowAlert = true;
        if (this.dailyspendRes.some((alert:any) => alert.severity === "High" || alert.severity === "Medium" || alert.severity === "Low")) {
        this.dailyspendRes.sort((a: any, b: any) => {
          // const sw: any = { Low: 3, Medium: 2, High: 1 };
          // const sA = sw[a.severity];
          // const sB = sw[b.severity];
          const sA = a.severity ? (severity === a.severity ? 3 : a.severity === "Medium" ? 2 : 1) : 0; 
          const sB = b.severity ? (severity === b.severity ? 3 : b.severity === "Medium" ? 2 : 1) : 0; 
          return sB - sA;
        });
      }
      }
      else if (severity == "daily") {
        this.clickDailyAlert=true;
        this.clickEveryAlert=false;
        this.dailyspendRes = this.dailyspendRes.sort((a: any, b: any) => {
          const typeA = a.type || "";
          const typeB = b.type || "";
          if (typeA === "dailyCost" && typeB !== "dailyCost") {
            return -1;
          } else if (typeA !== "dailyCost" && typeB === "dailyCost") {
            return 1;
          } else {
            return 0;
          }
        });
      } else if (severity == "every") {
        this.clickDailyAlert=false;
        this.clickEveryAlert=true;
        this.dailyspendRes = this.dailyspendRes.sort((a:any, b:any) => {
          const typeA = a.type || "";
          const typeB = b.type || "";
          if (!typeA && typeB) {
            return -1;
          } else if (typeA && !typeB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    } else if (this.isDcostActive) {
      if (severity == "High") {
        this.clickMedAlert = false;
        this.clickHighAlert = true;
        this.clickLowAlert = false;
        this.dailyCostRes.sort((a: any, b: any) => {
          const sw: any = { Low: 1, Medium: 2, High: 3 };
          const sA = sw[a.severity];
          const sB = sw[b.severity];
          return sB - sA;
        });
      } else if (severity == "Medium") {
        this.clickMedAlert = true;
        this.clickHighAlert = false;
        this.clickLowAlert = false;
        this.dailyCostRes.sort((a: any, b: any) => {
          const sw: any = { Low: 1, Medium: 3, High: 2 };
          const sA = sw[a.severity];
          const sB = sw[b.severity];
          return sB - sA;
        });
      }
      else if (severity == "Low") {
        this.clickMedAlert = false;
        this.clickHighAlert = false;
        this.clickLowAlert = true;
        this.dailyCostRes.sort((a: any, b: any) => {
          const sw: any = { Low: 3, Medium: 2, High: 1 };
          const sA = sw[a.severity];
          const sB = sw[b.severity];
          return sB - sA;
        });
      }
    }
  }

  formatDate(date: any): string {
    if (date !== "") {
      //  const inputData = moment(date, "MMM DD, YYYY hh:mm A", true);
      return moment(date).format("MMM DD, YYYY hh:mm A");
    }
    return "";
  }

  // markAll() {
  //   this.securityRes.forEach((element: any) => {
  //     element.check = !element.check;
  //   });
  // }

  markAll(): void {
    this.secAll = !this.secAll;
    const areAllSelected = this.securityRes.every((item: any) => item.check);
    this.securityRes.forEach((item: any) => {
      item.check = !areAllSelected;
      if (
        !areAllSelected &&
        !this.idListSec.includes(item.id) &&
        item.status === "incomplete"
      ) {
        this.idListSec.push(item.id);
      } else if (areAllSelected) {
        this.idListSec = this.idListSec.filter((id: any) => id !== item.id);
      }
    });
  }

  // filteredData = this.data.slice();

  searchData() {
    if (this.isSecurityActive) {
      if (this.searchKeyword.trim() === "") {
        this.securityRes=[];
        this.securityRes = this.filtersecurity;
      } else {
        const matchingItems = this.securityRes.filter(
          (item: any) =>
            item.description
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.eventTime
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.severity
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.createdBy
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.identifier
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())
        );
        // const nonMatchingItems = this.dailyspendRes.filter(
        //   (item:any) =>
        //     !item.description.toLowerCase().includes(this.searchKeyword.toLowerCase())
        // );
        this.securityRes = matchingItems;
      }
    } else if (this.isDspendActive) {
      if (this.searchKeyword.trim() === "") {
        this.dailyspendRes = this.filterdailyspend;
      } else {
        const matchingItems = this.dailyspendRes.filter(
          (item: any) =>
            item.description
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.eventTime
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.severity
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.createdBy
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.identifier
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())
        );
        // const nonMatchingItems = this.dailyspendRes.filter(
        //   (item:any) =>
        //     !item.description.toLowerCase().includes(this.searchKeyword.toLowerCase())
        // );
        this.dailyspendRes = matchingItems;
      }
    } else if (this.isGovActive) {
      if (this.searchKeyword.trim() === "") {
        this.governanceRes = this.filtergovernance;
      } else {
        const matchingItems = this.governanceRes.filter(
          (item: any) =>
            item.description
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.eventTime
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.severity
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.createdBy
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.identifier
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())
        );
        // const nonMatchingItems = this.dailyspendRes.filter(
        //   (item:any) =>
        //     !item.description.toLowerCase().includes(this.searchKeyword.toLowerCase())
        // );
        this.governanceRes = matchingItems;
      }
    } else if (this.isDcostActive) {
      if (this.searchKeyword.trim() === "") {
        this.dailyCostRes = this.filtercost;
      } else {
        const matchingItems = this.dailyCostRes.filter(
          (item: any) =>
            item.description
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.eventTime
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.severity
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.createdBy
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())
        );
        // const nonMatchingItems = this.dailyspendRes.filter(
        //   (item:any) =>
        //     !item.description.toLowerCase().includes(this.searchKeyword.toLowerCase())
        // );
        this.dailyCostRes = matchingItems;
      }
    }
  }
  highlightSearch(description: string): string {
    if (this.searchKeyword.trim() !== "") {
      const regex = new RegExp(`(${this.searchKeyword})`, "gi");

      return description.replace(
        regex,
        '<span style="font-weight: bold;">$1</span>'
      );
    }
    return description;
  }

  showfre(event: Event) {
    event.preventDefault();
    this.isfre = !this.isfre;
  }

  showcre() {
    this.iscre = !this.iscre;
  }

  async refresh() {
    if (this.isSecurityActive) {
      this.notifier.loading(true);
      await this.fetchHistory();
      this.notifier.loading(false);
    } else if (this.isGovActive) {
      this.notifier.loading(true);
      this.clickHighAlert = false;
      this.clickMedAlert = false;
      this.clickDailyAlert=false;
      this.clickEveryAlert=false;
      await this.fetchDailyGov();
      this.notifier.loading(false);
    } else if (this.isDspendActive) {
      this.notifier.loading(true);
      await this.fetchDailyCost();
      this.notifier.loading(false);
    }
  }

// showLoader(){
//   this.openTicketmsg='';
//   this.loaderPage.classList.remove("d-none");
//   this.openTickets.classList.remove("custom-loader-100");
//   this.openTickets.classList.remove("d-none");

//   setTimeout(() => {
//     this.openTickets.classList.add("custom-loader-25");
//     this.openTicketmsg='25%';

//     setTimeout(() => {
//       this.openTickets.classList.remove("custom-loader-25");
//       this.openTickets.classList.add("custom-loader-50");
//       this.openTicketmsg='50%';

//       setTimeout(() => {
//         this.openTickets.classList.remove("custom-loader-50");
//         this.openTickets.classList.add("custom-loader-75");
//         this.openTicketmsg='75%';
//       }, 100);
//     }, 100);
//   }, 100);

  
// }

checkProcessComplete(){
  if(this.securityDone && this.govDone && this.spendDone){
    setTimeout(() => {
      this.notifier.loading(false);
    }, 1000);
   
    // this.openTickets.classList.remove("custom-loader-75");
    //     this.openTickets.classList.add("custom-loader-100");
    //     this.openTicketmsg='100%';

    //     setTimeout(() => {
    //       // this.loaderPage.classList.add("d-none");
    //       // this.openTickets.classList.add("d-none");
    //     }, 900);
  }
}

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
