<div class="enable-cur-screen" *ngIf="!enableCUR">
  <div class="enable-cur-container">
    <div class="header-section">
      <div class="title">Configure CUR</div>
      <div class="description">
        Configure CUR in your AWS Account with {{ whitelabelData['pd']['appName'] }} to get near real time billing reports and alerts
      </div>
    </div>
    <div class="features-section">
      <div class="flex-center">
        <div class="title-center">Features</div>
      </div>
      <div class="feature flex-start">
        <div class="feature-icon-container">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg" alt="">
        </div>
        <div>
          <div class="title">Daily Cost Variance</div>
          <div>Get daily near real time cost variance report</div>
        </div>
      </div>
      <div class="feature flex-start">
        <div class="feature-icon-container">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg" alt="">
        </div>
        <div>
          <div class="title">Monthly Bill</div>
          <div>Get monthly cost variance report</div>
        </div>
      </div>
      <div class="feature flex-start">
        <div class="feature-icon-container">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg" alt="">
        </div>
        <div>
          <div class="title">Budgets</div>
          <div>Limit your costs by setting budgets in {{ whitelabelData['pd']['appName'] }}</div>
        </div>
      </div>
      <div class="feature flex-start">
        <div class="feature-icon-container">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg" alt="">
        </div>
        <div>
          <div class="title">Email Notifications</div>
          <div>Get in-depth daily cost variance report through email</div>
        </div>
      </div>
    </div>
    <div class="cur-actions flex-end">
      <button class="btn btn-light btn-primary-soft" (click)="enableCUR = true">
        How to Enable?
      </button>
      <button class="btn btn-light" (click)="hideModal.emit(false)">
        Cancel
      </button>
    </div>
  </div>
</div>
<app-steps-enable-cur *ngIf="enableCUR" [data]="data" (hideModel)="enableCUR = false"></app-steps-enable-cur>