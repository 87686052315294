import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-certificate',
  templateUrl: './request-certificate.component.html',
  styleUrls: ['./request-certificate.component.css'],
})
export class RequestCertificateComponent implements OnInit {
  @Input('requestCertificate') requestCertificate: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  new: boolean = false;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}
  loading: boolean = false;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  keyAlorithm: string = 'RSA';

  addAlternateName(): void {
    this.requestCertificate.subject_alternate_names.push({ name: '' });
  }

  removeAlternateName(index: number): void {
    this.requestCertificate.subject_alternate_names.splice(index, 1);
  }

  ngOnInit(): void {
    this.requestCertificate = JSON.parse(
      JSON.stringify(this.requestCertificate)
    );
    if (this.requestCertificate.domain_name != '') {
      this.new = false;
    } else {
      this.new = true;
    }
    this.requestCertificate.subject_alternate_names =
      this.requestCertificate.subject_alternate_names.map((name: any) => {
        return { name: name };
      });
  }

  async requestCert() {
    this.loading = true;
    let data: any = this.requestCertificate;

    data = JSON.parse(JSON.stringify(data));

    data['subject_alternate_names'] = data['subject_alternate_names'].map(
      (name: any) => {
        return name['name'];
      }
    );

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/operations/acm`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        'Certificate Requested Successfully',
        'success',
        5000
      );
      this.hideModel.emit(this.requestCertificate.domain_name);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.loading = false;
  }
}
