import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as echarts from "echarts";
@Component({
  selector: "app-lens-overview",
  templateUrl: "./lens-overview.component.html",
  styleUrls: ["./lens-overview.component.css", "./../../warquiz.component.css"],
})
export class LensOverviewComponent implements OnInit {
  @Input("loading") loading: any;
  @Input("lenses") lenses: any;
  @Input("pillers") pillers: any;
  @Input("questions") questions: any;
  @Input("workload") workload: any;
  @Input("selectedLens") selectedLens: any;
  @Input("isMilestone") isMilestone: any;
  @Input("filter") filter: any;

  @Output("selectPiller") selectPiller = new EventEmitter<string>();
  @Output("showQuestions") showQue = new EventEmitter<string>();

  loaded: boolean = false;
  lensesData: any = {
    softwareasaservice: "SaaS Lens",
    wellarchitected: "AWS Well-Architected Framework",
    serverless: "Serverless Lens",
    foundationaltechnicalreview: "FTR Lens",
    "arn:aws:wellarchitected::aws:lens/sap": "SAP Lens",
  };
  pillersData: any = {
    costOptimization: "Cost Optimization",
    operationalExcellence: "Operational Excellence",
    performance: "Performance",
    reliability: "Reliability",
    security: "Security",
    sustainability: "Sustainability",
  };
  colors: any = [
    "#556ec7",
    "#93cb77",
    "#edcb5e",
    "#73c0de",
    "#bc76c5",
    "#fe8353",
  ];

  constructor() {}

  filterQuestions(
    value: any = undefined,
    type: string = undefined,
    answered: boolean = false,
    piller?: any,
    lens?: any
  ) {
    return this.questions.filter((question: any) => {
      if (type) {
        if (answered) {
          if (type == "PillarId") {
            return (
              question[type] == value &&
              question["Risk"] != "UNANSWERED" &&
              question["lensId"] == this.selectedLens
            );
          }

          return question[type] == value && question["Risk"] != "UNANSWERED";
        } else {
          if (type == "PillarId") {
            return (
              question[type] == value && question["lensId"] == this.selectedLens
            );
          }
          return question[type] == value;
        }
      } else {
        return question.PillarId == piller && question.lensId == lens;
      }
    });
  }

  fetchTotalAnsweredQuestions() {
    return this.questions.filter((question: any) => {
      return (
        question["Risk"] != "UNANSWERED" &&
        question["lensId"] == this.selectedLens
      );
    });
  }

  fetchUnansweredQuestions(PillarId: string = null) {
    return this.questions.filter((question: any) => {
      return (
        question["Risk"] == "UNANSWERED" &&
        question["lensId"] == this.selectedLens &&
        question["PillarId"] == PillarId
      );
    });
  }

  fetchAnsweredQuestions(PillarId: string = null) {
    return this.questions.filter((question: any) => {
      return (
        question["Risk"] != "UNANSWERED" &&
        question["lensId"] == this.selectedLens &&
        question["PillarId"] == PillarId
      );
    });
  }

  fetchNoImprovementQuestions(PillarId: string = null) {
    return this.questions.filter((question: any) => {
      return (
        question["Risk"] == "NONE" &&
        question["lensId"] == this.selectedLens &&
        question["PillarId"] == PillarId
      );
    });
  }

  checkIfAnswered(question: any) {
    return (
      question["SelectedChoices"].length > 0 ||
      !question["IsApplicable"] ||
      question["ChoiceAnswerSummaries"].filter((choice: any) => {
        return choice["Status"] == "NOT_APPLICABLE";
      }).length ==
        question["ChoiceAnswerSummaries"].length - 1
    );
  }

  checkQuestionIsComplianced(question: any) {
    if (question["IsApplicable"] == false) {
      return false;
    }
    if (this.checkIfAnswered(question)) {
      if (question["SwayamChoices"] && question["SwayamChoices"].length > 0) {
        // let check = false;
        let failedOptions = question["SwayamChoices"].filter((choice: any) => {
          return !(
            choice["evidenceStatus"] != undefined ||
            (choice["evidence"] && choice["evidence"].length == 0) ||
            choice["evidence"] == undefined ||
            choice["source"] == "Manual" ||
            (choice["evidence"] &&
              choice["evidence"].length > 0 &&
              choice["evidence"].filter((evidence: any) => {
                return (
                  evidence["status"] || evidence["message"] == "Swayam Findings"
                );
              }).length == choice["evidence"].length)
          );
        });

        let unselectedOption = question["Choices"].filter((choice: any) => {
          return (
            question["SelectedChoices"].indexOf(choice["ChoiceId"]) <= -1 &&
            !choice["ChoiceId"].endsWith("_no") &&
            question["ChoiceAnswerSummaries"].find((choice_: any) => {
              return (
                choice_["ChoiceId"] == choice["ChoiceId"] &&
                choice_["Status"] != "NOT_APPLICABLE"
              );
            })
          );
        });

        return failedOptions.length > 0 || unselectedOption.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  fetchQuestionsRisks(risk: string, PillarId: string = null) {
    return this.filterQuestions(this.selectedLens, "lensId", false).filter(
      (question: any) => {
        if (PillarId && question["PillarId"] != PillarId) {
          return false;
        }
        return question["Risk"] == risk.toUpperCase();
      }
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loaded = true;
    }, 100);
    this.filter["questions"] = "All";
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes["lenses"]) {
      // console.log(this.lenses);
      this.lenses = this.lenses.map((lens: any, index: number) => {
        return { lens: lens, color: this.colors[index] };
      });
    }
    if (changes["pillers"]) {
      // console.log(this.pillers);
      this.pillers = this.pillers.map((piller: string, index: number) => {
        if (!this.pillersData[piller]) {
          this.pillersData[piller] = piller;
        }
        // console.log("pillerdata",this.pillersData);
        return { piller: piller, color: this.colors[index] };
      });
    }
    if (changes["questions"]) {
      // console.log("workload",this.workload);
      // console.log("selectedlens",this.selectedLens);
  
      setTimeout(() => {
        this.loaded = true;
        this.loadGraph();
      }, 100);
    }
  }

  showQuestions(piller: string) {
    this.showQue.emit(piller);
  }

  loadGraph() {
    // console.log("puillers in lens overview",this.pillers);
    // console.log("selected pillers",this.showQue);
    this.pillers.forEach((piller: any) => {
      let _graph_container:any = document.querySelector(`.${piller["piller"]}_graph_container`);
      // console.log("filter questions",this.filterQuestions(piller["piller"], "PillarId", true).lenght, piller.piller);
      if(this.filterQuestions(piller["piller"], "PillarId", false).length == 0){
        _graph_container.style.display = "none";
      return;
      }
      else{
        _graph_container.style.display = "block";
  
      }

      var chartDom = document.getElementById(`${piller["piller"]}graph`);
      var myChart = echarts.init(chartDom);

      myChart.on("click", (params: any) => {
        this.filter["questions"] = params["name"];
        this.selectPiller.emit(piller["piller"]);
      });

      let option = {
        tooltip: {
          formatter: (params: any) => {
            return `
              <div class='flex-start'>
                <div>${params["name"]}</div>
                <div class='graph-text-bold'>${params["value"]}</div>
              </div>
            `;
          },
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Type",
            type: "pie",
            radius: "50%",
            color: ["rgb(212, 209, 209)", "rgb(238, 102, 102)", "#58c388"],
            data: [
              this.fetchUnansweredQuestions(piller["piller"]).length > 0
                ? {
                    value: this.fetchUnansweredQuestions(piller["piller"])
                      .length,
                    name: "Unanswered",
                  }
                : undefined,
              this.filterQuestions(piller["piller"], "PillarId", true).filter(
                (question: any) => {
                  return this.checkQuestionIsComplianced(question);
                }
              ).length > 0
                ? {
                    value: this.filterQuestions(
                      piller["piller"],
                      "PillarId",
                      true
                    ).filter((question: any) => {
                      return this.checkQuestionIsComplianced(question);
                    }).length,
                    name: "Non Compliance",
                  }
                : undefined,
              this.filterQuestions(piller["piller"], "PillarId", true).filter(
                (question: any) => {
                  return !this.checkQuestionIsComplianced(question);
                }
              ).length > 0
                ? {
                    value: this.filterQuestions(
                      piller["piller"],
                      "PillarId",
                      true
                    ).filter((question: any) => {
                      return !this.checkQuestionIsComplianced(question);
                    }).length,
                    name: "Well-Architected",
                  }
                : undefined,
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    });
  }

  loadRiskGraph() {
    var chartDom = document.getElementById("secgraph");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      this.filter["questions"] = params["name"];
      this.selectPiller.emit("security");
    });

    let option = {
      tooltip: {
        formatter: (params: any) => {
          return `
            <div class='flex-start'>
              <div>${params["name"]}</div>
              <div class='graph-text-bold'>${params["value"]}</div>
            </div>
          `;
        },
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          color: ["rgb(212, 209, 209)", "rgb(238, 102, 102)", "#58c388"],
          data: [
            this.fetchUnansweredQuestions("security").length > 0
              ? {
                  value: this.fetchUnansweredQuestions("security").length,
                  name: "Unanswered",
                }
              : undefined,
            this.filterQuestions("security", "PillarId", true).filter(
              (question: any) => {
                return this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "security",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Non Compliance",
                }
              : undefined,
            this.filterQuestions("security", "PillarId", true).filter(
              (question: any) => {
                return !this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "security",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return !this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Well-Architected",
                }
              : undefined,
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadReliabilityGraph() {
    var chartDom = document.getElementById("religraph");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      this.filter["questions"] = params["name"];
      this.selectPiller.emit("reliability");
    });

    let option = {
      tooltip: {
        formatter: (params: any) => {
          return `
            <div class='flex-start'>
              <div>${params["name"]}</div>
              <div class='graph-text-bold'>${params["value"]}</div>
            </div>
          `;
        },
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          color: ["rgb(212, 209, 209)", "rgb(238, 102, 102)", "#58c388"],
          data: [
            this.fetchUnansweredQuestions("reliability").length > 0
              ? {
                  value: this.fetchUnansweredQuestions("reliability").length,
                  name: "Unanswered",
                }
              : undefined,
            this.filterQuestions("reliability", "PillarId", true).filter(
              (question: any) => {
                return this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "reliability",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Non Compliance",
                }
              : undefined,
            this.filterQuestions("reliability", "PillarId", true).filter(
              (question: any) => {
                return !this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "reliability",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return !this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Well-Architected",
                }
              : undefined,
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadPerformanceGraph() {
    var chartDom = document.getElementById("performancegraph");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      this.filter["questions"] = params["name"];
      this.selectPiller.emit("performance");
    });

    let option: any = {
      tooltip: {
        formatter: (params: any) => {
          return `
            <div class='flex-start'>
              <div>${params["name"]}</div>
              <div class='graph-text-bold'>${params["value"]}</div>
            </div>
          `;
        },
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          color: ["rgb(212, 209, 209)", "rgb(238, 102, 102)", "#58c388"],
          data: [
            this.fetchUnansweredQuestions("performance").length > 0
              ? {
                  value: this.fetchUnansweredQuestions("performance").length,
                  name: "Unanswered",
                }
              : undefined,
            this.filterQuestions("performance", "PillarId", true).filter(
              (question: any) => {
                return this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "performance",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Non Compliance",
                }
              : undefined,
            this.filterQuestions("performance", "PillarId", true).filter(
              (question: any) => {
                return !this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "performance",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return !this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Well-Architected",
                }
              : undefined,
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadCostGraph() {
    var chartDom = document.getElementById("costgraph");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      this.filter["questions"] = params["name"];
      this.selectPiller.emit("costOptimization");
    });

    let option = {
      tooltip: {
        formatter: (params: any) => {
          return `
            <div class='flex-start'>
              <div>${params["name"]}</div>
              <div class='graph-text-bold'>${params["value"]}</div>
            </div>
          `;
        },
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          color: ["rgb(212, 209, 209)", "rgb(238, 102, 102)", "#58c388"],
          data: [
            this.fetchUnansweredQuestions("costOptimization").length > 0
              ? {
                  value:
                    this.fetchUnansweredQuestions("costOptimization").length,
                  name: "Unanswered",
                }
              : undefined,
            this.filterQuestions("costOptimization", "PillarId", true).filter(
              (question: any) => {
                return this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "costOptimization",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Non Compliance",
                }
              : undefined,
            this.filterQuestions("costOptimization", "PillarId", true).filter(
              (question: any) => {
                return !this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "costOptimization",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return !this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Well-Architected",
                }
              : undefined,
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadSusGraph() {
    var chartDom = document.getElementById("susgraph");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      this.filter["questions"] = params["name"];
      this.selectPiller.emit("sustainability");
    });

    let option = {
      tooltip: {
        formatter: (params: any) => {
          return `
            <div class='flex-start'>
              <div>${params["name"]}</div>
              <div class='graph-text-bold'>${params["value"]}</div>
            </div>
          `;
        },
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          color: ["rgb(212, 209, 209)", "rgb(238, 102, 102)", "#58c388"],
          data: [
            this.fetchUnansweredQuestions("sustainability").length > 0
              ? {
                  value: this.fetchUnansweredQuestions("sustainability").length,
                  name: "Unanswered",
                }
              : undefined,
            this.filterQuestions("sustainability", "PillarId", true).filter(
              (question: any) => {
                return this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "sustainability",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Non Compliance",
                }
              : undefined,
            this.filterQuestions("sustainability", "PillarId", true).filter(
              (question: any) => {
                return !this.checkQuestionIsComplianced(question);
              }
            ).length > 0
              ? {
                  value: this.filterQuestions(
                    "sustainability",
                    "PillarId",
                    true
                  ).filter((question: any) => {
                    return !this.checkQuestionIsComplianced(question);
                  }).length,
                  name: "Well-Architected",
                }
              : undefined,
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }
}
