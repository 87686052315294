<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <div>
        <button
          class="btn btn-light"
          (click)="backToUsers()"
          *ngIf="source == 'user_details'"
        >
          < Back
        </button>
      </div>
      <div>
        <h5>Audit Trail</h5>
        <p class="header-desc">
          Audit trail enables you to track the actions that the end user performs.
          (Audit trail logs are maintained for 3 months before deleting
          permanently)
        </p>
      </div>
    </div>
    <div class="flex-start">
      <div class="flex-start gap-0">
        <div class="no-wrap">Include Globals</div>
        <span class="relative">
          <label class="switch">
            <input autocomplete="off" type="checkbox" [(ngModel)]="includeGlobals" (change)="load()">
            <span class="slider round"></span>
          </label>
        </span>
      </div>
      <button class="btn btn-success-soft" (click)="export()">Export</button>
    </div>
  </div>
  <div class="card-body">
    <div class="audit_container">
      <div>
        <table class="table table-docs" id="audit-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Action</th>
              <th>Source</th>
              <th>Resource (if any)</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let date of getDates()">
              <ng-container
                *ngFor="let event of auditList[date]; let i = index"
              >
                <tr>
                  <td
                    [attr.rowspan]="auditList[date].length"
                    *ngIf="i == 0"
                    class="date_container"
                  >
                    <span>
                      {{ date }}
                    </span>
                  </td>
                  <td>{{ event["action_time"] }}</td>
                  <td>{{ event["eventName"] }}</td>
                  <td>{{ event["eventSource"] }}</td>
                  <td>
                    {{
                      event["resources"] && event["resources"].length > 0
                        ? event["resources"][0]["resourceId"]
                        : "-"
                    }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
        <div class="center" *ngIf="!fetching && getDates().length == 0">No Events Found</div>
        <div *ngIf="fetching">
          <div
            style="
              padding: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              width="10"
              style="width: 20px; margin: 10px"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="display: flex; justify-content: flex-end; gap: 8px;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="load()">Next</button>
    </div> -->
  </div>
</div>
