import { Directive, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';

@Directive({
  selector: '[dateformat]'
})

@Input()
export class DateFormat {
  constructor(public el: ElementRef) {

    this.el.nativeElement.onkeypress = (evt: any) => {
      //console.log("DateFormat", evt.which);

      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      } else {

        var input = this.el.nativeElement.value;

        if (input.length >= 14) {
          evt.preventDefault();         
        }

       // console.log("input", input);

        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split('-').map(function (v: string) {
          return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = this.checkValue(values[0], 31);
        if (values[1]) values[1] = this.checkValue(values[1], 12);
      
        var output = values.map(function (v: any, i: any) {
          return v.length == 2 && i < 2 ? v + ' - ' : v;
        });
        console.log(output.join('').substr(0, 14));

        this.el.nativeElement.value = output.join('').substr(0, 14);

      }
    };

    this.el.nativeElement.onblur = (evt: any) => {
      console.log("DateFormat", evt.which);
      var input = this.el.nativeElement.value;
      var values = input.split('-').map(function (v:any, i:any) {
        return v.replace(/\D/g, '')
      });
      var output = '';

      if (values.length == 3) {
        var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
        var month = parseInt(values[1]);
        var day = parseInt(values[0]);
      
        //if (month > 11) {
        //  alert('Please Enter Valid Month.');
        //  return;
        //}
        let dd = day + "-" + month + "-" + year;
        var d = moment(dd, "DD-MM-YYYY");
        console.log("DateFormat1", dd);

        if (d.isValid()) {

          output = d.format("DD - MM - YYYY");
          ////document.getElementById('result').innerText = d.toString();
          //var dates: any = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
          //output = dates.map(function (v: string) {
          //  v = v.toString();
          //  return v.length == 1 ? '0' + v : v;
          //}).join(' - ');
        } else {
          alert('Please Enter Valid Date, "DD-MM-YYYY"');
        }

      };
      this.el.nativeElement.value = output;
    };

  }

  checkValue(str: string, max: number) {
    if (str.charAt(0) !== '0' || str == '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return str;
  };





}
