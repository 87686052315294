import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as echarts from 'echarts';

declare let window: any;

@Component({
  templateUrl: './iam_groups.component.html',
  styleUrls:['./iam_groups.component.css'],
})
export class BetaIAMGroupsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  funRef: any;

  userHeader: any = [
    { id: 'user_Name', name: 'User', filter: true },
    { id: 'accessKey_ID', name: 'Access Key ID', filter: true },
    {
      id: 'id_Status',
      name: 'Status',
      filter: true,
    },
    {
      id: 'age_in_days_html',
      name: 'Age in Days',
      center: true,
    },

    {
      id: 'created_Date',
      name: 'Create Date',
      filter: true,
    },
  ];

  moreDetailsHeaders: any = [];
  groupDetail: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.refresh();
      }
    });
    this.funRef = {};
  }

  position: any = { x: '0px', y: '0px' };
  setPosition(event: any) {
    this.position.x = window.innerWidth - event.clientX - 60 + 'px';
    this.position.y = event.clientY + 10 + 'px';
  }

  refresh_: boolean = false;
  refresh() {
    this.refresh_ = true;
    this.loadGroupDetails();
  }

  nextToken: any = null;
  groupLoading: boolean = true;
  async loadGroupDetails(nextToken: any = '') {
    if (this.destroyed) {
      return true;
    }
    this.groupLoading = true;
    if (nextToken == '') {
      this.refresh_ = true;
      this.groupDetail = {};
    }
    let data = {
      aId: this.accountId,
      eId: this.userId,
      a: 'IAMGroupReport',
      nT: nextToken == '' ? undefined : nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/iamageingreport`;

    if (nextToken != '' && this.refresh_) {
      nextToken = '';
      this.groupDetail = {};
      return true;
    }

    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      result.status == '200' ||
      result.status == '1' ||
      result.status_code == 200 ||
      result.groupDetails
    ) {
      this.groupDetail = result.groupDetails;
      this.loadGraph();
    } else {
      this.groupDetail = {
        groups: '-',
        without_Users: '-',
        with_Inline_Policies: '-',
      };
      if (result.message != 'NO RECORD FOUND') {
        this.notifier.alert('Error', '', result.message, 'error', 5000);
      }
    }

    if (result.nextToken) {
      await this.loadGroupDetails(result.nextToken);
    } else {
      this.nextToken = null;
    }
    if (!this.destroyed) {
      this.fetching = false;
    }

    this.groupLoading = false;
    return true;
  }

  loadGraph() {
    this.loadGraph1();
    this.loadGraph2();
  }

  loadGraph1() {
    var chartDom = document.getElementById('graph_without_user');
    var myChart = echarts.init(chartDom);

    let option: any = {
      color: ["rgb(211 57 76 / 82%)", '#0f6fec'], // "rgb(214 41 62 / 60%)" 'rgb(110 182 110)'
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',
        textStyle: {
          fontSize: '19',
          fontFamily: 'Roboto'
        }
      },
      series: [
        {
          name: 'Groups',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: this.groupDetail.without_Users, name: 'No users' },
            {
              value: this.groupDetail.groups - this.groupDetail.without_Users,
              name: 'With users',
            },
          ],
        },
      ],
    };

    option && myChart.setOption(option);
  }
  loadGraph2() {
    var chartDom = document.getElementById('graph_with_inline');
    var myChart = echarts.init(chartDom);

    let option: any = {
      color: ["rgb(211 57 76 / 82%)", '#0f6fec'],

      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',        
        textStyle: {
          fontSize: '19',
          fontFamily: 'Roboto'
        }
      },
      series: [
        {
          name: 'Groups',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: this.groupDetail.with_Inline_Policies,
              name: 'With inline policies',
            },
            {
              value:
                this.groupDetail.groups - this.groupDetail.with_Inline_Policies,
              name: 'No inline policies',
            },
          ],
        },
      ],
    };

    option && myChart.setOption(option);
  }

  downloadReport() {}

  callFunction(name: any, param: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
