import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';

declare let $: any;
declare let window: any;

@Component({
  selector: 'tagskeypair-launch-instance',
  templateUrl: 'tagskeypair.launch.component.html',
  styleUrls: [
    './../../launch.instance.component.css',
    './../../../../upload.bom.component.css',
    './tagskeypair.launch.component.css',
  ],
})
export class TagsKeyPairLaunchInstanceComponent implements OnInit, OnDestroy {
  @Input('instance') instance: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  tags: any = [];
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  removeTag(index: number) {
    this.tags.splice(index, 1);
  }
  addTag() {
    this.tags.push({
      Key: '',
      Value: '',
    });
  }

  accountId: any;
  regionId: any;
  currentMessage: any;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.tags = this.instance['tags'];
    this.addTag();

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.reset();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.reset();
      }
    });
  }

  reset() {
    this.loadKeypairs();
  }

  updateReports(event: any) {
    if (event.event == 'add-item') {
      this.instance.key_name = event.data;
    }
  }

  keyPairs: any = [];
  async loadKeypairs() {
    this.notifier.loading(true);
    let data = {
      action: 'list_key_pairs',
      login_user_email: this.userId,
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'BOMv2.Components.Launch.Components.Tags_Keypair',
      'Keypairs Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2/create`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.keyPairs = result.key_pairs.map((key: any) => {
        return { key_name: key };
      });
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }

    this.notifier.loading(false);
  }

  ngOnDestroy(): void {}
}
