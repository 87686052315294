<div class="card">
  <div class="card-header flex-between">
    <h5 *ngIf="selectedCloudPartner == 'aws'">S3 {{ type == 'operations' ? 'Operations' : 'Cost Controls' }}</h5>
    <h5 *ngIf="selectedCloudPartner == 'azure'">Blobs</h5>
    <div class="flex-start">
      <ng-container *ngIf="scanDetails && selectedCloudPartner == 'aws'">
        <span class="border-right">
          <div class="text-bold">
            Scanned on
          </div>
          <div>
            {{ scanDetails["lastScan"] || '-' }}
          </div>
        </span>
        <span>
          <div class="text-bold">
            Status
          </div>
          <div>
            {{ scanDetails["status"] || '-' }}
          </div>
        </span>
      </ng-container>
      <button class=" btn btn-light" (click)="loadRecommendations()">Refresh</button>
      <button class=" btn btn-primary-soft" *ngIf="selectedCloudPartner == 'aws'" (click)="createScan()">Scan</button>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="discovery-section-container">
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            {{ overview['buckets']() }}
          </span>
          <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        </div>
        <div class="item-desc">Total {{ selectedCloudPartner == 'aws' ? 'Buckets' : 'Containers'}}</div>
      </div>
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            {{ overview['objects']() }}
          </span>
          <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        </div>
        <div class="item-desc">Total {{ selectedCloudPartner == 'aws' ? 'Objects' : 'Blobs'}}</div>
      </div>
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            {{ overview['size']() }} GB
          </span>
          <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        </div>
        <div class="item-desc">Total Size Utilized</div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <ng-container *ngIf="!loading && (this.selectedCloudPartner == 'azure' || scanDetails['status'] != '-') && buckets.length > 0">
      <div class="flex-end">
        <input type="text" [(ngModel)]="filterText" (keyup)="pagination['page'] = 1" class="form-control width-250px"
          placeholder="Search...">
      </div>
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>{{ selectedCloudPartner == 'aws' ? 'Bucket' : 'Container'}} Name</th>
            <th>Size</th>
            <th>{{ selectedCloudPartner == 'aws' ? 'Objects' : 'Blobs'}} Count</th>
            <th class="center" *ngIf="type == 'cost-controls'">Recommendations</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let bucket of filteredTable() | paginate
                          : {
                              itemsPerPage: pagination['perPage'],
                              currentPage: pagination['page'],
                              totalItems: filteredTable().length
                            }; let i = index">
            <tr>
              <td>{{ ((pagination['page'] - 1) * pagination['perPage']) + i + 1 }}</td>
              <td>{{ bucket['BucketName'] }}</td>
              <td>{{ bucket['Displaysize'] || (bucket['size'].toFixed(2) + ' GB') }}</td>
              <td>{{ bucket['ObjectCount'] }}</td>
              <td class="center" *ngIf="type == 'cost-controls'"><span class="nav-link" (click)="selectedBucket = bucket">{{
                  bucket['recommendation'].length }}</span></td>
            </tr>
          </ng-container>
          <ng-container *ngIf="loading && (!scanDetails || this.selectedCloudPartner == 'azure')">
            <tr>
              <td class="center" [attr.colspan]="type == 'cost-controls' ? '5' : '4'">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!loading && (!scanDetails.status || selectedCloudPartner == 'azure')">
            <tr>
              <td class="center" [attr.colspan]="type == 'cost-controls' ? '5' : '4'">
                <div class="flex-center" *ngIf="selectedCloudPartner == 'aws' && buckets.length == 0">
                  No data found. <span class="nav-link" (click)="createScan()">click here</span> to scan
                </div>
                <div class="flex-center" *ngIf="selectedCloudPartner == 'azure' && buckets.length == 0">
                  No Blobs Found
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!loading && (scanDetails.status || selectedCloudPartner == 'azure') && filterText != '' && filteredTable().length == 0">
            <tr>
              <td class="center" [attr.colspan]="type == 'cost-controls' ? '5' : '4'">
                <div class="flex-center">
                  No search results with search key<strong>{{ filterText }}</strong>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="loading && scanDetails.status && scanDetails.status != 'Completed'">
            <tr>
              <td class="center" [attr.colspan]="type == 'cost-controls' ? '5' : '4'">
                <div class="flex-center">
                  <div>
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                  <div>
                    {{ scanDetails['status'] }}
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select class="form-select" [(ngModel)]="pagination['perPage']" (change)="pagination['page'] = 1">
            <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
          </select>
        </div>
        <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
          (pageChange)="pagination['page'] = $event">
        </pagination-controls>
      </div>
    </ng-container>
    <div *ngIf="!loading && scanDetails['status'] == '-' && selectedCloudPartner == 'aws'" class="center">
      No Scans Found. <span class="nav-link" (click)="createScan()">click here</span>to scan
    </div>
    <div *ngIf="!loading && scanDetails['status'] != '-' && buckets.length == 0 && selectedCloudPartner == 'aws'" class="center">
      No Data Found. <span class="nav-link" (click)="createScan()">click here</span>to scan
    </div>
    <div class="center" *ngIf="loading">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
    </div>
  </div>
</div>

<modal-popup [headers]="recommendationsHeader" [data]="selectedBucket['recommendation']"
  [modalTitle]="'Recommendations'" [showModal]="true" *ngIf="selectedBucket"
  (hideModal)="selectedBucket = null"></modal-popup>