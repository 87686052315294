<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">Scan</div>
    <div class="KBBody">
      <div class="col-mb-12 descriptiontextalign">
        <span [innerHTML]="description">
        </span>
      </div>
      <div class="col-mb-6">
        <label>Account ID: </label>
        <span>{{data.account_id || data.accountId}}</span>
      </div>
      <div class="col-mb-6">
        <label>Region: </label>
        <span>{{data.region_name || data.region}}</span>
      </div>
      <div class="KBActions">
        <button class="btn btn-success" (click)="yes()">Create</button>
        <button class="btn btn-light" (click)="no()">Cancel</button>
      </div>
    </div>
  </div>
</div>