import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";
import * as echarts from "echarts";
import * as moment from "moment";

@Component({
  selector: "app-azure-dashboard",
  templateUrl: "./azure-dashboard.component.html",
  styleUrls: ["./azure-dashboard.component.css"],
})
export class AzureDashboardComponent implements OnInit, OnDestroy, OnChanges {
  accountId: string;
  regionId: string;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  selectedPrice: any = null;
  userId: string = localStorage.getItem('eId');
  title: string = null;
  newMonth: boolean = false;
  @Input("resourceGroup") resourceGroup: string;
  prevResourceGroup: string = null;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.newMonth = moment().format('DD') == '01';
    window.addEventListener("resize", (event: any) => this.resizeScreen(event));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["resourceGroup"] &&
      this.prevResourceGroup != this.resourceGroup
    ) {
      this.prevResourceGroup = this.resourceGroup;
      this.load();
    }
  }

  popupType: any = {};

  async load() {
    this.loadMonthlyCost();
    this.loadYearlyCost();
    this.loadTotalStorage();
    this.loadTotalRam();
    this.loadOsTypes();
    this.loadDailyCost();
    this.loadServiceCostTillDate();
    // this.loadVpcusCountAndTheirType();
    // this.loadVMSizeSeriesAndCount();
    this.loadForecasteData();
    this.loadMinfySupportTickets();
    this.notifier.loading(false);
  }

  setSelectedPrice(json: any, key: string, type: string) {
    if (this.errors[key] || !json) {
      return;
    }
    switch (type) {
      case "yearly_cost":
        this.title = "Annual Cost";
        this.selectedPrice = [
          {
            price: json["yearly_cost"],
            title: `${json["start_date"]} to ${json["end_date"]}`,
          },
        ];
        break;

      case "monthly_cost":
        this.title = "Month to Date (MTD) Spend";
        this.selectedPrice = [
          {
            price: json["last_month_cost"],
            title: `${json["last_month_start_date"]} to ${json["last_month_end_date"]}`,
          },
          {
            price: json["current_month_cost"],
            title: `${json["current_month_start_date"]} to ${json["current_month_end_date"]}`,
          },
        ];
        break;

      case "daily_cost":
        this.title = "Today's Spend";
        this.selectedPrice = [
          {
            price: json["last_month_daily_cost"],
            title: `${json["last_month_daily_start_date"]} to ${json["last_month_daily_end_date"]}`,
          },
          {
            price: json["current_month_daily_cost"],
            title: `${json["current_month_daily_start_date"]} to ${json["current_month_daily_end_date"]}`,
          },
        ];
        break;

      case "forecasted_data":
        this.title = "Forecast for upcoming months";
        this.selectedPrice = [
          {
            price: json["forecast_cost_of_the_current_month"],
            title: `${json["current_month_start_date"]} to ${json["current_month_last_date"]}`,
          },
          {
            price: json["next_month_forecast_cost"],
            title: `${json["next_month_start_date"]} to ${json["next_month_end_date"]}`,
          },
          {
            price: json["forecast_cost_for_next_2nd_month"],
            title: `${json["next_2nd_month_start_date"]} to ${json["next_2nd_month_last_date"]}`,
          },
        ];
        break;

      case "processor_data":
        this.popupType["processor_data"] = [
          {
            id: localStorage.getItem("selectedaccountIds"),
            vcpu: json["total_cores"],
            cost: Object.keys(json["vm_types_count"]).map((type: any) => {
              return {
                key: type,
                value: json["vm_types_count"][type]["count"],
              };
            }),
          },
        ];

        break;

      case "storage_data":
        this.popupType["storage_data"] = [
          {
            id: localStorage.getItem("selectedaccountIds"),
            cost: [
              {
                key: "Blob Storage",
                value: json["blob_storage"],
              },
              {
                key: "Disk Storage",
                value: json["disk_storage"],
              },
              {
                key: "File Storage",
                value: json["file_storage"],
              },
            ],
          },
        ];
        break;

      case "instance_type":
        this.popupType["instance_type"] = [
          {
            id: localStorage.getItem("selectedaccountIds"),
            instance: [
              {
                value: Object.keys(
                  this.data["total_ram"]["vm_types_count"]
                ).map((os: any) => {
                  return {
                    type: os,
                    size: this.data["total_ram"]["vm_types_count"][os]["count"],
                  };
                }),
              },
            ],
          },
        ];

        break;

      default:
        break;
    }
  }

  loading: any = {
    jira_tickets: false,
    yearly_cost: false,
    os_type: false,
    total_storage: false,
    total_ram: false,
    monthly_cost: false,
    daily_cost: false,
    services_cost_till_date: false,
    vm_size_series_and_count: false,
    vcpus_count_and_their_type: false,
    forecasted_data: false,
  };

  errors: any = {
    jira_tickets: null,
    yearly_cost: null,
    os_type: null,
    total_storage: null,
    total_ram: null,
    monthly_cost: null,
    daily_cost: null,
    services_cost_till_date: null,
    vm_size_series_and_count: null,
    vcpus_count_and_their_type: null,
    forecasted_data: null,
  };

  data: any = {
    jira_tickets: null,
    yearly_cost: null,
    os_type: null,
    total_storage: null,
    total_ram: null,
    monthly_cost: null,
    daily_cost: null,
    services_cost_till_date: null,
    vm_size_series_and_count: null,
    vcpus_count_and_their_type: null,
    forecasted_data: null,
  };

  interval: any = null;
  resizeScreen(event: any) {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setTimeout(() => {
      this.loadResize();
      this.interval = null;
    }, 500);
  }

  loadResize() {
    Object.keys(this.charts).forEach((chart: string) => {
      if(this.charts[chart]) {
        this.charts[chart].resize();
      }
    })
  }

  async loadMonthlyCost() {
    this.loading["monthly_cost"] = true;

    const data: any = {
      action: "monthly_cost",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["monthly_cost"] = result["monthly_cost"];
      this.errors["monthly_cost"] = null;
    } else {
      this.errors["monthly_cost"] = result.error_message;
      this.data["monthly_cost"] = null;
    }

    this.loading["monthly_cost"] = false;
  }

  async loadYearlyCost() {
    this.loading["yearly_cost"] = true;

    const data: any = {
      action: "yearly_cost",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["yearly_cost"] = result["yearly_cost"];
      this.errors["yearly_cost"] = null;
    } else {
      this.errors["yearly_cost"] = result.error_message;
      this.data["yearly_cost"] = null;
    }

    this.loading["yearly_cost"] = false;
  }

  async loadTotalStorage() {
    this.loading["total_storage"] = true;

    const data: any = {
      action: "total_storage",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["total_storage"] = result;
      this.errors["total_storage"] = null;
    } else {
      this.errors["total_storage"] = result.error_message;
      this.data["total_storage"] = null;
    }

    this.loading["total_storage"] = false;
  }

  async loadTotalRam() {
    this.loading["total_ram"] = true;

    const data: any = {
      action: "total_core_and_ram",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["total_ram"] = result;
      this.errors["total_ram"] = null;
    } else {
      this.errors["total_ram"] = result.error_message;
      this.data["total_ram"] = null;
    }

    setTimeout(() => {
      this.loadInstanceTypeGraphs();
    }, 10);

    this.loading["total_ram"] = false;
  }

  async loadOsTypes() {
    this.loading["os_type"] = true;

    const data: any = {
      action: "os_type",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["os_type"] = result;
      this.errors["os_type"] = null;
      if (result["total_os"] > 0) {
        setTimeout(() => {
          this.loadOsTypeGraphs();
        }, 10);
      }
    } else {
      this.errors["os_type"] = result.error_message;
      this.data["os_type"] = null;
    }

    this.loading["os_type"] = false;
  }

  async loadDailyCost() {
    this.loading["daily_cost"] = true;

    const data: any = {
      action: "daily_cost",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["daily_cost"] = result["daily_cost"];
      this.errors["daily_cost"] = null;
    } else {
      this.errors["daily_cost"] = result.error_message;
      this.data["daily_cost"] = null;
    }

    this.loading["daily_cost"] = false;
  }

  async loadServiceCostTillDate() {
    this.loading["services_cost_till_date"] = true;

    const data: any = {
      action: "services_cost_till_date",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["services_cost_till_date"] = result["top_services_cost"];
      this.errors["services_cost_till_date"] = null;
      if (result?.top_services_cost?.service.length > 0) {
        setTimeout(() => {
          this.loadResourcesGraph();
        }, 10);
      }
    } else {
      this.errors["services_cost_till_date"] = result.error_message;
      this.data["services_cost_till_date"] = null;
    }

    this.loading["services_cost_till_date"] = false;
  }

  async loadVpcusCountAndTheirType() {
    this.loading["vcpus_count_and_their_type"] = true;

    const data: any = {
      action: "vcpus_count_and_their_type",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["vcpus_count_and_their_type"] = result;
      this.errors["vcpus_count_and_their_type"] = null;
      this.loadvCPUTypesGraphs();
    } else {
      this.errors["vcpus_count_and_their_type"] = result.error_message;
      this.data["vcpus_count_and_their_type"] = null;
    }

    this.loading["vcpus_count_and_their_type"] = false;
  }

  async loadVMSizeSeriesAndCount() {
    this.loading["vm_size_series_and_count"] = true;

    const data: any = {
      action: "vm_size_series_and_count",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["vm_size_series_and_count"] = result;
      this.errors["vm_size_series_and_count"] = null;
    } else {
      this.errors["vm_size_series_and_count"] = result.error_message;
      this.data["vm_size_series_and_count"] = null;
    }

    this.loading["vm_size_series_and_count"] = false;
  }

  async loadForecasteData() {
    this.loading["forecasted_data"] = true;

    const data: any = {
      action: "forecasted_data",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/dashboard`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.data["forecasted_data"] = result["forecast_data"];
      this.errors["forecasted_data"] = null;
    } else {
      this.errors["forecasted_data"] = result.error_message;
      this.data["forecasted_data"] = null;
    }

    if (this.data["forecasted_data"]) {
      setTimeout(() => {
        this.loadForeCastGraph();
      }, 10);
    }

    this.loading["forecasted_data"] = false;
  }

  charts: any = {};
  loadResourcesGraph() {
    this.charts['resourcesGraphAzure'] = echarts.init(document.getElementById("resourcesGraphAzure"));

    let option = {
      color: [
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        formatter: function (params: any) {
          const seriesName = params[0].axisValueLabel;
          let content = `<div><b>${seriesName}</b>`;
          content += "<hr>";
          for (const param of params) {
            const value = param.value !== undefined ? param.value : 0;
            content += `<div class="d-flex justify-content-between w-10"> <div> ${param.marker} ${param.seriesName}</div> &nbsp; <div><b> $${value}</b></div> </div>`;
          }
          content += "</div>";
          return content;
        },
      },
      barMaxWidth: 25,
      legend: { top: "1%" },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      xAxis: { type: "value", axisLabel: { formatter: "${value}" } },
      yAxis: { type: "category", data: [this.resourceGroup] },
      series: this.data["services_cost_till_date"]["service"].map(
        (service: any) => {
          return {
            name: service["service_name"],
            type: "bar",
            stack: "total",
            data: [service["price_in_dollars"]],
          };
        }
      ),
    };
    //let div = document.getElementById("resourcesGraph");
    this.charts['resourcesGraphAzure'].setOption(option, true);
  }

  loadForeCastGraph() {
    this.charts['forecastGraphAzure'] = echarts.init(document.getElementById("forecastGraphAzure"));

    let option = {
      color: ["#b2e061"],
      grid: {
        left: 70,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params: any) {
          var value = params[0].value;
          var name = params[0].name;
          return name + ": $" + value;
        },
      },
      xAxis: {
        type: "category",
        data: [
          this.data["forecasted_data"]
            ? moment(
                this.data["forecasted_data"]["current_month_start_date"],
                "YYYY-MM-DD"
              ).format("MMM")
            : "",
          this.data["forecasted_data"]
            ? moment(
                this.data["forecasted_data"]["next_month_start_date"],
                "YYYY-MM-DD"
              ).format("MMM")
            : "",
          this.data["forecasted_data"]
            ? moment(
                this.data["forecasted_data"]["next_2nd_month_start_date"],
                "YYYY-MM-DD"
              ).format("MMM")
            : "",
        ],
        axisLabel: { fontSize: 9 },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: "${value}",
        },
      },
      series: [
        {
          data: [
            {
              value: this.data["forecasted_data"]
                ? Number(
                    this.data["forecasted_data"][
                      "forecast_cost_of_the_current_month"
                    ].split(" USD")[0]
                  )
                : 0,
              itemStyle: {
                color: "#7EB0D5",
              },
            },
            this.data["forecasted_data"]
              ? Number(
                  this.data["forecasted_data"][
                    "next_month_forecast_cost"
                  ].split(" USD")[0]
                )
              : 0,
            {
              value: this.data["forecasted_data"]
                ? Number(
                    this.data["forecasted_data"][
                      "forecast_cost_for_next_2nd_month"
                    ].split(" USD")[0]
                  )
                : 0,
              itemStyle: {
                color: "#FD7F6F",
              },
            },
          ],
          type: "bar",
        },
      ],
      barWidth: "50%",
    };
    this.charts['forecastGraphAzure'].setOption(option, true);
  }

  loadInstanceTypeGraphs() {
    if (this.data["total_ram"]["total_cores"] == 0) {
      return;
    }

    var chartDom = document.getElementById("instanceTypeGraphAzure");
    this.charts['instanceTypeGraphAzure'] = echarts.init(chartDom);

    let option = {
      color: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
        "#ffb55a",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{c}",
        textStyle: {
          fontWeight: "bold",
        },
      },
      legend: {
        top: "10%",
        right: "7%",
        orient: "vertical",
        type: "scroll",
        height: 200,
      },
      series: [
        {
          name: "Instances",
          type: "pie",
          radius: ["59%", "65%"],
          right: "100",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: Object.keys(this.data["total_ram"]["vm_types_count"]).map(
            (os: any) => {
              return {
                name: os,
                value: this.data["total_ram"]["vm_types_count"][os]["count"],
              };
            }
          ),
        },
      ],
    };
    this.charts['instanceTypeGraphAzure'] && this.charts['instanceTypeGraphAzure'].setOption(option);
  }

  loadOsTypeGraphs() {
    var chartDom = document.getElementById("osTypeGraphAzure");
    this.charts['osTypeGraphAzure'] = echarts.init(chartDom);

    let option = {
      color: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
        "#ffb55a",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{c}",
        textStyle: {
          fontWeight: "bold",
        },
      },
      legend: {
        top: "10%",
        right: "7%",
        orient: "vertical",
        type: "scroll",
        height: 200,
      },
      series: [
        {
          name: "Instances",
          type: "pie",
          radius: ["59%", "65%"],
          right: "100",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: Object.keys(this.data["os_type"]["total_os_wise"]).map(
            (os: any) => {
              return {
                name: os,
                value: this.data["os_type"]["total_os_wise"][os],
              };
            }
          ),
        },
      ],
    };
    this.charts['osTypeGraphAzure'] && this.charts['osTypeGraphAzure'].setOption(option);
  }

  loadvCPUTypesGraphs() {
    if (this.data["vcpus_count_and_their_type"]["total_vcpus_count"] == 0) {
      return;
    }
    var chartDom = document.getElementById("vCPUTypesGraphAzure");
    this.charts['vCPUTypesGraphAzure'] = echarts.init(chartDom);

    let option = {
      color: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
        "#ffb55a",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{c}",
        textStyle: {
          fontWeight: "bold",
        },
      },
      legend: {
        top: "10%",
        right: "7%",
        orient: "vertical",
        type: "scroll",
        height: 200,
      },
      series: [
        {
          name: "vCPU Types",
          type: "pie",
          radius: ["59%", "65%"],
          right: "100",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              name: "Intel",
              value:
                this.data["vcpus_count_and_their_type"][
                  "no_of_vms_using_intel_processors"
                ],
            },
            {
              name: "AMD",
              value:
                this.data["vcpus_count_and_their_type"][
                  "no_of_vms_using_amd_processors"
                ],
            },
            {
              name: "ARN",
              value:
                this.data["vcpus_count_and_their_type"][
                  "no_of_vms_using_arm_processors"
                ],
            },
          ],
        },
      ],
    };
    this.charts['vCPUTypesGraphAzure'] && this.charts['vCPUTypesGraphAzure'].setOption(option);
  }

  tickets: any = [];
  async loadMinfySupportTickets() {
    this.loading["jira_tickets"] = true;
    let data = {
      action: "jira_list",
      account_id: "398665278814",
      include_resolved: "true",
      jira_project_key: "SC",
      jira_organization_key: "SREI",
      start_date: "2023-11-01",
      end_date: "2023-11-24",
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.data["jira_tickets"] = {};
      result.issues.forEach((element: any) => {
        if (element.status === "Open") {
          this.data["jira_tickets"]["open"] += 1;
        }
        if (element["status"] === "Closed") {
          this.data["jira_tickets"]["closed"] += 1;
        }
        if (element["status"] === "Resolved") {
          this.data["jira_tickets"]["resolved"] += 1;
        }
        if (element["status"] === "Waiting for Customer Response") {
          this.data["jira_tickets"]["watingForCosRes"] += 1;
        }
        if (element["status"] === "Work in progress") {
          this.data["jira_tickets"]["WorkInProgress"] += 1;
        }
        if (element["status"] === "Closed By BOT") {
          this.data["jira_tickets"]["closedByBot"] += 1;
        }
        if (element["status"] === "Under Investigation") {
          this.data["jira_tickets"]["investigation"] += 1;
        }
      });
      this.errors["jira_tickets"] = null;
    } else {
      this.errors["jira_tickets"] = result.error_message;
      this.data["jira_tickets"] = result.error_message;
    }
    this.loading["jira_tickets"] = false;

    this.loadTicketsGraph();
  }

  loadTicketsGraph() {
    var chartDom = document.getElementById("totalIssuesSupport");
    this.charts['totalIssuesSupport'] = echarts.init(chartDom);

    let option = {
      color: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#8bd3c7",
        "#beb9db",
        "#fdcce5",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{c}",
        textStyle: {
          fontWeight: "bold",
        },
      },
      legend: {
        top: "10%",
        right: "5%",

        orient: "vertical",
      },
      series: [
        {
          name: "Tickets",
          type: "pie",
          radius: "90%",
          right: "209",
          top: "10%",
          label: {
            show: false,
            position: "center",
          },
          data: [
            {
              value: this.data["jira_tickets"]["WorkInProgress"] || 0,
              name: "Work in progress",
            },
            {
              value: this.data["jira_tickets"]["open"] || 0,
              name: "Open",
            },
            {
              value: this.data["jira_tickets"]["resolved"] || 0,
              name: "Resolved",
            },
            {
              value: this.data["jira_tickets"]["closedByBot"] || 0,
              name: "Closed by Bot",
            },
            {
              value: this.data["jira_tickets"]["closed"] || 0,
              name: "Closed",
            },

            {
              value: this.data["jira_tickets"]["watingForCosRes"] || 0,
              name: "Customer Response",
            },
            {
              value: this.data["jira_tickets"]["investigation"] || 0,
              name: "Under Investigation",
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };

    this.charts['totalIssuesSupport'] && this.charts['totalIssuesSupport'].setOption(option);
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", (event: any) =>
      this.resizeScreen(event)
    );
  }
}
