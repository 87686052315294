import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './cfrc.view.component.html',
  styleUrls: ['./cfrc.view.component.css'],
})
export class ViewCFRCComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  customerType: string = localStorage.getItem("customerType");
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  cfrcData: any = [];
  funRef: any;
  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'region',
      name: 'Region',
    },
    { id: 'description', name: 'Description' },
    {
      id: 'totalCost',
      name: 'Cost per Hour',
    },
  ];
  cfrc_start_date: any;
  cfrc_end_date: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  ranges: any = [];
  selectedRange: any = null;

  filteredCFRCs() {
    return this.cfrcData.filter((cfrc: any) => {
      let temp = `${cfrc['startDate']} - ${cfrc['endDate']}`;
      return this.selectedRange == temp;
    });
  }

  async load() {
    this.notifier.loading(true);
    this.cfrcData = [];
    this.ranges = [];
    let data = {
      lid: this.accountId,
      userType: 'C',
      action: 'fetchCustomCFRCDetails',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
    
    if(this.customerType == 'mp') {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.cloudFrontDetailsList) {
        result.cloudFrontDetailsList.forEach((cfrc: any) => {
          let temp = `${cfrc['startDate']} - ${cfrc['endDate']}`;
          if (this.ranges.indexOf(temp) < 0) {
            this.ranges.push(temp);
          }
        });
        this.selectedRange = this.ranges[0];
        this.cfrc_start_date = result.startDate;
        this.cfrc_end_date = result.endDate;
        this.cfrcData = result.cloudFrontDetailsList;
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
