<div class="card" *ngIf="!selectedScanner">
  <h5 class="card-header flex-between">
    <div>
      Scanners
    </div>
    <div *ngIf="arns.length > 0">
      <button class="btn btn-danger-soft" (click)="view = 'DeleteRole'">Delete Role</button>
    </div>
  </h5>
  <div class="card-body">
    <table class="table table-docs" *ngIf="!loading">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Scanner Name</th>
          <th>Scanner ID</th>
          <th>Alert Email Id List</th>
          <th>Scanner Status</th>
          <th class="center">Scanner Result</th>
          <th class="center">Scanner History</th>
          <th class="center">Edit</th>
          <th class="center">Delete</th>
        </tr>
      </thead>
      <tbody *ngIf="scanners.length > 0">
        <tr *ngFor="let scanner of scanners; let i = index">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            <span class="nav-link" (click)="viewDetails(scanner, 'ScannerDetails')">
              {{ scanner["scanner_name"] || '' }}
            </span>
          </td>
          <td>
            {{ scanner["scanner_id"] || '' }}
          </td>
          <td>
            {{ scanner["emails_list"] ? scanner["emails_list"].join(", ") : '' }}
          </td>
          <td>
            {{ scanner["status"] || "Under Processing" }}
          </td>
          <td class="center">
            <span class="nav-link" (click)="viewDetails(scanner, 'ScannerResult')">
              <i class="fa fa-line-chart"></i>
            </span>
          </td>
          <td class="center">
            <span class="nav-link">
              <i class="fa fa-history"></i>
            </span>
          </td>
          <td class="center">
            <span class="nav-link" (click)="viewDetails(scanner, 'EditScanner')">
              <i class="fa fa-pencil"></i>
            </span>
          </td>
          <td class="center">
            <span class="nav-link" (click)="deleteScanner(scanner)" *ngIf="!scanner['delete']">
              <i class="fa fa-trash"></i>
            </span>
            <span class="nav-link" *ngIf="scanner['delete']">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="scanners.length == 0 && !loading">
        <tr>
          <td class="center" colspan="9">
            No Scanners found
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="loading" class="center">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
    </div>
  </div>
</div>

<app-view-scanner-alerts *ngIf="selectedScanner && view == 'ScannerDetails'" [accountId]="accountId"
  [regionId]="regionId" [scanner]="selectedScanner" (hideModel)="hideDetails($event)"></app-view-scanner-alerts>

<app-view-scanner-results *ngIf="selectedScanner && view == 'ScannerResult'" [accountId]="accountId"
  [regionId]="regionId" [scanner]="selectedScanner" (hideModel)="hideDetails($event)"></app-view-scanner-results>

<app-edit-scanner-cams *ngIf="selectedScanner && view == 'EditScanner'" [accountId]="accountId" [regionId]="regionId"
  [roleARN]="arns[0]['role_arn']" [scanner]="selectedScanner" (hideModel)="hideDetails($event)"></app-edit-scanner-cams>

<app-delete-role-cams *ngIf="view == 'DeleteRole'" [accountId]="accountId" [regionId]="regionId"
  [roleARN]="arns[0]['role_arn']" (hideModel)="hideDetails($event)"></app-delete-role-cams>