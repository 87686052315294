import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-edit-scanner-cams",
  templateUrl: "./edit-scanner-cams.component.html",
  styleUrls: ["./edit-scanner-cams.component.css"],
})
export class EditScannerCamsComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<any>();
  @Input("regionId") regionId: string;
  @Input("accountId") accountId: string;
  @Input("roleARN") roleARN: string;
  @Input("scanner") scanner: any;
  intervalFrequency: string;
  interval: string;

  scannerObj: any = {};

  constructor(private apiServer: APIService) {}

  ngOnInit(): void {
    this.scannerObj = JSON.parse(JSON.stringify(this.scanner));
    this.interval = this.scannerObj["interval"].split(" ")[0];
    this.intervalFrequency = this.scannerObj["interval"].split(" ")[1];
    if (this.intervalFrequency.indexOf("s") <= -1) {
      this.intervalFrequency = this.intervalFrequency + "s";
    }
    this.scannerObj["emails_list"] = this.scannerObj["emails_list"].map(
      (email: string) => {
        return { email: email };
      }
    );
    this.load();
  }

  async load() {
    let data: any = {
      action: "get_scanner",
      scanner_id: this.scanner["scanner_id"],
      account_id: this.accountId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/alerts`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.scannerObj["payload"] = {};
      this.scannerObj["payload"]["testscsanner"] = result.items[0];
    }
  }

  addEmail() {
    this.scannerObj["emails_list"].push({ email: "" });
  }

  removeEmail(index: number) {
    this.scannerObj["emails_list"].splice(index, 1);
  }

  loading: boolean = false;
  async update() {
    this.loading = true;
    let data: any = {
      action: "add",
      ...this.scannerObj,
      interval: this.interval + this.intervalFrequency,
      role_arn: this.roleARN,
      status: undefined,
      emails_list: this.scannerObj["emails_list"].map((email: any) => {
        return email["email"];
      }),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/alerts`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.hideModel.emit(true);
    }

    this.loading = false;
  }
}
