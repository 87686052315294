<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Non Standard Inbound Ports Exposed over the Public Internet</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
      <div class="KBBody table-responsive ">

        <table class="table table-hover mb-0">
            <thead class="blue fixed-header">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">Security Group Name</td>
                <td scope="col">Security Group ID</td>  
                <td scope="col">Port</td>
                <td scope="col">CIDR</td>
              </tr>
            </thead>
          

            <tbody class="scrollable-body">
              <tr *ngFor="let i of PortList; let j = index">
                <td scope="row" class="m-4">{{j+1}}</td>
                <td>{{i.p.port.sg_name}}</td>
                <td>{{i.p.sg}}</td>
                <td>{{i.filteredPorts}}</td>
                <td>{{i.p.port.cidr_range}}</td>
              </tr>           
            </tbody> 
        </table>
      
    </div>
          
</div>