import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-cost-saving-tab',
  templateUrl: './view-cost-saving-tab.component.html',
  styleUrls: ['./view-cost-saving-tab.component.css']
})
export class ViewCostSavingTabComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() CostSavings :any=[];
  constructor(public notifier: NotifierService,) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }
  no(){
    this.hideModal.emit({ status: 0 });
    
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}


