import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit
} from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let $: any;

@Component({
  selector: "workload-associate-profile",
  templateUrl: "./associate-profile.component.html",
  styleUrls: ["./associate-profile.component.css"],
})
export class AssociateProfileComponent implements AfterViewInit, OnInit {
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;
  @Input("profiles") profiles: any;
  @Input("workload") workload: any;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  profile_region:any;
  @Output() hideModel: any = new EventEmitter<any>();

  ngAfterViewInit(): void {
    $("#profileSelector").selectpicker();
  }

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {
   
  }
  ngOnInit(): void {
     // console.log("propfiles",this.profiles);
     if (this.regionId) {
      this.profile_region = this.profiles.filter(
        (obj: any) => obj.region_id === this.regionId
      );
      // console.log("filtered workload", this.profile_region , this.regionId);
    } else {
      this.profile_region = [];
      // console.log("filtered workload", this.profile_region, this.regionId);
    }
  }
  loading: boolean = false;
  profileSelected: any = null;

  errors: any = {
    profile: {
      error: false,
      message: "Please select a profile",
    },
  };

  async associateProfile() {
    if (!this.profileSelected) {
      this.errors["profile"]["error"] = true;
    } else {
      this.errors["profile"]["error"] = false;
    }

    this.loading = true;

    let data: any = {
      accountId: this.accountId,
      action: "associateProfile",
      profileArnList: [this.profileSelected["profileARN"]],
      workLoadID: this.workload["aws_id"],
      swayamWId: this.workload["id"],
      lensId: this.workload["lenses"],
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiService.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      this.workload["profile"] = {
        profileName: this.profileSelected["profileName"],
        profileDescription: this.profileSelected["profileDescription"],
        profileARN: this.profileSelected["profileARN"],
      };
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
    }

    this.loading = false;
  }
}
