<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Create Alarm</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        <label>Alarm Name</label>
        <input autocomplete="off" type="text" [(ngModel)]="inputMonitor.alarm_name" name="" id=""
          class="form-control" />
      </div>
      <div class="row">
        <label>Anomaly Count</label>
        <input autocomplete="off" type="number" [(ngModel)]="inputMonitor.anomaly_count" name="" id=""
          class="form-control" />
      </div>
      <div class="row">
        <label>Alarm Status</label>
        <select class="form-control" name="" id="" [(ngModel)]="inputMonitor.status">
          <option value="true">Enable</option>
          <option value="false">Disable</option>
        </select>
      </div>
      <div class="row">
        <label>Time Range</label>
        <div class="time-range">
          <input autocomplete="off" type="number" class="form-control" [(ngModel)]="inputMonitor.timeRangeValue" />
          <select class="form-control" name="" id="" [(ngModel)]="inputMonitor.timeRangeFrequency">
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
          </select>
        </div>
      </div>
      <div class="row">
        <label>Email IDs</label>
        <div class="additional_contacts">
          <div class="additional_contact" *ngFor="
              let contact of additionalContacts;
              let i = index;
              trackBy: trackByFn
            ">
            <input autocomplete="off" type="text" name="contact_value" class="form-control"
              *ngIf="additionalContacts.length - 3 < i" (keyup)="checkForLast(contact, i)"
              [(ngModel)]="additionalContacts[i]" />
            <input autocomplete="off" type="text" name="contact_value" class="form-control"
              *ngIf="additionalContacts.length - 3 >= i" [(ngModel)]="additionalContacts[i]" />
            <div>
              <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
            </div>
            <div>
              <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alarm_actions">
      <div class="alarm-action-flex">
        <button class="btn alarm-action-background" (click)="createAlarm()">
          Submit
        </button>
        <button class="btn btn-light" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div>