import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "workload-disassociate-profile",
  templateUrl: "./disassociate-profile.component.html",
  styleUrls: ["./disassociate-profile.component.css"],
})
export class DisassociateProfileComponent {
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;
  @Input("workload") workload: any;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  @Output() hideModel: any = new EventEmitter<any>();

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  loading: boolean = false;
  profileSelected: any = null;

  errors: any = {
    profile: {
      error: false,
      message: "Please select a profile",
    },
  };

  async disassociateProfile() {
    this.loading = true;

    let data: any = {
      accountId: this.accountId,
      action: "disassociateProfile",
      profileArnList: [this.workload["profile"]["profileARN"]],
      workLoadID: this.workload["aws_id"],
      swayamWId: this.workload["id"],
      lensId: this.workload["lenses"],
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiService.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      this.workload["profile"] = undefined;
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
    }

    this.loading = false;
  }
}
