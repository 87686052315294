import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-delete-role-cams",
  templateUrl: "./delete-role-cams.component.html",
  styleUrls: ["./delete-role-cams.component.css"],
})
export class DeleteRoleCamsComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<any>();
  @Input("regionId") regionId: string;
  @Input("accountId") accountId: string;
  @Input("roleARN") roleARN: string;
  intervalFrequency: string;
  interval: string;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  loading: boolean = false;
  async deleteScanner() {
    this.loading = true;
    let data: any = {
      action: "delete",
      role_arn: this.roleARN,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/account`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.hideModel.emit(true);
      this.notifier.alert(
        "Success",
        "",
        "Role Deleted Successfully",
        "success",
        5000
      );
    } else {
      this.notifier.alert("Info", "", result["errorMessage"], "info", 5000);
    }

    this.loading = false;
  }

  ngOnInit(): void {}
}
