<div class="card" [ngStyle]="{'display': slaSummaryView ? 'block' : 'none'}">
  <h5 class="card-header">First Response SLA</h5>
  <div class="card-body">
    <div class="display-flex">
      <span class="box-shadow-grey">
        <div>
          <div><strong>Jira Project Key</strong></div>
          <div>{{jira_project_key || 'NA'}}</div>
        </div>
        <div>
          <div><strong>Jira Organization Key</strong></div>
          <div>{{jira_org_name || 'NA'}}</div>
        </div>
      </span>
    </div>
    <div class="row">
      <div class="col">
        <div id="graphdiv" class="visualGraph"></div>
      </div>
    </div>
    <div class="row">
      <div class="display-flex-justify-end">
        <div class="display-flex-justify-start">
          <span class="margin-0">Frequency</span>
          <select class="form-select" name="" id="" [(ngModel)]="frequency" (change)="load()">
            <option value="today">Today</option>
            <option value="2days">2 Days</option>
            <option value="3days">3 Days</option>
            <option value="thisweek">This Week</option>
            <option value="thismonth">This Month</option>
          </select>
        </div>
      </div>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let i of firstresponsesla; let j = index">
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i, h.id)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="card" *ngIf="!createTicket" [ngStyle]="{'display': !slaSummaryView ? 'block' : 'none'}">
  <h5 class="card-header">
    <span><button class="btn btn-light" (click)="goBack()">
        < Back</button></span>
    Details: {{detailsOf}}
  </h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of ticketHeaders">{{ h.name }}</th>
          <th *ngIf="writeAccess">Minfy Support</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let i of tickets; let j = index">
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of ticketHeaders">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i, h.id)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>
          <td *ngIf="writeAccess"><span class="btn" (click)="createTicketTrigger(i)">Create Ticket</span></td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="ticketsNextToken" class="display-flex-only-justify-end">
      <button class="btn btn-light blue-button" (click)="loadMoreTickets()">Next</button>
    </div>
  </div>
</div>
<create-ticket *ngIf="createTicket" [createTicketStrictTicketType]="true" [createTicketSupportType]="'minfy'"
  [createTicketSubject]="createTicket.subject" [createTicketDescription]="createTicket.description"
  (hideCreateTicket)="hideCreateTicket($event)"></create-ticket>

<style>
  .visualGraph {
    width: 100%;
    height: 400px;
  }
</style>