<div class="card">
  <div class="card-body">
    <h5 class="card-title">Volumes</h5>
    <div class="card">
      <div class="card-body">
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of volumeHeaders">{{ h.name }}</th>
            </tr>
          </thead>
          <tbody [ngStyle]="{'display': volumeSnapList.length > 0 ? 'table-row-group' : 'none'}">
            <tr *ngFor="let i of volumeSnapList; let j = index">
              <td>{{j+1}}</td>
              <td *ngFor="let h of volumeHeaders" [innerHTML]="i[h.id]">
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{'display': volumeSnapList.length == 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td [attr.colspan]="volumeHeaders.length + 1">
                <div style="text-align: center;">No Volumes Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>