import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";

@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./manage.policies.component.html",
  styleUrls: ["./manage.policies.component.css"],
})
export class PoliciesComponenet implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin" ||
    this.isUserAdmin;
  loading = true;
  policies: any = [];
  updatePolicy: any;
  policyAction: string;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  headers: any = [
    {
      id: "policyName",
      name: "Policy Name",
    },
    {
      id: "mlistStr",
      name: "Service List"
    },
    { id: "accessType", name: "Access Type" },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  services: any = [];
  async load() {
    this.notifier.loading(true);
    let data = {
      clientID: this.isUserAdmin
        ? localStorage.getItem("masterId")
        : this.userId,
      action: "fetchAll",
      latestMenu: true,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/policydetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.policies = result.policyDetails.map((policy: any) => {
        policy['mlistStr'] = (policy['mlist'] ? Object.keys(policy['mlist']) : policy['menuList']).join(', ');
        return policy;
      }) || [];
      this.services =
        this.urlPrefix === 'client'
          ? JSON.parse(localStorage.getItem("menuList"))
          : result.menuList;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  updatePolicyAction(group: any) {
    this.policyAction = "update";
    this.updatePolicy = group;
  }

  async hideUpdatePolicy(event: any) {
    if (event) {
      this.updatePolicy = event;
    }
    await this.load();
    this.updatePolicy = null;
  }

  createPolicy() {
    this.policyAction = "add";
    this.updatePolicy = {
      policyName: "",
      menuList: [],
      accessType: "readOnly",
    };
  }

  callFunction(name: any, param: any) {
    return;
  }
}
