import { Component } from '@angular/core';

@Component({
  selector: 'app-rescanpop',
  templateUrl: './rescanpop.component.html',
  styleUrls: ['./rescanpop.component.css']
})
export class RescanpopComponent {

}
