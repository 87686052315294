import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import { periods } from "./periods";

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  selector: "uptime-reports",
  templateUrl: "./uptime.view.reports.component.html",
  styleUrls: ["./uptime.view.reports.component.css"],
})
export class UptimeReportsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading: boolean = true;
  uptimeList: any = [];
  periods: any = periods;
  reportType: string = "";

  accountId: any;
  regionId: any;
  currentMessage: any;
  destroyed: boolean = false;

  uptimeHeader: any = [
    {
      id: "tagName",
      name: "Instance Name",
    },
    {
      id: "instanceId",
      name: "Instance ID",
    },
    {
      id: "instanceType",
      name: "Instance Type",
    },
    {
      id: "availability_percentage",
      name: "Availability Percentage",
    },
    {
      id: "availability_duration",
      name: "Availability Duration",
    },
    {
      id: "alarm_count",
      name: "Alarm Count",
    },
    {
      id: "down_count",
      name: "Down Count",
    },
    {
      id: "downtime_duration",
      name: "Downtime Duration",
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.reportType = this.periods[0]["id"];

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }
  async load() {
    this.notifier.loading(true);
    this.uptimeList = [];
    await this.loadInstances();
    this.at = "";
  }

  async loadInstances(nextToken: any = "") {
    this.loading = true;
    if (nextToken == "") {
      this.uptimeList = [];
      this.completed = [];
    }

    let data = {
      action: "testListInstancesWithPagination",
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      result.instanceDetails = result.instanceDetails.map((instance: any) => {
        return {
          ...instance,
          ...{
            maintenance_percentage: "LOADING_CHECK",
            alarm_count: "LOADING_CHECK",
            availability_duration: "LOADING_CHECK",
            unmanaged_percentage: "LOADING_CHECK",
            unmanaged_duration: "LOADING_CHECK",
            downtime_duration: "LOADING_CHECK",
            down_count: "LOADING_CHECK",
            availability_percentage: "LOADING_CHECK",
            maintenance_duration: "LOADING_CHECK",
            mttr: "LOADING_CHECK",
            mtbf: "LOADING_CHECK",
            monitorId: "LOADING_CHECK",
            monitorType: "LOADING_CHECK",
            monitorName: "LOADING_CHECK",
          },
        };
      });
      this.uptimeList = [...this.uptimeList, ...result.instanceDetails];
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    if (result.nextToken && result.nextToken != "") {
      await this.loadInstances(result.nextToken);
    } else {
      this.loading = false;
      if (this.uptimeList.length > 0) {
        await this.loadUptimes(this.uptimeList);
      }
    }
  }

  at: any = "";

  completed: string[] = [];
  async loadUptimes(instances: any) {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let account: any = localStorage.getItem("emsConfig");

        if (account) {
          account = JSON.parse(account);
        }
        let data = {
          reportType: "Weekly",
          action: "fetchUptimeReport",
          zaaid: account ? account["zaid"] : site_247_project_id,
          instanceList: instances.map((instance: any) => {
            return instance.tagName;
          }),
          origin: account ? "swayam" : "minfy",
          login_user_name: localStorage.getItem("un"),
          at:
            this.at ||
            "1000.42167bb9a7b1bba25eef6311c7d166e4.ef25cd55a7b58ba97ca86951ebc0581c",
        };
        let header = {
          Authorization: localStorage.getItem("t"),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2cpudetails`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (this.destroyed) {
          return;
        }

        if (result.status == "1" || result.s == "1") {
          result.summaryReportList.forEach(async (summary: any) => {
            let check: any = this.completed.filter((i: string) => {
              return i == summary.name;
            });
            let uptime: any;
            if (check.length > 0) {
              uptime = this.uptimeList.filter((i: any) => {
                return i.tagName == summary.name;
              })[check.length];
            } else {
              uptime = this.uptimeList.find((i: any) => {
                return i.tagName == summary.name;
              });
              this.completed.push(summary.name);
            }
            if (!summary["alarm_count"]) {
              uptime["alarm_count"] = "-";
            }
            uptime = { ...uptime, ...summary };
            let uptimeIndex = this.uptimeList.findIndex((i: any) => {
              return i.instanceId == uptime.instanceId;
            });
            this.uptimeList[uptimeIndex] = uptime;
            this.at = result.at;
          });
        } else {
          instances.forEach((instance: any) => {
            let uptimeIndex = this.uptimeList.findIndex((i: any) => {
              return i.tagName == instance.tagName;
            });
            this.uptimeList[uptimeIndex] = {
              ...instance,
              ...{
                maintenance_percentage: "-",
                alarm_count: "-",
                availability_duration: "-",
                unmanaged_percentage: "-",
                unmanaged_duration: "-",
                downtime_duration: "-",
                down_count: "-",
                availability_percentage: "-",
                maintenance_duration: "-",
                mttr: "-",
                mtbf: "-",
                monitorId: "-",
                monitorType: "-",
                monitorName: "-",
              },
            };
          });
          this.notifier.alert("Error", "", result.error, "error", 5000);
        }
      } else {
        this.notifier.alert(
          "Error",
          "",
          selectedAccountData.msg,
          "error",
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert("Error", "", err.msg, "error", 5000);
    }

    this.uptimeList.forEach((instance: any, index: number) => {
      if (this.completed.indexOf(instance["tagName"]) <= -1) {
        this.uptimeList[index] = {
          ...instance,
          ...{
            maintenance_percentage: "-",
            alarm_count: "-",
            availability_duration: "-",
            unmanaged_percentage: "-",
            unmanaged_duration: "-",
            downtime_duration: "-",
            down_count: "-",
            availability_percentage: "-",
            maintenance_duration: "-",
            mttr: "-",
            mtbf: "-",
            monitorId: "-",
            monitorType: "-",
            monitorName: "-",
          },
        };
      }
    });
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel("uptimeTable", "Uptime Report", "csv");
  }

  callFunction(id: any, id1: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
