<div class="main" #captureView>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bt p-3 d-flex justify-content-between">
                    <div *ngIf="ticketFound" class="m-top">
                        <b>{{ jirakey }}</b>
                    </div>
                    <div *ngIf="!ticketFound" class="m-top">
                        <p class="bg-red"><i class="fa-solid fa-circle-info"></i>&nbsp;Minfy managed service is not enabled</p>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div class="btn-hold" *ngIf="ticketFound" #projectkey>
                            <button  class="btn btn-key d-flex justify-content-between" (click)="onProjectKey()" [ngClass]="{'disabled':current_month_loader || open_ticket_loader || last_7_days_loader || TR_loader}"><i class="fa-solid fa-square-check" (click)="selectAllKey()" *ngIf="selectedKeys.length != testKeys.length"></i><b class="pro-title">Projects</b><i class="fa-solid fa-caret-down"></i></button>
                        <div class="opt" [class.keyopen]="isProjectKey">
                            <!-- <span (click)="captureAsImage()" class="png d-flex" ><i class="fa-solid fa-file-image"></i> PNG</span> -->
                            <ng-container *ngFor="let i of selectedKeys; let j = index">
                            <span *ngIf="ticketFound" class="csv d-flex"><input type="checkbox" [checked]="i.selected" (change)="onCheckboxChange($event, i.id)" id="check" [disabled]="testKeys.length===1 && i.selected" class="form-check-input">&nbsp;&nbsp;<span for="check">{{i.id}}</span></span>
                        </ng-container>
                            
                        </div>
                        </div>

                        <div class="dropdown d-flex justify-content-between">
                            <div #dropdownContainer>
                                <div class="dropdown-toggle" (click)="toggleDropdown()" [ngClass]="{'disabled':current_month_loader || open_ticket_loader || last_7_days_loader || TR_loader}">
                                    <span class="drop-opt"><i class="fa-solid fa-calendar-day"></i>&nbsp;{{
                                        selectedOption
                                        }}</span>
                                    <span class="dropdown-icon">&#9662;</span>
                                </div>
                                <div class="dropdown-menu" [class.open]="isDropdownOpen" >
                                    <div class="dropdown-option" *ngFor="let option of options"
                                        [class.selected]="option === selectedOption" (click)="selectedDate(option.key)">
                                        {{ option.key }}
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="customDateView" class="datepick" [ngClass]="{'disabled':current_month_loader || open_ticket_loader || last_7_days_loader || TR_loader}">
                                <input autocomplete="off" class="datetimepicker" type="text" [(ngModel)]="dateRange" />
                            </div>
                        </div>
                        <!-- <div (click)="captureAsImage()" data-toggle="tooltip" title="save screenshot"><i class="fa-solid fa-download"></i></div> -->

                        <!-- <div class="dropdown"> <button class="btn btn-secondary dropdown-toggle" type="button"
                                id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"> Dropdown button </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"> <a class="dropdown-item"
                                    href="#">Action</a> <a class="dropdown-item" href="#">Another action</a> <a
                                    class="dropdown-item" href="#">Something else here</a> </div>
                        </div> -->
                        <!-- <div><select class="dropdown-toggle2" [formControl] ="export" >
                           
                                <option value="Export" (click)="captureAsImage()" >PNG</option>
                                <option value="Export">CSV</option>
                                
                            </select> </div> -->
                            
                            <div class="btn-hold" *ngIf="ticketFound" #export>
                                <button  class="btn btn-success-soft" (click)="onExport()" [ngClass]="{'disabled':current_month_loader || open_ticket_loader || last_7_days_loader || TR_loader}"><i class="fa-solid fa-download"></i>Export</button>
                            <div class="opt" [class.open]="isExportOpen">
                                <!-- <span (click)="captureAsImage()" class="png d-flex" ><i class="fa-solid fa-file-image"></i> PNG</span> -->
                                <span *ngIf="ticketFound" (click)="exportCSV()" class="csv d-flex"><i class="fa-solid fa-file-csv"></i>CSV</span>
                            </div>
                            </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-3 col-sm-3 p-1">
            <div class="card">
                <div class="card-header bt d-flex justify-content-between">
                    <b>Tickets Based On Priority</b>
                    <!-- <div *ngIf="open_ticket_loader">
                        <img src="../assets/img/loading_2.svg" alt="loading scans" class="miniloader2" />
                    </div> -->
                    <!-- <div >
                       <div class="openTickets"  data-toggle="tooltip" data-placement="up" [title]="openTicketmsg"></div>  
                    </div> -->
                </div>
                <div class="card-body height-10 p-2">
                    <div class="row p-3 bb">
                        <div class="col d-flex flex-column rb">
                            <span class="text-center t-13"><b>{{ ticketPriority.critical || 0 }}</b></span>
                            <div class="text-center">
                                <span class="badge badge-danger">Critical</span>
                            </div>
                        </div>
                        <div class="col d-flex flex-column">
                            <span class="text-center t-13"><b>{{ ticketPriority.high || 0 }}</b></span>
                            <div class="text-center">
                                <span class="badge badge-warning">High</span>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3 bb">
                        <div class="col d-flex flex-column rb">
                            <span class="text-center t-13"><b>{{ ticketPriority.medium || 0 }}</b></span>
                            <div class="text-center">
                                <span class="badge badge-warningg">Medium</span>
                            </div>
                        </div>
                        <div class="col d-flex flex-column">
                            <span class="text-center t-13"><b>{{ ticketPriority.low || 0 }}</b></span>
                            <div class="text-center">
                                <span class="badge badge-secondary">Low</span>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col d-flex justify-content-center flex-column">
                            <span class="text-center t-13"><b>{{ ticketPriority.open_tickets || 0 }}</b></span>
                            <div class="text-center">
                                <span class="badge badge-success">Open Tickets</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-9 col-sm-9 p-1">
            <div class="d-flex flex-column">
                <div class="card">
                    <!-- <div class="card-header bt"><b>SLA First Response (FR) and Time to Resolve (TR)</b></div> -->
                    <div class="card-header bt d-flex justify-content-between">
                        <div class="d-flex">
                            <b>SLA First Response (FR) and Time to Resolve (TR)</b>
                            <div class="d-flex nodata" *ngIf="TR_nodata">
                                <div><i class="fa-solid fa-circle-info bg-yellow"></i></div>
                                <div class="">{{ selectedOption }} data is not available</div>
                            </div>
                        </div>
                        <!-- <div *ngIf="TR_loader">
                            <img src="../assets/img/loading_2.svg" alt="loading scans" class="miniloader2" />
                        </div> -->
                        <!-- <div >
                            <div class="openTickets"  data-toggle="tooltip" data-placement="up" [title]="openTicketmsg"></div>  
                         </div> -->
                    </div>
                    <div class="card-body p-2">
                        <div class="row ml">
                            <div class="col-2 d-flex flex-column frHold">
                                <span class="frNo">{{
                                    TR_list.TR_breached +
                                    TR_list.TR_met +
                                    TR_list.FR_breached +
                                    TR_list.FR_met || 0
                                    }}</span>
                                <span class="frDse">Total</span>
                            </div>
                            <div class="col-2 d-flex flex-column frHold">
                                <span class="frNo">{{ TR_list.TR_met || 0 }}</span>
                                <span class="frDse">TR Met</span>
                            </div>
                            <div class="col-2 d-flex flex-column frHold">
                                <span class="frNo">{{ TR_list.TR_breached || 0 }}</span>
                                <span class="frDse">TR Breached</span>
                            </div>
                            <div class="col-2 d-flex flex-column frHold">
                                <span class="frNo">{{ TR_list.FR_met || 0 }}</span>
                                <span class="frDse">FR Met</span>
                            </div>
                            <div class="col-2 d-flex flex-column frHold">
                                <span class="frNo">{{ TR_list.FR_breached || 0 }}</span>
                                <span class="frDse">FR Breached</span>
                            </div>
                        </div>
                        <div class="frgraph" id="frGraph" *ngIf="!TR_nodata"></div>
                    </div>
                </div>
                <!-- <div class="card">
            <div class="card-header bt"><b>Age Chart Last 15 Days</b></div>
            <div class="card-body p-2">
                <div class="frgraph" id="ageGraph"></div>
            </div>
        </div> -->
            </div>
        </div>
        <!-- <div class="col-3 col-sm-3 p-1">
        <div class="card">
        <div class="card-header bt d-flex justify-content-between"><b>Tickets Based On Priority</b> 
            <div *ngIf="open_ticket_loader"><img src="../assets/img/loading_2.svg"
            alt="loading scans" class="miniloader2" /></div>
        </div>
        <div class="card-body p-2">
           <div class="row p-3 bb">
            <div class="col d-flex flex-column rb">
                <span class="text-center t-13"><b>{{ticketPriority.critical || 0}}</b></span>
                <div class="text-center">  <span class="badge badge-danger">Critical</span> </div>
            </div>
            <div class="col d-flex flex-column">
                <span class="text-center t-13"><b>{{ticketPriority.high || 0}}</b></span>
                <div class="text-center"> <span class="badge badge-warning">High</span></div>
            </div>
           </div>
           <div class="row p-3 bb">
            <div class="col d-flex flex-column rb">
                <span class="text-center t-13"><b>{{ticketPriority.medium || 0}}</b></span>
                <div class="text-center"><span class="badge badge-warningg">Medium</span></div>
            </div>
            <div class="col d-flex flex-column">
                <span class="text-center t-13"><b>{{ticketPriority.low || 0}}</b></span>
                <div class="text-center"> <span class="badge badge-secondary">Low</span></div>
            </div>
           </div>
           <div class="row p-3">
            <div class="col d-flex justify-content-center flex-column">
                <span class="text-center t-13"><b>{{ticketPriority.open_tickets || 0}}</b></span>
                <div class="text-center"><span class="badge badge-success">Open Tickets</span></div>
            </div>
           </div>
        </div>
        </div>
    </div> -->
    </div>

    <div class="row">
        <div class="col-7 col-sm-7 p-1">
            <div class="d-flex flex-column">
                <div class="card">
                    <div class="card-header bt d-flex justify-content-between">
                        <b>{{ selectedOption }} Tickets and Priority</b>
                        <!-- <div *ngIf="current_month_loader">
                            <img src="../assets/img/loading_2.svg" alt="loading scans" class="miniloader2" />
                        </div> -->
                        <!-- <div >
                            <div class="openTickets"  data-toggle="tooltip" data-placement="up" [title]="openTicketmsg"></div>  
                         </div> -->
                    </div>
                    <div class="card-body p-2">
                        <div class="table-responsive">
                            <table class="table table-hover mb-0">
                                <thead class="blue">
                                    <tr>
                                        <th scope="col">Issue Type</th>
                                        <th scope="col">Critical</th>
                                        <th scope="col">High</th>
                                        <th scope="col">Medium</th>
                                        <th scope="col">Low</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let i of result">
                                        <tr *ngIf="ticketFound" [ngClass]="{ lightblue: i.last == true }">
                                            <td>{{ i.issue_type }}</td>
                                            <td>{{ i.critical }}</td>
                                            <td>{{ i.high }}</td>
                                            <td>{{ i.medium }}</td>
                                            <td>{{ i.low }}</td>
                                            <td class="lightblue">{{ i.total }}</td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="!ticketFound">
                                        <td colspan="5" class="text-center">No Tickets Found!</td>
                                    </tr>
                                    <!-- <tr class="lightblue">
                                    <td>Total Unique Issues:</td>
                                    <td class="text-center">8</td>
                                    <td class="text-center">18</td>
                                    <td class="text-center">84</td>
                                    <td class="text-center">110</td>
                                  </tr>           -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5 col-sm-5 p-1">
            <div class="card">
                <div class="card-header bt d-flex justify-content-between">
                    <b>Last 7 Days Created vs Resolved Trend</b>
                    <!-- <div *ngIf="last_7_days_loader">
                        <img src="../assets/img/loading_2.svg" alt="loading scans" class="miniloader2" />
                    </div> -->
                    <!-- <div >
                        <div class="openTickets"  data-toggle="tooltip" data-placement="up" [title]="openTicketmsg"></div>  
                     </div> -->
                </div>
                <div *ngIf="ticketFound" class="card-body p-2">
                    <div class="frgraph" id="ldGraph"></div>
                </div>
                <table *ngIf="!ticketFound" class="table">
                    <tbody>
                        <tr>
                            <td colspan="2" class="text-center">No Tickets Found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="col-3 col-sm-3 p-1">
            <div class="card">
                <div class="card-header bt"><b>Age Chart Last 15 Days</b></div>
                <div class="card-body p-2">
                    <div class="frgraph" id="ageGraph"></div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="main-loader d-none">
        <div class="openTicket"  data-toggle="tooltip" data-placement="up" [title]="openTicketmsg"></div>  
        <div class="text-progress">{{openTicketmsg}}</div>
        <div class="text-loading">Loading <span class="dot-1">.</span><span class="dot-2">.</span><span class="dot-3">.</span></div>
     </div>
</div>
<!-- <i class="fa-solid fa-angles-up"></i>&nbsp;
    <i class="fa-solid fa-angle-up"></i>&nbsp;
    <i class="fa-solid fa-equals"></i>&nbsp; -->