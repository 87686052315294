<div class="login-container" *ngIf="whitelabelData && whitelabelData['pd']">
    <div class="login-section">
        <div class="login-screen">
            <div class="login-form-container">
                <div class="image-container">
                    <img alt=""
                        [attr.src]="whitelabelData['pd']['logoDark']">
                </div>
                <div class="login-body">
                    <div *ngIf="page == 'details'" class="login-banner">
                        Welcome to {{ whitelabelData['pd']['appName'] }}!
                    </div>
                    <div *ngIf="page == 'password'" class="login-banner">
                        Set a Password
                    </div>
                    <div *ngIf="page == 'password'" class="login-short-info">
                        Almost done. Set a password to your account and you're all set.
                    </div>
                    <ng-container *ngIf="!otpSent && page == 'details'">
                        <div class="login-cred">
                            <div class="line">

                            </div>
                            <div class="login-cred-text">
                                Register
                            </div>
                            <div class="line">

                            </div>
                        </div>
                        <div class="margin-bottom-10" *ngIf="role != 'client' || this.regToken">
                            <div class="grid-2">
                                <div>
                                    <div class="email-container">
                                        <i class="fa fa-user left-align"></i>
                                        <input [(ngModel)]="firstName"
                                            [attr.disabled]="loading['register'] ? true : null" autocomplete="off"
                                            class="form-control" placeholder="First Name" type="text">
                                    </div>
                                    <div *ngIf="errors['firstName']['error']" class="error-message">
                                        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                        errors['firstName']['message'] }}
                                    </div>
                                </div>
                                <div>
                                    <div class="email-container">
                                        <i class="fa fa-user left-align"></i>
                                        <input [(ngModel)]="lastName"
                                            [attr.disabled]="loading['register'] ? true : null" autocomplete="off"
                                            class="form-control" placeholder="Last Name" type="text">
                                    </div>
                                    <div *ngIf="errors['lastName']['error']" class="error-message">
                                        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                        errors['lastName']['message'] }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="margin-bottom-10">
                            <div class="email-container">
                                <i class="fa fa-envelope left-align"></i>
                                <input [(ngModel)]="emailId" [attr.disabled]="loading['register'] ? true : null"
                                    autocomplete="off" class="form-control" placeholder="Email Address" type="email">
                            </div>
                            <div *ngIf="errors['emailId']['error']" class="error-message">
                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                errors['emailId']['message'] }}
                            </div>

                        </div>
                        <div class="margin-bottom-10">
                            <div *ngIf="role != 'client' || this.regToken" class="email-container">
                                <i class="fa fa-building left-align"></i>
                                <input [(ngModel)]="cmpName" [attr.disabled]="loading['register'] ? true : null"
                                    autocomplete="off" class="form-control" placeholder="Company Name" type="text">
                            </div>
                            <div *ngIf="errors['cmpName']['error']" class="error-message">
                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                errors['cmpName']['message'] }}
                            </div>

                        </div>
                        <div class="margin-bottom-10">
                            <div *ngIf="role != 'client' || this.regToken" class="email-container">
                                <i class="fa fa-briefcase left-align"></i>
                                <input [(ngModel)]="designation" [attr.disabled]="loading['register'] ? true : null"
                                    autocomplete="off" class="form-control" placeholder="Designation" type="text">
                            </div>
                            <div *ngIf="errors['designation']['error']" class="error-message">
                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                errors['designation']['message'] }}
                            </div>

                        </div>
                        <ng-container *ngIf="role == 'client' || this.regToken">
                            <div class="email-container margin-bottom-10">
                                <i class="fa fa-map-marker left-align" aria-hidden="true"></i>
                                <select [(ngModel)]="country" class="form-select" placeholder="Country"
                                    (change)="countryChange()">
                                    <option value="{{ country['name'] }}" *ngFor="let country of countryCodesObj">
                                        {{ country['name'] }}
                                    </option>
                                </select>
                            </div>
                            <div class="email-container margin-bottom-10">
                                <i class="fa fa-clock left-align" aria-hidden="true"></i>
                                <select [(ngModel)]="timeZone" class="form-select" placeholder="Time Zone">
                                    <option value="(UTC {{ getOffsetTimeInMinutes(zone) }}) {{ zone['name'] }}"
                                        *ngFor="let zone of getTimeZones()">
                                        (UTC {{ getOffsetTimeInMinutes(zone) }}) {{ zone['name'] }}
                                    </option>
                                </select>
                            </div>
                            <div class="email-container margin-bottom-10">
                                <i class="fa fa-briefcase left-align"></i>
                                <select class="form-select" placeholder="Source" [(ngModel)]="source">
                                    <option value="Direct">Direct</option>
                                    <option value="WAR">WAR</option>
                                    <option value="SSB">SSB</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            <div class="margin-bottom-10">
                                <div class="email-container">
                                    <i class="fa fa-ticket left-align" aria-hidden="true"></i>
                                    <input class="form-control" placeholder="Coupon (Optional)" [(ngModel)]="coupon">
                                </div>
                                <div *ngIf="errors['coupon']['error']" class="error-message">
                                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                    errors['coupon']['message']
                                    }}
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="role != 'client' || this.regToken">
                            <div class="flex-start">
                                <span [attr.data-placeholder]="selectedCountryCode" class="relative">
                                    <select (change)="selectCode($event)" [(ngModel)]="selectedCountryCode"
                                        class="form-select width-auto" placeholder="Country Code">
                                        <option *ngFor="let country of countryCodesObj"
                                            [attr.selected]="selectedCountryCode == country.dial_code ? true : null"
                                            value="{{country.dial_code}}">
                                            {{ country.name }} ({{
                                            country.dial_code }})
                                        </option>
                                    </select>
                                </span>
                                <div *ngIf="role != 'client' || this.regToken"
                                    class="email-container margin-bottom-10 flex-1">
                                    <i class="fa fa-phone left-align"></i>
                                    <input [(ngModel)]="phoneNumber" [attr.disabled]="loading['register'] ? true : null"
                                        autocomplete="off" class="form-control" placeholder="Phone Number" type="text">
                                </div>

                            </div>
                            <div *ngIf="errors['phoneNumber']['error']" class="error-message">
                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                errors['phoneNumber']['message'] }}
                            </div>
                        </div>

                        <!-- <div class="email-container margin-bottom-10" *ngIf="role != 'client' || this.regToken">
                              <i class="fa fa-briefcase left-align"></i>
                              <input type="text" [attr.disabled]="loading['register'] ? true : null" [(ngModel)]="designation"
                                placeholder="Designation" class="form-control" autocomplete="off">
                            </div> -->

                        <div class="login-actions">
                            <button (click)="next()" [attr.disabled]="tokenExpired['checking'] ? true : null"
                                *ngIf="!loading['register']" class="btn btn-minfy-color">Next
                            </button>
                            <div class="flex-center font-0-8">
                                Already have Account? <a class="nav-link" routerLink="/login">Login</a>
                            </div>
                            <img [ngStyle]="{display: loading['register'] ? 'inline': 'none'}" alt="" class="width-18"
                                src="./assets/img/loading_2.svg">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="page == 'password' && !otpSent">
                        <div class="margin-bottom-10 margin-top-10">
                            <div class="password-container">
                                <i class="fa fa-lock left-align"></i>
                                <input [(ngModel)]="password" [attr.disabled]="loading['register'] ? true: null"
                                    [attr.type]="showPassword ? 'text' : 'password'" autocomplete="off"
                                    class="form-control paddingleft" placeholder="Password">
                                <i (click)="alterPassword()" *ngIf="!showPassword" aria-hidden="true"
                                    class="fa fa-eye right-align"></i>
                                <i (click)="alterPassword()" *ngIf="showPassword" aria-hidden="true"
                                    class="fa fa-eye-slash right-align"></i>
                            </div>
                            <div *ngIf="errors['password']['error']" class="error-message">
                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                errors['password']['message']
                                }}
                            </div>
                        </div>
                        <div class="password-policy-container">
                            <div *ngFor="let policy_key of getKeys(passwordChecker(password)['policies']); let i = index"
                                [ngClass]="{correct: passwordChecker(password)['policies'][policy_key]}"
                                class="password-policy flex-start flex-start-center">
                                <i *ngIf="!passwordChecker(password)['policies'][policy_key]" aria-hidden="true"
                                    class="fa fa-times-circle"></i>
                                <i *ngIf="passwordChecker(password)['policies'][policy_key]" aria-hidden="true"
                                    class="fa fa-check-circle"></i>
                                <span>
                                    {{ policy_key }}
                                </span>
                            </div>
                        </div>
                        <div class="margin-bottom-10">
                            <div class="password-container">
                                <i class="fa fa-lock left-align"></i>
                                <input [(ngModel)]="confPassword" [attr.disabled]="loading['register'] ? true: null"
                                    [attr.type]="showPassword ? 'text' : 'password'" autocomplete="off"
                                    class="form-control paddingleft" placeholder="Confirm Password">
                                <i (click)="alterPassword()" *ngIf="!showPassword" aria-hidden="true"
                                    class="fa fa-eye right-align"></i>
                                <i (click)="alterPassword()" *ngIf="showPassword" aria-hidden="true"
                                    class="fa fa-eye-slash right-align"></i>
                            </div>
                            <div *ngIf="errors['confPassword']['error']" class="error-message">
                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                errors['confPassword']['message'] }}
                            </div>
                        </div>
                        <div class="login-actions">
                            <button (click)="register()" *ngIf="!loading['register']"
                                [attr.disabled]="tokenExpired['checking'] ? true : null"
                                class="btn btn-minfy-color">Register
                            </button>
                            <img [ngStyle]="{display: loading['register'] ? 'inline': 'none'}" alt="" class="width-18"
                                src="./assets/img/loading_2.svg">
                        </div>
                        <div>
                            <span (click)="page = 'details'" class="nav-link flex-start flex-start-center">
                                <i aria-hidden="true" class="fa fa-arrow-left"></i>
                                <span class="color-inherit">
                                    Back to register
                                </span>
                            </span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="otpSent">
                        <div class="info">
                            Please enter the OTP that you have received from <br> <span
                                class="text-bold">{{ resend }}</span>
                        </div>
                        <div class="margin-bottom-10">
                            <div class="email-container">
                                <i class="fa fa-key left-align"></i>
                                <input (keyup)="otpValidation()" [(ngModel)]="otp"
                                    [attr.disabled]="loading['otp'] ? true : null" autocomplete="off"
                                    class="form-control" placeholder="OTP" type="text">
                            </div>
                            <div *ngIf="errors['otp']['error']" class="error-message">
                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i> {{
                                errors['otp']['message'] }}
                            </div>
                        </div>
                        <div class="flex-end">
                            <span (click)="register(false)" *ngIf="!loading['register']" class="nav-link">Resend
                                OTP</span>
                            <img [ngStyle]="{display: loading['register'] ? 'inline': 'none'}" alt="" class="width-14"
                                src="./assets/img/loading_2.svg">
                        </div>
                        <div class="login-actions">
                            <button (click)="otpValidation($event)" *ngIf="!loading['otp']"
                                [attr.disabled]="tokenExpired['checking'] ? true : null"
                                class="btn btn-minfy-color">Validate
                            </button>
                            <img [ngStyle]="{display: loading['otp'] ? 'inline': 'none'}" alt="" class="width-18"
                                src="./assets/img/loading_2.svg">
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="login-carousel">
                <div [ngStyle]="{'margin-left': '-' + this.currentImage * 100 + '%'}" class="carousel-container">
                    <div *ngFor="let item of carousel" class="carousel padding-bottom-0">
                        <img [attr.src]="item['image']" alt="">
                    </div>
                </div>
                <div [ngStyle]="{'margin-left': '-' + this.currentImage * 100 + '%'}" class="carousel-container">
                    <div *ngFor="let item of carousel" class="carousel padding-top-0 padding-bottom-0">
                        <div>
                            <h4 class="title">{{item['title']}}</h4>
                            <p class="description">{{ item['description'] }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex-center m-2">
                    <div (click)="setImage(i)" *ngFor="let _ of carousel; let i = index"
                        [ngClass]="{'active-dot': this.currentImage == i}" class="carousel-dot">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>