import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class ChecksHomeComponent implements OnInit, OnDestroy {
  selected: any = null;
  checkGroups: any = [
    {
      name: "Advanced Cost Optimisation",
      slug: "cost_optimization",
      api: "/apis/operations/costoptimization",
      scan_required: true,
      extras: {
        last_1_month_billing: true,
        last_6_months_billing: true,
        daily_cost_variance_report_link: true,
      },
    },
    {
      name: "Advanced Security Protocol",
      slug: "security",
      api: "/apis/operations/costoptimization/security",
      scan_required: true,
      extras: {
        view: "ssb",
      },
    },
    {
      name: "Landing Zone Assessment",
      slug: "landing_zone",
      api: "/apis/operations/costoptimization/landingzone",
      scan_required: true,
    },
    {
      name: "EKS Security Best Practices",
      slug: "eks_security",
      api: "/apis/operations/costoptimization/eks",
      scan_required: false,
      other: {
        payload: {
          eks_type: "security",
        },
      },
    },
    {
      name: "EKS Reliability Best Practices",
      slug: "eks_reliability",
      api: "/apis/operations/costoptimization/eks",
      scan_required: false,
      other: {
        payload: {
          eks_type: "reliability",
        },
      },
    },
  ];

  subscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {

    this.subscription = this.route.fragment.subscribe((param: any) => {
      if (!param) {
        this.selected = this.checkGroups[0];
      } else {
        let temp = this.checkGroups.find((check: any) => {
          return check["slug"] == param;
        });
        if (temp) {
          this.selected = temp;
        } else {
          this.router.navigate(["/dash/security/advanced-security-protocol"], {
            fragment: this.checkGroups[0]["slug"],
          });
        }
      }
    });
    // this.type_value.setype(this.selected);
    // console.log("type value in home", this.selected);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
