import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "workload-delete-profile",
  templateUrl: "./delete-profile.component.html",
  styleUrls: ["./delete-profile.component.css"],
})
export class DeleteProfileComponent implements OnInit {
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;
  @Input("profile") profile: any;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  loading: boolean = false;

  @Output() hideModel: any = new EventEmitter<any>();

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
  }

  async disassociateProfile(workload: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let data: any = {
        accountId: this.accountId,
        action: "disassociateProfile",
        profileArnList: [this.profile["profileARN"]],
        workLoadID: workload,
      };

      let headers: any = {
        Authorization: localStorage.getItem("t"),
      };

      let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

      let result = await this.apiService.postDataPromis(apiURL, data, headers);

      if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      } else {
        this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
      }
      resolve(true);
    });
  }

  async disassociateAll() {
    return new Promise((resolve: any, reject: any) => {
      let promises: Promise<any>[] = [];
      this.profile["workloads"].forEach((workload: any) => {
        promises.push(this.disassociateProfile(workload));
      });
      Promise.all(promises).then((values: any) => {
        resolve(true);
      });
    });
  }

  async deleteProfile() {
    this.loading = true;
    if (this.profile["workloads"].length > 0) {
      await this.disassociateAll();
    }
    let data: any = {
      action: "deleteProfile",
      accountId: this.accountId,
      profileArn: this.profile.profileArn,
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiService.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      this.hideModel.emit(true);
    }
    this.loading = false;
  }
}
