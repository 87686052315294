<div class="leftmenu">
  <div class="offcanvas-body px-0">



    <button class="menuCloseButton closeButton" (click)="hideLeftMenu()" id="menuClosebtn">
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button class="showLeftMenu" (click)="showLeftMenu()" id="menuShowbtn">
      <i class="fa fa-arrow-circle-right"></i>
    </button>


    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">

      <li class="dropdown" *ngIf="checkIfAvaForUser('cbs')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Anomalies.svg" alt=""
            class="sdropDown"> CBS
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">CBS</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:masterSummary')">
            <a class="dropdown-item" routerLink="/cbs/mastersummary">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                class="sdropdown-item" alt=""><span>Master Summary</span>
            </a>
          </li>
        </ul>
      </li>
      <!--<li class="nav-item" *ngIf="checkIfAvaForUser('BackupReport')">
    <a routerLink="/dash/backupreport" class="nav-link text-truncate" title="Backup Report">
      <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Backup.svg"
           style="width: 40px;" alt=""> Backup Report
    </a>
  </li>-->


    </ul>
  </div>
</div>

<div class="snavbar">
  <div class="row">
    <div class="col snavbar_under">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">

          <div class="nav-item nav_item_css">
            <a routerLink="/dash/" class="nav-link text-truncate routing_Link">
              <img class="rlink_img" src="../assets/img/swayam_logo_v3.0.png" />
            </a>
          </div>

          <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto ">
              <li class="nav-item dropdown">
                <a class="d-block text-decoration-none dropdown-toggle dropDown_user" id="dropdownUser1"
                  data-bs-toggle="dropdown" aria-expanded="true">
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <span class="user_name">{{username}}</span>
                </a>
                <ul class="dropdown-menu text-small dropDown_user1" aria-labelledby="dropdownUser1"
                  data-popper-placement="bottom-start">

                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                  <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><button class="dropdown-item" (click)="logout()">Sign out</button></li>
                </ul>
              </li>

            </ul>

            <div class="dropdown text-end"></div>

            <div class="dropdown_end"></div>

          </div>

        </div>

      </nav>


    </div>
  </div>
</div>


<div class="content  min-vh-100 rightcontent">
  <router-outlet></router-outlet>
</div>




<!--<router-outlet></router-outlet>-->