
<main class="main" id="top">
  <div class="container" data-layout="container">
    <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand-xl hideonPrint" >
      <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>

      <a class="navbar-brand me-1 me-sm-3" href="/dashc">
        <div class="d-flex align-items-center"><img class="me-2" src="../assets/img/2.png" alt="" width="50" />     
        </div>
      </a>
      <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
        <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dashboards">Dashboard</a>
            <div class="dropdown-menu dropdown-menu-card border-0 mt-0" aria-labelledby="dashboards">
              <div class="bg-white dark__bg-1000 rounded-3 py-2">
                <a class="dropdown-item link-600 fw-medium" href="/dashc/fdw-biodata">FDW BioData</a>
                <!--<a class="dropdown-item link-600 fw-medium" href="/dashc/biodata">Bio-Data</a>
  <a class="dropdown-item link-600 fw-medium" href="/dashc/authorisation">Authorisation</a>
  <a class="dropdown-item link-600 fw-medium" href="/dashc/authorisationprint">Authorization Print</a>
  <a class="dropdown-item link-600 fw-medium" href="/dashc/service-fees">Service Fees</a>
  <a class="dropdown-item link-600 fw-medium" href="/dashc/service-agreement">Service Agreement</a>-->
                <!--<a class="dropdown-item link-600 fw-medium" href="../dash/employer">Employer</a>
  <a class="dropdown-item link-600 fw-medium" href="../dash/employee">Employee</a>
  <a class="dropdown-item link-600 fw-medium" href="../dash/agreements">Agreements</a>
  <a class="dropdown-item link-600 fw-medium" href="../dash/agreements">Invoices</a>-->

              </div>
            </div>
          </li>     
        </ul>
      </div>
      <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
        <!--<li class="nav-item">
          <a class="nav-link pe-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait" href="../app/e-commerce/shopping-cart.html">
          <span class="fas fa-shopping-cart" data-fa-transform="shrink-7" style="font-size: 25px; color: #5555557a !important;"></span>
          <span class="notification-indicator-number">1</span>
          </a>
        </li>-->

        <li class="nav-item dropdown" style="margin-right: 10px;">
          <a class="nav-link notification-indicator notification-indicator-primary pe-0 fa-icon-wait" id="navbarDropdownNotification" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fas fa-bell" data-fa-transform="shrink-6" style="font-size: 25px; color: #5555557a!important;"></span></a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification" aria-labelledby="navbarDropdownNotification">
            <div class="card card-notification shadow-none">
              <div class="card-header">
                <div class="row justify-content-between align-items-center">
                  <div class="col-auto">
                    <h6 class="card-header-title mb-0">Notifications</h6>
                  </div>
                  <div class="col-auto ps-0 ps-sm-3"><a class="card-link fw-normal" href="#">Mark all as read</a></div>
                </div>
              </div>
              <div class="scrollbar-overlay" style="max-height:19rem">
                <div class="list-group list-group-flush fw-normal fs--1">
                  <div class="list-group-title border-bottom">NEW</div>
                  <div class="list-group-item">
                    <a class="notification notification-flush notification-unread" href="#!">
                      <div class="notification-avatar">
                        <div class="avatar avatar-2xl me-3">
                          <img class="rounded-circle" src="../assets/img/team/1-thumb.png" alt="" />
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1"><strong>Emma</strong> replied to your comment : "Hello world 😍"</p>
                        <span class="notification-time"><span class="me-2" role="img" aria-label="Emoji">💬</span>Just now</span>
                      </div>
                    </a>
                  </div>
                  <div class="list-group-item">
                    <a class="notification notification-flush notification-unread" href="#!">
                      <div class="notification-avatar">
                        <div class="avatar avatar-2xl me-3">
                          <div class="avatar-name rounded-circle"><span>AB</span></div>
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1"><strong>Albert Brooks</strong> reacted to <strong>MM's</strong> status</p>
                        <span class="notification-time"><span class="me-2 fab fa-gratipay text-danger"></span>9hr</span>
                      </div>
                    </a>
                  </div>
                  <div class="list-group-title border-bottom">EARLIER</div>
                  <div class="list-group-item">
                    <a class="notification notification-flush" href="#!">
                      <div class="notification-avatar">
                        <div class="avatar avatar-2xl me-3">
                          <img class="rounded-circle" src="../assets/img/icons/weather-sm.jpg" alt="" />
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1">The forecast today shows a low of 20&#8451; in California. See today's weather.</p>
                        <span class="notification-time"><span class="me-2" role="img" aria-label="Emoji">🌤️</span>1d</span>
                      </div>
                    </a>
                  </div>        
                </div>
              </div>
              <div class="card-footer text-center border-top"><a class="card-link d-block" href="../app/social/notifications.html">View all</a></div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link pe-0" id="navbarDropdownUser" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="avatar avatar-xl">
              <img class="rounded-circle" src="../assets/img/avatar.png" alt="" />
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
            <div class="bg-white dark__bg-1000 rounded-2 py-2">
              <!--<a class="dropdown-item fw-bold text-warning" href="#!"><span class="fas fa-crown me-1"></span><span>Go Pro</span></a>
              <div class="dropdown-divider"></div>-->
              <!--<a class="dropdown-item" href="#!">Set status</a>-->
              <a class="dropdown-item" href="/dashc/change-password">Change Password</a>
              <!--<a class="dropdown-item" href="#!">Feedback</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="user/settings.html">Settings</a>-->
              <a class="dropdown-item" href="/login">Logout</a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <div class="content">
    

      <router-outlet></router-outlet>

      <footer class="hideonPrint">
        <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">
              &copy; 2021 .
              <span class="d-none d-sm-inline-block">| </span>
              <br class="d-sm-none" /> Powered by
              <a target="_blank" href=""></a>
            </p>
          </div>
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">v2.0.9</p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</main>



