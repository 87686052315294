<table class="table table-docs">
  <thead>
    <tr>
      <th>Sl. No.</th>
      <th>Name</th>
      <th>Created On</th>
      <th class="center">Questions Answered</th>
      <th class="center">High Risks</th>
      <th class="center">Medium Risks</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="milestones.length > 0">
      <tr *ngFor="let milestone of milestones; let i = index" (click)="selectMilestone(milestone)">
        <td>
          {{ i + 1 }}
        </td>
        <td>
          <span class="nav-link">{{ milestone['name'] }}</span>
        </td>
        <td>
          {{milestone['creation_time']}}
        </td>
        <ng-container *ngIf="!loading['milestone_' + milestone.id]">
          <td class="center">{{fetchTotalAnsweredQuestions(milestone).length}}/{{milestone['questions'].length}}</td>
          <td class="center">{{ fetchQuestionsRisks(milestone, 'High').length }}</td>
          <td class="center">{{ fetchQuestionsRisks(milestone, 'Medium').length }}</td>
        </ng-container>
        <ng-container *ngIf="loading['milestone_' + milestone.id]">
          <td class="center">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
          </td>
          <td class="center">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
          </td>
          <td class="center">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
          </td>
        </ng-container>
      </tr>
    </ng-container>
    <ng-container *ngIf="milestones.length == 0 && !loading['milestones']">
      <tr>
        <td colspan="6" class="text-center">No Milestones found</td>
      </tr>
    </ng-container>
    <ng-container *ngIf="loading['milestones']">
      <tr>
        <td colspan="6" class="text-center">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar-18" />
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>