<div class="card">
  <div class="card-body">
    <div
      class="display-flex-right pb-2"
      *ngIf="rdsData['instances'].length > 0 && !exportLoader"
    >
      <button class="btn btn-light back-color" (click)="export()">
        Export
      </button>
    </div>
    <div *ngIf="exportLoader" class="flex-end">
      <img
        src="../assets/img/loading_2.svg"
        alt="loading scans"
        class="width-16px"
      />
    </div>
    <div class="sections">
      <div class="section">
        <div
          class="section_header"
          (click)="view['instances'] = !view['instances']"
        >
          <div class="section_title">Instances</div>
          <div class="flex-start">
            <ng-container *ngIf="mrrImplemented">
              <div [innerHTML]="total['instancesStr']"></div>
            </ng-container>
            <ng-container *ngIf="!mrrImplemented">
              <div *ngIf="!loadMore">
                ${{
                  response["rdsMrrApplied"]
                    ? response["finalMrrRDSInstanceUpdatedCost"].toFixed(2)
                    : total["instances"].toFixed(2)
                }}
              </div>
            </ng-container>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="view['instances']" class="overflow-auto">
          <table class="table table-docs table-hover" id="rds-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of rdsHeaders">
                  <th
                    *ngIf="
                      !h.hide &&
                      (!h.conditionalShow || showConditionalElement) &&
                      (h.instance_only || h.instance_only == undefined)
                    "
                  >
                    {{ h.name }}
                  </th>
                </ng-container>
              </tr>
            </thead>

            <tbody
              [ngStyle]="{
                display:
                  rdsData['instances'].length > 0 ? 'table-row-group' : 'none'
              }"
            >
              <tr *ngFor="let i of rdsData['instances']; let j = index">
                <td>{{ j + 1 }}</td>
                <ng-container *ngFor="let h of rdsHeaders">
                  <td
                    *ngIf="
                      !h.hide &&
                      (!h.conditionalShow || showConditionalElement) &&
                      (h.instance_only || h.instance_only == undefined)
                    "
                  >
                    <span
                      *ngIf="!h.click"
                      [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                    >
                    </span>
                  </td>
                </ng-container>
              </tr>
            </tbody>
            <tbody *ngIf="rdsData['instances'].length == 0">
              <tr>
                <td [attr.colspan]="rdsHeaders.length + 1">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="section">
        <div
          class="section_header"
          (click)="view['storage'] = !view['storage']"
        >
          <div class="section_title">Storage</div>
          <div class="flex-start">
            <ng-container *ngIf="mrrImplemented">
              <div [innerHTML]="total['storageStr']"></div>
            </ng-container>
            <ng-container *ngIf="!mrrImplemented">
              <div *ngIf="!loadMore">
                ${{
                  response["rdsMrrApplied"]
                    ? response["finalMrrRDSStorageUpdatedCost"].toFixed(2)
                    : total["storage"].toFixed(2)
                }}
              </div>
            </ng-container>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="view['storage']" class="overflow-auto">
          <table class="table table-docs table-hover" id="rds-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of rdsHeaders">
                  <th
                    *ngIf="
                      !h.hide &&
                      (!h.conditionalShow || showConditionalElement) &&
                      (h.storage_only || h.storage_only == undefined)
                    "
                  >
                    {{ h.name }}
                  </th>
                </ng-container>
              </tr>
            </thead>

            <tbody
              [ngStyle]="{
                display:
                  rdsData['storage'].length > 0 ? 'table-row-group' : 'none'
              }"
            >
              <tr *ngFor="let i of rdsData['storage']; let j = index">
                <td>{{ j + 1 }}</td>
                <ng-container *ngFor="let h of rdsHeaders">
                  <td
                    *ngIf="
                      !h.hide &&
                      (!h.conditionalShow || showConditionalElement) &&
                      (h.storage_only || h.storage_only == undefined)
                    "
                  >
                    <span
                      *ngIf="!h.click"
                      [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"
                    >
                    </span>
                  </td>
                </ng-container>
              </tr>
            </tbody>
            <tbody *ngIf="rdsData['storage'].length == 0">
              <tr>
                <td [attr.colspan]="rdsHeaders.length + 1">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="display-flex-right" *ngIf="loadMore">
        <button class="btn btn-light back-color" (click)="loadRDSData()">
          Next
        </button>
      </div>
    </div>
  </div>
</div>
