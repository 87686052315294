<div class="card">
  <h5 class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" (click)="goBack()">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        Back
      </button>
      <span>
        {{ milestone ? milestone["name"] : selectedWorkload.name }} -
        {{ lensesData[selectedLens] }}
      </span>
    </div>
    <div class="flex-end">
      <button class="btn btn-light filter_click">
        <i class="fa fa-filter" aria-hidden="true"></i>
        <div class="font-9">
          <div>
            {{ filters["questions"] }}
          </div>
        </div>
        <div class="filter_dropdown">
          <div class="filter-item" (click)="changeFilter('All')">
            <div
              class="filter-checkbox"
              [ngClass]="{ checked: filters['questions'] == 'All' }"
            ></div>
            <div>All</div>
          </div>
          <div class="filter-item" (click)="changeFilter('Well-Architected')">
            <div
              class="filter-checkbox"
              [ngClass]="{
                checked: filters['questions'] == 'Well-Architected'
              }"
            ></div>
            <div>Well-Architected</div>
          </div>
          <div class="filter-item" (click)="changeFilter('Non Compliance')">
            <div
              class="filter-checkbox"
              [ngClass]="{ checked: filters['questions'] == 'Non Compliance' }"
            ></div>
            <div>Non Compliance</div>
          </div>
          <div class="filter-item" (click)="changeFilter('Unanswered')">
            <div
              class="filter-checkbox"
              [ngClass]="{ checked: filters['questions'] == 'Unanswered' }"
            ></div>
            <div>Unanswered</div>
          </div>
        </div>
      </button>
      <button class="btn btn-light filter_click">
        <i class="fa fa-filter" aria-hidden="true"></i>
        <div class="font-9">
          <div class="flex-start">
            <span class="light-text"> Risk: </span>
            <span>
              {{ filters["risk"] }}
            </span>
          </div>
        </div>
        <div class="filter_dropdown">
          <div class="filter-item" (click)="changeFilter('All', 'risk')">
            <div
              class="filter-checkbox"
              [ngClass]="{ checked: filters['risk'] == 'All' }"
            ></div>
            <div>All</div>
          </div>
          <div class="filter-item" (click)="changeFilter('HIGH', 'risk')">
            <div
              class="filter-checkbox"
              [ngClass]="{ checked: filters['risk'] == 'HIGH' }"
            ></div>
            <div>High</div>
          </div>
          <div class="filter-item" (click)="changeFilter('MEDIUM', 'risk')">
            <div
              class="filter-checkbox"
              [ngClass]="{ checked: filters['risk'] == 'MEDIUM' }"
            ></div>
            <div>Medium</div>
          </div>
          <!-- <div class="filter-item" (click)="changeFilter('LOW', 'risk')">
            <div class="filter-checkbox" [ngClass]="{checked: filters['risk'] == 'LOW'}">

            </div>
            <div>
              Low
            </div>
          </div> -->
        </div>
      </button>
    </div>
  </h5>

  <div class="card-body">
    <div class="quiz-app">
      <ng-container *ngIf="showSidebar">
        <div class="pillers_container position-relative">
          <div class="padding-center">PILLARS</div>
          <ng-container *ngIf="loading['pillars']; else showPillers">
            <div class="piller loading_shadhow mar-bottom-12">
              <span class="padding-100"></span>
            </div>
            <div class="piller loading_shadhow mar-bottom-12">
              <span class="padding-100"></span>
            </div>
            <div class="piller loading_shadhow mar-bottom-12">
              <span class="padding-100"></span>
            </div>
            <div class="piller loading_shadhow mar-bottom-12">
              <span class="padding-100"></span>
            </div>
          </ng-container>
          <ng-template #showPillers>
            <ng-container *ngIf="selectedWorkload['profile']">
              <hr />
              <div class="center fw-bold mb-2">Prioritized</div>
              <ng-container *ngFor="let piller of pillers">
                <ng-container
                  *ngIf="
                    filterQuestions(piller, 'PillarId', false, true).length != 0
                  "
                >
                  <div
                    class="piller"
                    (click)="changePiller(piller, 'PRIORITIZED')"
                    [ngClass]="{
                      selected:
                        selectedPiller == piller &&
                        selectedQuestionType == 'PRIORITIZED'
                    }"
                  >
                    <span>{{ pillersData[piller] }}</span>
                    <span class="color-radius-20"
                      >{{
                        filter["questions"] == "Unanswered"
                          ? 0
                          : filterQuestions(piller, "PillarId", true, true)
                              .length
                      }}/{{
                        filterQuestions(piller, "PillarId", false, true).length
                      }}</span
                    >
                  </div>
                </ng-container>
              </ng-container>

              <hr />
              <div class="center fw-bold mb-2">Non Prioritized</div>
            </ng-container>
            <ng-container *ngFor="let piller of pillers">
              <ng-container
                *ngIf="
                  filterQuestions(piller, 'PillarId', false, false).length != 0
                "
              >
                <div
                  class="piller"
                  (click)="changePiller(piller, 'NON_PRIORITIZED')"
                  [ngClass]="{
                    selected:
                      selectedPiller == piller &&
                      selectedQuestionType != 'PRIORITIZED'
                  }"
                >
                  <span>{{ pillersData[piller] }}</span>
                  <span class="color-radius-20"
                    >{{
                      filter["questions"] == "Unanswered"
                        ? 0
                        : filterQuestions(piller, "PillarId", true, false)
                            .length
                    }}/{{
                      filterQuestions(piller, "PillarId", false, false).length
                    }}</span
                  >
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
          <div class="hideSidebar" (click)="showSidebar = false">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showSidebar">
        <div class="hidden_sidebar" (click)="showSidebar = true">
          <i class="fa fa-bars mar-top-10" aria-hidden="true"></i>
        </div>
      </ng-container>
      <div class="questions_container">
        <ng-container
          *ngIf="
            questions.length > 0 &&
            filterQuestions().length > 0 &&
            !loading['questions']
          "
        >
          <div class="quiz_actions">
            <button
              class="btn btn-light"
              *ngIf="currentQuestionIndexs[selectedLens][selectedPiller] > 0"
              (click)="prev()"
            >
              <div class="flex-start">
                <span><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                <span>Prev</span>
              </div>
            </button>
            <button
              class="btn btn-light"
              *ngIf="
                filterQuestions().length - 1 !=
                currentQuestionIndexs[selectedLens][selectedPiller]
              "
              (click)="next()"
            >
              <div class="flex-start">
                <span>Next</span>
                <span
                  ><i class="fa fa-angle-right" aria-hidden="true"></i
                ></span>
              </div>
            </button>
            <ng-container *ngIf="!loading['saving']">
              <button
                class="btn btn-light"
                *ngIf="!isMilestone || !this.currentQuestion.IsApplicable"
                (click)="save(this.currentQuestion)"
              >
                Save
              </button>
            </ng-container>
            <ng-container *ngIf="loading['saving']">
              <img
                src="../assets/img/loading_2.svg"
                alt="loading scans"
                class="wid-14"
              />
            </ng-container>
          </div>
          <div class="question" *ngIf="currentQuestion">
            <div class="question_title flex-start flex-top">
              <span class="nowrap">
                {{
                  this.currentQuestion.Qno
                    ? this.currentQuestion.Qno + ". "
                    : ""
                }}
              </span>
              <span>
                {{ this.currentQuestion.QuestionTitle }}
                <span
                  *ngIf="
                    this.currentQuestion['Risk'] &&
                    this.currentQuestion['Risk'] != '' &&
                    this.currentQuestion['Risk'] != 'NONE' &&
                    this.currentQuestion['Risk'] != 'NOT_APPLICABLE'
                  "
                  class="sevirity"
                >
                  <span
                    [ngClass]="{
                      green_fancy: this.currentQuestion['Risk'] == 'NONE',
                      red_fancy: this.currentQuestion['Risk'] == 'HIGH',
                      grey_fancy: this.currentQuestion['Risk'] == 'UNANSWERED',
                      yellow_fancy: this.currentQuestion['Risk'] == 'LOW',
                      orange_fancy: this.currentQuestion['Risk'] == 'MEDIUM'
                    }"
                  >
                    {{ this.currentQuestion["Risk"] }}
                  </span>
                </span>
                <span
                  *ngIf="this.currentQuestion['QuestionType']"
                  class="question_type"
                >
                  {{ this.currentQuestion["QuestionType"] }}
                </span>
              </span>
            </div>
            <div *ngIf="!milestone">
              <div class="not_application_container flex-start">
                <label class="switch">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    [ngModel]="!this.currentQuestion.IsApplicable"
                    (change)="changeApplication(this.currentQuestion)"
                  />
                  <span class="slider round"></span>
                </label>
                <span> Question does not apply to this workload </span>
              </div>
              <div
                class="flex-start"
                *ngIf="!this.currentQuestion.IsApplicable"
              >
                <label class="switch hide">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    [ngModel]="!this.currentQuestion.IsApplicable"
                    (change)="changeApplication(this.currentQuestion)"
                  />
                  <span class="slider round"></span>
                </label>
                <div
                  [ngClass]="{
                    absolute_select: !this.currentQuestion['Reason']
                  }"
                >
                  <div
                    *ngIf="!this.currentQuestion['Reason']"
                    class="absolute_select_element"
                  >
                    Reason
                  </div>
                  <select
                    class="form-select"
                    placeholder="Reason"
                    [(ngModel)]="this.currentQuestion['Reason']"
                    *ngIf="!this.currentQuestion.IsApplicable"
                  >
                    <option
                      *ngFor="let reason of getObjectKeys(reasonsData)"
                      [ngValue]="reason"
                    >
                      {{ reasonsData[reason] }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="options_container">
              <div
                class="option"
                [ngClass]="{
                  selected_option_contaniner: checkIfChoiceIsSelected(
                    this.currentQuestion,
                    option.ChoiceId
                  ),
                  'hover-option': !isMilestone,
                  'disabled-option':
                    isMilestone || !this.currentQuestion.IsApplicable
                }"
                (click)="selectOption(this.currentQuestion, j, $event)"
                *ngFor="
                  let option of this.currentQuestion.Choices;
                  let j = index
                "
              >
                <div class="pointer-none">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    [attr.checked]="
                      checkIfChoiceIsSelected(
                        this.currentQuestion,
                        option.ChoiceId
                      )
                        ? true
                        : null
                    "
                    *ngIf="option.Title != 'None of these'"
                    [attr.disabled]="isMilestone ? true : null"
                  />
                  <input
                    autocomplete="off"
                    type="radio"
                    id="option_{{ j }}"
                    [attr.checked]="
                      checkIfChoiceIsSelected(
                        this.currentQuestion,
                        option.ChoiceId
                      ) == true
                        ? true
                        : null
                    "
                    *ngIf="option.Title == 'None of these'"
                    [attr.disabled]="isMilestone ? true : null"
                  />
                </div>
                <div class="flex-1">
                  <div class="option_text">
                    <label for="option_{{ j }}"></label>
                    {{ option.Title }}
                  </div>
                  <div
                    class="option_description"
                    *ngIf="option.Description && option.Description != ''"
                  >
                    {{ option.Description }}
                  </div>

                  <!-- Severity  -->
                  <div
                    class="reject_margin reject_margin_top"
                    *ngIf="
                      getSwayamOption(this.currentQuestion, option) &&
                      getSwayamOption(this.currentQuestion, option)['severity']
                    "
                  >
                    <div class="sevirity">
                      <span class="bold">Risk: </span>
                      <span
                        [ngClass]="{
                          red_fancy:
                            getSwayamOption(this.currentQuestion, option)[
                              'severity'
                            ] == 'High',
                          yellow_fancy:
                            getSwayamOption(this.currentQuestion, option)[
                              'severity'
                            ] == 'Low',
                          orange_fancy:
                            getSwayamOption(this.currentQuestion, option)[
                              'severity'
                            ] == 'Medium'
                        }"
                      >
                        {{
                          getSwayamOption(this.currentQuestion, option)[
                            "severity"
                          ]
                        }}
                      </span>
                    </div>
                  </div>
                  <ng-container *ngIf="option.Title != 'None of these'">
                    <!-- Evidence -->
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        getSwayamOption(this.currentQuestion, option) &&
                        getSwayamOption(this.currentQuestion, option)[
                          'source'
                        ] != 'Manual'
                      "
                    >
                      <ng-container
                        *ngIf="
                          getSwayamOption(this.currentQuestion, option)[
                            'evidence'
                          ]
                        "
                      >
                        <div class="bold">Evidence</div>
                        <div
                          class="flex-start"
                          *ngFor="
                            let evidence of getSwayamOption(
                              this.currentQuestion,
                              option
                            )['evidence']
                          "
                        >
                          <ng-container
                            *ngIf="evidence['message'] != 'Swayam Findings'"
                          >
                            <div *ngIf="evidence['status'] === true">
                              <i
                                class="fa fa-check-circle green_fancy"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div *ngIf="evidence['status'] === false">
                              <i
                                class="fa fa-times-circle red_fancy"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </ng-container>
                          <div>
                            {{ evidence["message"] }}
                            <span>
                              <div
                                *ngIf="evidence['info']"
                                (click)="preventDefault($event)"
                                class="vertical-block"
                              >
                                <ng-container>
                                  <div
                                    class="failed_ami"
                                    (mouseenter)="setPosition($event)"
                                  >
                                    <div class="status_container text-hidden">
                                      <span class="nav-link display-block">
                                        Info
                                      </span>
                                    </div>
                                    <div
                                      class="failed_ami_message_container"
                                      [ngStyle]="{
                                        right: position.x ? position.x : 0,
                                        top: position.y ? position.y : 0,
                                        height: position.height,
                                        width: position.width
                                      }"
                                    >
                                      <div class="message_back">
                                        <div>
                                          <div
                                            class="message_grid"
                                            [ngStyle]="{
                                              'max-width': position.overflow
                                                ? position.overflowwidth
                                                : '',
                                              'overflow-x': position.overflow
                                                ? 'auto'
                                                : 'visible'
                                            }"
                                          >
                                            <div class="white-width">
                                              {{ evidence["info"] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="message_container">
                                        <div>
                                          <div
                                            class="message_grid"
                                            [ngStyle]="{
                                              'max-width': position.overflow
                                                ? position.overflowwidth
                                                : '',
                                              'overflow-x': position.overflow
                                                ? 'auto'
                                                : 'visible'
                                            }"
                                          >
                                            <div class="white-width">
                                              {{ evidence["info"] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </span>
                          </div>
                          <div *ngIf="evidence['swayam_link']">
                            <a
                              href="/{{ evidence['swayam_link'] }}"
                              target="_blank"
                              >{{
                                evidence["complianceName"] ||
                                  whitelabelData["pd"]["appName"] + " Link"
                              }}
                              <i
                                class="fa fa-external-link"
                                aria-hidden="true"
                              ></i
                            ></a>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          !getSwayamOption(this.currentQuestion, option)[
                            'evidence'
                          ]
                        "
                      >
                        <div class="bold">Evidence</div>
                        <div
                          *ngIf="
                            getSwayamOption(this.currentQuestion, option)[
                              'status'
                            ]
                          "
                        >
                          {{
                            getSwayamOption(this.currentQuestion, option)[
                              "metaData"
                            ]
                          }}
                        </div>
                        <div
                          *ngIf="
                            !getSwayamOption(this.currentQuestion, option)[
                              'status'
                            ]
                          "
                        >
                          {{
                            getSwayamOption(this.currentQuestion, option)[
                              "failurereason"
                            ]
                          }}
                        </div>
                      </ng-container>
                      <div
                        class="flex-start"
                        *ngIf="
                          getSwayamOption(this.currentQuestion, option)[
                            'evidenceStatus'
                          ] == undefined && !isMilestone
                        "
                      >
                        <button
                          class="btn btn-primary"
                          (click)="
                            validateOption(
                              $event,
                              getSwayamOption(this.currentQuestion, option),
                              true
                            )
                          "
                        >
                          Accept
                        </button>
                        <button
                          (click)="
                            validateOption(
                              $event,
                              getSwayamOption(this.currentQuestion, option),
                              false
                            )
                          "
                          class="btn btn-primary"
                        >
                          Exception
                        </button>
                      </div>
                      <div
                        class="flex-start"
                        *ngIf="
                          getSwayamOption(this.currentQuestion, option)[
                            'evidenceStatus'
                          ] != undefined
                        "
                      >
                        Evidence is
                        {{
                          getSwayamOption(this.currentQuestion, option)[
                            "evidenceStatus"
                          ]
                            ? "valid"
                            : "invalid"
                        }}
                        <div
                          class="nav-link"
                          *ngIf="!isMilestone"
                          (click)="
                            resetEvidence(
                              $event,
                              getSwayamOption(this.currentQuestion, option)
                            )
                          "
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i> Change
                        </div>
                      </div>
                    </div>

                    <!-- Decision -->
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        getSwayamOption(this.currentQuestion, option) &&
                        getSwayamOption(this.currentQuestion, option)[
                          'evidenceStatus'
                        ] == false &&
                        !(
                          isMilestone &&
                          !getSwayamOption(this.currentQuestion, option)[
                            'decision'
                          ] != undefined
                        )
                      "
                    >
                      <div class="bold">Decision</div>
                      <div
                        class="flex-start"
                        *ngIf="
                          getSwayamOption(this.currentQuestion, option)[
                            'decision'
                          ] == undefined && !isMilestone
                        "
                      >
                        <button
                          class="btn btn-primary"
                          (click)="
                            fixOption(
                              $event,
                              getSwayamOption(this.currentQuestion, option),
                              'Remediate'
                            )
                          "
                        >
                          Remediate
                        </button>
                        <!-- <button class="btn btn-primary"
                          (click)="fixOption($event, getSwayamOption(this.currentQuestion, option), 'Wont Fix')">
                          Won't Fix
                        </button> -->
                        <button
                          (click)="
                            fixOption(
                              $event,
                              getSwayamOption(this.currentQuestion, option),
                              'Not Applicable'
                            )
                          "
                          class="btn btn-primary"
                        >
                          Not Applicable
                        </button>
                      </div>
                      <div
                        class="flex-start pad-0"
                        *ngIf="
                          getSwayamOption(this.currentQuestion, option)[
                            'decision'
                          ] != undefined
                        "
                      >
                        {{
                          getSwayamOption(this.currentQuestion, option)[
                            "decision"
                          ]
                        }}
                        <div
                          class="nav-link"
                          *ngIf="!isMilestone"
                          (click)="
                            resetFix(
                              $event,
                              getSwayamOption(this.currentQuestion, option)
                            )
                          "
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i> Change
                        </div>
                      </div>
                    </div>

                    <!-- Non Swayam Choices -->
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        !getSwayamOption(this.currentQuestion, option) &&
                        getOptionSummary(this.currentQuestion, option)[
                          'Status'
                        ] != 'SELECTED' &&
                        !(
                          isMilestone &&
                          getOptionSummary(this.currentQuestion, option)[
                            'Status'
                          ] != 'NOT_APPLICABLE'
                        )
                      "
                    >
                      <div class="bold">Decision</div>
                      <div
                        class="flex-start"
                        *ngIf="
                          getOptionSummary(this.currentQuestion, option)[
                            'Status'
                          ] == 'UNSELECTED' && !isMilestone
                        "
                      >
                        <!-- <button class="btn btn-primary"
                          (click)="fixOption($event, getSwayamOption(this.currentQuestion, option), 'Remediate')">
                          Remediate
                        </button> -->
                        <!-- <button class="btn btn-primary"
                          (click)="fixOption($event, getSwayamOption(this.currentQuestion, option), 'Wont Fix')">
                          Won't Fix
                        </button> -->
                        <button
                          (click)="
                            fixOption(
                              $event,
                              getOptionSummary(this.currentQuestion, option),
                              'NOT_APPLICABLE',
                              'Status'
                            )
                          "
                          class="btn btn-primary"
                        >
                          Not Applicable
                        </button>
                      </div>
                      <div
                        class="flex-start pad-0"
                        *ngIf="
                          getOptionSummary(this.currentQuestion, option)[
                            'Status'
                          ] == 'NOT_APPLICABLE'
                        "
                      >
                        {{
                          getOptionSummary(this.currentQuestion, option)[
                            "Status"
                          ]
                        }}
                        <div
                          class="nav-link"
                          *ngIf="!isMilestone"
                          (click)="
                            resetFix(
                              $event,
                              getOptionSummary(this.currentQuestion, option),
                              'Status',
                              'UNSELECTED'
                            )
                          "
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i> Change
                        </div>
                      </div>
                    </div>

                    <!-- Improvement Plan  -->
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        getSwayamOption(this.currentQuestion, option) &&
                        (getSwayamOption(this.currentQuestion, option)[
                          'source'
                        ] == 'Manual' ||
                          (getSwayamOption(this.currentQuestion, option)[
                            'decision'
                          ] &&
                            getSwayamOption(this.currentQuestion, option)[
                              'decision'
                            ] != 'Not Applicable'))
                      "
                    >
                      <div class="bold">Improvement Plan</div>
                      <div>
                        {{
                          getSwayamOption(this.currentQuestion, option)[
                            "improvementPlan"
                          ]
                        }}
                      </div>
                      <div
                        *ngIf="
                          getSwayamOption(this.currentQuestion, option)['link']
                        "
                        (click)="preventDefault($event, false)"
                      >
                        <a
                          target="_blank"
                          [attr.href]="
                            getSwayamOption(this.currentQuestion, option)[
                              'link'
                            ]
                          "
                          >Reference
                          <i class="fa fa-external-link" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>

                    <!-- Best Practices -->
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        ((getSwayamOption(this.currentQuestion, option) &&
                          getSwayamOption(this.currentQuestion, option)[
                            'source'
                          ] == 'Manual') ||
                          (getSwayamOption(this.currentQuestion, option) &&
                            getSwayamOption(this.currentQuestion, option)[
                              'decision'
                            ] &&
                            getSwayamOption(this.currentQuestion, option)[
                              'decision'
                            ] != 'Not Applicable')) &&
                        getBestPractices(this.currentQuestion, option) &&
                        getBestPractices(this.currentQuestion, option).length >
                          0
                      "
                    >
                      <div class="bold">Best Practices</div>
                      <ol>
                        <li
                          class="practice gap-pad-10"
                          [ngStyle]="{
                            'pointer-events': isMilestone ? 'none' : 'auto'
                          }"
                          *ngFor="
                            let practice of getBestPractices(
                              this.currentQuestion,
                              option
                            )
                          "
                        >
                          {{ practice }}
                        </li>
                      </ol>
                    </div>

                    <!-- Non Swayam Choices - Source ['Manual'] -->
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        getSwayamOption(this.currentQuestion, option) &&
                        getSwayamOption(this.currentQuestion, option)[
                          'source'
                        ] == 'Manual' &&
                        getOptionSummary(this.currentQuestion, option)[
                          'Status'
                        ] != 'SELECTED' &&
                        !(
                          isMilestone &&
                          getOptionSummary(this.currentQuestion, option)[
                            'Status'
                          ] != 'NOT_APPLICABLE'
                        )
                      "
                    >
                      <div class="bold">Decision</div>
                      <div
                        class="flex-start"
                        *ngIf="
                          getOptionSummary(this.currentQuestion, option)[
                            'Status'
                          ] == 'UNSELECTED' && !isMilestone
                        "
                      >
                        <!-- <button class="btn btn-primary"
                                              (click)="fixOption($event, getSwayamOption(this.currentQuestion, option), 'Remediate')">
                                              Remediate
                                            </button> -->
                        <!-- <button class="btn btn-primary"
                                              (click)="fixOption($event, getSwayamOption(this.currentQuestion, option), 'Wont Fix')">
                                              Won't Fix
                                            </button> -->
                        <button
                          (click)="
                            fixOption(
                              $event,
                              getOptionSummary(this.currentQuestion, option),
                              'NOT_APPLICABLE',
                              'Status'
                            )
                          "
                          class="btn btn-primary"
                        >
                          Not Applicable
                        </button>
                      </div>
                      <div
                        class="flex-start pad-0"
                        *ngIf="
                          getOptionSummary(this.currentQuestion, option)[
                            'Status'
                          ] == 'NOT_APPLICABLE'
                        "
                      >
                        {{
                          getOptionSummary(this.currentQuestion, option)[
                            "Status"
                          ]
                        }}
                        <div
                          class="nav-link"
                          *ngIf="!isMilestone"
                          (click)="
                            resetFix(
                              $event,
                              getOptionSummary(this.currentQuestion, option),
                              'Status',
                              'UNSELECTED'
                            )
                          "
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i> Change
                        </div>
                      </div>
                    </div>

                    <!-- Not Applicable Reason -->
                    <div
                      *ngIf="
                        (getOptionSummary(this.currentQuestion, option) &&
                          getOptionSummary(this.currentQuestion, option)[
                            'Status'
                          ] == 'NOT_APPLICABLE') ||
                        (getSwayamOption(this.currentQuestion, option) &&
                          getSwayamOption(this.currentQuestion, option)[
                            'decision'
                          ] == 'Not Applicable')
                      "
                      class="reject_margin reject_margin_top"
                    >
                      <div class="bold">Reason</div>
                      <div
                        class="flex-start pad-0"
                        *ngIf="
                          getOptionSummary(this.currentQuestion, option)[
                            'reason_dummy'
                          ] != undefined && !isMilestone
                        "
                      >
                        <select
                          (click)="preventDefault($event)"
                          [ngClass]="{
                            'error-input':
                              getOptionSummary(this.currentQuestion, option)[
                                'errors'
                              ] &&
                              getOptionSummary(this.currentQuestion, option)[
                                'errors'
                              ]['reason']
                          }"
                          class="form-select"
                          [(ngModel)]="
                            getOptionSummary(this.currentQuestion, option)[
                              'reason_dummy'
                            ]
                          "
                        >
                          <option
                            *ngFor="let reason of getObjectKeys(reasonsData)"
                            [ngValue]="reason"
                          >
                            {{ reasonsData[reason] }}
                          </option>
                        </select>
                        <button
                          class="btn btn-primary"
                          (click)="
                            resetReason(
                              $event,
                              getOptionSummary(this.currentQuestion, option),
                              false
                            )
                          "
                        >
                          Submit
                        </button>
                      </div>
                      <div
                        *ngIf="
                          getOptionSummary(this.currentQuestion, option)[
                            'errors'
                          ] &&
                          getOptionSummary(this.currentQuestion, option)[
                            'errors'
                          ]['reason']
                        "
                        class="error-message"
                      >
                        <i
                          class="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {{
                          getOptionSummary(this.currentQuestion, option)[
                            "errors"
                          ]["reason"]
                        }}
                      </div>
                      <div
                        class="flex-start pad-0"
                        *ngIf="
                          getOptionSummary(this.currentQuestion, option)[
                            'reason_dummy'
                          ] == undefined
                        "
                      >
                        {{
                          reasonsData[
                            getOptionSummary(this.currentQuestion, option)[
                              "Reason"
                            ]
                          ]
                        }}
                        <div
                          class="nav-link"
                          *ngIf="!isMilestone"
                          (click)="
                            resetReason(
                              $event,
                              getOptionSummary(this.currentQuestion, option),
                              true
                            )
                          "
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i> Change
                        </div>
                      </div>
                    </div>

                    <!-- <div class="reject_margin reject_margin_top"
                      *ngIf="(!getSwayamOption(this.currentQuestion, option) || (getSwayamOption(this.currentQuestion, option) && getSwayamOption(this.currentQuestion, option)['source'] == 'Manual')) && getOptionSummary(this.currentQuestion,
                                                                                  option)['Status'] == 'NOT_APPLICABLE'">
                      <div class=" bold">Comment</div>
                      <div>
                        <div
                          *ngIf="getSwayamOption(this.currentQuestion, option)['comments'] != '' && !getSwayamOption(this.currentQuestion, option)['edit']">
                          {{getSwayamOption(this.currentQuestion,
                          option)['comments']}} <span class="nav-link display-block" *ngIf="!isMilestone"
                            (click)="editComment($event, getSwayamOption(this.currentQuestion, option))"><i
                              class="fa fa-pencil" aria-hidden="true"></i>
                            Edit</span></div>

                        <div *ngIf="getSwayamOption(this.currentQuestion, option)['edit'] && !isMilestone"
                          class="flex-start pad-0">
                          <input autocomplete="off" type="text" class="form-control"
                            [(ngModel)]="getSwayamOption(this.currentQuestion, option)['comments']"
                            (click)="preventDefault($event)">
                          <button class="btn btn-primary"
                            (click)="preventDefault($event) && getSwayamOption(this.currentQuestion, option)['edit'] = undefined">
                            Comment
                          </button>
                        </div>
                      </div>
                      <div class="flex-start pad-0"
                        *ngIf="getSwayamOption(this.currentQuestion, option)['comments'] == '' && !isMilestone">
                        <input autocomplete="off" type="text" class="form-control" (click)="preventDefault($event)"
                          [(ngModel)]="getSwayamOption(this.currentQuestion, option)['dummy_comment']">
                        <button class="btn btn-primary"
                          (click)="addComment($event, getSwayamOption(this.currentQuestion, option))">
                          Comment
                        </button>
                      </div>
                    </div> -->

                    <!-- Comments -->
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        getSwayamOption(this.currentQuestion, option) ||
                        getOptionSummary(this.currentQuestion, option)
                      "
                    >
                      <div class="bold">Comment</div>
                      <div>
                        <div
                          *ngIf="
                            getSwayamOption(this.currentQuestion, option) &&
                            getSwayamOption(this.currentQuestion, option)[
                              'edit'
                            ] != true
                          "
                        >
                          {{
                            getSwayamOption(this.currentQuestion, option)[
                              "comments"
                            ]
                          }}
                          <span
                            class="nav-link display-block"
                            *ngIf="!isMilestone"
                            (click)="
                              editComment(
                                $event,
                                getSwayamOption(this.currentQuestion, option)
                              )
                            "
                            ><i class="fa fa-pencil" aria-hidden="true"></i>
                            Edit</span
                          >
                        </div>
                        <div
                          *ngIf="
                            !getSwayamOption(this.currentQuestion, option) &&
                            getOptionSummary(this.currentQuestion, option) &&
                            !getOptionSummary(this.currentQuestion, option)[
                              'edit'
                            ]
                          "
                        >
                          {{
                            getOptionSummary(this.currentQuestion, option)[
                              "Notes"
                            ]
                          }}
                          <span
                            class="nav-link display-block"
                            *ngIf="!isMilestone"
                            (click)="
                              editComment(
                                $event,
                                getOptionSummary(this.currentQuestion, option)
                              )
                            "
                            ><i class="fa fa-pencil" aria-hidden="true"></i>
                            Edit</span
                          >
                        </div>

                        <div *ngIf="!isMilestone" class="flex-start pad-0">
                          <ng-container
                            *ngIf="
                              getSwayamOption(this.currentQuestion, option) &&
                              getSwayamOption(this.currentQuestion, option)[
                                'edit'
                              ]
                            "
                          >
                            <input
                              autocomplete="off"
                              type="text"
                              class="form-control"
                              [(ngModel)]="
                                getSwayamOption(this.currentQuestion, option)[
                                  'comments'
                                ]
                              "
                              (click)="preventDefault($event)"
                            />
                            <button
                              class="btn btn-primary"
                              (click)="
                                preventDefault($event) &&
                                  (getSwayamOption(
                                    this.currentQuestion,
                                    option
                                  )['edit'] = undefined)
                              "
                            >
                              Comment
                            </button>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              !getSwayamOption(this.currentQuestion, option) &&
                              getOptionSummary(this.currentQuestion, option) &&
                              getOptionSummary(this.currentQuestion, option)[
                                'edit'
                              ]
                            "
                          >
                            <input
                              autocomplete="off"
                              type="text"
                              class="form-control"
                              [(ngModel)]="
                                getOptionSummary(this.currentQuestion, option)[
                                  'Notes'
                                ]
                              "
                              (click)="preventDefault($event)"
                            />
                            <button
                              class="btn btn-primary"
                              (click)="
                                preventDefault($event) &&
                                  (getOptionSummary(
                                    this.currentQuestion,
                                    option
                                  )['edit'] = undefined)
                              "
                            >
                              Comment
                            </button>
                          </ng-container>
                        </div>
                      </div>
                      <!-- <div class="flex-start pad-0"
                        *ngIf="getSwayamOption(this.currentQuestion, option)['comments'] == '' && !isMilestone">
                        <input autocomplete="off" type="text" class="form-control" (click)="preventDefault($event)"
                          [(ngModel)]="getSwayamOption(this.currentQuestion, option)['dummy_comment']">
                        <button class="btn btn-primary"
                          (click)="addComment($event, getSwayamOption(this.currentQuestion, option))">
                          Comment
                        </button>
                      </div> -->
                    </div>
                  </ng-container>

                  <!-- Comments for None of These -->
                  <!-- <div class="reject_margin reject_margin_top"
                    *ngIf="option.Title == 'None of these' && checkIfChoiceIsSelected(this.currentQuestion, option.ChoiceId) == true">
                    <div class="bold">Comment</div>
                    <div
                      *ngIf="getSwayamOption(this.currentQuestion, option)['comments'] != ''">
                      <div
                        *ngIf="!getSwayamOption(this.currentQuestion, option)['edit']">
                        {{getSwayamOption(this.currentQuestion,
                        option)['comments']}} <span class="nav-link display-block" *ngIf="!isMilestone"
                          (click)="editComment($event, getSwayamOption(this.currentQuestion, option))"><i
                            class="fa fa-pencil" aria-hidden="true"></i>
                          Edit</span></div>

                      <div
                        *ngIf="getSwayamOption(this.currentQuestion, option)['edit'] && !isMilestone"
                        class="flex-start">
                        <input autocomplete="off" type="text" class="form-control"
                          [(ngModel)]="getSwayamOption(this.currentQuestion, option)['comments']"
                          (click)="preventDefault($event)">
                        <button class="btn btn-primary"
                          (click)="getSwayamOption(this.currentQuestion, option)['edit'] = undefined && preventDefault($event)">
                          Comment
                        </button>
                      </div>
                    </div>
                    <div class="flex-start"
                      *ngIf="getSwayamOption(this.currentQuestion, option)['comments'] == '' && !isMilestone">
                      <input autocomplete="off" type="text" class="form-control" (click)="preventDefault($event)"
                        [(ngModel)]="getSwayamOption(this.currentQuestion, option)['dummy_comment']">
                      <button class="btn btn-primary"
                        (click)="addComment($event, getSwayamOption(this.currentQuestion, option))">
                        Comment
                      </button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="notes_container">
              <div class="reject_margin reject_margin_top">
                <div class="bold">Notes</div>
                <div>
                  <textarea
                    class="form-control"
                    name=""
                    id=""
                    rows="3"
                    *ngIf="!milestone"
                    [(ngModel)]="this.currentQuestion['Notes']"
                  ></textarea>
                  <div *ngIf="milestone">
                    {{ this.currentQuestion["Notes"] }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- question bottom actions -->
          <div class="quiz_actions">
            <button
              class="btn btn-light"
              *ngIf="currentQuestionIndexs[selectedLens][selectedPiller] > 0"
              (click)="prev()"
            >
              <div class="flex-start">
                <span><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                <span>Prev</span>
              </div>
            </button>
            <button
              class="btn btn-light"
              *ngIf="
                filterQuestions().length - 1 !=
                currentQuestionIndexs[selectedLens][selectedPiller]
              "
              (click)="next()"
            >
              <div class="flex-start">
                <span>Next</span>
                <span
                  ><i class="fa fa-angle-right" aria-hidden="true"></i
                ></span>
              </div>
            </button>
            <ng-container *ngIf="!loading['saving']">
              <button
                class="btn btn-light"
                *ngIf="!isMilestone || !this.currentQuestion.IsApplicable"
                (click)="save(this.currentQuestion)"
              >
                Save
              </button>
            </ng-container>
            <ng-container *ngIf="loading['saving']">
              <img
                src="../assets/img/loading_2.svg"
                alt="loading scans"
                class="wid-14"
              />
            </ng-container>
          </div>
        </ng-container>
        <ng-container
          *ngIf="!loading['questions'] && filterQuestions().length == 0"
        >
          <div class="text-center">No Questions Found</div>
        </ng-container>
        <ng-container *ngIf="loading['questions']">
          <div class="question pointer-none-50">
            <div class="question_title loading_shadhow padding-8-width"></div>
            <div class="options_container wid-100">
              <div class="option wid-100">
                <div class="wid-100">
                  <div
                    class="option_text loading_shadhow margin-pad-wid-70"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow margin-pad-width-30"
                  ></div>
                </div>
              </div>
              <div class="option wid-100">
                <div class="wid-100">
                  <div
                    class="option_text loading_shadhow margin-pad-wid-70"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow margin-pad-width-30"
                  ></div>
                </div>
              </div>
              <div class="option wid-100">
                <div class="wid-100">
                  <div
                    class="option_text loading_shadhow margin-pad-wid-70"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow margin-pad-width-30"
                  ></div>
                </div>
              </div>
              <div class="option wid-100">
                <div class="wid-100">
                  <div
                    class="option_text loading_shadhow margin-pad-wid-70"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow bottom-width-100"
                  ></div>
                  <div
                    class="option_description loading_shadhow margin-pad-width-30"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="question_switcher">
        <div class="question_slider">
          <div class="mar-left">QUESTIONS</div>
          <div
            *ngFor="let question of filterQuestions(); let i = index"
            class="question_number"
            (click)="changeQuestion(i)"
          >
            <div
              class="question_no font-bold"
              [attr.data-sevirity]="question['Risk']"
              [ngClass]="{
                absolute_floating: question['Risk'] != 'UNANSWERED',
                red_fancy: question['Risk'] == 'HIGH',
                yellow_fancy: question['Risk'] == 'LOW',
                orange_fancy: question['Risk'] == 'MEDIUM'
              }"
            >
              <span
                [ngClass]="{
                  current_question:
                    i == currentQuestionIndexs[selectedLens][selectedPiller],
                  answerd_question: checkIfAnswered(question),
                  un_answerd_question: !checkIfAnswered(question)
                }"
              >
                {{ i + 1 }}
              </span>
            </div>
            <div class="question_switcher_text_container white-now">
              <span
                class="question_switcher_text"
                title="{{ question.QuestionTitle }}"
                [ngStyle]="{
                  'font-weight':
                    i == currentQuestionIndexs[selectedLens][selectedPiller]
                      ? 'bold'
                      : 'normal'
                }"
              >
                {{ question.QuestionTitle }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-save-dialog
  *ngIf="unsavedChanges['status']"
  (popupAction)="popupAction($event)"
></app-save-dialog>
<app-none-of-these-confirm
  *ngIf="noneOfThese['status']"
  (popupAction)="confirmNoneOfThese($event)"
></app-none-of-these-confirm>
