import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let window: any;

@Component({
  selector: "app-create-monitor",
  templateUrl: "./create-monitor.component.html",
  styleUrls: ["./create-monitor.component.css"],
})
export class CreateMonitorComponent implements OnInit {
  @Input("accountId") accountId: string;
  @Input("instance") instance?: any;
  @Input("pending") pending?: any;
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  userId = localStorage.getItem("eId");
  selectedCloudPartner: string | null = localStorage.getItem("cp-selected");
  resourceGroup: string = localStorage.getItem("selectedResourceGroup");
  filterType: string;

  selectInstance(instance: any) {
    if (
      instance["emsStatus"] == 1 ||
      instance["pending"] ||
      instance["repeat_name"]
    ) {
      return;
    }
    instance["selected"] = !instance["selected"];
  }

  regionId: string = localStorage.getItem("regionId");

  stepCount: number = 0;
  platforms: any = ["Windows", "Linux"];

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  selectedPlatform: string;

  rawCommands: any = {
    Linux: [
      {
        title:
          "Open command prompt as administrator and execute the following commands",
        command: [
          `sudo -E bash -c "$(curl -sL https://staticdownloads.site24x7.com/server/Site24x7InstallScript.sh)" readlink -i -key=[[deviceKey]] -dn='[[tagName]]' -gn='[[accountId]]' -f`,
        ],
      },
    ],
    Windows: [
      {
        title: "Download and install the agent in the location of your choice",
        link: {
          class: "btn-success-soft",
          type: "download-button",
          text: "Download Site24x7 Windows Server Agent",
          link: "https://www,google.com",
        },
        or: {
          title:
            "Log in to Site24x7 and go to Server > Server Monitor > Servers (+) > Windows/Linux. Download and install the agent in the location of your choice",
        },
      },
      {
        title:
          "Once the Windows agent is downloaded, go to the path where the file is present and then execute the following command",
        command: [
          "msiexec.exe /i Site24x7WindowsAgent.msi EDITA1=<Device Keyd> ENABLESILENT=YES REBOOT=ReallySuppress DN=<Display Named> TP=<Threshold profile named> RP=<Resource profile named> NP=<Notification profile named> GN=<Group named> RULE=<Configuration rule Named> /qn",
        ],
      },
    ],
    Docker: [
      {
        title:
          "Open command prompt as administrator and execute the following commands",
        command: [
          "docker run -d --name site24x7-agent \n -v /var/run/docker.sock:/var/run/docker.sock:ro \n -v /proc/:/host/proc/:ro \n -v /sys:/host/sys/:ro \n -e key=<device_key> \n -e ct=<configuration_profile_name> \n -e tp=<threshold_profile>\n -e rule=<configuration_rule_name>\n site24x7/docker-agent:latest",
        ],
      },
    ],
  };

  commands: any = {
    Linux: [
      {
        title:
          "Open command prompt as administrator and execute the following commands",
        command: [
          `sudo -E bash -c "$(curl -sL https://staticdownloads.site24x7.com/server/Site24x7InstallScript.sh)" readlink -i -key=[[deviceKey]] -dn='[[tagName]]' -gn='[[accountId]]' -f`,
        ],
      },
    ],
    Windows: [
      {
        title: "Download and install the agent in the location of your choice",
        link: {
          class: "btn-success-soft",
          type: "download-button",
          text: "Download Site24x7 Windows Server Agent",
          link: "https://staticdownloads.site24x7.com/server/Site24x7WindowsAgent.msi",
        },
        or: {
          title:
            "Log in to Site24x7 and go to Server > Server Monitor > Servers (+) > Windows/Linux. Download and install the agent in the location of your choice",
        },
      },
      {
        title:
          "Once the Windows agent is downloaded, go to the path where the file is present and then execute the following command",
        command: [
          "msiexec.exe /i Site24x7WindowsAgent.msi EDITA1=<Device Keyd> ENABLESILENT=YES REBOOT=ReallySuppress DN=<Display Named> TP=<Threshold profile named> RP=<Resource profile named> NP=<Notification profile named> GN=<Group named> RULE=<Configuration rule Named> /qn",
        ],
      },
    ],
    Docker: [
      {
        title:
          "Open command prompt as administrator and execute the following commands",
        command: [
          "docker run -d --name site24x7-agent \n -v /var/run/docker.sock:/var/run/docker.sock:ro \n -v /proc/:/host/proc/:ro \n -v /sys:/host/sys/:ro \n -e key=<device_key> \n -e ct=<configuration_profile_name> \n -e tp=<threshold_profile>\n -e rule=<configuration_rule_name>\n site24x7/docker-agent:latest",
        ],
      },
    ],
  };

  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.selectedPlatform = this.platforms[0];
    let temp: any = JSON.stringify(this.commands);
    temp = temp.replaceAll(
      "[[accountId]]",
      this.accountId + "_" + this.regionId
    );
    let accountsObj: any = JSON.parse(localStorage.getItem("emsConfig"));
    temp = temp.replaceAll(
      "[[deviceKey]]",
      accountsObj["deviceId"].slice(0, 10) +
        accountsObj["deviceId"]
          .slice(10)
          .replace(accountsObj["deviceId"].slice(10), "xxxxxxxxx")
    );
    if (this.instance) {
      temp = temp.replaceAll("[[tagName]]", this.instance["tagName"]);
      if (this.instance["platform"] != "Windows") {
        this.selectedPlatform = "Linux";
      } else {
        this.selectedPlatform = this.instance["platform"];
      }
      this.stepCount = 1;
    }

    this.commands = JSON.parse(temp);

    temp = JSON.stringify(this.rawCommands);
    temp = temp.replaceAll(
      "[[accountId]]",
      this.accountId + "_" + this.regionId
    );
    accountsObj = JSON.parse(localStorage.getItem("emsConfig"));
    temp = temp.replaceAll("[[deviceKey]]", accountsObj["deviceId"]);
    if (this.instance) {
      temp = temp.replaceAll("[[tagName]]", this.instance["tagName"]);
      if (this.instance["platform"] != "Windows") {
        this.selectedPlatform = "Linux";
      } else {
        this.selectedPlatform = this.instance["platform"];
      }
      this.stepCount = 1;
    }

    this.rawCommands = JSON.parse(temp);

    if (!this.checkForMonitorGroups()) {
      this.createMonitorGroup();
    }
    if (!this.instance) {
      if (this.selectedCloudPartner == "aws") {
        await this.loadInstances();
      } else if (this.selectedCloudPartner == "azure") {
        await this.loadAzureVMs();
      }
    }
  }

  async loadAzureVMs() {
    this.loading = true;
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Tags", "EC2 Instances Fetched");
    //https://api.swayam.cloud/v3/admin/support

    let apiURL: string | null = `${environment.apiURL}/admin/azure/central`;
    let data = {
      action: "list_vms",
      user_id:
        this.urlPrefix == "admin" &&
        (localStorage.getItem("selectedAccountGroup") ==
          "DevelopmentTestGroup" ||
          localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
          ? "rakeshkumar.t@minfytech.com"
          : this.userId,
      subscription_id: this.accountId,
      resource_group_name: this.resourceGroup,
    };

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if(result.status == '1' || result.status == '1') {
      this.instancesList = result.virtual_machines;
    } else {
      this.instancesList = [];
    }
    this.loading = false;
  }

  resetSearch() {
    this.filterType = "";
    this.searchText = "";
    this.loadInstances();
  }

  checkForMonitorGroups() {
    let monitorGroupsStorage: any = localStorage.getItem(
      "site24x7MonitorGroups"
    );
    if (!monitorGroupsStorage) {
      return null;
    }
    let monitorGroups: any = JSON.parse(monitorGroupsStorage);

    return monitorGroups.find((group: any) => {
      return group["display_name"] == this.accountId;
    });
  }

  getSelectedInstances() {
    return this.instancesList.filter((instance: any) => {
      return instance["selected"];
    });
  }

  async savePending() {
    this.loading = true;

    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }

    account = JSON.parse(account);

    let data: any = {
      action: "pending_installation",
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      origin: "swayam",
      instances: this.getSelectedInstances().map((instance: any) => {
        return {
          name: instance["KeyName"],
          instance_id: instance["InstanceId"],
          type: instance["InstanceType"],
          account_id: this.accountId,
          region_id: this.regionId,
          time: moment().toISOString(),
          zaaid: account["zaid"],
          platform: instance["platform"],
        };
      }),
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.loading = false;
  }

  copied: boolean = false;
  async copyCommand(cmd: string) {
    this.copied = true;
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(cmd);
      } catch (e) {
        console.error("Error while copying code", e);
      }
    }
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }

  page: number = 1;
  searchText: string = "";

  setFilterType(filterType: string) {
    this.filterType = filterType;
    this.showDropdown = false;
  }

  // selectInstance(instance: any) {
  //   if (instance["emsStatus"] == 1) {
  //     return;
  //   }
  //   let temp: any = JSON.stringify(this.commands);
  //   if (temp.includes("[[tagName]]")) {
  //     temp = temp.replaceAll("[[tagName]]", instance["tagName"]);
  //   } else if (this.selectedInstance) {
  //     temp = temp.replaceAll(
  //       this.selectedInstance["tagName"],
  //       instance["tagName"]
  //     );
  //   }

  //   this.commands = JSON.parse(temp);

  //   temp = JSON.stringify(this.rawCommands);

  //   if (temp.includes("[[tagName]]")) {
  //     temp = temp.replaceAll("[[tagName]]", instance["tagName"]);
  //   } else if (this.selectedInstance) {
  //     temp = temp.replaceAll(
  //       this.selectedInstance["tagName"],
  //       instance["tagName"]
  //     );
  //   }
  //   this.rawCommands = JSON.parse(temp);

  //   if (instance["platform"] != "Windows") {
  //     this.selectedPlatform = "Linux";
  //   } else {
  //     this.selectedPlatform = instance["platform"];
  //   }

  //   this.selectedInstance = instance;
  // }

  async createMonitorGroup() {
    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }
    let monitorGroups: any = localStorage.getItem("site24x7MonitorGroups");

    if (monitorGroups) {
      let check: any = JSON.parse(monitorGroups).find((group: any) => {
        return group["display_name"] == this.accountId + "_" + this.regionId;
      });
      if (check) {
        return;
      }
    }

    account = JSON.parse(account);
    let data: any = {
      action: "create_monitor_group",
      id_token: localStorage.getItem("idT"),
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      account_id: this.accountId + "_" + this.regionId,
      origin: "swayam",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }
  }

  instancesList: any = [];
  destroyed: boolean = false;
  loading: boolean = false;
  showDropdown: boolean = false;

  checkForPending(instance_id: string) {
    return this.pending.find((instance: any) => {
      return instance["instance_id"] == instance_id;
    });
  }

  async loadInstances(nextToken?: any) {
    this.loading = true;
    if (!nextToken) {
      this.instancesList = [];
    }
    let data: any = {
      action: "list_ec2_instances",
      account_id: this.accountId,
      region_id: this.regionId,
      filter_type: this.filterType || "", //"", //Instance Status
      filter_value: this.searchText, //"running",
      limit: 100,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Tags", "EC2 Instances Fetched");
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2v2`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      this.instancesList = [...this.instancesList, ...result.instances];
      let dt: any = [];
      result.instances.forEach((instance: any, index: number) => {
        const name = instance["Tags"].find((tag: any) => {
          return tag["Key"] == "Name";
        });
        if (name) {
          instance["display_name"] = name["Value"];
        } else {
          instance["display_name"] = "-";
        }
        instance["actionStart"] = false;
        if (this.checkForPending(instance["InstanceId"])) {
          instance["pending"] = true;
        }
        if (instance.instanceStatus == "running") {
          dt.push(instance);
          instance;
        }
      });
      if (result.nextToken && result.nextToken != "") {
        await this.loadInstances(result.nextToken);
      } else {
        await this.listMonitors();
      }
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    this.loading = false;
  }

  async listMonitors() {
    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }

    account = JSON.parse(account);

    let data: any = {
      action: "list_monitors",
      id_token: localStorage.getItem("idT"),
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("un"),
      origin: "swayam",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.instancesList.forEach((instance: any) => {
        let monitor = result.monitors.find((monitor: any) => {
          return (
            monitor["conf_instance_id"] == instance["instanceId"] ||
            monitor["display_name"] == instance["tagName"]
          );
        });

        if (monitor) {
          if (monitor["conf_instance_id"] == instance["instanceId"]) {
            instance["emsStatus"] = 1;
            instance["emsDetails"] = monitor;
          } else if (monitor["display_name"] == instance["tagName"]) {
            instance["repeat_name"] = true;
          }
        } else {
          instance["emsStatus"] = -1;
        }
      });
      // this.getInstanceStatus(this.monitors);
    }
  }
}
