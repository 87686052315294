import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";

import { APIService } from "./../api/api.service";
import { NotifierService } from "./../_services/notifier.service";
import * as moment from "moment";
import { tokenExpired } from "./../api/api.service";

declare var window: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  otpRequired: boolean = false;
  tokenExpired: any = tokenExpired;
  whitelabelData: any = {
    pd: {
      appName: "",
      logoDark: "",
      logoLight: "",
    },
  };

  passwordUpdated: boolean = false;
  registered: boolean = false;
  currentImage: number = 0;
  imageTimer: any = null;

  nextImage() {
    if (this.currentImage == this.carousel.length - 1) {
      this.currentImage = 0;
    } else {
      this.currentImage += 1;
    }
  }

  setImage(count: number) {
    this.currentImage = count;
    if (this.imageTimer) {
      clearInterval(this.imageTimer);
    }
    this.imageTimer = setInterval(() => {
      this.nextImage();
    }, 5000);
  }

  expiresIn: number = null;
  public loginForm = new UntypedFormGroup({
    userName: new UntypedFormControl("addet0260@gmail.com", []),
    password: new UntypedFormControl("", []),
  });

  public otpForm = new UntypedFormGroup({
    otpText: new UntypedFormControl("1234", []),
  });

  get f() {
    return this.loginForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }

  register: boolean = false;

  loading: any = {
    login: false,
    otp: false,
  };

  public username = "";
  public password = "";
  public otpText = "";

  forgotPassword: boolean = false;

  public uid = localStorage.getItem("t");
  public t = localStorage.getItem("t");
  public returnUrl = "";
  public role = "Admin";

  public hideRoleSelection = false;

  isInvalid = false;
  error = "";
  validations: any = {
    username: {
      policy: {
        check: {
          key: "otpRequired",
          value: false,
        },
        required: true,
        regex: (text: string) => {
          let regexFormat: RegExp =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
          return regexFormat.test(text);
        },
      },
      name: "Email ID",
    },
    password: {
      policy: {
        check: {
          key: "otpRequired",
          value: false,
        },
        required: true,
      },
      name: "Password",
    },
    otpText: {
      policy: {
        required: true,
        check: {
          key: "otpRequired",
          value: true,
        },
      },
      name: "OTP",
    },
  };
  validate(): boolean {
    let ref: any = this;
    let check: boolean = true;
    Object.keys(this.validations).forEach((key: string) => {
      this.errors[key]["error"] = false;
      if (
        (this.validations[key]["policy"]["check"] &&
          ref[this.validations[key]["policy"]["check"]["key"]] ==
            this.validations[key]["policy"]["check"]["value"]) ||
        !this.validations[key]["policy"]["check"]
      ) {
        if (
          this.validations[key]["policy"]["required"] &&
          (!ref[key].trim() || ref[key].trim() == "")
        ) {
          this.errors[key]["error"] = true;
          this.errors[key][
            "message"
          ] = `${this.validations[key]["name"]} is a mandatory field`;
          check = false;
        } else if (
          this.validations[key]["policy"]["regex"] &&
          this.validations[key]["policy"]["regex"](ref[key]) === false
        ) {
          this.errors[key]["error"] = true;
          this.errors[key][
            "message"
          ] = `Entered ${this.validations[key]["name"]} is not valid`;
          check = false;
        } else {
          this.errors[key]["error"] = false;
        }
      } else {
        this.errors[key]["error"] = false;
      }
    });

    return check;
  }
  errors: any = {
    username: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
    otpText: {
      error: false,
      message: "",
    },
  };
  showPassword: boolean = false;
  passwordInterval: any = null;

  showpopOTP = false;
  otp = "";
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {
    this.whitelabelData = this.route.snapshot.data.data;
    
    this.carousel.forEach((page: any) => {
      page['description'] = page['description'].replace("${this.whitelabelData['pd']['appName']}", this.whitelabelData['pd']['appName'])    
    })

    if (this.whitelabelData['pd']["logoLight"]) {
      this.changeFavicon(this.whitelabelData['pd']['logoLight']);
    }
  }

  carousel: any = [
    {
      title: "Dashboards",
      description:
        "Gain valuable insights and make data-driven decisions faster and more effectively, while also improving collaboration and productivity within your team",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_Dashboard_BlueBG.svg",
    },
    {
      title: "Security",
      description:
        "Advanced algorithms and analytics, the platform can analyze vast amounts of data in real-time and provide you with valuable insights into your cloud operations.",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_+Security_BlueBG.svg",
    },
    {
      title: "Cost Optimisation",
      description: "Optimize your cloud costs and performance with ${this.whitelabelData['pd']['appName']}'s advanced analytics and automation, freeing up resources for business innovation.",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/3_Cost+Optimisation_BlueBg.svg",
    },
  ];

  changeFavicon(newFaviconURL: any) {
    const favicon: any = document.getElementById("favicon");
    if (favicon) {
      favicon.href = newFaviconURL;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.type = "image/png";
      newFavicon.href = newFaviconURL;
      newFavicon.id = "favicon";
      document.head.appendChild(newFavicon);
    }
  }

  redirectURL: any;

  trackByFn(index: number, obj: any) {
    return obj.id;
  }

  alterPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.passwordInterval = setTimeout(() => {
        this.showPassword = false;
        clearInterval(this.passwordInterval);
        this.passwordInterval = null;
      }, 5000);
    } else if (this.passwordInterval) {
      clearInterval(this.passwordInterval);
      this.passwordInterval = null;
    }
  }

  async ngOnInit() {
    this.imageTimer = setInterval(() => {
      this.nextImage();
    }, 5000);

    this.route.queryParams.subscribe((res) => {
      if (res["pass"]) {
        this.passwordUpdated = true;
        this.router.navigate(["/login"]);
        return true;
      }
      if (res["register"]) {
        this.registered = true;
        this.router.navigate(["/login"]);
        return true;
      }
      this.redirectURL = JSON.parse(JSON.stringify(res));
      if (
        this.redirectURL["redirectURL"] &&
        this.redirectURL["redirectURL"].indexOf("?") > -1
      ) {
        this.redirectURL["redirectURL"]
          .split("?")[1]
          .split("&")
          .map((query: any) => {
            this.redirectURL[query.split("=")[0]] = query.split("=")[1];
          });
      }
      if (this.redirectURL["redirectURL"]) {
        this.redirectURL["redirectURL"] =
          this.redirectURL["redirectURL"].split("?")[0];
      } else {
        this.redirectURL = null;
      }
      return true;
    });
    //localStorage.clear();

    if (window.location.href.indexOf("app.swayam.cloud") != -1) {
      this.role = "Admin";
    } else {
      this.role = "Client";
    }
    this.hideRoleSelection = true;
    
    setTimeout(() => {
      this.ifTokenIsValid();
    }, 1000);
  }

  async ifTokenIsValid() {
    if (
      localStorage.getItem("rt") == null &&
      localStorage.getItem("t") == null
    ) {
      return false;
    }
    this.tokenExpired["checking"] = true;
    let data = {
      //fromIndex: 1,
      //toIndex: '10',
      clientemailid: localStorage.getItem("eId"),
      accountid: localStorage.getItem("accountId"),
      action: "check",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/tokenvalidation`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);
    this.tokenExpired["checking"] = false;
    if (result.s == "1") {
      //this.router.navigate(['/dash']);
      if (this.redirectURL) {
        let temp = this.redirectURL["redirectURL"];
        if (!temp) {
          if (this.role == "Admin") {
            this.router.navigate(["/dash/home"]);
            // this.router.navigate(["/dash/tell-me/alerts"]);
            return true;
          } else if (this.role == "Client") {
            let storage: any = localStorage.getItem("menuList");
            if (storage) {
              storage = JSON.parse(storage);
              if (Object.keys(storage).includes("Dashboard")) {
                this.router.navigate(["/dash/home"]);
                // this.router.navigate(["/dash/tell-me/alerts"]);
                return true;
              } else {
                this.router.navigate(["/dash/ticket/create"]);
                return true;
              }
            } else {
              this.router.navigate(["/dash/ticket/create"]);
              return true;
            }
          }
          return false;
        }
        delete this.redirectURL["redirectURL"];
        this.router.navigate([temp], { queryParams: this.redirectURL });
        return true;
      } else {
        if (this.role == "Admin") {
          this.router.navigate(["/dash/home"]);
          // this.router.navigate(["/dash/tell-me/alerts"]);
          return true;
        } else if (this.role == "Client") {
          let storage: any = localStorage.getItem("menuList");
          if (storage) {
            storage = JSON.parse(storage);
            if (Object.keys(storage).includes("Dashboard")) {
              this.router.navigate(["/dash/home"]);
              // this.router.navigate(["/dash/tell-me/alerts"]);
              return true;
            } else {
              this.router.navigate(["/dash/ticket/create"]);
              return true;
            }
          } else {
            this.router.navigate(["/dash/ticket/create"]);
            return true;
          }
        }
      }
    } else {
      //localStorage.clear();
    }

    return true;
  }

  async login(validate: boolean = true) {
    if (this.tokenExpired["checking"]) {
      return false;
    }
    this.otpText = "";
    //this.notifier.alert("Info", "", "selector as an optional parameter", "info");
    //this.notifier.alert("Success", "", "selector as an optional parameter", "success");
    //this.notifier.alert("Warning", "", "selector as an optional parameter", "warning");
    //this.notifier.alert("Error", "", "selector as an optional parameter", "error");

    this.loginForm.markAsDirty();

    localStorage.clear();

    Object.keys(this.loginForm.controls).forEach((key) => {
      this.loginForm.controls[key].markAsDirty();
    });

    if (validate && !this.validate()) {
      return false;
    }

    /*    this.loading = true;*/

    // if (this.username == '' || this.password == '') {
    //   this.error = 'Please Enter User Name and Password!';
    //   this.notifier.alert(
    //     'Error',
    //     '',
    //     'Please Enter User Name and Password',
    //     'error',
    //     5000
    //   );
    //   this.isInvalid = true;
    //   this.notifier.loading(false);
    //   return false;
    // }

    this.loading["login"] = true;

    // if (this.username == "" || this.password == "") {
    //     this.error = "Please Enter User Name and Password!";
    //     this.notifier.alert(
    //         "Error",
    //         "",
    //         "Please Enter User Name and Password",
    //         "error",
    //         5000
    //     );
    //     this.isInvalid = true;
    //     this.notifier.loading(false);
    //     return false;
    // }

    let data: any = {
      a: "login",
      eId: this.username.toLowerCase(),
      pswd: this.password,
      ut: "client",
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/client/users/logindev`;

    if (this.role == "Admin") {
      //apiURL = `${APIService.API_ENDPOINT}/login`;
      apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;

      data = {
        eId: this.username.toLowerCase(),
        pswd: this.password,
        ut: "msops",
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == "1" || result.s == "1") {
      this.resend = result.otpMail || "no-reply@swayam.cloud";
      //this.showpopOTP = true;

      if (this.role != "Admin") {
        localStorage.setItem("menuList", JSON.stringify(result.user.ml));

        localStorage.setItem("ut", result.user.ut);
        localStorage.setItem("un", result.user.un);
        localStorage.setItem("ph", result.user.ph);

        localStorage.setItem("masterId", result.user.masterId);

        localStorage.setItem("com", result.user.com);
        localStorage.setItem("des", result.user.des);
        localStorage.setItem("accountStatus", result.user.accountStatus);
        localStorage.setItem("eId", result.user.eId);
        localStorage.setItem("expires", result.user.expires);
        localStorage.setItem("productType", result.user.productType);
        localStorage.setItem("expiresIn", result.user.expiresIn || "");
        localStorage.setItem("customerId", result.user.customerId);
        localStorage.setItem("clientId", result.user.customerId);
        localStorage.setItem("subPeriod", result.user.subPeriod);
        localStorage.setItem("subType", result.user.subType);
        localStorage.setItem("tagsList", JSON.stringify(result.user.tagsList));
        localStorage.setItem("tagsEnabled", result.user.tagsEnabled);
        if (result.user.expires) {
          let period = moment(result.user.expires, "YYYY-MM-DD");
          let diffObj: any;
          if (result.user.subPeriod) {
            let sub = moment(result.user.expires, "YYYY-MM-DD").subtract(
              result.user.subPeriod,
              "months"
            );
            diffObj = period.diff(sub, "days");
          } else {
            diffObj = result.user.expiresIn > 7 ? 15 : 7;
          }
          localStorage.setItem("totalPeriod", diffObj);
        }
        // this.expiresIn = result.user.expiresIn || 0;
        // if (result.user.customerType == 'mp') {
        //   if (result.user.expiresIn != undefined) {
        //     localStorage.setItem('accessType', 'Free Trial');
        //   }
        //   if (result.user.expiresIn == undefined) {
        //     localStorage.setItem('accessType', 'Billing');
        //   }
        // } else {
        //   localStorage.setItem('accessType', 'Managed');
        // }
        localStorage.setItem("subscription", result.user.subscription);
        localStorage.setItem("acT", result.user.acT || "readandwrite");
        localStorage.setItem("role", this.role);
        localStorage.setItem("customerType", result.user.customerType);

        result.mfaRequired = result.user.mfaRequired;
      } else {
        localStorage.setItem("accessType", "Admin");
        localStorage.removeItem("accountGroupdetails");

        localStorage.setItem("menuList", JSON.stringify(result.ml));

        localStorage.setItem("clientId", result.cid);
        // localStorage.setItem('t', result.at);

        localStorage.setItem("ut", result.ut);
        localStorage.setItem("un", result.un);
        localStorage.setItem("ph", result.ph);

        localStorage.setItem("com", result.com);
        localStorage.setItem("des", result.des);
        localStorage.setItem("eId", result.eId);
        localStorage.setItem("acT", result.acT || "readandwrite");
        localStorage.setItem("role", this.role);
        localStorage.setItem("accountDetailsList", result.accountDetailsList);
      }

      try {
        window.analyticsMapUser(result.eId);
      } catch (e) {
        console.log(e);
      }
      localStorage.setItem("otpReq", result.mfaRequired ? "1" : "0");
      if (!result.mfaRequired) {
        if (localStorage.getItem("accountStatus") == "Inactive") {
          this.otpRequired = false;
          this.username = "";
          this.password = "";
          // this.router.navigate(["/dash/accounts/aws"]);
          this.notifier.alert(
            "Info",
            "",
            "Account is Disabled/Expired. Please check with admin",
            "info",
            5000
          );
        }
        if (localStorage.getItem("ut") == "userAdmin") {
          localStorage.setItem("ut", "client");
          localStorage.setItem("isUserAdmin", "1");
        }
        localStorage.setItem("t", result.at);
        localStorage.setItem("idT", result.id);
        localStorage.setItem("rt", result.rt);
        let cbsCheck =
          window.location.host == "cbs.swayam.cloud" ||
          window.location.host == "cbsadmin.swayam.cloud";
        if (cbsCheck) {
          this.router.navigate(["/cbs/mastersummary"]);
          return true;
        } else {
          if (this.redirectURL) {
            let temp = this.redirectURL["redirectURL"];
            if (!temp) {
              if (this.role == "Admin") {
                this.router.navigate(["/dash/home"]);
                // this.router.navigate(["/dash/tell-me/alerts"]);
                return true;
              } else if (this.role == "Client") {
                let storage: any = localStorage.getItem("menuList");
                if (storage) {
                  storage = JSON.parse(storage);
                  if (Object.keys(storage).includes("Dashboard")) {
                    this.router.navigate(["/dash/home"]);
                    // this.router.navigate(["/dash/tell-me/alerts"]);
                    return true;
                  } else {
                    this.router.navigate(["/dash/ticket/create"]);
                    return true;
                  }
                } else {
                  this.router.navigate(["/dash/ticket/create"]);
                  return true;
                }
              }
              return false;
            }
            delete this.redirectURL["redirectURL"];
            this.router.navigate([temp], { queryParams: this.redirectURL });
            return true;
          } else {
            if (this.role == "Admin") {
              this.router.navigate(["/dash/home"]);
              // this.router.navigate(["/dash/tell-me/alerts"]);
              return true;
            } else if (this.role == "Client") {
              let storage: any = localStorage.getItem("menuList");
              if (storage) {
                storage = JSON.parse(storage);
                if (Object.keys(storage).includes("Dashboard")) {
                  this.router.navigate(["/dash/home"]);
                  // this.router.navigate(["/dash/tell-me/alerts"]);
                  return true;
                } else {
                  this.router.navigate(["/dash/ticket/create"]);
                  return true;
                }
              } else {
                this.router.navigate(["/dash/ticket/create"]);
                return true;
              }
            }
          }
        }
      } else {
        if (result.msg == "Success") {
          // await this.sendOPT();
          this.otpRequired = true;
        }
      }
    } else {
      if (result.error.toLowerCase().includes("password")) {
        this.errors["password"] = {
          error: true,
          message: result.error,
        };
      } else if (result.error.toLowerCase().includes("email")) {
        this.errors["username"] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert("Info", "", result.error, "info", 5000);
      }
    }

    this.loading["login"] = false;
    return false;
  }

  // async sendOPT() {
  //   this.notifier.loading(true);
  //   let data = {
  //     ac: 'sendOtp',
  //     un: localStorage.getItem('un'),
  //     eId: localStorage.getItem('eId'),
  //     ut: localStorage.getItem('ut'),
  //   };

  //   let apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;

  //   // if (this.role == 'Admin') {
  //   //   //apiURL = `${APIService.API_ENDPOINT}/login`;
  //   //   apiURL = `${APIService.API_ENDPOINTV3}/admin/users/login`;

  //   //   data['ut'] = 'msops';
  //   // }

  //   let result = await this.apiServer.postDataPromis(apiURL, data);

  //   if (result.status == '1' || result.s == '1') {
  //   } else {
  //     this.notifier.alert('Error', '', result.msg, 'error', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  showExpired: boolean = false;
  validating: boolean = false;

  async validateOTP(event: any = null) {
    if (this.tokenExpired["checking"]) {
      return false;
    }
    if ((!event && this.otpText.trim().length < 6) || this.validating)
      return false;
    if (!this.validate()) {
      return false;
    }
    this.validating = true;
    this.loading["otp"] = true;
    let data: any = {
      ac: "validateOtp",
      un: localStorage.getItem("un"),
      eId: localStorage.getItem("eId").toLowerCase(),
      ut: this.role == "Admin" ? "msops" : "client",
      otp: this.otpText.trim(),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;

    if (this.role != "Admin") {
      data["a"] = data["ac"];
      data["ut"] = localStorage.getItem("ut");

      apiURL = `${APIService.API_ENDPOINTV3}/client/users/logindev`;
    }

    // if (this.role == 'Admin') {
    //   //apiURL = `${APIService.API_ENDPOINT}/login`;
    //   apiURL = `${APIService.API_ENDPOINTV3}/admin/users/login`;

    //   data['ut'] = 'msops';
    // }

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == "1" || result.s == "1") {
      localStorage.setItem("role", this.role);

      if (result.msg == "Please try again with Valid OTP") {
        this.errors["otpText"] = {
          error: true,
          message: result.msg,
        };
        // this.notifier.alert("Error", "", result.msg, "error", 5000);
      } else {
        if (localStorage.getItem("ut") == "userAdmin") {
          localStorage.setItem("ut", "client");
          localStorage.setItem("isUserAdmin", "1");
        }
        localStorage.setItem("t", result.at);
        localStorage.setItem("rt", result.rt);
        localStorage.setItem("idT", result.id);

        if (localStorage.getItem("accountStatus") == "Inactive") {
          this.otpRequired = false;
          this.username = "";
          this.password = "";
          this.otpText = "";
          // this.router.navigate(["/dash/accounts/aws"]);
          this.notifier.alert(
            "Info",
            "",
            "Account is Disabled/Expired. Please check with admin",
            "info",
            5000
          );
        } else {
          if (this.redirectURL) {
            let temp = this.redirectURL["redirectURL"];
            if (temp) {
              delete this.redirectURL["redirectURL"];
              this.router.navigate([temp], { queryParams: this.redirectURL });
              return true;
            }
          }
          if (this.role == "Admin") {
            this.router.navigate(["/dash/home"]);
            // this.router.navigate(["/dash/tell-me/alerts"]);
            return true;
          } else if (this.role == "Client") {
            let productType: string = localStorage.getItem("productType");

            if (productType == "WAFR") {
              this.router.navigate(["/dash/wafr"]);
              return true;
            }

            let storage: any = localStorage.getItem("menuList");
            if (storage) {
              storage = JSON.parse(storage);
              if (Object.keys(storage).includes("Dashboard")) {
                this.router.navigate(["/dash/home"]);
                // this.router.navigate(["/dash/tell-me/alerts"]);
                return true;
              } else {
                this.router.navigate(["/dash/ticket/create"]);
                return true;
              }
            } else {
              this.router.navigate(["/dash/ticket/create"]);
              return true;
            }
          }
        }
      }
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    this.loading["otp"] = false;
    this.validating = false;
    return false;
  }

  resend: string = "";
  async loginOPT() {
    let data = {
      a: "resendOtp",
      un: localStorage.getItem("un"),
      eId: localStorage.getItem("eId"),
      ut: localStorage.getItem("ut"),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/client/users/logindev`;

    this.otpText = "";

    if (this.role == "Admin") {
      // this.loading['otp'] = false;
      // this.loading['login'] = false;
      // this.resend = true;
      this.login(false);
      return false;
      // return false;
      //apiURL = `${APIService.API_ENDPOINT}/login`;
      // apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;
    } else {
      // this.loading['otp'] = true
      this.loading["login"] = true;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == "1" || result.s == "1") {
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);

      this.error = result.msg;
    }

    this.resend = result.otpMail || "no-reply@swayam.cloud";
    this.loading["otp"] = false;
    this.loading["login"] = false;

    return false;
  }

  ngOnDestroy() {
    if (this.imageTimer) {
      clearInterval(this.imageTimer);
    }
  }
}
