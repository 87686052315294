import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-first-login-getting-started",
  templateUrl: "./first-login-getting-started.component.html",
  styleUrls: ["./first-login-getting-started.component.css"],
})
export class FirstLoginGettingStartedComponent implements OnInit {
  @Output() hide = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  hideModel() {
    this.router.navigate(["/dash/accounts/aws"]);
    this.hide.emit(false);
  }

  ngOnInit(): void {}
}
