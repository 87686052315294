<div class="card" [ngStyle]="{'display': showInnerData ? 'none': 'block'}">
  <h5 class="card-header">Cloud Trail</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div class="dropdowns dropdown5">
      <div>
        <select class="form-select" name="service" id="service" [(ngModel)]="service" (change)="setSubService()">
          <option value="EC2">EC2</option>
          <option value="RDS">RDS</option>
          <option value="Others">Others</option>
        </select>
      </div>
      <div *ngIf="service == 'EC2' || service == 'RDS'">
        <select name="subService" id="subService" [(ngModel)]="subService" (change)="load(true)">
          <option value={{service.value}} *ngFor="let service of subServices[service]">
            {{service.str}}
          </option>
        </select>
      </div>
      <div *ngIf="service == 'Others'">
        <div class="service21">
          <select class="subService24" data-live-search="true" id="subService" [(ngModel)]="subService"
            (change)="load(true)">
            <option [value]="service.value" *ngFor="let service of subServices[service]">
              {{service.str}}
            </option>
          </select>
        </div>
      </div>
      <div>
        <input autocomplete="off" class="datetimepicker" type="text" [(ngModel)]="dateRange" />
      </div>
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': cloudTrailList.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of cloudTrailList; let j = index">
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': cloudTrailList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div class="no_data61">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="nextToken" class="nextToken67">
      <button class="btn btn-light next68" (click)="load()">Next</button>
    </div>
  </div>
</div>
<div [ngStyle]="{'display': !showInnerData ? 'none': 'block'}">
  <div class="card">
    <h5 class="card-header">
      <button class="btn btn-light" (click)="goBack()">
        < Back</button>
          <span>Event Details</span>
    </h5>
    <div class="card-body">
      <div class="card">
        <!--<h5 class="card-header">
          <span>Details</span>
        </h5>-->
        <div class="card-body">
          <div class="panel panel-default">
            <div class="panel-heading panel_heading86"></div>

            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <b>Event Name</b>
                  <p id="eventname">
                    {{ innerData.EventName }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Event ID</b>
                  <p id="eventid">{{ innerData.EventId }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Read Only</b>
                  <p id="readonly">{{ innerData.ReadOnly }}</p>
                </div>
                <div class="col-md-6">
                  <b>Event Source</b>
                  <p id="eventsource">{{ innerData.EventSource }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>AWS Access Key</b>
                  <p id="aws_access_key">{{ innerData.accessKeyId }}</p>
                </div>
                <div class="col-md-6">
                  <b>AWS Region</b>
                  <p id="aws_region">
                    {{ innerData.CloudTrailEvent && innerData.CloudTrailEvent.awsRegion }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Event Time</b>
                  <p id="eventtime">{{ innerData.EventTime }}</p>
                </div>
                <div class="col-md-6">
                  <b>Username</b>
                  <p id="username">{{ innerData.Username }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Source IP Address</b>
                  <p id="source_ip_address">{{ innerData.sourceIPAddress }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h5 class="card-header">
          <span>Resources Referenced</span>
        </h5>
        <div class="card-body">
          <div class="panel panel-default">
            <div class="panel-heading panel_heading149"></div>

            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <b>Resource Type</b>
                    </div>
                    <div class="col-md-6">
                      <b>Resource Name</b>
                    </div>
                  </div>
                  <div class="row" *ngFor="let resource of innerData.Resources">
                    <div class="col-md-6">
                      <p>{{resource.ResourceType}}</p>
                    </div>
                    <div class="col-md-6">
                      <p>{{resource.ResourceName}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h5 class="card-header">
          <span>Event Record</span>
        </h5>
        <div class="card-body">
          <div class="panel panel-default">
            <div class="panel-heading panel_heading182"></div>

            <div class="panel-body">
              <div class="row">
                <div class="col-md-12">
                  <pre class="code language-json panel_body187">
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  div.code {
    white-space: pre;
  }
</style>