import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-tag-tab',
  templateUrl: './view-tag-tab.component.html',
  styleUrls: ['./view-tag-tab.component.css']
})
export class ViewTagTabComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() tagList :any=[];
  @Input() regionHeader:any =[];

  constructor(public notifier: NotifierService,) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }
  toggleItem(item: any): void { 
    item.isHidden = !item.isHidden; 
  } 
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}
