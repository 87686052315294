import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import * as moment from "moment";

@Component({
  selector: "app-notification-tab",
  templateUrl: "./notification-tab.component.html",
  styleUrls: ["./notification-tab.component.css"],
})
export class NotificationTabComponent implements OnInit {
  emailId = localStorage.getItem("eId");
  accountId = localStorage.getItem("accountId");
  notification: any[] = [];
  @Input() noticount: any = 0;
  @Output() notiRefresh = new EventEmitter<any>();
  loading = true;
  currentMessage: any;
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.loadNotification();
    });
  }

  //Average Retention Time
  async loadNotification() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/global_notification/fetch_all_notifications/?email_id=${this.emailId}&account_number=${this.accountId}&user_type=internal`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          const urlRegex = /(https?:\/\/[^\s]+)/;
          this.notification = Object.entries(data["message"]).map(
            ([key, value]: [string, any]) => {
              const match = value.message.match(urlRegex);
              console.log(match);
              let link = "";
              if (match) {
                link = match[0];
                value.message = value.message.replace(urlRegex, "");
              }
              const formattedDate = moment(value.date_created).format(
                "MMMM Do YYYY, h:mm A"
              );
              return {
                name: key,
                msg: { ...value, date: formattedDate, link: link },
              };
            }
          );

          console.log(this.notification);
          this.loading = false;
        } catch (error) {}
      });
    } catch (error) {
      this.loading = false;
    }
    this.loading = false;
  }

  extractRouterLink(url: string): string {
    const index = url.indexOf("/dash");
    if (index !== -1) {
      return url.substring(index);
    }
    return url;
  }

  getSortedNotification() {
    return this.notification.sort((a: any, b: any) => {
      const dateA = new Date(a.msg.date_created);
      const dateB = new Date(b.msg.date_created);
      return dateB.getTime() - dateA.getTime();
    });
  }

  isCollapsed = true; // Initial collapse state
  paragraph = ""; // Paragraph text input

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  async readMsg(id: any) {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    try {
      let apiURL = `https://apis-eks.swayam.cloud/global_notification/update_notifications_read/?email_id=${this.emailId}&user_type=internal&list_of_notification_ids=${id}`;
      await this.apiServer
        .postDataPromis(apiURL, data, header)
        .then((data) => {});
      this.notification = this.notification.filter(
        (item) => item.msg.notification_id !== id
      );
      this.notiRefresh.emit({ status: 0 });
    } catch (error) {}
  }

  async readAllMsg() {
    const idString = this.notification
      .map((item) => item.msg.notification_id)
      .join(",");
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    try {
      let apiURL = `https://apis-eks.swayam.cloud/global_notification/update_notifications_read/?email_id=${this.emailId}&user_type=internal&list_of_notification_ids=${idString}`;
      await this.apiServer
        .postDataPromis(apiURL, data, header)
        .then((data) => {});
      this.notification = [];
      this.notiRefresh.emit({ status: 0 });
    } catch (error) {}
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
