import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
declare let window: any;
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
@Component({
  selector: 'launch-instance',
  templateUrl: 'launch.instance.component.html',
  styleUrls: [
    './launch.instance.component.css',
    './../../upload.bom.component.css',
    
  ],
})
export class LaunchInstanceComponent implements OnInit, OnDestroy {
  @Input('instance') instance: any;
  @Input('bom_id') bom_id: any;
  @Output('exit') exit: any = new EventEmitter<any>();
  payload: any = {
    action: 'create_ec2',
    guid: '',
    environment: '',
    account_id: '837682544263',
    region_id: 'ap-south-1',
    image_id: '',
    tags: [],
    client_id: '',
    instance_type: '',
    key_name: '',
    sg_ids: [],
    vpc_id: '',
    subnet_id: '',
    iam_role: '',
    login_user_email: '',
    disks: [
      {
        DeviceName: '/dev/xvda',
        Ebs: {
          VolumeSize: 10,
          VolumeType: 'gp3',
        },
      },
    ],
  };
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  stepCount: number = 0;
  uplaodBOMLoading: boolean = false;
  accountId: any;
  regionId: any;
  currentMessage: any;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}
  ngOnInit(): void {
    this.payload['instance_type'] = this.instance[0].instanceName;
    this.payload['login_user_email'] = this.userId;
    this.payload['disks'][0]['Ebs']['VolumeSize'] = this.instance[0].volumeSize;
    this.payload['guid'] = this.instance[0].guid;
    this.payload['account_id'] = this.accountId;
    this.payload['region_id'] = this.regionId;
    this.payload['environment'] = this.instance[0]['env']['id'];
    this.payload['sheet_name'] = this.instance[0]['env']['key'];
    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
    let client_id;
    if (this.urlPrefix == 'admin') {
      client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem('selectedClientList');
      })['clientIdList'];
    } else {
      client_id = localStorage.getItem('clientId');
    }
    this.payload['client_id'] = client_id;
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
      this.payload['instance_type'] = this.instance[0].instanceName;
      this.payload['login_user_email'] = this.userId;
      this.payload['disks'][0]['Ebs']['VolumeSize'] =
        this.instance[0].volumeSize;
      this.payload['guid'] = this.instance[0].guid;
      this.payload['account_id'] = this.accountId;
      this.payload['region_id'] = this.regionId;
      let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
      let client_id;
      if (this.urlPrefix == 'admin') {
        client_id = selectedClientData.find((client: any) => {
          return client.emailId == localStorage.getItem('selectedClientList');
        })['clientIdList'];
      } else {
        client_id = localStorage.getItem('clientId');
      }
      this.payload['client_id'] = client_id;
    });
  }

  checkForKeyValue(obj: any, key: string) {
    if (!obj[key] || obj[key] == '' || obj[key] == 0) {
      return false;
    }
    return true;
  }

  validate() {
    this.payload['errors'] = {};
    let check = true;
    if (this.stepCount == 1) {
      if (!this.checkForKeyValue(this.payload, 'image_id')) {
        this.payload['errors']['image_id'] = 'AMI ID is mandatory field';
        check = false;
      }
      if (!this.checkForKeyValue(this.payload, 'iam_role')) {
        this.payload['errors']['iam_role'] = 'IAM Role is mandatory field';
        check = false;
      }
      if (check) {
        this.stepCount = 2;
      }
      return;
    }
    if (this.stepCount == 2) {
      if (!this.checkForKeyValue(this.payload, 'vpc_id')) {
        this.payload['errors']['vpc_id'] = 'VPC ID is mandatory field';
        check = false;
      }
      if (!this.checkForKeyValue(this.payload, 'subnet_id')) {
        this.payload['errors']['subnet_id'] = 'Subnet ID is mandatory field';
        check = false;
      }
      if (
        this.payload['sg_ids'].length == 0 ||
        this.payload['sg_ids'][0] == ''
      ) {
        this.payload['errors']['sg_ids'] = 'Security Group is mandatory field';
        check = false;
      }
      if (check) {
        this.stepCount = 3;
      }
      return;
    }
    if (this.stepCount == 4) {
      if (!this.checkForKeyValue(this.payload, 'key_name')) {
        this.payload['errors']['key_name'] = 'Kay Name is mandatory field';
        check = false;
      }
      if (this.payload['tags'].length == 0) {
        this.payload['errors']['tags'] = 'Name tag is mandatory';
        check = false;
      } else {
        let tag = this.payload.tags.find((tag: any) => {
          return tag.Key == 'Name' && tag.Value != '';
        });
        if (!tag) {
          this.payload['errors']['tags'] = 'Name tag is mandatory';
          check = false;
        }
      }
    }
    if (check) {
      delete this.payload['errors'];
      if (this.stepCount == 4) {
        this.launchPopUP();
      }
    }
  }

  launchCheck: boolean = false;

  launchPopUP() {
    this.launchCheck = true;
  }

  launchTrigger(event: any) {
    if (event) {
      this.launch();
    }
    this.launchCheck = false;
  }

  async launch() {
    this.notifier.loading(true);
    this.payload['bom_id'] = this.bom_id;
    let data = this.payload;

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'BOMv2.Components.Launch',
      'Ec2 Instance Launched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2/create`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.message, 'success', 5000);
      this.close(true);
    } else {
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }
    this.notifier.loading(false);
  }

  close(value: any) {
    this.exit.emit(value);
  }

  saveMappings() {}

  ngOnDestroy(): void {}
}
