<div class="alarm_screen shdw">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 class="txt-cap">{{action == 'save' ? 'Create' : action}} EC2 Group</h4>
    </div>
    <div class="alarm_body alrm-pad">
      <div class="row">
        <label>Client ID</label>
        <input autocomplete="off" type="text" class="form-control" [attr.disabled]="userId" [ngModel]="userId">
      </div>
      <div class="row">
        <label>Account ID</label>
        <input autocomplete="off" type="text" class="form-control" [attr.disabled]="true" [ngModel]="accountId">
      </div>

      <div class="row">
        <label>EC2 Group Name</label>
        <input autocomplete="off" type="text" class="form-control" *ngIf="action == 'save'"
          [(ngModel)]="group.groupName">
        <input autocomplete="off" type="text" class="form-control" *ngIf="action == 'update'" [attr.disabled]="true"
          [ngModel]="group.groupName">
      </div>
      <div class="row">
        <label>Select Instances List</label>
        <select class="form-control" multiple id="instancesList" data-live-search="true">
          <option *ngFor="let instance of instances" value="{{instance.instanceId}}">{{instance.instanceId}}
            {{instance.tagName ? '(' + instance.tagName + ')' : '(' + instance.instanceName + ')'}}</option>
        </select>
      </div>
      <div class="alarm_actions">
        <div class="alrm-dply">
          <button class="btn btn-styl" (click)="updateEntry()" [ngClass]="{'click-disabled': !writeAccess}"
            [attr.disabled]="!writeAccess ? true : null">
            {{action == 'save' ? 'Create' : 'Update'}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>