<div class="screen">
  <div class="popup-container">
    <div class="popup-header">
      <div class="color-inherit popup-header-bold">
        Delink Site24x7
      </div>
      <div class="popup-header-description">
        Delinking {{ whitelabelData['pd']['appName'] }} with Site24x7 will delete the BU, Monitor Groups and Monitors created using {{ whitelabelData['pd']['appName'] }}
      </div>
    </div>
    <div class="popup-body">
      <ng-container *ngIf="!otpSent">
        Are you sure you want to delink Site24x7?
      </ng-container>
      <ng-container *ngIf="otpSent">
        <div>OTP</div>
        <div>
          <input type="text" name="" id="" class="form-control" [attr.disabled]="loading ? true : null"
            [(ngModel)]="otpText">
        </div>
        <div class="flex-end nav-link" *ngIf="!loading" (click)="sendOTP()">
          Resend OTP
        </div>
      </ng-container>
    </div>
    <div class="popup-actions flex-start">
      <button class="btn btn-danger-soft" *ngIf="!loading && !otpSent" (click)="sendOTP()">
        Delink
      </button>
      <button class="btn btn-danger-soft" *ngIf="!loading && otpSent" (click)="validateOTP()">
        Submit
      </button>
      <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>