<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-link active" id="a-tab" (click)="currentPage = 'a'" data-bs-toggle="tab" data-bs-target="#a"
      type="button" role="tab" aria-controls="a" aria-selected="true">
      AMD
    </a>
    <a class="nav-link" id="g-tab" data-bs-toggle="tab" data-bs-target="#g" (click)="currentPage = 'g'" type="button"
      role="tab" aria-controls="g" aria-selected="true">
      Graviton
    </a>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane active" id="a" role="tabpanel" aria-labelledby="a-tab">
    <ng-container *ngIf="currentPage == 'a'">
      <div *ngIf="recommendations.length > 0" class="pink_margin">
        Moving out of Intel to AMD has a definite cost advantage over intel. It has been noted that most of the
        workloads run
        seamlessly on AMD without any performance degradation. You may choose to move production to AMD based instances
        after
        thorough testing to avail the cost savings advantage.
      </div>
      <div class="flex_gap10 margintop-container">
        <div>Potential Saving: </div>
        <div>
          <span class="green_text fontsize_em" [innerHTML]="getTotalSavings()"></span>
        </div>
      </div>

      <div class="card-body">
        <div class="overflow_auto2">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Instance ID</th>
                <th>Instance Name</th>
                <th>Instance Type</th>
                <th>Recommended Instance Type</th>
                <th class="center">RAM</th>
                <th class="center">vCPU</th>
                <th>OS</th>
                <th class="center">Current Spend</th>
                <th class="center">New Spend</th>
                <th class="center">Savings</th>
              </tr>
            </thead>
            <tbody *ngIf="this.filterRecommendations().length > 0">
              <tr *ngFor="let recommendation of this.filterRecommendations() | paginate
                            : {
                                itemsPerPage: pagination['a']['perPage'],
                                currentPage: pagination['a']['page'],
                                totalItems: this.filterRecommendations().length
                              }; let i = index">
                <td>
                  {{
                  (pagination['a']['perPage'] * (pagination['a']['page'] - 1)) + i + 1 }}
                </td>
                <td>
                  {{ recommendation['instanceid'] }}
                </td>
                <td>
                  {{ recommendation['instancename'] }}
                </td>
                <td>
                  {{ recommendation['instancetype'] }}
                </td>
                <td>
                  {{ recommendation['recommendedInsType'] }}
                </td>
                <td class="center">
                  {{ recommendation['ram'] }}
                </td>
                <td class="center">
                  {{ recommendation['vCpu'] }}
                </td>
                <td>
                  {{ recommendation['platform'] }}
                </td>
                <td class="center">
                  {{ recommendation['currentPrice'] }}
                </td>
                <td class="center">
                  {{ recommendation['recommendedPrice'] }}
                </td>
                <td class="center">
                  <span [ngClass]="{green_text: recommendation['potential_savings'] > 0, red_text: recommendation['potential_savings'] < 0}">
                    {{ recommendation['potential_savings'].toFixed(2) }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody
              *ngIf="this.filterRecommendations().length == 0 && jobDetails && jobDetails['acctstatus'] === 'Completed'">
              <tr>
                <td [attr.colspan]="11" class="center">
                  No Recommendations Found. Click <span class="nav-link" (click)="scan.emit(true)">here</span> to scan
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!jobDetails && !fetching">
              <tr>
                <td [attr.colspan]="11" class="center">
                  No Scans Found. Click <span class="nav-link" (click)="scan.emit(true)">here</span> to scan
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="fetching">
              <tr>
                <td [attr.colspan]="11">
                  <div class="flex-center">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                    <span *ngIf="jobDetails">
                      {{ jobDetails['acctstatus'] }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginator">
          <div class="flex-start">
            <span class="no-wrap">Per Page</span>
            <select class="form-select" [(ngModel)]="pagination['a']['perPage']" (change)="pagination['a']['page'] = 1">
              <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
            </select>
          </div>
          <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
            (pageChange)="pagination['a']['page'] = $event">
          </pagination-controls>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="tab-pane active" id="g" role="tabpanel" aria-labelledby="g-tab">
    <ng-container *ngIf="currentPage == 'g'">
      <div *ngIf="recommendations.length > 0" class="pink_margin">
        Use of Graviton Processes may require a recompilation of the application program. It is highly recommended to
        run
        a
        workload first on a test/dev instance rigorously before moving it to live
      </div>
      <div class="flex_gap10 margintop-container">
        <div>Potential Saving: </div>
        <div>
          <span class="green_text fontsize_em" [innerHTML]="getTotalSavings()"></span>
        </div>
      </div>

      <div class="card-body">
        <div class="overflow_auto2">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Instance ID</th>
                <th>Instance Name</th>
                <th>Instance Type</th>
                <th>Recommended Instance Type</th>
                <th class="center">RAM</th>
                <th class="center">vCPU</th>
                <th>OS</th>
                <th class="center">Current Spend</th>
                <th class="center">New Spend</th>
                <th class="center">Savings</th>
              </tr>
            </thead>
            <tbody *ngIf="this.filterRecommendations().length > 0">
              <tr *ngFor="let recommendation of this.filterRecommendations() | paginate
                            : {
                                itemsPerPage: pagination['g']['perPage'],
                                currentPage: pagination['g']['page'],
                                totalItems: this.filterRecommendations().length
                              }; let i = index">
                <td>
                  {{
                  (pagination['g']['perPage'] * (pagination['g']['page'] - 1)) + i + 1 }}
                </td>
                <td>
                  {{ recommendation['instanceid'] }}
                </td>
                <td>
                  {{ recommendation['instancename'] }}
                </td>
                <td>
                  {{ recommendation['instancetype'] }}
                </td>
                <td>
                  {{ recommendation['recommendedInsType'] }}
                </td>
                <td class="center">
                  {{ recommendation['ram'] }}
                </td>
                <td class="center">
                  {{ recommendation['vCpu'] }}
                </td>
                <td>
                  {{ recommendation['platform'] }}
                </td>
                <td class="center">
                  {{ recommendation['currentPrice'] }}
                </td>
                <td class="center">
                  {{ recommendation['recommendedPrice'] }}
                </td>
                <td class="center">
                  <span [ngClass]="{green_text: recommendation['potential_savings'] > 0, red_text: recommendation['potential_savings'] < 0}">
                    {{ recommendation['potential_savings'].toFixed(2) }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody
              *ngIf="this.filterRecommendations().length == 0 && jobDetails && jobDetails['acctstatus'] === 'Completed'">
              <tr>
                <td [attr.colspan]="11" class="center">
                  No Recommendations Found. Click <span class="nav-link" (click)="scan.emit(true)">here</span> to scan
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!jobDetails && !fetching">
              <tr>
                <td [attr.colspan]="11" class="center">
                  No Scans Found. Click <span class="nav-link" (click)="scan.emit(true)">here</span> to scan
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="fetching">
              <tr>
                <td [attr.colspan]="11">
                  <div class="flex-center">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                    <span *ngIf="jobDetails">
                      {{ jobDetails['acctstatus'] }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginator">
          <div class="flex-start">
            <span class="no-wrap">Per Page</span>
            <select class="form-select" [(ngModel)]="pagination['g']['perPage']" (change)="pagination['g']['page'] = 1">
              <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
            </select>
          </div>
          <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
            (pageChange)="pagination['g']['page'] = $event">
          </pagination-controls>
        </div>
      </div>
    </ng-container>
  </div>
</div>