<div class="details-container-screen">
  <div class="details-container" *ngIf="instance">
    <div class="details-header flex-between gap-100">
      <span class="font-bold nowrap flex-start gap-4">
        <span>
          {{ instance['instanceName'] }}
        </span>
        <span class="item-desc">
          {{ instance['instanceType'] }}
        </span>
      </span>
      <div class="flex-start nowrap">
        <span>On-Demand Price: ${{ instance['pricing']['odPricing']['pricePerMonth'] }}</span>
        <span *ngIf="instance['pricing']['spotPricing']">
          |
        </span>
        <span class="flex-center gap-4 nowrap">
          <span *ngIf=" instance['pricing']['spotPricing']">Spot Price: ${{
            instance['pricing']['spotPricing']['pricePerMonth'] }}</span>
          <span class="item-desc" *ngIf="instance['pricing']['spotPricing']"
            [ngClass]="{'light-green': instance['pricing']['spotPricing']['diffPerMonth'] > 0, 'light-red': instance['pricing']['spotPricing']['diffPerMonth'] < 0}">
            <span class="inherit">
              {{((instance['pricing']['spotPricing']['diffPerMonth'] /
              instance['pricing']['odPricing']['pricePerMonth']) *
              100).toFixed(2) }}%
            </span>
            <span class="inherit">
              <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
            </span>
          </span>
        </span>
        <span (click)="hide()">
          <i class="fa fa-times pointer font-bold"></i>
        </span>
      </div>
    </div>
    <div class="details-body">
      <table class="table table-docs">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th colspan="1" class="center">1 Yr RI</th>
            <th colspan="1" class="center">3 Yr RI</th>
          </tr>
        </thead>
        <tbody>
          <tr class="light-blue">
            <td rowspan="3" class="border-grey width-40 relative">
              <div class="deg-90">
                Standard
              </div>
            </td>
            <td>
              Price/month
            </td>
            <td class="center">
              ${{ getPrice('standard', '1yr', 'No Upfront', 'pricePerMonth') }}
            </td>
            <td class="center">${{ getPrice('standard', '3yr', 'No Upfront', 'pricePerMonth') }}</td>
          </tr>
          <tr class="light-blue">
            <td>
              Price/hr
            </td>
            <td class="center">${{ getPrice('standard', '1yr', 'No Upfront', 'pricePerHour') }}</td>
            <td class="center">${{ getPrice('standard', '3yr', 'No Upfront', 'pricePerHour') }}</td>
          </tr>
          <tr class="border-grey light-blue">
            <td>
              Potential Savings`
            </td>
            <td class="center">
              <div>
                <span>
                  ${{ getPrice('standard', '1yr', 'No Upfront', 'diffPerMonth') }}
                </span>
                <div>
                  <span class="item-desc padd-less-font"
                    [ngClass]="{'light-green': getPrice('standard', '1yr', 'No Upfront', 'diffPerMonth') > 0, 'light-red': getPrice('standard', '1yr', 'No Upfront', 'diffPerMonth') < 0}">
                    <span class="inherit">
                      {{((getPrice('standard', '1yr',
                      'No Upfront', 'diffPerMonth') / instance['pricing']['odPricing']['pricePerMonth']) *
                      100).toFixed(2) }}%
                    </span>
                    <span class="inherit">
                      <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                    </span>
                  </span>
                </div>
              </div>
            </td>
            <td class="center">
              <div>
                <span>
                  ${{ getPrice('standard', '3yr', 'No Upfront', 'diffPerMonth') }}
                </span>
                <div>
                  <span class="item-desc padd-less-font"
                    [ngClass]="{'light-green': getPrice('standard', '3yr', 'No Upfront', 'diffPerMonth') > 0, 'light-red': getPrice('standard', '3yr', 'No Upfront', 'diffPerMonth') < 0}">
                    <span class="inherit">
                      {{((getPrice('standard', '3yr',
                      'No Upfront', 'diffPerMonth') / instance['pricing']['odPricing']['pricePerMonth']) *
                      100).toFixed(2) }}%
                    </span>
                    <span class="inherit">
                      <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                    </span>
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td rowspan="3" class=" width-40 relative">
              <div class="deg-90">
                Convertible
              </div>
            </td>
            <td>
              Price/month
            </td>
            <td class="center">${{ getPrice('convertible', '1yr', 'No Upfront', 'pricePerMonth') }}</td>
            <td class="center">${{ getPrice('convertible', '3yr', 'No Upfront', 'pricePerMonth') }}</td>
          </tr>
          <tr>
            <td>
              Price/hr
            </td>
            <td class="center">${{ getPrice('convertible', '1yr', 'No Upfront', 'pricePerHour') }}</td>
            <td class="center">${{ getPrice('convertible', '3yr', 'No Upfront', 'pricePerHour') }}</td>
          </tr>
          <tr>
            <td>
              Potential Savings`
            </td>
            <td class="center">
              <div>
                <span>
                  ${{ getPrice('convertible', '1yr', 'No Upfront', 'diffPerMonth') }}
                </span>
                <div>
                  <span class="item-desc padd-less-font"
                    [ngClass]="{'light-green': getPrice('convertible', '1yr', 'No Upfront', 'diffPerMonth') > 0, 'light-red': getPrice('convertible', '1yr', 'No Upfront', 'diffPerMonth') < 0}">
                    <span class="inherit">
                      {{((getPrice('convertible', '1yr',
                      'No Upfront', 'diffPerMonth') / instance['pricing']['odPricing']['pricePerMonth']) *
                      100).toFixed(2) }}%
                    </span>
                    <span class="inherit">
                      <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                    </span>
                  </span>
                </div>
              </div>
            </td>
            <td class="center">
              <div>
                <span>
                  ${{ getPrice('convertible', '3yr', 'No Upfront', 'diffPerMonth') }}
                </span>
                <div>
                  <span class="item-desc padd-less-font"
                    [ngClass]="{'light-green': getPrice('convertible', '3yr', 'No Upfront', 'diffPerMonth') > 0, 'light-red': getPrice('convertible', '3yr', 'No Upfront', 'diffPerMonth') < 0}">
                    <span class="inherit">
                      {{((getPrice('convertible', '3yr',
                      'No Upfront', 'diffPerMonth') / instance['pricing']['odPricing']['pricePerMonth']) *
                      100).toFixed(2) }}%
                    </span>
                    <span class="inherit">
                      <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                    </span>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>