<div class="card">
  <div class="card-header">Daily Variance Report</div>
  <div class="card-body">
    <!-- <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="daily-cost-variance-tab" (click)="currentPage = 'cost-variance-report'"
          data-bs-toggle="tab" data-bs-target="#daily-cost-variance" type="button" role="tab"
          aria-controls="daily-cost-variance" aria-selected="true">Daily Variance Report</button>
        <button class="nav-link" id="cost-anomaly-tab" (click)="currentPage = 'cost-anomaly-report'"
          data-bs-toggle="tab" data-bs-target="#cost-anomaly" type="button" role="tab" aria-controls="cost-anomaly"
          aria-selected="false">Cost Anomaly Report</button>
      </div>
    </nav> -->
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="daily-cost-variance" role="tabpanel"
        aria-labelledby="daily-cost-variance-tab">
        <div>
          <div class="card_body_style">
            <div [ngStyle]="{display: this.customerType !== 'mp' ? 'block' : 'none'}">
              <span class="font_weight_bold">Master Accounts</span>
              <span>
                <select class="form-control master_account_style_select" id="masterAccountSelect"
                  data-live-search="true" (change)="fetchMasterAccounts(true, true)">
                  <option [value]="account" *ngFor="let account of masterAccounts">
                    {{ account }}
                  </option>
                </select>
              </span>
            </div>
            <div>
              <span class="font_weight_bold">Range</span>
              <span>
                <select class="form-select" [(ngModel)]="range" (change)="fetchMasterAccounts(true, true)">
                  <option value="last_7_days">Last 7 days</option>
                  <option value="this_week">This Week</option>
                  <option value="last_week">Last Week</option>
                  <option value="this_month">This Month</option>
                  <option value="last_month">Last Month</option>
                  <option value="last_3_month">Last 3 Months</option>
                  <option value="last_6_month">Last 6 Months</option>
                  <option value="custom_range">Custom Range</option>
                </select>
              </span>
            </div>
            <div [ngStyle]="{display: range == 'custom_range' ? 'block': 'none'}">
              <span class="font_weight_bold">Custom Range</span>
              <span>
                <input type="text" class="datetimepicker form-control" id="datetimepicker">
              </span>
            </div>
          </div>
          <div class="text_align_right" *ngIf="range == 'last_6_month' && this.headers.length < 6">
            * Baseline month to view Billing Variance Report is April 2022
          </div>
          <div class="view_billing_variance_style">
            <div>
              Estimated Usage Details: <span class="font_weight_bold">{{loadRangeString()}}</span>
            </div>
            <div class="view_billing_variance_style_2"
              *ngIf="range != 'custom_range' || fetchDiffDays(this.dateRange['start_date'], this.dateRange['end_date']) <= (activeService ? MAX_DAYS_INNER : MAX_DAYS)">
              <div>
                Total: <span class="font_weight_bold" [innerHTML]="loadAllColumnTotalParsed()"></span>
              </div>
              <div
                *ngIf="(range == 'this_month' || range == 'last_month' || (customRange == 'month' && range != 'custom_range')) && !activeService && urlPrefix == 'admin'">
                Total: {{monthTotal(fetchMonthYear()) + ' (Monthly)'}}
                <ng-container *ngIf="!loadingObj[fetchMonthYear()]">
                  <span class="green_fancy"
                    *ngIf="checkForVariation( monthTotal(fetchMonthYear()), loadAllColumnTotalParsed(true))['check']">
                    <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>
                  </span>
                  <span class="red_fancy"
                    [attr.title]="'Variation: ' +checkForVariation( monthTotal(fetchMonthYear()), loadAllColumnTotalParsed(true))['variation']"
                    *ngIf="!checkForVariation( monthTotal(fetchMonthYear()), loadAllColumnTotalParsed(true))['check']">
                    <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>
                  </span>
                </ng-container>
                <span *ngIf="loadingObj[getValidMonth(fetchMonthYear())]">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="asset_style" />
                </span>
              </div>
              <div *ngIf="isExportable">
                <button class="btn btn-success-soft" (click)="exportData()">Export</button>
              </div>
            </div>
          </div>
          <div *ngIf="breadCrum.length > 1 && (range != 'custom_range' || fetchDiffDays(this.dateRange['start_date'], this.dateRange['end_date']) <=
            (activeService ? MAX_DAYS_INNER : MAX_DAYS))" class="breadcrums">
            <div *ngFor="let value of breadCrum; let i = index" class="breadCrum_item">
              <span (click)="i + 1 != breadCrum.length && navigate(value)"
                [ngStyle]="{cursor: i + 1 != breadCrum.length && 'pointer', color: i + 1 != breadCrum.length && 'blue'}">
                {{value.value}}
              </span>
              <span class="breadcrums_style" *ngIf="i + 1 != breadCrum.length">
                >
              </span>
            </div>
          </div>
          <ng-container
            *ngIf="range != 'custom_range' || fetchDiffDays(this.dateRange['start_date'], this.dateRange['end_date']) <= (activeService ? MAX_DAYS_INNER : MAX_DAYS)">
            <div class="daily_bill_style1">
              <table class="table table-docs table-hover" id="daily_bill_table">
                <thead>
                  <tr>
                    <td class="font_weight_bold">S. N.</td>
                    <td class="font_weight_bold float-left">Service Name</td>
                    <td class="font_weight_bold" *ngIf="activeService == 'EC2'">Tag Name</td>
                    <td class="font_weight_bold" *ngIf="activeService == 'EC2'">Instance Type
                      <span class="inputseach">
                        <input autocomplete="off" type="text" [(ngModel)]="filter" />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </td>
                    <td class="font_align">Total</td>
                    <td class="font_align"
                      *ngIf="(range == 'this_month' || range == 'last_month' || (customRange == 'month' && range != 'custom_range')) && !activeService && urlPrefix == 'admin'">
                      Total (Monthly)
                      <span *ngIf="loadingObj[fetchMonthYear()]">
                        <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scans" />
                      </span>
                    </td>
                    <ng-container *ngFor="let h of this.headers; let h_index = index">
                      <td class="text_align_right" (click)="fetchRangeData(h)">
                        <div class="white_space_nowrap">
                          {{ parsedHeader(h) }} <span *ngIf="parsedHeader_days(h)">({{parsedHeader_days(h)}}
                            Days)</span>
                        </div>
                        <div class="whitespace_nowrap2">
                          <span class="white_space_nowrap" [innerHTML]="loadColumnTotalParsed(h)">
                          </span>
                          <span class="compare_indicator"
                            [ngStyle]="{backgroundColor: compareValue(loadColumnTotal(h), loadColumnTotal(this.headers[h_index - 1]), h_index + 1 == this.headers.length)['type'], color: compareValue(loadColumnTotal(h), loadColumnTotal(this.headers[h_index - 1]), h_index + 1 == this.headers.length)['color']}"
                            *ngIf="h_index > 0 && compareValue(loadColumnTotal(h), loadColumnTotal(this.headers[h_index - 1]), h_index + 1 == this.headers.length)['indicator'] != 0">
                            <span class="color_inherit">
                              {{compareValue(loadColumnTotal(h), loadColumnTotal(this.headers[h_index - 1]), h_index +
                              1
                              ==
                              this.headers.length)['change']}}%
                            </span>
                            <span>
                              <i class="fa fa-caret-up color_inherit"
                                *ngIf="compareValue(loadColumnTotal(h), loadColumnTotal(this.headers[h_index - 1]), h_index + 1 == this.headers.length)['indicator'] == -1"
                                aria-hidden="true"></i>
                              <i class="fa fa-caret-down color_inherit"
                                *ngIf="compareValue(loadColumnTotal(h), loadColumnTotal(this.headers[h_index - 1]), h_index + 1 == this.headers.length)['indicator'] == 1"
                                aria-hidden="true"></i>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td class="text_align_right"
                        *ngIf="(range == 'last_3_month' || range == 'last_6_month') && customRange == null && !activeService && urlPrefix == 'admin'">
                        <div class="white_space_nowrap">
                          {{ parsedHeader(h) }} (Monthly)
                          <div class="font_weight_bold">
                            <ng-container *ngIf="!loadingObj[getValidMonth(h)]">
                              ${{monthTotal(h)}}
                              <span class="green_fancy"
                                *ngIf="checkForVariation( monthTotal(h), loadColumnTotal(h))['check']">
                                <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                              </span>
                              <span class="red_fancy"
                                [attr.title]="'Variation: ' +checkForVariation( monthTotal(h), loadColumnTotal(h))['variation']"
                                *ngIf="!checkForVariation( monthTotal(h), loadColumnTotal(h))['check']">
                                <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                              </span>

                            </ng-container>
                            <span *ngIf="loadingObj[getValidMonth(h)]">
                              <img src="../assets/img/loading_2.svg" alt="loading scans" class="get_validmonth" />
                            </span>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                  </tr>
                </thead>

                <tbody [ngStyle]="{ display: appended.length > 0 ? 'table-row-group' : 'none' }">
                  <ng-container *ngFor="let service of appended; let j = index">
                    <tr *ngIf="checkFilter(service)">
                      <td>{{j + 1}}</td>
                      <td class="float-left" *ngIf="checkIfClickable(service)" (click)="expandService(service)">
                        <span class="cliakable">{{service}}</span>
                      </td>
                      <td *ngIf="!checkIfClickable(service)" class="chech_iftable float-left">
                        <span class="check_iftable2">
                          {{service}}
                        </span>
                      </td>
                      <td class="float-left" *ngIf="activeService == 'EC2'">{{ getInstanceName(service) }}</td>
                      <td class="float-left" *ngIf="activeService == 'EC2'">{{ getInstanceType(service) }}</td>
                      <td [innerHTML]="fetchServiceTotal(service)" class="text_align_right"></td>
                      <td class="text_align_right"
                        *ngIf="(range == 'this_month' || range == 'last_month' || (customRange == 'month' && range != 'custom_range')) && !activeService && urlPrefix == 'admin'">
                        <div class="white_space_nowrap">
                          <span [innerHTML]="'$'+getMonthlyValue('MONTHLY', service).toFixed(2) + ' '"></span>
                          <span class="green_fancy"
                            *ngIf="checkForVariation( getMonthlyValue('MONTHLY', service), fetchServiceTotal(service, true) ? fetchServiceTotal(service, true)['finalTotalBill']: '0.00')['check']">
                            <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                          </span>
                          <span class="red_fancy"
                            [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue('MONTHLY', service), fetchServiceTotal(service, true) ? fetchServiceTotal(service, true)['finalTotalBill']: '0.00')['variation']"
                            *ngIf="!checkForVariation( getMonthlyValue('MONTHLY', service), fetchServiceTotal(service, true) ? fetchServiceTotal(service, true)['finalTotalBill']: '0.00')['check']">
                            <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                          </span>
                        </div>
                      </td>
                      <ng-container *ngFor="let h of this.headers; let h_index = index">
                        <td class="value_container text_align_right" (click)="fetchRangeData(h)">
                          <span [innerHTML]="loadPrasedValue(loadValue(service, h) || 0)">
                          </span>
                          <span class="compare_indicator" [ngStyle]="{backgroundColor: compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, this.headers[h_index -
                                                  1])['finalTotalBill'], h_index + 1 == this.headers.length)['type'], color: compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, this.headers[h_index -
                                                  1])['finalTotalBill'], h_index + 1 == this.headers.length)['color']}"
                            *ngIf="loadValue(service, h) && loadValue(service, h)['finalTotalBill'] && loadValue(service, this.headers[h_index -1]) && loadValue(service, this.headers[h_index -1])['finalTotalBill'] && compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, this.headers[h_index -
                                                  1])['finalTotalBill'], h_index + 1 == this.headers.length)['indicator'] != 0">
                            <span>
                              {{compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service,
                              this.headers[h_index -
                              1])['finalTotalBill'], h_index + 1 == this.headers.length)['change']}}%
                            </span>
                            <span>
                              <i class="fa fa-caret-up"
                                *ngIf="compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, this.headers[h_index -
                                                  1])['finalTotalBill'], h_index + 1 == this.headers.length)['indicator'] == -1"
                                aria-hidden="true"></i>
                              <i class="fa fa-caret-down"
                                *ngIf="compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, this.headers[h_index -
                                                  1])['finalTotalBill'], h_index + 1 == this.headers.length)['indicator'] == 1"
                                aria-hidden="true"></i>
                            </span>
                          </span>
                        </td>
                        <td class="text_align_right"
                          *ngIf="(range == 'last_3_month' || range == 'last_6_month') && customRange == null && !activeService && urlPrefix == 'admin'">
                          <div class="white_space_nowrap">
                            ${{getMonthlyValue(h, service).toFixed(2)}}
                            <span class="green_fancy"
                              *ngIf="checkForVariation( getMonthlyValue(h, service), loadValue(service, h) ? loadValue(service, h)['finalTotalBill']: '0.00')['check']">
                              <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                            </span>
                            <span class="red_fancy"
                              [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue(h, service), loadValue(service, h) ? loadValue(service, h)['finalTotalBill']: '0.00')['variation']"
                              *ngIf="!checkForVariation( getMonthlyValue(h, service), loadValue(service, h) ? loadValue(service, h)['finalTotalBill']: '0.00')['check']">
                              <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                            </span>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                    <ng-container *ngIf="checkIfClickable(service) && expanded[service]">
                      <ng-container *ngFor="let sub_servive of subServices(service); let index = index">
                        <tr>
                          <td>{{j + 1}}.{{ index + 1 }}</td>
                          <td class="float-left"><span class="cliakable check_iftable2"
                              (click)="loadMore(sub_servive)">{{sub_servive}}</span>
                          </td>
                          <td [innerHTML]="fetchSubServiceTotal(service, sub_servive)" class="text_align_right"></td>
                          <td class="text_align_right"
                            *ngIf="(range == 'this_month' || range == 'last_month' || customRange == 'month') && !activeService && urlPrefix == 'admin'">
                            <div class="white_space_nowrap">
                              <span
                                [innerHTML]="'$'+getMonthlyValue('MONTHLY', service, sub_servive).toFixed(2) + ' '"></span>
                              <span class="green_fancy"
                                *ngIf="checkForVariation( getMonthlyValue('MONTHLY', service, sub_servive), fetchSubServiceTotal(service, sub_servive, true) ? fetchSubServiceTotal(service, sub_servive, true)['finalTotalBill']: '0.00')['check']">
                                <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                              </span>
                              <span class="red_fancy"
                                [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue('MONTHLY', service, sub_servive), fetchSubServiceTotal(service, sub_servive, true) ? fetchSubServiceTotal(service, sub_servive, true)['finalTotalBill']: '0.00')['variation']"
                                *ngIf="!checkForVariation( getMonthlyValue('MONTHLY', service, sub_servive), fetchSubServiceTotal(service, sub_servive, true) ? fetchSubServiceTotal(service, sub_servive, true)['finalTotalBill']: '0.00')['check']">
                                <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                              </span>
                            </div>
                          </td>
                          <ng-container *ngFor="let h of this.headers; let h_index = index">
                            <td class="value_container text_align_right" (click)="fetchRangeData(h)">
                              <span class="white_space_nowrap"
                                [innerHTML]="loadPrasedValue(loadNestedValue(service, h, sub_servive) || 0)">
                              </span>
                              <span class="compare_indicator"
                                [ngStyle]="{backgroundColor: compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, this.headers[h_index -
                                                              1], sub_servive)['finalTotalBill'], h_index + 1 == this.headers.length)['type'], color: compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, this.headers[h_index -
                                                              1], sub_servive)['finalTotalBill'], h_index + 1 == this.headers.length)['color']}"
                                *ngIf="loadNestedValue(service, h, sub_servive) && loadNestedValue(service, h, sub_servive)['finalTotalBill'] && loadNestedValue(service, this.headers[h_index -1], sub_servive) && loadNestedValue(service, this.headers[h_index -1], sub_servive)['finalTotalBill'] && compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, this.headers[h_index -
                                                              1], sub_servive)['finalTotalBill'], h_index + 1 == this.headers.length)['indicator'] != 0">
                                <span>
                                  {{compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'],
                                  loadNestedValue(service, this.headers[h_index -
                                  1], sub_servive)['finalTotalBill'], h_index + 1 == this.headers.length)['change']}}%
                                </span>
                                <span>
                                  <i class="fa fa-caret-up color_inherit"
                                    *ngIf="compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, this.headers[h_index -
                                                              1], sub_servive)['finalTotalBill'], h_index + 1 == this.headers.length)['indicator'] == -1"
                                    aria-hidden="true"></i>
                                  <i class="fa fa-caret-down color_inherit"
                                    *ngIf="compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, this.headers[h_index -
                                                              1], sub_servive)['finalTotalBill'], h_index + 1 == this.headers.length)['indicator'] == 1"
                                    aria-hidden="true"></i>
                                </span>
                              </span>
                            </td>
                            <td class="text_align_right"
                              *ngIf="(range == 'last_3_month' || range == 'last_6_month') && customRange == null && !activeService && urlPrefix == 'admin'">
                              <div class="white_space_nowrap">
                                ${{getMonthlyValue(h, service, sub_servive).toFixed(2)}}
                                <span class="green_fancy"
                                  *ngIf="checkForVariation( getMonthlyValue(h, service, sub_servive), loadNestedValue(service, h, sub_servive) ? loadNestedValue(service, h, sub_servive)['finalTotalBill']: '0.00')['check']">
                                  <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                                </span>
                                <span class="red_fancy"
                                  [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue(h, service), loadNestedValue(service, h, sub_servive) ? loadNestedValue(service, h, sub_servive)['finalTotalBill']: '0.00')['variation']"
                                  *ngIf="!checkForVariation( getMonthlyValue(h, service, sub_servive), loadNestedValue(service, h, sub_servive) ? loadNestedValue(service, h, sub_servive)['finalTotalBill']: '0.00')['check']">
                                  <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                                </span>
                              </div>
                            </td>
                          </ng-container>

                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tbody>
                <tbody [ngStyle]="{ display: appended.length == 0 ? 'table-row-group' : 'none' }">
                  <tr>
                    <td [attr.colspan]="this.headers.length + 3 + (activeService == 'EC2' ? 1 : 0)"
                      class="text_align_center center">
                      No Bills Found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container
            *ngIf="fetchDiffDays(this.dateRange['start_date'], this.dateRange['end_date']) > (activeService ? MAX_DAYS_INNER : MAX_DAYS) && range == 'custom_range'">
            <div class="alert alert-info center width-auto">
              Variance Report more than {{activeService ? MAX_DAYS_INNER : MAX_DAYS}} days can't be analyzed. Please
              select different range
            </div>
          </ng-container>
        </div>
      </div>
      <div class="tab-pane" id="cost-anomaly" role="tabpanel" aria-labelledby="cost-anomaly-tab">
        <app-cost-anamoly-report *ngIf="currentPage == 'cost-anomaly-report'"></app-cost-anamoly-report>
      </div>
    </div>
  </div>
</div>