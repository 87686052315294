<div class="card border-none">
  <h5 class="card-header flex-between">
    <div class="flex-start">
      <a [routerLink]="'/dash/reports/home'" class="default-link">
        <button class="btn btn-light flex-start">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>Reports</span>
        </button>
      </a>
      <span>Uptime Report</span>
    </div>
    <div class="flex-start">
      <select class="form-select width-100px" name="reportType" [(ngModel)]="reportType" (change)="loadInstances()">
        <option [ngValue]="period.id" *ngFor="let period of periods">{{period['resource']}}</option>
      </select>
      <button *ngIf="!loading" class="btn btn-light flex-start" (click)="load()">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        <span class="color-inherit">
          Refresh
        </span>
      </button>
      <button class="btn btn-light flex-start" *ngIf="uptimeList.length > 0" (click)="export()">
        <i class="fa fa-download" aria-hidden="true"></i>
        <span class="color-inherit">
          Export
        </span>
      </button>
    </div>
  </h5>

  <div class="card-body">

    <table class="table table-docs table-hover" id="uptimeTable">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of uptimeHeader">{{ h.name }}</th>
        </tr>
      </thead>

      <tbody *ngIf="uptimeList.length > 0">
        <tr *ngFor="let i of uptimeList; let j = index">
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of uptimeHeader">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="i[h.id] == 'LOADING_CHECK'">
                <img src="../assets/img/loading.svg" class="img-styl" alt="" />
              </span>
              <span *ngIf="i[h.id] != 'LOADING_CHECK'">
                {{i[h.id]}}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td [attr.colSpan]="uptimeHeader.length + 1">
            <div class="center flex-center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
              <span>
                Looking for Uptime Report...
              </span>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!loading && uptimeList.length == 0">
        <tr>
          <td [attr.colSpan]="uptimeHeader.length + 1">
            <div class="center flex-center">
              No Data Found
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>