<div class="card" *ngIf="!(type?.extras?.view && type?.extras?.view == 'ssb')">
  <!-- *ngIf="!(type?.extras?.view && type?.extras?.view == 'ssb')" -->
  <div class="card-header">
    <div class="flex-between">
      <div class="flex-start" *ngIf="checksAccObj.length > 1">
        <button class="btn btn-light" (click)="hide(false)">< Back</button>
        <h5>
          {{ type.name }}
          <ng-container *ngIf="type['scan_required']">
            - {{ job.name }}
          </ng-container>
        </h5>
      </div>
      <div *ngIf="checksAccObj.length == 1">
        <h5>{{ type.name }} requirements</h5>
      </div>
      <div class="flex-start">
        <button class="btn btn-light filter_click">
          <i class="fa fa-filter" aria-hidden="true"></i>
          <div class="font-9">
            <div>
              {{ filters["questions"] }}
            </div>
          </div>
          <div class="filter_dropdown">
            <div class="filter-item" (click)="changeFilter('All', true)">
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'All' }"
              ></div>
              <div>All</div>
            </div>
            <div class="filter-item" (click)="changeFilter('Passed', true)">
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'Passed' }"
              ></div>
              <div>Passed</div>
            </div>
            <div class="filter-item" (click)="changeFilter('Failed', true)">
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'Failed' }"
              ></div>
              <div>Failed</div>
            </div>
            <div class="filter-item" (click)="changeFilter('Manual', true)">
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'Manual' }"
              ></div>
              <div>Manual</div>
            </div>
          </div>
        </button>
        <div class="quiz_actions">
          <!-- <ng-container *ngIf="!loading">
            <button class="btn btn-light" (click)="saveQuestions()">
              Save
            </button>
          </ng-container> -->
          <ng-container *ngIf="loading">
            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              class="wid-14"
            />
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="quiz-app">
      <ng-container *ngIf="checksAcc.length > 1">
        <ng-container *ngIf="showSidebar">
          <div class="pillers_container position-relative">
            <div class="padding-center">{{ type.name }} requirements</div>
            <ng-container>
              <div
                class="piller flex-between"
                (click)="changeRequirement(check)"
                [ngClass]="{
                  selected: selectedRequirement == check['check_root']
                }"
                *ngFor="let check of checksAcc"
              >
                <span>{{ check["check_root"] }}</span>
                <span class="color-radius-20"
                  >{{ check["answered"]() }}/{{ check["count"] }}</span
                >
              </div>
            </ng-container>

            <div class="hideSidebar" (click)="showSidebar = false">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!showSidebar">
          <div class="hidden_sidebar" (click)="showSidebar = true">
            <i class="fa fa-bars mar-top-10" aria-hidden="true"></i>
          </div>
        </ng-container>
      </ng-container>
      <div class="questions_container">
        <div class="question" *ngIf="this.selectedRequirement">
          <div class="question_title flex-start flex-top">
            <span>
              {{ this.selectedRequirement }}
            </span>
          </div>
          <div
            class="options_container"
            [ngStyle]="{ padding: checksAcc.length == 1 ? '0' : null }"
          >
            <ng-container *ngIf="filterQuestions().length > 0; else noOptions">
              <div
                class="option hover-option"
                [ngClass]="{ selected_option_contaniner: option['checkbox'] }"
                *ngFor="let option of filterQuestions(); let j = index"
              >
                <div>
                  <span
                    [ngClass]="{
                      green_fancy: (option.status == true && option.service != 'manual') || option.status_check,
                      red_fancy: option.status == false && option.service != 'manual',
                      yellow_fancy: option.service == 'manual' && !option.status_check,
                    }"
                  >
                    <ng-container
                      *ngIf="option.service == 'manual' && !option.status_check"
                    >
                      <i class="fa fa-exclamation-circle"></i>
                    </ng-container>
                    <ng-container
                      *ngIf="option.service != 'manual' || option.status_check"
                    >
                      <i
                        class="fa fa-check-circle"
                        *ngIf="option.status == true || option.status_check"
                      ></i>
                      <i
                        class="fa fa-times-circle"
                        *ngIf="option.status == false && !option.status_check"
                      ></i>
                    </ng-container>
                  </span>
                </div>
                <div class="main-container">
                  <div (click)="option.active = option.active ? false : true">
                    <div class="option_text">
                      {{ option["check_id"] }}: {{ option.title }}
                      <span *ngIf="option.service == 'manual'">
                        (This is a manual check)
                      </span>
                    </div>
                  </div>
                  <div
                    class="option-more-details"
                    [ngClass]="{ active: option.active }"
                  >
                    <div class="option-container">
                      <div
                        class="option_description"
                        *ngIf="option.info && option.info != ''"
                      >
                        {{ option.info }}
                      </div>
                      <div
                        class="reject_margin reject_margin_top alert alert-primary"
                        role="alert"
                        *ngIf="
                          option['recomendation'] &&
                          type['slug'] == 'eks_reliability'
                        "
                        [innerHTML]="option['recomendation']"
                      ></div>
                      <div
                        class="reject_margin reject_margin_top"
                        *ngIf="option['check']"
                      >
                        <div class="flex-start">
                          <div class="bold">Service:</div>
                          <div class="capitalize">{{ option["check"] }}</div>
                        </div>
                      </div>
                      <div
                        class="reject_margin reject_margin_top alert alert-danger"
                        role="alert"
                        *ngIf="
                          option['disruption'] && option['disruption'] == 'Yes'
                        "
                      >
                        <strong>Caution:</strong> Enabling this could lead to
                        disruption in your cloud environment
                      </div>
                      <div
                        class="reject_margin reject_margin_top"
                        *ngIf="option.reason"
                      >
                        <div class="bold">Evidence</div>
                        <div class="flex-start">
                          <span
                            [ngClass]="{
                              green_fancy: option.status == true,
                              red_fancy: option.status == false
                            }"
                          >
                            <i
                              class="fa fa-check-circle"
                              *ngIf="option.status == true"
                            ></i>
                            <i
                              class="fa fa-times-circle"
                              *ngIf="option.status == false"
                            ></i>
                          </span>
                          <span [innerHTML]="option.reason"> </span>
                        </div>
                        <!-- <div
                        class="flex-start"
                        *ngIf="
                          option['evidence'] == null || option['edit_evidence']
                        "
                      >
                        <button
                          class="btn btn-primary"
                          (click)="setEvidence($event, option, true)"
                        >
                          Accept
                        </button>
                        <button
                          class="btn btn-primary"
                          (click)="setEvidence($event, option, false)"
                        >
                          Exception
                        </button>
                      </div>
                      <div
                        class="flex-start"
                        *ngIf="
                          option['evidence'] != null && !option['edit_evidence']
                        "
                      >
                        <div class="pad-0">
                          Evidence is
                          {{ option["evidence"] ? "valid" : "invalid" }}
                          <span
                            class="nav-link"
                            (click)="resetEvidence($event, option)"
                            ><i class="fa fa-pencil"></i> Change</span
                          >
                        </div>
                      </div> -->
                      </div>
                      <div
                        class="reject_margin reject_margin_top"
                        *ngIf="
                          option['check_id'] == 'RES-005' ||
                          option['check_id'] == 'RES-004'
                        "
                      >
                        <div class="flex-start">
                          <div class="bold">
                            {{ whitelabelData["pd"]["appName"] }} Link:
                          </div>
                          <span class="nav-link" (click)="showBackup = true"
                            >Backup Report</span
                          >
                        </div>
                      </div>
                      <div
                        class="reject_margin reject_margin_top"
                        *ngIf="
                          option.evidence == false ||
                          option['check'] == 'manual'
                        "
                      >
                        <div class="bold">Decision</div>
                        <div
                          class="flex-start"
                          *ngIf="
                            option['decision'] == null ||
                            option['edit_decision']
                          "
                        >
                          <button
                            class="btn btn-primary"
                            *ngIf="option['check'] == 'manual'"
                            (click)="setDecision($event, option, 'ACCEPT')"
                          >
                            Accept
                          </button>
                          <button
                            class="btn btn-primary"
                            *ngIf="option['check'] != 'manual'"
                            (click)="setDecision($event, option, 'REMEDIATE')"
                          >
                            Remediate
                          </button>
                          <button
                            class="btn btn-primary"
                            (click)="
                              setDecision($event, option, 'NOT_APPLICABLE')
                            "
                          >
                            Not Applicable
                          </button>
                        </div>
                        <div
                          class="flex-start"
                          *ngIf="
                            option['decision'] != null &&
                            !option['edit_decision']
                          "
                        >
                          <div class="pad-0">
                            {{ DECISIONS[option["decision"]] }}
                            <span
                              class="nav-link"
                              (click)="resetDecision($event, option)"
                              ><i class="fa fa-pencil"></i> Change</span
                            >
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="option['view_details']">
                        <div
                          class="reject_margin reject_margin_top"
                          *ngIf="
                            option['offenders'] &&
                            option['offenders'].length > 0
                          "
                        >
                          <div class="bold">
                            {{ option["off_key"] || "Offenders" }}
                          </div>
                          <div class="offenders_list">
                            <ol>
                              <li
                                *ngFor="let offender of sliceOfferders(option)"
                              >
                                {{ offender }}
                              </li>
                            </ol>
                            <div *ngIf="option['show_offenders']">
                              <span
                                class="nav-link"
                                (click)="showOffenders($event, option)"
                              >
                                Show Less
                              </span>
                            </div>
                            <div
                              *ngIf="
                                !option['show_offenders'] &&
                                option['offenders'].length > 5
                              "
                            >
                              <span>
                                {{ option["offenders"].length - 5 }}+ more
                                <span
                                  class="nav-link"
                                  (click)="showOffenders($event, option)"
                                  >view all</span
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="reject_margin reject_margin_top meta_data_details"
                          *ngIf="
                            option['metadata'] && option['metadata'].length > 0
                          "
                        >
                          <div
                            class="bold"
                            *ngIf="
                              !option['offenders'] ||
                              option['offenders'].length == 0
                            "
                          >
                            {{ option["off_key"] || "Metadata" }}
                          </div>
                          <div
                            *ngIf="option['check_id'] == 'WAFR-001'"
                            class="table-container"
                          >
                            <table border="1">
                              <thead>
                                <tr>
                                  <th rowspan="2">Sl. No.</th>
                                  <th rowspan="2">Workload Name</th>
                                  <th colspan="4" class="center">Status</th>
                                  <th rowspan="2">Link</th>
                                </tr>
                                <tr>
                                  <th class="center">High</th>
                                  <th class="center">Medium</th>
                                  <th class="center">Unanswered</th>
                                  <th class="center">None</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let workload of option['metadata'];
                                    let i = index
                                  "
                                >
                                  <td>{{ i + 1 }}</td>
                                  <td>{{ workload["workload_name"] }}</td>
                                  <td class="center bold red_fancy">
                                    {{ workload["risk"]["HIGH"] || "-" }}
                                  </td>
                                  <td class="center bold yellow_fancy">
                                    {{ workload["risk"]["MEDIUM"] || "-" }}
                                  </td>
                                  <td class="center bold">
                                    {{ workload["risk"]["UNANSWERED"] || "-" }}
                                  </td>
                                  <td class="center bold green_fancy">
                                    {{ workload["risk"]["NONE"] || "-" }}
                                  </td>
                                  <td>
                                    <a
                                      routerLink="/dash/wafr"
                                      (click)="preventDefault($event)"
                                      target="_blank"
                                      [queryParams]="{
                                        workload_name: workload['workload_name']
                                      }"
                                      >Open <i class="fa fa-external-link"></i
                                    ></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            *ngIf="option['check_id'] == 'WAFR-002'"
                            class="table-container"
                          >
                            <table border="1">
                              <thead>
                                <tr>
                                  <th rowspan="2">SSB Scan Name</th>
                                  <th colspan="4" class="center">Result</th>
                                  <th rowspan="2">Link</th>
                                </tr>
                                <tr>
                                  <th class="center">Total Checks</th>
                                  <th class="center">Passed</th>
                                  <th class="center">Failed</th>
                                  <th class="center">Score (%)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ option["metadata"][0]["name"] }}</td>
                                  <td class="center">
                                    {{ option["metadata"][0]["total_checks"] }}
                                  </td>
                                  <td class="center bold green_fancy">
                                    {{ option["metadata"][0]["passed"] || "-" }}
                                  </td>
                                  <td class="center bold red_fancy">
                                    {{ option["metadata"][0]["failed"] || "-" }}
                                  </td>
                                  <td class="center bold">
                                    {{ option["metadata"][0]["perc"] || "-" }}
                                  </td>
                                  <td>
                                    <a
                                      routerLink="/dash/compliance/ssb"
                                      (click)="preventDefault($event)"
                                      target="_blank"
                                      [queryParams]="{
                                        job_id: option['metadata'][0]['job_id']
                                      }"
                                      >Open <i class="fa fa-external-link"></i
                                    ></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            *ngIf="
                              option['check_id'] == 'IAM-007' ||
                              option['check_id'] == 'IAM-008'
                            "
                            class="table-container"
                          >
                            <table border="1">
                              <thead>
                                <tr>
                                  <th>Sl. No.</th>
                                  <th>IAM Name</th>
                                  <th>Access Key</th>
                                  <th>Last Used on</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let workload of option['metadata'];
                                    let i = index
                                  "
                                >
                                  <td>{{ i + 1 }}</td>
                                  <td>{{ workload["IAMName"] }}</td>
                                  <td>{{ workload["AccessKey"] }}</td>
                                  <td>{{ workload["Lastusedon"] }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          class="reject_margin reject_margin_top meta_data_details"
                          *ngIf="
                            option['custom_metadata'] &&
                            option['custom_metadata'].length > 0
                          "
                        >
                          <div
                            *ngFor="let table of option['custom_metadata']"
                            class="mb-2"
                          >
                            <div class="bold">
                              {{ table["tableName"] || "Metadata" }}
                            </div>
                            <div class="table-container">
                              <table border="1">
                                <thead>
                                  <tr>
                                    <th>Sl. No.</th>
                                    <th *ngFor="let h of table['headers']">
                                      {{ h.name }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="
                                      let i of table['data'];
                                      let j = index
                                    "
                                  >
                                    <td>{{ j + 1 }}</td>
                                    <td *ngFor="let h of table['headers']">
                                      {{ i[h.id] }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <div class="reject_margin reject_margin_top flex-start">
                        <ng-container
                          *ngIf="
                            (option['offenders'] &&
                              option['offenders'].length > 0) ||
                            (option['metadata'] &&
                              option['metadata'].length > 0) ||
                            (option['custom_metadata'] &&
                              option['custom_metadata'].length > 0)
                          "
                        >
                          <span
                            class="nav-link"
                            *ngIf="option['view_details']"
                            (click)="updateHideDetails($event, option)"
                          >
                            Hide Details
                          </span>
                          <span
                            class="nav-link"
                            *ngIf="!option['view_details']"
                            (click)="updateHideDetails($event, option)"
                          >
                            View Details
                          </span>
                        </ng-container>
                        <div
                          *ngIf="
                            (option['offenders'] &&
                              option['offenders'].length > 0) ||
                            (option['metadata'] &&
                              option['metadata'].length > 0 &&
                              option['reference']) ||
                            (option['custom_metadata'] &&
                              option['custom_metadata'].length > 0 &&
                              option['reference'])
                          "
                        >
                          |
                        </div>
                        <a
                          href="{{ option['reference'] }}"
                          target="_blank"
                          *ngIf="option['reference']"
                        >
                          Reference
                          <i class="fa fa-external-link" aria-hidden="true"></i>
                        </a>
                      </div>
                      <!-- <div
                        class="reject_margin reject_margin_top"
                        *ngIf="option.service == 'manual'"
                      >
                        <div class="flex-start">
                          <input
                            autocomplete="off"
                            type="checkbox"
                            [(ngModel)]="option['status_check']"
                            (click)="selectOption(option, j)"
                            id="confirm-process-{{ option['check_id'] }}"
                          />
                          <label for="confirm-process-{{ option['check_id'] }}"
                            >I confirm we follow this process</label
                          >
                        </div>
                      </div> -->
                      <div class="reject_margin reject_margin_top">
                        <div class="bold">
                          Comment
                          <span
                            class="error-message"
                            *ngIf="
                              option['errors'] && option['errors']['comment']
                            "
                            ><i
                              class="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            Comment is mandatory when evidence is selected as
                            exception
                          </span>
                        </div>
                        <div>
                          <textarea
                            class="form-control"
                            [(ngModel)]="option['comment']"
                            (change)="saveQuestion(option, j)"
                            cols="30"
                            (click)="preventDefault($event)"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="option['criticality']">
                  <div class="bold">
                    <span
                      [ngClass]="{
                        green_fancy_bg: option['criticality'] == 'LOW',
                        red_fancy_bg: option['criticality'] == 'HIGH',
                        orange_fancy_bg: option['criticality'] == 'MEDIUM'
                      }"
                    >
                      {{ option["criticality"] }}
                    </span>
                  </div>
                </ng-container>
                <div class="icon-caret-container">
                  <i
                    class="fa fa-angle-down"
                    *ngIf="!option.active"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="fa fa-angle-up"
                    *ngIf="option.active"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </ng-container>
            <ng-template #noOptions>
              <h3
                class="center alert alert-warning font-size-1rem p-3"
                role="alert"
              >
                Unfortunately, no checks were found for
                <strong>{{ selectedRequirement }}</strong> with the filter
                <strong>{{ filters["questions"] }}</strong
                >. Please select a different requirement or modify the filter.
              </h3>
            </ng-template>
          </div>
        </div>
        <div class="center" *ngIf="!this.selectedRequirement">
          <div class="alert alert-warning font-size-1rem p-3" role="alert">
            Please select a requirement
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<div
  class="progress-container"
  *ngIf="type?.extras?.view && type?.extras?.view == 'ssb'"
>

<!-- <div class="flex-left">
  <div>
    <div class="progress-container" *ngIf="job.status == 'Completed'">
      <div class="progress-header">
        Test Score
        <img src="../assets/img/loading_2.svg" alt="loading scans" *ngIf="job.status != 'Completed'"
          class="wid-mar-right" />

      </div>
      <div class="progress-body">
        <div class="height-wid" id="testscore_graph"></div>
      </div>
    </div>
    <div class="progress-container" *ngIf="job.status != 'Completed'">
      <div class="progress-header">
        Scan Progress
        <img src="../assets/img/loading_2.svg" *ngIf="job.status == 'In progress'" alt="loading scans"
          class="wid-mar-right" />
      </div>
      <div class="progress-body">
        <div class="height-wid" id="testscore_graph"></div>
      </div>
    </div>
  </div>
  <div class="progress-container">
    <div class="progress-header">Check Types</div>
    <div class="progress-body">
      <div id="graphMain" class="height-wid" >
      </div>
      <div class="position-width-height" >
        <div class="round_loader loader"></div>
      </div>
    </div>
  </div>
  <div class="progress-container">
    <div class="progress-header">Failed Checks</div>
    <div class="progress-body">
      <div id="failedGraph" class="height-wid" >
      </div>
      <div class="position-width-height" >
        <div class="round_loader loader"></div>
      </div>
    </div>
  </div>
</div> -->

  <div class="progress-header prog-head">
    <div *ngIf="checksAccObj.length > 1">
      <button class="btn btn-light" (click)="hide(false)">< Back</button>
      <h5>
        {{ type.name }}
        <ng-container *ngIf="type['scan_required']">
          - {{ job.name }}
        </ng-container>
      </h5>
    </div>
    <div *ngIf="checksAccObj.length == 1">
      <h5>{{ type.name }} requirements</h5>
    </div>
    <button class="btn btn-light filter_click">
      <i class="fa fa-filter" aria-hidden="true"></i>
      <div class="font-em">
        <div>
          {{ filters["questions"] }}
        </div>
      </div>
      <div class="filter_dropdown">
        <div class="filter-item" (click)="changeFilter('All', true)">
          <div
            class="filter-checkbox"
            [ngClass]="{ checked: filters['questions'] == 'All' }"
          ></div>
          <div>All</div>
        </div>
        <div class="filter-item" (click)="changeFilter('Answered', true)">
          <div
            class="filter-checkbox"
            [ngClass]="{ checked: filters['questions'] == 'Answered' }"
          ></div>
          <div>Passed</div>
        </div>
        <div class="filter-item" (click)="changeFilter('Unanswered', true)">
          <div
            class="filter-checkbox"
            [ngClass]="{ checked: filters['questions'] == 'Unanswered' }"
          ></div>
          <div>Failed</div>
        </div>
        <div class="filter-item" (click)="changeFilter('Manual', true)">
          <div
            class="filter-checkbox"
            [ngClass]="{ checked: filters['questions'] == 'Manual' }"
          ></div>
          <div>Manual</div>
        </div>
      </div>
    </button>
  </div>
  <div class="progress-body">
    <div
      [ngClass]="{ expanded: check.expanded }"
      *ngFor="let check of filterQuestions(); let j = index"
    >
      <!-- *ngFor="let check of filterChecks()" -->
      <div
        class="check"
        id="check_{{ check['check_id'] }}"
        (click)="showCheck(check)"
      >
        <!--  -->
        <div class="check_inner" [attr.data-status]="checkauto(check.service)">
          <div
            class="status"
            [ngStyle]="{
              'background-color':
                check.status == false && check.service !== 'manual'
                  ? 'rgb(243, 115, 115)'
                  : check.status == true ||
                    (check.service !== 'manual' && check.status == false)
                  ? 'rgb(92, 156, 92)'
                  : check.service == 'manual'
                  ? 'rgb(240 175 30)'
                  : 'inherit'
            }"
          >
            <!-- {'background-color': ((check.status == false && !check.status_check || (check.service != 'Manual' && check.status == true || check.status_check && check.status == false && !check.status_check)) && 'rgb(243, 115, 115)') ||( (check.status == true || check.status_check > 0 && check.status == false && !check.status_check) && 'rgb(92, 156, 92)') || check.service == 'Manual' && 'rgb(240 175 30)'} -->
            <ng-container class="status" *ngIf="check.service != 'manual'">
              <span *ngIf="check.status == false" class="white">Failed</span>
            </ng-container>
            <ng-container class="status" *ngIf="check.service != 'manual'">
              <span *ngIf="check.status == true" class="white">Passed</span>
            </ng-container>
            <ng-container
              class="status"
              *ngIf="check.service == 'manual'"
              class="white"
            >
              Manual
            </ng-container>
          </div>
          <div class="flex-pad">
            <div class="display-flex-space-center">
              <div class="flex-1">
                <div class="font-pointer">
                  {{ check["check_id"] }}: {{ check.title }}
                </div>
              </div>
            </div>
            <div class="nav-link pad-size">
              <!-- <h5>{{ check["check_id"] }}: {{ check.title }}</h5> -->
            </div>
            <!-- <div class="tag_list">
              <div class="tag">
                <div class="tag_item">{{ check['failed'] + check['passed'] }}</div>
                <div class="tag_item">Tested</div>
              </div>
              <div class="tag passed">
                <div class="tag_item">{{ check['passed'] }}</div>
                <div class="tag_item">Passed</div>
              </div>
              <div class="tag failed">
                <div class="tag_item">{{ check['failed'] }}</div>
                <div class="tag_item">Failed</div>
              </div>
            </div> -->
          </div>
          <div class="down-arrow">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div *ngIf="check.expanded" class="mb-2 pad-25" (click)="prevent($event)">
        <!-- <div class="font-border1">
          <span>
            Findings
          </span>
          <div class="display-flex-end">
            <button class="btn btn-light filter_click">
              <i class="fa fa-filter" aria-hidden="true"></i>
              <div class="font-em">
                <div>
                  {{ filter['findings'] }}
                </div>
              </div>
              <div class="filter_dropdown">
                <div class="filter-item" (click)="filter['findings'] = 'ALL'">
                  <div class="filter-checkbox" [ngClass]="{checked: filter['findings'] == 'ALL'}">

                  </div>
                  <div>
                    All
                  </div>
                </div>
                <div class="filter-item" (click)="filter['findings'] = 'Passed'">
                  <div class="filter-checkbox" [ngClass]="{checked: filter['findings'] == 'Passed'}">

                  </div>
                  <div>
                    Passed
                  </div>
                </div>
                <div class="filter-item" (click)="filter['findings'] = 'Failed'">
                  <div class="filter-checkbox" [ngClass]="{checked: filter['findings'] == 'Failed'}">

                  </div>
                  <div>
                    Failed
                  </div>
                </div>
              </div>
            </button>
            <div class="display-content-end" *ngIf="check['pagination']">
              <div>
                Per Page:
              </div>
              <div>
                <select class="pad-background" [(ngModel)]="check['pagination']['per_page']"
                  (change)="changePerPage(check)">
                  <option [ngValue]="count" *ngFor="let count of perPages">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <ng-container *ngIf="!check['loading']">
          <div class="finding">
            <div class="mb-2">
              <div class="display-content-start">
                <div>
                  <span *ngIf="finding['resource']">
                    Resource: {{ finding['resource'] }}
                  </span> 
                  <span  *ngIf="check.info && check.info != ''">{{ check.info }}</span></div>
              </div>
            </div>
            <ng-container *ngIf="check['status'] == 'Manual' || check['status'] == false">
              <div class="mb-2">
                <div class="font-bold">Reason</div>
                <div>{{ check['reason'] }}</div>
              </div>
              <div class="mb-2 display-flex-item-gap" *ngIf="check['reference']">
                <div><a target="_blank" href="{{ check['reference'] }}"
                    class="btn btn-primary-soft display-flex-gap"><span>
                      <span>Fix </span> <i class="fa fa-external-link" aria-hidden="true"></i>
                    </span>
                  </a></div>
                <div *ngIf="finding['actions'] && getObjectKeys(finding['actions']).length > 0">
                  <div *ngFor="let key of getObjectKeys(finding['actions'])"><a target="_blank"
                      href="{{ finding['actions'][key] }}" class="btn btn-primary-soft display-flex-gap"><span>
                        <img src="../assets/img/aws_logo_1.png" alt="" class="height14">
                        <span>{{ key }}</span>
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </span>
                    </a></div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="check['status'] == true">
              Passed
            </ng-container>
          </div>
          <div class="paginator">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="onTableDataChange($event, check)">
            </pagination-controls>
          </div>
        </ng-container> -->

        <div class="option-container">
          <div class="bold">Reason</div>
          <div *ngIf="check.info && check.info != ''">
            {{ check.info }}
          </div>
          <div
            class="reject_margin reject_margin_top alert alert-primary"
            role="alert"
            *ngIf="check['recomendation'] && type['slug'] == 'eks_reliability'"
            [innerHTML]="check['recomendation']"
          ></div>
          <div class="reject_margin reject_margin_top" *ngIf="check['check']">
            <div class="flex-start">
              <div class="bold">Service:</div>
              <div class="capitalize">{{ check["check"] }}</div>
            </div>
          </div>
          <div
            class="reject_margin reject_margin_top alert alert-danger"
            role="alert"
            *ngIf="check['disruption'] && check['disruption'] == 'Yes'"
          >
            <strong>Caution:</strong> Enabling this could lead to disruption in
            your cloud environment
          </div>
          <div class="reject_margin reject_margin_top" *ngIf="check.reason">
            <div class="bold">Evidence</div>
            <div class="flex-start">
              <span
                [ngClass]="{
                  green_fancy: check.status == true,
                  red_fancy: check.status == false
                }"
              >
                <i class="fa fa-check-circle" *ngIf="check.status == true"></i>
                <i class="fa fa-times-circle" *ngIf="check.status == false"></i>
              </span>
              <span [innerHTML]="check.reason"> </span>
            </div>
            <!-- <div
            class="flex-start"
            *ngIf="
              option['evidence'] == null || option['edit_evidence']
            "
          >
            <button
              class="btn btn-primary"
              (click)="setEvidence($event, option, true)"
            >
              Accept
            </button>
            <button
              class="btn btn-primary"
              (click)="setEvidence($event, option, false)"
            >
              Exception
            </button>
          </div>
          <div
            class="flex-start"
            *ngIf="
              option['evidence'] != null && !option['edit_evidence']
            "
          >
            <div class="pad-0">
              Evidence is
              {{ option["evidence"] ? "valid" : "invalid" }}
              <span
                class="nav-link"
                (click)="resetEvidence($event, option)"
                ><i class="fa fa-pencil"></i> Change</span
              >
            </div>
          </div> -->
          </div>
          <div
            class="reject_margin reject_margin_top"
            *ngIf="
              check['check_id'] == 'RES-005' || check['check_id'] == 'RES-004'
            "
          >
            <div class="flex-start">
              <div class="bold">
                {{ whitelabelData["pd"]["appName"] }} Link:
              </div>
              <span class="nav-link" (click)="showBackup = true"
                >Backup Report</span
              >
            </div>
          </div>
          <div
            class="reject_margin reject_margin_top"
            *ngIf="check.evidence == false || check['check'] == 'manual'"
          >
            <div class="bold">Decision</div>
            <div
              class="flex-start"
              *ngIf="check['decision'] == null || check['edit_decision']"
            >
              <button
                class="btn btn-primary"
                *ngIf="check['check'] == 'manual'"
                (click)="setDecision($event, check, 'ACCEPT')"
              >
                Accept
              </button>
              <button
                class="btn btn-primary"
                *ngIf="check['check'] != 'manual'"
                (click)="setDecision($event, check, 'REMEDIATE')"
              >
                Remediate
              </button>
              <button
                class="btn btn-primary"
                (click)="setDecision($event, check, 'NOT_APPLICABLE')"
              >
                Not Applicable
              </button>
            </div>
            <div
              class="flex-start"
              *ngIf="check['decision'] != null && !check['edit_decision']"
            >
              <div class="pad-0">
                {{ DECISIONS[check["decision"]] }}
                <span class="nav-link" (click)="resetDecision($event, check)"
                  ><i class="fa fa-pencil"></i> Change</span
                >
              </div>
            </div>
          </div>
          <ng-container *ngIf="check['view_details']">
            <div
              class="reject_margin reject_margin_top"
              *ngIf="check['offenders'] && check['offenders'].length > 0"
            >
              <div class="bold">
                {{ check["off_key"] || "Offenders" }}
              </div>
              <div class="offenders_list">
                <ol>
                  <li *ngFor="let offender of sliceOfferders(check)">
                    {{ offender }}
                  </li>
                </ol>
                <div *ngIf="check['show_offenders']">
                  <span class="nav-link" (click)="showOffenders($event, check)">
                    Show Less
                  </span>
                </div>
                <div
                  *ngIf="
                    !check['show_offenders'] && check['offenders'].length > 5
                  "
                >
                  <span>
                    {{ check["offenders"].length - 5 }}+ more
                    <span
                      class="nav-link"
                      (click)="showOffenders($event, check)"
                      >view all</span
                    >
                  </span>
                </div>
              </div>
            </div>
            <div
              class="reject_margin reject_margin_top meta_data_details"
              *ngIf="check['metadata'] && check['metadata'].length > 0"
            >
              <div
                class="bold"
                *ngIf="!check['offenders'] || check['offenders'].length == 0"
              >
                {{ check["off_key"] || "Metadata" }}
              </div>
              <div
                *ngIf="check['check_id'] == 'WAFR-001'"
                class="table-container"
              >
                <table border="1">
                  <thead>
                    <tr>
                      <th rowspan="2">Sl. No.</th>
                      <th rowspan="2">Workload Name</th>
                      <th colspan="4" class="center">Status</th>
                      <th rowspan="2">Link</th>
                    </tr>
                    <tr>
                      <th class="center">High</th>
                      <th class="center">Medium</th>
                      <th class="center">Unanswered</th>
                      <th class="center">None</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let workload of check['metadata']; let i = index"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ workload["workload_name"] }}</td>
                      <td class="center bold red_fancy">
                        {{ workload["risk"]["HIGH"] || "-" }}
                      </td>
                      <td class="center bold yellow_fancy">
                        {{ workload["risk"]["MEDIUM"] || "-" }}
                      </td>
                      <td class="center bold">
                        {{ workload["risk"]["UNANSWERED"] || "-" }}
                      </td>
                      <td class="center bold green_fancy">
                        {{ workload["risk"]["NONE"] || "-" }}
                      </td>
                      <td>
                        <a
                          routerLink="/dash/wafr"
                          (click)="preventDefault($event)"
                          target="_blank"
                          [queryParams]="{
                            workload_name: workload['workload_name']
                          }"
                          >Open <i class="fa fa-external-link"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                *ngIf="check['check_id'] == 'WAFR-002'"
                class="table-container"
              >
                <table border="1">
                  <thead>
                    <tr>
                      <th rowspan="2">SSB Scan Name</th>
                      <th colspan="4" class="center">Result</th>
                      <th rowspan="2">Link</th>
                    </tr>
                    <tr>
                      <th class="center">Total Checks</th>
                      <th class="center">Passed</th>
                      <th class="center">Failed</th>
                      <th class="center">Score (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ check["metadata"][0]["name"] }}</td>
                      <td class="center">
                        {{ check["metadata"][0]["total_checks"] }}
                      </td>
                      <td class="center bold green_fancy">
                        {{ check["metadata"][0]["passed"] || "-" }}
                      </td>
                      <td class="center bold red_fancy">
                        {{ check["metadata"][0]["failed"] || "-" }}
                      </td>
                      <td class="center bold">
                        {{ check["metadata"][0]["perc"] || "-" }}
                      </td>
                      <td>
                        <a
                          routerLink="/dash/compliance/ssb"
                          (click)="preventDefault($event)"
                          target="_blank"
                          [queryParams]="{
                            job_id: check['metadata'][0]['job_id']
                          }"
                          >Open <i class="fa fa-external-link"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                *ngIf="
                  check['check_id'] == 'IAM-007' ||
                  check['check_id'] == 'IAM-008'
                "
                class="table-container"
              >
                <table border="1">
                  <thead>
                    <tr>
                      <th>Sl. No.</th>
                      <th>IAM Name</th>
                      <th>Access Key</th>
                      <th>Last Used on</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let workload of check['metadata']; let i = index"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ workload["IAMName"] }}</td>
                      <td>{{ workload["AccessKey"] }}</td>
                      <td>{{ workload["Lastusedon"] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="reject_margin reject_margin_top meta_data_details"
              *ngIf="
                check['custom_metadata'] && check['custom_metadata'].length > 0
              "
            >
              <div *ngFor="let table of check['custom_metadata']" class="mb-2">
                <div class="bold">
                  {{ table["tableName"] || "Metadata" }}
                </div>
                <div class="table-container">
                  <table border="1">
                    <thead>
                      <tr>
                        <th>Sl. No.</th>
                        <th *ngFor="let h of table['headers']">
                          {{ h.name }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let i of table['data']; let j = index">
                        <td>{{ j + 1 }}</td>
                        <td *ngFor="let h of table['headers']">
                          {{ i[h.id] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="reject_margin reject_margin_top flex-start">
            <ng-container
              *ngIf="
                (check['offenders'] && check['offenders'].length > 0) ||
                (check['metadata'] && check['metadata'].length > 0) ||
                (check['custom_metadata'] &&
                  check['custom_metadata'].length > 0)
              "
            >
              <!-- <span
                class="nav-link"
                *ngIf="check['view_details']"
                (click)="updateHideDetails($event, check)"
              >
                Hide Details
              </span> -->
              <div
                class="mb-2 display-flex-item-gap"
                *ngIf="check['reference']"
              >
                <div *ngIf="check['view_details']">
                  <a
                    (click)="updateHideDetails($event, check)"
                    class="btn btn-primary-soft display-flex-gap"
                    ><span>
                      <span>Hide Details </span>
                    </span>
                  </a>
                </div>
              </div>

              <!-- <span
                class="nav-link"
                *ngIf="!check['view_details']"
                (click)="updateHideDetails($event, check)"
              >
                View Details
              </span> -->
              <div
                class="mb-2 display-flex-item-gap"
                *ngIf="check['reference']"
              >
                <div *ngIf="!check['view_details']">
                  <a
                    (click)="updateHideDetails($event, check)"
                    class="btn btn-primary-soft display-flex-gap"
                    ><span>
                      <span>View Details </span>
                    </span>
                  </a>
                </div>
              </div>
            </ng-container>
            <div
              *ngIf="
                (check['offenders'] && check['offenders'].length > 0) ||
                (check['metadata'] &&
                  check['metadata'].length > 0 &&
                  check['reference']) ||
                (check['custom_metadata'] &&
                  check['custom_metadata'].length > 0 &&
                  check['reference'])
              "
            ></div>
            <!-- <a
              href="{{ check['reference'] }}"
              target="_blank"
              *ngIf="check['reference']"
            >
              Fix
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </a> -->
            <div class="mb-2 display-flex-item-gap" *ngIf="check['reference'] && !check['status']">
              <div>
                <a
                  target="_blank"
                  href="{{ check['reference'] }}"
                  class="btn btn-primary-soft display-flex-gap"
                  ><span>
                    <span>Fix </span>
                    <i class="fa fa-external-link" aria-hidden="true"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <!-- <div
            class="reject_margin reject_margin_top"
            *ngIf="check.service == 'manual'"
          >
            <div class="flex-start">
              <input
                autocomplete="off"
                type="checkbox"
                [(ngModel)]="check['status_check']"
                (click)="selectOption(check, j)"
                id="confirm-process-{{ check['check_id'] }}"
              />
              <label for="confirm-process-{{ check['check_id'] }}"
                >I confirm we follow this process</label
              >
            </div>
          </div> -->
          <div class="reject_margin reject_margin_top">
            <div class="bold">
              Comment
              <span
                class="error-message"
                *ngIf="check['errors'] && check['errors']['comment']"
                ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                Comment is mandatory when evidence is selected as exception
              </span>
            </div>
            <div>
              <textarea
                class="form-control"
                [(ngModel)]="check['comment']"
                (change)="saveQuestion(check, j)"
                cols="30"
                (click)="preventDefault($event)"
              ></textarea>
            </div>
          </div>
        </div>

        <ng-container *ngIf="check['loading']">
          <div class="text-cent">
            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              class="wid-mar10"
            />
          </div>
        </ng-container>
      </div>
    </div>
    <div class="text-cent" *ngIf="loading">
      <img
        src="../assets/img/loading_2.svg"
        alt="loading scans"
        class="wid-mar-right"
      />
    </div>
  </div>
</div>

<div class="floating-fixed" *ngIf="showBackup">
  <div class="floating-container">
    <div class="floating-header flex-between">
      <div>Backup Report</div>
      <div (click)="showBackup = false"><i class="fa fa-times"></i></div>
    </div>
    <div class="floating-body">
      <snapshots-backupreport></snapshots-backupreport>
    </div>
  </div>
</div>
