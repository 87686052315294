<div class="tell-me-container">
    <div class="info-header mb-3">
      Tell me is a revolutionary new feature launched by {{ whitelabelData['pd']['appName'] }}. It keeps an eye on most critical changes in your infrastructure and alerts you within 30 minutes of the pre-defined events occurrence. <span class="nav-link" (click)="review = true">Click here</span> to review the rules.
    </div>
  <div class="cft-missing-tell-me flex-center">
    <div class="divider">

    </div>
    <div class="header">
      Tell Me Features
    </div>
    <div class="divider">

    </div>
  </div>
  <div class="tell-me-features">
    <div *ngFor="let feature of features" class="feature"
      [ngStyle]="{'flex-direction': feature['textalign'] == 'left' ? 'row-reverse': 'row'}">
      <div>
        <img (click)="imageURL = feature['img']" src="{{ feature['img'] }}" alt="">
        <div class="center nav-link" (click)="imageURL = feature['img']">
          <u>
            View Image
          </u>
        </div>
      </div>
      <div>
        <div class="text-bold">{{ feature['title'] }}</div>
        <div>{{ feature['description'] }}</div>
      </div>
    </div>
  </div>
</div>

<!--Tell Me Photo Viewer-->
<tell-me-photo-viewer [url]="imageURL" *ngIf="imageURL" (hideModel)="imageURL = null"></tell-me-photo-viewer>

<!--Rules Viewer-->
<tell-me-rules-viewer [tellMes]="tellMes" [types]="types" *ngIf="review" (hideModel)="review = false"></tell-me-rules-viewer>