import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let $: any;
declare let window: any;
declare let flatpickr: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './cfrc.enable.component.html',
  styleUrls: ['./cfrc.enable.component.css'],
})
export class EnableCFRCComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') attachmentElement: ElementRef;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  customerType: string = localStorage.getItem("customerType");
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  cfrcData: any = [];
  funRef: any;
  accountId: any;
  regionId: any;
  currentMessage: any;
  editable: any = [];
  upload: boolean = false;
  showConditionalElement = false;

  editableHeader: any = [
    {
      id: 'S.N.',
      name: 'S.N.',
    },
    {
      id: 'Region',
      name: 'Region',
    },
    {
      id: 'Description',
      name: 'Description',
    },
    {
      id: 'Minfy Rate Card',
      name: 'Cost per Hour',
      editable: true,
    },
  ];

  headers: any = [
    {
      id: 'region',
      name: 'Region',
    },
    { id: 'description', name: 'Description' },
    {
      id: 'description',
      name: 'Cost per Hour',
      alter: (value: string) => {
        if (value.indexOf('_') > -1) {
          return value.substring(1, value.indexOf(')')).split('(')[1];
        } else {
          return value.substring(1, value.indexOf(')'));
        }
      },
      editable: true,
    },
    {
      id: 'minfy Rate Card',
      name: 'Minfy Rate Card',
      conditionalShow: true,
    },
  ];
  cfrc_start_date: any;
  cfrc_end_date: any;
  dateRange: any;
  masterAccounts: any = [];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private zone: NgZone,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.funRef = {};
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    let temp = new Date();
    $('#monthDate').val(`${temp.getMonth() + 1}/${temp.getFullYear()}`);
    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-M-d',
      mode: 'range',
      showMonths: 2,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  closeUpload() {
    this.upload = false;
    this.editable = [];
  }

  async saveDetails() {
    let sDate = $('#monthDate').val();
    sDate = moment(sDate, 'MM/YYYY').format('MM-YYYY');
    let dateRange: any;
    try {
      dateRange = this.dateRange.split('to');
    } catch (err) {
      this.notifier.alert(
        'Error',
        '',
        'CFRC Date Range is Required',
        'error',
        5000
      );
      return;
    }
    let valid: boolean = true;
    let dt = this.editable.map((cost: any) => {
      let dt: any = {};
      dt['description'] = cost['Description'];
      dt['totalCost'] = cost['Minfy Rate Card'];
      dt['linkedAccountId'] = this.accountId;
      dt['region'] = cost['Region'];
      if (!dt['totalCost'] || dt['totalCost'] == '') {
        valid = false;
      }
      return dt;
    });
    if (!dateRange && dateRange.length == 0) {
      this.notifier.alert(
        'Error',
        '',
        'CFRC Date Range is Required',
        'error',
        5000
      );
      return;
    }
    if (!valid) {
      this.notifier.alert(
        'Error',
        '',
        'All fields are mandatory',
        'error',
        5000
      );
      return;
    }

    let startDate = moment(dateRange[0].trim(), 'YYYY-MMM-DD').format(
      'YYYY-MM-DD'
    );
    let endDate = moment(dateRange[1].trim(), 'YYYY-MMM-DD').format(
      'YYYY-MM-DD'
    );
    this.notifier.loading(true);
    let data: any = {
      month_year: sDate,
      lid: this.accountId,
      startDate: startDate,
      endDate: endDate,
      userType: 'C',
      action: 'saveCustomCFRCDetails',
      cloudFrontDetailsList: dt,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
    
    if(this.customerType == 'mp') {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.closeUpload();
      this.router.navigateByUrl('/dash/cbs/cfrc/view');

      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async load() {
    this.notifier.loading(true);
    await this.loadMasterAccounts();
    this.notifier.loading(false);
  }

  fetchAccountID: string;
  async loadMasterAccounts() {
    if (
      this.masterAccounts.length > 0 &&
      this.fetchAccountID == this.accountId
    ) {
      await this.loadCFRCData($('#masterAccountSelect').val());
      return;
    }
    this.fetchAccountID = this.accountId;
    let data = {
      lid: [this.accountId],
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
    
    if(this.customerType == 'mp') {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      if (this.masterAccounts && this.masterAccounts.length > 0) {
        setTimeout(async () => {
          $('#masterAccountSelect').selectpicker('refresh');
          $('#masterAccountSelect').val(this.masterAccounts[0]);
          $('#masterAccountSelect').selectpicker('refresh');
          await this.loadCFRCData(this.masterAccounts[0]);
        }, 100);
      } else {
        setTimeout(async () => {
          $('#masterAccountSelect').val('');
          $('#masterAccountSelect').selectpicker('refresh');
        }, 100);
        this.masterAccounts = [];
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  async loadCFRCData(masterAccId: string) {
    let sDate = $('#monthDate').val();
    sDate = moment(sDate, 'MM/YYYY').format('MM-YYYY');
    let data = {
      month_year: sDate,
      lid: this.accountId,
      userType: 'C',
      action: 'fetchCloudFrontData',
      masterAccId: masterAccId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
    
    if(this.customerType == 'mp') {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.cloudFrontDetailsList) {
        this.cfrcData = result.cloudFrontDetailsList;
      } else {
        this.cfrcData = [];
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  triggerFIle() {
    this.attachmentElement.nativeElement.value = '';
    this.attachmentElement.nativeElement.click();
  }

  checkFile(event: any) {
    let file = event.target.files[0];
    if (
      file.name.indexOf('.xlsx') > -1 ||
      file.name.indexOf('.xls') > -1 ||
      file.name.indexOf('.csv') > -1
    ) {
      var r = new FileReader();
      r.onload = (e) => {
        var contents = window.processExcel(e.target.result);
        let data = JSON.parse(contents);
        data = data[Object.keys(data)[0]];
        let headers: any = data[0];
        let valid = true;
        headers.forEach((element: any) => {
          if (
            [
              'S.N.',
              'Region',
              'Description',
              'Cost per Hour',
              'Minfy Rate Card',
            ].indexOf(element) <= -1
          ) {
            valid = false;
          }
        });
        if (!valid) {
          this.notifier.alert('Info', '', 'Not a Valid File', 'info', 5000);
          return;
        }
        let dt: any = [];
        for (let i = 1; i < data.length; i++) {
          let obj: any = {};
          for (let j = 0; j < headers.length; j++) {
            if (
              headers[j] == 'Cost per Hour' ||
              headers[j] == 'Minfy Rate Card'
            ) {
              obj[headers[j]] =
                isNaN(Number.parseFloat(data[i][j])) || j == 0
                  ? data[i][j]
                  : Number.parseFloat(data[i][j]).toFixed(12);
            } else {
              obj[headers[j]] = data[i][j];
            }
          }
          dt.push(obj);
        }
        this.editable = dt;
        this.upload = true;
      };
      r.readAsBinaryString(file);
    } else {
      this.notifier.alert('Info', '', 'File is not supported', 'info', 5000);
    }
  }

  export() {
    this.showConditionalElement = true;

    setTimeout(() => {
      window.exportToExcel(
        'cloudFrontData',
        `CFRC_Order_Report_${this.accountId}`,
        'xlsx'
      );
      setTimeout(() => {
        this.showConditionalElement = false;
      }, 100);
    }, 100);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
