import { Component, OnDestroy, OnInit } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-vms",
  templateUrl: "./vms.component.html",
  styleUrls: ["./vms.component.css"],
})
export class VmsComponent implements OnInit, OnDestroy {
  accountId: string;
  regionId: string;
  resourceGroup: string;
  userId: string = localStorage.getItem('eId');

  vmsHeader: any = [
    {
      id: "name",
      name: "Name",
    },
    {
      id: "location",
      name: "Location",
    },
  ];

  currentMessage: any;

  loading: boolean = true;
  destroyed: boolean = false;

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  vms: any = [];

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.resourceGroup = localStorage.getItem("selectedResourceGroup");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
  }
  load() {
    this.loadVMs();
  }

  async loadVMs() {
    this.loading = true;
    this.vms = [];

    const data: any = {
      action: "list_vms",
      user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/central`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.vms = result.virtual_machines;
    }

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
    this.destroyed = true;
  }
}
