import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import * as echarts from "echarts";
import { Router } from "@angular/router";
import { DashService } from "src/app/dash/dash.service";
import { FindingsComponent } from "src/app/views/compliance/findings/findings.component";

declare let Jhxlsx: any;

class createGraph {
  private _panelImageURL = "./assets/img/custom-gauge-panel.png";
  private _animationDuration = 1000;
  private _animationDurationUpdate = 1000;
  private _animationEasingUpdate = "quarticInOut";
  private _valOnRadianMax = 200;
  private _outerRadius = 120;
  private _innerRadius = 100;
  private _pointerInnerRadius = 20;
  private _insidePanelRadius = 80;

  private renderItem(params: any, api: any, percentage: any) {
    var valOnRadian = (percentage / 100) * this._valOnRadianMax;
    var coords = api.coord([1, valOnRadian]);
    var polarEndRadian = coords[3];
    var imageStyle = {
      image: this._panelImageURL,
      x: params.coordSys.cx - this._outerRadius,
      y: params.coordSys.cy - this._outerRadius,
      width: this._outerRadius * 2,
      height: this._outerRadius * 2,
    };
    return {
      type: "group",
      children: [
        {
          type: "image",
          style: imageStyle,
          clipPath: {
            type: "sector",
            shape: {
              cx: params.coordSys.cx,
              cy: params.coordSys.cy,
              r: this._outerRadius,
              r0: this._innerRadius,
              startAngle: 0,
              endAngle: -polarEndRadian,
              transition: "endAngle",
              enterFrom: {
                endAngle: 0,
              },
            },
          },
        },
        {
          type: "image",
          style: imageStyle,
          clipPath: {
            type: "polygon",
            shape: {
              points: this.makePionterPoints(params, polarEndRadian),
            },
            extra: {
              polarEndRadian: polarEndRadian,
              transition: "polarEndRadian",
              enterFrom: {
                polarEndRadian: 0,
              },
            },
            during: (apiDuring: any) => {
              apiDuring.setShape(
                "points",
                this.makePionterPoints(
                  params,
                  apiDuring.getExtra("polarEndRadian")
                )
              );
            },
          },
        },
        {
          type: "circle",
          shape: {
            cx: params.coordSys.cx,
            cy: params.coordSys.cy,
            r: this._insidePanelRadius,
          },
          style: {
            fill: "#fff",
            shadowBlur: 25,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: "rgba(76,107,167,0.4)",
          },
        },
        {
          type: "text",
          extra: {
            valOnRadian: valOnRadian,
            transition: "valOnRadian",
            enterFrom: {
              valOnRadian: 0,
            },
          },
          style: {
            text: this.makeText(valOnRadian),
            fontSize: 30,
            fontWeight: 700,
            x: params.coordSys.cx,
            y: params.coordSys.cy,
            fill: "rgb(0,50,190)",
            align: "center",
            verticalAlign: "middle",
            enterFrom: {
              opacity: 0,
            },
          },
          during: (apiDuring: any) => {
            apiDuring.setStyle(
              "text",
              this.makeText(apiDuring.getExtra("valOnRadian"))
            );
          },
        },
      ],
    };
  }

  private convertToPolarPoint(renderItemParams: any, radius: any, radian: any) {
    return [
      Math.cos(radian) * radius + renderItemParams.coordSys.cx,
      -Math.sin(radian) * radius + renderItemParams.coordSys.cy,
    ];
  }

  private makePionterPoints(renderItemParams: any, polarEndRadian: any) {
    return [
      this.convertToPolarPoint(
        renderItemParams,
        this._outerRadius,
        polarEndRadian
      ),
      this.convertToPolarPoint(
        renderItemParams,
        this._outerRadius,
        polarEndRadian + Math.PI * 0.03
      ),
      this.convertToPolarPoint(
        renderItemParams,
        this._pointerInnerRadius,
        polarEndRadian
      ),
    ];
  }

  private makeText(valOnRadian: any) {
    // Validate additive animation calc.
    if (valOnRadian < -10) {
      alert("illegal during val: " + valOnRadian);
    }
    return ((valOnRadian / this._valOnRadianMax) * 100).toFixed(0) + "%";
  }

  showGraph(element: HTMLElement, percentage: number) {
    let myChart: any = echarts.init(element);
    myChart.resize({
      width: element.offsetWidth,
      height: element.offsetHeight,
    });
    let option = {
      animationEasing: this._animationEasingUpdate,
      animationDuration: this._animationDuration,
      animationDurationUpdate: this._animationDurationUpdate,
      animationEasingUpdate: this._animationEasingUpdate,
      dataset: {
        source: [[1, percentage]],
      },
      tooltip: {},
      angleAxis: {
        type: "value",
        startAngle: 0,
        show: false,
        min: 0,
        max: this._valOnRadianMax,
      },
      radiusAxis: {
        type: "value",
        show: false,
      },
      polar: {},
      series: [
        {
          type: "custom",
          coordinateSystem: "polar",
          renderItem: (params: any, api: any) => {
            return this.renderItem(params, api, percentage); // Pass myPercentage directly
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }
}

@Component({
  selector: "app-greengame-check",
  templateUrl: "./greengame-check-component.component.html",
  styleUrls: ["./greengame-check-component.component.css"],
})
export class GreenGameCheckComponentComponent implements OnInit, OnDestroy {
  @Input("loading") loading: any;
  @Input("job") job: any;
  @Input("dselected") dselected: any;

  @ViewChild("progressBar") progressBarRef: ElementRef;

  @Output() percentageEvent = new EventEmitter<number>();

  // @Output("load") onload: any = new EventEmitter<any>();
  @Input("accountId") accountId: any;
  @Input("regionId") regionId: any;
  @Output("hideModel") hide: any = new EventEmitter<boolean>();
  @Output("loadJobs") loadJobs: any = new EventEmitter<string>();
  perPages: any = [10, 50, 100, 200];
  checks: any = [];
  progress: number;
  progresspercentage: any;
  levels: any = [];
  ggame: boolean = false;
  rescan: boolean = false;
  currentdate = new Date();
  currentMonth: string;
  currentYear: number;
  currentMonthYear: string;
  additionadis: any = [];
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  urlPre: any = localStorage.getItem("role") == "Admin" ? "admin" : "market";
  stars: any = [1, 2, 3];
  leveldesc: any = [
    {
      level1:
        "Start your eco-cloud journey! Lay the groundwork for sustainable management. (100% of our users complete this level)",
    },
    {
      level2:
        "Fine-tune your resources for efficiency! Unlock rewards as you optimize. (70% of our users complete this level)",
    },
    {
      level3:
        "Level up your skills! Rearchitect for a greener future. (50% of our users complete this level)",
    },
  ];

  jobsHeaders: any = [
    {
      id: "job_id",
      name: "Job ID",
    },
  ];
  currentMessage: any;
  filter: any = {
    checks: "ALL",
    findings: "ALL",
  };

  checkCount = 0;
  checkInterval = 10000;
  jobCurrentStatus = ""; //Scanning, Completed

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private navigator: Router,
    private dashService: DashService
  ) {
    this.currentMonth = (this.currentdate.getMonth() + 1).toString();
    this.currentYear = this.currentdate.getFullYear();
    if (+this.currentMonth < 10) {
      this.currentMonth = "0" + this.currentMonth;
    }
    this.currentMonthYear =
      this.currentMonth.toString() + "-" + this.currentYear.toString();
  }

  comparemonth() {
    if (this.dselected == this.currentMonthYear) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.loading["checks"] = true;
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.jobCurrentStatus = this.job["status"];

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  updateProgressBar() {
    // console.log("Hey I am being called in dash");
    const progressBar = this.progressBarRef.nativeElement;
    progressBar.style.width = `${this.progresspercentage}%`;
    progressBar.innerText = `${this.progresspercentage}`;
    // console.log("I am in dash",this.progresspercentage);
  }

  sendPercentage() {
    this.percentageEvent.emit(this.progresspercentage);
  }

  destroyed: boolean = false;

  async load() {
    //this.loading["checks"] = true;
    //if (this.job.status != "Completed") {
    //  this.loadJobs.emit(this.job.job_id);
    //  this.loadChecks();
    //  if (this.timer) {
    //    return;
    //  }
    //  this.timer = setInterval(() => {
    //    if (this.job.status != "Completed" && this.destroyed == false) {
    //      this.loadJobs.emit(this.job.job_id);
    //      this.loadChecks();
    //    } else {
    //      if (this.timer) {
    //        clearInterval(this.timer);
    //        this.timer = null;
    //      }
    //    }
    //  }, 5000);
    //} else {
    //  if (this.timer) {
    //    clearInterval(this.timer);
    //    this.timer = null;
    //  }
    //  this.loadChecks();
    //}

    await this.loadChecks();
    if (this.jobCurrentStatus == "Scanning") {
      this.checkProgress();
    }
  }

  async checkProgress() {
    setTimeout(async () => {
      if (this.checkCount > 10) {
        this.loading.checks = false;
        this.notifier.alert(
          "Info",
          "",
          "Security Scan is taking longer than usual. Please click on the Refresh button after a couple of minutes.",
          "info",
          -1
        );
        return;
      }
      this.checkCount++;
      this.checkInterval = this.checkInterval + 1000;
      await this.loadChecks();
      if (this.jobCurrentStatus == "Scanning") {
        this.checkProgress();
      } else {
        this.loading.checks = false;
      }
    }, this.checkInterval);
  }

  hideModel(event: any) {
    this.navigator.navigate(["/dash/green-game"]);
    // this.loadJobs.emit();
    // this.hide.emit();
  }

  checksAcc: any = [];

  async loadChecks() {
    this.notifier.loading(true);
    // this.loading["checks"] = true;

    let data: any = {
      action: "get",
      accId: this.job["accountId"],
      scanId: this.job["scanId"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    this.loading["checks"] = false;

    this.checksAcc = [];
    if (result.status == "1" || result.s == "1") {
      this.notifier.loading(false);
      this.checks = result.scans.map((check: any) => {
        check["passed"] = Number(check["passed"]);
        check["failed"] = Number(check["failed"]);
        check["passedPercentageForFailedLevel"] = Number(
          check["passedPercentageForFailedLevel"]
        );
        this.additionadis = result.additionalDiscount;
        this.additionadis.passed = Number(this.additionadis.passed);
        this.additionadis.failed = Number(this.additionadis.failed);

        this.progresspercentage = check.passedPercentageForFailedLevel;

        // console.log("final try", this.progresspercentage);
        return check;
      });

      // this.progresspercentage=this.job["passedPercentageForFailedLevel"];

      this.jobCurrentStatus = this.job["status"];
      setTimeout(() => {
        // this.loadGraph();
        this.loadFailedGraphs();
        this.loadPercentageDiscount();
        //if (this.job.status == "Completed") {
        if (this.job["status"] == "completed") {
          this.checks.forEach((check: any) => {
            this.loadCheckDetailed(check);
          });
          this.loadTestScoreGraph();
          // this.progresspercentage=this.checks["passedPercentageForFailedLevel"];
        } else {
          this.loadTestScoreGraph("Scanning...");
        }
      }, 10);
    } else {
      this.checks = [];
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    this.notifier.loading(false);
  }

  levelsMap: { [key: string]: string } = {
    level1: "Level 1",
    level2: "Level 2",
    level3: "Level 3",
    level4: "Level 4",
    level5: "Level 5",
  };
  levmap: { [key: string]: any } = {
    level1: [1],
    level2: [1, 2],
    level3: [1, 2, 3],
  };

  getstars(check: { name?: string; result?: string }[]): number {
    return check.filter((check) => check.name).length;
  }

  getTotalPages(pagintation: any, total_count: number) {
    return new Array(Math.ceil(total_count / pagintation["per_page"]));
  }

  showCheck(check: any) {
    if (check["expanded"]) {
      check["expanded"] = false;
      return;
    } else {
      check["expanded"] = true;
      this.loadCheckDetailed(check);
    }
  }

  // export() {
  //   // exportToExcel("viewTicketTable","ticketlist", "xlsx");
  //   let dict: any = {
  //     sheetName: `SSB Report`,
  //     data: [],
  //   };

  //   dict.data.push([
  //     {
  //       text: "Name",
  //     },
  //     {
  //       text: "Creation Time",
  //     },
  //     {
  //       text: "Total Checks",
  //     },
  //     {
  //       text: "Score",
  //     },
  //     {
  //       text: "#Passed",
  //     },
  //     {
  //       text: "#Failed",
  //     },
  //     {
  //       text: "Status",
  //     },
  //     {
  //       text: "Completed Time",
  //     },
  //   ]);
  //   dict.data.push([
  //     {
  //       text: this.job.name || "",
  //     },
  //     {
  //       text: this.job.cr_time || "",
  //     },
  //     {
  //       text: this.job.total_checks || "",
  //     },
  //     {
  //       text: this.job.perc || "",
  //     },
  //     {
  //       text: this.job.passed || "",
  //     },
  //     {
  //       text: this.job.failed || "",
  //     },
  //     {
  //       text: this.job.status || "",
  //     },
  //     {
  //       text: this.job.cm_time || "",
  //     },
  //   ]);

  //   dict.data.push([{ text: "" }]);
  //   dict.data.push([{ text: "Checks" }]);

  //   dict.data.push([
  //     {
  //       text: "Sl. No.",
  //     },
  //     {
  //       text: "Name",
  //     },
  //     {
  //       text: "Type",
  //     },
  //     {
  //       text: "Total",
  //     },
  //     {
  //       text: "Passed",
  //     },
  //     {
  //       text: "Failed",
  //     },
  //   ]);
  //   this.checks.forEach((check: any, index: number) => {
  //     dict.data.push([
  //       {
  //         text: index + 1,
  //       },
  //       {
  //         text: check["name"] || 0,
  //       },
  //       {
  //         text: check["type"] || 0,
  //       },
  //       {
  //         text: (check["passed"] || 0) + (check["failed"] || 0) || "-",
  //       },
  //       {
  //         text: check["passed"] || "-",
  //       },
  //       {
  //         text: check["failed"] || "-",
  //       },
  //     ]);

  //     dict.data.push([
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "Findings",
  //       },
  //     ]);

  //     if (check.findings.length > 0) {
  //       dict.data.push([
  //         {
  //           text: "",
  //         },
  //         {
  //           text: "Sl. No.",
  //         },
  //         {
  //           text: "Check ID",
  //         },
  //         {
  //           text: "Name",
  //         },
  //         {
  //           text: "Reason",
  //         },
  //         {
  //           text: "Resource",
  //         },
  //         {
  //           text: "Status",
  //         },
  //       ]);

  //       check.findings.forEach((finding: any, check_no: number) => {
  //         dict.data.push([
  //           {
  //             text: "",
  //           },
  //           {
  //             text: check_no + 1,
  //           },
  //           {
  //             text: finding["check_id"] || "",
  //           },
  //           {
  //             text: finding["name"] || "",
  //           },
  //           {
  //             text: finding["reason"] || "",
  //           },
  //           {
  //             text: finding["resource"] || "",
  //           },
  //           {
  //             text:
  //               finding["status"].toString().toLowerCase() == "manual"
  //                 ? "Manual"
  //                 : finding["status"]
  //                 ? "Passed"
  //                 : "Failed",
  //           },
  //         ]);
  //       });
  //     }

  //     dict.data.push([
  //       {
  //         text: "",
  //       },
  //     ]);
  //   });

  //   var options = {
  //     fileName: `SSB Report - ${this.job.name} - ${
  //       localStorage.getItem("selectedaccountIds") || this.accountId
  //     }`,
  //   };

  //   Jhxlsx.export([dict], options);
  // }

  async export() {
    // check["loading"] = true;
    this.notifier.loading(true);
    let data: any = {
      action: "export",
      accId: this.job["accountId"],
      scanId: this.job["scanId"],
      name: this.job["name"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      // console.log("export success");
      // console.log(result.url);

      let url = result.url;
      let filename = `SSB Report - ${this.job.name} ${
        localStorage.getItem("selectedaccountIds") || this.accountId
      }`;

      //  let parsedUrl = new URL(url);
      //  let originalUrl = parsedUrl.searchParams.get('src');

      let link: any = document.createElement("a");
      link.href = url;
      link.download = true;
      link.click();
    } else {
      // console.log("export Fail");
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    // check["loading"] = false;
    this.notifier.loading(false);
  }

  // downloadFile(url, filename){
  //   const link = document.getElementById('download-link');
  //   link.href = url;
  //   link.download = filename;
  //   link.click();
  // }

  async loadCheckDetailed(check: any) {
    check["loading"] = true;
    let data: any = {
      action: "fetchlevel",
      accId: this.job["accountId"],
      scanId: this.job["scanId"],
      levelName: check["name"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      check["pagination"] = {
        page: 1,
        per_page: 10,
      };

      check["findings"] = result.scans;
    }
    this.progress = check["passedPercentage"];
    //   if (this.progress) {
    //     const percentage = this.progress + '%';
    //     const progressBar = document.querySelector('.progress-bar') as HTMLElement;
    //     progressBar.style.width = percentage;
    //     progressBar.setAttribute('aria-valuenow', this.progress.toString());
    // }

    check["loading"] = false;
  }

  async re_evaluate(check: any) {
    check["loading"] = true;
    let data: any = {
      action: "reevaluate",
      accId: this.job["accountId"],
      scanId: this.job["scanId"],
      regions: this.job["regions"],
      levelName: check["name"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      // this.notifier.loading(true);
      // console.log("reevaluate success");
      setTimeout(() => {
        this.loadChecks();
        this.showCheck(check);
        check["loading"] = false;
      }, 10000);
    } else {
      // console.log("reevaluate error");
      check["loading"] = false;
    }
    // check["loading"] = false;
  }
  async re_scan() {
    // this.loading=true;
    // this.loading["rescan"] = true;
    this.notifier.loading(true);
    let data: any = {
      action: "rescan",
      accId: this.job["accountId"],
      scanId: this.job["scanId"],
      regions: this.job["regions"],
      levelName: "level1",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPre}/greengame`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );
    this.notifier.loading(false);
    this.loading["rescan"] = false;

    if (result.status == "1" || result.s == "1") {
      this.loading["rescan"] = false;
      // console.log("success on rescan");

      this.notifier.alert("Info", "", "Rescan has been intiated", "info", 5000);

      this.navigator.navigate(["/dash/green-game"]);

      this.loadJobs.emit();
      this.dashService.sendNotification("green_game", { type: "show_loader" });
    } else {
      this.notifier.alert(
        "Info",
        "",
        "You can Rescan only current Month Scan",
        "info",
        5000
      );
      // console.log("error on rescan");
    }
    this.notifier.loading(false);
  }

  onTableDataChange(event: any, check: any) {
    check["pagination"]["page"] = event;
  }

  prevent(event: any) {
    event.stopPropagation();
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

  filterChecks() {
    // console.log("All",this.checks);
    if (this.filter["checks"] == "ALL") {
      return this.checks;
    }
    return this.checks.filter((check: any) => {
      if (this.filter["checks"] == "Passed") {
        if (!check["result"]) {
          // console.log("only passed",check["result"].toLowerCase() === "passed");
          return false;
        }
        let pass = check["result"].toLowerCase();
        return pass === "passed";
      } else if (this.filter["checks"] == "Failed") {
        if (!check["result"]) {
          return false;
        }
        // console.log("only failed",check["result"].toLowerCase() === "failed");

        return check["result"].toLowerCase() === "failed";
      } else if (this.filter["checks"] == "Pending") {
        // console.log("only pending",check["status"].toLowerCase() === "pending");

        return check["status"].toLowerCase() === "pending";
      } else {
        return false;
      }
    });
  }

  // applyfilter(checks: any) {
  //   if (this.filter["checks"] == "ALL") {
  //     return checks;
  //   }
  //   return checks.filter((check: any) => {
  //     if (this.filter["checks"] == "Passed") {
  //       return check["result"].toLowerCase() === "passed";
  //     } else if (this.filter["checks"] == "Failed") {
  //       return check["result"].toLowerCase() === "failed";
  //     } else {
  //       return true;
  //     }
  //   });
  // }

  // filterFindings(findings: any) {
  //   if (this.filter["findings"] == "ALL") {
  //     return findings;
  //   }
  //   return findings.filter((finding: any) => {
  //     if (this.filter["findings"] == "passed") {
  //       return !(finding["reason"] || finding["fix"]);
  //     } else if (this.filter["findings"] == "failed") {
  //       return finding["reason"] || finding["fix"];
  //     } else {
  //       return true;
  //     }
  //   });
  // }

  filterFindings(findings: any) {
    if (this.filter["findings"] == "ALL") {
      // console.log("all finsings",findings)
      return findings;
    }
    return findings.filter((finding: any) => {
      if (this.filter["findings"] == "Passed") {
        // console.log("passed findings",finding["status"].toLowerCase() === "passed");
        return finding["status"].toLowerCase() === "passed";
      } else if (this.filter["findings"] == "Failed") {
        // console.log("failed findings",finding["status"].toLowerCase() === "failed")
        return finding["status"].toLowerCase() === "failed";
      } else if (this.filter["findings"] == "Failed") {
        // console.log("failed findings",finding["status"].toLowerCase() === "failed")
        return finding["status"].toLowerCase() === "failed";
      } else {
        return true;
      }
    });
  }

  changePerPage(check: any) {
    check["pagination"]["page"] = 1;
  }

  loadFailedGraphs() {
    const chartDom = document.getElementById("failedGraph");
    if (!chartDom) return;

    const myChart = echarts.init(chartDom);

    const options = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        formatter: (params: any) => {
          let value = params[0].value;
          let formattedValue = isNaN(value) ? "-" : `${value}%`;
          return `${params[0].marker} <strong>${params[0].name}</strong> : ${formattedValue}`;
        },
      },
      xAxis: {
        type: "category",
        data: this.checks.map((check: any) => check.gameLevel),
      },
      yAxis: { type: "value" },
      series: [
        {
          data: this.checks.map((check: any) => {
            let color;
            switch (check.gameLevel) {
              case "Prepare":
                color = "#a0cbe8"; // Light blue
                break;
              case "Optimize":
                color = "#ffd98a"; // Light yellow
                break;
              case "Rearchitect":
                color = "#a0ffa0"; // Light green
                break;
              default:
                color = "#ddd"; // Default color if gameLevel doesn't match
            }

            return {
              name: check.gameLevel,
              value: Number(check.discountApplied),
              itemStyle: {
                color: color,
              },
            };
          }),
          type: "bar",
          barWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "#000",
            fontSize: 12,
            formatter: (params: any) => `${params.value}%`,
          },
        },
      ],
    };

    myChart.setOption(options);
  }

  loadPercentageDiscount() {
    var chartDom = document.getElementById("appliedDiscountGraph");
    var myChart = echarts.init(chartDom);

    let option: any = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params: any) => {
          let value = params[0].value;
          let formattedValue = isNaN(value) ? "-" : `${value}%`;
          return `${params[0].marker} <strong>${params[0].name}</strong> :
           ${formattedValue}`;
        },
      },
      grid: {
        left: "3%",
        bottom: "15%",
        top: "15%",
        containLabel: true,
      },
      yAxis: {
        type: "category",
        axisLabel: {
          margin: 10,
          align: "right",
          verticalAlign: "middle",
        },
        data: this.checks.map((check: any) => check.gameLevel),
      },
      xAxis: {
        type: "value",
      },
      series: [
        {
          data: this.checks.map((check: any) => {
            let color;
            switch (check.gameLevel) {
              case "Prepare":
                color = "#a0cbe8";
                break;
              case "Optimize":
                color = "#ffd98a";
                break;
              case "Rearchitect":
                color = "#a0ffa0";
                break;
            }

            return {
              value: Number(check.passedPercentage).toFixed(2),
              itemStyle: {
                color: color,
              },
            };
          }),
          type: "bar",
          barWidth: 40,
          label: {
            show: true,
            position: "right",
            color: "#000",
            fontSize: 12,
            formatter: (params: any) => `${params.value}%`,
          },
        },
      ],
    };

    myChart.setOption(option);
  }

  loadGraph() {
    var chartDom = document.getElementById("graphMain");
    var myChart = echarts.init(chartDom);

    myChart.on("click", (params: any) => {
      this.filter["checks"] = params["name"];
    });

    let option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          data: this.checks.map((check: any) => {
            return {
              name: check["name"],
              value: Number(check["discountProvided"]),
            };
          }),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadTestScoreGraph(title: string = "") {
    var chartDom = document.getElementById("testscore_graph");

    let currentValue: number = 0;
    this.checks.forEach((check: any) => {
      currentValue += Number(check["discountApplied"] || 0);
    });

    let graph = new createGraph();
    graph.showGraph(chartDom, currentValue);
  }

  // Delete Job
  // async deleteJob(job: any) {
  //   this.loading["delete"] = true;
  //   let data = {
  //     action: "delete_job",
  //     account_id: this.accountId,
  //     region_id: this.regionId,
  //     job_id: this.job["job_id"],
  //   };

  //   let header: any = {
  //     Authorization: localStorage.getItem("t"),
  //   };

  //   let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/security/ssb`;

  //   let result: any = await this.apiService.postDataPromis(
  //     apiURL,
  //     data,
  //     header
  //   );

  //   if (result.status == "1" || result.s == "1") {
  //     this.hideModel(true);
  //   } else {
  //     this.notifier.alert("Info", "", result["error_message"], "info", 5000);
  //   }

  //   this.loading["delete"] = false;
  // }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
  no() {
    this.rescan = false;
    // this.navigator.navigate(["/dash/green-game"]);
  }

  dispdisc(levelnum: string): void {
    // console.log("level name",levelnum);
    let desc = this.leveldesc.find((obj: any) => levelnum in obj);
    // console.log("description for the levels:", desc);
    return desc ? desc[levelnum] : "";
  }
}
