<div class="confirmContainer">
  <div class="confirmBox">
    <div class="d-flex justify-content-between">
      <span class="table-title">Processor Per Region</span>
      <button class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
    </div>

    <div class="KBBody table-responsive ">

      <!-- <ng-container *ngFor="let inst of insList;" class="scrollable-body mb-0">
        <div class="card-header acc">
          <b> {{inst.id}} </b>
        </div>

          <div class="card mt-1">
            <div class="card-header d-flex justify-content-between">
              <b>vCPU : {{inst.vcpu}} </b> 
            </div>
            <div class="card-body">
              <table class="table table-hover  m-0 p-0">
                <thead class="blue fixed-header">
                  <tr>
                    <td scope="col">S.N.</td>
                    <td scope="col">Processor Type</td>
                    <td class="text-center" scope="col">Count</td>
                  </tr>
                </thead>
                <tbody *ngIf="inst.cost.length!=0">
                  <tr *ngFor="let item of inst.cost; let j = index">
                    <td>{{j+1}}</td>
                    <td>{{item.key}}</td>
                    <td class="text-center">{{item.value}}</td>
                  </tr>
                </tbody>
                <tbody *ngIf="inst.cost.length==0">
                  <tr>
                   <td class="text-center m-2" colspan="3">No Data Found!</td>
                  </tr>
                </tbody>
                
              </table>
            </div>
          </div>
       
      </ng-container> -->


      <ng-container class="table-responsive">
        
         
         
            <table class="table table-hover  m-0 p-0">
              <thead class="blue fixed-header table-header-sticky">
                <tr>
                  <td scope="col" class="hsticky"></td>
                  <ng-container *ngFor="let header of regionHeader;">
                  <td class="text-center" scope="col">{{header}}</td>
                </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let inst of insList;" class="scrollable-body mb-0">
                <tr class="pointer" (click)="toggleItem(inst)">
                  <td  class="sticky bold"><span><i [class]="inst.isHidden ? 'fa-regular fa-square-minus' : 'fa-regular fa-square-plus'"></i></span>&nbsp;   {{inst.id}} </td>
                  
                </tr>
                <ng-container *ngIf=inst.isHidden>
                <tr *ngFor="let reg of inst.regionData;">
                  <td class="sticky mr-2">{{formatType(reg.type)}}</td>
                  <ng-container *ngFor="let val of reg.values;">
                  <td class="text-center" [ngClass]="{'bold2': val != 'NA'}">{{val ==='NA' ? '0' : val}}</td>
                </ng-container>
                </tr>
               
              </ng-container>
            </ng-container>
             
              </tbody>
            </table>
        
        
      </ng-container>

      <!-- <div class="btnHold">
                <div class="bhold">
                  <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
                  <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
                  [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
                </div>
              </div> -->


      <!-- <div class="KBActions">
              <button class="btn btn-light" (click)="no()">Cancel</button>
            </div> -->

    </div>

  </div>