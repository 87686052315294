import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';


@Component({
  selector: 'modal-popup',
  styleUrls:['./modal.css'],
  templateUrl: 'modal.html',
  //styleUrls: ['./hero-detail.component.css']
})
export class modalPopupComponent implements OnInit {
  @Input() headers?:any;
  @Input() data?:any;
  @Input() modalTitle?: any;
  @Input() maxWidth?: any;

  @Input() showModal?: any;

  @Output() hideModal = new EventEmitter<boolean>();

  close(value: boolean) {    
    this.hideModal.emit(value);
  }

  constructor(public notifier: NotifierService) { }

  ngOnInit() {
    console.log("headers", this.headers);
    console.log("data", this.data);
    console.log("modaltitle", this.modalTitle);
    console.log("showmodal", this.showModal);
    console.log("maxWidth", this.maxWidth);
  }

  callFunction(name: any, param: any) {
    return;
  }

}
