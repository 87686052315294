import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as moment from "moment";
@Component({
  selector: "app-daily-cost",
  templateUrl: "./daily-cost.component.html",
  styleUrls: ["./daily-cost.component.css"],
})
export class DailyCostComponent {
  @Input("dailyCostRes") dailyCostRes: any;
  @Input("isdailycostres") isdailycostres: any;
  @Input("dcAll") dcAll: any;
  @Output() idlist = new EventEmitter<any[]>();
  currentPage: number = 1;
  itemsPerPage: number = 10;
  idLis:any=[];
  constructor() {}

  ngOnInit(): void {}

  get totalPages(): number {
    return Math.ceil(this.dailyCostRes.length / this.itemsPerPage);
  }
  getItemsForPage(pageNumber: number): any[] {
    const startIndex = (pageNumber - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dailyCostRes.slice(startIndex, endIndex);
  }

  navigateToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  navigateToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  getPageNumbers(): number[] {
    return Array(this.totalPages)
      .fill(0)
      .map((_, i) => i + 1);
  }

  fixedDecimal(input: string): string {
    if(input!==''){
    const regex = /(\d+\.\d+)/g;
    const formattedString = input.replace(regex, (match) => {
      return parseFloat(match).toFixed(2);
    });
    return formattedString;
  }else{
    return '-';
  }
  }


  markAll() {
    const areAllSelected = this.dailyCostRes.every((item: any) => item.check);
    this.dailyCostRes.forEach((item: any) => {
      item.check = !areAllSelected;
      if (!areAllSelected && !this.idLis.includes(item.id) && item.status==='incomplete') {
        this.idLis.push(item.id);
      } else if (areAllSelected) {
        this.idLis = this.idLis.filter((id: any) => id !== item.id);
      }
    });
    this.idlist.emit(this.idLis);
  }

  updateStatus(id: any) {
    const index = this.idLis.indexOf(id);
    if (index === -1) {
      this.idLis.push(id);
    } else {
      this.idLis.splice(index, 1);
    }
    this.idlist.emit(this.idLis);
  }



  formatDate(date: any): any {
    if (date !== "") {
      return  moment.utc(date, 'YYYY-MM-DDTHH:mm:ss').local().format('MMM DD, YYYY hh:mm A');
    }
    return "";
  }
}
