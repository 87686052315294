<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Forecast for upcoming month</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
          <div class="KBBody table-responsive ">

            <!-- <table class="table table-hover  mb-0"> -->
                
              
    
                <!-- <tbody class="scrollable-body"> -->
                  <ng-container *ngFor="let ann of forcastList;" class="scrollable-body mb-0">
                   
                    <div class="card mt-1">
                      <div class="card-header">
                     <b> {{ann.id}} </b>
                    </div>
                 <div class="card-body">
                  <table class="table table-hover  m-0 p-0">
                    <thead class="blue fixed-header">
                      <tr>
                        <td scope="col">S.N.</td>
                        <td scope="col">Prediction Amount</td>
                            
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1</td>
                      <td>{{ann.cost.total_forecast_monthly_report.prediction_amount}}&nbsp;USD</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
                  </ng-container>
                  <!-- <ng-container *ngFor="let i of instancesTypes;">
                    <tr *ngFor="let value of i[key]>
                    <td>{{key}}</td>
                   
                  </tr>
                  </ng-container>            -->
                <!-- </tbody>  -->
            <!-- </table> -->
            
              <!-- <div class="btnHold">
                <div class="bhold">
                  <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
                  <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
                  [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
                </div>
              </div> -->
    
    
            <!-- <div class="KBActions">
              <button class="btn btn-light" (click)="no()">Cancel</button>
            </div> -->
          
        </div>
          
</div>


