<div class="card" *ngIf="!selectedRequirement">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button
        class="btn btn-light"
        *ngIf="type.scan_required"
        (click)="hideModel(false)"
      >
        < Back
      </button>
      <h5>
        {{ type.name }}
        <ng-container *ngIf="type['scan_required']">
          - {{ job.name }}
        </ng-container>
      </h5>
    </div>
    <div class="flex-start">
      <!-- <button class="btn btn-danger-soft float-right" (click)="deleteJob(job)">
        <img src="../assets/img/loading_2.svg" class="width-margin-right" alt="loading scans"
          *ngIf="loading['delete']" />
        Delete
      </button> -->
      <button
        class="btn btn-primary-soft float-right"
        (click)="createJob = true"
        *ngIf="type.slug == 'eks_reliability' || type.slug == 'eks_security'"
      >
        <img
          src="../assets/img/loading_2.svg"
          class="width-margin-right"
          alt="loading scans"
          *ngIf="loading['scan']"
        />
        Scan
      </button>
      <button
        class="btn btn-success-soft"
        (click)="exportFTRCompliance()"
        *ngIf="!loading['checks']"
      >
        Export
      </button>
      <button class="btn btn-primary-soft float-right" (click)="loadChecks()">
        Refresh
      </button>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="loading && loading['checks']" class="flex-center">
      <img
        src="../assets/img/loading_2.svg"
        alt="loading scans"
        class="wid-mar"
      />
    </div>
    <ng-container *ngIf="!loading['checks']">
      <!-- && !(type?.extras?.view && type?.extras?.view == 'ssb') -->
      <div
        class="review-alert mb-3"
        *ngIf="checks.length - fetchTotalAnsweredChecks().length > 0 && type.slug != 'security'"
      >
        <div>
          <i class="fa fa-info-circle" aria-hidden="true"></i>
        </div>
        <div class="alert-body">
          <div>
            <div class="alert-title">Continue Reviewing</div>
            <div>
              You have unanswered questions. Continue reviewing and answer the
              questions for each {{ type.name }} requirement.
            </div>
          </div>
          <div *ngIf="checksAcc.length > 1">
            <button class="btn btn-primary-soft continue-lenses">
              <span>Continue Reviewing</span>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
              <div class="continue-lenses-absolute">
                <div
                  *ngFor="let check of checksAcc"
                  class="button-lens"
                  (click)="selectedRequirement = check['check_root']"
                >
                  {{ check["check_root"] }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <!-- <ng-container *ngIf="checks.length - fetchTotalAnsweredChecks().length > 0 && !(type?.extras?.view && type?.extras?.view == 'ssb')"> -->

      <div
        class="card"
        [ngStyle]="{
          display:
            checks.length - fetchTotalAnsweredChecks().length > 0 &&
            !(type?.extras?.view && type?.extras?.view == 'ssb')
              ? 'block'
              : 'none'
        }"
      >
        <div class="card-header">
          <h5>{{ type.name }} Overview</h5>
        </div>
        <div class="card-body">
          <div class="grid-3">
            <div class="card progress-container">
              <div class="card-header flex-between">
                <div>
                  <div class="font-size-1_2em fw-bold">
                    {{ summary["count"]() }}
                  </div>
                  <div>
                    <label class="font-size-0_9em">Total Checks</label>
                  </div>
                </div>
                <div>
                  <div class="font-size-1_2em fw-bold">
                    {{ summary["answered"]() }}
                  </div>
                  <div>
                    <label class="font-size-0_9em">Answered</label>
                  </div>
                </div>
                <div>
                  <div class="font-size-1_2em fw-bold">
                    {{ summary["unanswered"]() }}
                  </div>
                  <div>
                    <label class="font-size-0_9em">Unanswered</label>
                  </div>
                </div>
              </div>
              <div class="card-body padding-0">
                <div class="graph-container" id="graph_score"></div>
              </div>
              <div class="center">Score (%)</div>
            </div>
            <!-- <div class="card progress-container">
              <div class="card-header flex-between">
                <h5 class="no-wrap">Overview
                </h5>
              </div>
              <div class="card-body">
                <div class="graph-container" id="graph_overview"></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- </ng-container> -->

      <ng-container
        [ngStyle]="{
          display:
            checks.length - fetchTotalAnsweredChecks().length > 0 &&
            type?.extras?.view &&
            type?.extras?.view == 'ssb'
              ? 'block'
              : 'none'
        }"
      >
        <!-- *ngIf="checks.length - fetchTotalAnsweredChecks().length > 0 && (type?.extras?.view && type?.extras?.view == 'ssb')" -->
        <!-- <div
          class="review-alert mb-3">
          <div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </div>
          <div class="alert-body">
            <div>
              <div class="alert-title">Continue Reviewing</div>
              <div>
                You have unanswered questions. Continue reviewing and answer the
                questions for each {{ type.name }} requirement.
              </div>
            </div>
            <div *ngIf="checksAcc.length > 1">
              <button class="btn btn-primary-soft continue-lenses">
                <span>Continue Reviewing</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
                <div class="continue-lenses-absolute">
                  <div
                    *ngFor="let check of checksAcc"
                    class="button-lens"
                    (click)="selectedRequirement = check['check_root']"
                  >
                    {{ check["check_root"] }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div> -->
        <div class="card" *ngIf="type?.extras?.view && type?.extras?.view == 'ssb'">
          
          <div class="flex-left">
            <div>
              <div class="progress-container" *ngIf="job.status == 'Completed'">
                <div class="progress-header">
                  Test Score
                  <img
                    src="../assets/img/loading_2.svg"
                    alt="loading scans"
                    *ngIf="jobCurrentStatus == 'In progress'"
                    class="wid-mar-right"
                  />
                </div>
                <div class="progress-body">
                  <div class="height-wid" id="testscore_graph"></div>
                </div>
              </div>
              <div class="progress-container" *ngIf="job.status != 'Completed'">
                <div class="progress-header">
                  Scan Progress
                  <img
                    src="../assets/img/loading_2.svg"
                    *ngIf="jobCurrentStatus == 'In progress'"
                    alt="loading scans"
                    class="wid-mar-right"
                  />
                </div>
                <div class="progress-body">
                  <div class="height-wid" id="testscore_graph"></div>
                </div>
              </div>
            </div>
            <div class="progress-container">
              <div class="progress-header">Check Types</div>
              <div class="progress-body">
                <div
                  id="graphMain"
                  class="height-wid"
                  *ngIf="!loading['checks']"
                ></div>
                <div class="position-width-height" *ngIf="loading['checks']">
                  <div class="round_loader loader"></div>
                </div>
              </div>
            </div>
            <!-- <div class="progress-container">
              <div class="progress-header">Failed Checks</div>
              <div class="progress-body">
                <div
                  id="failedGraph"
                  class="height-wid"
                  *ngIf="!loading['checks']"
                ></div>
                <div class="position-width-height" *ngIf="loading['checks']">
                  <div class="round_loader loader"></div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </ng-container>

      <div class="card" *ngIf="checksAcc.length > 1">
        <div class="card-header flex-between">
          <h5>{{ type.name }} requirements</h5>
        </div>
        <div class="card-body" *ngIf="!loading['questions']">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Name</th>
                <th class="center">Total Checks</th>
                <th class="center">Answered</th>
                <th class="center">Unanswered</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let check of checksAcc; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <span
                    class="nav-link"
                    (click)="selectedRequirement = check['check_root']"
                    >{{ check["check_root"] }}</span
                  >
                </td>
                <td class="center">
                  {{ check["count"] }}
                </td>
                <td class="center">
                  {{ check["answered"]() }}
                </td>
                <td class="center">
                  {{ check["unanswered"]() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="checksAcc.length == 1">
        <cost-optimization-questions-ftr
          [type]="type"
          [checks]="checks"
          [filters]="filters"
          [checksAcc]="checksAcc"
          [job]="job"
          [requirement]="checksAcc[0]['check_root']"
          (hideModal)="load()"
        ></cost-optimization-questions-ftr>
      </div>
    </ng-container>
  </div>
</div>

<cost-optimization-questions-ftr
  *ngIf="selectedRequirement"
  [type]="type"
  [checks]="checks"
  [filters]="filters"
  [checksAcc]="checksAcc"
  [job]="job"
  [requirement]="selectedRequirement"
  (hideModal)="load()"
></cost-optimization-questions-ftr>

<cost-optimization-create-job-ftr
  *ngIf="createJob"
  [type]="type"
  [accountId]="accountId"
  [regionId]="regionId"
  (hideMadal)="hideMadel($event)"
  [refresh]="loadChecks"
>
</cost-optimization-create-job-ftr>
