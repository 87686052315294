<div class="search-screen" (click)="hideModal.emit(false)" *ngIf="whitelabelData && whitelabelData['pd']">
  <div class="search-container" (click)="stopPropogation($event)">
    <div class="search-section">
      <div class="search-input-container">
        <i class="fa fa-search" aria-hidden="true"></i>
        <form (submit)="generateAPI()" class="flex-1">
          <input type="text" class="search-input" placeholder="Search in {{ whitelabelData['pd']['appName'] }}..." [(ngModel)]="searchValue"
            name="search-value" (keyup)="inputChange()">
        </form>

        <span class="nav-link pointer" (click)="clearForm($event)">Clear</span>
        <i class="fa fa-times pointer" (click)="hideModal.emit(false)" aria-hidden="true"></i>
      </div>
      <div class="search-result-container overflow-auto result-generate" *ngIf="showAskMe">
        <div class="document-container" *ngIf="!loading">
          <ng-container *ngFor="let type_element of segregateText(displayed, '<br>'); let i = index">
            <!-- {{variable_text}} -->
            <div class="display-inline" *ngIf="type_element != ''"
              [ngClass]="{'generator': i + 1 == segregateText(displayed, '<br>').length && generating}">
              <!-- {{type_element}} -->
              <span [innerHTML]="getString(type_element)">
              </span>
            </div>
            <br>
          </ng-container>
        </div>
        <div *ngIf="loading">
          <div class="loading_shadhow margin-pad-wid-70">
          </div>
          <div class="loading_shadhow margin-pad-wid-70">
          </div>
          <div class="loading_shadhow margin-pad-wid-70 width-80">
          </div>
        </div>
      </div>
      <div class='search-result-container overflow-auto' *ngIf="!showAskMe && filterPrompts().length > 0">
        <div class="text-bold pt-2 pb-1">
          Suggestions
        </div>
        <span (click)="updatePrompt($event, i, true)" class="search-result flex-start prompt-result"
          *ngFor="let i of filterPrompts()">
          <div>
            <i class="fa fa-search opacity-5" aria-hidden="true"></i>
          </div>
          <div class="flex-1">
            {{ i }}
          </div>
        </span>
      </div>
      <div class="search-result-container" *ngIf="filterResults().length > 0">
        <div class="text-bold pt-2 pb-1">
          {{ whitelabelData['pd']['appName'] }} Features
        </div>
        <a [routerLink]="i.link" [queryParams]="i.query" (click)="hideModal.emit(false)"
          class="search-result flex-start" *ngFor="let i of filterResults()">
          <div>
            <img [attr.src]="i.icon" alt="">
          </div>
          <div>
            <div class="title">{{ i.name }}</div>
            <div class="desc">{{ i.desc }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>