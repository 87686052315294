import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-low-alert',
  templateUrl: './low-alert.component.html',
  styleUrls: ['./low-alert.component.css']
})
export class LowAlertComponent {
  @Output() hideModal = new EventEmitter<any>();
  @Input("seLow") seLow: any;
  @Input("govLow") govLow: any;
  @Input("dsLow") dsLow: any;
  @Input("dcLow") dcLow: any;



  no(){
    this.hideModal.emit({ status: 0 });
    
  }
}
