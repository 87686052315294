import { Component, OnInit } from "@angular/core";

@Component({
  selector: "site24x7-more-details",
  templateUrl: "./more-details.component.html",
  styleUrls: ["./more-details.component.css"],
})
export class MoreDetailsComponent implements OnInit {
  constructor() {}

  conditions: any = [
    {
      servers: 0,
      total_hours: 0,
    },
  ];

  calculateTotal() {
    let total: number = 0;
    this.conditions.forEach((current: any) => {
      total =
        total +
        Number(current["servers"] || 0) *
          Number(current["total_hours"] || 0) *
          0.03;
    });

    return total.toFixed(3);
  }

  removeCondition(index: Number) {
    this.conditions.splice(index, 1);
  }

  addCondition() {
    this.conditions.push({
      servers: 0,
      total_hours: 0,
    });
  }

  ngOnInit(): void {}
}
