<div class="cft-banner">
  <div class="flex-between">
    <div class="flex-start gap-20">
      <div>
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      </div>
      <div>
        <div class="cft-banner-title">
          Configure EMS
        </div>
        <ng-container>
          <div class="cft-banner-description">
            EMS is not configured for this account. Please click Configure to enable the same
            <!-- <span class="cursor-pointer color-inherit" (click)="stepsConfigure = true">
                <u>
                  Know More
                </u>
              </span> -->
          </div>
        </ng-container>
      </div>
    </div>

    <div>
      <button class="btn btn-primary-soft border-right-no" (click)="hideModel.emit(true)">Configure</button>
    </div>
  </div>
</div>