import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { DashService } from "src/app/dash/dash.service";
import { environment } from "src/environments/environment";

declare let window: any;

@Component({
  selector: "app-list-monitors",
  templateUrl: "./list-monitors.component.html",
  styleUrls: ["./list-monitors.component.css"],
})
export class ListMonitorsComponent implements OnInit, OnDestroy {
  accountId: string;
  regionId: string;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  pending: any = [];
  showHistory: boolean = false;
  invite: boolean = false;
  ut: string = localStorage.getItem('ut')
  selectedPending: any = null;

  monitors: any = [];

  pendingHeader: any = [
    {
      id: "name",
      name: "Name",
    },
    {
      id: "instance_id",
      name: "Instance ID",
    },
    {
      id: "type",
      name: "Type",
    },
    {
      id: "time",
      name: "Time",
    },
  ];

  monitorsHeader: any = [
    {
      id: "display_name",
      name: "Monitor Name",
      show: () => {
        return true;
      },
    },
    {
      id: "monitor_id",
      name: "Monitor ID",
      show: () => {
        return true;
      },
    },
    {
      id: "instance_status",
      name: "Instance Status",
      show: () => {
        return this.checkForBU();
      },
    },
    {
      id: "type",
      name: "Type",
      show: () => {
        return !this.checkForBU();
      },
    },
    {
      id: "hostname",
      name: "Host Name",
      show: () => {
        return this.checkForBU();
      },
    },
    {
      id: "server_type",
      name: "Server Type",
      show: () => {
        return this.checkForBU();
      },
    },
  ];

  statusChange: any = null;
  createMonitor: boolean = false;

  currentMessage: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private dashService: DashService
  ) {}

  resourceGroup: string = localStorage.getItem("selectedResourceGroup");
  selectedCloudPartner: string = null;

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId" && this.selectedCloudPartner == 'aws') {
        this.accountId = d.value;
        this.regionId = localStorage.getItem("regionId");
        this.page = "monitors";
        this.refresh();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.page = "monitors";
        this.accountId = localStorage.getItem("accountId");
        this.refresh();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.page = "monitors";
        this.accountId = localStorage.getItem("accountId");
        this.refresh();
      }
    });
  }

  refresh() {
    this.listMonitors();
    if (this.checkForBU()) {
      this.fetchPendingInstallations();
    }
  }

  page: string = "monitors";
  hideCreateMonitor(event: any) {
    if (event) {
      this.fetchPendingInstallations();
      this.page = "pending";
    }
    this.selectedPending = null;
    this.createMonitor = false;
  }

  loading: boolean = true;

  createMonitorGroup: boolean = false;

  showCreateMonitor(monitor: any) {
    this.selectedPending = {
      tagName: monitor["name"],
      platform: monitor["platform"],
    };
    this.createMonitor = true;
  }

  async deletePending(monitor: any, show_message: boolean = true) {
    monitor.deleting = true;

    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }

    account = JSON.parse(account);

    let data: any = {
      action: "delete_from_pending_installation",
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      origin: "swayam",
      instance_id: monitor["instance_id"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.fetchPendingInstallations();
      if (show_message) {
        this.notifier.alert("Success", "", result.message, "success", 5000);
      }
    } else {
      if (show_message) {
        this.notifier.alert("Info", "", result.error_message, "info", 5000);
      }
    }
    monitor.deleting = false;
  }

  async listMonitors() {
    this.monitors = [];
    let account: any = localStorage.getItem("emsConfig");
    let accountOld: any = JSON.parse(localStorage.getItem("accountIdData"))[
      "list"
    ].find((account: any) => {
      return account["accountId"] == this.accountId;
    });
    if (!(account || accountOld["site_247_project_id"])) {
      this.loading = false;
      return;
    }

    if (account) {
      account = JSON.parse(account);
    }

    this.loading = true;
    let data: any = {
      action: "list_monitors",
      id_token: localStorage.getItem("idT"),
      zaaid: account ? account["zaid"] : accountOld["site_247_project_id"],
      login_user_name: localStorage.getItem("un"),
      origin: account ? "swayam" : "minfy",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      result.monitors = result.monitors.map((monitor: any) => {
        if (monitor["conf_instance_id"]) {
          monitor["display_name"] =
            monitor["display_name"] + ` (${monitor["conf_instance_id"]})`;
        }
        monitor["state"] = monitor["state"] == 5 ? 0 : 1;
        return monitor;
      });
      if (this.checkForBU() && result.monitors.length > 0) {
        this.getInstanceStatus(result.monitors);
      }
      this.monitors = result.monitors;
    }

    this.loading = false;
  }

  hideStateChange(event: boolean) {
    if (event) {
      this.statusChange["state"] = this.statusChange["state"] == 1 ? 0 : 1;
    }
    this.statusChange = null;
  }

  async getInstanceStatus(monitors: any) {
    let data: any = {
      action: "ec2_status",
      account_id: this.accountId,
      region_name: this.selectedCloudPartner == 'aws' ? this.regionId : 'centralindia',
      resource_ids: monitors.map((monitor: any) => {
        return monitor["conf_instance_id"];
      }),
      login_user_name: localStorage.getItem("eId"),
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.monitors.forEach((monitor: any) => {
        if (result[monitor["conf_instance_id"]]) {
          monitor["instance_status"] =
            result[monitor["conf_instance_id"]]["state"];
        }
      });
    } else {
      if(result.error_message.indexOf('InvalidInstanceID.NotFound') > -1) {
        this.monitors.forEach((monitor: any) => {
          if (result.error_message.indexOf(monitor["conf_instance_id"]) > -1) {
            monitor["instance_status"] = 'Terminated/Not Found';
          }
        });
      }
    }
  }

  checkForBU() {
    return localStorage.getItem("emsConfig") ? true : false;
  }

  deLink: boolean = false;

  async fetchPendingInstallationStatus(monitor: any) {
    let account: any = localStorage.getItem("emsConfig");

    if (account) {
      account = JSON.parse(account);
    } else {
      return;
    }
    let data: any = {
      action: "get_id_by_name",
      account_id: this.accountId,
      region_name: this.selectedCloudPartner == 'aws' ? this.regionId : 'centralindia',
      origin: "swayam",
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("eId"),
      instance_name: monitor["name"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" || result.status == "1") {
      if (result.monitor_id != null) {
        this.deletePending(monitor, false);
      }
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }
  }

  async fetchPendingInstallations() {
    let account: any = localStorage.getItem("emsConfig");

    if (account) {
      account = JSON.parse(account);
    } else {
      return;
    }
    let data: any = {
      action: "list_from_pending_installation",
      account_id: this.accountId,
      region_name: this.selectedCloudPartner == 'aws' ? this.regionId : 'centralindia',
      origin: "swayam",
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("eId"),
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" || result.status == "1") {
      result.records.forEach((record: any) => {
        this.fetchPendingInstallationStatus(record);
        record["time"] = moment(record["time"]).format("MMM DD, YYYY hh:mm A");
      });
      this.pending = result.records;
    }
  }

  deLinkCheck(event: boolean) {
    if (event) {
      this.monitors = [];
      this.dashService.sendNotification("refresh_accounts");
    }
    this.deLink = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
