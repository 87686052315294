import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
@Component({
  selector: 'app-view-retention',
  templateUrl: './view-retention.component.html',
  styleUrls: ['./view-retention.component.css']
})
export class ViewRetentionComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() averageRetention :any=[]

  constructor(public notifier: NotifierService) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }

  getRetentionTimeDuration(retention_time:any) {
    const parts = retention_time.split(' ');
   // console.log(parts);
    const days = parseInt(parts[0]) || 0;
    const hours = parseInt(parts[3]) || 0;
    const minutes = parseInt(parts[5]) || 0;
    const seconds = parseInt(parts[7]) || 0;
    //console.log(days, hours, minutes, seconds);
    return days * 86400 + hours * 3600 + minutes * 60 + seconds;
  }

  getDisplayedItems() {
     return this.averageRetention.sort((a:any, b:any) => {
      this.averageRetention.sort((a: any, b: any) => { 
        // const aDays:any = this.getRetentionTimeDuration(a.duration.retention_time);
        // const bDays:any = this.getRetentionTimeDuration(b.duration.retention_time);
         const aDays:any = a.duration.snap;
        const bDays:any = b.duration.snap;
        return aDays - bDays; });
    });
   
    
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}
