import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { APIService } from "../../../../../api/api.service";
import { NotifierService } from "../../../../../_services/notifier.service";
import { environment } from "../../../../../../environments/environment";

@Component({
  selector: "latest_mbr-report",
  templateUrl: "./mbr-report.component.html",
  styleUrls: ["./mbr-report.component.css"],
})
export class LastestMbrReportComponent implements OnInit {
  @Input("type") type: string;
  @Output("hideModel") hideModel: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  accountId: string = localStorage.getItem("accountId");
  regionId: string = localStorage.getItem("regionId");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.requestReport();
  }

  fetchSelectedClientDetails() {
    let clientList: any = localStorage.getItem("clientList");

    if (clientList && localStorage.getItem("role") == "Admin") {
      let selectedCLient: string = localStorage.getItem("selectedClientList");
      let data: any = JSON.parse(clientList);
      return (
        data.find((client: any) => {
          return client["emailId"] == selectedCLient;
        }) || null
      );
    } else {
      return null;
    }
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }

  async requestReport() {
    let selectedClient: any = this.fetchSelectedClientDetails();
    if (!selectedClient) {
      this.hideModel.emit(false);
    }
    this.notifier.loading(true);
    let data: any = {
      operation: "latest_mbr_report",
      request: {
        email: selectedClient["emailId"],
        clientName: selectedClient["cmpName"],
        customerName: localStorage.getItem("un"),
        loginEmail: localStorage.getItem("eId"),
        action: "pdf",
        region: this.regionId,
    
      },
    };

    // if (this.type == "single") {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      const account = selectedAccountData.data;
      // console.log("account details", account);
      data["request"]["jira_organization_key"] = account["jira_org_name"];
      data["request"]["jira_project_key"] = account["jira_project_key"];
      data["request"]["accountName"] = account["accountName"];
      data["request"]["organizationId"]= account["jira_org_name"];
      data["request"]["projectId"] = account["jira_project_key"];
      data["request"]["accountId"] = this.accountId;
    // }

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/apis/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status) {
      this.notifier.alert("Success", "", result.responseMsg, "success", 5000);
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    this.notifier.loading(false);

    this.hideModel.emit(true);
  }
}
