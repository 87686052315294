<div class="screen">
  <div class="popup-container">
    <div class="popup-header">
      <div class="color-inherit popup-header-bold">
        Create Monitor Group
      </div>
      <div class="popup-header-description">
        Monitor Group helps to manage all the monitors associated to a Account
      </div>
    </div>
    <div class="popup-body">
      Are you sure you want to create a monitor group for account {{ accountId }}?
    </div>
    <div class="popup-actions flex-start">
      <button class="btn btn-primary-soft" *ngIf="!loading" (click)="createMonitorGroup()">
        Create
      </button>
      <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>