<div class="card">
  <div class="card-header flex-between">
    <h5>Reserved Instances</h5>
    <div class="flex-start" *ngIf="!loading">
      <button class="btn btn-primary-soft flex-start" (click)="loadRI()">
        <i class="fa fa-refresh"></i>
        Refresh
      </button>
      <!-- <button class="btn btn-success-soft flex-start">
        <i class="fa fa-download"></i>
        Download
      </button> -->
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="discovery-section-container">
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            {{ overview['total']() }}
          </span>
          <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        </div>
        <div class="item-desc">Total RIs</div>
      </div>
      <div class="item-container">
        <div class="item-highlight" [ngClass]="{ 'light-green': overview['active']() > 0 }">
          {{ overview['active']() }}
        </div>
        <div class="item-desc">Active RIs</div>
      </div>
      <div class="item-container">
        <div class="item-highlight" [ngClass]="{ 'failed-red': overview['retired']() > 0 }">
          {{ overview['retired']() }}
        </div>
        <div class="item-desc">Inactive RIs</div>
      </div>
      <div class="item-container">
        <div class="item-highlight" [ngClass]="{ 'failed-red': overview['expires_in_30_days']() > 0 }">
          {{ overview['expires_in_30_days']() }}
        </div>
        <div class="item-desc">Expires in 30 days</div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <table class="table table-docs table-hober">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Reserved Instance ID</th>
          <th>Instance Type</th>
          <th>
            Platform
          </th>
          <th class="center">
            Start Date
          </th>
          <th class="center">
            End Date
          </th>
          <th>
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let instance of instances | paginate
                        : {
                            itemsPerPage: pagination['perPage'],
                            currentPage: pagination['page'],
                            totalItems: instances.length
                          }; let i = index">
          <td>
            {{ (pagination['perPage'] * (pagination['page'] - 1)) + i + 1 }}
          </td>
          <td>
            {{instance['ReservedInstanceId']}}
          </td>
          <td>
            {{instance['InstanceType']}}
          </td>
          <td>
            {{instance['Platform']}}
          </td>
          <td class="center">
            {{instance['Start']}}
          </td>
          <td class="center">
            {{instance['End']}}
          </td>
          <td>
            <div class="flex-start" *ngIf="instance['State_Text']">
              <span class="status-indicator"
                [ngClass]="{'green-fancy': instance['State'] == 'active', 'red-fancy': instance['State'] != 'active'}">

              </span>
              <span class="status-indicator-text"
                [ngClass]="{'red-text-fancy': instance['State'] != 'active', 'green-text-fancy': instance['State'] == 'active'}">
                {{ instance['State_Text'] }}
              </span>
            </div>
            <div *ngIf="!instance['State_Text']">
              -
            </div>
          </td>
        </tr>
        <tr *ngIf="loading">
          <td colspan="7" class="center">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
          </td>
        </tr>
        <tr *ngIf="!loading && instances.length == 0">
          <td colspan="7" class="center">
            <span>No Reserved Instances Found</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paginator">
      <div class="flex-start">
        <span class="no-wrap">Per Page</span>
        <select class="form-select" [(ngModel)]="pagination['perPage']" (change)="pagination['page'] = 1">
          <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
        </select>
      </div>
      <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
        (pageChange)="pagination['page'] = $event">
      </pagination-controls>
    </div>
  </div>
</div>