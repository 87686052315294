<div [ngStyle]="{ display: !selectedCertificate ? 'block' : 'none' }">
  <div class="card">
    <div class="card-header flex-between">
      <h5>ACM</h5>
      <div>
        <button class="btn btn-primary-soft" *ngIf="!loading" (click)="load()">
          Refresh
        </button>
        <button class="btn btn-success-soft" *ngIf="!loading" (click)="requestCertificateAction()">
          Request Certificate
        </button>
      </div>
    </div>
  </div>
  <div class="discovery-section section">
    <div class="card">
      <div class="card-body p-2">
        <div class="discovery-section-container">
          <div class="item-container">
            <div class="item-highlight flex-start">
              <span>
                {{ overview['total'] }}
              </span>
              <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
            </div>
            <div class="item-desc">Total Certificates</div>
          </div>
          <div class="item-container">
            <div class="item-highlight" [ngClass]="{ 'light-green': overview['active'] > 0 }">
              {{ overview['active'] }}
            </div>
            <div class="item-desc">Active Certificates</div>
          </div>
          <div class="item-container">
            <div class="item-highlight" [ngClass]="{ 'failed-red': overview['failed'] > 0 }">
              {{ overview['failed'] }}
            </div>
            <div class="item-desc">Failed Certificates</div>
          </div>
          <div class="item-container">
            <div class="item-highlight" [ngClass]="{ 'failed-red': overview['renew_week'] > 0 }">
              {{ overview['renew_week'] }}
            </div>
            <div class="item-desc">Need to renew in this week</div>
          </div>
          <div class="item-container pointer" (click)="addFilter('expired', true, 'Expired')">
            <div class="item-highlight light-red">
              {{ overview['expired'] }}
            </div>
            <div class="item-desc">Expired</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="upcoming-expiry">
      <div class="grid-2">
        <div class="card flex-1">
          <div class="card-body">
            <div class="item-container flex-1 flex-column">
              <div class="section-title">
                <div>Upcoming Expirations</div>
                <div *ngIf="loading">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                </div>
              </div>
              <div class="flex-center">
                <div class="expiry-graph">
                  <div class="expiry-graph-item">
                    <div class="round-text red-back pointer" (click)="addFilter('expired', true, 'Expired')" [ngClass]="{
                        'big-round': overview['expired'] > 10
                      }">
                      {{ overview['expired'] }}
                    </div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">Expired</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text card-blue-back pointer"
                      (click)="addFilter('renew_7d', true, 'Renew in 7 days')" [ngClass]="{
                        'big-round': overview['renew_7d'] > 10
                      }">
                      {{ overview['renew_7d'] }}
                    </div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">7d</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text blue-back pointer" (click)="addFilter('renew_30d', true, 'Renew in 30 days')"
                      [ngClass]="{
                        'big-round': overview['renew_30d'] > 10
                      }">
                      {{ overview['renew_30d'] }}
                    </div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">30d</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text blue-back pointer" (click)="addFilter('renew_90d', true, 'Renew in 90 days')"
                      [ngClass]="{
                        'big-round': overview['renew_90d'] > 10
                      }">
                      {{ overview['renew_90d'] }}
                    </div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">90d</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-2 flex-1 graphs">
          <div class="item-container">
            <div class="card">
              <div class="card-body">
                <div class="section-title">
                  <div>Algorithms</div>
                  <div *ngIf="loading">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                </div>
                <div id="algorithms_graph" class="graph"></div>
              </div>
            </div>
          </div>
          <div class="item-container">
            <div class="card">
              <div class="card-body">
                <div class="section-title">
                  <div>Types</div>
                  <div *ngIf="loading">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                </div>
                <div id="types_graph" class="graph"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="card">
      <div class="card-body table-acm-container">
        <div class="flex-between input-container">
          <div>
            <div *ngIf="filterApplied['status']">
              Filters Applied:
              <span class="bold-text">{{ filterApplied['text'] }}
                <ng-container *ngIf="filterApplied['value'] != true">
                  ({{ filterApplied['value'] }})
                </ng-container>
                <span class="nav-link pointer" (click)="removeFilter()">
                  Remove Filter
                </span>
              </span>
            </div>
          </div>
          <input autocomplete="off" type="text" placeholder="Search..." class="form-control" [(ngModel)]="filterText" />
        </div>
        <table class="table table-docs">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Domain Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Issued At</th>
              <th>Expires On</th>
              <th>Alternate Names</th>
              <th>Clone</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let acm of filterACMs()
                  | paginate
                    : {
                        itemsPerPage: perPage,
                        currentPage: page,
                        totalItems: filterACMs().length
                      };
                let i = index
              ">
              <td>{{ (page - 1) * perPage + i + 1 }}</td>
              <td>
                <span class="nav-link" (click)="describeCertificate(acm)">
                  {{ acm['DomainName'] }}
                </span>
              </td>
              <td>{{ acm['Type'] }}</td>
              <td>
                <div class="flex-start red_fancy" *ngIf="acm['Status'] == 'EXPIRED'">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <div>
                    {{ acm['Status'] }}
                  </div>
                </div>
                <div class="flex-start red_fancy" *ngIf="acm['Status'] == 'FAILED'">
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                  <div>
                    {{ acm['Status'] }}
                  </div>
                </div>
                <div class="flex-start green_fancy" *ngIf="acm['Status'] == 'ISSUED'">
                  <i class="fa fa-check-circle" aria-hidden="true"></i>
                  <div>
                    {{ acm['Status'] }}
                  </div>
                </div>
                <div class="flex-start" *ngIf="acm['Status'] == 'PENDING_VALIDATION'">
                  <i class="fa-regular fa-clock"></i>
                  <div>
                    {{ acm['Status'] }}
                  </div>
                </div>
              </td>
              <td>{{ acm['IssuedAtText'] }}</td>
              <td>{{ acm['NotAfterText'] }}</td>
              <td>
                <button *ngIf="acm['SubjectAlternativeNameSummaries'].length > 0" class="btn btn-light" (click)="
                    offendeList = acm['SubjectAlternativeNameSummariesUpdated']
                  ">
                  {{ acm['SubjectAlternativeNameSummaries'].length }}
                </button>
                <span *ngIf="acm['SubjectAlternativeNameSummaries'].length <= 0" class="center">-</span>
              </td>
              <td>
                <button class="btn btn-success-soft" (click)="requestCertificateAction(acm)">
                  Clone
                </button>
              </td>
            </tr>
            <tr *ngIf="loading">
              <td colspan="8" class="center">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
              </td>
            </tr>
            <tr *ngIf="!loading && acms.length == 0">
              <td colspan="8" class="center">
                <span>No Certificates found</span>
              </td>
            </tr>
            <tr *ngIf="
                !loading &&
                (filterApplied['status'] || filterText != '') &&
                filterACMs().length == 0
              ">
              <td colspan="8" class="center">
                <span>No Certificates found.
                  <span class="nav-link pointer" (click)="removeFilter()">Remove filters</span>
                  to view all certificates</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="paginator">
          <div class="flex-start">
            <span class="no-wrap">Per Page</span>
            <select class="form-select" [(ngModel)]="perPage" (change)="page = 1">
              <option value="{{ page }}" *ngFor="let page of pages">
                {{ page }}
              </option>
            </select>
          </div>
          <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="page = $event">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>

</div>
<div *ngIf="selectedCertificate" class="certificate-main">
  <div class="card">
    <div class="card-header flex-between">
      <div>
        <button class="btn btn-light" (click)="goBack()">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          Certificates
        </button>
        <span>{{ selectedCertificate['id'] }}</span>
      </div>
      <div>
        <button *ngIf="!loading" class="btn btn-primary-soft" (click)="describeCertificate(selectedCertificate)">
          Refresh
        </button>
      </div>
    </div>
    <div class="card-body overflow-auto">
      <div class="cert">
        <div class="cert-header">
          <div class="cert-header-bold">
            Certificate Status
          </div>
        </div>
        <div class="cert-body">
          <div class="grid-2-main">
            <div class="validation-method">
              <div class="text-bold font-size-1">Identifier</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['id']}}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Status</div>
              <div class="small-text font-size-1">
                <div class="flex-start red_fancy" *ngIf="selectedCertificate['Status'] == 'EXPIRED'">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <div>
                    {{ selectedCertificate['Status'] }}
                  </div>
                </div>
                <div class="flex-start red_fancy" *ngIf="selectedCertificate['Status'] == 'FAILED'">
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                  <div>
                    {{ selectedCertificate['Status'] }}
                  </div>
                </div>
                <div class="flex-start green_fancy" *ngIf="selectedCertificate['Status'] == 'ISSUED'">
                  <i class="fa fa-check-circle" aria-hidden="true"></i>
                  <div>
                    {{ selectedCertificate['Status'] }}
                  </div>
                </div>
                <div class="flex-start" *ngIf="selectedCertificate['Status'] == 'PENDING_VALIDATION'">
                  <i class="fa-regular fa-clock"></i>
                  <div>
                    {{ selectedCertificate['Status'] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">ARN</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['CertificateArn']}}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Type</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['Type']}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cert">
        <div class="cert-header flex-between">
          <div class="cert-header-bold">
            Domains ({{selectedCertificate['SubjectAlternativeNameSummaries'].length}})
          </div>
          <div *ngIf="selectedCertificate['SubjectAlternativeNameSummaries'].length > 0 && !loading">
            <button class="btn btn-success-soft" (click)="exportDomains()">Export</button>
          </div>
        </div>
        <div class="cert-body">
          <table class="table table-docs" id="domains-id"
            *ngIf="selectedCertificate['details'] && selectedCertificate['details']['DomainValidationOptions']">
            <thead>
              <tr>
                <th>Domain</th>
                <th>Status</th>
                <th>Type</th>
                <th>CNAME name</th>
                <th>CNAME value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let domain of selectedCertificate['details']['DomainValidationOptions']">
                <td>{{ domain['DomainName'] }}</td>
                <td>
                  <div class="flex-start red_fancy" *ngIf="domain['ValidationStatus'] == 'FAILED'">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                    <div>
                      {{ domain['ValidationStatus'] }}
                    </div>
                  </div>
                  <div class="flex-start green_fancy" *ngIf="domain['ValidationStatus'] == 'SUCCESS'">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <div>
                      {{ domain['ValidationStatus'] }}
                    </div>
                  </div>
                  <div class="flex-start" *ngIf="domain['ValidationStatus'] == 'PENDING_VALIDATION'">
                    <i class="fa-regular fa-clock"></i>
                    <div>
                      {{ domain['ValidationStatus'] }}
                    </div>
                  </div>
                </td>
                <td>{{ (selectedCertificate['details'] && selectedCertificate['details']['Type']) ||
                  selectedCertificate['details']['Type'] }}</td>
                <td>{{ (domain['ResourceRecord'] && domain['ResourceRecord']['Name']) || '-' }}</td>
                <td>{{ (domain['ResourceRecord'] && domain['ResourceRecord']['Value']) || '-'}}</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="loading" class="center">
            <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
          </div>
        </div>
      </div>
      <div class="cert">
        <div class="cert-header">
          <div class="cert-header-bold">
            Details
          </div>
        </div>
        <div class="cert-body">
          <div class="grid-3" *ngIf="selectedCertificate['details']">
            <div class="validation-method">
              <div class="text-bold font-size-1">Domain Name</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['details']['DomainName']}}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Requested At</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['details']['CreatedAt']}}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Renewal Eligibility</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['details']['RenewalEligibility']}}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Public key info</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['details']['KeyAlgorithm']}}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Number of additional names</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['details']['DomainValidationOptions'].length}}
              </div>
            </div>
            <div class="validation-method">
              <div class="text-bold font-size-1">Signature algorithm</div>
              <div class="small-text font-size-1">
                {{selectedCertificate['details']['SignatureAlgorithm']}}
              </div>
            </div>
          </div>
          <div *ngIf="loading" class="center">
            <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<modal-popup [headers]="alternateNamesHeader" [data]="offendeList" *ngIf="offendeList" [modalTitle]="'Alternate Names'"
  [showModal]="true" (hideModal)="offendeList = null"></modal-popup>
<app-request-certificate *ngIf="requestCertificate" [requestCertificate]="requestCertificate"
  (hideModel)="hideModel($event)"></app-request-certificate>
<div class="move-top" [ngStyle]="{ bottom: pageOffset > 600 ? '50px' : '-100px' }" (click)="moveToTop()">
  <i class="fa fa-angle-double-up" aria-hidden="true"></i>
</div>