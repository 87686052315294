import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.css", "./../../../warquiz.component.css"],
})
export class CreateComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() accountId: string;
  @Input() regionId: string;
  @Input() workload: any;
  @Input("milestone_names") milestones: string[];

  uid = localStorage.getItem("t");
  t = localStorage.getItem("t");
  ut = localStorage.getItem("ut");
  eId = localStorage.getItem("eId");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  errors: any = {
    milestone: {
      length: 100,
      error: false,
      message: "",
    },
  };

  scan_name: string = "";
  loading: boolean = false;

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  validate() {
    let check = true;
    this.errors["milestone"]["error"] = false;
    if (
      !this.scan_name ||
      this.scan_name.trim().length == 0 ||
      this.scan_name.trim() == ""
    ) {
      this.errors["milestone"]["error"] = true;
      this.errors["milestone"]["message"] =
        "Milestone Name is a mandatory field";
      check = false;
    }
    if (
      (this.scan_name.trim().length < 3 ||
        this.scan_name.trim().length > 100) &&
      this.errors["milestone"]["error"] == false
    ) {
      this.errors["milestone"]["error"] = true;
      this.errors["milestone"]["message"] =
        "Milestone Name should be atleast 3 characters long and atmost 100 characters long";
      check = false;
    }
    if (
      this.checkForMilestone() &&
      this.errors["milestone"]["error"] == false
    ) {
      this.errors["milestone"]["error"] = true;
      this.errors["milestone"][
        "message"
      ] = `Milestone with name ${this.scan_name.trim()} already exists`;
      check = false;
    }
    return check;
  }

  checkForMilestone() {
    return this.milestones.indexOf(this.scan_name.trim()) > -1;
  }

  async createJob() {
    if (!this.validate()) {
      return;
    }
    this.loading = true;
    let data: any = {
      action: "create_milestone",
      workload_id: this.workload["id"],
      account_id: this.accountId,
      region_id: this.regionId,
      aws_workload_id: this.workload["aws_id"],
      swayam_workload_id: this.workload["id"],
      milestone_name: this.scan_name.trim(),
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("success", "", result.message, "success", 5000);
      this.no(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }
    this.loading = false;
  }
}
