<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" (click)="hideModel(false)">
        < Back</button>
          <h5>EcoCloud Game - {{ job.name }}</h5>
    </div>

    <div class="flex-start">
      <button class="btn btn-success-soft float-right" (click)="export()">
        Export
      </button>
      <!-- <button class="btn btn-danger-soft float-right" (click)="deleteJob(job)">
        <img src="../assets/img/loading_2.svg" class="width-margin-right" alt="loading scans"
          *ngIf="loading['delete']" />
        Delete
      </button> -->
      <button [disabled]="!comparemonth()" class="btn btn-primary-soft float-right" (click)="rescan = true">
        Rescan
      </button>
      <button class="btn btn-primary-soft float-right" (click)="loadChecks()">
        Refresh
      </button>
    </div>
  </div>
</div>
<div class="card-body">
  <!-- <div class="flex-left"> -->
    <!-- <div>
      <div class="progress-container" *ngIf="job.status == 'completed'">
        <div class="progress-header">Total Discount Applied</div>
        <div class="progress-body">
          <div class="height-wid" id="testscore_graph"></div>
        </div>
      </div>
      <div class="progress-container" *ngIf="job.status != 'completed'">
        <div class="progress-header">
          Scan Progress
          <img src="../assets/img/loading_2.svg" *ngIf="job.status == 'Scanning'" alt="loading scans"
            class="wid-mar-right" />
        </div>
        <div class="progress-body">
          <div class="height-wid" id="testscore_graph"></div>
        </div>
      </div>
    </div>
    <div class="progress-container">
      <div class="progress-header">Applied Discount Weightable</div>
      <div class="progress-body">
        <div id="failedGraph" class="height-wid" *ngIf="!loading['checks']"></div>
        <div class="position-width-height" *ngIf="loading['checks']">
          <div class="round_loader loader"></div>
        </div>
      </div>
    </div> -->
    <div class="progress-container">
      <div class="progress-header">Level Completion(%)</div>
      <div class="progress-body">
        <div id="appliedDiscountGraph" class="height-wid" *ngIf="!loading['checks']"></div>
        <div class="position-width-height" *ngIf="loading['checks']">
          <div class="round_loader loader"></div>
        </div>
      </div>
    </div>
  <!-- </div> -->
  <div class="progress-container">
    <div class="progress-header">
      <div>Levels</div>
      <button class="btn btn-light filter_click">
        <i class="fa fa-filter" aria-hidden="true"></i>
        <div class="font-em">
          <div>
            {{ filter["checks"] }}
          </div>
        </div>
        <div class="filter_dropdown">
          <div class="filter-item" (click)="filter['checks'] = 'ALL'">
            <div class="filter-checkbox" [ngClass]="{ checked: filter['checks'] == 'ALL' }"></div>
            <div>All</div>
          </div>
          <div class="filter-item" (click)="filter['checks'] = 'Passed'">
            <div class="filter-checkbox" [ngClass]="{ checked: filter['checks'] == 'Passed' }"></div>
            <div>Passed</div>
          </div>
          <div class="filter-item" (click)="filter['checks'] = 'Failed'">
            <div class="filter-checkbox" [ngClass]="{ checked: filter['checks'] == 'Failed' }"></div>
            <div>Failed</div>
          </div>
          <div class="filter-item" (click)="filter['checks'] = 'Pending'">
            <div class="filter-checkbox" [ngClass]="{ checked: filter['checks'] == 'Pending' }"></div>
            <div>Pending</div>
          </div>
        </div>
      </button>
    </div>
    <div class="progress-body">
      <div [ngClass]="{ expanded: check.expanded && check.status !='pending' }" *ngFor="let check of filterChecks()">
        <div class="check" (click)="showCheck(check)" id="check_{{ check['check_id'] }}">
          <div class="check_inner" [attr.data-status]="'scan '+ check.status">
            <!-- <div class="status"
              [ngStyle]="{'background-color': ((check.failed > 0 || (check.type != 'Manual' && check.failed == 0 && check.passed == 0)) && 'rgb(243, 115, 115)') ||( (check.passed > 0 && check.failed == 0) && 'rgb(92, 156, 92)') || check.type == 'Manual' && 'rgb(240 175 30)'}">
              <ng-container class="status"
                *ngIf="check.failed > 0 || (check.type != 'Manual' && check.failed == 0 && check.passed == 0)">
                Failed
              </ng-container>
              <ng-container class="status" *ngIf="check.passed > 0 && check.failed == 0">
                Passed
              </ng-container>
              <ng-container class="status" *ngIf="check.type == 'Manual'">
                Manual
              </ng-container>
            </div> -->
            <!-- (check.result == 'failed' && 'rgb(243, 115, 115)') || -->

            <div class="status" [ngStyle]="{
                'background-color':
                  (check.status == 'pending' && 'rgb(234, 228, 228)') ||
                  (check.result == 'passed' && 'rgb(92, 156, 92)') ||
                  (check.result == 'failed' && 'rgb(255 181 0 / 52%)') ||
                  (check.type == 'Manual' && 'rgb(240 175 30)'),
                  'color':(check.status == 'pending' && 'black')
              }">
              <ng-container class="status" *ngIf="check.result == 'failed'">
                In-Progress
              </ng-container>
              <ng-container class="status" *ngIf="check.result == 'passed'">
                Passed
              </ng-container>
              <ng-container class="status" *ngIf="check.status == 'pending'">
              Pending
              </ng-container>
              <ng-container class="status" *ngIf="check.type == 'Manual'">
                Manual
              </ng-container>
            </div>

            <!-- <div class="status"
            [ngStyle]="{'background-color': ((check.result == 'failed') && 'rgb(243, 115, 115)') ||( (check.result == 'passed') && 'rgb(92, 156, 92)')}">
            <ng-container class="status"
            *ngIf="check.result=='failed'">
            Failed
          </ng-container>
          <ng-container class="status"
            *ngIf="check.result=='passed'">
            Passed
          </ng-container>
          <ng-container class="status"
            *ngIf="check.result==''">
            Pending
          </ng-container> -->

            <div class="flex-pad">
              <div class="display-flex-space-center mr_r">
                <!-- <div class="flex-1"> -->
                <div class="font-pointer">{{levelsMap[check.name]}}: {{ check.gameLevel }}</div>
                <div class="flex-end mr_12">
                  <span class="star_g" *ngFor="let star of levmap[check.name]">
                    <i class="fa-solid fa-star text-end" style="color:gold"></i>
                  </span>
                </div>
                
              </div>
              <div class="leveldesc">
                <span>{{dispdisc(check.name)}}</span>
              </div>
              <div *ngIf="check.status == 'pending'" class="flex-between">
                <div class="tag_list leveldesc">
                  <span *ngIf="levelsMap[check.name]== 'Level 2'">Complete Level 1 to Unlock {{levelsMap[check.name]}}.</span>
                  <span *ngIf="levelsMap[check.name]== 'Level 3'">Complete Level 2 to Unlock {{levelsMap[check.name]}}.</span> 
                  </div>
                  </div>
              <!-- <div class="progress-bar-container" style="margin: 18px 18px 18px 0px" *ngIf="check.result == 'failed'">
                <div class="progress-bar" style="color: white;" [style.width]="check.passedPercentageForFailedLevel + '%'">{{check.passedPercentageForFailedLevel}} %</div>
              </div> -->
              <div *ngIf="check.result == 'passed' || check.result == 'failed'" class="flex-between">
                <div class="tag_list">
                  <div class="tag">
                    <div class="tag_item">
                      {{ check["failed"] + check["passed"] }}
                    </div>
                    <div class="tag_item">Tested</div>
                  </div>
                  <div class="tag passed">
                    <div class="tag_item">{{ check["passed"] }}</div>
                    <div class="tag_item">Passed</div>
                  </div>
                  <div class="tag failed">
                    <div class="tag_item">{{ check["failed"] }}</div>
                    <div class="tag_item">Failed</div>
                  </div>
                </div>
                <!-- <div class="tag_list">
                  <div class="tag passed">
                    <div class="tag_item">
                      {{ check.discountProvided | number : "1.0-0" }}%
                    </div>
                    <div class="tag_item">Total Discount</div>
                  </div>
                  <div class="tag passed">
                    <div class="tag_item">
                      {{ check.discountApplied | number : "1.0-0" }}%
                    </div>
                    <div class="tag_item">Discount Applied</div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="down-arrow">
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <ng-container *ngIf="check.result == 'passed' || check.result == 'failed'">
        <div *ngIf="check.expanded" class="mb-2 pad-25" (click)="prevent($event)">
          <div class="font-border1">
            <span> Checks </span>

            <div class="display-flex-end">
              <button *ngIf="check.result == 'failed'" [disabled]="!comparemonth()" class="btn btn-primary-soft"
                (click)="re_evaluate(check)">
                Re-Evaluate
              </button>
              <button class="btn btn-light filter_click">
                <i class="fa fa-filter" aria-hidden="true"></i>
                <div class="font-em">
                  <div>
                    {{ filter["findings"] }}
                  </div>
                </div>
                <div class="filter_dropdown">
                  <div class="filter-item" (click)="filter['findings'] = 'ALL'">
                    <div class="filter-checkbox" [ngClass]="{ checked: filter['findings'] === 'ALL' }"></div>
                    <div>All</div>
                  </div>
                  <div class="filter-item" (click)="filter['findings'] = 'Passed'">
                    <div class="filter-checkbox" [ngClass]="{ checked: filter['findings'] === 'Passed' }"></div>
                    <div>Passed</div>
                  </div>
                  <div class="filter-item" (click)="filter['findings'] = 'Failed'">
                    <div class="filter-checkbox" [ngClass]="{ checked: filter['findings'] == 'Failed' }"></div>
                    <div>Failed</div>
                  </div>
                </div>
              </button>
              <div class="display-content-end" *ngIf="check['pagination']">
                <div>Per Page:</div>
                <div>
                  <select class="pad-background" [(ngModel)]="check['pagination']['per_page']"
                    (change)="changePerPage(check)">
                    <option [ngValue]="count" *ngFor="let count of perPages">
                      {{ count }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!check['loading']">
            <div class="finding" *ngFor="
                let finding of filterFindings(check.findings)
                  | paginate
                    : {
                        itemsPerPage: check['pagination'].per_page,
                        currentPage: check['pagination'].page,
                        totalItems: filterFindings(check.findings).length
                      };
                let i = index
              ">
              <div class="mb-2">
                <div class="display-content-start">
                  <div class="flex-1">
                    <h5>
                      {{
                      check["pagination"]["per_page"] *
                      (check["pagination"]["page"] - 1) +
                      (i + 1)
                      }}. {{ finding["name"] }}
                    </h5>
                    <div>
                      {{ finding["description"] }}
                    </div>
                    <div *ngIf=""></div>
                  </div>
                  <div>
                    <span *ngIf="finding['resource']">
                      Resource: {{ finding["resource"] }}
                    </span>
                    <span *ngIf="finding['region']">| Region: {{ finding["region"] }}</span>
                  </div>
                </div>
              </div>
              <div>
                <ng-container *ngIf="finding['status'] == 'passed'">
                  <span class="passfail" [ngStyle]="{ 'background-color': 'rgb(92, 156, 92)' }">Passed</span>
                </ng-container>
                <ng-container *ngIf="finding['status'] == 'failed'">
                  <span class="passfail" [ngStyle]="{ 'background-color': 'rgb(243, 115, 115)' }">Failed</span>
                </ng-container>
              </div>
              <ng-container *ngIf="
                  finding['status'] == 'Manual' || finding['status'] == 'failed'
                ">
                <div class="mb-2" style="margin-top: 12px">
                  <div class="font-bold">Reason</div>
                  <div>{{ finding["reason"] }}</div>
                </div>

                <div class="mb-2 flex-start" *ngIf="finding['redirectURL'] != '-'">
                  <!-- <div *ngIf="finding['redirectUrl']['swayam']">
                    <a target="_blank" href="{{ finding['redirectUrl']['swayam'] }}"
                    class="btn btn-primary-soft display-flex-gap"><span>
                      <span>Swayam </span><i class="fa fa-external-link" aria-hidden="true"></i>
                    </span>
                  </a>
                  </div> -->
                  <div class="mb-2 display-flex-item-gap" *ngIf="finding['redirectUrl']['swayam']">
                    <a target="_blank" href="{{ finding['redirectUrl']['swayam'] }}"
                      class="btn btn-primary-soft display-flex-gap"><span>
                        <span>Swayam </span><i class="fa fa-external-link" aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                  <div class="mb-2 display-flex-item-gap" *ngIf="finding['redirectUrl']['awsConsole']">
                    <a target="_blank" href="{{ finding['redirectUrl']['awsConsole'] }}"
                      class="btn btn-primary-soft display-flex-gap"><span>
                        <i class="fa-brands fa-aws"></i><span> Go to Console </span><i class="fa fa-external-link"
                          aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                  <div class="mb-2 display-flex-item-gap" *ngIf="finding['redirectUrl']['awsDoc']">
                    <a target="_blank" href="{{ finding['redirectUrl']['awsDoc'] }}"
                      class="btn btn-primary-soft display-flex-gap"><span>
                        <span>How to </span>
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                  <div *ngIf="
                      finding['actions'] &&
                      getObjectKeys(finding['actions']).length > 0
                    ">
                    <div *ngFor="let key of getObjectKeys(finding['actions'])">
                      <a target="_blank" href="{{ finding['actions'][key] }}"
                        class="btn btn-primary-soft display-flex-gap"><span>
                          <img src="../assets/img/aws_logo_1.png" alt="" class="height14" />
                          <span>{{ key }}</span>
                          <i class="fa fa-external-link" aria-hidden="true"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="finding['status'] == true">
                Passed
              </ng-container>
            </div>
            <div class="paginator">
              <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
                (pageChange)="onTableDataChange($event, check)">
              </pagination-controls>
            </div>
          </ng-container>
          <ng-container *ngIf="check['loading']">
            <div class="text-cent">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar10" />
            </div>
          </ng-container>
        </div>
      </ng-container>
      </div>
      <div class="text-cent" *ngIf="loading['checks']">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar-right" />
      </div>
    </div>
  </div>


  <div *ngIf="additionadis.isAdditionalDiscountActive == 'yes'" class="progress-container">
    <div class="progress-header">
      <div>{{additionadis.gameLevel}}</div>
    </div>
    <div class="progress-body">
      <div [ngClass]="{ expanded: additionadis.expanded }">
        <div class="check" (click)="showCheck(additionadis)" id="check_{{ additionadis['check_id'] }}">
          <div class="check_inner" [attr.data-status]="additionadis.status">

            <div class="status" [ngStyle]="{
                'background-color':
                  (additionadis.result == 'failed' && 'rgb(234, 228, 228)') ||
                  (additionadis.result == 'passed' && 'rgb(92, 156, 92)') ||
                  (additionadis.type == 'Manual' && 'rgb(240 175 30)'),
                  'color':(additionadis.result == 'failed' && 'black')
              }">
              <ng-container class="status" *ngIf="additionadis.result == 'failed'">
                Pending
              </ng-container>
              <ng-container class="status" *ngIf="additionadis.result == 'passed'">
                Passed
              </ng-container>
              <ng-container class="status" *ngIf="additionadis.type == 'Manual'">
                Manual
              </ng-container>
            </div>


            <div class="flex-pad">
              <div class="display-flex-space-center">
                <!-- <div class="flex-1"> -->
                <div class="font-pointer">{{ additionadis.name }}</div>

              </div>

              <div class="flex-between">
                <div class="tag_list">
                  <div class="tag">
                    <div class="tag_item">
                      {{ additionadis["failed"] + additionadis["passed"] }}
                    </div>
                    <div class="tag_item">Tested</div>
                  </div>
                  <div class="tag passed">
                    <div class="tag_item">{{ additionadis["passed"] }}</div>
                    <div class="tag_item">Passed</div>
                  </div>
                  <div class="tag failed">
                    <div class="tag_item">{{ additionadis["failed"] }}</div>
                    <div class="tag_item">Failed</div>
                  </div>
                </div>
                <!-- <div class="tag_list">
                  <div class="tag passed">
                    <div class="tag_item">
                      {{ additionadis.discountProvided | number : "1.0-0" }}%
                    </div>
                    <div class="tag_item">Total Discount</div>
                  </div>
                  <div class="tag passed">
                    <div class="tag_item">
                      {{additionadis.discountApplied | number : "1.0-0" }}%
                    </div>
                    <div class="tag_item">Discount Applied</div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="down-arrow">
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div *ngIf="additionadis.expanded" class="mb-2 pad-25" (click)="prevent($event)">

          <ng-container>
            <div class="finding">
              <ng-container>
                <div *ngIf="additionadis.result == 'passed'" class="mb-2 flex-start dir-alit">
                  <h5>{{additionadis.description}}</h5>
                  <p ><strong>Well Done!</strong><br>
                    You have taken a major step towards Cloud Optimization and Sustainability.
                  </p>
                </div>

                <div *ngIf="additionadis.result == 'failed'">
                  <h5>{{additionadis.description}}</h5>
                  <div class="mb-2" style="margin-top: 12px">
                    <div class="font-bold">Reason</div>
                    <div>{{ additionadis.reason }}</div>
                  </div>


                  <div class="mb-2 flex-start">
                  <div class="mb-2 display-flex-item-gap">
                    <a target="_blank" href="{{ additionadis.swayam }}"
                      class="btn btn-primary-soft display-flex-gap"><span>
                        <span>Swayam </span><i class="fa fa-external-link" aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                  <div class="mb-2 display-flex-item-gap">
                    <a target="_blank" href="{{  additionadis.awsLink }}"
                      class="btn btn-primary-soft display-flex-gap"><span>
                        <i class="fa-brands fa-aws"></i><span> Go to Console </span><i class="fa fa-external-link"
                          aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </ng-container>

        </div>
      </div>
      <div class="text-cent" *ngIf="loading['checks']">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar-right" />
      </div>
    </div>
  </div>




</div>
<!-- <app-greengame-rescan *ngIf="rescan" (re_scan)="re_scan()"></app-greengame-rescan> -->
<div *ngIf="rescan" class="confirmContainer">
  <div class="confirmBox confirmBox-wid">
    <div class="KBBody flex-1">
      <div class="col-mb-6 mb-2">
        <h6>Do you want to do Rescan?</h6>
      </div>
    </div>

    <div class="KBActions">
      <button class="btn btn-success" (click)="re_scan()">Yes</button>
      <button class="btn btn-light" (click)="no()">No</button>
    </div>
  </div>
</div>
<!-- <span>{{(check.passedPercentage | number:'1.2-2')}}% </span> -->