import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
import { subServicesSRC } from "./subServices";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
declare let window: any;
declare var hljs: any;
declare var $: any;
declare var flatpickr: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./cloudtrail.component.html",
  styleUrls: ["./cloudtrail.component.css"],
})
export class cloudTrailComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  cloudTrailList: any = [];
  service: string = "EC2";
  subService: string;
  nextToken: any;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  dateRange: string | null = null;
  selectedResourceId: string = null;
  selectedDateString: string = null;
  showInnerData: boolean = false;
  innerData: any = {};
  subServices: any = {
    EC2: [
      {
        str: "EC2::StartInstances",
        value: "StartInstances",
      },
      {
        str: "EC2::StopInstances",
        value: "StopInstances",
      },
      {
        str: "EC2::CreateInstances",
        value: "CreateInstances",
      },
      {
        str: "EC2::CreateInstance",
        value: "CreateInstance",
      },
      {
        str: "EC2::RunInstances",
        value: "RunInstances",
      },
      {
        str: "EC2::ModifyInstanceAttribute",
        value: "ModifyInstanceAttribute",
      },
      {
        str: "EC2::TerminateInstances",
        value: "TerminateInstances",
      },
    ],
    RDS: [
      {
        value: "CreateDBInstance",
        str: "RDS::CreateDBInstance",
      },
      {
        value: "ModifyDBInstance",
        str: "RDS::ModifyDBInstance",
      },
      {
        value: "CreateDBCluster",
        str: "RDS::CreateDBCluster",
      },
      {
        value: "ModifyDBCluster",
        str: "RDS::ModifyDBCluster",
      },
      {
        value: "DeleteDBInstance",
        str: "RDS::DeleteDBInstance",
      },
      {
        value: "DeleteDBCluster",
        str: "RDS::DeleteDBCluster",
      },
    ],
    Others: subServicesSRC,
  };
  headers: any = [
    {
      id: "EventName",
      name: "Event Name",
      show: true,
      sort: { sort: true, direction: "Asc" },
      type: "String",
      filter: true,
      style: { width: 0 },
      click: "showData",
    },
    {
      id: "EventTime",
      name: "Event Time",
    },
    { id: "Username", name: "User Name" },
    { id: "EventSource", name: "Event Source" },
    { id: "ResourceTypes", name: "Resource Type" },
    { id: "ResourceNames", name: "Resource Name" },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.route.queryParams.subscribe((values: any) => {
      if(values['resource_type']) {
        this.service = values['resource_type'];
      }
      if(values['type_of_action']) {
        this.subService = values['type_of_action'];
      }
      if(values['resource_name']) {
        this.selectedResourceId = values['resource_name'];
      }
      if(values['date']) {
        this.selectedDateString = values['date'];
        let momentObj: any = moment(values['date'], 'YYYY-MM-DDTHH:mm:ss');
        let start_date = momentObj.subtract(1, 'day').format("DD-MMM-YYYY");
        let end_date = momentObj.add(1, 'day').format("DD-MMM-YYYY");
        this.dateRange = start_date + " to " + end_date;
      }
    })

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.setSubService(true);
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.setSubService(true);
      }
    });
    setTimeout(() => {
      if(!this.dateRange) {
        let start_date = moment().startOf("month").format("DD-MMM-YYYY");
        let end_date = moment().startOf("day").format("DD-MMM-YYYY");
        this.dateRange = start_date + " to " + end_date;
      }
      flatpickr(".datetimepicker", {
        dateFormat: "d-M-Y",
        mode: "range",
        maxDate: new Date(),
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes("to")) return;
          this.dateRange = dateStr;
          this.nextToken = null;

          this.load();
        },
      });
    }, 500);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  filterDate(dateString: any) {
    return moment(dateString).format("DD-MMM-YYYY HH:MM:SS");
  }

  setSubService(event: boolean = false) {
    if(this.selectedResourceId) {
      this.nextToken = null;
      this.load();
      return;
    }
    if (event) {
      this.service = "EC2";
    }
    if (this.service == "Others") {
      this.subService = "";
      setTimeout(() => {
        $("#subService").selectpicker();
      }, 100);
    } else {
      this.subService = this.subServices[this.service][0].value;
      this.nextToken = null;
      this.load();
    }
  }

  async load(refresh: boolean = false) {
    if (refresh) {
      this.nextToken = undefined;
      this.innerData = {};
      this.showInnerData = null;
      this.cloudTrailList = [];
    }

    if (this.service == "Others") {
      this.subService = $("#subService").val() || this.subService;
    }
    this.notifier.loading(true);
    if (!this.nextToken) {
      this.cloudTrailList = [];
    }
    this.showInnerData = false;
    this.innerData = {};
    let start_date;
    let end_date;
    if (!(this.dateRange && this.dateRange.includes("to"))) {
      start_date = moment().startOf("month").format("YYYY-MM-DD");
      end_date = moment().startOf("day").format("YYYY-MM-DD");
    } else {
      let dRange = this.dateRange.toString().split(" to ");
      start_date = moment(dRange[0], "DD-MMM-YYYY").format("YYYY-MM-DD");
      end_date = moment(dRange[1], "DD-MMM-YYYY").format("YYYY-MM-DD");
    }
    let data = {
      action: "event_history",
      region_name: this.regionId, //TODO: Replace with dynamic value or variable
      account_id: this.accountId, //TODO: Replace with dynamic value or variable
      resource_type: this.service.toLowerCase(),
      type_of_action: this.subService,
      start_time: start_date, //TODO: Replace with dynamic value or variable
      end_time: end_date, //TODO: Replace with dynamic value or variable
      next_token: this.nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if(this.selectedResourceId) {
        let event: any = result.events.find((event: any) => {
          let EventTime: any = moment(event['EventTime']).add(5.5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
          console.log(event['EventTime'], EventTime, this.selectedDateString);
          
          return EventTime == this.selectedDateString && (event['Resources'].find((resource: any) => {
            return resource['ResourceName'] == this.selectedResourceId
          }) ? true : false)
        })
        if(event) {
          this.callFunction(event);
        } else {
          this.router.navigate(['/dash/compliance/cloudtrail']);
        }
      }
      window.analyticsSendEventClick(
        "Compliance.CloudTrail",
        "Cloudtrail Fetched"
      );
      this.cloudTrailList = [...this.cloudTrailList, ...result.events];
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    if (result.next_token) {
      this.nextToken = result.next_token;
    } else {
      this.nextToken = null;
    }

    $("#subService").selectpicker("refresh");
    this.notifier.loading(false);
  }

  goBack() {
    this.router.navigate(['/dash/compliance/cloudtrail']);
    this.showInnerData = false;
  }

  callFunction(item: any) {
    this.showInnerData = true;
    this.innerData = item;
    this.innerData["CloudTrailEvent"] = JSON.parse(
      this.innerData["CloudTrailEvent"]
    );
    this.innerData["eventRecord"] = JSON.stringify(
      this.innerData["CloudTrailEvent"],
      null,
      2
    );

    var m = this.innerData["eventRecord"];

    delete this.innerData.eventRecord;

    var hlt = hljs.highlight("json", JSON.stringify(this.innerData, null, 4));

    /*    this.innerData['eventRecord']*/

    setTimeout(() => {
      //  $('#codehere').html(hlt.value);
      document.querySelector("pre.code").innerHTML = hlt.value;
    }, 500);
  }

  print_r(object: any, html: any) {
    if (html) return "<pre>" + JSON.stringify(object, null, 4) + "</pre>";
    else return JSON.stringify(object, null, 4);
  }
}
