<div class="card">
  <div class="card-header flex-between">
    <h5>
      {{ selectedCloudPartner == 'aws' ? 'S3' : 'Blobs' }}
    </h5>
    <div class="flex-start">
      <div>
        <div class="text-bold">
          Total {{selectedCloudPartner == 'aws' ? 'Buckets' : 'Containers' }}
        </div>
        <div>
          {{ totalBuckets || 0 }}
        </div>
      </div>
      <div *ngIf="selectedCloudPartner == 'aws'">
        <div class="text-bold">
          Public Access
        </div>
        <div *ngIf="publicAccess != null">
          <div *ngIf="publicAccess" class="red_fancy text-bold">
            <i class="fa fa-exclamation-triangle"></i> Allowed
          </div>
          <div class="green_fancy text-bold" *ngIf="!publicAccess">
            Restricted
          </div>
        </div>
        <div class="text-bold" *ngIf="publicAccess == null">
          -
        </div>
      </div>
      <button (click)="load(true, 1)" *ngIf="!loading" class="btn btn-primary-soft flex-start">
        <i class="fa fa-refresh"></i>
        Refresh
      </button>
    </div>
  </div>
  <div class="card-body">
    <!-- <div class="grid_container buckets_summary">
      <div class="grid_child flex_horizontal" (click)="filterBuckets('ALL', true)"
        [ngClass]="{'active_filter': filterType == 'ALL'}">
        <div>All Buckets</div>
        <div>{{ s3List.length }}</div>
      </div>
      <div class="grid_child flex_horizontal" (click)="filterBuckets('public', true)"
        [ngClass]="{'active_filter': filterType == 'public'}">
        <div>Public Buckets</div>
        <div>{{ getLength("public", true) }}</div>
      </div>
      <div class="grid_child flex_horizontal" (click)="filterBuckets('isEncrypted', true)"
        [ngClass]="{'active_filter': filterType == 'isEncrypted'}">
        <div>Encrypted Buckets</div>
        <div>{{ getLength("isEncrypted", true) }}</div>
      </div>
    </div> -->
    <!--<h5 class="card-title">Case Details</h5>-->
    <!-- <!-- <div class="grid_container">
      <div *ngFor="let bucket of s3List">
        <div class="grid_child">
          <div
            class="child_default info-group"
            [ngStyle]="{ opacity: bucket.focused ? 1 : 0.4 }"
          >
            <div class="bucket_name info-title">
              <div class="success_icon icon_container bucket_icon_container">
                <img
                  class="bucket_icon"
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Compliance+S3.svg"
                  alt=""
                />
              </div>
              <div>
                {{ bucket.bucketName }}
              </div>
            </div>

            <div class="child_public_check info-side">
              <div class="success_icon icon_container" *ngIf="bucket.public">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </div>
              <div class="failed_icon icon_container" *ngIf="!bucket.public">
                <i class="fa fa-unlock-alt" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="child_more">
            <div class="info-group">
              <div class="info-title">Public</div>
              <div class="info-side">
                <div class="failed_icon icon_container" *ngIf="bucket.public">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div class="success_icon icon_container" *ngIf="!bucket.public">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Encrypted</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.isEncrypted"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.isEncrypted"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Bucket Public Policy Status</div>
              <div class="info-side">
                <div
                  class="failed_icon icon_container"
                  *ngIf="bucket.bucketPublicPolicyStatus"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="success_icon icon_container"
                  *ngIf="!bucket.bucketPublicPolicyStatus"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Restrict Public Buckets</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.restrictPublicBuckets"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.restrictPublicBuckets"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Block Public Access</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.blockPublicAccess"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.blockPublicAccess"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Block Public Acl</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.blockPublicAcl"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.blockPublicAcl"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="display-none" *ngFor="let i of getArray() | paginate
    : {
        itemsPerPage: perPage,
        currentPage: page,
        totalItems: totalBuckets
      }; let j = index"></div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of selectedCloudPartner == 'aws' ? headers: azureHeaders">{{h.name}}</th>
          <th>More Details</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': !loading && s3List.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of s3List; let j = index">
          <td>{{(perPage * (page - 1)) + j + 1}}</td>
          <td *ngFor="let h of selectedCloudPartner == 'aws' ? headers: azureHeaders">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="h.color">
                <span [ngStyle]="{'color': h.color(i[h.id])}">{{checkforBool(i[h.id])}}</span>
              </span>
              <span *ngIf="!h.color">
                <span>{{checkforBool(i[h.id])}}</span>
              </span>
            </span>
          </td>
          <td>
            <div class="failed_ami" (mouseenter)="setPosition($event)">
              <div class="status_container">
                <div class="black_bottom_border">
                  More Details
                </div>
              </div>
              <div class="failed_ami_message_container"
                [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0, height: position.height, width: position.width}">
                <div class="message_back">
                  <div>
                    <div class="message_grid"
                      [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                      <div class="flex_start_direction">
                        <table class="flex_grow">
                          <thead>
                            <tr>
                              <th *ngFor="let h of selectedCloudPartner == 'aws' ? moreDetailsHeaders: azureMoreDetailsHeader">{{h.name}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td *ngFor="let h of selectedCloudPartner == 'aws' ? moreDetailsHeaders: azureMoreDetailsHeader">
                                <span *ngIf="h.color" [ngStyle]="{'color': h.color(i[h.id])}">{{h.value(i[h.id])}}</span>
                                <span *ngIf="!h.color">{{h.value(i[h.id])}}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="display_flex_height20">
                  <div class="showdown" [ngStyle]="{right: position.pointer}"></div>
                </div>
                <div class="message_container">
                  <div>
                    <div class="message_grid"
                      [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                      <div class="flex_start_direction">
                        <table class="flex_grow">
                          <thead>
                            <tr>
                              <th *ngFor="let h of selectedCloudPartner == 'aws' ? moreDetailsHeaders: azureMoreDetailsHeader">{{h.name}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td *ngFor="let h of selectedCloudPartner == 'aws' ? moreDetailsHeaders: azureMoreDetailsHeader">
                                <span *ngIf="h.color" [ngStyle]="{'color': h.color(i[h.id])}">{{h.value(i[h.id])}}</span>
                                <span *ngIf="!h.color">{{h.value(i[h.id])}}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': !loading && s3List.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div class="no_datafound_184">No Data Found</div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': loading ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2" class="center">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paginator" *ngIf="totalBuckets > 0">
      <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="load(false, $event)">
      </pagination-controls>
    </div>
  </div>
</div>