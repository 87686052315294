<div class="vpc_container" *ngIf="ami">
  <div class="flex-space-around">
    <span class="text_bold">
      {{ami.ami_id}}
    </span>
    <span *ngIf="ami.os" class="text_bold">
      {{ami.os}}
    </span>
  </div>
  <div class="flex-space-around">
    <span class="light_text">
      Description:
      {{ami.description}}
    </span>
    <span class="light_text">
      Version:
      {{ami.version}}
    </span>
    <span class="light_text">
      Architecture:
      {{ami.architecture}}
    </span>
  </div>
</div>