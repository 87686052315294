<div class="card">
    <h5 class="card-header">
        Reports
    </h5>
    <div class="card-body">
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="this-account-tab" data-bs-toggle="tab"
                    data-bs-target="#this-account" type="button" role="tab" aria-controls="this-account"
                    aria-selected="true">This Account</button>
                <button class="nav-link" id="all-accounts-tab" data-bs-toggle="tab" data-bs-target="#all-accounts"
                    type="button" role="tab" aria-controls="all-accounts" aria-selected="false">All Accounts</button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="this-account" role="tabpanel" aria-labelledby="this-account-tab">
                <div class="reports-grid">
                    <ng-container *ngFor="let report of reports['This Account']">
                        <div class="scanner" *ngIf="!report['show'] || report['show']()"
                            [ngClass]="{new: report['new'] && report['new']()}">
                            <div class="flex-1">
                                <div class="image-holder">
                                    <div class="image-container">
                                        <img [attr.src]="report['icon']" class="wid-40">
                                    </div>
                                </div>
                                <div class="text-bold">
                                    {{report['name']}}
                                </div>
                                <div class="small-text">
                                    {{ report['description'] }}
                                </div>
                            </div>
                            <div class="action-container" *ngIf="!report.downloadOnly">
                                <a class="btn btn-primary-soft" routerLink="/dash/reports/view"
                                    [queryParams]="{reportType: report['id']}">
                                    View Report
                                </a>
                            </div>
                            <div class="action-container" *ngIf="report.downloadOnly">
                                <span class="btn btn-primary-soft" (click)="selectedReport = report.id">
                                    Request Report
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="tab-pane fade show" id="all-accounts" role="tabpanel" aria-labelledby="all-accounts-tab">
                <div class="reports-grid">
                    <ng-container *ngFor="let report of reports['All Accounts']">
                        <div class="scanner" *ngIf="!report['show'] || report['show']()"
                            [ngClass]="{new: report['new'] && report['new']()}">
                            <div class="flex-1">
                                <div class="image-holder">
                                    <div class="image-container">
                                        <img [attr.src]="report['icon']" class="wid-40">
                                    </div>
                                </div>
                                <div class="text-bold">
                                    {{report['name']}}
                                </div>
                                <div class="small-text">
                                    {{ report['description'] }}
                                </div>
                            </div>
                            <div class="action-container" *ngIf="!report.downloadOnly">
                                <a class="btn btn-primary-soft" routerLink="/dash/reports/view"
                                    [queryParams]="{reportType: report['id']}">
                                    View Report
                                </a>
                            </div>
                            <div class="action-container" *ngIf="report.downloadOnly">
                                <span class="btn btn-primary-soft" (click)="selectedReport = report.id">
                                    Request Report
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>


<mbr-report *ngIf="selectedReport == 'mbr_reports'" [type]="'single'" (hideModel)="selectedReport = ''"></mbr-report>
<mbr-report *ngIf="selectedReport == 'mbr_reports_all'" [type]="'all'" (hideModel)="selectedReport = ''"></mbr-report>

<latest_mbr-report *ngIf="selectedReport == 'latest_mbr_reports_all'" [type]="'all'" (hideModel)="selectedReport = ''" ></latest_mbr-report>
<consolidated-ec2-report *ngIf="selectedReport == 'consolidated_ec2_report'"
    (hideModel)="selectedReport = ''"></consolidated-ec2-report>