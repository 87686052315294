import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "site24x7-monitor-activate",
  templateUrl: "./activate.component.html",
  styleUrls: ["./activate.component.css"],
})
export class ActivateComponent implements OnInit {
  @Input("monitor") monitor: any;
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  otpSent: boolean = false;
  otpText: string = "";
  requestID: string = "";
  async sendOTP() {
    this.loading = true;
    let data: any = {
      action: "send_common_otp",
      email_id: localStorage.getItem("eId"),
      user_name: localStorage.getItem("un"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.otpSent = true;
      this.requestID = result.request_id;
      this.notifier.alert("Success", "", result.message, "success", 5000);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.loading = false;
  }

  async validateOTP() {
    if (!this.otpText) {
      this.notifier.alert("Info", "", "OTP is required", "info", 5000);
      return;
    }
    this.loading = true;

    let data: any = {
      action: "validate_common_otp",
      email_id: localStorage.getItem("eId"),
      user_name: localStorage.getItem("un"),
      otp: this.otpText,
      request_id: this.requestID,
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      await this.updateMonitor();
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.error_message || result.message,
        "info",
        5000
      );
    }

    this.loading = false;
  }

  loading: boolean = false;
  async updateMonitor() {
    this.loading = true;
    let account: any = localStorage.getItem("emsConfig");
    let accountOld: any = JSON.parse(localStorage.getItem("accountIdData"))[
      "list"
    ].find((account: any) => {
      return account["accountId"] == localStorage.getItem("accountId");
    });
    if (!(account || accountOld["site_247_project_id"])) {
      this.loading = false;
      return;
    }

    if (account) {
      account = JSON.parse(account);
    }

    let data: any = {
      action: this.monitor["state"] ? "suspend_monitor" : "activate_monitor",
      id_token: localStorage.getItem("idT"),
      zaaid: account ? account["zaid"] : accountOld["site_247_project_id"],
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      origin: account ? "swayam" : "minfy",
      monitor_id: this.monitor["monitor_id"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.message, "success", 5000);

      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.loading = false;
  }
}
