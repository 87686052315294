<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">
      <i class="fa fa-exclamation-circle mar-right-7" aria-hidden="true"></i>
      {{title}}
    </div>
    <div class="KBBody">
      <div class="col-mb-12 align-center-10">
        <span [innerHTML]="message">
        </span>
      </div>
      <div class="KBActions">
        <button class="btn btn_blue btn_flex" [ngClass]="{'btn_loading': loading}" (click)="yes()">
          <span *ngIf="loading"><img src="../assets/img/loading_2_white.svg" alt="loading scans" width="10"
              class="wid-15"></span>
          <span class="color-inherit">Confirm</span>
        </button>
        <button class="btn btn-light" (click)="no()">Cancel</button>
      </div>
    </div>
  </div>