<div class="card">
  <div class="card-header flex-start">
    <button class="btn btn-light flex-start" (click)="hideModel.emit(false)">
      <i class="fa fa-angle-left" aria-hidden="true"></i>
      <span>Back</span>
    </button>
    <h5>
      Scanners - {{ scanner['scanner_name'] }}
    </h5>
  </div>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active flex-start" id="cdn-tab" data-bs-toggle="tab" data-bs-target="#cdn" type="button"
          role="tab" aria-controls="cdn" aria-selected="true" (click)="currentPage = 'cdn'">
          <span>
            Cloudfront
          </span>
          <span class="selected-count">{{ resources['cdn'].length }}</span>
        </button>
        <button class="nav-link flex-start" id="elb-tab" data-bs-toggle="tab" data-bs-target="#elb" type="button"
          role="tab" aria-controls="elb" aria-selected="true" (click)="currentPage = 'elb'">
          <span>
            ELB
          </span>
          <span class="selected-count">{{ resources['elb'].length }}</span>
        </button>
        <button class="nav-link flex-start" id="ec2-tab" data-bs-toggle="tab" data-bs-target="#ec2" type="button"
          role="tab" aria-controls="ec2" aria-selected="true" (click)="currentPage = 'ec2'">
          <span>
            EC2
          </span>
          <span class="selected-count">{{ resources['ec2'].length }}</span></button>
        <button class="nav-link flex-start" id="rds-tab" data-bs-toggle="tab" data-bs-target="#rds" type="button"
          role="tab" aria-controls="rds" aria-selected="true" (click)="currentPage = 'rds'">
          <span>
            RDS
          </span>
          <span class="selected-count">{{ resources['rds'].length }}</span>
        </button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="cdn" role="tabpanel" aria-labelledby="cdn-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>ARN</th>
                <th>Region</th>
                <th>End Point</th>
                <th>Response Code</th>
                <th>Port</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['cdn'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['cdn'] | paginate
                      : {
                          itemsPerPage: pagination['cdn']['perPage'],
                          currentPage: pagination['cdn']['page'],
                          totalItems: resources['cdn'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ this.regionId }}
                </td>
                <td>
                  {{ resource['url'] }}
                </td>
                <td>
                  {{ resource['success_code'].join(', ') }}
                </td>
                <td>
                  {{ resource['port'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['cdn'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="6">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'cdn'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['cdn']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
      <div class="tab-pane fade" id="elb" role="tabpanel" aria-labelledby="elb-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>ARN</th>
                <th>Region</th>
                <th>End Point</th>
                <th>Response Code</th>
                <th>Port</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['elb'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['elb'] | paginate
                      : {
                          itemsPerPage: pagination['elb']['perPage'],
                          currentPage: pagination['elb']['page'],
                          totalItems: resources['elb'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ this.regionId }}
                </td>
                <td>
                  {{ resource['url'] }}
                </td>
                <td>
                  {{ resource['success_code'].join(', ') }}
                </td>
                <td>
                  {{ resource['port'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['elb'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="6">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'elb'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['elb']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
      <div class="tab-pane fade" id="ec2" role="tabpanel" aria-labelledby="ec2-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Instance ID</th>
                <th>Instance Type</th>
                <th>Region</th>
                <th>End Point</th>
                <th>Response Code</th>
                <th>Port</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['ec2'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['ec2'] | paginate
                      : {
                          itemsPerPage: pagination['ec2']['perPage'],
                          currentPage: pagination['ec2']['page'],
                          totalItems: resources['ec2'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ resource['instance_type'] }}
                </td>
                <td>
                  {{ this.regionId }}
                </td>
                <td>
                  {{ resource['url'] }}
                </td>
                <td>
                  {{ resource['success_code'].join(', ') }}
                </td>
                <td>
                  {{ resource['port'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['ec2'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="6">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'ec2'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['ec2']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
      <div class="tab-pane fade" id="rds" role="tabpanel" aria-labelledby="rds-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>DB Identifier</th>
                <th>DB Type</th>
                <th>Region</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['rds'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['rds'] | paginate
                      : {
                          itemsPerPage: pagination['rds']['perPage'],
                          currentPage: pagination['rds']['page'],
                          totalItems: resources['rds'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ resource['instance_type'] }}
                </td>
                <td>
                  {{ regionId }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['rds'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="6">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'rds'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['rds']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
    </div>
  </div>
</div>