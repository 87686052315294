<div class="card">
  <h5 class="card-header flex-start">
    <a [routerLink]="'/dash/reports/home'" class="default-link">
      <button class="btn btn-light flex-start">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span>Reports</span>
      </button>
    </a>
    <span>
      Disk Details
    </span>
  </h5>
  <div class="card-body">
    <div class="row cont-sty">
      <div class="width-33">
        <select class="form-control width-33" id="instanceID" data-live-search="true"
          (change)="getDiskUtilization($event)">
          <option *ngFor="let i of instances" value="{{ i }}">{{ i }}</option>
        </select>
      </div>
      <div class="card border-none">
        <div class="card-body">
          <div class="card-dply" *ngIf="diskUtilization.length > 0">
            <button class="btn btn-light btn-styl" (click)="export()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="diskTable">
            <thead>
              <tr>
                <th>S.N.</th>
                <th *ngFor="let h of diskUtilizationHeader">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let i of diskUtilization; let j = index">
                <td>{{ j + 1 }}</td>
                <td *ngFor="let h of diskUtilizationHeader">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span>
                      {{ i[h.id] }}
                    </span>
                  </span>
                  <span *ngIf="!h.click">
                    {{ i[h.id] }}
                  </span>
                </td>
              </tr>
              <ng-container *ngIf="diskUtilization.length == 0">
                <tr>
                  <td class="txt-cntr" [attr.colspan]=" diskUtilizationHeader.length + 1">
                    No Disks Found
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>