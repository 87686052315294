import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

@Component({
  selector: 'createalarm-anomalies',
  templateUrl: './createalarm.anomalies.component.html',
  styleUrls: ['./createalarm.anomalies.component.css'],
})
export class CreateAlarmAnomaliesComponent implements OnInit, OnDestroy {
  additionalContacts: string[] = [''];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<boolean>();
  @Input() inputMonitor: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    if (this.inputMonitor) {
      this.additionalContacts = this.inputMonitor.email_ids.split(', ');
      this.inputMonitor.timeRangeValue = Number(
        this.inputMonitor.time_range.split(' ')[0]
      );
      this.inputMonitor.timeRangeFrequency =
        this.inputMonitor.time_range.split(' ')[1];
    }

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
  }

  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  deleteSelectedAdditionalContact(index: number) {
    this.additionalContacts.splice(index, 1);
  }

  close() {
    this.hideModel.emit(this.inputMonitor.status);
  }

  async createAlarm() {
    let data = {
      action: 'alarm',
      attribute_name: this.inputMonitor.attribute_name,
      monitor_id: this.inputMonitor.monitor_id,
      project_id: this.inputMonitor.zaaid,
      monitor_type: this.inputMonitor.monitor_type,
      alarm_action: this.inputMonitor.status.toString(),
      alarm_name: this.inputMonitor.alarm_name,
      anomaly_count: this.inputMonitor.anomaly_count.toString(),
      time_range:
        this.inputMonitor.timeRangeValue +
        ' ' +
        this.inputMonitor.timeRangeFrequency.toLowerCase(),
      email_ids: this.additionalContacts.join(', '),
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.hideModel.emit(!this.inputMonitor.status);
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.additionalContacts.length - 1 == index
    ) {
      this.additionalContacts.push('');
    } else if (
      value == '' &&
      value.length == 0 &&
      this.additionalContacts.length - 2 == index &&
      this.additionalContacts[this.additionalContacts.length - 1] == ''
    ) {
      this.additionalContacts.splice(this.additionalContacts.length - 1, 1);
    }
    console.log(this.additionalContacts);
  }

  addAdditionalContact() {
    this.additionalContacts.push('');
  }
}
