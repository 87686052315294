import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./view.reports.component.html",
  styleUrls: ["./view.reports.component.css"],
})
export class viewReportsComponent implements OnInit {
  reportType: String = null;
  customHeader: any = [
    {
      id: "instance_name",
      name: "Host Name",
    },
    {
      id: "instance_id",
      name: "Instance ID",
    },
    {
      id: "job_status",
      name: "Job Status",
    },
    {
      id: "latest_ami_name",
      name: "Latest AMI Name",
    },
    {
      id: "latest_ami_id",
      name: "Latest AMI ID",
    },
    {
      id: "first_ami_friendly",
      name: "AMI Date",
    },
    {
      id: "latest_ami_friendly",
      name: "Last AMI Date",
    },
    {
      id: "ami_avg_retention",
      name: "Retention",
    },
    {
      id: "total_amis",
      name: "Total AMIs",
    },
  ];
  constructor(private router: ActivatedRoute, private navigator: Router) {}
  ngOnInit(): void {
    this.reportType = "sg";
    this.router.queryParams.subscribe((params: any) => {
      if (params["reportType"]) {
        console.log("reportType",this.reportType);

        this.reportType = params["reportType"];
      } else {
        console.log("reportType",this.reportType);

        this.navigator.navigate(["/dash/reports/home"]);
      }

    });
    

  }
}
