<div class="screen">
  <div class="protocol-container">
    <div class="header">
      Default Protocols On Server
    </div>
    <div class="body">
      <div class="form-group">
        <label>Protocol</label>
        <select class="form-select" [(ngModel)]="protocol['protocol']">
          <option value="http://">Http</option>
          <option value="https://">Https</option>
        </select>
      </div>
      <div class="form-group">
        <label>Ports</label>
        <input autocomplete="off" type="text" class="form-control" placeholder="Enter Ports"
          [(ngModel)]="protocol['port']">
      </div>
      <div class="form-group">
        <label>Fully Qualified Domain Name</label>
        <input autocomplete="off" type="text" class="form-control" placeholder="Enter Fully Qualified Domain Name"
          [(ngModel)]="protocol['url']">
      </div>
      <div class="form-group">
        <label>Method</label>
        <select class="form-select">
          <option value="get">GET</option>
          <option value="post">POST</option>
        </select>
      </div>
      <div class="form-group">
        <label>Success Response Code</label>
        <ng-select [items]="protocols" [addTag]="true" [virtualScroll]="true" bindLabel="protocol" bindValue="protocol"
          [multiple]="true" placeholder="Select Response Codes" [(ngModel)]="protocol['success_code']">
        </ng-select>
      </div>
    </div>
    <div class="footer flex-end">
      <button class="btn btn-primary-soft" (click)="close(true)">
        Save
      </button>
      <button class="btn btn-light" (click)="close(false)">
        Cancel
      </button>
    </div>
  </div>
</div>