<div class="enable-cur-screen" *ngIf="!enableCUR">
  <div class="enable-cur-container">
    <div class="header-section">
      <div class="title">Configure S3 Bucket</div>
      <div class="description">
        S3 Bucket is used to store CUR Report from AWS and later used to analyze data
      </div>
    </div>
    <div class="features-section">
      <div class="form-group">
        <div *ngIf="error" class="error-message">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ error }}
        </div>
      </div>
      <div class="form-group">
        <label>Bucket Name</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="details.bucketName"
          placeholder="Enter Bucket Name" [attr.disabled]="loading ? true : null"
          [ngClass]="{'error-text': errors['bucketName']['error']}">
        <div *ngIf="errors['bucketName']['error']" class="error-message">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ errors['bucketName']['message'] }}
        </div>
      </div>
      <div class="form-group">
        <label>Bucket Prefix</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="details.bucketPrefix"
          placeholder="Enter Bucket Prefix" [attr.disabled]="loading ? true : null"
          [ngClass]="{'error-text': errors['bucketPrefix']['error']}">
        <div *ngIf="errors['bucketPrefix']['error']" class="error-message">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ errors['bucketPrefix']['message'] }}
        </div>
      </div>
      <div class="form-group">
        <label>Region</label>
        <select class="form-select" [(ngModel)]="details.region" placeholder="Select Region"
          [attr.disabled]="loading ? true : null" [ngClass]="{'error-text': errors['region']['error']}">
          <option value="{{ region.id }}" *ngFor="let region of regions">{{ region.name }}</option>
        </select>
        <div *ngIf="errors['region']['error']" class="error-message">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ errors['region']['message'] }}
        </div>
      </div>
    </div>
    <div class="cur-actions flex-end">
      <button class="btn btn-light" (click)="hideModal.emit(false)">
        Cancel
      </button>
      <button class="btn btn-light btn-primary-soft" (click)="validate()" *ngIf="!loading">
        Validate
      </button>
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" *ngIf="loading" />
    </div>
  </div>
</div>
<app-steps-enable-cur *ngIf="enableCUR" (hideModel)="enableCUR = false"></app-steps-enable-cur>