import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
import * as moment from "moment";

declare let window: any;

@Component({
  templateUrl: "./s3_ageing.component.html",
  styleUrls: ["./s3_ageing.component.css"],
})
export class BetaS3AgeReportComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  s3Data: any = [];
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  funRef: any;

  userHeader: any = [
    { id: "name", name: "Bucket Name", filter: true },
    { id: "bucketLocation", name: "Region", filter: true },
    {
      id: "createDate",
      name: "Create Date",
      filter: true,
    },
    {
      id: "age_in_days_html",
      name: "Age in Days",
      center: true,
    },
  ];

  moreDetailsHeaders: any = [];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.refresh();
      }
    });
    this.funRef = {};
  }

  position: any = { x: "0px", y: "0px" };
  setPosition(event: any) {
    this.position.x = window.innerWidth - event.clientX - 60 + "px";
    this.position.y = event.clientY + 10 + "px";
  }

  refresh_: boolean = false;
  refresh() {
    this.refresh_ = true;
    this.index = 1;
    this.loadS3Data();
  }
  index: number = 1;
  nextToken: any = null;
  async loadS3Data(nextToken: string = "") {
    this.nextToken = nextToken == "" ? null : nextToken;
    this.fetching = true;
    if (this.destroyed) {
      return true;
    }
    if (this.index == 1) {
      this.s3Data = [];
    }
    let data = {
      accountId: this.accountId,
      fromIndex: this.index,
      toIndex: 10,
      eid: this.userId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/s3ageingreport`;

    if (nextToken != "" && this.refresh_) {
      nextToken = "";
      this.s3Data = [];
      return true;
    }

    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      result.status == "200" ||
      result.status == "1" ||
      result.status_code == 200 ||
      result.buckets
    ) {
      result.buckets = result.buckets.map((access: any) => {
        if (access.ageInDays > 90) {
          access["age_in_days_html"] = `<span>${access.ageInDays}</span>`;
        } else {
          access["age_in_days_html"] = `<span>${access.ageInDays}</span>`;
        }
        return access;
      });
      this.s3Data = [...this.s3Data, ...result.buckets];
    } else {
      if (result.message != "NO RECORD FOUND") {
        this.notifier.alert("Error", "", result.message, "error", 5000);
      }
    }

    if (result.nextToken) {
      await this.loadS3Data(result.nextToken);
    } else {
      this.nextToken = null;
    }
    if (result.buckets.length >= 10) {
      this.index++;
    } else {
      this.index = 1;
    }
    if (!this.destroyed) {
      this.fetching = false;
    }

    return true;
  }

  downloadReport() {
    let element = document.createElement("table");
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${[...this.userHeader, ...this.moreDetailsHeaders].map((h: any) => {
              return `<th>${h.name}</th>`;
            })}
          </tr>
        </thead>
        <tbody>
            ${this.s3Data.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${[...this.userHeader, ...this.moreDetailsHeaders].map(
                    (h: any) => {
                      return `<td>
                      ${ec2[h.id]}
                    </td>`;
                    }
                  )}
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `S3 Age Report - ${this.accountId}`,
      "csv"
    );
  }

  callFunction(name: any, param: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
