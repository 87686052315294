export let subServicesSRC = [
  {
    value: 'AbortDocumentVersionUpload',
    str: 'AbortDocumentVersionUpload',
  },
  {
    value: 'AbortEnvironmentUpdate',
    str: 'AbortEnvironmentUpdate',
  },
  {
    value: 'AbortMultipartUpload',
    str: 'AbortMultipartUpload',
  },
  {
    value: 'AbortVaultLock',
    str: 'AbortVaultLock',
  },
  {
    value: 'AcceptAccountMapping',
    str: 'AcceptAccountMapping',
  },
  {
    value: 'AcceptCertificateTransfer',
    str: 'AcceptCertificateTransfer',
  },
  {
    value: 'AcceptDelegate',
    str: 'AcceptDelegate',
  },
  {
    value: 'AcceptDirectConnectGatewayAssociationProposal',
    str: 'AcceptDirectConnectGatewayAssociationProposal',
  },
  {
    value: 'AcceptFxPaymentCurrencyTermsAndConditions',
    str: 'AcceptFxPaymentCurrencyTermsAndConditions',
  },
  {
    value: 'AcceptHandshake',
    str: 'AcceptHandshake',
  },
  {
    value: 'AcceptInvitation',
    str: 'AcceptInvitation',
  },
  {
    value: 'AcceptMatch',
    str: 'AcceptMatch',
  },
  {
    value: 'AcceptPortfolioShare',
    str: 'AcceptPortfolioShare',
  },
  {
    value: 'AcceptReservedInstancesExchangeQuote',
    str: 'AcceptReservedInstancesExchangeQuote',
  },
  {
    value: 'AcceptVpcPeeringConnection',
    str: 'AcceptVpcPeeringConnection',
  },
  {
    value: 'AcknowledgeJob',
    str: 'AcknowledgeJob',
  },
  {
    value: 'AcknowledgeThirdPartyJob',
    str: 'AcknowledgeThirdPartyJob',
  },
  {
    value: 'ActivateGateway',
    str: 'ActivateGateway',
  },
  {
    value: 'ActivatePipeline',
    str: 'ActivatePipeline',
  },
  {
    value: 'ActivateUser',
    str: 'ActivateUser',
  },
  {
    value: 'ActivateUsers',
    str: 'ActivateUsers',
  },
  {
    value: 'AddAttachmentsToSet',
    str: 'AddAttachmentsToSet',
  },
  {
    value: 'AddAttributesToFindings',
    str: 'AddAttributesToFindings',
  },
  {
    value: 'AddCache',
    str: 'AddCache',
  },
  {
    value: 'AddClientIDToOpenIDConnectProvider',
    str: 'AddClientIDToOpenIDConnectProvider',
  },
  {
    value: 'AddCommunicationToCase',
    str: 'AddCommunicationToCase',
  },
  {
    value: 'AddCustomAttributes',
    str: 'AddCustomAttributes',
  },
  {
    value: 'AddDnssec',
    str: 'AddDnssec',
  },
  {
    value: 'AddDomain',
    str: 'AddDomain',
  },
  {
    value: 'AddInstanceGroups',
    str: 'AddInstanceGroups',
  },
  {
    value: 'AddIpRoutes',
    str: 'AddIpRoutes',
  },
  {
    value: 'AddJobFlowSteps',
    str: 'AddJobFlowSteps',
  },
  {
    value: 'AddMembersToGroup',
    str: 'AddMembersToGroup',
  },
  {
    value: 'AddOrUpdateGroups',
    str: 'AddOrUpdateGroups',
  },
  {
    value: 'AddPermission20150331',
    str: 'AddPermission20150331',
  },
  {
    value: 'AddPermission20150331v2',
    str: 'AddPermission20150331v2',
  },
  {
    value: 'AddResourcePermissions',
    str: 'AddResourcePermissions',
  },
  {
    value: 'AddRoleToDbCluster',
    str: 'AddRoleToDbCluster',
  },
  {
    value: 'AddRoleToInstanceProfile',
    str: 'AddRoleToInstanceProfile',
  },
  {
    value: 'AddSourceIdentifierToSubscription',
    str: 'AddSourceIdentifierToSubscription',
  },
  {
    value: 'AddTags',
    str: 'AddTags',
  },
  {
    value: 'AddTagsToCertificate',
    str: 'AddTagsToCertificate',
  },
  {
    value: 'AddTagsToOnPremisesInstances',
    str: 'AddTagsToOnPremisesInstances',
  },
  {
    value: 'AddTagsToResource',
    str: 'AddTagsToResource',
  },
  {
    value: 'AddTagsToStream',
    str: 'AddTagsToStream',
  },
  {
    value: 'AddTagsToVault',
    str: 'AddTagsToVault',
  },
  {
    value: 'AddThingToThingGroup',
    str: 'AddThingToThingGroup',
  },
  {
    value: 'AddUploadBuffer',
    str: 'AddUploadBuffer',
  },
  {
    value: 'AddUsersToGroup',
    str: 'AddUsersToGroup',
  },
  {
    value: 'AddUserToGroup',
    str: 'AddUserToGroup',
  },
  {
    value: 'AddWorkingStorage',
    str: 'AddWorkingStorage',
  },
  {
    value: 'AdminConfirmSignUp',
    str: 'AdminConfirmSignUp',
  },
  {
    value: 'AdminCreateHsm',
    str: 'AdminCreateHsm',
  },
  {
    value: 'AdminDeleteUser',
    str: 'AdminDeleteUser',
  },
  {
    value: 'AdminDeleteUserAttributes',
    str: 'AdminDeleteUserAttributes',
  },
  {
    value: 'AdminDisableUser',
    str: 'AdminDisableUser',
  },
  {
    value: 'AdminGetUser',
    str: 'AdminGetUser',
  },
  {
    value: 'AdminListGroupsForUser',
    str: 'AdminListGroupsForUser',
  },
  {
    value: 'AdminRemoveUserFromGroup',
    str: 'AdminRemoveUserFromGroup',
  },
  {
    value: 'AdminResetUserPassword',
    str: 'AdminResetUserPassword',
  },
  {
    value: 'AdminSetUserSettings',
    str: 'AdminSetUserSettings',
  },
  {
    value: 'AdminUpdateUserAttributes',
    str: 'AdminUpdateUserAttributes',
  },
  {
    value: 'AllocateAddress',
    str: 'AllocateAddress',
  },
  {
    value: 'AllocateConnectionOnInterconnect',
    str: 'AllocateConnectionOnInterconnect',
  },
  {
    value: 'AllocateHostedConnection',
    str: 'AllocateHostedConnection',
  },
  {
    value: 'AllocateHosts',
    str: 'AllocateHosts',
  },
  {
    value: 'AllocatePrivateVirtualInterface',
    str: 'AllocatePrivateVirtualInterface',
  },
  {
    value: 'AllocatePublicVirtualInterface',
    str: 'AllocatePublicVirtualInterface',
  },
  {
    value: 'AllocateStaticIp',
    str: 'AllocateStaticIp',
  },
  {
    value: 'ApplyPendingMaintenanceAction',
    str: 'ApplyPendingMaintenanceAction',
  },
  {
    value: 'ApplySchema',
    str: 'ApplySchema',
  },
  {
    value: 'ApplySecurityGroupsToLoadBalancer',
    str: 'ApplySecurityGroupsToLoadBalancer',
  },
  {
    value: 'ApproveRfc',
    str: 'ApproveRfc',
  },
  {
    value: 'ArchiveFindings',
    str: 'ArchiveFindings',
  },
  {
    value: 'AssignInstance',
    str: 'AssignInstance',
  },
  {
    value: 'AssignIpv6Addresses',
    str: 'AssignIpv6Addresses',
  },
  {
    value: 'AssignPrivateIpAddresses',
    str: 'AssignPrivateIpAddresses',
  },
  {
    value: 'AssignVolume',
    str: 'AssignVolume',
  },
  {
    value: 'AssociateAddress',
    str: 'AssociateAddress',
  },
  {
    value: 'AssociateAdminAccount',
    str: 'AssociateAdminAccount',
  },
  {
    value: 'AssociateConnectionWithLag',
    str: 'AssociateConnectionWithLag',
  },
  {
    value: 'AssociateContactWithAddressBook',
    str: 'AssociateContactWithAddressBook',
  },
  {
    value: 'AssociateCreatedArtifact',
    str: 'AssociateCreatedArtifact',
  },
  {
    value: 'AssociateDeviceWithRoom',
    str: 'AssociateDeviceWithRoom',
  },
  {
    value: 'AssociateDhcpOptions',
    str: 'AssociateDhcpOptions',
  },
  {
    value: 'AssociateDirectory',
    str: 'AssociateDirectory',
  },
  {
    value: 'AssociateElasticIp',
    str: 'AssociateElasticIp',
  },
  {
    value: 'AssociateHostedConnection',
    str: 'AssociateHostedConnection',
  },
  {
    value: 'AssociateIamInstanceProfile',
    str: 'AssociateIamInstanceProfile',
  },
  {
    value: 'AssociatePrincipalWithPortfolio',
    str: 'AssociatePrincipalWithPortfolio',
  },
  {
    value: 'AssociateProductWithPortfolio',
    str: 'AssociateProductWithPortfolio',
  },
  {
    value: 'AssociateProfile',
    str: 'AssociateProfile',
  },
  {
    value: 'AssociateResource',
    str: 'AssociateResource',
  },
  {
    value: 'AssociateRouteTable',
    str: 'AssociateRouteTable',
  },
  {
    value: 'AssociateSkillGroupWithRoom',
    str: 'AssociateSkillGroupWithRoom',
  },
  {
    value: 'AssociateSubnetCidrBlock',
    str: 'AssociateSubnetCidrBlock',
  },
  {
    value: 'AssociateTargetsWithJob',
    str: 'AssociateTargetsWithJob',
  },
  {
    value: 'AssociateTeamMember',
    str: 'AssociateTeamMember',
  },
  {
    value: 'AssociateTechnicianWithRfc',
    str: 'AssociateTechnicianWithRfc',
  },
  {
    value: 'AssociateVirtualInterface',
    str: 'AssociateVirtualInterface',
  },
  {
    value: 'AssociateVpcCidrBlock',
    str: 'AssociateVpcCidrBlock',
  },
  {
    value: 'AssociateVPCWithHostedZone',
    str: 'AssociateVPCWithHostedZone',
  },
  {
    value: 'AssociateWebACL',
    str: 'AssociateWebACL',
  },
  {
    value: 'AttachClassicLinkVpc',
    str: 'AttachClassicLinkVpc',
  },
  {
    value: 'AttachElasticLoadBalancer',
    str: 'AttachElasticLoadBalancer',
  },
  {
    value: 'AttachGroupPolicy',
    str: 'AttachGroupPolicy',
  },
  {
    value: 'AttachInstances',
    str: 'AttachInstances',
  },
  {
    value: 'AttachInstancesToLoadBalancer',
    str: 'AttachInstancesToLoadBalancer',
  },
  {
    value: 'AttachInternetGateway',
    str: 'AttachInternetGateway',
  },
  {
    value: 'AttachLoadBalancers',
    str: 'AttachLoadBalancers',
  },
  {
    value: 'AttachLoadBalancerTlsCertificate',
    str: 'AttachLoadBalancerTlsCertificate',
  },
  {
    value: 'AttachLoadBalancerToSubnets',
    str: 'AttachLoadBalancerToSubnets',
  },
  {
    value: 'AttachNetworkInterface',
    str: 'AttachNetworkInterface',
  },
  {
    value: 'AttachPolicy',
    str: 'AttachPolicy',
  },
  {
    value: 'AttachPrincipalPolicy',
    str: 'AttachPrincipalPolicy',
  },
  {
    value: 'AttachRolePolicy',
    str: 'AttachRolePolicy',
  },
  {
    value: 'AttachSecurityProfile',
    str: 'AttachSecurityProfile',
  },
  {
    value: 'AttachThingPrincipal',
    str: 'AttachThingPrincipal',
  },
  {
    value: 'AttachUserPolicy',
    str: 'AttachUserPolicy',
  },
  {
    value: 'AttachVolume',
    str: 'AttachVolume',
  },
  {
    value: 'AttachVpnGateway',
    str: 'AttachVpnGateway',
  },
  {
    value: 'AuthenticateUser',
    str: 'AuthenticateUser',
  },
  {
    value: 'AuthorizeCacheSecurityGroupIngress',
    str: 'AuthorizeCacheSecurityGroupIngress',
  },
  {
    value: 'AuthorizeClusterSecurityGroupIngress',
    str: 'AuthorizeClusterSecurityGroupIngress',
  },
  {
    value: 'AuthorizeDBSecurityGroupIngress',
    str: 'AuthorizeDBSecurityGroupIngress',
  },
  {
    value: 'AuthorizeDirectory',
    str: 'AuthorizeDirectory',
  },
  {
    value: 'AuthorizeSecurityGroupEgress',
    str: 'AuthorizeSecurityGroupEgress',
  },
  {
    value: 'AuthorizeSecurityGroupIngress',
    str: 'AuthorizeSecurityGroupIngress',
  },
  {
    value: 'AuthorizeSnapshotAccess',
    str: 'AuthorizeSnapshotAccess',
  },
  {
    value: 'BatchCreatePartition',
    str: 'BatchCreatePartition',
  },
  {
    value: 'BatchCreateUser',
    str: 'BatchCreateUser',
  },
  {
    value: 'BatchDeleteBuilds',
    str: 'BatchDeleteBuilds',
  },
  {
    value: 'BatchDeleteConnection',
    str: 'BatchDeleteConnection',
  },
  {
    value: 'BatchDeletePartition',
    str: 'BatchDeletePartition',
  },
  {
    value: 'BatchDeleteTable',
    str: 'BatchDeleteTable',
  },
  {
    value: 'BatchGetApplicationRevisions',
    str: 'BatchGetApplicationRevisions',
  },
  {
    value: 'BatchGetApplications',
    str: 'BatchGetApplications',
  },
  {
    value: 'BatchGetBuilds',
    str: 'BatchGetBuilds',
  },
  {
    value: 'BatchGetDeploymentGroups',
    str: 'BatchGetDeploymentGroups',
  },
  {
    value: 'BatchGetDeploymentInstances',
    str: 'BatchGetDeploymentInstances',
  },
  {
    value: 'BatchGetDeployments',
    str: 'BatchGetDeployments',
  },
  {
    value: 'BatchGetNamedQuery',
    str: 'BatchGetNamedQuery',
  },
  {
    value: 'BatchGetOnPremisesInstances',
    str: 'BatchGetOnPremisesInstances',
  },
  {
    value: 'BatchGetPartition',
    str: 'BatchGetPartition',
  },
  {
    value: 'BatchGetProjects',
    str: 'BatchGetProjects',
  },
  {
    value: 'BatchGetQueryExecution',
    str: 'BatchGetQueryExecution',
  },
  {
    value: 'BatchGetRepositories',
    str: 'BatchGetRepositories',
  },
  {
    value: 'BatchGetUsers',
    str: 'BatchGetUsers',
  },
  {
    value: 'BatchMeterUsage',
    str: 'BatchMeterUsage',
  },
  {
    value: 'BatchPutIntent',
    str: 'BatchPutIntent',
  },
  {
    value: 'BatchResendUserInvite',
    str: 'BatchResendUserInvite',
  },
  {
    value: 'BatchStopJobRun',
    str: 'BatchStopJobRun',
  },
  {
    value: 'BatchTransferDomain',
    str: 'BatchTransferDomain',
  },
  {
    value: 'BindGitHubAccountTokenToApplication',
    str: 'BindGitHubAccountTokenToApplication',
  },
  {
    value: 'BuildSuggesters',
    str: 'BuildSuggesters',
  },
  {
    value: 'BulkPublish',
    str: 'BulkPublish',
  },
  {
    value: 'BundleInstance',
    str: 'BundleInstance',
  },
  {
    value: 'CancelActivation',
    str: 'CancelActivation',
  },
  {
    value: 'CancelArchival',
    str: 'CancelArchival',
  },
  {
    value: 'CancelAuditTask',
    str: 'CancelAuditTask',
  },
  {
    value: 'CancelBundleTask',
    str: 'CancelBundleTask',
  },
  {
    value: 'CancelCertificateTransfer',
    str: 'CancelCertificateTransfer',
  },
  {
    value: 'CancelCommand',
    str: 'CancelCommand',
  },
  {
    value: 'CancelConversionTask',
    str: 'CancelConversionTask',
  },
  {
    value: 'CancelExportTask',
    str: 'CancelExportTask',
  },
  {
    value: 'CancelHandshake',
    str: 'CancelHandshake',
  },
  {
    value: 'CancelImportTask',
    str: 'CancelImportTask',
  },
  {
    value: 'CancelJob',
    str: 'CancelJob',
  },
  {
    value: 'CancelPipelineReprocessing',
    str: 'CancelPipelineReprocessing',
  },
  {
    value: 'CancelReservedInstancesListing',
    str: 'CancelReservedInstancesListing',
  },
  {
    value: 'CancelRetrieval',
    str: 'CancelRetrieval',
  },
  {
    value: 'CancelRfc',
    str: 'CancelRfc',
  },
  {
    value: 'CancelRotateSecret',
    str: 'CancelRotateSecret',
  },
  {
    value: 'CancelScheduledInstanceRequest',
    str: 'CancelScheduledInstanceRequest',
  },
  {
    value: 'CancelSchemaExtension',
    str: 'CancelSchemaExtension',
  },
  {
    value: 'CancelSecretVersionDelete',
    str: 'CancelSecretVersionDelete',
  },
  {
    value: 'CancelSpotFleetRequests',
    str: 'CancelSpotFleetRequests',
  },
  {
    value: 'CancelSpotInstanceRequests',
    str: 'CancelSpotInstanceRequests',
  },
  {
    value: 'CancelUpdateStack',
    str: 'CancelUpdateStack',
  },
  {
    value: 'ChangeDomainOwner',
    str: 'ChangeDomainOwner',
  },
  {
    value: 'ChangePassword',
    str: 'ChangePassword',
  },
  {
    value: 'ChangeResourceRecordSets',
    str: 'ChangeResourceRecordSets',
  },
  {
    value: 'ChangeTagsForResource',
    str: 'ChangeTagsForResource',
  },
  {
    value: 'CheckAlias',
    str: 'CheckAlias',
  },
  {
    value: 'CheckAvailabilities',
    str: 'CheckAvailabilities',
  },
  {
    value: 'CheckDNSAvailability',
    str: 'CheckDNSAvailability',
  },
  {
    value: 'CheckDomainTransferability',
    str: 'CheckDomainTransferability',
  },
  {
    value: 'CheckMfa',
    str: 'CheckMfa',
  },
  {
    value: 'ClearDefaultAuthorizer',
    str: 'ClearDefaultAuthorizer',
  },
  {
    value: 'CloneStack',
    str: 'CloneStack',
  },
  {
    value: 'CloseAccount',
    str: 'CloseAccount',
  },
  {
    value: 'CompleteDocumentVersionUpload',
    str: 'CompleteDocumentVersionUpload',
  },
  {
    value: 'CompleteLifecycleAction',
    str: 'CompleteLifecycleAction',
  },
  {
    value: 'CompleteMultipartUpload',
    str: 'CompleteMultipartUpload',
  },
  {
    value: 'CompleteVaultLock',
    str: 'CompleteVaultLock',
  },
  {
    value: 'ConfigureHealthCheck',
    str: 'ConfigureHealthCheck',
  },
  {
    value: 'ConfirmConnection',
    str: 'ConfirmConnection',
  },
  {
    value: 'ConfirmPrivateVirtualInterface',
    str: 'ConfirmPrivateVirtualInterface',
  },
  {
    value: 'ConfirmProductInstance',
    str: 'ConfirmProductInstance',
  },
  {
    value: 'ConfirmPublicVirtualInterface',
    str: 'ConfirmPublicVirtualInterface',
  },
  {
    value: 'ConnectDirectory',
    str: 'ConnectDirectory',
  },
  {
    value: 'ConsoleLogin',
    str: 'ConsoleLogin',
  },
  {
    value: 'ContinueDeployment',
    str: 'ContinueDeployment',
  },
  {
    value: 'ContinueUpdateRollback',
    str: 'ContinueUpdateRollback',
  },
  {
    value: 'CopyBackupToRegion',
    str: 'CopyBackupToRegion',
  },
  {
    value: 'CopyClusterSnapshot',
    str: 'CopyClusterSnapshot',
  },
  {
    value: 'CopyDbClusterParameterGroup',
    str: 'CopyDbClusterParameterGroup',
  },
  {
    value: 'CopyDBClusterSnapshot',
    str: 'CopyDBClusterSnapshot',
  },
  {
    value: 'CopyDBParameterGroup',
    str: 'CopyDBParameterGroup',
  },
  {
    value: 'CopyDBSnapshot',
    str: 'CopyDBSnapshot',
  },
  {
    value: 'CopyImage',
    str: 'CopyImage',
  },
  {
    value: 'CopyOptionGroup',
    str: 'CopyOptionGroup',
  },
  {
    value: 'CopySnapshot',
    str: 'CopySnapshot',
  },
  {
    value: 'CreateAccessKey',
    str: 'CreateAccessKey',
  },
  {
    value: 'CreateAccount',
    str: 'CreateAccount',
  },
  {
    value: 'CreateAccountAlias',
    str: 'CreateAccountAlias',
  },
  {
    value: 'CreateAccountMapping',
    str: 'CreateAccountMapping',
  },
  {
    value: 'CreateActivity',
    str: 'CreateActivity',
  },
  {
    value: 'CreateAddressBook',
    str: 'CreateAddressBook',
  },
  {
    value: 'CreateAlias',
    str: 'CreateAlias',
  },
  {
    value: 'CreateAlias20150331',
    str: 'CreateAlias20150331',
  },
  {
    value: 'CreateAnalysis',
    str: 'CreateAnalysis',
  },
  {
    value: 'CreateAnnotation',
    str: 'CreateAnnotation',
  },
  {
    value: 'CreateApiKey',
    str: 'CreateApiKey',
  },
  {
    value: 'CreateApp',
    str: 'CreateApp',
  },
  {
    value: 'CreateAppCookieStickinessPolicy',
    str: 'CreateAppCookieStickinessPolicy',
  },
  {
    value: 'CreateApplication',
    str: 'CreateApplication',
  },
  {
    value: 'CreateApplicationInstance',
    str: 'CreateApplicationInstance',
  },
  {
    value: 'CreateApplicationInstanceCertificate',
    str: 'CreateApplicationInstanceCertificate',
  },
  {
    value: 'CreateApplicationVersion',
    str: 'CreateApplicationVersion',
  },
  {
    value: 'CreateAssessmentTarget',
    str: 'CreateAssessmentTarget',
  },
  {
    value: 'CreateAssessmentTemplate',
    str: 'CreateAssessmentTemplate',
  },
  {
    value: 'CreateAssociation',
    str: 'CreateAssociation',
  },
  {
    value: 'CreateAssociationBatch',
    str: 'CreateAssociationBatch',
  },
  {
    value: 'CreateAuthorizer',
    str: 'CreateAuthorizer',
  },
  {
    value: 'CreateAutoScalingGroup',
    str: 'CreateAutoScalingGroup',
  },
  {
    value: 'CreateBackup',
    str: 'CreateBackup',
  },
  {
    value: 'CreateBasePathMapping',
    str: 'CreateBasePathMapping',
  },
  {
    value: 'CreateBatchPrediction',
    str: 'CreateBatchPrediction',
  },
  {
    value: 'CreateBotChannelAssociation',
    str: 'CreateBotChannelAssociation',
  },
  {
    value: 'CreateBotVersion',
    str: 'CreateBotVersion',
  },
  {
    value: 'CreateBranch',
    str: 'CreateBranch',
  },
  {
    value: 'CreateBroker',
    str: 'CreateBroker',
  },
  {
    value: 'CreateBucket',
    str: 'CreateBucket',
  },
  {
    value: 'CreateBuild',
    str: 'CreateBuild',
  },
  {
    value: 'CreateByteMatchSet',
    str: 'CreateByteMatchSet',
  },
  {
    value: 'CreateCacheCluster',
    str: 'CreateCacheCluster',
  },
  {
    value: 'CreateCachediSCSIVolume',
    str: 'CreateCachediSCSIVolume',
  },
  {
    value: 'CreateCacheParameterGroup',
    str: 'CreateCacheParameterGroup',
  },
  {
    value: 'CreateCacheSecurityGroup',
    str: 'CreateCacheSecurityGroup',
  },
  {
    value: 'CreateCacheSubnetGroup',
    str: 'CreateCacheSubnetGroup',
  },
  {
    value: 'CreateCampaign',
    str: 'CreateCampaign',
  },
  {
    value: 'CreateCase',
    str: 'CreateCase',
  },
  {
    value: 'CreateCDRBucket',
    str: 'CreateCDRBucket',
  },
  {
    value: 'CreateCertificateAuthority',
    str: 'CreateCertificateAuthority',
  },
  {
    value: 'CreateCertificateAuthorityAuditReport',
    str: 'CreateCertificateAuthorityAuditReport',
  },
  {
    value: 'CreateCertificateFromCSR',
    str: 'CreateCertificateFromCSR',
  },
  {
    value: 'CreateChangeSet',
    str: 'CreateChangeSet',
  },
  {
    value: 'CreateChannel',
    str: 'CreateChannel',
  },
  {
    value: 'CreateClassifier',
    str: 'CreateClassifier',
  },
  {
    value: 'CreateCloudFormationChangeSet',
    str: 'CreateCloudFormationChangeSet',
  },
  {
    value: 'CreateCloudFrontOriginAccessIdentity',
    str: 'CreateCloudFrontOriginAccessIdentity',
  },
  {
    value: 'CreateCluster',
    str: 'CreateCluster',
  },
  {
    value: 'CreateClusterParameterGroup',
    str: 'CreateClusterParameterGroup',
  },
  {
    value: 'CreateClusterSecurityGroup',
    str: 'CreateClusterSecurityGroup',
  },
  {
    value: 'CreateClusterSnapshot',
    str: 'CreateClusterSnapshot',
  },
  {
    value: 'CreateClusterSubnetGroup',
    str: 'CreateClusterSubnetGroup',
  },
  {
    value: 'CreateCollection',
    str: 'CreateCollection',
  },
  {
    value: 'CreateComment',
    str: 'CreateComment',
  },
  {
    value: 'CreateComputeEnvironment',
    str: 'CreateComputeEnvironment',
  },
  {
    value: 'CreateComputer',
    str: 'CreateComputer',
  },
  {
    value: 'CreateConditionalForwarder',
    str: 'CreateConditionalForwarder',
  },
  {
    value: 'CreateConferenceAppliance',
    str: 'CreateConferenceAppliance',
  },
  {
    value: 'CreateConfiguration',
    str: 'CreateConfiguration',
  },
  {
    value: 'CreateConfigurationTemplate',
    str: 'CreateConfigurationTemplate',
  },
  {
    value: 'CreateConnection',
    str: 'CreateConnection',
  },
  {
    value: 'CreateConstraint',
    str: 'CreateConstraint',
  },
  {
    value: 'CreateContact',
    str: 'CreateContact',
  },
  {
    value: 'CreateContainer',
    str: 'CreateContainer',
  },
  {
    value: 'CreateCrawler',
    str: 'CreateCrawler',
  },
  {
    value: 'CreateCustomActionType',
    str: 'CreateCustomActionType',
  },
  {
    value: 'CreateCustomerGateway',
    str: 'CreateCustomerGateway',
  },
  {
    value: 'CreateDashboard',
    str: 'CreateDashboard',
  },
  {
    value: 'CreateDatabase',
    str: 'CreateDatabase',
  },
  {
    value: 'CreateDataSet',
    str: 'CreateDataSet',
  },
  {
    value: 'CreateDataset',
    str: 'CreateDataset',
  },
  {
    value: 'CreateDatasetContent',
    str: 'CreateDatasetContent',
  },
  {
    value: 'CreateDataSource',
    str: 'CreateDataSource',
  },
  {
    value: 'CreateDataSourceFromRDS',
    str: 'CreateDataSourceFromRDS',
  },
  {
    value: 'CreateDataSourceFromRedshift',
    str: 'CreateDataSourceFromRedshift',
  },
  {
    value: 'CreateDataSourceFromS3',
    str: 'CreateDataSourceFromS3',
  },
  {
    value: 'CreateDatastore',
    str: 'CreateDatastore',
  },
  {
    value: 'CreateDBCluster',
    str: 'CreateDBCluster',
  },
  {
    value: 'CreateDBClusterParameterGroup',
    str: 'CreateDBClusterParameterGroup',
  },
  {
    value: 'CreateDBClusterSnapshot',
    str: 'CreateDBClusterSnapshot',
  },
  {
    value: 'CreateDBInstance',
    str: 'CreateDBInstance',
  },
  {
    value: 'CreateDBInstanceReadReplica',
    str: 'CreateDBInstanceReadReplica',
  },
  {
    value: 'CreateDBParameterGroup',
    str: 'CreateDBParameterGroup',
  },
  {
    value: 'CreateDBSecurityGroup',
    str: 'CreateDBSecurityGroup',
  },
  {
    value: 'CreateDBSnapshot',
    str: 'CreateDBSnapshot',
  },
  {
    value: 'CreateDBSubnetGroup',
    str: 'CreateDBSubnetGroup',
  },
  {
    value: 'CreateDefaultSubnet',
    str: 'CreateDefaultSubnet',
  },
  {
    value: 'CreateDefaultVpc',
    str: 'CreateDefaultVpc',
  },
  {
    value: 'CreateDeliveryStream',
    str: 'CreateDeliveryStream',
  },
  {
    value: 'CreateDeployment',
    str: 'CreateDeployment',
  },
  {
    value: 'CreateDeploymentConfig',
    str: 'CreateDeploymentConfig',
  },
  {
    value: 'CreateDeploymentGroup',
    str: 'CreateDeploymentGroup',
  },
  {
    value: 'CreateDetector',
    str: 'CreateDetector',
  },
  {
    value: 'CreateDevEndpoint',
    str: 'CreateDevEndpoint',
  },
  {
    value: 'CreateDhcpOptions',
    str: 'CreateDhcpOptions',
  },
  {
    value: 'CreateDirectConnectGateway',
    str: 'CreateDirectConnectGateway',
  },
  {
    value: 'CreateDirectConnectGatewayAssociation',
    str: 'CreateDirectConnectGatewayAssociation',
  },
  {
    value: 'CreateDirectConnectGatewayAssociationProposal',
    str: 'CreateDirectConnectGatewayAssociationProposal',
  },
  {
    value: 'CreateDirectory',
    str: 'CreateDirectory',
  },
  {
    value: 'CreateDiskFromSnapshot',
    str: 'CreateDiskFromSnapshot',
  },
  {
    value: 'CreateDistribution',
    str: 'CreateDistribution',
  },
  {
    value: 'CreateDocument',
    str: 'CreateDocument',
  },
  {
    value: 'CreateDocumentationPart',
    str: 'CreateDocumentationPart',
  },
  {
    value: 'CreateDocumentationVersion',
    str: 'CreateDocumentationVersion',
  },
  {
    value: 'CreateDomain',
    str: 'CreateDomain',
  },
  {
    value: 'CreateDomainEntry',
    str: 'CreateDomainEntry',
  },
  {
    value: 'CreateDomainName',
    str: 'CreateDomainName',
  },
  {
    value: 'CreateEgressOnlyInternetGateway',
    str: 'CreateEgressOnlyInternetGateway',
  },
  {
    value: 'CreateElasticsearchDomain',
    str: 'CreateElasticsearchDomain',
  },
  {
    value: 'CreateElasticsearchServiceRole',
    str: 'CreateElasticsearchServiceRole',
  },
  {
    value: 'CreateEndpoint',
    str: 'CreateEndpoint',
  },
  {
    value: 'CreateEndpointConfig',
    str: 'CreateEndpointConfig',
  },
  {
    value: 'CreateEnrollmentToken',
    str: 'CreateEnrollmentToken',
  },
  {
    value: 'CreateEnvironment',
    str: 'CreateEnvironment',
  },
  {
    value: 'CreateEvaluation',
    str: 'CreateEvaluation',
  },
  {
    value: 'CreateEventSourceMapping20150331',
    str: 'CreateEventSourceMapping20150331',
  },
  {
    value: 'CreateEventSubscription',
    str: 'CreateEventSubscription',
  },
  {
    value: 'CreateExportJob',
    str: 'CreateExportJob',
  },
  {
    value: 'CreateExportTask',
    str: 'CreateExportTask',
  },
  {
    value: 'CreateFacet',
    str: 'CreateFacet',
  },
  {
    value: 'CreateFeedbackRequest',
    str: 'CreateFeedbackRequest',
  },
  {
    value: 'CreateFileSystem',
    str: 'CreateFileSystem',
  },
  {
    value: 'CreateFilter',
    str: 'CreateFilter',
  },
  {
    value: 'CreateFleet',
    str: 'CreateFleet',
  },
  {
    value: 'CreateFlowLogs',
    str: 'CreateFlowLogs',
  },
  {
    value: 'CreateFolder',
    str: 'CreateFolder',
  },
  {
    value: 'CreateFolderShare',
    str: 'CreateFolderShare',
  },
  {
    value: 'CreateFunction20150331',
    str: 'CreateFunction20150331',
  },
  {
    value: 'CreateGameSession',
    str: 'CreateGameSession',
  },
  {
    value: 'CreateGameSessionQueue',
    str: 'CreateGameSessionQueue',
  },
  {
    value: 'CreateGraphqlApi',
    str: 'CreateGraphqlApi',
  },
  {
    value: 'CreateGroup',
    str: 'CreateGroup',
  },
  {
    value: 'CreateHapg',
    str: 'CreateHapg',
  },
  {
    value: 'CreateHealthCheck',
    str: 'CreateHealthCheck',
  },
  {
    value: 'CreateHostedZone',
    str: 'CreateHostedZone',
  },
  {
    value: 'CreateHsm',
    str: 'CreateHsm',
  },
  {
    value: 'CreateHsmClientCertificate',
    str: 'CreateHsmClientCertificate',
  },
  {
    value: 'CreateHsmConfiguration',
    str: 'CreateHsmConfiguration',
  },
  {
    value: 'CreateHyperParameterTuningJob',
    str: 'CreateHyperParameterTuningJob',
  },
  {
    value: 'CreateIdentityPool',
    str: 'CreateIdentityPool',
  },
  {
    value: 'CreateImage',
    str: 'CreateImage',
  },
  {
    value: 'CreateImportJob',
    str: 'CreateImportJob',
  },
  {
    value: 'CreateInstance',
    str: 'CreateInstance',
  },
  {
    value: 'CreateInstanceExportTask',
    str: 'CreateInstanceExportTask',
  },
  {
    value: 'CreateInstanceProfile',
    str: 'CreateInstanceProfile',
  },
  {
    value: 'CreateInstances',
    str: 'CreateInstances',
  },
  {
    value: 'CreateInstancesFromSnapshot',
    str: 'CreateInstancesFromSnapshot',
  },
  {
    value: 'CreateInstanceSnapshot',
    str: 'CreateInstanceSnapshot',
  },
  {
    value: 'CreateIntentVersion',
    str: 'CreateIntentVersion',
  },
  {
    value: 'CreateInterconnect',
    str: 'CreateInterconnect',
  },
  {
    value: 'CreateInternetGateway',
    str: 'CreateInternetGateway',
  },
  {
    value: 'CreateInvalidation',
    str: 'CreateInvalidation',
  },
  {
    value: 'CreateIPSet',
    str: 'CreateIPSet',
  },
  {
    value: 'CreateJob',
    str: 'CreateJob',
  },
  {
    value: 'CreateJobQueue',
    str: 'CreateJobQueue',
  },
  {
    value: 'CreateJobTemplate',
    str: 'CreateJobTemplate',
  },
  {
    value: 'CreateKeyPair',
    str: 'CreateKeyPair',
  },
  {
    value: 'CreateKeysAndCertificate',
    str: 'CreateKeysAndCertificate',
  },
  {
    value: 'CreateLag',
    str: 'CreateLag',
  },
  {
    value: 'CreateLaunchConfiguration',
    str: 'CreateLaunchConfiguration',
  },
  {
    value: 'CreateLayer',
    str: 'CreateLayer',
  },
  {
    value: 'CreateLBCookieStickinessPolicy',
    str: 'CreateLBCookieStickinessPolicy',
  },
  {
    value: 'CreateListener',
    str: 'CreateListener',
  },
  {
    value: 'CreateLoadBalancer',
    str: 'CreateLoadBalancer',
  },
  {
    value: 'CreateLoadBalancerListeners',
    str: 'CreateLoadBalancerListeners',
  },
  {
    value: 'CreateLoadBalancerPolicy',
    str: 'CreateLoadBalancerPolicy',
  },
  {
    value: 'CreateLoadBalancerTlsCertificate',
    str: 'CreateLoadBalancerTlsCertificate',
  },
  {
    value: 'CreateLogGroup',
    str: 'CreateLogGroup',
  },
  {
    value: 'CreateLoginProfile',
    str: 'CreateLoginProfile',
  },
  {
    value: 'CreateLogStream',
    str: 'CreateLogStream',
  },
  {
    value: 'CreateLunaClient',
    str: 'CreateLunaClient',
  },
  {
    value: 'CreateMailUser',
    str: 'CreateMailUser',
  },
  {
    value: 'CreateMaintenanceWindow',
    str: 'CreateMaintenanceWindow',
  },
  {
    value: 'CreateMatchmakingConfiguration',
    str: 'CreateMatchmakingConfiguration',
  },
  {
    value: 'CreateMatchmakingRuleSet',
    str: 'CreateMatchmakingRuleSet',
  },
  {
    value: 'CreateMembers',
    str: 'CreateMembers',
  },
  {
    value: 'CreateMicrosoftAD',
    str: 'CreateMicrosoftAD',
  },
  {
    value: 'CreateMLModel',
    str: 'CreateMLModel',
  },
  {
    value: 'CreateModel',
    str: 'CreateModel',
  },
  {
    value: 'CreateMountTarget',
    str: 'CreateMountTarget',
  },
  {
    value: 'CreateNamedQuery',
    str: 'CreateNamedQuery',
  },
  {
    value: 'CreateNatGateway',
    str: 'CreateNatGateway',
  },
  {
    value: 'CreateNetworkAcl',
    str: 'CreateNetworkAcl',
  },
  {
    value: 'CreateNetworkAclEntry',
    str: 'CreateNetworkAclEntry',
  },
  {
    value: 'CreateNetworkInterface',
    str: 'CreateNetworkInterface',
  },
  {
    value: 'CreateNfsFileShare',
    str: 'CreateNfsFileShare',
  },
  {
    value: 'CreateNotebookInstance',
    str: 'CreateNotebookInstance',
  },
  {
    value: 'CreateNotebookInstanceLifecycleConfig',
    str: 'CreateNotebookInstanceLifecycleConfig',
  },
  {
    value: 'CreateNotificationTopic',
    str: 'CreateNotificationTopic',
  },
  {
    value: 'CreateOpenIDConnectProvider',
    str: 'CreateOpenIDConnectProvider',
  },
  {
    value: 'CreateOptionGroup',
    str: 'CreateOptionGroup',
  },
  {
    value: 'CreateOrganization',
    str: 'CreateOrganization',
  },
  {
    value: 'CreateOrganizationalUnit',
    str: 'CreateOrganizationalUnit',
  },
  {
    value: 'CreateOrigamiReportPreference',
    str: 'CreateOrigamiReportPreference',
  },
  {
    value: 'CreateOrUpdateScalingTrigger',
    str: 'CreateOrUpdateScalingTrigger',
  },
  {
    value: 'CreateOrUpdateTags',
    str: 'CreateOrUpdateTags',
  },
  {
    value: 'CreateOTAUpdate',
    str: 'CreateOTAUpdate',
  },
  {
    value: 'CreateParameterGroup',
    str: 'CreateParameterGroup',
  },
  {
    value: 'CreatePartition',
    str: 'CreatePartition',
  },
  {
    value: 'CreatePatchBaseline',
    str: 'CreatePatchBaseline',
  },
  {
    value: 'CreatePermission',
    str: 'CreatePermission',
  },
  {
    value: 'CreatePermissionSet',
    str: 'CreatePermissionSet',
  },
  {
    value: 'CreatePipeline',
    str: 'CreatePipeline',
  },
  {
    value: 'CreatePlacementGroup',
    str: 'CreatePlacementGroup',
  },
  {
    value: 'CreatePlayerSession',
    str: 'CreatePlayerSession',
  },
  {
    value: 'CreatePlayerSessions',
    str: 'CreatePlayerSessions',
  },
  {
    value: 'CreatePolicy',
    str: 'CreatePolicy',
  },
  {
    value: 'CreatePolicyVersion',
    str: 'CreatePolicyVersion',
  },
  {
    value: 'CreatePortfolio',
    str: 'CreatePortfolio',
  },
  {
    value: 'CreatePortfolioShare',
    str: 'CreatePortfolioShare',
  },
  {
    value: 'CreatePreset',
    str: 'CreatePreset',
  },
  {
    value: 'CreatePresignedNotebookInstanceUrl',
    str: 'CreatePresignedNotebookInstanceUrl',
  },
  {
    value: 'CreatePrivateDnsNamespace',
    str: 'CreatePrivateDnsNamespace',
  },
  {
    value: 'CreatePrivateVirtualInterface',
    str: 'CreatePrivateVirtualInterface',
  },
  {
    value: 'CreateProduct',
    str: 'CreateProduct',
  },
  {
    value: 'CreateProfile',
    str: 'CreateProfile',
  },
  {
    value: 'CreateProgressUpdateStream',
    str: 'CreateProgressUpdateStream',
  },
  {
    value: 'CreateProject',
    str: 'CreateProject',
  },
  {
    value: 'CreateProtection',
    str: 'CreateProtection',
  },
  {
    value: 'CreateProvisioningArtifact',
    str: 'CreateProvisioningArtifact',
  },
  {
    value: 'CreatePublicDnsNamespace',
    str: 'CreatePublicDnsNamespace',
  },
  {
    value: 'CreatePublicVirtualInterface',
    str: 'CreatePublicVirtualInterface',
  },
  {
    value: 'CreateQueue',
    str: 'CreateQueue',
  },
  {
    value: 'CreateRealtimeEndpoint',
    str: 'CreateRealtimeEndpoint',
  },
  {
    value: 'CreateReplicationGroup',
    str: 'CreateReplicationGroup',
  },
  {
    value: 'CreateReplicationInstance',
    str: 'CreateReplicationInstance',
  },
  {
    value: 'CreateReplicationJob',
    str: 'CreateReplicationJob',
  },
  {
    value: 'CreateReplicationSubnetGroup',
    str: 'CreateReplicationSubnetGroup',
  },
  {
    value: 'CreateReplicationTask',
    str: 'CreateReplicationTask',
  },
  {
    value: 'CreateRepository',
    str: 'CreateRepository',
  },
  {
    value: 'CreateRequestValidator',
    str: 'CreateRequestValidator',
  },
  {
    value: 'CreateReservedInstancesListing',
    str: 'CreateReservedInstancesListing',
  },
  {
    value: 'CreateResolver',
    str: 'CreateResolver',
  },
  {
    value: 'CreateResource',
    str: 'CreateResource',
  },
  {
    value: 'CreateResourceDataSync',
    str: 'CreateResourceDataSync',
  },
  {
    value: 'CreateResourceGroup',
    str: 'CreateResourceGroup',
  },
  {
    value: 'CreateRestApi',
    str: 'CreateRestApi',
  },
  {
    value: 'CreateReusableDelegationSet',
    str: 'CreateReusableDelegationSet',
  },
  {
    value: 'CreateRfc',
    str: 'CreateRfc',
  },
  {
    value: 'CreateRole',
    str: 'CreateRole',
  },
  {
    value: 'CreateRoleAlias',
    str: 'CreateRoleAlias',
  },
  {
    value: 'CreateRoom',
    str: 'CreateRoom',
  },
  {
    value: 'CreateRoute',
    str: 'CreateRoute',
  },
  {
    value: 'CreateRouteTable',
    str: 'CreateRouteTable',
  },
  {
    value: 'CreateRule',
    str: 'CreateRule',
  },
  {
    value: 'CreateSAMLProvider',
    str: 'CreateSAMLProvider',
  },
  {
    value: 'CreateSampleFindings',
    str: 'CreateSampleFindings',
  },
  {
    value: 'CreateScheduledAudit',
    str: 'CreateScheduledAudit',
  },
  {
    value: 'CreateSchema',
    str: 'CreateSchema',
  },
  {
    value: 'CreateScript',
    str: 'CreateScript',
  },
  {
    value: 'CreateSecret',
    str: 'CreateSecret',
  },
  {
    value: 'CreateSecurityConfiguration',
    str: 'CreateSecurityConfiguration',
  },
  {
    value: 'CreateSecurityGroup',
    str: 'CreateSecurityGroup',
  },
  {
    value: 'CreateSecurityProfile',
    str: 'CreateSecurityProfile',
  },
  {
    value: 'CreateSegment',
    str: 'CreateSegment',
  },
  {
    value: 'CreateServer',
    str: 'CreateServer',
  },
  {
    value: 'CreateService',
    str: 'CreateService',
  },
  {
    value: 'CreateServiceLinkedRole',
    str: 'CreateServiceLinkedRole',
  },
  {
    value: 'CreateServiceSpecificCredential',
    str: 'CreateServiceSpecificCredential',
  },
  {
    value: 'CreateShare',
    str: 'CreateShare',
  },
  {
    value: 'CreateSizeConstraintSet',
    str: 'CreateSizeConstraintSet',
  },
  {
    value: 'CreateSkillGroup',
    str: 'CreateSkillGroup',
  },
  {
    value: 'CreateSlotTypeVersion',
    str: 'CreateSlotTypeVersion',
  },
  {
    value: 'CreateSnapshot',
    str: 'CreateSnapshot',
  },
  {
    value: 'CreateSnapshotFromVolumeRecoveryPoint',
    str: 'CreateSnapshotFromVolumeRecoveryPoint',
  },
  {
    value: 'CreateSoftwareConfiguration',
    str: 'CreateSoftwareConfiguration',
  },
  {
    value: 'CreateSpiceCapacity',
    str: 'CreateSpiceCapacity',
  },
  {
    value: 'CreateSpotDatafeedSubscription',
    str: 'CreateSpotDatafeedSubscription',
  },
  {
    value: 'CreateSqlInjectionMatchSet',
    str: 'CreateSqlInjectionMatchSet',
  },
  {
    value: 'CreateStack',
    str: 'CreateStack',
  },
  {
    value: 'CreateStackInstances',
    str: 'CreateStackInstances',
  },
  {
    value: 'CreateStackSet',
    str: 'CreateStackSet',
  },
  {
    value: 'CreateStage',
    str: 'CreateStage',
  },
  {
    value: 'CreateStateMachine',
    str: 'CreateStateMachine',
  },
  {
    value: 'CreateStorageLocation',
    str: 'CreateStorageLocation',
  },
  {
    value: 'CreateStorediSCSIVolume',
    str: 'CreateStorediSCSIVolume',
  },
  {
    value: 'CreateStream',
    str: 'CreateStream',
  },
  {
    value: 'CreateStreamingDistribution',
    str: 'CreateStreamingDistribution',
  },
  {
    value: 'CreateStreamProcessor',
    str: 'CreateStreamProcessor',
  },
  {
    value: 'CreateSubnet',
    str: 'CreateSubnet',
  },
  {
    value: 'CreateSubnetGroup',
    str: 'CreateSubnetGroup',
  },
  {
    value: 'CreateSubscription',
    str: 'CreateSubscription',
  },
  {
    value: 'CreateTable',
    str: 'CreateTable',
  },
  {
    value: 'CreateTags',
    str: 'CreateTags',
  },
  {
    value: 'CreateTapes',
    str: 'CreateTapes',
  },
  {
    value: 'CreateTargetGroup',
    str: 'CreateTargetGroup',
  },
  {
    value: 'CreateThing',
    str: 'CreateThing',
  },
  {
    value: 'CreateThingGroup',
    str: 'CreateThingGroup',
  },
  {
    value: 'CreateThingType',
    str: 'CreateThingType',
  },
  {
    value: 'CreateThreatIntelSet',
    str: 'CreateThreatIntelSet',
  },
  {
    value: 'CreateTopicRule',
    str: 'CreateTopicRule',
  },
  {
    value: 'CreateTrafficPolicy',
    str: 'CreateTrafficPolicy',
  },
  {
    value: 'CreateTrafficPolicyInstance',
    str: 'CreateTrafficPolicyInstance',
  },
  {
    value: 'CreateTrafficPolicyVersion',
    str: 'CreateTrafficPolicyVersion',
  },
  {
    value: 'CreateTrail',
    str: 'CreateTrail',
  },
  {
    value: 'CreateTrainingJob',
    str: 'CreateTrainingJob',
  },
  {
    value: 'CreateTransfer',
    str: 'CreateTransfer',
  },
  {
    value: 'CreateTrigger',
    str: 'CreateTrigger',
  },
  {
    value: 'CreateTrust',
    str: 'CreateTrust',
  },
  {
    value: 'CreateType',
    str: 'CreateType',
  },
  {
    value: 'CreateTypedLinkFacet',
    str: 'CreateTypedLinkFacet',
  },
  {
    value: 'CreateUsagePlan',
    str: 'CreateUsagePlan',
  },
  {
    value: 'CreateUsagePlanKey',
    str: 'CreateUsagePlanKey',
  },
  {
    value: 'CreateUser',
    str: 'CreateUser',
  },
  {
    value: 'CreateUserDefinedFunction',
    str: 'CreateUserDefinedFunction',
  },
  {
    value: 'CreateUserPool',
    str: 'CreateUserPool',
  },
  {
    value: 'CreateUserPoolClient',
    str: 'CreateUserPoolClient',
  },
  {
    value: 'CreateUserProfile',
    str: 'CreateUserProfile',
  },
  {
    value: 'CreateVault',
    str: 'CreateVault',
  },
  {
    value: 'CreateVirtualMFADevice',
    str: 'CreateVirtualMFADevice',
  },
  {
    value: 'CreateVolume',
    str: 'CreateVolume',
  },
  {
    value: 'CreateVpc',
    str: 'CreateVpc',
  },
  {
    value: 'CreateVpcEndpoint',
    str: 'CreateVpcEndpoint',
  },
  {
    value: 'CreateVpcLink',
    str: 'CreateVpcLink',
  },
  {
    value: 'CreateVpcPeeringAuthorization',
    str: 'CreateVpcPeeringAuthorization',
  },
  {
    value: 'CreateVpcPeeringConnection',
    str: 'CreateVpcPeeringConnection',
  },
  {
    value: 'CreateVpnConnection',
    str: 'CreateVpnConnection',
  },
  {
    value: 'CreateVpnConnectionRoute',
    str: 'CreateVpnConnectionRoute',
  },
  {
    value: 'CreateVpnGateway',
    str: 'CreateVpnGateway',
  },
  {
    value: 'CreateWebACL',
    str: 'CreateWebACL',
  },
  {
    value: 'CreateWebhook',
    str: 'CreateWebhook',
  },
  {
    value: 'CreateWorkspaces',
    str: 'CreateWorkspaces',
  },
  {
    value: 'CreateXssMatchSet',
    str: 'CreateXssMatchSet',
  },
  {
    value: 'DeactivateMFADevice',
    str: 'DeactivateMFADevice',
  },
  {
    value: 'DeactivatePipeline',
    str: 'DeactivatePipeline',
  },
  {
    value: 'DeactivateUser',
    str: 'DeactivateUser',
  },
  {
    value: 'DeclineHandshake',
    str: 'DeclineHandshake',
  },
  {
    value: 'DeclineInvitations',
    str: 'DeclineInvitations',
  },
  {
    value: 'DecreaseReplicationFactor',
    str: 'DecreaseReplicationFactor',
  },
  {
    value: 'DecreaseStreamRetentionPeriod',
    str: 'DecreaseStreamRetentionPeriod',
  },
  {
    value: 'DefineAnalysisScheme',
    str: 'DefineAnalysisScheme',
  },
  {
    value: 'DefineExpression',
    str: 'DefineExpression',
  },
  {
    value: 'DefineIndexField',
    str: 'DefineIndexField',
  },
  {
    value: 'DefineIndexFields',
    str: 'DefineIndexFields',
  },
  {
    value: 'DefineRankExpression',
    str: 'DefineRankExpression',
  },
  {
    value: 'DefineSuggester',
    str: 'DefineSuggester',
  },
  {
    value: 'DeleteAccessKey',
    str: 'DeleteAccessKey',
  },
  {
    value: 'DeleteAccount',
    str: 'DeleteAccount',
  },
  {
    value: 'DeleteAccountAlias',
    str: 'DeleteAccountAlias',
  },
  {
    value: 'DeleteAccountAuditConfiguration',
    str: 'DeleteAccountAuditConfiguration',
  },
  {
    value: 'DeleteAccountMapping',
    str: 'DeleteAccountMapping',
  },
  {
    value: 'DeleteAccountOpenIdConfig',
    str: 'DeleteAccountOpenIdConfig',
  },
  {
    value: 'DeleteAccountPasswordPolicy',
    str: 'DeleteAccountPasswordPolicy',
  },
  {
    value: 'DeleteActivation',
    str: 'DeleteActivation',
  },
  {
    value: 'DeleteActivity',
    str: 'DeleteActivity',
  },
  {
    value: 'DeleteAddressBook',
    str: 'DeleteAddressBook',
  },
  {
    value: 'DeleteAdmChannel',
    str: 'DeleteAdmChannel',
  },
  {
    value: 'DeleteAlarms',
    str: 'DeleteAlarms',
  },
  {
    value: 'DeleteAlias',
    str: 'DeleteAlias',
  },
  {
    value: 'DeleteAlias20150331',
    str: 'DeleteAlias20150331',
  },
  {
    value: 'DeleteAllResourcePermissions',
    str: 'DeleteAllResourcePermissions',
  },
  {
    value: 'DeleteAllTagsForDomain',
    str: 'DeleteAllTagsForDomain',
  },
  {
    value: 'DeleteAnalysis',
    str: 'DeleteAnalysis',
  },
  {
    value: 'DeleteAnalysisScheme',
    str: 'DeleteAnalysisScheme',
  },
  {
    value: 'DeleteAnnotation',
    str: 'DeleteAnnotation',
  },
  {
    value: 'DeleteApiKey',
    str: 'DeleteApiKey',
  },
  {
    value: 'DeleteApnsChannel',
    str: 'DeleteApnsChannel',
  },
  {
    value: 'DeleteApnsSandboxChannel',
    str: 'DeleteApnsSandboxChannel',
  },
  {
    value: 'DeleteApp',
    str: 'DeleteApp',
  },
  {
    value: 'DeleteApplianceGroup',
    str: 'DeleteApplianceGroup',
  },
  {
    value: 'DeleteApplication',
    str: 'DeleteApplication',
  },
  {
    value: 'DeleteApplicationInstance',
    str: 'DeleteApplicationInstance',
  },
  {
    value: 'DeleteApplicationInstanceCertificate',
    str: 'DeleteApplicationInstanceCertificate',
  },
  {
    value: 'DeleteApplicationVersion',
    str: 'DeleteApplicationVersion',
  },
  {
    value: 'DeleteArchive',
    str: 'DeleteArchive',
  },
  {
    value: 'DeleteAssessmentRun',
    str: 'DeleteAssessmentRun',
  },
  {
    value: 'DeleteAssessmentTarget',
    str: 'DeleteAssessmentTarget',
  },
  {
    value: 'DeleteAssessmentTemplate',
    str: 'DeleteAssessmentTemplate',
  },
  {
    value: 'DeleteAssociation',
    str: 'DeleteAssociation',
  },
  {
    value: 'DeleteAttributes',
    str: 'DeleteAttributes',
  },
  {
    value: 'DeleteAuthorizer',
    str: 'DeleteAuthorizer',
  },
  {
    value: 'DeleteAutoScalingGroup',
    str: 'DeleteAutoScalingGroup',
  },
  {
    value: 'DeleteBackup',
    str: 'DeleteBackup',
  },
  {
    value: 'DeleteBaiduChannel',
    str: 'DeleteBaiduChannel',
  },
  {
    value: 'DeleteBandwidthRateLimit',
    str: 'DeleteBandwidthRateLimit',
  },
  {
    value: 'DeleteBasePathMapping',
    str: 'DeleteBasePathMapping',
  },
  {
    value: 'DeleteBatchPrediction',
    str: 'DeleteBatchPrediction',
  },
  {
    value: 'DeleteBot',
    str: 'DeleteBot',
  },
  {
    value: 'DeleteBotAlias',
    str: 'DeleteBotAlias',
  },
  {
    value: 'DeleteBotChannelAssociation',
    str: 'DeleteBotChannelAssociation',
  },
  {
    value: 'DeleteBotVersion',
    str: 'DeleteBotVersion',
  },
  {
    value: 'DeleteBroker',
    str: 'DeleteBroker',
  },
  {
    value: 'DeleteBucket',
    str: 'DeleteBucket',
  },
  {
    value: 'DeleteBucketCors',
    str: 'DeleteBucketCors',
  },
  {
    value: 'DeleteBucketEncryption',
    str: 'DeleteBucketEncryption',
  },
  {
    value: 'DeleteBucketLifecycle',
    str: 'DeleteBucketLifecycle',
  },
  {
    value: 'DeleteBucketPolicy',
    str: 'DeleteBucketPolicy',
  },
  {
    value: 'DeleteBucketReplication',
    str: 'DeleteBucketReplication',
  },
  {
    value: 'DeleteBucketTagging',
    str: 'DeleteBucketTagging',
  },
  {
    value: 'DeleteBucketWebsite',
    str: 'DeleteBucketWebsite',
  },
  {
    value: 'DeleteBuild',
    str: 'DeleteBuild',
  },
  {
    value: 'DeleteByteMatchSet',
    str: 'DeleteByteMatchSet',
  },
  {
    value: 'DeleteCACertificate',
    str: 'DeleteCACertificate',
  },
  {
    value: 'DeleteCacheCluster',
    str: 'DeleteCacheCluster',
  },
  {
    value: 'DeleteCacheParameterGroup',
    str: 'DeleteCacheParameterGroup',
  },
  {
    value: 'DeleteCacheSecurityGroup',
    str: 'DeleteCacheSecurityGroup',
  },
  {
    value: 'DeleteCacheSubnetGroup',
    str: 'DeleteCacheSubnetGroup',
  },
  {
    value: 'DeleteCampaign',
    str: 'DeleteCampaign',
  },
  {
    value: 'DeleteCDRBucket',
    str: 'DeleteCDRBucket',
  },
  {
    value: 'DeleteCertificate',
    str: 'DeleteCertificate',
  },
  {
    value: 'DeleteCertificateAuthority',
    str: 'DeleteCertificateAuthority',
  },
  {
    value: 'DeleteChangeSet',
    str: 'DeleteChangeSet',
  },
  {
    value: 'DeleteChannel',
    str: 'DeleteChannel',
  },
  {
    value: 'DeleteChapCredentials',
    str: 'DeleteChapCredentials',
  },
  {
    value: 'DeleteClassifier',
    str: 'DeleteClassifier',
  },
  {
    value: 'DeleteClientCertificate',
    str: 'DeleteClientCertificate',
  },
  {
    value: 'DeleteCloudFrontOriginAccessIdentity',
    str: 'DeleteCloudFrontOriginAccessIdentity',
  },
  {
    value: 'DeleteCluster',
    str: 'DeleteCluster',
  },
  {
    value: 'DeleteClusterParameterGroup',
    str: 'DeleteClusterParameterGroup',
  },
  {
    value: 'DeleteClusterSecurityGroup',
    str: 'DeleteClusterSecurityGroup',
  },
  {
    value: 'DeleteClusterSnapshot',
    str: 'DeleteClusterSnapshot',
  },
  {
    value: 'DeleteClusterSubnetGroup',
    str: 'DeleteClusterSubnetGroup',
  },
  {
    value: 'DeleteCollection',
    str: 'DeleteCollection',
  },
  {
    value: 'DeleteComment',
    str: 'DeleteComment',
  },
  {
    value: 'DeleteComputeEnvironment',
    str: 'DeleteComputeEnvironment',
  },
  {
    value: 'DeleteConditionalForwarder',
    str: 'DeleteConditionalForwarder',
  },
  {
    value: 'DeleteConferenceAppliance',
    str: 'DeleteConferenceAppliance',
  },
  {
    value: 'DeleteConfigRule',
    str: 'DeleteConfigRule',
  },
  {
    value: 'DeleteConfigurationRecorder',
    str: 'DeleteConfigurationRecorder',
  },
  {
    value: 'DeleteConfigurationTemplate',
    str: 'DeleteConfigurationTemplate',
  },
  {
    value: 'DeleteConnection',
    str: 'DeleteConnection',
  },
  {
    value: 'DeleteConstraint',
    str: 'DeleteConstraint',
  },
  {
    value: 'DeleteContact',
    str: 'DeleteContact',
  },
  {
    value: 'DeleteContainer',
    str: 'DeleteContainer',
  },
  {
    value: 'DeleteContainerPolicy',
    str: 'DeleteContainerPolicy',
  },
  {
    value: 'DeleteCorsPolicy',
    str: 'DeleteCorsPolicy',
  },
  {
    value: 'DeleteCrawler',
    str: 'DeleteCrawler',
  },
  {
    value: 'DeleteCustomActionType',
    str: 'DeleteCustomActionType',
  },
  {
    value: 'DeleteCustomerGateway',
    str: 'DeleteCustomerGateway',
  },
  {
    value: 'DeleteDashboard',
    str: 'DeleteDashboard',
  },
  {
    value: 'DeleteDashboards',
    str: 'DeleteDashboards',
  },
  {
    value: 'DeleteDashboardsForAccount',
    str: 'DeleteDashboardsForAccount',
  },
  {
    value: 'DeleteDatabase',
    str: 'DeleteDatabase',
  },
  {
    value: 'DeleteDataSet',
    str: 'DeleteDataSet',
  },
  {
    value: 'DeleteDataset',
    str: 'DeleteDataset',
  },
  {
    value: 'DeleteDatasetContent',
    str: 'DeleteDatasetContent',
  },
  {
    value: 'DeleteDataSource',
    str: 'DeleteDataSource',
  },
  {
    value: 'DeleteDatastore',
    str: 'DeleteDatastore',
  },
  {
    value: 'DeleteDBCluster',
    str: 'DeleteDBCluster',
  },
  {
    value: 'DeleteDBClusterParameterGroup',
    str: 'DeleteDBClusterParameterGroup',
  },
  {
    value: 'DeleteDBClusterSnapshot',
    str: 'DeleteDBClusterSnapshot',
  },
  {
    value: 'DeleteDBInstance',
    str: 'DeleteDBInstance',
  },
  {
    value: 'DeleteDBParameterGroup',
    str: 'DeleteDBParameterGroup',
  },
  {
    value: 'DeleteDBSecurityGroup',
    str: 'DeleteDBSecurityGroup',
  },
  {
    value: 'DeleteDBSnapshot',
    str: 'DeleteDBSnapshot',
  },
  {
    value: 'DeleteDBSubnetGroup',
    str: 'DeleteDBSubnetGroup',
  },
  {
    value: 'DeleteDelegate',
    str: 'DeleteDelegate',
  },
  {
    value: 'DeleteDeliveryChannel',
    str: 'DeleteDeliveryChannel',
  },
  {
    value: 'DeleteDeliveryStream',
    str: 'DeleteDeliveryStream',
  },
  {
    value: 'DeleteDeployment',
    str: 'DeleteDeployment',
  },
  {
    value: 'DeleteDeploymentConfig',
    str: 'DeleteDeploymentConfig',
  },
  {
    value: 'DeleteDeploymentGroup',
    str: 'DeleteDeploymentGroup',
  },
  {
    value: 'DeleteDestination',
    str: 'DeleteDestination',
  },
  {
    value: 'DeleteDetector',
    str: 'DeleteDetector',
  },
  {
    value: 'DeleteDevEndpoint',
    str: 'DeleteDevEndpoint',
  },
  {
    value: 'DeleteDhcpOptions',
    str: 'DeleteDhcpOptions',
  },
  {
    value: 'DeleteDirectConnectGateway',
    str: 'DeleteDirectConnectGateway',
  },
  {
    value: 'DeleteDirectConnectGatewayAssociation',
    str: 'DeleteDirectConnectGatewayAssociation',
  },
  {
    value: 'DeleteDirectConnectGatewayAssociationProposal',
    str: 'DeleteDirectConnectGatewayAssociationProposal',
  },
  {
    value: 'DeleteDirectory',
    str: 'DeleteDirectory',
  },
  {
    value: 'DeleteDisk',
    str: 'DeleteDisk',
  },
  {
    value: 'DeleteDiskSnapshot',
    str: 'DeleteDiskSnapshot',
  },
  {
    value: 'DeleteDistribution',
    str: 'DeleteDistribution',
  },
  {
    value: 'DeleteDocument',
    str: 'DeleteDocument',
  },
  {
    value: 'DeleteDocumentationPart',
    str: 'DeleteDocumentationPart',
  },
  {
    value: 'DeleteDocumentationVersion',
    str: 'DeleteDocumentationVersion',
  },
  {
    value: 'DeleteDomain',
    str: 'DeleteDomain',
  },
  {
    value: 'DeleteDomainName',
    str: 'DeleteDomainName',
  },
  {
    value: 'DeleteEgressOnlyInternetGateway',
    str: 'DeleteEgressOnlyInternetGateway',
  },
  {
    value: 'DeleteElasticsearchDomain',
    str: 'DeleteElasticsearchDomain',
  },
  {
    value: 'DeleteEmailChannel',
    str: 'DeleteEmailChannel',
  },
  {
    value: 'DeleteEndpoint',
    str: 'DeleteEndpoint',
  },
  {
    value: 'DeleteEndpointConfig',
    str: 'DeleteEndpointConfig',
  },
  {
    value: 'DeleteEnvironmentConfiguration',
    str: 'DeleteEnvironmentConfiguration',
  },
  {
    value: 'DeleteEvaluation',
    str: 'DeleteEvaluation',
  },
  {
    value: 'DeleteEvaluationResults',
    str: 'DeleteEvaluationResults',
  },
  {
    value: 'DeleteEventSourceMapping20150331',
    str: 'DeleteEventSourceMapping20150331',
  },
  {
    value: 'DeleteEventStream',
    str: 'DeleteEventStream',
  },
  {
    value: 'DeleteEventSubscription',
    str: 'DeleteEventSubscription',
  },
  {
    value: 'DeleteExpression',
    str: 'DeleteExpression',
  },
  {
    value: 'DeleteFacet',
    str: 'DeleteFacet',
  },
  {
    value: 'DeleteFeedbackRequest',
    str: 'DeleteFeedbackRequest',
  },
  {
    value: 'DeleteFileShare',
    str: 'DeleteFileShare',
  },
  {
    value: 'DeleteFileSystem',
    str: 'DeleteFileSystem',
  },
  {
    value: 'DeleteFilter',
    str: 'DeleteFilter',
  },
  {
    value: 'DeleteFleet',
    str: 'DeleteFleet',
  },
  {
    value: 'DeleteFlowLogs',
    str: 'DeleteFlowLogs',
  },
  {
    value: 'DeleteFolder',
    str: 'DeleteFolder',
  },
  {
    value: 'DeleteFolderContent',
    str: 'DeleteFolderContent',
  },
  {
    value: 'DeleteFolderContents',
    str: 'DeleteFolderContents',
  },
  {
    value: 'DeleteFolderShare',
    str: 'DeleteFolderShare',
  },
  {
    value: 'DeleteFreeBusyConfiguration',
    str: 'DeleteFreeBusyConfiguration',
  },
  {
    value: 'DeleteFunction',
    str: 'DeleteFunction',
  },
  {
    value: 'DeleteFunction20150331',
    str: 'DeleteFunction20150331',
  },
  {
    value: 'DeleteFunctionConcurrency20171031',
    str: 'DeleteFunctionConcurrency20171031',
  },
  {
    value: 'DeleteGameSessionQueue',
    str: 'DeleteGameSessionQueue',
  },
  {
    value: 'DeleteGateway',
    str: 'DeleteGateway',
  },
  {
    value: 'DeleteGatewayResponse',
    str: 'DeleteGatewayResponse',
  },
  {
    value: 'DeleteGcmChannel',
    str: 'DeleteGcmChannel',
  },
  {
    value: 'DeleteGitHubAccountToken',
    str: 'DeleteGitHubAccountToken',
  },
  {
    value: 'DeleteGraphqlApi',
    str: 'DeleteGraphqlApi',
  },
  {
    value: 'DeleteGroup',
    str: 'DeleteGroup',
  },
  {
    value: 'DeleteGroupPolicy',
    str: 'DeleteGroupPolicy',
  },
  {
    value: 'DeleteGroups',
    str: 'DeleteGroups',
  },
  {
    value: 'DeleteHapg',
    str: 'DeleteHapg',
  },
  {
    value: 'DeleteHealthCheck',
    str: 'DeleteHealthCheck',
  },
  {
    value: 'DeleteHostedZone',
    str: 'DeleteHostedZone',
  },
  {
    value: 'DeleteHsm',
    str: 'DeleteHsm',
  },
  {
    value: 'DeleteHsmClientCertificate',
    str: 'DeleteHsmClientCertificate',
  },
  {
    value: 'DeleteHsmConfiguration',
    str: 'DeleteHsmConfiguration',
  },
  {
    value: 'DeleteIdentities',
    str: 'DeleteIdentities',
  },
  {
    value: 'DeleteIdentityPool',
    str: 'DeleteIdentityPool',
  },
  {
    value: 'DeleteIndexField',
    str: 'DeleteIndexField',
  },
  {
    value: 'DeleteInstance',
    str: 'DeleteInstance',
  },
  {
    value: 'DeleteInstanceProfile',
    str: 'DeleteInstanceProfile',
  },
  {
    value: 'DeleteInstanceSnapshot',
    str: 'DeleteInstanceSnapshot',
  },
  {
    value: 'DeleteIntegration',
    str: 'DeleteIntegration',
  },
  {
    value: 'DeleteIntegrationResponse',
    str: 'DeleteIntegrationResponse',
  },
  {
    value: 'DeleteIntent',
    str: 'DeleteIntent',
  },
  {
    value: 'DeleteIntentVersion',
    str: 'DeleteIntentVersion',
  },
  {
    value: 'DeleteInterconnect',
    str: 'DeleteInterconnect',
  },
  {
    value: 'DeleteInternetGateway',
    str: 'DeleteInternetGateway',
  },
  {
    value: 'DeleteInvitations',
    str: 'DeleteInvitations',
  },
  {
    value: 'DeleteIPSet',
    str: 'DeleteIPSet',
  },
  {
    value: 'DeleteJob',
    str: 'DeleteJob',
  },
  {
    value: 'DeleteJobQueue',
    str: 'DeleteJobQueue',
  },
  {
    value: 'DeleteJobTemplate',
    str: 'DeleteJobTemplate',
  },
  {
    value: 'DeleteKeyPair',
    str: 'DeleteKeyPair',
  },
  {
    value: 'DeleteLag',
    str: 'DeleteLag',
  },
  {
    value: 'DeleteLaunchConfiguration',
    str: 'DeleteLaunchConfiguration',
  },
  {
    value: 'DeleteLayer',
    str: 'DeleteLayer',
  },
  {
    value: 'DeleteLexicon',
    str: 'DeleteLexicon',
  },
  {
    value: 'DeleteLifecycleHook',
    str: 'DeleteLifecycleHook',
  },
  {
    value: 'DeleteListener',
    str: 'DeleteListener',
  },
  {
    value: 'DeleteLoadBalancer',
    str: 'DeleteLoadBalancer',
  },
  {
    value: 'DeleteLoadBalancerListeners',
    str: 'DeleteLoadBalancerListeners',
  },
  {
    value: 'DeleteLoadBalancerPolicy',
    str: 'DeleteLoadBalancerPolicy',
  },
  {
    value: 'DeleteLoadBalancerTlsCertificate',
    str: 'DeleteLoadBalancerTlsCertificate',
  },
  {
    value: 'DeleteLogGroup',
    str: 'DeleteLogGroup',
  },
  {
    value: 'DeleteLoginProfile',
    str: 'DeleteLoginProfile',
  },
  {
    value: 'DeleteLogStream',
    str: 'DeleteLogStream',
  },
  {
    value: 'DeleteLunaClient',
    str: 'DeleteLunaClient',
  },
  {
    value: 'DeleteMailDomain',
    str: 'DeleteMailDomain',
  },
  {
    value: 'DeleteMailFlowRule',
    str: 'DeleteMailFlowRule',
  },
  {
    value: 'DeleteMaintenanceWindow',
    str: 'DeleteMaintenanceWindow',
  },
  {
    value: 'DeleteMatchmakingConfiguration',
    str: 'DeleteMatchmakingConfiguration',
  },
  {
    value: 'DeleteMembers',
    str: 'DeleteMembers',
  },
  {
    value: 'DeleteMethod',
    str: 'DeleteMethod',
  },
  {
    value: 'DeleteMethodResponse',
    str: 'DeleteMethodResponse',
  },
  {
    value: 'DeleteMetricFilter',
    str: 'DeleteMetricFilter',
  },
  {
    value: 'DeleteMLModel',
    str: 'DeleteMLModel',
  },
  {
    value: 'DeleteModel',
    str: 'DeleteModel',
  },
  {
    value: 'DeleteMountTarget',
    str: 'DeleteMountTarget',
  },
  {
    value: 'DeleteNamedQuery',
    str: 'DeleteNamedQuery',
  },
  {
    value: 'DeleteNamespace',
    str: 'DeleteNamespace',
  },
  {
    value: 'DeleteNatGateway',
    str: 'DeleteNatGateway',
  },
  {
    value: 'DeleteNetworkAcl',
    str: 'DeleteNetworkAcl',
  },
  {
    value: 'DeleteNetworkAclEntry',
    str: 'DeleteNetworkAclEntry',
  },
  {
    value: 'DeleteNetworkInterface',
    str: 'DeleteNetworkInterface',
  },
  {
    value: 'DeleteNotebookInstance',
    str: 'DeleteNotebookInstance',
  },
  {
    value: 'DeleteNotebookInstanceLifecycleConfig',
    str: 'DeleteNotebookInstanceLifecycleConfig',
  },
  {
    value: 'DeleteNotificationConfiguration',
    str: 'DeleteNotificationConfiguration',
  },
  {
    value: 'DeleteNotificationTopic',
    str: 'DeleteNotificationTopic',
  },
  {
    value: 'DeleteOpenIDConnectProvider',
    str: 'DeleteOpenIDConnectProvider',
  },
  {
    value: 'DeleteOptionGroup',
    str: 'DeleteOptionGroup',
  },
  {
    value: 'DeleteOrganization',
    str: 'DeleteOrganization',
  },
  {
    value: 'DeleteOrganizationalUnit',
    str: 'DeleteOrganizationalUnit',
  },
  {
    value: 'DeleteOrganizationFolderShare',
    str: 'DeleteOrganizationFolderShare',
  },
  {
    value: 'DeleteOrganizationShare',
    str: 'DeleteOrganizationShare',
  },
  {
    value: 'DeleteOrigamiReportPreferences',
    str: 'DeleteOrigamiReportPreferences',
  },
  {
    value: 'DeleteOTAUpdate',
    str: 'DeleteOTAUpdate',
  },
  {
    value: 'DeleteParameter',
    str: 'DeleteParameter',
  },
  {
    value: 'DeleteParameterGroup',
    str: 'DeleteParameterGroup',
  },
  {
    value: 'DeleteParameters',
    str: 'DeleteParameters',
  },
  {
    value: 'DeletePartition',
    str: 'DeletePartition',
  },
  {
    value: 'DeletePatchBaseline',
    str: 'DeletePatchBaseline',
  },
  {
    value: 'DeletePermissionSet',
    str: 'DeletePermissionSet',
  },
  {
    value: 'DeletePermissionsPolicy',
    str: 'DeletePermissionsPolicy',
  },
  {
    value: 'DeletePipeline',
    str: 'DeletePipeline',
  },
  {
    value: 'DeletePlacementGroup',
    str: 'DeletePlacementGroup',
  },
  {
    value: 'DeletePolicy',
    str: 'DeletePolicy',
  },
  {
    value: 'DeletePolicyVersion',
    str: 'DeletePolicyVersion',
  },
  {
    value: 'DeletePortfolio',
    str: 'DeletePortfolio',
  },
  {
    value: 'DeletePortfolioShare',
    str: 'DeletePortfolioShare',
  },
  {
    value: 'DeletePreset',
    str: 'DeletePreset',
  },
  {
    value: 'DeleteProduct',
    str: 'DeleteProduct',
  },
  {
    value: 'DeleteProfile',
    str: 'DeleteProfile',
  },
  {
    value: 'DeleteProgressUpdateStream',
    str: 'DeleteProgressUpdateStream',
  },
  {
    value: 'DeleteProject',
    str: 'DeleteProject',
  },
  {
    value: 'DeleteProjectSnapshot',
    str: 'DeleteProjectSnapshot',
  },
  {
    value: 'DeleteProtection',
    str: 'DeleteProtection',
  },
  {
    value: 'DeleteProvisioningArtifact',
    str: 'DeleteProvisioningArtifact',
  },
  {
    value: 'DeleteQueue',
    str: 'DeleteQueue',
  },
  {
    value: 'DeleteRankExpression',
    str: 'DeleteRankExpression',
  },
  {
    value: 'DeleteRealtimeEndpoint',
    str: 'DeleteRealtimeEndpoint',
  },
  {
    value: 'DeleteRegistrationCode',
    str: 'DeleteRegistrationCode',
  },
  {
    value: 'DeleteReplicationGroup',
    str: 'DeleteReplicationGroup',
  },
  {
    value: 'DeleteReplicationInstance',
    str: 'DeleteReplicationInstance',
  },
  {
    value: 'DeleteReplicationJob',
    str: 'DeleteReplicationJob',
  },
  {
    value: 'DeleteReplicationSubnetGroup',
    str: 'DeleteReplicationSubnetGroup',
  },
  {
    value: 'DeleteReplicationTask',
    str: 'DeleteReplicationTask',
  },
  {
    value: 'DeleteRepository',
    str: 'DeleteRepository',
  },
  {
    value: 'DeleteRequestValidator',
    str: 'DeleteRequestValidator',
  },
  {
    value: 'DeleteResolver',
    str: 'DeleteResolver',
  },
  {
    value: 'DeleteResource',
    str: 'DeleteResource',
  },
  {
    value: 'DeleteResourceDataSync',
    str: 'DeleteResourceDataSync',
  },
  {
    value: 'DeleteResourcePermission',
    str: 'DeleteResourcePermission',
  },
  {
    value: 'DeleteResourcePolicy',
    str: 'DeleteResourcePolicy',
  },
  {
    value: 'DeleteResources',
    str: 'DeleteResources',
  },
  {
    value: 'DeleteRestApi',
    str: 'DeleteRestApi',
  },
  {
    value: 'DeleteRetentionPolicy',
    str: 'DeleteRetentionPolicy',
  },
  {
    value: 'DeleteReusableDelegationSet',
    str: 'DeleteReusableDelegationSet',
  },
  {
    value: 'DeleteRole',
    str: 'DeleteRole',
  },
  {
    value: 'DeleteRoleAlias',
    str: 'DeleteRoleAlias',
  },
  {
    value: 'DeleteRolePolicy',
    str: 'DeleteRolePolicy',
  },
  {
    value: 'DeleteRoom',
    str: 'DeleteRoom',
  },
  {
    value: 'DeleteRoomSkillParameter',
    str: 'DeleteRoomSkillParameter',
  },
  {
    value: 'DeleteRoute',
    str: 'DeleteRoute',
  },
  {
    value: 'DeleteRouteTable',
    str: 'DeleteRouteTable',
  },
  {
    value: 'DeleteRule',
    str: 'DeleteRule',
  },
  {
    value: 'DeleteSAMLProvider',
    str: 'DeleteSAMLProvider',
  },
  {
    value: 'DeleteScheduledAction',
    str: 'DeleteScheduledAction',
  },
  {
    value: 'DeleteScheduledAudit',
    str: 'DeleteScheduledAudit',
  },
  {
    value: 'DeleteSchema',
    str: 'DeleteSchema',
  },
  {
    value: 'DeleteSecret',
    str: 'DeleteSecret',
  },
  {
    value: 'DeleteSecurityConfiguration',
    str: 'DeleteSecurityConfiguration',
  },
  {
    value: 'DeleteSecurityGroup',
    str: 'DeleteSecurityGroup',
  },
  {
    value: 'DeleteSecurityProfile',
    str: 'DeleteSecurityProfile',
  },
  {
    value: 'DeleteSegment',
    str: 'DeleteSegment',
  },
  {
    value: 'DeleteServer',
    str: 'DeleteServer',
  },
  {
    value: 'DeleteServerCatalog',
    str: 'DeleteServerCatalog',
  },
  {
    value: 'DeleteServerCertificate',
    str: 'DeleteServerCertificate',
  },
  {
    value: 'DeleteService',
    str: 'DeleteService',
  },
  {
    value: 'DeleteServiceLinkedRole',
    str: 'DeleteServiceLinkedRole',
  },
  {
    value: 'DeleteServiceRoleForLexBots',
    str: 'DeleteServiceRoleForLexBots',
  },
  {
    value: 'DeleteServiceRoleForLexChannels',
    str: 'DeleteServiceRoleForLexChannels',
  },
  {
    value: 'DeleteServiceSpecificCredential',
    str: 'DeleteServiceSpecificCredential',
  },
  {
    value: 'DeleteShare',
    str: 'DeleteShare',
  },
  {
    value: 'DeleteSigningCertificate',
    str: 'DeleteSigningCertificate',
  },
  {
    value: 'DeleteSizeConstraintSet',
    str: 'DeleteSizeConstraintSet',
  },
  {
    value: 'DeleteSkillGroup',
    str: 'DeleteSkillGroup',
  },
  {
    value: 'DeleteSlotType',
    str: 'DeleteSlotType',
  },
  {
    value: 'DeleteSlotTypeVersion',
    str: 'DeleteSlotTypeVersion',
  },
  {
    value: 'DeleteSmsChannel',
    str: 'DeleteSmsChannel',
  },
  {
    value: 'DeleteSnapshot',
    str: 'DeleteSnapshot',
  },
  {
    value: 'DeleteSnapshotSchedule',
    str: 'DeleteSnapshotSchedule',
  },
  {
    value: 'DeleteSoftwareConfiguration',
    str: 'DeleteSoftwareConfiguration',
  },
  {
    value: 'DeleteSpiceCapacity',
    str: 'DeleteSpiceCapacity',
  },
  {
    value: 'DeleteSpotDatafeedSubscription',
    str: 'DeleteSpotDatafeedSubscription',
  },
  {
    value: 'DeleteSqlInjectionMatchSet',
    str: 'DeleteSqlInjectionMatchSet',
  },
  {
    value: 'DeleteSSHPublicKey',
    str: 'DeleteSSHPublicKey',
  },
  {
    value: 'DeleteStack',
    str: 'DeleteStack',
  },
  {
    value: 'DeleteStackInstances',
    str: 'DeleteStackInstances',
  },
  {
    value: 'DeleteStackSet',
    str: 'DeleteStackSet',
  },
  {
    value: 'DeleteStage',
    str: 'DeleteStage',
  },
  {
    value: 'DeleteStateMachine',
    str: 'DeleteStateMachine',
  },
  {
    value: 'DeleteStream',
    str: 'DeleteStream',
  },
  {
    value: 'DeleteStreamingDistribution',
    str: 'DeleteStreamingDistribution',
  },
  {
    value: 'DeleteStreamProcessor',
    str: 'DeleteStreamProcessor',
  },
  {
    value: 'DeleteSubnet',
    str: 'DeleteSubnet',
  },
  {
    value: 'DeleteSubnetGroup',
    str: 'DeleteSubnetGroup',
  },
  {
    value: 'DeleteSubscription',
    str: 'DeleteSubscription',
  },
  {
    value: 'DeleteSubscriptionFilter',
    str: 'DeleteSubscriptionFilter',
  },
  {
    value: 'DeleteSuggester',
    str: 'DeleteSuggester',
  },
  {
    value: 'DeleteTable',
    str: 'DeleteTable',
  },
  {
    value: 'DeleteTags',
    str: 'DeleteTags',
  },
  {
    value: 'DeleteTagsForDomain',
    str: 'DeleteTagsForDomain',
  },
  {
    value: 'DeleteTape',
    str: 'DeleteTape',
  },
  {
    value: 'DeleteTapeArchive',
    str: 'DeleteTapeArchive',
  },
  {
    value: 'DeleteTargetGroup',
    str: 'DeleteTargetGroup',
  },
  {
    value: 'DeleteThing',
    str: 'DeleteThing',
  },
  {
    value: 'DeleteThingGroup',
    str: 'DeleteThingGroup',
  },
  {
    value: 'DeleteThingType',
    str: 'DeleteThingType',
  },
  {
    value: 'DeleteThreatIntelSet',
    str: 'DeleteThreatIntelSet',
  },
  {
    value: 'DeleteTopicRule',
    str: 'DeleteTopicRule',
  },
  {
    value: 'DeleteTrafficPolicy',
    str: 'DeleteTrafficPolicy',
  },
  {
    value: 'DeleteTrafficPolicyInstance',
    str: 'DeleteTrafficPolicyInstance',
  },
  {
    value: 'DeleteTrail',
    str: 'DeleteTrail',
  },
  {
    value: 'DeleteTrigger',
    str: 'DeleteTrigger',
  },
  {
    value: 'DeleteTrust',
    str: 'DeleteTrust',
  },
  {
    value: 'DeleteType',
    str: 'DeleteType',
  },
  {
    value: 'DeleteTypedLinkFacet',
    str: 'DeleteTypedLinkFacet',
  },
  {
    value: 'DeleteUsagePlan',
    str: 'DeleteUsagePlan',
  },
  {
    value: 'DeleteUsagePlanKey',
    str: 'DeleteUsagePlanKey',
  },
  {
    value: 'DeleteUser',
    str: 'DeleteUser',
  },
  {
    value: 'DeleteUserDefinedFunction',
    str: 'DeleteUserDefinedFunction',
  },
  {
    value: 'DeleteUserPolicy',
    str: 'DeleteUserPolicy',
  },
  {
    value: 'DeleteUserPool',
    str: 'DeleteUserPool',
  },
  {
    value: 'DeleteUserPoolClient',
    str: 'DeleteUserPoolClient',
  },
  {
    value: 'DeleteUserProfile',
    str: 'DeleteUserProfile',
  },
  {
    value: 'DeleteUtterances',
    str: 'DeleteUtterances',
  },
  {
    value: 'DeleteV2LoggingLevel',
    str: 'DeleteV2LoggingLevel',
  },
  {
    value: 'DeleteVault',
    str: 'DeleteVault',
  },
  {
    value: 'DeleteVaultAccessPolicy',
    str: 'DeleteVaultAccessPolicy',
  },
  {
    value: 'DeleteVaultNotifications',
    str: 'DeleteVaultNotifications',
  },
  {
    value: 'DeleteVirtualInterface',
    str: 'DeleteVirtualInterface',
  },
  {
    value: 'DeleteVirtualMFADevice',
    str: 'DeleteVirtualMFADevice',
  },
  {
    value: 'DeleteVolume',
    str: 'DeleteVolume',
  },
  {
    value: 'DeleteVpc',
    str: 'DeleteVpc',
  },
  {
    value: 'DeleteVpcEndpoints',
    str: 'DeleteVpcEndpoints',
  },
  {
    value: 'DeleteVpcLink',
    str: 'DeleteVpcLink',
  },
  {
    value: 'DeleteVpcPeeringAuthorization',
    str: 'DeleteVpcPeeringAuthorization',
  },
  {
    value: 'DeleteVpcPeeringConnection',
    str: 'DeleteVpcPeeringConnection',
  },
  {
    value: 'DeleteVpnConnection',
    str: 'DeleteVpnConnection',
  },
  {
    value: 'DeleteVpnConnectionRoute',
    str: 'DeleteVpnConnectionRoute',
  },
  {
    value: 'DeleteVpnGateway',
    str: 'DeleteVpnGateway',
  },
  {
    value: 'DeleteWebACL',
    str: 'DeleteWebACL',
  },
  {
    value: 'DeleteWebhook',
    str: 'DeleteWebhook',
  },
  {
    value: 'DeleteXssMatchSet',
    str: 'DeleteXssMatchSet',
  },
  {
    value: 'DeliverConfigSnapshot',
    str: 'DeliverConfigSnapshot',
  },
  {
    value: 'DeprecateActivityType',
    str: 'DeprecateActivityType',
  },
  {
    value: 'DeprecateDomain',
    str: 'DeprecateDomain',
  },
  {
    value: 'DeprecateThingType',
    str: 'DeprecateThingType',
  },
  {
    value: 'DeprecateWorkflowType',
    str: 'DeprecateWorkflowType',
  },
  {
    value: 'DeregisterContainerInstance',
    str: 'DeregisterContainerInstance',
  },
  {
    value: 'DeregisterDirectory',
    str: 'DeregisterDirectory',
  },
  {
    value: 'DeregisterEcsCluster',
    str: 'DeregisterEcsCluster',
  },
  {
    value: 'DeregisterElasticIp',
    str: 'DeregisterElasticIp',
  },
  {
    value: 'DeregisterEventTopic',
    str: 'DeregisterEventTopic',
  },
  {
    value: 'DeregisterFromWorkMail',
    str: 'DeregisterFromWorkMail',
  },
  {
    value: 'DeregisterImage',
    str: 'DeregisterImage',
  },
  {
    value: 'DeregisterInstance',
    str: 'DeregisterInstance',
  },
  {
    value: 'DeregisterInstancesFromLoadBalancer',
    str: 'DeregisterInstancesFromLoadBalancer',
  },
  {
    value: 'DeregisterJobDefinition',
    str: 'DeregisterJobDefinition',
  },
  {
    value: 'DeregisterManagedInstance',
    str: 'DeregisterManagedInstance',
  },
  {
    value: 'DeregisterOnPremisesInstance',
    str: 'DeregisterOnPremisesInstance',
  },
  {
    value: 'DeregisterPatchBaselineForPatchGroup',
    str: 'DeregisterPatchBaselineForPatchGroup',
  },
  {
    value: 'DeregisterRdsDbInstance',
    str: 'DeregisterRdsDbInstance',
  },
  {
    value: 'DeregisterStreamConsumer',
    str: 'DeregisterStreamConsumer',
  },
  {
    value: 'DeregisterTargetFromMaintenanceWindow',
    str: 'DeregisterTargetFromMaintenanceWindow',
  },
  {
    value: 'DeregisterTargets',
    str: 'DeregisterTargets',
  },
  {
    value: 'DeregisterTaskDefinition',
    str: 'DeregisterTaskDefinition',
  },
  {
    value: 'DeregisterTaskFromMaintenanceWindow',
    str: 'DeregisterTaskFromMaintenanceWindow',
  },
  {
    value: 'DeregisterVolume',
    str: 'DeregisterVolume',
  },
  {
    value: 'DeregisterWebhookWithThirdParty',
    str: 'DeregisterWebhookWithThirdParty',
  },
  {
    value: 'DescribeAccount',
    str: 'DescribeAccount',
  },
  {
    value: 'DescribeAccountAttributes',
    str: 'DescribeAccountAttributes',
  },
  {
    value: 'DescribeAccountAuditConfiguration',
    str: 'DescribeAccountAuditConfiguration',
  },
  {
    value: 'DescribeAccountLimits',
    str: 'DescribeAccountLimits',
  },
  {
    value: 'DescribeAccountsAttributes',
    str: 'DescribeAccountsAttributes',
  },
  {
    value: 'DescribeActivityType',
    str: 'DescribeActivityType',
  },
  {
    value: 'DescribeAddresses',
    str: 'DescribeAddresses',
  },
  {
    value: 'DescribeAdjustmentTypes',
    str: 'DescribeAdjustmentTypes',
  },
  {
    value: 'DescribeAffectedEntities',
    str: 'DescribeAffectedEntities',
  },
  {
    value: 'DescribeAgents',
    str: 'DescribeAgents',
  },
  {
    value: 'DescribeAgentVersions',
    str: 'DescribeAgentVersions',
  },
  {
    value: 'DescribeAlarmHistory',
    str: 'DescribeAlarmHistory',
  },
  {
    value: 'DescribeAlarms',
    str: 'DescribeAlarms',
  },
  {
    value: 'DescribeAlarmsForMetric',
    str: 'DescribeAlarmsForMetric',
  },
  {
    value: 'DescribeAlias',
    str: 'DescribeAlias',
  },
  {
    value: 'DescribeAnalysisSchemes',
    str: 'DescribeAnalysisSchemes',
  },
  {
    value: 'DescribeApplications',
    str: 'DescribeApplications',
  },
  {
    value: 'DescribeApplicationVersions',
    str: 'DescribeApplicationVersions',
  },
  {
    value: 'DescribeApps',
    str: 'DescribeApps',
  },
  {
    value: 'DescribeAssessmentRuns',
    str: 'DescribeAssessmentRuns',
  },
  {
    value: 'DescribeAssessmentTargets',
    str: 'DescribeAssessmentTargets',
  },
  {
    value: 'DescribeAssessmentTemplates',
    str: 'DescribeAssessmentTemplates',
  },
  {
    value: 'DescribeAssociation',
    str: 'DescribeAssociation',
  },
  {
    value: 'DescribeAttachment',
    str: 'DescribeAttachment',
  },
  {
    value: 'DescribeAttack',
    str: 'DescribeAttack',
  },
  {
    value: 'DescribeAuditTask',
    str: 'DescribeAuditTask',
  },
  {
    value: 'DescribeAuthorizer',
    str: 'DescribeAuthorizer',
  },
  {
    value: 'DescribeAutoScalingGroups',
    str: 'DescribeAutoScalingGroups',
  },
  {
    value: 'DescribeAutoScalingInstances',
    str: 'DescribeAutoScalingInstances',
  },
  {
    value: 'DescribeAutoScalingNotificationTypes',
    str: 'DescribeAutoScalingNotificationTypes',
  },
  {
    value: 'DescribeAvailabilityOptions',
    str: 'DescribeAvailabilityOptions',
  },
  {
    value: 'DescribeAvailabilityZones',
    str: 'DescribeAvailabilityZones',
  },
  {
    value: 'DescribeAvailableDirectories',
    str: 'DescribeAvailableDirectories',
  },
  {
    value: 'DescribeBackup',
    str: 'DescribeBackup',
  },
  {
    value: 'DescribeBackups',
    str: 'DescribeBackups',
  },
  {
    value: 'DescribeBandwidthRateLimit',
    str: 'DescribeBandwidthRateLimit',
  },
  {
    value: 'DescribeBroker',
    str: 'DescribeBroker',
  },
  {
    value: 'DescribeBuild',
    str: 'DescribeBuild',
  },
  {
    value: 'DescribeBundle',
    str: 'DescribeBundle',
  },
  {
    value: 'DescribeBundleTasks',
    str: 'DescribeBundleTasks',
  },
  {
    value: 'DescribeCACertificate',
    str: 'DescribeCACertificate',
  },
  {
    value: 'DescribeCache',
    str: 'DescribeCache',
  },
  {
    value: 'DescribeCacheClusters',
    str: 'DescribeCacheClusters',
  },
  {
    value: 'DescribeCachediSCSIVolumes',
    str: 'DescribeCachediSCSIVolumes',
  },
  {
    value: 'DescribeCacheEngineVersions',
    str: 'DescribeCacheEngineVersions',
  },
  {
    value: 'DescribeCacheParameterGroups',
    str: 'DescribeCacheParameterGroups',
  },
  {
    value: 'DescribeCacheParameters',
    str: 'DescribeCacheParameters',
  },
  {
    value: 'DescribeCacheSecurityGroups',
    str: 'DescribeCacheSecurityGroups',
  },
  {
    value: 'DescribeCacheSubnetGroups',
    str: 'DescribeCacheSubnetGroups',
  },
  {
    value: 'DescribeCases',
    str: 'DescribeCases',
  },
  {
    value: 'DescribeCertificate',
    str: 'DescribeCertificate',
  },
  {
    value: 'DescribeCertificateAuthority',
    str: 'DescribeCertificateAuthority',
  },
  {
    value: 'DescribeCertificateAuthorityAuditReport',
    str: 'DescribeCertificateAuthorityAuditReport',
  },
  {
    value: 'DescribeCertificates',
    str: 'DescribeCertificates',
  },
  {
    value: 'DescribeCertificateTag',
    str: 'DescribeCertificateTag',
  },
  {
    value: 'DescribeChannel',
    str: 'DescribeChannel',
  },
  {
    value: 'DescribeChapCredentials',
    str: 'DescribeChapCredentials',
  },
  {
    value: 'DescribeClassicLinkInstances',
    str: 'DescribeClassicLinkInstances',
  },
  {
    value: 'DescribeCluster',
    str: 'DescribeCluster',
  },
  {
    value: 'DescribeClusterParameterGroups',
    str: 'DescribeClusterParameterGroups',
  },
  {
    value: 'DescribeClusterParameters',
    str: 'DescribeClusterParameters',
  },
  {
    value: 'DescribeClusters',
    str: 'DescribeClusters',
  },
  {
    value: 'DescribeClusterSecurityGroups',
    str: 'DescribeClusterSecurityGroups',
  },
  {
    value: 'DescribeClusterSnapshots',
    str: 'DescribeClusterSnapshots',
  },
  {
    value: 'DescribeClusterSubnetGroups',
    str: 'DescribeClusterSubnetGroups',
  },
  {
    value: 'DescribeClusterVersions',
    str: 'DescribeClusterVersions',
  },
  {
    value: 'DescribeCommands',
    str: 'DescribeCommands',
  },
  {
    value: 'DescribeCommunications',
    str: 'DescribeCommunications',
  },
  {
    value: 'DescribeComplianceByConfigRule',
    str: 'DescribeComplianceByConfigRule',
  },
  {
    value: 'DescribeComplianceByResource',
    str: 'DescribeComplianceByResource',
  },
  {
    value: 'DescribeComputeEnvironments',
    str: 'DescribeComputeEnvironments',
  },
  {
    value: 'DescribeConditionalForwarders',
    str: 'DescribeConditionalForwarders',
  },
  {
    value: 'DescribeConfigRuleEvaluationStatus',
    str: 'DescribeConfigRuleEvaluationStatus',
  },
  {
    value: 'DescribeConfigRules',
    str: 'DescribeConfigRules',
  },
  {
    value: 'DescribeConfiguration',
    str: 'DescribeConfiguration',
  },
  {
    value: 'DescribeConfigurationOptions',
    str: 'DescribeConfigurationOptions',
  },
  {
    value: 'DescribeConfigurationRecorders',
    str: 'DescribeConfigurationRecorders',
  },
  {
    value: 'DescribeConfigurationRecorderStatus',
    str: 'DescribeConfigurationRecorderStatus',
  },
  {
    value: 'DescribeConfigurationRevision',
    str: 'DescribeConfigurationRevision',
  },
  {
    value: 'DescribeConfigurations',
    str: 'DescribeConfigurations',
  },
  {
    value: 'DescribeConfigurationSettings',
    str: 'DescribeConfigurationSettings',
  },
  {
    value: 'DescribeConnections',
    str: 'DescribeConnections',
  },
  {
    value: 'DescribeConnectionsOnInterconnect',
    str: 'DescribeConnectionsOnInterconnect',
  },
  {
    value: 'DescribeConstraint',
    str: 'DescribeConstraint',
  },
  {
    value: 'DescribeContainer',
    str: 'DescribeContainer',
  },
  {
    value: 'DescribeContainerInstances',
    str: 'DescribeContainerInstances',
  },
  {
    value: 'DescribeContinuousBackups',
    str: 'DescribeContinuousBackups',
  },
  {
    value: 'DescribeContinuousExports',
    str: 'DescribeContinuousExports',
  },
  {
    value: 'DescribeConversionTasks',
    str: 'DescribeConversionTasks',
  },
  {
    value: 'DescribeCreateAccountStatus',
    str: 'DescribeCreateAccountStatus',
  },
  {
    value: 'DescribeCrossAccountAccessRole',
    str: 'DescribeCrossAccountAccessRole',
  },
  {
    value: 'DescribeCustomerGateways',
    str: 'DescribeCustomerGateways',
  },
  {
    value: 'DescribeDataset',
    str: 'DescribeDataset',
  },
  {
    value: 'DescribeDatastore',
    str: 'DescribeDatastore',
  },
  {
    value: 'DescribeDBClusterParameterGroups',
    str: 'DescribeDBClusterParameterGroups',
  },
  {
    value: 'DescribeDBClusterParameters',
    str: 'DescribeDBClusterParameters',
  },
  {
    value: 'DescribeDBClusters',
    str: 'DescribeDBClusters',
  },
  {
    value: 'DescribeDbClusterSnapshotAttributes',
    str: 'DescribeDbClusterSnapshotAttributes',
  },
  {
    value: 'DescribeDBClusterSnapshots',
    str: 'DescribeDBClusterSnapshots',
  },
  {
    value: 'DescribeDBEngineVersions',
    str: 'DescribeDBEngineVersions',
  },
  {
    value: 'DescribeDBInstances',
    str: 'DescribeDBInstances',
  },
  {
    value: 'DescribeDBLogFiles',
    str: 'DescribeDBLogFiles',
  },
  {
    value: 'DescribeDBParameterGroups',
    str: 'DescribeDBParameterGroups',
  },
  {
    value: 'DescribeDBParameters',
    str: 'DescribeDBParameters',
  },
  {
    value: 'DescribeDBSecurityGroups',
    str: 'DescribeDBSecurityGroups',
  },
  {
    value: 'DescribeDBSnapshotAttributes',
    str: 'DescribeDBSnapshotAttributes',
  },
  {
    value: 'DescribeDBSnapshots',
    str: 'DescribeDBSnapshots',
  },
  {
    value: 'DescribeDBSubnetGroups',
    str: 'DescribeDBSubnetGroups',
  },
  {
    value: 'DescribeDefaultAuthorizer',
    str: 'DescribeDefaultAuthorizer',
  },
  {
    value: 'DescribeDefaultClusterParameters',
    str: 'DescribeDefaultClusterParameters',
  },
  {
    value: 'DescribeDefaultParameters',
    str: 'DescribeDefaultParameters',
  },
  {
    value: 'DescribeDefaultSearchField',
    str: 'DescribeDefaultSearchField',
  },
  {
    value: 'DescribeDeliveryChannels',
    str: 'DescribeDeliveryChannels',
  },
  {
    value: 'DescribeDeliveryChannelStatus',
    str: 'DescribeDeliveryChannelStatus',
  },
  {
    value: 'DescribeDeliveryStream',
    str: 'DescribeDeliveryStream',
  },
  {
    value: 'DescribeDeployments',
    str: 'DescribeDeployments',
  },
  {
    value: 'DescribeDestinations',
    str: 'DescribeDestinations',
  },
  {
    value: 'DescribeDevices',
    str: 'DescribeDevices',
  },
  {
    value: 'DescribeDhcpOptions',
    str: 'DescribeDhcpOptions',
  },
  {
    value: 'DescribeDirectConnectGatewayAssociationProposals',
    str: 'DescribeDirectConnectGatewayAssociationProposals',
  },
  {
    value: 'DescribeDirectConnectGatewayAssociations',
    str: 'DescribeDirectConnectGatewayAssociations',
  },
  {
    value: 'DescribeDirectConnectGatewayAttachments',
    str: 'DescribeDirectConnectGatewayAttachments',
  },
  {
    value: 'DescribeDirectConnectGateways',
    str: 'DescribeDirectConnectGateways',
  },
  {
    value: 'DescribeDirectories',
    str: 'DescribeDirectories',
  },
  {
    value: 'DescribeDirectoryMFA',
    str: 'DescribeDirectoryMFA',
  },
  {
    value: 'DescribeDocument',
    str: 'DescribeDocument',
  },
  {
    value: 'DescribeDomain',
    str: 'DescribeDomain',
  },
  {
    value: 'DescribeDomainControllers',
    str: 'DescribeDomainControllers',
  },
  {
    value: 'DescribeDomains',
    str: 'DescribeDomains',
  },
  {
    value: 'DescribeEC2InstanceLimits',
    str: 'DescribeEC2InstanceLimits',
  },
  {
    value: 'DescribeEcsClusters',
    str: 'DescribeEcsClusters',
  },
  {
    value: 'DescribeEgressOnlyInternetGateways',
    str: 'DescribeEgressOnlyInternetGateways',
  },
  {
    value: 'DescribeElasticIps',
    str: 'DescribeElasticIps',
  },
  {
    value: 'DescribeElasticLoadBalancers',
    str: 'DescribeElasticLoadBalancers',
  },
  {
    value: 'DescribeElasticsearchDomain',
    str: 'DescribeElasticsearchDomain',
  },
  {
    value: 'DescribeElasticsearchDomainConfig',
    str: 'DescribeElasticsearchDomainConfig',
  },
  {
    value: 'DescribeElasticsearchDomains',
    str: 'DescribeElasticsearchDomains',
  },
  {
    value: 'DescribeElasticsearchInstanceTypeLimits',
    str: 'DescribeElasticsearchInstanceTypeLimits',
  },
  {
    value: 'DescribeEndpoint',
    str: 'DescribeEndpoint',
  },
  {
    value: 'DescribeEndpointConfig',
    str: 'DescribeEndpointConfig',
  },
  {
    value: 'DescribeEndpoints',
    str: 'DescribeEndpoints',
  },
  {
    value: 'DescribeEndpointTypes',
    str: 'DescribeEndpointTypes',
  },
  {
    value: 'DescribeEngineDefaultClusterParameters',
    str: 'DescribeEngineDefaultClusterParameters',
  },
  {
    value: 'DescribeEngineDefaultParameters',
    str: 'DescribeEngineDefaultParameters',
  },
  {
    value: 'DescribeEnvironmentHealth',
    str: 'DescribeEnvironmentHealth',
  },
  {
    value: 'DescribeEnvironmentResources',
    str: 'DescribeEnvironmentResources',
  },
  {
    value: 'DescribeEnvironments',
    str: 'DescribeEnvironments',
  },
  {
    value: 'DescribeEventAggregates',
    str: 'DescribeEventAggregates',
  },
  {
    value: 'DescribeEventCategories',
    str: 'DescribeEventCategories',
  },
  {
    value: 'DescribeEventConfigurations',
    str: 'DescribeEventConfigurations',
  },
  {
    value: 'DescribeEventDetails',
    str: 'DescribeEventDetails',
  },
  {
    value: 'DescribeEvents',
    str: 'DescribeEvents',
  },
  {
    value: 'DescribeEventSubscriptions',
    str: 'DescribeEventSubscriptions',
  },
  {
    value: 'DescribeEventTopics',
    str: 'DescribeEventTopics',
  },
  {
    value: 'DescribeEventTypes',
    str: 'DescribeEventTypes',
  },
  {
    value: 'DescribeExportConfigurations',
    str: 'DescribeExportConfigurations',
  },
  {
    value: 'DescribeExportTasks',
    str: 'DescribeExportTasks',
  },
  {
    value: 'DescribeExpressions',
    str: 'DescribeExpressions',
  },
  {
    value: 'DescribeFileSystems',
    str: 'DescribeFileSystems',
  },
  {
    value: 'DescribeFindings',
    str: 'DescribeFindings',
  },
  {
    value: 'DescribeFleetAttributes',
    str: 'DescribeFleetAttributes',
  },
  {
    value: 'DescribeFleetCapacity',
    str: 'DescribeFleetCapacity',
  },
  {
    value: 'DescribeFleetEvents',
    str: 'DescribeFleetEvents',
  },
  {
    value: 'DescribeFleetPortSettings',
    str: 'DescribeFleetPortSettings',
  },
  {
    value: 'DescribeFleetUtilization',
    str: 'DescribeFleetUtilization',
  },
  {
    value: 'DescribeFlowLogs',
    str: 'DescribeFlowLogs',
  },
  {
    value: 'DescribeFreeBusyConfigurations',
    str: 'DescribeFreeBusyConfigurations',
  },
  {
    value: 'DescribeGameSessionPlacement',
    str: 'DescribeGameSessionPlacement',
  },
  {
    value: 'DescribeGameSessionQueues',
    str: 'DescribeGameSessionQueues',
  },
  {
    value: 'DescribeGameSessions',
    str: 'DescribeGameSessions',
  },
  {
    value: 'DescribeGatewayInformation',
    str: 'DescribeGatewayInformation',
  },
  {
    value: 'DescribeGroup',
    str: 'DescribeGroup',
  },
  {
    value: 'DescribeGroups',
    str: 'DescribeGroups',
  },
  {
    value: 'DescribeHandshake',
    str: 'DescribeHandshake',
  },
  {
    value: 'DescribeHapg',
    str: 'DescribeHapg',
  },
  {
    value: 'DescribeHardwarePlatform',
    str: 'DescribeHardwarePlatform',
  },
  {
    value: 'DescribeHostedConnections',
    str: 'DescribeHostedConnections',
  },
  {
    value: 'DescribeHostReservationOfferings',
    str: 'DescribeHostReservationOfferings',
  },
  {
    value: 'DescribeHostReservations',
    str: 'DescribeHostReservations',
  },
  {
    value: 'DescribeHosts',
    str: 'DescribeHosts',
  },
  {
    value: 'DescribeHsm',
    str: 'DescribeHsm',
  },
  {
    value: 'DescribeHsmClientCertificates',
    str: 'DescribeHsmClientCertificates',
  },
  {
    value: 'DescribeHsmConfigurations',
    str: 'DescribeHsmConfigurations',
  },
  {
    value: 'DescribeHyperParameterTuningJob',
    str: 'DescribeHyperParameterTuningJob',
  },
  {
    value: 'DescribeIamInstanceProfileAssociations',
    str: 'DescribeIamInstanceProfileAssociations',
  },
  {
    value: 'DescribeIdentity',
    str: 'DescribeIdentity',
  },
  {
    value: 'DescribeIdentityIdFormat',
    str: 'DescribeIdentityIdFormat',
  },
  {
    value: 'DescribeIdentityPool',
    str: 'DescribeIdentityPool',
  },
  {
    value: 'DescribeIdentityPoolUsage',
    str: 'DescribeIdentityPoolUsage',
  },
  {
    value: 'DescribeIdFormat',
    str: 'DescribeIdFormat',
  },
  {
    value: 'DescribeImageAttribute',
    str: 'DescribeImageAttribute',
  },
  {
    value: 'DescribeImages',
    str: 'DescribeImages',
  },
  {
    value: 'DescribeImportImageTasks',
    str: 'DescribeImportImageTasks',
  },
  {
    value: 'DescribeImportSnapshotTasks',
    str: 'DescribeImportSnapshotTasks',
  },
  {
    value: 'DescribeIndex',
    str: 'DescribeIndex',
  },
  {
    value: 'DescribeIndexFields',
    str: 'DescribeIndexFields',
  },
  {
    value: 'DescribeInstanceAttribute',
    str: 'DescribeInstanceAttribute',
  },
  {
    value: 'DescribeInstanceHealth',
    str: 'DescribeInstanceHealth',
  },
  {
    value: 'DescribeInstances',
    str: 'DescribeInstances',
  },
  {
    value: 'DescribeInstancesHealth',
    str: 'DescribeInstancesHealth',
  },
  {
    value: 'DescribeInstanceStatus',
    str: 'DescribeInstanceStatus',
  },
  {
    value: 'DescribeInterconnects',
    str: 'DescribeInterconnects',
  },
  {
    value: 'DescribeInternetGateways',
    str: 'DescribeInternetGateways',
  },
  {
    value: 'DescribeJob',
    str: 'DescribeJob',
  },
  {
    value: 'DescribeJobDefinitions',
    str: 'DescribeJobDefinitions',
  },
  {
    value: 'DescribeJobExecution',
    str: 'DescribeJobExecution',
  },
  {
    value: 'DescribeJobFlows',
    str: 'DescribeJobFlows',
  },
  {
    value: 'DescribeJobQueues',
    str: 'DescribeJobQueues',
  },
  {
    value: 'DescribeJobs',
    str: 'DescribeJobs',
  },
  {
    value: 'DescribeKeyPairs',
    str: 'DescribeKeyPairs',
  },
  {
    value: 'DescribeLags',
    str: 'DescribeLags',
  },
  {
    value: 'DescribeLaunchConfigurations',
    str: 'DescribeLaunchConfigurations',
  },
  {
    value: 'DescribeLayers',
    str: 'DescribeLayers',
  },
  {
    value: 'DescribeLicenses',
    str: 'DescribeLicenses',
  },
  {
    value: 'DescribeLifecycleHooks',
    str: 'DescribeLifecycleHooks',
  },
  {
    value: 'DescribeLifecycleHookTypes',
    str: 'DescribeLifecycleHookTypes',
  },
  {
    value: 'DescribeListeners',
    str: 'DescribeListeners',
  },
  {
    value: 'DescribeLoa',
    str: 'DescribeLoa',
  },
  {
    value: 'DescribeLoadBalancerAttributes',
    str: 'DescribeLoadBalancerAttributes',
  },
  {
    value: 'DescribeLoadBalancerPolicies',
    str: 'DescribeLoadBalancerPolicies',
  },
  {
    value: 'DescribeLoadBalancerPolicyTypes',
    str: 'DescribeLoadBalancerPolicyTypes',
  },
  {
    value: 'DescribeLoadBalancers',
    str: 'DescribeLoadBalancers',
  },
  {
    value: 'DescribeLoadBasedAutoScaling',
    str: 'DescribeLoadBasedAutoScaling',
  },
  {
    value: 'DescribeLocations',
    str: 'DescribeLocations',
  },
  {
    value: 'DescribeLoggingOptions',
    str: 'DescribeLoggingOptions',
  },
  {
    value: 'DescribeLoggingStatus',
    str: 'DescribeLoggingStatus',
  },
  {
    value: 'DescribeLogGroups',
    str: 'DescribeLogGroups',
  },
  {
    value: 'DescribeLogStreams',
    str: 'DescribeLogStreams',
  },
  {
    value: 'DescribeLunaClient',
    str: 'DescribeLunaClient',
  },
  {
    value: 'DescribeMailDomains',
    str: 'DescribeMailDomains',
  },
  {
    value: 'DescribeMaintenanceStartTime',
    str: 'DescribeMaintenanceStartTime',
  },
  {
    value: 'DescribeMatchmaking',
    str: 'DescribeMatchmaking',
  },
  {
    value: 'DescribeMatchmakingConfigurations',
    str: 'DescribeMatchmakingConfigurations',
  },
  {
    value: 'DescribeMatchmakingRuleSets',
    str: 'DescribeMatchmakingRuleSets',
  },
  {
    value: 'DescribeMetricCollectionTypes',
    str: 'DescribeMetricCollectionTypes',
  },
  {
    value: 'DescribeMetricFilters',
    str: 'DescribeMetricFilters',
  },
  {
    value: 'DescribeMigrationTask',
    str: 'DescribeMigrationTask',
  },
  {
    value: 'DescribeModel',
    str: 'DescribeModel',
  },
  {
    value: 'DescribeMountTargets',
    str: 'DescribeMountTargets',
  },
  {
    value: 'DescribeMountTargetSecurityGroups',
    str: 'DescribeMountTargetSecurityGroups',
  },
  {
    value: 'DescribeMovingAddresses',
    str: 'DescribeMovingAddresses',
  },
  {
    value: 'DescribeMyUserProfile',
    str: 'DescribeMyUserProfile',
  },
  {
    value: 'DescribeNatGateways',
    str: 'DescribeNatGateways',
  },
  {
    value: 'DescribeNetworkAcls',
    str: 'DescribeNetworkAcls',
  },
  {
    value: 'DescribeNetworkInterfaceAttribute',
    str: 'DescribeNetworkInterfaceAttribute',
  },
  {
    value: 'DescribeNetworkInterfaces',
    str: 'DescribeNetworkInterfaces',
  },
  {
    value: 'DescribeNfsFileShares',
    str: 'DescribeNfsFileShares',
  },
  {
    value: 'DescribeNotebookInstance',
    str: 'DescribeNotebookInstance',
  },
  {
    value: 'DescribeNotebookInstanceLifecycleConfig',
    str: 'DescribeNotebookInstanceLifecycleConfig',
  },
  {
    value: 'DescribeNotificationConfigurations',
    str: 'DescribeNotificationConfigurations',
  },
  {
    value: 'DescribeObjects',
    str: 'DescribeObjects',
  },
  {
    value: 'DescribeOptionGroupOptions',
    str: 'DescribeOptionGroupOptions',
  },
  {
    value: 'DescribeOptionGroups',
    str: 'DescribeOptionGroups',
  },
  {
    value: 'DescribeOrderableClusterOptions',
    str: 'DescribeOrderableClusterOptions',
  },
  {
    value: 'DescribeOrderableDBInstanceOptions',
    str: 'DescribeOrderableDBInstanceOptions',
  },
  {
    value: 'DescribeOrderableReplicationInstances',
    str: 'DescribeOrderableReplicationInstances',
  },
  {
    value: 'DescribeOrganization',
    str: 'DescribeOrganization',
  },
  {
    value: 'DescribeOrganizationalUnit',
    str: 'DescribeOrganizationalUnit',
  },
  {
    value: 'DescribeOrganizations',
    str: 'DescribeOrganizations',
  },
  {
    value: 'DescribeParameterGroups',
    str: 'DescribeParameterGroups',
  },
  {
    value: 'DescribeParamters',
    str: 'DescribeParamters',
  },
  {
    value: 'DescribePath',
    str: 'DescribePath',
  },
  {
    value: 'DescribePendingMaintenanceActions',
    str: 'DescribePendingMaintenanceActions',
  },
  {
    value: 'DescribePermissions',
    str: 'DescribePermissions',
  },
  {
    value: 'DescribePermissionsPolicies',
    str: 'DescribePermissionsPolicies',
  },
  {
    value: 'DescribePipeline',
    str: 'DescribePipeline',
  },
  {
    value: 'DescribePipelines',
    str: 'DescribePipelines',
  },
  {
    value: 'DescribePlacementGroups',
    str: 'DescribePlacementGroups',
  },
  {
    value: 'DescribePlayerSessions',
    str: 'DescribePlayerSessions',
  },
  {
    value: 'DescribePolicies',
    str: 'DescribePolicies',
  },
  {
    value: 'DescribePolicy',
    str: 'DescribePolicy',
  },
  {
    value: 'DescribePortfolio',
    str: 'DescribePortfolio',
  },
  {
    value: 'DescribePrefixLists',
    str: 'DescribePrefixLists',
  },
  {
    value: 'DescribeProduct',
    str: 'DescribeProduct',
  },
  {
    value: 'DescribeProductAsAdmin',
    str: 'DescribeProductAsAdmin',
  },
  {
    value: 'DescribeProductView',
    str: 'DescribeProductView',
  },
  {
    value: 'DescribeProject',
    str: 'DescribeProject',
  },
  {
    value: 'DescribeProtection',
    str: 'DescribeProtection',
  },
  {
    value: 'DescribeProvisioningArtifact',
    str: 'DescribeProvisioningArtifact',
  },
  {
    value: 'DescribeProvisioningParameters',
    str: 'DescribeProvisioningParameters',
  },
  {
    value: 'DescribeRaidArrays',
    str: 'DescribeRaidArrays',
  },
  {
    value: 'DescribeRankExpressions',
    str: 'DescribeRankExpressions',
  },
  {
    value: 'DescribeRdsDbInstances',
    str: 'DescribeRdsDbInstances',
  },
  {
    value: 'DescribeRecord',
    str: 'DescribeRecord',
  },
  {
    value: 'DescribeRefreshSchemasStatus',
    str: 'DescribeRefreshSchemasStatus',
  },
  {
    value: 'DescribeRegions',
    str: 'DescribeRegions',
  },
  {
    value: 'DescribeReplicationGroups',
    str: 'DescribeReplicationGroups',
  },
  {
    value: 'DescribeReplicationInstances',
    str: 'DescribeReplicationInstances',
  },
  {
    value: 'DescribeReplicationInstanceTaskLogs',
    str: 'DescribeReplicationInstanceTaskLogs',
  },
  {
    value: 'DescribeReplicationSubnetGroups',
    str: 'DescribeReplicationSubnetGroups',
  },
  {
    value: 'DescribeReplicationTaskAssessmentResults',
    str: 'DescribeReplicationTaskAssessmentResults',
  },
  {
    value: 'DescribeReplicationTasks',
    str: 'DescribeReplicationTasks',
  },
  {
    value: 'DescribeReservedCacheNodes',
    str: 'DescribeReservedCacheNodes',
  },
  {
    value: 'DescribeReservedCacheNodesOfferings',
    str: 'DescribeReservedCacheNodesOfferings',
  },
  {
    value: 'DescribeReservedCapacity',
    str: 'DescribeReservedCapacity',
  },
  {
    value: 'DescribeReservedCapacityOfferings',
    str: 'DescribeReservedCapacityOfferings',
  },
  {
    value: 'DescribeReservedDBInstances',
    str: 'DescribeReservedDBInstances',
  },
  {
    value: 'DescribeReservedDBInstancesOfferings',
    str: 'DescribeReservedDBInstancesOfferings',
  },
  {
    value: 'DescribeReservedInstances',
    str: 'DescribeReservedInstances',
  },
  {
    value: 'DescribeReservedInstancesListings',
    str: 'DescribeReservedInstancesListings',
  },
  {
    value: 'DescribeReservedInstancesModifications',
    str: 'DescribeReservedInstancesModifications',
  },
  {
    value: 'DescribeReservedInstancesOfferings',
    str: 'DescribeReservedInstancesOfferings',
  },
  {
    value: 'DescribeReservedNodeOfferings',
    str: 'DescribeReservedNodeOfferings',
  },
  {
    value: 'DescribeReservedNodes',
    str: 'DescribeReservedNodes',
  },
  {
    value: 'DescribeResize',
    str: 'DescribeResize',
  },
  {
    value: 'DescribeResource',
    str: 'DescribeResource',
  },
  {
    value: 'DescribeResourceGroups',
    str: 'DescribeResourceGroups',
  },
  {
    value: 'DescribeResourcePermissions',
    str: 'DescribeResourcePermissions',
  },
  {
    value: 'DescribeResources',
    str: 'DescribeResources',
  },
  {
    value: 'DescribeRoleAlias',
    str: 'DescribeRoleAlias',
  },
  {
    value: 'DescribeRouteTables',
    str: 'DescribeRouteTables',
  },
  {
    value: 'DescribeRules',
    str: 'DescribeRules',
  },
  {
    value: 'DescribeRulesPackages',
    str: 'DescribeRulesPackages',
  },
  {
    value: 'DescribeScalingActivities',
    str: 'DescribeScalingActivities',
  },
  {
    value: 'DescribeScalingParameters',
    str: 'DescribeScalingParameters',
  },
  {
    value: 'DescribeScalingPolicies',
    str: 'DescribeScalingPolicies',
  },
  {
    value: 'DescribeScalingProcessTypes',
    str: 'DescribeScalingProcessTypes',
  },
  {
    value: 'DescribeScheduledActions',
    str: 'DescribeScheduledActions',
  },
  {
    value: 'DescribeScheduledAudit',
    str: 'DescribeScheduledAudit',
  },
  {
    value: 'DescribeScheduledInstanceAvailability',
    str: 'DescribeScheduledInstanceAvailability',
  },
  {
    value: 'DescribeScheduledInstanceRequests',
    str: 'DescribeScheduledInstanceRequests',
  },
  {
    value: 'DescribeScheduledInstances',
    str: 'DescribeScheduledInstances',
  },
  {
    value: 'DescribeSchemas',
    str: 'DescribeSchemas',
  },
  {
    value: 'DescribeSecret',
    str: 'DescribeSecret',
  },
  {
    value: 'DescribeSecurityConfiguration',
    str: 'DescribeSecurityConfiguration',
  },
  {
    value: 'DescribeSecurityGroupReferences',
    str: 'DescribeSecurityGroupReferences',
  },
  {
    value: 'DescribeSecurityGroups',
    str: 'DescribeSecurityGroups',
  },
  {
    value: 'DescribeSecurityProfile',
    str: 'DescribeSecurityProfile',
  },
  {
    value: 'DescribeServers',
    str: 'DescribeServers',
  },
  {
    value: 'DescribeServiceAccessPolicies',
    str: 'DescribeServiceAccessPolicies',
  },
  {
    value: 'DescribeServiceErrors',
    str: 'DescribeServiceErrors',
  },
  {
    value: 'DescribeServices',
    str: 'DescribeServices',
  },
  {
    value: 'DescribeSessions',
    str: 'DescribeSessions',
  },
  {
    value: 'DescribeSeverityLevels',
    str: 'DescribeSeverityLevels',
  },
  {
    value: 'DescribeSigningJob',
    str: 'DescribeSigningJob',
  },
  {
    value: 'DescribeSnapshotAttribute',
    str: 'DescribeSnapshotAttribute',
  },
  {
    value: 'DescribeSnapshots',
    str: 'DescribeSnapshots',
  },
  {
    value: 'DescribeSnapshotSchedule',
    str: 'DescribeSnapshotSchedule',
  },
  {
    value: 'DescribeSoftwareConfiguration',
    str: 'DescribeSoftwareConfiguration',
  },
  {
    value: 'DescribeSolutionStacks',
    str: 'DescribeSolutionStacks',
  },
  {
    value: 'DescribeSpotDatafeedSubscription',
    str: 'DescribeSpotDatafeedSubscription',
  },
  {
    value: 'DescribeSpotFleetInstances',
    str: 'DescribeSpotFleetInstances',
  },
  {
    value: 'DescribeSpotFleetRequestHistory',
    str: 'DescribeSpotFleetRequestHistory',
  },
  {
    value: 'DescribeSpotFleetRequests',
    str: 'DescribeSpotFleetRequests',
  },
  {
    value: 'DescribeSpotInstanceRequests',
    str: 'DescribeSpotInstanceRequests',
  },
  {
    value: 'DescribeSpotPriceHistory',
    str: 'DescribeSpotPriceHistory',
  },
  {
    value: 'DescribeSSLPolicies',
    str: 'DescribeSSLPolicies',
  },
  {
    value: 'DescribeStackEvents',
    str: 'DescribeStackEvents',
  },
  {
    value: 'DescribeStackProvisioningParameters',
    str: 'DescribeStackProvisioningParameters',
  },
  {
    value: 'DescribeStackResource',
    str: 'DescribeStackResource',
  },
  {
    value: 'DescribeStackResources',
    str: 'DescribeStackResources',
  },
  {
    value: 'DescribeStacks',
    str: 'DescribeStacks',
  },
  {
    value: 'DescribeStackSummary',
    str: 'DescribeStackSummary',
  },
  {
    value: 'DescribeStaleSecurityGroups',
    str: 'DescribeStaleSecurityGroups',
  },
  {
    value: 'DescribeStemmingOptions',
    str: 'DescribeStemmingOptions',
  },
  {
    value: 'DescribeStep',
    str: 'DescribeStep',
  },
  {
    value: 'DescribeStopwordOptions',
    str: 'DescribeStopwordOptions',
  },
  {
    value: 'DescribeStorediSCSIVolumes',
    str: 'DescribeStorediSCSIVolumes',
  },
  {
    value: 'DescribeStream',
    str: 'DescribeStream',
  },
  {
    value: 'DescribeStreamConsumer',
    str: 'DescribeStreamConsumer',
  },
  {
    value: 'DescribeStreamProcessor',
    str: 'DescribeStreamProcessor',
  },
  {
    value: 'DescribeSubnetGroups',
    str: 'DescribeSubnetGroups',
  },
  {
    value: 'DescribeSubnets',
    str: 'DescribeSubnets',
  },
  {
    value: 'DescribeSubscription',
    str: 'DescribeSubscription',
  },
  {
    value: 'DescribeSubscriptionFilters',
    str: 'DescribeSubscriptionFilters',
  },
  {
    value: 'DescribeSuggesters',
    str: 'DescribeSuggesters',
  },
  {
    value: 'DescribeSynonymOptions',
    str: 'DescribeSynonymOptions',
  },
  {
    value: 'DescribeTable',
    str: 'DescribeTable',
  },
  {
    value: 'DescribeTableStatistics',
    str: 'DescribeTableStatistics',
  },
  {
    value: 'DescribeTags',
    str: 'DescribeTags',
  },
  {
    value: 'DescribeTapeArchives',
    str: 'DescribeTapeArchives',
  },
  {
    value: 'DescribeTapeRecoveryPoints',
    str: 'DescribeTapeRecoveryPoints',
  },
  {
    value: 'DescribeTapes',
    str: 'DescribeTapes',
  },
  {
    value: 'DescribeTargetGroupAttributes',
    str: 'DescribeTargetGroupAttributes',
  },
  {
    value: 'DescribeTargetGroups',
    str: 'DescribeTargetGroups',
  },
  {
    value: 'DescribeTargetHealth',
    str: 'DescribeTargetHealth',
  },
  {
    value: 'DescribeTaskDefinition',
    str: 'DescribeTaskDefinition',
  },
  {
    value: 'DescribeTasks',
    str: 'DescribeTasks',
  },
  {
    value: 'DescribeTerminationPolicyTypes',
    str: 'DescribeTerminationPolicyTypes',
  },
  {
    value: 'DescribeThing',
    str: 'DescribeThing',
  },
  {
    value: 'DescribeThingGroup',
    str: 'DescribeThingGroup',
  },
  {
    value: 'DescribeThingRegistrationTask',
    str: 'DescribeThingRegistrationTask',
  },
  {
    value: 'DescribeThingType',
    str: 'DescribeThingType',
  },
  {
    value: 'DescribeTimeBasedAutoScaling',
    str: 'DescribeTimeBasedAutoScaling',
  },
  {
    value: 'DescribeTrails',
    str: 'DescribeTrails',
  },
  {
    value: 'DescribeTrainingJob',
    str: 'DescribeTrainingJob',
  },
  {
    value: 'DescribeTriggers',
    str: 'DescribeTriggers',
  },
  {
    value: 'DescribeTrusts',
    str: 'DescribeTrusts',
  },
  {
    value: 'DescribeUploadBuffer',
    str: 'DescribeUploadBuffer',
  },
  {
    value: 'DescribeUser',
    str: 'DescribeUser',
  },
  {
    value: 'DescribeUserPool',
    str: 'DescribeUserPool',
  },
  {
    value: 'DescribeUserPoolClient',
    str: 'DescribeUserPoolClient',
  },
  {
    value: 'DescribeUserProfile',
    str: 'DescribeUserProfile',
  },
  {
    value: 'DescribeUserProfiles',
    str: 'DescribeUserProfiles',
  },
  {
    value: 'DescribeVault',
    str: 'DescribeVault',
  },
  {
    value: 'DescribeVirtualGateways',
    str: 'DescribeVirtualGateways',
  },
  {
    value: 'DescribeVirtualInterfaces',
    str: 'DescribeVirtualInterfaces',
  },
  {
    value: 'DescribeVoices',
    str: 'DescribeVoices',
  },
  {
    value: 'DescribeVolumeAttribute',
    str: 'DescribeVolumeAttribute',
  },
  {
    value: 'DescribeVolumes',
    str: 'DescribeVolumes',
  },
  {
    value: 'DescribeVolumesModifications',
    str: 'DescribeVolumesModifications',
  },
  {
    value: 'DescribeVolumeStatus',
    str: 'DescribeVolumeStatus',
  },
  {
    value: 'DescribeVpcAttribute',
    str: 'DescribeVpcAttribute',
  },
  {
    value: 'DescribeVpcClassicLink',
    str: 'DescribeVpcClassicLink',
  },
  {
    value: 'DescribeVpcClassicLinkDnsSupport',
    str: 'DescribeVpcClassicLinkDnsSupport',
  },
  {
    value: 'DescribeVpcEndpoints',
    str: 'DescribeVpcEndpoints',
  },
  {
    value: 'DescribeVpcEndpointServices',
    str: 'DescribeVpcEndpointServices',
  },
  {
    value: 'DescribeVpcPeeringAuthorizations',
    str: 'DescribeVpcPeeringAuthorizations',
  },
  {
    value: 'DescribeVpcPeeringConnections',
    str: 'DescribeVpcPeeringConnections',
  },
  {
    value: 'DescribeVpcs',
    str: 'DescribeVpcs',
  },
  {
    value: 'DescribeVpnConnections',
    str: 'DescribeVpnConnections',
  },
  {
    value: 'DescribeVpnGateways',
    str: 'DescribeVpnGateways',
  },
  {
    value: 'DescribeVTLDevices',
    str: 'DescribeVTLDevices',
  },
  {
    value: 'DescribeWorkflowType',
    str: 'DescribeWorkflowType',
  },
  {
    value: 'DescribeWorkingStorage',
    str: 'DescribeWorkingStorage',
  },
  {
    value: 'DescribeWorkspaceBundles',
    str: 'DescribeWorkspaceBundles',
  },
  {
    value: 'DescribeWorkspaceDirectories',
    str: 'DescribeWorkspaceDirectories',
  },
  {
    value: 'DescribeWorkspaces',
    str: 'DescribeWorkspaces',
  },
  {
    value: 'DetachClassicLinkVpc',
    str: 'DetachClassicLinkVpc',
  },
  {
    value: 'DetachDisk',
    str: 'DetachDisk',
  },
  {
    value: 'DetachElasticLoadBalancer',
    str: 'DetachElasticLoadBalancer',
  },
  {
    value: 'DetachGroupPolicy',
    str: 'DetachGroupPolicy',
  },
  {
    value: 'DetachInstances',
    str: 'DetachInstances',
  },
  {
    value: 'DetachInstancesFromLoadBalancer',
    str: 'DetachInstancesFromLoadBalancer',
  },
  {
    value: 'DetachInternetGateway',
    str: 'DetachInternetGateway',
  },
  {
    value: 'DetachLoadBalancerFromSubnets',
    str: 'DetachLoadBalancerFromSubnets',
  },
  {
    value: 'DetachLoadBalancers',
    str: 'DetachLoadBalancers',
  },
  {
    value: 'DetachNetworkInterface',
    str: 'DetachNetworkInterface',
  },
  {
    value: 'DetachPolicy',
    str: 'DetachPolicy',
  },
  {
    value: 'DetachPrincipalPolicy',
    str: 'DetachPrincipalPolicy',
  },
  {
    value: 'DetachRolePolicy',
    str: 'DetachRolePolicy',
  },
  {
    value: 'DetachSecurityProfile',
    str: 'DetachSecurityProfile',
  },
  {
    value: 'DetachStaticIp',
    str: 'DetachStaticIp',
  },
  {
    value: 'DetachThingPrincipal',
    str: 'DetachThingPrincipal',
  },
  {
    value: 'DetachUserPolicy',
    str: 'DetachUserPolicy',
  },
  {
    value: 'DetachVolume',
    str: 'DetachVolume',
  },
  {
    value: 'DetachVpnGateway',
    str: 'DetachVpnGateway',
  },
  {
    value: 'DisableAlarmActions',
    str: 'DisableAlarmActions',
  },
  {
    value: 'DisableAutoRenew',
    str: 'DisableAutoRenew',
  },
  {
    value: 'DisableAvailabilityZonesForLoadBalancer',
    str: 'DisableAvailabilityZonesForLoadBalancer',
  },
  {
    value: 'DisableAWSServiceAccess',
    str: 'DisableAWSServiceAccess',
  },
  {
    value: 'DisableDirectory',
    str: 'DisableDirectory',
  },
  {
    value: 'DisableDirectoryMFA',
    str: 'DisableDirectoryMFA',
  },
  {
    value: 'DisableEnhancedMonitoring',
    str: 'DisableEnhancedMonitoring',
  },
  {
    value: 'DisableGateway',
    str: 'DisableGateway',
  },
  {
    value: 'DisableInteroperabilityMode',
    str: 'DisableInteroperabilityMode',
  },
  {
    value: 'DisableLogging',
    str: 'DisableLogging',
  },
  {
    value: 'DisableMailGroups',
    str: 'DisableMailGroups',
  },
  {
    value: 'DisableMailUsers',
    str: 'DisableMailUsers',
  },
  {
    value: 'DisableMetricsCollection',
    str: 'DisableMetricsCollection',
  },
  {
    value: 'DisablePolicyType',
    str: 'DisablePolicyType',
  },
  {
    value: 'DisableRadius',
    str: 'DisableRadius',
  },
  {
    value: 'DisableRule',
    str: 'DisableRule',
  },
  {
    value: 'DisableSnapshotCopy',
    str: 'DisableSnapshotCopy',
  },
  {
    value: 'DisableSso',
    str: 'DisableSso',
  },
  {
    value: 'DisableStageTransition',
    str: 'DisableStageTransition',
  },
  {
    value: 'DisableTopicRule',
    str: 'DisableTopicRule',
  },
  {
    value: 'DisableVgwRoutePropagation',
    str: 'DisableVgwRoutePropagation',
  },
  {
    value: 'DisableVpcClassicLink',
    str: 'DisableVpcClassicLink',
  },
  {
    value: 'DisableVpcClassicLinkDnsSupport',
    str: 'DisableVpcClassicLinkDnsSupport',
  },
  {
    value: 'DisassociateAddress',
    str: 'DisassociateAddress',
  },
  {
    value: 'DisassociateAdminAccount',
    str: 'DisassociateAdminAccount',
  },
  {
    value: 'DisassociateConnectionFromLag',
    str: 'DisassociateConnectionFromLag',
  },
  {
    value: 'DisassociateConnector',
    str: 'DisassociateConnector',
  },
  {
    value: 'DisassociateContactFromAddressBook',
    str: 'DisassociateContactFromAddressBook',
  },
  {
    value: 'DisassociateCreatedArtifact',
    str: 'DisassociateCreatedArtifact',
  },
  {
    value: 'DisassociateDelegateFromResource',
    str: 'DisassociateDelegateFromResource',
  },
  {
    value: 'DisassociateDeviceFromRoom',
    str: 'DisassociateDeviceFromRoom',
  },
  {
    value: 'DisassociateDirectory',
    str: 'DisassociateDirectory',
  },
  {
    value: 'DisassociateElasticIp',
    str: 'DisassociateElasticIp',
  },
  {
    value: 'DisassociateFromMasterAccount',
    str: 'DisassociateFromMasterAccount',
  },
  {
    value: 'DisassociateIamInstanceProfile',
    str: 'DisassociateIamInstanceProfile',
  },
  {
    value: 'DisassociateMemberFromGroup',
    str: 'DisassociateMemberFromGroup',
  },
  {
    value: 'DisassociateMembers',
    str: 'DisassociateMembers',
  },
  {
    value: 'DisassociatePrincipalFromPortfolio',
    str: 'DisassociatePrincipalFromPortfolio',
  },
  {
    value: 'DisassociateProductFromPortfolio',
    str: 'DisassociateProductFromPortfolio',
  },
  {
    value: 'DisassociateProfile',
    str: 'DisassociateProfile',
  },
  {
    value: 'DisassociateResource',
    str: 'DisassociateResource',
  },
  {
    value: 'DisassociateRouteTable',
    str: 'DisassociateRouteTable',
  },
  {
    value: 'DisassociateSkillGroupFromRoom',
    str: 'DisassociateSkillGroupFromRoom',
  },
  {
    value: 'DisassociateSubnetCidrBlock',
    str: 'DisassociateSubnetCidrBlock',
  },
  {
    value: 'DisassociateTeamMember',
    str: 'DisassociateTeamMember',
  },
  {
    value: 'DisassociateTechnicianFromRfc',
    str: 'DisassociateTechnicianFromRfc',
  },
  {
    value: 'DisassociateVpcCidrBlock',
    str: 'DisassociateVpcCidrBlock',
  },
  {
    value: 'DisassociateVPCFromHostedZone',
    str: 'DisassociateVPCFromHostedZone',
  },
  {
    value: 'DisassociateWebACL',
    str: 'DisassociateWebACL',
  },
  {
    value: 'DisconnectDirectory',
    str: 'DisconnectDirectory',
  },
  {
    value: 'DiscoverAppliances',
    str: 'DiscoverAppliances',
  },
  {
    value: 'DiscoverPollEndpoint',
    str: 'DiscoverPollEndpoint',
  },
  {
    value: 'DownloadCompleteDBLogFile',
    str: 'DownloadCompleteDBLogFile',
  },
  {
    value: 'DownloadDBLogFilePortion',
    str: 'DownloadDBLogFilePortion',
  },
  {
    value: 'DownloadDocumentVersion',
    str: 'DownloadDocumentVersion',
  },
  {
    value: 'DownloadDocumentVersionUnderlays',
    str: 'DownloadDocumentVersionUnderlays',
  },
  {
    value: 'DownloadFolderSnapshot',
    str: 'DownloadFolderSnapshot',
  },
  {
    value: 'DryRunEvent',
    str: 'DryRunEvent',
  },
  {
    value: 'EnableAlarmActions',
    str: 'EnableAlarmActions',
  },
  {
    value: 'EnableAllFeatures',
    str: 'EnableAllFeatures',
  },
  {
    value: 'EnableAutoRenew',
    str: 'EnableAutoRenew',
  },
  {
    value: 'EnableAvailabilityZonesForLoadBalancer',
    str: 'EnableAvailabilityZonesForLoadBalancer',
  },
  {
    value: 'EnableAWSServiceAccess',
    str: 'EnableAWSServiceAccess',
  },
  {
    value: 'EnableBillingAlerts',
    str: 'EnableBillingAlerts',
  },
  {
    value: 'EnableDefaultTenancyForVpc',
    str: 'EnableDefaultTenancyForVpc',
  },
  {
    value: 'EnableDirectory',
    str: 'EnableDirectory',
  },
  {
    value: 'EnableDirectoryMFA',
    str: 'EnableDirectoryMFA',
  },
  {
    value: 'EnableEnhancedMonitoring',
    str: 'EnableEnhancedMonitoring',
  },
  {
    value: 'EnableLogging',
    str: 'EnableLogging',
  },
  {
    value: 'EnableMailDomain',
    str: 'EnableMailDomain',
  },
  {
    value: 'EnableMailUsers',
    str: 'EnableMailUsers',
  },
  {
    value: 'EnableMetricsCollection',
    str: 'EnableMetricsCollection',
  },
  {
    value: 'EnableMFADevice',
    str: 'EnableMFADevice',
  },
  {
    value: 'EnablePolicyType',
    str: 'EnablePolicyType',
  },
  {
    value: 'EnableRadius',
    str: 'EnableRadius',
  },
  {
    value: 'EnableRule',
    str: 'EnableRule',
  },
  {
    value: 'EnableSnapshotCopy',
    str: 'EnableSnapshotCopy',
  },
  {
    value: 'EnableSso',
    str: 'EnableSso',
  },
  {
    value: 'EnableStageTransition',
    str: 'EnableStageTransition',
  },
  {
    value: 'EnableTopicRule',
    str: 'EnableTopicRule',
  },
  {
    value: 'EnableVgwRoutePropagation',
    str: 'EnableVgwRoutePropagation',
  },
  {
    value: 'EnableVolumeIO',
    str: 'EnableVolumeIO',
  },
  {
    value: 'EnableVpcClassicLink',
    str: 'EnableVpcClassicLink',
  },
  {
    value: 'EnableVpcClassicLinkDnsSupport',
    str: 'EnableVpcClassicLinkDnsSupport',
  },
  {
    value: 'EndSecretVersionDelete',
    str: 'EndSecretVersionDelete',
  },
  {
    value: 'EnterStandby',
    str: 'EnterStandby',
  },
  {
    value: 'EstimateTemplateCost',
    str: 'EstimateTemplateCost',
  },
  {
    value: 'EvaluateExpression',
    str: 'EvaluateExpression',
  },
  {
    value: 'EvaluateMailFlowRules',
    str: 'EvaluateMailFlowRules',
  },
  {
    value: 'ExecuteChangeSet',
    str: 'ExecuteChangeSet',
  },
  {
    value: 'ExecutePolicy',
    str: 'ExecutePolicy',
  },
  {
    value: 'ExitRole',
    str: 'ExitRole',
  },
  {
    value: 'ExitStandby',
    str: 'ExitStandby',
  },
  {
    value: 'ExportApiKeys',
    str: 'ExportApiKeys',
  },
  {
    value: 'ExportBundle',
    str: 'ExportBundle',
  },
  {
    value: 'ExportCertificate',
    str: 'ExportCertificate',
  },
  {
    value: 'ExportConfigurations',
    str: 'ExportConfigurations',
  },
  {
    value: 'ExportProject',
    str: 'ExportProject',
  },
  {
    value: 'FailoverDBCluster',
    str: 'FailoverDBCluster',
  },
  {
    value: 'FlushStageAuthorizersCache',
    str: 'FlushStageAuthorizersCache',
  },
  {
    value: 'FlushStageCache',
    str: 'FlushStageCache',
  },
  {
    value: 'GenerateClientCertificate',
    str: 'GenerateClientCertificate',
  },
  {
    value: 'GenerateCredentialReport',
    str: 'GenerateCredentialReport',
  },
  {
    value: 'GetAccessKeyLastUsed',
    str: 'GetAccessKeyLastUsed',
  },
  {
    value: 'GetAccessToken',
    str: 'GetAccessToken',
  },
  {
    value: 'GetAccount',
    str: 'GetAccount',
  },
  {
    value: 'GetAccountAuthorizationDetails',
    str: 'GetAccountAuthorizationDetails',
  },
  {
    value: 'GetAccountInstance',
    str: 'GetAccountInstance',
  },
  {
    value: 'GetAccountMapping',
    str: 'GetAccountMapping',
  },
  {
    value: 'GetAccountPasswordPolicy',
    str: 'GetAccountPasswordPolicy',
  },
  {
    value: 'GetAccountResource',
    str: 'GetAccountResource',
  },
  {
    value: 'GetAccountSettings',
    str: 'GetAccountSettings',
  },
  {
    value: 'GetAccountSettings20150331',
    str: 'GetAccountSettings20150331',
  },
  {
    value: 'GetAccountSettings20160819',
    str: 'GetAccountSettings20160819',
  },
  {
    value: 'GetAccountSummary',
    str: 'GetAccountSummary',
  },
  {
    value: 'GetAccountWithOpenIdConfig',
    str: 'GetAccountWithOpenIdConfig',
  },
  {
    value: 'GetActiveNames',
    str: 'GetActiveNames',
  },
  {
    value: 'GetAddressBook',
    str: 'GetAddressBook',
  },
  {
    value: 'GetAdmChannel',
    str: 'GetAdmChannel',
  },
  {
    value: 'GetAdminAccount',
    str: 'GetAdminAccount',
  },
  {
    value: 'GetAlias20150331',
    str: 'GetAlias20150331',
  },
  {
    value: 'GetAllHSMs',
    str: 'GetAllHSMs',
  },
  {
    value: 'GetAnalysis',
    str: 'GetAnalysis',
  },
  {
    value: 'GetAnnotations',
    str: 'GetAnnotations',
  },
  {
    value: 'GetApiGateway',
    str: 'GetApiGateway',
  },
  {
    value: 'GetApiKey',
    str: 'GetApiKey',
  },
  {
    value: 'GetApiKeys',
    str: 'GetApiKeys',
  },
  {
    value: 'GetApnsChannel',
    str: 'GetApnsChannel',
  },
  {
    value: 'GetApnsSandboxChannel',
    str: 'GetApnsSandboxChannel',
  },
  {
    value: 'GetApnsVoipChannel',
    str: 'GetApnsVoipChannel',
  },
  {
    value: 'GetApnsVoipSandboxChannel',
    str: 'GetApnsVoipSandboxChannel',
  },
  {
    value: 'GetApp',
    str: 'GetApp',
  },
  {
    value: 'GetApplication',
    str: 'GetApplication',
  },
  {
    value: 'GetApplicationInstance',
    str: 'GetApplicationInstance',
  },
  {
    value: 'GetApplicationPolicy',
    str: 'GetApplicationPolicy',
  },
  {
    value: 'GetApplicationRevision',
    str: 'GetApplicationRevision',
  },
  {
    value: 'GetApplicationSettings',
    str: 'GetApplicationSettings',
  },
  {
    value: 'GetApplicationTemplate',
    str: 'GetApplicationTemplate',
  },
  {
    value: 'GetAppliedSchemaVersion',
    str: 'GetAppliedSchemaVersion',
  },
  {
    value: 'GetApps',
    str: 'GetApps',
  },
  {
    value: 'GetAuthCode',
    str: 'GetAuthCode',
  },
  {
    value: 'GetAuthorizer',
    str: 'GetAuthorizer',
  },
  {
    value: 'GetAuthorizers',
    str: 'GetAuthorizers',
  },
  {
    value: 'GetBaiduChannel',
    str: 'GetBaiduChannel',
  },
  {
    value: 'GetBasePathMapping',
    str: 'GetBasePathMapping',
  },
  {
    value: 'GetBasePathMappings',
    str: 'GetBasePathMappings',
  },
  {
    value: 'GetBlueprints',
    str: 'GetBlueprints',
  },
  {
    value: 'GetBot',
    str: 'GetBot',
  },
  {
    value: 'GetBotAlias,',
    str: 'GetBotAlias,',
  },
  {
    value: 'GetBotAliases',
    str: 'GetBotAliases',
  },
  {
    value: 'GetBotChannelAssociation',
    str: 'GetBotChannelAssociation',
  },
  {
    value: 'GetBotChannelAssociations',
    str: 'GetBotChannelAssociations',
  },
  {
    value: 'GetBots',
    str: 'GetBots',
  },
  {
    value: 'GetBotVersions',
    str: 'GetBotVersions',
  },
  {
    value: 'GetBranch',
    str: 'GetBranch',
  },
  {
    value: 'GetBucketAcl',
    str: 'GetBucketAcl',
  },
  {
    value: 'GetBucketCors',
    str: 'GetBucketCors',
  },
  {
    value: 'GetBucketEncryption',
    str: 'GetBucketEncryption',
  },
  {
    value: 'GetBucketLifecycle',
    str: 'GetBucketLifecycle',
  },
  {
    value: 'GetBucketLocation',
    str: 'GetBucketLocation',
  },
  {
    value: 'GetBucketLogging',
    str: 'GetBucketLogging',
  },
  {
    value: 'GetBucketNotification',
    str: 'GetBucketNotification',
  },
  {
    value: 'GetBucketPolicy',
    str: 'GetBucketPolicy',
  },
  {
    value: 'GetBucketReplication',
    str: 'GetBucketReplication',
  },
  {
    value: 'GetBucketRequestPayment',
    str: 'GetBucketRequestPayment',
  },
  {
    value: 'GetBucketTagging',
    str: 'GetBucketTagging',
  },
  {
    value: 'GetBucketVersioning',
    str: 'GetBucketVersioning',
  },
  {
    value: 'GetBucketWebsite',
    str: 'GetBucketWebsite',
  },
  {
    value: 'GetBuiltinIntent',
    str: 'GetBuiltinIntent',
  },
  {
    value: 'GetBuiltinIntents',
    str: 'GetBuiltinIntents',
  },
  {
    value: 'GetBuiltinSlotTypes',
    str: 'GetBuiltinSlotTypes',
  },
  {
    value: 'GetBulkPublishDetails',
    str: 'GetBulkPublishDetails',
  },
  {
    value: 'GetBundles',
    str: 'GetBundles',
  },
  {
    value: 'GetByteMatchSet',
    str: 'GetByteMatchSet',
  },
  {
    value: 'GetCampaign',
    str: 'GetCampaign',
  },
  {
    value: 'GetCampaignActivities',
    str: 'GetCampaignActivities',
  },
  {
    value: 'GetCampaigns',
    str: 'GetCampaigns',
  },
  {
    value: 'GetCampaignVersion',
    str: 'GetCampaignVersion',
  },
  {
    value: 'GetCampaignVersions',
    str: 'GetCampaignVersions',
  },
  {
    value: 'GetCatalogImportStatus',
    str: 'GetCatalogImportStatus',
  },
  {
    value: 'GetCDRSettings',
    str: 'GetCDRSettings',
  },
  {
    value: 'GetCertificate',
    str: 'GetCertificate',
  },
  {
    value: 'GetCertificateAuthorityCertificate',
    str: 'GetCertificateAuthorityCertificate',
  },
  {
    value: 'GetCertificateAuthorityCsr',
    str: 'GetCertificateAuthorityCsr',
  },
  {
    value: 'GetChange',
    str: 'GetChange',
  },
  {
    value: 'GetChangeDetails',
    str: 'GetChangeDetails',
  },
  {
    value: 'GetChangePasswordUrl',
    str: 'GetChangePasswordUrl',
  },
  {
    value: 'GetChangeToken',
    str: 'GetChangeToken',
  },
  {
    value: 'GetChangeTokenStatus',
    str: 'GetChangeTokenStatus',
  },
  {
    value: 'GetChangeTypeVersion',
    str: 'GetChangeTypeVersion',
  },
  {
    value: 'GetCheckerIpRanges',
    str: 'GetCheckerIpRanges',
  },
  {
    value: 'GetClassifier',
    str: 'GetClassifier',
  },
  {
    value: 'GetClassifiers',
    str: 'GetClassifiers',
  },
  {
    value: 'GetClientCertificate',
    str: 'GetClientCertificate',
  },
  {
    value: 'GetClientCertificates',
    str: 'GetClientCertificates',
  },
  {
    value: 'GetClientConfiguration',
    str: 'GetClientConfiguration',
  },
  {
    value: 'GetCloudFrontOriginAccessIdentity',
    str: 'GetCloudFrontOriginAccessIdentity',
  },
  {
    value: 'GetCloudFrontOriginAccessIdentityConfig',
    str: 'GetCloudFrontOriginAccessIdentityConfig',
  },
  {
    value: 'GetCognitoEvents',
    str: 'GetCognitoEvents',
  },
  {
    value: 'GetComments',
    str: 'GetComments',
  },
  {
    value: 'GetCommit',
    str: 'GetCommit',
  },
  {
    value: 'GetComplianceDetail',
    str: 'GetComplianceDetail',
  },
  {
    value: 'GetComplianceDetailsByConfigRule',
    str: 'GetComplianceDetailsByConfigRule',
  },
  {
    value: 'GetComplianceDetailsByResource',
    str: 'GetComplianceDetailsByResource',
  },
  {
    value: 'GetComplianceSummaryByConfigRule',
    str: 'GetComplianceSummaryByConfigRule',
  },
  {
    value: 'GetComplianceSummaryByResourceType',
    str: 'GetComplianceSummaryByResourceType',
  },
  {
    value: 'GetConferenceAppliance',
    str: 'GetConferenceAppliance',
  },
  {
    value: 'GetConfig',
    str: 'GetConfig',
  },
  {
    value: 'GetConnections',
    str: 'GetConnections',
  },
  {
    value: 'GetConnectionStatus',
    str: 'GetConnectionStatus',
  },
  {
    value: 'GetConnectors',
    str: 'GetConnectors',
  },
  {
    value: 'GetConsoleOutput',
    str: 'GetConsoleOutput',
  },
  {
    value: 'GetConsoleScreenshot',
    str: 'GetConsoleScreenshot',
  },
  {
    value: 'GetContact',
    str: 'GetContact',
  },
  {
    value: 'GetContactReachabilityStatus',
    str: 'GetContactReachabilityStatus',
  },
  {
    value: 'GetContainerPolicy',
    str: 'GetContainerPolicy',
  },
  {
    value: 'GetContextKeysForCustomPolicy',
    str: 'GetContextKeysForCustomPolicy',
  },
  {
    value: 'GetContextKeysForPrincipalPolicy',
    str: 'GetContextKeysForPrincipalPolicy',
  },
  {
    value: 'GetCorsPolicy',
    str: 'GetCorsPolicy',
  },
  {
    value: 'GetCostAndUsage',
    str: 'GetCostAndUsage',
  },
  {
    value: 'GetCrawler',
    str: 'GetCrawler',
  },
  {
    value: 'GetCrawlerMetrics',
    str: 'GetCrawlerMetrics',
  },
  {
    value: 'GetCrawlers',
    str: 'GetCrawlers',
  },
  {
    value: 'GetCredentialReport',
    str: 'GetCredentialReport',
  },
  {
    value: 'GetDashboard',
    str: 'GetDashboard',
  },
  {
    value: 'GetDatabase',
    str: 'GetDatabase',
  },
  {
    value: 'GetDatabases',
    str: 'GetDatabases',
  },
  {
    value: 'GetDataflowGraph',
    str: 'GetDataflowGraph',
  },
  {
    value: 'GetDataRetrievalPolicy',
    str: 'GetDataRetrievalPolicy',
  },
  {
    value: 'GetDatasetContent',
    str: 'GetDatasetContent',
  },
  {
    value: 'GetDataSource',
    str: 'GetDataSource',
  },
  {
    value: 'GetDeployment',
    str: 'GetDeployment',
  },
  {
    value: 'GetDeploymentConfig',
    str: 'GetDeploymentConfig',
  },
  {
    value: 'GetDeploymentGroup',
    str: 'GetDeploymentGroup',
  },
  {
    value: 'GetDeploymentInstance',
    str: 'GetDeploymentInstance',
  },
  {
    value: 'GetDeployments',
    str: 'GetDeployments',
  },
  {
    value: 'GetDetector',
    str: 'GetDetector',
  },
  {
    value: 'GetDevEndpoint',
    str: 'GetDevEndpoint',
  },
  {
    value: 'GetDevEndpoints',
    str: 'GetDevEndpoints',
  },
  {
    value: 'GetDevice',
    str: 'GetDevice',
  },
  {
    value: 'GetDimensionValue',
    str: 'GetDimensionValue',
  },
  {
    value: 'GetDirectory',
    str: 'GetDirectory',
  },
  {
    value: 'GetDirectoryLimits',
    str: 'GetDirectoryLimits',
  },
  {
    value: 'GetDiscoveredResourceCounts',
    str: 'GetDiscoveredResourceCounts',
  },
  {
    value: 'GetDisks',
    str: 'GetDisks',
  },
  {
    value: 'GetDiskSnapshot',
    str: 'GetDiskSnapshot',
  },
  {
    value: 'GetDiskSnapshots',
    str: 'GetDiskSnapshots',
  },
  {
    value: 'GetDistribution',
    str: 'GetDistribution',
  },
  {
    value: 'GetDistributionConfig',
    str: 'GetDistributionConfig',
  },
  {
    value: 'GetDocument',
    str: 'GetDocument',
  },
  {
    value: 'GetDocumentActivity',
    str: 'GetDocumentActivity',
  },
  {
    value: 'GetDocumentationPart',
    str: 'GetDocumentationPart',
  },
  {
    value: 'GetDocumentationParts',
    str: 'GetDocumentationParts',
  },
  {
    value: 'GetDocumentationVersion',
    str: 'GetDocumentationVersion',
  },
  {
    value: 'GetDocumentationVersions',
    str: 'GetDocumentationVersions',
  },
  {
    value: 'GetDocumentCollection',
    str: 'GetDocumentCollection',
  },
  {
    value: 'GetDocumentPath',
    str: 'GetDocumentPath',
  },
  {
    value: 'GetDocumentVersion',
    str: 'GetDocumentVersion',
  },
  {
    value: 'GetDocumentVersionCredentials',
    str: 'GetDocumentVersionCredentials',
  },
  {
    value: 'GetDocumentVersions',
    str: 'GetDocumentVersions',
  },
  {
    value: 'GetDomain',
    str: 'GetDomain',
  },
  {
    value: 'GetDomainInformation',
    str: 'GetDomainInformation',
  },
  {
    value: 'GetDomainName',
    str: 'GetDomainName',
  },
  {
    value: 'GetDomainNames',
    str: 'GetDomainNames',
  },
  {
    value: 'GetDomains',
    str: 'GetDomains',
  },
  {
    value: 'GetDomainSuggestions',
    str: 'GetDomainSuggestions',
  },
  {
    value: 'GetEffectivePolicies',
    str: 'GetEffectivePolicies',
  },
  {
    value: 'GetEmailChannel',
    str: 'GetEmailChannel',
  },
  {
    value: 'GetEncryptionConfig',
    str: 'GetEncryptionConfig',
  },
  {
    value: 'GetEvents',
    str: 'GetEvents',
  },
  {
    value: 'GetEventSelectors',
    str: 'GetEventSelectors',
  },
  {
    value: 'GetEventSourceMapping20150331',
    str: 'GetEventSourceMapping20150331',
  },
  {
    value: 'GetEventStream',
    str: 'GetEventStream',
  },
  {
    value: 'GetExport',
    str: 'GetExport',
  },
  {
    value: 'GetFacet',
    str: 'GetFacet',
  },
  {
    value: 'GetFeedbackRequests',
    str: 'GetFeedbackRequests',
  },
  {
    value: 'GetFilter',
    str: 'GetFilter',
  },
  {
    value: 'GetFindings',
    str: 'GetFindings',
  },
  {
    value: 'GetFindingsStatistics',
    str: 'GetFindingsStatistics',
  },
  {
    value: 'GetFolder',
    str: 'GetFolder',
  },
  {
    value: 'GetFolderContents',
    str: 'GetFolderContents',
  },
  {
    value: 'GetFolderPath',
    str: 'GetFolderPath',
  },
  {
    value: 'GetFunction',
    str: 'GetFunction',
  },
  {
    value: 'GetFunction20150331',
    str: 'GetFunction20150331',
  },
  {
    value: 'GetFunction20150331v2',
    str: 'GetFunction20150331v2',
  },
  {
    value: 'GetFunctionConfiguration',
    str: 'GetFunctionConfiguration',
  },
  {
    value: 'GetFunctionConfiguration20150331',
    str: 'GetFunctionConfiguration20150331',
  },
  {
    value: 'GetFunctionConfiguration20150331v2',
    str: 'GetFunctionConfiguration20150331v2',
  },
  {
    value: 'GetGameSessionLogUrl',
    str: 'GetGameSessionLogUrl',
  },
  {
    value: 'GetGatewayResponse',
    str: 'GetGatewayResponse',
  },
  {
    value: 'GetGatewayResponses',
    str: 'GetGatewayResponses',
  },
  {
    value: 'GetGcmChannel',
    str: 'GetGcmChannel',
  },
  {
    value: 'GetGeoLocation',
    str: 'GetGeoLocation',
  },
  {
    value: 'GetGraphqlApi',
    str: 'GetGraphqlApi',
  },
  {
    value: 'GetGroup',
    str: 'GetGroup',
  },
  {
    value: 'GetGroupPolicy',
    str: 'GetGroupPolicy',
  },
  {
    value: 'GetGroupQuery',
    str: 'GetGroupQuery',
  },
  {
    value: 'GetHAPartitionGroupConfig',
    str: 'GetHAPartitionGroupConfig',
  },
  {
    value: 'GetHealthCheck',
    str: 'GetHealthCheck',
  },
  {
    value: 'GetHealthCheckCount',
    str: 'GetHealthCheckCount',
  },
  {
    value: 'GetHealthCheckLastFailureReason',
    str: 'GetHealthCheckLastFailureReason',
  },
  {
    value: 'GetHealthCheckStatus',
    str: 'GetHealthCheckStatus',
  },
  {
    value: 'GetHostedZone',
    str: 'GetHostedZone',
  },
  {
    value: 'GetHostedZoneCount',
    str: 'GetHostedZoneCount',
  },
  {
    value: 'GetHostnameSuggestion',
    str: 'GetHostnameSuggestion',
  },
  {
    value: 'GetHostReservationPurchasePreview',
    str: 'GetHostReservationPurchasePreview',
  },
  {
    value: 'GetHsmCredentials',
    str: 'GetHsmCredentials',
  },
  {
    value: 'GetHsmStatus',
    str: 'GetHsmStatus',
  },
  {
    value: 'GetIdentityPoolConfiguration',
    str: 'GetIdentityPoolConfiguration',
  },
  {
    value: 'GetIdentityPoolRules',
    str: 'GetIdentityPoolRules',
  },
  {
    value: 'GetImplicitUser',
    str: 'GetImplicitUser',
  },
  {
    value: 'GetImportJobs',
    str: 'GetImportJobs',
  },
  {
    value: 'GetIndexingConfiguration',
    str: 'GetIndexingConfiguration',
  },
  {
    value: 'GetInstance',
    str: 'GetInstance',
  },
  {
    value: 'GetInstancePortStates',
    str: 'GetInstancePortStates',
  },
  {
    value: 'GetInstanceProfile',
    str: 'GetInstanceProfile',
  },
  {
    value: 'GetInstances',
    str: 'GetInstances',
  },
  {
    value: 'GetInstancesHealthStatus',
    str: 'GetInstancesHealthStatus',
  },
  {
    value: 'GetInstanceSnapshot',
    str: 'GetInstanceSnapshot',
  },
  {
    value: 'GetInstanceState',
    str: 'GetInstanceState',
  },
  {
    value: 'GetIntegration',
    str: 'GetIntegration',
  },
  {
    value: 'GetIntegrationResponse',
    str: 'GetIntegrationResponse',
  },
  {
    value: 'GetIntent',
    str: 'GetIntent',
  },
  {
    value: 'GetIntents',
    str: 'GetIntents',
  },
  {
    value: 'GetIntentVersions',
    str: 'GetIntentVersions',
  },
  {
    value: 'GetIntrospectionSchema',
    str: 'GetIntrospectionSchema',
  },
  {
    value: 'GetInvalidation',
    str: 'GetInvalidation',
  },
  {
    value: 'GetInvitationsCount',
    str: 'GetInvitationsCount',
  },
  {
    value: 'GetIPSet',
    str: 'GetIPSet',
  },
  {
    value: 'GetJob',
    str: 'GetJob',
  },
  {
    value: 'GetJobDetails',
    str: 'GetJobDetails',
  },
  {
    value: 'GetJobDocument',
    str: 'GetJobDocument',
  },
  {
    value: 'GetJobOutput',
    str: 'GetJobOutput',
  },
  {
    value: 'GetJobRun',
    str: 'GetJobRun',
  },
  {
    value: 'GetJobRuns',
    str: 'GetJobRuns',
  },
  {
    value: 'GetJobs',
    str: 'GetJobs',
  },
  {
    value: 'GetJobTemplate',
    str: 'GetJobTemplate',
  },
  {
    value: 'GetJournalingRules',
    str: 'GetJournalingRules',
  },
  {
    value: 'GetKeyPair',
    str: 'GetKeyPair',
  },
  {
    value: 'GetKeyPairs',
    str: 'GetKeyPairs',
  },
  {
    value: 'GetLexicon',
    str: 'GetLexicon',
  },
  {
    value: 'GetLinkAttributes',
    str: 'GetLinkAttributes',
  },
  {
    value: 'GetLoadBalancer',
    str: 'GetLoadBalancer',
  },
  {
    value: 'GetLoadBalancers',
    str: 'GetLoadBalancers',
  },
  {
    value: 'GetLoadBalancerTlsCertificates',
    str: 'GetLoadBalancerTlsCertificates',
  },
  {
    value: 'GetLoggingOptions',
    str: 'GetLoggingOptions',
  },
  {
    value: 'GetLoginProfile',
    str: 'GetLoginProfile',
  },
  {
    value: 'GetMailboxDetails',
    str: 'GetMailboxDetails',
  },
  {
    value: 'GetMailDomainDetails',
    str: 'GetMailDomainDetails',
  },
  {
    value: 'GetMailGroupDetails',
    str: 'GetMailGroupDetails',
  },
  {
    value: 'GetMailUserDetails',
    str: 'GetMailUserDetails',
  },
  {
    value: 'GetMapping',
    str: 'GetMapping',
  },
  {
    value: 'GetMasterAccount',
    str: 'GetMasterAccount',
  },
  {
    value: 'GetMeetingDetail',
    str: 'GetMeetingDetail',
  },
  {
    value: 'GetMembers',
    str: 'GetMembers',
  },
  {
    value: 'GetMessages',
    str: 'GetMessages',
  },
  {
    value: 'GetMethod',
    str: 'GetMethod',
  },
  {
    value: 'GetMethodResponse',
    str: 'GetMethodResponse',
  },
  {
    value: 'GetMobileDeviceDetails',
    str: 'GetMobileDeviceDetails',
  },
  {
    value: 'GetMobileDevicesForUser',
    str: 'GetMobileDevicesForUser',
  },
  {
    value: 'GetModel',
    str: 'GetModel',
  },
  {
    value: 'GetModels',
    str: 'GetModels',
  },
  {
    value: 'GetModelTemplate',
    str: 'GetModelTemplate',
  },
  {
    value: 'GetNamedQuery',
    str: 'GetNamedQuery',
  },
  {
    value: 'GetNamespace',
    str: 'GetNamespace',
  },
  {
    value: 'GetNotificationChannel',
    str: 'GetNotificationChannel',
  },
  {
    value: 'GetNotificationTopic',
    str: 'GetNotificationTopic',
  },
  {
    value: 'GetOAuthCode',
    str: 'GetOAuthCode',
  },
  {
    value: 'GetObjects',
    str: 'GetObjects',
  },
  {
    value: 'GetOnPremisesInstance',
    str: 'GetOnPremisesInstance',
  },
  {
    value: 'GetOpenIDConnectProvider',
    str: 'GetOpenIDConnectProvider',
  },
  {
    value: 'GetOpenIdTokenForDeveloperIdentity',
    str: 'GetOpenIdTokenForDeveloperIdentity',
  },
  {
    value: 'GetOperation',
    str: 'GetOperation',
  },
  {
    value: 'GetOperationsForResource',
    str: 'GetOperationsForResource',
  },
  {
    value: 'GetOperationStatus',
    str: 'GetOperationStatus',
  },
  {
    value: 'GetOrganization',
    str: 'GetOrganization',
  },
  {
    value: 'GetOrganizationUsers',
    str: 'GetOrganizationUsers',
  },
  {
    value: 'GetOTAUpdate',
    str: 'GetOTAUpdate',
  },
  {
    value: 'GetPartition',
    str: 'GetPartition',
  },
  {
    value: 'GetPartitions',
    str: 'GetPartitions',
  },
  {
    value: 'GetPasswordData',
    str: 'GetPasswordData',
  },
  {
    value: 'GetPermissionSet',
    str: 'GetPermissionSet',
  },
  {
    value: 'GetPermissionsPolicy',
    str: 'GetPermissionsPolicy',
  },
  {
    value: 'GetPipeline',
    str: 'GetPipeline',
  },
  {
    value: 'GetPipelineDefinition',
    str: 'GetPipelineDefinition',
  },
  {
    value: 'GetPipelineState',
    str: 'GetPipelineState',
  },
  {
    value: 'GetPlan',
    str: 'GetPlan',
  },
  {
    value: 'GetPolicy',
    str: 'GetPolicy',
  },
  {
    value: 'GetPolicy20150331',
    str: 'GetPolicy20150331',
  },
  {
    value: 'GetPolicy20150331v2',
    str: 'GetPolicy20150331v2',
  },
  {
    value: 'GetPolicyVersion',
    str: 'GetPolicyVersion',
  },
  {
    value: 'GetPreset',
    str: 'GetPreset',
  },
  {
    value: 'GetProfile',
    str: 'GetProfile',
  },
  {
    value: 'GetQueryExecution',
    str: 'GetQueryExecution',
  },
  {
    value: 'GetQueryResults',
    str: 'GetQueryResults',
  },
  {
    value: 'GetQueryResultsStream',
    str: 'GetQueryResultsStream',
  },
  {
    value: 'GetQueue',
    str: 'GetQueue',
  },
  {
    value: 'GetRandomPassword',
    str: 'GetRandomPassword',
  },
  {
    value: 'GetRegions',
    str: 'GetRegions',
  },
  {
    value: 'GetRegistrationCode',
    str: 'GetRegistrationCode',
  },
  {
    value: 'GetReplicationJobs',
    str: 'GetReplicationJobs',
  },
  {
    value: 'GetReplicationRuns',
    str: 'GetReplicationRuns',
  },
  {
    value: 'GetRepository',
    str: 'GetRepository',
  },
  {
    value: 'GetRepositoryTriggers',
    str: 'GetRepositoryTriggers',
  },
  {
    value: 'GetRequestValidator',
    str: 'GetRequestValidator',
  },
  {
    value: 'GetRequestValidators',
    str: 'GetRequestValidators',
  },
  {
    value: 'GetReservationCoverage',
    str: 'GetReservationCoverage',
  },
  {
    value: 'GetReservationPurchaseRecommendation',
    str: 'GetReservationPurchaseRecommendation',
  },
  {
    value: 'GetReservationUtilization',
    str: 'GetReservationUtilization',
  },
  {
    value: 'GetReservedInstancesExchangeQuote',
    str: 'GetReservedInstancesExchangeQuote',
  },
  {
    value: 'GetResolver',
    str: 'GetResolver',
  },
  {
    value: 'GetResource',
    str: 'GetResource',
  },
  {
    value: 'GetResourceCollection',
    str: 'GetResourceCollection',
  },
  {
    value: 'GetResourceConfigHistory',
    str: 'GetResourceConfigHistory',
  },
  {
    value: 'GetResourceDetails',
    str: 'GetResourceDetails',
  },
  {
    value: 'GetResourcePath',
    str: 'GetResourcePath',
  },
  {
    value: 'GetResourcePolicy',
    str: 'GetResourcePolicy',
  },
  {
    value: 'GetResources',
    str: 'GetResources',
  },
  {
    value: 'GetRestApi',
    str: 'GetRestApi',
  },
  {
    value: 'GetRestApis',
    str: 'GetRestApis',
  },
  {
    value: 'GetReusableDelegationSet',
    str: 'GetReusableDelegationSet',
  },
  {
    value: 'GetRfc',
    str: 'GetRfc',
  },
  {
    value: 'GetRole',
    str: 'GetRole',
  },
  {
    value: 'GetRolePolicy',
    str: 'GetRolePolicy',
  },
  {
    value: 'GetRoom',
    str: 'GetRoom',
  },
  {
    value: 'GetRoomSkillParameter',
    str: 'GetRoomSkillParameter',
  },
  {
    value: 'GetRootFolder',
    str: 'GetRootFolder',
  },
  {
    value: 'GetRule',
    str: 'GetRule',
  },
  {
    value: 'GetSAMLProvider',
    str: 'GetSAMLProvider',
  },
  {
    value: 'GetSampledRequests',
    str: 'GetSampledRequests',
  },
  {
    value: 'GetSchemaAsJson',
    str: 'GetSchemaAsJson',
  },
  {
    value: 'GetSchemaCreationStatus',
    str: 'GetSchemaCreationStatus',
  },
  {
    value: 'GetSdk',
    str: 'GetSdk',
  },
  {
    value: 'GetSdkType',
    str: 'GetSdkType',
  },
  {
    value: 'GetSdkTypes',
    str: 'GetSdkTypes',
  },
  {
    value: 'GetSecretValue',
    str: 'GetSecretValue',
  },
  {
    value: 'GetSegment',
    str: 'GetSegment',
  },
  {
    value: 'GetSegmentImportJobs',
    str: 'GetSegmentImportJobs',
  },
  {
    value: 'GetSegments',
    str: 'GetSegments',
  },
  {
    value: 'GetSegmentVersion',
    str: 'GetSegmentVersion',
  },
  {
    value: 'GetSegmentVersions',
    str: 'GetSegmentVersions',
  },
  {
    value: 'GetServerCertificate',
    str: 'GetServerCertificate',
  },
  {
    value: 'GetServers',
    str: 'GetServers',
  },
  {
    value: 'GetService',
    str: 'GetService',
  },
  {
    value: 'GetServiceLinkedRoleDeletionStatus',
    str: 'GetServiceLinkedRoleDeletionStatus',
  },
  {
    value: 'GetSizeConstraintSet',
    str: 'GetSizeConstraintSet',
  },
  {
    value: 'GetSkillGroup',
    str: 'GetSkillGroup',
  },
  {
    value: 'GetSlotType',
    str: 'GetSlotType',
  },
  {
    value: 'GetSlotTypes',
    str: 'GetSlotTypes',
  },
  {
    value: 'GetSlotTypeVersions',
    str: 'GetSlotTypeVersions',
  },
  {
    value: 'GetSmsChannel',
    str: 'GetSmsChannel',
  },
  {
    value: 'GetSnapshotLimits',
    str: 'GetSnapshotLimits',
  },
  {
    value: 'GetSoftwareURL',
    str: 'GetSoftwareURL',
  },
  {
    value: 'GetSoftwareURLForConfiguration',
    str: 'GetSoftwareURLForConfiguration',
  },
  {
    value: 'GetSqlInjectionMatchSet',
    str: 'GetSqlInjectionMatchSet',
  },
  {
    value: 'GetSSHPublicKey',
    str: 'GetSSHPublicKey',
  },
  {
    value: 'GetSSOStatus',
    str: 'GetSSOStatus',
  },
  {
    value: 'GetStack',
    str: 'GetStack',
  },
  {
    value: 'GetStackPolicy',
    str: 'GetStackPolicy',
  },
  {
    value: 'GetStage',
    str: 'GetStage',
  },
  {
    value: 'GetStages',
    str: 'GetStages',
  },
  {
    value: 'GetStaticIp',
    str: 'GetStaticIp',
  },
  {
    value: 'GetStaticIps',
    str: 'GetStaticIps',
  },
  {
    value: 'GetStatus',
    str: 'GetStatus',
  },
  {
    value: 'GetStreamingDistribution',
    str: 'GetStreamingDistribution',
  },
  {
    value: 'GetStreamingDistributionConfig',
    str: 'GetStreamingDistributionConfig',
  },
  {
    value: 'GetSubnet',
    str: 'GetSubnet',
  },
  {
    value: 'GetSubscriptionState',
    str: 'GetSubscriptionState',
  },
  {
    value: 'GetTable',
    str: 'GetTable',
  },
  {
    value: 'GetTables',
    str: 'GetTables',
  },
  {
    value: 'GetTableVersions',
    str: 'GetTableVersions',
  },
  {
    value: 'GetTags',
    str: 'GetTags',
  },
  {
    value: 'GetTelemetryMetadata',
    str: 'GetTelemetryMetadata',
  },
  {
    value: 'GetTemplate',
    str: 'GetTemplate',
  },
  {
    value: 'GetTemplateSummary',
    str: 'GetTemplateSummary',
  },
  {
    value: 'GetThirdPartyJobDetails',
    str: 'GetThirdPartyJobDetails',
  },
  {
    value: 'GetThreatIntelSet',
    str: 'GetThreatIntelSet',
  },
  {
    value: 'GetTopicRule',
    str: 'GetTopicRule',
  },
  {
    value: 'GetTrafficPolicy',
    str: 'GetTrafficPolicy',
  },
  {
    value: 'GetTrafficPolicyInstance',
    str: 'GetTrafficPolicyInstance',
  },
  {
    value: 'GetTrafficPolicyInstanceCount',
    str: 'GetTrafficPolicyInstanceCount',
  },
  {
    value: 'GetTrailStatus',
    str: 'GetTrailStatus',
  },
  {
    value: 'GetTransfer',
    str: 'GetTransfer',
  },
  {
    value: 'GetTrigger',
    str: 'GetTrigger',
  },
  {
    value: 'GetTriggers',
    str: 'GetTriggers',
  },
  {
    value: 'GetType',
    str: 'GetType',
  },
  {
    value: 'GetTypedLinkFacetInformation',
    str: 'GetTypedLinkFacetInformation',
  },
  {
    value: 'GetUsage',
    str: 'GetUsage',
  },
  {
    value: 'GetUsagePlan',
    str: 'GetUsagePlan',
  },
  {
    value: 'GetUsagePlanKey',
    str: 'GetUsagePlanKey',
  },
  {
    value: 'GetUsagePlanKeys',
    str: 'GetUsagePlanKeys',
  },
  {
    value: 'GetUsagePlans',
    str: 'GetUsagePlans',
  },
  {
    value: 'GetUser',
    str: 'GetUser',
  },
  {
    value: 'GetUserActivityReportData',
    str: 'GetUserActivityReportData',
  },
  {
    value: 'GetUserByEmail',
    str: 'GetUserByEmail',
  },
  {
    value: 'GetUserDefinedFunction',
    str: 'GetUserDefinedFunction',
  },
  {
    value: 'GetUserDefinedFunctions',
    str: 'GetUserDefinedFunctions',
  },
  {
    value: 'GetUserFeatures',
    str: 'GetUserFeatures',
  },
  {
    value: 'GetUserGroups',
    str: 'GetUserGroups',
  },
  {
    value: 'GetUserPolicy',
    str: 'GetUserPolicy',
  },
  {
    value: 'GetUsersInGroup',
    str: 'GetUsersInGroup',
  },
  {
    value: 'GetUtterancesView',
    str: 'GetUtterancesView',
  },
  {
    value: 'GetV2LoggingOptions',
    str: 'GetV2LoggingOptions',
  },
  {
    value: 'GetVaultAccessPolicy',
    str: 'GetVaultAccessPolicy',
  },
  {
    value: 'GetVaultLock',
    str: 'GetVaultLock',
  },
  {
    value: 'GetVaultNotifications',
    str: 'GetVaultNotifications',
  },
  {
    value: 'GetVpc',
    str: 'GetVpc',
  },
  {
    value: 'GetVpcEndpointCount',
    str: 'GetVpcEndpointCount',
  },
  {
    value: 'GetVpcLink',
    str: 'GetVpcLink',
  },
  {
    value: 'GetVpcLinks',
    str: 'GetVpcLinks',
  },
  {
    value: 'GetWebACL',
    str: 'GetWebACL',
  },
  {
    value: 'GetWebACLForResource',
    str: 'GetWebACLForResource',
  },
  {
    value: 'GetXssMatchSet',
    str: 'GetXssMatchSet',
  },
  {
    value: 'GrantAccess',
    str: 'GrantAccess',
  },
  {
    value: 'ImportApiKeys',
    str: 'ImportApiKeys',
  },
  {
    value: 'ImportApplicationInstanceServiceProviderMetadata',
    str: 'ImportApplicationInstanceServiceProviderMetadata',
  },
  {
    value: 'ImportCatalogToGlue',
    str: 'ImportCatalogToGlue',
  },
  {
    value: 'ImportCertificate',
    str: 'ImportCertificate',
  },
  {
    value: 'ImportCertificateAuthorityCertificate',
    str: 'ImportCertificateAuthorityCertificate',
  },
  {
    value: 'ImportDocumentationParts',
    str: 'ImportDocumentationParts',
  },
  {
    value: 'ImportImage',
    str: 'ImportImage',
  },
  {
    value: 'ImportInstance',
    str: 'ImportInstance',
  },
  {
    value: 'ImportKeyPair',
    str: 'ImportKeyPair',
  },
  {
    value: 'ImportMigrationTask',
    str: 'ImportMigrationTask',
  },
  {
    value: 'ImportRestApi',
    str: 'ImportRestApi',
  },
  {
    value: 'ImportS3ManifestFile',
    str: 'ImportS3ManifestFile',
  },
  {
    value: 'ImportServerCatalog',
    str: 'ImportServerCatalog',
  },
  {
    value: 'ImportSnapshot',
    str: 'ImportSnapshot',
  },
  {
    value: 'ImportVolume',
    str: 'ImportVolume',
  },
  {
    value: 'IncreaseReplicationFactor',
    str: 'IncreaseReplicationFactor',
  },
  {
    value: 'IncreaseStreamRetentionPeriod',
    str: 'IncreaseStreamRetentionPeriod',
  },
  {
    value: 'IndexDocuments',
    str: 'IndexDocuments',
  },
  {
    value: 'InitializeCluster',
    str: 'InitializeCluster',
  },
  {
    value: 'InitiateDocumentVersionUpload',
    str: 'InitiateDocumentVersionUpload',
  },
  {
    value: 'InitiateJob',
    str: 'InitiateJob',
  },
  {
    value: 'InitiateMultipartUpload',
    str: 'InitiateMultipartUpload',
  },
  {
    value: 'InitiateVaultLock',
    str: 'InitiateVaultLock',
  },
  {
    value: 'InstallBundle',
    str: 'InstallBundle',
  },
  {
    value: 'InviteAccountToOrganization',
    str: 'InviteAccountToOrganization',
  },
  {
    value: 'InviteDelegate',
    str: 'InviteDelegate',
  },
  {
    value: 'InviteMembers',
    str: 'InviteMembers',
  },
  {
    value: 'InviteUser',
    str: 'InviteUser',
  },
  {
    value: 'InviteUsers',
    str: 'InviteUsers',
  },
  {
    value: 'Invoke20150331',
    str: 'Invoke20150331',
  },
  {
    value: 'InvokeAsync',
    str: 'InvokeAsync',
  },
  {
    value: 'IsEmailReachable',
    str: 'IsEmailReachable',
  },
  {
    value: 'IssueCertificate',
    str: 'IssueCertificate',
  },
  {
    value: 'IsVpcPeered',
    str: 'IsVpcPeered',
  },
  {
    value: 'LeaveOrganization',
    str: 'LeaveOrganization',
  },
  {
    value: 'ListAcceptedPortfolioShares',
    str: 'ListAcceptedPortfolioShares',
  },
  {
    value: 'ListAccessKeys',
    str: 'ListAccessKeys',
  },
  {
    value: 'ListAccountAliases',
    str: 'ListAccountAliases',
  },
  {
    value: 'ListAccountInstances',
    str: 'ListAccountInstances',
  },
  {
    value: 'ListAccountMappings',
    str: 'ListAccountMappings',
  },
  {
    value: 'ListAccounts',
    str: 'ListAccounts',
  },
  {
    value: 'ListAccountsForParent',
    str: 'ListAccountsForParent',
  },
  {
    value: 'ListAccountUsageReportData',
    str: 'ListAccountUsageReportData',
  },
  {
    value: 'ListActionTypes',
    str: 'ListActionTypes',
  },
  {
    value: 'ListActiveViolations',
    str: 'ListActiveViolations',
  },
  {
    value: 'ListActivityTypes',
    str: 'ListActivityTypes',
  },
  {
    value: 'ListAliases',
    str: 'ListAliases',
  },
  {
    value: 'ListAliases20150331',
    str: 'ListAliases20150331',
  },
  {
    value: 'ListAmis',
    str: 'ListAmis',
  },
  {
    value: 'ListApiKeys',
    str: 'ListApiKeys',
  },
  {
    value: 'ListApplianceGroups',
    str: 'ListApplianceGroups',
  },
  {
    value: 'ListApplicationInstanceCertificates',
    str: 'ListApplicationInstanceCertificates',
  },
  {
    value: 'ListApplicationRevisions',
    str: 'ListApplicationRevisions',
  },
  {
    value: 'ListApplications',
    str: 'ListApplications',
  },
  {
    value: 'ListApplicationVersions',
    str: 'ListApplicationVersions',
  },
  {
    value: 'ListAppliedSchemaArns',
    str: 'ListAppliedSchemaArns',
  },
  {
    value: 'ListAssessmentRunAgents',
    str: 'ListAssessmentRunAgents',
  },
  {
    value: 'ListAssessmentRuns',
    str: 'ListAssessmentRuns',
  },
  {
    value: 'ListAssessmentTargets',
    str: 'ListAssessmentTargets',
  },
  {
    value: 'ListAssessmentTemplates',
    str: 'ListAssessmentTemplates',
  },
  {
    value: 'ListAssociations',
    str: 'ListAssociations',
  },
  {
    value: 'ListAttachedGroupPolicies',
    str: 'ListAttachedGroupPolicies',
  },
  {
    value: 'ListAttachedPolicies',
    str: 'ListAttachedPolicies',
  },
  {
    value: 'ListAttachedRolePolicies',
    str: 'ListAttachedRolePolicies',
  },
  {
    value: 'ListAttachedUserPolicies',
    str: 'ListAttachedUserPolicies',
  },
  {
    value: 'ListAttacks',
    str: 'ListAttacks',
  },
  {
    value: 'ListAttributes',
    str: 'ListAttributes',
  },
  {
    value: 'ListAuditFindings',
    str: 'ListAuditFindings',
  },
  {
    value: 'ListAuditTasks',
    str: 'ListAuditTasks',
  },
  {
    value: 'ListAuthorizers',
    str: 'ListAuthorizers',
  },
  {
    value: 'ListAvailableSolutionStacks',
    str: 'ListAvailableSolutionStacks',
  },
  {
    value: 'ListAvailableTiers',
    str: 'ListAvailableTiers',
  },
  {
    value: 'ListAvailableZones',
    str: 'ListAvailableZones',
  },
  {
    value: 'ListAWSServiceAccessForOrganization',
    str: 'ListAWSServiceAccessForOrganization',
  },
  {
    value: 'ListBootstrapActions',
    str: 'ListBootstrapActions',
  },
  {
    value: 'ListBranches',
    str: 'ListBranches',
  },
  {
    value: 'ListBrokers',
    str: 'ListBrokers',
  },
  {
    value: 'ListBuckets',
    str: 'ListBuckets',
  },
  {
    value: 'ListBuilds',
    str: 'ListBuilds',
  },
  {
    value: 'ListBuildsForProject',
    str: 'ListBuildsForProject',
  },
  {
    value: 'ListBundles',
    str: 'ListBundles',
  },
  {
    value: 'ListByteMatchSets',
    str: 'ListByteMatchSets',
  },
  {
    value: 'ListCACertificates',
    str: 'ListCACertificates',
  },
  {
    value: 'ListCDRBuckets',
    str: 'ListCDRBuckets',
  },
  {
    value: 'ListCertificateAuthorities',
    str: 'ListCertificateAuthorities',
  },
  {
    value: 'ListCertificates',
    str: 'ListCertificates',
  },
  {
    value: 'ListCertificatesByCA',
    str: 'ListCertificatesByCA',
  },
  {
    value: 'ListChangeTypeCategories',
    str: 'ListChangeTypeCategories',
  },
  {
    value: 'ListChangeTypeClassificationSummaries',
    str: 'ListChangeTypeClassificationSummaries',
  },
  {
    value: 'ListChangeTypeItems',
    str: 'ListChangeTypeItems',
  },
  {
    value: 'ListChangeTypeOperations',
    str: 'ListChangeTypeOperations',
  },
  {
    value: 'ListChangeTypeSubcategories',
    str: 'ListChangeTypeSubcategories',
  },
  {
    value: 'ListChangeTypeVersionSummaries',
    str: 'ListChangeTypeVersionSummaries',
  },
  {
    value: 'ListChannels',
    str: 'ListChannels',
  },
  {
    value: 'ListChildren',
    str: 'ListChildren',
  },
  {
    value: 'ListCloudFrontOriginAccessIdentities',
    str: 'ListCloudFrontOriginAccessIdentities',
  },
  {
    value: 'ListClusters',
    str: 'ListClusters',
  },
  {
    value: 'ListCollections',
    str: 'ListCollections',
  },
  {
    value: 'ListComplianceStatus',
    str: 'ListComplianceStatus',
  },
  {
    value: 'ListConferenceAppliances',
    str: 'ListConferenceAppliances',
  },
  {
    value: 'ListConfigurationRevisions',
    str: 'ListConfigurationRevisions',
  },
  {
    value: 'ListConfigurations',
    str: 'ListConfigurations',
  },
  {
    value: 'ListConstraintsForPortfolio',
    str: 'ListConstraintsForPortfolio',
  },
  {
    value: 'ListContactTemplates',
    str: 'ListContactTemplates',
  },
  {
    value: 'ListContainerInstances',
    str: 'ListContainerInstances',
  },
  {
    value: 'ListContainers',
    str: 'ListContainers',
  },
  {
    value: 'ListCreateAccountStatus',
    str: 'ListCreateAccountStatus',
  },
  {
    value: 'ListCreatedArtifacts',
    str: 'ListCreatedArtifacts',
  },
  {
    value: 'ListCuratedEnvironmentImages',
    str: 'ListCuratedEnvironmentImages',
  },
  {
    value: 'ListDatasets',
    str: 'ListDatasets',
  },
  {
    value: 'ListDataSources',
    str: 'ListDataSources',
  },
  {
    value: 'ListDatastores',
    str: 'ListDatastores',
  },
  {
    value: 'ListDelegates',
    str: 'ListDelegates',
  },
  {
    value: 'ListDeliveryStream',
    str: 'ListDeliveryStream',
  },
  {
    value: 'ListDeploymentConfigs',
    str: 'ListDeploymentConfigs',
  },
  {
    value: 'ListDeploymentGroups',
    str: 'ListDeploymentGroups',
  },
  {
    value: 'ListDeploymentInstances',
    str: 'ListDeploymentInstances',
  },
  {
    value: 'ListDeployments',
    str: 'ListDeployments',
  },
  {
    value: 'ListDetectors',
    str: 'ListDetectors',
  },
  {
    value: 'ListDevelopmentSchemaArns',
    str: 'ListDevelopmentSchemaArns',
  },
  {
    value: 'ListDirectories',
    str: 'ListDirectories',
  },
  {
    value: 'ListDirectoryAssociations',
    str: 'ListDirectoryAssociations',
  },
  {
    value: 'ListDiscoveredResources',
    str: 'ListDiscoveredResources',
  },
  {
    value: 'ListDistributions',
    str: 'ListDistributions',
  },
  {
    value: 'ListDistributionsByWebACLId',
    str: 'ListDistributionsByWebACLId',
  },
  {
    value: 'ListDnssec',
    str: 'ListDnssec',
  },
  {
    value: 'ListDocuments',
    str: 'ListDocuments',
  },
  {
    value: 'ListDomainNames',
    str: 'ListDomainNames',
  },
  {
    value: 'ListDomains',
    str: 'ListDomains',
  },
  {
    value: 'ListElasticsearchInstanceTypeDetails',
    str: 'ListElasticsearchInstanceTypeDetails',
  },
  {
    value: 'ListElasticsearchInstanceTypes',
    str: 'ListElasticsearchInstanceTypes',
  },
  {
    value: 'ListElasticsearchVersions',
    str: 'ListElasticsearchVersions',
  },
  {
    value: 'ListEndpointConfigs',
    str: 'ListEndpointConfigs',
  },
  {
    value: 'ListEndpoints',
    str: 'ListEndpoints',
  },
  {
    value: 'ListEntitiesForPolicy',
    str: 'ListEntitiesForPolicy',
  },
  {
    value: 'ListEventSourceMappings20150331',
    str: 'ListEventSourceMappings20150331',
  },
  {
    value: 'ListEventSubscriptions',
    str: 'ListEventSubscriptions',
  },
  {
    value: 'ListFacetAttributes',
    str: 'ListFacetAttributes',
  },
  {
    value: 'ListFacetNames',
    str: 'ListFacetNames',
  },
  {
    value: 'ListFileShares',
    str: 'ListFileShares',
  },
  {
    value: 'ListFilters',
    str: 'ListFilters',
  },
  {
    value: 'ListFindings',
    str: 'ListFindings',
  },
  {
    value: 'ListFleets',
    str: 'ListFleets',
  },
  {
    value: 'ListFreeRTOSVersions',
    str: 'ListFreeRTOSVersions',
  },
  {
    value: 'ListFunctions20150331',
    str: 'ListFunctions20150331',
  },
  {
    value: 'ListGateways',
    str: 'ListGateways',
  },
  {
    value: 'ListGeoLocations',
    str: 'ListGeoLocations',
  },
  {
    value: 'ListGitHubAccountTokenNames',
    str: 'ListGitHubAccountTokenNames',
  },
  {
    value: 'ListGlobalTables',
    str: 'ListGlobalTables',
  },
  {
    value: 'ListGraphqlApis',
    str: 'ListGraphqlApis',
  },
  {
    value: 'ListGroupMembers',
    str: 'ListGroupMembers',
  },
  {
    value: 'ListGroupPolicies',
    str: 'ListGroupPolicies',
  },
  {
    value: 'ListGroupResources',
    str: 'ListGroupResources',
  },
  {
    value: 'ListGroups',
    str: 'ListGroups',
  },
  {
    value: 'ListGroupsForUser',
    str: 'ListGroupsForUser',
  },
  {
    value: 'ListHandshakesForAccount',
    str: 'ListHandshakesForAccount',
  },
  {
    value: 'ListHandshakesForOrganization',
    str: 'ListHandshakesForOrganization',
  },
  {
    value: 'ListHapgs',
    str: 'ListHapgs',
  },
  {
    value: 'ListHardwarePlatforms',
    str: 'ListHardwarePlatforms',
  },
  {
    value: 'ListHealthChecks',
    str: 'ListHealthChecks',
  },
  {
    value: 'ListHostedZones',
    str: 'ListHostedZones',
  },
  {
    value: 'ListHostedZonesByName',
    str: 'ListHostedZonesByName',
  },
  {
    value: 'ListHsms',
    str: 'ListHsms',
  },
  {
    value: 'ListIdentities',
    str: 'ListIdentities',
  },
  {
    value: 'ListIdentityPools',
    str: 'ListIdentityPools',
  },
  {
    value: 'ListIdentityPoolUsage',
    str: 'ListIdentityPoolUsage',
  },
  {
    value: 'ListIndices',
    str: 'ListIndices',
  },
  {
    value: 'ListInstanceGroups',
    str: 'ListInstanceGroups',
  },
  {
    value: 'ListInstanceProfiles',
    str: 'ListInstanceProfiles',
  },
  {
    value: 'ListInstanceProfilesForRole',
    str: 'ListInstanceProfilesForRole',
  },
  {
    value: 'ListInstances',
    str: 'ListInstances',
  },
  {
    value: 'ListInvalidations',
    str: 'ListInvalidations',
  },
  {
    value: 'ListInvitations',
    str: 'ListInvitations',
  },
  {
    value: 'ListIpRoutes',
    str: 'ListIpRoutes',
  },
  {
    value: 'ListIPSets',
    str: 'ListIPSets',
  },
  {
    value: 'ListJobExecutionsForJob',
    str: 'ListJobExecutionsForJob',
  },
  {
    value: 'ListJobExecutionsForThing',
    str: 'ListJobExecutionsForThing',
  },
  {
    value: 'ListJobs',
    str: 'ListJobs',
  },
  {
    value: 'ListJobsByPipeline',
    str: 'ListJobsByPipeline',
  },
  {
    value: 'ListJobsByStatus',
    str: 'ListJobsByStatus',
  },
  {
    value: 'ListJobsForThingGroup',
    str: 'ListJobsForThingGroup',
  },
  {
    value: 'ListJobTemplates',
    str: 'ListJobTemplates',
  },
  {
    value: 'ListLaunchPaths',
    str: 'ListLaunchPaths',
  },
  {
    value: 'ListLexicons',
    str: 'ListLexicons',
  },
  {
    value: 'ListLocalDisks',
    str: 'ListLocalDisks',
  },
  {
    value: 'ListLunaClients',
    str: 'ListLunaClients',
  },
  {
    value: 'ListManagedSchemaArns',
    str: 'ListManagedSchemaArns',
  },
  {
    value: 'ListMeetingEvents',
    str: 'ListMeetingEvents',
  },
  {
    value: 'ListMeetingsReportData',
    str: 'ListMeetingsReportData',
  },
  {
    value: 'ListMembers',
    str: 'ListMembers',
  },
  {
    value: 'ListMembersInMailGroup',
    str: 'ListMembersInMailGroup',
  },
  {
    value: 'ListMFADevices',
    str: 'ListMFADevices',
  },
  {
    value: 'ListModels',
    str: 'ListModels',
  },
  {
    value: 'ListMultipartUploads',
    str: 'ListMultipartUploads',
  },
  {
    value: 'ListNamedQueries',
    str: 'ListNamedQueries',
  },
  {
    value: 'ListNamespaces',
    str: 'ListNamespaces',
  },
  {
    value: 'ListNotebookInstanceLifecycleConfigs',
    str: 'ListNotebookInstanceLifecycleConfigs',
  },
  {
    value: 'ListNotebookInstances',
    str: 'ListNotebookInstances',
  },
  {
    value: 'ListOnPremisesInstances',
    str: 'ListOnPremisesInstances',
  },
  {
    value: 'ListOpenIDConnectProviders',
    str: 'ListOpenIDConnectProviders',
  },
  {
    value: 'ListOperations',
    str: 'ListOperations',
  },
  {
    value: 'ListOrganizationalUnitsForParent',
    str: 'ListOrganizationalUnitsForParent',
  },
  {
    value: 'ListOrganizations',
    str: 'ListOrganizations',
  },
  {
    value: 'ListOTAUpdates',
    str: 'ListOTAUpdates',
  },
  {
    value: 'ListOutgoingCertificates',
    str: 'ListOutgoingCertificates',
  },
  {
    value: 'ListParents',
    str: 'ListParents',
  },
  {
    value: 'ListParts',
    str: 'ListParts',
  },
  {
    value: 'ListPath',
    str: 'ListPath',
  },
  {
    value: 'ListPendingOperations',
    str: 'ListPendingOperations',
  },
  {
    value: 'ListPermissionSets',
    str: 'ListPermissionSets',
  },
  {
    value: 'ListPipelines',
    str: 'ListPipelines',
  },
  {
    value: 'ListPolicies',
    str: 'ListPolicies',
  },
  {
    value: 'ListPoliciesForTarget',
    str: 'ListPoliciesForTarget',
  },
  {
    value: 'ListPolicyPrincipals',
    str: 'ListPolicyPrincipals',
  },
  {
    value: 'ListPolicyVersions',
    str: 'ListPolicyVersions',
  },
  {
    value: 'ListPortfolioAccess',
    str: 'ListPortfolioAccess',
  },
  {
    value: 'ListPortfolios',
    str: 'ListPortfolios',
  },
  {
    value: 'ListPortfoliosForProduct',
    str: 'ListPortfoliosForProduct',
  },
  {
    value: 'ListPresets',
    str: 'ListPresets',
  },
  {
    value: 'ListPrincipal',
    str: 'ListPrincipal',
  },
  {
    value: 'ListPrincipalPolicies',
    str: 'ListPrincipalPolicies',
  },
  {
    value: 'ListPrincipalsForPortfolio',
    str: 'ListPrincipalsForPortfolio',
  },
  {
    value: 'ListPrincipalThings',
    str: 'ListPrincipalThings',
  },
  {
    value: 'ListProfileAssociations',
    str: 'ListProfileAssociations',
  },
  {
    value: 'ListProgressUpdateStreams',
    str: 'ListProgressUpdateStreams',
  },
  {
    value: 'ListProjects',
    str: 'ListProjects',
  },
  {
    value: 'ListProjectSnapshots',
    str: 'ListProjectSnapshots',
  },
  {
    value: 'ListProtections',
    str: 'ListProtections',
  },
  {
    value: 'ListProvisioningArtifacts',
    str: 'ListProvisioningArtifacts',
  },
  {
    value: 'ListPublicKeys',
    str: 'ListPublicKeys',
  },
  {
    value: 'ListPublishedSchemaArns',
    str: 'ListPublishedSchemaArns',
  },
  {
    value: 'ListQueryExecutions',
    str: 'ListQueryExecutions',
  },
  {
    value: 'ListQueues',
    str: 'ListQueues',
  },
  {
    value: 'ListRecordHistory',
    str: 'ListRecordHistory',
  },
  {
    value: 'ListRegisteredEcsClusters',
    str: 'ListRegisteredEcsClusters',
  },
  {
    value: 'ListReleases',
    str: 'ListReleases',
  },
  {
    value: 'ListRepositories',
    str: 'ListRepositories',
  },
  {
    value: 'ListResolvers',
    str: 'ListResolvers',
  },
  {
    value: 'ListResourceRecordSets',
    str: 'ListResourceRecordSets',
  },
  {
    value: 'ListResources',
    str: 'ListResources',
  },
  {
    value: 'ListResourcesForWebACL',
    str: 'ListResourcesForWebACL',
  },
  {
    value: 'ListRestrictedExecutionTimes',
    str: 'ListRestrictedExecutionTimes',
  },
  {
    value: 'ListReusableDelegationSets',
    str: 'ListReusableDelegationSets',
  },
  {
    value: 'ListRfcCorrespondences',
    str: 'ListRfcCorrespondences',
  },
  {
    value: 'ListRfcSummaries',
    str: 'ListRfcSummaries',
  },
  {
    value: 'ListRfcTechnicians',
    str: 'ListRfcTechnicians',
  },
  {
    value: 'ListRoleAliases',
    str: 'ListRoleAliases',
  },
  {
    value: 'ListRolePolicies',
    str: 'ListRolePolicies',
  },
  {
    value: 'ListRoles',
    str: 'ListRoles',
  },
  {
    value: 'ListRoots',
    str: 'ListRoots',
  },
  {
    value: 'ListRules',
    str: 'ListRules',
  },
  {
    value: 'ListRulesPackages',
    str: 'ListRulesPackages',
  },
  {
    value: 'ListSAMLProviders',
    str: 'ListSAMLProviders',
  },
  {
    value: 'ListScheduledAudits',
    str: 'ListScheduledAudits',
  },
  {
    value: 'ListSchemaExtensions',
    str: 'ListSchemaExtensions',
  },
  {
    value: 'ListSecrets',
    str: 'ListSecrets',
  },
  {
    value: 'ListSecretVersionIds',
    str: 'ListSecretVersionIds',
  },
  {
    value: 'ListSecurityConfigurations',
    str: 'ListSecurityConfigurations',
  },
  {
    value: 'ListSecurityProfiles',
    str: 'ListSecurityProfiles',
  },
  {
    value: 'ListSecurityProfilesForTarget',
    str: 'ListSecurityProfilesForTarget',
  },
  {
    value: 'ListServerCertificates',
    str: 'ListServerCertificates',
  },
  {
    value: 'ListServices',
    str: 'ListServices',
  },
  {
    value: 'ListServiceSpecificCredentials',
    str: 'ListServiceSpecificCredentials',
  },
  {
    value: 'ListSigningCertificates',
    str: 'ListSigningCertificates',
  },
  {
    value: 'ListSigningJobs',
    str: 'ListSigningJobs',
  },
  {
    value: 'ListSizeConstraintSets',
    str: 'ListSizeConstraintSets',
  },
  {
    value: 'ListSkillPermissionScopeConsents',
    str: 'ListSkillPermissionScopeConsents',
  },
  {
    value: 'ListSkills',
    str: 'ListSkills',
  },
  {
    value: 'ListSkillsForOrganization',
    str: 'ListSkillsForOrganization',
  },
  {
    value: 'ListSoftwareConfigurations',
    str: 'ListSoftwareConfigurations',
  },
  {
    value: 'ListSqlInjectionMatchSets',
    str: 'ListSqlInjectionMatchSets',
  },
  {
    value: 'ListSSHPublicKeys',
    str: 'ListSSHPublicKeys',
  },
  {
    value: 'ListStackResources',
    str: 'ListStackResources',
  },
  {
    value: 'ListStacks',
    str: 'ListStacks',
  },
  {
    value: 'ListStackSummaries',
    str: 'ListStackSummaries',
  },
  {
    value: 'ListSteps',
    str: 'ListSteps',
  },
  {
    value: 'ListStreamConsumers',
    str: 'ListStreamConsumers',
  },
  {
    value: 'ListStreamingDistributions',
    str: 'ListStreamingDistributions',
  },
  {
    value: 'ListStreamProcessors',
    str: 'ListStreamProcessors',
  },
  {
    value: 'ListStreams',
    str: 'ListStreams',
  },
  {
    value: 'ListSubnetSummaries',
    str: 'ListSubnetSummaries',
  },
  {
    value: 'ListTables',
    str: 'ListTables',
  },
  {
    value: 'ListTags',
    str: 'ListTags',
  },
  {
    value: 'ListTags20170331',
    str: 'ListTags20170331',
  },
  {
    value: 'ListTagsForCertificate',
    str: 'ListTagsForCertificate',
  },
  {
    value: 'ListTagsForDeliveryStream',
    str: 'ListTagsForDeliveryStream',
  },
  {
    value: 'ListTagsForDomain',
    str: 'ListTagsForDomain',
  },
  {
    value: 'ListTagsForProject',
    str: 'ListTagsForProject',
  },
  {
    value: 'ListTagsForResource',
    str: 'ListTagsForResource',
  },
  {
    value: 'ListTagsForResources',
    str: 'ListTagsForResources',
  },
  {
    value: 'ListTagsForStream',
    str: 'ListTagsForStream',
  },
  {
    value: 'ListTagsForVault',
    str: 'ListTagsForVault',
  },
  {
    value: 'ListTargetsForPolicy',
    str: 'ListTargetsForPolicy',
  },
  {
    value: 'ListTargetsForSecurityProfile',
    str: 'ListTargetsForSecurityProfile',
  },
  {
    value: 'ListTaskDefinitionFamilies',
    str: 'ListTaskDefinitionFamilies',
  },
  {
    value: 'ListTaskDefinitions',
    str: 'ListTaskDefinitions',
  },
  {
    value: 'ListTasks',
    str: 'ListTasks',
  },
  {
    value: 'ListTeamMembers',
    str: 'ListTeamMembers',
  },
  {
    value: 'ListThingGroups',
    str: 'ListThingGroups',
  },
  {
    value: 'ListThingGroupsForThing',
    str: 'ListThingGroupsForThing',
  },
  {
    value: 'ListThingPrincipals',
    str: 'ListThingPrincipals',
  },
  {
    value: 'ListThingRegistrationTaskReports',
    str: 'ListThingRegistrationTaskReports',
  },
  {
    value: 'ListThingRegistrationTasks',
    str: 'ListThingRegistrationTasks',
  },
  {
    value: 'ListThings',
    str: 'ListThings',
  },
  {
    value: 'ListThingsInThingGroup',
    str: 'ListThingsInThingGroup',
  },
  {
    value: 'ListThingTypes',
    str: 'ListThingTypes',
  },
  {
    value: 'ListThreatIntelSets',
    str: 'ListThreatIntelSets',
  },
  {
    value: 'ListTopicRules',
    str: 'ListTopicRules',
  },
  {
    value: 'ListTrafficPolicies',
    str: 'ListTrafficPolicies',
  },
  {
    value: 'ListTrafficPolicyInstances',
    str: 'ListTrafficPolicyInstances',
  },
  {
    value: 'ListTrafficPolicyInstancesByHostedZone',
    str: 'ListTrafficPolicyInstancesByHostedZone',
  },
  {
    value: 'ListTrafficPolicyInstancesByPolicy',
    str: 'ListTrafficPolicyInstancesByPolicy',
  },
  {
    value: 'ListTrafficPolicyVersions',
    str: 'ListTrafficPolicyVersions',
  },
  {
    value: 'ListTrainingJobs',
    str: 'ListTrainingJobs',
  },
  {
    value: 'ListTransfers',
    str: 'ListTransfers',
  },
  {
    value: 'ListTypedLinkFacetAttributes',
    str: 'ListTypedLinkFacetAttributes',
  },
  {
    value: 'ListTypedLinkFacetNames',
    str: 'ListTypedLinkFacetNames',
  },
  {
    value: 'ListTypes',
    str: 'ListTypes',
  },
  {
    value: 'ListUnregisteredEcsClusters',
    str: 'ListUnregisteredEcsClusters',
  },
  {
    value: 'ListUserPolicies',
    str: 'ListUserPolicies',
  },
  {
    value: 'ListUserPoolClients',
    str: 'ListUserPoolClients',
  },
  {
    value: 'ListUserPools',
    str: 'ListUserPools',
  },
  {
    value: 'ListUserProfiles',
    str: 'ListUserProfiles',
  },
  {
    value: 'ListUsers',
    str: 'ListUsers',
  },
  {
    value: 'ListUsersInGroup',
    str: 'ListUsersInGroup',
  },
  {
    value: 'ListV2LoggingLevels',
    str: 'ListV2LoggingLevels',
  },
  {
    value: 'ListVaults',
    str: 'ListVaults',
  },
  {
    value: 'ListVersionsByFunction20150331',
    str: 'ListVersionsByFunction20150331',
  },
  {
    value: 'ListViolationEvents',
    str: 'ListViolationEvents',
  },
  {
    value: 'ListVirtualMFADevices',
    str: 'ListVirtualMFADevices',
  },
  {
    value: 'ListVolumeInitiators',
    str: 'ListVolumeInitiators',
  },
  {
    value: 'ListVolumeRecoveryPoints',
    str: 'ListVolumeRecoveryPoints',
  },
  {
    value: 'ListVolumes',
    str: 'ListVolumes',
  },
  {
    value: 'ListVpcSummaries',
    str: 'ListVpcSummaries',
  },
  {
    value: 'ListWebACLs',
    str: 'ListWebACLs',
  },
  {
    value: 'ListWebhooks',
    str: 'ListWebhooks',
  },
  {
    value: 'ListWorkflowTypes',
    str: 'ListWorkflowTypes',
  },
  {
    value: 'ListXssMatchSets',
    str: 'ListXssMatchSets',
  },
  {
    value: 'LockDomain',
    str: 'LockDomain',
  },
  {
    value: 'LogoutUser',
    str: 'LogoutUser',
  },
  {
    value: 'LookupDeveloperIdentity',
    str: 'LookupDeveloperIdentity',
  },
  {
    value: 'LookupEvents',
    str: 'LookupEvents',
  },
  {
    value: 'MergeDeveloperIdentities',
    str: 'MergeDeveloperIdentities',
  },
  {
    value: 'MergeShards',
    str: 'MergeShards',
  },
  {
    value: 'ModifyCacheCluster',
    str: 'ModifyCacheCluster',
  },
  {
    value: 'ModifyCacheParameterGroup',
    str: 'ModifyCacheParameterGroup',
  },
  {
    value: 'ModifyCacheSubnetGroup',
    str: 'ModifyCacheSubnetGroup',
  },
  {
    value: 'ModifyCluster',
    str: 'ModifyCluster',
  },
  {
    value: 'ModifyClusterIamRoles',
    str: 'ModifyClusterIamRoles',
  },
  {
    value: 'ModifyClusterParameterGroup',
    str: 'ModifyClusterParameterGroup',
  },
  {
    value: 'ModifyClusterSubnetGroup',
    str: 'ModifyClusterSubnetGroup',
  },
  {
    value: 'ModifyDBCluster',
    str: 'ModifyDBCluster',
  },
  {
    value: 'ModifyDBClusterParameterGroup',
    str: 'ModifyDBClusterParameterGroup',
  },
  {
    value: 'ModifyDbClusterSnapshotAttribute',
    str: 'ModifyDbClusterSnapshotAttribute',
  },
  {
    value: 'ModifyDBInstance',
    str: 'ModifyDBInstance',
  },
  {
    value: 'ModifyDBParameterGroup',
    str: 'ModifyDBParameterGroup',
  },
  {
    value: 'ModifyDBSnapshotAttribute',
    str: 'ModifyDBSnapshotAttribute',
  },
  {
    value: 'ModifyDBSubnetGroup',
    str: 'ModifyDBSubnetGroup',
  },
  {
    value: 'ModifyDocumentPermission',
    str: 'ModifyDocumentPermission',
  },
  {
    value: 'ModifyEndpoint',
    str: 'ModifyEndpoint',
  },
  {
    value: 'ModifyEventSubscription',
    str: 'ModifyEventSubscription',
  },
  {
    value: 'ModifyHapg',
    str: 'ModifyHapg',
  },
  {
    value: 'ModifyHosts',
    str: 'ModifyHosts',
  },
  {
    value: 'ModifyHsm',
    str: 'ModifyHsm',
  },
  {
    value: 'ModifyIdentityIdFormat',
    str: 'ModifyIdentityIdFormat',
  },
  {
    value: 'ModifyIdFormat',
    str: 'ModifyIdFormat',
  },
  {
    value: 'ModifyImageAttribute',
    str: 'ModifyImageAttribute',
  },
  {
    value: 'ModifyInstanceAttribute',
    str: 'ModifyInstanceAttribute',
  },
  {
    value: 'ModifyInstanceGroups',
    str: 'ModifyInstanceGroups',
  },
  {
    value: 'ModifyInstancePlacement',
    str: 'ModifyInstancePlacement',
  },
  {
    value: 'ModifyListener',
    str: 'ModifyListener',
  },
  {
    value: 'ModifyLoadBalancerAttributes',
    str: 'ModifyLoadBalancerAttributes',
  },
  {
    value: 'ModifyLunaClient',
    str: 'ModifyLunaClient',
  },
  {
    value: 'ModifyMountTargetSecurityGroups',
    str: 'ModifyMountTargetSecurityGroups',
  },
  {
    value: 'ModifyNetworkInterfaceAttribute',
    str: 'ModifyNetworkInterfaceAttribute',
  },
  {
    value: 'ModifyOptionGroup',
    str: 'ModifyOptionGroup',
  },
  {
    value: 'ModifyParameterGroup',
    str: 'ModifyParameterGroup',
  },
  {
    value: 'ModifyProvisionedCapacity',
    str: 'ModifyProvisionedCapacity',
  },
  {
    value: 'ModifyReplicationGroup',
    str: 'ModifyReplicationGroup',
  },
  {
    value: 'ModifyReplicationInstance',
    str: 'ModifyReplicationInstance',
  },
  {
    value: 'ModifyReplicationSubnetGroup',
    str: 'ModifyReplicationSubnetGroup',
  },
  {
    value: 'ModifyReplicationTask',
    str: 'ModifyReplicationTask',
  },
  {
    value: 'ModifyReservedInstances',
    str: 'ModifyReservedInstances',
  },
  {
    value: 'ModifyRule',
    str: 'ModifyRule',
  },
  {
    value: 'ModifySnapshotAttribute',
    str: 'ModifySnapshotAttribute',
  },
  {
    value: 'ModifySnapshotCopyRetentionPeriod',
    str: 'ModifySnapshotCopyRetentionPeriod',
  },
  {
    value: 'ModifySpotFleetRequest',
    str: 'ModifySpotFleetRequest',
  },
  {
    value: 'ModifySubnetAttribute',
    str: 'ModifySubnetAttribute',
  },
  {
    value: 'ModifySubnetGroup',
    str: 'ModifySubnetGroup',
  },
  {
    value: 'ModifyTargetGroup',
    str: 'ModifyTargetGroup',
  },
  {
    value: 'ModifyTargetGroupAttributes',
    str: 'ModifyTargetGroupAttributes',
  },
  {
    value: 'ModifyVolume',
    str: 'ModifyVolume',
  },
  {
    value: 'ModifyVolumeAttribute',
    str: 'ModifyVolumeAttribute',
  },
  {
    value: 'ModifyVpcAttribute',
    str: 'ModifyVpcAttribute',
  },
  {
    value: 'ModifyVpcEndpoint',
    str: 'ModifyVpcEndpoint',
  },
  {
    value: 'ModifyVpcPeeringConnectionOptions',
    str: 'ModifyVpcPeeringConnectionOptions',
  },
  {
    value: 'MonitorInstances',
    str: 'MonitorInstances',
  },
  {
    value: 'MoveAccount',
    str: 'MoveAccount',
  },
  {
    value: 'MoveAddressToVpc',
    str: 'MoveAddressToVpc',
  },
  {
    value: 'NotifyMigrationTaskState',
    str: 'NotifyMigrationTaskState',
  },
  {
    value: 'PaginatedOrganizationActivity',
    str: 'PaginatedOrganizationActivity',
  },
  {
    value: 'PeerVpc',
    str: 'PeerVpc',
  },
  {
    value: 'Ping',
    str: 'Ping',
  },
  {
    value: 'PolicyExecutionEvent',
    str: 'PolicyExecutionEvent',
  },
  {
    value: 'PollForJobs',
    str: 'PollForJobs',
  },
  {
    value: 'PollForTask',
    str: 'PollForTask',
  },
  {
    value: 'PollForThirdPartyJobs',
    str: 'PollForThirdPartyJobs',
  },
  {
    value: 'PostContent',
    str: 'PostContent',
  },
  {
    value: 'PostCorrespondenceToRfc',
    str: 'PostCorrespondenceToRfc',
  },
  {
    value: 'PostTagsForDomain',
    str: 'PostTagsForDomain',
  },
  {
    value: 'PostText',
    str: 'PostText',
  },
  {
    value: 'PreviewAgents',
    str: 'PreviewAgents',
  },
  {
    value: 'ProcessWebhook',
    str: 'ProcessWebhook',
  },
  {
    value: 'PromoteReadReplica',
    str: 'PromoteReadReplica',
  },
  {
    value: 'PromoteReadReplicaDbCluster',
    str: 'PromoteReadReplicaDbCluster',
  },
  {
    value: 'ProvisionProduct',
    str: 'ProvisionProduct',
  },
  {
    value: 'ProvisionThing',
    str: 'ProvisionThing',
  },
  {
    value: 'PublishAnnotations',
    str: 'PublishAnnotations',
  },
  {
    value: 'PublishComments',
    str: 'PublishComments',
  },
  {
    value: 'PublishSchema',
    str: 'PublishSchema',
  },
  {
    value: 'PublishVersion20150331',
    str: 'PublishVersion20150331',
  },
  {
    value: 'PurchaseHostReservation',
    str: 'PurchaseHostReservation',
  },
  {
    value: 'PurchaseReservedCacheNodesOffering',
    str: 'PurchaseReservedCacheNodesOffering',
  },
  {
    value: 'PurchaseReservedCapacityOfferings',
    str: 'PurchaseReservedCapacityOfferings',
  },
  {
    value: 'PurchaseReservedDBInstancesOffering',
    str: 'PurchaseReservedDBInstancesOffering',
  },
  {
    value: 'PurchaseReservedInstancesOffering',
    str: 'PurchaseReservedInstancesOffering',
  },
  {
    value: 'PurchaseReservedNodeOffering',
    str: 'PurchaseReservedNodeOffering',
  },
  {
    value: 'PurchaseScheduledInstances',
    str: 'PurchaseScheduledInstances',
  },
  {
    value: 'PushDomain',
    str: 'PushDomain',
  },
  {
    value: 'PutActionRevision',
    str: 'PutActionRevision',
  },
  {
    value: 'PutAlarmWithState',
    str: 'PutAlarmWithState',
  },
  {
    value: 'PutApplicationPolicy',
    str: 'PutApplicationPolicy',
  },
  {
    value: 'PutApprovalResult',
    str: 'PutApprovalResult',
  },
  {
    value: 'PutAttributes',
    str: 'PutAttributes',
  },
  {
    value: 'PutBot',
    str: 'PutBot',
  },
  {
    value: 'PutBotAlias',
    str: 'PutBotAlias',
  },
  {
    value: 'PutBucketAcl',
    str: 'PutBucketAcl',
  },
  {
    value: 'PutBucketCors',
    str: 'PutBucketCors',
  },
  {
    value: 'PutBucketEncryption',
    str: 'PutBucketEncryption',
  },
  {
    value: 'PutBucketLifecycle',
    str: 'PutBucketLifecycle',
  },
  {
    value: 'PutBucketLogging',
    str: 'PutBucketLogging',
  },
  {
    value: 'PutBucketNotification',
    str: 'PutBucketNotification',
  },
  {
    value: 'PutBucketPolicy',
    str: 'PutBucketPolicy',
  },
  {
    value: 'PutBucketReplication',
    str: 'PutBucketReplication',
  },
  {
    value: 'PutBucketRequestPayment',
    str: 'PutBucketRequestPayment',
  },
  {
    value: 'PutBucketTagging',
    str: 'PutBucketTagging',
  },
  {
    value: 'PutBucketVersioning',
    str: 'PutBucketVersioning',
  },
  {
    value: 'PutBucketWebsite',
    str: 'PutBucketWebsite',
  },
  {
    value: 'PutConfigRule',
    str: 'PutConfigRule',
  },
  {
    value: 'PutConfigurationRecorder',
    str: 'PutConfigurationRecorder',
  },
  {
    value: 'PutContainerPolicy',
    str: 'PutContainerPolicy',
  },
  {
    value: 'PutCorsPolicy',
    str: 'PutCorsPolicy',
  },
  {
    value: 'PutDashboard',
    str: 'PutDashboard',
  },
  {
    value: 'PutDashboardForAccount',
    str: 'PutDashboardForAccount',
  },
  {
    value: 'PutDeliveryChannel',
    str: 'PutDeliveryChannel',
  },
  {
    value: 'PutDestination',
    str: 'PutDestination',
  },
  {
    value: 'PutDestinationPolicy',
    str: 'PutDestinationPolicy',
  },
  {
    value: 'PutEncryptionConfig',
    str: 'PutEncryptionConfig',
  },
  {
    value: 'PutEvaluations',
    str: 'PutEvaluations',
  },
  {
    value: 'PutEventSelectors',
    str: 'PutEventSelectors',
  },
  {
    value: 'PutEventStream',
    str: 'PutEventStream',
  },
  {
    value: 'PutFunctionConcurrency20171031',
    str: 'PutFunctionConcurrency20171031',
  },
  {
    value: 'PutGatewayResponse',
    str: 'PutGatewayResponse',
  },
  {
    value: 'PutGroupPolicy',
    str: 'PutGroupPolicy',
  },
  {
    value: 'PutIntegration',
    str: 'PutIntegration',
  },
  {
    value: 'PutIntegrationResponse',
    str: 'PutIntegrationResponse',
  },
  {
    value: 'PutIntent',
    str: 'PutIntent',
  },
  {
    value: 'PutJobFailureResult',
    str: 'PutJobFailureResult',
  },
  {
    value: 'PutJobSuccessResult',
    str: 'PutJobSuccessResult',
  },
  {
    value: 'PutLexicon',
    str: 'PutLexicon',
  },
  {
    value: 'PutLifecycleHook',
    str: 'PutLifecycleHook',
  },
  {
    value: 'PutLoggingOptions',
    str: 'PutLoggingOptions',
  },
  {
    value: 'PutMailboxPermissions',
    str: 'PutMailboxPermissions',
  },
  {
    value: 'PutMethod',
    str: 'PutMethod',
  },
  {
    value: 'PutMethodResponse',
    str: 'PutMethodResponse',
  },
  {
    value: 'PutMetricAlarm',
    str: 'PutMetricAlarm',
  },
  {
    value: 'PutMetricFilter',
    str: 'PutMetricFilter',
  },
  {
    value: 'PutNotificationConfiguration',
    str: 'PutNotificationConfiguration',
  },
  {
    value: 'PutParameter',
    str: 'PutParameter',
  },
  {
    value: 'PutPermissionsPolicy',
    str: 'PutPermissionsPolicy',
  },
  {
    value: 'PutPipelineDefinition',
    str: 'PutPipelineDefinition',
  },
  {
    value: 'PutPolicy',
    str: 'PutPolicy',
  },
  {
    value: 'PutRepositoryTriggers',
    str: 'PutRepositoryTriggers',
  },
  {
    value: 'PutResourceAttributes',
    str: 'PutResourceAttributes',
  },
  {
    value: 'PutResourcePolicy',
    str: 'PutResourcePolicy',
  },
  {
    value: 'PutRestApi',
    str: 'PutRestApi',
  },
  {
    value: 'PutRetentionPolicy',
    str: 'PutRetentionPolicy',
  },
  {
    value: 'PutRolePolicy',
    str: 'PutRolePolicy',
  },
  {
    value: 'PutRoomSkillParameter',
    str: 'PutRoomSkillParameter',
  },
  {
    value: 'PutRule',
    str: 'PutRule',
  },
  {
    value: 'PutScalingPolicy',
    str: 'PutScalingPolicy',
  },
  {
    value: 'PutScheduledUpdateGroupAction',
    str: 'PutScheduledUpdateGroupAction',
  },
  {
    value: 'PutSchemaFromJson',
    str: 'PutSchemaFromJson',
  },
  {
    value: 'PutSecretValue',
    str: 'PutSecretValue',
  },
  {
    value: 'PutSkillPermissionScopeConsents',
    str: 'PutSkillPermissionScopeConsents',
  },
  {
    value: 'PutSlotType',
    str: 'PutSlotType',
  },
  {
    value: 'PutSubscriptionFilter',
    str: 'PutSubscriptionFilter',
  },
  {
    value: 'PutTargets',
    str: 'PutTargets',
  },
  {
    value: 'PutThirdPartyJobFailureResult',
    str: 'PutThirdPartyJobFailureResult',
  },
  {
    value: 'PutThirdPartyJobSuccessResult',
    str: 'PutThirdPartyJobSuccessResult',
  },
  {
    value: 'PutUserPolicy',
    str: 'PutUserPolicy',
  },
  {
    value: 'PutWebhook',
    str: 'PutWebhook',
  },
  {
    value: 'QueryObjects',
    str: 'QueryObjects',
  },
  {
    value: 'ReadJob',
    str: 'ReadJob',
  },
  {
    value: 'ReadPipeline',
    str: 'ReadPipeline',
  },
  {
    value: 'ReadPreset',
    str: 'ReadPreset',
  },
  {
    value: 'RebootBroker',
    str: 'RebootBroker',
  },
  {
    value: 'RebootCacheCluster',
    str: 'RebootCacheCluster',
  },
  {
    value: 'RebootCluster',
    str: 'RebootCluster',
  },
  {
    value: 'RebootDBInstance',
    str: 'RebootDBInstance',
  },
  {
    value: 'RebootInstance',
    str: 'RebootInstance',
  },
  {
    value: 'RebootInstances',
    str: 'RebootInstances',
  },
  {
    value: 'RebootNode',
    str: 'RebootNode',
  },
  {
    value: 'RebootReplicationInstance',
    str: 'RebootReplicationInstance',
  },
  {
    value: 'RebootWorkspaces',
    str: 'RebootWorkspaces',
  },
  {
    value: 'RebuildEnvironment',
    str: 'RebuildEnvironment',
  },
  {
    value: 'RebuildWorkspaces',
    str: 'RebuildWorkspaces',
  },
  {
    value: 'RecordLifecycleActionHeartbeat',
    str: 'RecordLifecycleActionHeartbeat',
  },
  {
    value: 'RedeemPromoCode',
    str: 'RedeemPromoCode',
  },
  {
    value: 'RefreshAccessToken',
    str: 'RefreshAccessToken',
  },
  {
    value: 'RefreshCache',
    str: 'RefreshCache',
  },
  {
    value: 'RefreshSchemas',
    str: 'RefreshSchemas',
  },
  {
    value: 'RegisterActivityType',
    str: 'RegisterActivityType',
  },
  {
    value: 'RegisterApplicationRevision',
    str: 'RegisterApplicationRevision',
  },
  {
    value: 'RegisterCACertificate',
    str: 'RegisterCACertificate',
  },
  {
    value: 'RegisterCertificate',
    str: 'RegisterCertificate',
  },
  {
    value: 'RegisterContainerInstance',
    str: 'RegisterContainerInstance',
  },
  {
    value: 'RegisterCrossAccountAccessRole',
    str: 'RegisterCrossAccountAccessRole',
  },
  {
    value: 'RegisterDefaultPatchBaseline',
    str: 'RegisterDefaultPatchBaseline',
  },
  {
    value: 'RegisterDirectory',
    str: 'RegisterDirectory',
  },
  {
    value: 'RegisterDomain',
    str: 'RegisterDomain',
  },
  {
    value: 'RegisterDomainComputerAccount',
    str: 'RegisterDomainComputerAccount',
  },
  {
    value: 'RegisterEcsCluster',
    str: 'RegisterEcsCluster',
  },
  {
    value: 'RegisterElasticIp',
    str: 'RegisterElasticIp',
  },
  {
    value: 'RegisterEventTopic',
    str: 'RegisterEventTopic',
  },
  {
    value: 'RegisterImage',
    str: 'RegisterImage',
  },
  {
    value: 'RegisterInstance',
    str: 'RegisterInstance',
  },
  {
    value: 'RegisterInstancesWithLoadBalancer',
    str: 'RegisterInstancesWithLoadBalancer',
  },
  {
    value: 'RegisterJobDefinition',
    str: 'RegisterJobDefinition',
  },
  {
    value: 'RegisterOnPremisesInstance',
    str: 'RegisterOnPremisesInstance',
  },
  {
    value: 'RegisterPatchBaselineForPatchGroup',
    str: 'RegisterPatchBaselineForPatchGroup',
  },
  {
    value: 'RegisterRdsDbInstance',
    str: 'RegisterRdsDbInstance',
  },
  {
    value: 'RegisterStreamConsumer',
    str: 'RegisterStreamConsumer',
  },
  {
    value: 'RegisterTargets',
    str: 'RegisterTargets',
  },
  {
    value: 'RegisterTargetWithMaintenanceWindow',
    str: 'RegisterTargetWithMaintenanceWindow',
  },
  {
    value: 'RegisterTaskDefinition',
    str: 'RegisterTaskDefinition',
  },
  {
    value: 'RegisterTaskWithMaintenanceWindow',
    str: 'RegisterTaskWithMaintenanceWindow',
  },
  {
    value: 'RegisterThing',
    str: 'RegisterThing',
  },
  {
    value: 'RegisterVolume',
    str: 'RegisterVolume',
  },
  {
    value: 'RegisterWebhookWithThirdParty',
    str: 'RegisterWebhookWithThirdParty',
  },
  {
    value: 'RegisterWorkflowType',
    str: 'RegisterWorkflowType',
  },
  {
    value: 'RejectAccountMapping',
    str: 'RejectAccountMapping',
  },
  {
    value: 'RejectCertificateTransfer',
    str: 'RejectCertificateTransfer',
  },
  {
    value: 'RejectPortfolioShare',
    str: 'RejectPortfolioShare',
  },
  {
    value: 'RejectRfc',
    str: 'RejectRfc',
  },
  {
    value: 'RejectVpcPeeringConnection',
    str: 'RejectVpcPeeringConnection',
  },
  {
    value: 'ReleaseAddress',
    str: 'ReleaseAddress',
  },
  {
    value: 'ReleaseHosts',
    str: 'ReleaseHosts',
  },
  {
    value: 'ReloadTables',
    str: 'ReloadTables',
  },
  {
    value: 'RemoveAccountFromOrganization',
    str: 'RemoveAccountFromOrganization',
  },
  {
    value: 'RemoveAttributesFromFindings',
    str: 'RemoveAttributesFromFindings',
  },
  {
    value: 'RemoveClientIDFromOpenIDConnectProvider',
    str: 'RemoveClientIDFromOpenIDConnectProvider',
  },
  {
    value: 'RemoveDnssec',
    str: 'RemoveDnssec',
  },
  {
    value: 'RemoveIpRoutes',
    str: 'RemoveIpRoutes',
  },
  {
    value: 'RemoveMembersFromGroup',
    str: 'RemoveMembersFromGroup',
  },
  {
    value: 'RemovePermission20150331',
    str: 'RemovePermission20150331',
  },
  {
    value: 'RemovePermission20150331v2',
    str: 'RemovePermission20150331v2',
  },
  {
    value: 'RemoveRoleFromDbCluster',
    str: 'RemoveRoleFromDbCluster',
  },
  {
    value: 'RemoveRoleFromInstanceProfile',
    str: 'RemoveRoleFromInstanceProfile',
  },
  {
    value: 'RemoveSourceIdentifierFromSubscription',
    str: 'RemoveSourceIdentifierFromSubscription',
  },
  {
    value: 'RemoveTags',
    str: 'RemoveTags',
  },
  {
    value: 'RemoveTagsFromCertificate',
    str: 'RemoveTagsFromCertificate',
  },
  {
    value: 'RemoveTagsFromOnPremisesInstances',
    str: 'RemoveTagsFromOnPremisesInstances',
  },
  {
    value: 'RemoveTagsFromResource',
    str: 'RemoveTagsFromResource',
  },
  {
    value: 'RemoveTagsFromStream',
    str: 'RemoveTagsFromStream',
  },
  {
    value: 'RemoveTagsFromVault',
    str: 'RemoveTagsFromVault',
  },
  {
    value: 'RemoveTargets',
    str: 'RemoveTargets',
  },
  {
    value: 'RemoveThingFromThingGroup',
    str: 'RemoveThingFromThingGroup',
  },
  {
    value: 'RemoveUserFromGroup',
    str: 'RemoveUserFromGroup',
  },
  {
    value: 'RenameAccount',
    str: 'RenameAccount',
  },
  {
    value: 'RenewDelegate',
    str: 'RenewDelegate',
  },
  {
    value: 'RenewDomain',
    str: 'RenewDomain',
  },
  {
    value: 'RenewRole',
    str: 'RenewRole',
  },
  {
    value: 'ReplaceIamInstanceProfileAssociation',
    str: 'ReplaceIamInstanceProfileAssociation',
  },
  {
    value: 'ReplaceNetworkAclAssociation',
    str: 'ReplaceNetworkAclAssociation',
  },
  {
    value: 'ReplaceNetworkAclEntry',
    str: 'ReplaceNetworkAclEntry',
  },
  {
    value: 'ReplaceRoute',
    str: 'ReplaceRoute',
  },
  {
    value: 'ReplaceRouteTableAssociation',
    str: 'ReplaceRouteTableAssociation',
  },
  {
    value: 'ReplaceTopicRule',
    str: 'ReplaceTopicRule',
  },
  {
    value: 'ReportInstanceStatus',
    str: 'ReportInstanceStatus',
  },
  {
    value: 'ReportTaskProgress',
    str: 'ReportTaskProgress',
  },
  {
    value: 'ReportTaskRunnerHeartbeat',
    str: 'ReportTaskRunnerHeartbeat',
  },
  {
    value: 'RequestCertificate',
    str: 'RequestCertificate',
  },
  {
    value: 'RequestEnvironmentInfo',
    str: 'RequestEnvironmentInfo',
  },
  {
    value: 'RequestScheduledInstances',
    str: 'RequestScheduledInstances',
  },
  {
    value: 'RequestSpotFleet',
    str: 'RequestSpotFleet',
  },
  {
    value: 'RequestSpotInstances',
    str: 'RequestSpotInstances',
  },
  {
    value: 'RequestUploadCredentials',
    str: 'RequestUploadCredentials',
  },
  {
    value: 'ResendContactReachabilityEmail',
    str: 'ResendContactReachabilityEmail',
  },
  {
    value: 'ResendFoaEmail',
    str: 'ResendFoaEmail',
  },
  {
    value: 'ResendUserNotification',
    str: 'ResendUserNotification',
  },
  {
    value: 'ResendValidationEmail',
    str: 'ResendValidationEmail',
  },
  {
    value: 'ResetAccountResource',
    str: 'ResetAccountResource',
  },
  {
    value: 'ResetCacheParameterGroup',
    str: 'ResetCacheParameterGroup',
  },
  {
    value: 'ResetClusterParameterGroup',
    str: 'ResetClusterParameterGroup',
  },
  {
    value: 'ResetDBClusterParameterGroup',
    str: 'ResetDBClusterParameterGroup',
  },
  {
    value: 'ResetDBParameterGroup',
    str: 'ResetDBParameterGroup',
  },
  {
    value: 'ResetImageAttribute',
    str: 'ResetImageAttribute',
  },
  {
    value: 'ResetInstanceAttribute',
    str: 'ResetInstanceAttribute',
  },
  {
    value: 'ResetJobBookmark',
    str: 'ResetJobBookmark',
  },
  {
    value: 'ResetNetworkInterfaceAttribute',
    str: 'ResetNetworkInterfaceAttribute',
  },
  {
    value: 'ResetParameterGroup',
    str: 'ResetParameterGroup',
  },
  {
    value: 'ResetPassword',
    str: 'ResetPassword',
  },
  {
    value: 'ResetPersonalPIN',
    str: 'ResetPersonalPIN',
  },
  {
    value: 'ResetSnapshotAttribute',
    str: 'ResetSnapshotAttribute',
  },
  {
    value: 'ResolveAlias',
    str: 'ResolveAlias',
  },
  {
    value: 'ResolveCase',
    str: 'ResolveCase',
  },
  {
    value: 'ResolveRoom',
    str: 'ResolveRoom',
  },
  {
    value: 'RestartAppServer',
    str: 'RestartAppServer',
  },
  {
    value: 'RestoreAddressToClassic',
    str: 'RestoreAddressToClassic',
  },
  {
    value: 'RestoreBackup',
    str: 'RestoreBackup',
  },
  {
    value: 'RestoreDbClusterFromS3',
    str: 'RestoreDbClusterFromS3',
  },
  {
    value: 'RestoreDBClusterFromSnapshot',
    str: 'RestoreDBClusterFromSnapshot',
  },
  {
    value: 'RestoreDBClusterToPointInTime',
    str: 'RestoreDBClusterToPointInTime',
  },
  {
    value: 'RestoreDBInstanceFromDBSnapshot',
    str: 'RestoreDBInstanceFromDBSnapshot',
  },
  {
    value: 'RestoreDbInstanceFromS3',
    str: 'RestoreDbInstanceFromS3',
  },
  {
    value: 'RestoreDBInstanceToPointInTime',
    str: 'RestoreDBInstanceToPointInTime',
  },
  {
    value: 'RestoreDocument',
    str: 'RestoreDocument',
  },
  {
    value: 'RestoreFolder',
    str: 'RestoreFolder',
  },
  {
    value: 'RestoreFromClusterSnapshot',
    str: 'RestoreFromClusterSnapshot',
  },
  {
    value: 'RestoreFromSnapshot',
    str: 'RestoreFromSnapshot',
  },
  {
    value: 'RestoreSecret',
    str: 'RestoreSecret',
  },
  {
    value: 'RestoreServer',
    str: 'RestoreServer',
  },
  {
    value: 'RestoreTableFromBackup',
    str: 'RestoreTableFromBackup',
  },
  {
    value: 'RestoreTableFromClusterSnapshot',
    str: 'RestoreTableFromClusterSnapshot',
  },
  {
    value: 'RestoreTableToPointInTime',
    str: 'RestoreTableToPointInTime',
  },
  {
    value: 'ResumeProcesses',
    str: 'ResumeProcesses',
  },
  {
    value: 'ResumeSession',
    str: 'ResumeSession',
  },
  {
    value: 'ResyncMFADevice',
    str: 'ResyncMFADevice',
  },
  {
    value: 'RetrieveDataExports',
    str: 'RetrieveDataExports',
  },
  {
    value: 'RetrieveEnvironmentInfo',
    str: 'RetrieveEnvironmentInfo',
  },
  {
    value: 'RetrieveTapeArchive',
    str: 'RetrieveTapeArchive',
  },
  {
    value: 'RetrieveTapeRecoveryPoint',
    str: 'RetrieveTapeRecoveryPoint',
  },
  {
    value: 'RetryStageExecution',
    str: 'RetryStageExecution',
  },
  {
    value: 'RevokeCacheSecurityGroupIngress',
    str: 'RevokeCacheSecurityGroupIngress',
  },
  {
    value: 'RevokeCertificate',
    str: 'RevokeCertificate',
  },
  {
    value: 'RevokeClusterSecurityGroupIngress',
    str: 'RevokeClusterSecurityGroupIngress',
  },
  {
    value: 'RevokeDBSecurityGroupIngress',
    str: 'RevokeDBSecurityGroupIngress',
  },
  {
    value: 'RevokeInvitation',
    str: 'RevokeInvitation',
  },
  {
    value: 'RevokeSecurityGroupEgress',
    str: 'RevokeSecurityGroupEgress',
  },
  {
    value: 'RevokeSecurityGroupIngress',
    str: 'RevokeSecurityGroupIngress',
  },
  {
    value: 'RevokeSnapshotAccess',
    str: 'RevokeSnapshotAccess',
  },
  {
    value: 'RotateEncryptionKey',
    str: 'RotateEncryptionKey',
  },
  {
    value: 'RotateSecret',
    str: 'RotateSecret',
  },
  {
    value: 'RotationFailed',
    str: 'RotationFailed',
  },
  {
    value: 'RotationStarted',
    str: 'RotationStarted',
  },
  {
    value: 'RotationSucceeded',
    str: 'RotationSucceeded',
  },
  {
    value: 'RunInstances',
    str: 'RunInstances',
  },
  {
    value: 'RunJobFlow',
    str: 'RunJobFlow',
  },
  {
    value: 'RunPipelineActivity',
    str: 'RunPipelineActivity',
  },
  {
    value: 'RunScheduledInstances',
    str: 'RunScheduledInstances',
  },
  {
    value: 'RunTask',
    str: 'RunTask',
  },
  {
    value: 'SampleChannelData',
    str: 'SampleChannelData',
  },
  {
    value: 'SaveContactTemplates',
    str: 'SaveContactTemplates',
  },
  {
    value: 'ScanProvisionedProducts',
    str: 'ScanProvisionedProducts',
  },
  {
    value: 'Search',
    str: 'Search',
  },
  {
    value: 'SearchAddressBooks',
    str: 'SearchAddressBooks',
  },
  {
    value: 'SearchContacts',
    str: 'SearchContacts',
  },
  {
    value: 'SearchDevices',
    str: 'SearchDevices',
  },
  {
    value: 'SearchDomains',
    str: 'SearchDomains',
  },
  {
    value: 'SearchGameSessions',
    str: 'SearchGameSessions',
  },
  {
    value: 'SearchGroups',
    str: 'SearchGroups',
  },
  {
    value: 'SearchIndex',
    str: 'SearchIndex',
  },
  {
    value: 'SearchOrganizationUsers',
    str: 'SearchOrganizationUsers',
  },
  {
    value: 'SearchProducts',
    str: 'SearchProducts',
  },
  {
    value: 'SearchProductsAsAdmin',
    str: 'SearchProductsAsAdmin',
  },
  {
    value: 'SearchProfiles',
    str: 'SearchProfiles',
  },
  {
    value: 'SearchResources',
    str: 'SearchResources',
  },
  {
    value: 'SearchRooms',
    str: 'SearchRooms',
  },
  {
    value: 'SearchSkillGroups',
    str: 'SearchSkillGroups',
  },
  {
    value: 'SearchUsers',
    str: 'SearchUsers',
  },
  {
    value: 'SendCommand',
    str: 'SendCommand',
  },
  {
    value: 'SendInvitation',
    str: 'SendInvitation',
  },
  {
    value: 'SendMessage',
    str: 'SendMessage',
  },
  {
    value: 'SendVerificationMessage',
    str: 'SendVerificationMessage',
  },
  {
    value: 'SetAccountContractMetadata',
    str: 'SetAccountContractMetadata',
  },
  {
    value: 'SetAccountPreferences',
    str: 'SetAccountPreferences',
  },
  {
    value: 'SetAdditionalContacts',
    str: 'SetAdditionalContacts',
  },
  {
    value: 'SetAlarmState',
    str: 'SetAlarmState',
  },
  {
    value: 'SetCognitoEvents',
    str: 'SetCognitoEvents',
  },
  {
    value: 'SetContactAddress',
    str: 'SetContactAddress',
  },
  {
    value: 'SetCostExplorerPreferences',
    str: 'SetCostExplorerPreferences',
  },
  {
    value: 'SetCreatedByOptIn',
    str: 'SetCreatedByOptIn',
  },
  {
    value: 'SetCreditSharing',
    str: 'SetCreditSharing',
  },
  {
    value: 'SetDataRetrievalPolicy',
    str: 'SetDataRetrievalPolicy',
  },
  {
    value: 'SetDefaultAuthorizer',
    str: 'SetDefaultAuthorizer',
  },
  {
    value: 'SetDefaultPolicyVersion',
    str: 'SetDefaultPolicyVersion',
  },
  {
    value: 'SetDesiredCapacity',
    str: 'SetDesiredCapacity',
  },
  {
    value: 'SetFreetierBudgetsPreference',
    str: 'SetFreetierBudgetsPreference',
  },
  {
    value: 'SetFxPaymentCurrency',
    str: 'SetFxPaymentCurrency',
  },
  {
    value: 'SetIAMAccessPreference',
    str: 'SetIAMAccessPreference',
  },
  {
    value: 'SetIdentityPoolConfiguration',
    str: 'SetIdentityPoolConfiguration',
  },
  {
    value: 'SetIdentityPoolRoles',
    str: 'SetIdentityPoolRoles',
  },
  {
    value: 'SetInstanceHealth',
    str: 'SetInstanceHealth',
  },
  {
    value: 'SetInstanceProtection',
    str: 'SetInstanceProtection',
  },
  {
    value: 'SetJournalingRules',
    str: 'SetJournalingRules',
  },
  {
    value: 'SetLoadBalancerListenerSSLCertificate',
    str: 'SetLoadBalancerListenerSSLCertificate',
  },
  {
    value: 'SetLoadBalancerPoliciesForBackendServer',
    str: 'SetLoadBalancerPoliciesForBackendServer',
  },
  {
    value: 'SetLoadBalancerPoliciesOfListener',
    str: 'SetLoadBalancerPoliciesOfListener',
  },
  {
    value: 'SetLoadBasedAutoScaling',
    str: 'SetLoadBasedAutoScaling',
  },
  {
    value: 'SetLoggingOptions',
    str: 'SetLoggingOptions',
  },
  {
    value: 'SetMobileusers/policydetails',
    str: 'SetMobileusers/policydetails',
  },
  {
    value: 'SetPanInformation',
    str: 'SetPanInformation',
  },
  {
    value: 'SetPermission',
    str: 'SetPermission',
  },
  {
    value: 'SetPublishRoleArn',
    str: 'SetPublishRoleArn',
  },
  {
    value: 'SetRISharing',
    str: 'SetRISharing',
  },
  {
    value: 'SetRulePriorities',
    str: 'SetRulePriorities',
  },
  {
    value: 'SetSecurityGroups',
    str: 'SetSecurityGroups',
  },
  {
    value: 'SetSecurityQuestions',
    str: 'SetSecurityQuestions',
  },
  {
    value: 'SetStackPolicy',
    str: 'SetStackPolicy',
  },
  {
    value: 'SetStatus',
    str: 'SetStatus',
  },
  {
    value: 'SetSubnets',
    str: 'SetSubnets',
  },
  {
    value: 'SetTagKeysState',
    str: 'SetTagKeysState',
  },
  {
    value: 'SetTagsForResource',
    str: 'SetTagsForResource',
  },
  {
    value: 'SetTaskStatus',
    str: 'SetTaskStatus',
  },
  {
    value: 'SetTaxRegistration',
    str: 'SetTaxRegistration',
  },
  {
    value: 'SetTerminationProtection',
    str: 'SetTerminationProtection',
  },
  {
    value: 'SetTimeBasedAutoScaling',
    str: 'SetTimeBasedAutoScaling',
  },
  {
    value: 'SetV2LoggingLevel',
    str: 'SetV2LoggingLevel',
  },
  {
    value: 'SetV2LoggingOptions',
    str: 'SetV2LoggingOptions',
  },
  {
    value: 'SetVaultAccessPolicy',
    str: 'SetVaultAccessPolicy',
  },
  {
    value: 'SetVaultNotifications',
    str: 'SetVaultNotifications',
  },
  {
    value: 'SetVisibleToAllUsers',
    str: 'SetVisibleToAllUsers',
  },
  {
    value: 'ShutdownGateway',
    str: 'ShutdownGateway',
  },
  {
    value: 'SignalResource',
    str: 'SignalResource',
  },
  {
    value: 'SimulateCustomPolicy',
    str: 'SimulateCustomPolicy',
  },
  {
    value: 'SimulatePrincipalPolicy',
    str: 'SimulatePrincipalPolicy',
  },
  {
    value: 'SkipWaitTimeForInstanceTermination',
    str: 'SkipWaitTimeForInstanceTermination',
  },
  {
    value: 'SplitShard',
    str: 'SplitShard',
  },
  {
    value: 'StartAssessmentRun',
    str: 'StartAssessmentRun',
  },
  {
    value: 'StartAutomationExecution',
    str: 'StartAutomationExecution',
  },
  {
    value: 'StartBuild',
    str: 'StartBuild',
  },
  {
    value: 'StartConfigRulesEvaluation',
    str: 'StartConfigRulesEvaluation',
  },
  {
    value: 'StartConfigurationRecorder',
    str: 'StartConfigurationRecorder',
  },
  {
    value: 'StartContinuousExport',
    str: 'StartContinuousExport',
  },
  {
    value: 'StartCrawler',
    str: 'StartCrawler',
  },
  {
    value: 'StartCrawlerSchedule',
    str: 'StartCrawlerSchedule',
  },
  {
    value: 'StartDataCollectionByAgentIds',
    str: 'StartDataCollectionByAgentIds',
  },
  {
    value: 'StartDataExport',
    str: 'StartDataExport',
  },
  {
    value: 'StartDBInstance',
    str: 'StartDBInstance',
  },
  {
    value: 'StartDeviceSync',
    str: 'StartDeviceSync',
  },
  {
    value: 'StartExecution',
    str: 'StartExecution',
  },
  {
    value: 'StartGameSessionPlacement',
    str: 'StartGameSessionPlacement',
  },
  {
    value: 'StartGateway',
    str: 'StartGateway',
  },
  {
    value: 'StartImport',
    str: 'StartImport',
  },
  {
    value: 'StartInstance',
    str: 'StartInstance',
  },
  {
    value: 'StartInstances',
    str: 'StartInstances',
  },
  {
    value: 'StartJobRun',
    str: 'StartJobRun',
  },
  {
    value: 'StartLogging',
    str: 'StartLogging',
  },
  {
    value: 'StartMaintenance',
    str: 'StartMaintenance',
  },
  {
    value: 'StartMatchBackfill',
    str: 'StartMatchBackfill',
  },
  {
    value: 'StartMatchmaking',
    str: 'StartMatchmaking',
  },
  {
    value: 'StartMonitoringMembers',
    str: 'StartMonitoringMembers',
  },
  {
    value: 'StartNotebookInstance',
    str: 'StartNotebookInstance',
  },
  {
    value: 'StartOnDemandAuditTask',
    str: 'StartOnDemandAuditTask',
  },
  {
    value: 'StartPipelineExecution',
    str: 'StartPipelineExecution',
  },
  {
    value: 'StartPipelineReprocessing',
    str: 'StartPipelineReprocessing',
  },
  {
    value: 'StartQueryExecution',
    str: 'StartQueryExecution',
  },
  {
    value: 'StartReplicationRun',
    str: 'StartReplicationRun',
  },
  {
    value: 'StartReplicationTask',
    str: 'StartReplicationTask',
  },
  {
    value: 'StartReplicationTaskAssessment',
    str: 'StartReplicationTaskAssessment',
  },
  {
    value: 'StartSchemaCreation',
    str: 'StartSchemaCreation',
  },
  {
    value: 'StartSchemaExtension',
    str: 'StartSchemaExtension',
  },
  {
    value: 'StartSecretVersionDelete',
    str: 'StartSecretVersionDelete',
  },
  {
    value: 'StartSession',
    str: 'StartSession',
  },
  {
    value: 'StartSigningJob',
    str: 'StartSigningJob',
  },
  {
    value: 'StartSSO',
    str: 'StartSSO',
  },
  {
    value: 'StartStack',
    str: 'StartStack',
  },
  {
    value: 'StartStreamEncryption',
    str: 'StartStreamEncryption',
  },
  {
    value: 'StartTask',
    str: 'StartTask',
  },
  {
    value: 'StartThingRegistrationTask',
    str: 'StartThingRegistrationTask',
  },
  {
    value: 'StartTrigger',
    str: 'StartTrigger',
  },
  {
    value: 'StopAssessmentRun',
    str: 'StopAssessmentRun',
  },
  {
    value: 'StopAutomationExecution',
    str: 'StopAutomationExecution',
  },
  {
    value: 'StopBuild',
    str: 'StopBuild',
  },
  {
    value: 'StopConfigurationRecorder',
    str: 'StopConfigurationRecorder',
  },
  {
    value: 'StopContinuousExport',
    str: 'StopContinuousExport',
  },
  {
    value: 'StopCrawler',
    str: 'StopCrawler',
  },
  {
    value: 'StopCrawlerSchedule',
    str: 'StopCrawlerSchedule',
  },
  {
    value: 'StopDataCollectionByAgentIds',
    str: 'StopDataCollectionByAgentIds',
  },
  {
    value: 'StopDBInstance',
    str: 'StopDBInstance',
  },
  {
    value: 'StopDeployment',
    str: 'StopDeployment',
  },
  {
    value: 'StopExecution',
    str: 'StopExecution',
  },
  {
    value: 'StopGameSessionPlacement',
    str: 'StopGameSessionPlacement',
  },
  {
    value: 'StopHyperParameterTuningJob',
    str: 'StopHyperParameterTuningJob',
  },
  {
    value: 'StopInstance',
    str: 'StopInstance',
  },
  {
    value: 'StopInstances',
    str: 'StopInstances',
  },
  {
    value: 'StopJob',
    str: 'StopJob',
  },
  {
    value: 'StopLogging',
    str: 'StopLogging',
  },
  {
    value: 'StopMatchmaking',
    str: 'StopMatchmaking',
  },
  {
    value: 'StopMonitoringMembers',
    str: 'StopMonitoringMembers',
  },
  {
    value: 'StopNotebookInstance',
    str: 'StopNotebookInstance',
  },
  {
    value: 'StopQueryExecution',
    str: 'StopQueryExecution',
  },
  {
    value: 'StopReplicationTask',
    str: 'StopReplicationTask',
  },
  {
    value: 'StopStack',
    str: 'StopStack',
  },
  {
    value: 'StopStackSetOperation',
    str: 'StopStackSetOperation',
  },
  {
    value: 'StopStreamEncryption',
    str: 'StopStreamEncryption',
  },
  {
    value: 'StopTask',
    str: 'StopTask',
  },
  {
    value: 'StopThingRegistrationTask',
    str: 'StopThingRegistrationTask',
  },
  {
    value: 'StopTrainingJob',
    str: 'StopTrainingJob',
  },
  {
    value: 'StopTrigger',
    str: 'StopTrigger',
  },
  {
    value: 'SubmitContainerStateChange',
    str: 'SubmitContainerStateChange',
  },
  {
    value: 'SubmitJob',
    str: 'SubmitJob',
  },
  {
    value: 'SubmitRfc',
    str: 'SubmitRfc',
  },
  {
    value: 'SubmitSupportRequest',
    str: 'SubmitSupportRequest',
  },
  {
    value: 'SubmitTaskStateChange',
    str: 'SubmitTaskStateChange',
  },
  {
    value: 'SubscribeToEvent',
    str: 'SubscribeToEvent',
  },
  {
    value: 'SuspendProcesses',
    str: 'SuspendProcesses',
  },
  {
    value: 'SuspendUsers',
    str: 'SuspendUsers',
  },
  {
    value: 'SwapEnvironmentCNAMEs',
    str: 'SwapEnvironmentCNAMEs',
  },
  {
    value: 'SwitchRole',
    str: 'SwitchRole',
  },
  {
    value: 'SynthesizeSpeech',
    str: 'SynthesizeSpeech',
  },
  {
    value: 'SynthesizeSpeechGet',
    str: 'SynthesizeSpeechGet',
  },
  {
    value: 'Tag',
    str: 'Tag',
  },
  {
    value: 'TagCertificateAuthority',
    str: 'TagCertificateAuthority',
  },
  {
    value: 'TagDeliveryStream',
    str: 'TagDeliveryStream',
  },
  {
    value: 'TagProject',
    str: 'TagProject',
  },
  {
    value: 'TagResource',
    str: 'TagResource',
  },
  {
    value: 'TagResource20170331',
    str: 'TagResource20170331',
  },
  {
    value: 'TagResource20170331v2',
    str: 'TagResource20170331v2',
  },
  {
    value: 'TerminateEnvironment',
    str: 'TerminateEnvironment',
  },
  {
    value: 'TerminateInstanceInAutoScalingGroup',
    str: 'TerminateInstanceInAutoScalingGroup',
  },
  {
    value: 'TerminateInstances',
    str: 'TerminateInstances',
  },
  {
    value: 'TerminateJob',
    str: 'TerminateJob',
  },
  {
    value: 'TerminateJobFlows',
    str: 'TerminateJobFlows',
  },
  {
    value: 'TerminateProvisionedProduct',
    str: 'TerminateProvisionedProduct',
  },
  {
    value: 'TerminateSession',
    str: 'TerminateSession',
  },
  {
    value: 'TerminateWorkspaces',
    str: 'TerminateWorkspaces',
  },
  {
    value: 'TestAuthorization',
    str: 'TestAuthorization',
  },
  {
    value: 'TestConnection',
    str: 'TestConnection',
  },
  {
    value: 'TestInvokeAuthorizer',
    str: 'TestInvokeAuthorizer',
  },
  {
    value: 'TestInvokeMethod',
    str: 'TestInvokeMethod',
  },
  {
    value: 'TestRepositoryTriggers',
    str: 'TestRepositoryTriggers',
  },
  {
    value: 'TestRole',
    str: 'TestRole',
  },
  {
    value: 'TransferCertificate',
    str: 'TransferCertificate',
  },
  {
    value: 'TransferDomain',
    str: 'TransferDomain',
  },
  {
    value: 'TransferUserResources',
    str: 'TransferUserResources',
  },
  {
    value: 'UnarchiveFindings',
    str: 'UnarchiveFindings',
  },
  {
    value: 'UnassignInstance',
    str: 'UnassignInstance',
  },
  {
    value: 'UnassignIpv6Addresses',
    str: 'UnassignIpv6Addresses',
  },
  {
    value: 'UnassignPrivateIpAddresses',
    str: 'UnassignPrivateIpAddresses',
  },
  {
    value: 'UnassignVolume',
    str: 'UnassignVolume',
  },
  {
    value: 'UnauthorizeDirectory',
    str: 'UnauthorizeDirectory',
  },
  {
    value: 'UnlinkDeveloperIdentity',
    str: 'UnlinkDeveloperIdentity',
  },
  {
    value: 'UnlockDomain',
    str: 'UnlockDomain',
  },
  {
    value: 'UnmonitorInstances',
    str: 'UnmonitorInstances',
  },
  {
    value: 'UnpeerVpc',
    str: 'UnpeerVpc',
  },
  {
    value: 'UnsubscribeFromEvent',
    str: 'UnsubscribeFromEvent',
  },
  {
    value: 'Untag',
    str: 'Untag',
  },
  {
    value: 'UntagCertificateAuthority',
    str: 'UntagCertificateAuthority',
  },
  {
    value: 'UntagDeliveryStream',
    str: 'UntagDeliveryStream',
  },
  {
    value: 'UntagProject',
    str: 'UntagProject',
  },
  {
    value: 'UntagResource',
    str: 'UntagResource',
  },
  {
    value: 'UntagResource20170331',
    str: 'UntagResource20170331',
  },
  {
    value: 'UntagResource20170331v2',
    str: 'UntagResource20170331v2',
  },
  {
    value: 'UpdateAccessKey',
    str: 'UpdateAccessKey',
  },
  {
    value: 'UpdateAccount',
    str: 'UpdateAccount',
  },
  {
    value: 'UpdateAccountAuditConfiguration',
    str: 'UpdateAccountAuditConfiguration',
  },
  {
    value: 'UpdateAccountMappingAlias',
    str: 'UpdateAccountMappingAlias',
  },
  {
    value: 'UpdateAccountMappingDeliveryArn',
    str: 'UpdateAccountMappingDeliveryArn',
  },
  {
    value: 'UpdateAccountOpenIdConfig',
    str: 'UpdateAccountOpenIdConfig',
  },
  {
    value: 'UpdateAccountPasswordPolicy',
    str: 'UpdateAccountPasswordPolicy',
  },
  {
    value: 'UpdateAccountResource',
    str: 'UpdateAccountResource',
  },
  {
    value: 'UpdateAccountSettings',
    str: 'UpdateAccountSettings',
  },
  {
    value: 'UpdateAddressBook',
    str: 'UpdateAddressBook',
  },
  {
    value: 'UpdateAdmChannel',
    str: 'UpdateAdmChannel',
  },
  {
    value: 'UpdateAlias',
    str: 'UpdateAlias',
  },
  {
    value: 'UpdateAlias20150331',
    str: 'UpdateAlias20150331',
  },
  {
    value: 'UpdateAnalysis',
    str: 'UpdateAnalysis',
  },
  {
    value: 'UpdateAnalysisAccess',
    str: 'UpdateAnalysisAccess',
  },
  {
    value: 'UpdateAnnotation',
    str: 'UpdateAnnotation',
  },
  {
    value: 'UpdateApiKey',
    str: 'UpdateApiKey',
  },
  {
    value: 'UpdateApnsChannel',
    str: 'UpdateApnsChannel',
  },
  {
    value: 'UpdateApnsSandboxChannel',
    str: 'UpdateApnsSandboxChannel',
  },
  {
    value: 'UpdateApp',
    str: 'UpdateApp',
  },
  {
    value: 'UpdateApplianceGroup',
    str: 'UpdateApplianceGroup',
  },
  {
    value: 'UpdateApplication',
    str: 'UpdateApplication',
  },
  {
    value: 'UpdateApplicationInstanceActiveCertificate',
    str: 'UpdateApplicationInstanceActiveCertificate',
  },
  {
    value: 'UpdateApplicationInstanceDisplayData',
    str: 'UpdateApplicationInstanceDisplayData',
  },
  {
    value: 'UpdateApplicationInstanceResponseConfiguration',
    str: 'UpdateApplicationInstanceResponseConfiguration',
  },
  {
    value: 'UpdateApplicationInstanceResponseSchemaConfiguration',
    str: 'UpdateApplicationInstanceResponseSchemaConfiguration',
  },
  {
    value: 'UpdateApplicationInstanceSecurityConfiguration',
    str: 'UpdateApplicationInstanceSecurityConfiguration',
  },
  {
    value: 'UpdateApplicationInstanceServiceProviderConfiguration',
    str: 'UpdateApplicationInstanceServiceProviderConfiguration',
  },
  {
    value: 'UpdateApplicationInstanceStatus',
    str: 'UpdateApplicationInstanceStatus',
  },
  {
    value: 'UpdateApplicationSettings',
    str: 'UpdateApplicationSettings',
  },
  {
    value: 'UpdateApplicationVersion',
    str: 'UpdateApplicationVersion',
  },
  {
    value: 'UpdateAssessmentTarget',
    str: 'UpdateAssessmentTarget',
  },
  {
    value: 'UpdateAssociation',
    str: 'UpdateAssociation',
  },
  {
    value: 'UpdateAssociationStatus',
    str: 'UpdateAssociationStatus',
  },
  {
    value: 'UpdateAssumeRolePolicy',
    str: 'UpdateAssumeRolePolicy',
  },
  {
    value: 'UpdateAuthorizer',
    str: 'UpdateAuthorizer',
  },
  {
    value: 'UpdateAutoScalingGroup',
    str: 'UpdateAutoScalingGroup',
  },
  {
    value: 'UpdateAvailabilityOptions',
    str: 'UpdateAvailabilityOptions',
  },
  {
    value: 'UpdateBaiduChannel',
    str: 'UpdateBaiduChannel',
  },
  {
    value: 'UpdateBandwidthRateLimit',
    str: 'UpdateBandwidthRateLimit',
  },
  {
    value: 'UpdateBasePathMapping',
    str: 'UpdateBasePathMapping',
  },
  {
    value: 'UpdateBatchPrediction',
    str: 'UpdateBatchPrediction',
  },
  {
    value: 'UpdateBroker',
    str: 'UpdateBroker',
  },
  {
    value: 'UpdateBuild',
    str: 'UpdateBuild',
  },
  {
    value: 'UpdateByteMatchSet',
    str: 'UpdateByteMatchSet',
  },
  {
    value: 'UpdateCACertificate',
    str: 'UpdateCACertificate',
  },
  {
    value: 'UpdateCampaign',
    str: 'UpdateCampaign',
  },
  {
    value: 'UpdateCDRSettings',
    str: 'UpdateCDRSettings',
  },
  {
    value: 'UpdateCertificate',
    str: 'UpdateCertificate',
  },
  {
    value: 'UpdateCertificateAuthority',
    str: 'UpdateCertificateAuthority',
  },
  {
    value: 'UpdateCertificateTag',
    str: 'UpdateCertificateTag',
  },
  {
    value: 'UpdateChannel',
    str: 'UpdateChannel',
  },
  {
    value: 'UpdateChapCredentials',
    str: 'UpdateChapCredentials',
  },
  {
    value: 'UpdateClassifier',
    str: 'UpdateClassifier',
  },
  {
    value: 'UpdateClientCertificate',
    str: 'UpdateClientCertificate',
  },
  {
    value: 'UpdateCloudFrontOriginAccessIdentity',
    str: 'UpdateCloudFrontOriginAccessIdentity',
  },
  {
    value: 'UpdateCluster',
    str: 'UpdateCluster',
  },
  {
    value: 'UpdateComment',
    str: 'UpdateComment',
  },
  {
    value: 'UpdateComputeEnvironment',
    str: 'UpdateComputeEnvironment',
  },
  {
    value: 'UpdateConditionalForwarder',
    str: 'UpdateConditionalForwarder',
  },
  {
    value: 'UpdateConfiguration',
    str: 'UpdateConfiguration',
  },
  {
    value: 'UpdateConfigurationTemplate',
    str: 'UpdateConfigurationTemplate',
  },
  {
    value: 'UpdateConnection',
    str: 'UpdateConnection',
  },
  {
    value: 'UpdateConstraint',
    str: 'UpdateConstraint',
  },
  {
    value: 'UpdateContact',
    str: 'UpdateContact',
  },
  {
    value: 'UpdateContainerAgent',
    str: 'UpdateContainerAgent',
  },
  {
    value: 'UpdateContainerInstancesState',
    str: 'UpdateContainerInstancesState',
  },
  {
    value: 'UpdateContinuousBackups',
    str: 'UpdateContinuousBackups',
  },
  {
    value: 'UpdateCrawler',
    str: 'UpdateCrawler',
  },
  {
    value: 'UpdateCrawlerSchedule',
    str: 'UpdateCrawlerSchedule',
  },
  {
    value: 'UpdateDashboard',
    str: 'UpdateDashboard',
  },
  {
    value: 'UpdateDashboardAccess',
    str: 'UpdateDashboardAccess',
  },
  {
    value: 'UpdateDatabase',
    str: 'UpdateDatabase',
  },
  {
    value: 'UpdateDataset',
    str: 'UpdateDataset',
  },
  {
    value: 'UpdateDataSet',
    str: 'UpdateDataSet',
  },
  {
    value: 'UpdateDataSource',
    str: 'UpdateDataSource',
  },
  {
    value: 'UpdateDatastore',
    str: 'UpdateDatastore',
  },
  {
    value: 'UpdateDefaultBranch',
    str: 'UpdateDefaultBranch',
  },
  {
    value: 'UpdateDefaultSearchField',
    str: 'UpdateDefaultSearchField',
  },
  {
    value: 'UpdateDeployment',
    str: 'UpdateDeployment',
  },
  {
    value: 'UpdateDeploymentGroup',
    str: 'UpdateDeploymentGroup',
  },
  {
    value: 'UpdateDestination',
    str: 'UpdateDestination',
  },
  {
    value: 'UpdateDetector',
    str: 'UpdateDetector',
  },
  {
    value: 'UpdateDevEndpoint',
    str: 'UpdateDevEndpoint',
  },
  {
    value: 'UpdateDevice',
    str: 'UpdateDevice',
  },
  {
    value: 'UpdateDirectConnectGatewayAssociation',
    str: 'UpdateDirectConnectGatewayAssociation',
  },
  {
    value: 'UpdateDirectoryAssociation',
    str: 'UpdateDirectoryAssociation',
  },
  {
    value: 'UpdateDirectoryMFA',
    str: 'UpdateDirectoryMFA',
  },
  {
    value: 'UpdateDistribution',
    str: 'UpdateDistribution',
  },
  {
    value: 'UpdateDocument',
    str: 'UpdateDocument',
  },
  {
    value: 'UpdateDocumentationPart',
    str: 'UpdateDocumentationPart',
  },
  {
    value: 'UpdateDocumentationVersion',
    str: 'UpdateDocumentationVersion',
  },
  {
    value: 'UpdateDocumentDefaultVersion',
    str: 'UpdateDocumentDefaultVersion',
  },
  {
    value: 'UpdateDocumentVersion',
    str: 'UpdateDocumentVersion',
  },
  {
    value: 'UpdateDomainContact',
    str: 'UpdateDomainContact',
  },
  {
    value: 'UpdateDomainEntry',
    str: 'UpdateDomainEntry',
  },
  {
    value: 'UpdateDomainName',
    str: 'UpdateDomainName',
  },
  {
    value: 'UpdateElasticIp',
    str: 'UpdateElasticIp',
  },
  {
    value: 'UpdateElasticsearchDomainConfig',
    str: 'UpdateElasticsearchDomainConfig',
  },
  {
    value: 'UpdateEmailChannel',
    str: 'UpdateEmailChannel',
  },
  {
    value: 'UpdateEndpoint',
    str: 'UpdateEndpoint',
  },
  {
    value: 'UpdateEndpointWeightsAndCapacities',
    str: 'UpdateEndpointWeightsAndCapacities',
  },
  {
    value: 'UpdateEnvironment',
    str: 'UpdateEnvironment',
  },
  {
    value: 'UpdateEvaluation',
    str: 'UpdateEvaluation',
  },
  {
    value: 'UpdateEventConfigurations',
    str: 'UpdateEventConfigurations',
  },
  {
    value: 'UpdateEventSourceMapping20150331',
    str: 'UpdateEventSourceMapping20150331',
  },
  {
    value: 'UpdateFacet',
    str: 'UpdateFacet',
  },
  {
    value: 'UpdateFilter',
    str: 'UpdateFilter',
  },
  {
    value: 'UpdateFindingsFeedback',
    str: 'UpdateFindingsFeedback',
  },
  {
    value: 'UpdateFleetAttributes',
    str: 'UpdateFleetAttributes',
  },
  {
    value: 'UpdateFleetCapacity',
    str: 'UpdateFleetCapacity',
  },
  {
    value: 'UpdateFleetPortSettings',
    str: 'UpdateFleetPortSettings',
  },
  {
    value: 'UpdateFolder',
    str: 'UpdateFolder',
  },
  {
    value: 'UpdateFunctionCode',
    str: 'UpdateFunctionCode',
  },
  {
    value: 'UpdateFunctionCode20150331',
    str: 'UpdateFunctionCode20150331',
  },
  {
    value: 'UpdateFunctionCode20150331v2',
    str: 'UpdateFunctionCode20150331v2',
  },
  {
    value: 'UpdateFunctionConfiguration',
    str: 'UpdateFunctionConfiguration',
  },
  {
    value: 'UpdateFunctionConfiguration20150331',
    str: 'UpdateFunctionConfiguration20150331',
  },
  {
    value: 'UpdateFunctionConfiguration20150331v2',
    str: 'UpdateFunctionConfiguration20150331v2',
  },
  {
    value: 'UpdateGameSession',
    str: 'UpdateGameSession',
  },
  {
    value: 'UpdateGameSessionQueue',
    str: 'UpdateGameSessionQueue',
  },
  {
    value: 'UpdateGatewayInformation',
    str: 'UpdateGatewayInformation',
  },
  {
    value: 'UpdateGatewayResponse',
    str: 'UpdateGatewayResponse',
  },
  {
    value: 'UpdateGatewaySoftwareNow',
    str: 'UpdateGatewaySoftwareNow',
  },
  {
    value: 'UpdateGcmChannel',
    str: 'UpdateGcmChannel',
  },
  {
    value: 'UpdateGraphqlApi',
    str: 'UpdateGraphqlApi',
  },
  {
    value: 'UpdateGroup',
    str: 'UpdateGroup',
  },
  {
    value: 'UpdateGroupQuery',
    str: 'UpdateGroupQuery',
  },
  {
    value: 'UpdateGroups',
    str: 'UpdateGroups',
  },
  {
    value: 'UpdateHealthCheck',
    str: 'UpdateHealthCheck',
  },
  {
    value: 'UpdateHostedZoneComment',
    str: 'UpdateHostedZoneComment',
  },
  {
    value: 'UpdateIdentityPool',
    str: 'UpdateIdentityPool',
  },
  {
    value: 'UpdateIndexingConfiguration',
    str: 'UpdateIndexingConfiguration',
  },
  {
    value: 'UpdateInstance',
    str: 'UpdateInstance',
  },
  {
    value: 'UpdateInstanceAlias',
    str: 'UpdateInstanceAlias',
  },
  {
    value: 'UpdateInstanceCustomHealthStatus',
    str: 'UpdateInstanceCustomHealthStatus',
  },
  {
    value: 'UpdateIntegration',
    str: 'UpdateIntegration',
  },
  {
    value: 'UpdateIntegrationResponse',
    str: 'UpdateIntegrationResponse',
  },
  {
    value: 'UpdateIPSet',
    str: 'UpdateIPSet',
  },
  {
    value: 'UpdateJob',
    str: 'UpdateJob',
  },
  {
    value: 'UpdateJobQueue',
    str: 'UpdateJobQueue',
  },
  {
    value: 'UpdateJobTemplate',
    str: 'UpdateJobTemplate',
  },
  {
    value: 'UpdateLag',
    str: 'UpdateLag',
  },
  {
    value: 'UpdateLayer',
    str: 'UpdateLayer',
  },
  {
    value: 'UpdateLinkAttributes',
    str: 'UpdateLinkAttributes',
  },
  {
    value: 'UpdateLoadBalancerAttribute',
    str: 'UpdateLoadBalancerAttribute',
  },
  {
    value: 'UpdateLoginProfile',
    str: 'UpdateLoginProfile',
  },
  {
    value: 'UpdateMailboxQuota',
    str: 'UpdateMailboxQuota',
  },
  {
    value: 'UpdateMailFlowRule',
    str: 'UpdateMailFlowRule',
  },
  {
    value: 'UpdateMaintenanceStartTime',
    str: 'UpdateMaintenanceStartTime',
  },
  {
    value: 'UpdateMaintenanceWindow',
    str: 'UpdateMaintenanceWindow',
  },
  {
    value: 'UpdateMaintenanceWindowTarget',
    str: 'UpdateMaintenanceWindowTarget',
  },
  {
    value: 'UpdateMaintenanceWindowTask',
    str: 'UpdateMaintenanceWindowTask',
  },
  {
    value: 'UpdateManagedInstanceRole',
    str: 'UpdateManagedInstanceRole',
  },
  {
    value: 'UpdateMatchmakingConfiguration',
    str: 'UpdateMatchmakingConfiguration',
  },
  {
    value: 'UpdateMetadata',
    str: 'UpdateMetadata',
  },
  {
    value: 'UpdateMethod',
    str: 'UpdateMethod',
  },
  {
    value: 'UpdateMethodResponse',
    str: 'UpdateMethodResponse',
  },
  {
    value: 'UpdateMLModel',
    str: 'UpdateMLModel',
  },
  {
    value: 'UpdateModel',
    str: 'UpdateModel',
  },
  {
    value: 'UpdateMyUserProfile',
    str: 'UpdateMyUserProfile',
  },
  {
    value: 'UpdateNameservers',
    str: 'UpdateNameservers',
  },
  {
    value: 'UpdateNfsFileShare',
    str: 'UpdateNfsFileShare',
  },
  {
    value: 'UpdateNotebookInstance',
    str: 'UpdateNotebookInstance',
  },
  {
    value: 'UpdateNotebookInstanceLifecycleConfig',
    str: 'UpdateNotebookInstanceLifecycleConfig',
  },
  {
    value: 'UpdateNumberOfDomainControllers',
    str: 'UpdateNumberOfDomainControllers',
  },
  {
    value: 'UpdateOpenIDConnectProviderThumbprint',
    str: 'UpdateOpenIDConnectProviderThumbprint',
  },
  {
    value: 'UpdateOrganization',
    str: 'UpdateOrganization',
  },
  {
    value: 'UpdateOrganizationalUnit',
    str: 'UpdateOrganizationalUnit',
  },
  {
    value: 'UpdateOrigamiReportPreference',
    str: 'UpdateOrigamiReportPreference',
  },
  {
    value: 'UpdateParameterGroup',
    str: 'UpdateParameterGroup',
  },
  {
    value: 'UpdatePartition',
    str: 'UpdatePartition',
  },
  {
    value: 'UpdatePatchBaseline',
    str: 'UpdatePatchBaseline',
  },
  {
    value: 'UpdatePipeline',
    str: 'UpdatePipeline',
  },
  {
    value: 'UpdatePipelineNotifications',
    str: 'UpdatePipelineNotifications',
  },
  {
    value: 'UpdatePipelineStatus',
    str: 'UpdatePipelineStatus',
  },
  {
    value: 'UpdatePolicy',
    str: 'UpdatePolicy',
  },
  {
    value: 'UpdatePortfolio',
    str: 'UpdatePortfolio',
  },
  {
    value: 'UpdatePreset',
    str: 'UpdatePreset',
  },
  {
    value: 'UpdatePrimaryEmailAddress',
    str: 'UpdatePrimaryEmailAddress',
  },
  {
    value: 'UpdatePrivacyProtection',
    str: 'UpdatePrivacyProtection',
  },
  {
    value: 'UpdateProduct',
    str: 'UpdateProduct',
  },
  {
    value: 'UpdateProfile',
    str: 'UpdateProfile',
  },
  {
    value: 'UpdateProject',
    str: 'UpdateProject',
  },
  {
    value: 'UpdateProvisionedProduct',
    str: 'UpdateProvisionedProduct',
  },
  {
    value: 'UpdateProvisioningArtifact',
    str: 'UpdateProvisioningArtifact',
  },
  {
    value: 'UpdateQueue',
    str: 'UpdateQueue',
  },
  {
    value: 'UpdateRadius',
    str: 'UpdateRadius',
  },
  {
    value: 'UpdateRdsDbInstance',
    str: 'UpdateRdsDbInstance',
  },
  {
    value: 'UpdateReplicationJob',
    str: 'UpdateReplicationJob',
  },
  {
    value: 'UpdateRepositoryDescription',
    str: 'UpdateRepositoryDescription',
  },
  {
    value: 'UpdateRepositoryName',
    str: 'UpdateRepositoryName',
  },
  {
    value: 'UpdateRequestValidator',
    str: 'UpdateRequestValidator',
  },
  {
    value: 'UpdateResolver',
    str: 'UpdateResolver',
  },
  {
    value: 'UpdateResource',
    str: 'UpdateResource',
  },
  {
    value: 'UpdateRestApi',
    str: 'UpdateRestApi',
  },
  {
    value: 'UpdateRestrictedExecutionTimes',
    str: 'UpdateRestrictedExecutionTimes',
  },
  {
    value: 'UpdateRfcDescription',
    str: 'UpdateRfcDescription',
  },
  {
    value: 'UpdateRfcExecutionParameters',
    str: 'UpdateRfcExecutionParameters',
  },
  {
    value: 'UpdateRfcExpectedOutcome',
    str: 'UpdateRfcExpectedOutcome',
  },
  {
    value: 'UpdateRfcImplementationPlan',
    str: 'UpdateRfcImplementationPlan',
  },
  {
    value: 'UpdateRfcRollbackPlan',
    str: 'UpdateRfcRollbackPlan',
  },
  {
    value: 'UpdateRfcSchedule',
    str: 'UpdateRfcSchedule',
  },
  {
    value: 'UpdateRfcTitle',
    str: 'UpdateRfcTitle',
  },
  {
    value: 'UpdateRfcWorstCaseScenario',
    str: 'UpdateRfcWorstCaseScenario',
  },
  {
    value: 'UpdateRole',
    str: 'UpdateRole',
  },
  {
    value: 'UpdateRoleAlias',
    str: 'UpdateRoleAlias',
  },
  {
    value: 'UpdateRoleDescription',
    str: 'UpdateRoleDescription',
  },
  {
    value: 'UpdateRoom',
    str: 'UpdateRoom',
  },
  {
    value: 'UpdateRule',
    str: 'UpdateRule',
  },
  {
    value: 'UpdateSAMLProvider',
    str: 'UpdateSAMLProvider',
  },
  {
    value: 'UpdateScalingParameters',
    str: 'UpdateScalingParameters',
  },
  {
    value: 'UpdateScheduledAudit',
    str: 'UpdateScheduledAudit',
  },
  {
    value: 'UpdateSchema',
    str: 'UpdateSchema',
  },
  {
    value: 'UpdateSecret',
    str: 'UpdateSecret',
  },
  {
    value: 'UpdateSecretVersionStage',
    str: 'UpdateSecretVersionStage',
  },
  {
    value: 'UpdateSecurityProfile',
    str: 'UpdateSecurityProfile',
  },
  {
    value: 'UpdateSegment',
    str: 'UpdateSegment',
  },
  {
    value: 'UpdateServer',
    str: 'UpdateServer',
  },
  {
    value: 'UpdateServerCertificate',
    str: 'UpdateServerCertificate',
  },
  {
    value: 'UpdateServerEngineAttributes',
    str: 'UpdateServerEngineAttributes',
  },
  {
    value: 'UpdateService',
    str: 'UpdateService',
  },
  {
    value: 'UpdateServiceAccessPolicies',
    str: 'UpdateServiceAccessPolicies',
  },
  {
    value: 'UpdateShardCount',
    str: 'UpdateShardCount',
  },
  {
    value: 'UpdateSigningCertificate',
    str: 'UpdateSigningCertificate',
  },
  {
    value: 'UpdateSizeConstraintSet',
    str: 'UpdateSizeConstraintSet',
  },
  {
    value: 'UpdateSkillGroup',
    str: 'UpdateSkillGroup',
  },
  {
    value: 'UpdateSnapshotSchedule',
    str: 'UpdateSnapshotSchedule',
  },
  {
    value: 'UpdateSoftwareConfiguration',
    str: 'UpdateSoftwareConfiguration',
  },
  {
    value: 'UpdateSqlInjectionMatchSet',
    str: 'UpdateSqlInjectionMatchSet',
  },
  {
    value: 'UpdateSSHPublicKey',
    str: 'UpdateSSHPublicKey',
  },
  {
    value: 'UpdateStack',
    str: 'UpdateStack',
  },
  {
    value: 'UpdateStackSet',
    str: 'UpdateStackSet',
  },
  {
    value: 'UpdateStage',
    str: 'UpdateStage',
  },
  {
    value: 'UpdateStemmingOptions',
    str: 'UpdateStemmingOptions',
  },
  {
    value: 'UpdateStopwordOptions',
    str: 'UpdateStopwordOptions',
  },
  {
    value: 'UpdateStorageTier',
    str: 'UpdateStorageTier',
  },
  {
    value: 'UpdateStream',
    str: 'UpdateStream',
  },
  {
    value: 'UpdateStreamingDistribution',
    str: 'UpdateStreamingDistribution',
  },
  {
    value: 'UpdateSubnetGroup',
    str: 'UpdateSubnetGroup',
  },
  {
    value: 'UpdateSubscription',
    str: 'UpdateSubscription',
  },
  {
    value: 'UpdateSupportedLicenses',
    str: 'UpdateSupportedLicenses',
  },
  {
    value: 'UpdateSynonymOptions',
    str: 'UpdateSynonymOptions',
  },
  {
    value: 'UpdateTable',
    str: 'UpdateTable',
  },
  {
    value: 'UpdateTeamMember',
    str: 'UpdateTeamMember',
  },
  {
    value: 'UpdateTerminationProtection',
    str: 'UpdateTerminationProtection',
  },
  {
    value: 'UpdateThing',
    str: 'UpdateThing',
  },
  {
    value: 'UpdateThingGroup',
    str: 'UpdateThingGroup',
  },
  {
    value: 'UpdateThingGroupsForThing',
    str: 'UpdateThingGroupsForThing',
  },
  {
    value: 'UpdateThreatIntelSet',
    str: 'UpdateThreatIntelSet',
  },
  {
    value: 'UpdateTrafficPolicyComment',
    str: 'UpdateTrafficPolicyComment',
  },
  {
    value: 'UpdateTrafficPolicyInstance',
    str: 'UpdateTrafficPolicyInstance',
  },
  {
    value: 'UpdateTrail',
    str: 'UpdateTrail',
  },
  {
    value: 'UpdateTrigger',
    str: 'UpdateTrigger',
  },
  {
    value: 'UpdateType',
    str: 'UpdateType',
  },
  {
    value: 'UpdateTypedLinkFacet',
    str: 'UpdateTypedLinkFacet',
  },
  {
    value: 'UpdateUsage',
    str: 'UpdateUsage',
  },
  {
    value: 'UpdateUsagePlan',
    str: 'UpdateUsagePlan',
  },
  {
    value: 'UpdateUser',
    str: 'UpdateUser',
  },
  {
    value: 'UpdateUserDefinedFunction',
    str: 'UpdateUserDefinedFunction',
  },
  {
    value: 'UpdateUserLicenses',
    str: 'UpdateUserLicenses',
  },
  {
    value: 'UpdateUserPool',
    str: 'UpdateUserPool',
  },
  {
    value: 'UpdateUserPoolClient',
    str: 'UpdateUserPoolClient',
  },
  {
    value: 'UpdateUserProfile',
    str: 'UpdateUserProfile',
  },
  {
    value: 'UpdateVolume',
    str: 'UpdateVolume',
  },
  {
    value: 'UpdateVpcLink',
    str: 'UpdateVpcLink',
  },
  {
    value: 'UpdateVTLDeviceType',
    str: 'UpdateVTLDeviceType',
  },
  {
    value: 'UpdateWebACL',
    str: 'UpdateWebACL',
  },
  {
    value: 'UpdateXssMatchSet',
    str: 'UpdateXssMatchSet',
  },
  {
    value: 'UpgradeAppliedSchema',
    str: 'UpgradeAppliedSchema',
  },
  {
    value: 'UpgradeJobTemplate',
    str: 'UpgradeJobTemplate',
  },
  {
    value: 'UpgradePreset',
    str: 'UpgradePreset',
  },
  {
    value: 'UpgradePublishedSchema',
    str: 'UpgradePublishedSchema',
  },
  {
    value: 'UploadArchive',
    str: 'UploadArchive',
  },
  {
    value: 'UploadMultipartPart',
    str: 'UploadMultipartPart',
  },
  {
    value: 'UploadServerCertificate',
    str: 'UploadServerCertificate',
  },
  {
    value: 'UploadSigningCertificate',
    str: 'UploadSigningCertificate',
  },
  {
    value: 'UploadSSHPublicKey',
    str: 'UploadSSHPublicKey',
  },
  {
    value: 'ValidateAccountResource',
    str: 'ValidateAccountResource',
  },
  {
    value: 'ValidateConfigurationSettings',
    str: 'ValidateConfigurationSettings',
  },
  {
    value: 'ValidateDelegate',
    str: 'ValidateDelegate',
  },
  {
    value: 'ValidateLogs',
    str: 'ValidateLogs',
  },
  {
    value: 'ValidateMatchmakingRuleSet',
    str: 'ValidateMatchmakingRuleSet',
  },
  {
    value: 'ValidatePipelineDefinition',
    str: 'ValidatePipelineDefinition',
  },
  {
    value: 'ValidateSecurityProfileBehaviors',
    str: 'ValidateSecurityProfileBehaviors',
  },
  {
    value: 'ValidateTemplate',
    str: 'ValidateTemplate',
  },
  {
    value: 'VerifyTrust',
    str: 'VerifyTrust',
  },
  {
    value: 'ViewBilling',
    str: 'ViewBilling',
  },
  {
    value: 'WipeDevice',
    str: 'WipeDevice',
  },
];
