import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "../../../_services/notifier.service";

@Component({
  selector: "ems-banner",
  templateUrl: "./ems-banner.component.html",
  styleUrls: ["./ems-banner.component.css"],
})
export class EMSBannerComponent implements OnInit {
  // @Input("account") account: any;
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();

  constructor(private notifier: NotifierService) {}

  ngOnInit(): void {}
}
