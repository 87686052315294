import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"],
})
export class AddComponent implements OnInit {
  @Output() hide = new EventEmitter<boolean>();
  stepCount: number = 1;
  userId: string = localStorage.getItem('eId');
  @Input() action: string;
  showInfo: any = null;
  @Input() selectedAccount: any;
  @Input("regions") regions: any;
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  hideModel(event: any) {
    this.hide.emit(event);
  }

  ngOnInit(): void {
    this.selectedAccount = JSON.parse(JSON.stringify(this.selectedAccount));
    if (this.action != "addaccount") {
      this.stepCount = 2;
    } else {
      this.loadCFTLink();
    }

    this.selectedAccount.customRegions = true;
  }

  link: string = "";
  async loadCFTLink() {
    this.loading["link"] = true;
    let apiURL: string = `https://prod.api.swayam.cloud/v3/cft`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(apiURL, {}, header);

    if (result.status == "1" || result.s == "1") {
      this.link = result["template_url"].replace('RootAdminEmailIdValue', this.userId || localStorage.getItem('eId'));;
    }

    this.loading["link"] = false;
  }

  getContinents(regions_: any) {
    let continents: any = [];

    regions_.forEach((region: any) => {
      if (continents.indexOf(region["name"].split(" (")[0]) < 0) {
        continents.push(region["name"].split(" (")[0]);
      }
    });

    continents = continents.sort((a: any, b: any) => {
      return this.getRegions(b).length - this.getRegions(a).length;
    });

    return continents;
  }

  checkForRegion(region_id: string) {
    return this.selectedAccount.regions.indexOf(region_id) > -1;
  }

  getRegions(continent: string) {
    return this.regions["enabled"].filter((region: any) => {
      return region.name.startsWith(continent);
    });
  }

  getDisabledRegions(continent: string) {
    return this.regions["disabled"].filter((region: any) => {
      return region.name.startsWith(continent);
    });
  }

  toggleRegion(event: any, region_id: string) {
    event.preventDefault();
    event.stopPropagation();
    const regionIndex = this.selectedAccount.regions.indexOf(region_id);
    if (regionIndex > -1) {
      this.selectedAccount.regions.splice(regionIndex, 1);
    } else {
      this.selectedAccount.regions.push(region_id);
    }
  }

  toggleAccountStatus(event: any, status: boolean) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedAccount.status = status ? "active" : "inactive";
  }

  toggleRegions(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedAccount.customRegions = !this.selectedAccount.customRegions;
  }

  getDefaultRegionsEnabled() {
    return this.regions["enabled"].filter((region: any) => {
      return this.selectedAccount.regions.indexOf(region.id) > -1;
    });
  }

  loading: any = {
    adding: false,
    deactivate: false,
  };
  roleType: string = "automatic";
  async updateAccount() {
    if (!this.checkForValidations(["accountId", "accountName"])) {
      return;
    }
    if (
      (this.selectedAccount.customRegions &&
        this.selectedAccount.regions.length == 0) ||
      this.getDefaultRegionsEnabled().length == 0
    ) {
      this.errors["regions"] = {
        error: true,
        msg: "Please select atleast one region from default AWS Regions.",
      };
      return;
    } else {
      this.errors["regions"] = {
        error: false,
        msg: null,
      };
    }
    this.loading["adding"] = true;
    let data: any = {
      a: this.action,
      eId: localStorage.getItem("eId"),
      accountId: this.selectedAccount.accountId,
      accountName: this.selectedAccount.accountName,
      cmpName: this.selectedAccount.companyName,
      customRegions: this.selectedAccount.customRegions,
      regions: this.selectedAccount.regions,
      ut: "admin",
      importType: this.selectedAccount.importType,
      status: this.selectedAccount.status,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/awsaccounts`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" && result.msg == "success") {
      this.hideModel(true);
    } else {
      if (result.error.indexOf("valid aws account id") > -1) {
        this.stepCount = 1;
        this.errors["accountId"] = {
          error: true,
          msg: "Please provide a valid aws account id",
        };
      } else {
        this.notifier.alert("Info", "", result.error, "info", 5000);
      }
    }

    this.loading["adding"] = false;
  }

  async deleteAccount() {
    this.loading["deactivate"] = true;
    let data: any = {
      a: "accountStatus",
      eId: localStorage.getItem("eId"),
      accountId: this.selectedAccount.accountId,
      status: this.selectedAccount.status == "active" ? "inActive" : "active",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/awsaccounts`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" && result.msg == "success") {
      this.hideModel(true);
      window.location.reload();
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    this.loading["deactivate"] = false;
  }

  readable: any = {
    accountId: "AWS Account ID",
    accountName: "Account Name",
  };

  validations: any = {
    accountId: {
      length: 12,
      empty: false,
      regex: {
        value: "^[0-9]*$",
        msg: `{{key}} should be Numbers only`,
      },
    },
    accountName: {
      empty: false,
    },
  };

  // Validation Check
  errors: any = {
    accountId: {
      error: false,
      msg: null,
    },
    accountName: {
      error: false,
      msg: null,
    },
  };
  checkForValidation(key: string) {
    if (Object.keys(this.validations[key]).length == 0) {
      this.errors[key]["error"] = false;
      this.errors[key]["msg"] = null;
      return true;
    }
    let value: string = this.selectedAccount[key];
    let reg: any = null;
    if (this.validations[key]["regex"] != undefined) {
      reg = new RegExp(this.validations[key]["regex"]["value"]);
    }
    if (
      this.validations[key]["empty"] != undefined &&
      !this.validations[key]["empty"] &&
      value.trim() == ""
    ) {
      this.errors[key]["error"] = true;
      this.errors[key]["msg"] = `${this.readable[key]} is required`;
    } else if (
      this.validations[key]["length"] != undefined &&
      value.length != this.validations[key]["length"]
    ) {
      this.errors[key]["error"] = true;
      this.errors[key][
        "msg"
      ] = `${this.readable[key]} should be ${this.validations[key]["length"]} characters long`;
    } else if (reg && !reg.test(value)) {
      this.errors[key]["error"] = true;
      this.errors[key]["msg"] = this.validations[key]["regex"]["msg"].replace(
        "{{key}}",
        this.readable[key]
      );
    } else {
      this.errors[key]["error"] = false;
      this.errors[key]["msg"] = null;
      return true;
    }
    return false;
  }

  checkForValidations(keys: any) {
    let check: boolean = true;
    keys.forEach((key: string) => {
      let result = this.checkForValidation(key);
      if (!result) {
        check = false;
        return;
      }
    });
    return check;
  }
}
