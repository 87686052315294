import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";

@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./onboardedmaster.component.html",
  styleUrls: ["./onboardedmaster.component.css"],
})
export class OnBoardedMasterComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  onboardedData: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: "accountId",
      name: "Account ID",
    },
    {
      id: "accountName",
      name: "Account Name",
    },
  ];

  funRef: any = {};

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load(nextToken: any = "") {
    this.notifier.loading(true);

    if (nextToken == "") {
      this.onboardedData = [];
    }

    let data = {
      action: "fetchMasterAccounts",
      lastEvaluatedKey: nextToken != "" ? nextToken : undefined,
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/fetchenabledmasteraccounts`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == "1" || result.s == "1") {
      this.onboardedData = [...this.onboardedData, ...result.masteracnts];
    } else {
      this.onboardedData = [];
      this.notifier.alert("Info", "", result.msg, "info", 5000);
    }

    if (result.lastEvaluatedKey) {
      await this.load(result.lastEvaluatedKey);
    }
    this.notifier.loading(false);
  }

  async toggleWR(account: any) {
    let data = {
      action: "save",
      mid: account.accountId,
      wrEnabled: account.wrEnabled,
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/fetchenabledmasteraccounts`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
    } else {
      this.notifier.alert(
        "Error",
        "",
        result.msg || result.error || result.error_message || result.message,
        "error",
        5000
      );
    }
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
