import { Component, OnInit } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.css"],
})
export class ViewComponent implements OnInit {
  accountId: string;
  regionId: string;

  currentMessage: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.loadScanners();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.loadScanners();
      }
    });
  }

  loading: boolean = true;

  scanners: any = [];

  selectedScanner: any = null;
  view: string = null;

  viewDetails(scanner: any, view: string) {
    this.selectedScanner = scanner;
    this.view = view;
  }

  hideDetails(event: boolean) {
    if (event) {
      this.loadScanners();
    }
    this.selectedScanner = null;
    this.view = null;
  }

  async deleteScanner(scanner: any) {
    scanner["delete"] = true;
    let data: any = {
      action: "delete",
      role_arn: this.arns[0]["role_arn"],
      scanner_id: scanner["scanner_id"],
      scanner_name: scanner["scanner_name"],
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/alerts`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.loadScanners();
      this.notifier.alert(
        "Success",
        "",
        "Scanner Deleted Successfully",
        "success",
        5000
      );
    }

    scanner["delete"] = undefined;
  }

  async loadScanners() {
    this.loading = true;
    this.scanners = [];
    this.checkCAMsStatus();
    let data: any = {
      action: "list",
      account_id: this.accountId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/alerts`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.scanners = result.items || [];
    } else {
      this.notifier.alert("Info", "", result.message, "info", 5000);
    }

    this.loading = false;
  }

  arns: any = [];
  async checkCAMsStatus() {
    let data: any = {
      action: "get",
      account_id: this.accountId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/account`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.arns = result["items"];
    }
  }
}
