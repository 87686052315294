<div class="main">

<div class="row mt-3 main">
  <div class="col-3">
    <div class="card-hold blue-g d-flex flex-column">
      <div>
        <span class="value">{{this.securityAlerts.total+this.governanceAlerts.total+this.dailyspendAlerts.total+this.dailycostAlerts.total}}</span>
        <!-- <span class="icon"><i class="fa-solid fa-arrow-up"></i></span>
        <span class="percent">1.2%</span> -->
      </div>
      <div>
        <span class="title-des">Total Number Of Alerts</span>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div (click)="showHighAlert()" class="card-hold red-g d-flex flex-column cursor">
      <div>
        <span class="value">{{this.securityAlerts.high+this.governanceAlerts.high+this.dailyspendAlerts.high+this.dailycostAlerts.high}}</span>
        <!-- <span class="icon"><i class="fa-solid fa-arrow-up"></i></span>
        <span class="percent">1.2%</span> -->
      </div>
      <div>
        <span class="title-des">High Alerts</span>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div (click)="showMediumAlert()" class="card-hold orange-g d-flex flex-column cursor">
      <div>
        <span class="value">{{this.securityAlerts.medium+this.governanceAlerts.medium+this.dailyspendAlerts.medium+this.dailycostAlerts.medium}}</span>
        <!-- <span class="icon" style="color: white;"><i class="fa-solid fa-arrow-down"></i></span>
        <span class="percent" style="color: white;">1.3%</span> -->
      </div>
      <div>
        <span class="title-des">Medium Alerts</span>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div (click)="showLowAlert()" class="card-hold green-g d-flex flex-column cursor">
      <div>
        <span class="value">{{this.securityAlerts.low+this.governanceAlerts.low+this.dailyspendAlerts.low+this.dailycostAlerts.low}}</span>
        <!-- <span class="icon"><i class="fa-solid fa-arrow-up"></i></span>
        <span class="percent">1.2%</span> -->
      </div>
      <div>
        <span class="title-des">Low Alerts</span>
      </div>
    </div>
  </div>
</div>

<div class="row opt-row">
<div class="col-12 opt-col">
<div class="d-flex opt-col2">
<div class="opt-hold" [class.active-tab]="isSecurityActive"  id="security-tab" (click)="switchTab('security')">
<span class="badge blue-g" >{{this.securityAlerts.total}}</span>&nbsp; Security
</div>
<div class="opt-hold" [class.active-tab]="isGovActive" id="gov-tab" (click)="switchTab('governance')">
  <span class="badge blue-g" >{{this.governanceAlerts.total}}</span>&nbsp; Governance
    </div>
    <div class="opt-hold" [class.active-tab]="isDspendActive" id="ds-tab" (click)="switchTab('dailyspend')">
      <span class="badge blue-g" >{{this.dailyspendAlerts.total}}</span>&nbsp;  Expenditures
      </div>
      <!-- <div class="opt-hold" id="dgov-tab" (click)="switchTab('dailygovernance')">
        Daily Governance
        </div> -->
        <!-- <div class="opt-hold" [class.active-tab]="isDcostActive" id="dc-tab" (click)="switchTab('dailycost')">
          Daily Cost
          </div> -->
          <div class="opt-hold" [class.active-tab]="isDgovActive" id="dgov-tab" (click)="switchTab('dailygovernance')">
        Others
        </div>
      </div>
      <div class="info">
        <i class="fa-solid fa-triangle-exclamation"></i> <span class="info-text">Data will get purged automatically after 30 days </span>
      </div>
</div>
</div>

<div class="row margintop-20">
<div class="col-12 df">
    <div class="search"><i class="fa-solid fa-magnifying-glass"></i> <input class="text-search" type="text" name="" placeholder="Search" id="" [(ngModel)]="searchKeyword" (input)="searchData()" ></div>
    <div class="d-flex">

      <div class="search3 mar-r-20" (click)="refresh()">
        <div class="touch d-flex justify-content-between"> <span class="search-text2" >Refresh</span> <i class="fa-solid fa-arrows-rotate mt search-text2"></i> </div>
     </div>


    <div class="search2 mar-r-20" (click)="showaction()">
       <div class="touch d-flex justify-content-between"> <span class="search-text" >Action</span> <i [ngClass]="{'rotate-icon': showact,'rotate-icon0':!showact}"  class="fa-solid fa-angle-down mt"></i> </div>
        <div *ngIf="showact"  class="oppt-values">
            <span (click)="markAsComplete()" class="optv" [class.opt-active]="clickComplete"><span>Complete</span>
            <!-- <i *ngIf="clickComplete" class="fa-solid fa-check"></i> -->
          </span>
            <span (click)="markAsIncomplete()" class="optv" [class.opt-active]="clickIncomplete"><span>Not Complete</span>
            <!-- <i *ngIf="clickIncomplete" class="fa-solid fa-check"></i> -->
          </span>
            <!-- <span class="optv">Archive</span> -->
        </div>
    </div>

    <div class="search2 mar-r-20" >
      <div (click)="showFilte()" class="touch d-flex justify-content-between"> <span class="search-text" >Filter</span> <i [ngClass]="{'rotate-icon': showFilter,'rotate-icon0':!showFilter}"  class="fa-solid fa-angle-down mt"></i> </div>
       <div *ngIf="showFilter"  class="oppt-values">
        <span *ngIf="isGovActive || isDspendActive" (click)="showfre($event)" class="optv opt-active"><span>Frequency</span><i class="fa-solid fa-angle-down mt"></i></span>
          <span *ngIf="isfre">
           <span class="optv" (click)="filterAlerts('daily')"><span>Daily Once</span><i class="fa-solid fa-check" *ngIf="clickDailyAlert"></i></span>
           <span class="optv" (click)="filterAlerts('every')"><span>Every 30 Mins</span><i class="fa-solid fa-check" *ngIf="clickEveryAlert"></i></span>
           <!-- <span class="optv">Yearly</span> -->
          </span>
          <span (click)="showcre()" class="optv opt-active"><span>Criticality</span><i class="fa-solid fa-angle-down mt"></i></span>
          <span *ngIf="this.iscre">
           <span (click)="filterAlerts('High')" class="optv" ><span> High Alerts</span><i class="fa-solid fa-check" *ngIf="clickHighAlert"></i> </span>
           <span (click)="filterAlerts('Medium')" class="optv" ><span> Medium Alerts</span><i class="fa-solid fa-check" *ngIf="clickMedAlert"></i> </span>
           <span (click)="filterAlerts('Low')" class="optv" ><span> Low Alerts</span><i class="fa-solid fa-check" *ngIf="clickLowAlert"></i> </span>
          </span>
       </div>
   </div>

    <!-- <div class="search2" (click)="showreminder()">
      <div class="touch d-flex justify-content-between"> <span class="search-text" >Remind Me</span> <i [ngClass]="{'rotate-icon': showrem,'rotate-icon0':!showrem}"  class="fa-solid fa-angle-down mt"></i> </div>
        <div *ngIf="showrem" class="oppt-values">
            <span class="optv opt-active"><span>Every Once</span><i class="fa-solid fa-check"></i></span>
            <span class="optv">Every Month</span>
            
        </div>
    </div> -->
</div>
</div>
</div>

<div class="row mt-3">
<div class="col-12">
  <ng-container *ngIf="isSecurityActive">
<table class="table table-responsive table-hover">
<thead>
    <tr>
      <th scope="col"><input *ngIf="isSecurityres" [checked]="secAll" (click)="markAll()" type="checkbox" class="check" name="" id=""></th>
        <th scope="col">Event Time</th>
        <th scope="col">Criticality</th>
        <th scope="col">Description</th>
        <th scope="col">Identifier</th>
        <th scope="col">Invoked By</th>
        <th scope="col">Protocol</th>
        <th scope="col">Source IP</th>
        <th scope="col">From IP - To IP</th>
        
    </tr>
</thead>
<tbody>
  <ng-container  *ngFor="let item of getItemsForPage(currentPage)">
    <tr *ngIf="item.status=='incomplete' && isSecurityres">
        <td><input type="checkbox" [checked]="item.check" (change)="getIdListSec(item)" name="" id=""></td>
        <td>{{item.eventTime}}</td>
        <td *ngIf="item.severity"><span class="badge" [ngClass]="{ redg: item.severity==='High',orangeg: item.severity==='Medium',greeng: item.severity==='Low'}" >{{item.severity}}</span></td>
        <td [innerHTML]="highlightSearch(item.description)"></td>
        <td>{{ item.identifier !== undefined && item.identifier !== '' ? item.identifier : '-' }}</td>
        <td>{{item.createdBy}}</td>
        <td>{{toUpperCase(item.protocal)}}</td>
        <td>{{item.sourceIp}}</td>
        <td>{{item.fromPort}} - {{item.toPort}}</td>
       
    </tr>
    
  
  </ng-container>
  <tr *ngIf="!isSecurityres">
    <td colspan="9" class="text-center">No Security Alerts Found!</td>
  </tr>
</tbody>
</table>

<!-- <div class="d-flex justify-content-end">
<nav>
  <ul class="pagination">
<li class="page-item" [class.disabled]="currentPage ===1">
<a class="page-link" (click)="navigateToPreviousPage()">Previous</a>
</li>
<li class="page-item" *ngFor="let page of getPageNumbers()" [class.active]="currentPage === page">
  <a class="page-link" (click)="currentPage = page">{{page}}</a>
  </li>
  <li class="page-item" [class.disabled]="currentPage === totalPages">
    <a class="page-link" (click)="navigateToNextPage()">Next</a>
    </li>
  </ul>
</nav>
</div> -->
<div class="d-flex justify-content-end">
  <nav>
    <ul class="pagination">
      <li class="page-item" [class.disabled]="currentPage === 1"> <a class="page-link"
          (click)="navigateToPreviousPage()">Previous</a> </li> <ng-container *ngFor="let page of getPageNumbers()">
        <li class="page-item"  *ngIf="page === '...' else notEllipsis" > <a
            class="page-link" (click)="navigateToPage(page)">{{ page }}</a> </li> <ng-template #notEllipsis>
          <li class="page-item" [class.active]="currentPage === page"> <a class="page-link"
              (click)="navigateToPage(page)">{{ page }}</a> </li>
        </ng-template>
      </ng-container>
      <li class="page-item" [class.disabled]="currentPage === totalPages"> <a class="page-link"
          (click)="navigateToNextPage()">Next </a> </li>
    </ul>
  </nav>
</div>
</ng-container>

<app-governance *ngIf="isGovActive" [governanceRes]="governanceRes" [isgovRes]="isGovres" (idlist)="getidGov($event)" [govAll]="govAll" (idlistdailygov)="getIdDailyGov($event)"></app-governance>

<app-daily-spend *ngIf="isDspendActive" [dailyspendRes]="dailyspendRes" (idlist)="getIdList($event)" (idlistdailycost)="getIdListdc($event)" [isspendRes]="isDailySpendRes" [dsAll]="dsAll"></app-daily-spend>

<app-daily-gov *ngIf="isDgovActive" [dailygovRes]="dailygovRes"></app-daily-gov>

<!-- <app-daily-cost *ngIf="isDcostActive" [dailyCostRes]="dailyCostRes" (idlist)="getIdListdc($event)" [isdailycostres]="isDailyCostRes" [dcAll]="dcAll"></app-daily-cost> -->

<app-high-alert *ngIf="highAlertTab" (hideModal)="hideHighAlert($event)" [seHigh]="this.securityAlerts.high" [govHigh]="this.governanceAlerts.high" [dsHigh]="this.dailyspendAlerts.high" [dcHigh]="this.dailycostAlerts.high"></app-high-alert>

<app-med-alert *ngIf="mediumAlertTab" (hideModal)="hideMedAlert($event)" [seMed]="this.securityAlerts.medium" [govMed]="this.governanceAlerts.medium" [dsMed]="this.dailyspendAlerts.medium" [dcMed]="this.dailycostAlerts.medium"></app-med-alert>

<app-low-alert *ngIf="lowAlertTab" (hideModal)="hideLowAlert($event)" [seLow]="this.securityAlerts.low" [govLow]="this.governanceAlerts.low" [dsLow]="this.dailyspendAlerts.low" [dcLow]="this.dailycostAlerts.low" ></app-low-alert>
</div>
</div>
</div>