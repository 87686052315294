import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-compare-plans",
  templateUrl: "./compare-plans.component.html",
  styleUrls: ["./compare-plans.component.css"],
})
export class ComparePlansComponent implements OnInit {
  @Input("plans") plans: any;
  planData: any = {
    standard: {
      name: "Standard",
    },
    advanced: {
      name: "Advanced",
    },
  };

  periods: any = [
    {
      period: "6 Months",
      selected: false,
    },
    {
      period: "9 Months",
      selected: false,
    },
    {
      period: "12 Months",
      selected: false,
    },
  ];

  slice(array: any, count: number) {
    return { array: array.slice(0, count), remaining: array.length - count };
  }

  selectPlan(plan: any) {
    plan["selected"] = true;
    this.plans.forEach((plan_: any) => {
      if (plan_["plan"] != plan["plan"]) {
        plan_["selected"] = false;
      }
    });
  }

  selectPeriod(period: any) {
    period["selected"] = true;
    this.periods.forEach((period_: any) => {
      if (period_["period"] != period["period"]) {
        period_["selected"] = false;
      }
    });
  }

  getFeatures(): any {
    let data: any = [];
    let standardPlan = this.plans.find((plan: any) => {
      return plan["plan"] == "advanced";
    });
    standardPlan["mList"].forEach((plan_: any) => {
      let dt: any = {
        plan: plan_,
        count: 0,
        freetrial: false,
        standard: false,
        advanced: false,
      };
      this.plans.forEach((plan: any) => {
        if (plan["mList"].includes(plan_)) {
          if (plan["plan"] == "freetrial") {
            dt["freetrial"] = true;
          }
          if (plan["plan"] == "standard") {
            dt["standard"] = true;
          }
          if (plan["plan"] == "advanced") {
            dt["advanced"] = true;
          }
          dt["count"] += 1;
        }
      });
      data.push(dt);
    });
    data = data.sort((a: any, b: any) => {
      return a["count"] > b["count"] ? -1 : 1;
    });
    return data;
  }

  constructor() {}

  ngOnInit(): void {
    this.plans = JSON.parse(JSON.stringify(this.plans));
    this.plans.push({
      rate: "0",
      plan: "freetrial",
      mList: [
        "Reports",
        "CloudSupport",
        "Compliance",
        "Operations",
        "Settings",
      ],
    });
  }
}
