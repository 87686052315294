<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">New Scan</div>
    <div class="KBBody flex-1">
      <div class="col-mb-6 mb-2">
        <label>Scan Name: </label>
        <div>
          <input autocomplete="off" class="form-control" type="text" [(ngModel)]="scan_name">
        </div>
        <div *ngIf="errors['scan_name']['is_error']" class="color-wieght-bold">
          *{{ errors['scan_name']['error'] }}
        </div>
      </div>
      <div class="col-mb-6 mb-2">
        <label>Account ID: </label>
        <span>{{accountId}}</span>
      </div>
      <div class="col-mb-6 mb-2">
        <label>Regions: </label>
        <div class="grid-2">
          <div class="flex-start" *ngFor="let region of regions['enabled']">
            <input type="checkbox" [attr.name]="region['id']" [attr.id]="region['id']" [(ngModel)]="region['selected']">
            <label [attr.for]="region.id">{{ region.name }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="KBActions">
      <button class="btn btn-success" (click)="yes()">Create</button>
      <button class="btn btn-light" (click)="no()">Cancel</button>
    </div>
  </div>
</div>