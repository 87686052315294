<div class="bg-blue-main">
  <div class="card">
    <div class="card-header flex-between">
      <h5>Reserved Instances(RI) Recommendations</h5>
      <div class="flex-start">
        <span *ngIf="scanDetails">Last scanned on {{ scanDetails['scanDate'] }}</span>
        <button class=" btn btn-light" *ngIf="!(loading['instances'] || loading['scan'])"
          (click)="load()">Refresh</button>
        <button class=" btn btn-primary-soft" *ngIf="!loading['scan']" (click)="createScan()">Scan</button>
      </div>
    </div>
  </div>
  <div class="discovery-section section">
    <div class="card">
      <div class="card-body">
        <div class="discovery-section-container">
          <div class="item-container">
            <div class="item-highlight flex-start">
              <span>
                ${{ overview['current_ec2'].toFixed(2) }}
              </span>
              <img *ngIf="loading['instances']" src="../assets/img/loading_2.svg" alt="loading scans"
                class="width-18" />
            </div>
            <div class="item-desc">
              Current Spend on On-Demand Instances
            </div>
          </div>
          <div class="item-container">
            <div class="flex-start">
              <div class="item-highlight">${{
                (overview['current_ec2'] - overview['ri_1yr_no_upfront']).toFixed(2) }}</div>
              <div class="item-desc" *ngIf="instances.length > 0"
                   [ngClass]="{'light-green': overview['ri_1yr_no_upfront'] > 0, 'light-red': overview['ri_1yr_no_upfront'] < 0}">
                <span class="inherit">
                  {{(100 - ((overview['current_ec2'] - overview['ri_1yr_no_upfront']) / overview['current_ec2']) *
                        100).toFixed(2) }}%
                </span>
                <span class="inherit">
                  <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="item-desc">
              1 Yr RI
            </div>
          </div>
          <div class="item-container">
            <div class="flex-start">
              <div class="item-highlight">${{
                (overview['current_ec2'] - overview['ri_3yr_no_upfront']).toFixed(2) }}</div>
              <div class="item-desc" *ngIf="instances.length > 0"
                   [ngClass]="{'light-green': overview['ri_3yr_no_upfront'] > 0, 'light-red': overview['ri_3yr_no_upfront'] < 0}">
                <span class="inherit">
                  {{(100 - ((overview['current_ec2'] - overview['ri_3yr_no_upfront']) / overview['current_ec2']) *
                        100).toFixed(2) }}%
                </span>
                <span class="inherit">
                  <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="item-desc">
              3 Yr RI
            </div>
          </div>
          <div class="item-container">
            <div class="flex-start">
              <div class="item-highlight">${{
                overview['partial_run'].toFixed(2) }}</div>
              <div class="item-desc" *ngIf="instances.length > 0"
                [ngClass]="{'light-green': overview['partial_run'] > 0, 'light-red': overview['partial_run'] < 0}">
                <span class="inherit">
                  {{(((overview['current_ec2'] - overview['partial_run']) / overview['current_ec2']) *
                  100).toFixed(2) }}%
                </span>
                <span class="inherit">
                  <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="item-desc">
              On-Demand (Basis 10 hr Schedule)
            </div>
          </div>
          <div class="item-container">
            <div class="flex-start">
              <div class="item-highlight">${{
                (overview['spot_price']).toFixed(2) }}</div>
              <div class="item-desc" *ngIf="instances.length > 0"
                [ngClass]="{'light-green': overview['spot_price'] > 0, 'light-red': overview['spot_price'] < 0}">
                <span class="inherit">
                  {{(100 - (overview['spot_price'] / overview['current_ec2']) *
                  100).toFixed(2) }}%
                </span>
                <span class="inherit">
                  <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="item-desc">
              Spot Price (Basis 10 hr Schedule)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="section">
    <div class="upcoming-expiry">
      <div class="grid-2">
        <div class="card flex-1">
          <div class="card-body">
            <div class="item-container flex-1 flex-column">
              <div class="section-title">
                <div>
                  Upcoming Expirations
                </div>
                <div *ngIf="loading">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                </div>
              </div>
              <div class="flex-center">
                <div class="expiry-graph">
                  <div class="expiry-graph-item">
                    <div class="round-text red-back" (click)="addFilter('expired', true, 'Expired')"
                      [ngClass]="{'big-round': overview['expired'] > 10}">{{
                      overview['expired'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">Expired</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text card-blue-back"
                      (click)="addFilter('renew_7d', true, 'Renew in 7 days')"
                      [ngClass]="{'big-round': overview['renew_7d'] > 10}">
                      {{ overview['renew_7d'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">7d</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text blue-back" (click)="addFilter('renew_30d', true, 'Renew in 30 days')"
                      [ngClass]="{'big-round': overview['renew_30d'] > 10}">{{
                      overview['renew_30d'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">30d</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text blue-back" (click)="addFilter('renew_90d', true, 'Renew in 90 days')"
                      [ngClass]="{'big-round': overview['renew_90d'] > 10}">{{
                      overview['renew_90d'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">90d</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-2 flex-1 graphs">
          <div class="item-container">
            <div class="card">
              <div class="card-body">
                <div class="section-title">
                  <div>
                    Algorithms
                  </div>
                  <div *ngIf="loading">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                </div>
                <div id="algorithms_graph" class="graph">
  
                </div>
              </div>
            </div>
          </div>
          <div class="item-container">
            <div class="card">
              <div class="card-body">
                <div class="section-title">
                  <div>
                    Types
                  </div>
                  <div *ngIf="loading">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                </div>
                <div id="types_graph" class="graph">
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="section">
    <div class="card">
      <div class="card-body table-acm-container">
        <table class="table table-docs">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Instance Name</th>
              <th>Instance Type</th>
              <th>OS</th>
              <!-- <th>RAM</th>
              <th>vCPU</th> -->
              <th>On-Demand Cost</th>
              <th>Basis 10 hr Schedule</th>
              <th>Spot Price</th>
              <th>Potential Savings</th>
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" (click)="selectedInstance = instance" *ngFor="let instance of instances | paginate
                  : {
                      itemsPerPage: perPage,
                      currentPage: page,
                      totalItems: instances.length
                    }; let i = index">
              <td>{{ ((page - 1) * perPage) + i + 1 }}</td>
              <td>
                <div class="flex-start">
                  <span>
                    {{ instance['instanceName'] }}
                  </span>
                  <span class="item-desc" *ngIf="instance['instanceId']">
                    {{ instance['instanceId']}}
                  </span>
                </div>
              </td>
              <td>{{ instance['instanceType'] }}</td>
              <td>{{ instance['os'] }}</td>
              <!-- <td>{{ instance['ram'] }}</td>
              <td>{{ instance['vCpu'] }}</td> -->
              <td>${{ instance['pricing']['odPricing']['pricePerMonth'] }}</td>
              <td>{{ instance['pricing']['odPricing'] ? '$' + instance['pricing']['odPricing']['partialRun'] :
                '-'
                }}
                <span class="item-desc" *ngIf="instance['pricing']['odPricing']"
                  [ngClass]="{'light-green': instance['pricing']['odPricing']['diffPartialRun'] > 0, 'light-red': instance['pricing']['odPricing']['diffPartialRun'] < 0}">
                  <span class="inherit">
                    {{(100 - ((instance['pricing']['odPricing']['pricePerMonth'] -
                    instance['pricing']['odPricing']['diffPartialRun']) /
                    instance['pricing']['odPricing']['pricePerMonth']) *
                    100).toFixed(2) }}%
                  </span>
                  <span class="inherit">
                    <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                  </span>
                </span>
              </td>
              <td>{{ instance['pricing']['spotPricing'] ? '$' + instance['pricing']['spotPricing']['pricePerMonth'] :
                '-'
                }}
                <span class="item-desc" *ngIf="instance['pricing']['spotPricing']"
                  [ngClass]="{'light-green': instance['pricing']['spotPricing']['diffPerMonth'] > 0, 'light-red': instance['pricing']['spotPricing']['diffPerMonth'] < 0}">
                  <span class="inherit">
                    {{((instance['pricing']['spotPricing']['diffPerMonth'] /
                    instance['pricing']['odPricing']['pricePerMonth']) *
                    100).toFixed(2) }}%
                  </span>
                  <span class="inherit">
                    <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                  </span>
                </span>
                <ng-container *ngIf="instance['pricing']['spotPricing']">
                  <br>
                  <span class="item-desc">
                    as of {{ instance['pricing']['spotPricing']['priceOn'] }}
                  </span>
                </ng-container>
              </td>
              <td>
                <span class="light-green-text">
                  ${{fetchLow(instance)}} to ${{fetchHigh(instance)}}
                </span>
              </td>
            </tr>
            <tr *ngIf="loading['instances'] || loading['scan']">
              <td colspan="8" class="center">
                <div class="flex-center center">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  {{scanDetails && scanDetails['scanStatus']}}
                </div>

              </td>
            </tr>
            <tr *ngIf="scanDetails && instances.length == 0 && !(loading['instances'] || loading['scan'])">
              <td colspan="8" class="center">
                <span>No Recommendations Found</span>
              </td>
            </tr>
            <tr *ngIf="!scanDetails && !(loading['instances'] || loading['scan'])">
              <td colspan="8" class="center">
                <span>No Scans Found. Click<span class="nav-link pointer" (click)="createScan()">here</span>to
                  scan</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="paginator">
          <div class="flex-start">
            <span class="no-wrap">Per Page</span>
            <select class="form-select" [(ngModel)]="perPage" (change)="page = 1">
              <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
            </select>
          </div>
          <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="page = $event">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<app-instance-savings *ngIf="selectedInstance" [instance]="selectedInstance"
  (hideModel)="selectedInstance = null"></app-instance-savings>

<div class="move-top" [ngStyle]="{bottom: pageOffset > 600 ? '50px' : '-100px'}" (click)="moveToTop()">
  <i class="fa fa-angle-double-up" aria-hidden="true"></i>
</div>