<div class="card" *ngIf="!cftMissing && !showNotification">
  <div class="card-header flex-between">
    <h5 class="p-0 m-0">Tell Me</h5>
    <button class="btn btn-primary-soft" (click)="showNotification = true">
      History
    </button>
  </div>
  <div class="card-body" *ngIf="!loading">
    <div>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link capitalize" [ngClass]="{active: i == 0}" id="{{ type }}-tab" data-bs-toggle="tab"
            *ngFor="let type of getObjectKeys(types_v2); let i = index" [attr.data-bs-target]="'#' + type "
            type="button" role="tab" [attr.aria-controls]="type" aria-selected="true">
            {{ types_v2[type]['name'] }}
          </button>
        </div>
      </nav>
      <div class="flex-start align-top">
        <div class="tab-content flex-1" id="nav-tabContent">
          <div *ngFor="let type of getObjectKeys(types_v2); let i = index" class="tab-pane fade show"
            [ngClass]="{active: i == 0}" id="{{ type }}" role="tabpanel" [attr.aria-labelledby]="type + '-tab'">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link capitalize" [ngClass]="{active: i == 0}" id="{{ tab }}-tab" data-bs-toggle="tab"
                  *ngFor="let tab of getObjectKeys(types_v2[type]['tabs']); let i = index"
                  [attr.data-bs-target]="'#' + tab " type="button" role="tab" [attr.aria-controls]="tab"
                  aria-selected="true">
                  {{ types_v2[type]['tabs'][tab]['name'] }}
                </button>
              </div>
            </nav>
            <div class="flex-start align-top">
              <div class="tab-content flex-1" id="nav-tabContent">
                <div *ngFor="let tab of getObjectKeys(types_v2[type]['tabs']); let i = index" class="tab-pane fade show"
                  [ngClass]="{active: i == 0}" id="{{ tab }}" role="tabpanel" [attr.aria-labelledby]="tab + '-tab'">
                  <ng-container *ngIf="types_v2[type]['tabs'][tab]['name'] == 'Security'">
                    <div class="card border-none">
                      <!--<h5 class="card-header"></h5>-->
                      <div class="card-header">
                        Network
                      </div>

                      <div class="card-body">
                        <div class="card-dply">
                          <div class="report-types-flex mb-10 card-postn">
                            <table class="table table-docs">
                              <thead>
                                <tr>
                                  <th clsas="max-width-90">Sl. No.</th>
                                  <th>Rule</th>
                                  <!-- <th>Description</th> -->
                                  <th class="max-width-180">Enable/Disable</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="tellMes['security'] && tellMes['security']['items'].length > 0">
                                <tr *ngFor="let tellme of tellMes['security']['items']; let i = index">
                                  <td class="max-width-90">{{ i + 1 }}</td>
                                  <td>
                                    <div class="display-inline"
                                      *ngFor="let type_element of segregateText(tellme['item']); let input_index = index">
                                      <span>
                                        {{ type_element }}
                                      </span>
                                      <input autocomplete="off" [(ngModel)]="tellme['configuration']"
                                        (change)="validate()" type="number" placeholder="value"
                                        (change)="deleteChange = true" class="width-50"
                                        [ngClass]="{'error-text': tellme['error']}"
                                        *ngIf="segregateText(tellme['item']).length > 0 && (input_index + 1 != segregateText(tellme['item']).length)">
                                    </div>
                                  </td>
                                  <td class="max-width-180">
                                    <label class="switch">
                                      <input autocomplete="off" type="checkbox" [(ngModel)]="tellme['enableStatus']"
                                        (change)="deleteChange = true">
                                      <span class="slider round"></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr *ngIf="loading">
                                  <td colspan="4" class="txt-cntr">
                                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="!tellMes['security'] || tellMes['security']['items'].length == 0">
                                <tr>
                                  <td colspan="4" class="center">
                                    No Rules Found
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card border-none">
                      <!--<h5 class="card-header"></h5>-->
                      <div class="card-header">
                        Database
                      </div>

                      <div class="card-body">
                        <div class="card-dply">
                          <div class="report-types-flex mb-10 card-postn">
                            <table class="table table-docs">
                              <thead>
                                <tr>
                                  <th class="max-width-90">Sl. No.</th>
                                  <th>Rule</th>
                                  <!-- <th>Description</th> -->
                                  <th class="max-width-180">Enable/Disable</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="tellMes['database'] && tellMes['database']['items'].length > 0">
                                <tr *ngFor="let tellme of tellMes['database']['items']; let i = index">
                                  <td class="max-width-90">{{ i + 1 }}</td>
                                  <td>
                                    <div class="display-inline"
                                      *ngFor="let type_element of segregateText(tellme['item']); let input_index = index">
                                      <span>
                                        {{ type_element }}
                                      </span>
                                      <input autocomplete="off" [(ngModel)]="tellme['configuration']"
                                        (change)="validate()" type="number" placeholder="value"
                                        (change)="deleteChange = true" class="width-50"
                                        [ngClass]="{'error-text': tellme['error']}"
                                        *ngIf="segregateText(tellme['item']).length > 0 && (input_index + 1 != segregateText(tellme['item']).length)">
                                    </div>
                                  </td>
                                  <td class="max-width-180">
                                    <label class="switch">
                                      <input autocomplete="off" type="checkbox" [(ngModel)]="tellme['enableStatus']"
                                        (change)="deleteChange = true">
                                      <span class="slider round"></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr *ngIf="loading">
                                  <td colspan="4" class="txt-cntr">
                                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="!tellMes['database'] || tellMes['database']['items'].length == 0">
                                <tr>
                                  <td class="center" colspan="4">
                                    No Rules Found
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="types_v2[type]['tabs'][tab]['name'] != 'Security'">
                    <div class="card border-none">
                      <!--<h5 class="card-header"></h5>-->

                      <div class="card-body">
                        <div class="card-dply">
                          <div class="report-types-flex mb-10 card-postn">
                            <table class="table table-docs">
                              <thead>
                                <tr>
                                  <th class="max-width-90">Sl. No.</th>
                                  <th>Rule</th>
                                  <!-- <th>Description</th> -->
                                  <th class="max-width-180">Enable/Disable</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let tellme of tellMes[tab]['items']; let i = index">
                                  <td class="max-width-90">{{ i + 1 }}</td>
                                  <td>
                                    <div class="display-inline"
                                      *ngFor="let type_element of segregateText(tellme['item']); let input_index = index">
                                      <span>
                                        {{ type_element }}
                                      </span>
                                      <input autocomplete="off" [(ngModel)]="tellme['configuration']"
                                        (change)="validate()" type="number" placeholder="value"
                                        (change)="deleteChange = true" class="width-50"
                                        [ngClass]="{'error-text': tellme['error']}"
                                        *ngIf="segregateText(tellme['item']).length > 0 && (input_index + 1 != segregateText(tellme['item']).length)">
                                    </div>
                                  </td>
                                  <td class="max-width-180">
                                    <label class="switch">
                                      <input autocomplete="off" type="checkbox" [(ngModel)]="tellme['enableStatus']"
                                        (change)="deleteChange = true">
                                      <span class="slider round"></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr *ngIf="loading">
                                  <td colspan="4" class="txt-cntr">
                                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="!tellMes[tab] || tellMes[tab]['items'].length == 0"></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="width-350px">
          <div class="label pad-styl">Email IDs</div>

          <div class="additional_contacts">
            <div class="additional_contact width-auto" *ngFor="
                                                                    let contact of additionalContacts;
                                                                    let i = index;
                                                                    trackBy: trackByFn
                                                                  ">
              <input autocomplete="off" type="text" name="contact_value" class="form-control"
                *ngIf="additionalContacts.length - 3 <i i" (keyup)="checkForLast(contact, i)"
                [(ngModel)]="additionalContacts[i]" />
              <input autocomplete="off" type="text" name="contact_value" class="form-control"
                *ngIf="additionalContacts.length - 3 >= i" [(ngModel)]="additionalContacts[i]" />
              <div>
                <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                  *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
              </div>
              <div>
                <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                  *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
              </div>
            </div>
          </div>
          <div class="error-text" *ngIf="totalAdditionalContacts() ==  0">
            At least one email id is required
          </div>
          <div *ngIf="!loading" class="padding-5px">
            <button *ngIf="!savingTellMe" type="button" class="btn btn-light btn-styl"
              [attr.disabled]="!writeAccess ? true : null" (click)="saveTellMe()">
              Update
            </button>
            <img src="../assets/img/loading_2.svg" *ngIf="savingTellMe" alt="loading scans" class="width-20px" />
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="card-body center" *ngIf="loading">
    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
  </div>
</div>
<div class="card" *ngIf="!cftMissing && showNotification">
  <tell-me-history [accountId]="accountId" [regionId]="regionId" [types]="types_v2" [names]="names"
    *ngIf="showNotification" (hideModal)="hideNotification()"></tell-me-history>
</div>
<app-cft-missing [tellMes]="tellMes" [types]="types" *ngIf="cftMissing"></app-cft-missing>