import { Component, OnInit } from "@angular/core";
import { APIService } from "../api/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

declare let window: any;

@Component({
  selector: "app-download-doc",
  templateUrl: "./download-doc.component.html",
  styleUrls: ["./download-doc.component.css"],
})
export class DownloadDocComponent implements OnInit {
  constructor(
    private apiServer: APIService,
    private router: ActivatedRoute,
    private navigator: Router
  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      if (params["path"]) {
        this.downloadFile(params["path"]);
      } else {
        this.navigator.navigate(["/"]);
      }
    });
  }

  async downloadFile(file_path: string) {
    let data: any = {
      a: 'getAttachment',
      fileName: file_path
    }

    let apiURL: string  = `${environment.apiURL}/general/getpresignedurl`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, {});

    if(result.s == '1' || result.status == '1') {
      let url: string = result['preUrl'];

      let a: any = document.createElement('a');

      a.download = true;
      a.href = url;
      a.click();

      setTimeout(() => {
        window.close();
      }, 1000);
    }

  }
}
