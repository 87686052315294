<div class="card">
  <h5 class="card-header casedetailsstyle-between">
    <div class="flex-start">
      <div *ngIf="showHeader">
        <a [routerLink]="'/dash/reports/home'" class="default-link">
          <button class="btn btn-light flex-start">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>Reports</span>
          </button>
        </a>
      </div>
      <span *ngIf="showHeader">Daily Backup Report</span>
    </div>
    <div class="flex-start">
      <button class="btn btn-light flex-start" (click)="load()">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        <span>
          Refresh
        </span>
      </button>
      <button *ngIf="instances && instances.length > 0" class="btn btn-light flex-start" (click)="export()">
        <i class="fa fa-download" aria-hidden="true"></i>
        <span>
          Export
        </span>
      </button>
      <button class="btn btn-primary-soft flex-start" (click)="createScanTrigger()">
        <i class="fa fa-search" aria-hidden="true"></i>
        <span class="color-inherit">
          Scan
        </span>
      </button>
    </div>
  </h5>
  <div class="card-body overflow">
    <table class="table table-docs table-hover" id="backup-report-table">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of instancesHeader">{{ h.name }}</th>
        </tr>
      </thead>

      <ng-container *ngIf="!loading && instances.length > 0">
        <tbody *ngFor="let i of instances; let j = index">
          <tr>
            <td>
              <div class="flex-center">
                <ng-container *ngIf="expandable">
                  <i *ngIf="!i.showNested" class="fa fa-caret-right"></i>
                  <i *ngIf="i.showNested" class="fa fa-caret-down"></i>
                </ng-container>
                <span>
                  {{ j + 1 }}
                </span>
              </div>
            </td>
            <td *ngFor="let h of instancesHeader">
              <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <span>
                  {{ i[h.id] || '-' }}
                </span>
              </span>
              <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
            </td>
          </tr>
          <tr *ngIf="i.showNested" class="nestedstyle">
            <td [attr.colspan]="instancesHeader.length + 1">
              <nTable [headers]="snapshotsHeader" [data]="i.snapshots" (hideModal)="hideNested($event)"></nTable>
            </td>
          </tr>
        </tbody>
      </ng-container>
      <tbody *ngIf="loading">
        <tr>
          <td [attr.colspan]="instancesHeader.length + 1">
            <div class="flex-center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
              <span>
                Loading..
              </span>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!loading && instances.length == 0">
        <tr>
          <td [attr.colspan]="instancesHeader.length + 1">
            <div class="flex-center">
              <span>
                No Data Found
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<consolidated-createscan-backupreport *ngIf="createScan" [apiUrl]="getURl()" [data]="getData()" [description]="
    'Depending on the number of snapshots in your account this may take some time to complete. <br> Are you sure you want to continue?'
  " [message]="'Scan Requested Successfully, [message]'" (hideModal)="hideModal($event)">
</consolidated-createscan-backupreport>