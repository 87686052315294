<div class="div_01">
  <div>
    Dollar Value: <span>{{dollarValue.value}} <span class="dollar_value_04">({{dollarValue.type}})</span></span>
  </div>
  <button *ngIf="getSelectedInstances().length >  0" type="button" class="btn btn-light"
    (click)="launchSelected()">Launch
    ({{getSelectedInstances().length}})</button>
  <button class="btn btn-primary-soft" (click)="load()">
    Refresh
  </button>
</div>
<nav>
  <div class="nav nav-tabs header_id_19" id="nav-tab" role="tablist">
    <button class="nav-link" [ngClass]="{active: index == 0}" *ngFor="let header of selectedHeaders; let index = index"
      [attr.id]="header.id + '_' + index+'-tab'" data-bs-toggle="tab"
      [attr.data-bs-target]="'#'+header.id + '_' + index" type="button" role="tab"
      [attr.aria-controls]="header.id + '_' + index" aria-selected="true">
      <span class="header_key_19">{{header.key}}</span>
      <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      <span class="header_key_19">{{header.value}}</span>
    </button>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show" [ngClass]="{active: index == 0}"
    *ngFor="let header of selectedHeaders; let index = index" [attr.id]="header.id + '_' + index" role="tabpanel"
    [attr.aria-labelledby]="header.id + '_' + index+'-tab'">
    <div class="pricing_strategy_container">
      <div class="pricing_sheet_body">
        <div *ngIf="header.value == 'Exclude'">
          <div class="no_strategy">
            No Pricing Plan
          </div>
        </div>
        <div *ngIf="header.value != 'Exclude'">
          <div class="no_strategy"
            *ngIf="pricingStrategy[header.key] && pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length == 0">
            No Pricing Plan
          </div>
          <div *ngIf="pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length > 0">
            <div>
              <div *ngIf="months[header.key] && getSortedKeys(months[header.key]).length > 0">
                <div class="div_44">
                  <table class="table table-hover table-docs table_45">
                    <thead>
                      <tr>
                        <td rowspan="2"></td>
                        <td rowspan="2" class="rowspan_52">
                          Instance Type
                        </td>
                        <td rowspan="2" class="rowspan_52">
                          OS
                        </td>
                        <td rowspan="2" class="rowspan_52">
                          Disk Size
                        </td>
                        <td rowspan="2" class="rowspan_52">
                          Disk Price
                        </td>
                        <td rowspan="2" class="rowspan_52">
                          CPU
                        </td>
                        <td rowspan="2" class="rowspan_52">
                          RAM
                        </td>
                        <td rowspan="2" class="rowspan_52">
                          Resource ID
                        </td>
                        <td></td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td *ngIf="strategy != 'OD'" class="strategy_72"
                            [attr.colspan]="months[header.key][strategy]['total']">
                            <span class="strategy_74">
                              {{ strategy }}
                            </span>
                          </td>
                          <ng-container *ngIf="strategy == 'OD'">
                            <td class="strategy_72" *ngFor="let obj of loadCol(header.key); let i = index"
                              [attr.colspan]="obj['colspan']">
                              <span class="strategy_74">
                                OD ({{ obj['hours_per_month'] }} H/M)
                              </span>
                            </td>
                          </ng-container>

                        </ng-container>

                      </tr>
                      <tr class="position90">
                        <td class="position91">Month</td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td class="month_93"
                            *ngFor="let date of getArrayByLength(months[header.key][strategy]['total'])">
                            <span class="strategy_74">
                              {{ months[header.key][strategy]['indexs'][date] }}
                              ({{parsedDate(months[header.key][strategy]['dates'][date])}})
                            </span>
                            <span class="cursor_99"
                              [title]="getMoreDetails(months[header.key][strategy]['dates'][date], months[header.key][strategy]['totalDays'][date], months[header.key][strategy]['totalDays'].length, date)">
                              <i class="fa fa-info-circle circle_101" aria-hidden="true"></i>
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let os of getIntances(header.key)" class="position90">
                        <td>
                          <span class="checked_110">
                            <input autocomplete="off" type="checkbox" (change)="selectInstance(os)"
                              [attr.checked]="checkForChecked(os.guid) ? true : null" class="checked_113" name="" id="">
                          </span>
                        </td>

                        <td class="instance_117">
                          {{os.instanceName}}</td>
                        <td class="instance_117">
                          {{os.os}}</td>
                        <td class="volume_size_122">
                          {{os.volumeSize}}</td>
                        <td class="volume_size_122">
                          {{os.volumeCost}}
                        </td>
                        <td class="volume_size_122">
                          {{os.cpu}}</td>
                        <td class="volume_size_122">
                          {{os.ram}}</td>
                        <td class="volume_size_122">
                          {{os.launchStatus ? os.resourceId : '-'}}
                          <ng-container *ngIf="os.launchStatus && os.resourceId">
                            <ng-container *ngIf="os.State">
                              <span *ngIf="os.State == 'LOADING'">
                                <img src="../assets/img/loading_2.svg" alt="loading scans" class="loadingScan_136" />
                              </span>
                              <span *ngIf="os.State != 'LOADING'" class="state_138">({{os.State}})</span>
                            </ng-container>

                          </ng-container>
                        <td>
                          <span class="lanch_Instance" (click)="launchInstance(os, header)" *ngIf="!os.launchStatus">
                            Launch
                          </span>
                          <span class="lanch_Instance" *ngIf="os.launchStatus">
                            <span class="terminated_153" *ngIf="os.State == 'terminated'">
                              Re Launch
                            </span>
                            <span class="terminated_153"
                              *ngIf="os.State != 'LOADING' && os.State != 'terminated' && os.launchStatus">
                              Change Request
                            </span>
                            <span *ngIf="os.State == 'LOADING'">
                              <img src="../assets/img/loading_2.svg" alt="loading scans" class="loadingScan_136" />
                            </span>
                          </span>
                        </td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td class="ng_container_161"
                            *ngFor="let date of getArrayByLength(months[header.key][strategy]['total'])">
                            <span class="strategy_74">
                              <input autocomplete="off" type="number" class="form-control price_165" disabled
                                [attr.value]="getPrice(os, strategy, months[header.key][strategy]['start'] + date).value">
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                      <tr class="position90">
                        <td colspan="9" class="position172">
                          Total Spend
                        </td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td class="ng_container_161"
                            *ngFor="let date of getArrayByLength(months[header.key][strategy]['total'])">
                            <span class="date_178">
                              {{ getColumnTotal(header.key, months[header.key][strategy]['start'] + date, strategy) }}
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div *ngFor="let strategy of pricingStrategy[header.key]['plans']; let i = index" style="margin-bottom: 10px;">
                  <div style="border-bottom: 1px solid rgb(221, 221, 221);">
                    {{strategy.plan}}
    
                  </div>
                  <div style="padding: 5px; flex: 1; max-width: 100; overflow-x: auto;">
                    <table class="table table-hover table-docs" style="width: 100%;">
                      <thead>
                        <tr class="position90">
                          <td>
                            <span style="width: 50px;">
                              <input autocomplete="off" type="checkbox" style="width: auto; min-width: auto;" name="" id="">
                            </span>
                          </td>
                          <td
                            style=" font-weight: bold; position: sticky; left: -5px; padding: 0px 8px; background-color: #fff;">
                            Months</td>
                          <td class="class="ng_container_161" style="padding: 3px; text-align: center;"
                            *ngFor="let _ of getArray(strategy.plan); let n = index">
                            {{n + 1}}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let os of getIntances(header.value)" class="position90">
                          <td>
                            <span style="width: 50px;">
                              <input autocomplete="off" type="checkbox" style="width: auto; min-width: auto;" name="" id="">
                            </span>
                          </td>
    
                          <td
                            style=" font-weight: bold; position: sticky; left: -5px; padding: 0px 8px; background-color: #fff;">
                            {{os.OSII}}</td>
                          <td *ngFor="let _ of getArray(strategy.plan); let n = index" style="text-align: center;">
                            <input autocomplete="off" type="number" class="form-control" style=" margin: 0px auto;"
                              [attr.value]="getPrice(os, strategy.plan, n)">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> -->
              </div>
              <div *ngIf="!months[header.key]" class="month_231">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="loadingScan_136" />
              </div>
              <div *ngIf="months[header.key] && getKeys(months[header.key]).length == 0" class="month_231">
                <span>No Pricing Details Found</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>

<oscheck-upload-bom [os]="confirmOs" (hideModel)="confirmCheck($event)" *ngIf="confirmOs.length > 1">
</oscheck-upload-bom>

<style>
  input:disabled {
    background-color: transparent;
  }
</style>