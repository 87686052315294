<div class="card" *ngIf="!selectedRequirement">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" (click)="hideModel(false)">
        < Back</button>
          <h5>
            RBI Compliance - {{job.name}}
          </h5>
    </div>
    <div class="flex-start">
      <!-- <button class="btn btn-danger-soft float-right" (click)="deleteJob(job)">
        <img src="../assets/img/loading_2.svg" class="width-margin-right" alt="loading scans"
          *ngIf="loading['delete']" />
        Delete
      </button> -->
      <button class="btn btn-light float-right" (click)="showFilesValid = true">
        Files
      </button>
    <button class="btn btn-success-soft float-right" (click)="exportCompliance()" *ngIf="!loading['checks']">
        Export
      </button>
      <button class="btn btn-primary-soft float-right" (click)="loadChecks()">
        Refresh
      </button>
    </div>

  </div>
  <div class="card-body">
    <div *ngIf="loading && loading['checks']" class="flex-center">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar" />
    </div>
    <ng-container *ngIf="!loading['checks']">
      <div class="review-alert mb-3" *ngIf="checks.length - fetchTotalAnsweredChecks().length > 0">
        <div>
          <i class="fa fa-info-circle" aria-hidden="true"></i>
        </div>
        <div class="alert-body">
          <div>
            <div class="alert-title">Continue Reviewing</div>
            <div>You have unanswered questions. Continue reviewing and answer the questions for each BFSI RBI Compliance.</div>
          </div>
          <div>
            <button class="btn btn-primary-soft continue-lenses">
              <span>Continue Reviewing</span>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
              <div class="continue-lenses-absolute">
                <div *ngFor="let check of checksAcc" class="button-lens"
                  (click)="selectedRequirement = check['check_root']">
                  {{ check['check_root'] }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header flex-between">
          <h5>BFSI RBI Compliance</h5>
        </div>
        <div class="card-body" *ngIf="!loading['questions']">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Name</th>
                <th class="center">Total Questions</th>
                <th class="center">Answered</th>
                <th class="center">Unanswered</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let check of checksAcc; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <span class="nav-link" (click)="selectedRequirement = check['check_root']">{{ check['check_root']
                    }}</span>
                </td>
                <td class="center">
                  {{check['count']}}
                </td>
                <td class="center">
                  {{check['answered']()}}
                </td>
                <td class="center">
                  {{check['unanswered']()}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-rbi-compliance-questions *ngIf='selectedRequirement' [checks]="checks" [filters]="filters" [checksAcc]="checksAcc" [job]="job" [files]="files"
  [requirement]="selectedRequirement" (resetFiles)="loadFiles()" (hideModal)="load()"></app-rbi-compliance-questions>
<app-files-viewer
*ngIf="showFilesValid"
[job]="job"
[files]="files"
[question]="null"
[questions]="checks"
[disable]="false"
(resetFiles)="loadFiles()"
(hideModel)="showFilesValid = false"
></app-files-viewer>
