import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";

import { APIService, tokenExpired } from "./../../api/api.service";
import { NotifierService } from "./../../_services/notifier.service";

@Component({
  selector: "forgot-password",
  templateUrl: "./forgot.component.html",
  styleUrls: [
    "./../register/register.component.css",
    "./../login.component.css",
  ],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  tokenExpired: any = tokenExpired;
  @Output() hideModel = new EventEmitter<boolean>();
  otpRequired: boolean = false;
  currentImage: number = 0;
  imageTimer: any = null;
  whitelabelData: any = {
    'pd': {
      'appName': '',
      'logoDark': '',
      'logoLight': '',
    }
  }
  carousel: any = [
    {
      title: "Dashboards",
      description:
        "Gain valuable insights and make data-driven decisions faster and more effectively, while also improving collaboration and productivity within your team",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_Dashboard_BlueBG.svg",
    },
    {
      title: "Security",
      description:
        "Advanced algorithms and analytics, the platform can analyze vast amounts of data in real-time and provide you with valuable insights into your cloud operations.",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_+Security_BlueBG.svg",
    },
    {
      title: "Cost Optimisation",
      description:
        "Optimize your cloud costs and performance with ${this.whitelabelData['pd']['appName']}'s advanced analytics and automation, freeing up resources for business innovation.",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/3_Cost+Optimisation_BlueBg.svg",
    },
  ];

  loading: any = {
    forgot: false,
    otp: false,
  };
  validations: any = {
    username: {
      policy: {
        check: {
          key: "otpSent",
          value: false,
        },
        required: true,
        regex: (text: string) => {
          let regexFormat: RegExp =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
          return regexFormat.test(text);
        },
      },
      name: "Email ID",
    },
    otp: {
      policy: {
        required: true,
        check: {
          key: "otpSent",
          value: true,
        },
      },
      name: "OTP",
    },
    newPassword: {
      policy: {
        required: true,
        check: {
          key: "otpSent",
          value: true,
        },
      },
      name: "New Password",
    },
    newConfirmPassword: {
      policy: {
        required: true,
        check: {
          key: "otpSent",
          value: true,
        },
      },
      name: "Confirm New Password",
    },
  };
  errors: any = {
    username: {
      error: false,
      message: "",
    },
    otp: {
      error: false,
      message: "",
    },
    newPassword: {
      error: false,
      message: "",
    },
    newConfirmPassword: {
      error: false,
      message: "",
    },
  };
  showPassword: boolean = false;
  passwordInterval: any = null;
  validating: boolean = false;

  validate(): boolean {
    let ref: any = this;
    let check: boolean = true;
    Object.keys(this.validations).forEach((key: string) => {
      this.errors[key]["error"] = false;
      if (
        (this.validations[key]["policy"]["check"] &&
          ref[this.validations[key]["policy"]["check"]["key"]] ==
            this.validations[key]["policy"]["check"]["value"]) ||
        !this.validations[key]["policy"]["check"]
      ) {
        if (
          this.validations[key]["policy"]["required"] &&
          (!ref[key].trim() || ref[key].trim() == "")
        ) {
          this.errors[key]["error"] = true;
          this.errors[key][
            "message"
          ] = `${this.validations[key]["name"]} is a mandatory field`;
          check = false;
        } else if (
          this.validations[key]["policy"]["regex"] &&
          this.validations[key]["policy"]["regex"](ref[key]) === false
        ) {
          this.errors[key]["error"] = true;
          this.errors[key][
            "message"
          ] = `Entered ${this.validations[key]["name"]} is not valid`;
          check = false;
        } else {
          this.errors[key]["error"] = false;
        }
      } else {
        this.errors[key]["error"] = false;
      }
    });

    return check;
  }

  nextImage() {
    if (this.currentImage == this.carousel.length - 1) {
      this.currentImage = 0;
    } else {
      this.currentImage += 1;
    }
  }

  setImage(count: number) {
    this.currentImage = count;
    if (this.imageTimer) {
      clearInterval(this.imageTimer);
    }
    this.imageTimer = setInterval(() => {
      this.nextImage();
    }, 5000);
  }

  alterPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.passwordInterval = setTimeout(() => {
        this.showPassword = false;
        clearInterval(this.passwordInterval);
        this.passwordInterval = null;
      }, 5000);
    } else if (this.passwordInterval) {
      clearInterval(this.passwordInterval);
      this.passwordInterval = null;
    }
  }

  public userNameForm = new UntypedFormGroup({
    username: new UntypedFormControl("addet0260@gmail.com", []),
  });

  public newPasswordForm = new UntypedFormGroup({
    otp: new UntypedFormControl("1234", []),
    new_password: new UntypedFormControl("1234", []),
    confirm_new_password: new UntypedFormControl("1234", []),
  });

  get username_form() {
    return this.userNameForm.controls;
  }

  get new_password_form() {
    return this.newPasswordForm.controls;
  }

  otpSent: boolean = false;

  username: string = "";
  otp: string = "";
  newPassword: string = "";
  newConfirmPassword: string = "";
  role: string = "Admin";
  hideRoleSelection: any = false;
  CBSPortal: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {
    this.whitelabelData = this.route.snapshot.data.data;
    
    this.carousel.forEach((page: any) => {
      page['description'] = page['description'].replace("${this.whitelabelData['pd']['appName']}", this.whitelabelData['pd']['appName'])    
    })

    if (this.whitelabelData['pd']["logoLight"]) {
      this.changeFavicon(this.whitelabelData['pd']['logoLight']);
    }
  }

  changeFavicon(newFaviconURL: any) {
    const favicon: any = document.getElementById("favicon");
    if (favicon) {
      favicon.href = newFaviconURL;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.type = "image/png";
      newFavicon.href = newFaviconURL;
      newFavicon.id = "favicon";
      document.head.appendChild(newFavicon);
    }
  }

  ngOnInit(): void {
    this.tokenExpired["checking"] = false;

    this.imageTimer = setInterval(() => {
      this.nextImage();
    }, 5000);
    if (
      window.location.href.indexOf("app.swayam.cloud") != -1 ||
      this.CBSPortal
    ) {
      this.role = "Admin";
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf("client.swayam.cloud") != -1) {
      this.role = "Client";
      this.hideRoleSelection = true;
    }
  }

  passwordChecker(password: string) {
    let passwordObj: any = {
      strength: 0,
      policies: {
        "Must have atleast 8 characters": password.length > 7,
        "Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number":
          false,
        "Must have atleast 1 Special Character": false,
        "Longer Password": false,
      },
    };
    if (passwordObj.policies["Must have atleast 8 characters"]) {
      passwordObj.strength += 1;
    }
    if (
      password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{0,}$/gm)
    ) {
      passwordObj.strength += 1;
      passwordObj.policies[
        "Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number"
      ] = true;
    }
    if (
      password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/gm)
    ) {
      passwordObj.strength += 1;
      passwordObj.policies["Longer Password"] = true;
    }
    if (password.match(/[^A-z\s\d][\\\^]?/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies["Must have atleast 1 Special Character"] = true;
    }
    return passwordObj;
  }

  resend: string = '';
  async sendOTP() {
    if (this.tokenExpired["checking"]) {
      return false;
    }
    if (!this.otpSent && !this.validate()) {
      return false;
    }
    this.loading["forgot"] = true;
    let data = {
      eId: this.username,
      ut: this.role == "Admin" ? "msops" : "client",
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${
      this.role == "Admin" ? "admin" : "client"
    }/users/forgotpassword`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == "1" || result.s == "1") {
      this.resend = result.otpMail || 'no-reply@swayam.cloud';
      if (result.statusCode == 200) {
        this.otpSent = true;
        // this.notifier.alert("Success", "", result.msg, "success", 5000);
      } else {
        this.notifier.alert(
          "Info",
          "",
          result.error || result.msg,
          "info",
          5000
        );
      }
    } else {
      if (result.error.toLowerCase().includes("email")) {
        this.errors["username"] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert(
          "Info",
          "",
          result.error || result.msg,
          "info",
          5000
        );
      }
    }
    this.loading["forgot"] = false;

    return true;
  }

  getKeys(obj: any): any {
    return Object.keys(obj);
  }

  async resetPassword() {
    if (this.tokenExpired["checking"]) {
      return false;
    }
    if (!this.validate()) {
      return false;
    }
    if (this.passwordChecker(this.newPassword)["strength"] < 3) {
      this.errors["newPassword"] = {
        error: true,
        message: "Entered Password doesn't meet the minimum password policy",
      };
      return false;
    } else {
      this.errors["newPassword"] = {
        error: false,
        message: "",
      };
    }
    if (this.newPassword != this.newConfirmPassword) {
      this.errors["newConfirmPassword"] = {
        error: true,
        message: "Passwords do not match",
      };
      // this.notifier.alert("Error", "", "Passwords do not match", "error", 5000);
      return false;
    } else {
      this.errors["newConfirmPassword"] = {
        error: false,
        message: "",
      };
    }
    this.loading["otp"] = true;
    let data = {
      eId: this.username,
      pswd: this.newPassword,
      otp: this.otp.toString(),
      ut: this.role == "Admin" ? "msops" : "client",
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${
      this.role == "Admin" ? "admin" : "client"
    }/users/confirmforgotpassword`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == "1" || result.s == "1") {
      this.router.navigate(["/login"], { queryParams: { pass: true } });
    } else {
      if (result.error.toLowerCase().includes("otp")) {
        this.errors["otp"] = {
          error: true,
          message: result.error,
        };
      } else if (result.error.toLowerCase().includes("password")) {
        this.errors["newPassword"] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert("Info", "", result.error, "info", 5000);
      }
    }
    this.loading["forgot"] = false;
    this.loading["otp"] = false;
    return true;
  }

  ngOnDestroy() {
    if (this.imageTimer) {
      clearInterval(this.imageTimer);
    }
  }
}
