import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardReportsComponent implements OnInit {
  selectedReport: string = "";
  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";
  whitelabelData: any = {
    'pd': {
      'appName': '',
      'logoDark': '',
      'logoLight': '',
    }
  }
  reports: any = {
    "This Account": [
      {
        id: "sg",
        name: "Security Groups Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Security+Groups+Report%404x.png",
        description:
          "Gain valuable insights into your Security Group with our in-depth report analysis.",
      },
      {
        id: "inventory",
        name: "Inventory Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Inventory+Report%404x.png",
        description:
          "Get a real-time view of your cloud assets with our Inventory Report.",
      },
      {
        id: "inventory-billing",
        name: "RI Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Inventory+Report%404x.png",
        description:
          "The 'RI Report' provides comprehensive details on AWS EC2 instances and Reserved Instance (RI) usage, offering insights into the billing and utilization of resources within the infrastructure.",
      },
      {
        id: "dailybackup",
        name: "Daily Backup Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Daily+Backup+Report%404x.png",
        description:
          "Daily Backup Report provides an overview of all backups completed in the last 24 hours",
      },
      {
        id: "mis",
        name: "MIS Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/MIS+Report%404x.png",
        description: "A real time view of your Jira tickets",
      },
      {
        id: "uptime",
        name: "Uptime Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Uptime+Report%404x.png",
        description:
          "Get real-time insights into your system's uptime with our comprehensive Uptime Report",
      },
      {
        id: "diskdetails",
        name: "Disk Details Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Disk+Details+Report%404x.png",
        description:
          "Get detailed insights into your system's disk usage with our report's breakdown of disk space consumption",
      },
      {
        id: "aging_snapshots",
        name: "Snapshot Ageing Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Snapshot+Ageing+Report%404x.png",
        description:
          "Ensure compliance and security of your environment with ${this.whitelabelData['pd']['appName']}'s Snapshot Ageing Report",
      },
      {
        id: "backup_reports",
        name: "Backup Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Backup.png",
        description:
          "A comprehensive report that helps you identify your backups of AMI's",
      },
      {
        id: "rds_backup_reports",
        name: "RDS Backup Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Backup.png",
        description:
          "A comprehensive report that helps you identify your backups of RDS",
      },
      {
        id: "mbr_reports",
        name: "MBR Report",
        show: () => {
          return localStorage.getItem("role") == "Admin";
        },
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/MBR+Report+(Management+Business+Review+Report)%404x.png",
        description: "Management business review report",
        downloadOnly: true,
        new: () => {
          return (
            moment().valueOf() <
            moment("30/06/2023", "DD/MM/YYYY").endOf("day").valueOf()
          );
        },
      },
    ],
    "All Accounts": [
      {
        id: "consolidated_ec2_report",
        name: "Consolidated EC2 Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/MBR+Report+(Management+Business+Review+Report)%404x.png",
        description: "Consolidated EC2 Report",
        downloadOnly: true,
        new: () => {
          return (
            moment().valueOf() <
            moment("15/07/2023", "DD/MM/YYYY").endOf("day").valueOf()
          );
        },
      },

      {
        id: "consolidated_backup_report",
        name: "Consolidated Backup Report",
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/MBR+Report+(Management+Business+Review+Report)%404x.png",
        description: "Consolidated Backup Report",
      //   downloadOnly: true,
      //   new: () => {
      //     return (
      //       moment().valueOf() <
      //       moment("15/07/2023", "DD/MM/YYYY").endOf("day").valueOf()
      //     );
      //   },
      },
      {
        id: "mbr_reports_all",
        name: "MBR Report",
        show: () => {
          return localStorage.getItem("role") == "Admin";
        },
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/MBR+Report+(Management+Business+Review+Report)%404x.png",
        description: "Management business review report",
        downloadOnly: true,
        new: () => {
          return (
            moment().valueOf() <
            moment("15/07/2023", "DD/MM/YYYY").endOf("day").valueOf()
          );
        },
      },
      {
        id: "latest_mbr_reports_all",
        name: "Latest MBR Report",
        show: () => {
          return localStorage.getItem("role") == "Admin";
        },
        icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/MBR+Report+(Management+Business+Review+Report)%404x.png",
        description: "Management business review report",
        downloadOnly: true,
        new: () => {
          return (
            moment().valueOf() <
            moment("15/07/2023", "DD/MM/YYYY").endOf("day").valueOf()
          );
        },
      },
    ],
  };

  constructor(private route: ActivatedRoute) {
    this.whitelabelData = this.route.snapshot.data.data;
    
    Object.keys(this.reports).forEach((page: any) => {
      this.reports[page].forEach((report: any) => {
        report['description'] = report['description'].replace("${this.whitelabelData['pd']['appName']}", this.whitelabelData['pd']['appName'])    
      })
    })
  }

  ngOnInit(): void {}
}
