<div class="card">
  <h5 class="card-header">Trigger History</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{display: history.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of history; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': history.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div class="no_data_found">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>