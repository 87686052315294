import { Component, OnDestroy, OnInit } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { cftrequired } from "src/app/dash/dash.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-tell-me",
  templateUrl: "./tell-me.component.html",
  styleUrls: ["./tell-me.component.css", "./../ticket/ticket.component.css"],
})
export class TellMeComponent implements OnInit, OnDestroy {
  cftMissing: boolean = false;
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";

  types: any = ["Security", "Governance"];

  names: any = {
    security: "Security",
    governance: "Governance",
    database: "Database",
    dailySpend: "Daily Spend",
    dailyGovernance: "Daily Governance",
    dailyCost: "DailyCost",
  };

  types_v2: any = {
    "every-30-mins": {
      name: "Every 30 Mins",
      tabs: {
        security: {
          name: "Security",
        },
        governance: {
          name: "Governance",
        },
      },
    },
    "every-day": {
      name: "Daily Once",
      tabs: {
        dailySpend: {
          name: "Daily Spend",
        },
        dailyGovernance: {
          name: "Daily Governance",
        },
        dailyCost: {
          name: "Daily Cost",
        },
      },
    },
  };
  selectedType: string = null;
  additionalContacts: string[] = [""];

  loading: boolean = true;

  accountId: string = null;
  regionId: string = null;
  currentMessage: any;
  showNotification: boolean = false;

  deleteSelectedAdditionalContact(index: number) {
    this.additionalContacts.splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != "" &&
      value.length > 0 &&
      this.additionalContacts.length - 1 == index
    ) {
      this.additionalContacts.push("");
    } else if (
      value == "" &&
      value.length == 0 &&
      this.additionalContacts.length - 2 == index &&
      this.additionalContacts[this.additionalContacts.length - 1] == ""
    ) {
      this.additionalContacts.splice(this.additionalContacts.length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.additionalContacts.push("");
  }

  trackByFn() {
    return true;
  }

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.regionId = localStorage.getItem("regionId");
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.accountId = localStorage.getItem("accountId");
        this.load();
      }
    });
  }

  getCurrentAccount() {
    let account = localStorage.getItem("accountId");
    if (account) {
      return JSON.parse(localStorage.getItem("accountIdData")).list.find(
        (account_: any) => {
          return account_.accountId == account;
        }
      );
    } else {
      return false;
    }
  }

  totalAdditionalContacts() {
    return this.additionalContacts.filter((contact: any) => {
      return contact != "";
    }).length;
  }

  tellMes: any = {};
  async load() {
    return new Promise(async (resolve: any, reject: any) => {
      this.cftMissing =
        (cftrequired["tell-me"]["cft"] &&
          cftrequired["tell-me"]["cft"] >
            this.getCurrentAccount()["cft_current_version"]) ||
        !this.getCurrentAccount()["cft_status"];
      if (this.cftMissing) {
        this.loading = false;
        this.notifier.loading(false);
      }
      this.loading = true;
      this.tellMes = {};
      this.selectedType = null;
      this.showNotification = false;
      this.additionalContacts = [""];
      let data: any = {
        accountId: this.accountId,
        region: this.regionId,
        operation: "fetchConfig_v3",
      };

      let header = {
        Authorization: localStorage.getItem("t"),
      };

      let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

      let result: any = await this.apiServer.postDataPromis(
        apiURL,
        data,
        header
      );

      if (result.status == 1 || result.s == 1) {
        this.getObjectKeys(result["configuration"]).forEach((tellme: any) => {
          if (tellme == "emails") {
            this.additionalContacts =
              result["configuration"]["emails"].length > 0
                ? result["configuration"]["emails"]
                : [""];
            return;
          }

          if (this.cftMissing) {
            result["configuration"][tellme] = result["configuration"][
              tellme
            ].map((tell: any) => {
              tell["item"] = tell["item"].replace("<Box>", "<value>");
              return tell;
            });
          }

          let type: string = this.types.find((type: string) => {
            return type === tellme;
          });
          if (type) {
            if (!this.selectedType) {
              this.selectedType = type;
            }
            this.tellMes[type] = { items: [], show: false };
            this.tellMes[type]["items"] = result["configuration"][tellme];
          } else {
            if (!this.selectedType) {
              this.selectedType = tellme;
            }
            this.tellMes[tellme] = { items: [], show: false };
            this.tellMes[tellme]["items"] = result["configuration"][tellme];
          }
        });
      } else {
        this.notifier.alert("Info", "", result.error_message, "info", 5000);
      }

      // console.log(this.tellMes);

      this.loading = false;
      resolve(true);
    });
  }

  savingTellMe: boolean = false;
  deleteChange: boolean = false;

  validate() {
    let error: string = "";
    this.getObjectKeys(this.names).forEach((tellme: any) => {
      this.tellMes[tellme]["items"].forEach((item: any, i: number) => {
        if (
          item["item"].includes("<Box>") &&
          (item["configuration"] == undefined ||
            item["configuration"].toString().trim() == "")
        ) {
          item["error"] = true;
          if (error == "") {
            error = `Missing value in ${this.names[tellme]}, Rule no. ${i + 1}`;
          }
        } else if (
          item["item"].includes("<Box>") &&
          item["configuration"] &&
          item["configuration"].toString().trim().length > 5
        ) {
          item["error"] = true;
          if (error == "") {
            error = `Value can't be greater than 99999 in ${
              this.names[tellme]
            }, Rule no. ${i + 1}`;
          }
        } else {
          item["error"] = false;
        }
        //  else {
        //   if (isNaN(item["configuration"])) {
        //     item["error"] = true;
        //     error = `Invalid value in ${tellme}, Rule no. ${i + 1}`;
        //   } else if (
        //     Number(item["configuration"]) < 0 ||
        //     Number(item["configuration"]) > 100
        //   ) {
        //     item["error"] = true;
        //     error = `Value is of percentage type, Please enter value between 0 and 100 in ${tellme}, Rule no. ${
        //       i + 1
        //     }`;
        //   } else {
        //     item["error"] = undefined;
        //   }
        // }
      });
    });
    return error;
  }

  async saveTellMe(event: any = null) {
    if (event) {
      event.stopPropagation();
    }
    let error: string = this.validate();
    if (error != "") {
      this.notifier.alert("Info", "", error, "info", 5000);
      return;
    }

    let masterEmailId: string;
    if (this.urlPrefix == "client") {
      masterEmailId = localStorage.getITem("masterId");
    } else {
      masterEmailId = localStorage.getItem("selectedClientList");
    }

    let account: any = JSON.parse(localStorage.getItem("accountIdData"))[
      "list"
    ].find((account: any) => {
      return account["accountId"] == this.accountId;
    });

    this.savingTellMe = true;
    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      operation: "saveConfig",
      accountName: account["accountName"],
      masterEmail: masterEmailId || this.userId,
      configuration: {
        emails: this.additionalContacts.filter((contact: any) => {
          return contact != "";
        }),
      },
    };

    this.getObjectKeys(this.tellMes).forEach((tellme: any) => {
      data["configuration"][tellme] = this.tellMes[tellme]["items"];
    });

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = ` ${environment.apiURL}/${this.urlPrefix}/tellme/operations`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == 1 || result.s == 1) {
      this.deleteChange = false;
      this.notifier.alert(
        "Success",
        "",
        "Configuration updated successfully",
        "success",
        5000
      );
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.savingTellMe = false;
  }

  showTellMes: boolean = false;

  hideNotification() {
    this.selectedType = this.types[0];
    setTimeout(() => {
      this.showNotification = false;
    }, 100);
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  segregateText(text: string) {
    return text.split("<Box>");
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
