<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Security Groups attached to multiple instances</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
      <div class="KBBody table-responsive ">

        <table class="table table-hover">
          <thead class="blue">
            <tr>
              <td>S.N.</td>
              <td>Instance Name</td>
              <td>Instance ID</td>
              <td>Image ID</td>
              <td>VPC ID</td>
              <td>Subnet ID</td>
              <td>Instance Type</td>
              <td>Platform</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of  instanceAttachtedToSG; let k = index" >
              <td scope="row" class="m-4">{{k+1}}</td>
              <td>
                {{item.sg.ec2_info[0].ec2_name}}
              </td>
              <td>{{item.instance}}</td>
              <td>{{item.sg.ec2_info[0].image_id}}</td>
              <td>{{item.sg.ec2_info[0].vpc_id}}</td>
              <td>{{item.sg.ec2_info[0].subnet_id}}</td>
              <td>{{item.sg.ec2_info[0].instance_type}}</td>
              <td>{{item.sg.ec2_info[0].platform_details}}</td>
            </tr>
          </tbody>
        </table>

        <!-- <table class="table table-hover mb-0">
            <thead class="blue">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">Instance Name</td>
                <td scope="col">Security Group Name</td> 
              </tr>
            </thead>
          

            <tbody>
              <ng-container *ngFor="let i of instanceAttachtedToSG; let j = index">
              <tr >
                <td scope="row" class="m-4">{{j+1}}</td>

                <td class="collapsible-cell" (click)="toggleSubRow(j+'ins')">   
                  <span> <i class="fa-regular" [ngClass]="{'fa-square-plus': !expandedRows[j+'ins'], 'fa-square-minus': expandedRows[j+'ins']}"></i> &nbsp;        
                   
                    <span *ngFor="let item of i.sg.ec2_info">
                      {{item.ec2_name}}
                      <ng-container *ngIf="!(i.sg.ec2_info.length==1)">,</ng-container>
                    </span>

                  </span>   
                </td>

                <td>   
                    <span *ngFor="let it of i.sg.sg_info">
                      {{it.sg_name}}
                      <ng-container *ngIf="!(i.sg.sg_info.length==1)">,</ng-container>
                    </span>
                </td>

              </tr>  

              <tr  *ngIf="expandedRows[j+'ins']">
                <td  colspan="8">
                <table class="table table-hover">
                  <thead class="liteblue">
                    <tr>
                      <td>S.N.</td>
                      <td>Instance ID</td>
                      <td>Image ID</td>
                      <td>VPC ID</td>
                      <td>Subnet ID</td>
                      <td>Instance Type</td>
                      <td>Platform</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of  i.sg.ec2_info; let k = index" >
                      <td scope="row" class="m-4">{{k+1}}</td>
                      <td>{{i.instance}}</td>
                      <td>{{item.image_id}}</td>
                      <td>{{item.vpc_id}}</td>
                      <td>{{item.subnet_id}}</td>
                      <td>{{item.instance_type}}</td>
                      <td>{{item.platform_details}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              
              </tr>

              <tr  *ngIf="expandedRows[j+'sg']">
                <td  colspan="8">
                <table class="table table-hover">
                  <thead class="liteblue">
                    <tr>
                      <td>S.N.</td>
                      <td>Security Group Name</td>
                      <td>Security Group ID</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of i.sg.sg_info; let k = index" >
                      <td scope="row" class="m-4">{{k+1}}</td>
                      <td>{{item.sg_name}}</td>
                      <td>{{item.sg_id}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              
              </tr>
              
              
            </ng-container>
            </tbody> 
        </table> -->
        
          <!-- <div class="btnHold">
            <div class="bhold">
              <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
              <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
              [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
            </div>
          </div> -->


        <!-- <div class="KBActions">
          <button class="btn btn-light" (click)="no()">Cancel</button>
        </div> -->
      
    </div>
          
</div>
