<div class="card-header margin-height-60">
  <span class="title font-height-align">IAM Groups</span>
  <button *ngIf="!fetching" class="btn btn-primary-soft float-margin-10" (click)="refresh()">
    <i class="fa fa-refresh" aria-hidden="true"></i>
    Refresh
  </button>
</div>
<div [ngStyle]="{display: !groupLoading ? 'block': 'none'}">
  <div class="display-content-item-gap">
    <div class="back-color-width">
      <div>
        <div>
          <Strong>Groups</Strong>
        </div>
        <div>
          <span class="size-font-2">{{groupDetail.groups}}</span>
        </div>
      </div>
    </div>
    <div
       class="back-color-width-200">
      <div class="display-gap-10">
        <div class="padd-06">
          <i class="fa fa-exclamation-circle font-color-white" aria-hidden="true"></i>
        </div>
        <div>
          <div>
            <Strong class="color-white">Without Users</Strong>
          </div>
          <div>
            <span class="color-size-font">{{groupDetail.without_Users}}</span>
          </div>
        </div>
      </div>
    </div>
    <div
     class="back-color-width-200">
      <div class="display-gap-10">
        <div class="padd-06">
          <i class="fa fa-exclamation-circle font-color-white" aria-hidden="true"></i>
        </div>
        <div>
          <div>
            <Strong class="color-white">With Inline Policies</Strong>
          </div>
          <div>
            <span class="color-size-font">{{groupDetail.with_Inline_Policies}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="margin-item-height-10"
    [ngStyle]="{display: groupDetail.groups == '-' ? 'none': 'flex'}">
    <div
      class="back-color-top-70">
      <span class="font-padding-display">Groups without Users</span>
      <hr class="mar-10">
      <div>
        <div id="graph_without_user" class="border-width-height"></div>
      </div>
    </div>

    <div
      class="back-color-top-70">
      <span class="font-padding-display">Inline Policies</span>
      <hr class="mar-10">
      <div>
        <div id="graph_with_inline" class="border-width-height"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="groupLoading">
  <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="width-mar-10">
</div>

<style>
  #graph_without_user canvas,
  #graph_with_inline canvas {
    width: 100%;
    height: 100%;
  }
</style>