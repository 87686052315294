<div class="amis_status">
  <div>
    <div class="status_container color-green" *ngIf="data.status == 'Completed'">
      <div>
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </div>
      <div>
        {{data.status}}
      </div>
    </div>
    <div class="status_container" *ngIf="data.status == 'In progress'">
      <div class="animate_rotate">
        <i class="fa fa-spinner" aria-hidden="true"></i>
      </div>
      <div>
        {{data.status}}
      </div>
    </div>
    <div class="failed_ami" *ngIf="data.status == 'Failed'">
      <div class="status_container color-red">
        <div>
          <i class="fa fa-times-circle" aria-hidden="true"></i>
        </div>
        <div>
          {{data.status}}
        </div>
      </div>
      <div class="failed_ami_message_container">
        <div class="message_back">
          {{data.fr}}
        </div>
        <div class="showdown"></div>
        <div class="message_container">
          {{data.fr}}
        </div>
      </div>
    </div>
  </div>
</div>