<div class="certificate-screen">
  <div class="certificate-container">
    <div class="certificate-header">
      {{ new ? 'Request': 'Clone' }} Certificate
    </div>
    <div class="cartificate-body">
      <div class="cert">
        <div class="cert-header">
          <div class="cert-header-bold">
            Domain Name
          </div>
          <div>
            Provide one or more domain names for your certificate.
          </div>
        </div>

        <div class="cert-body">
          <div class="form-group">
            <label>
              Fully qualified domain name
            </label>
            <div>
              <input autocomplete="off" type="text" [(ngModel)]="this.requestCertificate.domain_name" name=""
                class="form-control" id="">
            </div>
          </div>
        </div>

      </div>
      <div class="cert">
        <div class="cert-header">
          <div class="cert-header-bold">
            Alternate Names - Optional
          </div>
          <div>
            You can add additional names to this certificate. For example, if you're requesting a certificate for
            "www.example.com",
            you might want to add the name "example.com" so that customers can reach your site by either name.
          </div>
        </div>

        <div class="cert-body">
          <div class="form-group" *ngIf="this.requestCertificate.subject_alternate_names.length > 0">
            <div class="flex-start" *ngFor="let name of this.requestCertificate.subject_alternate_names; let i = index">
              <input autocomplete="off" type="text" class="form-control" id="" [(ngModel)]="name['name']">
              <button class="btn btn-light" (click)="addAlternateName()">
                <i class="fa fa-plus"></i>
              </button>
              <button class="btn btn-light" (click)="removeAlternateName(i)">
                <i class="fa fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="center" *ngIf="this.requestCertificate.subject_alternate_names.length == 0">
            <div>
              <span (click)="addAlternateName()" class="nav-link">Click here</span>to add alternate name
            </div>
          </div>
        </div>

      </div>
      <div class="cert">
        <div class="cert-header">
          <div class="cert-header-bold">
            Validation method
          </div>
          <div>
            Select a method for validating domain ownership.
          </div>
        </div>

        <div class="cert-body">
          <div class="flex-top validation-method" (click)="this.requestCertificate.validation_method = 'DNS'">
            <div>
              <div class="radio-custom" [ngClass]="{selected: this.requestCertificate.validation_method == 'DNS'}">
              </div>
            </div>
            <div>
              <div class="text-bold">DNS validation - recommended</div>
              <div class="small-text">
                Choose this option if you are authorized to modify the DNS configuration for the domains in your
                certificate request.
              </div>
            </div>
          </div>
          <div class="flex-top validation-method" (click)="this.requestCertificate.validation_method = 'EMAIL'">
            <div>
              <div class="radio-custom" [ngClass]="{selected: this.requestCertificate.validation_method == 'EMAIL'}">
              </div>
            </div>
            <div>
              <div class="text-bold">Email validation</div>
              <div class="small-text">
                Choose this option if you do not have permission or cannot obtain permission to modify the DNS
                configuration for the
                domains in your certificate request.
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="cert">
        <div class="cert-header">
          <div class="cert-header-bold">
            Key algorithm
          </div>
          <div>
            Select an encryption algorithm. Some algorithms may not be supported by all AWS services.
          </div>
        </div>

        <div class="cert-body">
          <div class="flex-top validation-method" (click)="keyAlorithm = 'RSA'">
            <div>
              <div class="radio-custom" [ngClass]="{selected: keyAlorithm == 'RSA'}">
              </div>
            </div>
            <div>
              <div class="text-bold">RSA 2048</div>
              <div class="small-text">
                RSA is the most widely used key type.
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="certificate-footer">
      <div class="cert-body flex-end">
        <button class="btn btn-primary-soft" *ngIf="!loading" (click)="requestCert()">
          {{ new ? 'Request': 'Clone' }}
        </button>
        <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        <button class="btn btn-light" (click)="hideModel.emit(false)">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>