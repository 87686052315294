import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-s3",
  templateUrl: "./s3.component.html",
  styleUrls: ["./s3.component.css"],
})
export class S3Component implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  buckets: any = [];
  destroyed: boolean = false;
  scanDetails: any = {
    status: "-",
    lastScan: "-",
  };

  overview: any = {
    buckets: () => {
      return this.buckets.length;
    },
    objects: () => {
      let total: number = 0;
      this.buckets.forEach((bucket: any) => {
        total += Number(bucket["ObjectCount"]);
      });
      return total;
    },
    size: () => {
      let total: number = 0;
      this.buckets.forEach((bucket: any) => {
        total += Number(bucket["size"]);
      });
      return total.toFixed(2);
    },
  };

  recommendationsHeader: any = [
    {
      id: "reason",
      name: "Description",
    },
    {
      id: "Original Cost",
      name: "Original Spent",
    },
    {
      id: "Recommended Cost",
      name: "Recommended Spent",
    },
    {
      id: "Savings",
      name: "Potential Savings",
    },
  ];

  resourceGroup: string = localStorage.getItem("selectedResourceGroup");
  selectedCloudPartner: string = localStorage.getItem("cp-selected");

  selectedBucket: any = null;
  loading: boolean = true;
  pagination: any = {
    perPage: 10,
    page: 1,
  };

  pages: any = [10, 50, 100, 200];

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  accountId: string = "";
  regionId: string = "";
  currentMessage: any;
  router: any;
  type: string;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService,
    private activeRoute: ActivatedRoute,
    private navigator: Router
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.router = this.activeRoute.params.subscribe((values: any) => {
      if(this.selectedCloudPartner == 'azure') return;
      if (values["type"]) {
        this.type = values["type"];
      } else {
        this.type == null;
      }

      if (this.type != "operations" && this.type != "cost-controls") {
        this.navigator.navigate(["/dash/s3/cost-controls"]);
      } else {
        this.loadRecommendations();
      }
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }
      
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.loadRecommendations();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.loadRecommendations();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.loadRecommendations();
      }
    });
  }

  async loadRecommendations() {
    this.loading = true;
    this.buckets = [];
    let data: any = {
      a: this.type == "operations" ? "fetchAll" : "fetch",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/s3`;

    if (this.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/central`;
      data = {
        action: "list_blob_storage",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      this.destroyed ||
      (this.selectedCloudPartner == "azure" && (data["subscription_id"] != this.accountId || data["resource_group_name"] != this.resourceGroup)) ||
      (this.selectedCloudPartner == "aws" && (data["accountId"] != this.accountId || data["region"] != this.regionId))
    ) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      result["data"] = result["data"] || result['containers_with_blobs']
      if (result["data"]) {
        this.buckets = result["data"].map((bucket: any) => {
          if (bucket["size"]) {
            bucket["size"] = Number(bucket["size"]);
          } else {
            bucket["size"] = 0;
          }
          if (bucket["recommendation"]) {
            bucket["recommendation"].forEach((recom: any) => {
              if (recom["Original Cost"]) {
                recom["Original Cost"] = Number(recom["Original Cost"]).toFixed(
                  5
                );
              }
              if (recom["Recommended Cost"]) {
                recom["Recommended Cost"] = Number(
                  recom["Recommended Cost"]
                ).toFixed(5);
              }
              if (recom["Savings"]) {
                recom["Savings"] = Number(recom["Savings"]).toFixed(5);
              }
            });
          }
          return bucket;
        });
      }
      if (this.selectedCloudPartner == "aws") {
        this.scanDetails["status"] = result["scanStatus"];
        this.scanDetails["lastScan"] = result["scanDate"]
          ? moment(result["scanDate"]).format("MMM DD, YYYY hh:mm A")
          : "-";
        if (this.scanDetails["status"] != "Completed") {
          if (
            data.accountId == this.accountId &&
            data.region == this.regionId
          ) {
            setTimeout(() => {
              if (
                this.accountId == data["accountId"] &&
                this.regionId == data["region"]
              ) {
                this.loadRecommendations();
              }
            }, 10000);
          }
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    } else {
      this.scanDetails["status"] = "-";
      this.scanDetails["lastScan"] = "-";
      this.loading = false;
    }
  }

  filterText: string = "";
  filteredTable() {
    if (this.filterText == "") return this.buckets;
    return this.buckets.filter((bucket: any) => {
      return bucket["BucketName"]
        .toLowerCase()
        .includes(this.filterText.toLowerCase());
    });
  }

  async createScan() {
    this.loading = true;
    this.buckets = [];
    let data = {
      a: "scan",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/s3`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      this.destroyed ||
      data["accountId"] != this.accountId ||
      data["region"] != this.regionId
    ) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert(
        "Success",
        "",
        "Scan created successfully",
        "success",
        5000
      );
      this.scanDetails["status"] = "Scanning";
      this.scanDetails["lastScan"] = moment().format("MMM DD, YYYY hh:mm A");
      setTimeout(() => {
        this.loadRecommendations();
      }, 10000);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
    this.router.unsubscribe();
  }
}
