import { Component, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { APIService } from "../../api/api.service";
import { NotifierService } from "../../_services/notifier.service";

import * as moment from "moment";

declare let flatpickr: any;
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./view.scheduler.component.html",
  styleUrls: ["./scheduler.component.css"],
})
export class viewSchedulertComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  showCreateShedule = false;
  showLog = false;

  funRef: any = {};
  accountId: any;
  regionId: any = "ap-south-1";
  currentMessage: any;

  showInnerData: boolean = false;
  scheduleHeaders: any = [
    {
      id: "name",
      name: "Name",
      show: true,
      sort: { sort: true, direction: "Asc" },
      type: "String",
      filter: true,
      style: { width: 0 },
      click: "showData",
    },
    /*{ id: 'action', name: 'Action' },*/
    {
      id: "desc",
      name: "Desc",
    },
    {
      id: "timeZone",
      name: "Time Zone",
    },
    {
      id: "startDateTime",
      name: "Start Date",
      sort: { sort: true, direction: "Asc" },
      pipe: this.filterDate,
      transform: (data: any, value: any): String => {
        return data[value]
          ? moment(data[value]).utc().format("DD-MMM-YYYY hh:mm A")
          : "-";
      },
    },
    {
      id: "endDateTime",
      name: "End Date",
      sort: { sort: true, direction: "Asc" },
      pipe: this.filterDate,
      transform: (data: any, value: any): String => {
        return data[value]
          ? moment(data[value]).utc().format("DD-MMM-YYYY hh:mm A")
          : "-";
      },
    },
    { id: "action", name: "Action" },
    {
      id: "status",
      name: "Status",
      transform: (data: any, value: any): String => {
        return data[value] == "P" ? "Paused" : "Active";
      },
    },
  ];

  targetEC2Header: any = [
    {
      id: "instanceId",
      name: "Instance Id",
      show: true,
      //sort: { sort: true, direction: 'Asc' },
      type: "String",
      filter: false,
      //style: { width: 0 },
      //click: 'showData',
    },
    {
      id: "tagName",
      name: "Name",
      show: true,
      type: "String",
      filter: false,
    },
    {
      id: "instanceType",
      name: "Instance Type",
      show: true,
      type: "String",
      filter: false,
    },
    {
      id: "publicIp",
      name: "Private IP / Public IP",
      show: true,
      type: "String",
      filter: false,
    },
    {
      id: "instanceStatus",
      name: "Instance Status",
      show: true,
      type: "String",
      filter: false,
    },
    //{
    //  id: 'tags',
    //  name: 'Tags',
    //  show: true,
    //  type: 'String',
    //  filter: true
    //},
  ];

  cShedule: any = {
    _id: "0",
    frequency: "Once",
    timeZone: "IST",
    rule: {
      monthyRepeat: "Days",
      frequencyWeekDay: {
        Friday: false,
        Monday: false,
        Saturday: false,
        Sunday: false,
        Thursday: false,
        Tuesday: false,
        Wednesday: false,
      },
      dayOfMonth: [],
    },
    retention: {
      unit: "Always",
      value: 1,
    },
    alerts: {
      onSuccess: "",
      onError: "",
      others: "",
    },
  };

  dayOfMonth: any = [];
  instancesList: any = [];
  rdsList: any = [];
  rdsClusterList: any = [];
  tagList: any = [];
  scheduleRules: any = [];
  showEc2SearchAutocomplete = false;
  showEc2SearchAutocompleteList: any = [];
  subView = "Basic Details";
  public regions = [
    { id: "ap-south-1", name: "Asia Pacific (Mumbai) ap-south-1" },
    { id: "us-east-1", name: "US East (N. Virginia) us-east-1" },
    { id: "us-east-2", name: "US East (Ohio) us-east-2" },
    { id: "us-west-1", name: "US West (N. California) us-west-1" },
    { id: "us-west-2", name: "US West (Oregon)us-west-2" },
    { id: "ap-northeast-3", name: "Asia Pacific (Osaka-Local) ap-northeast-3" },
    { id: "ap-northeast-2", name: "Asia Pacific (Seoul) ap-northeast-2" },
    { id: "ap-southeast-1", name: "Asia Pacific (Singapore) ap-southeast-1" },
    { id: "ap-southeast-2", name: "Asia Pacific (Sydney) ap-southeast-2" },
    { id: "ap-northeast-1", name: "Asia Pacific (Tokyo) ap-northeast-1" },
    { id: "ca-central-1", name: "Canada (Central) ca-central-1" },
    { id: "eu-central-1", name: "Europe (Frankfurt) eu-central-1" },
    { id: "eu-west-1", name: "Europe (Ireland) eu-west-1" },
    { id: "eu-west-2", name: "Europe (London) eu-west-2" },
    { id: "eu-west-3", name: "Europe (Paris) eu-west-3" },
    { id: "eu-north-1", name: "Europe (Stockholm) eu-north-1" },
    { id: "me-south-1", name: "Middle East (Bahrain) me-south-1" },
    { id: "sa-east-1", name: "South America (São Paulo) sa-east-1" },
    { id: "ap-east-1", name: "Asia Pacific (Hong Kong) ap-east-1" },
    { id: "af-south-1", name: "Africa (Cape Town) af-south-1" },
    { id: "ap-southeast-3", name: "Asia Pacific (Jakarta) ap-southeast-3" },
    { id: "eu-south-1", name: "Europe (Milan) eu-south-1" },
  ];
  public selectedRegion = "Asia Pacific (Mumbai) ap-south-1";
  public scheduleMaps: any;

  public logShData: any;
  public logData: any;
  public otpModal = false;
  public otpValue = "";

  public cancelOTP = false;
  public otpValid = false;
  public otpValided = false;
  public optErrorMSG = "";
  public otpPurpose = "";

  ec2SearchType = "Select";
  ec2SearchValue = "";

  ec2SearchValueHint = "";
  ec2SearchValuePlaceholder = "";
  ec2PageSize = 10;
  public timeZones: any = [
    {
      value: "Dateline Standard Time",
      abbr: "DST",
      offset: -12,
      isdst: false,
      text: "(UTC-12:00) International Date Line West",
      utc: ["Etc/GMT+12"],
    },
    {
      value: "UTC-11",
      abbr: "U",
      offset: -11,
      isdst: false,
      text: "(UTC-11:00) Coordinated Universal Time-11",
      utc: [
        "Etc/GMT+11",
        "Pacific/Midway",
        "Pacific/Niue",
        "Pacific/Pago_Pago",
      ],
    },
    {
      value: "Hawaiian Standard Time",
      abbr: "HST",
      offset: -10,
      isdst: false,
      text: "(UTC-10:00) Hawaii",
      utc: [
        "Etc/GMT+10",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Rarotonga",
        "Pacific/Tahiti",
      ],
    },
    {
      value: "Alaskan Standard Time",
      abbr: "AKDT",
      offset: -8,
      isdst: true,
      text: "(UTC-09:00) Alaska",
      utc: [
        "America/Anchorage",
        "America/Juneau",
        "America/Nome",
        "America/Sitka",
        "America/Yakutat",
      ],
    },
    {
      value: "Pacific Standard Time (Mexico)",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      text: "(UTC-08:00) Baja California",
      utc: ["America/Santa_Isabel"],
    },
    {
      value: "Pacific Daylight Time",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      text: "(UTC-07:00) Pacific Daylight Time (US & Canada)",
      utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"],
    },
    {
      value: "Pacific Standard Time",
      abbr: "PST",
      offset: -8,
      isdst: false,
      text: "(UTC-08:00) Pacific Standard Time (US & Canada)",
      utc: [
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "PST8PDT",
      ],
    },
    {
      value: "US Mountain Standard Time",
      abbr: "UMST",
      offset: -7,
      isdst: false,
      text: "(UTC-07:00) Arizona",
      utc: [
        "America/Creston",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Hermosillo",
        "America/Phoenix",
        "America/Whitehorse",
        "Etc/GMT+7",
      ],
    },
    {
      value: "Mountain Standard Time (Mexico)",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      utc: ["America/Chihuahua", "America/Mazatlan"],
    },
    {
      value: "Mountain Standard Time",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      text: "(UTC-07:00) Mountain Time (US & Canada)",
      utc: [
        "America/Boise",
        "America/Cambridge_Bay",
        "America/Denver",
        "America/Edmonton",
        "America/Inuvik",
        "America/Ojinaga",
        "America/Yellowknife",
        "MST7MDT",
      ],
    },
    {
      value: "Central America Standard Time",
      abbr: "CAST",
      offset: -6,
      isdst: false,
      text: "(UTC-06:00) Central America",
      utc: [
        "America/Belize",
        "America/Costa_Rica",
        "America/El_Salvador",
        "America/Guatemala",
        "America/Managua",
        "America/Tegucigalpa",
        "Etc/GMT+6",
        "Pacific/Galapagos",
      ],
    },
    {
      value: "Central Standard Time",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      text: "(UTC-06:00) Central Time (US & Canada)",
      utc: [
        "America/Chicago",
        "America/Indiana/Knox",
        "America/Indiana/Tell_City",
        "America/Matamoros",
        "America/Menominee",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Resolute",
        "America/Winnipeg",
        "CST6CDT",
      ],
    },
    {
      value: "Central Standard Time (Mexico)",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      utc: [
        "America/Bahia_Banderas",
        "America/Cancun",
        "America/Merida",
        "America/Mexico_City",
        "America/Monterrey",
      ],
    },
    {
      value: "Canada Central Standard Time",
      abbr: "CCST",
      offset: -6,
      isdst: false,
      text: "(UTC-06:00) Saskatchewan",
      utc: ["America/Regina", "America/Swift_Current"],
    },
    {
      value: "SA Pacific Standard Time",
      abbr: "SPST",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Bogota, Lima, Quito",
      utc: [
        "America/Bogota",
        "America/Cayman",
        "America/Coral_Harbour",
        "America/Eirunepe",
        "America/Guayaquil",
        "America/Jamaica",
        "America/Lima",
        "America/Panama",
        "America/Rio_Branco",
        "Etc/GMT+5",
      ],
    },
    {
      value: "Eastern Standard Time",
      abbr: "EST",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Eastern Time (US & Canada)",
      utc: [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto",
      ],
    },
    {
      value: "Eastern Daylight Time",
      abbr: "EDT",
      offset: -4,
      isdst: true,
      text: "(UTC-04:00) Eastern Daylight Time (US & Canada)",
      utc: [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto",
      ],
    },
    {
      value: "US Eastern Standard Time",
      abbr: "UEDT",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Indiana (East)",
      utc: [
        "America/Indiana/Marengo",
        "America/Indiana/Vevay",
        "America/Indianapolis",
      ],
    },
    {
      value: "Venezuela Standard Time",
      abbr: "VST",
      offset: -4.5,
      isdst: false,
      text: "(UTC-04:30) Caracas",
      utc: ["America/Caracas"],
    },
    {
      value: "Paraguay Standard Time",
      abbr: "PYT",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Asuncion",
      utc: ["America/Asuncion"],
    },
    {
      value: "Atlantic Standard Time",
      abbr: "ADT",
      offset: -3,
      isdst: true,
      text: "(UTC-04:00) Atlantic Time (Canada)",
      utc: [
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Moncton",
        "America/Thule",
        "Atlantic/Bermuda",
      ],
    },
    {
      value: "Central Brazilian Standard Time",
      abbr: "CBST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Cuiaba",
      utc: ["America/Campo_Grande", "America/Cuiaba"],
    },
    {
      value: "SA Western Standard Time",
      abbr: "SWST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      utc: [
        "America/Anguilla",
        "America/Antigua",
        "America/Aruba",
        "America/Barbados",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Curacao",
        "America/Dominica",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guyana",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lower_Princes",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Montserrat",
        "America/Port_of_Spain",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Santo_Domingo",
        "America/St_Barthelemy",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Tortola",
        "Etc/GMT+4",
      ],
    },
    {
      value: "Pacific SA Standard Time",
      abbr: "PSST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Santiago",
      utc: ["America/Santiago", "Antarctica/Palmer"],
    },
    {
      value: "Newfoundland Standard Time",
      abbr: "NDT",
      offset: -2.5,
      isdst: true,
      text: "(UTC-03:30) Newfoundland",
      utc: ["America/St_Johns"],
    },
    {
      value: "E. South America Standard Time",
      abbr: "ESAST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Brasilia",
      utc: ["America/Sao_Paulo"],
    },
    {
      value: "Argentina Standard Time",
      abbr: "AST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Buenos Aires",
      utc: [
        "America/Argentina/La_Rioja",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Buenos_Aires",
        "America/Catamarca",
        "America/Cordoba",
        "America/Jujuy",
        "America/Mendoza",
      ],
    },
    {
      value: "SA Eastern Standard Time",
      abbr: "SEST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Cayenne, Fortaleza",
      utc: [
        "America/Araguaina",
        "America/Belem",
        "America/Cayenne",
        "America/Fortaleza",
        "America/Maceio",
        "America/Paramaribo",
        "America/Recife",
        "America/Santarem",
        "Antarctica/Rothera",
        "Atlantic/Stanley",
        "Etc/GMT+3",
      ],
    },
    {
      value: "Greenland Standard Time",
      abbr: "GDT",
      offset: -3,
      isdst: true,
      text: "(UTC-03:00) Greenland",
      utc: ["America/Godthab"],
    },
    {
      value: "Montevideo Standard Time",
      abbr: "MST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Montevideo",
      utc: ["America/Montevideo"],
    },
    {
      value: "Bahia Standard Time",
      abbr: "BST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Salvador",
      utc: ["America/Bahia"],
    },
    {
      value: "UTC-02",
      abbr: "U",
      offset: -2,
      isdst: false,
      text: "(UTC-02:00) Coordinated Universal Time-02",
      utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
    },
    {
      value: "Mid-Atlantic Standard Time",
      abbr: "MDT",
      offset: -1,
      isdst: true,
      text: "(UTC-02:00) Mid-Atlantic - Old",
      utc: [],
    },
    {
      value: "Azores Standard Time",
      abbr: "ADT",
      offset: 0,
      isdst: true,
      text: "(UTC-01:00) Azores",
      utc: ["America/Scoresbysund", "Atlantic/Azores"],
    },
    {
      value: "Cape Verde Standard Time",
      abbr: "CVST",
      offset: -1,
      isdst: false,
      text: "(UTC-01:00) Cape Verde Is.",
      utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"],
    },
    {
      value: "Morocco Standard Time",
      abbr: "MDT",
      offset: 1,
      isdst: true,
      text: "(UTC) Casablanca",
      utc: ["Africa/Casablanca", "Africa/El_Aaiun"],
    },
    {
      value: "UTC",
      abbr: "UTC",
      offset: 0,
      isdst: false,
      text: "(UTC) Coordinated Universal Time",
      utc: ["America/Danmarkshavn", "Etc/GMT"],
    },
    {
      value: "GMT Standard Time",
      abbr: "GMT",
      offset: 0,
      isdst: false,
      text: "(UTC) Edinburgh, London",
      utc: [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London",
      ],
    },
    {
      value: "British Summer Time",
      abbr: "BST",
      offset: 1,
      isdst: true,
      text: "(UTC+01:00) Edinburgh, London",
      utc: [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London",
      ],
    },
    {
      value: "GMT Standard Time",
      abbr: "GDT",
      offset: 1,
      isdst: true,
      text: "(UTC) Dublin, Lisbon",
      utc: [
        "Atlantic/Canary",
        "Atlantic/Faeroe",
        "Atlantic/Madeira",
        "Europe/Dublin",
        "Europe/Lisbon",
      ],
    },
    {
      value: "Greenwich Standard Time",
      abbr: "GST",
      offset: 0,
      isdst: false,
      text: "(UTC) Monrovia, Reykjavik",
      utc: [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Bamako",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Freetown",
        "Africa/Lome",
        "Africa/Monrovia",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Sao_Tome",
        "Atlantic/Reykjavik",
        "Atlantic/St_Helena",
      ],
    },
    {
      value: "W. Europe Standard Time",
      abbr: "WEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      utc: [
        "Arctic/Longyearbyen",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Berlin",
        "Europe/Busingen",
        "Europe/Gibraltar",
        "Europe/Luxembourg",
        "Europe/Malta",
        "Europe/Monaco",
        "Europe/Oslo",
        "Europe/Rome",
        "Europe/San_Marino",
        "Europe/Stockholm",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Zurich",
      ],
    },
    {
      value: "Central Europe Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      utc: [
        "Europe/Belgrade",
        "Europe/Bratislava",
        "Europe/Budapest",
        "Europe/Ljubljana",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Tirane",
      ],
    },
    {
      value: "Romance Standard Time",
      abbr: "RDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      utc: [
        "Africa/Ceuta",
        "Europe/Brussels",
        "Europe/Copenhagen",
        "Europe/Madrid",
        "Europe/Paris",
      ],
    },
    {
      value: "Central European Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      utc: [
        "Europe/Sarajevo",
        "Europe/Skopje",
        "Europe/Warsaw",
        "Europe/Zagreb",
      ],
    },
    {
      value: "W. Central Africa Standard Time",
      abbr: "WCAST",
      offset: 1,
      isdst: false,
      text: "(UTC+01:00) West Central Africa",
      utc: [
        "Africa/Algiers",
        "Africa/Bangui",
        "Africa/Brazzaville",
        "Africa/Douala",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Luanda",
        "Africa/Malabo",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Porto-Novo",
        "Africa/Tunis",
        "Etc/GMT-1",
      ],
    },
    {
      value: "Namibia Standard Time",
      abbr: "NST",
      offset: 1,
      isdst: false,
      text: "(UTC+01:00) Windhoek",
      utc: ["Africa/Windhoek"],
    },
    {
      value: "GTB Standard Time",
      abbr: "GDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Athens, Bucharest",
      utc: [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
      ],
    },
    {
      value: "Middle East Standard Time",
      abbr: "MEDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Beirut",
      utc: ["Asia/Beirut"],
    },
    {
      value: "Egypt Standard Time",
      abbr: "EST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Cairo",
      utc: ["Africa/Cairo"],
    },
    {
      value: "Syria Standard Time",
      abbr: "SDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Damascus",
      utc: ["Asia/Damascus"],
    },
    {
      value: "E. Europe Standard Time",
      abbr: "EEDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) E. Europe",
      utc: [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Nicosia",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye",
      ],
    },
    {
      value: "South Africa Standard Time",
      abbr: "SAST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Harare, Pretoria",
      utc: [
        "Africa/Blantyre",
        "Africa/Bujumbura",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Kigali",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Etc/GMT-2",
      ],
    },
    {
      value: "FLE Standard Time",
      abbr: "FDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      utc: [
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye",
      ],
    },
    {
      value: "Turkey Standard Time",
      abbr: "TDT",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Istanbul",
      utc: ["Europe/Istanbul"],
    },
    {
      value: "Israel Standard Time",
      abbr: "JDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Jerusalem",
      utc: ["Asia/Jerusalem"],
    },
    {
      value: "Libya Standard Time",
      abbr: "LST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Tripoli",
      utc: ["Africa/Tripoli"],
    },
    {
      value: "Jordan Standard Time",
      abbr: "JST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Amman",
      utc: ["Asia/Amman"],
    },
    {
      value: "Arabic Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Baghdad",
      utc: ["Asia/Baghdad"],
    },
    {
      value: "Kaliningrad Standard Time",
      abbr: "KST",
      offset: 3,
      isdst: false,
      text: "(UTC+02:00) Kaliningrad",
      utc: ["Europe/Kaliningrad"],
    },
    {
      value: "Arab Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Kuwait, Riyadh",
      utc: [
        "Asia/Aden",
        "Asia/Bahrain",
        "Asia/Kuwait",
        "Asia/Qatar",
        "Asia/Riyadh",
      ],
    },
    {
      value: "E. Africa Standard Time",
      abbr: "EAST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Nairobi",
      utc: [
        "Africa/Addis_Ababa",
        "Africa/Asmera",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Mogadishu",
        "Africa/Nairobi",
        "Antarctica/Syowa",
        "Etc/GMT-3",
        "Indian/Antananarivo",
        "Indian/Comoro",
        "Indian/Mayotte",
      ],
    },
    {
      value: "Moscow Standard Time",
      abbr: "MSK",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
      utc: [
        "Europe/Kirov",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Minsk",
      ],
    },
    {
      value: "Samara Time",
      abbr: "SAMT",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Samara, Ulyanovsk, Saratov",
      utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"],
    },
    {
      value: "Iran Standard Time",
      abbr: "IDT",
      offset: 4.5,
      isdst: true,
      text: "(UTC+03:30) Tehran",
      utc: ["Asia/Tehran"],
    },
    {
      value: "Arabian Standard Time",
      abbr: "AST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Abu Dhabi, Muscat",
      utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"],
    },
    {
      value: "Azerbaijan Standard Time",
      abbr: "ADT",
      offset: 5,
      isdst: true,
      text: "(UTC+04:00) Baku",
      utc: ["Asia/Baku"],
    },
    {
      value: "Mauritius Standard Time",
      abbr: "MST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Port Louis",
      utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"],
    },
    {
      value: "Georgian Standard Time",
      abbr: "GET",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Tbilisi",
      utc: ["Asia/Tbilisi"],
    },
    {
      value: "Caucasus Standard Time",
      abbr: "CST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Yerevan",
      utc: ["Asia/Yerevan"],
    },
    {
      value: "Afghanistan Standard Time",
      abbr: "AST",
      offset: 4.5,
      isdst: false,
      text: "(UTC+04:30) Kabul",
      utc: ["Asia/Kabul"],
    },
    {
      value: "West Asia Standard Time",
      abbr: "WAST",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Ashgabat, Tashkent",
      utc: [
        "Antarctica/Mawson",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Dushanbe",
        "Asia/Oral",
        "Asia/Samarkand",
        "Asia/Tashkent",
        "Etc/GMT-5",
        "Indian/Kerguelen",
        "Indian/Maldives",
      ],
    },
    {
      value: "Yekaterinburg Time",
      abbr: "YEKT",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Yekaterinburg",
      utc: ["Asia/Yekaterinburg"],
    },
    {
      value: "Pakistan Standard Time",
      abbr: "PKT",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Islamabad, Karachi",
      utc: ["Asia/Karachi"],
    },
    {
      value: "India Standard Time",
      abbr: "IST",
      offset: 5.5,
      isdst: false,
      text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      utc: ["Asia/Kolkata", "Asia/Calcutta"],
    },
    {
      value: "Sri Lanka Standard Time",
      abbr: "SLST",
      offset: 5.5,
      isdst: false,
      text: "(UTC+05:30) Sri Jayawardenepura",
      utc: ["Asia/Colombo"],
    },
    {
      value: "Nepal Standard Time",
      abbr: "NST",
      offset: 5.75,
      isdst: false,
      text: "(UTC+05:45) Kathmandu",
      utc: ["Asia/Kathmandu"],
    },
    {
      value: "Central Asia Standard Time",
      abbr: "CAST",
      offset: 6,
      isdst: false,
      text: "(UTC+06:00) Nur-Sultan (Astana)",
      utc: [
        "Antarctica/Vostok",
        "Asia/Almaty",
        "Asia/Bishkek",
        "Asia/Qyzylorda",
        "Asia/Urumqi",
        "Etc/GMT-6",
        "Indian/Chagos",
      ],
    },
    {
      value: "Bangladesh Standard Time",
      abbr: "BST",
      offset: 6,
      isdst: false,
      text: "(UTC+06:00) Dhaka",
      utc: ["Asia/Dhaka", "Asia/Thimphu"],
    },
    {
      value: "Myanmar Standard Time",
      abbr: "MST",
      offset: 6.5,
      isdst: false,
      text: "(UTC+06:30) Yangon (Rangoon)",
      utc: ["Asia/Rangoon", "Indian/Cocos"],
    },
    {
      value: "SE Asia Standard Time",
      abbr: "SAST",
      offset: 7,
      isdst: false,
      text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      utc: [
        "Antarctica/Davis",
        "Asia/Bangkok",
        "Asia/Hovd",
        "Asia/Jakarta",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Saigon",
        "Asia/Vientiane",
        "Etc/GMT-7",
        "Indian/Christmas",
      ],
    },
    {
      value: "N. Central Asia Standard Time",
      abbr: "NCAST",
      offset: 7,
      isdst: false,
      text: "(UTC+07:00) Novosibirsk",
      utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"],
    },
    {
      value: "China Standard Time",
      abbr: "CST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"],
    },
    {
      value: "North Asia Standard Time",
      abbr: "NAST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Krasnoyarsk",
      utc: ["Asia/Krasnoyarsk"],
    },
    {
      value: "Singapore Standard Time",
      abbr: "MPST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Kuala Lumpur, Singapore",
      utc: [
        "Asia/Brunei",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Singapore",
        "Etc/GMT-8",
      ],
    },
    {
      value: "W. Australia Standard Time",
      abbr: "WAST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Perth",
      utc: ["Antarctica/Casey", "Australia/Perth"],
    },
    {
      value: "Taipei Standard Time",
      abbr: "TST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Taipei",
      utc: ["Asia/Taipei"],
    },
    {
      value: "Ulaanbaatar Standard Time",
      abbr: "UST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Ulaanbaatar",
      utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
    },
    {
      value: "North Asia East Standard Time",
      abbr: "NAEST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Irkutsk",
      utc: ["Asia/Irkutsk"],
    },
    {
      value: "Japan Standard Time",
      abbr: "JST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
      utc: [
        "Asia/Dili",
        "Asia/Jayapura",
        "Asia/Tokyo",
        "Etc/GMT-9",
        "Pacific/Palau",
      ],
    },
    {
      value: "Korea Standard Time",
      abbr: "KST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Seoul",
      utc: ["Asia/Pyongyang", "Asia/Seoul"],
    },
    {
      value: "Cen. Australia Standard Time",
      abbr: "CAST",
      offset: 9.5,
      isdst: false,
      text: "(UTC+09:30) Adelaide",
      utc: ["Australia/Adelaide", "Australia/Broken_Hill"],
    },
    {
      value: "AUS Central Standard Time",
      abbr: "ACST",
      offset: 9.5,
      isdst: false,
      text: "(UTC+09:30) Darwin",
      utc: ["Australia/Darwin"],
    },
    {
      value: "E. Australia Standard Time",
      abbr: "EAST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Brisbane",
      utc: ["Australia/Brisbane", "Australia/Lindeman"],
    },
    {
      value: "AUS Eastern Standard Time",
      abbr: "AEST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Canberra, Melbourne, Sydney",
      utc: ["Australia/Melbourne", "Australia/Sydney"],
    },
    {
      value: "West Pacific Standard Time",
      abbr: "WPST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Guam, Port Moresby",
      utc: [
        "Antarctica/DumontDUrville",
        "Etc/GMT-10",
        "Pacific/Guam",
        "Pacific/Port_Moresby",
        "Pacific/Saipan",
        "Pacific/Truk",
      ],
    },
    {
      value: "Tasmania Standard Time",
      abbr: "TST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Hobart",
      utc: ["Australia/Currie", "Australia/Hobart"],
    },
    {
      value: "Yakutsk Standard Time",
      abbr: "YST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Yakutsk",
      utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
    },
    {
      value: "Central Pacific Standard Time",
      abbr: "CPST",
      offset: 11,
      isdst: false,
      text: "(UTC+11:00) Solomon Is., New Caledonia",
      utc: [
        "Antarctica/Macquarie",
        "Etc/GMT-11",
        "Pacific/Efate",
        "Pacific/Guadalcanal",
        "Pacific/Kosrae",
        "Pacific/Noumea",
        "Pacific/Ponape",
      ],
    },
    {
      value: "Vladivostok Standard Time",
      abbr: "VST",
      offset: 11,
      isdst: false,
      text: "(UTC+11:00) Vladivostok",
      utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"],
    },
    {
      value: "New Zealand Standard Time",
      abbr: "NZST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Auckland, Wellington",
      utc: ["Antarctica/McMurdo", "Pacific/Auckland"],
    },
    {
      value: "UTC+12",
      abbr: "U",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Coordinated Universal Time+12",
      utc: [
        "Etc/GMT-12",
        "Pacific/Funafuti",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Nauru",
        "Pacific/Tarawa",
        "Pacific/Wake",
        "Pacific/Wallis",
      ],
    },
    {
      value: "Fiji Standard Time",
      abbr: "FST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Fiji",
      utc: ["Pacific/Fiji"],
    },
    {
      value: "Magadan Standard Time",
      abbr: "MST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Magadan",
      utc: [
        "Asia/Anadyr",
        "Asia/Kamchatka",
        "Asia/Magadan",
        "Asia/Srednekolymsk",
      ],
    },
    {
      value: "Kamchatka Standard Time",
      abbr: "KDT",
      offset: 13,
      isdst: true,
      text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
      utc: ["Asia/Kamchatka"],
    },
    {
      value: "Tonga Standard Time",
      abbr: "TST",
      offset: 13,
      isdst: false,
      text: "(UTC+13:00) Nuku'alofa",
      utc: [
        "Etc/GMT-13",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Tongatapu",
      ],
    },
    {
      value: "Samoa Standard Time",
      abbr: "SST",
      offset: 13,
      isdst: false,
      text: "(UTC+13:00) Samoa",
      utc: ["Pacific/Apia"],
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
    };

    for (let i = 1; i <= 31; i++) {
      this.dayOfMonth.push(i);
    }
    this.dayOfMonth.push("Last");

    this.accountId = localStorage.getItem("accountId");
    //this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        //this.regionId = d.value;
        //this.load();
      }
    });

    setTimeout(() => {
      flatpickr(".datetimepicker", { dateFormat: "d-M-Y" });
      //let start_date = moment().startOf('month').format('DD-MMM-YYYY');
      //let end_date = moment().startOf('day').format('DD-MMM-YYYY');

      //this.dateRange = start_date + ' to ' + end_date;
      //flatpickr('.datetimepicker', {
      //  dateFormat: 'd-M-Y',
      //  mode: 'range',
      //  maxDate: new Date(),
      //  showMonths: 2,
      //  onChange: (selectedDates: any, dateStr: any, instance: any) => {
      //    if (!dateStr.includes('to')) return;
      //    this.dateRange = dateStr;
      //    this.load();
      //  },
      //});
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, 500);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async showData(ref: any, id: any) {
    window.analyticsSendEventClick("scheduler", "View Schedule Details");
    console.log("showData", id);
    ref.subView = "Basic Details";
    ref.cShedule = JSON.parse(JSON.stringify(id));
    ref.cShedule.startDate = moment(ref.cShedule.startDate)
      .utc()
      .format("DD-MMM-YYYY");
    ref.cShedule.startDateTime = moment(ref.cShedule.startDateTime)
      .utc()
      .format("HH:mm");

    ref.cShedule.endDate = moment(ref.cShedule.endDate)
      .utc()
      .format("DD-MMM-YYYY");
    ref.cShedule.endDateTime = moment(ref.cShedule.endDateTime)
      .utc()
      .format("HH:mm");

    if (!ref.cShedule.alerts) {
      ref.cShedule.alerts = {
        onSuccess: "",
        onError: "",
        others: "",
      };
    }

    ref.notifier.loading(true);
    //await Promise.all([ref.loadEC2(), ref.loadRDS()]); //, this.loadTags()
    //await ref.loadEC2()
    await ref.loadEC2New();
    ref.notifier.loading(false);
    ref.showCreateShedule = true;
    setTimeout(() => {
      flatpickr(".datetimepicker", { dateFormat: "d-M-Y" });

      try {
        let test = document.createElement("input");
        test.type = "time";
        test = null;
      } catch (e) {
        console.log(e);

        $(".datetimepickerTime").attr("readonly", "readonly");

        flatpickr(".datetimepickerTime", {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
        });
      }
    }, 500);

    //ref.notifier.loading(true);

    //let data = {
    //  accountId: ref.accountId,
    //  region: ref.regionId,
    //  instanceId: id.instanceId
    //};

    //let header = {
    //  Authorization: localStorage.getItem('t'),
    //};
    ////https://api.swayam.cloud/v3/admin/support
    ////let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/support`;
    //let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/operations/ebs`;

    //let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    //console.log(result);

    //if (result.status == '1' || result.s == '1') {
    //  //ref.kbDetails = result.page;
    //  //ref.showDetailKB = true;

    //  ref.notifier.messageBox(
    //    'More Instance Info!',
    //    `<div style="display: flex; gap: 9px; align-items: center;"><strong>Instance Tag:</strong>  ` +
    //    result.instanceName +
    //    '</div>'
    //  );
    //} else {
    //  //ref.notifier.messageBox("More Instance Info!", "sdasdasdasda");

    //  ref.notifier.alert('Error', '', result.error, 'error', 5000);
    //}
    //ref.notifier.loading(false);
  }

  async load() {
    this.notifier.loading(true);
    await this.loadAllShedule();
    //await Promise.all([this.loadEC2(), this.loadRDS()]); //, this.loadTags()
    this.loadEC2();
    this.notifier.loading(false);
  }

  async CreateShedule() {
    window.analyticsSendEventClick("scheduler", "Create New Schedule");
    this.subView = "Basic Details";
    this.cShedule = {
      _id: "0",
      frequency: "Once",
      timeZone: "IST",
      rule: {
        monthyRepeat: "Days",
        frequencyWeekDay: {
          Friday: false,
          Monday: false,
          Saturday: false,
          Sunday: false,
          Thursday: false,
          Tuesday: false,
          Wednesday: false,
        },
        dayOfMonth: [],
        frequencyMonthDay: {
          First: false,
          Second: false,
          Third: false,
          Fourth: false,
          Last: false,
        },
      },
      retention: {
        unit: "Always",
        value: 1,
      },
      alerts: {
        onSuccess: "",
        onError: "",
        others: "",
      },
    };
    this.notifier.loading(true);
    this.loadEC2();
    //await Promise.all([this.loadEC2(), this.loadRDS()]); //, this.loadTags()
    this.notifier.loading(false);
    this.showCreateShedule = true;

    setTimeout(() => {
      flatpickr(".datetimepicker", { dateFormat: "d-M-Y" });
      try {
        let test = document.createElement("input");
        test.type = "time";
        test = null;
      } catch (e) {
        console.log(e);
        flatpickr(".datetimepickerTime", {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
        });
      }
    }, 500);
  }

  CloseCreateShedule() {
    this.showCreateShedule = false;
    this.scheduleMaps = [];
    this.load();
  }

  async loadMinfySupportTickets() {
    this.notifier.loading(true);
    try {
      let data = {
        account_id: this.accountId,
      };

      let header = {
        Authorization: localStorage.getItem("t"),
      };

      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == "1" || result.s == "1") {
        //} else {
        //  this.notifier.alert('Error', '', result.error_message, 'error', 5000);
        //}
      } else {
        this.notifier.alert(
          "Error",
          "",
          "", //selectedAccountData.msg,
          "error",
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert("Error", "", err.msg, "error", 5000);
    }
    this.notifier.loading(false);
  }

  filterDate(dateString: any) {
    return moment(dateString).utc().format("DD-MMM-YYYY HH:MM:SS");
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel("view-tickets-table", "ticketlist", "csv");
  }

  print_r(object: any, html: any) {
    if (html) return "<pre>" + JSON.stringify(object, null, 4) + "</pre>";
    else return JSON.stringify(object, null, 4);
  }

  async loadEC2(nextToken?: any) {
    if (!nextToken) {
      this.instancesList = [];
    }
    this.notifier.loading(true);
    //let data = {
    //  action: 'testListInstancesWithPagination',
    //  accountId: this.accountId,
    //  region: this.regionId,
    //  nextToken: nextToken,
    //};

    let data = {
      action: "list_ec2_instances",
      account_id: this.accountId,
      region_id: this.regionId,
      //nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2v2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == "1" || result.status == "1") {
      this.instancesList = [];
      result.instances; // [...this.instancesList, ...result.instanceDetails];
      for (let i = 0; i < result.instances.length; i++) {
        let tagName = "";
        for (var j = 0; j < result.instances[i].Tags.length; j++) {
          if (result.instances[i].Tags[j].Key == "Name") {
            tagName = result.instances[i].Tags[j].Value;
            break;
          }
        }
        this.instancesList.push({
          instanceId: result.instances[i].InstanceId,
          imageID: result.instances[i].ImageId,
          instanceType: result.instances[i].InstanceType,
          instanceStatus: result.instances[i].State.Name,
          tagName: tagName, //result.instances[i].Tags[0].Value,  //TODO : Fix This
          privateIp: result.instances[i].PrivateIpAddress,
          publicIp: result.instances[i].PublicIpAddress,
          isHibernateEnabled: false,
          platform: result.instances[i].Platform,
          workloadType: "",
          tags: result.instances[i].Tags,
        });
      }

      let dt: any = [];
      this.instancesList.forEach((instance: any, index: number) => {
        instance["actionStart"] = false;
        if (instance.instanceStatus == "running") {
          dt.push(instance);
        }
        instance.ipdata =
          (instance.privateIp ? instance.privateIp.toString() : "") +
          (instance.publicIp ? "/" + instance.publicIp.toString() : "");
      });
      //if (result.nextToken && result.nextToken != '') {
      //  await this.loadEC2(result.nextToken);
      //}
      //if (dt.length > 0) {
      //  await this.fetchInstanceAZ(dt);
      //}
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async loadEC2New(nextToken?: any) {
    if (!nextToken) {
      this.instancesList = [];
    }
    let filter_type = "";
    let filter_value = "";

    if (this.ec2SearchType != "Select") {
      filter_type = this.ec2SearchType;
      filter_value = this.ec2SearchValue;
    }

    this.notifier.loading(true);
    let data: any = {
      action: "list_ec2_instances",
      account_id: this.accountId,
      region_id: this.regionId,
      filter_type: filter_type, //"", //Instance Status
      filter_value: filter_value, //"running",
      limit: parseInt(this.ec2PageSize.toString()),

      //nextToken: nextToken,
    };

    if (nextToken) data.nextToken = nextToken;

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2v2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    console.log("/operations/listec2v2", result);

    if (result.s == "1" || result.status == "1") {
      //this.instancesList = [...this.instancesList, ...result.instances];

      this.instancesList = []; //result.instances; // [...this.instancesList, ...result.instanceDetails];
      for (let i = 0; i < result.instances.length; i++) {
        let tagName = "";
        for (var j = 0; j < result.instances[i].Tags.length; j++) {
          if (result.instances[i].Tags[j].Key == "Name") {
            tagName = result.instances[i].Tags[j].Value;
            break;
          }
        }
        this.instancesList.push({
          instanceId: result.instances[i].InstanceId,
          imageID: result.instances[i].ImageId,
          instanceType: result.instances[i].InstanceType,
          instanceStatus: result.instances[i].State.Name,
          tagName: tagName,
          privateIp: result.instances[i].PrivateIpAddress,
          publicIp: result.instances[i].PublicIpAddress,
          isHibernateEnabled: false,
          platform: result.instances[i].Platform,
          workloadType: "",
          tags: result.instances[i].Tags,
        });
      }

      let dt: any = [];
      this.instancesList.forEach((instance: any, index: number) => {
        instance["actionStart"] = false;
        if (instance.instanceStatus == "running") {
          dt.push(instance);
        }
        instance.ipdata =
          (instance.privateIp ? instance.privateIp.toString() : "") +
          (instance.publicIp ? "/" + instance.publicIp.toString() : "");
      });

      this.instancesList.forEach((instance: any, index: number) => {
        instance["actionStart"] = false;
        if (instance.instanceStatus == "running") {
          dt.push(instance);
        }
        instance.ipdata =
          (instance.privateIp ? instance.privateIp.toString() : "") +
          (instance.publicIp ? "/" + instance.publicIp.toString() : "");
      });
      if (result.nextToken && result.nextToken != "") {
        await this.loadEC2New(result.nextToken);
      }
      //if (dt.length > 0) {
      //  await this.fetchInstanceAZ(dt);
      //}
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async loadRDS() {
    this.notifier.loading(true);
    let data = {
      emailId: "rubeena.j@minfytech.com",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listrds`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == "1" || result.s == "1") {
      this.rdsList = result.rdsDetailsList;
      this.rdsClusterList = result.clusterDetailsList;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async loadTags() {
    this.notifier.loading(true);
    let data = {
      action: "listEc2Tags",
      accountId: this.accountId,
      region: this.regionId,
      nextToken: "",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == "1" || result.s == "1") {
      this.tagList = result.tagKeyList;
      //setTimeout(() => {
      //  $('#tagListID').selectpicker('refresh');
      //}, 100);
      //await this.fetchSubmittedTags();
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  changeTragetType(nType: String) {
    this.cShedule.targetType = nType;
    //this.cShedule.target = [];
  }

  async createShedule(draft: boolean = false) {
    window.analyticsSendEventClick(
      "scheduler",
      "Save Schedule " + (draft ? "draft" : "")
    );

    if (!this.cShedule.name) {
      alert("Please Enter Schedule Title");
      return;
    }

    if (!this.cShedule.action) {
      alert("Please Select Schedule Action");
      return;
    }

    if (!this.cShedule.startDate) {
      alert("Please Select Schedule Start Date");
      return;
    }

    if (!this.cShedule.startDateTime) {
      alert("Please Select Schedule Start Time");
      return;
    }

    if (!this.cShedule.rule.frequency) {
      alert("Please Select Schedule Trigger");
      return;
    }
    let repeatEvery = parseInt(this.cShedule.rule.repeatEvery);
    if (repeatEvery <= 0) {
      alert("Please Select Valid Repeat Value");
      return;
    }

    if (this.cShedule.rule.frequency != "Once" && !this.cShedule.endDate) {
      alert("Please Select Schedule End Date");
      return;
    }

    if (this.cShedule.rule.frequency != "Once" && !this.cShedule.endDateTime) {
      alert("Please Select Schedule End Time");
      return;
    }

    let selectedTargets = 0;
    if (this.cShedule.target) {
      for (let i = 0; i < this.cShedule.target.length; i++) {
        selectedTargets += this.cShedule.target[i].EC2.length;
      }
    }

    if (!draft && selectedTargets == 0) {
      alert("Please Select Schedule Targets");
      return;
    }

    this.notifier.loading(true);

    this.cShedule.status = "A";

    if (this.cShedule.rule.frequency == "Once") {
      this.cShedule.endDate = this.cShedule.startDate;
      this.cShedule.endDateTime = this.cShedule.startDateTime;
    }
    let res: any = await this.optValidateFor(
      "Create Schedule",
      "OTP for Create a Schedule"
    );
    console.log("changeTargetType", res);
    if (!res) {
      this.notifier.loading(false);
      return;
    }

    let data = {
      accountId: this.accountId,
      regionId: this.regionId,
      cShedule: this.cShedule,
      id: this.cShedule._id,
      draft: draft,
      urlAction: "/api/scheduler/save",
    };

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTMongo}/api/scheduler/save`; http://43.204.90.89:14189/api/map/api/scheduler/save
    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == "1") {
      this.notifier.alert(
        "Success",
        "",
        "Schedule Rule saved successfully",
        "success",
        5000
      );
      this.showCreateShedule = false;
      this.loadAllShedule();
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async loadAllShedule() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      regionId: this.regionId,
      urlAction: "/api/scheduler/get/all",
    };

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    //http://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTMongo}/api/scheduler/get/all`;
    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == "1") {
      this.scheduleRules = result.shedulerRules;
      //setTimeout(() => {
      //  $('#tagListID').selectpicker('refresh');
      //}, 100);
      //await this.fetchSubmittedTags();
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  onChangePush(data: any, item: string) {
    console.log("onChangePush", data, item);
    let index = data.indexOf(item);
    if (index == -1) {
      data.push(item);
    } else {
      data.splice(index, 1);
    }
  }

  showViewTargets() {
    if (!this.cShedule.name) {
      alert("Please Enter Schedule Title");
      return;
    }

    if (!this.cShedule.action) {
      alert("Please Select Schedule Action");
      return;
    }

    if (!this.cShedule.startDate) {
      alert("Please Select Schedule Start Date");
      return;
    }

    if (!this.cShedule.startDateTime) {
      alert("Please Select Schedule Start Time");
      return;
    }

    if (!this.cShedule.rule.frequency) {
      alert("Please Select Schedule Trigger");
      return;
    }

    if (this.cShedule.rule.frequency != "Once" && !this.cShedule.endDate) {
      alert("Please Select Schedule End Date");
      return;
    }

    if (this.cShedule.rule.frequency != "Once" && !this.cShedule.endDateTime) {
      alert("Please Select Schedule End Time");
      return;
    }

    this.subView = "Select Targets";
    this.cShedule.startDate = $("#startDate").val();
    this.cShedule.startDateTime = $("#startDateTime").val();

    this.cShedule.endDate = $("#endDate").val();
    this.cShedule.endDateTime = $("#endDateTime").val();

    this.cShedule.targetType = "EC2";

    //for (let i = 0; i < this.cShedule.target.length; i++) {
    //  for (let j = 0; j < this.cShedule.target[i].region.length; j++) {
    //    for (let k = 0; k < this.cShedule.target[i].region[j].length; k++) {

    //    }
    //  }
    //}

    this.cdRef.detectChanges();
  }

  backToBasic() {
    this.subView = "Basic Details";
    setTimeout(() => {
      flatpickr(".datetimepicker", { dateFormat: "d-M-Y" });
      try {
        let test = document.createElement("input");
        test.type = "time";
        test = null;
      } catch (e) {
        console.log(e);
        flatpickr(".datetimepickerTime", {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
        });
      }
    }, 500);
  }

  changeWeekDay(wDay: string) {
    console.log("wDay", this.cShedule.rule.frequencyWeekDay[wDay]);
    this.cShedule.rule.frequencyWeekDay[wDay] =
      !this.cShedule.rule.frequencyWeekDay[wDay];
    console.log("wDay", this.cShedule.rule.frequencyWeekDay[wDay]);
  }

  changeMonthDay(wDay: string) {
    console.log("wDay", this.cShedule.rule.frequencyMonthDay[wDay]);
    this.cShedule.rule.frequencyMonthDay[wDay] =
      !this.cShedule.rule.frequencyMonthDay[wDay];
    console.log("wDay", this.cShedule.rule.frequencyMonthDay[wDay]);
  }

  setMonthRepeat(w: string) {
    console.log("setMonthRepeat", w);
    this.cShedule.rule.monthyRepeat = w;
  }

  clone(sh: any) {
    window.analyticsSendEventClick("scheduler", "Clone Schedule");
    let newShedule = JSON.parse(JSON.stringify(sh));

    newShedule._id = "0";
    newShedule.name = newShedule.name + " Clone";

    console.log("showData", sh);
    this.subView = "Basic Details";
    this.cShedule = newShedule;

    //this.cShedule.startDateTime = moment(this.cShedule.startDateTime).format("DD-MMM-YYYY HH:mm")

    this.cShedule.startDate = moment(this.cShedule.startDate)
      .utc()
      .format("DD-MMM-YYYY");
    this.cShedule.startDateTime = moment(this.cShedule.startDateTime)
      .utc()
      .format("HH:mm");

    this.cShedule.endDate = moment(this.cShedule.endDate)
      .utc()
      .format("DD-MMM-YYYY");
    this.cShedule.endDateTime = moment(this.cShedule.endDateTime)
      .utc()
      .format("HH:mm");

    this.showCreateShedule = true;
    setTimeout(() => {
      flatpickr(".datetimepicker", { dateFormat: "d-M-Y" });
      try {
        let test = document.createElement("input");
        test.type = "time";
        test = null;
      } catch (e) {
        console.log(e);
        flatpickr(".datetimepickerTime", {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
        });
      }
    }, 500);
  }

  async delete(sh: any) {
    window.analyticsSendEventClick("scheduler", "Delete Schedule ");

    if (!confirm("Are you sure want to delete the schedule rule!")) {
      return;
    }

    this.notifier.loading(true);

    let res: any = await this.optValidateFor(
      "Delete Schedule",
      "OTP for Delete Schedule : " + sh.name
    );
    if (!res) return;

    let data = {
      accountId: sh.accountId,
      id: sh._id,
      urlAction: "/api/scheduler/delete",
    };

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == "1") {
      this.notifier.alert(
        "Success",
        "",
        "Schedule Rule deleted successfully",
        "success",
        5000
      );
      this.showCreateShedule = false;
      this.loadAllShedule();
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async pause(sh: any) {
    window.analyticsSendEventClick("scheduler", "Pause Schedule");
    if (sh.status == "A") {
      if (!confirm("Are you sure want to pause the schedule rule!")) {
        return;
      }

      let res: any = await this.optValidateFor(
        "Pause Schedule",
        "OTP for Pause Schedule : " + sh.name
      );
      if (!res) return;
    } else {
      if (!confirm("Are you sure want to activate the schedule rule!")) {
        return;
      }

      let res: any = await this.optValidateFor(
        "Activate Schedule",
        "OTP for Activate Schedule : " + sh.name
      );
      if (!res) return;
    }

    this.notifier.loading(true);

    let data = {
      accountId: sh.accountId,
      id: sh._id,
      urlAction: "/api/scheduler/pause",
    };

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == "1") {
      if (result.shedulerRule.status == "P") {
        this.notifier.alert(
          "Success",
          "",
          "Schedule Rule Paused successfully",
          "success",
          5000
        );
      } else {
        this.notifier.alert(
          "Success",
          "",
          "Schedule Rule Activated successfully",
          "success",
          5000
        );
      }
      this.showCreateShedule = false;
      this.loadAllShedule();
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  mapInstanceToTarget(item: any, targetType = "", regionIndexx = -1) {
    let instanceId = item;

    if (!targetType) {
      targetType = this.cShedule.targetType;
    }

    if (targetType == "EC2") instanceId = item.instanceId;
    if (targetType == "RDS") instanceId = item.dbInstanceIdentifier;

    let regionIndex = -1;
    if (regionIndexx == -1) {
      for (let i = 0; i < this.cShedule.target.length; i++) {
        if (this.cShedule.target[i].regionId == this.regionId) {
          regionIndex = i;
          break;
        }
      }
    } else {
      regionIndex = regionIndexx;
    }

    if (regionIndex == -1) {
      regionIndex = this.cShedule.target.length;
      this.cShedule.target.push({
        regionId: this.regionId,
        regionName: this.selectedRegion,
        RDS: [],
        EC2: [],
        Tags: [],

        RDSD: [],
        EC2D: [],
        TagsD: [],
      });
    }

    let index =
      this.cShedule.target[regionIndex][targetType].indexOf(instanceId);
    if (index == -1) {
      this.animateAdd(instanceId);
      setTimeout(() => {
        this.cShedule.target[regionIndex][targetType].push(instanceId);
        this.cShedule.target[regionIndex][targetType + "D"].push(item);
        console.log(
          targetType + "D",
          this.cShedule.target[regionIndex][targetType + "D"]
        );

        this.cdRef.detectChanges();
      }, 400);
    } else {
      this.cShedule.target[regionIndex][targetType].splice(index, 1);
      this.cShedule.target[regionIndex][targetType + "D"].splice(index, 1);
      if (
        this.cShedule.target[regionIndex].RDS.length == 0 &&
        this.cShedule.target[regionIndex].EC2.length == 0 &&
        this.cShedule.target[regionIndex].Tags.length == 0
      ) {
        this.cShedule.target.splice(regionIndex, 1);
      }
    }
    this.cdRef.detectChanges();
  }

  animateAdd(instanceId: string) {
    instanceId = "#" + instanceId;
    let item = $(instanceId);
    if (!item || !item[0]) return;
    //let offset = item.offset();
    let offset = item[0].getBoundingClientRect();
    let top = offset.top; //- $(window).scrollTop()
    $(instanceId).animate(
      {
        //top: (top + 200) + "px"
        opacity: 0.5,
      },
      {
        duration: 500,
        step: function (now, fx) {
          //console.log("now", now);
          let t = Math.ceil(top - 300 * (1 - now)) + "px";
          //console.log("step", t);
          $(instanceId).css("top", t);
        },
        start: function () {
          //console.log("start", top);
          item.css("top", top + "px");
          item.css("position", "fixed");
        },
        complete: function () {
          $(instanceId).css("position", "static");
          $(instanceId).css("top", "auto");
          $(instanceId).css("opacity", "1");
        },
      }
    );
  }

  checkIf(instanceId: string, nType: string) {
    if (!this.cShedule.target) this.cShedule.target = [];
    let regionIndex = -1;
    for (let i = 0; i < this.cShedule.target.length; i++) {
      if (this.cShedule.target[i].regionId == this.regionId) {
        regionIndex = i;
        break;
      }
    }

    if (!this.cShedule.target[regionIndex]) return false;

    let index = this.cShedule.target[regionIndex][nType].indexOf(instanceId);
    if (index == -1) return false;
    return true;
  }

  async setRegion(i: any) {
    this.regionId = i.id;
    this.selectedRegion = i.name;
    this.notifier.loading(true);
    await this.loadEC2New();
    //await Promise.all([this.loadEC2(), this.loadRDS()]); //, this.loadTags()
    //this.loadEC2()
    this.notifier.loading(false);

    this.cdRef.detectChanges();
  }

  async showCalculatedSchedule() {
    this.notifier.loading(true);

    let data = {
      accountId: this.accountId,
      regionId: this.regionId,
      cShedule: this.cShedule,
      id: this.cShedule._id,
      draft: this.cShedule.draft,
      loadData: true,
      urlAction: "/api/scheduler/save",
    };

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTMongo}/api/scheduler/save`; http://43.204.90.89:14189/api/map/api/scheduler/save
    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == "1") {
      //this.notifier.alert('Success', '', "Schedule Rule saved successfully", 'success', 5000);
      //this.showCreateShedule = false;
      //this.loadAllShedule();
      this.scheduleMaps = result.em;
      console.log("em", result.em);
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  convertToIST(d: any) {
    try {
      console.log("convertToIST", d);
      return moment(d)
        .utc()
        .add("minutes", 330)
        .format("dddd DD-MMM-YYYY hh:mm A");

      //return moment(d).utc().format("DD-MMM-YYYY hh:mm A")
    } catch (e: any) {
      console.log(e.stack);
      return "";
    }
  }

  convetToString(d: any) {
    try {
      for (let i = 0; i < d.length; i++) {
        delete d[i].Tags;
        delete d[i].RDSD;
        delete d[i].EC2D;
        delete d[i].TagsD;
      }
      return JSON.stringify(d, null, 4);
    } catch (e: any) {
      console.log(e.stack);
      return "";
    }
  }

  async showLogData(sh: any) {
    window.analyticsSendEventClick("scheduler", "View Schedule Log");
    this.showLog = true;
    //Get Log Data
    //api/scheduler/get/log

    this.notifier.loading(true);

    let data = {
      accountId: sh.accountId,
      id: sh._id,
      urlAction: "/api/scheduler/get/log",
    };

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == "1") {
      this.logShData = result.shedulerRule;
      this.logData = result.shedulerRuleMap;

      for (let i = 0; i < this.logData.length; i++) {
        let dt = [];
        if (this.logData[i].lastRun && this.logData[i].lastRun.m) {
          for (let q = 0; q < this.logData[i].lastRun.m.length; q++) {
            if (this.logData[i].action == "Stop") {
              dt.push(...this.logData[i].lastRun.m[q].StoppingInstances);
            } else if (this.logData[i].action == "Start") {
              dt.push(...this.logData[i].lastRun.m[q].StartingInstances);
            }
          }
        }

        if (this.logData[i].target) {
          for (let k = 0; k < this.logData[i].target.length; k++) {
            if (this.logData[i].target[k] && this.logData[i].target[k].EC2D) {
              for (let z = 0; z < this.logData[i].target[k].EC2D.length; z++) {
                let d = dt.filter(
                  (a) =>
                    a.InstanceId == this.logData[i].target[k].EC2D[z].instanceId
                );
                if (d.length > 0) {
                  this.logData[i].target[k].EC2D[z].previousState =
                    d[0].PreviousState.Name;
                  this.logData[i].target[k].EC2D[z].currentState =
                    d[0].CurrentState.Name;
                }
              }
            }
          }
        }
      }
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }

    this.notifier.loading(false);
  }

  showEC2SearchFilters() {
    $("#showEC2SearchFiltersList").toggleClass("show");
  }

  setSearchType(sType: string = "Select") {
    this.ec2SearchType = sType;
    this.showEc2SearchAutocomplete = false;
    this.ec2SearchValue = "";
    $("#showEC2SearchFiltersList").removeClass("show");
    if (sType == "Select") {
      this.ec2SearchValueHint = "Showing All Data";
      this.ec2SearchValuePlaceholder = "All";
    } else if (sType == "Instance Id") {
      this.ec2SearchValueHint = "Enter Instance Id to Search";
      this.ec2SearchValuePlaceholder = "i-xxxxxxxxxxxxx";
    } else if (sType == "Name") {
      this.ec2SearchValueHint = "Enter Instance Name to Search";
      this.ec2SearchValuePlaceholder = "abc";
    } else if (sType == "Instance Type") {
      this.ec2SearchValueHint =
        "Enter Instance Type to Search, e.g. 	t2.xlarge | t3.xlarge";
      this.ec2SearchValuePlaceholder = "t2.xlarge";
      this.showEc2SearchAutocomplete = true;
      this.showEc2SearchAutocompleteList = [
        "a1.2xlarge",
        "a1.4xlarge",
        "a1.large",
        "a1.medium",
        "a1.metal",
        "a1.xlarge",
        "c1.medium",
        "c1.xlarge",
        "c3.2xlarge",
        "c3.4xlarge",
        "c3.8xlarge",
        "c3.large",
        "c3.xlarge",
        "c4.2xlarge",
        "c4.4xlarge",
        "c4.8xlarge",
        "c4.large",
        "c4.xlarge",
        "c5.12xlarge",
        "c5.18xlarge",
        "c5.24xlarge",
        "c5.2xlarge",
        "c5.4xlarge",
        "c5.9xlarge",
        "c5.large",
        "c5.metal",
        "c5.xlarge",
        "c5a.12xlarge",
        "c5a.16xlarge",
        "c5a.24xlarge",
        "c5a.2xlarge",
        "c5a.4xlarge",
        "c5a.8xlarge",
        "c5a.large",
        "c5a.xlarge",
        "c5ad.12xlarge",
        "c5ad.16xlarge",
        "c5ad.24xlarge",
        "c5ad.2xlarge",
        "c5ad.4xlarge",
        "c5ad.8xlarge",
        "c5ad.large",
        "c5ad.xlarge",
        "c5d.12xlarge",
        "c5d.18xlarge",
        "c5d.24xlarge",
        "c5d.2xlarge",
        "c5d.4xlarge",
        "c5d.9xlarge",
        "c5d.large",
        "c5d.metal",
        "c5d.xlarge",
        "c5n.18xlarge",
        "c5n.2xlarge",
        "c5n.4xlarge",
        "c5n.9xlarge",
        "c5n.large",
        "c5n.metal",
        "c5n.xlarge",
        "c6a.12xlarge",
        "c6a.16xlarge",
        "c6a.24xlarge",
        "c6a.2xlarge",
        "c6a.32xlarge",
        "c6a.48xlarge",
        "c6a.4xlarge",
        "c6a.8xlarge",
        "c6a.large",
        "c6a.metal",
        "c6a.xlarge",
        "c6g.12xlarge",
        "c6g.16xlarge",
        "c6g.2xlarge",
        "c6g.4xlarge",
        "c6g.8xlarge",
        "c6g.large",
        "c6g.medium",
        "c6g.metal",
        "c6g.xlarge",
        "c6gd.12xlarge",
        "c6gd.16xlarge",
        "c6gd.2xlarge",
        "c6gd.4xlarge",
        "c6gd.8xlarge",
        "c6gd.large",
        "c6gd.medium",
        "c6gd.metal",
        "c6gd.xlarge",
        "c6gn.12xlarge",
        "c6gn.16xlarge",
        "c6gn.2xlarge",
        "c6gn.4xlarge",
        "c6gn.8xlarge",
        "c6gn.large",
        "c6gn.medium",
        "c6gn.xlarge",
        "c6i.12xlarge",
        "c6i.16xlarge",
        "c6i.24xlarge",
        "c6i.2xlarge",
        "c6i.32xlarge",
        "c6i.4xlarge",
        "c6i.8xlarge",
        "c6i.large",
        "c6i.metal",
        "c6i.xlarge",
        "c6id.12xlarge",
        "c6id.16xlarge",
        "c6id.24xlarge",
        "c6id.2xlarge",
        "c6id.32xlarge",
        "c6id.4xlarge",
        "c6id.8xlarge",
        "c6id.large",
        "c6id.metal",
        "c6id.xlarge",
        "c7g.12xlarge",
        "c7g.16xlarge",
        "c7g.2xlarge",
        "c7g.4xlarge",
        "c7g.8xlarge",
        "c7g.large",
        "c7g.medium",
        "c7g.xlarge",
        "cc2.8xlarge",
        "cr1.8xlarge",
        "d2.2xlarge",
        "d2.4xlarge",
        "d2.8xlarge",
        "d2.xlarge",
        "d3.2xlarge",
        "d3.4xlarge",
        "d3.8xlarge",
        "d3.xlarge",
        "d3en.12xlarge",
        "d3en.2xlarge",
        "d3en.4xlarge",
        "d3en.6xlarge",
        "d3en.8xlarge",
        "d3en.xlarge",
        "dl1.24xlarge",
        "f1.16xlarge",
        "f1.2xlarge",
        "f1.4xlarge",
        "g2.2xlarge",
        "g2.8xlarge",
        "g3.16xlarge",
        "g3.4xlarge",
        "g3.8xlarge",
        "g3s.xlarge",
        "g4ad.16xlarge",
        "g4ad.2xlarge",
        "g4ad.4xlarge",
        "g4ad.8xlarge",
        "g4ad.xlarge",
        "g4dn.12xlarge",
        "g4dn.16xlarge",
        "g4dn.2xlarge",
        "g4dn.4xlarge",
        "g4dn.8xlarge",
        "g4dn.metal",
        "g4dn.xlarge",
        "g5.12xlarge",
        "g5.16xlarge",
        "g5.24xlarge",
        "g5.2xlarge",
        "g5.48xlarge",
        "g5.4xlarge",
        "g5.8xlarge",
        "g5.xlarge",
        "g5g.16xlarge",
        "g5g.2xlarge",
        "g5g.4xlarge",
        "g5g.8xlarge",
        "g5g.metal",
        "g5g.xlarge",
        "h1.16xlarge",
        "h1.2xlarge",
        "h1.4xlarge",
        "h1.8xlarge",
        "hpc6a.48xlarge",
        "hs1.8xlarge",
        "i2.2xlarge",
        "i2.4xlarge",
        "i2.8xlarge",
        "i2.xlarge",
        "i3.16xlarge",
        "i3.2xlarge",
        "i3.4xlarge",
        "i3.8xlarge",
        "i3.large",
        "i3.metal",
        "i3.xlarge",
        "i3en.12xlarge",
        "i3en.24xlarge",
        "i3en.2xlarge",
        "i3en.3xlarge",
        "i3en.6xlarge",
        "i3en.large",
        "i3en.metal",
        "i3en.xlarge",
        "i3p.16xlarge",
        "i4i.16xlarge",
        "i4i.2xlarge",
        "i4i.32xlarge",
        "i4i.4xlarge",
        "i4i.8xlarge",
        "i4i.large",
        "i4i.metal",
        "i4i.xlarge",
        "im4gn.16xlarge",
        "im4gn.2xlarge",
        "im4gn.4xlarge",
        "im4gn.8xlarge",
        "im4gn.large",
        "im4gn.xlarge",
        "inf1.24xlarge",
        "inf1.2xlarge",
        "inf1.6xlarge",
        "inf1.xlarge",
        "is4gen.2xlarge",
        "is4gen.4xlarge",
        "is4gen.8xlarge",
        "is4gen.large",
        "is4gen.medium",
        "is4gen.xlarge",
        "m1.large",
        "m1.medium",
        "m1.small",
        "m1.xlarge",
        "m2.2xlarge",
        "m2.4xlarge",
        "m2.xlarge",
        "m3.2xlarge",
        "m3.large",
        "m3.medium",
        "m3.xlarge",
        "m4.10xlarge",
        "m4.16xlarge",
        "m4.2xlarge",
        "m4.4xlarge",
        "m4.large",
        "m4.xlarge",
        "m5.12xlarge",
        "m5.16xlarge",
        "m5.24xlarge",
        "m5.2xlarge",
        "m5.4xlarge",
        "m5.8xlarge",
        "m5.large",
        "m5.metal",
        "m5.xlarge",
        "m5a.12xlarge",
        "m5a.16xlarge",
        "m5a.24xlarge",
        "m5a.2xlarge",
        "m5a.4xlarge",
        "m5a.8xlarge",
        "m5a.large",
        "m5a.xlarge",
        "m5ad.12xlarge",
        "m5ad.16xlarge",
        "m5ad.24xlarge",
        "m5ad.2xlarge",
        "m5ad.4xlarge",
        "m5ad.8xlarge",
        "m5ad.large",
        "m5ad.xlarge",
        "m5d.12xlarge",
        "m5d.16xlarge",
        "m5d.24xlarge",
        "m5d.2xlarge",
        "m5d.4xlarge",
        "m5d.8xlarge",
        "m5d.large",
        "m5d.metal",
        "m5d.xlarge",
        "m5dn.12xlarge",
        "m5dn.16xlarge",
        "m5dn.24xlarge",
        "m5dn.2xlarge",
        "m5dn.4xlarge",
        "m5dn.8xlarge",
        "m5dn.large",
        "m5dn.metal",
        "m5dn.xlarge",
        "m5n.12xlarge",
        "m5n.16xlarge",
        "m5n.24xlarge",
        "m5n.2xlarge",
        "m5n.4xlarge",
        "m5n.8xlarge",
        "m5n.large",
        "m5n.metal",
        "m5n.xlarge",
        "m5zn.12xlarge",
        "m5zn.2xlarge",
        "m5zn.3xlarge",
        "m5zn.6xlarge",
        "m5zn.large",
        "m5zn.metal",
        "m5zn.xlarge",
        "m6a.12xlarge",
        "m6a.16xlarge",
        "m6a.24xlarge",
        "m6a.2xlarge",
        "m6a.32xlarge",
        "m6a.48xlarge",
        "m6a.4xlarge",
        "m6a.8xlarge",
        "m6a.large",
        "m6a.metal",
        "m6a.xlarge",
        "m6g.12xlarge",
        "m6g.16xlarge",
        "m6g.2xlarge",
        "m6g.4xlarge",
        "m6g.8xlarge",
        "m6g.large",
        "m6g.medium",
        "m6g.metal",
        "m6g.xlarge",
        "m6gd.12xlarge",
        "m6gd.16xlarge",
        "m6gd.2xlarge",
        "m6gd.4xlarge",
        "m6gd.8xlarge",
        "m6gd.large",
        "m6gd.medium",
        "m6gd.metal",
        "m6gd.xlarge",
        "m6i.12xlarge",
        "m6i.16xlarge",
        "m6i.24xlarge",
        "m6i.2xlarge",
        "m6i.32xlarge",
        "m6i.4xlarge",
        "m6i.8xlarge",
        "m6i.large",
        "m6i.metal",
        "m6i.xlarge",
        "m6id.12xlarge",
        "m6id.16xlarge",
        "m6id.24xlarge",
        "m6id.2xlarge",
        "m6id.32xlarge",
        "m6id.4xlarge",
        "m6id.8xlarge",
        "m6id.large",
        "m6id.metal",
        "m6id.xlarge",
        "mac1.metal",
        "mac2.metal",
        "p2.16xlarge",
        "p2.8xlarge",
        "p2.xlarge",
        "p3.16xlarge",
        "p3.2xlarge",
        "p3.8xlarge",
        "p3dn.24xlarge",
        "p4d.24xlarge",
        "p4de.24xlarge",
        "r3.2xlarge",
        "r3.4xlarge",
        "r3.8xlarge",
        "r3.large",
        "r3.xlarge",
        "r4.16xlarge",
        "r4.2xlarge",
        "r4.4xlarge",
        "r4.8xlarge",
        "r4.large",
        "r4.xlarge",
        "r5.12xlarge",
        "r5.16xlarge",
        "r5.24xlarge",
        "r5.2xlarge",
        "r5.4xlarge",
        "r5.8xlarge",
        "r5.large",
        "r5.metal",
        "r5.xlarge",
        "r5a.12xlarge",
        "r5a.16xlarge",
        "r5a.24xlarge",
        "r5a.2xlarge",
        "r5a.4xlarge",
        "r5a.8xlarge",
        "r5a.large",
        "r5a.xlarge",
        "r5ad.12xlarge",
        "r5ad.16xlarge",
        "r5ad.24xlarge",
        "r5ad.2xlarge",
        "r5ad.4xlarge",
        "r5ad.8xlarge",
        "r5ad.large",
        "r5ad.xlarge",
        "r5b.12xlarge",
        "r5b.16xlarge",
        "r5b.24xlarge",
        "r5b.2xlarge",
        "r5b.4xlarge",
        "r5b.8xlarge",
        "r5b.large",
        "r5b.metal",
        "r5b.xlarge",
        "r5d.12xlarge",
        "r5d.16xlarge",
        "r5d.24xlarge",
        "r5d.2xlarge",
        "r5d.4xlarge",
        "r5d.8xlarge",
        "r5d.large",
        "r5d.metal",
        "r5d.xlarge",
        "r5dn.12xlarge",
        "r5dn.16xlarge",
        "r5dn.24xlarge",
        "r5dn.2xlarge",
        "r5dn.4xlarge",
        "r5dn.8xlarge",
        "r5dn.large",
        "r5dn.metal",
        "r5dn.xlarge",
        "r5n.12xlarge",
        "r5n.16xlarge",
        "r5n.24xlarge",
        "r5n.2xlarge",
        "r5n.4xlarge",
        "r5n.8xlarge",
        "r5n.large",
        "r5n.metal",
        "r5n.xlarge",
        "r6a.12xlarge",
        "r6a.16xlarge",
        "r6a.24xlarge",
        "r6a.2xlarge",
        "r6a.32xlarge",
        "r6a.48xlarge",
        "r6a.4xlarge",
        "r6a.8xlarge",
        "r6a.large",
        "r6a.metal",
        "r6a.xlarge",
        "r6g.12xlarge",
        "r6g.16xlarge",
        "r6g.2xlarge",
        "r6g.4xlarge",
        "r6g.8xlarge",
        "r6g.large",
        "r6g.medium",
        "r6g.metal",
        "r6g.xlarge",
        "r6gd.12xlarge",
        "r6gd.16xlarge",
        "r6gd.2xlarge",
        "r6gd.4xlarge",
        "r6gd.8xlarge",
        "r6gd.large",
        "r6gd.medium",
        "r6gd.metal",
        "r6gd.xlarge",
        "r6i.12xlarge",
        "r6i.16xlarge",
        "r6i.24xlarge",
        "r6i.2xlarge",
        "r6i.32xlarge",
        "r6i.4xlarge",
        "r6i.8xlarge",
        "r6i.large",
        "r6i.metal",
        "r6i.xlarge",
        "r6id.12xlarge",
        "r6id.16xlarge",
        "r6id.24xlarge",
        "r6id.2xlarge",
        "r6id.32xlarge",
        "r6id.4xlarge",
        "r6id.8xlarge",
        "r6id.large",
        "r6id.metal",
        "r6id.xlarge",
        "t1.micro",
        "t2.2xlarge",
        "t2.large",
        "t2.medium",
        "t2.micro",
        "t2.nano",
        "t2.small",
        "t2.xlarge",
        "t3.2xlarge",
        "t3.large",
        "t3.medium",
        "t3.micro",
        "t3.nano",
        "t3.small",
        "t3.xlarge",
        "t3a.2xlarge",
        "t3a.large",
        "t3a.medium",
        "t3a.micro",
        "t3a.nano",
        "t3a.small",
        "t3a.xlarge",
        "t4g.2xlarge",
        "t4g.large",
        "t4g.medium",
        "t4g.micro",
        "t4g.nano",
        "t4g.small",
        "t4g.xlarge",
        "trn1.2xlarge",
        "trn1.32xlarge",
        "u-12tb1.112xlarge",
        "u-12tb1.metal",
        "u-18tb1.112xlarge",
        "u-18tb1.metal",
        "u-24tb1.112xlarge",
        "u-24tb1.metal",
        "u-3tb1.56xlarge",
        "u-6tb1.112xlarge",
        "u-6tb1.56xlarge",
        "u-6tb1.metal",
        "u-9tb1.112xlarge",
        "u-9tb1.metal",
        "vt1.24xlarge",
        "vt1.3xlarge",
        "vt1.6xlarge",
        "x1.16xlarge",
        "x1.32xlarge",
        "x1e.16xlarge",
        "x1e.2xlarge",
        "x1e.32xlarge",
        "x1e.4xlarge",
        "x1e.8xlarge",
        "x1e.xlarge",
        "x2gd.12xlarge",
        "x2gd.16xlarge",
        "x2gd.2xlarge",
        "x2gd.4xlarge",
        "x2gd.8xlarge",
        "x2gd.large",
        "x2gd.medium",
        "x2gd.metal",
        "x2gd.xlarge",
        "x2idn.16xlarge",
        "x2idn.24xlarge",
        "x2idn.32xlarge",
        "x2idn.metal",
        "x2iedn.16xlarge",
        "x2iedn.24xlarge",
        "x2iedn.2xlarge",
        "x2iedn.32xlarge",
        "x2iedn.4xlarge",
        "x2iedn.8xlarge",
        "x2iedn.metal",
        "x2iedn.xlarge",
        "x2iezn.12xlarge",
        "x2iezn.2xlarge",
        "x2iezn.4xlarge",
        "x2iezn.6xlarge",
        "x2iezn.8xlarge",
        "x2iezn.metal",
        "z1d.12xlarge",
        "z1d.2xlarge",
        "z1d.3xlarge",
        "z1d.6xlarge",
        "z1d.large",
        "z1d.metal",
        "z1d.xlarge",
      ];
    } else if (sType == "Tags") {
      this.ec2SearchValueHint =
        "Enter Tags Name e.g. Tagname:tagValue | TagName:*";
      this.ec2SearchValuePlaceholder = "Tagname:tagValue";
    } else if (sType == "Private IP") {
      this.ec2SearchValueHint = "Enter Private IP to Search";
      this.ec2SearchValuePlaceholder = "xxx.xxx.xxx.xxx";
    } else if (sType == "Public  IP") {
      this.ec2SearchValueHint = "Enter Public  IP to Search";
      this.ec2SearchValuePlaceholder = "xxx.xxx.xxx.xxx";
    } else if (sType == "Instance Status") {
      this.ec2SearchValueHint =
        "Enter Instance Status to Search e.g. running | stopped";
      this.ec2SearchValuePlaceholder = "running";
      this.showEc2SearchAutocomplete = true;
      this.showEc2SearchAutocompleteList = [
        "pending",
        "running",
        "shutting-down",
        "terminated",
        "stopping",
        "stopped",
      ];
    }
  }

  frequencyChange(frequency: string) {
    this.cShedule.rule.frequency = frequency;
    setTimeout(() => {
      flatpickr(".datetimepicker", { dateFormat: "d-M-Y" });
      try {
        let test = document.createElement("input");
        test.type = "time";
        test = null;
      } catch (e) {
        console.log(e);
        flatpickr(".datetimepickerTime", {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
        });
      }
    }, 100);
  }

  async sendOtp(purpose: string, description: string) {
    try {
      let data = {
        action: "sendOtp",
        email: this.userId,
        purpose: purpose,
        description: description,
        accountId: this.accountId,
      };
      let header = {
        Authorization: "Bearer " + localStorage.getItem("t"),
      };
      let apiURL = APIService.API_ENDPOINTSchedulerOtp;
      let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

      return result;
    } catch (e) {
      return { s: 0, error: e };
    }
  }

  async validateOtp(purpose: string, otp: string) {
    try {
      let data = {
        action: "validateOtp",
        purpose: purpose,
        otp: otp,
        accountId: this.accountId,
      };
      let header = {
        Authorization: "Bearer " + localStorage.getItem("t"),
      };
      let apiURL = APIService.API_ENDPOINTSchedulerOtp;
      let result = await this.apiServer.postDataPromisNew(apiURL, data, header);
      return result;
    } catch (e) {
      return { s: 0, error: e };
    }
  }

  cancelOTPClick() {
    this.cancelOTP = true;
    this.otpModal = false;
  }

  optValidateFor(purpose: string, description: string) {
    return new Promise(async (resolve, rejects) => {
      this.cancelOTP = false;
      this.otpValided = false;
      this.otpValid = false;

      if (APIService.OTP_Enabled === false) {
        return resolve(true);
      }

      let res: any = await this.sendOtp(purpose, description);

      if (res.s != 1) {
        this.notifier.alert(
          "Error",
          "",
          "Error Please Try Againe.",
          "error",
          5000
        ); // TODO : Fix Error Message, put more Detail
        return resolve(false);
      }
      this.otpPurpose = purpose;
      this.otpModal = true;
      let si = setInterval(() => {
        console.log(
          "optValidateFor",
          this.cancelOTP,
          this.otpValided,
          this.otpValid
        );
        if (this.cancelOTP) {
          clearInterval(si);
          this.otpModal = false;
          return resolve(false);
        }

        if (this.otpValided && this.otpValid) {
          clearInterval(si);
          this.otpModal = false;
          return resolve(true);
        }
      }, 100);
    });
  }

  async confirmOTPClick() {
    if (!this.otpValue || this.otpValue.length != 8) {
      this.optErrorMSG = "Please Enter OTP";
    }
    let resOTP: any = await this.validateOtp(this.otpPurpose, this.otpValue);
    this.notifier.loading(false);
    if (resOTP.s != 1) {
      this.optErrorMSG = "Error OTP do not Match!";
      return;
    }

    this.otpValided = true;
    this.otpValid = true;
    this.otpValue = "";
  }

  //let attempt = 3;

  //while (true) {
  //  this.notifier.loading(false);
  //  let otp = prompt(description);

  //  if (!otp || otp.length > 14) {
  //    this.notifier.alert('Error', '', 'Error Please Try Againe.', 'error', 5000);
  //    continue;
  //  }
  //  this.notifier.loading(true);
  //  let resOTP: any = await this.validateOtp(purpose, otp);
  //  this.notifier.loading(false);

  //  if (resOTP.s != 1) {
  //    attempt--;
  //    this.notifier.alert('Error', '', 'Error Wrong OTP.', 'error', 5000);  // TODO : Fix Error Message, put more Detail
  //    if (attempt == 0) {
  //      this.notifier.loading(false);
  //      return false;
  //    }
  //    continue;
  //  }
  //  else {
  //    this.notifier.loading(false);
  //    break;
  //  }

  //}

  //async optValidateFor(purpose: string, description: string) {

  //  let res: any = await this.sendOtp(purpose, description);

  //  if (res.s != 1) {
  //    this.notifier.alert('Error', '', 'Error Please Try Againe.', 'error', 5000);  // TODO : Fix Error Message, put more Detail
  //    return false;
  //  }

  //  let attempt = 3;

  //  while (true) {
  //    this.notifier.loading(false);
  //    let otp = prompt(description);

  //    if (!otp || otp.length > 14) {
  //      this.notifier.alert('Error', '', 'Error Please Try Againe.', 'error', 5000);
  //      continue;
  //    }
  //    this.notifier.loading(true);
  //    let resOTP: any = await this.validateOtp(purpose, otp);
  //    this.notifier.loading(false);

  //    if (resOTP.s != 1) {
  //      attempt--;
  //      this.notifier.alert('Error', '', 'Error Wrong OTP.', 'error', 5000);  // TODO : Fix Error Message, put more Detail
  //      if (attempt == 0) {
  //        this.notifier.loading(false);
  //        return false;
  //      }
  //      continue;
  //    }
  //    else {
  //      this.notifier.loading(false);
  //      break;
  //    }

  //  }

  //  return true;
  //}
}
