import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-backup-report",
  templateUrl: "./backup-report.component.html",
  styleUrls: ["./backup-report.component.css"],
})
export class BackupReportComponent implements OnInit {
  currentTab: string = "snapshots";

  constructor() {}

  ngOnInit(): void {}
}
