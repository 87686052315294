import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";

@Component({
  //moduleId: module.id.toString(),
  styleUrls: ["./deltareport.bom.component.css"],
  templateUrl: "./deltareport.bom.component.html",
})
export class deltaReportBOMComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  proposedvsactualList: any = [];
  additionalInstancesList: any = [];
  deltaReportList: any = [];
  accountId: any;
  regionId: any;
  currentMessage: any;

  deltaReportHeader: any = [
    {
      id: "name",
      name: "Categories",
    },
    { id: "required", name: "Required" },
    { id: "used", name: "Used" },
    { id: "delta", name: "Delta" },
  ];
  proposedvsactualheaders: any = [
    {
      id: "instanceType",
      name: "Instance Type",
    },
    { id: "count", name: "BOM Instances" },
    { id: "actualCount", name: "Actual Instances" },
  ];
  additionalInstancesHeader: any = [
    {
      id: "instanceType",
      name: "Instance Type",
    },
    { id: "count", name: "Count" },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    this.deltaReportList = [];
    const output = await this.loadDeltaReport();
    console.log("OUTPUT ", output);
    if (output) {
      await this.loadDeltaUsed();
      await this.loadDeltaRAMCPUUsed();
    }
    this.notifier.loading(false);
  }

  async loadDeltaReport() {
    let data = { accountId: this.accountId, action: "fetchDeltaAcutals" };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      (result.status == "1" || result.s == "1") &&
      result.deltaDetails &&
      Object.entries(result.deltaDetails).length > 0
    ) {
      for (let key in result.deltaDetails) {
        this.deltaReportList.push({
          category: key,
          required: result.deltaDetails[key],
          used: "",
          delta: result.deltaDetails[key],
          name:
            key == "cpu" ? "CPU" : key.charAt(0).toUpperCase() + key.slice(1),
        });
      }
      return true;
    } else if (
      (result.status == "1" || result.s == "1") &&
      result.deltaDetails
    ) {
      this.notifier.alert("Error", "", "No Delta Report Found", "error", 5000);
      return false;
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
      return false;
    }
  }
  async loadDeltaUsed() {
    let data = {
      accountId: this.accountId,
      action: "fetchDeltaUsed",
      region: this.regionId,
      diskAllocated: "0.0",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if ((result.status == "1" || result.s == "1") && result.deltaDetails) {
      for (let key in result.deltaDetails) {
        let category = this.deltaReportList.find((category: any) => {
          return category.category == key;
        });
        if (category) {
          category.used = String(
            Number(category.used) + Number(result.deltaDetails[key])
          );
          category.delta = String(
            Number(category.delta) - Number(result.deltaDetails[key])
          );
          let cateforyIndex = this.deltaReportList.findIndex(
            (category: any) => {
              return category.category == key;
            }
          );
          this.deltaReportList[cateforyIndex] = category;
        }
      }
      return true;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
      return false;
    }
  }
  async loadDeltaRAMCPUUsed(
    nextToken: String = "",
    cpu: String = "0",
    memory: String = "0"
  ) {
    let data = {
      accountId: this.accountId,
      action: "fetchDeltaRAMUsed",
      region: this.regionId,
      cpu: cpu,
      ram: memory,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if ((result.status == "1" || result.s == "1") && result.deltaDetails) {
      for (let key in result.deltaDetails) {
        let category = this.deltaReportList.find((category: any) => {
          return category.category == key;
        });
        category.used = String(
          Number(category.used) + Number(result.deltaDetails[key])
        );
        category.delta = String(
          Number(category.delta) - Number(result.deltaDetails[key])
        );
        let cateforyIndex = this.deltaReportList.findIndex((category: any) => {
          return category.category == key;
        });
        this.deltaReportList[cateforyIndex] = category;
      }
      if (result.nextToken) {
        this.loadDeltaRAMCPUUsed(
          result.nextToken,
          result.deltaDetails.cpu,
          result.deltaDetails.memory
        );
      }
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
  }

  async loadUsageReport() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: "fetchInfraActualReport",
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.proposedvsactualList = result.instanceTypeDetailsList;
      this.additionalInstancesList = result.instanceInfraTypeDetailsList;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    return;
  }
}
