<!-- toggler -->
<!--<a class="navbar-brand" >

</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>-->
<!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-primary" type="submit">Search</button> *ngIf="searchOnList(i.groupName,accountGroupSearchTerm)" -->
<!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
  <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
</button>-->
<!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
<!--<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>
  </div>-->

  <div class="leftmenu" *ngIf="started">
    <div class="offcanvas-body px-0 max-overflow">
      <button
        class="menuCloseButton ground-border"
        (click)="hideLeftMenu()"
        id="menuClosebtn"
      >
        <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
      </button>
  
      <button class="ground-border" (click)="showLeftMenu()" id="menuShowbtn">
        <i class="fa fa-arrow-circle-right"></i>
      </button>
  
      <ul
        class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
        id="menu"
      >
        <li
          class="dropdown"
          *ngIf="
            this.urlPrefix == 'client' &&
            customerType == 'mp' &&
            ut == 'admin' &&
            !isUserAdmin
          "
        >
          <a
            href="#"
            class="nav-link dropdown-toggle text-truncate"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Operations+Security+Group.svg"
              alt=""
              class="wid-40"
            />Cloud Partners
          </a>
          <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdown">
            <li>
              <h3 class="sub-menu-title">Cloud Partners</h3>
              <hr />
            </li>
            <li>
              <a class="dropdown-item" routerLink="/dash/accounts/aws">
                <img
                  src="./assets/img/aws_logo.png"
                  class="width-30"
                  alt=""
                /><span>AWS</span>
              </a>
            </li>
            <!-- <li *ngIf="checkIfAvaForUser('cloudSupport:ticket:list')">
              <a class="dropdown-item" routerLink="/dash/ticket/list">
                <img src="./assets/img/azure_logo.png" style="max-width: 30px; max-height: 30px; margin-right: 10px;"
                  alt=""><span>Azure</span>
              </a>
            </li> -->
  
            <!-- <li *ngIf="
                checkIfAvaForUser('cloudSupport:knowledgebase') &&
                urlPrefix == 'admin'
              ">
              <a class="dropdown-item" routerLink="/dash/knowledgebase">
                <img src="./assets/img/gcp_logo.png" class="width-30" alt="" /><span>GCP</span>
              </a>
            </li> -->
          </ul>
        </li>
  
        <ng-container *ngIf="selectedCloudPartner == 'aws'">
          <li
            class="nav-item"
            *ngIf="checkIfAvaForUser('Compliance') && productType == 'WAFR'"
          >
            <a
              routerLink="/dash/wafr"
              class="nav-link text-truncate"
              title="WAFR"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/framework+white.svg"
                class="monitoringimg"
                alt=""
              />
              WAFR
            </a>
          </li>
  
          <li
            class="dropdown"
            *ngIf="
              checkIfAvaForUser('Support')
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Cloud+Support.svg"
                alt=""
                class="wid-40"
              />
              Support
            </a>
            <ul
              class="dropdown-menu text-small shadow"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Support</h3>
                <hr />
              </li>
              <li
                *ngIf="
                  checkIfAvaForUser('Support:create') && writeAccess
                "
              >
                <a class="dropdown-item" routerLink="/dash/ticket/create">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Create+Ticket.svg"
                    class="wid-40"
                    alt=""
                  /><span>Create Ticket</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Support:view tickets')">
                <a class="dropdown-item" routerLink="/dash/ticket/list">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+Ticket.svg"
                    class="wid-40"
                    alt=""
                  /><span>View Tickets</span>
                </a>
              </li>
  
              <li
                *ngIf="
                  checkIfAvaForUser('Support:knowledge base') &&
                  urlPrefix == 'admin'
                "
              >
                <a class="dropdown-item" routerLink="/dash/knowledgebase">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Knowledge+Base.svg"
                    class="wid-40"
                    alt=""
                  /><span>Knowledge Base</span>
                </a>
              </li>
            </ul>
          </li>
         
          <li class="dropdown" *ngIf="checkIfAvaForUser('Dashboard')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
                alt=""
                class="wid-40"
              />
              Dashboards
            </a>
            <ul
              class="dropdown-menu text-small shadow"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Dashboards</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('Dashboard:global')">
                <a class="dropdown-item" routerLink="/dash/home">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Dashboard%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>Global</span>
                </a>
              </li>
              <li
                *ngIf="
                  checkIfAvaForUser('Dashboard:governance')
                "
              >
                <a class="dropdown-item" routerLink="/dash/compliance/dash">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compliance+Dashboard%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>Governance</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="dropdown" *ngIf="totalWhatsNew().length > 0">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/whats-new.svg"
                alt=""
                class="wid-40"
              />
              Whats New
            </a>
            <ul
              class="dropdown-menu text-small shadow"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Whats New</h3>
                <hr />
              </li>
              <li *ngFor="let menu of whatsNew">
                <a
                  class="dropdown-item"
                  *ngIf="menu['permission']()"
                  [routerLink]="menu['url']"
                >
                  <img src="{{ menu['icon'] }}" class="wid-40" alt="" /><span>{{
                    menu["name"]
                  }}</span>
                </a>
              </li>
            </ul>
          </li>
  
          <!-- <li class="nav-item" *ngIf="checkIfAvaForUser('Tell-Me')">
            <a routerLink="/dash/reports/tell-me" class="nav-link text-truncate" title="Tell-Me">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Enable+Reports%404x.png"
                class="monitoringimg" alt="" />
              Tell Me
            </a>
          </li> -->
          <li class="nav-item" *ngIf="checkIfAvaForUser('Alerts')">
            <a routerLink="/dash/tell-me/alerts" class="nav-link text-truncate">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Alerts.svg"
                alt=""
                class="wid-40"
              />
              Alerts
            </a>
          </li>
          <li class="dropdown" *ngIf="checkIfAvaForUser('Operations')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Operations+(Manage+Cloud+Resources).svg"
                alt=""
                class="wid-40"
              />
              Operations
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Operations</h3>
                <hr />
              </li>
  
              <li *ngIf="checkIfAvaForUser('Operations:ec2')">
                <a class="dropdown-item" routerLink="/dash/operations/tags">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>EC2</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:rds')">
                <a class="dropdown-item" routerLink="/dash/operations/rds">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                    class="wid-40"
                    alt=""
                  /><span>RDS</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:ebs')">
                <a class="dropdown-item" routerLink="/dash/operations/ebs">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+EBS.svg"
                    class="wid-40"
                    alt=""
                  /><span>EBS</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:acm')">
                <a class="dropdown-item" routerLink="/dash/operations/acm">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>ACM</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:tags')">
                <a class="dropdown-item" routerLink="/dash/compliance/tags">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Tags.svg"
                    class="wid-40"
                    alt=""
                  /><span>Tags</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:cloudtrail')">
                <a class="dropdown-item" routerLink="/dash/compliance/cloudtrail">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  />
                  <span>Cloud Trail</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:s3')">
                <a class="dropdown-item" routerLink="/dash/s3/operations">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  />
                  <span>S3</span>
                </a>
              </li>
              <!-- <li *ngIf="checkIfAvaForUser('Operations:ServiceIntelligence')">
                <a class="dropdown-item" routerLink="/dash/operations/itsi">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Service+Intelligence.svg"
                    style="width: 40px;" alt=""><span>Service Intelligence</span>
                </a>
              </li> -->
            </ul>
          </li>
  
          <li class="dropdown" *ngIf="checkIfAvaForUser('Governance')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Compliance.svg"
                class="wid-40"
                alt=""
              />
              Governance
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu min-width-300"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Governance</h3>
                <hr />
              </li>
              <li class="divider padding-0 border-top-0" *ngIf="checkIfAvaForUser('Governance:dashboard')">
                <div class="dropdown-item flex-between padding-0">
                  <a
                    class="dropdown-item padding-0 flex-start"
                    routerLink="/dash/compliance/dash"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compliance+Dashboard%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span>Dashboard</span>
                  </a>
                </div>
              </li>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'compute')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compute%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Compute </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['compute']">
                <li *ngIf="checkIfAvaForUser('Governance:ec2')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'ec2' }"
                  >
                    <span>EC2</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:elb')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'elb' }"
                  >
                    <span>ELB</span>
                  </a>
                </li>
  
                <li *ngIf="checkIfAvaForUser('Governance:s3')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 's3' }"
                  >
                    <span>S3</span>
                  </a>
                </li>
  
                <!-- <li *ngIf="checkIfAvaForUser('s3:age_report`') && writeAccess">
                  <a class="dropdown-item" routerLink="/dash/compliance/s3/age_report">
                    <span>S3 Age Report</span>
                  </a>
                </li> -->
  
                <li *ngIf="checkIfAvaForUser('Governance:security groups')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'sg' }"
                  >
                    <span>Security Groups</span>
                  </a>
                </li>
              </div>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'serverless')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Serverless </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['serverless']">
                <li *ngIf="checkIfAvaForUser('Governance:api gateway')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'apigateway' }"
                  >
                    <span>API Gateway</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:lambda')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'lambda' }"
                  >
                    <span>Lambda</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:ses')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'ses' }"
                  >
                    <span>SES</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:sns')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'sns' }"
                  >
                    <span>SNS</span>
                  </a>
                </li>
  
                <li *ngIf="checkIfAvaForUser('Governance:eks')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'kube' }"
                  >
                    <span>EKS</span>
                  </a>
                </li>
              </div>
  
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'database')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Database%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Database </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['database']">
                <li *ngIf="checkIfAvaForUser('Governance:dynamodb')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'dynamo' }"
                  >
                    <span>DynamoDB</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:elasticsearch')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'es' }"
                  >
                    <span>Elastic Search</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:rds')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'rds' }"
                  >
                    <span>RDS</span>
                  </a>
                </li>
              </div>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'storage')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Storage%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Storage </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['storage']">
                <li *ngIf="checkIfAvaForUser('Governance:cloudfront')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'cloudfront' }"
                  >
                    <span>Cloudfront</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:ebs')">
                  <a class="dropdown-item" routerLink="/dash/compliance/ebs">
                    <span>EBS</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:redshift')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'redshift' }"
                  >
                    <span>Redshift</span>
                  </a>
                </li>
              </div>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'others')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Others%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Others </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['others']">
                <li *ngIf="checkIfAvaForUser('Governance:cloud trail')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'cloudtrail' }"
                  >
                    <span>Cloudtrail Compliance</span>
                  </a>
                </li>
  
                <li *ngIf="checkIfAvaForUser('Governance:route53')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'route53' }"
                  >
                    <span>Route 53</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>
  
          <li
            class="dropdown"
            *ngIf="
              checkIfAvaForUser('Cost Controls')
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Cost+Optimization.svg"
                class="wid-40"
                alt=""
              />
              Cost Controls
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu min-300"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Cost Controls</h3>
                <hr />
              </li>
              <li
                class="divider padding-0 border-solid"
                (click)="expandDropdown($event, 'expenditure')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Expenditure+and+Usage%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Expenditure and usage </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['expenditure']">
                <li *ngIf="checkIfAvaForUser('Cost Controls:billing daily')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/billing/billing-daily"
                  >
                    <span>Daily Variance Report</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Cost Controls:billing anomaly')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/billing/billing-anomaly"
                  >
                    <span>Cost Anomaly Report</span>
                  </a>
                </li>
              </div>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'optimization')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Optimization </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['optimization']">
                <li *ngIf="checkIfAvaForUser('Cost Controls:ec2')">
                  <a class="dropdown-item" routerLink="/dash/costopt/ec2">
                    <span>EC2</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Cost Controls:ri recommendations')">
                  <a class="dropdown-item" routerLink="/dash/costcontrols/ec2-ri">
                    <span>RI Recommendations</span>
                  </a>
                </li>
                <li
                  *ngIf="checkIfAvaForUser('Cost Controls:reserved instances')"
                >
                  <a
                    class="dropdown-item"
                    routerLink="/dash/cost-controls/reserved-instances"
                  >
                    <span>Reserved Instances</span>
                  </a>
                </li>
                <li
                  *ngIf="checkIfAvaForUser('Cost Controls:RDS RI')"
                >
                  <a
                    class="dropdown-item"
                    routerLink="/dash/cost-controls/rdsri"
                  >
                    <span>RDS RI</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Cost Controls:ebs')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/costoptimization/ebs"
                  >
                    <span>EBS</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Cost Controls:amis')">
                  <a class="dropdown-item" routerLink="/dash/costopt/floating">
                    <span>AMIs and Snapshots</span>
                  </a>
                </li>
                <!-- <li *ngIf="checkIfAvaForUser('CostOptimization:s3')">
                  <a class="dropdown-item" routerLink="/dash/s3/cost-controls">
                    <span>S3</span>
                  </a>
                </li> -->
                <li *ngIf="checkIfAvaForUser('Cost Controls:rds')">
                  <a class="dropdown-item" routerLink="/dash/cost-controls/rds">
                    <span>RDS</span>
                  </a>
                </li>
                <!-- <li *ngIf="checkIfAvaForUser('CostOptimization:cloudwatch')">
                  <a class="dropdown-item" routerLink="/dash/cost-controls/cloudwatch">
                    <span>Cloudwatch</span>
                  </a>
                </li> -->
                <li *ngIf="checkIfAvaForUser('Cost Controls:Advanced Cost Optimisation')">
                  <a class="dropdown-item" [routerLink]="['/dash/security/advanced-security-protocol']" fragment="cost_optimization">
                    <span>Advanced Cost Optimisation</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Cost Controls:other')">
                  <a class="dropdown-item" routerLink="/dash/costopt/other">
                    <span>Other</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>
  
          <li
            class="dropdown"
            *ngIf="
              (checkIfAvaForUser('Compliance') || checkIfAvaForUser('WAFR')) &&
              productType != 'WAFR'
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Security%404x.png"
                alt=""
                class="wid-40"
              />
              Compliance
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Compliance</h3>
                <hr />
              </li>
  
              <!-- <li *ngIf="checkIfAvaForUser('Compliance:ssb')">
                <a class="dropdown-item" routerLink="/dash/compliance/ssb">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Startup+Security+Baseline%404x.png"
                    class="shub"
                    alt=""
                  /><span>Security Baseline</span>
                </a>
              </li> -->
  
              <li *ngIf="checkIfAvaForUser('Compliance:ftr')">
                <a class="dropdown-item" routerLink="/dash/security/ftr">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/document-review+1.svg"
                    class="shub"
                    alt=""
                  /><span>FTR</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Compliance:wafr')">
                <a class="dropdown-item" routerLink="/dash/wafr">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/framework+Blue.svg"
                    class="shub"
                    alt=""
                  /><span>WAFR</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Compliance:rbi compliance')">
                <a class="dropdown-item" routerLink="/dash/rbi-compliance">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    class="shub"
                    alt=""
                  /><span>RBI Compliance</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Compliance:Landing Zone Assessment')">
                <a class="dropdown-item" [routerLink]="['/dash/security/advanced-security-protocol']" fragment="landing_zone">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    class="shub"
                    alt=""
                  /><span>Landing Zone Assessment</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Compliance:EKS Security Best Practices')">
                <a class="dropdown-item" [routerLink]="['/dash/security/advanced-security-protocol']" fragment="eks_security">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    class="shub"
                    alt=""
                  /><span>EKS Security Best Practices</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Compliance:EKS Reliability Best Practices')">
                <a class="dropdown-item" [routerLink]="['/dash/security/advanced-security-protocol']" fragment="eks_reliability">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    class="shub"
                    alt=""
                  /><span>EKS Reliability Best Practices</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li
            class="dropdown"
            *ngIf="
              checkIfAvaForUser('Security')
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/shield-outline+white+1.svg"
                alt=""
                class="wid-40"
              />
              Security
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Security</h3>
                <hr />
              </li>
  
              <li *ngIf="checkIfAvaForUser('Security:iam')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/compliance/findings"
                  [queryParams]="{ audit_type: 'iam' }"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                    class="wid-40"
                    alt=""
                  /><span>IAM</span>
                </a>
              </li>
  
              <li *ngIf="checkIfAvaForUser('Security:iam users')">
                <a class="dropdown-item" routerLink="/dash/compliance/iam/users">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/IAM+Users%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>IAM Users</span>
                </a>
              </li>
  
              <li
                *ngIf="checkIfAvaForUser('Security:iam access keys')"
              >
                <a
                  class="dropdown-item"
                  routerLink="/dash/compliance/iam/age_report"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/IAM+Access+Key+Age+Report%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>IAM Access Key Age Report</span>
                </a>
              </li>
  
              <li *ngIf="checkIfAvaForUser('Security:security groups')">
                <a class="dropdown-item" routerLink="/dash/operations/sg">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg"
                    class="wid-40"
                    alt=""
                  /><span>Security Groups</span>
                </a>
              </li>
  
              <li *ngIf="checkIfAvaForUser('Security:s3')">
                <a class="dropdown-item" routerLink="/dash/compliance/s3">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                    class="iamgrp"
                    alt=""
                  />
                  <span>S3</span>
                </a>
              </li>
  
              <li *ngIf="checkIfAvaForUser('Security:cloud formation')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/compliance/findings"
                  [queryParams]="{ audit_type: 'cft' }"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  />
                  <span>Cloud Formation</span>
                </a>
              </li>
  
              <li *ngIf="checkIfAvaForUser('Security:kms')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/compliance/findings"
                  [queryParams]="{ audit_type: 'kms' }"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  /><span>KMS</span>
                </a>
              </li>
              <!-- <li *ngIf="checkIfAvaForUser('Security:advanced security protocol')">
                <a class="dropdown-item" [routerLink]="['/dash/security/advanced-security-protocol']" fragment="security">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                    class="iamgrp"
                    alt=""
                  />
                  <span>Advanced Security Protocol</span>
                </a>
              </li> -->
              <li *ngIf="checkIfAvaForUser('Security:advanced security protocol')">
                <a class="dropdown-item" [routerLink]="['/dash/security/aws-security-protocol']" fragment="security">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                    class="iamgrp"
                    alt=""
                  />
                  <span>AWS Security Protocol</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li
            class="dropdown"
            *ngIf="
              checkIfAvaForUser('Maintenance')
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Maintance%404x.png"
                alt=""
                class="wid-40"
              />
              Maintenance
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Maintenance</h3>
                <hr />
              </li>
  
              <li *ngIf="checkIfAvaForUser('Maintenance:patch report')">
                <a class="dropdown-item" routerLink="/dash/compliance/patch">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Patch+Report.svg"
                    class="wid-40"
                    alt=""
                  /><span>Patch Report</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Maintenance:securityhub')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/compliance/securityhub"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Security+Hub.svg"
                    class="wid-40"
                    alt=""
                  /><span>Security Hub</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Maintenance:trusted advisor')">
                <a class="dropdown-item" routerLink="/dash/compliance/advisor">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Trusted+Advisor.svg"
                    class="wid-40"
                    alt=""
                  /><span>Trusted Advisor</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Maintenance:inspector')">
                <a class="dropdown-item" routerLink="/dash/compliance/inspector">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Inspector.svg"
                    class="wid-40"
                    alt=""
                  /><span>Inspector</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li class="dropdown" *ngIf="checkIfAvaForUser('Monitoring')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Monitoring.svg"
                alt=""
                class="wid-40"
              />
              Monitoring
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Monitoring</h3>
                <hr />
              </li>
  
              <li *ngIf="checkIfAvaForUser('Monitoring:config')">
                <a class="dropdown-item" routerLink="/dash/compliance/config">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Settings.svg"
                    class="wid-40"
                    alt=""
                  /><span>Config</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Monitoring:tell me')">
                <a class="dropdown-item" routerLink="/dash/tell-me">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Tell+Me+Feature%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>Tell Me</span>
                </a>
              </li>
  
              <li *ngIf="checkIfAvaForUser('Monitoring:cloudwatch')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/compliance/findings"
                  [queryParams]="{ audit_type: 'cloudwatch' }"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  /><span>Cloudwatch</span>
                </a>
              </li>
  
              <li *ngIf="checkIfAvaForUser('Monitoring:ems dashboard')">
                <a
                  routerLink="/dash/monitoring"
                  class="dropdown-item"
                  title="Monitoring"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/EMS+Dashbaord%404x.png"
                    class="wid-40"
                    alt=""
                  />
                  <span> EMS Dashboard </span>
                </a>
              </li>
            </ul>
          </li>
  
          <li class="dropdown" *ngIf="checkIfAvaForUser('Billing')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Billing.svg"
                class="wid-40"
                alt=""
              />
              Billing
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Billing</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('Billing:dashboard')">
                <a class="dropdown-item" routerLink="/dash/billing/dashboard">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                    class="wid-40"
                    alt=""
                  /><span>All Accounts</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Billing:monthly bill')">
                <a class="dropdown-item" routerLink="/dash/billing">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                    class="wid-40"
                    alt=""
                  /><span>Monthly Bill</span>
                </a>
              </li>
              <li
                *ngIf="
                  checkIfAvaForUser('Billing:ri form') &&
                  urlPrefix == 'admin'
                "
              >
                <a class="dropdown-item" routerLink="/dash/billing/ri">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/RI+Order+Report%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>RI Order Report</span>
                </a>
              </li>
              <!-- <li *ngIf="checkIfAvaForUser('Billing:Budgets')">
                      <a class="dropdown-item" routerLink="/dash/billing/budgets">
                        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                          class="wid-40" alt="" /><span>Budgets</span>
                      </a>
                    </li> -->
            </ul>
          </li>
  
          <li
            class="dropdown"
            *ngIf="checkIfAvaForUser('SLAReport') && customerType != 'mp' && whitelabelData['pd']['appName'].toLowerCase() == 'swayam'"
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/SLA+Report.svg"
                class="wid-40"
                alt=""
              />
              SLA Report
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">SLA Report</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:dashboard')">
                <a class="dropdown-item" routerLink="/dash/sla/dashboard">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Dashboard%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>SLA Dashboard</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:created vs resolved')">
                <a class="dropdown-item" routerLink="/dash/sla/createdvsresolved">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Created+vs+Resolved).svg"
                    class="wid-40"
                    alt=""
                  /><span>Created vs Resolved</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:first response sla')">
                <a class="dropdown-item" routerLink="/dash/sla/firstresponsesla">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(First+Response+SLA).svg"
                    class="wid-40"
                    alt=""
                  /><span>First Response SLA</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:resolution sla')">
                <a class="dropdown-item" routerLink="/dash/sla/resolutionsla">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Resolution+SLA).svg"
                    class="wid-40"
                    alt=""
                  /><span>Resolution SLA</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li class="dropdown" *ngIf="checkIfAvaForUser('Reports')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Report.svg"
                class="wid-40"
                alt=""
              />
              Reports
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Reports</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('Reports:view reports')">
                <a class="dropdown-item" routerLink="/dash/reports/view">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/View+Reports%404x.png"
                    class="wid-40"
                    alt=""
                  />
                  <span>View and Download Reports</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Reports:enable reports') && writeAccess">
                <a class="dropdown-item" routerLink="/dash/reports/enable">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Enable+Reports%404x.png"
                    class="wid-40"
                    alt=""
                  />
                  <span>Automated Reports</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li
            class="dropdown"
            *ngIf="checkIfAvaForUser('Automation') && writeAccess"
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Upload+BOM.svg"
                class="wid-40"
                alt=""
              />
              Automation
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Automation</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('Automation:scheduler')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/beta/automation/scheduler"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg"
                    class="wid-40"
                    alt=""
                  /><span>Scheduler</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="dropdown" *ngIf="checkIfAvaForUser('cbs') && cbsAccess">
            <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Anomalies.svg"
                style="width: 40px;" alt=""> CBS
            </a>
            <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
              <li>
                <h3 class="sub-menu-title">CBS</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:riCoverageReport')">
                <a class="dropdown-item" routerLink="/dash/cbs/ricoverage">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>RI Coverage Report</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:riPurchasedReport')">
                <a class="dropdown-item" routerLink="/dash/cbs/ripurchased">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>RI Purchased Report</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:minfyRI')">
                <a class="dropdown-item" routerLink="/dash/cbs/minfyri">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>Minfy RI Account Details</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:organizations')">
                <a class="dropdown-item" routerLink="/dash/cbs/organizations">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>Organizationsan</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:masterSummary')">
                <a class="dropdown-item" routerLink="/dash/cbs/mastersummary">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>Master Summary</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:riOrder')">
                <a class="dropdown-item" routerLink="/dash/cbs/riorder">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>RI Order Report</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:rdsRiOrder')">
                <a class="dropdown-item" routerLink="/dash/cbs/rds/riorder">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>RDS RI Order Report</span>
                </a>
              </li>
    
              <li *ngIf="checkIfAvaForUser('cbs:enablesupportplan')">
                <a class="dropdown-item" routerLink="/dash/cbs/enablesupportplan">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>Enable Support Plan</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:onboardedmaster')">
                <a class="dropdown-item" routerLink="/dash/cbs/onboardedmaster">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>Onboarded Master</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:EnableCFRCReport')">
                <a class="dropdown-item" routerLink="/dash/cbs/cfrc/enable">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>Enable CFRC Report</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:CFRCOrderReport')">
                <a class="dropdown-item" routerLink="/dash/cbs/cfrc/view">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>CFRC Order Report</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:enableMasterAccount')">
                <a class="dropdown-item" routerLink="/dash/cbs/masterenable">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>Enable Master</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('cbs:viewDiscount')">
                <a class="dropdown-item" routerLink="/dash/cbs/discount">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px;" alt=""><span>View Discount</span>
                </a>
              </li>
            </ul>
          </li> -->
          <!-- <li class="dropdown" *ngIf="checkIfAvaForUser('Anomalies') && customerType != 'mp'">
            <a href="#" class="nav-link dropdown-toggle text-truncate" id="dropdown" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Anomalies.svg"
                class="wid-40" alt="" />
              Anomalies
            </a>
            <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
              <li>
                <h3 class="sub-menu-title">Anomalies</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('Anomalies:dashboard')">
                <a class="dropdown-item" routerLink="/dash/anomalies/dashboard">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    class="wid-40" alt="" /><span>Dashboard</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Anomalies:history')">
                <a class="dropdown-item" routerLink="/dash/anomalies/history">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/History.svg"
                    class="wid-40" alt="" /><span> Trigger History </span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Anomalies:expections')">
                <a class="dropdown-item" routerLink="/dash/anomalies/exceptions">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Exceptions.svg"
                    class="wid-40" alt="" /><span>Exceptions</span>
                </a>
              </li>
            </ul>
          </li> -->
          <li class="dropdown" *ngIf="checkIfAvaForUser('BOM') && BOMAccess">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/BOM.svg"
                class="wid-40"
                alt=""
              />
              BOM
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">BOM</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('BOM:uploadBOM') && writeAccess">
                <a class="dropdown-item" routerLink="/dash/bom/upload">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg"
                    class="wid-40"
                    alt=""
                  /><span>Upload BOM</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('BOM:ViewBOMDetails')">
                <a class="dropdown-item" routerLink="/dash/bom/view">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+BOM+Details.svg"
                    class="wid-40"
                    alt=""
                  /><span>View BOM Details</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('BOM:DeltaReport')">
                <a class="dropdown-item" routerLink="/dash/bom/deltareport">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Delta+Report.svg"
                    class="wid-40"
                    alt=""
                  /><span>Delta Report</span>
                </a>
              </li>
  
              <!--<li class="dropdown" *ngIf="checkIfAvaForUser('BOM') && BOMAccess">
            <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
               aria-expanded="false">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/BOM.svg"
                   style="width: 40px;" alt="">
              BOM v2
            </a>
            <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
              <li>
                <h3 class="sub-menu-title">BOM v2</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('BOM:uploadBOM') && writeAccess">
                <a class="dropdown-item" routerLink="/dash/bom/uploadv2">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg"
                       style="width: 40px;" alt=""><span>Upload BOM</span>
                </a>
              </li>
            </ul>
          </li>-->
            </ul>
          </li>
  
          <!-- <li class="dropdown" *ngIf="checkIfAvaForUser('BackupReport')">
            <a href="#" class="nav-link dropdown-toggle text-truncate" id="dropdown" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Backup.svg"
                class="wid-40" alt="" />
              Backup Report
            </a>
            <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
              <li>
                <h3 class="sub-menu-title">Backup Report</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('BackupReport:Snapshots')">
                <a class="dropdown-item" routerLink="/dash/backupreport">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Backup.svg"
                    class="wid-40" alt="" /><span>Snapshots</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('BackupReport:AMIs')">
                <a class="dropdown-item" routerLink="/dash/amis">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Backup.svg"
                    class="wid-40" alt="" /><span>AMIs</span>
                </a>
              </li>
            </ul>
          </li> -->
          <!--<li class="nav-item" *ngIf="checkIfAvaForUser('BackupReport')">
        <a routerLink="/dash/backupreport" class="nav-link text-truncate" title="Backup Report">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Backup.svg"
               style="width: 40px;" alt=""> Backup Report
        </a>
      </li>-->
  
          <li class="dropdown" *ngIf="checkIfAvaForUser('Marketplace')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Report.svg"
                class="wid-40"
                alt=""
              />
              Marketplace
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Marketplace</h3>
                <hr />
              </li>
              <li class="padding-0" (click)="expandDropdown($event, 'site24x7')">
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                      class="wid-40"
                      alt=""
                    />
                    <span> Site24x7 </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['site24x7']">
                <li *ngIf="checkIfAvaForUser('Marketplace:site24X7')">
                  <a class="dropdown-item" routerLink="/dash/site24x7/monitors">
                    <span> Monitors </span>
                  </a>
                </li>
              </div>
            </ul>
          </li>
  
          <li class="dropdown" *ngIf="checkIfAvaForUser('Beta') && betaAccess">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Report.svg"
                class="wid-40"
                alt=""
              />
              Beta
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Beta</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('BetaCostOptimization:tags')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/beta/costoptimization/tags"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  /><span>Tags Cost Controls</span>
                </a>
              </li>
              <li
                *ngIf="
                  checkIfAvaForUser('cloudSupport:ticket:create') && writeAccess
                "
              >
                <a class="dropdown-item" routerLink="/dash/beta/ticket/create">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Create+Ticket.svg"
                    class="wid-40"
                    alt=""
                  /><span>Create Ticket</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('iam:groups') && writeAccess">
                <a class="dropdown-item" routerLink="/dash/beta/iam/groups">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                    class="wid-40"
                    alt=""
                  /><span>IAM Groups</span>
                </a>
              </li>
              <!-- <li *ngIf="checkIfAvaForUser('compliance:s3') && writeAccess">
                <a class="dropdown-item" routerLink="/dash/beta/compliance/s3">
                  <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                    class="iamgrp" alt=""><span>S3 Compliance</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('BetaComplaince:cloudtrail')">
                <a class="dropdown-item" routerLink="/dash/beta/compliance/cloudtrail">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40" alt="" /><span>Cloudtrail Compliance</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('BetaComplaince:cloudwatch')">
                <a class="dropdown-item" routerLink="/dash/beta/compliance/cloudwatch">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40" alt="" /><span>Cloudwatch Compliance</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('BetaComplaince:cloudfront')">
                <a class="dropdown-item" routerLink="/dash/beta/compliance/cloudfront">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40" alt="" /><span>Cloudfront Compliance</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('BetaComplaince:apigateway')">
                <a class="dropdown-item" routerLink="/dash/beta/compliance/api-gateway">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40" alt="" /><span>API Gateway Compliance</span>
                </a>
              </li> -->
            </ul>
          </li>
        
        </ng-container>
  
        <ng-container *ngIf="selectedCloudPartner == 'azure'">
          <li
            class="dropdown"
            *ngIf="
              checkIfAvaForUser('Support')
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Cloud+Support.svg"
                alt=""
                class="wid-40"
              />
              Support
            </a>
            <ul
              class="dropdown-menu text-small shadow"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Support</h3>
                <hr />
              </li>
              <li
                *ngIf="
                  checkIfAvaForUser('Support:create') && writeAccess
                "
              >
                <a class="dropdown-item" routerLink="/dash/ticket/create">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Create+Ticket.svg"
                    class="wid-40"
                    alt=""
                  /><span>Create Ticket</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Support:view tickets')">
                <a class="dropdown-item" routerLink="/dash/ticket/list">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+Ticket.svg"
                    class="wid-40"
                    alt=""
                  /><span>View Tickets</span>
                </a>
              </li>
  
              <li
                *ngIf="
                  checkIfAvaForUser('Support:knowledge base') &&
                  urlPrefix == 'admin'
                "
              >
                <a class="dropdown-item" routerLink="/dash/knowledgebase">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Knowledge+Base.svg"
                    class="wid-40"
                    alt=""
                  /><span>Knowledge Base</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item" *ngIf="checkIfAvaForUser('Dashboard')">
            <a
              routerLink="/dash/home"
              class="nav-link text-truncate"
              title="Feedback"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
                class="monitoringimg"
                alt=""
              />
              Dashboard
            </a>
          </li>
          <li class="dropdown" *ngIf="checkIfAvaForUser('Operations')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Operations+(Manage+Cloud+Resources).svg"
                alt=""
                class="wid-40"
              />
              Operations
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Operations</h3>
                <hr />
              </li>
  
              <li *ngIf="checkIfAvaForUser('Operations:ec2')">
                <a class="dropdown-item" routerLink="/dash/operations/tags">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>VMs</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:disks')">
                <a class="dropdown-item" routerLink="/dash/operations/ebs">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>Disks</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:blobs')">
                <a class="dropdown-item" routerLink="/dash/s3/operations">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  />
                  <span>Blobs</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Operations:databases')">
                <a class="dropdown-item" routerLink="/dash/azure/databases">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>Databases</span>
                </a>
              </li>
              <!-- <li *ngIf="checkIfAvaForUser('Operations:key vault')">
                <a class="dropdown-item" routerLink="/dash/operations/acm">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>Key Vault Certificates</span>
                </a>
              </li> -->
              <li *ngIf="checkIfAvaForUser('Operations:functions')">
                <a class="dropdown-item" routerLink="/dash/azure/functions">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>Functions</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="dropdown" *ngIf="checkIfAvaForUser('Governance')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Operations+(Manage+Cloud+Resources).svg"
                alt=""
                class="wid-40"
              />
              Governance
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Governance</h3>
                <hr />
              </li>
  
              <!-- <li *ngIf="checkIfAvaForUser('Governance:iam')">
                <a class="dropdown-item" routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'iam_audit' }">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg"
                    alt="" class="wid-40" />
                  <span>IAM</span>
                </a>
              </li> -->
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'compute')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compute%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Compute </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['compute']">
                <li *ngIf="checkIfAvaForUser('Governance:vm')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'vm_audit' }"
                  >
                    <span>VM</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:load balancer')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'load_balancer_audit' }"
                  >
                    <span>Load Balancer</span>
                  </a>
                </li>
  
                <li *ngIf="checkIfAvaForUser('Governance:blob')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'blob_audit' }"
                  >
                    <span>Blob</span>
                  </a>
                </li>
  
                <!-- <li *ngIf="checkIfAvaForUser('s3:age_report`') && writeAccess">
                  <a class="dropdown-item" routerLink="/dash/compliance/s3/age_report">
                    <span>S3 Age Report</span>
                  </a>
                </li> -->
  
                <li *ngIf="checkIfAvaForUser('Governance:network')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'network_audit' }"
                  >
                    <span>Network</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:security center audit')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'security_center_audit' }"
                  >
                    <span>Security Center</span>
                  </a>
                </li>
              </div>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'serverless')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span>Serverless</span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['serverless']">
                <!-- <li *ngIf="checkIfAvaForUser('Governance:api gateway')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'apigateway' }"
                  >
                    <span>API Gateway</span>
                  </a>
                </li> -->
                <li *ngIf="checkIfAvaForUser('Governance:app service')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'app_service_audit' }"
                  >
                    <span>App Service</span>
                  </a>
                </li>
                <!-- <li *ngIf="checkIfAvaForUser('Governance:ses')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'ses' }"
                  >
                    <span>SES</span>
                  </a>
                </li> -->
                <!-- <li *ngIf="checkIfAvaForUser('Governance:sns')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'sns' }"
                  >
                    <span>SNS</span>
                  </a>
                </li> -->
  
                <li *ngIf="checkIfAvaForUser('Governance:aks')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'aks_audit' }"
                  >
                    <span>AKS</span>
                  </a>
                </li>
              </div>
  
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'database')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Database%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Database </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['database']">
                <!-- <li *ngIf="checkIfAvaForUser('Governance:dynamodb')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'dynamo' }"
                  >
                    <span>DynamoDB</span>
                  </a>
                </li> -->
                <!-- <li *ngIf="checkIfAvaForUser('Governance:elasticsearch')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'es' }"
                  >
                    <span>Elastic Search</span>
                  </a>
                </li> -->
                <li *ngIf="checkIfAvaForUser('Governance:cosmodb')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'list_all_cosmodb_audit' }"
                  >
                    <span>Cosmo DB</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:sql')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'sql_audit' }"
                  >
                    <span>SQL</span>
                  </a>
                </li>
              </div>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'storage')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Storage%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Storage </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['storage']">
                <li *ngIf="checkIfAvaForUser('Governance:storage')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'storage_audit' }"
                  >
                    <span>Storage</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:ebs')">
                  <a class="dropdown-item" routerLink="/dash/compliance/ebs">
                    <span>Disks</span>
                  </a>
                </li>
              </div>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'others')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Others%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Others </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['others']">
                <li *ngIf="checkIfAvaForUser('Governance:key vault')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'key_vault_audit' }"
                  >
                    <span>Key Vault</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:monitor')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'monitor_audit' }"
                  >
                    <span>Monitor</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Governance:log alert')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'log_alert_audit' }"
                  >
                    <span>Log Alert</span>
                  </a>
                </li>
  
                <li *ngIf="checkIfAvaForUser('Governance:sqnapse')">
                  <a
                    class="dropdown-item"
                    routerLink="/dash/compliance/findings"
                    [queryParams]="{ audit_type: 'synapse_audit' }"
                  >
                    <span>Synapse</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li
            class="dropdown"
            *ngIf="
              checkIfAvaForUser('Cost Controls')
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Cost+Optimization.svg"
                class="wid-40"
                alt=""
              />
              Cost Controls
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu min-300"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Cost Controls</h3>
                <hr />
              </li>
              <li
                class="divider padding-0"
                (click)="expandDropdown($event, 'optimization')"
              >
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png"
                      class="wid-40"
                      alt=""
                    />
                    <span> Optimization </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['optimization']">
                <!-- <li *ngIf="checkIfAvaForUser('Cost Controls:ec2')">
                  <a class="dropdown-item" routerLink="/dash/costopt/ec2">
                    <span>VMs</span>
                  </a>
                </li> -->
                <!-- <li *ngIf="checkIfAvaForUser('Cost Controls:amis')">
                  <a class="dropdown-item" routerLink="/dash/costopt/floating">
                    <span>AMIs and Snapshots</span>
                  </a>
                </li> -->
                <li *ngIf="checkIfAvaForUser('Cost Controls:other')">
                  <a class="dropdown-item" routerLink="/dash/costopt/other" [queryParams]="{page: 'list_unused_vms'}">
                    <span>Unused VMs</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Cost Controls:other')">
                  <a class="dropdown-item" routerLink="/dash/costopt/other" [queryParams]="{page: 'unattached_disks_to_vms'}">
                    <span>Unattached Disks to VM</span>
                  </a>
                </li>
                <li *ngIf="checkIfAvaForUser('Cost Controls:other')">
                  <a class="dropdown-item" routerLink="/dash/costopt/other" [queryParams]="{page: 'list_unused_ips'}">
                    <span>Unused IPs</span>
                  </a>
                </li>
                <!-- <li *ngIf="checkIfAvaForUser('CostOptimization:s3')">
                  <a class="dropdown-item" routerLink="/dash/s3/cost-controls">
                    <span>S3</span>
                  </a>
                </li> -->
                <!-- <li *ngIf="checkIfAvaForUser('CostOptimization:cloudwatch')">
                  <a class="dropdown-item" routerLink="/dash/cost-controls/cloudwatch">
                    <span>Cloudwatch</span>
                  </a>
                </li> -->
                <!-- <li *ngIf="checkIfAvaForUser('Cost Controls:other')">
                  <a class="dropdown-item" routerLink="/dash/costopt/other">
                    <span>Other</span>
                  </a>
                </li> -->
              </div>
            </ul>
          </li>
          <li
            class="dropdown"
            *ngIf="
              checkIfAvaForUser('Security')
            "
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/shield-outline+white+1.svg"
                alt=""
                class="wid-40"
              />
              Security
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Security</h3>
                <hr />
              </li>
  
              <li *ngIf="checkIfAvaForUser('Security:dns')">
                <a
                  class="dropdown-item"
                  routerLink="/dash/compliance/findings"
                  [queryParams]="{ audit_type: 'dns_audit' }"
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                    class="wid-40"
                    alt=""
                  /><span>DNS</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Security:s3')">
                <a class="dropdown-item" routerLink="/dash/compliance/s3">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                    class="iamgrp"
                    alt=""
                  />
                  <span>Blobs</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Security:security groups')">
                <a class="dropdown-item" routerLink="/dash/operations/sg">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg"
                    class="wid-40"
                    alt=""
                  /><span>Security Groups</span>
                </a>
              </li>
            </ul>
          </li>
          <li
            class="dropdown"
            *ngIf="checkIfAvaForUser('SLAReport') && customerType != 'mp' && whitelabelData['pd']['appName'].toLowerCase() == 'swayam'"
          >
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/SLA+Report.svg"
                class="wid-40"
                alt=""
              />
              SLA Report
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">SLA Report</h3>
                <hr />
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:resolutionSLA')">
                <a class="dropdown-item" routerLink="/dash/sla/dashboard">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Dashboard%404x.png"
                    class="wid-40"
                    alt=""
                  /><span>SLA Dashboard</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:createdVsResolved')">
                <a class="dropdown-item" routerLink="/dash/sla/createdvsresolved">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Created+vs+Resolved).svg"
                    class="wid-40"
                    alt=""
                  /><span>Created vs Resolved</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:firstResponseSLA')">
                <a class="dropdown-item" routerLink="/dash/sla/firstresponsesla">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(First+Response+SLA).svg"
                    class="wid-40"
                    alt=""
                  /><span>First Response SLA</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('SLAReport:resolutionSLA')">
                <a class="dropdown-item" routerLink="/dash/sla/resolutionsla">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Resolution+SLA).svg"
                    class="wid-40"
                    alt=""
                  /><span>Resolution SLA</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="dropdown" *ngIf="checkIfAvaForUser('Marketplace')">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Report.svg"
                class="wid-40"
                alt=""
              />
              Marketplace
            </a>
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">Marketplace</h3>
                <hr />
              </li>
              <li class="padding-0" (click)="expandDropdown($event, 'site24x7')">
                <div class="dropdown-item flex-between padding-0">
                  <div class="flex-start">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                      class="wid-40"
                      alt=""
                    />
                    <span> Site24x7 </span>
                  </div>
                  <div>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              <div class="ng-container-main" *ngIf="expanded['site24x7']">
                <li *ngIf="checkIfAvaForUser('Marketplace:site24X7')">
                  <a class="dropdown-item" routerLink="/dash/site24x7/monitors">
                    <span> Monitors </span>
                  </a>
                </li>
              </div>
            </ul>
          </li>
        </ng-container>
  
        <li class="dropdown" *ngIf="checkIfAvaForUser('Settings')">
          <a
            href="#"
            class="nav-link dropdown-toggle text-truncate"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Settings.svg"
              class="wid-40"
              alt=""
            />
            Settings
          </a>
          <ul
            class="dropdown-menu text-small shadow sub-menu"
            aria-labelledby="dropdown"
          >
            <li>
              <h3 class="sub-menu-title">Settings</h3>
              <hr />
            </li>
            <li
              *ngIf="
                (checkIfAvaForUser('Settings:users') &&
                  ut == 'admin') ||
                isUserAdmin
              "
            >
              <a class="dropdown-item" routerLink="/dash/settings/users/view">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User%404x.png"
                  class="wid-40"
                  alt=""
                />
                <span>Users</span>
              </a>
            </li>
            <!-- <li *ngIf="
                checkIfAvaForUser('Settings:operations/ec2groups') &&
                urlPrefix == 'client' &&
                ut == 'admin'
              ">
              <a class="dropdown-item" routerLink="/dash/settings/ec2group/view">
                <i class="fa fa-server"></i><span> EC2 Groups </span>
              </a>
            </li> -->
            <li
              *ngIf="
                checkIfAvaForUser('Settings:policies') &&
                urlPrefix == 'client' &&
                (ut == 'admin' || isUserAdmin)
              "
            >
              <a class="dropdown-item" routerLink="/dash/settings/policies/view">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Policies%404x.png"
                  class="wid-40"
                  alt=""
                />
                <span>Policies</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Settings:audit trail')">
              <a class="dropdown-item" routerLink="/dash/settings/audittrail">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Audit+Trail%404x.png"
                  class="wid-40"
                  alt=""
                />
                <span> Audit Trail </span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Settings:user management')">
              <a class="dropdown-item" routerLink="/dash/settings/profile">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User+Management%404x.png"
                  class="wid-40"
                  alt=""
                /><span>User Management</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" *ngIf="selectedCloudPartner == 'aws'">
          <a routerLink="/dash/green-game" class="nav-link text-truncate" title="GreenGame">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/green_game_light.png"
              class="monitoringimg" alt="" class="wid-40" style="padding: 6px;" />
            EcoCloud Game
          </a>
        </li>
  
        <li class="nav-item">
          <a
            routerLink="/dash/feedback"
            class="nav-link text-truncate"
            title="FeatureXpress"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback white.svg"
              class="monitoringimg"
              alt=""
            />
            FeatureXpress
          </a>
        </li>
      </ul>
    </div>
  </div>
  
  <div class="position-wid-99">
    <div class="row">
      <div class="col pad-right">
        <nav class="navbar navbar-expand-lg top-menu">
          <div class="container-fluid">
            <div class="nav-item wid-175">
              <span class="nav-link text-truncate padd-0">
                <img
                  class="wid-115"
                  [attr.src]="whitelabelData['pd']['logoLight']"
                />
              </span>
            </div>
  
            <div id="btnGroupAddon">
              <span
                class="search-icon-container"
                (click)="showSearchResult = true"
              >
                <i class="icon bi-search" aria-hidden="true"></i>
              </span>
            </div>
  
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">

                <li class="nav-item searchbox min-width-auto gsec searchpointerr hoverable pos_rl" (click)="greengameroute()" *ngIf="selectedCloudPartner == 'aws' && getCurrentAccount()['cft_status']">
                  <div>
                     <img class="wid-85 h43" src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/green_game_light.png" /> 
                  </div>
                  <div *ngIf="!loading['jobs']"
                    class="d-block text-decoration-none color-top progress-layout starsec" id="dropdownGreenGame"
                    data-bs-toggle="dropdown" aria-expanded="true">
                        <span class="odc" *ngFor="let job of jobs">
                          <span span *ngFor="let star of stars; index as i" class="star" [style.color]="getStarColor(i + 1, levelsMap[job.currentLevel], job.currentStatus || '', stars.length)">
                            <i class="fa-solid fa-star"></i>
                          </span>

                        </span>
    
      
                  </div>
                  <div class="flex-align" *ngIf="loading['jobs']"><img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar" />
                  </div>
    
                  <div class="text-small shadow wid-250 pos" 
                    data-popper-placement="bottom-start">
    
                    <div class="dropgreen" *ngFor="let job of jobs">
                      <span  style="font-size:12px;"><strong> Learn, Optimize, Save, Win!</strong></span>
                    <!-- <span style="font-size:12px;">Total Discount Applied: {{job.discountPercentage}}% / {{job.totalDiscount}}%</span> -->

                      <!-- <span *ngIf="acc_cftstatus" style="font-size:12px;"><strong>Total Applied Discount:</strong> {{job.discountPercentage}}% / {{job.totalDiscount}}%</span>
                      <span *ngIf="!acc_cftstatus" style="font-size:12px;"><strong>CFT is not Deployed</strong></span> -->

                    </div>
                  </div>
                </li>


                <li
                  class="nav-item dropdown searchbox"
                  id="accountGroups"
                  *ngIf="
                    accountGroups &&
                    accountGroups.list &&
                    accountGroups.list.length > 0
                  "
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ accountGroups.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i
                            _ngcontent-bob-c49=""
                            aria-hidden="true"
                            class="icon bi-search position-right"
                          ></i>
                          <input
                            type="text"
                            class="form-control radius-top"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="btnGroupAddon"
                            [(ngModel)]="accountGroupSearchTerm"
                          />
                        </div>
                      </div>
                      <content
                        *ngFor="let i of accountGroups.list"
                        [ngClass]="{
                          'active-account': accountGroups.selected == i.groupName
                        }"
                      >
                        <div
                          *ngIf="
                            searchOnList(i.groupName, accountGroupSearchTerm)
                          "
                          (click)="selectAccountGroup(i)"
                        >
                          <span class="dropdown-item">{{ i.groupName }}</span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>
  
                <li
                  class="nav-item dropdown searchbox"
                  id="clientNames"
                  *ngIf="
                    clientNames && clientNames.list && clientNames.list.length > 0
                  "
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ clientNames.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i
                            _ngcontent-bob-c49=""
                            aria-hidden="true"
                            class="icon bi-search position-right"
                          ></i>
                          <input
                            type="text"
                            class="form-control radius-top"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="btnGroupAddon"
                            [(ngModel)]="clientNameSearchTerm"
                          />
                        </div>
                      </div>
                      <content
                        *ngFor="let i of clientNames.list"
                        [ngClass]="{
                          'active-account': clientNames.selected == i.cmpName
                        }"
                      >
                        <div
                          *ngIf="searchOnList(i.cmpName, clientNameSearchTerm)"
                          (click)="selectclientName(i)"
                        >
                          <span class="dropdown-item">{{ i.cmpName }}</span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>
  
                <li
                  class="nav-item dropdown searchbox"
                  id="accountIds"
                  *ngIf="
                    !accountIds || !accountIds.list || accountIds.list.length > 0
                  "
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ accountIds.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i
                            _ngcontent-bob-c49=""
                            aria-hidden="true"
                            class="icon bi-search position-right"
                          ></i>
                          <input
                            type="text"
                            class="form-control radius-top"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="btnGroupAddon"
                            [(ngModel)]="accountIdSearchTerm"
                          />
                        </div>
                      </div>
                      <content
                        *ngFor="let i of accountIds.list"
                        [ngClass]="{
                          'active-account': getSelectedAccount() == i.accountId
                        }"
                      >
                        <div
                          *ngIf="
                            searchOnList(i.accountName, accountIdSearchTerm) &&
                            i['cft_status']
                          "
                          (click)="setAccountList(i['accountId'])"
                        >
                          <span class="dropdown-item flex-between">
                            <span> {{ i.accountName }} ({{ i.accountId }}) </span>
                            <div>
                              <span *ngIf="i.cp == 'aws'">
                                <img
                                  src="./assets/img/aws_logo.png"
                                  class="height-14"
                                  alt=""
                                />
                              </span>
                              <span *ngIf="i.cp != 'aws'">
                                <img
                                  src="./assets/img/azure_logo.png"
                                  class="height-14"
                                  alt=""
                                />
                              </span>
                              <span
                                class="copy-clipboard"
                                (click)="copyToClipboard($event, i.accountId)"
                              >
                                <i class="fa fa-clone"></i>
                              </span>
                            </div>
                          </span>
                        </div>
                        <div
                          *ngIf="
                            searchOnList(i.accountName, accountIdSearchTerm) &&
                            i['cft_status'] == 'loading'
                          "
                          class="display-content"
                          (click)="setAccountList(i['accountId'])"
                        >
                          <span class="dropdown-item flex-between">
                            <span> {{ i.accountName }} ({{ i.accountId }}) </span>
                            <div>
                              <span *ngIf="i.cp == 'aws'">
                                <img
                                  src="./assets/img/aws_logo.png"
                                  style="height: 14px"
                                  alt=""
                                />
                              </span>
                              <span *ngIf="i.cp != 'aws'">
                                <img
                                  src="./assets/img/azure_logo.png"
                                  class="height-14"
                                  alt=""
                                />
                              </span>
                              <span
                                class="copy-clipboard"
                                (click)="copyToClipboard($event, i.accountId)"
                              >
                                <i class="fa fa-clone"></i>
                              </span>
                            </div>
                          </span>
                          <img
                            src="../assets/img/loading_2.svg"
                            alt="loading scans"
                            class="wid-mar"
                          />
                        </div>
                        <div
                          *ngIf="
                            searchOnList(i.accountName, accountIdSearchTerm) &&
                            !i['cft_status']
                          "
                          (click)="setAccountList(i['accountId'])"
                          class="display-content"
                        >
                          <span class="dropdown-item flex-between">
                            <span> {{ i.accountName }} ({{ i.accountId }}) </span>
                            <div>
                              <span *ngIf="i.cp == 'aws'">
                                <img
                                  src="./assets/img/aws_logo.png"
                                  style="height: 14px"
                                  alt=""
                                />
                              </span>
                              <span *ngIf="i.cp != 'aws'">
                                <img
                                  src="./assets/img/azure_logo.png"
                                  class="height-14"
                                  alt=""
                                />
                              </span>
                              <span
                                class="copy-clipboard"
                                (click)="copyToClipboard($event, i.accountId)"
                              >
                                <i class="fa fa-clone"></i>
                              </span>
                            </div>
                          </span>
                          <span class="padding-10">
                            <i
                              class="fa fa-exclamation-triangle color-red"
                              title="Cloud Formation Template is not Configured"
                            ></i>
                          </span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>
  
                <li
                  class="nav-item dropdown searchbox"
                  id="regions"
                  *ngIf="
                    started &&
                    accountIds.list.length > 0 &&
                    selectedCloudPartner == 'aws'
                  "
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ getMiddleString(selectedRegion) }}
                  </a>
                  <div
                    class="dropdown-menu relative"
                    aria-labelledby="navbarDropdown"
                  >
                    <ul>
                      <li class="center" *ngIf="customerType == 'mp'">
                        Selected
                      </li>
                      <li
                        class="disabled border-top"
                        *ngIf="customerType == 'mp'"
                      ></li>
                      <ng-container *ngFor="let continent of continents">
                        <ng-container *ngIf="getRegions(continent).length > 0">
                          <ng-container *ngFor="let i of getRegions(continent)">
                            <li
                              [ngClass]="{
                                'active-account': getSelectedRegion() == i.id
                              }"
                            >
                              <span
                                class="dropdown-item"
                                (click)="setRegion(i, true)"
                                >{{ i.name }}</span
                              >
                            </li>
                          </ng-container>
                          <li
                            class="disabled border-top"
                            *ngIf="getRegions(continent).length > 0"
                          ></li>
                        </ng-container>
                      </ng-container>
                      <li class="center" *ngIf="customerType == 'mp'">
                        AWS Default Regions
                      </li>
                      <li
                        class="disabled border-top"
                        *ngIf="customerType == 'mp'"
                      ></li>
                      <ng-container *ngIf="customerType == 'mp'">
                        <ng-container *ngFor="let continent of continents">
                          <ng-container
                            *ngIf="getRegions(continent, false).length > 0"
                          >
                            <ng-container
                              *ngFor="let i of getRegions(continent, false)"
                            >
                              <li
                                [ngClass]="{
                                  'active-account': getSelectedRegion() == i.id
                                }"
                              >
                                <span
                                  class="dropdown-item"
                                  (click)="setRegion(i, true)"
                                  >{{ i.name }}</span
                                >
                              </li>
                            </ng-container>
                            <li
                              class="disabled border-top"
                              *ngIf="getRegions(continent, false).length > 0"
                            ></li>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <li
                        class="disabled dropdown-item border-bottom center"
                        *ngIf="getDisabledRegions(false).length > 0"
                      >
                        Disabled Regions ({{ getDisabledRegions(false).length }})
                      </li>
                      <ng-container *ngIf="getDisabledRegions(false).length > 0">
                        <li *ngFor="let i of getDisabledRegions(false)">
                          <span class="dropdown-item disabled">{{ i.name }}</span>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </li>
  
                <!-- notification -->
                <li class="nav-item dropdown">
                  <div class="notiBtn" (click)="viewNotification($event)">
                    <i class="fa-solid fa-bell"></i>
                    <span
                      *ngIf="noticount != 0"
                      class="badge badge-pill badge-danger bg-danger"
                      >{{ noticount }}</span
                    >
                    <!-- <span class="badge" >12</span> -->
                  </div>
                </li>
                <!-- notification -->
                <li class="nav-item dropdown searchbox min-width-auto">
                  <a
                    class="d-block text-decoration-none dropdown-toggle color-top"
                    id="dropdownUser1"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <!-- <span class="color-family">{{ username }}</span> -->
                  </a>
                  <ul
                    class="dropdown-menu text-small"
                    aria-labelledby="dropdownUser1"
                    data-popper-placement="bottom-start"
                  >
                    <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                    <li><a class="dropdown-item" href="#">Profile</a></li>-->
                    <li>
                      <span class="color-family text-bold">{{ username }}</span>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <button class="dropdown-item" (click)="logout()">
                        Sign out
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
  
              <div
                class="expiry-indicator float-right"
                *ngIf="
                  expiresIn >= 0 &&
                  customerType == 'mp' &&
                  subType == 'free trail'
                "
              >
                <div class="progress-container">
                  <svg class="progress-circle" width="40px" height="50px">
                    <circle
                      class="progress-circle-back"
                      cx="20"
                      cy="27"
                      r="12"
                    ></circle>
                    <circle
                      class="progress-circle-prog"
                      [ngStyle]="{
                        'stroke-dashoffset': getPercentage(
                          (this.expiresIn / totalPeriod) * 100
                        ),
                        stroke: this.expiresIn <= 3 ? 'red' : '#6872ff'
                      }"
                      cx="20"
                      cy="27"
                      r="12"
                    ></circle>
                  </svg>
                </div>
                <div>
                  <div>
                    <span
                      class="bold-text"
                      [ngStyle]="{
                        color: this.expiresIn <= 3 ? 'red' : '#6872ff'
                      }"
                      >{{ expiresIn }} / {{ totalPeriod }} Days</span
                    >
                  </div>
                  <div class="light-text">
                    {{ plans[subType] || "Free Trial" }}
                  </div>
                </div>
              </div>
  
              <div class="dropdown text-end"></div>
  
              <div class="wid-75"></div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  
  <!-- notification -->
  <app-notification-tab
    *ngIf="viewNotificationTrigger"
    (notiRefresh)="notirefe($event)"
  ></app-notification-tab>
  <!-- notification -->
  
  <div
    class="content min-vh-100 rightcontent"
    [ngStyle]="{
      marginLeft: !started ? '26px' : undefined
    }"
  >
    <ng-container *ngIf="selectedCloudPartner == 'aws'">
      <cft-banner-optional
        [account]="getCurrentAccount()"
        [mandatory]="mandatory || !getCurrentAccount()['cft_status']"
        *ngIf="
          getCurrentAccount() &&
          (getCurrentAccount()['cft_current_version'] <
            getCurrentAccount()['cft_latest_version'] ||
            !getCurrentAccount()['cft_status']) &&
          (getCurrentAccount()['cft_error_show'] == undefined ||
            this.mandatory ||
            !getCurrentAccount()['cft_status'])
        "
      ></cft-banner-optional>
      <ems-banner
        *ngIf="checkForEMS()"
        (hideModel)="enableBU = true"
      ></ems-banner>
      <site24x7-enable-bu
        *ngIf="enableBU"
        (hideModel)="createBUCheck($event)"
      ></site24x7-enable-bu>
    </ng-container>
    <ng-container *ngIf="selectedCloudPartner == 'azure'">
      <div class="azure-resource-groups-container">
        <div class="azure-resource-groups">
          <div class="azure-resource-groups-absolute">
            <div
              *ngFor="let rg of resourceGroups"
              (click)="changeResourceGroup(rg)"
            >
              <div
                class="resource_group"
                *ngIf="rg.selected"
                [ngClass]="{ selected: selectedResourceGroup == rg.name }"
              >
                {{ rg.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="azure-resource-groups-actions">
          <!-- <ng-container *ngIf="checkForWidth()">
            <div><i class="fa fa-angle-left" aria-hidden="true" (click)="moveLeft()"></i></div>
            <div><i class="fa fa-angle-right" aria-hidden="true"></i></div>
          </ng-container> -->
          <div class="more-resource-groups-container">
            <div
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 12px;
                cursor: pointer;
              "
              (click)="showMore = !showMore"
            >
              <span style="font-size: 0.9em">Configure Resource Groups</span>
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </div>
            <div class="more-rgs" *ngIf="showMore">
              <div>
                <input
                  type="text"
                  placeholder="Search..."
                  [(ngModel)]="filterText"
                  class="form-control"
                />
                <div style="max-height: 500px; overflow-y: auto">
                  <div *ngFor="let rg of filteredResourceGroups()">
                    <div class="rg">
                      <div
                        class="text-white-space"
                        (click)="changeResourceGroup(rg)"
                      >
                        {{ rg.name }}
                      </div>
                      <div>
                        <label
                          class="switch"
                          [ngClass]="{
                            disabled: selectedResourceGroup == rg.name
                          }"
                        >
                          <input
                            type="checkbox"
                            (click)="toggleResourceGroup(rg)"
                            [(ngModel)]="rg.selected"
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="whitelabelData && whitelabelData['pd']">
      <router-outlet></router-outlet>
    </ng-container>
    <dash-expired
      *ngIf="showExpired && selectedCloudPartner == 'aws'"
    ></dash-expired>
  </div>
  
  <div class="cft-error-container" *ngIf="currentAccountCFTStatus">
    <div class="popup-container">
      <div class="popup-description">
        CFT not configured for this account. <br />
        Please contact admin
      </div>
      <div class="mt-2">
        <button class="btn btn-light" (click)="currentAccountCFTStatus = false">
          Close
        </button>
      </div>
    </div>
  </div>
  <div class="cft-error-container" *ngIf="checkingCFT">
    <div class="popup-container">
      <div class="popup-description">
        Validating {{ whitelabelData["pd"]["appName"] }} Integration with your
        account...
      </div>
      <div class="mt-2">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="wid-mar"
        />
      </div>
    </div>
  </div>
  
  <app-global-search
    *ngIf="showSearchResult"
    (hideModal)="showSearchResult = false"
  ></app-global-search>
  <!--<cft-viewer></cft-viewer>-->
  <!-- <dash-expired *ngIf="expiresIn && expiresIn == 0"></dash-expired> -->
  <!-- <router-outlet></router-outlet> -->
  
  <!-- Chatbot -->
  <div class="itas-container flex-end-column">
    <div class="itas-component-container">
      <app-itas *ngIf="showITAS"></app-itas>
    </div>
    <div class="flex-center icon-container" (click)="showITAS = !showITAS">
      <i class="fa fa-comments" *ngIf="!showITAS" aria-hidden="true"></i>
      <i class="fa fa-times" *ngIf="showITAS" aria-hidden="true"></i>
    </div>
  </div>
  