<div class="screen">
  <div class="popup-container">
    <div class="popup-header">
      <span class="color-inherit">{{ instance ? 'Install' : 'Add' }} Monitor {{ instance ? '(' + instance['tagName'] +
        ')' : '' }}</span>
    </div>
    <div class="popup-body" *ngIf="!instance">
      <div [ngStyle]="{display: stepCount == 0 ? 'block' : 'none'}">
        <div class="card">
          <div class="card-header">
            Step 1: Select Instance
          </div>
          <div class="card-body">
            <div class="flex-row sticky" *ngIf="selectedCloudPartner == 'aws'">
              <div class="dropdown">
                <button class="btn btn-light dropdown-toggle" (click)="showDropdown = !showDropdown" type="button"
                  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ filterType || 'Filter' }}
                </button>

              </div>
              <div class="dropdown-menu" [ngClass]="{show: showDropdown}" aria-labelledby="dropdownMenuButton">
                <div class="dropdown-item" (click)="setFilterType('Instance Id')">Instance ID</div>
                <div class="dropdown-item" (click)="setFilterType('Name')">Name</div>
                <div class="dropdown-item" (click)="setFilterType('Instance Type')">Instance Type</div>
                <div class="dropdown-divider"></div>
                <div class="dropdown-item" (click)="setFilterType('Tags')">Tags</div>
                <div class="dropdown-divider"></div>
                <div class="dropdown-item" (click)="setFilterType('Private IP')">Private IP</div>
                <div class="dropdown-item" (click)="setFilterType('Public  IP')">Public IP</div>
                <div class="dropdown-divider"></div>
                <div class="dropdown-item" (click)="setFilterType('Instance Status')">Instance Status</div>
              </div>
              <div class="flex-1">
                <input type="text" class="form-control padding-6-12" placeholder="Search..." [(ngModel)]="searchText">
              </div>
              <div>
                <button class="btn btn-light" (click)="loadInstances()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <div>
                <button class="btn btn-light" (click)="resetSearch()">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
            <table class="table table-docs">
              <thead>
                <tr>
                  <th></th>
                  <th>Tag Name</th>
                  <th>Instance Id</th>
                  <th>Instance Type</th>
                  <th>Instance Status</th>
                  <th>EMS Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let instance of instancesList"
                  [ngClass]="{disabled: instance['emsStatus'] == 1 || instance['pending'] || instance['repeat_name'], hoverable: !instance['pending'] && instance['emsStatus'] == -1 && !instance['repeat_name']}"
                  (click)="selectInstance(instance)">
                  <td>
                    <input type="checkbox" class="pointer-events-none" [checked]="instance['selected']">
                  </td>
                  <td>
                    {{ instance['display_name'] }}
                  </td>
                  <td>
                    {{ instance['InstanceId'] }}
                  </td>
                  <td>
                    {{ instance['InstanceType'] }}
                  </td>
                  <td>
                    {{ instance['State']['Name'] }}
                  </td>
                  <td>
                    <div class="flex-start">
                      <ng-container *ngIf="!instance['pending']">
                        <div *ngIf="instance['emsStatus'] == 1">
                          Configured
                        </div>
                        <div *ngIf="instance['emsStatus'] == -1">
                          Not Configured
                        </div>
                      </ng-container>
                      <ng-container *ngIf="instance['pending']">
                        <div>
                          Pending Installation
                        </div>
                      </ng-container>
                      <ng-container *ngIf="instance['repeat_name']">
                        <div>
                          MONITOR_NAME_ISSUE
                        </div>
                      </ng-container>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!loading && instancesList.length == 0 && searchText == ''">
                <tr>
                  <td colspan="6" class="center">
                    No Instances Found
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!loading && instancesList.length == 0 && searchText != ''">
                <tr>
                  <td colspan="6" class="center">
                    No Search Results
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="loading">
                <tr>
                  <td colspan="6" class="center">
                    Loading...
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <ng-container *ngIf="stepCount == 1">
        <div class="card">
          <div class="card-header">
            Step 2: Add Monitor
          </div>
          <div class="card-body">
            <div class="tabs flex-start">
              <div class="tab" (click)="selectedPlatform = platform"
                [ngClass]="{ active: platform == selectedPlatform }" *ngFor="let platform of platforms">
                {{ platform }}
              </div>
            </div>
            <ng-container *ngFor="let platform of platforms">
              <div class="platform-details" *ngIf="platform == selectedPlatform">
                <ol>
                  <li *ngFor="let step of commands[platform]; let i = index" class="mb-2">
                    <div *ngIf="step['title']">{{ step["title"] }}</div>
                    <div *ngIf="step['command']">
                      <pre><div class="flex-start align-top" *ngFor="let cmd of step['command']; let j = index" [ngClass]="{copied: copied}" (click)="copyCommand(rawCommands[platform][i]['command'][j].toString())"><span>$</span><span class="relative">{{ cmd.toString() }} <span>
                              <i class="fa fa-clone"></i>
                            </span> </span></div></pre>
                      <div class="info-message">
                        *Keep the script unchanged after you copy it. It has auto generated parameters that make it
                        work
                        properly. If you
                        change
                        the parameters, you may end up paying for the whole month instead of by the EC2 running state
                        hour.
                      </div>
                    </div>
                    <ng-container *ngIf="step['link']">
                      <a *ngIf="step['link']['type'] == 'download-button'" class="btn {{ step['link']['class'] }}"
                        target="_blank" href="{{ step['link']['link'] }}">{{ step["link"]["text"] }}</a>
                    </ng-container>
                    <ng-container *ngIf="step['or']">
                      <div class="mt-2 mb-2">-or-</div>
                      <div *ngIf="step['or']['title']">{{ step["or"]["title"] }}</div>
                      <div *ngIf="step['or']['command']">
                        <pre><div class="flex-start align-top" *ngFor="let cmd of step['or']['command']; let j = index" [ngClass]="{copied: copied}" (click)="copyCommand(rawCommands[platform][i]['or']['command'][j].toString())"><span>$</span><span class="relative">{{ cmd.toString() }}
                                  <i class="fa fa-clone" ></i> 
                                </span></div></pre>

                        <div class="info-message">
                          *Keep the script unchanged after you copy it. It has auto generated parameters that make
                          it
                          work
                          properly. If you
                          change
                          the parameters, you may end up paying for the whole month instead of by the EC2 running
                          state
                          hour.
                        </div>
                      </div>
                      <ng-container *ngIf="step['or']['link']">
                        <a *ngIf="step['or']['link']['type'] == 'download-button'"
                          class="btn {{ step['or']['link']['class'] }}" target="_blank"
                          href="{{ step['or']['link']['link'] }}">{{ step["or"]["link"]["text"] }}</a>
                      </ng-container>
                    </ng-container>
                  </li>
                </ol>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="stepCount == 2">
        Once the installation is completed, go to MarketPlace > Monitors to view your monitor
      </ng-container>
    </div>
    <div class="popup-body" *ngIf="instance">
      <div class="tabs flex-start">
        <div class="tab" (click)="selectedPlatform = platform" [ngClass]="{ active: platform == selectedPlatform }"
          *ngFor="let platform of platforms">
          {{ platform }}
        </div>
      </div>
      <ng-container *ngFor="let platform of platforms">
        <div class="platform-details" *ngIf="platform == selectedPlatform">
          <ol>
            <li *ngFor="let step of commands[platform]; let i = index" class="mb-2">
              <div *ngIf="step['title']">{{ step["title"] }}</div>
              <div *ngIf="step['command']">
                <pre><div class="flex-start align-top" *ngFor="let cmd of step['command']; let j = index" [ngClass]="{copied: copied}" (click)="copyCommand(rawCommands[platform][i]['command'][j].toString())"><span>$</span><span class="relative">{{ cmd.toString() }} <span>
                  <i class="fa fa-clone"></i>
                </span> </span></div></pre>
                <div class="info-message">
                  *Keep the script unchanged after you copy it. It has auto generated parameters that make it work
                  properly. If you
                  change
                  the parameters, you may end up paying for the whole month instead of by the EC2 running state hour.
                </div>
              </div>
              <ng-container *ngIf="step['link']">
                <a *ngIf="step['link']['type'] == 'download-button'" class="btn {{ step['link']['class'] }}"
                  target="_blank" href="{{ step['link']['link'] }}">{{ step["link"]["text"] }}</a>
              </ng-container>
              <ng-container *ngIf="step['or']">
                <div class="mt-2 mb-2">-or-</div>
                <div *ngIf="step['or']['title']">{{ step["or"]["title"] }}</div>
                <div *ngIf="step['or']['command']">
                  <pre><div class="flex-start align-top" *ngFor="let cmd of step['or']['command']; let j = index" [ngClass]="{copied: copied}" (click)="copyCommand(rawCommands[platform][i]['or']['command'][j].toString())"><span>$</span><span class="relative">{{ cmd.toString() }}
                      <i class="fa fa-clone" ></i> 
                    </span></div></pre>
                  <div class="info-message">
                    *Keep the script unchanged after you copy it. It has auto generated parameters that make it
                    work
                    properly. If you change
                    the parameters, you may end up paying for the whole month instead of by the EC2 running state
                    hour.
                  </div>

                </div>
                <ng-container *ngIf="step['or']['link']">
                  <a *ngIf="step['or']['link']['type'] == 'download-button'"
                    class="btn {{ step['or']['link']['class'] }}" target="_blank"
                    href="{{ step['or']['link']['link'] }}">{{ step["or"]["link"]["text"] }}</a>
                </ng-container>
              </ng-container>
            </li>
          </ol>
        </div>
      </ng-container>
    </div>
    <div class="popup-actions flex-end">
      <button class="btn btn-primary-soft" (click)="stepCount = stepCount - 1"
        *ngIf="getSelectedInstances().length && stepCount > 0 && !instance">Prev</button>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
      <ng-container *ngIf="!loading">
        <button class="btn btn-primary-soft" (click)="savePending()"
          *ngIf="getSelectedInstances().length && stepCount < 2 && !instance">Save and Next</button>
        <button class="btn btn-primary-soft" (click)="stepCount = stepCount + 1"
          *ngIf="getSelectedInstances().length && stepCount < 2 && instance">Next</button>
      </ng-container>
      <ng-container *ngIf="loading">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-20px" />
      </ng-container>
    </div>
  </div>
</div>