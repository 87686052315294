import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.css"],
})
export class InfoComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  @Input("steps") steps: any;
  step: number = 0;

  constructor() {}

  appendStep() {
    let index = this.steps.findIndex((step: any) => {
      return step == this.step + 1;
    });
    this.step = this.steps[index];
  }

  removeStep() {
    let index = this.steps.findIndex((step: any) => {
      return step == this.step - 1;
    });
    this.step = this.steps[index];
  }

  getMarginLeft() {
    let element = document.querySelector(".info-space .steps-container");
    let index = this.steps.findIndex((step: any) => {
      return step == this.step;
    });
    return `${index * element.getBoundingClientRect().width * -2}px`;
  }

  setStep(index: number) {
    this.step = index;
  }

  checkForStep(step: any) {
    return this.steps.indexOf(step) > -1;
  }

  ngOnInit(): void {
    this.step = this.steps[0];
  }
}
