import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { APIService } from "../../../../api/api.service";
import { NotifierService } from "../../../../_services/notifier.service";
import { regions } from "src/app/dash/dash.component";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";

declare let $: any;

@Component({
  selector: "update-policy",
  templateUrl: "./update.policies.component.html",
  styleUrls: [
    "./../../ec2-group/components/ec2-group.component.css",
    "./update.policies.component.css",
  ],
})
export class UpdatePoliciesComponent implements OnInit, OnDestroy {
  @Input("group") policy: any;
  @Input("action") action: any;
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin" ||
    this.isUserAdmin;
  loading = true;
  accountId: any;
  instances: any = [];
  @Input("services") services: any;
  tagKeyValuesObj: any = {};
  regions: any = regions;
  tagsEnabled: boolean = false;
  accountDetailsList: any = [];
  accounts: any = [];

  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.policy = { ...this.policy };
    this.tagsEnabled = this.policy.tagsEnabled;
    if (
      this.policy.tagsEnabled &&
      this.policy.tagsList &&
      Object.keys(this.policy.tagsList).length > 0
    ) {
      this.tagKeyValuesObj = JSON.parse(JSON.stringify(this.policy.tagsList));
      this.tagsState = JSON.parse(JSON.stringify(this.policy.tagsList));
      Object.keys(this.tagKeyValuesObj).forEach((account: string) => {
        Object.keys(this.tagKeyValuesObj[account]).forEach((region: string) => {
          this.loadTags({ accountId: account }, region);
          Object.keys(this.tagKeyValuesObj[account][region]).forEach(
            async (key: string) => {
              await this.loadValues(account, region, key);
              await this.fetchAccountGroups();
              setTimeout(() => {
                document
                  .querySelectorAll(".single-select")
                  .forEach((element: any) => {
                    $(element).selectpicker("refresh");
                  });
              }, 100);
            }
          );
        });
      });
    }
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.load();
        this.accountId = d.value;
      } else if (d.key == "regionId") {
        this.load();
        this.regionId = d.value;
      }
    });
  }

  async load() {
    await this.loadServices();
  }

  errors: any = {
    policyName: {
      required: true,
      error: false,
      msg: "Please give a name to the policy",
    },
    accountId: {
      requried: true,
      error: false,
      msg: "Please select at least one Account",
    },
  };
  validate() {
    let check = true;
    if (this.policy.policyName.trim() == "") {
      this.errors["policyName"]["error"] = true;
      check = false;
    } else {
      this.errors["policyName"]["error"] = false;
    }

    if ($("#instancesList").val().length == 0) {
      this.errors["accountId"]["error"] = true;
      check = false;
    } else {
      this.errors["accountId"]["error"] = false;
    }

    return check;
  }

  getSelectedMenusLength(menu: any) {
    return menu["submenus"]
      ? menu["submenus"].filter((menu_: any) => {
          return menu_["selected"];
        }).length
      : 0;
  }

  async updateEntry() {
    if (!this.validate()) {
      return;
    }
    if (this.action == "local") {
      this.close(true);
      return;
    }

    const selectedAccounts = $("#instancesList").val();
    this.notifier.loading(true);
    let menuList: any = {};
    this.services.forEach((menu: any) => {
      if (menu["selected"] || this.getSelectedMenusLength(menu) > 0) {
        menuList[menu["label"]] = [];
        menuList[menu["label"]] = menu["submenus"]
          ? menu["submenus"]
              .filter((menu_: any) => {
                return menu_["selected"] || menu["selected"];
              })
              .map((menu_: any) => {
                return menu_["label"];
              })
          : [];
      }
    });
    let data = {
      clientID: this.isUserAdmin
        ? localStorage.getItem("masterId")
        : this.userId,
      policyName: this.policy.policyName,
      action: this.action,
      mlist: menuList,
      menuList: this.policy.menuList,
      accessType: this.policy.accessType,
      tagsList: this.tagsEnabled ? this.tagKeyValuesObj : undefined,
      tagsEnabled: this.tagsEnabled,
      accountDetailsList: this.accounts
        .filter((account: any) => {
          return selectedAccounts.indexOf(account.accountId) > -1;
        })
        .map((account: any) => {
          return {
            accountId: account.accountId,
            accountName: account.accountName,
          };
        }),
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/policydetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
      if (this.action == "add") {
        this.close();
      } else {
        this.close(true);
      }
    } else {
      this.notifier.alert("Info", "", result.msg || result.error, "info", 5000);
    }
    this.notifier.loading(false);
  }

  async fetchAccountGroups() {
    // this.notifier.loading(true);

    let accountIdList = $("#instancesList")
      .val()
      .map((instance: any) => {
        if (!this.tagsState[instance]) {
          this.tagsState[instance] = {};
          this.tagKeyValuesObj[instance] = {};
        }
        return { accountId: instance };
      });
    this.accountDetailsList = accountIdList;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  selectAllValues(event: any, accountId: string, region: string, key: string) {
    if (event.target.checked) {
      this.tagKeyValuesObj[accountId][region][key] = this.tagsState[accountId][
        region
      ][key].map((value: any) => {
        return value.id;
      });
    } else {
      this.tagKeyValuesObj[accountId][region][key] = [];
    }
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.policy["menuList"] = $("#servicesList").val();
      this.hideModel.emit(this.policy);
    } else {
      this.hideModel.emit(false);
    }
  }

  checkOfServices() {
    const fixedValues = ["Support", "Settings"];
    fixedValues.forEach((element: any) => {
      const selectedValues = $("#servicesList").val();
      if (selectedValues.indexOf(element) < 0) {
        this.notifier.alert(
          "Info",
          "",
          `${element} is a Mandatory Service`,
          "info",
          5000
        );
        $("#servicesList").val([...selectedValues, element]);
        $("#servicesList").selectpicker("refresh");
      }
    });
  }

  async loadServices(nextToken?: any) {
    this.services = Object.keys(
      JSON.parse(localStorage.getItem("menuList"))
    ).map((menu: any) => {
      return {
        label: menu,
        selected: false,
        expanded: false,
        submenus: JSON.parse(localStorage.getItem("menuList"))[menu].map(
          (menu_: any) => {
            return {
              label: menu_,
              selected: false,
            };
          }
        ),
      };
    })
    let accounts: any = localStorage.getItem("accountIdData");

    if (accounts) {
      let accountsObj: any = JSON.parse(accounts);
      if (accountsObj["list"].length > 0) {
        this.accounts = accountsObj["list"];

        setTimeout(() => {
          $("#instancesList").selectpicker("refresh");
          $("#instancesList").val([
            ...this.policy.accountDetailsList.map((account: any) => {
              return account.accountId;
            }),
          ]);

          $("#instancesList").selectpicker("refresh");
          this.fetchAccountGroups();
        }, 100);
      }
    }
    // this.notifier.loading(true);
    // let data = {
    //   emailId: this.userId,
    //   clientName: localStorage.getItem("un"),
    // };

    // let header = {
    //   Authorization: localStorage.getItem("t"),
    // };
    // //https://api.swayam.cloud/v3/admin/support
    // let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccounts`;

    // let result = await this.apiService.postDataPromis(apiURL, data, header);

    // if (result.status == "1" || result.s == "1") {
    //   this.services = result.menuList;
    //   this.accounts = result.list[0]["otherdetail"];

    //   setTimeout(() => {
    //     $("#instancesList").selectpicker("refresh");
    //     $("#instancesList").selectpicker(
    //       "val",
    //       Object.keys(this.tagKeyValuesObj)
    //     );
    //   }, 100);
    // } else {
    //   this.notifier.alert("Info", "", result.error, "info", 5000);
    // }
    // this.notifier.loading(false);
  }

  tagsState: any = {};

  getObjectKeys(object: Object): string[] {
    return object ? Object.keys(object) : [];
  }

  loadingTags: boolean = false;
  destroyed: boolean = false;
  async loadTags(account: any, regionId: string, nextToken: any = null) {
    // this.notifier.loading(true);
    this.loadingTags = true;
    if (!nextToken) {
      this.tagsState[account["accountId"]][regionId] = {};
    }
    let data = {
      action: "listEc2Tags",
      accountId: account["accountId"],
      region: regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    this.loadingTags = false;

    if (this.destroyed) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      if (result.tagKeyList) {
        result.tagKeyList.forEach((key: string) => {
          this.tagsState[account["accountId"]][regionId][key] = [];
        });
      }
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    if (result.nextToken) {
      await this.loadTags(account, regionId, result.nextToken);
    }
    // this.notifier.loading(false);
  }

  async loadValues(accountId: string, regionId: string, key: string) {
    this.loadingTags = true;
    // this.notifier.loading(true);
    let data = {
      action: "listEc2TagsValues",
      accountId: accountId,
      region: regionId,
      tagKey: key,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return false;
    }

    if (result.status == "1" || result.s == "1") {
      this.tagsState[accountId][regionId][key] = result["tagKeyList"].map(
        (value: any) => {
          return { id: value };
        }
      );
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    this.loadingTags = false;
    return true;

    // this.notifier.loading(false);
  }

  setValues(account: any) {
    let values: string[] = $("#tagValuesFilter").val();
    if (
      (!values || values.length == 0) &&
      this.tagKeyValuesObj[account["currentTag"]]
    ) {
      delete this.tagKeyValuesObj[account["currentTag"]];
    }
    this.tagKeyValuesObj[account["currentTag"]] = values;
    setTimeout(() => {
      $("#tagKeysFilter").selectpicker("refresh");
    }, 100);
  }

  getValues(key: string) {
    return this.tagKeyValuesObj[key] || [];
  }

  keysToSelect(accountId: string, regionId: string) {
    return Object.keys(this.tagsState[accountId][regionId]).filter(
      (x) => !Object.keys(this.tagKeyValuesObj[accountId][regionId]).includes(x)
    );
  }

  newKeySet(
    oldKey: string = null,
    newKeyEvent: any,
    accountId: string,
    regionId: string
  ) {
    if (oldKey) {
      Object.defineProperty(
        this.tagKeyValuesObj[accountId][regionId],
        newKeyEvent.target.value,
        Object.getOwnPropertyDescriptor(
          this.tagKeyValuesObj[accountId][regionId],
          oldKey
        )
      );
      delete this.tagKeyValuesObj[accountId][regionId][oldKey];
      this.loadValues(accountId, regionId, newKeyEvent.target.value);
    } else if (newKeyEvent == "FETCH") {
      let newKeyEvent = this.keysToSelect(accountId, regionId)[0];
      this.tagKeyValuesObj[accountId][regionId][newKeyEvent] = [];
      this.loadValues(accountId, regionId, newKeyEvent);
    }
    setTimeout(() => {
      document.querySelectorAll(".single-select").forEach((element: any) => {
        $(element).selectpicker("refresh");
      });
    }, 100);
  }

  resetValues(account: any) {
    if (this.tagKeyValuesObj[account["currentTag"]]) {
      delete this.tagKeyValuesObj[account["currentTag"]];
      $("#tagValuesFilter").val("");
      $("#tagValuesFilter").selectpicker("refresh");
      setTimeout(() => {
        $("#tagKeysFilter").selectpicker("refresh");
      }, 100);
    }
  }

  getUnselectedRegion(accountId: string) {
    if (!this.tagKeyValuesObj[accountId]) {
      return;
    }
    return this.regions["enabled"].filter((region: any) => {
      return (
        Object.keys(this.tagKeyValuesObj[accountId]).indexOf(region.id) < 0
      );
    });
  }

  removeKeySet(accountId: string, regionId: string, key: string) {
    delete this.tagKeyValuesObj[accountId][regionId][key];
  }

  addTagRegion(account: any, accountId: string, event: any): void {
    if (event.target.value == "") {
      return;
    }
    account[event.target.value] = {};
    this.tagKeyValuesObj[accountId][event.target.value] = {};
    this.loadTags({ accountId: accountId }, event.target.value);
  }

  removeTagRegion(account: any, regionId: string): void {
    try {
      delete account[regionId];
    } catch (error) {
      account[regionId] = undefined;
    }
  }
}
