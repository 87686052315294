<ng-container *ngIf="!notValid">
  <ng-container *ngIf="selectedScan">
    <div class="card">
      <div class="card-header flex-between">
        <div class="flex-start">
          <button class="btn btn-light flex-start" (click)="selectedScan = null">
            <i class="fa fa-angle-left"></i>
            <span>Back</span>
          </button>
  
          <h5 class="padding-0">{{ getFinding()['title'] }} Compliance > {{selectedScan['scanId']}}</h5>
        </div>
        <div *ngIf="scanDetails" class="flex-start">
          <span class="border-right">
            <div class="text-bold">
              Scanned on
            </div>
            <div>
              {{ scanDetails["startedAt"] }}
            </div>
          </span>
          <span>
            <div class="text-bold">
              Status
            </div>
            <div>
              {{ scanDetails["scanStatus"] || scanDetails["status"] }}
            </div>
          </span>
          <button class="btn btn-success-soft float-margin-10" *ngIf="sortedIAMCheckList.length > 0"
            (click)="downloadReport()"><i class="fa fa-download" aria-hidden="true"></i>
            Download</button>
          <button class="btn btn-primary-soft float-margin-10" (click)="loadScan(selectedScan, true, uuid)">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            Refresh
          </button>
        </div>
      </div>
    </div>
    <div class='card'>
      <div class="card-body padding-10">
        <div class="discovery-section-container">
          <div class="item-container pointer" (click)="removeFilter(true)">
            <div class="item-highlight flex-start">
              <span>
                {{ overview['total'] }}
              </span>
              <img *ngIf="fetching" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
            </div>
            <div class="item-desc">Total Checks</div>
          </div>
          <div class="item-container pointer" (click)="showResources = true">
            <div class="item-highlight flex-start">
              {{ resources.length }}
            </div>
            <div class="item-desc">Resources Under Threat</div>
          </div>
          <div class="item-container pointer" (click)="addFilter('Passed', true)">
            <div class="item-highlight" [ngClass]="{ 'light-green': overview['passed'] > 0 }">
              {{ overview['passed'] }}
            </div>
            <div class="item-desc">Passed Checks</div>
          </div>
          <div class="item-container pointer" (click)="addFilter('Failed', true)">
            <div class="item-highlight" [ngClass]="{ 'failed-red': overview['failed'] > 0 }">
              {{ overview['failed'] }}
            </div>
            <div class="item-desc">Failed Checks</div>
          </div>
          <div class="item-container pointer" (click)="
                      addFilter('High Risk', true)
                    ">
            <div class="item-highlight" [ngClass]="{ 'failed-red': overview['failed_high_risk'] > 0 }">
              {{ overview['failed_high_risk'] }}
            </div>
            <div class="item-desc">High Risk Failed</div>
          </div>
          <div class="item-container pointer" (click)="addFilter('Medium Risk', true)">
            <div class="item-highlight" [ngClass]="{ 'failed-medium': overview['failed_medium_risk'] > 0 }">
              {{ overview['failed_medium_risk'] }}
            </div>
            <div class="item-desc">Medium Risk Failed</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="flex-end">
          <button class="btn btn-light filter_click dropdown-toggle">
            <i class="fa fa-filter" aria-hidden="true"></i>
            <div class="font-9">
              <div>
                {{ filter }}
              </div>
            </div>
            <div class="filter_dropdown">
              <div class="filter-item" (click)="addFilter('All')">
                <div class="filter-checkbox" [ngClass]="{checked: filter == 'All'}">
  
                </div>
                <div>
                  All
                </div>
              </div>
              <div class="filter-item" (click)="addFilter('Passed')">
                <div class="filter-checkbox" [ngClass]="{checked: filter == 'Passed'}">
  
                </div>
                <div>
                  Passed
                </div>
              </div>
              <div class="filter-item" (click)="addFilter('Failed')">
                <div class="filter-checkbox" [ngClass]="{checked: filter == 'Failed'}">
  
                </div>
                <div>
                  Failed
                </div>
              </div>
              <div class="filter-item" (click)="addFilter('High Risk')">
                <div class="filter-checkbox" [ngClass]="{checked: filter == 'High Risk'}">
  
                </div>
                <div>
                  High Risk
                </div>
              </div>
              <div class="filter-item" (click)="addFilter('Medium Risk')">
                <div class="filter-checkbox" [ngClass]="{checked: filter == 'Medium Risk'}">
  
                </div>
                <div>
                  Medium Risk
                </div>
              </div>
            </div>
          </button>
          <div class="dropdown">
            <button id="resourceDropdown" role="button" data-bs-toggle="dropdown" title="{{ resourceSort }}"
              class="btn btn-light filter_click dropdown-toggle">
              <i class="fa fa-filter" aria-hidden="true"></i>
              <div class="font-9">
                <div>
                  <span class="light-text">
                    Resource:
                  </span>
                  <span>
                    {{ resourceSort }}
                  </span>
                </div>
              </div>
            </button>
            <div class="filter_dropdown dropdown-menu" aria-labelledby="resourceDropdown">
              <input type="text" class="form-control" placeholder="Search" [(ngModel)]="resourceText"
                (click)="preventDefault($event)">
              <div class="filter-item" (click)="closeResources('All')" *ngIf="resourceText == ''">
                <div class="filter-checkbox" [ngClass]="{checked: resourceSort == 'All'}">
  
                </div>
                <div class="white-space-none">
                  All
                </div>
              </div>
              <div class="filter-item" (click)="closeResources(resource.name)" *ngFor="let resource of getResources()">
                <div class="filter-checkbox" [ngClass]="{checked: resourceSort == resource.name}">
  
                </div>
                <div class="white-space-none" title="{{ resource.name }}">
                  {{ resource.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of headers">
                {{h.name}}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,sortedIAMCheckList)">
  
                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>
  
                <span *ngIf="h.filter " class="inputseach">
                  <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
              <th>Offenders</th>
            </tr>
          </thead>
  
          <ng-container *ngIf="(scanDetails && scanDetails['resource_found']) || fetching">
            <tbody [ngStyle]="{'display': sortedIAMCheckList.length > 0 ? 'table-row-group': 'none'}"
              *ngFor="let i of sortedIAMCheckList; let j = index">
              <tr *ngIf="notifier.filterTableColums(headers,i)">
                <td>{{j+1}}</td>
                <td *ngFor="let h of headers">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span (click)="callFunction(h.click,i)">
                      {{i[h.id]}}
                    </span>
                  </span>
                  <span *ngIf="!h.click">
                    <span *ngIf="h.color && !h.alter">
                      <span *ngIf="i[h.id] == 'true'" class="flex-center-gap-8">
                        <span><i class="fa fa-check-circle color-green" aria-hidden="true"></i></span>
                        <span class="color-green">Pass</span>
                      </span>
                      <span *ngIf="i[h.id] == 'false'" class="color-item-gap-8">
                        <span><i class="fa fa-times-circle color-red" aria-hidden="true"></i></span>
                        <span class="color-red">Fail</span>
                      </span>
                      <span *ngIf="i[h.id] == 'Manual' || i[h.id] == 'manual'" class="flex-center-gap-8">
                        <span><i class="fa fa-times-circle color-red" aria-hidden="true"></i></span>
                        <span class="color-red">Manual</span>
                      </span>
                      <span *ngIf="i[h.id] == ''">
                        <span>-</span>
                      </span>
                    </span>
                    <span *ngIf="!h.color && !h.alter">
                      {{checkforBool(i[h.id], h.id) || '-'}}
                    </span>
                    <span *ngIf="h.alter">
                      <span *ngIf="i[h.id] == 'Low'" class="flex-center-gap-8">
                        <span class="width-height-radius"></span>
                        <span color-163 class="text-wrap-none">{{i[h.id]}}</span>
                      </span>
                      <span *ngIf="i[h.id] == 'Medium'" class="color-direction-item">
                        <span class="width-height-orange"></span>
                        <span class="color-orange text-wrap-none">{{i[h.id]}}</span>
                      </span>
                      <span *ngIf="i[h.id] == 'High'" class="flex-center-gap-8">
                        <span class="width-height-red"></span>
                        <span class="color-red text-wrap-none">{{i[h.id]}}</span>
                      </span>
                      <span *ngIf="i[h.id] == 'Very High'" class="flex-center-gap-8">
                        <span class="width-height-red"></span>
                        <span class="color-red text-wrap-none">{{i[h.id]}}</span>
                      </span>
                    </span>
                  </span>
                </td>
                <td> <button class="btn btn-link" *ngIf="i.Result != 'true' && i.Result != true"
                    (click)="showOffendes(i.Offenders)">Offenders</button>
                </td>
              </tr>
            </tbody>
            <tbody
              [ngStyle]="{'display': sortedIAMCheckList.length == 0 && scanDetails && scanDetails['scanStatus'] == 'COMPLETE' ? 'table-row-group' : 'none'}">
              <tr>
                <td [attr.colspan]="headers.length + 2">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
            <tbody
              [ngStyle]="{'display': sortedIAMCheckList.length == 0 && scanDetails &&  scanDetails['scanStatus'] != 'COMPLETE' ? 'table-row-group' : 'none'}">
              <tr>
                <td [attr.colspan]="headers.length + 2">
                  <div class="align-center" *ngIf="scanDetails">
                    <span class="text-bold">Status: </span>
                    {{scanDetails['scanStatus'] }}
                  </div>
                  <div class="align-center" *ngIf="!scanDetails">
                    <img *ngIf="fetching" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                </td>
              </tr>
            </tbody>
          </ng-container>
          <ng-container *ngIf="fetching == false && scanDetails && scanDetails['resource_found'] == false">
            <tbody>
              <tr>
                <td [attr.colspan]="headers.length + 2">
                  <div class="align-center">Resource not found/configured</div>
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!selectedScan">
    <div class="card">
      <div class="card-header flex-between">
        <h5 class="padding-0">
          {{ getFinding()['title'] }} Compliance
        </h5>
        <div class="flex-start">
          <button *ngIf="!fetching" class="btn btn-light flex-start" (click)="load()">
            <i class="fa fa-refresh"></i>
            <span class="color-inherit">
              Refresh
            </span>
          </button>
          <button *ngIf="!fetching && scans.length > 0" class="btn btn-primary-soft flex-start"
            (click)="createScan = true">
            <i class="fa fa-search"></i>
            <span class="color-inherit">
              Scan
            </span>
          </button>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>
                Sl. No.
              </th>
              <th>
                Scan ID
              </th>
              <th>
                Scanned On
              </th>
              <th>
                Status
              </th>
            </tr>
          </thead>
          <tbody *ngIf="!fetching">
            <tr *ngFor="let scan of scans; let i = index">
              <td>{{ i + 1 }}</td>
              <td>
                <span class="nav-link" (click)="loadScan(scan, true, uuid)" *ngIf="scan['SCAN_STATUS'] == 'COMPLETE'">
                  {{ scan['scanId'] }}
                </span>
                <span *ngIf="scan['SCAN_STATUS'] != 'COMPLETE'">
                  {{ scan['scanId'] }}
                </span>
              </td>
              <td>{{ scan['REQUESTED_AT'] }}</td>
              <td>
                <div class="flex-start">
                  <img src="../assets/img/loading_2.svg" *ngIf="scan['SCAN_STATUS'] != 'COMPLETE'" alt="loading scans"
                    class="width-18" />
                  <span>
                    {{ scan['SCAN_STATUS'] }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="fetching">
            <tr>
              <td [attr.colspan]="4" class="center">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!fetching && scans.length == 0">
            <tr>
              <td [attr.colspan]="4" class="center">
                <span>No scans found. <span class="nav-link" (click)="createScan = true">Click
                    here</span> to
                  create one</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pt-2" *ngIf="scans.length > 0">
          *Scans will get auto deleted after 24 hours from the time of generation
        </div>
      </div>
    </div>
  </ng-container>

</ng-container>

<ng-container *ngIf="notValid">
  <div class="card">  
    <div class="card-header flex-between">
      <h5 class="padding-0">
        {{ getFinding()['title'] }} Compliance
      </h5>
    </div>
    <div class="card-body center pt-2">
      {{ getFinding()['title'] }} is not a service of {{ selectedCloudPartner }}
    </div>
  </div>
</ng-container>

<!--<div class="modal show" tabindex="-1" *ngIf="showOffendesModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Offender List</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="showOffendesModal = false" ></button>
      </div>
      <div class="modal-body">
        <div class="card">
         
          <div class="card-body">-->
<!--<h5 class="card-title">Case Details</h5>-->

<!--<table class="table table-docs table-hover" *ngIf="offendeList.length > 0">
              <thead >
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of offendeListHeader">{{h.name}}</th>
                
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of offendeList; let j = index">
                  <td>{{j+1}}</td>
                  <td *ngFor="let h of offendeListHeader">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span (click)="callFunction(h.click,i)">
                        {{i[h.id]}}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{checkforBool(i[h.id])}}
                    </span>
                  </td>                
                </tr>
              </tbody>
            </table>
            <h5 style="text-align:center; color:#0094ff" *ngIf="offendeList.length == 0" >No Data</h5>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="showOffendesModal = false" >Close</button>      
      </div>
    </div>
  </div>
</div>-->

<modal-popup [headers]="offendeListHeader" [data]="offendeList" [modalTitle]="offentpopTitle"
  [showModal]="showOffendesModal" (hideModal)="hidePop($event)"></modal-popup>

<app-create-scan-finding *ngIf="createScan" [accountId]="accountId" [regionId]="regionId" [resourceGroup]="resourceGroup" [findingCp]="getFinding()['cp']"
  [finding]="this.getFinding()['audit_type']" [findingName]="this.getFinding()['title']"
  (hideModel)="close($event)"></app-create-scan-finding>

<app-threat-resources *ngIf="showResources" [resources]="resources"
  (hideModel)="closeResources($event)"></app-threat-resources>