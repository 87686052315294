import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import {NotifierService} from "../../../_services/notifier.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "cft-banner-optional",
  templateUrl: "./cft-banner-optional.component.html",
  styleUrls: ["./cft-banner-optional.component.css"],
})
export class CftBannerOptionalComponent implements OnInit {
  @Input("account") account: any;
  @Input("mandatory") mandatory: boolean;

  showUpdatePopup: boolean = false;
  stepsUpdate: boolean = false;
  stepsConfigure: boolean = false;
  existing: boolean = false;
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(private notifier: NotifierService, private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  ngOnInit(): void {
    const existing = this.getCookie(`minfy-swayam-update-${this.account['accountId']}`);
    if (existing != "") {
      this.existing = true;
      this.createTimer();
      // this.hideModal.emit(null);
    }
  }

  remindMeLater(event: any, param: string) {
    event.stopPropagation();
    const existing = this.getCookie(`minfy-swayam-update-${this.account['accountId']}`);

    if (existing == "") {
      let expiry: number = 0;
      let expiryText: string = '';
      if(param === '1HOUR') {
        expiry = 1/24;
        expiryText = '1 Hour'
      } else if(param === '1DAY') {
        expiry = 1;
        expiryText = '1 Day'
      } else if(param == '30SECS') {
        expiry = (1/24/60/60) * 30
        expiryText = '30 Seconds';
      }
      this.setCookie(
        `minfy-swayam-update-${this.account['accountId']}`,
        "true",
          expiry
      );
      this.existing = true;
      this.createTimer();
      this.notifier.alert(
          "Info",
          "",
          `You will be alerted about CFT Update after ${expiryText}.`,
          "info",
          5000
      );
    } else {
      return;
    }
  }

  // get Cookie
  getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  createTimer() {
    const cookie = this.getCookie(`minfy-swayam-update-${this.account['accountId']}-expiry`);
    if(cookie && cookie !== '') {
      const seconds: number = moment(cookie).valueOf() -moment().valueOf();
      if(seconds > 0) {
        setTimeout(() => {
          this.existing = false;
        }, seconds)
      } else {
        this.existing = false;
      }
    } else {
      this.existing = false;
    }
  }

  // set cookie
  setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    document.cookie = cname + "-expiry=" + d.toUTCString() + ";" + expires + ";path=/";
  }
}
