import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'launch-select-dropdown',
  templateUrl: './dropdown.launch.component.html',
  styleUrls: ['./dropdown.launch.component.css'],
})
export class selectLaunchDropdownComponent implements OnInit, OnDestroy {
  @ViewChild('maindoprdown') attachmentElement: ElementRef;
  @Input('options') options: any;
  @Input('key') key: string;
  @Input('selected') selected: any;
  @Input('title') title: string;
  @Output('update') update = new EventEmitter<any>();

  searchText: string;

  ngOnInit(): void {}

  addOption(index: any) {
    this.onUpdate('add-item', index);
  }

  onUpdate(event: any, data: any) {
    this.update.emit({ event: event, data: data, key: this.title });
    this.removeOptions();
  }

  filterSearchResult() {
    if (!this.searchText || this.searchText == '') {
      return this.options;
    }

    return this.options.filter((option: any) => {
      let check = false;
      Object.keys(this.options[0]).forEach((key: any) => {
        if (check) {
          return;
        }
        if (
          option[key].toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
        ) {
          check = true;
        }
      });
      return check;
    });
  }

  getSelected() {
    return this.options.find((option: any) => {
      return option[this.key] == this.selected;
    });
  }

  checkForSeleceted(option: any) {
    if (!this.selected || this.selected == '') {
      return false;
    }
    return option[this.key] == this.selected;
  }

  alterOption(event: any, option: any) {
    event.stopPropagation();
    this.addOption(option[this.key]);
  }

  showoptions: boolean = false;

  removeOptions() {
    if (!this.showoptions) {
      return;
    }

    document.body.removeEventListener('click', this.checkClick);
    this.searchText = '';
    this.showoptions = false;
  }

  checkClick(event: any) {
    if (this.attachmentElement.nativeElement.contains(event.target)) {
      return;
    }
    this.removeOptions();
  }

  showOptions() {
    if (this.showoptions) {
      this.removeOptions();
      return;
    }
    document.body.addEventListener('click', (event) => {
      this.checkClick(event);
    });
    this.showoptions = true;
  }

  ngOnDestroy(): void {
    this.removeOptions();
  }
}
