import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";

import { tokenExpired } from "./../../api/api.service";

import { APIService } from "./../../api/api.service";
import { NotifierService } from "./../../_services/notifier.service";
import countryCodes from "./countryCodes";
import "moment-timezone";
import * as moment from "moment-timezone";
import { environment } from "src/environments/environment";

@Component({
  selector: "register-page",
  templateUrl: "./register.component.html",
  styleUrls: ["./../login.component.css", "./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  tokenExpired: any = tokenExpired;
  @Output() hideModel = new EventEmitter<boolean>();
  currentImage: number = 0;
  imageTimer: any = null;
  whitelabelData: any = {
    pd: {
      appName: "",
      logoDark: "",
      logoLight: "",
    },
  };
  loading: any = {
    register: false,
    otp: false,
  };
  validating: boolean = false;
  carousel: any = [
    {
      title: "Dashboards",
      description:
        "Gain valuable insights and make data-driven decisions faster and more effectively, while also improving collaboration and productivity within your team",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_Dashboard_BlueBG.svg",
    },
    {
      title: "Security",
      description:
        "Advanced algorithms and analytics, the platform can analyze vast amounts of data in real-time and provide you with valuable insights into your cloud operations.",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/2_+Security_BlueBG.svg",
    },
    {
      title: "Cost Optimisation",
      description:
        "Optimize your cloud costs and performance with ${this.whitelabelData['pd']['appName']}'s advanced analytics and automation, freeing up resources for business innovation.",
      image:
        "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-features/login-carousel/3_Cost+Optimisation_BlueBg.svg",
    },
  ];

  nextImage() {
    if (this.currentImage == this.carousel.length - 1) {
      this.currentImage = 0;
    } else {
      this.currentImage += 1;
    }
  }

  setImage(count: number) {
    this.currentImage = count;
    if (this.imageTimer) {
      clearInterval(this.imageTimer);
    }
    this.imageTimer = setInterval(() => {
      this.nextImage();
    }, 5000);
  }

  changeFavicon(newFaviconURL: any) {
    const favicon: any = document.getElementById("favicon");
    if (favicon) {
      favicon.href = newFaviconURL;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.type = "image/png";
      newFavicon.href = newFaviconURL;
      newFavicon.id = "favicon";
      document.head.appendChild(newFavicon);
    }
  }

  countryCodesObj: any = countryCodes;
  selectedCountryCode: string = "+91";

  validations: any = {
    emailId: {
      policy: {
        required: true,
        check: () => {
          return this.page === "details";
        },
        regex: (text: string) => {
          let regexFormat: RegExp =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
          return regexFormat.test(text);
        },
      },
      name: "Email ID",
    },
    firstName: {
      policy: {
        check: () => {
          return this.role != "client" || this.regToken;
        },
        required: true,
      },
      name: "First Name",
    },
    lastName: {
      policy: {
        check: () => {
          return this.role != "client" || this.regToken;
        },
        required: true,
      },
      name: "Last Name",
    },
    designation: {
      policy: {
        check: () => {
          return this.role != "client" || this.regToken;
        },
        required: true,
      },
      name: "Designation",
    },
    cmpName: {
      policy: {
        check: () => {
          return this.role != "client" || this.regToken;
        },
        required: true,
      },
      name: "Company Name",
    },
    phoneNumber: {
      policy: {
        required: true,
        check: () => {
          return this.role != "client" || this.regToken;
        },
        regex: (text: string) => {
          let regexFormat: RegExp =
            /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/g;
          regexFormat.test(text);
        },
      },
      name: "Phone Number",
    },
    otp: {
      policy: {
        check: () => {
          return this.otpSent;
        },
        required: true,
      },
      name: "OTP",
    },
  };
  validate(): boolean {
    let ref: any = this;
    let check: boolean = true;
    Object.keys(this.validations).forEach((key: string) => {
      this.errors[key]["error"] = false;
      if (
        this.validations[key]["policy"]["check"] == undefined ||
        this.validations[key]["policy"]["check"]()
      ) {
        if (
          this.validations[key]["policy"]["required"] &&
          (!ref[key].trim() || ref[key].trim() == "")
        ) {
          this.errors[key]["error"] = true;
          this.errors[key][
            "message"
          ] = `${this.validations[key]["name"]} is a mandatory field`;
          check = false;
        } else if (
          this.validations[key]["policy"]["regex"] &&
          this.validations[key]["policy"]["regex"](ref[key]) === false
        ) {
          this.errors[key]["error"] = true;
          this.errors[key][
            "message"
          ] = `Entered ${this.validations[key]["name"]} is not valid`;
          check = false;
        } else {
          this.errors[key]["error"] = false;
        }
      } else {
        this.errors[key]["error"] = false;
      }
    });

    return check;
  }
  errors: any = {
    firstName: {
      error: false,
      message: "",
    },
    lastName: {
      error: false,
      message: "",
    },
    emailId: {
      error: false,
      message: "",
    },
    cmpName: {
      error: false,
      message: "",
    },
    designation: {
      error: false,
      message: "",
    },
    country: {
      error: false,
      message: "",
    },
    timeZone: {
      error: false,
      message: "",
    },
    source: {
      error: false,
      message: "",
    },
    coupon: {
      error: false,
      message: "",
    },
    phoneNumber: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
    confPassword: {
      error: false,
      message: "",
    },
    otp: {
      error: false,
      message: "",
    },
  };

  showPassword: boolean = false;
  passwordInterval: any = null;
  page: string = "details";
  otpRequired: boolean = false;
  public registerForm = new UntypedFormGroup({
    firstName: new UntypedFormControl("", []),
    lastName: new UntypedFormControl("", []),
    emailId: new UntypedFormControl("", []),
    cmpName: new UntypedFormControl("", []),
    designation: new UntypedFormControl("", []),
    password: new UntypedFormControl("", []),
    confPassword: new UntypedFormControl("", []),
    phoneNumber: new UntypedFormControl("", []),
    source: new UntypedFormControl("", []),
    coupon: new UntypedFormControl("", []),
    country: new UntypedFormControl("", []),
    timeZone: new UntypedFormControl("", []),
  });

  role: string = "client";

  public otpForm = new UntypedFormGroup({
    otp: new UntypedFormControl("", []),
  });

  public hideRoleSelection = false;

  // get username_form() {
  //   return this.userNameForm.controls;
  // }

  get register_form() {
    return this.registerForm.controls;
  }

  get otp_form() {
    return this.otpForm.controls;
  }

  otpSent: boolean = false;

  firstName: string = "";
  lastName: string = "";
  emailId: string = "";
  cmpName: string = "";
  designation: string = "";
  password: string = "";
  confPassword: string = "";
  phoneNumber: string = "";
  source: string = "Direct";
  coupon: string = "";
  country: string = "India";
  timeZone: string = "";

  otp: string = "";
  regToken: string = null;
  cid: string = null;
  resend: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {
    this.whitelabelData = this.route.snapshot.data.data;
    
    this.carousel.forEach((page: any) => {
      page['description'] = page['description'].replace("${this.whitelabelData['pd']['appName']}", this.whitelabelData['pd']['appName'])    
    })

    if (this.whitelabelData['pd']["logoLight"]) {
      this.changeFavicon(this.whitelabelData['pd']['logoLight']);
    }
  }

  ngOnInit(): void {
    this.tokenExpired["checking"] = false;

    this.imageTimer = setInterval(() => {
      this.nextImage();
    }, 5000);
    this.route.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length == 0) {
        this.regToken = null;
      }
      if (
        params["x-amzn-marketplace-token"] &&
        params["x-amzn-marketplace-token"] != ""
      ) {
        this.regToken = params["x-amzn-marketplace-token"];
      } else {
        this.regToken = null;
      }
    });
    if (window.location.href.indexOf("app.swayam.cloud") != -1) {
      this.role = "admin";
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf("client.swayam.cloud") != -1) {
      this.role = "client";
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf("cbs.swayam.cloud") != -1) {
      this.role = "admin";
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf("cbsadmin.swayam.cloud") != -1) {
      this.role = "admin";
      this.hideRoleSelection = true;
    } else {
      this.role = "client";
      this.hideRoleSelection = true;
    }
    if (this.role == "client" || this.regToken) {
      this.countryChange();
    }
  }

  alterPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.passwordInterval = setTimeout(() => {
        this.showPassword = false;
        clearInterval(this.passwordInterval);
        this.passwordInterval = null;
      }, 5000);
    } else if (this.passwordInterval) {
      clearInterval(this.passwordInterval);
      this.passwordInterval = null;
    }
  }

  getTimeZones() {
    let zones: any = [];
    let checked: any = [];
    let country: any = this.countryCodesObj.find((country: any) => {
      return country["name"] == this.country;
    });

    moment.tz.zonesForCountry(country["code"], true).forEach((zone: any) => {
      if (checked.indexOf(zone.offset) > -1) {
        return;
      } else {
        checked.push(zone.offset);
        let getZones: any = moment.tz
          .zonesForCountry(country["code"], true)
          .filter((offset: any) => {
            return offset["offset"] == zone["offset"];
          })
          .map((offset: any) => {
            return offset["name"].split("/")[
              offset["name"].split("/").length - 1
            ];
          });
        zones.push({ offset: zone.offset, name: getZones.join(", ") });
      }
    });

    return zones;
  }

  getOffsetTimeInMinutes(offset: any) {
    const formatted: string = offset["offset"] < 0 ? "+" : "-";
    let minutes: number;
    if (offset["offset"] < 0) {
      minutes = offset["offset"] * -1;
    } else {
      minutes = offset["offset"];
    }
    const getHours: number = Math.floor(minutes / 60);
    const getMinutes: number = minutes % 60;
    return `${formatted}${getHours}:${getMinutes}`;
  }

  countryChange() {
    this.timeZone =
      this.getTimeZones().length > 0
        ? `(UTC ${this.getOffsetTimeInMinutes(this.getTimeZones()[0])}) ${
            this.getTimeZones()[0]["name"]
          }`
        : "";

    let country: any = this.countryCodesObj.find((country: any) => {
      return country["name"] == this.country;
    });

    this.selectedCountryCode = country["dial_code"];
  }

  async otpValidation(event?: any) {
    if (this.tokenExpired["checking"]) {
      return false;
    }
    if ((!event && this.otp.trim().length < 6) || this.validating) return false;
    if (!this.validate()) {
      return false;
    }
    this.loading["otp"] = true;
    let data: any = {
      eId: this.emailId.trim().toLowerCase(),
      otp: this.otp.trim(),
      cid: this.regToken ? this.cid : undefined,
      ut: this.role == "admin" ? "msops" : "client",
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.role}/users/validatesignupotp`;

    if (this.role.toLowerCase() != "admin") {
      apiURL = `${APIService.API_ENDPOINTV3}/market/signupdev`;

      data = {
        a: "validateOtp",
        eId: this.emailId.trim(),
        otp: this.otp.trim(),
        cid: this.regToken ? this.cid : undefined,
        customerType: this.regToken ? "mp" : "minfy",
      };
    }
    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == "1" || result.s == "1") {
      if (this.regToken) {
        localStorage.setItem("regToken", this.regToken);
      }
      // this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.router.navigate(["/login"], { queryParams: { register: true } });
    } else {
      if (result.error.toLowerCase().includes("otp")) {
        this.errors["otp"] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert("Info", "", result.error, "info", 5000);
      }
    }
    this.loading["otp"] = false;
    return true;
  }

  getKeys(obj: any): any {
    return Object.keys(obj);
  }

  passwordChecker(password: string) {
    let passwordObj: any = {
      strength: 0,
      policies: {
        "Must have atleast 8 characters": password.length > 7,
        "Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number":
          false,
        "Must have atleast 1 Special Character": false,
        "Longer Password": false,
      },
    };
    if (passwordObj.policies["Must have atleast 8 characters"]) {
      passwordObj.strength += 1;
    }
    if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{0,}$/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies[
        "Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number"
      ] = true;
    }
    if (password.length >= 10) {
      passwordObj.strength += 1;
      passwordObj.policies["Longer Password"] = true;
    }
    if (password.match(/[^A-z\s\d][\\\^]?/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies["Must have atleast 1 Special Character"] = true;
    }
    return passwordObj;
  }

  selectCode(event: any) {
    this.selectedCountryCode = event.target.value;
  }

  async register(validate: boolean = true) {
    if (this.tokenExpired["checking"]) {
      return false;
    }
    if (validate && !this.validate()) {
      return false;
    }

    if (this.passwordChecker(this.password)["strength"] < 3) {
      this.errors["password"] = {
        error: true,
        message: "Entered Password doesn't meet the minimum password policy",
      };
      return false;
    } else {
      this.errors["password"] = {
        error: false,
        message: "",
      };
    }

    if (this.password.trim() != this.confPassword.trim()) {
      this.errors["confPassword"] = {
        error: true,
        message: "Passwords do not match",
      };
      return false;
    } else {
      this.errors["confPassword"] = {
        error: false,
        message: "",
      };
    }

    if (
      this.role == "client" &&
      (!this.source.trim() || !this.timeZone.trim() || !this.country.trim())
    ) {
      this.notifier.alert("Error", "", "Fields are missing", "error", 5000);
      return false;
    }

    if (
      this.role == "client" &&
      this.coupon.trim() != "" &&
      (!this.coupon.trim().match(/^(?=.*\d)(?=.*[A-Z])(?=.*[0-9]).{8}$/gm) ||
        this.coupon.match(/[^A-z\s\d][\\\^]?/gm))
    ) {
      this.errors["coupon"] = {
        error: true,
        message: "Coupon is not valid",
      };
      return false;
    }

    this.loading["register"] = true;

    this.phoneNumber = this.phoneNumber.toString().replace(/[^0-9 ]/g, "");

    let data: any = {
      eId: this.emailId.trim().toLowerCase(),
      ut: "msops",
      pswd: this.password.trim(),
      un: this.firstName.trim() + " " + this.lastName.trim(),
      mn: this.selectedCountryCode + this.phoneNumber.toString(),
      cn: this.cmpName.trim(),
      des: this.designation.trim(),
    };

    let apiURL: string;

    if (this.role == "client") {
      data = {
        a: "signup",
        eId: this.emailId.trim().toLowerCase(),
        ut: "client",
        pswd: this.password.trim(),
        customerType: "minfy",
        source: this.source,
        timeZone: this.timeZone,
        country: this.country,
        coupon: this.coupon.trim() || undefined,
      };
      if (this.regToken) {
        data = {
          ...data,
          ...{
            a: "signup",
            userName: this.firstName.trim() + " " + this.lastName.trim(),
            customerType: "mp",
            cid: this.regToken,
            num: this.selectedCountryCode + this.phoneNumber.toString(),
            cmpName: this.cmpName.trim(),
            des: this.designation.trim(),
          },
        };
      }
      apiURL = `${APIService.API_ENDPOINTV3}/market/signupdev`;
    } else {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.role}/users/signup`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == "1" || result.s == "1") {
      this.resend = result.otpMail || 'no-reply@swayam.cloud';
      if (this.regToken && result["cid"]) {
        this.cid = result["cid"];
      } else {
        this.cid = null;
      }
      this.otpSent = true;
    } else {
      if (
        result.error.toLowerCase().includes("email") ||
        result.error === "User already exists."
      ) {
        this.page = "details";
        this.errors["emailId"] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert("Error", "", result.error, "error", 5000);
      }
    }

    this.loading["register"] = false;
    return true;
  }

  next() {
    if (!this.validate()) {
      return false;
    }
    if (
      this.role == "client" &&
      this.coupon.trim() != "" &&
      (!this.coupon.trim().match(/^(?=.*\d)(?=.*[A-Z])(?=.*[0-9]).{8}$/gm) ||
        this.coupon.match(/[^A-z\s\d][\\\^]?/gm))
    ) {
      this.errors["coupon"] = {
        error: true,
        message: "Coupon is not valid",
      };
      return false;
    } else {
      this.errors["coupon"] = {
        error: false,
        message: "",
      };
    }
    this.page = "password";
    return true;
  }

  ngOnDestroy() {
    if (this.imageTimer) {
      clearInterval(this.imageTimer);
    }
  }
}
