import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as echarts from "echarts";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let Jhxlsx: any;
declare let window: any;

@Component({
  selector: "app-ftr-check",
  templateUrl: "./ftr-check.component.html",
  styleUrls: ["./ftr-check.component.css", "./../../ftr.component.css"],
})
export class FtrCheckComponent implements OnInit {
  @Input("loading") loading: any;
  @Input("job") job: any;
  filters: any = {
    questions: "All",
  };
  @Input("accountId") accountId: any;
  @Input("regionId") regionId: any;
  @Output("hideModel") hide: any = new EventEmitter<boolean>();
  @Output("loadJobs") loadJobs: any = new EventEmitter<string>();
  perPages: any = [10, 50, 100, 200];
  checks: any = [];
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  jobsHeaders: any = [
    {
      id: "job_id",
      name: "Job ID",
    },
  ];
  currentMessage: any;
  filter: any = {
    checks: "ALL",
    findings: "ALL",
  };

  checkCount = 0;
  checkInterval = 10000;
  jobCurrentStatus = ""; //In progress, Completed

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private navigator: Router
  ) {}

  ngOnInit(): void {
    this.loading["checks"] = true;

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    window.addEventListener("resize", () => {
      this.loadGraphsOverview();
      this.loadGraphs();
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  destroyed: boolean = false;

  async load() {
    this.filters["questions"] = "All";
    this.selectedRequirement = null;
    //this.loading["checks"] = true;
    //if (this.job.status != "Completed") {
    //  this.loadJobs.emit(this.job.job_id);
    //  this.loadChecks();
    //  if (this.timer) {
    //    return;
    //  }
    //  this.timer = setInterval(() => {
    //    if (this.job.status != "Completed" && this.destroyed == false) {
    //      this.loadJobs.emit(this.job.job_id);
    //      this.loadChecks();
    //    } else {
    //      if (this.timer) {
    //        clearInterval(this.timer);
    //        this.timer = null;
    //      }
    //    }
    //  }, 5000);
    //} else {
    //  if (this.timer) {
    //    clearInterval(this.timer);
    //    this.timer = null;
    //  }
    //  this.loadChecks();
    //}

    this.loadJobs.emit(true, true);
    await this.loadChecks();
    if (this.jobCurrentStatus == "In progress") {
      this.checkProgress();
    }
  }

  async checkProgress() {
    setTimeout(async () => {
      if (this.checkCount > 10) {
        this.loading.checks = false;
        this.notifier.alert(
          "Info",
          "",
          "Security Scan is taking longer than usual. Please click on the Refresh button after a couple of minutes.",
          "info",
          -1
        );
        return;
      }
      this.checkCount++;
      this.checkInterval = this.checkInterval + 1000;
      await this.loadChecks();
      if (this.jobCurrentStatus == "In progress") {
        this.checkProgress();
      } else {
        this.loading.checks = false;
      }
    }, this.checkInterval);
  }

  hideModel(event: any) {
    this.navigator.navigate(["/dash/security/ftr"]);
    this.hide.emit(event);
  }

  checksAcc: any = [];
  selectedRequirement: string = "";

  getChecks() {
    return this.checks.filter((check: any) => {
      return (
        check["check_root"].toLowerCase().trim() ==
        this.selectedRequirement.toLowerCase().trim()
      );
    });
  }

  metadata: any = ["IAM-007", "IAM-008", "WAFR-001", "WAFR-002"];
  async loadChecks() {
    this.loading["checks"] = true;
    let data: any = {
      //action: "list_checks",
      action: "job_status",
      job_id: this.job.job_id,
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ftr/operations`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    this.loading["checks"] = false;

    this.checksAcc = [];
    if (result.status == "1" || result.s == "1") {
      this.job = result.job_status[0];
      result.checks = result.checks
        .map((check: any) => {
          check["check_status"] =
            check["check_status"] != undefined && check["check_status"] != null
              ? check["check_status"]
              : "false";
          if (!this.metadata.includes(check["check_id"]) && check["metadata"]) {
            if (
              typeof check["metadata"] == "object" &&
              !Array.isArray(check["metadata"])
            ) {
              let dict: any = {};
              Object.keys(check["metadata"]).forEach((key: any) => {
                dict[key] = check["metadata"][key].toString();
              });
              check["metadata"] = [dict];
            }

            if (check["custom_metadata"]) {
              check["custom_metadata"][check["off_key"]] = JSON.parse(
                JSON.stringify(check["metadata"])
              );
            } else {
              let key: string = check["off_key"] || "Metadata";
              check["custom_metadata"] = {};
              check["custom_metadata"][key] = JSON.parse(
                JSON.stringify(check["metadata"])
              );
            }

            delete check["metadata"];
          }

          if (check["custom_metadata"]) {
            let data: any = [];
            Object.keys(check["custom_metadata"]).forEach((key: any) => {
              if (
                check["custom_metadata"][key] &&
                check["custom_metadata"][key].length > 0
              ) {
                data.push({
                  tableName: key.replaceAll("_", " "),
                  headers: Object.keys(check["custom_metadata"][key][0]).map(
                    (key_: any) => {
                      return {
                        id: key_,
                        name: key_.replaceAll("_", " "),
                      };
                    }
                  ),
                  data: check["custom_metadata"][key],
                });
              }
            });
            check["custom_metadata"] = data;
          }

          if (check["metadata"] || check["custom_metadata"]) {
            delete check["offenders"];
          }
          // updating checks for frontend values
          check["evidence"] =
            check["evidence"] != undefined ? check["evidence"] : null;

          check["check_root_order"] = check["check_root_order"]
            ? Number(check["check_root_order"])
            : 0;
          check["decision"] =
            check["decision"] != undefined ? check["decision"] : null;
          if (check["status_check"] == "true") {
            check["checkbox"] = true;
          } else if (check["status_check"] == "false") {
            check["checkbox"] = false;
          } else if (check["check"] != "manual" && check["status"] == "false") {
            check["checkbox"] = true;
          } else if (check["check"] != "manual" && check["status"] == "true") {
            check["checkbox"] = false;
          } else if (check["check"] == "manual") {
            check["checkbox"] = false;
          }

          // updating categories
          let temp = this.checksAcc.find((check_: any) => {
            return check_["check_root"] == check.check_root;
          });

          if (!temp) {
            this.checksAcc.push({
              check_root: check.check_root,
              check_root_order: check.check_root_order,
              check_id: check.check_root.replaceAll(" ", "_"),
              unanswered: () => {
                let check_id: string = check.check_root;
                return this.checks.filter((check_: any) => {
                  // console.log(check_['status'], check_['check_status'], check_['decision']);
                  return (
                    check_["check_root"] == check_id &&
                    !(
                      check_["check"] != "manual" ||
                      (check_["check"] == "manual" &&
                        (check_["checkbox"] || check_["decision"] != null))
                    )
                  );
                }).length;
              },
              answered: () => {
                let check_id: string = check.check_root;
                return this.checks.filter((check_: any) => {
                  return (
                    check_["check_root"] == check_id &&
                    (check_["check"] != "manual" ||
                      (check_["check"] == "manual" &&
                        (check_["checkbox"] || check_["decision"] != null)))
                  );
                }).length;
              },
              count: 1,
            });
          } else {
            temp.count += 1;
          }
          return check;
        })
        .sort((a: any, b: any) => {
          return a["check_root_order"] - b["check_root_order"];
        });
      this.checksAcc = this.checksAcc.sort((a: any, b: any) => {
        return a["check_root_order"] - b["check_root_order"];
      });

      this.checks = result.checks;
      this.jobCurrentStatus = result.job_status[0]["status"];
      setTimeout(() => {
        this.loadGraphs();
        this.loadGraphsOverview();
      }, 10);
    } else {
      this.checks = [];
    }
  }

  exportFTRCompliance() {
    let json: any = [
      {
        sheetName: this.job["name"],
        data: [
          [
            {'text': 'Job Name'},
            {'text': 'Date'},
            {'text': 'Total Checks'},
            {'text': 'Score (%)'},
            {'text': 'Passed'},
            {'text': 'Failed'},
            {'text': 'Compliant'},
            {'text': 'Non-Compliant'},
            {'text': 'Manual'},
          ],
          [
            {'text': this.job['name']},
            {'text': this.job['cr_time']},
            {'text': this.job['total_checks']},
            {'text': Number(Number(
              (this.job["passed"] / this.checks.length) * 100
            ).toFixed(2))},
            {'text': this.job['passed']},
            {'text': this.job['failed']},
            {'text': this.checks.filter((check: any) => {
              return (
                check["check"] != "manual" &&
                ((check["status"] == "true" &&
                  check["check_status"] == "false") ||
                  check["decision"] == "NOT_APPLICABLE")
              );
            }).length},
            {'text': this.checks.filter((check: any) => {
              return (
                check["check"] != "manual" &&
                (check["status"] == "false" ||
                  (check["status_check"] == "true" &&
                    check["decision"] == "REMEDIATE"))
              );
            }).length},
            {'text': this.checks.filter((check: any) => {
              return check["check"] == "manual";
            }).length}
          ],
          [{text: ''}],
          [
            { text: "Question ID" },
            { text: "Question Title" },
            { text: "Source" },
            { text: "Evidence" },
            { text: "Evidence Status" },
            { text: "Evidence (valid/invalid)" },
            { text: "Decision" },
            { text: "Comment" },
            { text: "Reference Link" },
          ],
        ],
      },
    ];

    this.checksAcc.forEach((requirement: any) => {
      json[0]["data"].push([{ text: requirement["check_root"] }]);
      this.checks
        .filter((check: any) => {
          return check["check_root"] == requirement["check_root"];
        })
        .forEach((check: any) => {
          json[0]["data"].push([
            { text: check["check_id"] || "" },
            { text: check["title"] || "" },
            { text: check["check"] || "" },
            { text: check["reason"] || "" },
            { text: check["status"] || "" },
            { text: check["evidence"] != null ? check['evidence'] ? 'Valid' : 'Invalid' : '-' },
            { text: check["decision"] || "" },
            { text: check["comment"] || "" },
            { text: check["reference"] || "" },
          ]);
        });

      json[0]["data"].push([{ text: "" }]);
    });

    var options = {
      fileName: `FTR ${this.accountId}_${this.regionId}`,
    };

    Jhxlsx.export(json, options);
  }

  fetchTotalAnsweredChecks() {
    return this.checksAcc.filter((check: any) => {
      return check["unanswered"] > 0;
    });
  }

  scoreChart: any = null;
  overviewChart: any = null;
  loadGraphs() {
    var chartDom = document.getElementById("graph_score");
    if (this.scoreChart) {
      this.scoreChart.dispose();
      this.scoreChart = null;
    }
    this.scoreChart = echarts.init(chartDom);
    var option;

    let totalAnswered: number = 0;

    this.checksAcc.forEach((check: any) => {
      totalAnswered += check["answered"];
    });

    option = {
      series: [
        {
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          center: ["50%", "75%"],
          radius: "90%",
          min: 0,
          max: 100,
          splitNumber: 20,
          axisLine: {
            lineStyle: {
              width: 50,
              color: [
                [0.3, "rgb(238, 102, 102)"],
                [0.6, "rgb(250, 200, 88)"],
                [0.8, "#f8832e"],
                [1, "#58c388"],
              ],
            },
          },
          pointer: {
            show: true,
            length: "45%",
            width: 5,
          },
          axisTick: {
            show: false,
            length: 12,
            lineStyle: { color: "auto", width: 1 },
          },
          splitLine: { length: 12, lineStyle: { color: "auto", width: 2 } },
          axisLabel: {
            show: true,
            color: "#474a4d",
            fontSize: 16,
            distance: -40,
            fontWeight: "bold",
            rotate: "tangential",
            formatter: (value: number) => {
              if (value == 15) {
                return "Poor";
              }
              if (value == 45) {
                return "Average";
              }
              if (value == 70) {
                return "Good";
              }
              if (value == 90) {
                return "Excellent";
              }
              return "";
            },
          },
          title: { offsetCenter: [0, "-10%"], fontSize: 20 },
          detail: {
            show: true,
            fontSize: 20,
            valueAnimation: true,
            color: "#03152d",
          },
          data: [
            {
              value: Number(
                (this.job["passed"] / this.checks.length) * 100
              ).toFixed(2),
              name: "",
            },
          ],
        },
      ],
    };

    option && this.scoreChart.setOption(option);
  }
  loadGraphsOverview() {
    var chartDom = document.getElementById(`graph_overview`);
    if (this.overviewChart) {
      this.overviewChart.dispose();
      this.overviewChart = null;
    }
    this.overviewChart = echarts.init(chartDom);

    this.overviewChart.on("click", (params: any) => {
      this.filters["questions"] = params["name"];
      this.selectedRequirement = this.checksAcc[0]["check_root"];
    });

    let option: any = {
      tooltip: {
        formatter: (params: any) => {
          return `
            <div class='flex-start'>
              <div>${params["name"]}</div>
              <div class='graph-text-bold'>${params["value"]}</div>
            </div>
          `;
        },
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          startAngle: 0,
          label: {
            show: true,
            rotate: "tangential",
          },
          color: ["#58c388", "rgb(238, 102, 102)", "rgb(250, 200, 88)"],
          data: [
            {
              value: this.checks.filter((check: any) => {
                return (
                  check["check"] != "manual" &&
                  ((check["status"] == "true" &&
                    check["check_status"] == "false") ||
                    check["decision"] == "NOT_APPLICABLE")
                );
              }).length,
              name: "Compliant",
            },
            {
              value: this.checks.filter((check: any) => {
                return (
                  check["check"] != "manual" &&
                  (check["status"] == "false" ||
                    (check["status_check"] == "true" &&
                      check["decision"] == "REMEDIATE"))
                );
              }).length,
              name: "Non Compliant",
            },
            {
              value: this.checks.filter((check: any) => {
                return check["check"] == "manual";
              }).length,
              name: "Manual",
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && this.overviewChart.setOption(option);
  }

  showQuestions(check: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
