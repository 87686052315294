<div class="card">
  <h5 class="card-header">
    Tags
  </h5>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="baseline-tags-tab" data-bs-toggle="tab" data-bs-target="#baseline-tags"
          type="button" role="tab" aria-controls="baseline-tags" aria-selected="true">Baseline Tags</button>
        <button class="nav-link" id="compliance-tags-tab" data-bs-toggle="tab" data-bs-target="#compliance-tags"
          (click)="loadCompliance()" type="button" role="tab" aria-controls="compliance-tags"
          aria-selected="false">Compliance</button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="baseline-tags" role="tabpanel" aria-labelledby="baseline-tags-tab">
        <div class="card card_14_css">
          <!--<h5 class="card-header"></h5>-->

          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->

            <div class="tag_list_20">
              <div class="tag_list_21">
                <div class="tag_list_22">Tags List</div>
                <div>
                  <select class="form-control" multiple (change)="changeSelectedTags($event)" id="tagListID"
                    data-live-search="true">
                    <option *ngFor="let tag of tagList" value="{{tag}}">{{tag}}</option>
                  </select>
                </div>
              </div>
              <div class="based_line_30">
                <div class="based_line_31">Baselined Tags
                </div>
                <div class="based_line_33">
                  <div *ngFor="let tag of selectedTags; let i = index" class="based_line_35">
                    <div>{{tag}}</div>
                    <div (click)="removeSelectedTags(i)" class="x_37">x</div>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary-soft" (click)="submitTags()"
                  [ngClass]="{'click-disabled': !writeAccess && selectedTags.length == 0}"
                  [attr.disabled]="!writeAccess && selectedTags.length == 0 ? true : null">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="compliance-tags" role="tabpanel" aria-labelledby="compliance-tags-tab">
        <div class="card card_50">
          <!--<h5 class="card-header"></h5>-->
          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->
            <div class="refresh_54">
              <button class="btn btn-light" (click)="loadCompliance()">Refresh</button>
              <button class="btn btn-primary-soft" (click)="createScanTrigger()">Scan</button>
            </div>

            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of headers">
                    {{h.name}}
                    <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,instances)">

                      <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                      <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                    </span>

                    <span *ngIf="h.filter " class="inputseach">
                      <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </th>
                </tr>
              </thead>

              <tbody [ngStyle]="{'display': instances.length > 0 ? 'table-row-group': 'none'}"
                *ngFor="let i of instances; let j = index">
                <tr *ngIf="notifier.filterTableColums(headers,i)">
                  <td>{{j+1}}</td>
                  <td *ngFor="let h of headers">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span (click)="callFunction(h.click,i)">
                        {{i[h.id]}}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{i[h.id]}}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody [ngStyle]="{'display': instances.length == 0 ? 'table-row-group' : 'none'}">
                <tr>
                  <td [attr.colspan]="headers.length + 1">
                    <div class="no_data_found_98">No Data Found</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<createscan-backupreport *ngIf="createScan" [apiUrl]="getURl()"
  [description]="'Depending on the number of instances in your account this may take some time to complete. <br> Are you sure you want to continue?'"
  [data]="getData()" [message]="'[message]'" (hideModal)="hideModal($event)"></createscan-backupreport>

<tags-modal *ngIf="showTagsModal" modalTitle="Tags" list={{showTagsModal}} instanceID={{showTagsModal.instanceId}}
  accountID={{accountId}} regionID={{regionId}} serviceName="ec2" (hideModal)="hideMdoal($event)"></tags-modal>