<div class="card" [ngStyle]="{ display: selectedJob ? 'none' : 'block' }">
  <div class="card-header">
    <div>
      <h5>EcoCloud Game</h5>
      <p class="desc"> Engage, optimize, win! Swayam Eco Cloud Game transforms cloud management into an interactive learning experience, where users optimize their AWS infrastructure while reducing their carbon footprint and cloud spend. Learn cloud best practices, and reap discounts for sustainable cloud management!</p>
  
    </div>
  </div>
  <div class="card-body">
    <div class="card-header flex-between">
      <div class="flex-item-center">
        <!-- <span>Per Page</span>
        <select class="padding-color" [(ngModel)]="perPage">
          <option [ngValue]="count" *ngFor="let count of pages">
            {{ count }}
          </option>
        </select> -->
      </div>
    <div class="flex-right">
      <span class="font-bold">Month Year: </span>
      <input
        autocomplete="off"
        class="datetimepicker"
        id="datetimepicker"
        type="text"
        [(ngModel)]="dateRange"
      />
      <!-- <span>{{monthYear}}</span> -->
      <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
    </div>
    
  </div>
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of jobsHeaders">{{ h.name }}</th>
          <th>Scanned On</th>
          <th>Status</th>
          <th>Current Level</th>
          <!-- <th>Discount Percentage</th> -->
        </tr>
      </thead>
      <tbody *ngIf="jobs.length > 0 && !loading['jobs']">
        <tr
          *ngFor="
            let job of jobs
              | paginate
                : {
                    itemsPerPage: perPage,
                    currentPage: page,
                    totalItems: jobs.length
                  };
            let i = index
          "
        >
          <td>{{ (page - 1) * perPage + i + 1 }}</td>
          <td *ngFor="let h of jobsHeaders" class="cursor"
          (click)="selectJob(job)">
            <span class="nav-link" [ngClass]="{ 'nav-link': h['link'] }">
              {{ job[h.id] }}
            </span>      
          </td>
          <td>
            <span>
              {{ job.scannedOn }}
            </span>
          </td>

          <td>
            <span *ngIf="job.status == 'completed'">Completed</span>
            <span *ngIf="job.status == 'scanning'">Scanning...</span>
          </td>
          <td>
            <span *ngIf="job.currentLevel != ''">{{ job.currentGameLevel }}</span>
            <span *ngIf="job.currentLevel == ''" class="text-center"
              ><img
                src="../assets/img/loading_2.svg"
                alt="loading scans"
                class="wid-mar"
              />
            </span>
          </td>
          <!-- <td>
            {{ job.discountPercentage }} / {{ job.totalDiscount }}
          </td> -->
        </tr>
      </tbody>
      <tbody *ngIf="loading['jobs']">
        <tr>
          <td class="text-center" [attr.colspan]="jobsHeaders.length + 5">
            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              class="wid-mar"
            />
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!loading['jobs'] && jobs.length == 0">
        <tr>
          <td [attr.colspan]="jobsHeaders.length + 5">
            <div class="center">
              No Scans Found.
              <!-- <span class="nav-link" (click)="createJob = true">Click here</span> to create one -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div class="paginator" *ngIf="jobs.length > 0">
      <pagination-controls
        class="pagination"
        previousLabel="Prev"
        nextLabel="Next"
        (pageChange)="page = $event"
      >
      </pagination-controls>
    </div> -->
  </div>
  <!-- <div *ngIf="scaninprogress" class="confirmContainer">
    <div class="confirmBox">
      <div class="KBBody flex-1">
        <div class="col-mb-6 mb-2">
          <h4>Scan in-progress, please wait 5 minutes</h4>
        </div>
      </div>
    </div>
  </div> -->
</div>
<app-greengame-check
  *ngIf="selectedJob"
  [dselected]="monthYear"
  [loading]="loading"
  [job]="selectedJob"
  (loadJobs)="loadJobs($event)"
  (hideModel)="hideModel($event)"
>
</app-greengame-check>
<!-- <create-job-greengame *ngIf="createJob" [accountId]="accountId" (hideModal)="hideModel($event)">
</create-job-greengame> -->
