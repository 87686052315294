export const categories: any = {
  'EC2 Instance': {
    'Actual CPU Utilization': 'P3',
    'Agent Service': 'P3',
    Availability: 'P2',
    'CPU Utilization': 'P3',
    'Disk Read/Write Bytes': 'P3',
    'Disk Read/Write Operations': 'P3',
    'Disk Space Used': 'P3',
    'Domain Expiry': 'P1',
    'EMR Termination': 'P3',
    'Instance Creation': 'P3',
    'Instance Reboot': 'P1',
    'Instance Stopped': 'P1',
    'Instance Terminated': 'P1',
    'Maintenance Activity': 'P3',
    'AMI/Snapshots': 'P3',
    'Auto Start/stop': 'P3',
    'User Access': 'P3',
    'Memory Utilization': 'P3',
    'Network Adapter': 'P3',
    'Network In/Out (Bytes)': 'P3',
    'Status Check': 'P3',
    'Swap Memory Utilization': 'P3',
    Others: 'P3',
  },
  'RDS Instance': {
    Availability: 'P3',
    'Bin Log Disk Usage': 'P2',
    'CPU Utilization': 'P3',
    'DataBase Connections': 'P3',
    'Free Storage': 'P3',
    'Freeable Memory': 'P3',
    'Instance Creation': 'P3',
    'Instance Reboot': 'P1',
    'Instance Stopped': 'P1',
    'Instance Terminated': 'P1',
    'Local Storage': 'P3',
    'Maintenance Activity': 'P3',
    'Network Throughput': 'P3',
    "Read/Write IOP's": 'P3',
    'Read/Write Latency': 'P3',
    'Read/Write Operations': 'P3',
    'Read/Write Throughput': 'P3',
    'Swap Usage': 'P3',
    Others: 'P3',
  },
  'Microsoft IIS Server': {
    'Agent Service': 'P3',
    'Input/Output per second': 'P3',
    'Network Traffic': 'P3',
    'Private Virtual Memory': 'P3',
    'Processor Time': 'P3',
    Others: 'P3',
  },
  'Classic Load Balancer': {
    'Average Latency': 'P3',
    'Backend HTTP Error': 'P2',
    'ELB Errors': 'P2',
    Hosts: 'P3',
    'Request Count': 'P3',
    Termination: 'P3',
    Others: 'P3',
  },

  'Microsoft SQL Server': {
    'Agent Down': 'P1',
    'Buffer Cache': 'P3',
    'Connection Report': 'P3',
    Error: 'P2',
    'Page Life Expectancy': 'P3',
    'Page Operations': 'P3',
    Others: 'P3',
  },
  'Website/URL': {
    Availability: 'P3',
    'Bad Gateway': 'P2',
    'Connection Timed Out': 'P1',
    'DNS Time': 'P2',
    'Internal SerCha ErrCha': 'P2',
    'Read Time Out': 'P1',
    'Request URL does not exist': 'P1',
    'Response Time': 'P2',
    'Service Unavailable': 'P1',
    Others: 'P3',
  },
  'Server Monitor': {
    'Actual CPU Utilization': 'P3',
    Availability: 'P3',
    'CPU Utilization': 'P3',
    'Disk Space Used': 'P3',
    'Maintenance Activity': 'P3',
    'Memory Utilization': 'P3',
    'Agent Down': 'P2',
    'Agent Service': 'P2',
    Others: 'P3',
  },
  'Service Request': {
    'AMI/Snapshots': 'P3',
    'Auto Start/stop': 'P3',
    'Instance Creation': 'P3',
    'Instance Patching /Hardening': 'P3',
    'Instance Provisioning': 'P3',
    'Instance Reboot': 'P3',
    'Instance Stop': 'P3',
    'Instance Termination': 'P3',
    'Service Request': 'P3',
    'User Access': 'P3',
    Others: 'P3',
  },
  'AWS Notification': {
    'Case ID': 'P3',
    CloudTrail: 'P3',
    'SNS Topic Notification': 'P3',
    'Budget Alarm': 'P2',
    'Follow-up': 'P3',
    'Security Alert': 'P2',
    Others: 'P3',
  },
  Lambda: { Duration: 'P3', Errors: 'P2', Invocations: 'P3' },
  VPC: {
    'Tunnel creation': 'P3',
    'VPC Route table': 'P3',
    'Transitgate Route tables': 'P3',
    'Direct connect': 'P3',
    'Subnet Creation': 'P3',

    Others: 'P3',
  },
  'SAP BASIS': {
    'User ID Creations': '',
    'User ID Lock/Unlock': '',
    'Authorization Addition/Removal': '',
    'Transport Request': '',
    'Job Schedule Related': '',
    'Log Related': '',
    'Security Related': '',
    'Integration Related': '',
    'SNOTE': '',
    'Patching Related': '',
    'Solution Manager': '',
    'Backup and Restore': '',
    'System Related Issues': '',
    'DB Issues': '',
    Others: '',
  },
};
