<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">VPCs with Public Subnet</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
      <div class="KBBody table-responsive ">

        <table class="table table-hover  mb-0">
            <!-- <thead class="blue">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">VPC Name</td>
                <td scope="col">VPC ID</td>
                <td scope="col">CIDR</td> 
              <td scope="col">Total Subnets</td>
              <td scope="col">Public Subnets</td>
              <td scope="col">Private Subnets</td>
              
              
              </tr>
            </thead>
          

            <tbody >
              <ng-container *ngFor="let i of vpcList; let j = index">
              <tr >
                <td scope="row" class="m-4">{{j+1}}</td>
                <td>{{i.cidr.vpc_name}}</td>
                <td>{{i.vpc}}</td>
                <td>{{i.cidr.cidr_block}}</td>
              <td class="text-center">{{i.cidr.no_of_subnets}}</td>
              <td class="collapsible-cell text-center" (click)="i.cidr.public_subnets.length===0 ? nottoggleSubRow(j+'public') :  toggleSubRow(j+'public')">   
                <span> <i *ngIf="i.cidr.public_subnets.length!=0" class="fa-regular" [ngClass]="{'fa-square-plus': !expandedRows[j+'public'], 'fa-square-minus': expandedRows[j+'public']}"></i> &nbsp;        
                {{i.cidr.public_subnets.length}}  
                </span>   
              </td>
              <td class="collapsible-cell text-center" (click)="toggleSubRow(j+'private')">
                <span> <i *ngIf="i.cidr.private_subnets.length!=0" class="fa-regular" [ngClass]="{'fa-square-plus': !expandedRows[j+'private'], 'fa-square-minus': expandedRows[j+'private']}"></i> &nbsp;
                {{i.cidr.private_subnets.length}}</span></td>
              
              
              </tr>  
              
              <tr  *ngIf="expandedRows[j+'public']">
                <td  colspan="8">
                <table class="table table-hover">
                  <thead class="liteblue">
                    <tr>
                      <td>S.N.</td>
                      <td>Subnet ID</td>
                      <td>CIDR</td>
                      <td>Free IPs</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of i.cidr.public_subnets; let k = index" >
                      <td scope="row" class="m-4">{{k+1}}</td>
                      <td>{{item.subnet_id}}</td>
                      <td>{{item.cidr_block}}</td>
                      <td>{{item.free_ips}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              
              </tr>
                     
             <tr *ngIf="expandedRows[j+'private']">
                <td  colspan="8">
                <table class="table table-hover">
                  <thead class="liteblue">
                    <tr>
                      <td>S.N.</td>
                      <td>Subnet ID</td>
                      <td>CIDR</td>
                      <td>Free IPs</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of i.cidr.private_subnets; let k = index" >
                      <td scope="row" class="m-4">{{k+1}}</td>
                      <td>{{item.subnet_id}}</td>
                      <td>{{item.cidr_block}}</td>
                      <td>{{item.free_ips}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              </tr>
            </ng-container>
             
            </tbody>  -->
            <table class="table table-hover">
              <thead class="blue">
                <tr>
                  <td>S.N.</td>
                  <td>Subnet ID</td>
                  <td>CIDR</td>
                  <td>Free IPs</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of vpcList; let k = index" >
                  <td scope="row" class="m-4">{{k+1}}</td>
                  <td>{{item.subnet_id}}</td>
                  <td>{{item.cidr_block}}</td>
                  <td>{{item.free_ips}}</td>
                </tr>
              </tbody>
            </table>
        </table>
        
          <!-- <div class="btnHold">
            <div class="bhold">
              <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
              <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
              [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
            </div>
          </div> -->


        <!-- <div class="KBActions">
          <button class="btn btn-light" (click)="no()">Cancel</button>
        </div> -->
      
    </div>
          
</div>


