<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">Add to KB</div>
    <div class="KBBody">
      <div class="mb-2">
        <label>Ticket ID: </label>
        <input class="form-control" [(ngModel)]="ticket.caseId || ticket.issue_key" disabled>
      </div>
      <div class="mb-2">
        <label>Title: </label>
        <input class="form-control" [(ngModel)]="ticket.subject">
      </div>
      <div class="mb-2">
        <label>KBDB: </label>
        <input type="checkbox" [(ngModel)]="ticket.kbdb.selected">
        <input class="form-control" *ngIf="ticket.kbdb.selected" [(ngModel)]="ticket.kbdb.text"
          placeholder="Enter Text">
      </div>
      <div class="mb-2">
        <label>Description: </label>
        <textarea class="ckeditor_description_kb" id="ckeditor_description_kb" [(ngModel)]="ticket.history" name=""
          cols="30" rows="10"></textarea>
      </div>
    </div>
    <div class="KBActions">
      <button class="btn btn-success" (click)="yes()" [ngClass]="{'click-disabled': !writeAccess}"
        [attr.disabled]="!writeAccess">Add</button>
      <button class="btn btn-light" (click)="no()">Cancel</button>
    </div>
  </div>
</div>