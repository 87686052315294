import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'vpc-option-launch-select-dropdown',
  templateUrl: './vpc.dropdown.launch.component.html',
  styleUrls: ['./vpc.dropdown.launch.component.css'],
})
export class VPCLaunchDropdownComponent implements OnInit, OnDestroy {
  @Input() vpc: any;
  ngOnInit(): void {}

  constructor() {}

  ngOnDestroy(): void {}
}
