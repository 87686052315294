




<table class="table table-responsive table-hover">
    <!-- <thead>
        <tr>
            <th scope="col"></th>
            
            <th scope="col">Event Time</th>
            <th scope="col">Description</th>
            <th scope="col">Identifier</th>
            <th scope="col">Triggered By</th>
            
           
        
        </tr>
    </thead> -->
    <tbody>
      <!-- <ng-container *ngFor="let item of getItemsForPage(currentPage)">
        <tr>
            <td><input type="checkbox" name="" id=""></td>
           
            
            <td>{{item.eventTime}}</td>
            <td>{{item.description}}</td>
            <td>{{item.identifier}}</td>
            <td>{{item.createdBy}}</td>
            
        </tr>
      </ng-container> -->
      <tr>
      <td colspan="9" class="text-center">No Data Found!</td>
    </tr>
    </tbody>
    </table>
    
    <!-- <div class="d-flex justify-content-end">
    <nav>
      <ul class="pagination">
    <li class="page-item" [class.disabled]="currentPage ===1">
    <a class="page-link" (click)="navigateToPreviousPage()">Previous</a>
    </li>
    <li class="page-item" *ngFor="let page of getPageNumbers()" [class.active]="currentPage === page">
        <ng-container *ngIf="page === -1; else pageLink">
            <span class="page-link">...</span>
        </ng-container>
        <ng-template #pageLink>
            <a class="page-link" (click)="currentPage = page">{{page}}</a>
        </ng-template>
      
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="navigateToNextPage()">Next</a>
        </li>
      </ul>
    </nav>
    </div> -->


