<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader mb-2">Save Milestone</div>
    <div class="KBBody">
      <div class="col-mb-6 mb-2">
        <label>Workload</label>
        <div>{{workload['name']}} ({{workload['id']}})</div>
      </div>
      <div class="col-mb-6 mb-15">
        <label>Milestone Name <span class="required">*</span> </label>
        <span>
          <div class="input-info">A unique identifier for the milestone</div>
          <input autocomplete="off" class="form-control custom-input" type="text" [(ngModel)]="scan_name"
            [ngClass]="{'error-text': errors['milestone']['error']}">
          <div *ngIf="errors['milestone']['error']" class="error-message">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ errors['milestone']['message'] }}
          </div>
          <div class="input-info">
            The name must be from 3 to 100 characters. At least 3 characters must be non-whitespace. {{
            errors['milestone'].length - scan_name.trim().length }} characters remaining
          </div>
        </span>
      </div>
      <div class="KBActions">
        <button class="btn btn-success" *ngIf="!loading" (click)="yes()">Save</button>
        <button class="btn btn-success" *ngIf="loading" [attr.disabled]="true">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar-right" />
          Save
        </button>
        <button class="btn btn-light" (click)="no(false)">Cancel</button>
      </div>
    </div>
  </div>
</div>