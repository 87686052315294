<div *ngIf="!alerttab" class="container-fluid p-3">
    <div class="row">
        <div class="col p-1">
            <div class="card">
                <div class="card-header"><b>Alert Coverage</b></div>
                <div class="card-body p-2">
                    <div class="alertgraph" id="alertGraph"></div>
                </div>
            </div>
        </div>
        <div class="col p-1">
            <div class="card">
                <div class="card-header"><b>Alert By Severity</b></div>
                <div class="card-body">
                    <!-- <div class="row">
                        <div class="col p-1 d-flex flex-column bg-primary">
                            <span class="text-center">4</span>
                            <div>
                                div.
                               <span class="text-center">Critical</span> 
                            </div>
                        </div>
                        <div class="col p-1">2</div>
                        <div class="col p-1">3</div>
                        <div class="col p-1">4</div>
                        <div class="col p-1">5</div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col p-1">
            <div class="card">
                <div class="card-header"><b>Top Incidents & Risks</b></div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-hover mb-0">
                          <thead class="blue">
                            <tr>
                              <td scope="col">Policy Type/Name</td>
                              <td scope="col">Alerts</td> 
                            </tr>
                          </thead>
                          <tbody >
                            
                            <tr *ngFor="let i of alertWithCount;">
                              
                              <td>{{i['type']}}</td>
                              <td><span class="countLink" (click)="filterByPolicyType(i['type'])">{{i['count']}}</span>/{{totalAlerts}}</td>
                            </tr>             
                          </tbody> 
                        </table>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-view-alerts *ngIf="alerttab" [alertList]="filterAlerts" (hideModal)="hidealertTab($event)"></app-view-alerts>