import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as echarts from "echarts";
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-ins-tab',
  templateUrl: './view-ins-tab.component.html',
  styleUrls: ['./view-ins-tab.component.css']
})
export class ViewInsTabComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() instancesTypes :any=[];
  @Input() regionHeader:any =[];

  constructor(public notifier: NotifierService,) { }

  ngOnInit(): void {
    this.notifier.disableScroll();

  }

  toggleItem(item: any): void {
    console.log(item);
    item.isHidden = !item.isHidden; } 
  no(){
    this.hideModal.emit({ status: 0 });
   
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}

}
