<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">
      Associate Profile
    </div>
    <div class="KBBody">
      <div class="col-mb-6 mb-15">
        <label class="lg-text">Account ID</label>
        <span>{{ accountId }}</span>
      </div>
      <div class="col-mb-6 mb-15">
        <label class="lg-text">Workload</label>
        <span>{{ workload['name'] }}</span>
      </div>
      <div class="col-mb-6 mb-15">
        <label class="lg-text"
          >Select Profile<span class="required">*</span>:
        </label>
        <span>
          <select id="profileSelector" data-live-search="true" [(ngModel)]="profileSelected">
            <option [ngValue]="profile" *ngFor="let profile of profile_region">{{ profile['profileName'] }}</option>
          </select>
          <div *ngIf="errors['profile']['error']" class="error-message">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ errors["profile"]["message"] }}
          </div>
        </span>
      </div>
    </div>
    <div class="KBActions">
      <button class="btn btn-success-soft" *ngIf="!loading" [attr.disabled]="!profileSelected ? true : null" (click)="associateProfile()">
        Associate
      </button>
      <button class="btn btn-success-soft" *ngIf="loading" [attr.disabled]="true">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="width-mar-18"
        />
        Associate
      </button>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>
