import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";

declare let window: any;

@Component({
  selector: "app-create-cams",
  templateUrl: "./create-cams.component.html",
  styleUrls: ["./create-cams.component.css"],
})
export class CreateCamsComponent implements OnInit, OnDestroy {
  accountId: string;
  regionId: string;
  currentMessage: any;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.checkCAMsStatus();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.checkCAMsStatus();
      }
    });
  }

  arns: any = [];

  loading: any = {
    status: true,
    create: false,
  };

  resources: any = {
    ec2: [],
    elb: [],
    rds: [],
    cdn: [],
  };

  emailList: any = [{ email: "" }];
  scannerID: string = window.uuidv4();

  step: string = "Create";
  interval: string = "1";
  intervalFrequency: string = "hours";
  scannerName: string;
  validate: boolean = false;

  addEmail() {
    this.emailList.push({ email: "" });
  }

  removeEmail(index: number) {
    this.emailList.splice(index, 1);
  }

  loadResources() {
    this.step = "Configure Resources";
  }

  closeValidate(event: any) {
    if (event["status"]) {
      this.checkCAMsStatus();
    }
    this.validate = false;
  }

  getSelectedCount(action: string) {
    return this.resources[action].filter((resource: any) => {
      return resource["selected"];
    });
  }

  async createScanner() {
    this.loading["create"] = true;
    let data: any = {
      action: "add",
      role_arn: this.arns[0]["role_arn"],
      scanner_id: this.scannerID,
      payload: {
        account_id: this.accountId,
        scanner_id: this.scannerID,
        testscanner: {
          ec2: {},
          elb: {},
          rds: {},
          cloudfront: {},
        },
      },
      scanner_name: this.scannerName,
      emails_list: this.emailList.map((email: any) => {
        return email["email"];
      }),
      interval: this.interval + this.intervalFrequency,
    };

    data["payload"]["testscanner"]["ec2"][`${this.regionId}`] = {};
    this.getSelectedCount("ec2").forEach((ec2: any) => {
      data["payload"]["testscanner"]["ec2"][`${this.regionId}`][
        `${ec2["instance_id"]}`
      ] = {
        instance_type: ec2["instance_type"],
        url: ec2["alert"]["protocol"] + ec2["alert"]["url"],
        method: ec2["alert"]["method"],
        port: ec2["alert"]["port"],
        success_code: ec2["alert"]["success_code"],
      };
    });

    data["payload"]["testscanner"]["elb"][`${this.regionId}`] = {};
    this.getSelectedCount("elb").forEach((elb: any) => {
      data["payload"]["testscanner"]["elb"][`${this.regionId}`][
        `${elb["elb_arn"]}`
      ] = {
        url: elb["alert"]["protocol"] + elb["alert"]["url"],
        method: elb["alert"]["method"],
        port: elb["alert"]["port"],
        success_code: elb["alert"]["success_code"],
      };
    });

    data["payload"]["testscanner"]["cloudfront"][`${this.regionId}`] = {};
    this.getSelectedCount("cdn").forEach((cdn: any) => {
      data["payload"]["testscanner"]["cloudfront"][`${this.regionId}`][
        `${cdn["arn"]}`
      ] = {
        url: cdn["alert"]["protocol"] + cdn["alert"]["url"],
        method: cdn["alert"]["method"],
        port: cdn["alert"]["port"],
        success_code: cdn["alert"]["success_code"],
      };
    });

    data["payload"]["testscanner"]["rds"][`${this.regionId}`] = {};
    this.getSelectedCount("rds").forEach((rds: any) => {
      data["payload"]["testscanner"]["rds"][`${this.regionId}`][
        `${rds["db_id"]}`
      ] = {
        instance_type: rds["db_class"],
      };
    });

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/alerts`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      console.log(result);
    } else {
      this.notifier.alert("Info", "", result.message, "info", 5000);
    }

    this.loading["create"] = false;
  }

  async checkCAMsStatus() {
    this.loading["status"] = true;
    let data: any = {
      action: "get",
      account_id: this.accountId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/account`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      this.arns = result["items"];
      if (this.arns.length == 0) {
        this.step = "IAM Policy";
      } else {
        this.step = "Create";
      }
    }

    this.loading["status"] = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
