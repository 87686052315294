<div class="fixed">
    <div class="absolute-container">
        <div class="container-header flex-between">
            <div>Attachments</div>
            <div><i class="fa f-times"></i></div>
        </div>
        <div class="container-body">
            <select [(ngModel)]="question" class="form-select" *ngIf="!disable">
                <option value="All">All</option>
                <option [ngValue]="que" *ngFor="let que of questions">{{ que['check_id'] }}</option>
            </select>
            <select [ngModel]="question" disabled class="form-select" *ngIf="disable">
                <option [ngValue]="question">{{ question['check_id'] }}</option>
            </select>
            <ng-container *ngIf="uploadFiles.length == 0">
                <div class="file_uploader" (dragover)="startDrag($event)" *ngIf="question != 'All'" (dragleave)="endDrag($event)" (drop)="drop($event)" (click)="fetchFiles($event)">
                    Drag and drop or click here to upload files
                </div>
                <input type="file" multiple="true" class="hidden-input-file" id="hidden-input-file" (change)="changeInput($event)">
                <div class="files">
                    <h5>Files</h5>
                    <ng-container *ngFor="let file of getFiles()">
                        <div class="file flex-between" *ngIf="question && (question == 'All' || file['question_id'] == question['check_id'])">
                            <div class="flex-start">
                                <i class="fa fa-file"></i>
                                <div>
                                    <div class="file_name">
                                        {{ file['file_path'] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="center" *ngIf="getFiles().length == 0">
                        No files found
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="container-body" *ngIf="uploadFiles.length > 0">
            <div class="files">
                <div class="flex-between">
                    <h5>
                        Files to Upload
                    </h5>
                    <div *ngIf="!loading"><i (click)="uploadFiles = []" class="fa fa-times"></i></div>
                </div>
                <div class="file flex-between" *ngFor="let file of uploadFiles; let i = index">
                    <div class="flex-start">
                        <i class="fa fa-file"></i>
                        <div>
                            <div class="file_name">
                                {{ file['name'] }}
                            </div>
                            <div class="file_size">
                                <ng-container *ngIf="uploadFilesMeta[i]['status'] == 'uploading'">{{formatBytes(uploadFilesMeta[i]['loaded'])}} / </ng-container>{{ formatBytes(file['size']) }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <i class="fa fa-times" *ngIf="uploadFilesMeta[i]['status'] != 'uploading' && uploadFilesMeta[i]['status'] != 'completed' && !loading" (click)="removeFile(i)"></i>
                        <i class="fa fa-check-circle" *ngIf="uploadFilesMeta[i]['status'] == 'completed'"></i>
                        <img
                            src="../assets/img/loading_2.svg"
                            alt="loading scans"
                            class="wid-14"
                            *ngIf="uploadFilesMeta[i]['status'] == 'uploading'"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="container-footer flex-end" *ngIf="!loading">
            <button class="btn btn-light" (click)="hideModel.emit(true)">Cancel</button>
            <ng-container *ngIf="uploadFiles.length > 0">
                <button class="btn btn-light" (click)="uploadFiles = []">Go to Files</button>
                <button class="btn btn-success-soft" (click)="submitFiles()">Upload</button>
            </ng-container>
        </div>
    </div>
</div>