import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-governance",
  templateUrl: "./governance.component.html",
  styleUrls: ["./governance.component.css"],
})
export class GovernanceComponent {
  @Input("governanceRes") governanceRes: any;
  @Input("isgovRes") isgovRes: any;
  @Input("govAll") govAll: any;
  @Output() idlist = new EventEmitter<any[]>();
  @Output() idlistdailygov = new EventEmitter<any[]>();
  currentPage: number = 1;
  itemsPerPage: number = 10;
  idLis: any = [];
  idlistdgov:any = [];
  constructor() {}

  ngOnInit(): void {
  }

  markAll() {
    const areAllSelected = this.governanceRes.every((item: any) => item.check);
    this.governanceRes.forEach((item: any) => {
      item.check = !areAllSelected;
    if(item.type === undefined){
      if (
        !areAllSelected &&
        !this.idLis.includes(item.id) &&
        item.status === "incomplete"
      ) {
        this.idLis.push(item.id);
      } else if (areAllSelected) {
        this.idLis = this.idLis.filter((id: any) => id !== item.id);
      }
    }else if(item.type==='dailyGov'){
      if (
        !areAllSelected &&
        !this.idlistdgov.includes(item.id) &&
        item.status === "incomplete"
      ) {
        this.idlistdgov.push(item.id);
      } else if (areAllSelected) {
        this.idlistdgov = this.idlistdgov.filter((id: any) => id !== item.id);
      }
    }
    });
    this.idlist.emit(this.idLis);
    this.idlistdailygov.emit(this.idlistdgov);
  }

  updateStatus(id: any,type:any) {
    if(type===undefined){
 const index = this.idLis.indexOf(id);
    if (index === -1) {
      this.idLis.push(id);
    } else {
      this.idLis.splice(index, 1);
    }
    this.idlist.emit(this.idLis);
    }
    else if(type==='dailyGov'){
      const index = this.idlistdgov.indexOf(id);
      if (index === -1) {
        this.idlistdgov.push(id);
      } else {
        this.idlistdgov.splice(index, 1);
      }
     this.idlistdailygov.emit(this.idlistdgov);
    }
  }

  get totalPages(): number {
    return Math.ceil(this.governanceRes.length / this.itemsPerPage);
  }
  getItemsForPage(pageNumber: number): any[] {
    const startIndex = (pageNumber - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.governanceRes.slice(startIndex, endIndex);
  }

  navigateToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  navigateToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  // getPageNumbers(): number[] {
  //   return Array(this.totalPages)
  //     .fill(0)
  //     .map((_, i) => i + 1);
  // }
  navigateToPage(page: number | '...') { if (page !== '...') { this.currentPage = page as number; } } 
  getPageNumbers(): (number | "...")[] {
    const totalPageCount = this.totalPages;
    const maxVisiblePages = 5;
    let start = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
    let end = Math.min(start + maxVisiblePages - 1, totalPageCount);
    start = Math.max(1, end - maxVisiblePages + 1);
    const pageNumbers: (number | "...")[] = [];
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    if (start > 1) {
      pageNumbers.unshift("...");
    }
    if (end < totalPageCount) {
      pageNumbers.push("...");
    }
    return pageNumbers;
  }
  formatDate(date: any): any {
    if (date !== "") {
      return moment
        .utc(date, "YYYY-MM-DDTHH:mm:ss")
        .local()
        .format("MMM DD, YYYY hh:mm A");
    }
    return "";
  }
}
