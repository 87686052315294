import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'tell-me-rules-viewer',
  templateUrl: './rules-viewer.component.html',
  styleUrls: ['./rules-viewer.component.css', './../../../../tell-me.component.css']
})
export class RulesViewerComponent implements OnInit {
  @Input('tellMes') tellMes: any;
  @Input('types') types: any;
  @Output('hideModel') hideModel: any = new EventEmitter<void>();
  selected: string = '';
  constructor() { }

  types_v2: any = {
    "every-30-mins": {
      name: "Every 30 Mins",
      tabs: {
        security: {
          name: "Security",
        },
        governance: {
          name: "Governance",
        },
      },
    },
    "every-day": {
      name: "Daily Once",
      tabs: {
        dailySpend: {
          name: "Daily Spend",
        },
        dailyGovernance: {
          name: "Daily Governance",
        },
        dailyCost: {
          name: "Daily Cost",
        },
      },
    },
  };

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  ngOnInit(): void {
    this.selected = this.types[0];
  }

}
