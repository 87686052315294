import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

declare let html2pdf: any;

@Component({
  selector: "app-swayam-docs",
  templateUrl: "./swayam-docs.component.html",
  styleUrls: ["./swayam-docs.component.css"],
})
export class SwayamDocsComponent implements OnInit {
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  components: any = [
    // {
    //   text: "A High Availability Web Architecture Solution",
    //   check: false,
    // },
    // {
    //   text: "A single zone HA SAP Solution",
    //   check: false,
    // },
    // {
    //   text: "Landing Zone",
    //   check: false,
    // },
    // {
    //   text: "Layer 4 security",
    //   check: false,
    // },
    // {
    //   text: "Layer 7 security",
    //   check: false,
    // },
    // {
    //   text: "HIPS/HIDS",
    //   check: false,
    // },
    // {
    //   text: "Include the standard solution architecture",
    //   check: false,
    // },
    {
      text: "Solution Document for Agritech",
      check: false,
      prompt:
        "Create a proposal document for an agritech solution on AWS, including the aws services used as well",
    },
    {
      text: "Solution Document for Disaster Recovery",
      check: false,
      prompt: "Create a proposal document for DR on aws",
    },
    {
      text: "Solution Document for MAP",
      check: false,
      prompt: "Create a proposal document for MAP on AWS",
    },
    {
      text: "Solution Document for POC",
      check: false,
      prompt: "Create a proposal document for POC on AWS",
    },
    {
      text: "Solution Document for Forecasting",
      check: false,
      prompt: "Create a proposal document for forecasting solution on AWS",
    },
    {
      text: "Solution Document for Health Care",
      check: false,
      prompt: "Create a proposal document for healthcare solution on AWS",
    },
    {
      text: "Solution Document for Fintech",
      check: false,
      prompt:
        "Create a proposal document for a fintech solution on AWS, including the aws services used as well",
    },
    {
      text: "Solution Document for Edtech",
      check: false,
      prompt:
        "Create a proposal document for an edtech solution on AWS, including the aws services used as well",
    },
    {
      text: "Solution Document for WAFR",
      check: false,
      prompt: "Create a proposal document for WAFR agreement",
    },
  ];

  document: any = "";

  displayed: any = "";

  additional: any = [
    {
      text: "Cover Page",
      check: false,
    },
    {
      text: "Brand",
      check: false,
      show: false,
    },
    {
      text: "Sign",
      check: false,
      data: null,
    },
  ];

  variables: any = {};
  generating: boolean = false;
  generated: boolean = false;
  loading: boolean = false;

  ngOnInit(): void {}

  getSelectedAdditional(text: string) {
    return this.additional.find((component: any) => {
      return component["text"] == text;
    });
  }

  triggerUploadImage() {
    let element: any = document.querySelector(".image-uploader-docs");
    element.click();
  }

  setImageDataURL(event: any) {
    const file = event.target.files[0];
    this.showImage(file);
  }

  showImage(file: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      let additional = this.getSelectedAdditional("Sign");
      additional["data"] = reader.result;
    });
    reader.readAsDataURL(file);
  }

  format() {
    let check: boolean = true;
    while (check) {
      let variable = this.document.substring(
        this.document.indexOf("[") + 1,
        this.document.indexOf("]")
      );

      this.variables[variable] = {
        type: "string",
        value: "",
      };

      if (variable.toLowerCase().includes("date")) {
        this.variables[variable]["type"] = "date";
      }

      if (variable.toLowerCase().includes("address")) {
        this.variables[variable]["type"] = "textbox";
      }

      this.document = this.document.replace(
        `[${variable}]`,
        `###${variable}***`
      );
      if (!this.document.includes("[")) {
        check = false;
      }
    }

    let tableHTML: string = `<table class='table table-docs'><thead><tr>[[ thead ]]</tr></thaed><tbody>[[ tbody ]]</tbody></table>`;
    let replacable: string = ``;
    let tableStarted: boolean = false;
    this.document.split("\n").forEach((lineItem: any, index: number) => {
      if (
        lineItem.replaceAll(" ", "").startsWith("|--") &&
        lineItem.replaceAll(" ", "").endsWith("--|")
      ) {
        replacable += lineItem + "\n";
        return;
      }
      if (
        lineItem.replaceAll(" ", "").startsWith("|") &&
        lineItem.replaceAll(" ", "").endsWith("|")
      ) {
        if (!tableStarted) {
          lineItem
            .split("|")
            .slice(1, -1)
            .forEach((th: string) => {
              tableHTML = tableHTML.replace(
                "[[ thead ]]",
                `<th>${th.trim()}</th>[[ thead ]]`
              );
            });
        } else {
          tableHTML = tableHTML.replace(
            "[[ tbody ]]",
            `<tr>${lineItem
              .split("|")
              .slice(1, -1)
              .map((td: string) => {
                return `<td>${td.trim()}</td>`;
              })
              .join("")}</tr>[[ tbody ]]`
          );
        }
        replacable += lineItem + "\n";
        tableStarted = true;
      } else {
        if (tableStarted) {
          tableHTML = tableHTML.replace("[[ thead ]]", "");
          tableHTML = tableHTML.replace("[[ tbody ]]", "");
          // console.log(tableHTML);
        }
        if (replacable != "") {
          this.document = this.document.replace(replacable, tableHTML);
          replacable = "";
        }
        tableStarted = false;
        tableHTML = `<table class='table table-docs'><thead><tr>[[ thead ]]</tr></thaed><tbody>[[ tbody ]]</tbody></table>`;
      }
    });
    if (tableStarted) {
      tableHTML = tableHTML.replace("[[ thead ]]", "");
      tableHTML = tableHTML.replace("[[ tbody ]]", "");

      // console.log(tableHTML);
      if (replacable != "") {
        replacable = replacable.slice(0, -1);
        this.document = this.document.replace(replacable, tableHTML);
        replacable = "";
      }
      tableStarted = false;
      tableHTML = `<table><thead><tr>[[ thead ]]</tr></thaed><tbody>[[ tbody ]]</tbody></table>`;
    }
    this.document = this.document.replaceAll("\n", "<br>");
    this.generating = true;
    this.generate();
  }

  generate() {
    let appended: number = 0;
    this.displayed = "";
    let total: string[] = this.document.trim().split(" ");
    this.generating = true;
    let interval = setInterval(() => {
      if (appended > total.length) {
        clearInterval(interval);
        this.generating = false;
        return;
      }
      if (total[appended] != undefined) {
        this.displayed += total[appended] + " ";
      }
      appended++;
      window.scrollTo(0, document.body.scrollHeight);
    }, 50);
  }

  checkForSelection(component: string) {
    return this.additional.find((i: any) => {
      return i.text == component;
    })["check"];
  }

  getSelected() {
    return this.components
      .filter((component: any) => {
        return component["check"];
      })
      .map((component: any) => {
        return component["text"];
      })
      .join(", ");
  }

  getString(text: string) {
    // console.log(text);

    if (text.includes("***")) {
      return text.slice(text.indexOf("***") + 3);
    } else {
      return text;
    }
  }

  segregateText(text: string, param: string) {
    return text.split(param);
  }

  getVariable(text: string) {
    return text.slice(0, text.indexOf("***"));
  }

  getCurrentMonthYear() {
    return moment().format("MMMM YYYY");
  }

  exportPDF() {
    let element: any = document.createElement("div");

    if (this.checkForSelection("Brand")) {
      element.innerHTML += `
        <style>
          .header { position: fixed; top: 0; left: 0; right: 0; height: 50px; background-color: #f5f5f5; padding: 10px; font-weight: bold; text-align: center; }
          .header:first-child { display: none; }
          @page {
            .header {
              display: block;
            }
          }
        </style>
        <div class='header'>
          Testing
        </div>
      `;
    }
    if (this.checkForSelection("Cover Page")) {
      element.innerHTML += `
        <style>
          @page:not(:first-child) {
            margin: 20px;
          }
          .cover-page-container-pdf {
            background-image: url('/assets/img/cover-page.png');
            width: 100%;
            color: #fff;
            margin: 0px;
            padding: 0px;
          }
          
        </style>
        <div class="cover-page-container-pdf">
          <div style='padding: 200px 0px 100px 100px; color: #fff; font-weight: bold;'>
            <h1>Statement of Work</h1>
          </div>
          <div style='padding: 0px 100px 100px 100px;'>
            <h1 style='font-weight: bold;'>SREI</h1>
            <h1>Minfy Technologies</h1>
          </div>
          <div style='padding: 0px 100px 100px 100px;'>
            <h1>${moment().format("MMMM YYYY")}</h1>
          </div>
          <div class='newPage'></div>
        </div>
      `;
    }

    this.document.split("<br>").forEach((para: any) => {
      element.innerHTML += `
        <span class='segregator'>
          ${para}
        </span>
        <br>
      `;
    });

    if (
      this.checkForSelection("Sign") &&
      this.getSelectedAdditional("Sign")["data"]
    ) {
      element.innerHTML += `
        <br>
        <span class='segregator'>
          <img src='${this.getSelectedAdditional("Sign")["data"]}'>
        </span>
      `;
    }

    let file_name: string = this.components.find((component: any) => {
      return component["check"];
    })["text"];

    Object.keys(this.variables).forEach((variable: string) => {
      element.innerHTML = element.innerHTML.replaceAll(
        `###${variable}***`,
        this.variables[variable]["value"]
      );
    });

    var opt = {
      onBeforeSave: () => {
        const pages: any = document.querySelectorAll(".html2pdf__page");
        pages.forEach((page: any, index: number) => {
          const header = page.querySelector(".header");
          if (index === 0) {
            header.style.display = "none";
          }
        });
      },
      margin: 10,

      filename: `${file_name}.pdf`,

      image: { type: "png", quality: 1 },

      html2canvas: { dpi: 192, scale: 2, letterRendering: true, useCORS: true },

      pagebreak: {
        before: ".newPage",
        avoid: ["h2", "h3", "h4", "td", "tr", ".segregator"],
      },

      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  }

  prompt: string = "";
  onSelect(component: any) {
    this.components.forEach((component_: any) => {
      if (component_["text"] != component["text"]) {
        component_["check"] = false;
      }
    });
    component["check"] = true;
    this.prompt = component["prompt"];
  }

  async generateAPI() {
    this.loading = true;
    let apiURL: string = `https://apis-eks.swayam.cloud/global_notification/search/?email_id=ramit.surana%40minfytech.com&category=poc_sow_documents&search_query_input=${this.prompt}&user_type=client`;

    let header: any = {
      Authorization: `Bearer ${localStorage.getItem("t")}`,
    };

    let result: any = await this.apiService.postDataPromis(apiURL, {}, header);

    if (result.doc_url) {
      let s3Object: any = await this.apiService.getDataPromis(
        result.doc_url,
        {},
        {}
      );

      this.document = s3Object["Search_Response"];
      this.format();
    }
    this.loading = false;
  }
}
