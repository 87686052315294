import { Component, OnInit } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let Jhxlsx: any;

@Component({
  selector: "app-databases",
  templateUrl: "./databases.component.html",
  styleUrls: ["./databases.component.css"],
})
export class DatabasesComponent implements OnInit {
  accountId: string;
  regionId: string;
  resourceGroup: string;
  userId: string = localStorage.getItem("eId");

  headers: any = {
    sql_database: [
      { id: "name", name: "Name" },
      { id: "version", name: "Version" },
      { id: "state", name: "State" },
      { id: "storage_in_mb", name: "Storage (MB)" },
      { id: "backup", name: "Backup" },
      { id: "backup_retention_days", name: "Backup Retention Days" },
      { id: "storage_autogrow", name: "Storage Autogrow" },
      { id: "public_network_access", name: "Public Network Access" },
      { id: "ssl_enforcement", name: "SSL Enforcement" },
      { id: "retention_days", name: "Retention (Days)" },
      { id: "security_state", name: "Security State" },
      { id: "disabled_alerts", name: "Disabled Alerts" },
      { id: "sql_encryption", name: "SQL Encryption" },
      { id: "sql_audit_policies", name: "SQL Audit Policies" },
    ],
    maria_database: [
      { id: "name", name: "Name" },
      { id: "tier", name: "Tier" },
      { id: "version", name: "Version" },
      { id: "storage_in_mb", name: "Storage (MB)" },
      { id: "backup", name: "Backup" },
      { id: "backup_retention_days", name: "Backup Retention Days" },
      { id: "storage_autogrow", name: "Storage Autogrow" },
      { id: "public_network_access", name: "Public Network Access" },
      { id: "ssl_enforcement", name: "SSL Enforcement" },
      { id: "state", name: "State" },
    ],
    mysql_database: [
      { id: "name", name: "Name" },
      { id: "location", name: "Location" },
      { id: "version", name: "Version" },
      { id: "availability_zone", name: "Availability Zone" },
      { id: "create_mode", name: "Create Mode" },
      { id: "source_server_resource_id", name: "Source Server Resource ID" },
      { id: "restore_point_in_time", name: "Restore Point In Time" },
      { id: "replication_role", name: "Replication Role" },
      { id: "data_encryption", name: "Data Encryption" },
      { id: "storage_in_gb", name: "Storage (GB)" },
      { id: "backup", name: "Backup" },
      { id: "high_availability", name: "High Availability" },
      { id: "network", name: "Network" },
    ],
    postgresql_database: [
      // { id: "name", name: "Name" },
      { id: "location", name: "Location" },
      { id: "version", name: "Version" },
      { id: "availability_zone", name: "Availability Zone" },
      { id: "create_mode", name: "Create Mode" },
      { id: "source_server_resource_id", name: "Source Server Resource ID" },
      { id: "point_in_time_utc", name: "Point In Time (UTC)" },
      { id: "storage_in_gb", name: "Storage (GB)" },
      { id: "backup", name: "Backup" },
      { id: "high_availability", name: "High Availability" },
      { id: "network", name: "Network" },
    ],
    cosmo_databases: [
      { id: "name", name: "Name" },
      { id: "database_id", name: "Database ID" },
      { id: "account_type", name: "Account Type" },
      { id: "location", name: "Location" },
      { id: "DB_Engine", name: "DB Engine" },
      { id: "cosmodb_tags", name: "CosmoDB Tags" },
      { id: "cosmodb_identity", name: "CosmoDB Identity" },
      { id: "cosmodb_systemdata", name: "CosmoDB Systemdata" },
      { id: "offer_type", name: "Offer Type" },
      { id: "ip_rules", name: "IP Rules" },
      { id: "free_tire", name: "Free Tire" },
      { id: "network_access", name: "Network Access" },
      { id: "capacity", name: "Capacity" },
      { id: "server_version", name: "Server Version" },
      { id: "backup_policy", name: "Backup Policy" },
      { id: "fail_over_policy", name: "Fail Over Policy" },
      { id: "is_virtual_network_enabled", name: "Virtual Network Status" },
      { id: "restore_information", name: "Restore Information" },
      { id: "enable_automatic_failover", name: "Enable Automatic Failover" },
    ],
  };

  currentMessage: any;

  loading: boolean = true;
  destroyed: boolean = false;

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  databases: any = [];

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.selectedDatabase = this.supportedDatabases[0]["id"];
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.resourceGroup = localStorage.getItem("selectedResourceGroup");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }

      if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
  }
  load() {
    this.loadVMs();
  }

  selectedDatabase: string;
  supportedDatabases: any = [
    {
      id: "postgresql_database",
      name: "Postgre SQL Databases",
    },
    {
      id: "mysql_database",
      name: "MySQL Databases",
    },
    {
      id: "maria_database",
      name: "Maria Databases",
    },
    {
      id: "sql_database",
      name: "SQL Databases",
    },
    // {
    //   id: "cosmo_databases",
    //   name: "Cosmo Databases",
    // },
  ];

  async loadVMs() {
    this.loading = true;
    this.databases = [];

    const data: any = {
      action: "list_databases",
      user_id:
        this.urlPrefix == "admin" &&
        (localStorage.getItem("selectedAccountGroup") ==
          "DevelopmentTestGroup" ||
          localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
          ? "rakeshkumar.t@minfytech.com"
          : this.userId,
      subscription_id: localStorage.getItem("accountId"),
      resource_group_name: this.resourceGroup,
      database_type: this.selectedDatabase,
    };

    const header: any = {
      Authorization: localStorage.getItem("t"),
    };

    const apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/central`;

    let result: any = await this.apiService.postDataPromis(
      apiUrl,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.databases = result[this.selectedDatabase].map((database: any) => {
        Object.keys(database).forEach((key: any) => {
          if(database[key] && typeof database[key] == 'object') {
            Object.keys(database[key]).forEach((key_: string) => {
              database[key_] = database[key][key_]
            })
          }
        })
        return database;
      });
    }

    this.loading = false;
  }

  getSelectedDatabase() {
    return this.supportedDatabases.find((database: any) => {
      return database["id"] === this.selectedDatabase;
    })["name"];
  }

  export() {
    let data: any = {
      sheetName: this.getSelectedDatabase(),
      data: [],
    };

    data["data"].push(
      this.headers[this.selectedDatabase].map((h: any) => {
        return { text: h["name"] };
      })
    );

    if (this.databases.length > 0) {
      this.databases.forEach((database: any) => {
        data["data"].push(
          this.headers[this.selectedDatabase].map((h: any) => {
            return { text: database[h.id] };
          })
        );
      });
    }

    var options = {
      fileName: this.getSelectedDatabase(),
    };

    Jhxlsx.export([data], options);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
    this.destroyed = true;
  }
}
