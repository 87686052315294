import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: [
    './save-dialog.component.css',
    './../../../../warquiz.component.css',
  ],
})
export class SaveDialogComponent implements OnInit {
  @Output('popupAction') popupAction: any = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  action(action: string) {
    this.popupAction.emit(action);
  }
}
