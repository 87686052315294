<div class="card">
  <h5 class="card-header">RDS</h5>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="rds-instance-tab" data-bs-toggle="tab" data-bs-target="#rds-instance"
          type="button" role="tab" aria-controls="rds-instance" aria-selected="true">RDS Instance</button>
        <button class="nav-link" id="rds-cluster-tab" data-bs-toggle="tab" data-bs-target="#rds-cluster" type="button"
          role="tab" aria-controls="rds-cluster" aria-selected="false">RDS Cluster</button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="rds-instance" role="tabpanel" aria-labelledby="rds-instance-tab">
        <div class="card tab-style">
          <!--<h5 class="card-header"></h5>-->

          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->

            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of rdsHeaders">{{h.name}}</th>
                  <th>Tags</th>
                  <th>VPC Group</th>
                  <th *ngIf="writeAccess">Action</th>
                </tr>
              </thead>

              <tbody [ngStyle]="{'display': rdsList.length > 0 ? 'table-row-group': 'none'}"
                *ngFor="let i of rdsList; let j = index">
                <tr>
                  <td>
                    {{j+1}}
                  </td>
                  <td *ngFor="let h of rdsHeaders">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        <span *ngIf="i[h.id]">{{i[h.id]}}</span>
                        <span *ngIf="!i[h.id]">{{i[h.alternate]}}</span>
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      <span *ngIf="i[h.id]">{{i[h.id]}}</span>
                      <span *ngIf="!i[h.id]">{{i[h.alternate]}}</span>
                    </span>
                  </td>
                  <td><span class="btn" (click)="showTags(i, 'rds')"> Tags</span> </td>
                  <td><span class="btn" (click)="loadVPCGroup(i.vpcGroupList)">VPC Group</span></td>
                  <td *ngIf="writeAccess && i.dbStatus == 'available'"><span class="btn"
                      (click)="startStopTrigger(i, 'stop', 'rdsStop')">Stop</span></td>
                  <td *ngIf="writeAccess && i.dbStatus == 'stopped'"><span class="btn"
                      (click)="startStopTrigger(i, 'run', 'rdsStart')">Start</span></td>
                  <td *ngIf="writeAccess && i.dbStatus != 'stopped' && i.dbStatus != 'available'"><span
                      class="db-status-style" disabled>{{i.dbStatus}}</span></td>
                </tr>
              </tbody>
              <tbody [ngStyle]="{'display': rdsList.length == 0 ? 'table-row-group' : 'none'}">
                <tr>
                  <td [attr.colspan]="rdsHeaders.length + 3">
                    <div class="rds-notfound">No RDS Instances Found</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="rds-cluster" role="tabpanel" aria-labelledby="rds-cluster-tab">
        <div class="card tab-style">
          <!--<h5 class="card-header"></h5>-->
          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->

            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of rdsClsuterHeaders">{{h.name}}</th>
                  <th>Tags</th>
                  <th *ngIf="writeAccess">Action</th>
                </tr>
              </thead>

              <tbody *ngFor="let i of rdsClusterList; let j = index">
                <tr>
                  <td>
                    {{j+1}}<i *ngIf="!i.showNested" class="fa fa-caret-right"></i> <i *ngIf="i.showNested"
                      class="fa fa-caret-down"></i>
                  </td>
                  <td *ngFor="let h of rdsClsuterHeaders">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        <span *ngIf="i[h.id]">{{i[h.id]}}</span>
                        <span *ngIf="!i[h.id]">{{i[h.alternate]}}</span>
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      <span *ngIf="i[h.id]">{{i[h.id]}}</span>
                      <span *ngIf="!i[h.id]">{{i[h.alternate]}}</span>
                    </span>
                  </td>
                  <td><span class="btn" (click)="showTags(i, 'rdsCluster')">Tags</span> </td>
                  <td *ngIf="writeAccess && i.status == 'available'"><span class="btn"
                      (click)="startStopTrigger(i, 'stop', 'stopCluster')">Stop</span></td>
                  <td *ngIf="writeAccess && i.status == 'stopped'"><span class="btn"
                      (click)="startStopTrigger(i, 'run', 'startCluster')">Start</span></td>
                </tr>
                <tr *ngIf="i.showNested" class="clusterheight">
                  <td [attr.colspan]="rdsClsuterHeaders.length + 3">
                    <table class="table table-docs table-hover">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th *ngFor="let h of rdsClsuterInstanceHeaders">{{h.name}}</th>
                          <th>VPC Group</th>
                        </tr>
                      </thead>

                      <tbody *ngFor="let j of i.clusterList; let k = index">
                        <tr>
                          <td>
                            {{k+1}}
                          </td>
                          <td *ngFor="let h of rdsClsuterInstanceHeaders">
                            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, j)">
                              <span>
                                <span *ngIf="j[h.id]">{{j[h.id]}}</span>
                                <span *ngIf="!j[h.id]">{{j[h.alternate]}}</span>
                              </span>
                            </span>
                            <span *ngIf="!h.click">
                              <span *ngIf="j[h.id]">{{j[h.id]}}</span>
                              <span *ngIf="!j[h.id]">{{j[h.alternate]}}</span>
                            </span>
                          </td>
                          <td><span class="btn" (click)="loadVPCGroup(j.vpcGroupList)">VPC Group</span></td>
                        </tr>
                      </tbody>
                      <tbody [ngStyle]="{'display': i.clusterList.length == 0 ? 'table-row-group' : 'none'}">
                        <tr>
                          <td [attr.colspan]="rdsClsuterInstanceHeaders.length + 3">
                            <div class="rds-notfound">No RDS Clusters Found</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
              <tbody [ngStyle]="{'display': rdsClusterList.length == 0 ? 'table-row-group' : 'none'}">
                <tr>
                  <td [attr.colspan]="rdsClsuterHeaders.length + 3">
                    <div class="rds-notfound">No RDS Clusters Found</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<tags-modal *ngIf="showTagsModal" modalTitle="Tags" instanceID={{showTagsModal.dbInstanceIdentifier}}
  accountID={{accountId}} regionID={{regionId}} [serviceName]="showTagsModal['identifier']"
  (hideModal)="hideMdoal($event)"></tags-modal>
<instacne-start-stop *ngIf="command && writeAccess" [modalTitle]="modalTitle" [command]="command" [instances]="instance"
  (hideModal)="command = null" [action]="action"></instacne-start-stop>

<modal-popup [headers]="VPCGroupHeader" [data]="VPCList" [modalTitle]="'VPC Group'" [showModal]="showVPCPopup"
  [maxWidth]="'360px'" (hideModal)="hidePop($event)"></modal-popup>