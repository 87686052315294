import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import * as moment from "moment/moment";

@Component({
  selector: "app-aws",
  templateUrl: "./aws.component.html",
  styleUrls: ["./aws.component.css"],
})
export class AwsComponent implements OnInit, OnDestroy {
  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router
  ) {}

  accountId: string;
  regionId: string;
  currentMessage: any;
  updateCFT: any = null;
  selectedAccountId: string = null;
  regions: any = {
    enabled: [],
    disabled: [],
  };

  add: boolean = false;
  action: string = null;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  ngOnInit(): void {
    this.loadRegions();
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.router.queryParams.subscribe((params: any) => {
      if (params["editable"]) {
        this.selectedAccountId = this.accountId;
        if (this.selectedAccountId && this.accounts.length > 0) {
          let account: any = this.accounts.find((account: any) => {
            return account["accountId"] == this.selectedAccountId;
          });
          if (account) this.updateaccount(account);
          this.navigator.navigate(["/dash/accounts/aws"]);
        }
      } else {
        this.selectedAccountId = null;
      }
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg: any) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
      } else if (d.key == "regionId") {
        this.regionId = d.value;
      }
    });
    this.loadAccounts();
  }

  accounts: any = [];
  headers: any = [
    {
      id: "accountId",
      name: "AWS Account ID",
      link: true,
    },
    {
      id: "accountName",
      name: "Name",
    },
    {
      id: "companyName",
      name: "Company Name",
    },
    {
      id: "status",
      name: "Status",
    },
  ];

  preventDefault(event: any) {
    // event.preventDefault();
    event.stopPropagation();
  }

  selectedAccount: any;

  async loadRegions() {
    let data: any = {
      a: "regions",
      accountId: null,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/globals`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" || result.status == "1") {
      this.regions["enabled"] = result.data.filter((region: any) => {
        return region["status"] == "enabled";
      });
      this.regions["disabled"] = result.data.filter((region: any) => {
        return region["status"] != "enabled";
      });
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.message || result.error || result.error_message,
        "info",
        5000
      );
    }
  }

  updateCFTTrigger(event: any, i: any) {
      event.stopPropagation();
      this.updateCFT = i;
  }

  async loadAccounts() {
    this.notifier.loading(true);

    let data: any = {
      a: "fetchAllAccounts",
      eId: localStorage.getItem("eId"),
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/awsaccounts`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" && result.accounts) {
      result.accounts = result.accounts.map((account: any) => {
        if (account["regions"] == undefined) {
          account["regions"] = [];
        }
        if (account["customeRegions"] != undefined) {
          account["customRegions"] = account["customeRegions"];
        }
        if (account["customRegions"] == undefined) {
          account["customRegions"] = true;
        }
        if (account["importType"] == undefined) {
          account["importType"] = "automatic";
        }
        return account;
      });
      this.accounts = result.accounts;
      result.accounts.forEach((account: any) => {
        if (
          this.selectedAccountId &&
          account["accountId"] == this.selectedAccountId
        ) {
          this.updateaccount(account);
          this.navigator.navigate(["/dash/accounts/aws"]);
        }
        this.checkForCFT(account);
      });
    } else {
      this.accounts = [];
    }

    this.notifier.loading(false);
  }

  async hideAdd(event: any) {
    this.add = false;
    this.selectedAccount = null;
    if (event) {
      window.location.reload();
    }
  }

  addaccount() {
    this.add = true;
    this.action = "addaccount";
    this.selectedAccount = {
      lastUpdated: "",
      accountId: "",
      emailIds: "",
      importType: "automatic",
      regions: [],
      accountName: "",
      companyName: localStorage.getItem("com"),
      customRegions: true,
      status: "active",
    };
  }

  // load CFT link to configure CFT
  async loadCFTLink(account: any) {
    let apiURL: string = `https://prod.api.swayam.cloud/v3/cft`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(apiURL, {}, header);

    if (result.status == "1" || result.s == "1") {
      account['configure_link'] = result["template_url"].replace('RootAdminEmailIdValue', localStorage.getItem('eId'));
    }
  }

  async checkForCFT(account_id: any) {
    return new Promise(async (resolve: any, reject: any) => {
      account_id["cft_status"] = "loading";
      let data = {
        action: "validate_access",
        iam_role_arn: `arn:aws:iam::${account_id["accountId"]}:role/msopsstsrole`,
        session_name: "Swayam@AssumeRoleValidate",
      };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/accounts/validate`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == "1" || result.s == "1") {
        account_id["cft_status"] = "Configured";
        account_id["cft_latest_version"] = moment(
            result["LatestStackVersion"],
            "DD-MM-YYYY"
        ).valueOf();
        account_id["cft_latest_version_raw"] = result["LatestStackVersion"];
        // result["StackVersion"] = "1.0.1";
        try {
          if (moment(result["StackVersion"], "DD-MM-YYYY", true).isValid()) {
            account_id["cft_current_version"] = moment(
                result["StackVersion"],
                "DD-MM-YYYY"
            ).valueOf();
          } else {
            account_id["cft_current_version"] = moment(
                "01-01-2023",
                "DD-MM-YYYY"
            ).valueOf();
          }
        } catch {
          account_id["cft_current_version"] = moment(
              "01-01-2023",
              "DD-MM-YYYY"
          ).valueOf();
        }
        if (result["CloudShell"]) {
          result["CloudShell"]["Command"] = result["CloudShell"][
              "Command"
              ].replace("RootAdminEmailIdValue", localStorage.getItem('eId'));
        }
        account_id["cft_latest_url"] = result["CloudShell"];
        account_id["cft_deployed_region"] = result["StackRegion"];
      } else {
        account_id["cft_status"] = "Not Configured";
        this.loadCFTLink(account_id);
      }
      resolve(result);
    });
  }

  updateaccount(account: any) {
    this.selectedAccount = account;
    this.add = true;
    this.action = "updateaccount";
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
