import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-view-scanner-alerts",
  templateUrl: "./view-scanner-alerts.component.html",
  styleUrls: ["./view-scanner-alerts.component.css"],
})
export class ViewScannerAlertsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input("regionId") regionId: string;
  @Input("accountId") accountId: string;
  @Input("roleARN") roleARN: string;
  @Input("scanner") scanner: any;
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  resources: any = {
    cdn: [],
    elb: [],
    ec2: [],
    rds: [],
  };

  loading: boolean = true;

  currentPage: string = "cdn";
  destroyed: boolean = false;

  protocolResource: any = null;

  constructor(private apiServer: APIService) {}

  pagination: any = {
    cdn: {
      page: 1,
      perPage: 10,
    },
    elb: {
      page: 1,
      perPage: 10,
    },
    rds: {
      page: 1,
      perPage: 10,
    },
    ec2: {
      page: 1,
      perPage: 10,
    },
  };

  currentMessage: any;

  ngOnInit(): void {}

  getSelectedCount(action: string) {
    return this.resources[action].filter((resource: any) => {
      return resource["selected"];
    })["length"];
  }

  async load() {
    this.loading = true;
    let data: any = {
      action: "get_scanner",
      scanner_id: this.scanner["scanner_id"],
      account_id: this.accountId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINT}/cams/alerts`;

    let result: any = await this.apiServer.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.status == "1" || result.s == "1") {
      if (result.items && result.items.length > 0) {
        this.resources["cdn"] = Object.keys(
          result["items"][0]["cloudfront"][`${this.regionId}`]
        ).map((cdn_key: any) => {
          return {
            ...result["items"][0]["cloudfront"][`${this.regionId}`][cdn_key],
            id: cdn_key,
          };
        });
        this.resources["elb"] = Object.keys(
          result["items"][0]["elb"][`${this.regionId}`]
        ).map((cdn_key: any) => {
          return {
            ...result["items"][0]["elb"][`${this.regionId}`][cdn_key],
            id: cdn_key,
          };
        });
        this.resources["rds"] = Object.keys(
          result["items"][0]["rds"][`${this.regionId}`]
        ).map((cdn_key: any) => {
          return {
            ...result["items"][0]["rds"][`${this.regionId}`][cdn_key],
            id: cdn_key,
          };
        });
        this.resources["ec2"] = Object.keys(
          result["items"][0]["ec2"][`${this.regionId}`]
        ).map((cdn_key: any) => {
          return {
            ...result["items"][0]["ec2"][`${this.regionId}`][cdn_key],
            id: cdn_key,
          };
        });
      }
    }

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["scanner"]) {
      this.load();
    }
  }
}
