<div class="card">
  <h5 class="card-header flex-start">
    <a [routerLink]="'/dash/reports/home'" class="default-link">
      <button class="btn btn-light flex-start">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span>Reports</span>
      </button>
    </a>
    <span>
      Backup Report
    </span>
  </h5>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="baseline-tags-tab" data-bs-toggle="tab" data-bs-target="#baseline-tags"
          type="button" (click)="currentTab = 'snapshots'" role="tab" aria-controls="baseline-tags"
          aria-selected="true">Snapshots</button>
        <button class="nav-link" id="compliance-tags-tab" data-bs-toggle="tab" data-bs-target="#compliance-tags"
          (click)="currentTab = 'amis'" type="button" role="tab" aria-controls="compliance-tags"
          aria-selected="false">AMIs</button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="baseline-tags" role="tabpanel" aria-labelledby="baseline-tags-tab">
        <snapshots-backupreport *ngIf="currentTab == 'snapshots'"></snapshots-backupreport>
      </div>
      <div class="tab-pane fade" id="compliance-tags" role="tabpanel" aria-labelledby="compliance-tags-tab">
        <backupreport-amis *ngIf="currentTab == 'amis'"></backupreport-amis>
      </div>

    </div>
  </div>
</div>