import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Renderer2,
  NgZone,
  HostListener,
} from "@angular/core";
import { APIService } from "../../api/api.service";
import { NotifierService } from "../../_services/notifier.service";
import * as echarts from "echarts";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { FormControl } from "@angular/forms";
declare let flatpickr: any;
@Component({
  selector: "app-sla-dash",
  templateUrl: "./sla-dash.component.html",
  styleUrls: ["./sla-dash.component.css"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class SLADashComponent implements OnInit {
  @ViewChild("captureView", { static: false }) captureView: ElementRef;
  @ViewChild("dropdownContainer") dropdownContainer: ElementRef;
  @ViewChild("export") export: ElementRef;
  @ViewChild("projectkey") projectkey: ElementRef;
  accountId: any;
  regionId: any;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  includeResolved: boolean = true;

  selectedOption: string = "Current Month";
  isDropdownOpen: boolean = false;
  isExportOpen: boolean = false;
  start_date = "";
  end_date = "";
  currentMessage: any;
  selectedAccountData: any = [];
  result: any;
  jirakey: any;
  dateRange: string;
  nextToken: any = "";
  customDateView = false;
  ticketFound = true;
  tickets: any = [];
  selected_date = "";
  overallTotals: any = { critical: 0, high: 0, medium: 0, low: 0, total: 0 };
  last7days: any = [];
  datesobj: any = [];
  created: any = [];
  resolved: any = [];

  ticketPriority: any = [];
  current_month_loader = true;
  open_ticket_loader = true;
  last_7_days_loader = true;

  TR_list: any = { TR_met: 0, TR_breached: 0, FR_met: 0, FR_breached: 0 };
  TR_Result: any = [];
  TR_Weeks: any = [];
  TR_values: any = [];
  FR_values: any = [];
  TR_per: any = [];
  FR_per: any = [];
  TR_loader = true;
  TR_nodata = false;
  chart: echarts.ECharts;
  Rchart: echarts.ECharts;
  resizeStart = false;
  testKeys: any = [];
  resList: any = [];
  mergedObject: any = {
    issues_info: [],
    last_7_days_report: {},
    open_tickets_priority: {
      medium: 0,
      critical: 0,
      high: 0,
      low: 0,
      open_tickets: 0,
    },
  };
  isProjectKey: boolean = false;
  selectedKeys: any = [];
  keyString: any;
  openTickets: any;
  openTicketmsg:any='';
  loaderPage: any;
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private el: ElementRef 
  ) {}
  selectedCloudPartner: string | null = localStorage.getItem('cp-selected');
resourceGroup: string = localStorage.getItem("selectedResourceGroup");
  
  ngOnInit(): void {
    this.currentMessage = this.notifier.currentMessage.subscribe(
      async (msg) => {
        let d = JSON.parse(msg);
        if (d.value == null || d.source != "notifier") {
          return;
        }
        this.selectedCloudPartner = localStorage.getItem("cp-selected");
        if (d.key == "accountId" && this.selectedCloudPartner == 'aws') {
          this.accountId = d.value;
          this.notifier.loading(true);
          this.selectedAccountData = [];
          this.jirakey = "";
          this.result = [];
          this.nextToken = "";
          this.customDateView = false;
          this.tickets = [];
          this.ticketFound = true;
          this.dateRange = "";
          this.selected_date = "";
          this.last7days = [];
          this.datesobj = [];
          this.created = [];
          this.resolved = [];
          this.ticketPriority = [];
          this.current_month_loader = true;
          this.open_ticket_loader = true;
          this.last_7_days_loader = true;
          this.TR_Result = [];
          this.TR_Weeks = [];
          this.TR_values = [];
          this.TR_loader = true;
          this.TR_nodata = false;
          this.selectedKeys = [];
          this.openTickets = document.querySelector(".openTicket"); 
          this.loaderPage = document.querySelector(".main-loader");
          //  this.selectedAccountData=await this.fetchSelectedAccountDetails();
          //this.resizeMainBox()
          setTimeout(() => {
            this.selectedDate("Current Month");
          }, 2000);
        } else if (d.key == "regionId") {
          this.regionId = d.value;

          this.notifier.loading(true);
          this.selectedAccountData = [];
          this.jirakey = "";
          this.result = [];
          this.nextToken = "";
          this.customDateView = false;
          this.tickets = [];
          this.dateRange = "";
          this.ticketFound = true;
          this.selected_date = "";
          this.last7days = [];
          this.datesobj = [];
          this.created = [];
          this.resolved = [];
          this.ticketPriority = [];
          this.current_month_loader = true;
          this.open_ticket_loader = true;
          this.last_7_days_loader = true;
          this.TR_Result = [];
          this.TR_Weeks = [];
          this.TR_values = [];
          this.TR_loader = true;
          this.TR_nodata = false;
          this.selectedKeys = [];
          //this.resizeMainBox()
          setTimeout(() => {
            this.selectedDate("Current Month");
          }, 2000);
        }  else if (d.key == "resourceGroup") {
          this.resourceGroup = d.value;

          this.notifier.loading(true);
          this.selectedAccountData = [];
          this.jirakey = "";
          this.result = [];
          this.nextToken = "";
          this.customDateView = false;
          this.tickets = [];
          this.dateRange = "";
          this.ticketFound = true;
          this.selected_date = "";
          this.last7days = [];
          this.datesobj = [];
          this.created = [];
          this.resolved = [];
          this.ticketPriority = [];
          this.current_month_loader = true;
          this.open_ticket_loader = true;
          this.last_7_days_loader = true;
          this.TR_Result = [];
          this.TR_Weeks = [];
          this.TR_values = [];
          this.TR_loader = true;
          this.TR_nodata = false;
          this.selectedKeys = [];
          //this.resizeMainBox()
          setTimeout(() => {
            this.selectedDate("Current Month");
          }, 2000);
        }
      }
    );
  }

  onResize(event: any) {
    //  let currentUrl = this.router.url;
    if (this.resizeStart == false) {
      this.resizeStart = true;
      this.notifier.loading(true);

      setTimeout(() => {
        if (this.chart) {
          this.chart.resize();
        }
        if (this.Rchart) {
          this.Rchart.resize();
        }
        this.notifier.loading(false);

        //this.router.navigate([currentUrl]);
        //window.location.href = "/dash/home";
        this.resizeStart = false;
      }, 500);
    }
  }

  load() {
    //this.loadMinfySupportTickets();
    this.loadSupportTickets(true);
    this.notifier.loading(false);
    //this.loadFrGraph();
    // this.loadLdGraph();
    //this.loadageGraph();

    // this.notifier.loading(false);
  }
  @HostListener("document:click", ["$event"])
  onClickOutside(event: Event): void {
    if (!this.dropdownContainer.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
    if (!this.export.nativeElement.contains(event.target)) {
      this.isExportOpen = false;
    }
    if (!this.projectkey.nativeElement.contains(event.target)) {
      this.isProjectKey = false;
    }
  }
  loadFrGraph() {
    // var chartDom = document.getElementById("frGraph");
    // var myChart = echarts.init(chartDom);
    this.chart = echarts.init(document.getElementById("frGraph"));
    // let option = {
    //   color: ['rgb(0, 143, 251)'],
    //   legend: {
    //     bottom:10
    //   },
    //   grid: { containLabel: true },
    //   xAxis: {
    //     type: 'category',
    //     data: this.TR_Weeks
    //   },
    //   yAxis: {
    //     type: 'value'
    //   },
    //   tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'cross',
    //     },
    //     backgroundColor: 'rgba(255, 255, 255, 0.8)',
    //     position: function (
    //       pos: any,
    //       params: any,
    //       el: any,
    //       elRect: any,
    //       size: any
    //     ) {
    //       var obj: any = { top: 10 };
    //       obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
    //       return obj;
    //     },
    //     extraCssText: 'width: 170px; white-space:pre-wrap;',
    //   },

    //   series: [
    //     {
    //       data: this.TR_values,
    //       type: 'line',
    //       name: 'Breached',
    //     },
    //   ],
    // };

    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross", crossStyle: { color: "#999" } },
        formatter: function (params: any) {
          const seriesName = params[0].axisValueLabel;
          let content = `<div><b>${seriesName}</b>`;
          content += '<hr>';
          for (const param of params) {
            const value = param.value !== undefined ? param.value : 0; 
              content += `<div class="d-flex justify-content-between"> <div> ${param.seriesName}</div> &nbsp; <div>`;
              if(param.seriesName === 'TR SLA Percent' || param.seriesName === 'FR SLA Percent'){
                content += `<b> ${value}%</b>`
              }else{
                content += `<b> ${value}</b>`
              }
              
              
              content +=  `</div> </div>`; 
          }
          content += '</div>';
          return content;

        }
      },
      grid: { left: "2%", right: "2%", containLabel: true },
      legend: {
        data: ["TR", "FR", "TR SLA Percent", "FR SLA Percent"],
        bottom: 10,
      },
      xAxis: [
        {
          type: "category",
          data: this.TR_Weeks,
          axisPointer: { type: "shadow" },
          axisLabel: { fontSize: 10 },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Total",
          axisLabel: { formatter: "{value}" },
        },
        {
          type: "value",
          name: "SLA %",
          axisLabel: { formatter: "{value} %" },
        },
      ],
      responsive: {
        rules: [
          {
            condition: { minWidth: 700 },
            option: {
              xAxis: [{ axisLabel: { fontSize: 1 } }],
            },
          },
          {},
        ],
      },
      series: [
        {
          name: "TR",
          type: "bar",
          tooltip: {
            valueFormatter: function (value: any) {
              return value;
            },
          },
          data: this.TR_values,
        },
        {
          name: "FR",
          type: "bar",
          tooltip: {
            valueFormatter: function (value: any) {
              return value;
            },
          },
          data: this.FR_values,
        },
        {
          name: "TR SLA Percent",
          type: "line",
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value: any) {
              return value + " %";
            },
          },
          data: this.TR_per,
        },
        {
          name: "FR SLA Percent",
          type: "line",
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value: any) {
              return value + " %";
            },
          },
          data: this.FR_per,
        },
      ],
    };

    // option && myChart.setOption(option);
    this.chart.setOption(option);
  }

  loadLdGraph() {
    // var chartDom = document.getElementById("ldGraph");
    // var myChart = echarts.init(chartDom);

    this.Rchart = echarts.init(document.getElementById("ldGraph"));
    // let option = {
    //   color: [
    //     "#7eb0d5",
    //     "#bd7ebe",
    //     "#ffb55a",
    //     "#ffee65",
    //     "#beb9db",
    //     "#fdcce5",
    //     "#8bd3c7",
    //   ],
    //   xAxis: {
    //     type: 'category',
    //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    //   },
    //   yAxis: {
    //     type: 'value'
    //   },

    //   series: [
    //     {
    //       data: [3, 8, 10, 2, 4, 7, 2],
    //       type: 'bar'
    //     },
    //   ],
    // };
    // const labelOption = {
    //   show: true, position: 'insideBottom', distance: 15, align: 'left', verticalAlign: 'middle', rotate: 90, formatter: '{value|{c}} {name|{a}}',
    //   fontSize: 12,
    //   rich: {
    //     value: {
    //       color: 'black',
    //       fontSize: 12, textBorderColor: 'transparent'
    //     }, name: { color: 'black', fontSize: 12, textBorderColor: 'transparent', }
    //   }
    // };
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        formatter: function (params: any) {
          const seriesName = params[0].axisValueLabel;
          let content = `<div><b>${seriesName}</b>`;
          content += '<hr>';
          for (const param of params) {
            const value = param.value !== undefined ? param.value : 0; 
              content += `<div class="d-flex justify-content-between"> <div> ${param.seriesName}</div> &nbsp; <div>`;
             
                content += `<b> ${value}</b>`
              
              
              
              content +=  `</div> </div>`; 
          }
          content += '</div>';
          return content;

        }
      },
      legend: { data: ["Created", "Resolved"] },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: this.datesobj,
          axisLabel: { interval: 0, rotate: 30 },
        },
      ],
      yAxis: [{ type: "value" }],
      series: [
        {
          name: "Created",
          type: "bar",
          barGap: 0,
          data: this.created,
        },
        {
          name: "Resolved",
          type: "bar",
          data: this.resolved,
        },
      ],
    };
    this.Rchart.setOption(option);
  }

  loadageGraph() {
    var chartDom = document.getElementById("ageGraph");
    var myChart = echarts.init(chartDom);

    let option = {
      color: ["#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"],
      xAxis: {
        type: "category",
        data: [
          "8-Aug",
          "10-Aug",
          "12-Aug",
          "15-Aug",
          "20-Aug",
          "23-Aug",
          "25-Aug",
        ],
      },
      yAxis: {
        type: "value",
      },

      series: [
        {
          data: [3, 8, 0, 0, 4, 7, 2],
          type: "bar",
        },
      ],
    };
    option && myChart.setOption(option);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }
  selectAllKey() {
    this.isProjectKey=true;
    this.testKeys=[];
    this.selectedKeys.forEach((ele:any)=>{
      ele.selected=true;
      this.testKeys.push(ele.id);
    })
    this.keyString = this.testKeys.join(',');
  
    setTimeout(() => {

      this.loadSupportTickets(false);

    }, 1000);
  }

  onCheckboxChange(event: any, accountId: string) {
    this.isProjectKey=false;
    const key = this.selectedKeys.find((acc: any) => acc.id === accountId);
    if (event.target.checked) {
    //  if(this.testKeys.length!==1){
      this.notifier.loading(true);
      key.selected = true;
      this.testKeys.push(accountId);

      setTimeout(() => {

        this.loadSupportTickets(false);
        this.notifier.loading(false);
      }, 1000);
   // }
    }
    else {
    //  if(this.testKeys.length!==1){
      this.notifier.loading(true);
      key.selected = false;
      this.testKeys = this.testKeys.filter((id: any) => id !== accountId);

      setTimeout(() => {

        this.loadSupportTickets(false);
        this.notifier.loading(false);
      }, 1000);
   // }
    }
    this.keyString = this.testKeys.join(',');

  }
 

  async loadSupportTickets(keyLoad: any) {
    this.openTicketmsg='';
    this.loaderPage.classList.remove("d-none");
    this.openTickets.classList.remove("custom-loader-100");
    this.openTickets.classList.remove("d-none");
    // this.openTickets.forEach((ele:any)=>{
    //   ele.classList.remove("custom-loader-100");
    //   ele.classList.remove("d-none");
      
    // })
    
    console.log('enter');
    console.log(this.openTickets);
    
    this.current_month_loader = true;
    this.open_ticket_loader = true;
    this.last_7_days_loader = true;
    this.TR_loader = true;
    this.TR_nodata = false;
   
    try {
      this.selectedAccountData = await this.fetchSelectedAccountDetails();

      this.ticketFound = true;

      const { jira_org_name, jira_project_key } = this.selectedAccountData.data;
      this.result = [];
      this.overallTotals.critical = 0;
      this.overallTotals.high = 0;
      this.overallTotals.medium = 0;
      this.overallTotals.low = 0;
      this.overallTotals.total = 0;

      this.TR_list.TR_met = 0;
      this.TR_list.TR_breached = 0;
      this.TR_list.FR_met = 0;
      this.TR_list.FR_breached = 0;

      this.TR_Result = [];
      this.TR_Weeks = [];
      this.TR_values = [];
      this.FR_values = [];
      this.TR_per = [];
      this.FR_per = [];
      this.resList = [];
      if (jira_org_name != "" && jira_project_key != "") {
        this.jirakey = jira_org_name;

        const mergedObject: any = {
          issues_info: [],
          last_7_days_report: {},
          open_tickets_priority: {
            medium: 0,
            critical: 0,
            high: 0,
            low: 0,
            open_tickets: 0,
          },
        };
        // this.openTickets.forEach((ele:any)=>{
        //   ele.classList.add("custom-loader-25");
          
        // })
       
        const SLAmergedData: {
          [week: string]: { [metric: string]: { [attribute: string]: number } };
        } = {};
      //  setTimeout(() => {
          // this.openTickets.forEach((ele:any)=>{
          //   ele.classList.remove("custom-loader-25");
          //   ele.classList.add("custom-loader-50");
            
          // })
          this.notifier.disableScroll();
          this.openTickets.classList.add("custom-loader-25");
    this.openTicketmsg='25%';
         
      //  }, 2000);
        
        // this.openTickets.classList.remove("custom-loader-25");
        // this.openTickets.classList.add("custom-loader-50");
       setTimeout(() => {
        this.openTickets.classList.remove("custom-loader-25");
        this.openTickets.classList.add("custom-loader-50");
        this.openTicketmsg='50%';
       }, 2000);
        if (keyLoad) {
          this.testKeys = [];
          const header2 = {
            Authorization: "Bearer " + localStorage.getItem("t"),
          };

          const authToken2 = "Bearer " + localStorage.getItem("t");

          let apiURL2 = `https://apis-eks.swayam.cloud/global_notification/list_jira_projects_by_org/?organization_id=${jira_org_name}&timeline_report=${this.selected_date}&user_type=client `;

          const result = await this.apiServer.postDataPromis(
            apiURL2,
            {},
            header2
          );
          this.testKeys = result["list_of_projects"];
          this.selectedKeys = this.testKeys;
          this.selectedKeys = this.selectedKeys.map((item: any) => ({
            selected: true,
            id: item,
          }));
          this.keyString = this.testKeys.join(',');
   
        }
       
        if(this.testKeys.length===0){
          this.notifier.enableScroll();
          this.openTickets.classList.add("d-none");
          this.loaderPage.classList.add("d-none");
          this.ticketFound = false;
          this.current_month_loader = false;
          this.open_ticket_loader = false;
          this.last_7_days_loader = false;
          this.TR_loader = false;
          this.TR_nodata = true;
          return;
        }
        this.openTickets.classList.remove("custom-loader-50");
        this.openTickets.classList.add("custom-loader-75");
        this.openTicketmsg='75%';
        // this.openTickets.forEach((ele:any)=>{
        //   ele.classList.remove("custom-loader-50");
        //   ele.classList.add("custom-loader-75");
          
        // })
      
        //For SLA Breached
        // const SLApromises = this.testKeys.map(async (key: any) => {
        //   const header = { Authorization: "Bearer " + localStorage.getItem("t") };

        //    const authToken = "Bearer " + localStorage.getItem("t");

        //    let apiURL = `https://apis-eks.swayam.cloud/global_notification/get_sla_report/?auth_token=${authToken}&organization_id=${jira_org_name}&account_no=${this.accountId}&timeline_report=${this.selected_date}&jira_project_key=${key}&user_type=client`;

        //      const result = await this.apiServer.postDataPromis(apiURL, {}, header);

        //      return result["accounts_ids"][this.accountId];
        //     });
       
        //for SLA breached
        const SLApromises = this.testKeys.map(async (key: any) => {
          const header = {
            Authorization: "Bearer " + localStorage.getItem("t"),
          };

          const authToken = "Bearer " + localStorage.getItem("t");

          let apiURL = `https://apis-eks.swayam.cloud/global_notification/sla_breached_info/?timeline_report=${this.selected_date}&organization_id=${jira_org_name}&jira_project_key=${key}&user_type=client`;

          const result = await this.apiServer.postDataPromis(
            apiURL,
            {},
            header
          );

          return result["sla_report"];
        });
        
        const SLAresponses = await Promise.all(SLApromises);

        

        for (const weekData of SLAresponses) {
          for (const weekNumber of Object.keys(weekData)) {
            const week = weekData[weekNumber];
            if (!SLAmergedData[weekNumber]) {
              SLAmergedData[weekNumber] = {
                time_to_resolution: {},
                time_to_first_response: {},
              };
            }
            const mergedWeek = SLAmergedData[weekNumber];
            for (const metric of [
              "time_to_resolution",
              "time_to_first_response",
            ]) {
              for (const attribute of ["met", "breached"]) {
                if (!mergedWeek[metric][attribute]) {
                  mergedWeek[metric][attribute] = 0;
                }
                mergedWeek[metric][attribute] += week[metric][attribute];
              }
            }
          }
        }
        for (const weekNumber of Object.keys(SLAmergedData)) {
          const week = SLAmergedData[weekNumber];
          for (const metric of [
            "time_to_resolution",
            "time_to_first_response",
          ]) {
            week[metric]["sla_breached_percent"] =
              ((week[metric]["met"] - week[metric]["breached"]) /
                week[metric]["met"]) *
                100 || 0;
          }
        }
     
        const promises = this.testKeys.map(async (key: any) => {
          const header = {
            Authorization: "Bearer " + localStorage.getItem("t"),
          };

          const authToken = "Bearer " + localStorage.getItem("t");

          let apiURL = `https://apis-eks.swayam.cloud/global_notification/get_sla_report/?auth_token=${authToken}&organization_id=${jira_org_name}&account_no=${this.accountId}&timeline_report=${this.selected_date}&jira_project_key=${key}&user_type=client`;

          const result = await this.apiServer.postDataPromis(
            apiURL,
            {},
            header
          );

          return result["accounts_ids"][this.accountId];
        });

        const responses = await Promise.all(promises);
      
        // responses.forEach((response) => {
        //   console.log(response);
        //  });

        responses.forEach((item: any) => {
          item.issues_info.forEach((issue: any) => {
            const mergedIssue = mergedObject.issues_info.find(
              (mergedIssue: any) => mergedIssue.issue_type === issue.issue_type
            );
            if (!mergedIssue) {
              mergedObject.issues_info.push({ ...issue });
            } else {
              mergedIssue.medium += issue.medium;
              mergedIssue.critical += issue.critical;
              mergedIssue.high += issue.high;
              mergedIssue.low += issue.low;
              mergedIssue.total += issue.total;
            }
          });

          for (const date in item.last_7_days_report) {
            if (!mergedObject.last_7_days_report[date]) {
              mergedObject.last_7_days_report[date] = {
                ...item.last_7_days_report[date],
              };
            } else {
              mergedObject.last_7_days_report[date].total_issue +=
                item.last_7_days_report[date].total_issue;
              mergedObject.last_7_days_report[date].resolve_issue +=
                item.last_7_days_report[date].resolve_issue;
            }
          }
          mergedObject.open_tickets_priority.medium +=
            item.open_tickets_priority.medium;
          mergedObject.open_tickets_priority.critical +=
            item.open_tickets_priority.critical;
          mergedObject.open_tickets_priority.high +=
            item.open_tickets_priority.high;
          mergedObject.open_tickets_priority.low +=
            item.open_tickets_priority.low;
          mergedObject.open_tickets_priority.open_tickets +=
            item.open_tickets_priority.open_tickets;
        });
        // openTicketProgress.classList.remove("custom-loader-50");
        // openTicketProgress.classList.add("custom-loader-75");
        // this.testKeys.forEach(async (key: any) => {

        //   let header = {
        //     Authorization: "Bearer " + localStorage.getItem("t"),
        //   };
        //   const authToken = "Bearer " + localStorage.getItem("t");
        //   //https://api.swayam.cloud/v3/admin/support
        //   let apiURL = `https://apis-eks.swayam.cloud/global_notification/get_sla_report/?auth_token=${authToken}&organization_id=${jira_org_name}&account_no=${this.accountId}&timeline_report=${this.selected_date}&jira_project_key=${key}&user_type=client`;

        //   let result = await this.apiServer.postDataPromis(apiURL, {}, header);
        //   this.resList.push(result["accounts_ids"][this.accountId]);
        // });

        let header = {
          Authorization: "Bearer " + localStorage.getItem("t"),
        };
        const authToken = "Bearer " + localStorage.getItem("t");
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `https://apis-eks.swayam.cloud/global_notification/get_sla_report/?auth_token=${authToken}&organization_id=${jira_org_name}&account_no=${this.accountId}&timeline_report=${this.selected_date}&jira_project_key=${jira_project_key}&user_type=client`;

        //for SLA Time to resolve graph
        // if (this.selected_date === "current_month") {
        let api_URL = `https://apis-eks.swayam.cloud/global_notification/sla_breached_info/?timeline_report=${this.selected_date}&organization_id=${jira_org_name}&jira_project_key=${jira_project_key}&user_type=client`;

        // let result2 = await this.apiServer.postDataPromis(api_URL, {}, header);
        this.TR_Result = SLAmergedData;
      
        // for (const key in this.TR_Result) {
        //   if (Object.prototype.hasOwnProperty.call(this.TR_Result, key)) {
        //     const week = this.TR_Result[key];
        //     this.TR_list.met += week.met;
        //     this.TR_list.breached += week.breached;
        //     if (week.sla_breached_percent !== 0) {
        //       this.TR_Weeks.push(this.transformWeekName(key));
        //       this.TR_values.push(week.sla_breached_percent);
        //     } } }

        for (const month in this.TR_Result) {
          if (this.TR_Result.hasOwnProperty(month)) {
            this.TR_list.TR_met += Number(
              this.TR_Result[month].time_to_resolution.met
            );
            this.TR_list.TR_breached += Number(
              this.TR_Result[month].time_to_resolution.breached
            );
            this.TR_list.FR_met += Number(
              this.TR_Result[month].time_to_first_response.met
            );
            this.TR_list.FR_breached += Number(
              this.TR_Result[month].time_to_first_response.breached
            );

            // if(this.TR_Result[month].time_to_resolution.sla_breached_percent !==0 && this.TR_Result[month].time_to_first_response
            //   .sla_breached_percent !==0){

            this.TR_Weeks.push(this.transformWeekName(month));
            this.TR_values.push(
              this.TR_Result[month].time_to_resolution.met +
                this.TR_Result[month].time_to_resolution.breached
            );
            this.FR_values.push(
              this.TR_Result[month].time_to_first_response.met +
                this.TR_Result[month].time_to_first_response.breached
            );
            let trper = Number(
              this.TR_Result[month].time_to_resolution.sla_breached_percent
            )
            if(!Number.isInteger(trper)){
              trper =Number(trper.toFixed(2));
            }

            this.TR_per.push(trper);

            let frper = Number(
              this.TR_Result[month].time_to_first_response.sla_breached_percent
            );

            if(!Number.isInteger(frper)){
              frper =Number(frper.toFixed(2));
            }

            this.FR_per.push(frper);
            // }
          }
        }
        this.TR_Weeks = this.TR_Weeks.map((longMonth: any) => {
          const parts = longMonth.split("-");
          if (parts.length === 2) {
            const month = parts[0];
            const year = parts[1].slice(2);
            const shortMonth = month.slice(0, 3);
            return `${shortMonth}-${year}`;
          }
          return longMonth;
        });
        setTimeout(() => {
          this.TR_loader = false;
          this.loadFrGraph();
        }, 1000);
        // } else {
        //   this.TR_loader = false;
        //   this.TR_nodata = true;
        // }
        //  let result = await this.apiServer.postDataPromis(apiURL, {}, header);
        // this.openTickets.forEach((ele:any)=>{
        //   ele.classList.remove("custom-loader-75");
        //   ele.classList.add("custom-loader-100");
          
        // })
        this.openTickets.classList.remove("custom-loader-75");
        this.openTickets.classList.add("custom-loader-100");
        // this.openTickets.classList.remove("custom-loader-75");
        // this.openTickets.classList.add("custom-loader-100");
        this.openTicketmsg='100%';
        this.result = mergedObject["issues_info"];
        this.last7days = mergedObject["last_7_days_report"];
        this.ticketPriority = mergedObject["open_tickets_priority"];
        this.notifier.enableScroll();
        setTimeout(() => {
           this.open_ticket_loader = false;
          //  this.openTickets.forEach((ele:any)=>{
           
          //   ele.classList.add("d-none");
            
          // });
          this.openTickets.classList.add("d-none");
          this.loaderPage.classList.add("d-none");
          // this.openTickets.classList.add("d-none");
        }, 800);
        
        for (const issue of this.result) {
          this.overallTotals.medium += issue.medium;
          this.overallTotals.critical += issue.critical;
          this.overallTotals.high += issue.high;
          this.overallTotals.low += issue.low;
          this.overallTotals.total += issue.total;
        }

        this.result.push({
          issue_type: "Total Issues",
          critical: this.overallTotals.critical || 0,
          high: this.overallTotals.high || 0,
          medium: this.overallTotals.medium || 0,
          low: this.overallTotals.low || 0,
          total: this.overallTotals.total || 0,
          last: true,
        });
        
        this.current_month_loader = false;

        //last 7 days
        this.datesobj = Object.keys(this.last7days);
        this.created = Object.values(this.last7days).map(
          (entry: any) => entry.total_issue
        );
        this.resolved = Object.values(this.last7days).map(
          (entry: any) => entry.resolve_issue
        );

        this.last_7_days_loader = false;
        this.loadLdGraph();
      } else {
        this.notifier.enableScroll();
        this.openTickets.classList.add("d-none");
        this.loaderPage.classList.add("d-none");
        this.ticketFound = false;
        this.current_month_loader = false;
        this.open_ticket_loader = false;
        this.last_7_days_loader = false;
        this.TR_loader = false;
        this.TR_nodata = true;
      }
    } catch (err: any) {
      this.notifier.enableScroll();
      this.openTickets.classList.add("d-none");
      this.loaderPage.classList.add("d-none");
      this.ticketFound = false;
      this.current_month_loader = false;
      this.open_ticket_loader = false;
      this.last_7_days_loader = false;
      this.TR_loader = false;
      this.TR_nodata = true;
    }
    this.notifier.enableScroll();
    // this.notifier.loading(false);
  }
  transformWeekName(weekName: string): string {
    const words = weekName.split("_");

    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  async loadMinfySupportTickets() {
    // let selectedAccounts: any = localStorage.getItem("accountIdData");
    // console.log(selectedAccounts);
    // if (selectedAccounts) {
    //   selectedAccounts = JSON.parse(selectedAccounts);
    //   this.selectedAccountData = selectedAccounts.list;
    // }
    //  this.minloader = true;
    if (!this.nextToken) {
      // this.resolved = 0;
      // this.open = 0;
      // this.closed = 0;
      // this.WorkInProgress = 0;
      // this.watingForCosRes = 0;
      // this.closedByBot = 0;
      // this.investigation = 0;
      // this.tickets = [];
    }
    // this.notifier.loading(true);
    try {
      this.selectedAccountData = await this.fetchSelectedAccountDetails();
      if (!this.selectedAccountData.error) {
        this.ticketFound = true;
        //   this.selectedAccountData= this.selectedAccountData.forEach((obj:any)=>{
        //   if(obj.cp =='aws'){
        //     console.log(obj);
        //     return obj;
        //   }
        //  });

        const { jira_org_name, jira_project_key } =
          this.selectedAccountData.data;
        // let start_date = moment().startOf("month").format("YYYY-MM-DD");
        // let end_date = moment()
        //   .startOf("day")
        //   .add("days", 1)
        //   .format("YYYY-MM-DD");
        if (this.dateRange && this.dateRange.includes("to")) {
          let dRange = this.dateRange.toString().split(" to ");
          this.start_date = moment(dRange[0], "DD-MMM-YYYY").format(
            "YYYY-MM-DD"
          );
          this.end_date = moment(dRange[1], "DD-MMM-YYYY")
            .add("days", 1)
            .format("YYYY-MM-DD");
        }
        this.jirakey = jira_org_name;
        let data = {
          action: "jira_list",
          account_id: this.accountId,
          include_resolved: this.includeResolved.toString(),
          jira_project_key: jira_project_key,
          jira_organization_key: jira_org_name,
          start_date: this.start_date,
          end_date: this.end_date,
          next_token: this.nextToken ? this.nextToken : undefined,
        };
        let header = {
          Authorization: localStorage.getItem("t"),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == "1" || result.s == "1") {
          // if (result.total === 0) {
          //   this.ticketNotFound = true;
          // } else {
          //   this.ticketNotFound = false;
          // }
          this.tickets = [...this.tickets, ...result.issues];
          //this.tickets=result;
          // console.log(this.tickets);

          if (result.next_token && result.next_token != "") {
            this.nextToken = result.next_token;
            this.loadMinfySupportTickets();
          } else {
            // this.hideGraph = false;
            // this.minloader = false;
            this.nextToken = null;

            this.result = [];
            this.overallTotals.critical = 0;
            this.overallTotals.high = 0;
            this.overallTotals.medium = 0;
            this.overallTotals.low = 0;
            this.overallTotals.total = 0;
            let counter: number = 0;
            let done = false;

            const issueTypes = [
              ...new Set(this.tickets.map((issue: any) => issue.issue_type)),
            ];
            issueTypes.forEach((issueType: any) => {
              counter += 1;
              const filteredIssues = this.tickets.filter(
                (issue: any) => issue.issue_type === issueType
              );
              const mediumCount = filteredIssues.filter(
                (issue: any) => issue.priority.toLowerCase() === "medium"
              ).length;
              const highCount = filteredIssues.filter(
                (issue: any) => issue.priority.toLowerCase() === "high"
              ).length;
              const criticalCount = filteredIssues.filter(
                (issue: any) => issue.priority.toLowerCase() === "critical"
              ).length;
              const issueTypeTotal = criticalCount + highCount + mediumCount;
              this.overallTotals.critical += criticalCount;
              this.overallTotals.high += highCount;
              this.overallTotals.medium += mediumCount;
              this.overallTotals.total += issueTypeTotal;
              this.result.push({
                issue_type: issueType,
                critical: criticalCount || 0,
                high: highCount || 0,
                medium: mediumCount || 0,
                total: highCount + mediumCount + criticalCount || 0,
                last: false,
              });
              if (issueType.length == counter) {
                done = true;
              }
            });
            //  if(done){
            this.result.push({
              issue_type: "Total Issues",
              critical: this.overallTotals.critical || 0,
              high: this.overallTotals.high || 0,
              medium: this.overallTotals.medium || 0,
              total: this.overallTotals.total || 0,
              last: true,
            });
            // }

            this.notifier.loading(false);
          }
        } else {
          // this.minloader = false;
          // this.ticketNotFound = true;
          this.ticketFound = false;
          this.notifier.loading(false);
        }
      } else {
        this.ticketFound = false;
        this.notifier.loading(false);
      }
    } catch (err: any) {
      this.ticketFound = false;
      this.notifier.loading(false);
    }
  }

  options: any[] = [
    { key: "Current Month" },
    { key: "Last 3 Months" },
    {
      key: "Last 6 Months",
    },
    {
      key: "Last 12 Months",
    },
    {
      key: "Custom Date",
    },
  ];

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  // selectOption(option: string): void {
  //   this.selectedOption = option;
  //   this.isDropdownOpen = false;
  // }

  selectedDate(option: any) {
    if (option == "Current Month") {
      this.notifier.loading(true);
      this.selectedOption = option;
      this.isDropdownOpen = false;
      this.customDateView = false;
      this.isProjectKey=false;
      this.ticketPriority = [];
      this.dateRange = "";
      // this.start_date = moment().startOf("month").format("YYYY-MM-DD");
      // this.end_date = moment()
      //   .startOf("day")
      //   .add("days", 1)
      //   .format("YYYY-MM-DD");
      this.selected_date = "current_month";
      setTimeout(() => {
        this.tickets = [];
        this.load();
      }, 2000);
    }
    if (option == "Last 3 Months") {
      this.notifier.loading(true);
      this.selectedOption = option;
      this.isDropdownOpen = false;
      this.customDateView = false;
      this.isProjectKey=false;
      this.ticketPriority = [];
      this.dateRange = "";
      // this.start_date = moment().subtract(3, "month").format("YYYY-MM-DD");
      // this.end_date = moment()
      //   .startOf("day")
      //   .add("days", 1)
      //   .format("YYYY-MM-DD");
      this.selected_date = "last_3_months";
      setTimeout(() => {
        this.tickets = [];
        this.load();
      }, 2000);
    }
    if (option == "Last 6 Months") {
      this.notifier.loading(true);
      this.selectedOption = option;
      this.isDropdownOpen = false;
      this.customDateView = false;
      this.isProjectKey=false;
      this.ticketPriority = [];
      this.dateRange = "";
      // this.start_date = moment().subtract(6, "month").format("YYYY-MM-DD");
      // this.end_date = moment()
      //   .startOf("day")
      //   .add("days", 1)
      //   .format("YYYY-MM-DD");
      this.selected_date = "last_6_months";
      setTimeout(() => {
        this.tickets = [];
        this.load();
      }, 2000);
    }
    if (option == "Last 12 Months") {
      this.notifier.loading(true);
      this.selectedOption = option;
      this.isDropdownOpen = false;
      this.customDateView = false;
      this.isProjectKey=false;
      this.ticketPriority = [];
      this.dateRange = "";
      // this.start_date = moment().subtract(6, "month").format("YYYY-MM-DD");
      // this.end_date = moment()
      //   .startOf("day")
      //   .add("days", 1)
      //   .format("YYYY-MM-DD");
      this.selected_date = "last_12_months";
      setTimeout(() => {
        this.tickets = [];
        this.load();
      }, 2000);
    }
    if (option == "Custom Date") {
      this.selectedOption = option;
      this.customDateView = true;
      this.isDropdownOpen = false;
      this.isProjectKey=false;
      setTimeout(() => {
        this.datepick();
      }, 400);
    }
  }

  // datepick() {
  //   let start_date = moment().startOf("month").format("DD-MMM-YYYY");
  //   let end_date = moment().startOf("day").format("DD-MMM-YYYY");

  //   this.dateRange = start_date + " to " + end_date;
  //   console.log(this.dateRange);
  //  flatpickr(".datetimepicker", {
  //     dateFormat: "Y-m-d",
  //     mode: "range",
  //     maxDate: new Date(),
  //     showMonths: 2,
  //     onChange: (selectedDates: any, dateStr: any, instance: any) => {
  //       if (!dateStr.includes("to")) return;

  //       dateStr = dateStr.replace(" to ", "_to_");
  //       const [startDate, endDate] = dateStr.split("_to_");
  //       console.log(startDate, endDate);
  //       const diffInDays = moment(endDate).diff(startDate, "days");
  //       console.log(diffInDays);
  //       if (diffInDays < 7 || diffInDays > 365) {
  //         this.notifier.alert(
  //           "Info",
  //           "",
  //           "Date range should be between 1 week and 1 year.",
  //           "Info",
  //           5000
  //         );
  //         return;
  //       }
  //       this.notifier.loading(true);
  //       this.selected_date = dateStr.replace(/-/g, "_");

  //       console.log(this.selected_date);
  //       setTimeout(() => {
  //         this.tickets = [];
  //         this.load();
  //       }, 2000);
  //     },
      
  //  });
 
  // }

  datepick() {
    let start_date = moment().startOf("month").format("DD-MMM-YYYY");
    let end_date = moment().startOf("day").format("DD-MMM-YYYY");

    this.dateRange = start_date + " to " + end_date;
    const dateInput = document.querySelector('.datetimepicker') as HTMLInputElement; 
    flatpickr(".datetimepicker", {
      dateFormat: "Y-m-d",
      mode: "range",
      maxDate: new Date(),
      showMonths: 2,
      static: true, 
      right:0,
      appendTo: dateInput.parentElement, 
      onReady: (selectedDates: any, dateStr: any, instance: any) => {
        
      
        const tooltip = document.createElement("div");
        tooltip.classList.add('flex-between');
        tooltip.classList.add('p-2');
        const button = document.createElement('button');
        const div = document.createElement('div');
        div.classList.add('error-message-tooltip');
        div.classList.add('text-danger');
        div.classList.add('p-2');
        div.classList.add('font-weight-bold');
        button.classList.add('apply-button');
        button.classList.add('btn');
        button.classList.add('btn-primary-soft');
        button.innerText = 'Apply';
        tooltip.appendChild(div);
        tooltip.appendChild(button);
        instance.calendarContainer.appendChild(tooltip);
      
        button
          .addEventListener("click", () => {
            dateStr = $(".datetimepicker").val();
            
          
            
            dateStr = dateStr.replace(" to ", "_to_");
            const [startDate, endDate] = dateStr.split("_to_");
         
            const diffInDays = moment(endDate).diff(startDate, "days");
           
            if (diffInDays < 7 || diffInDays > 365) {
             
              let element: any = document.querySelector('.error-message-tooltip')
              element.innerText = 'Date range should be a min. of 1 week and max. of 1 year.'
              instance.open();
              return;
            }else{
              let element: any = document.querySelector('.error-message-tooltip')
              element.innerText = '';
            }
            this.notifier.loading(true);
            this.selected_date = dateStr.replace(/-/g, "_");
         
            setTimeout(() => {
              this.tickets = [];
              this.load();
            }, 2000);
            instance.close();
          });
          
      },
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        instance.open();
        return;
      },
    });
  }

  // printAsPdf() {
  //   const elementToCapture = this.captureDiv.nativeElement;
  //   const printWindow = window.open("", "", "width=800,height=600");
  //   printWindow.document.write("<html><head><title>print</title></head><body>");
  //   printWindow.document.write(elementToCapture.innerHTML);
  //   printWindow.document.write("</body></html>");
  //   printWindow.print();
  //   printWindow.close();
  // }

  // captureAsImage() {
  //   console.log('enter');
  //   const elementToCapture = this.captureDiv.nativeElement;
  //   const width = elementToCapture.offsetWidth;
  //   const height = elementToCapture.offsetHeight;
  //   console.log(width,height);
  //   const canvas = document.createElement('canvas');
  //   canvas.width = width;
  //   canvas.height = height;
  //   const context = canvas.getContext('2d');
  //   const svgString = new XMLSerializer().serializeToString(elementToCapture);
  //   const img = new Image();
  //   img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));

  //    console.log(img.onload);
  //   // setTimeout(() => {

  //       context.drawImage(img, 0, 0, width, height);
  //       const imageDataUrl = canvas.toDataURL('image/png');
  //       const downloadLink = document.createElement('a');
  //       downloadLink.href = imageDataUrl;
  //       downloadLink.download = 'captured_image.png';
  //       console.log(downloadLink);
  //       downloadLink.click();
  //    // }, 3000);

  // }

  // captureAsImage() {
  //   const viewToCapture = this.captureView.nativeElement;
  //    const canvas = document.createElement('canvas');
  //    canvas.width = viewToCapture.offsetWidth;
  //    canvas.height = viewToCapture.offsetHeight;
  //     const context = canvas.getContext('2d');
  //      context.drawImage(viewToCapture, 0, 0);
  //       const screenshotDataUrl = canvas.toDataURL('image/png');
  //        const downloadLink = document.createElement('a');
  //        downloadLink.href = screenshotDataUrl; downloadLink.download = 'screenshot.png';
  //         downloadLink.click();
  // }

  async exportCSV() {
    this.isExportOpen = false;
    this.selectedAccountData = await this.fetchSelectedAccountDetails();
    if (!this.selectedAccountData.error) {
      const { jira_org_name, jira_project_key } = this.selectedAccountData.data;
      let header = {
        Authorization: "Bearer " + localStorage.getItem("t"),
      };
      const authToken = "Bearer " + localStorage.getItem("t");
      //https://api.swayam.cloud/v3/admin/support

      let apiURL = `https://apis-eks.swayam.cloud/global_notification/create_sla_csv_report/?timeline_report=${this.selected_date}&account_no=${this.accountId}&organization_id=${jira_org_name}&jira_project_key=${this.keyString}&user_type=client`;

      let result = await this.apiServer.postDataPromis(apiURL, {}, header);

     
      var link = document.createElement("a");
      // link.download = this.workload['name'] + '.' + format;
      link.target = "_blank";
      link.href = result["presigned_url"];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  dataURLtoBlob(dataURL: string) {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  onExport() {
    this.isExportOpen = !this.isExportOpen;
  }

  offExport() {
    this.isExportOpen = false;
  }

  onProjectKey() {
    this.isProjectKey = !this.isProjectKey;
  }
  offProjectKey() {
    this.isProjectKey = false;
  }
  toggleDropdown2() {
    this.isExportOpen = true;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
