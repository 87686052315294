import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
@Component({
  selector: 'app-view-backup',
  templateUrl: './view-backup.component.html',
  styleUrls: ['./view-backup.component.css']
})
export class ViewBackupComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() backupList :any=[]

  constructor(public notifier: NotifierService) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }

  
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}
