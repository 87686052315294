import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-create-job-ftr",
  templateUrl: "./create-job-ftr.component.html",
  styleUrls: ["./create-job-ftr.component.css"],
})
export class CreateJobFtrComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() regionId: string;
  @Input() accountId: string;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = "";

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  errors: any = {
    scan_name: {
      is_error: false,
      error: "",
    },
  };
  async createJob() {
    var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

    if (format.test(this.scan_name)) {
      this.errors["scan_name"]["is_error"] = true;
      this.errors["scan_name"]["error"] = "Special Characters are not allowed.";
      return;
    } else {
      this.errors["scan_name"]["is_error"] = false;
    }
    if (this.scan_name.trim() == "") {
      this.errors["scan_name"]["is_error"] = true;
      this.errors["scan_name"]["error"] = "Scan Name is required.";
      return;
    } else {
      this.errors["scan_name"]["is_error"] = false;
    }
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "create_job",
      account_id: this.accountId,
      region_id: this.regionId,
      name: this.scan_name,
    };

    let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/ftr/operations`;

    let result = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      this.no(result.job_id);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }
    this.notifier.loading(false);
  }
}
