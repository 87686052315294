import { Component, Input } from "@angular/core";

@Component({
  selector: "app-daily-gov",
  templateUrl: "./daily-gov.component.html",
  styleUrls: ["./daily-gov.component.css"],
})
export class DailyGovComponent {
  @Input("dailygovRes") dailygovRes: any;

  currentPage: number = 1;
  itemsPerPage: number = 10;
  constructor() {}

  ngOnInit(): void {}

  get totalPages(): number {
    return Math.ceil(this.dailygovRes.length / this.itemsPerPage);
  }
  getItemsForPage(pageNumber: number): any[] {
    const startIndex = (pageNumber - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dailygovRes.slice(startIndex, endIndex);
  }

  navigateToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  navigateToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  // getPageNumbers(): number[] {
  //   return Array(this.totalPages)
  //     .fill(0)
  //     .map((_, i) => i + 1);
  // }
  getPageNumbers(): number[] {
    const maxPages = 5;
    const pageNumbers: number[] = [];
    if (this.totalPages <= maxPages) {
      for (let i = 1; i <= this.totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (this.currentPage <= maxPages - 2) {
        for (let i = 1; i <= maxPages; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push(-1);
        pageNumbers.push(this.totalPages);
      } else if (this.currentPage >= this.totalPages - (maxPages - 2)) {
        pageNumbers.push(1);
        pageNumbers.push(-1);
        for (
          let i = this.totalPages - (maxPages - 3);
          i <= this.totalPages;
          i++
        ) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push(-1);
        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push(-1);
        pageNumbers.push(this.totalPages);
      }
    }
    return pageNumbers;
  }
}
