<div class="card mt-20 crd-bdy">
  <h5 class="card-header">Onboarded Master Account Details</h5>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>WR Enabled</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of onboardedData; let j = index"
        [ngStyle]="{'display': onboardedData.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i)">
            </span>

          </td>
          <td>
            <span>
              <label class="switch">
                <input autocomplete="off" type="checkbox" [(ngModel)]="i.wrEnabled" (change)="toggleWR(i)">
                <span class="slider round"></span>
              </label>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': onboardedData.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div class="txt-cntr">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<style>
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    transform: scale(0.6);
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: #5864FF;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #5864FF;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>