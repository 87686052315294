import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "./../../../_services/notifier.service";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";

declare let window: any;
declare let Jhxlsx: any;

@Component({
  templateUrl: "./ec2costoptimization.component.html",
  styleUrls: ["./ec2costoptimization.component.css"],
})
export class BetaEc2CostOptimizationComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading: any = {};
  jobDetails: any = null;
  recomondations: any = {
    utilization: [],
    os: [],
    processor: [],
  };
  funRef: any;
  tagsList: any = null;
  tagsModalTitle: any = "Tags";
  modalHeader: any;
  createJobTrigger: boolean = null;
  fetching: boolean = true;

  selectedInstance: any;
  destroyed: boolean = false;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;

  ec2CostHeaders: any = [
    {
      id: "instname",
      name: "Instance Name",
      filter: true,
      rowspan: true,
      click: "loadMetrics",
      rowspanbasedon: "recommendations",
    },
    { id: "insttype", name: "Instance Type", filter: true },
    {
      id: "platform",
      name: "Platform",
    },
    {
      id: "currentprice",
      name: "Current Spend (On Demand)",
      filter: true,
      center: true,
    },
    // {
    //   id: 'rec',
    //   name: 'Recommended',
    //   filter: true,
    // },
    // {
    //   id: 'reccPr',
    //   name: 'Recommended Cost',
    //   filter: true,
    //   center: true,
    // },
    // {
    //   id: 'savings_html',
    //   name: 'Savings',
    //   filter: true,
    //   center: true,
    // },
  ];

  ec2Recommendations: any = [
    {
      id: "recommendedInsType",
      name: "Recommended",
    },
    {
      id: "price",
      name: "New Spend (On Demand)",
      center: true,
    },
    {
      id: "savings_html",
      name: "Savings",
      center: true,
    },
  ];

  moreDetailsHeaders: any = [
    {
      id: "current_cors",
      name: "Current Cores",
    },
    {
      id: "current_ram",
      name: "Current RAM(GB)",
    },
    {
      id: "mxcpu",
      name: "4 Weeks Max CPU",
    },
    {
      id: "mncpu",
      name: "4 Weeks Min CPU",
    },
    {
      id: "acpu",
      name: "Avg 4 Weeks CPU",
    },
    {
      id: "mvalue",
      name: "Avg 4 Weeks RAM Metrics",
    },
  ];

  recomHeader: any = [
    {
      id: "recom_cors",
      name: "Recommended Cores",
    },
    {
      id: "recom_ram",
      name: "Recommended RAM(GB)",
    },
  ];

  scanAccess: boolean = true;
  EMSStatus: boolean = false;
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.funRef = {
      loadMetrics: this.loadMetrics,
    };

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.refresh();
      }
    });
  }

  loading_recom: boolean = false;

  getTotalSavings(recommend_type: string): String {
    let savings_html = `0 USD`;
    let minsaving = 0;
    let maxsaving = 0;
    this.recomondations[recommend_type].forEach((recom: any) => {
      if (recom.recommendations && recom.recommendations.length > 0) {
        recom.recommendations.sort((a: any, b: any) => {
          if (
            (isNaN(a.savings) ? 0 : Number(a.savings)) <
            (isNaN(b.savings) ? 0 : Number(b.savings))
          ) {
            return -1;
          }
          return 0;
        });
        minsaving += isNaN(recom.recommendations[0].savings)
          ? 0
          : Number(recom.recommendations[0].savings);
        maxsaving += isNaN(
          recom.recommendations[recom.recommendations.length - 1].savings
        )
          ? 0
          : Number(
              recom.recommendations[recom.recommendations.length - 1].savings
            );
      }
    });
    if (minsaving == maxsaving) {
      savings_html = `${minsaving.toFixed(2)} USD`;
    } else {
      savings_html = `${minsaving.toFixed(2)} USD - ${maxsaving.toFixed(
        2
      )} USD`;
    }
    return savings_html;
  }

  mainHeaders() {
    return [...this.ec2CostHeaders, ...this.ec2Recommendations];
  }

  mainDetailsHeader() {
    return [...this.moreDetailsHeaders, ...this.recomHeader];
  }

  getTotalCost(recommend_type: string): String {
    let total = 0;
    this.recomondations[recommend_type].forEach((recom: any) => {
      total += isNaN(recom.present_price_plain)
        ? 0
        : Number(recom.present_price_plain);
    });
    return total.toFixed(2);
  }

  position: any = {
    x: "0px",
    y: "0px",
    overflow: false,
    overflowwidth: "0px",
    pointer: "0px",
    width: "",
    height: "",
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector(".message_back").offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector(".message_back").offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + "px";
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + "px";
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector(".message_back").offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector(".message_back").offsetWidth / 2 +
        "px";
    } else {
      this.position.x = 60 + "px";
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      "px";
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + "px";
    this.position.width =
      event.target.querySelector(".message_grid").offsetWidth + 30 + "px";
    this.position.height =
      event.target.querySelector(".message_grid").offsetHeight + 30 + "px";
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector(".message_grid").offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  loadMetrics(ref: any, value: any) {
    ref.selectedInstance = value;
  }

  hideMetric(event: any) {
    this.selectedInstance = null;
  }

  refresh_: boolean = false;
  async refresh() {
    this.fetching = true;
    this.refresh_ = true;
    this.jobDetails = null;
    this.loadStatusCount = 0;
    this.recomondations = {
      utilization: [],
      os: [],
      processor: [],
    };
    this.loadJobDetails();
  }

  pageOffset: number = 0;
  moveTop(event: any) {
    this.pageOffset = window.pageYOffset;
  }

  moveToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  getReason(): string {
    return this.recomondations["utilization"][0]["recommendations"][0][
      "reason"
    ];
  }

  pages: any = [10, 50, 100, 200];

  pagination: any = {
    utilization: {
      perPage: 10,
      page: 1,
    },
  };

  currentPage: string = "utilization";

  loadStatusCount: number = 0;
  async loadJobDetails() {
    this.fetching = true;
    this.pagination["utilization"]["page"] = 1;
    this.currentPage = "utilization";
    this.selectedInstance = null;
    this.recomondations = {
      utilization: [],
      os: [],
      processor: [],
    };
    let data = {
      action: "JobStatus",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2metricsupdated`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status_code == 1 || result.item) {
      if (result.item.length == 0) {
        this.jobDetails = null;
      } else {
        this.jobDetails = result.item[0];
        this.jobDetails.scandate = moment(this.jobDetails.scandate).format(
          "DD/MM/YYYY hh:mm A"
        );

        if (result.item[0]["acctstatus"] === "Completed") {
          this.loadStatusCount = 0;
          await this.loadrecomondations();
        } else {
          if (this.loadStatusCount > 10) {
            this.fetching = false;
            this.notifier.alert(
              "Info",
              "",
              "Scan is taking longer than usual. Please click on the Refresh button after a couple of minutes.",
              "info",
              -1
            );
          } else {
            let refreshCount = this.loadStatusCount;
            setTimeout(() => {
              if (refreshCount != this.loadStatusCount) {
                return;
              }
              this.loadJobDetails();
              this.loadStatusCount += 1;
            }, (10 + this.loadStatusCount) * 1000);
          }
        }
      }
    } else {
      this.jobDetails = null;
      if (result.message != "NO RECORD FOUND") {
        this.notifier.alert("Info", "", result.message, "info", 5000);
      }
    }
    if (
      !this.destroyed &&
      ((this.jobDetails && this.jobDetails["acctstatus"] === "Completed") ||
        !this.jobDetails)
    ) {
      this.fetching = false;
    }
    this.refresh_ = false;
    return true;
  }

  checkForEMS() {
    return (
      this.recomondations["utilization"].filter((recom: any) => {
        return recom.EMS;
      }).length == 0
    );
  }

  overview: any = {
    current_spend: 0,
    utilization_savings: 0,
    os_savings: 0,
    processor_savings: 0,
  };

  async loadrecomondations() {
    this.loading_recom = true;
    if (this.destroyed) {
      return true;
    }
    this.EMSStatus = false;
    this.refresh_ = true;
    this.notifier.loading(true);
    this.recomondations = {
      utilization: [],
      os: [],
      processor: [],
    };
    let data = {
      action: "getRecommendedData",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2metricsupatedv2`;

    let currentAccountId = this.accountId;
    let currentRegionId = this.regionId;
    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.status_code == 1 || result.instances) {
      result.instances = result.instances
        .filter((recom: any) => {
          if (recom.mvalue == "-") {
            recom.EMS = false;
          } else {
            recom.EMS = true;
          }

          return recom.insttype != recom.rec;
        })
        .map((recom: any) => {
          recom.recommendations = recom.recommendations
            .filter((recom_: any) => {
              return recom_["reason"].indexOf("Average CPU usage") > -1;
            })
            .map((recom_: any) => {
              recom_["savings_html"] = `<span class='${
                Number(recom_.savings) > 0
                  ? "green_text"
                  : Number(recom_.savings) < 0
                  ? "red_text"
                  : ""
              }'>$${recom_.savings}</span>`;
              if (recom["initial_insType_coreram"]) {
                let current_data = recom["initial_insType_coreram"].split(", ");
                recom["current_cors"] = current_data[0].split(":")[1];
                recom["current_ram"] = current_data[1].split(":")[1];
              }
              if (recom_["recommendedCoreRam"]) {
                let recommended_cors_ram =
                  recom_["recommendedCoreRam"].split(", ");
                recom_["recom_cors"] = recommended_cors_ram[0].split(":")[1];
                recom_["recom_ram"] = recommended_cors_ram[1].split(":")[1];
              }
              recom_["accountID"] = currentAccountId;
              recom_["regionID"] = currentRegionId;
              return recom_;
            });
          return recom;
        }).filter((recom: any) => {
          return recom['recommendations'].length > 0
        });
      this.recomondations["utilization"] = result.instances || [];
      this.recomondations["os"] = result.osRecommendations || [];
      this.recomondations["processor"] = result.insRecommendations || [];
    } else {
      this.loading_recom = false;
      if (result.message != "NO RECORD FOUND") {
      }
    }

    if (!this.destroyed) {
      this.notifier.loading(false);
    }
    this.loading_recom = false;
    return true;
  }

  currentAccountId: string = this.accountId;

  downloadReport() {
    let data: any = [];
    let utilizationReport: any = {
      sheetName: "Utilization",
      data: [],
    };

    utilizationReport["data"].push([
      { text: "Sl. No." },
      { text: "Instance Name" },
      { text: "Instance Type" },
      { text: "Instance ID" },
      { text: "Platform" },
      { text: "Current Spend (On Demand)" },
      { text: "Recommended" },
      { text: "New Spend (On Demand)" },
      { text: "Savings" },
      { text: "Current Cores" },
      { text: "Current RAM(GB)" },
      { text: "4 Weeks Max CPU" },
      { text: "4 Weeks Min CPU" },
      { text: "Avg 4 Weeks CPU" },
      { text: "Avg 4 Weeks RAM Metrics" },
      { text: "Recommended Cors" },
      { text: "Recommended RAM (GB)" },
    ]);

    this.recomondations["utilization"].forEach((item: any, index: number) => {
      utilizationReport["data"].push([
        { text: index + 1 },
        { text: item["instname"] || "-" },
        { text: item["insttype"] || "-" },
        { text: item["instid"] || "-" },
        { text: item["platform"] || "-" },
        { text: item["currentprice"] || "-" },
        { text: item["recommendations"][0]["recommendedInsType"] || "-" },
        { text: item["recommendations"][0]["price"] || "-" },
        { text: item["recommendations"][0]["savings"] || "-" },
        { text: item["current_cors"] || "-" },
        { text: item["current_ram"] || "-" },
        { text: item["mxcpu"] || "-" },
        { text: item["mncpu"] || "-" },
        { text: item["acpu"] || "-" },
        { text: item["mvalue"] || "-" },
        { text: item["recommendations"][0]["recom_cors"] || "-" },
        {
          text: item["recommendations"][0]["recom_ram"]
            ? (
                Number(item["recommendations"][0]["recom_ram"]) / 1024
              ).toString()
            : "-",
        },
      ]);
    });

    data.push(utilizationReport);

    let processorReport: any = {
      sheetName: "Processor",
      data: [],
    };

    processorReport["data"].push([
      { text: "Sl. No." },
      { text: "Instance ID" },
      { text: "Instance Name" },
      { text: "Instance Type" },
      { text: "Recommended Instance Type" },
      { text: "RAM" },
      { text: "vCPU" },
      { text: "OS" },
      { text: "Current Spend" },
      { text: "New Spend" },
      { text: "Savings" },
    ]);

    this.recomondations["processor"].forEach((item: any, index: number) => {
      processorReport["data"].push([
        { text: index + 1 },
        { text: item["instanceid"] || "-" },
        { text: item["instancename"] || "-" },
        { text: item["instancetype"] || "-" },
        { text: item["recommendedInsType"] || "-" },
        { text: item["ram"] || "-" },
        { text: item["vCpu"] || "-" },
        { text: item["platform"] || "-" },
        { text: item["currentPrice"] || "-" },
        { text: item["recommendedPrice"] || "-" },
        {
          text: (
            Number(item["currentPrice"]) - Number(item["recommendedPrice"])
          ).toFixed(2),
        },
      ]);
    });
    data.push(processorReport);

    let osReport: any = {
      sheetName: "OS",
      data: [],
    };

    osReport["data"].push([
      { text: "Sl. No." },
      { text: "Instance ID" },
      { text: "Instance Name" },
      { text: "Instance Type" },
      { text: "RAM" },
      { text: "vCPU" },
      { text: "Current OS" },
      { text: "Recommended OS" },
      { text: "Current Spend" },
      { text: "New Spend" },
      { text: "Savings" },
    ]);

    this.recomondations["os"].forEach((item: any, index: number) => {
      osReport["data"].push([
        { text: index + 1 },
        { text: item["instanceid"] || "-" },
        { text: item["instancename"] || "-" },
        { text: item["instancetype"] || "-" },
        { text: item["ram"] || "-" },
        { text: item["vCpu"] || "-" },
        { text: item["platform"] || "-" },
        { text: item["recommendedOs"] || "-" },
        { text: item["currentPrice"] || "-" },
        { text: item["recommendedPrice"] || "-" },
        {
          text: (
            Number(item["currentPrice"]) - Number(item["recommendedPrice"])
          ).toFixed(2),
        },
      ]);
    });
    data.push(osReport);

    var options = {
      fileName: `EC2 Cost Controls ${this.accountId}_${this.regionId}`,
    };

    Jhxlsx.export(data, options);
  }

  loadHTML(value: any) {
    if (value == "LOADING_PRICE_THROUGH_API") {
      return `<span class='center'>
        <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px;">
      </span>`;
    } else {
      return `<span>${value}</span>`;
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  createJob(event: any) {
    this.createJobTrigger = true;
  }

  hideCreateJob(event: any) {
    this.createJobTrigger = null;
    if (event["status"] == 1) {
      setTimeout(() => {
        this.loadStatusCount = 1;
        this.loadJobDetails();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
