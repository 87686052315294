<section>
  <div class="form-container">
    <div>
      <div class="swayam-name">Swayam Plans</div>
      <div>
        <span>Compare swayam plans and select which suits your workload</span>
      </div>
      <div class="compare-table-container">
        <table class="table">
          <thead>
            <tr>
              <th class="back_blue">Features</th>
              <th class="center back_blue">Free Trial</th>
              <th class="center back_blue">Standard</th>
              <th class="center back_blue">Advanced</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Plan Price<span class="light-text">/Server/Month</span></td>
              <td class="center">Free</td>
              <td class="center">5.00</td>
              <td class="center">7.00</td>
            </tr>
            <tr>
              <td>Support</td>
              <td class="center">
                <i class="fa fa-times-circle-o" aria-hidden="true"></i><br>
                <span class="display-none">Email Support</span>

              </td>
              <td class="center">
                Email Support
              </td>
              <td class="center">
                Email Support <br>
                <span class="light-text">(TAT 24 Hours)</span>
              </td>
            </tr>
            <tr>
              <td>One time setup</td>
              <td class="center">
                <i class="fa fa-times-circle-o" aria-hidden="true"></i>

              </td>
              <td class="center">
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>

              </td>
              <td class="center">
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>

              </td>
            </tr>
            <tr>
              <td class="center padding-5 small-text back_blue bold-text" colspan="4">Swayam Features</td>
            </tr>
            <tr *ngFor="let feature of getFeatures()">
              <td>{{ feature["plan"] }}</td>
              <td class="center">
                <span *ngIf="feature['freetrial']">
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </span>
                <span *ngIf="!feature['freetrial']">
                  <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                </span>
              </td>
              <td class="center">
                <span *ngIf="feature['standard']">
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </span>
                <span *ngIf="!feature['standard']">
                  <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                </span>
              </td>
              <td class="center">
                <span *ngIf="feature['advanced']">
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </span>
                <span *ngIf="!feature['advanced']">
                  <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>