<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Instances with Snapshot ID</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
      <div class="KBBody table-responsive ">

        <table class="table table-hover mb-0">
            <thead class="blue">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">Instance Name</td>
                <td scope="col">Instance ID</td>
                <td scope="col">Snapshot ID</td>
                <td scope="col">Last Snapshot on</td>
              </tr>
            </thead>
          

            <tbody class="scrollable-body">
              <tr *ngFor="let i of ebsList; let j = index">
                <td scope="row" class="m-4">{{j+1}}</td>
                <td>{{i.insName}}</td>
                <td>{{i.id}}</td>
                <td>{{i.latestSnapId}}</td>
                <td>{{i.formattedDate}}</td>
              </tr>           
            </tbody> 
        </table>
        
          <!-- <div class="btnHold">
            <div class="bhold">
              <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
              <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
              [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
            </div>
          </div> -->


        <!-- <div class="KBActions">
          <button class="btn btn-light" (click)="no()">Cancel</button>
        </div> -->
      
    </div>
          
</div>
