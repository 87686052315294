<div class="mb-3 mb_3_01">
  <div class="exchange-flex">
    <div>Dollar Value: </div>
    <div class="flex-start">
      <div class="cust_btn" [ngClass]="{active: dollarValue.type == 'dynamic'}"
        (click)="stepsCompleted < 4 && getDollarValue()">Dynamic</div>
      <div class="divider"></div>

    </div>
    <div class="cust_btn" (click)="stepsCompleted < 4 && dollarValue.type = 'fixed'"
      [ngClass]="{active: dollarValue.type == 'fixed'}">Fixed
    </div>
    <div *ngIf="dollarValue.type == 'dynamic'">
      <span *ngIf="dollarValue.value != 'LOADING'">
        {{dollarValue.value}}
      </span>
      <span *ngIf="dollarValue.value == 'LOADING'">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_Scan_18" />
      </span>
    </div>
    <div *ngIf="dollarValue.type == 'fixed' && stepsCompleted < 4">
      <input autocomplete="off" type="number" class="form-control" [(ngModel)]="dollarValue.value">
    </div>
    <div *ngIf="dollarValue.type == 'fixed' && stepsCompleted >= 4">
      {{dollarValue.value}}
    </div>
  </div>
</div>
<div>
  <div class="bom_under_process" *ngIf="processing">
    <span>
      BOM uploaded successfully. Please wait while we process the BOM...
    </span>
    <span>
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_Scan_35" />
      <button class="btn btn-primary-soft" (click)="refreshStatus(true)">
        Refresh
      </button>
    </span>
  </div>
  <div class="pricing_strategy_container" *ngFor="let header of selectedHeaders">
    <div class="pricing_sheet_header">
      <div>
        Sheet <span class="font_bold_alter">{{header.key}}</span> mapped to <span
          class="font_bold_alter">{{header.value}}</span>
      </div>
      <div class="datepicker_container"
        *ngIf=" pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length> 0">
        <span>Start Date</span>
        <span>{{formatDate(pricingStrategy[header.key]['start_date'], "DD-MM-YYYY", "DD-MM-YYYY")}}</span>
        <span>End Date</span>
        <span>{{pricingStrategy[header.key]['end_date']}}</span>
      </div>
    </div>
    <div class="bom_failed bom_failed_56"
      *ngIf="processFailed && processFailed['error'] && processFailed['error'][header.key]">
      <span *ngIf="processFailed['error_code'] == 'BOMV2E001'">
        Failed: Uploaded BOM is invalid. Missing columns are {{processFailed['error'][header.key][0].join(", ")}}.
        Please
        upload the valid BOM
      </span>
      <span>
        <button class="btn btn-primary-soft" (click)="refreshStatus(false)">
          Upload
        </button>
      </span>
    </div>
    <div class="pricing_sheet_body">
      <div *ngIf="header.value == 'Exclude'">
        <div class="no_strategy">
          No Pricing Plan
        </div>
      </div>
      <div *ngIf="header.id != 'exclude'">
        <div class="no_strategy"
          *ngIf="!pricingStrategy[header.key] || !pricingStrategy[header.key]['plans'] || pricingStrategy[header.key]['plans'].length == 0">
          No Pricing Plan
        </div>
        <div *ngIf="pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length > 0">
          <div class="strategy_div" *ngFor="let strategy of pricingStrategy[header.key]['plans']; let i = index">
            <div class="strategy_80">
              <div>
                <div>
                  <label for="">
                    Pricing Plan
                  </label>
                </div>
                <span>
                  {{strategy.plan}}
                </span>
              </div>
              <div *ngIf="strategy.plan == 'OD'">
                <div>
                  <label for="">
                    Hours per month
                  </label>
                </div>
                <span>
                  {{strategy.hours_per_month}}
                </span>
              </div>
              <div>
                <div>
                  <label for="">
                    Duration
                  </label>
                </div>
                <div *ngIf="strategy.plan == 'OD'" class="strategy_109">
                  {{strategy.duration}} {{strategy.period || 'Days'}}
                </div>
                <div *ngIf="strategy.plan != 'OD'">
                  {{getDuration(strategy.plan).duration}}
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>