import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-create-scan-finding",
  templateUrl: "./create-scan-finding.component.html",
  styleUrls: ["./create-scan-finding.component.css"],
})
export class CreateScanFindingComponent implements OnInit {
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;
  @Input("resourceGroup") resourceGroup: string;
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  @Input("finding") finding: string;
  @Input("findingName") findingName: string;
  @Input("findingCp") findingCp: string;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  userId = localStorage.getItem("eId");
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  creating: boolean = false;
  async createScan() {
    this.creating = true;
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/audit/ec2`;

    let data: any;

    if(this.findingCp != 'azure') {
      data  = {
        accountId: this.accountId,
        audit_type: `/audit/${this.finding}`,
        region_id: this.regionId,
      };

    }else if(this.findingCp == 'azure') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/azure/governance`
      data = {
        audit_type: this.finding,
        region_id: this.regionId,
        action: "create_scan",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
      };
    }

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      ((result.statusCode == 202 || result.statusCode == 200) &&
      result.Result == "success") || result.status == '1'
    ) {
      this.notifier.alert(
        "Success",
        "",
        "Scan created successfully",
        "success",
        5000
      );
      this.hideModel.emit(true);
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.message || result.error_message,
        "info",
        5000
      );
    }
    this.creating = false;
  }
}
