<div class="info-space">
  <div class="info-container">
    <div class="strong-text large-text how-to-relative">How to enable CUR report in AWS?
      <div class="absoute-close" (click)="hideModel.emit(false)">
        <i class="fa fa-times" aria-hidden="true"></i>

      </div>
    </div>
    <div class="main-container">
      <div class="steps-container" [ngStyle]="{'margin-left': getMarginLeft() }">
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-billing-icons/step1.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Enable CUR</div>
            <div>
              Go to billing dashboard on your AWS console. Click on <span class="bold-text">Cost & Usage Reports</span>
              then click on <span class="bold-text">Create Report</span>.
            </div>
          </div>
        </div>
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-billing-icons/step2.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Give inputs to Enable CUR</div>
            <div>
              Provide report name and click on <span class="bold-text">Next</span>
            </div>
          </div>
        </div>
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-billing-icons/step3.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Configure S3 Bucket</div>
            <div>
              Configure your s3 bucket to store the reports. You can create new bucket(recommended) or choose any
              existing
              bucket. <br>Provide s3 path prefix and Please choose the below options
            </div>
            <div class="flex-center">
              <div><span class="bold-text">1. Report data time granularity</span>: daily</div>
              <div><span class="bold-text">2. Report versioning</span>: Overwrite existing report</div>
              <div><span class="bold-text">3. Compression type</span>: ZIP</div>
            </div>
          </div>
        </div>
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-billing-icons/step 5.png"
              alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Review Details</div>
            <div>
              Review the details and click on <span class="bold-text">Create Report</span>.
            </div>
          </div>
        </div>
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-billing-icons/step 6.png"
              alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Please be Noted</div>
            <div>
              Once the report is created it can take upto 24 hours to get the report
            </div>
            <div>
              After enabling the CUR report, you can see the above screen in Cost and Usage Report page.
            </div>
          </div>
        </div>
        <div class="step-container">
          <div class="image-container">
            <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/swayam-billing-icons/step4.png" alt="">
          </div>
          <div class="description">
            <div class="strong-text large-text">Update Policy</div>
            <div>
              Once CUR report is created, go to that AWS S3 bucket which is given at the time cur report configuration.
              <ng-container *ngIf="data?.fetch?.s3Policy">
                <span class="nav-link" (click)="copyToClipboard(data?.fetch?.s3Policy)">click here</span> policy to that existing bucket policy.
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="steps-indicator" *ngIf="steps > 1">
        <div class="step" *ngFor="let _ of getArray(); let i = index" [ngClass]="{active_step: step == i}"
          (click)="setStep(i)"></div>
      </div>
      <div class="actions">
        <div>
          <button class="btn btn-light" (click)="removeStep()" *ngIf="step > 0">
            Prev
          </button>
        </div>
        <div>
          <button class="btn btn-light" (click)="appendStep()" *ngIf="step < steps - 1">
            Next
          </button>
          <button class="btn btn-light" (click)="hideModel.emit(false)" *ngIf="step >= steps - 1">
            Finish
          </button>
        </div>
      </div>
    </div>
  </div>
</div>