import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { Router, ActivatedRoute } from '@angular/router';
import { support } from 'jquery';

declare let window: any;

@Component({
  selector: 'confirm-element',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css'],
})
export class ConfirmBox implements OnInit {
  @ViewChild('attachmenrs') attachmentElement: ElementRef;
  @Output() hideModal = new EventEmitter<any>();
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  ngOnInit(): void {}

  yes() {
    this.hideModal.emit({ status: 1 });
  }

  no() {
    this.hideModal.emit({ status: 0 });
  }
}
