import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { APIService } from "../../api/api.service";
import { NotifierService } from "../../_services/notifier.service";
import * as echarts from "echarts";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class DashboardComponent implements OnInit, OnDestroy {
  regionId: string = localStorage.getItem("regionId");
  accountId: string = localStorage.getItem("accountId");
  selectedRegion: string = localStorage.getItem("selectedRegion");
  chart: echarts.ECharts;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  dailyCost: any = [];
  monthlyCost: any = [];
  totalIssue: any = [];
  totalCost: any = [];
  costSavings: any = [];
  resources: any = [];
  costCompare: any = [];
  instancesTypes: any = [];
  OSTypes: any = [];
  ec2Recomendation: any = [];
  recomondations: any = [];
  includeResolved: boolean = true;
  totalTickets: number = 0;
  dailyCostValue: String = "0.00";
  dailyCostDiff: String = "0.0";

  monthlyCostValue: String = "0.00";
  previousMonthCost: string = "0.00";
  monthlyCostDiff: String = "0.0";

  totalCostvalue: String = "0.00";
  totalCostDiff: String = "0.0";

  costSavingValue: String = "0.00";
  costSavingDiff: String = "0.0";

  countOfTags: String = "-";
  countOfCpu: String = "-";
  s3Size: any = "-";
  //for total issue
  resolved: number = 0;
  open: number = 0;
  closed: number = 0;
  WorkInProgress: number = 0;
  watingForCosRes: number = 0;
  closedByBot: number = 0;
  investigation: number = 0;
  minloader = true;
  loading: String = "true";
  currentMessage: any;
  currentSortOrder: string = "asc";
  sortIcon: string = "fa-arrow-down-wide-short";
  costApiError: boolean = false;
  dailyCostApiError: boolean = false;
  totalCostApiError: boolean = false;
  resCostApiError: boolean = false;
  resizeStart = false;
  hideGraph = true;
  instanceNotFound = false;
  instanceLoader = true;
  osNotFound = false;
  osLoader = true;
  ticketNotFound = false;
  hideForResize = false;
  instancesView = false;
  storageView = false;
  OSView: boolean = false;
  annualView = false;
  monthlyView = false;
  dailyView = false;
  accView = false;
  insList: any = [];
  outputArray: any = [];
  accountIdsList: any = [];
  accountIdsListWithName: any = "";
  isFirstReload = false;
  yearly: any = [];
  monthly: any = [];
  vcpu: any = [];
  tagsList: any = [];
  oswin: any = [];
  winTab = false;
  tagTab = false;
  totalcost: number = 0;
  currForecast: number = 0;
  storageList: any = [];
  forcastList: any = [];
  forcastTab = false;
  annualCostLoader = true;
  monthlyCostLoader = true;
  dailyCostLoader = true;
  vcpuLoader = true;
  tagLoader = true;
  storageLoader = true;
  forcastLoader = true;
  costSavingTab = false;
  CostSavings: any = [];
  costSavingLoader = true;
  resourceOption: any = [];
  divHeight: any = `400px`;
  firstDay = false;
  currentDate: string = "";
  expandable: boolean = false;
  ascending: boolean = true;
  selectedregion: string = "";
  forechart: echarts.ECharts;
  vcpuformattedData: any = [];
  regionHeaders: any = [];
  regionCodeToName: any = {
    "ap-south-1": "Mumbai",
    "ca-central-1": "Canada",
    "eu-central-1": "Frankfurt",
    "us-west-1": "Northern California",
    "us-west-2": "Oregon",
    "eu-north-1": "Stockholm",
    "eu-west-3": "Paris",
    "eu-west-2": "London",
    "eu-west-1": "Ireland",
    "ap-northeast-3": "Osaka",
    "ap-northeast-2": "Seoul",
    "ap-northeast-1": "Tokyo",
    "sa-east-1": "Sao Paulo",
    "ap-southeast-1": "Singapore",
    "ap-southeast-2": "Sydney",
    "us-east-1": "Northern Virginia",
    "us-east-2": "Ohio",
    "ap-east-1": "Hong Kong",
    "ap-south-2": "Hyderabad",
    "eu-south-1": "Milan",
    "eu-south-2": "Spain",
    "me-central-1": "UAE",
    "eu-central-2": "Zurich",
    "af-south-1": "Cape Town",
    "me-south-1": "Bahrain",
    "ap-southeast-3": "Jakarta",
    "ap-southeast-4": "Melbourne",
  };
  regions: any = [];
  vcpuNotFound=false;
  ramNotFound=false;
  s3NotFound=false;
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  cloudPartner: string =
    localStorage.getItem("cp-selected") == "aws" ? "aws" : null;
  resourceGroup: string = localStorage.getItem("selectedResourceGroup");

  changeCloudPartner(value: string) {
    if (value != this.cloudPartner) {
      this.cloudPartner = value;
    }
  }

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    this.selectedRegion = localStorage.getItem("selectedRegion");
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }

      if (d.key == "accountId") {
        this.changeCloudPartner(localStorage.getItem("cp-selected"));
        this.regionId = localStorage.getItem("regionId");
        this.selectedRegion = localStorage.getItem("selectedRegion");
        this.notifier.loading(true);
        this.accView = false;
        this.checkForAccounts();
        this.accountId = d.value;
        this.hideGraph = true;
        this.minloader = true;
        this.instanceLoader = true;
        this.instanceNotFound = false;
        this.osNotFound = false;
        this.osLoader = true;
        this.ticketNotFound = false;
        this.dailyCostValue = "0.00";
        this.dailyCostDiff = "0.0";
        this.monthlyCostValue = "0.00";
        this.previousMonthCost = "0.00";
        this.monthlyCostDiff = "0.0";
        this.totalCostvalue = "0.00";
        this.totalCostDiff = "0.0";
        this.costSavingValue = "0.00";
        this.costSavingDiff = "0.0";
        this.resolved = 0;
        this.open = 0;
        this.closed = 0;
        this.WorkInProgress = 0;
        this.watingForCosRes = 0;
        this.closedByBot = 0;
        this.investigation = 0;
        this.countOfTags = "-";
        this.countOfCpu = "-";
        this.s3Size = "-";
        this.currentSortOrder = "asc";
        this.resources = [];
        this.recomondations = [];
        this.instancesView = false;
        this.storageView = false;
        this.OSView = false;
        this.annualView = false;
        this.insList = [];
        this.outputArray = [];
        this.accView = true;
        this.monthlyView = false;
        this.dailyView = false;
        this.isFirstReload = false;
        this.yearly = [];
        this.monthly = [];
        this.vcpu = [];
        this.tagsList = [];
        this.oswin = [];
        this.winTab = false;
        this.tagTab = false;
        this.totalcost = 0;
        this.storageList = [];
        this.forcastList = [];
        this.forcastTab = false;
        this.annualCostLoader = true;
        this.monthlyCostLoader = true;
        this.dailyCostLoader = true;
        this.vcpuLoader = true;
        this.tagLoader = true;
        this.storageLoader = true;
        this.forcastLoader = true;
        this.costSavingTab = false;
        this.CostSavings = [];
        this.costSavingLoader = true;
        this.resourceOption = [];
        this.firstDay = false;
        this.currentDate = "";
        this.selectedregion = "";
        this.currForecast = 0;
        this.vcpuformattedData = [];
        this.regionHeaders = [];
        this.vcpuNotFound=false;
        this.ramNotFound=false;
        this.s3NotFound=false;
        //this.resizeMainBox()
        setTimeout(() => {
          if (this.accountIdsList.length != 0) {
            this.load();
          } else {
            this.notifier.loading(false);
          }
        }, 2000);
      } else if (d.key == "regionId") {
        // this.cloudPartner = localStorage.getItem("cp-selected");
        this.accountId = localStorage.getItem("accountId");
        this.selectedRegion = localStorage.getItem("selectedRegion");
        this.notifier.loading(true);
        this.accView = false;
        this.checkForAccounts();
        this.regionId = d.value;
        this.hideGraph = true;
        this.minloader = true;
        this.instanceLoader = true;
        this.instanceNotFound = false;
        this.osNotFound = false;
        this.osLoader = true;
        this.ticketNotFound = false;
        this.dailyCostValue = "0.00";
        this.dailyCostDiff = "0.0";
        this.monthlyCostValue = "0.00";
        this.monthlyCostDiff = "0.0";
        this.totalCostvalue = "0.00";
        this.totalCostDiff = "0.0";
        this.costSavingValue = "0.00";
        this.costSavingDiff = "0.0";
        this.previousMonthCost = "0.00";
        this.resolved = 0;
        this.open = 0;
        this.closed = 0;
        this.WorkInProgress = 0;
        this.watingForCosRes = 0;
        this.closedByBot = 0;
        this.investigation = 0;
        this.countOfTags = "-";
        this.countOfCpu = "-";
        this.s3Size = "-";
        this.currentSortOrder = "asc";
        this.resources = [];
        this.recomondations = [];
        this.instancesView = false;
        this.storageView = false;
        this.OSView = false;
        this.annualView = false;
        this.insList = [];
        this.outputArray = [];
        this.accView = true;
        this.monthlyView = false;
        this.dailyView = false;
        this.isFirstReload = false;
        this.yearly = [];
        this.monthly = [];
        this.vcpu = [];
        this.tagsList = [];
        this.oswin = [];
        this.winTab = false;
        this.tagTab = false;
        this.totalCost = 0;
        this.storageList = [];
        this.forcastList = [];
        this.forcastTab = false;
        this.annualCostLoader = true;
        this.monthlyCostLoader = true;
        this.dailyCostLoader = true;
        this.vcpuLoader = true;
        this.tagLoader = true;
        this.storageLoader = true;
        this.forcastLoader = true;
        this.costSavingTab = false;
        this.CostSavings = [];
        this.costSavingLoader = true;
        this.resourceOption = [];
        this.firstDay = false;
        this.currentDate = "";
        this.selectedregion = "";
        this.currForecast = 0;
        this.vcpuformattedData = [];
        this.regionHeaders = [];

        this.vcpuNotFound=false;
        this.ramNotFound=false;
        this.s3NotFound=false;
        //this.resizeMainBox()
        setTimeout(() => {
          if (this.accountIdsList.length != 0) {
            this.load();
          } else {
            this.notifier.loading(false);
          }
        }, 2000);
      } else if (d.key == "resourceGroup") {
        this.changeCloudPartner(localStorage.getItem("cp-selected"));
        this.resourceGroup = d.value;
        this.accView = false;
      }
    });
  }

  onResize(event: any) {
    let currentUrl = this.router.url;
    if (this.resizeStart == false) {
      this.resizeStart = true;
      this.hideForResize = true;
      setTimeout(() => {
        this.hideForResize = false;
        if (this.cloudPartner != "azure") {
          this.notifier.loading(true);
          this.load();
          if (this.forechart) {
            this.forechart.resize();
          }
        }
        //this.router.navigate([currentUrl]);
        //window.location.href = "/dash/home";
        this.resizeStart = false;
      }, 500);
    }
  }

  resizeMainBox() {
    //let width = $(".mainBox").width();
    //let height = $(".mainBox").height();
    //$(".leftOuterBox").width(width * .7);
    //$(".costOuterBox").width(width * .7);
    //$(".cpuOuterBox").width(width * .7);
    //$(".ticketOuterBox").width(width * .7);
    //$(".costCard").width(width * .3);
    //$(".rightOuterBox").width(width * .3);
    //$(".costCompareOuterBox").width(width * .3);
    //$(".totalIssue").width(width * .3);
  }

  async checkForAccounts() {
    //  const accountList = localStorage.getItem("accountListNew");
    const accountListData = localStorage.getItem("accountIdData");

    const data1 = JSON.parse(accountListData);
    let idList: any = [];
    if (data1["list"].length > 0) {
      idList = data1["list"].sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1;
        }
        if (a.accountName > b.accountName) {
          return 1;
        }
        return 0;
      });
    }

    //const email = 'test7@minfytech.com';
    const email = localStorage.getItem("eId");
    const clientName = idList[0]["companyName"];
    let groupName = localStorage.getItem("selectedAccountGroup");

    if (groupName == null) {
      groupName = "clientportal";
    }
    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/dashboard_user_preference/?user_email=${email}&group_name=${groupName}&client_name=${clientName}&action=fetch&user_type=client`;

    await this.apiServer.postDataPromis(apiURL, {}, header).then((data) => {
      try {
        if (data["detail"].includes("not")) {
          this.isFirstReload = true;
          this.accView = true;
        }
      } catch (error) {
        this.accView = false;
        this.accountIdsList = data["message"];
        this.accountIdsListWithName = idList
          .filter((item: any) => this.accountIdsList.includes(item.accountId))
          .map((item: any) => ({
            cName: `${item.accountName} (${item.accountId})`,
            accId: item.accountId,
          }));
      }
    });
  }
  calculateDivHeight() {
    //  setTimeout(() => {
    //  this.divHeight='100px';
    const accountListData = localStorage.getItem("accountIdData");

    const data1 = JSON.parse(accountListData);
    let idList: any = [];
    if (data1["list"].length > 0) {
      idList = data1["list"].sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1;
        }
        if (a.accountName > b.accountName) {
          return 1;
        }
        return 0;
      });
    }
    this.divHeight = idList.length * 40 + "px";

    //  return this.divHeight;
    //  }, 2000);
  }
  getCurrentDate() {
    const currentDate = moment().format("Do MMM YYYY");
    this.currentDate = `(As of ${currentDate})`;
  }
  load() {
    this.ascending = true;
    this.getRegionName();
    this.getCurrentDate();
    this.recomondations = [];
    this.costSavingValue = "0.00";
    // this.calculateDivHeight();
    this.checkForJobStatus();
    // this.loadMultiCostSavings();
    this.loadMinfySupportTickets();
    // this.loadrecomondations();
    this.loadTotalCostData();
    this.loadCostData();
    this.loadMonthlyCostData();
    // this.loadTotalInsData();
    // this.loadRecomendationData();

    // this.loadVcpu();
    //this.loadTags();
    // this.loadRams();
    // this.loadS3Size();
    // this.loadtotalIssueGraphData();
    // this.loadGraph();
    this.loadResourseGraphData();

    // this.loadResourceGraph();

    // this.loadInstancesTypes();
    // this.loadOSTypes();
    this.getAllRegions();
    // this.loadDistributionGraph();
    // this.loadCostComparedGraph();
    this.notifier.loading(false);
  }

  getRegionName() {
    const startIndex = this.selectedRegion.indexOf("(") + 1;
    const endIndex = this.selectedRegion.indexOf(")");
    this.selectedregion = this.selectedRegion.substring(startIndex, endIndex);
  }
  async loadCostData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/get_total_cost_of_the_account/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&report_timeline=DAILY&aws_region=${this.regionId}`;
    if (!this.checkFirstDay()) {
      try {
        this.firstDay = false;
        await this.apiServer
          .postDataPromis(apiURL, data, header)
          .then((data) => {
            try {
              // this.dailyCost =
              //   data["accounts_ids"][this.accountId]["total_daily_report"];
              // let keys = Object.keys(this.dailyCost);
              // let second_key = this.dateCompare(keys);
              // this.dailyCostValue = this.dailyCost[second_key].replace("USD", "");
              // this.dailyCostDiff = this.dailyCost["difference"].toString();
              const accountListData = localStorage.getItem("accountIdData");

              const data1 = JSON.parse(accountListData);
              let idList: any = [];
              if (data1["list"].length > 0) {
                idList = data1["list"].sort(function (a: any, b: any) {
                  if (a.accountName < b.accountName) {
                    return -1;
                  }
                  if (a.accountName > b.accountName) {
                    return 1;
                  }
                  return 0;
                });
              }

              let costValue: number = 0;
              let diff: number = 0;
              this.dailyCost = Object.entries(data["accounts_ids"]).map(
                ([key, value]) => {
                  return { id: key, cost: value };
                }
              );
              this.dailyCost.forEach((obj: any) => {
                let keys = Object.keys(obj.cost.total_daily_report);
                let second_key = this.dateCompare(keys);
                costValue += Number(
                  obj.cost.total_daily_report[second_key]
                    .replace("USD", "")
                    .replace(/,/g, "")
                );
                diff += obj.cost.total_daily_report["difference"];
              });

              diff = Math.round((diff / this.dailyCost.length) * 100) / 100;
              const formattedNumber = String(
                costValue.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              );

              this.dailyCostValue = formattedNumber;
              this.dailyCostDiff = String(diff.toFixed(2));

              //for sub menu
              this.dailyCost = this.dailyCost.map((item: any) => {
                const costArray = Object.entries(
                  item.cost.total_daily_report
                ).map(([key, value]) => ({ key, value }));
                const selectedIndices = [2, 3];
                const selectedCostArray = costArray.filter((_, index) =>
                  selectedIndices.includes(index)
                );
                const matchedData = idList.find(
                  (data: any) => data.accountId === item.id
                );
                const newItem = {
                  id: matchedData?.accountName + " (" + item.id + ")",
                  cost: selectedCostArray,
                };
                return newItem;
                //return { id: item.id, cost: selectedCostArray };
              });

              const replaceUnderscores = (str: string) =>
                str.replace(/_/g, " ");

              this.dailyCost.forEach((item: any) => {
                item.cost.forEach((costItem: any) => {
                  costItem.key = replaceUnderscores(costItem.key);
                });
              });

              this.dailyCostApiError = false;
              this.dailyCostLoader = false;
            } catch (error) {
              this.dailyCostApiError = true;
              this.dailyCostLoader = false;
            }
          });
      } catch (error) {}
    } else {
      this.firstDay = true;
      this.dailyCostLoader = false;
    }
  }
  checkFirstDay() {
    return moment().format("DD") == "01";
  }
  async loadMonthlyCostData() {
    let data = {
      user_type: "client",
      report_timeline: "MONTHLY",
      aws_region: this.regionId,
    };

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    // this.accountIdsList="837682544263,872599169723";
    let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/get_total_cost_of_the_account/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&report_timeline=MONTHLY&aws_region=${this.regionId}`;

    try {
      this.monthlyCostValue = "0.00";

      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        if (!this.checkFirstDay()) {
          try {
            this.firstDay = false;
            // this.monthlyCost =
            //   data["accounts_ids"][this.accountId]["total_monthly_report"];
            // let keys = Object.keys(this.monthlyCost);

            // let second_key = this.dateCompare(keys);
            // this.monthlyCostValue = this.monthlyCost[second_key].replace(
            //   "USD",
            //   ""
            // );
            // this.monthlyCostDiff = this.monthlyCost["difference"].toString();
            // let monthly=[];

            const accountListData = localStorage.getItem("accountIdData");

            const data1 = JSON.parse(accountListData);
            let idList: any = [];
            if (data1["list"].length > 0) {
              idList = data1["list"].sort(function (a: any, b: any) {
                if (a.accountName < b.accountName) {
                  return -1;
                }
                if (a.accountName > b.accountName) {
                  return 1;
                }
                return 0;
              });
            }

            let costValue: number = 0;
            let preCostValue: number = 0;
            let diff: number = 0;
            this.monthly = Object.entries(data["accounts_ids"]).map(
              ([key, value]) => {
                return { id: key, cost: value };
              }
            );

            this.monthly.forEach((obj: any) => {
              let keys = Object.keys(obj.cost.total_monthly_report);
              let second_key = this.dateCompare(keys);
              let first_key = this.dateCompare2(keys);
              costValue += Number(
                obj.cost.total_monthly_report[second_key]
                  .replace("USD", "")
                  .replace(/,/g, "")
              );
              preCostValue += Number(
                obj.cost.total_monthly_report[first_key]
                  .replace("USD", "")
                  .replace(/,/g, "")
              );
              diff += obj.cost.total_monthly_report["difference"];
            });

            diff = Math.round((diff / this.monthly.length) * 100) / 100;

            const formattedNumber = String(
              costValue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );

            this.monthlyCostValue = formattedNumber;
            this.previousMonthCost = String(preCostValue.toFixed(2));
            this.monthlyCostDiff = String(diff.toFixed(2));

            //for sub menu
            this.monthly = this.monthly.map((item: any) => {
              const costArray = Object.entries(
                item.cost.total_monthly_report
              ).map(([key, value]) => ({ key, value }));
              const selectedIndices = [2, 3];
              const selectedCostArray = costArray.filter((_, index) =>
                selectedIndices.includes(index)
              );
              const matchedData = idList.find(
                (data: any) => data.accountId === item.id
              );
              const newItem = {
                id: matchedData?.accountName + " (" + item.id + ")",
                cost: selectedCostArray,
              };
              return newItem;
              // return { id: item.id, cost: selectedCostArray };
            });

            const replaceUnderscores = (str: string) => str.replace(/_/g, " ");

            this.monthly.forEach((item: any) => {
              item.cost.forEach((costItem: any) => {
                costItem.key = replaceUnderscores(costItem.key);
              });
            });
            //end

            this.loading = "true";
            this.costApiError = false;
            this.monthlyCostLoader = false;
          } catch (error) {
            this.loading = "false";
            this.costApiError = true;
            this.monthlyCostLoader = false;
          }
        } else {
          this.firstDay = true;
          this.monthlyCostLoader = false;
          this.forcastLoader = false;
        }
      });
      this.loadCostCompareGraphData();
    } catch (error) {}
  }

  dateCompare(key: any): any {
    const date1: string = key[0];
    const date2: string = key[1];

    const momentDate1 = moment(date1, "DD MMM, YYYY");
    const momentDate2 = moment(date2, "DD MMM, YYYY");
    const diffInDays = momentDate1.diff(momentDate2, "days");
    const diffInMonths = momentDate1.diff(momentDate2, "months");

    if (diffInDays > diffInMonths) {
      return date1;
    } else if (diffInMonths > diffInDays) {
      return date2;
    }
  }

  dateCompare2(key: any): any {
    const date1: string = key[0];
    const date2: string = key[1];

    const momentDate1 = moment(date1, "DD MMM, YYYY");
    const momentDate2 = moment(date2, "DD MMM, YYYY");
    const diffInDays = momentDate1.diff(momentDate2, "days");
    const diffInMonths = momentDate1.diff(momentDate2, "months");

    if (diffInDays < diffInMonths) {
      return date1;
    } else if (diffInMonths < diffInDays) {
      return date2;
    }
  }

  async loadTotalCostData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/get_total_cost_of_the_account/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&report_timeline=YEARLY&aws_region=${this.regionId}`;
    if (!this.checkFirstDay()) {
      this.firstDay = false;
      try {
        await this.apiServer
          .postDataPromis(apiURL, data, header)
          .then((data) => {
            try {
              //  this.totalCostvalue = data["accounts_ids"][this.accountId]["total_yearly_report"].replace("USD", "");
              // let yearly=[];
              let totalYearly: number = 0;
              this.yearly = Object.entries(data["accounts_ids"]).map(
                ([key, value]) => {
                  return { id: key, cost: value };
                }
              );
              this.yearly.forEach((obj: any) => {
                totalYearly += Number(
                  obj.cost.total_yearly_report
                    .replace("USD", "")
                    .replace(/,/g, "")
                );
              });
              const formattedNumber = String(
                totalYearly.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              );
              this.totalCostvalue = formattedNumber;
              this.totalCostApiError = false;
              this.annualCostLoader = false;
            } catch (error) {
              this.totalCostApiError = true;
              this.annualCostLoader = false;
            }
          });
      } catch (error) {}
    } else {
      this.firstDay = true;
      this.annualCostLoader = false;
    }
  }

  async loadCostCompareGraphData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/get_forecast_for_billing_information/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&report_timeline=MONTHLY&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          const accountListData = localStorage.getItem("accountIdData");

          const data1 = JSON.parse(accountListData);
          let idList: any = [];
          if (data1["list"].length > 0) {
            idList = data1["list"].sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          }
          this.currForecast = 0;
          this.forcastList = Object.entries(data["accounts_ids"]).map(
            ([key, value]) => {
              return { id: key, cost: value };
            }
          );

          this.totalcost = 0;
          this.forcastList.forEach((obj: any) => {
            this.totalcost += Number(
              obj.cost.total_forecast_monthly_report.prediction_amount
            );
            this.currForecast += Number(
              obj.cost.total_forecast_monthly_report.current_month
                .prediction_amount
            );
          });
          this.totalcost = Number(this.totalcost.toFixed(2));
          this.currForecast = Number(this.currForecast.toFixed(2));
          this.forcastList = idList
            .map((obj: any) => {
              const resultItem = this.forcastList.find(
                (item: any) => item.id === obj.accountId
              );
              if (resultItem) {
                return {
                  id: `${obj.accountName} (${obj.accountId})`,
                  cost: resultItem.cost,
                };
              }
              return null;
            })
            .filter(Boolean);
          // this.costCompare =
          //   data["accounts_ids"][this.accountId][
          //   "total_forecast_monthly_report"
          //   ];
          this.loading == "true";
          this.forcastLoader = false;
        } catch (error) {
          this.forcastLoader = false;
          this.loading == "false";
          this.costCompare = [];
        }

        this.loadCostComparedGraph();
      });
    } catch (error) {}
  }

  async loadS3Size() {
    this.s3NotFound=false;
    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/get_total_size_of_s3_buckets_by_cloudwatch/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&aws_region=all`;

    try {
      await this.apiServer.postDataPromis(apiURL, {}, header).then((data) => {
        try {
          this.s3Size = data;

          const accountListData = localStorage.getItem("accountIdData");

          const data1 = JSON.parse(accountListData);
          let idList: any = [];
          if (data1["list"].length > 0) {
            idList = data1["list"].sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          }

          // for (const accountId in data.accounts_ids) {
          //   const regionData = data.accounts_ids[accountId].regions;
          //   const types = [
          //     "total_size_of_s3",
          //     "total_efs_size",
          //     "total_ebs_size",
          //   ];
          //   const accountData = {
          //     id: accountId,
          //     regionData: types.map((type: any) => {
          //       const typeData = Object.values(regionData).map(
          //         (region: any) => {
          //           if (type === "total_size_of_s3") {
          //             return region[type].toString();
          //           } else if (type === "total_efs_size") {
          //             return region[type].toString();
          //           } else if (type === "total_ebs_size") {
          //             return region[type].toString();
          //           } else {
          //             return "NA";
          //           }
          //         }
          //       );
          //       return { type: type, values: typeData };
          //     }),
          //   };
          //   this.storageList.push(accountData);
          // }

          for (const accountId in data.accounts_ids) {
            const regionData = data.accounts_ids[accountId].regions;
            const types = [
              "total_size_of_s3",
              "total_efs_size",
              "total_ebs_size",
            ];
            const accountData = {
              id: accountId,
              regionData: types.map((type: any) => {
                const typeData: any = [];
                this.regions.forEach((region: any) => {
                  const regionValue =
                    regionData[region] && regionData[region][type] !== undefined
                      ? regionData[region][type].toString()
                      : "0.00 MB";
                  typeData.push(regionValue);
                });
                return { type: type, values: typeData };
              }),
            };
            this.storageList.push(accountData);
          }
          this.storageList = idList
            .map((obj: any, index:number) => {
              const resultItem = this.storageList.find(
                (item: any) => item.id === obj.accountId
              );
              if (resultItem) {
                return {
                  id: `${obj.accountName} (${obj.accountId})`,
                  regionData: resultItem.regionData,
                  isHidden: index === 0 ? true : false
                };
              }
              return null;
            })
            .filter(Boolean);
          // this.storageList = Object.entries(data["accounts_ids"]).map(
          //   ([key, value]) => {
          //     return { id: key, cost: value };
          //   }
          // );

          // this.storageList = this.storageList.map((item: any) => {
          //   const costArray = [
          //     {
          //       key: "Elastic Block Storage (EBS)",
          //       value: item.cost.total_ebs_size,
          //     },
          //     {
          //       key: "Elastic File System (EFS)",
          //       value: item.cost.total_efs_size,
          //     },
          //     {
          //       key: "Simple Storage Service (S3)",
          //       value: item.cost.total_size_of_all_s3,
          //     },
          //   ];
          //   return { id: item.id, cost: costArray };
          // });

          // this.storageList = idList
          //   .map((obj: any) => {
          //     const resultItem = this.storageList.find(
          //       (item: any) => item.id === obj.accountId
          //     );
          //     if (resultItem) {
          //       return {
          //         id: `${obj.accountName} (${obj.accountId})`,
          //         cost: resultItem.cost,
          //       };
          //     }
          //     return null;
          //   })
          //   .filter(Boolean);

          this.storageLoader = false;
        } catch (error) {
          this.storageLoader = false;
          this.s3NotFound=true;
        }
      });
    } catch (error) {
      this.storageLoader = false;
      this.s3NotFound=true;
    }
    this.storageLoader = false;
  }
  async loadTags() {
    this.tagsList = [];
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/list_of_resources_attached_to_tags_info/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          // this.countOfTags = data["accounts_ids"][this.accountId]["count_of_tag_keys"];
          let tagCount = 0;
          this.tagsList = Object.entries(data["accounts_ids"]).map(
            ([key, value]) => {
              return { id: key, cost: value };
            }
          );

          const accountListData = localStorage.getItem("accountIdData");

          const data1 = JSON.parse(accountListData);
          let idList: any = [];
          if (data1["list"].length > 0) {
            idList = data1["list"].sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          }

          const modifyId = (id: string) => {
            const match = idList.find((item: any) => item.accountId === id);
            if (match) {
              return `${match.accountName} (${id})`;
            }
            return id;
          };

          this.tagsList = this.tagsList.map((item: any) => ({
            id: modifyId(item.id),
            tag: [{ key: item.cost.count_of_tag_keys }],
          }));

          //  this.tagsList.forEach((obj: any) => {
          // console.log(obj);

          //  })
          this.countOfTags = String(data["total_count_of_tags_of_all_account"]);
          this.tagLoader = false;
        } catch (error) {}
      });
    } catch (error) {}
  }

  async loadRams() {
    this.ramNotFound=false;
    this.tagsList = [];
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/calculate_total_ram/?list_of_account_numbers=${this.accountIdsList}&user_type=client&aws_region=all`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          // this.countOfTags = data["accounts_ids"][this.accountId]["count_of_tag_keys"];
          let tagCount = 0;
          // this.tagsList = Object.entries(data["accounts_ids"]).map(
          //   ([key, value]) => {
          //     return { id: key, cost: value };
          //   }
          // );

          const accountListData = localStorage.getItem("accountIdData");

          const data1 = JSON.parse(accountListData);
          let idList: any = [];
          if (data1["list"].length > 0) {
            idList = data1["list"].sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          }

          const modifyId = (id: string) => {
            const match = idList.find((item: any) => item.accountId === id);
            if (match) {
              return `${match.accountName} (${id})`;
            }
            return id;
          };

          // for (const accountId in data.accounts_ids) {
          //   if (accountId !== "total_ram_of_all_accounts") {
          //     const regions = data.accounts_ids[accountId].regions;
          //     const totalRamValues = Object.values(regions).map(
          //       (region: any) => region.total_ram
          //     );
          //     const accountData = {
          //       id: accountId,
          //       regionData: [{ type: "RAM", values: totalRamValues }],
          //     };
          //     this.tagsList.push(accountData);
          //   }
          // }

          for (const accountId in data.accounts_ids) {
            if (accountId !== "total_ram_of_all_accounts") {
              const regions = data.accounts_ids[accountId].regions;
              const totalRamValues: any = [];
              this.regions.forEach((region: any) => {
                const regionValue =
                  regions[region] && regions[region].total_ram !== undefined
                    ? regions[region].total_ram
                    : "0.00 MB";
                totalRamValues.push(regionValue);
              });
              const accountData = {
                id: accountId,
                regionData: [{ type: "RAM", values: totalRamValues }],
              };
              this.tagsList.push(accountData);
            }
          }

          this.tagsList = this.tagsList.map((item: any, index:number) => (
            {
            id: modifyId(item.id),
            regionData: item.regionData,
            isHidden: index === 0 ? true : false 
          }));

          // this.tagsList = this.tagsList.map((item: any) => ({
          //   id: modifyId(item.id),
          //   tag: [{ key: item.cost.total_ram }],
          // }));

          //  this.tagsList.forEach((obj: any) => {
          // console.log(obj);

          //  })
          this.countOfTags = String(
            data["accounts_ids"]["total_ram_of_all_accounts"]["total"]
          );
          this.tagLoader = false;
        } catch (error) {
          this.tagLoader = false;
          this.ramNotFound=true;
        }
      });
    } catch (error) {
      this.tagLoader = false;
      this.ramNotFound=true;
    }
    this.tagLoader = false;
  }

  async loadVcpu() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/get_the_count_of_cpu/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          // this.countOfCpu = data["accounts_ids"][this.accountId]["total_count_of_vcpu"];
          let vcpuCount = 0;
          this.vcpu = Object.entries(data["accounts_ids"]).map(
            ([key, value]) => {
              return { id: key, cost: value };
            }
          );

          this.loadTotalInsData();

          this.vcpu.forEach((obj: any) => {
            vcpuCount += Number(obj.cost.total_count_of_vcpu);
          });
          this.countOfCpu = String(vcpuCount);
          // this.vcpuLoader = false;
        } catch (error) {}
      });
    } catch (error) {}
  }

  async loadInstancesTypes() {
    this.instanceLoader = true;
    this.instanceNotFound = false;
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/list_all_ec2_instances_type_and_their_count/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&aws_region=all`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          const areAllInstancesCountZero = (accountsData: any): boolean => {
            for (const accountId in accountsData) {
              if (accountsData.hasOwnProperty(accountId)) {
                if (accountsData[accountId].instances_count !== 0) {
                  return false;
                }
              }
            }
            return true;
          };
          const allInstancesCountZero = areAllInstancesCountZero(
            data["accounts_ids"]
          );
          if (Object.keys(data["accounts_ids"]).length == 0) {
            this.instanceLoader = false;
            this.instanceNotFound = true;
          }
          if (!allInstancesCountZero) {
            this.instanceNotFound = false;

            let test = Object.entries(data["accounts_ids"]).map(
              ([key, value]) => {
                return { id: key, cost: value };
              }
            );

            // this.instancesTypes =
            //   data["accounts_ids"][this.accountId]["instances_type"];

            const countObject: { [key: string]: number } = {};

            test.forEach((obj: any) => {
              this.instancesTypes = obj.cost.instances_type;

              for (const key in this.instancesTypes) {
                const type = key.charAt(0).toUpperCase();
                const label = `${type} Type`;
                if (countObject.hasOwnProperty(label)) {
                  countObject[label] += this.instancesTypes[key];
                } else {
                  countObject[label] = this.instancesTypes[key];
                }
              }
            });

            const accountListData = localStorage.getItem("accountIdData");
            const data1 = JSON.parse(accountListData);
            let idList: any = [];
            if (data1["list"].length > 0) {
              idList = data1["list"].sort(function (a: any, b: any) {
                if (a.accountName < b.accountName) {
                  return -1;
                }
                if (a.accountName > b.accountName) {
                  return 1;
                }
                return 0;
              });
            }

            // for (const accountId in data.accounts_ids) {
            //   if (data.accounts_ids.hasOwnProperty(accountId)) {
            //     const regionData = data.accounts_ids[accountId].regions;
            //     const uniqueInstances = new Set();
            //     Object.values(regionData).forEach((region: any) => {
            //       Object.keys(region.instances_type).forEach(
            //         (insType: string) => {
            //           uniqueInstances.add(insType);
            //         }
            //       );
            //     });
            //     const regionDataArray = Array.from(uniqueInstances).map(
            //       (insType: any) => {
            //         const values = Object.values(regionData).map(
            //           (region: any) => {
            //             return region.instances_type[insType]
            //               ? region.instances_type[insType].toString()
            //               : "0";
            //           }
            //         );
            //         return { insType, values };
            //       }
            //     );
            //     this.outputArray.push({ id: accountId, regionData: regionDataArray });
            //   }
            // }

            //for multi region
            // for (const accountId in data.accounts_ids) {
            //   if (data.accounts_ids.hasOwnProperty(accountId)) {
            //     const regionData = data.accounts_ids[accountId].regions;
            //     const uniqueInstances: any = {};
            //     Object.values(regionData).forEach((region: any) => {
            //       Object.keys(region.instances_type).forEach(
            //         (insType: string) => {
            //           const groupKey = insType.charAt(0);
            //           if (!uniqueInstances[groupKey]) {
            //             uniqueInstances[groupKey] = [];
            //           }
            //           uniqueInstances[groupKey].push(insType);
            //         }
            //       );
            //     });
            //     const regionDataArray = Object.entries(uniqueInstances).map(
            //       ([groupKey, instances]: [any, any]) => {
            //         const dataValues = instances.map((insType: string) => {
            //           const values = Object.values(regionData).map(
            //             (region: any) => {
            //               return region.instances_type[insType]
            //                 ? region.instances_type[insType].toString()
            //                 : "0";
            //             }
            //           );
            //           return { insType, values };
            //         });
            //         return {
            //           type: `${groupKey.toUpperCase()} Type`,
            //           dataValues,
            //         };
            //       }
            //     );
            //     this.outputArray.push({
            //       id: accountId,
            //       regionData: regionDataArray,
            //     });
            //   }
            // }

            for (const accountId in data.accounts_ids) {
              if (data.accounts_ids.hasOwnProperty(accountId)) {
                const regionData = data.accounts_ids[accountId].regions;
                const uniqueInstances: any = {};
                Object.values(regionData).forEach((region: any) => {
                  Object.keys(region.instances_type).forEach(
                    (insType: string) => {
                      const groupKey = insType.charAt(0);
                      if (!uniqueInstances[groupKey]) {
                        uniqueInstances[groupKey] = [];
                      }
                      uniqueInstances[groupKey].push(insType);
                    }
                  );
                });
                const regionDataArray = Object.entries(uniqueInstances).map(
                  ([groupKey, instances]: [any, any],index:number) => {
                    const dataValues = instances.map((insType: string) => {
                      const values = this.regions.map((region: any) => {
                        return regionData[region] &&
                          regionData[region].instances_type[insType] !==
                            undefined
                          ? regionData[region].instances_type[
                              insType
                            ].toString()
                          : "0";
                      });
                      return { insType, values };
                    });
                    return {
                      type: `${groupKey.toUpperCase()} Type`,
                      dataValues,
                      isHidden: index === 0 ? true : false
                    };
                  }
                );
                this.outputArray.push({
                  id: accountId,
                  regionData: regionDataArray,
                });
              }
            }

            this.outputArray = idList
              .map((obj: any, index:number) => {
                const resultItem = this.outputArray.find(
                  (item: any) => item.id === obj.accountId
                );
                if (resultItem) {
                  return {
                    id: `${obj.accountName} (${obj.accountId})`,
                    regionData: resultItem.regionData,
                    isHidden: index === 0 ? true : false
                  };
                }
                return null;
              })
              .filter(Boolean);
            // type GroupedData = {
            //   key: string;
            //   value: Array<{ type: string; size: string }>;
            // };

            // const groupByType = (data: any[]): GroupedData[] => {
            //   const groupedData: GroupedData[] = [];
            //   data.forEach((item) => {
            //     const instances_type = item.cost.instances_type;
            //     const groupedInstances: Record<
            //       string,
            //       Array<{ type: string; size: string }>
            //     > = {};
            //     for (const key in instances_type) {
            //       const type = key;
            //       const size = instances_type[key].toString();
            //       const firstLetter = key.charAt(0).toUpperCase();
            //       if (!groupedInstances[firstLetter]) {
            //         groupedInstances[firstLetter] = [];
            //       }
            //       groupedInstances[firstLetter].push({ type, size });
            //     }
            //     for (const key in groupedInstances) {
            //       groupedInstances[key].sort(
            //         (a, b) => parseInt(b.size) - parseInt(a.size)
            //       );
            //       groupedData.push({
            //         key: key + " Type",
            //         value: groupedInstances[key],
            //       });
            //     }
            //   });
            //   return groupedData;
            // };

            // this.outputArray = test.map((item) => {
            //   return { id: item.id, instance: groupByType([item]) };
            // });

            // this.outputArray.forEach((item: any) => {
            //   const match = idList.find(
            //     (obj: any) => obj.accountId === item.id
            //   );
            //   if (match) {
            //     item.id = `${match.accountName} (${item.id})`;
            //   }
            // });

            //for submenu
            // const transformedObject: Record<string, { type: string; size: string }[]> = {};
            // for (const key in this.instancesTypes) {
            //   const type = key.charAt(0).toUpperCase() + " Type";
            //   const value = { type: key, size: this.instancesTypes[key].toString() };
            //   if (!transformedObject[type]) {
            //     transformedObject[type] = [value];
            //   } else {
            //     transformedObject[type].push(value);
            //   }
            // }
            // for (const key in transformedObject) {
            //   transformedObject[key].sort((a: any, b: any) => b.size - a.size);
            // }
            // this.outputArray = Object.entries(transformedObject).map(([key, value]) => ({ [key]: value }));
            // this.outputArray = this.outputArray.map((item: any, index: any) => ({
            //   key: Object.keys(item)[0],
            //   value: Object.values(item)[0]
            // }));

            this.instanceLoader = false;
            this.loadTotalUnresolvedGraph(countObject);
          } else {
            this.instanceLoader = false;
            this.instanceNotFound = true;
          }
        } catch (error) {
          this.instanceLoader = false;
          this.instanceNotFound = true;
        }
      });
    } catch (error) {
      this.instanceLoader = false;
      this.instanceNotFound = true;
    }
  }

  async loadOSTypes() {
    this.osLoader = true;
    this.osNotFound = false;
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    let apiURL = `https://apis-eks.swayam.cloud/dashboard/count_of_ec2_instances_os_type/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&aws_region=all`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          const accountListData = localStorage.getItem("accountIdData");
          const data1 = JSON.parse(accountListData);
          let idList: any = [];
          if (data1["list"].length > 0) {
            idList = data1["list"].sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          }
          const hasSomeValues = (accountsData: any): boolean => {
            for (const accountId in accountsData) {
              if (accountsData.hasOwnProperty(accountId)) {
                const account = accountsData[accountId];
                if (account.windows > 0 || account.linux > 0) {
                  return true;
                }
              }
            }
            return false;
          };
          const someValuesPresent = hasSomeValues(data["accounts_ids"]);
          if (Object.keys(data["accounts_ids"]).length == 0) {
            this.osLoader = false;
            this.osNotFound = true;
          }
          if (someValuesPresent) {
            this.osNotFound = false;
            // this.OSTypes = data["accounts_ids"][this.accountId];
            // this.oswin = Object.entries(data["accounts_ids"]).map(
            //   ([key, value]) => {
            //     return { id: key, os: value };
            //   }
            // );

            const sumByOs: { [key: string]: number } = { windows: 0, linux: 0 };

            for (const accountId in data.accounts_ids) {
              const account = data.accounts_ids[accountId];
              for (const osType in account) {
                sumByOs[osType] += account[osType];
              }
            }
            this.OSTypes = sumByOs;

            //capital
            this.OSTypes = Object.keys(this.OSTypes).reduce(
              (result: any, key) => {
                const capitalizedKey =
                  key.charAt(0).toUpperCase() + key.slice(1);
                result[capitalizedKey] = this.OSTypes[key];
                return result;
              },
              {}
            );
            //for all regions

            // for (const accountId in data.accounts_ids) {
            //   if (data.accounts_ids.hasOwnProperty(accountId)) {
            //     const regionData = data.accounts_ids[accountId].regions;
            //     const types = ["windows", "linux"];
            //     const accountData = {
            //       id: accountId,
            //       regionData: types.map((type: any) => {
            //         const typeData = Object.values(regionData).map(
            //           (region: any) => {
            //             return region[type].toString();
            //           }
            //         );
            //         return { type: type, values: typeData };
            //       }),
            //     };
            //     this.oswin.push(accountData);
            //   }
            // }

            for (const accountId in data.accounts_ids) {
              if (data.accounts_ids.hasOwnProperty(accountId)) {
                const regionData = data.accounts_ids[accountId].regions;
                const types = ["windows", "linux"];
                const osWinData: any = [];
                types.forEach((type) => {
                  const typeData = this.regions.map((region: any) => {
                    const regionValue =
                      regionData[region] &&
                      regionData[region][type] !== undefined
                        ? regionData[region][type].toString()
                        : 0;
                    return regionValue;
                  });
                  osWinData.push({ type: type, values: typeData });
                });
                const accountData = { id: accountId, regionData: osWinData };
                this.oswin.push(accountData);
              }
            }

            this.oswin = idList
              .map((obj: any,index:number) => {
                const resultItem = this.oswin.find(
                  (item: any) => item.id === obj.accountId
                );
                if (resultItem) {
                  return {
                    id: `${obj.accountName} (${obj.accountId})`,
                    regionData: resultItem.regionData,
                    isHidden: index === 0 ? true : false
                  };
                }
                return null;
              })
              .filter(Boolean);
            //   console.log('oswin');

            this.osLoader = false;
            this.loadOSTypeGraph();

            //for sub menu
            const modifyId = (id: string) => {
              const match = idList.find((item: any) => item.accountId === id);
              if (match) {
                return `${match.accountName} (${id})`;
              }
              return id;
            };

            // this.oswin = this.oswin.map((item: any) => ({
            //   id: modifyId(item.id),
            //   os: item.os,
            // }));

            // const convertOsToArray = (os: any) => {
            //   return Object.entries(os).map(([key, value]) => ({ key, value }));
            // };
            // this.oswin = this.oswin.map((item: any) => ({
            //   id: item.id,
            //   os: convertOsToArray(item.os),
            // }));

            // this.oswin = this.oswin.map((item: any) => {
            //   const capitalizedOS = item.os.map((osItem: any) => {
            //     const capitalizedKey =
            //       osItem.key.charAt(0).toUpperCase() + osItem.key.slice(1);
            //     return { key: capitalizedKey, value: osItem.value };
            //   });
            //   return { id: item.id, os: capitalizedOS };
            // });
          } else {
            this.osLoader = false;
            this.osNotFound = true;
          }
        } catch (error) {
          this.osLoader = false;
          this.osNotFound = true;
        }
      });
    } catch (error) {
      this.osLoader = false;
      this.osNotFound = true;
    }
  }

  async loadResourseGraphData() {
    if (!this.checkFirstDay()) {
      this.resCostApiError = false;
      this.firstDay = false;
      this.resources = [];
      let data = {};

      let header = {
        Authorization: "Bearer " + localStorage.getItem("t"),
      };

      let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/get_billing_info_for_aws_resources/?list_of_account_numbers=${this.accountIdsList}&user_type=internal&report_timeline=MONTHLY&aws_region=${this.regionId}`;

      try {
        await this.apiServer
          .postDataPromis(apiURL, data, header)
          .then((data) => {
            try {
              //  this.resources = data["accounts_ids"][this.accountId]["service"];
              const accountListData = localStorage.getItem("accountIdData");
              const data1 = JSON.parse(accountListData);
              let idList: any = [];
              if (data1["list"].length > 0) {
                idList = data1["list"].sort(function (a: any, b: any) {
                  if (a.accountName < b.accountName) {
                    return -1;
                  }
                  if (a.accountName > b.accountName) {
                    return 1;
                  }
                  return 0;
                });
              }
              // let data_: any = [];
              // data_ = Object.keys(data["accounts_ids"]).sort(
              //   (a: any, b: any): any => {
              //     return (
              //       Object.keys(a["service"])
              //         .map((key: any): any => {
              //           return { service: key, value: a["service"][key] };
              //         })
              //         .reduce((service: any, total: number = 0) => {
              //           return (total += service["value"]);
              //         }) >
              //       Object.keys(b["service"])
              //         .map((key: any): any => {
              //           return { service: key, value: b["service"][key] };
              //         })
              //         .reduce((service: any, total: number = 0) => {
              //           return (total += service["value"]);
              //         })
              //     );
              //   }
              // );
              // console.log(data_);

              this.resources = Object.keys(data["accounts_ids"]).map((key) => {
                return { id: key, res: data["accounts_ids"][key] };
              });

              this.resources.forEach((item: any) => {
                const ec2Compute =
                  item.res.service["Amazon Elastic Compute Cloud - Compute"] ||
                  0;
                const ec2Other = item.res.service["EC2 - Other"] || 0;
                const ec2Sum = ec2Compute + ec2Other;
                item.res.service.EC2 = ec2Sum.toFixed(2);
              });

              const attributesToPick = [
                // "EC2 - Other",
                //  "Amazon Elastic Compute Cloud - Compute",
                "EC2",
                "Amazon Simple Storage Service",
                "Amazon Virtual Private Cloud",
                "AmazonCloudWatch",
                "Amazon Relational Database Service",
              ];

              const calculateTotal = (res: any): number => {
                let total = 0;
                attributesToPick.forEach((service) => {
                  total += Number(res.service[service]) || 0;
                });

                return total;
              };
              if (this.ascending) {
                this.ascending = false;
                this.sortIcon = "fa-arrow-down-wide-short";
                this.resources = this.resources.sort((a: any, b: any) => {
                  const totalA = calculateTotal(a.res);
                  const totalB = calculateTotal(b.res);
                  return totalA - totalB;
                });
              } else {
                this.ascending = true;
                this.sortIcon = "fa-arrow-up-wide-short";
                this.resources = this.resources.sort((a: any, b: any) => {
                  const totalA = calculateTotal(a.res);
                  const totalB = calculateTotal(b.res);
                  return totalB - totalA;
                });
              }

              const result = attributesToPick.map((attribute) => {
                const key = attribute;

                const value = this.resources.map(
                  (item: any) => item.res.service[attribute]
                );
                return { key, value };
              });

              // const ec2OtherValues = this.resources.map(
              //   (item: any) => item.res.service["EC2 - Other"]
              // );
              // const ec2ComputeValues = this.resources.map(
              //   (item: any) =>
              //     item.res.service["Amazon Elastic Compute Cloud - Compute"]
              // );
              // const ec2SumValues = ec2OtherValues.map(
              //   (value: any, index: any) => value + ec2ComputeValues[index]
              // );

              // result.forEach((item, index) => {
              //   if (item.key === "EC2") {
              //     item.value = ec2SumValues;
              //   }
              // });

              this.resources = {
                id: this.resources.map((item: any) => item.id),
                res: result,
              };

              for (let i = 0; i < this.resources.id.length; i++) {
                const accountId = this.resources.id[i];
                const account = idList.find(
                  (acc: any) => acc.accountId === accountId
                );
                if (account) {
                  this.resources.id[
                    i
                  ] = `${account.accountName} (${accountId})`;
                }
              }

              this.resCostApiError = false;
            } catch (error) {
              this.resCostApiError = true;
            }
            if (!this.resCostApiError) {
              // this.loadResourceGraph();
              this.renderChart();
            }
          });
      } catch (error) {}
    } else {
      this.firstDay = true;
    }
  }

  tickets: any = [];
  async loadMinfySupportTickets() {
    this.minloader = true;
    if (!this.nextToken) {
      this.resolved = 0;
      this.open = 0;
      this.closed = 0;
      this.WorkInProgress = 0;
      this.watingForCosRes = 0;
      this.closedByBot = 0;
      this.investigation = 0;
      this.tickets = [];
    }
    // this.notifier.loading(true);
    try {
      this.tickets = [];
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { jira_org_name, jira_project_key } = selectedAccountData.data;
        let start_date = moment().startOf("month").format("YYYY-MM-DD");
        let end_date = moment()
          .startOf("day")
          .add("days", 1)
          .format("YYYY-MM-DD");

        let data = {
          action: "jira_list",
          account_id: this.accountId,
          include_resolved: this.includeResolved.toString(),
          jira_project_key: jira_project_key,
          jira_organization_key: jira_org_name,
          start_date: start_date,
          end_date: end_date,
          next_token: this.nextToken ? this.nextToken : undefined,
        };
        let header = {
          Authorization: localStorage.getItem("t"),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == "1" || result.s == "1") {
          if (result.total === 0) {
            this.ticketNotFound = true;
          } else {
            this.ticketNotFound = false;
          }
          this.tickets = [...this.tickets, ...result.issues];
          this.totalTickets = result.all_issues || result.total;
          this.tickets.forEach((element: any) => {
            if (element.status === "Open") {
              this.open += 1;
            }
            if (element["status"] === "Closed") {
              this.closed += 1;
            }
            if (element["status"] === "Resolved") {
              this.resolved += 1;
            }
            if (element["status"] === "Waiting for Customer Response") {
              this.watingForCosRes += 1;
            }
            if (element["status"] === "Work in progress") {
              this.WorkInProgress += 1;
            }
            if (element["status"] === "Closed By BOT") {
              this.closedByBot += 1;
            }
            if (element["status"] === "Under Investigation") {
              this.investigation += 1;
            }
          });

          if (result.next_token && result.next_token != "") {
            this.nextToken = result.next_token;
            this.loadMinfySupportTickets();
          } else {
            this.hideGraph = false;
            this.minloader = false;
            this.nextToken = null;

            this.loadGraph();
          }
        } else {
          this.minloader = false;
          this.ticketNotFound = true;
        }
      } else {
      }
    } catch (err: any) {}
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }

  loadGraph() {
    var chartDom = document.getElementById("issueGraph");
    var myChart = echarts.init(chartDom);

    let option = {
      color: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#8bd3c7",
        "#beb9db",
        "#fdcce5",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{c}",
        textStyle: {
          fontWeight: "bold",
        },
      },
      legend: {
        top: "10%",
        right: "5%",

        orient: "vertical",
      },
      series: [
        {
          name: "Tickets",
          type: "pie",
          radius: "90%",
          right: "209",
          top: "10%",
          label: {
            show: false,
            position: "center",
          },
          data: [
            {
              value: this.WorkInProgress,
              name: "Work in progress",
            },
            {
              value: this.open,
              name: "Open",
            },
            {
              value: this.resolved,
              name: "Resolved",
            },
            {
              value: this.closedByBot,
              name: "Closed by Bot",
            },
            {
              value: this.closed,
              name: "Closed",
            },

            {
              value: this.watingForCosRes,
              name: "Customer Response",
            },
            {
              value: this.investigation,
              name: "Under Investigation",
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  toggleSortOrder() {
    if (this.currentSortOrder === "asc") {
      this.loadResourceGraph();
    } else {
      this.loadResourceGraph();
    }
  }

  loadResourceGraph() {
    // let data = [];
    // data = Object.entries(this.resources).map(([key, value]) => {
    //   return { name: key, value };
    // });
    // if (this.currentSortOrder === "asc") {
    //   this.currentSortOrder = "desc";
    //   this.sortIcon = "fa-arrow-down-wide-short";
    //   //Sort data in ascending order
    //   data.sort(function (a: any, b: any) {
    //     return b.value - a.value;
    //   });
    //   var xAxisData = data.map(function (item) {
    //     return item.name;
    //   });
    // } else {
    //   this.currentSortOrder = "asc";
    //   this.sortIcon = "fa-arrow-up-wide-short";
    //   //Sort data in decending order
    //   data.sort(function (a: any, b: any) {
    //     return a.value - b.value;
    //   });
    //   var xAxisData = data.map(function (item) {
    //     return item.name;
    //   });
    // }
    // var chartDom = document.getElementById("resourceGraph");
    // var myChart = echarts.init(chartDom);

    // myChart.on('click', (params: any) => {
    //   this.filter['checks'] = params['name'];
    // });

    // let option = {
    //   color: ["#7EB0D5"],
    //   grid: {
    //     height: "50%",
    //     left: 60,
    //     right: 50,
    //     bottom: 115,
    //   },
    //   tooltip: {
    //     trigger: "axis",
    //     axisPointer: {
    //       type: "shadow",
    //     },
    //     formatter: function (params: any) {
    //       var value = params[0].value;
    //       var name = params[0].name;
    //       return name + ": $" + value;
    //     },
    //   },
    //   xAxis: {
    //     type: "category",
    //     data: xAxisData,
    //   },
    //   yAxis: {
    //     type: "value",
    //     splitLine: {
    //       show: false,
    //     },
    //     axisLabel: {
    //       formatter: "${value}",
    //     },
    //   },
    //   series: [
    //     {
    //       data: data,
    //       type: "bar",
    //     },
    //   ],
    //   barMaxWidth: 25,
    //   dataZoom: [
    //     {
    //       type: "slider",
    //       start: 0,
    //       end: 100,
    //       bottom: 46,
    //     },
    //   ],
    // };

    const resultArray = this.resources.res.map((item: any) => ({
      name: item.key,
      type: "bar",
      stack: "total",
      data: item.value,
      formatter: "${value}",
    }));

    this.resourceOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      barMaxWidth: 25,
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: { formatter: "${value}" },
      },
      yAxis: {
        type: "category",
        data: this.resources.id,
      },
      series: resultArray,
    };

    // option && myChart.setOption(option);
  }

  customTooltipFormatter(params: any): string {
    const name = params.seriesName;
    //const value = params.value;
    const tooltipContent = ` <div> <div>${name}</div> `;
    return tooltipContent;
  }

  renderChart() {
    //option
    const resultArray = this.resources.res.map((item: any) => ({
      name: item.key,
      type: "bar",
      stack: "total",
      data: item.value,
      //  formatter: (params: any) => this.customTooltipFormatter(params),
    }));

    // this.divHeight = "250px";
    setTimeout(() => {
      let h = 250;
      if (resultArray && resultArray.length > 0) {
        h = 200 + 50 * resultArray[0].data.length;
        this.divHeight = h + "px";
      } else {
        //  this.divHeight = "250px";
      }

      setTimeout(() => {
        this.chart = echarts.init(
          document.getElementById("resourcesGraph"),
          null,
          { height: h }
        );
        let option = {
          color: [
            "#7eb0d5",
            "#b2e061",
            "#bd7ebe",
            "#ffb55a",
            "#ffee65",
            "#beb9db",
            "#fdcce5",
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "shadow" },
            formatter: function (params: any) {
              const seriesName = params[0].axisValueLabel;
              let content = `<div><b>${seriesName}</b>`;
              content += "<hr>";
              for (const param of params) {
                const value = param.value !== undefined ? param.value : 0;
                content += `<div class="d-flex justify-content-between w-10"> <div> ${param.marker} ${param.seriesName}</div> &nbsp; <div><b> $${value}</b></div> </div>`;
              }
              content += "</div>";
              return content;
            },
          },
          barMaxWidth: 25,
          legend: { top: "1%" },
          grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
          xAxis: { type: "value", axisLabel: { formatter: "${value}" } },
          yAxis: { type: "category", data: this.resources.id },
          series: resultArray,
        };
        //let div = document.getElementById("resourcesGraph");
        this.chart.setOption(option, true);
        setTimeout(() => {
          this.chart.resize({ height: h });
        }, 1000);
        // this.chart.on('finished', () => { // const chartHeight = this.chart.getHeight(); // div.style.height = `${chartHeight}px`; // // this.chartContainer.nativeElement.style.height = `${chartHeight}px`; // });
      }, 100);
    }, 100);
  }

  loadTotalUnresolvedGraph(obj: any) {
    // const data = Object.entries(this.instancesTypes).map(([key, value]) => {
    //   return { name: key, value };
    // });

    const data = Object.entries(obj).map(([key, value]) => {
      return { name: key, value };
    });

    var chartDom = document.getElementById("unresolvedGraph");
    var myChart = echarts.init(chartDom);

    let option = {
      color: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
        "#ffb55a",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{c}",
        textStyle: {
          fontWeight: "bold",
        },
      },
      legend: {
        top: "10%",
        right: "7%",
        orient: "vertical",
        type: "scroll",
        height: 200,
      },
      series: [
        {
          name: "Instances",
          type: "pie",
          radius: ["59%", "65%"],
          right: "100",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
    option && myChart.setOption(option);
  }

  loadOSTypeGraph() {
    delete this.OSTypes.Regions;
    const data = Object.entries(this.OSTypes).map(([key, value]) => {
      return { name: key, value };
    });
    var chartDom = document.getElementById("osGraph");
    var myChart = echarts.init(chartDom);

    let option = {
      color: [
        "#b2e061",
        "#7eb0d5",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{c}",
        textStyle: {
          fontWeight: "bold",
        },
      },
      legend: {
        top: "10%",
        right: "7%",

        orient: "vertical",
      },
      series: [
        {
          name: "OS",
          type: "pie",
          radius: ["59%", "65%"],
          right: "100",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
    option && myChart.setOption(option);
  }

  loadDistributionGraph() {
    var chartDom = document.getElementById("distributionGraph");
    var myChart = echarts.init(chartDom);

    let option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "horizontal",
        bottom: "bottom",
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["45%", "75%"],
          bottom: 15,
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 1048, name: "Unresolved" },
            { value: 735, name: "Solved" },
            { value: 580, name: "Critical" },
          ],
        },
      ],
    };
    option && myChart.setOption(option);
  }

  loadCostComparedGraph() {
    this.forechart = echarts.init(document.getElementById("costCompareGraph"));
    // Get current date
    const today = new Date();
    const currentMonthName = today.toLocaleString("default", { month: "long" });
    const currentYear = today.getFullYear();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);
    const nextMonthName = nextMonth.toLocaleString("default", {
      month: "long",
    });
    const nextMonthYear = nextMonth.getFullYear();
    const CurrentMonth = `${currentMonthName}`;
    const NextMonth = `${nextMonthName}`;
    const previousMonthName = moment().subtract(1, "month").format("MMMM");

    let option = {
      color: ["#b2e061"],
      grid: {
        left: 70,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params: any) {
          var value = params[0].value;
          var name = params[0].name;
          return name + ": $" + value;
        },
      },
      xAxis: {
        type: "category",
        data: [previousMonthName, CurrentMonth, NextMonth],
        axisLabel: { fontSize: 9 },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: "${value}",
        },
      },
      series: [
        {
          data: [
            {
              value: this.previousMonthCost,
              itemStyle: {
                color: "#7EB0D5",
              },
            },
            this.currForecast,
            {
              value: this.totalcost,
              itemStyle: {
                color: "#FD7F6F",
              },
            },
          ],
          type: "bar",
        },
      ],
      barWidth: "50%",
    };
    this.forechart.setOption(option);
  }

  nextToken: any = "";
  // async loadrecomondations(nextToken: string = "") {
  //   this.nextToken = nextToken == "" ? null : nextToken;
  //   let data = {
  //     action: "getRecommendedData",
  //     accountId: this.accountId,
  //     region: this.regionId,
  //   };

  //   let header = {
  //     Authorization: localStorage.getItem("t"),
  //   };
  //   let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2metricsupatedv2`;

  //   let currentAccountId = this.accountId;
  //   let currentRegionId = this.regionId;
  //   if (nextToken != "") {
  //     nextToken = "";
  //     this.recomondations = [];
  //     return true;
  //   }
  //   // this.refresh_ = false;

  //   let result = await this.apiServer.postDataPromis(apiURL, data, header);

  //   if (result.status == "1" || result.status_code == 1 || result.instances) {
  //     result.instances = result.instances
  //       .filter((recom: any) => {
  //         if (recom.mvalue == "-") {
  //           recom.EMS = false;
  //         } else {
  //           recom.EMS = true;
  //         }
  //         return recom.insttype != recom.rec;
  //       })
  //       .map((recom: any) => {
  //         recom.recommendations = recom.recommendations.map((recom_: any) => {
  //           recom_["savings_html"] = `<span class='${Number(recom_.savings) > 0
  //             ? "green_text"
  //             : Number(recom_.savings) < 0
  //               ? "red_text"
  //               : ""
  //             }'>$${recom_.savings}</span>`;
  //           if (recom["initial_insType_coreram"]) {
  //             let current_data = recom["initial_insType_coreram"].split(", ");
  //             recom["current_cors"] = current_data[0].split(":")[1];
  //             recom["current_ram"] = current_data[1].split(":")[1];
  //           }
  //           if (recom_["recommendedCoreRam"]) {
  //             let recommended_cors_ram =
  //               recom_["recommendedCoreRam"].split(", ");
  //             recom_["recom_cors"] = recommended_cors_ram[0].split(":")[1];
  //             recom_["recom_ram"] = recommended_cors_ram[1].split(":")[1];
  //           }
  //           recom_["accountID"] = currentAccountId;
  //           recom_["regionID"] = currentRegionId;
  //           return recom_;
  //         });
  //         return recom;
  //       });
  //     this.recomondations = [...this.recomondations, ...result.instances];
  //   } else {
  //     // this.loading_recom = false;
  //     if (result.message != "NO RECORD FOUND") {
  //     }
  //   }

  //   if (result.next_token) {
  //     this.loadrecomondations(result.next_token);
  //   } else {
  //     this.nextToken = null;
  //   }
  //   this.getTotalSavings();

  //   return true;
  // }

  async checkForJobStatus() {
    let data = {
      action: "getalljobstatus",
      accountIds: this.accountIdsList,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2mtericsv2 `;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    let successAccountList: any = [];
    let failAccountList: any = [];
    if (result.status == "1" || result.status_code == "1") {
      const accountStatus = result.accountStatus;
      for (const accountId in accountStatus) {
        const accounts = accountStatus[accountId];

        if (accounts["acctstatus"] && accounts["acctstatus"] == "Completed") {
          successAccountList.push(accountId);
        } else {
          failAccountList.push(accountId);
        }
      }
    }

    this.loadMultiCostSavings(successAccountList, failAccountList);
    // if(this.accountIdsList.length == successAccountList.length && failAccountList.length==0){
    //   this.loadMultiCostSavings();
    // }else if(failAccountList.length>0){
    //  this.jobInit(failAccountList);
    // }

    this.notifier.loading(false);
  }
  // async jobInit(failacclist:any){

  //   let header = {
  //     Authorization: localStorage.getItem("t"),
  //   };
  //   let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2metricsupdated`;

  // let passList:any=[];
  // let failList:any=[];
  // let scannNotCompleteList:any=[];
  //   failacclist.forEach(async (acc:any)=>{
  //     let data = {
  //       action: "JobStatus",
  //       accountId: acc,
  //       region: this.regionId,
  //     };

  //     let result = await this.apiServer.postDataPromis(apiURL, data, header);
  //     if(result.status_code==1){
  //      if(result.item[0]['acctstatus']=='Completed') {
  //       passList.push(acc);
  //      }else{
  //       scannNotCompleteList.push(acc);
  //      }

  //     }else{
  //     failList.push(acc);
  //     }
  //   });

  //   if(scannNotCompleteList.length>0){
  //     setTimeout(() => {
  //       this.checkForJobStatus();
  //     }, 5000);
  //   }

  //   if(passList.length == this.accountIdsList){
  //     this.loadMultiCostSavings();
  //   }

  // }
  async loadMultiCostSavings(successAccount: any, failaccount: any) {
    this.CostSavings = [];
    // if (successAccount.length > 0) {

    let data = {
      action: "getAllRecommendedData",
      accountIds: this.accountIdsList,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2mtericsv2 `;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    let maxsaving = 0;
    let saving = 0;
    if (result.status == "1" || result.status_code == "1") {
      const accountData = result.accountData;

      for (const accountId in accountData) {
        const accounts = accountData[accountId];
        if (accounts.length != 0) {
          accounts.forEach((recom: any) => {
            if (recom.recommendations && recom.recommendations.length > 0) {
              recom.recommendations.sort((a: any, b: any) => {
                if (
                  (isNaN(a.savings) ? 0 : Number(a.savings)) <
                  (isNaN(b.savings) ? 0 : Number(b.savings))
                ) {
                  return -1;
                }
                return 0;
              });
              // minsaving += isNaN(recom.recommendations[0].savings)
              // ? 0
              // : Number(recom.recommendations[0].savings);

              maxsaving += isNaN(
                recom.recommendations[recom.recommendations.length - 1].savings
              )
                ? 0
                : Number(
                    recom.recommendations[recom.recommendations.length - 1]
                      .savings
                  );

              saving += isNaN(
                recom.recommendations[recom.recommendations.length - 1].savings
              )
                ? 0
                : Number(
                    recom.recommendations[recom.recommendations.length - 1]
                      .savings
                  );
            }
          });

          this.CostSavings.push({
            id: accountId,
            cost: { saving: `${saving.toFixed(2)}`, status: "Completed" },
          });
          saving = 0;
        } else {
          this.CostSavings.push({
            id: accountId,
            cost: { saving: 0, status: "No Data Found" },
          });
        }
      }

      this.costSavingValue = `${maxsaving.toFixed(2)}`;

      // successAccount.forEach((id: any) => {
      //   const found = this.CostSavings.some((item: any) => item.id === id);
      //   if (!found) {
      //     this.CostSavings.push({ id: id, cost: { saving: 0, status: 'No Data Found' } });
      //   }
      // });
    } else {
      //  this.costSavings = successAccount.map((id: any) => ({ id: id, cost: { saving: 0, status: "No Data Found" }, }));
    }

    if (failaccount.length > 0) {
      // const missingDataArray = failaccount.map((id: any) => ({ id: id, cost: { saving: 0, status: "No Data Found" }, }));
      //  this.CostSavings = [...this.CostSavings, ...missingDataArray];
      // this.costSavingValue = `${maxsaving.toFixed(2)}`;
    }
    // } else {

    //   this.CostSavings = failaccount.map((id: any) => ({ id: id, cost: { saving: 0, status: "No Data Found" }, }));

    //   //  this.costSavingValue = '0.00';
    // }
    //add account name
    const accountListData = localStorage.getItem("accountIdData");
    const data1 = JSON.parse(accountListData);
    let idList: any = [];
    if (data1["list"].length > 0) {
      idList = data1["list"].sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1;
        }
        if (a.accountName > b.accountName) {
          return 1;
        }
        return 0;
      });
    }

    this.CostSavings = this.CostSavings.map((data: any) => {
      const accountData = idList.find(
        (account: any) => account.accountId === data.id
      );
      if (accountData) {
        return {
          id: `${accountData.accountName} (${data.id})`,
          cost: data.cost,
        };
      } else {
        return data;
      }
    });
    this.costSavingLoader = false;
  }
  // getTotalSavings() {
  //   let savings_html = `0 USD`;
  //   this.costSavingValue = "0";

  //   // let minsaving = 0;
  //   let maxsaving = 0;
  //   this.recomondations.forEach((recom: any) => {
  //     if (recom.recommendations && recom.recommendations.length > 0) {
  //       recom.recommendations.sort((a: any, b: any) => {
  //         if (
  //           (isNaN(a.savings) ? 0 : Number(a.savings)) <
  //           (isNaN(b.savings) ? 0 : Number(b.savings))
  //         ) {
  //           return -1;
  //         }
  //         return 0;
  //       });
  //       // minsaving += isNaN(recom.recommendations[0].savings)
  //       // ? 0
  //       // : Number(recom.recommendations[0].savings);
  //       maxsaving += isNaN(
  //         recom.recommendations[recom.recommendations.length - 1].savings
  //       )
  //         ? 0
  //         : Number(
  //           recom.recommendations[recom.recommendations.length - 1].savings
  //         );
  //     }
  //   });
  //   this.costSavingValue = `${maxsaving.toFixed(2)}`;
  //   this.notifier.loading(false);
  // }

  async loadTotalInsData() {
    this.vcpuNotFound=false;
    this.vcpuformattedData = [];
    this.insList = [];
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/cost/get_the_processor_info_of_instances/?list_of_account_numbers=${this.accountIdsList}&user_type=client&aws_region=all`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          const accountListData = localStorage.getItem("accountIdData");

          const data1 = JSON.parse(accountListData);
          let idList: any = [];
          if (data1["list"].length > 0) {
            idList = data1["list"].sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          }

          //  this.insList = data["accounts_ids"][this.accountId];
          let pro = Object.entries(data["accounts_ids"]).map(([key, value]) => {
            return { id: key, cost: value };
          });
          this.countOfCpu = data["total_vcpu_of_all_account"];

          // for (const accountId in data.accounts_ids) {
          //   const regionData = data.accounts_ids[accountId].all_region;
          //   const types = [
          //     "intel_instances",
          //     "amd_instances",
          //     "graviton_intances",
          //   ];
          //   const accountData = {
          //     id: accountId,
          //     regionData: types.map((type: any) => {
          //       const typeData = Object.values(regionData).map(
          //         (region: any) => {
          //           if (region[type] && region[type].amd_count !== undefined) {
          //             return region[type].amd_count.toString();
          //           } else if (
          //             region[type] &&
          //             region[type].intel_count !== undefined
          //           ) {
          //             return region[type].intel_count.toString();
          //           } else if (
          //             region[type] &&
          //             region[type].graviton_count !== undefined
          //           ) {
          //             return region[type].graviton_count.toString();
          //           } else {
          //             return "NA";
          //           }
          //         }
          //       );
          //       this.regions.forEach((region:any) => { if (!regionData[region]) { typeData.push("N"); } });
          //       return { type: type, values: typeData };
          //     }),
          //   };
          //   this.vcpuformattedData.push(accountData);
          // }
         
          for (const accountId in data.accounts_ids) {
            const regionData = data.accounts_ids[accountId].all_region;
            const types = [
              "intel_instances",
              "amd_instances",
              "graviton_intances",
            ];
            const accountData = {
              id: accountId,
              regionData: types.map((type: any) => {
                const typeData: any = [];
                //     this.regions.forEach((region: any) => {
                //       console.log(region);
                //       console.log(regionData[region]);
                //       console.log(regionData[region][type]);
                //       console.log(regionData[region][type].amd_count);
                //       console.log(regionData[region][type].intel_count);
                //       console.log(regionData[region][type].graviton_count);
                //       const regionValue =
                //         regionData[region] && regionData[region][type]
                //           ? regionData[region][type].amd_count ||
                //             regionData[region][type].intel_count ||
                //             regionData[region][type].graviton_count
                //           : "NA";
                //           console.log(regionValue);
                //       typeData.push(regionValue.toString());
                //     });
                //     return { type: type, values: typeData };
                //   }),
                // };
                this.regions.forEach((region:any) => {
                  const regionValue =
                    (regionData[region] &&
                      regionData[region][type] &&
                      (regionData[region][type].amd_count !== undefined
                        ? regionData[region][type].amd_count
                        : regionData[region][type].intel_count !== undefined
                        ? regionData[region][type].intel_count
                        : regionData[region][type].graviton_count !== undefined
                        ? regionData[region][type].graviton_count
                        : "NA")) ||
                    "NA";
                  typeData.push(regionValue.toString());
                });
                return { type: type, values: typeData };
              }),
            };
            this.vcpuformattedData.push(accountData);
          }
          this.insList = this.vcpuformattedData;
          this.insList = idList
            .map((obj: any, index:number) => {
              const resultItem = this.insList.find(
                (item: any) => item.id === obj.accountId
              );
              if (resultItem) {
                return {
                  id: `${obj.accountName} (${obj.accountId})`,
                  regionData: resultItem.regionData,
                  isHidden: index === 0 ? true : false
                };
              }
              return null;
            })
            .filter(Boolean);

          //if -- remove all cmnt -->remove
          // const mergeCostObjects = (firstCost: any, secondCost: any) => {
          //   try {
          //     return [
          //       {
          //         key: "Intel",
          //         value: secondCost.intel_instances.intel_count.toString(),
          //       },
          //       {
          //         key: "AMD",
          //         value: secondCost.amd_instances.amd_count.toString(),
          //       },
          //       {
          //         key: "Graviton",
          //         value: secondCost.graviton_intances.graviton_count.toString(),
          //       },
          //     ];
          //   } catch (error) {
          //     return [];
          //   }
          // };
          // const costMap: { [id: string]: any } = {};
          // pro.forEach((item: any) => {
          //   costMap[item.id] = item.cost;
          // });
          // this.insList = this.vcpu.map((item: any) => ({
          //   id: item.id,
          //   vcpu: item.cost.total_count_of_vcpu.toString(),
          //   cost: mergeCostObjects(item.cost, costMap[item.id]),
          // }));

          // this.insList = idList
          //   .map((obj: any) => {
          //     const resultItem = this.insList.find(
          //       (item: any) => item.id === obj.accountId
          //     );
          //     if (resultItem) {
          //       return {
          //         id: `${obj.accountName} (${obj.accountId})`,
          //         vcpu: resultItem.vcpu,
          //         cost: resultItem.cost,
          //       };
          //     }
          //     return null;
          //   })
          //   .filter(Boolean);
          this.vcpuLoader = false;
        } catch (error) {
          this.vcpuLoader = false;
          this.vcpuNotFound=true;
        }
      });
    } catch (error) {
      this.vcpuNotFound=true;
      this.vcpuLoader = false;
    }
    this.vcpuLoader = false;
  }

  //for multi account feature

  getAccList(accList: string) {
    const accountListData = localStorage.getItem("accountIdData");
    const data1 = JSON.parse(accountListData);
    let idList: any = [];
    if (data1["list"].length > 0) {
      idList = data1["list"].sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1;
        }
        if (a.accountName > b.accountName) {
          return 1;
        }
        return 0;
      });
    }
    this.accountIdsList = accList;
    this.accountIdsListWithName = idList
      .filter((item: any) => this.accountIdsList.includes(item.accountId))
      .map((item: any) => ({
        cName: `${item.accountName} (${item.accountId})`,
        accId: item.accountId,
      }));
  }

  hidePrompt(event: any) {
    this.notifier.loading(true);
    // this.checkForAccounts();
    this.accView = false;
    this.hideGraph = true;
    this.minloader = true;
    this.instanceLoader = true;
    this.instanceNotFound = false;
    this.osNotFound = false;
    this.osLoader = true;
    this.ticketNotFound = false;
    this.dailyCostValue = "0.00";
    this.dailyCostDiff = "0.0";
    this.monthlyCostValue = "0.00";
    this.monthlyCostDiff = "0.0";
    this.totalCostvalue = "0.00";
    this.totalCostDiff = "0.0";
    this.costSavingValue = "0.00";
    this.costSavingDiff = "0.0";
    this.resolved = 0;
    this.open = 0;
    this.closed = 0;
    this.WorkInProgress = 0;
    this.watingForCosRes = 0;
    this.closedByBot = 0;
    this.investigation = 0;
    this.countOfTags = "-";
    this.countOfCpu = "-";
    this.s3Size = "-";
    this.currentSortOrder = "asc";
    this.resources = [];
    this.recomondations = [];
    this.instancesView = false;
    this.storageView = false;
    this.OSView = false;
    this.annualView = false;
    this.insList = [];
    this.outputArray = [];

    this.monthlyView = false;
    this.dailyView = false;
    this.isFirstReload = false;
    this.yearly = [];
    this.monthly = [];
    this.vcpu = [];
    this.tagsList = [];
    this.oswin = [];
    this.winTab = false;
    this.tagTab = false;
    this.totalCost = 0;
    this.storageList = [];
    this.annualCostLoader = true;
    this.monthlyCostLoader = true;
    this.dailyCostLoader = true;
    this.vcpuLoader = true;
    this.tagLoader = true;
    this.storageLoader = true;
    this.forcastLoader = true;
    this.costSavingTab = false;
    this.CostSavings = [];
    this.costSavingLoader = true;
    this.firstDay = false;
    setTimeout(() => {
      this.load();
    }, 2000);
  }

  cancelPrompt(event: any) {
    this.accView = false;
  }
  async removeAccount(account: any) {
    if (this.accountIdsList.length != 1) {
      this.notifier.loading(true);
      this.accountIdsList = this.accountIdsList.filter(
        (item: any) => item !== account
      );

      const accountListData = localStorage.getItem("accountIdData");
      const data1 = JSON.parse(accountListData);
      let idList: any = [];
      if (data1["list"].length > 0) {
        idList = data1["list"].sort(function (a: any, b: any) {
          if (a.accountName < b.accountName) {
            return -1;
          }
          if (a.accountName > b.accountName) {
            return 1;
          }
          return 0;
        });
      }
      //update preferences
      this.hideGraph = true;
      this.minloader = true;
      this.instanceLoader = true;
      this.instanceNotFound = false;
      this.osNotFound = false;
      this.osLoader = true;
      this.ticketNotFound = false;
      this.dailyCostValue = "0.00";
      this.dailyCostDiff = "0.0";
      this.monthlyCostValue = "0.00";
      this.monthlyCostDiff = "0.0";
      this.totalCostvalue = "0.00";
      this.totalCostDiff = "0.0";
      this.costSavingValue = "0.00";
      this.costSavingDiff = "0.0";
      this.resolved = 0;
      this.open = 0;
      this.closed = 0;
      this.WorkInProgress = 0;
      this.watingForCosRes = 0;
      this.closedByBot = 0;
      this.investigation = 0;
      this.countOfTags = "-";
      this.countOfCpu = "-";
      this.s3Size = "-";
      this.currentSortOrder = "asc";
      this.resources = [];
      this.recomondations = [];
      this.instancesView = false;
      this.storageView = false;
      this.OSView = false;
      this.annualView = false;
      this.insList = [];
      this.outputArray = [];

      this.monthlyView = false;
      this.dailyView = false;
      this.isFirstReload = false;
      this.yearly = [];
      this.monthly = [];
      this.vcpu = [];
      this.tagsList = [];
      this.oswin = [];
      this.winTab = false;
      this.tagTab = false;
      this.totalCost = 0;
      this.storageList = [];
      this.annualCostLoader = true;
      this.monthlyCostLoader = true;
      this.dailyCostLoader = true;
      this.vcpuLoader = true;
      this.tagLoader = true;
      this.storageLoader = true;
      this.forcastLoader = true;
      this.costSavingTab = false;
      this.CostSavings = [];
      this.costSavingLoader = true;
      this.firstDay = false;

      //-----------------------
      const email = localStorage.getItem("eId");
      const clientName = idList[0]["companyName"];
      let groupName = localStorage.getItem("selectedAccountGroup");
      if (groupName == null) {
        groupName = "clientportal";
      }
      let header = {
        Authorization: "Bearer " + localStorage.getItem("t"),
      };

      let apiURL = `https://apis-eks.swayam.cloud/dashboard/dashboard_user_preference/?user_email=${email}&group_name=${groupName}&client_name=${clientName}&list_of_account_numbers=${this.accountIdsList}&action=store&user_type=client`;
      try {
        await this.apiServer
          .postDataPromis(apiURL, {}, header)
          .then((data) => {});
      } catch (error) {}

      this.accountIdsListWithName = idList
        .filter((item: any) => this.accountIdsList.includes(item.accountId))
        .map((item: any) => ({
          cName: `${item.accountName} (${item.accountId})`,
          accId: item.accountId,
        }));

      setTimeout(() => {
        this.load();
      }, 2000);
    }
  }
  showAccTab() {
    this.isFirstReload = false;
    this.accView = true;
  }
  viewinstances(event: any) {
    this.instancesView = !this.instancesView;
  }
  hideIns(event: any) {
    this.instancesView = null;
  }
  viewForcast(event: any) {
    this.forcastTab = true;
  }
  hideForcastTab(event: any) {
    this.forcastTab = null;
  }
  viewStorage(event: any) {
    this.storageView = !this.storageView;
  }
  hideStorage(event: any) {
    this.storageView = null;
  }
  viewTags(event: any) {
    this.tagTab = true;
  }
  hidetagTab(event: any) {
    this.tagTab = null;
  }
  viewOS(event: any) {
    this.OSView = !this.OSView;
  }
  hideOS(event: any) {
    this.OSView = null;
  }
  hidewinTab(event: any) {
    this.winTab = null;
  }
  viewwinTab(event: any) {
    this.winTab = true;
  }
  viewAnnualcost(event: any) {
    this.annualView = true;
  }
  hideAnnualCost(event: any) {
    this.annualView = null;
  }

  viewMonthlycost(event: any) {
    this.monthlyView = true;
  }
  hideMonthlyCost(event: any) {
    this.monthlyView = null;
  }

  viewDailycost(event: any) {
    this.dailyView = true;
  }
  hideDailyCost(event: any) {
    this.dailyView = null;
  }
  showCostSavingTab(event: any) {
    this.costSavingTab = true;
  }
  hidecostsavingTab(event: any) {
    this.costSavingTab = null;
  }

  async getAllRegions() {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/get_all_region_for_account?list_of_account_numbers=${this.accountIdsList}&user_type=client&aws_region=all`;

    const result = await this.apiServer.postDataPromis(apiURL, {}, header);
    this.regionHeaders = Array.from(
      new Set(
        [].concat(
          ...Object.values(result.accounts_ids).map(
            (account: any) => account.list_of_regions
          )
        )
      )
    );
    this.regions = this.regionHeaders;
    this.regionHeaders = this.regionHeaders.map((regionCode: any) => {
      return this.regionCodeToName[regionCode];
    });

    if (result) {
      //this.loadVcpu();
      this.loadTotalInsData();
      this.loadRams();
      this.loadS3Size();
      this.loadInstancesTypes();
      this.loadOSTypes();
    }
  }
  openCostPage() { const url = '/dash/billing/billing-daily'; window.open(url, '_blank'); } 
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
