import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-view-daily-tab',
  templateUrl: './view-daily-tab.component.html',
  styleUrls: ['./view-daily-tab.component.css']
})
export class ViewDailyTabComponent implements OnInit {

  @Output() hideModal = new EventEmitter<any>();
  @Input() dailyCost :any=[];

  constructor( public notifier: NotifierService,) { }

  ngOnInit(): void {
   this.notifier.disableScroll();


  }
 
  
  
  no(){
    this.hideModal.emit({ status: 0 });
    
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}
