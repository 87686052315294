<div>
  <div *ngIf="!isMilestone">
    <nav class="mar-top-19 mb-2">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-link active"
          id="nav-overview-tab"
          data-bs-toggle="tab"
          data-bs-target="#overview"
          type="button"
          role="tab"
          aria-controls="overview"
          aria-selected="true"
        >
          Overview
        </a>
        <a
          class="nav-link"
          id="nav-milestones-tab"
          data-bs-toggle="tab"
          data-bs-target="#milestones"
          type="button"
          role="tab"
          aria-controls="milestones"
          aria-selected="true"
        >
          Milestones
        </a>
        <a
          class="nav-link"
          id="nav-properties-tab"
          data-bs-toggle="tab"
          data-bs-target="#properties"
          type="button"
          role="tab"
          aria-controls="properties"
          aria-selected="true"
        >
          Properties
        </a>
      </div>
    </nav>
  </div>
  <div>
    <div
      class="tab-content"
      [ngClass]="{ 'tab-content': !isMilestone }"
      id="nav-tabContent"
    >
      <div
        class="tab-pane"
        [ngClass]="{ active: !isMilestone }"
        id="overview"
        role="tabpanel"
        aria-labelledby="overview-tab"
      >
        <div
          class="review-alert mb-3"
          *ngIf="
            questions.length - fetchTotalAnsweredQuestions().length > 0 &&
            lenses.length > 0 &&
            !isMilestone
          "
        >
          <div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </div>
          <div class="alert-body">
            <div>
              <div class="alert-title">Continue Reviewing</div>
              <div>
                You have unanswered questions. Continue reviewing and answer the
                questions for each lens.
              </div>
            </div>
            <div>
              <button
                class="btn btn-primary-soft continue-lenses"
                *ngIf="lenses.length > 0"
              >
                <span>Continue Reviewing</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
                <div class="continue-lenses-absolute">
                  <div
                    *ngFor="let lens of lenses"
                    class="button-lens"
                    (click)="showQuestions(lens['lens'])"
                  >
                    {{ lensesData[lens["lens"]] }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="progress-container mb-3">
          <label
            >Questions Answered ({{ fetchTotalAnsweredQuestions().length }}/{{
              questions.length
            }})</label
          >
          <div class="progress" [ngClass]="{ loading: loading['questions'] }">
            <ng-container *ngIf="!loading['questions']">
              <div
                [ngStyle]="{
                  'background-color': lens['color'],
                  width: loaded
                    ? (filterQuestions(lens['lens'], 'lensId', true).length /
                        questions.length) *
                        100 +
                      '%'
                    : '0%'
                }"
                class="progress-indicator"
                *ngFor="let lens of lenses"
              >
                <div class="progress-tooltop">
                  <div class="flex-between">
                    <div class="white-no-wrap">
                      {{ lensesData[lens["lens"]] }}
                    </div>
                    <div>
                      {{ filterQuestions(lens["lens"], "lensId", true).length }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="card mt-0">
          <div class="card-header flex-between">
            <h5 *ngIf="!isMilestone">Workload Overview</h5>
            <h5 *ngIf="isMilestone">Milestone Overview</h5>
            <div class="flex-start">
              <span
                class="nav-link pl-10"
                *ngIf="!isMilestone"
                (click)="saveMilestone.emit(true)"
                >Save Milestone</span
              >
            </div>
          </div>
          <div>
            <!--
            <div class="progress-container mb-3">
              <label>Risks</label>
              <div class="flex-left">
                <div class="progress" [ngClass]="{loading: loading['questions']}">
                  <ng-container *ngIf="!loading['questions']">
                    <div
                      [ngStyle]="{'background-color': '#c94d6d', width: loaded ? (fetchQuestionsRisks('High').length / questions.length) * 100 + '%' : '0px'} "
                      class="progress-indicator">
                      <div class="progress-tooltop">
                        <h5>High</h5>
                        <div *ngFor="let lens of lenses">
                          <div class="flex-between">
                            <div class="white-no-wrap">
                              {{ lensesData[lens['lens']] }}
                            </div>
                            <div>
                              {{fetchQuestionsRisks('High', lens['lens']).length}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div
                      [ngStyle]="{'background-color': '#e48b1f', width: loaded ? (fetchQuestionsRisks('Medium').length / questions.length) * 100 + '%' : '0%'} "
                      class="progress-indicator">
                      <div class="progress-tooltop">
                        <h5>Medium</h5>
                        <div *ngFor="let lens of lenses">
                          <div class="flex-between">
                            <div class="white-no-wrap">
                              {{ lensesData[lens['lens']] }}
                            </div>
                            <div>
                              {{fetchQuestionsRisks('Medium', lens['lens']).length}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div
                      [ngStyle]="{'background-color': '#e7c443', width: loaded ? (fetchQuestionsRisks('Low').length / questions.length) * 100 + '%' : '0px'} "
                      class="progress-indicator">
                      <div class="progress-tooltop">
                        <h5>Low</h5>
                        <div *ngFor="let lens of lenses">
                          <div class="flex-between">
                            <div class="white-no-wrap">
                              {{ lensesData[lens['lens']] }}
                            </div>
                            <div>
                              {{fetchQuestionsRisks('Low', lens['lens']).length}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </ng-container>
                </div>
              </div>
            </div> -->

            <div class="flex-left">
              <div class="progress-container" *ngFor="let lens of lenses">
                <div class="progress-header">
                  {{ lensesData[lens["lens"]] }} Risks
                </div>
                <div class="progress-body">
                  <div
                    id="{{ lens['lens'] }}_risksgraph"
                    class="piegraph"
                    *ngIf="!loading['questions']"
                  ></div>
                  <div class="loaderdiv" *ngIf="loading['questions']">
                    <div class="round_loader loader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header flex-between">
            <h5>Lenses</h5>
          </div>
          <div class="card-body" *ngIf="!loading['questions']">
            <table class="table table-docs">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th>Name</th>
                  <th class="center">Questions Answered</th>
                  <th class="center">High Risks</th>
                  <th class="center">Medium Risks</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let lens of lenses; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <span
                      class="nav-link"
                      (click)="selectLens.emit(lens['lens'])"
                      >{{ getLensData(lens) }}</span
                    >
                  </td>
                  <td class="center">
                    {{
                      filterQuestions(lens["lens"], "lensId", true).length
                    }}/{{
                      filterQuestions(lens["lens"], "lensId", false).length
                    }}
                  </td>
                  <td class="center">
                    {{ fetchQuestionsRisks("High", lens["lens"]).length }}
                  </td>
                  <td class="center">
                    {{ fetchQuestionsRisks("Medium", lens["lens"]).length }}
                  </td>
                  <td>
                    <div class="flex-start">
                      <span
                        class="btn btn-success-soft"
                        (click)="downloadReport(lens, 'pdf')"
                        *ngIf="!lens['download_pdf']"
                      >
                        <i class="fa fa-download mr-5" aria-hidden="true"></i>
                        PDF
                      </span>
                      <span class="center" *ngIf="lens['download_pdf']">
                        <img
                          src="../assets/img/loading_2.svg"
                          alt="loading scans"
                          class="wid-14"
                        />
                      </span>
                      <span
                        class="btn btn-pptx"
                        (click)="downloadReport(lens, 'pptx')"
                        *ngIf="
                          !lens['download_pptx'] &&
                          lens['lens'] == 'wellarchitected'
                        "
                      >
                        <i class="fa fa-download mr-5" aria-hidden="true"></i>
                        PPTX
                      </span>
                      <span class="center" *ngIf="lens['download_pptx']">
                        <img
                          src="../assets/img/loading_2.svg"
                          alt="loading scans"
                          class="wid-14"
                        />
                      </span>
                      <span
                        class="btn btn-success-soft"
                        (click)="downloadReport(lens, 'xlsx')"
                        *ngIf="!lens['download_xlsx']"
                      >
                        <i class="fa fa-download mr-5" aria-hidden="true"></i>
                        XLSX
                      </span>
                      <span class="center" *ngIf="lens['download_xlsx']">
                        <img
                          src="../assets/img/loading_2.svg"
                          alt="loading scans"
                          class="wid-14"
                        />
                      </span>
                      <span
                        class="btn btn-success-soft"
                        (click)="downloadReport(lens, 'offenders')"
                        *ngIf="!lens['download_offenders']"
                      >
                        <i class="fa fa-download mr-5" aria-hidden="true"></i>
                        Offenders
                      </span>
                      <span class="center" *ngIf="lens['download_offenders']">
                        <img
                          src="../assets/img/loading_2.svg"
                          alt="loading scans"
                          class="wid-14"
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="tab-pane"
        *ngIf="!isMilestone"
        id="milestones"
        role="tabpanel"
        aria-labelledby="milestones-tab"
      >
        <app-milestones
          [loading]="loading"
          *ngIf="!isMilestone"
          [workloadId]="workload['id']"
          [milestones]="milestones"
          (milestone)="milestone.emit($event)"
        ></app-milestones>
      </div>
      <div
        class="tab-pane"
        *ngIf="!isMilestone"
        id="properties"
        role="tabpanel"
        aria-labelledby="properties-tab"
      >
        <div class="card">
          <div class="card-header flex-between">
            <span> Workload Properties </span>
          </div>
          <div class="card-body">
            <app-properties
              [workload]="workload"
              [lensesData]="lensesData"
            ></app-properties>
          </div>
        </div>
        <div class="card">
          <div class="card-header flex-between">
            <span> Profile Properties </span>
            <button
              class="btn btn-danger-soft"
              (click)="disassociateProfile = true"
              *ngIf="workload['profile']"
            >
              Disassociate Profile
            </button>
          </div>
          <div class="card-body">
            <div
              class="p-2 center"
              *ngIf="!workload['profile'] && !loading['profiles']"
            >
              No profile associated with this workload.
              <span class="nav-link" (click)="associateProfile = true"
                >Click here</span
              >
              to associate
            </div>
            <div class="row grid-2" *ngIf="workload['profile']">
              <div>
                <label>Name</label>
                <span>
                  {{ workload["profile"]["profileName"] || "-" }}
                </span>
              </div>
              <div>
                <label>ARN</label>
                <span>
                  <i
                    *ngIf="workload['aws_arn']"
                    (click)="copyURL(workload['aws_arn'])"
                    class="fa-regular fa-clone"
                    aria-hidden="true"
                  ></i>
                  {{ workload["profile"]["profileARN"] || "-" }}
                </span>
              </div>
              <div>
                <label>Description</label>
                <span>
                  {{ workload["profile"]["profileDescription"] || "-" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<workload-associate-profile
  *ngIf="associateProfile"
  [accountId]="workload['account_id']"
  [profiles]="profiles"
  [regionId]="workload['region_id']"
  [workload]="workload"
  (hideModel)="hideProfileWindow($event)"
></workload-associate-profile>

<workload-disassociate-profile
  *ngIf="disassociateProfile"
  [accountId]="workload['account_id']"
  [regionId]="workload['region_id']"
  [workload]="workload"
  (hideModel)="hideProfileWindow($event)"
></workload-disassociate-profile>
