import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { APIService } from "../../../../api/api.service";
import { NotifierService } from "./../../../../_services/notifier.service";
import * as moment from "moment";

import * as echarts from "echarts";

@Component({
  selector: "metrics-ec2",
  templateUrl: "./metrics.ec2.component.html",
  styleUrls: ["./metrics.ec2.component.css"],
})
export class MetricsComponent implements OnInit, OnDestroy {
  @Input("instance") instance: any;
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  jobDetails: any = null;
  recomondations: any = [];
  funRef: any;
  tagsList: any = null;
  tagsModalTitle: any = "Tags";
  modalHeader: any;
  createJobTrigger: boolean = null;
  currentPage: String = "job_details";
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  metricsData: any;
  interval: string = "24";
  ngOnInit(): void {
    this.load(this.interval);
  }

  async load(interval: any) {
    this.interval = interval;
    this.notifier.loading(true);
    let data = {
      region: this.regionId,
      accountId: this.accountId,
      instanceId: this.instance.instid,
      interval: this.interval + " Hours",
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2metricsgraph`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status_code == 1 || result.status_code == "1") {
      this.metricsData = result.cpuMetrics;
      this.metricsData = this.metricsData.sort(function (a: any, b: any) {
        const dA = moment(a.date.trim(), "DD-MM-YYYY hh:mm:ss").format(
          "MM-DD-YYYY hh:mm:ss"
        );
        const dB = moment(b.date.trim(), "DD-MM-YYYY hh:mm:ss").format(
          "MM-DD-YYYY hh:mm:ss"
        );
        let dateA = new Date(dA).getTime();
        let dateB = new Date(dB).getTime();
        return dateA > dateB ? 1 : -1;
      });
      // let dt: any = [];
      // this.metricsData = this.metricsData.filter((value: any) => {
      //   let check = moment(value['date'], 'DD-MM-YYYY hh:mm:ss').format(
      //     'DD-MM-YYYY'
      //   );
      //   if (dt.includes(check)) {
      //     return false;
      //   } else {
      //     dt.push(check);
      //     return true;
      //   }
      // });
      this.loadGraph();
    }

    this.notifier.loading(false);
  }

  loadGraph() {
    var chartDom = document.getElementById("graphdiv");
    var myChart = echarts.init(chartDom);

    let averageCPU = this.metricsData.map((a: any) => a.avg_cpu);
    let minimumCPU = this.metricsData.map((a: any) => a.min_cpu);
    let maximumCPU = this.metricsData.map((a: any) => a.max_cpu);
    let labelData = this.metricsData.map((a: any) => {
      return moment(a.date, "DD-MM-YYYY hh:mm:ss").format("DD-MM_YYYY hh:mm A");
    });

    let xInterval = 0;
    if (this.interval == "24") {
      xInterval = 0;
    }
    if (this.interval == "12") {
      xInterval = 1;
    }
    if (this.interval == "6") {
      xInterval = 3;
    }

    let option: any = {
      color: ["#0cbc87", "#5864FF", "#f597a0"],
      xAxis: {
        type: "category",
        data: labelData,
        axisLabel: { interval: xInterval, rotate: 30, name: "Count" },
      },
      yAxis: {
        type: "value",
      },
      legend: {},
      series: [
        {
          data: minimumCPU,
          type: "line",
          name: "Min CPU",
        },
        {
          data: averageCPU,
          type: "line",
          name: "Avg CPU",
        },
        {
          data: maximumCPU,
          type: "line",
          name: "Max CPU",
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        position: function (
          pos: any,
          params: any,
          el: any,
          elRect: any,
          size: any
        ) {
          var obj: any = { top: 10 };
          obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
        extraCssText: "width: 170px",
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false,
          },
          brush: {
            type: ["lineX", "clear"],
          },
        },
      },
    };

    option && myChart.setOption(option);
  }

  ngOnDestroy(): void {}
}
