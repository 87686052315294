<div class="screen">
    <div class="screen-container">
        <div class="screen-header flex-between">
            <div>
                {{title}}
            </div>
            <div (click)="hideModal.emit(false)">
                <i class="fa fa-times"></i>
            </div>
        </div>
        <div class="screen-body">
            <div *ngFor="let price of data" class="mb-2">
                <div class="flex-between text-bold">
                    <div>{{ price['title'] }}</div>
                    <div>{{ price['price'] }}</div>
                </div>
                <div>
                    <div class="progress-bar">
                        <div class="progress" [ngStyle]="{width: price['perc'] + '%'}"></div>
                    </div>
                </div>
                <div class="flex-between font-size-0-8rem">
                    <div>
                        {{range['start']}} USD
                    </div>
                    <div>
                        {{range['end']}} USD
                    </div>
                </div>
            </div>
            <div class="flex-end">
                <button class="btn btn-light" (click)="hideModal.emit(false)">Cancel</button>
            </div>
        </div>
    </div>
</div>