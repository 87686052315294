<div class="card-header margin-height-60">
  <span class="title font-height-align">S3 Age
    Report</span>
  <button *ngIf="!fetching" class="btn btn-primary-soft float-right-margin" (click)="refresh()">
    <i class="fa fa-refresh" aria-hidden="true"></i>
    Refresh
  </button>
  <button class="btn btn-success-soft float-right-margin" *ngIf="!fetching && s3Data.length > 0"
    (click)="downloadReport()"><i class="fa fa-download" aria-hidden="true"></i>
    Download</button>
</div>
<div class="card">
  <div class="card-body">
    <div class="max-flow-100">
      <table class="table table-docs table-hover cost-table" id="ebs_cost_optimization">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of userHeader">
              <div *ngIf="h.center" class="align-center">
                {{ h.name }}
              </div>
              <div *ngIf="!h.center">
                {{ h.name }}
              </div>
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, s3Data)">
                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': s3Data.length ? 'table-row-group': 'none'}"
          *ngFor="let i of s3Data; let j = index">
          <tr *ngIf="notifier.filterTableColums(userHeader, i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of userHeader">
              <div *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" [innerHTML]="i[h.id]">
                </div>
              </div>
              <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click" [innerHTML]="i[h.id]">
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="s3Data.length == 0 && !fetching"
          [ngStyle]="{'display': s3Data.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="userHeader.length + 1">
              <div class="align-center">No Data Found</div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="fetching">
          <tr>
            <td [attr.colspan]="userHeader.length + 1" class="align-center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="width-mar-20">
            </td>
          </tr>
        </tbody>
      </table>
      <div class="align-right" *ngIf="index > 1"><button class="btn btn-primary-soft mar-10"
          (click)="loadS3Data()">Next</button></div>
    </div>
  </div>
</div>