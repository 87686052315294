<!--<div class="content">
  <div class="row g-3">
    <div class="col-12">
      <div class="card h-100">
        <div class="card-header">
          <div class="row flex-between-center">
            <div class="col-auto">
              <h6 class="mb-2">Candle Chart</h6>
            </div>
            <div class="col-auto mt-2">
              <div class="row g-sm-4">
                <div class="col-12 col-sm-auto">
                  <div class="mb-3 pe-4 border-sm-end border-200">
                    <h6 class="fs--2 text-600 mb-1">Forecast Hours</h6>
                    <div class="d-flex align-items-center">
                      <h5 class="fs-0 text-900 mb-0 me-2">2077h</h5><span class="badge rounded-pill badge-soft-primary"><span class="fas fa-caret-up"></span> 20.2%</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-auto">
                  <div class="mb-3 pe-4 border-sm-end border-200">
                    <h6 class="fs--2 text-600 mb-1">Workflow Hours</h6>
                    <div class="d-flex align-items-center">
                      <h5 class="fs-0 text-900 mb-0 me-2">100h</h5><span class="badge rounded-pill badge-soft-success"><span class="fas fa-caret-up"></span> 20%</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-auto">
                  <div class="mb-3 pe-0">
                    <h6 class="fs--2 text-600 mb-1">Forecast Income</h6>
                    <div class="d-flex align-items-center">
                      <h5 class="fs-0 text-900 mb-0 me-2">$256,489</h5><span class="badge rounded-pill badge-soft-primary"><span class="fas fa-caret-up"></span> 18%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pe-0 position-relative" id="candle-chart" dir="ltr">
          <div class="btn-group position-absolute z-index-1 top-0 d-inline-block" role="group" style="left:20px;right:20px;"><button class="btn btn-falcon-default btn-sm mb-1" data-zoom="in"><span class="fas fa-plus"></span></button><button class="btn btn-falcon-default btn-sm mb-1" data-zoom="out"><span class="fas fa-minus"></span></button></div>
          <div class="echart-candle-chart" data-echart-responsive="true" data-action-target="candle-chart"> </div>
        </div>
      </div>
    </div>

    <div class="echart-bar-weekly-sales chartt">
      <div class="btn-group position-absolute z-index-1 top-0 d-inline-block" role="group" style="left:20px;right:20px;"><button class="btn btn-falcon-default btn-sm mb-1" data-zoom="in"><span class="fas fa-plus"></span></button><button class="btn btn-falcon-default btn-sm mb-1" data-zoom="out"><span class="fas fa-minus"></span></button></div>
      <div class="echart-candle-chart" data-echart-responsive="true" data-action-target="candle-chart"> </div> rounded-circle
    </div>
  </div>

  </div>-->
<div style="padding:20px; display:none; ">

  <div class="row g-6 mb-3">
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row"><div class="col"><span class="h6 font-semibold text-muted text-sm d-block mb-2">Budget</span> <span class="h3 font-bold mb-0">$750.90</span></div><div class="col-auto"><div class="icon icon-shape bg-tertiary text-white text-lg "><i class="bi bi-credit-card"></i></div></div></div><div class="mt-2 mb-0 text-sm"><span class="badge badge-pill bg-soft-success text-success me-2"><i class="bi bi-arrow-up me-1"></i>30% </span><span class="text-nowrap text-xs text-muted">Since last month</span></div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card"><div class="card-body"><div class="row"><div class="col"><span class="h6 font-semibold text-muted text-sm d-block mb-2">New projects</span> <span class="h3 font-bold mb-0">215</span></div><div class="col-auto"><div class="icon icon-shape bg-primary text-white text-lg "><i class="bi bi-people"></i></div></div></div><div class="mt-2 mb-0 text-sm"><span class="badge badge-pill bg-soft-success text-success me-2"><i class="bi bi-arrow-up me-1"></i>23% </span><span class="text-nowrap text-xs text-muted">Since last week</span></div></div></div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12"><div class="card"><div class="card-body"><div class="row"><div class="col"><span class="h6 font-semibold text-muted text-sm d-block mb-2">Total hours</span> <span class="h3 font-bold mb-0">1.400</span></div><div class="col-auto"><div class="icon icon-shape bg-info text-white text-lg"><i class="bi bi-clock-history"></i></div></div></div><div class="mt-2 mb-0 text-sm"><span class="badge badge-pill bg-soft-danger text-danger me-2"><i class="bi bi-arrow-down me-1"></i>-10% </span><span class="text-nowrap text-xs text-muted">Since last month</span></div></div></div></div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row"><div class="col"><span class="h6 font-semibold text-muted text-sm d-block mb-2">Work load</span> <span class="h3 font-bold mb-0">95%</span></div><div class="col-auto"><div class="icon icon-shape bg-warning text-white text-lg"><i class="bi bi-minecart-loaded"></i></div></div></div><div class="mt-2 mb-0 text-sm">
            <span class="badge badge-pill bg-soft-success text-success me-2"><i class="bi bi-arrow-up me-1"></i>15% </span><span class="text-nowrap text-xs text-muted">Since yesterday</span>
          </div>
        </div>
      </div>
    </div>
  </div>




  <div class="row g-6 mb-6">
    <div class=" col-xl-6 col-sm-6 col-12">
      <div id="echartFunel" class="chartt card "></div>
    </div>
    <div class=" col-xl-6 col-sm-6 col-12">
      <div id="echartWatter" class="chartt card "></div>
    </div>
  </div>
</div>

<style>

  body {
    background-color: #f5f9fc;
  }

  .chartt {
    width: 100%;
    height: 400px;
    float: left;
    padding: 20px;
    margin-top: 15px !important;
  }

  .mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .g-6, .gy-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-6, .gx-6 {
    --x-gutter-x: 1.5rem;
  }

  .card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 0 solid #eceef3;
    border-radius: .75rem;
    box-shadow: 0 3px 3px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06);
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
  }

  .pd-10 {
    padding: 10px;
  }

  .pd-20 {
    padding: 20px;
  }

  .rounded-circle {
    /*border-radius: 50% !important;*/
  }

  .bg-tertiary, .bg-tertiary-focus:focus, .bg-tertiary-hover:hover {
    background-color: rgb(255, 87, 154);
  }

  .bg-tertiary {
    --x-bg-opacity: 1;
  }

  .text-white, .text-white-focus:focus, .text-white-hover:hover {
    color: #fff;
  }

  .text-white {
    --x-text-opacity: 1;
    color: #fff;
  }

  .text-lg {
    font-size: 1.25rem !important;
  }

  .icon-shape {
    align-items: center;
    border-radius: .375rem;
    display: inline-flex;
    height: 3rem;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    width: 3rem;
  }

  .bg-soft-success {
    background-color: #ccf5e7 !important;
  }

  .bg-soft-danger {
    background-color: #ffd6e0 !important;
  }
</style>
