import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "site24x7-enable-bu",
  templateUrl: "./enable-bu.component.html",
  styleUrls: ["./enable-bu.component.css"],
})
export class EnableBuComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  loading: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  displayName: string = localStorage.getItem("com");
  termsRead: boolean = false;

  isUserAdmin: boolean = localStorage.getItem("isUserAdmin") == "1";

  ngOnInit(): void {}

  otpSent: boolean = false;
  otpText: string = "";
  requestID: string = "";
  moreDetails: boolean = false;
  async sendOTP() {
    this.loading = true;
    let data: any = {
      action: "send_common_otp",
      email_id: localStorage.getItem("eId"),
      user_name: localStorage.getItem("un"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.otpSent = true;
      this.requestID = result.request_id;
      this.notifier.alert("Success", "", result.message, "success", 5000);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.loading = false;
  }

  async validateOTP() {
    if (!this.otpText) {
      this.notifier.alert("Info", "", "OTP is required", "info", 5000);
      return;
    }
    this.loading = true;
    let data: any = {
      action: "validate_common_otp",
      email_id: localStorage.getItem("eId"),
      user_name: localStorage.getItem("un"),
      otp: this.otpText,
      request_id: this.requestID,
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      await this.createBU();
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.error_message || result.message,
        "info",
        5000
      );
    }

    this.loading = false;
  }

  async createBU() {
    this.loading = true;
    let data: any = {
      action: "create_bu",
      id_token: localStorage.getItem("idT"),
      login_user_email: localStorage.getItem("eId"),
      login_user_name: localStorage.getItem("un"),
      origin: "swayam",
      display_name: this.displayName,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      let device_key = await this.loadDeviceKey(result.zaaid);
      await this.savezaaid(result.zaaid, result.user_id, device_key);
      this.notifier.alert("Success", "", result.message, "success", 5000);
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.loading = false;
  }

  async loadDeviceKey(zaaId: string) {
    let device_key: string = null;
    let data: any = {
      action: "get_device_key",
      id_token: localStorage.getItem("idT"),
      zaaid: zaaId,
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      origin: "swayam",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result["status"] == "1" || result["s"] == "1") {
      device_key = result.device_key;
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    return device_key;
  }

  async savezaaid(zaaId: string, userId: string, deviceKey: string) {
    let masterId: string;
    if (this.urlPrefix == "client") {
      masterId = this.isUserAdmin
        ? localStorage.getItem("masterId")
        : localStorage.getItem("eId");
    } else {
      masterId = localStorage.getItem("selectedClientList");
    }
    let data: any = {
      a: "addems",
      eId: masterId,
      zaid: zaaId,
      userId: userId,
      deviceId: deviceKey,
      emsName: this.displayName,
    };
    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/awsaccounts`;

    if (this.urlPrefix == "admin") {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/awsaccounts`;
    }

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    console.log(result);

    this.loading = false;
  }
}
