<div class="card-header margin-height-60">
  <span class="title font-height-align">EBS Cost Controls</span>

  <button class="btn btn-primary-soft float-margin-10
  " (click)="refresh()">
    <i class="fa fa-refresh" aria-hidden="true"></i>
    Refresh
  </button>
  <button class="btn btn-success-soft float-margin-10"
    *ngIf="loading_recom == false && recomondations.length > 0 && currentPage == 'recomondations'"
    (click)="downloadReport()"><i class="fa fa-download" aria-hidden="true"></i>
    Download</button>
</div>
<div class="card">
  <!-- <div style="width: 100%; height: 100px; background-color: red;"></div> -->
  <div class="card-body">
    <div class="display-content-item">
      <div>Potential Saving: </div>
      <div>
        <span class="green_text margin-size-5" [innerHTML]="getTotalSavings() + ' USD'"></span>
      </div>
      <div *ngIf="loading_recom">
        <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="width-margin-15">
      </div>
    </div>
    <div class="max-flow-width">
      <table class="table table-docs table-hover cost-table" id="ebs_cost_optimization">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of ec2CostHeaders">
              {{ h.name }}
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, recomondations)">
                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th>More Details</th>
            <th>Reason</th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': recomondations.length ? 'table-row-group': 'none'}"
          *ngFor="let i of recomondations; let j = index">
          <tr *ngIf="notifier.filterTableColums(ec2CostHeaders, i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of ec2CostHeaders">
              <div *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" [innerHTML]="loadHTML(i[h.id])">
                </div>
              </div>
              <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click"
                [innerHTML]="loadHTML(i[h.id])">
              </div>
            </td>
            <td>
              <div class="failed_ami" (mouseenter)="setPosition($event)">
                <div class="status_container">
                  <div class="border-bottom-1">
                    More Details
                  </div>
                </div>
                <div class="failed_ami_message_container"
                  [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0}">
                  <div class="message_back">
                    <div>
                      <div class="message_grid"
                        [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                        <table>
                          <thead>
                            <tr>
                              <th *ngFor="let h of moreDetailsHeaders">{{h.name}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="showdown" [ngStyle]="{right: position.pointer}"></div>
                  <div class="message_container">
                    <div>
                      <div class="message_grid"
                        [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                        <table>
                          <thead>
                            <tr>
                              <th *ngFor="let h of moreDetailsHeaders">{{h.name}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>{{i['recReason']}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="recomondations.length == 0"
          [ngStyle]="{'display': recomondations.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="ec2CostHeaders.length + 3">
              <div class="align-center">No Recommendations Found</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="nextToken" class="padding-content-item-15">
        <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="width-margin-20">
      </div>
    </div>
  </div>
</div>
<ec2-cost-create-job-element *ngIf="createJobTrigger" (hideModal)="hideCreateJob($event)">
</ec2-cost-create-job-element>