<div class="card">
  <h5 class="card-header">
    Functions
  </h5>
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of functionsHeader">{{ h.name }}</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vm of functions; let i = index">
            <td>{{ i + 1 }}</td>
            <td *ngFor="let h of functionsHeader">{{ vm[h.id] }}</td>
            <td><span class="nav-link" (click)="showTags(vm['tags'] || [])">Manage Tags</span></td>
          </tr>
          <tr *ngIf="loading">
            <td [attr.colspan]="functionsHeader.length + 2" class='center'>
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
            </td>
          </tr>
          <tr *ngIf="!loading && functions.length == 0">
            <td [attr.colspan]="functionsHeader.length + 2" class='center'>
              <span>No Azure functions found in <strong>{{ resourceGroup }}</strong> resource group. You can change the
                resource group on the top panel</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<modal-popup [headers]="tags" *ngIf="showTagsModal" [data]="showTagsModal" [modalTitle]="'Tags'"
  [showModal]="true" (hideModal)="showTagsModal = null"></modal-popup>