import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";

@Component({
  selector: "app-steps-enable-cur",
  templateUrl: "./steps-enable-cur.component.html",
  styleUrls: ["./steps-enable-cur.component.css"],
})
export class StepsEnableCurComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();

  @Input('data') data: any;
  
  step: number = 0;
  steps: number = 6;

  constructor(private notifier: NotifierService) {}

  appendStep() {
    this.step += 1;
  }

  async copyToClipboard(code: string) {
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(code);
        this.notifier.alert('Success', '', 'sucess', 'Copied', 5000)
      } catch (e) {
        console.error("Error while copying code", e);
      }
    }
  }

  removeStep() {
    this.step -= 1;
  }

  getArray() {
    return new Array(this.steps).fill(0);
  }

  getMarginLeft() {
    let element = document.querySelector(".info-space .steps-container");
    return `${this.step * element.getBoundingClientRect().width * -2}px`;
  }

  setStep(index: number) {
    this.step = index;
  }

  ngOnInit(): void {
    this.step = 0;
  }
}
