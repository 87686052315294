<div class="card">
  <h5 class="card-header">Enable CFRC Order</h5>
  <div class="card-body">
    <div class="master_04">
      <div>
        <span>Master Accounts:</span>
        <span>
          <select class="form-control master_08" id="masterAccountSelect" data-live-search="true" (change)="load()">
            <option [value]="account" *ngFor="let account of masterAccounts">
              {{ account }}
            </option>
          </select>
        </span>
      </div>
      <div>
        <span>Month Year:</span>
        <span>
          <input autocomplete="off" type="text" id="monthDate" class="form-control">
        </span>
      </div>
      <div>
        <button class="btn btn-light button_23 checkFile_25" (click)="triggerFIle()">Upload</button>
        <input autocomplete="off" type="file" name="" id="fileInput" (change)="checkFile($event)" #fileInput>
      </div>
      <div *ngIf="cfrcData.length > 0">
        <button class="btn btn-light button_23" (click)="export()">Download
          Excel</button>
      </div>
    </div>
    <table class="table table-docs table-hover" id="cloudFrontData">
      <thead>
        <tr>
          <th>S.N.</th>
          <ng-container *ngFor="let h of headers">
            <th *ngIf="!h.conditionalShow || showConditionalElement">{{ h.name }}</th>
          </ng-container>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display' : cfrcData.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of cfrcData; let j = index">
          <td>{{j+1}}</td>
          <ng-container *ngFor="let h of headers">
            <td *ngIf="!h.conditionalShow || showConditionalElement">
              <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <span [innerHTML]="i[h.id]">
                </span>
              </span>
              <span *ngIf="!h.click">
                {{h.alter ? h.alter(i[h.id]) : i[h.id]}}
              </span>
            </td>
          </ng-container>
        </tr>
      </tbody>
      <tbody *ngIf="cfrcData.length == 0">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div class="no_data_found_61">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="position_70" [ngStyle]="{'display': upload ? 'block': 'none'}">
  <div class="position_73">
    <div (click)="closeUpload()" class="btn btn-light position_74">
      X
    </div>
    <div class="cfrc_period_77">
      <label for="">CFRC Period: </label>
      <input autocomplete="off" type="text" id="dataRangePicker" [(ngModel)]="dateRange"
        class="form-control data_range_78">
    </div>
    <div class="data_range_79">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th *ngFor="let h of editableHeader">
              {{h.name}}
            </th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display' : editable.length > 0 ? 'table-row-group': 'none'}">
          <tr *ngFor="let i of editable; let j = index">
            <td *ngFor="let h of editableHeader">
              <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <span [innerHTML]="i[h.id]">
                </span>
              </span>
              <span *ngIf="!h.click && !h.editable">
                {{h.alter ? h.alter(i[h.id]) : i[h.id]}}
              </span>
              <span *ngIf="!h.click && h.editable">
                <input autocomplete="off" class="form-control" type="text" [(ngModel)]="i[h.id]">
              </span>
            </td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': editable.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="editableHeader.length">
              <div class="no_data_found_109">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="button_23_115">
      <button class="btn btn-light button_23" (click)="saveDetails()">
        Save Details
      </button>
    </div>
  </div>
</div>