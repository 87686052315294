<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">
      Create Custom Lens
    </div>
    <div class="KBBody">
      Are you sure you want to create the custom lens?
    </div>
    <div class="KBActions">
      <button class="btn btn-success-soft" *ngIf="!loading" (click)="createLens()">
        Create
      </button>
      <button class="btn btn-success-soft" *ngIf="loading" [attr.disabled]="true">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="width-mar-18"
        />
        Create
      </button>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>