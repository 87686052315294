import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-cur-feature",
  templateUrl: "./cur-feature.component.html",
  styleUrls: ["./cur-feature.component.css"],
})
export class CurFeatureComponent implements OnInit {
  @Output("hideModel") hideModel = new EventEmitter<boolean>();
  @Input("accountId") accountId: string;
  activated: boolean = false;
  loading: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private route: ActivatedRoute
  ) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  ngOnInit(): void {}

  async activateBilling() {
    this.loading = true;
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      console.log(selectedAccountData);

      if (!selectedAccountData.error) {
        const account = selectedAccountData.data;
        let data = {
          a: "sendEmail",
          accountId: this.accountId,
          accountName: account["accountName"],
          emailId: localStorage.getItem("eId"),
          clientName: localStorage.getItem("un"),
          cmpName: localStorage.getItem("com"),
        };

        let header = {
          Authorization: localStorage.getItem("t"),
        };

        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/globals/curalerts`;
        let result = await this.apiService.postDataPromis(apiURL, data, header);

        if (result.status == "1" || result.s == "1") {
          this.activated = true;
        }
      }
    } catch (err: any) {
      console.log(err);
    }
    this.loading = false;
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }
}
