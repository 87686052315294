<div class="properties-grid-2 KBBody">
  <div>
    <div class="col-mb-6 mb-10">
      <label>Name </label>
      <span>
        {{ workload['name'] || '-' }}
      </span>
    </div>
    <div class="col-mb-6 mb-10">
      <label>Owner </label>
      <span>
        {{ workload['account_id'] || '-' }}
      </span>
    </div>
    <div class="col-mb-6 mb-10">
      <label>AWS ARN </label>
      <span>
        <i *ngIf="workload['aws_arn']" clas (click)="copyURL(workload['aws_arn'])" class="fa-regular fa-clone"
          aria-hidden="true"></i>
        {{ workload['aws_arn'] || '-' }}
      </span>
    </div>
    <div class="col-mb-6 mb-10">
      <label>Description </label>
      <span>
        {{ workload['description'] || '-' }}
      </span>
    </div>
  </div>
  <div>
    <div class="col-mb-6 mb-10">
      <label>Review owner </label>
      <span>
        {{ workload['review_owner'] || '-' }}
      </span>
    </div>
    <div class="col-mb-6 mb-10">
      <label>Environment </label>
      <span>{{ environments[workload['environment']] || '-' }}</span>
    </div>
    <div class="col-mb-6 mb-10">
      <label>Lenses </label>
      <span>
        {{ commaSeperated(workload['lenses']) || '-' }}
      </span>
    </div>
  </div>
</div>