import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

@Component({
  styleUrls: ['./createscan.backupreport.component.css'],
  selector: 'createscan-backupreport',
  templateUrl: './createscan.backupreport.component.html',
})
export class CreateScanBackupReportComponent implements OnInit, OnDestroy {
  @Output() hideModal = new EventEmitter<any>();
  @Input() apiUrl: string;
  @Input() description: string;
  @Input() data: any;
  @Input() message: any;
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}
  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no() {
    this.hideModal.emit({ status: 0 });
  }

  async createJob() {
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let result = await this.apiServer.postDataPromis(
      this.apiUrl,
      this.data,
      header
    );

    if (result.status == '1' || result.s == '1') {
      result.message = result.message || result.msg;
      this.message = this.message.replace('[message]', result.message);
      this.notifier.alert('success', '', this.message, 'success', 5000);
      this.no();
    } else {
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }
    this.notifier.loading(false);
  }
}
