import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "delete-lens",
  templateUrl: "./delete-lens.component.html",
  styleUrls: ["./delete-lens.component.css"],
})
export class DeleteLensComponent {
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;
  @Input("lens") lens: any;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  loading: boolean = false;

  @Output() hideModel: any = new EventEmitter<any>();

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  async deleteLens() {
    this.loading = true;
    let data: any = {
      accountId: this.accountId,
      action: "deleteCustomLens",
      profileArn: this.lens["arn"],
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiService.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
    }
    this.loading = false;
  }
}
