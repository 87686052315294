import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { FileUploadService } from "src/app/fileupload/fileupload.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-files-viewer",
  templateUrl: "./files-viewer.component.html",
  styleUrls: ["./files-viewer.component.css"],
})
export class FilesViewerComponent implements OnInit{
  @Input("files") files: any;
  @Input("job") job: any;
  @Input("question") question: any;
  @Input("questions") questions: any;
  @Input("disable") disable?: boolean;
  @Output("resetFiles") resetFiles: any = new EventEmitter<void>();
  @Output("hideModel") hideModel: any = new EventEmitter<void>();

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  loading: boolean = false;

  constructor(
    private apiService: APIService,
    public fileUploadService: FileUploadService
  ) {}

  ngOnInit(): void {
      if(!this.question) {
        this.question = 'All';
      }
  }

  fetchFiles(event: any) {
    event.stopPropagation();
    document.getElementById("hidden-input-file").click();
  }

  getFiles() {
    return this.files.filter((file: any) => {
      return this.question && (this.question == 'All' || this.question['check_id'] == file['question_id']);
    })
  }

  startDrag(event: any) {
    event.preventDefault();
    event.target.classList.add("highlight");
  }

  endDrag(event: any) {
    event.target.classList.remove("highlight");
  }

  drop(event: any) {
    event.preventDefault();
    this.endDrag(event);
    this.handleFiles(event.dataTransfer.files);
  }

  changeInput(event: any) {
    event.preventDefault();
    this.endDrag(event);
    this.handleFiles(event.target.files);
  }

  uploadFiles: any = [];
  uploadFilesMeta: any = [];
  handleFiles(files: any = []) {
    this.uploadFiles = [...files];
    this.uploadFilesMeta = this.uploadFiles.map((file: File) => {
      return { ...file };
    });
  }

  submitFiles() {
    let promises: any = [];
    this.loading = true;
    this.uploadFiles.forEach((file: any, index: number) => {
      this.uploadFilesMeta[index]["status"] = "uploading";
      this.uploadFilesMeta[index]["loaded"] = 0;
      this.uploadFilesMeta[index]["total"] = file["size"];
      promises.push(this.generatePresignedUrl(file, index));
    });
    Promise.all(promises).then((values: any) => {
      this.loading = false;
      this.uploadFiles = [];
      this.resetFiles.emit();
    });
  }

  removeFile(index: number) {
    this.uploadFiles.splice(index, 1);
    this.uploadFilesMeta.splice(index, 1);
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
    );
  }

  async generatePresignedUrl(file: File, index: number) {
    let data: any = {
      account_id: localStorage.getItem("accountId"),
      region_id: localStorage.getItem("regionId"),
      action: "get_presigned_url",
      job_id: this.job["job_id"],
      question_id: this.question["check_id"],
      mime_type:
        "." + file["name"].split(".")[file["name"].split(".").length - 1],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/compliance/rbicompliance`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" || result.status == "1") {
      await this.uploadFile(file, result, index);
    }
  }

  async saveFile(obj: any, index: number) {
    let data: any = {
      account_id: localStorage.getItem("accountId"),
      region_id: localStorage.getItem("regionId"),
      action: "upload_file",
      job_id: this.job['job_id'],
      file_path: obj['file_path'],
      question_id: this.question['check_id'],
    };
    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/compliance/rbicompliance`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" || result.status == "1") {
      this.uploadFilesMeta[index]['status'] = 'completed';
    }
  }

  async uploadFile(file: File, result: any, index: number) {
    await this.uploadFileAPI(result, file, this.uploadFilesMeta[index], index);
  }

  async uploadFileAPI(url: any, file: any, meta: any, index: number) {
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(url["signed_url"], file)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              meta["status"] = "uploading";
              let progress = Math.round((event.loaded / event.total) * 100);
              meta.loaded = event.loaded;
              meta.percentage = progress;
              break;
            case HttpEventType.Response:
              await this.saveFile(url, index);
              resolve(true);
          }
        });
    });
  }
}
