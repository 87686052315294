<div class="feature-screen">
  <div class="feature-container">
    <ng-container *ngIf="!activated">

      <div class="feature-header">
        Activate CUR
        <div class="close-button" (click)="hideModel.emit(true)">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="feature-body">
        <div>
          Activate the {{ whitelabelData['pd']['appName'] }}'s Cost and Usage Reports Feature
        </div>
        <div>
          <div class="feature-header">
            Benefits
          </div>
          <div class="benifits">
            <div class="benifit">
              <div class="icon-container">
                <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Billing.svg"
                  alt="">

              </div>
              <div>
                <div class="benifit-header">
                  Monthly Bill
                </div>
                <div class="benifit-body">View and Export Monthly usage reports from {{ whitelabelData['pd']['appName'] }}</div>
              </div>
            </div>
            <div class="benifit">
              <div class="icon-container">
                <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Billing.svg"
                  alt="">

              </div>
              <div>
                <div class="benifit-header">
                  Daily Bill
                </div>
                <div class="benifit-body">
                  View and Export Daily usage reports from {{ whitelabelData['pd']['appName'] }}
                </div>
              </div>
            </div>
            <div class="benifit">
              <div class="icon-container">
                <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Billing.svg"
                  alt="">

              </div>
              <div>
                <div class="benifit-header">
                  Budgets
                </div>
                <div class="benifit-body">Get notifications on your budgets</div>
              </div>
            </div>
            <div class="benifit">
              <div class="icon-container">
                <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Billing.svg"
                  alt="">

              </div>
              <div>
                <div class="benifit-header">
                  Daily Cost Variance Report
                </div>
                <div class="benifit-body">Get notifications on your daily cost variance</div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button class="btn btn-primary-soft btn-large" *ngIf="!loading" (click)="activateBilling()">Activate</button>
          <img src="../assets/img/loading_2.svg" alt="loading scans" *ngIf="loading" class="wid-mar" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activated">
      <div class="activated-icon center">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </div>
      <div class="feature-header font-1-1">
        CUR Feature Activation Request has been sent. <br> Our Support Team will contact you shortly
      </div>
      <div class="actions center">
        <button class="btn btn-light" (click)="hideModel.emit(true)">Close</button>
      </div>
    </ng-container>
  </div>
</div>