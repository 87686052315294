<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">RAM Per Region</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
          <div class="KBBody table-responsive ">

            <!-- <ng-container *ngFor="let tag of tagList;" class="scrollable-body mb-0">
              <div *ngIf="tag.id != 'total_ram_of_all_accounts'">     
                <div class="card mt-1">
                  <div class="card-header d-flex justify-content-between">
                 <b> {{tag.id}} </b> <b class="mright-50">All Regions</b>
                </div>
             <div class="card-body">
              <table class="table table-hover  m-0 p-0">
                <thead class="blue fixed-header">
                  <tr>
                    <td scope="col">S.N.</td>
                    <td class="text-center" scope="col">RAM</td>   
                  </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tg of tag.tag;let j = index">
                <td>{{j+1}}</td>
                 <td class="text-center">{{tg.key}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
              </ng-container> -->


              <ng-container class="table-responsive">
        
         
         
                <table class="table table-hover  m-0 p-0">
                  <thead class="blue fixed-header table-header-sticky">
                    <tr>
                      <td scope="col" class="hsticky"></td>
                      <ng-container *ngFor="let header of regionHeader;">
                      <td class="text-center" scope="col">{{header}}</td>
                    </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let inst of tagList;" class="scrollable-body mb-0">
                    <tr class="pointer" (click)="toggleItem(inst)">
                      <td  class="sticky bold"><span><i [class]="inst.isHidden ? 'fa-regular fa-square-minus' : 'fa-regular fa-square-plus'"></i></span>&nbsp;   {{inst.id}} </td>
                      
                    </tr>
                    <ng-container *ngIf=inst.isHidden>
                    <tr *ngFor="let reg of inst.regionData;">
                      <td class="sticky mr-2">{{reg.type}}</td>
                      <ng-container *ngFor="let val of reg.values;">
                      <td class="text-center" [ngClass]="{'bold2': val != '0.00 MB'}">{{val}}</td>
                    </ng-container>
                    </tr>
                   
                  </ng-container>
                </ng-container>
                 
                  </tbody>
                </table>
            
            
          </ng-container>


            
              <!-- <div class="btnHold">
                <div class="bhold">
                  <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
                  <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
                  [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
                </div>
              </div> -->
    
    
            <!-- <div class="KBActions">
              <button class="btn btn-light" (click)="no()">Cancel</button>
            </div> -->
          
        </div>
          
</div>


