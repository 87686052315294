<div class="card">
  <div class="card-header flex-between">
    <h5>Cloudwatch Cost Controls</h5>
    <div class="flex-start">
      <ng-container *ngIf="scanDetails">
        <span class="border-right">
          <div class="text-bold">
            Scanned on
          </div>
          <div>
            {{ scanDetails["lastScan"] || '-' }}
          </div>
        </span>
        <span>
          <div class="text-bold">
            Status
          </div>
          <div>
            {{ scanDetails["status"] || '-' }}
          </div>
        </span>
      </ng-container>
      <button class=" btn btn-light" (click)="loadRecommendations()">Refresh</button>
      <button class=" btn btn-primary-soft" (click)="createScan()">Scan</button>
    </div>
  </div>
</div>
<!-- <div class="card">
  <div class="card-body p-2">
    <div class="discovery-section-container">
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            ${{ metadata['MetricsCost']().toFixed(2) }}
          </span>
          <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        </div>
        <div class="item-desc">Metrics Cost</div>
      </div>
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            ${{ metadata['TotalLogsCost']().toFixed(2) }}
          </span>
          <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        </div>
        <div class="item-desc">Total Logs Cost</div>
      </div>
      <div class="item-container">
        <div class="item-highlight flex-start">
          <span>
            ${{ metadata['AlarmCost']().toFixed(2) }}
          </span>
          <img *ngIf="loading" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        </div>
        <div class="item-desc">Alarm Cost</div>
      </div>

    </div>
  </div>
</div> -->
<div class="card">
  <div class="card-body">
    <table class="table" *ngIf="!loading && scanDetails['status'] != '-'">
      <tbody>
        <tr>
          <th>Product</th>
          <th>Free Tier/month</th>
          <th>Usage</th>
          <th>Usage Cost</th>
        </tr>
        <tr>
          <th>Metrics</th>
          <td>
            <div>10 Custom Metrics</div>
            <div>Standard Metrics</div>
          </td>
          <td>
            <div class="hoverable">{{ metadata['CustomMetricsCount']() }} <span class="show_hoverable">Custom
                Metrics</span></div>
            <div class="hoverable">{{ metadata['StandardMetricsCount']() }} <span class="show_hoverable">Standard
                Metrics</span></div>
          </td>
          <td>
            <div>${{ metadata['MetricsCost']().toFixed(2) }}</div>
            <div>$0.00</div>
          </td>
        </tr>
        <tr>
          <th>Logs</th>
          <td>
            5 GB logs ingested
          </td>
          <td>
            <div class="hoverable">
              {{ metadata['TotalLogSize']().toFixed(4) }} GB <span class="show_hoverable">logs ingested</span>
            </div>
          </td>
          <td>
            ${{ metadata['TotalLogsCost']().toFixed(2) }}
          </td>
        </tr>
        <tr>
          <th>Alarms</th>
          <td>
            10 Alarms
          </td>
          <td>
            <div class="hoverable">
              {{ metadata['AlarmCount']() }} <span class="show_hoverable">Alarms</span>
            </div>
          </td>
          <td>
            ${{ metadata['AlarmCost']().toFixed(2) }}
          </td>
        </tr>
        <tr>
          <th>Dashboards</th>
          <td>
            1 Dashboard
          </td>
          <td>
            <div class="hoverable">
              {{ metadata['DashboardCount']() }} <span class="show_hoverable">Dashboard(s)</span>
            </div>
          </td>
          <td>
            ${{ metadata['DashBoardCost']().toFixed(2) }}
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td colspan="2" class="center">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="center" *ngIf="!loading && scanDetails['status'] == '-'">
      No Data found. <span class="nav-link" (click)="createScan()">click here</span>to scan
    </div>
    <div class="center" *ngIf="loading">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
    </div>
  </div>
</div>

<modal-popup [headers]="recommendationsHeader" [data]="selectedBucket['recommendation']"
  [modalTitle]="'Recommendations'" [showModal]="true" *ngIf="selectedBucket"
  (hideModal)="selectedBucket = null"></modal-popup>