import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'create-lens',
  templateUrl: './create-lens.component.html',
  styleUrls: ['./create-lens.component.css']
})
export class CreateLensComponent {
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  loading: boolean = false;

  @Output() hideModel: any = new EventEmitter<any>();

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  async createLens() {
    this.loading = true;
    let data: any = {
      accountId: this.accountId,
      action: "createCustomLens",
      regionId: this.regionId,
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiService.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
    }
    this.loading = false;
  }
}
