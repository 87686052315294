import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";

declare let flatpickr: any;
declare let window: any;

@Component({
  templateUrl: "./audit.settings.component.html",
  styleUrls: ["./audit.settings.component.css"],
})
export class auditSettingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  auditList: any = {};
  destroyed: boolean = false;
  dataFilter: string = "date";

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  filterDate: any = moment();
  selectedAction: any = "all";
  selectedUser: string = "all";
  endDate: string;
  source: string;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute,
    private navigator: Router,
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.router.queryParams.subscribe((params: any) => {
      if(params['user_id'] && this.ut == 'admin') {
        this.userId = params['user_id'];
      } else if(params['user_id']) {
        this.navigator.navigate(['/dash/settings/audittrail']);
        this.userId = localStorage.getItem("eId");
      }
      if(params['source']) {
        this.source = params['source'];
      }
    })

    // document.addEventListener('scroll', (event) => this.loadMoreTrails(event));

    // flatpickr('#date-selector', {
    //   dateFormat: 'd/m/Y',
    //   defaultDate: [moment().format('DD/MM/YYYY')],
    //   maxDate: new Date(),
    //   onChange: (selectedDates: any, dateStr: any, instance: any) => {
    //     this.filterDate = moment(dateStr, 'DD/MM/YYYY');
    //     this.nextToken = {};
    //     this.auditList = {};
    //     this.load(this.filterDate);
    //   },
    // });

    // flatpickr('#date-selector-range', {
    //   dateFormat: 'd/m/Y',
    //   defaultDate: [
    //     moment().subtract(1, 'days').format('DD/MM/YYYY'),
    //     moment().format('DD/MM/YYYY'),
    //   ],
    //   maxDate: moment().format('DD/MM/YYYY'),
    //   minDate: moment().subtract(3, 'months').format('DD/MM/YYYY'),
    //   showMonths: 2,
    //   mode: 'range',
    //   onChange: (selectedDates: any, dateStr: any, instance: any) => {
    //     if (!dateStr.includes('to')) return;
    //     this.filterDate = moment(dateStr.split(' to ')[1], 'DD/MM/YYYY');
    //     this.endDate = dateStr.split(' to ')[0];
    //     this.nextToken = {};
    //     this.auditList = {};
    //     this.load(this.filterDate);
    //   },
    // });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != 'notifier') {
        return;
      }

      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        // this.regionId = d.value;
        this.load();
      }
    });
  }

  backToUsers() {
    this.navigator.navigate(['/dash/settings/users/view']);
  }

  getDates() {
    return Object.keys(this.auditList);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
    this.destroyed = true;
  }

  colors: any = {};
  actions: any = [];
  users: any = [];
  usersAppended: any = [];

  getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  user_favicon(username: string, user_name_parsed_requrired: boolean = false) {
    var user_favicon_text = "";
    var user_names;
    var username_name_parsed = "";
    if (username == "") {
      if (user_name_parsed_requrired) {
        return { user_full_name: "Not Assigned", user_favicon_parsed: "NA" };
      }
      return "Not Assigned";
    }
    username = username.trim();
    if (!username.includes("@")) {
      user_names = username.split(" ");
    } else {
      user_names = username.split("@")[0].split(".");
    }
    if (user_names.length > 0) {
      user_favicon_text += user_names[0][0].trim();
      username_name_parsed += user_names[0].trim();
      if (user_names.length > 1) {
        if (user_names[user_names.length - 1].length > 0) {
          user_favicon_text += user_names[user_names.length - 1][0].trim();
          username_name_parsed +=
            " " + user_names[user_names.length - 1].trim();
        }
      } else {
        user_favicon_text += " ";
      }
    }
    if (user_name_parsed_requrired) {
      return {
        user_full_name: username_name_parsed,
        user_favicon_parsed: user_favicon_text,
      };
    }
    return user_favicon_text.toUpperCase();
  }

  loadMoreData(event: any) {
    event.preventDefault();
    if (event.target.classList.contains("more_info_container_expanded")) {
      event.target.querySelector(".more_info_container").style.maxHeight =
        0 + "px";
    } else {
      event.target.querySelector(".more_info_container").style.maxHeight =
        event.target.querySelector(".more_info_container_main").offsetHeight +
        "px";
    }
    event.target.classList.toggle("more_info_container_expanded");
  }

  fetching: boolean = true;
  nextToken: any = {};
  includeGlobals: boolean = false;
  async load() {
    if (this.destroyed) {
      return;
    }
    this.auditList = {};
    this.fetching = true;
    let data: any = {
      action: "get_events",
      filters: {
        accountId: !this.includeGlobals ? this.accountId : undefined,
        "userIdentity.emailId": this.userId,
        regionId: !this.includeGlobals ? this.regionId : undefined,
      },
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `${environment.apiURL}/${this.urlPrefix}/compliance/trail/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      result.status == "1" ||
      result.s == "1" ||
      (result.events && result.events.length > 0)
    ) {
      result.events.sort((a: any, b: any) => {
        return moment(b['eventTime']).valueOf() - moment(a['eventTime']).valueOf();
      }).forEach((event: any) => {
        event['action_date'] = moment(event['eventTime']).format('MMM DD, YYYY');
        if(!this.auditList.hasOwnProperty(event['action_date'])) {
          this.auditList[event['action_date']] = [];
        }
        event['action_time'] = moment(event['eventTime']).format('hh:mm A');
        this.auditList[event['action_date']].push(event);
      })
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    this.fetching = false;
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel("audit-table", "swayam-audit-history", "csv");
  }
}
