import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

@Component({
  styleUrls: [
    "./createscan.amis.component.css",
    "./../../warquiz.component.css",
  ],
  selector: "create-profile",
  templateUrl: "./createscan.amis.component.html",
})
export class CreateProfileComponent implements OnInit, OnDestroy {
  @Output() hideModal = new EventEmitter<any>();
  @Input() accountId: string;
  @Input() regionId: string;
  @Input() action: string;
  @Input() profile: any;
  uid = localStorage.getItem("t");
  t = localStorage.getItem("t");
  ut = localStorage.getItem("ut");
  eId = localStorage.getItem("eId");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = "";
  description: string = "";
  loading: boolean = false;
  errors: any = {
    workloadName: {
      length: 100,
      error: false,
      message: "",
    },
    description: {
      length: 250,
      error: false,
      message: "",
    },
  };

  async ngOnInit(): Promise<any> {
    this.notifier.loading(true);
    if(this.action == 'updateProfile') {
      this.scan_name = this.profile.profileName;
      this.description = this.profile.profileDescription;
      this.questions = this.profile.profileQuestion.map((question: any) => {
        if(question['selectedChoiceIds'] && question['selectedChoiceIds'].length > 0) {
          if(question['maxSelectedChoices'] == 1) {
            question['checked'] = question['selectedChoiceIds'][0];
          } else {
            question['answered'] = question['selectedChoiceIds'].length;
            question['questionChoices'].forEach((option: any) => {
              if(question.selectedChoiceIds.includes(option['choiceId'])) {
                option['selected'] = true;
              }
            })
          }

        }
        return question;
      })
    } else {
      await this.loadQuestions();
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  getObjectKeys(obj: any): any {
    return Object.keys(obj);
  }

  validate() {
    let check = true;
    this.errors["workloadName"]["error"] = false;
    this.errors["description"]["error"] = false;
    if (
      !this.scan_name ||
      this.scan_name.trim().length == 0 ||
      this.scan_name.trim() == ""
    ) {
      this.errors["workloadName"]["error"] = true;
      this.errors["workloadName"]["message"] =
        "Profile Name is a mandatory field";
      check = false;
    }
    if (
      (this.scan_name.trim().length < 3 ||
        this.scan_name.trim().length > 100) &&
      this.errors["workloadName"]["error"] == false
    ) {
      this.errors["workloadName"]["error"] = true;
      this.errors["workloadName"]["message"] =
        "Profile Name should be atleast 3 characters long and atmost 100 characters long";
      check = false;
    }
    if (
      !this.description ||
      this.description.trim().length == 0 ||
      this.description.trim() == ""
    ) {
      this.errors["description"]["error"] = true;
      this.errors["description"]["message"] =
        "Description is a mandatory field";
      check = false;
    }
    if (
      (this.description.trim().length < 3 ||
        this.description.trim().length > 250) &&
      this.errors["description"]["error"] == false
    ) {
      this.errors["description"]["error"] = true;
      this.errors["description"]["message"] =
        "Description should be atleast 3 characters long and atmost 250 characters long";
      check = false;
    }
    return check;
  }

  async createJob() {
    if (!this.validate()) {
      return;
    }
    this.loading = true;
    let answers: any = {};

    this.questions.forEach((question: any) => {
      if (question["maxSelectedChoices"] == 1) {
        answers[question["questionId"]] = question["checked"] ? [question["checked"]] : [];
      } else {
        answers[question["questionId"]] = question["questionChoices"]
          .filter((option: any) => {
            return option['selected'];
          })
          .map((option: any) => {
            return option["choiceId"];
          });
      }
    });

    let data: any = {
      action: this.action,
      accountId: this.accountId,
      region_id: this.regionId,
      profileName: this.scan_name.trim(),
      profileDescription: this.description,
      profileArn: this.action == 'updateProfile' ? this.profile.profileARN : undefined,
      answers: answers,
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      this.notifier.alert("success", "", result.message, "success", 5000);
      this.no(true);
    } else {
      if(result.responseMessage.includes("ConflictException")){
        this.notifier.alert("Info","" ,"Duplicate workload Name", "info", 5000);

      }
      else{
        this.notifier.alert("Info", "", result.responseMessage, "info", 5000);
      }
    }
    this.loading = false;
  }

  selectOption(event: any, question: any) {
    question["answered"] =
      (question["answered"] || 0) + (event.target.checked ? 1 : -1);
  }

  selectRadioOption(event: any, question: any, optionId: string) {
    if(event.target.checked) {
      question["checked"] = optionId;
    }
  }

  questions: any[];
  async loadQuestions() {
    let data: any = {
      action: "listquestions",
      accountId: this.accountId,
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/profiles`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1" || result.statusCode == 1) {
      this.questions = result?.profileTemplate?.templateQuestions.map(
        (question: any) => {
          question["checked"] = undefined;
          return question;
        }
      );
    }
  }
}
