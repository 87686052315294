<div *ngIf="recommendations.length > 0" class="pink_margin">
  Moving from Windows to Linux can be a great choice for those looking for a more stable, secure, and customizable
  operating system. 
</div>
<div class="flex_gap10 margintop-container">
  <div>Potential Saving: </div>
  <div>
    <span class="green_text fontsize_em" [innerHTML]="getTotalSavings()"></span>
  </div>
</div>

<div class="card-body">
  <div class="overflow_auto2">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Instance ID</th>
          <th>Instance Name</th>
          <th>Instance Type</th>
          <th class="center">RAM</th>
          <th class="center">vCPU</th>
          <th>Current OS</th>
          <th>Recommended OS</th>
          <th class="center">Current Spend</th>
          <th class="center">New Spend</th>
          <th class="center">Savings</th>
        </tr>
      </thead>
      <tbody *ngIf="recommendations.length > 0">
        <tr *ngFor="let recommendation of recommendations | paginate
                      : {
                          itemsPerPage: pagination['perPage'],
                          currentPage: pagination['page'],
                          totalItems: recommendations.length
                        }; let i = index">
          <td>
            {{
            (pagination['perPage'] * (pagination['page'] - 1)) + i + 1 }}
          </td>
          <td>
            {{ recommendation['instanceid'] }}
          </td>
          <td>
            {{ recommendation['instancename'] }}
          </td>
          <td>
            {{ recommendation['instancetype'] }}
          </td>
          <td class="center">
            {{ recommendation['ram'] }}
          </td>
          <td class="center">
            {{ recommendation['vCpu'] }}
          </td>
          <td>
            {{ recommendation['platform'] }}
          </td>
          <td>
            {{ recommendation['recommendedOs'] }}
          </td>
          <td class="center">
            {{ recommendation['currentPrice'] }}
          </td>
          <td class="center">
            {{ recommendation['recommendedPrice'] }}
          </td>
          <td class="center">
            <span [ngClass]="{green_text: recommendation['potential_savings'] > 0, red_text: recommendation['potential_savings'] < 0}">
              {{ recommendation['potential_savings'].toFixed(2) }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="recommendations.length == 0 && jobDetails && jobDetails['acctstatus'] === 'Completed'">
        <tr>
          <td [attr.colspan]="11" class="center">
            No Recommendations Found. Click <span class="nav-link" (click)="scan.emit(true)">here</span> to scan
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!jobDetails && !fetching">
        <tr>
          <td [attr.colspan]="11" class="center">
            No Scans Found. Click <span class="nav-link" (click)="scan.emit(true)">here</span> to scan
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="fetching">
        <tr>
          <td [attr.colspan]="11">
            <div class="flex-center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
              <span *ngIf="jobDetails">
                {{ jobDetails['acctstatus'] }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="paginator">
    <div class="flex-start">
      <span class="no-wrap">Per Page</span>
      <select class="form-select" [(ngModel)]="pagination['perPage']" (change)="pagination['page'] = 1">
        <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
      </select>
    </div>
    <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
      (pageChange)="pagination['page'] = $event">
    </pagination-controls>
  </div>
</div>