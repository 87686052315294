import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
@Component({
  selector: 'app-view-sg',
  templateUrl: './view-sg.component.html',
  styleUrls: ['./view-sg.component.css']
})
export class ViewSGComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() SGList :any=[]

  constructor(public notifier: NotifierService) { }

  ngOnInit(): void {
    this.notifier.disableScroll();
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }
  ngOnDestroy(): void {
    this.notifier.enableScroll();
}
}
