<div class="getting-started-popup">
  <div class="popup-container">
    <div class="popup-haeder">
      Getting Started
    </div>
    <div class="popup-body">
      <div>
        <h1>Welcome to Swayam <img style="height: 35px;" src="./assets/img/images.png" alt=""></h1>
        <p style="font-size: 1.1em;">Lets get started by importing your cloud accounts into Swayam</p>
        <hr>
      </div>
      <div class="cloud-partners">
        <p style="margin-bottom: 10px; font-weight: bold;">Choose your cloud provider</p>
        <div class="cloud-partners-container">
          <div class="cloud-partner-container" (click)="hideModel()">
            <div style="border-right: 2px solid #D8D8D8;">
              <img src="./assets/img/aws_logo.png" class="cloud-partner-logo" alt="">
            </div>
            <div style="padding: 10px;">
              <div style="font-weight: bold;">
                AWS
              </div>
              <div style="opacity: 0.5; font-weight: bold;">
                131 Clients in Swayam
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cloud-partners" style="margin-top: 20px;">
        <p style="margin-bottom: 10px; font-weight: bold;">Get Help</p>
        <div class="cloud-partners-container">
          <div class="cloud-partner-container">
            <div style="border-right: 2px solid #D8D8D8;">
              <img src="./assets/img/onboading_icon.png" class="cloud-partner-logo" alt="">
            </div>
            <div style="padding: 10px;">
              <div style="font-weight: bold;">
                Onboarding
              </div>
              <div style="opacity: 0.5; font-weight: bold;">
                Swayam Walkthrough
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>