import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

@Component({
  selector: "app-cloudwatch-cost-controls",
  templateUrl: "./cloudwatch-cost-controls.component.html",
  styleUrls: ["./cloudwatch-cost-controls.component.css"],
})
export class CloudwatchCostControlsComponent implements OnInit, OnDestroy {
  buckets: any = [];
  scanDetails: any = {
    status: "-",
    lastScan: "-",
  };

  recommendationsHeader: any = [
    {
      id: "reason",
      name: "Description",
    },
    {
      id: "Original Cost",
      name: "Original Cost",
    },
    {
      id: "Recommended Cost",
      name: "Recommended Cost",
    },
    {
      id: "Savings",
      name: "Savings",
    },
  ];

  selectedBucket: any = null;
  loading: boolean = true;
  pagination: any = {
    perPage: 10,
    page: 1,
  };

  pages: any = [10, 50, 100, 200];
  destroyed: boolean = false;

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  accountId: string = "";
  regionId: string = "";
  currentMessage: any;
  metadata: any = {
    DashboardCount: () => {
      return this.buckets.length > 0 ? this.buckets[0]["DashboardCount"] : 0;
    },
    DashBoardCost: () => {
      return this.buckets.length > 0 ? this.buckets[0]["DashBoardCost"] : 0;
    },
    StandardMetricsCount: () => {
      return this.buckets.length > 0
        ? this.buckets[0]["StandardMetricsCount"]
        : 0;
    },
    CustomMetricsCount: () => {
      return this.buckets.length > 0
        ? this.buckets[0]["CustomMetricsCount"]
        : 0;
    },
    MetricsCost: () => {
      return this.buckets.length > 0 ? this.buckets[0]["MetricsCost"] : 0;
    },
    TotalLogSize: () => {
      return this.buckets.length > 0 ? this.buckets[0]["TotalLogSize"] : 0;
    },
    TotalLogsCost: () => {
      return this.buckets.length > 0 ? this.buckets[0]["TotalLogsCost"] : 0;
    },
    AlarmCount: () => {
      return this.buckets.length > 0 ? this.buckets[0]["AlarmCount"] : 0;
    },
    AlarmCost: () => {
      return this.buckets.length > 0 ? this.buckets[0]["AlarmCost"] : 0;
    },
  };

  logGroups: any = [];

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.loadRecommendations();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.loadRecommendations();
      }
    });
  }

  selectedFilter: string = "All";
  filteredBuckets: any = [];

  filterRecoms() {
    if (this.selectedFilter == "All") {
      this.filteredBuckets = this.buckets;
    } else {
      this.filteredBuckets = this.buckets.filter((bucket: any) => {
        return bucket["LogGroup Name"]
          .toLowerCase()
          .includes(`/${this.selectedFilter.toLowerCase()}/`);
      });
    }
  }

  async loadRecommendations() {
    this.loading = true;
    this.buckets = [];
    this.logGroups = ["All"];
    this.selectedFilter = "All";
    let data = {
      a: "fetch",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/cloudwatch`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      data["accountId"] != this.accountId ||
      data["region"] != this.regionId ||
      this.destroyed
    ) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      if (result["data"] && result["data"].length > 0) {
        result["data"][0]["MetricsCost"] = Number(
          result["data"][0]["MetricsCost"]
        );
        result["data"][0]["AlarmCost"] = Number(result["data"][0]["AlarmCost"]);
        result["data"][0]["TotalLogsCost"] = Number(
          result["data"][0]["TotalLogsCost"]
        );
        result["data"][0]["TotalLogSize"] = Number(
          result["data"][0]["TotalLogSize"]
        );
        result["data"][0]["DashBoardCost"] = Number(
          result["data"][0]["DashBoardCost"]
        );
        this.buckets = result["data"];

        // this.buckets = result["data"].map((bucket: any, index: number) => {
        //   if (!bucket["LogGroup Name"].startsWith("/")) {
        //     bucket["LogGroup Name"] = `/${bucket["LogGroup Name"]}`;
        //   }
        //   let temp: string = bucket["LogGroup Name"]
        //     .split("/")
        //     .slice(1, -1)
        //     .join("/");
        //   let groupName: string = temp.replace("aws/", "");
        //   if (!this.logGroups.includes(groupName)) {
        //     this.logGroups.push(groupName);
        //   }
        //   bucket["MetricsCost"] = Number(bucket["MetricsCost"]);
        //   bucket["AlarmCost"] = Number(bucket["AlarmCost"]);
        //   bucket["TotalLogsCost"] = Number(bucket["TotalLogsCost"]);
        //   bucket["TotalLogSize"] = Number(bucket["TotalLogSize"]);
        //   if (bucket["Recommendations"]["Original Cost"]) {
        //     bucket["Recommendations"]["Original Cost"] = Number(
        //       bucket["Recommendations"]["Original Cost"]
        //     ).toFixed(5);
        //   }
        //   if (bucket["Recommendations"]["Recommended Cost"]) {
        //     bucket["Recommendations"]["Recommended Cost"] = Number(
        //       bucket["Recommendations"]["Recommended Cost"]
        //     ).toFixed(5);
        //   }
        //   if (bucket["Recommendations"]["Savings"]) {
        //     bucket["Recommendations"]["Savings"] = Number(
        //       bucket["Recommendations"]["Savings"]
        //     ).toFixed(5);
        //   }
        //   if (index == 0) {
        //   }
        //   return bucket;
        // });
        // Object.keys(this.metadata).forEach((key: string) => {
        //   this.metadata[key] = result["data"][0][key];
        // });
      }
      this.scanDetails["status"] = result["scanStatus"];
      this.scanDetails["lastScan"] = result["scanDate"]
        ? moment(result["scanDate"]).format("MMM DD, YYYY hh:mm A")
        : "-";
      if (this.scanDetails["status"] != "Completed") {
        if (data.accountId == this.accountId && data.region == this.regionId) {
          setTimeout(() => {
            this.loadRecommendations();
          }, 10000);
        }
      } else {
        this.loading = false;
      }
    } else {
      this.scanDetails["status"] = "-";
      this.scanDetails["lastScan"] = "-";
      this.loading = false;
    }

    this.filterRecoms();
  }

  async createScan() {
    this.loading = true;
    this.buckets = [];
    let data = {
      a: "scan",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/cloudwatch`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      data["accountId"] != this.accountId ||
      data["region"] != this.regionId
    ) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert(
        "Success",
        "",
        "Scan created successfully",
        "success",
        5000
      );
      this.scanDetails["status"] = "Scanning";
      this.scanDetails["lastScan"] = moment().format("MMM DD, YYYY hh:mm A");
      setTimeout(() => {
        this.loadRecommendations();
      }, 10000);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
