import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import { data } from "jquery";
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit{
  show: boolean;
  ssb: any;
  asp: any;
  jobs: any = [];
  jobs_a:any = [];
  createJob: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  accountId: string;
  regionId: string;
  loading: any = {
    jobs: false,
    jobs_a: false,
  };
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;
  selectedJob: any;
  selectedJobID: any;
  selectedJobName: any;
  currentMessage: any;
  latestItem:any;
  latestItem_a:any;
  type: any ={
    name: "Advanced Security Protocol",
    slug: "security",
    api: "/apis/operations/costoptimization/security",
    scan_required: true,
    extras: {
      view: "ssb",
    },
  };

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router,
    private notifier: NotifierService,
    private title: Title,
  ) {}

  ngOnInit(){
    this.title.setTitle("AWS Security Protocol");
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    // this.loadJobs(event);
    // this.loadJobs_a();
    this.latestItem = this.getlatestItem(this.jobs);
    this.latestItem_a = this.getlatestItem_a(this.jobs_a);


    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {

        this.accountId = d.value;
        let promises = [];
        // this.loadJobs(event);
        // this.loadJobs_a();
        // this.scoredis = 0;
        promises.push(this.loadJobs(event))
        promises.push(this.loadJobs_a())
        Promise.all(promises).then( (values)=>{
          this.loading["sec_all"] = true;
          this.latestItem = this.getlatestItem(this.jobs);
          this.latestItem_a = this.getlatestItem_a(this.jobs_a);  
          this.loading["sec_all"] = false;

        })

      } else if (d.key == "regionId") {
        
        this.regionId = d.value;
        let promises = [];
        // this.loadJobs(event);
        // this.loadJobs_a();
        // this.scoredis;
        promises.push(this.loadJobs(event))
        promises.push(this.loadJobs_a())
        Promise.all(promises).then( (values)=>{
          this.latestItem = this.getlatestItem(this.jobs);
          this.latestItem_a = this.getlatestItem_a(this.jobs_a);  
        })
      }
    });



}

scoredis:any;
// console.log("scores out method", this.scoredis);

getscore(){
  // console.log("latest Item", this.latestItem);
  if(this.latestItem != null){
    this.scoredis = (this.latestItem.passed /(this.latestItem.passed + this.latestItem.failed)) * 100;
    // console.log("scores in method",this.scoredis);
    return this.scoredis;  
  }
  else{
    return '';
  }
  // console.log("percentage",(this.latestItem.passed /(this.latestItem.passed + this.latestItem.failed)) * 100);
}


async loadJobs(event:any) {
  this.loading["jobs"] = true;
  this.page = 1;

  let data: any = {
    action: "list_jobs",
    account_id: this.accountId,
    region_id: this.regionId,
  };

  let header: any = {
    Authorization: localStorage.getItem("t"),
  };

  let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/security/ssb`;

  let result: any = await this.apiService.postDataPromis(
    apiURL,
    data,
    header
  );
// this.sec.loadJobs(this.accountId, this.regionId).subscribe((result: { status: string; s: string; jobs: any[]; }) => {
//   console.log("after service being called and the result is ", result);
if (result.status == "1" || result.s == "1") {
  result.jobs = result.jobs.sort((a: any, b: any) => {
    let date_a: any = new Date(a["cr_time"]);
    let date_b: any = new Date(b["cr_time"]);
    return Date.parse(date_b) - Date.parse(date_a);
  });
  this.jobs = result.jobs;
  let temp: any;
  this.jobs.forEach((job: any) => {
    job["cr_time"] = moment(job["cr_time"]).format("MMM DD, YYYY hh:mm A");
    job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
    if (this.selectedJobID && job.job_id == this.selectedJobID) {
      temp = job;
    }
    if (this.selectedJobName && job.name == this.selectedJobName) {
      temp = job;
    }
    if (!job["name"]) {
      job["name"] = job["job_id"];
    }
  });

  if (temp) {
    this.selectedJob = temp;
  }
  //  else {
  //   this.navigator.navigate(["/dash/compliance/ssb"]);
  // }
} else {
  this.jobs = [];
}
// })
 

  this.loading["jobs"] = false;
}
getlatestItem(jobs:any[]):any{
  if(jobs.length === 0){
    return null;
  }
  return jobs.reduce((prevItem, currentItem) =>{
    const prevTime = new Date(prevItem.cr_time);
    const currentTime = new Date(currentItem.cm_time);
    return currentTime > prevTime ? currentItem : prevItem;
  })
}
getlatestItem_a(jobs:any[]):any{
  if(jobs.length === 0){
    return null;
  }
  return jobs.reduce((prevItem, currentItem) =>{
    const prevTime = new Date(prevItem.cr_time);
    const currentTime = new Date(currentItem.cm_time);
    return currentTime > prevTime ? currentItem : prevItem;
  })
}

getObjectKeys(obj: any):string[]{
  return Object.keys(obj);
}

// async loadJobs(event:any) {

//   this.sec.loadJobs(this.accountId, this.regionId).subscribe((result: { status: string; s: string; jobs: any[]; }) => {
//     console.log("after service being called and the result is ", result);
//     if (result.status == "1" || result.s == "1") {
//       result.jobs = result.jobs.sort((a: any, b: any) => {
//         let date_a: any = new Date(a["cr_time"]);
//         let date_b: any = new Date(b["cr_time"]);
//         return Date.parse(date_b) - Date.parse(date_a);
//       });
//       this.jobs = result.jobs;
//       let temp: any;
//       this.jobs.forEach((job: any) => {
//         job["cr_time"] = moment(job["cr_time"]).format("MMM DD, YYYY hh:mm A");
//         job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
//         if (this.selectedJobID && job.job_id == this.selectedJobID) {
//           temp = job;
//         }
//         if (this.selectedJobName && job.name == this.selectedJobName) {
//           temp = job;
//         }
//         if (!job["name"]) {
//           job["name"] = job["job_id"];
//         }
//       });
  
//       if (temp) {
//         this.selectedJob = temp;
//       } 
//       // else {
//       //   this.navigator.navigate(["/dash/compliance/ssb"]);
//       // }
//     } else {
//       this.jobs = [];
//     }
//   })
     
  
//       this.loading["jobs"] = false;
//     }

    
//   async loadJobsasp(event:any){
//     this.show = true;
//   this.sec.loadJob_asp(this.accountId, this.regionId, this.show, this.type).subscribe((result: { status: string; s: string; jobs: any[]; }) => {
//     if (result.status == "1" || result.s == "1") {
//       if(result.jobs.length == 0 && this.type.scan_required == false) {
//         const job: boolean = await this.createJobCaller();
//         if(job) {
//           setTimeout(() => {
//             this.loadJobs(null, false);
//           }, 5000);
//         }
//         return;
//       }
//       result.jobs = result.jobs.sort((a: any, b: any) => {
//         let date_a: any = new Date(a["cr_time"]);
//         let date_b: any = new Date(b["cr_time"]);
//         return Date.parse(date_b) - Date.parse(date_a);
//       });
//       this.jobs = result.jobs.map((job: any) => {
//         if (job["status"] == "Completed") {
//           job["perc"] = ((job["passed"] / job["total_checks"]) * 100).toFixed(
//             2
//           );
//         } else {
//           job["perc"] = "0.0";
//         }
//         return job;
//       });
//       let temp: any;
//       let check: boolean = false;
//       this.jobs.forEach((job: any) => {
//         job["cr_time"] = moment(job["cr_time"]).format("MMM DD, YYYY hh:mm A");
//         job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
//         if (job["status"] == "In progress") {
//           check = true;
//         }
//         if (this.selectedJobID && job.job_id == this.selectedJobID) {
//           temp = job;
//         }
//         if (!job["name"]) {
//           job["name"] = job["job_id"];
//         }
//       });

//       if (check) {
//         setTimeout(() => {
//           this.loadJobs(null, false);
//         }, 10000);
//       }

//       if (show) {
//         if (temp) {
//           this.selectedJob = temp;
//         } else if (this.type.scan_required == true) {
//           this.navigator.navigate(
//             ["/dash/security/advanced-security-protocol"],
//             {
//               fragment: this.type.slug,
//             }
//           );
//         } else if (this.type.scan_required == false) {
//           this.selectedJob = this.jobs[0];
//           this.navigator.navigate(
//             ["/dash/security/advanced-security-protocol"],
//             {
//               queryParams: { job_id: this.selectedJob.job_id },
//               fragment: this.type.slug,
//             }
//           );
//         }
//       } else if (this.type.scan_required == false) {
//         this.selectedJob = this.jobs[0];
//         this.navigator.navigate(["/dash/security/advanced-security-protocol"], {
//           queryParams: { job_id: this.selectedJob.job_id },
//           fragment: this.type.slug,
//         });
//       }
//     } else {
//       this.jobs = [];
//     }

//     if (this.type.scan_required == false) {
//       this.notifier.loading(false);
//     }

//     this.loading["jobs"] = false;
//   });
// }

async loadJobs_a(job_id?: string, show: boolean = true) {
  if (show) {
    this.loading["jobs_a"] = true;
    this.page = 1;
  }

  if (this.type.scan_required == false) {
    this.notifier.loading(true);
  }

  let data: any = {
    action: "list_jobs",
    account_id: this.accountId,
    region_id: this.regionId,
    ...this.type?.other?.payload,
  };

  let header: any = {
    Authorization: localStorage.getItem("t"),
  };

  let apiURL: string = `${environment.apiURL}/${this.urlPrefix}${this.type.api}`;

  let result: any = await this.apiService.postDataPromis(
    apiURL,
    data,
    header
  );

  if (result.status == "1" || result.s == "1") {
    if(result.jobs.length == 0 && this.type.scan_required == false) {
      const job: boolean = await this.createJobCaller();
      if(job) {
        setTimeout(() => {
          this.loadJobs_a(null, false);
        }, 5000);
      }
      return;
    }
    result.jobs = result.jobs.sort((a: any, b: any) => {
      let date_a: any = new Date(a["cr_time"]);
      let date_b: any = new Date(b["cr_time"]);
      return Date.parse(date_b) - Date.parse(date_a);
    });
    this.jobs_a = result.jobs.map((job: any) => {
      if (job["status"] == "Completed") {
        job["perc"] = ((job["passed"] / job["total_checks"]) * 100).toFixed(
          2
        );
      } else {
        job["perc"] = "0.0";
      }
      return job;
    });
    let temp: any;
    let check: boolean = false;
    this.jobs_a.forEach((job: any) => {
      job["cr_time"] = moment(job["cr_time"]).format("MMM DD, YYYY hh:mm A");
      job["cm_time"] = moment(job["cm_time"]).format("MMM DD, YYYY hh:mm A");
      if (job["status"] == "In progress") {
        check = true;
      }
      if (this.selectedJobID && job.job_id == this.selectedJobID) {
        temp = job;
      }
      if (!job["name"]) {
        job["name"] = job["job_id"];
      }
    });

    if (check) {
      setTimeout(() => {
        this.loadJobs_a(null, false);
      }, 10000);
    }

    if (show) {
      if (temp) {
        this.selectedJob = temp;
      } else if (this.type.scan_required == true) {
        // this.navigator.navigate(
        //   ["/dash/security/advanced-security-protocol"],
        //   {
        //     fragment: this.type.slug,
        //   }
        // );
      } else if (this.type.scan_required == false) {
        this.selectedJob = this.jobs_a[0];
        // this.navigator.navigate(
        //   ["/dash/security/advanced-security-protocol"],
        //   {
        //     queryParams: { job_id: this.selectedJob.job_id },
        //     fragment: this.type.slug,
        //   }
        // );
      }
    } 
    else if (this.type.scan_required == false) {
      this.selectedJob = this.jobs_a[0];
      // this.navigator.navigate(["/dash/security/advanced-security-protocol"], {
      //   queryParams: { job_id: this.selectedJob.job_id },
      //   fragment: this.type.slug,
      // });
    }
  } else {
    this.jobs_a = [];
  }

  if (this.type.scan_required == false) {
    this.notifier.loading(false);
  }

  this.loading["jobs_a"] = false;
}

async createJobCaller(): Promise<boolean> {
  return new Promise(async (resolve: any, reject: any) => {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "create_job",
      account_id: this.accountId,
      region_id: this.regionId,
      name: `SCAN${Math.floor(Math.random()*10000)}`,
      ...this.type?.other?.payload
    };

    let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}${this.type.api}`;

    let result = await this.apiService.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      resolve(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
      resolve(false);
    }   
  })
}

}
