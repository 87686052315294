import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "tell-me-photo-viewer",
  templateUrl: "./photo-viewer.component.html",
  styleUrls: ["./photo-viewer.component.css"],
})
export class PhotoViewerComponent implements OnInit {
  @Input("url") url: string;
  @Output("hideModel") hideModel: any = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
