<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">
      Disassociate Profile
    </div>
    <div class="KBBody">
      Are you sure you want to disassociate profile <strong>{{ workload['profile']['profileName'] }}</strong> from the workload <strong>{{ workload['name'] }}</strong>?
    </div>
    <div class="KBActions">
      <button class="btn btn-danger-soft" *ngIf="!loading" (click)="disassociateProfile()">
        Disassociate
      </button>
      <button class="btn btn-danger-soft" *ngIf="loading" [attr.disabled]="true">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="width-mar-18"
        />
        Disassociate
      </button>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>
