<div class="amis_body">
  <div class="amis_more">
    <div>
      <div class="mb-3">
        <label for="" class="tags_05">Tags</label>
        <div class="index_06" *ngFor="let tag of tags; let i = index">
          <div>
            <div>
              <label for="">
                Key
              </label>
            </div>
            <input autocomplete="off" type="text" name="" id="" placeholder="Key" [(ngModel)]="tag.Key">
          </div>
          <div>
            <div>
              <label for="">
                Value
              </label>
            </div>
            <input autocomplete="off" type="text" name="" id="" placeholder="Value" [(ngModel)]="tag.Value">
          </div>
          <div class="link remove_24" (click)="removeTag(i)">
            <div>
              <i class="fa fa-times" aria-hidden="true"></i>
              <span class="remove_27">Remove</span>
            </div>
          </div>
        </div>
        <div class="link add_tags_32" (click)="addTag()">
          <i class="fa fa-plus" aria-hidden="true"></i>
          <span>Add</span>
        </div>
        <div class="error-text" *ngIf="instance.errors && instance.errors.tags">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.tags}}
        </div>
      </div>
      <div class="mb-3">
        <label for="" class="key_40">Key Pair</label>
        <launch-select-dropdown [options]="keyPairs" (update)="updateReports($event)" key="key_name"
          [selected]="instance.key_name" title="Key Pair"></launch-select-dropdown>
        <div class="error-text" *ngIf="instance.errors && instance.errors.key_name && !instance.key_name">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.key_name}}
        </div>
      </div>
    </div>
  </div>
</div>