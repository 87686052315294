import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';
declare let window: any;
declare let flatpickr: any;
declare let hljs: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.css'],
})
export class inspectorComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  inspectorList: any = [];
  dateRange: string;
  nextToken: any;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  headers: any = [
    {
      id: 'createdAt',
      name: 'Created At',
    },
    { id: 'severity', name: 'Severity' },
    { id: 'title', name: 'Finding' },
    {
      id: 'recommendation',
      name: 'Recommendation',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    setTimeout(() => {
      let start_date = moment().startOf('month').format('DD-MMM-YYYY');
      let end_date = moment().startOf('day').format('DD-MMM-YYYY');

      this.dateRange = start_date + ' to ' + end_date;
      flatpickr('.datetimepicker', {
        dateFormat: 'd-M-Y',
        mode: 'range',
        maxDate: new Date(),
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes('to')) return;
          this.dateRange = dateStr;
          this.load();
        },
      });
    }, 500);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load(next_token: string = '') {
    if (next_token == '' && !this.nextToken) {
      this.inspectorList = [];
    }
    this.notifier.loading(true);
    let start_date;
    let end_date;
    if (!(this.dateRange && this.dateRange.includes('to'))) {
      start_date = moment().startOf('month').format('YYYY-MM-DD');
      end_date = moment().startOf('day').format('YYYY-MM-DD');
    } else {
      let dRange = this.dateRange.toString().split(' to ');
      start_date = moment(dRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD');
      end_date = moment(dRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD');
    }
    let data = {
      action: 'inspector_findings',
      region_name: this.regionId,
      account_id: this.accountId,
      start_time: start_date,
      end_time: end_date,
      next_token: this.nextToken || next_token,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'Compliance.Inspector',
      'Inspector-Finding Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      result.findings = result.findings.map((finding: any) => {
        finding.expanded = false;
        return finding;
      });
      this.inspectorList = [...this.inspectorList, ...result.findings];
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
    if (result.next_token && result.next_token != '') {
      this.nextToken = result.next_token;
    } else {
      this.nextToken = null;
    }

    this.notifier.loading(false);
  }

  expandCollapse(value: any) {
    value.expanded = !value.expanded;
  }

  parseJson(value: any) {
    var hlt = hljs.highlight('json', JSON.stringify(value, null, 4));
    return hlt.value;
  }

  callFunction(name: any, param: any) {
    return;
  }
}
