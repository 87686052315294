<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <a [routerLink]="'/dash/reports/home'" class="default-link" *ngIf="showHeader">
        <button class="btn btn-light flex-start">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>Reports</span>
        </button>
      </a>
      <h5 class="mb-0">RI Report</h5>
    </div>
    <div
      class="flex-start"
      [ngStyle]="{ visibility: !loading ? 'visible' : 'hidden' }"
    >
      <button class="btn btn-primary-soft flex-start" (click)="load(true)">
        <i class="fa fa-refresh"></i>
        Refresh
      </button>
      <button class="btn btn-success-soft flex-start" (click)="export()">
        <i class="fa fa-download"></i>
        Export
      </button>
      <div>
        <input
          autocomplete="off"
          class="datetimepicker form-control"
          type="text"
        />
      </div>
    </div>
    <div *ngIf="loading">
      <img
        src="../assets/img/loading_2.svg"
        alt="loading scans"
        class="wid-mar-10"
      />
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Name</th>
          <th>Instance ID</th>
          <th>Instance Type</th>
          <th>Billing Type</th>
          <th>Final Cost</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let instance of inventory; let i = index">
          <tr
            (click)="instance['selected'] = !instance['selected']"
            [ngClass]="{ selected: instance['selected'] }"
          >
            <td>{{ i + 1 }}</td>
            <td>{{ instance["instanceName"] }}</td>
            <td>{{ instance["instanceID"] }}</td>
            <td>{{ instance["InstanceType"] }}</td>
            <td>{{ instance["billingType"] }}</td>
            <td>${{ instance["finalTotalCost"] || "-" }}</td>
            <td>
              <i class="fa fa-chevron-down"></i>
            </td>
          </tr>
          <tr [ngClass]="{ selected: instance['selected'] }">
            <td class="more-details-td" colspan="7">
              <div
                class="hide-container"
                [ngClass]="{ selected: instance['selected'] }"
              >
                <div class="expandable">
                  <div class="grid-3">
                    <div>
                      <div class="text-bold">Name</div>
                      <div>{{ instance["instanceName"] }}</div>
                    </div>
                    <div>
                      <div class="text-bold">Instance ID</div>
                      <div>{{ instance["instanceID"] }}</div>
                    </div>
                    <div>
                      <div class="text-bold">Instance Type</div>
                      <div>{{ instance["InstanceType"] }}</div>
                    </div>
                    <div>
                      <div class="text-bold">OS</div>
                      <div>{{ instance["os"] }}</div>
                    </div>
                    <div>
                      <div class="text-bold">CPU</div>
                      <div>{{ instance["cpu"] }}</div>
                    </div>
                    <div>
                      <div class="text-bold">RAM</div>
                      <div>{{ instance["ram"] }}</div>
                    </div>
                  </div>
                  <hr />
                  <div class="grid-3">
                    <!-- <div>
                      <div class="text-bold">Savings Plan Info</div>
                      <div>{{ instance["SavingsPlanInfo"] }}</div>
                    </div> -->
                    <div>
                      <div class="text-bold">Billing Type</div>
                      <div>{{ instance["billingType"] || "-" }}</div>
                    </div>
                    <div>
                      <div class="text-bold">RI Applied</div>
                      <div>{{ instance["riApplied"] || "-" }}</div>
                    </div>
                    <div>
                      <div class="text-bold">AWS Cost</div>
                      <div>{{ instance["awsCost"] || "-" }}</div>
                    </div>
                    <div>
                      <div class="text-bold">RI Rate</div>
                      <div>{{ instance["riRate"] || "-" }}</div>
                    </div>
                    <div>
                      <div class="text-bold">Usage Hours</div>
                      <div>{{ instance["hours"] || "-" }}</div>
                    </div>
                    <div>
                      <div class="text-bold">RI Final Cost</div>
                      <div>{{ instance["riFinalCost"] || "-" }}</div>
                    </div>
                    <div>
                      <div class="text-bold">AWS Total Cost</div>
                      <div>{{ instance["awsTotalCost"] || "-" }}</div>
                    </div>
                    <div>
                      <div class="text-bold">Final Total Cost</div>
                      <div>{{ instance["finalTotalCost"] || "-" }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="loading">
          <td colspan="7" class="center">
            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              class="wid-mar-10"
            />
          </td>
        </tr>
        <tr *ngIf="!loading && inventory.length == 0">
          <td colspan="7">
            <div class="center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
