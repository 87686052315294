<div class="card" [ngStyle]="{display: !selectedJob && type.scan_required ? 'block' : 'none'}">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" *ngIf=" type.slug == 'security'" routerLink="/dash/security/aws-security-protocol" fragment="security"><i class="fas fa-angle-left"></i> Back</button>
    <h5>
      {{ type.name }}
    </h5>
    </div>
    <div class="flex-right">
      <a
        routerLink="/dash/billing/billing-daily"
        target="_blank"
        *ngIf="type?.extras?.daily_cost_variance_report_link"
        class="btn btn-primary-soft flex-start"
      >
        Daily Variance Report
        <i class="fa fa-external-link"></i>
      </a>
      <button class="btn btn-primary-soft flex-start" (click)="load()">
        <i class="fa fa-refresh"></i>
        Refresh
      </button>
      <button
        class="btn btn-success-soft flex-start"
        (click)="createJob = true"
        *ngIf="jobs.length > 0 "
      >
        <i class="fa fa-search"></i>
        Scan
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="grid-2">
      <div class="card" *ngIf="type?.extras?.last_1_month_billing">
        <div class="card-header">Last Month ({{ lastMonth }})</div>
        <div class="card-body">
          <div
            class="last_1_month_billing_graph graph_container"
            id="last_1_month_billing_graph"
          ></div>
        </div>
      </div>
      <div class="card" *ngIf="type?.extras?.last_6_months_billing">
        <div class="card-header">Last 6 Months</div>
        <div class="card-body">
          <div
            class="last_6_months_billing_graph graph_container"
            id="last_6_months_billing_graph"
          ></div>
        </div>
      </div>
    </div>
    <div class="flex-item-center">
      <span>Per Page</span>
      <select class="padding-color" [(ngModel)]="perPage">
        <option [ngValue]="count" *ngFor="let count of pages">
          {{ count }}
        </option>
      </select>
    </div>
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of jobsHeaders" [ngClass]="{ center: h.center }">
            {{ h.name }}
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody *ngIf="jobs.length > 0 && !loading['jobs']">
        <tr
          *ngFor="
            let job of jobs
              | paginate
                : {
                    itemsPerPage: perPage,
                    currentPage: page,
                    totalItems: jobs.length
                  };
            let i = index
          "
          class="cursor"
          (click)="job['status'] == 'Completed' && selectJob(job)"
        >
          <td>{{ (page - 1) * perPage + i + 1 }}</td>
          <td *ngFor="let h of jobsHeaders" [ngClass]="{ center: h.center }">
            <span
              class="nav-link"
              [ngClass]="{
                'nav-link': h['link'] && job['status'] == 'Completed'
              }"
            >
              {{ job[h.id] }}
            </span>
          </td>
          <td>
            <span
              class="btn btn-danger-soft"
              (click)="deleteJobCaller($event, job)"
              *ngIf="!job['deleting'] && job['status'] != 'In progress'"
            >
              <i class="fa fa-trash"></i>
            </span>

            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              class="wid-mar"
              *ngIf="job['deleting'] || job['status'] == 'In progress'"
            />
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading['jobs']">
        <tr>
          <td class="text-center" [attr.colspan]="jobsHeaders.length + 2">
            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              class="wid-mar"
            />
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!loading['jobs'] && jobs.length == 0">
        <tr>
          <td [attr.colspan]="jobsHeaders.length + 2">
            <div class="center">
              No Scans Found.
              <span class="nav-link" (click)="createJob = true"
                >Click here</span
              >
              to create one
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paginator" *ngIf="jobs.length > 0">
      <pagination-controls
        class="pagination"
        previousLabel="Prev"
        nextLabel="Next"
        (pageChange)="page = $event"
      >
      </pagination-controls>
    </div>
  </div>
</div>
<cost-optimization-ftr-check
  *ngIf="selectedJob"
  [type]="type"
  [loading]="loading"
  [job]="selectedJob"
  (loadJobs)="loadJobs($event)"
  (hideModel)="load($event)"
>
</cost-optimization-ftr-check>
<cost-optimization-create-job-ftr
  *ngIf="createJob"
  [type]="type"
  [accountId]="accountId"
  [regionId]="regionId"
  (hideModal)="hideModel($event)"
>
</cost-optimization-create-job-ftr>

<cost-optimization-delete-job
  *ngIf="deleteJob"
  [accountId]="accountId"
  [regionId]="regionId"
  [type]="type"
  [job]="deleteJob"
  (hideModel)="closeDeletePopup($event)"
></cost-optimization-delete-job>
