import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";

export function getReadableTimeDifference(StartTime: any, EndTime: any) {
  // let duration = this.getDiffDays(StartTime, EndTime);
  let durationObj = moment.duration(EndTime.diff(StartTime));
  // const Y = durationObj.years();
  // StartTime = StartTime.add(Y, 'years');
  // durationObj = moment.duration(EndTime.diff(StartTime));
  // const M = durationObj.months();
  // StartTime = StartTime.add(M, 'months');
  // durationObj = moment.duration(EndTime.diff(StartTime));
  // const W = durationObj.weeks();
  // StartTime = StartTime.add(W, 'weeks');
  // durationObj = moment.duration(EndTime.diff(StartTime));
  const D = durationObj.days();
  StartTime = StartTime.add(D, 'days');
  durationObj = moment.duration(EndTime.diff(StartTime));
  let H = durationObj.hours();
  StartTime = StartTime.add(H, 'hours');
  durationObj = moment.duration(EndTime.diff(StartTime));
  const m = durationObj.minutes();
  StartTime = StartTime.add(m, 'minutes');
  durationObj = moment.duration(EndTime.diff(StartTime));
  const s = durationObj.seconds();
  let result = "";
  // if (Y > 0) {
  //   result += `${Y}y `;
  // }
  // if (M > 0) {
  //   result += `${M}mo `;
  // }
  // if (W > 0) {
  //   result += `${W}w `;
  // }
  // if (D > 0) {
  //   result += `${D}d `;
  // }
  H = H + D * 24;
  if (H > 0) {
    result += `${H} ${H > 1 ? 'hours': 'hour'} `;
  }
  if (m > 0) {
    result += `${m} ${m > 1 ? 'minutes': 'minute'} `;
  }
  if (s > 0) {
    result += `${s} ${s > 1 ? 'seconds': 'second'} `;
  }

  return result.replace(/\s*$/, "");
}

@Component({
  selector: "ticket-timeline",
  templateUrl: "./ticket-timeline.component.html",
  styleUrls: ["./ticket-timeline.component.css"],
})
export class TicketTimelineComponent implements OnInit, OnChanges {
  @Input("status_history") status_history: any;
  @Input("resolved") resolved: boolean;
  @Input("ticket") ticket: any;
  @Input("comments") comments: any;
  @Output("hideModel") hideModel: any = new EventEmitter<void>();

  // timelineView: boolean = false;

  ngOnInit(): void {}
  comment_history: any = [];

  // getDiffDays(StartTime: any, EndTime: any) {
  //   const duration = moment.duration(EndTime.diff(StartTime));
  //   return duration.days();
  // }

  // getDurationObject(StartTime: any, EndTime: any) {
  //   return moment.duration(EndTime.diff(StartTime));
    
  // }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["comments"]) {
      // this.comment_history = JSON.parse(JSON.stringify(this.comments)).reverse().map(
      //   (comment: any, index: number) => {
      //     let dict: any = {
      //       status: '',
      //       time: comment['timeCreated'],
      //       diff: null,
      //       name: comment['submittedBy'],
      //     }
      //     if (
      //       index < JSON.parse(JSON.stringify(this.comments)).reverse().length - 1
      //       ) {
      //       const StartTime = moment(comment['timeCreated'], 'MMM DD, YYYY hh:mm A');
      //       const EndTime = moment(JSON.parse(JSON.stringify(this.comments)).reverse()[index + 1]['timeCreated'], 'MMM DD, YYYY hh:mm A');
      //       const Duration = moment.duration(EndTime.diff(StartTime));
      //       let diff =  this.getReadableTimeDifference(Duration) || '0s';
      //       if(this.comment_history.length > 0) {
      //         this.comment_history[this.comment_history.length - 1]['diff'] = diff;
      //       } else {
      //         dict['diff'] = diff;
      //       }
      //     }
      //     if(this.status_history.length == 1) {
      //       if(moment(dict['time'], 'MMM DD, YYYY hh:mm A').valueOf() == moment(this.status_history[0]['time'], 'MMM DD, YYYY hh:mm A').valueOf()) {
      //         dict['status'] = this.status_history[0]['status'];
      //       }
      //     } else {
      //       if(moment(dict['time'], 'MMM DD, YYYY hh:mm A').valueOf() > moment(this.status_history[this.status_history.length - 1]['time'], 'MMM DD, YYYY hh:mm A').valueOf()) {
      //         dict['status']  = this.status_history[this.status_history.length - 1]['status'];
      //       } else {
      //         let status_: any = this.status_history.findIndex((status: any, index: number) => {
      //           if(moment(dict['time'], 'MMM DD, YYYY hh:mm A').valueOf() == moment(status['time'], 'MMM DD, YYYY hh:mm A').valueOf()) {
      //             return true;
      //           }
      //           if(index > this.status_history.length - 1) {
      //             return true;
      //           }
      //           if(index != this.status_history.length - 1) {
      //             return moment(dict['time'], 'MMM DD, YYYY hh:mm A').valueOf() > moment(status['time'], 'MMM DD, YYYY hh:mm A').valueOf() && moment(dict['time'], 'MMM DD, YYYY hh:mm A').valueOf() < moment(this.status_history[index + 1]['time'], 'MMM DD, YYYY hh:mm A').valueOf();
      //           } else {
      //             return false;
      //           }
      //         })
      //         if(status_ > -1) {
      //           dict['status'] = this.status_history[status_]['status'];
      //         }
      //       }
      //     }

      //     if(!dict['status']) {
      //       dict['status'] = 'Created';
      //     }
      //     return dict;
      //   }
      // );

      // this.comment_history = this.comment_history.reverse()

      let temp: any = [
        ...this.status_history,
        ...JSON.parse(
          JSON.stringify(
            this.comments.map((comment: any) => {
              let temp_: any = {
                status: "",
                time: comment["timeCreated"],
                diff: null,
                name: comment["submittedBy"],
                source: "comment",
              };
              return temp_;
            })
          )
        ).reverse()
      ];

      temp = temp.sort((a: any, b: any) => {
        return (
          moment(a["time"], "MMM DD, YYYY hh:mm A").valueOf() -
          moment(b["time"], "MMM DD, YYYY hh:mm A").valueOf()
        );
      });

      
      temp.forEach((status: any, index: number) => {
        if (status["status"] == "") {
          for (let index_ = index; index_ >= 0; index_--) {
            const status_ = temp[index_];
            if (status_["status"] != "") {
              status["status"] = status_["status"];
              if(status_['source'] != 'comment') {
                status_["remove"] = true;
              }
              break;
            }
          }
        }
        if(status["status"] == "") {
          status["status"] = 'Created';
        }
      });


      temp = temp.filter((status: any) => {
        return !status["remove"];
      })
      let include_breached = false;
      temp = temp.map((status: any, index: number) => {
        if(index < temp.length - 1) {
          const StartTime = moment(
            status["time"],
            "MMM DD, YYYY hh:mm A"
          );
          const EndTime = moment(
            temp[index + 1][
              "time"
            ],
            "MMM DD, YYYY hh:mm A"
          );

          if(StartTime.valueOf() != EndTime.valueOf()) {
            let diff = getReadableTimeDifference(StartTime, EndTime) || "0s";
            status["diff"] = diff;
          } else {
            status['diff'] = '0s';
          }
        }
        return status;
      });

      if(!this.resolved) {
        temp[temp.length - 1]['line'] = 'dotted';
        temp.push({
          name: '',
          time: '',
          status: 'Resolved'
        })
      }

      this.comment_history = temp;

    }
  }
}
