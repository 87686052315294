<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Cost Anomaly Report Status</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
     
        <div class="form-container mt-2" *ngIf="!loading['fetch']">
        <div class="form-group">
            <label>Client ID</label>
            <input type="text" class="form-control width-350px mt-1" disabled [ngModel]="clientId">
          </div>
          <div class="d-flex">
            <label class="m-1">Status</label>
            <label class="switch">
              <input autocomplete="off" type="checkbox" [(ngModel)]="checkboxStatus" (change)="updateCheckboxStatus()">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="form-group mt-3" *ngIf="checkboxStatus">
            <label>Threshold</label>
            <div class="width-350px">
              <div class="additional_contacts">
                <div class="additional_contact width-auto flex-start">
                <input autocomplete="off" (keyup)="checkThreshold()" type="text" name="threshold" class="form-control" [(ngModel)]="threshold"/>
              </div>
              </div>
              <div class="error-text" *ngIf="isError">
               Threshold value should be between 5 to 100
              </div>
            </div>
            <label>Emails</label>
            <div class="width-350px">
              <div class="additional_contacts">
                <div class="additional_contact width-auto flex-start" *ngFor="
                                                    let contact of emails;
                                                    let i = index;
                                                    trackBy: trackByFn
                                                  ">
                  <input autocomplete="off" type="text" name="contact_value" class="form-control" *ngIf="emails.length - 3 < i"
                    (keyup)="checkForLast(contact, i)" [(ngModel)]="emails[i]" />
                  <input autocomplete="off" type="text" name="contact_value" class="form-control" *ngIf="emails.length - 3 >= i"
                    [(ngModel)]="emails[i]" />
                  <div>
                    <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add" *ngIf="emails.length == i + 1"
                      (click)="addAdditionalContact()"></i>
                  </div>
                  <div>
                    <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove" *ngIf="emails.length > 1"
                      (click)="deleteSelectedAdditionalContact(i)"></i>
                  </div>
                </div>
              </div>
              <div class="error-text" *ngIf="totalemails().length ==  0">
                At least one email id is required
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="checkboxStatus">
            <div class="padding-5px">
              <button [disabled]="isError || totalemails().length ==  0" type="button" class="btn btn-primary-soft flex-start" (click)="save()">
                <span [ngStyle]="{display: loading['save'] ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-16px" />
                </span>
                <span class="spn-clr">
                  {{ account ? 'Update' : 'Add' }}
                </span>
              </button>
            </div>
          </div>
      </div>
        
        <div class="flex-center" *ngIf="loading['fetch']">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="asset_style" />
        
    </div>
          
</div>

