<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">New Scan</div>
    <div class="KBBody">
      <div class="col-mb-6">
        <label>Account ID: </label>
        <span>{{accountId}}</span>
      </div>
      <div class="col-mb-6">
        <label>Region: </label>
        <span>{{regionId}}</span>
      </div>
      <div class="col-mb-6">
        <label>Compliance Type: </label>
        <span>{{findingName}}</span>
      </div>
      <div class="KBActions">
        <button class="btn btn-success" (click)="createScan()" *ngIf="!creating">Create</button>
        <img *ngIf="creating" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
        <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
      </div>
    </div>
  </div>
</div>