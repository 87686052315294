import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";
import * as echarts from "echarts";

@Component({
  selector: "app-workload-overview",
  templateUrl: "./workload-overview.component.html",
  styleUrls: [
    "./workload-overview.component.css",
    "./../../warquiz.component.css",
  ],
})
export class WorkloadOverviewComponent implements OnInit, OnChanges {
  @Input("loading") loading: any;
  @Input("lenses") lenses: any;
  @Input("pillers") pillers: any;
  @Input("profiles") profiles: any;
  @Input("questions") questions: any;
  @Input("milestones") milestones: any;
  @Input("workload") workload: any;
  @Input("isMilestone") isMilestone: boolean;
  @Input("milestone") milestoneIn: any;

  @Output("refresh") refresh: any = new EventEmitter<any>();
  @Output("selectLens") selectLens = new EventEmitter<string>();
  @Output("showQuestions") showQue = new EventEmitter<string>();
  @Output("saveMilestone") saveMilestone = new EventEmitter<boolean>();
  @Output("milestone") milestone: any = new EventEmitter<any>();

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  loaded: boolean = false;
  lensesData: any = {
    softwareasaservice: "SaaS Lens",
    wellarchitected: "AWS Well-Architected Framework",
    serverless: "Serverless Lens",
    foundationaltechnicalreview: "FTR Lens",
    "arn:aws:wellarchitected::aws:lens/sap": "SAP Lens",
  };
  colors: any = [
    "#556ec7",
    "#93cb77",
    "#edcb5e",
    "#73c0de",
    "#bc76c5",
    "#fe8353",
  ];
  accountId: string;
  regionId: string;
  currentMessage: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  filterQuestions(
    value: any = undefined,
    type: string = undefined,
    answered: boolean = false,
    piller?: any,
    lens?: any
  ) {
    return this.questions.filter((question: any) => {
      if (type) {
        if (answered) {
          if (type == "PillarId") {
            return (
              question[type] == value &&
              question["Risk"] != "UNANSWERED" &&
              question["lensId"] == lens
            );
          }

          return question[type] == value && question["Risk"] != "UNANSWERED";
        } else {
          if (type == "PillarId") {
            return question[type] == value && question["lensId"] == lens;
          }
          return question[type] == value;
        }
      } else {
        return question.PillarId == piller && question.lensId == lens;
      }
    });
  }
  customname: any = "Custom SAP Lens";
  csname: any;
  getLensData(lens: { lens: string }): any {
    this.csname = this.lensesData[lens.lens];

    return this.csname !== undefined ? this.csname : this.customname;
  }

  fetchTotalAnsweredQuestions() {
    return this.questions.filter((question: any) => {
      return question["Risk"] != "UNANSWERED";
    });
  }

  fetchQuestionsRisks(risk: string, lensId: string = null) {
    return this.questions.filter((question: any) => {
      if (lensId && question["lensId"] != lensId) {
        return false;
      }
      return question["Risk"] == risk.toUpperCase();
    });
  }

  ngOnInit(): void {
    // console.log("lenses details", this.lenses);
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        // this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        // this.load();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes["lenses"]) {
      this.lenses = this.lenses.map((lens: any, index: number) => {
        if (!Object.keys(this.lensesData).includes(lens)) {
          this.lensesData[lens] = "Custom SAP Lens";
        }
        // console.log(this.lensesData);
        return { lens: lens, color: this.colors[index] };
      });
    }
    if (changes["pillers"]) {
      this.pillers = this.pillers.map((piller: any, index: number) => {
        return { piller: piller, color: this.colors[index] };
      });
    }

    this.workload["lenses"].forEach((lens: any) => {
      if (!this.lensesData.hasOwnProperty(lens)) {
        this.lensesData[lens] = lens;
      }
    });

    if (changes["questions"] && !this.loading["questions"]) {
      setTimeout(() => {
        this.loaded = true;
        // this.loadGraph();
        this.lenses.forEach((lens: any) => {
          this.loadLensGraph(lens["lens"]);
        });
      }, 100);
    }
  }

  showQuestions(lens: string) {
    this.showQue.emit(lens);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.workload["account_id"];
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }

  hideProfileWindow(event: any) {
    this.associateProfile = false;
    this.disassociateProfile = false;
    if (event) {
      this.refresh.emit(true);
    }
  }

  async downloadReport(lens: any, format: string = "pdf") {
    lens["download_" + format] = true;
    let data: any = {
      action: format == "pdf" ? "generate_report" : "generate_pp_report ",
      workload_id: this.workload["id"],
      id_token: localStorage.getItem("idT"),
      lens_id: lens["lens"],
      milestone_id: this.milestoneIn ? this.milestoneIn["id"] : undefined,
    };

    if (format == "pptx") {
      let account: any = await this.fetchSelectedAccountDetails();
      const { accountId, accountName } = account.data;
      data["account_id"] = this.workload["account_id"];
      data["region_id"] = this.workload["region_id"];
      data["client_name"] = `${accountName}(${accountId})`;
    }

    if (format == "xlsx") {
      data = {
        wId: this.workload["id"],
        lensId: lens["lens"],
      };
    }

    if (format == "offenders") {
      data = {
        action:"getPPT",
        accountId: this.accountId,
        regionId: this.regionId,
      };
    }
 

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    if (format == "xlsx") {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/war/downloadxlsx`;
    }
    if (format == "offenders") {
      apiURL = `${environment.apiURL}/admin/war/offenderxlsx`;
    }

    // https://dev.api.swayam.cloud/v3/client/war/operations
    // /v3/admin/war/offenderxlsx

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (
      result.s == "1" ||
      result.status == "1" ||
      result["statusCode"] == "1"
    ) {


      if(result.msg == 'Success' && format == "offenders"){
        let link: HTMLAnchorElement = document.createElement("a");
        // link.download = this.workload['name'] + '.' + format;
        link.target = "_blank";
        link.href = result.pre_signed_url || result.response;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
      }
      else if(format !== "offenders"){
        let link: HTMLAnchorElement = document.createElement("a");
        link.target = "_blank";
        link.href = result.pre_signed_url || result.response;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      else{
        this.notifier.alert("Info", "", result.msg, "info", 5000);

      }

    } else {
      const errorMessage: string =
        result.error_message || "Unable to download the report at this moment";
      this.notifier.alert("Info", "", errorMessage, "info", 5000);
    }
    
    lens["download_" + format] = undefined;
  }

  // loadGraph() {
  //   var chartDom = document.getElementById('ansgraph');
  //   var myChart = echarts.init(chartDom);

  //   // myChart.on('click', (params: any) => {
  //   //   this.filter['checks'] = params['name'];
  //   // });

  //   let option = {
  //     tooltip: {
  //       trigger: 'item',
  //     },
  //     legend: {
  //       orient: 'vertical',
  //       left: 'left',
  //     },
  //     series: [
  //       {
  //         name: 'Type',
  //         type: 'pie',
  //         radius: '50%',
  //         data: [
  //           {
  //             name: 'Total',
  //           },
  //           {
  //             name: 'Answered',
  //           },
  //         ],
  //         emphasis: {
  //           itemStyle: {
  //             shadowBlur: 10,
  //             shadowOffsetX: 0,
  //             shadowColor: 'rgba(0, 0, 0, 0.5)',
  //           },
  //         },
  //       },
  //     ],
  //   };

  //   option && myChart.setOption(option);
  // }

  loadLensGraph(lens: string) {
    var chartDom = document.getElementById(`${lens}_risksgraph`);
    var myChart = echarts.init(chartDom);

    // myChart.on('click', (params: any) => {
    //   this.filter['checks'] = params['name'];
    // });

    let option: any = {
      tooltip: {
        formatter: (params: any) => {
          return `
            <div class='flex-start'>
              <div>${params["name"]}</div>
              <div class='graph-text-bold'>${params["value"]}</div>
            </div>
          `;
        },
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Type",
          type: "pie",
          radius: "50%",
          startAngle: 0,
          label: {
            show: true,
            rotate: "tangential",
          },
          color: ["rgb(250, 200, 88)", "rgb(238, 102, 102)"],
          data: [
            {
              value: this.fetchQuestionsRisks("Medium", lens).length,
              name: "Medium",
            },
            {
              value: this.fetchQuestionsRisks("High", lens).length,
              name: "High",
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  associateProfile: boolean = false;
  disassociateProfile: boolean = false;

  copyURL(url: string) {
    if (url) {
      const textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
      this.notifier.alert("Success", "", "Copied", "success", 5000);
    }
  }
}
