<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active flex-start" id="cdn-tab" data-bs-toggle="tab" data-bs-target="#cdn" type="button"
      role="tab" aria-controls="cdn" aria-selected="true" (click)="fetchResource('cdn')">
      <span>
        Cloudfront
      </span>
      <span class="selected-count">{{ getSelectedCount('cdn') }} Selected</span>
    </button>
    <button class="nav-link flex-start" id="elb-tab" data-bs-toggle="tab" data-bs-target="#elb" type="button" role="tab"
      aria-controls="elb" aria-selected="true" (click)="fetchResource('elb')">
      <span>
        ELB
      </span>
      <span class="selected-count">{{ getSelectedCount('elb') }} Selected</span>
    </button>
    <button class="nav-link flex-start" id="ec2-tab" data-bs-toggle="tab" data-bs-target="#ec2" type="button" role="tab"
      aria-controls="ec2" aria-selected="true" (click)="fetchResource('ec2')">
      <span>
        EC2
      </span>
      <span class="selected-count">{{ getSelectedCount('ec2') }} Selected</span></button>
    <button class="nav-link flex-start" id="rds-tab" data-bs-toggle="tab" data-bs-target="#rds" type="button" role="tab"
      aria-controls="rds" aria-selected="true" (click)="fetchResource('rds')">
      <span>
        RDS
      </span>
      <span class="selected-count">{{ getSelectedCount('rds') }} Selected</span>
    </button>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="cdn" role="tabpanel" aria-labelledby="cdn-tab">
    <div class="resource-container" *ngIf="currentPage == 'cdn'">
      <table class="table table-docs">
        <thead>
          <tr>
            <th>ID</th>
            <th>Domain Name</th>
            <th>ARN</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resource of resources['cdn'] | paginate
                  : {
                      itemsPerPage: pagination['cdn']['perPage'],
                      currentPage: pagination['cdn']['page'],
                      totalItems: resources['cdn'].length
                    }; let i = index">
            <td>
              {{ resource['id'] }}
            </td>
            <td>
              {{ resource['domain'] }}
            </td>
            <td>
              {{ resource['arn'] }}
            </td>
            <td>
              {{ resource['status'] }}
            </td>
            <td>
              <label class="switch">
                <input autocomplete="off" type="checkbox" [(ngModel)]="resource.selected"
                  (change)="getProtocols(resource)">
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex-end" *ngIf="!loading['cdn']">
        <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
          (pageChange)="pagination['cdn']['page'] = $event">
        </pagination-controls>
      </div>
    </div>
    <div class="center" *ngIf="loading['cdn']">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
    </div>
  </div>
  <div class="tab-pane fade" id="elb" role="tabpanel" aria-labelledby="elb-tab">
    <div class="resource-container" *ngIf="currentPage == 'elb'">
      <table class="table table-docs">
        <thead>
          <tr>
            <th>Name</th>
            <th>DNS Name</th>
            <th>ARN</th>
            <th>Resources</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resource of resources['elb'] | paginate
                  : {
                      itemsPerPage: pagination['elb']['perPage'],
                      currentPage: pagination['elb']['page'],
                      totalItems: resources['elb'].length
                    }; let i = index">
            <td>
              {{ resource['name'] }}
            </td>
            <td>
              {{ resource['dns'] }}
            </td>
            <td>
              {{ resource['elb_arn'] }}
            </td>
            <td><button class="btn btn-light" (click)="loadELBTargets(resource)">Resources</button></td>
            <td>
              <label class="switch">
                <input autocomplete="off" type="checkbox" [(ngModel)]="resource.selected"
                  (change)="getProtocols(resource)">
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex-end" *ngIf="!loading['elb']">
        <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
          (pageChange)="pagination['elb']['page'] = $event">
        </pagination-controls>
      </div>
    </div>
    <div class="center" *ngIf="loading['elb']">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
    </div>
  </div>
  <div class="tab-pane fade" id="ec2" role="tabpanel" aria-labelledby="ec2-tab">
    <div class="resource-container" *ngIf="currentPage == 'ec2'">
      <table class="table table-docs">
        <thead>
          <tr>
            <th>Instance Id</th>
            <th>Instance Type</th>
            <th>AZ</th>
            <th>State</th>
            <th>Tags</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resource of resources['ec2'] | paginate
                  : {
                      itemsPerPage: pagination['ec2']['perPage'],
                      currentPage: pagination['ec2']['page'],
                      totalItems: resources['ec2'].length
                    }; let i = index">
            <td>
              {{ resource['instance_id'] }}
            </td>
            <td>
              {{ resource['instance_type'] }}
            </td>
            <td>
              {{ resource['az'] }}
            </td>
            <td>
              {{ resource['state'] }}
            </td>
            <td>
              {{ resource['tags']['Name'] || '-' }}
            </td>
            <td>
              <label class="switch">
                <input autocomplete="off" type="checkbox" [(ngModel)]="resource.selected"
                  (change)="getProtocols(resource)">
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex-end" *ngIf="!loading['ec2']">
        <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
          (pageChange)="pagination['ec2']['page'] = $event">
        </pagination-controls>
      </div>
    </div>
    <div class="center" *ngIf="loading['ec2']">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
    </div>
  </div>
  <div class="tab-pane fade" id="rds" role="tabpanel" aria-labelledby="rds-tab">
    <div class="resource-container" *ngIf="currentPage == 'rds'">
      <table class="table table-docs">
        <thead>
          <tr>
            <th>DB Identifier</th>
            <th>DB Type</th>
            <th>Engine</th>
            <th>AZ</th>
            <th>Status</th>
            <th>Multi AZ</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let resource of resources['rds'] | paginate
                  : {
                      itemsPerPage: pagination['rds']['perPage'],
                      currentPage: pagination['rds']['page'],
                      totalItems: resources['rds'].length
                    }; let i = index">
            <td>
              {{ resource['db_id'] }}
            </td>
            <td>
              {{ resource['db_class'] }}
            </td>
            <td>
              {{ resource['db_engine'] }}
            </td>
            <td>
              {{ resource['az'] }}
            </td>
            <td>
              {{ resource['db_status'] }}
            </td>
            <td>
              {{ resource['multi_az'] }}
            </td>
            <td>
              <label class="switch">
                <input autocomplete="off" type="checkbox" [(ngModel)]="resource.selected">
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex-end" *ngIf="!loading['rds']">
        <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
          (pageChange)="pagination['rds']['page'] = $event">
        </pagination-controls>
      </div>
    </div>
    <div class="center" *ngIf="loading['rds']">
      <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
    </div>
  </div>
</div>

<app-protocol-form-cams *ngIf="protocolResource" (hideModel)="catchProtocolPopup($event)"></app-protocol-form-cams>

<modal-popup *ngIf="show" [headers]="elbTargetsHeaders" [data]="show" [modalTitle]="'Attached Resources'"
  [showModal]="true" (hideModal)="show = null"></modal-popup>