import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-price-animation',
  templateUrl: './price-animation.component.html',
  styleUrls: ['./price-animation.component.css'],
})
export class PriceAnimationComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('maindoprdown') attachmentElement: ElementRef<any>;
  @Input('price') price: any;
  loaded: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  getPriceArray() {
    return this.price.toString().split('');
  }

  checkForNumber(value: any) {
    return isNaN(value) ? false : true;
  }

  generateArray() {
    return Array(10).fill(0);
  }

  checkAbsoluteValue(value: any) {
    let element: any =
      this.attachmentElement.nativeElement.querySelectorAll(
        '.absolute-parent'
      )[0];
    let refer: any = element.getBoundingClientRect().height;

    return `-${Number(value) * refer}px`;
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['price']) {
      this.loaded = false;
      setTimeout(() => {
        this.loaded = true;
      }, 10);
    }
  }
}
