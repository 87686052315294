<div class="confirmContainer">
    <div class="confirmBox">
      <div class="KBBody flex-1">
        <div class="col-mb-6 mb-2">
          <h6>ReScan </h6>
        </div>
        </div>

        <div class="KBActions">
          <button class="btn btn-success"(click)="yes()">Yes</button>
          <button class="btn btn-light" (click)="no()">No</button>
        </div>
      </div>
     
    </div>