<div class="alarm_screen alarm_screen_box">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 class="alarm_header_text">{{action == 'add' ? 'Invite' : 'Update'}} User</h4>
    </div>
    <div class="alarm_body alarm_padding" *ngIf="loaded"
      [ngStyle]="{display: !viewPolicy ? 'block': 'none', 'overflow-y': urlPrefix == 'client' ? 'auto': 'visible'}">
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Name</label>
        <input autocomplete="off" type="text" class="form-control" placeholder="Enter User Name" *ngIf="action == 'add'"
          [(ngModel)]="userDetails.clientName" name="username"
          [ngClass]="{'error-input': errors['clientName']['error']}" />
        <input autocomplete="off" type="text" class="form-control" [attr.disabled]="true" *ngIf="action == 'update'"
          [ngModel]="userDetails.clientName" name="username"
          [ngClass]="{'error-input': errors['clientName']['error']}" />
        <span class="error-msg" *ngIf="errors['clientName']['error']">
          {{errors['clientName']['msg']}}
        </span>
      </div>
      <div class="form-group mb-2" *ngIf="action == 'update'">
        <label for="exampleInputEmail1">Company Name</label>
        <input autocomplete="off" type="text" class="form-control" [attr.disabled]="true"
          [ngModel]="userDetails.clientCmpName" name="company" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Email ID</label>
        <input autocomplete="off" type="text" class="form-control" placeholder="Enter Email ID" *ngIf="action == 'add'"
          [(ngModel)]="userDetails.emaiId" name="emailid" [ngClass]="{'error-input': errors['emaiId']['error']}" />
        <input autocomplete="off" type="text" class="form-control" [attr.disabled]="true" *ngIf="action == 'update'"
          [ngClass]="{'error-input': errors['emaiId']['error']}" [ngModel]="userDetails.emaiId" name="emailid" />

        <span class="error-msg" *ngIf="errors['emaiId']['error']">
          {{errors['emaiId']['msg']}}</span>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Phone Number</label>
        <div class="flex-top" *ngIf="action == 'add'">
          <div class="relative" [attr.data-placeholder]="selectedCountryCode">
            <select placeholder="Country Code" class="form-select form-control width-auto relative"
              (change)="selectCode($event)">
              <option value="{{country.dial_code}}"
                [attr.selected]="selectedCountryCode == country.dial_code ? true : null"
                *ngFor="let country of countryCodesObj">{{ country.name }} ({{
                country.dial_code }})</option>
            </select>
          </div>
          <div class="flex-1">
            <input autocomplete="off" class="form-control" id="card-email" placeholder="Enter Phone Number"
              (keydown)="phoneNumberValidate($event)" name="mobileNumber" type="number" required minlength="4"
              maxlength="50" [(ngModel)]="userDetails.mobileNumber"
              [ngClass]="{'error-input': errors['mobileNumber']['error']}" />
            <span class="error-msg" *ngIf="errors['mobileNumber']['error']">
              {{errors['mobileNumber']['msg']}}</span>
          </div>
        </div>
        <div *ngIf="action == 'update'">
          <input autocomplete="off" type="text" class="form-control" [attr.disabled]="true"
            [ngModel]="userDetails.mobileNumber" name="company" />
        </div>
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'admin'? 'block' : 'none'}">
        <label for="exampleInputEmail1">Account Group</label>
        <select id="accountGroupLists" class="form-control" multiple data-live-search="true">
          <option *ngFor="let account of accountGroups" value="{{account.groupName}}">{{account.groupName}}</option>
        </select>
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'client' && !isUserAdmin ? 'block' : 'none'}">
        <label for="exampleInputEmail1">User Type</label>
        <div class="flex-start">
          <input autocomplete="off" type="radio" [(ngModel)]="userType" value="user" id="userUserType" name="userType">
          <label for="userUserType">User</label>
        </div>
        <div class="flex-start">
          <input autocomplete="off" type="radio" (change)="checkOfServices()" [(ngModel)]="userType" value="userAdmin"
            id="adminUserType" name="userType">
          <div>
            <label for="adminUserType">Admin</label>
            <div class="small-text">Provides Admin level privileges of {{ whitelabelData['pd']['appName'] }}, but can't create admins
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-2"
        [ngStyle]="{display: action == 'add' && userType == 'user' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Select Policy</label>
        <div class="flex-start">
          <input autocomplete="off" type="radio" (change)="checkOfServices()" [(ngModel)]="policyType" value="manual"
            id="manualPolicy" name="policyType">
          <label for="manualPolicy">Manual Policy</label>
        </div>
        <div class="flex-start">
          <input autocomplete="off" type="radio" [(ngModel)]="policyType" value="predefined" id="predefinedPolity"
            name="policyType">
          <label for="predefinedPolity">Policy Group</label>
        </div>
      </div>
      <div class="mb-2"
        [ngStyle]="{'display': policyType == 'predefined' && userType == 'user' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Select Policy</label>
        <div>
          <select id="policyList" [ngStyle]="{display: polices.length > 0 ? 'block': 'none'}" class="form-control"
            [(ngModel)]="userDetails.policyName" data-live-search="true">
            <option *ngFor="let policy of polices" value="{{policy['policyName']}}">{{policy['policyName']}}</option>
          </select>
          <span class="error-msg" *ngIf="errors['policy']['error']">
            {{errors['policy']['msg']}}</span>
          <div *ngIf="userDetails.policyName">
            <span class="nav-link" (click)="selectPolicy()">View Policy ({{ userDetails.policyName }})</span>
          </div>
          <div class="mb-2" [ngStyle]="{display: polices.length == 0 ? 'block': 'none'}">
            No Policies Created <a routerLink="/dash/settings/policies/view">Create Policy</a>
          </div>
        </div>
      </div>
      <div class="form-group mb-2"
        [ngStyle]="{display: urlPrefix == 'client' && userType == 'user' && policyType == 'manual' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">AWS Account List</label>
        <select id="instancesList" class="form-control" multiple data-live-search="true"
          (change)="fetchAccountGroups()">
          <option *ngFor="let account of accounts" value="{{account.accountId}}">{{account.accountId}}
            ({{account.accountName}})</option>
        </select>
        <span class="error-msg" *ngIf="errors['accountId']['error']">
          {{errors['accountId']['msg']}}</span>
      </div>
      <div class="mb-2"
        [ngStyle]="{'display': policyType == 'manual' && userType == 'user' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Select Service List</label>
        <div class="pt-2">
          <menu-selector [menus]="services" [selected]="userDetails['mlist']"></menu-selector>
        </div>
      </div>
      <div class="tags-container mb-2 form-group"
        [ngStyle]="{display: urlPrefix == 'client' && userType == 'user' && policyType == 'manual' ? 'block' : 'none'}">
        <div class="container-header flex-between">
          <label for="exampleInputEmail1">Tags</label>
          <div class="flex-start" (click)="userDetails.tagsEnabled = userDetails.tagsEnabled ? false : true">
            <input autocomplete="off" type="checkbox" class="pointer-none-class" name="" id=""
              [attr.checked]="!userDetails.tagsEnabled ? true : null">
            <span>
              All Tags
            </span>
          </div>
        </div>
        <div class="container-body" [ngStyle]="{display: userDetails.tagsEnabled ? 'block' : 'none'}">
          <div class="account-conntainers" *ngFor="let account of userDetails.accountDetailsList">
            <div class="account-header">
              {{account.accountId}}
            </div>
            <div class="account-body">
              <ng-container *ngIf="getObjectKeys(account['accountId']).length > 0">
                <div class="account-conntainers"
                  *ngFor="let region of getObjectKeys(tagKeyValuesObj[account['accountId']])">
                  <div class="account-header flex-between">
                    <div>
                      {{region}}
                    </div>
                    <div class="pointer" (click)="removeTagRegion(tagKeyValuesObj[account['accountId']], region)">
                      <i class="fa fa-times"></i>
                    </div>
                  </div>
                  <div class="account-body" *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length > 0">
                    <ng-container *ngFor="let key of getObjectKeys(tagKeyValuesObj[account['accountId']][region])">
                      <div class="flex-start">
                        <select class="single-select" data-live-search="true"
                          (change)="newKeySet(key, $event, account['accountId'], region)">
                          <option value="{{ key }}" selected>{{key}}</option>
                          <option *ngFor="let key_ of keysToSelect(account['accountId'], region)" [value]="key_">{{
                            key_
                            }}</option>
                        </select>
                        <div>
                          <ng-select [items]="tagsState[account['accountId']][region][key]" [addTag]="true"
                            [virtualScroll]="true" bindLabel="id" bindValue="id" [multiple]="true"
                            placeholder="Select Values"
                            [(ngModel)]="tagKeyValuesObj[account['accountId']][region][key]">
                          </ng-select>
                        </div>
                        <div class="flex-start">
                          <input autocomplete="off" type="checkbox" name="" id=""
                            (click)="selectAllValues($event, account['accountId'], region, key)">
                          <label for="" class="nowrap">Select All</label>
                        </div>
                        <button class="btn btn-light" (click)="removeKeySet(account['accountId'], region, key)">
                          <i class="fa fa-minus"></i>
                        </button>
                        <img src="../assets/img/loading_2.svg" alt="loading scans"
                          [ngStyle]="{display: loadingTags ? 'block' : 'none'}" class="loading_tags" />
                      </div>
                    </ng-container>
                    <div class="center" *ngIf="keysToSelect(account['accountId'], region).length > 0">
                      <button class=" btn btn-light" (click)="newKeySet(null, 'FETCH', account['accountId'], region)">
                        <i class="fa fa-plus"></i> Select Tag
                      </button>
                    </div>
                  </div>
                  <div class="account-body center"
                    *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length == 0 && !loadingTags">
                    No Tags Found
                  </div>
                  <div class="account-body center"
                    *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length == 0 && loadingTags">
                    Looking for Tags...
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div class="flex-start">
                  <select class="form-select"
                    (change)="addTagRegion(tagsState[account['accountId']], account['accountId'], $event)">
                    <option value="-">Select Region</option>
                    <option [value]="region.id" *ngFor="let region of getUnselectedRegion(account['accountId'])">{{
                      region.name }}</option>
                  </select>
                  <img src="../assets/img/loading_2.svg" alt="loading scans"
                    [ngStyle]="{display: loadingTags ? 'block' : 'none'}" class="tags_width" />
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2"
        [ngStyle]="{'display': policyType == 'manual' && userType == 'user' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Access Type</label>
        <div>
          <div class="flex-start">
            <input autocomplete="off" type="radio" [(ngModel)]="accessType" value="readOnly" id="policyAccessReadOnly"
              name="policyAccessType">
            <label for="policyAccessReadOnly">Read Only</label>
          </div>
          <div class="flex-start">
            <input autocomplete="off" type="radio" [(ngModel)]="accessType" value="readOnly(startstop)"
              id="policyAccessReadOnlyStartStop" name="policyAccessType">
            <label for="policyAccessReadOnlyStartStop">Read Only (Start/Stop)</label>
          </div>
          <div class="flex-start">
            <input autocomplete="off" type="radio" [(ngModel)]="accessType" value="readandwrite"
              id="policyAccessReadAndWrite" name="policyAccessType">
            <label for="policyAccessReadAndWrite">Read and Write</label>
          </div>
        </div>
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'client'? 'block' : 'none'}">
        <label for="exampleInputEmail1">Two Factor Authentication</label>
        <select name="" [(ngModel)]="twowayfactor" id="" class="form-select" (change)="checkTwoWayFactor()">
          <option [value]="true">Enable</option>
          <option [value]="false">Disable</option>
        </select>
      </div>
    </div>
    <div class="alarm_body" *ngIf="viewPolicy">
      <app-policy-view [policyDetails]="viewPolicy" (hideModel)="viewPolicy = null"></app-policy-view>

    </div>
    <div class="alarm_actions alarm_footer" *ngIf="!viewPolicy">
      <div class="alarm_div">
        <button class="btn alarm_btn" (click)="updateEntry()" [ngClass]="{'click-disabled': !writeAccess}"
          [attr.disabled]="!writeAccess ? true : null">
          {{action == 'add' ? 'Create' : 'Update'}}
        </button>
        <button class="btn btn-light" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div>