


<table class="table table-responsive table-hover">
    <thead>
        <tr>
            <th scope="col"><input *ngIf="isdailyspendRes" [checked]="dsAll" (click)="markAll()" type="checkbox" class="check" name="" id=""></th>
            
            <th scope="col">Event Time</th>
            <th scope="col">Criticality</th>
            <th scope="col">Description</th>
            <th scope="col">Identifier</th>
            <!-- <th scope="col">Triggered By</th> -->
           
           
        
        </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of getItemsForPage(currentPage)">
        <tr  *ngIf="item.status=='incomplete' && isdailyspendRes">
            <td><input type="checkbox" [checked]="item.check" (click)="updateStatus(item.id,item.type)" name="" id=""></td>
           
            
            <td>{{item.eventTime}}</td>
            <td *ngIf="item.severity"><span class="badge" [ngClass]="{ redg: item.severity==='High',orangeg: item.severity==='Medium',greeng: item.severity==='Low'}" >{{item.severity}}</span></td>
            <td>{{item.description}}</td>
            <td *ngIf="item.identifier !== ''">{{fixedDecimal(item.identifier)}}</td>
            <td *ngIf="item.identifier == ''">-</td>
            <!-- <td>{{item.createdBy}}</td> -->
            

            
        </tr>
      </ng-container>
      <tr *ngIf="!isdailyspendRes">
        <td colspan="9" class="text-center">No Daily Spend Alerts Found!</td>
      </tr>
    </tbody>
    </table>
    
    <!-- <div class="d-flex justify-content-end">
    <nav>
      <ul class="pagination">
    <li class="page-item" [class.disabled]="currentPage ===1">
    <a class="page-link" (click)="navigateToPreviousPage()">Previous</a>
    </li>
    <li class="page-item" *ngFor="let page of getPageNumbers()" [class.active]="currentPage === page">
      <a class="page-link" (click)="currentPage = page">{{page}}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="navigateToNextPage()">Next</a>
        </li>
      </ul>
    </nav>
    </div> -->

    <div class="d-flex justify-content-end">
      <nav>
        <ul class="pagination">
          <li class="page-item" [class.disabled]="currentPage === 1"> <a class="page-link"
              (click)="navigateToPreviousPage()">Previous</a> </li> <ng-container *ngFor="let page of getPageNumbers()">
            <li class="page-item"  *ngIf="page === '...' else notEllipsis" > <a
                class="page-link" (click)="navigateToPage(page)">{{ page }}</a> </li> <ng-template #notEllipsis>
              <li class="page-item" [class.active]="currentPage === page"> <a class="page-link"
                  (click)="navigateToPage(page)">{{ page }}</a> </li>
            </ng-template>
          </ng-container>
          <li class="page-item" [class.disabled]="currentPage === totalPages"> <a class="page-link"
              (click)="navigateToNextPage()">Next </a> </li>
        </ul>
      </nav>
    </div>
