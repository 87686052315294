<div class="card">
  <div class="card-header flex-between">
    <h5>
      Databases
    </h5>
    <div class="flex-start">
      <select
        [(ngModel)]="selectedDatabase"
        (change)="load()"
        class="form-select width-250px"
      >
        <option
          value="{{ database['id'] }}"
          *ngFor="let database of supportedDatabases"
        >
          {{ database["name"] }}
        </option>
      </select>
      <button class="btn btn-success-soft"  (click)="export()">Export</button>
    </div>
  </div>
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers[selectedDatabase]">{{ h.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vm of databases; let i = index">
            <td>{{ i + 1 }}</td>
            <td *ngFor="let h of headers[selectedDatabase]">{{ vm[h.id] }}</td>
          </tr>
          <tr *ngIf="loading">
            <td
              [attr.colspan]="headers[selectedDatabase].length + 1"
              class="center"
            >
              Looking for <strong>{{ getSelectedDatabase() }}</strong> in
              <strong>{{ resourceGroup }}</strong
              >...
            </td>
          </tr>
          <tr *ngIf="!loading && databases.length == 0">
            <td
              [attr.colspan]="headers[selectedDatabase].length + 1"
              class="center"
            >
              <span
                >No <strong>{{ getSelectedDatabase() }}</strong> found in
                <strong>{{ resourceGroup }}</strong> resource group. You can
                change the resource group on the top panel</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
