<div class="card mt-20 crd-bdy" >
  <h5 class="card-header">RDS RI Order Report</h5>
  <div class="card-body">
    <div class="fl-dpy">
      <button class="btn btn-light btn-styl" (click)="addPurchase()">Add
        Purchase Details</button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of riData; let j = index"
        [ngStyle]="{'display': riData.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || '-'" (click)="callFunction(h.click, i)">
            </span>

          </td>
          <td><span (click)="updateTrigger(i)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
          <td><span (click)="deleteTrigger(i)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': riData.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div class="txt-cntr">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="st-ptn"
  [ngStyle]="{'display': unsavedRI.length > 0 ? 'block': 'none'}">
  <div
    class="div-styll">
    <div class="div-ssty">
      <h4>Unsaved RI Order</h4>
      <div class="fl-dpy">
        <button class="btn btn-light btn-styl"  (click)="addPurchase()">Add
          Purchase Details</button>
      </div>
    </div>
    <div  (click)="unsavedRI = []"
      class="btn btn-light div-ptnn">
      X
    </div>
    <div class="div-fl">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers">{{ h.name }}</th>
            <th>Update</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody *ngFor="let i of unsavedRI; let j = index"
          [ngStyle]="{'display': riData.length > 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td>{{j + 1}}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
              <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || '-'"
                (click)="callFunction(h.click, i)">
              </span>

            </td>
            <td><span (click)="updateUnsavedRI(i, j)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
            <td><span (click)="deleteUnsavedRI(j)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': riData.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 3">
              <div class="txt-cntr">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="fl-pddd">
      <button class="btn btn-light btn-styl" (click)="saveDetails()">
        Save Details
      </button>
    </div>
  </div>
</div>

<rds-enableri-update *ngIf="updateRI" [action]="updateAction" [ri]="updateRI" (hideModel)="closeUpdateTrigger($event)">
</rds-enableri-update>