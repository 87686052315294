<div class="card" [ngStyle]="{display: selectedLinkedAccount ? 'none' : 'block'}">
  <div class="card-header flex-space-item">
    <h5>All Accounts</h5>
  </div>
  <div class="card-body">
    <div class="flex-end-gap">
      <ng-container *ngIf="customerType != 'mp'">
        <span class="font-bold">Master Accounts: </span>
        <span>
          <select class="form-control" [(ngModel)]="selectedMasterAccount" (change)="load(false)"
            id="masterAccountSelect" data-live-search="true">
            <option [attr.value]="account" *ngFor="let account of masterAccounts">
              {{ account }}
            </option>
          </select>
        </span>
      </ng-container>
      <img src="../assets/img/loading_2.svg" *ngIf="loading['master']" alt="loading scans" class="wid-margin" />
      <span class="font-bold">Month Year: </span>
      <input autocomplete="off" class="datetimepicker" id="datetimepicker" type="text" [(ngModel)]="dateRange" />
      <button class="btn btn-primary-soft" (click)="load(customerType !== 'mp' ? true : false)">Refresh</button>
      <button class="btn btn-success-soft" (click)="selectedReport = true">Consolidated EC2 Report</button>
      <button class="btn btn-success-soft" (click)="export()" *ngIf="appended.length > 0">Export</button>
    </div>
  </div>
</div>
<div class="card" *ngIf="!selectedLinkedAccount">
  <div class="card-body flow-auto">
    <table class="table table-docs table-hover" id="billing-dashboard">
      <thead>
        <tr>
          <th>S.N.</th>
          <th>Service Name</th>
          <th (click)="selectedLinkedAccount = h.id" title="View Monthly Bill Report" *ngFor="
              let h of headers
            ">
            <span>
              <span>
                {{ h.name }}
              </span>
              <br>
              <span *ngIf="totalBills[h.id]">
                ${{totalBills[h.id]}}
              </span>
              <span *ngIf="!totalBills[h.id]">
                -
              </span>

              <img src="../assets/img/loading_2.svg" *ngIf="loading[h.id] || loading['master']" alt="loading scans"
                class="wid-margin" />
            </span>
          </th>
          <th>Total for Services
            <br>
            <span>${{fetchFinalCost()}}</span>
          </th>
        </tr>
      </thead>

      <tbody *ngIf="appended.length > 0">
        <tr *ngFor="let i of appended; let j = index">
          <td>{{ j + 1 }}</td>
          <td>{{i}}</td>
          <td *ngFor="
              let h of headers
            ">
            <span>
              {{ (responseData[h.id] && responseData[h.id][i] && toFixed(responseData[h.id][i]['finalTotalBill'], 2)) ||
              '-' }}
            </span>
          </td>
          <td>
            {{toFixed(fetchTotalBillService(i), 2)}}
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="appended.length == 0">
        <tr>
          <td [attr.colSpan]="headers.length + 3" class="align-center">
            <span>No Data Found</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<billing-monthly *ngIf="selectedLinkedAccount" [mid]="selectedMasterAccount" [lid]="selectedLinkedAccount"
  [monthYear]="monthYear" (close)="close($event)"></billing-monthly>

<consolidated-ec2-report *ngIf="selectedReport" [defaultDate]="getCurrentMonth()"
  (hideModel)="selectedReport = false"></consolidated-ec2-report>