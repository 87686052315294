<div class="card">
  <h5 class="card-header">
    <span class="title txt-font">IAM Users</span>
    <button *ngIf="!fetching" class="btn btn-primary-soft btn-pstn" (click)="refresh()">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Refresh
    </button>
    <button class="btn btn-success-soft btn-pstn" *ngIf="!fetching && users.length > 0" (click)="downloadReport()"><i
        class="fa fa-download" aria-hidden="true"></i>
      Download</button>
  </h5>
  <!-- <div style="width: 100%; height: 100px; background-color: red;"></div> -->
  <div class="card-body">
    <div class="ovr-auto">
      <table class="table table-docs table-hover cost-table" id="ebs_cost_optimization">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of userHeader">
              {{ h.name }}
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, users)">
                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': users.length ? 'table-row-group': 'none'}" *ngFor="let i of users; let j = index">
          <tr *ngIf="notifier.filterTableColums(userHeader, i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of userHeader">
              <div *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" [innerHTML]="i[h.id]">
                </div>
              </div>
              <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click" [innerHTML]="i[h.id]">
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="users.length == 0 && !fetching"
          [ngStyle]="{'display': users.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="userHeader.length + 1">
              <div class="txt-cntr">No Users Found</div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="fetching">
          <tr>
            <td [attr.colspan]="userHeader.length + 1" class="txt-cntr">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="img-pstn">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>