import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let $: any;
declare let window: any;
@Component({
  templateUrl: "./tags.operations.component.html",
  styleUrls: ["./tags.operations.component.css"],
})
export class TagsOperationsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    localStorage.getItem("acT") == "readOnly(startstop)" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  instancesList: any = [];
  showTagsModal: any = null;
  selectedInstanceId: string = null;
  instance: any = null;
  tagList: any = [];
  selectedTag: any = null;
  valueList: any = [];
  selectedValue: any = null;
  command: string = null;
  modalTitle: any;
  destroyed: boolean = false;
  eId = localStorage.getItem('eId');

  headers: any = [
    {
      id: "tagName",
      name: "Tag Name",
      show: true,
      sort: { sort: true, direction: "Asc" },
      type: "String",
      filter: true,
      style: { width: 0 },
      click: "showData",
    },
    {
      id: "instanceType",
      name: "Instance Type",
      sort: { sort: true, direction: "Asc" },
      filter: true,
    },
    { id: "availabilityZone", name: "Availability Zone" },
    {
      id: "statusCheck",
      name: "Status Check",
      color: (value: string) => (value != "2/2" ? "red" : "green"),
    },
    {
      id: "ipdata",
      name: "Private IP / Public IP",
      sort: { sort: true, direction: "Asc" },
    },
    {
      id: "instanceStatus",
      name: "Instance Status",
      statusCheck: true,
    },
  ];

  tagsEnabled: any = "true";

  funRef: any;
  action: any;
  accountId: any;
  regionId: any;
  currentMessage: any;
  filterText = "";
  resourceGroup: string = localStorage.getItem('selectedResourceGroup');
  selectedCloudPartner: string = null;
  constructor(
    private apiServer: APIService,
    private route: ActivatedRoute, 
    public notifier: NotifierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      $("#tagListID").selectpicker();
      $("#valueListID").selectpicker();
    }, 100);

    this.funRef = {
      showData: this.showData,
    };

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    

    this.route.queryParams.subscribe((values: any) => {
      if(values['instance_id']) {
        this.selectedInstanceId = values['instance_id'];
      }
    })

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != 'notifier') {
        return;
      }

      this.hideMdoal(true);
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.loadInstances();
      }
    });
  }

  tagsListObj: any = null;
  async load() {
    this.loading = true;
    this.tagList = [];
    this.valueList = [];
    setTimeout(() => {
      $("#tagListID").selectpicker("refresh");
      $("#valueListID").selectpicker("refresh");
    }, 100);
    let tagList = localStorage.getItem("tagsList");
    this.tagsEnabled = localStorage.getItem("tagsEnabled");
    try {
      if (this.tagsEnabled == "true" && tagList && this.urlPrefix == "client") {
        this.tagsEnabled = "true";

        this.tagsListObj = JSON.parse(tagList);
        this.tagList = Object.keys(
          this.tagsListObj[this.accountId][this.regionId]
        );

        this.selectedTag = this.tagList[0];

        setTimeout(() => {
          $("#tagListID").selectpicker("refresh");
        }, 100);
        this.loadValues();
      } else {
        this.tagsEnabled = "false";

        this.tagsListObj = null;
        await this.loadTags();
      }
    } catch (error) {
      this.tagsEnabled = "false";
      await this.loadTags();
    }
  }

  checkForSwayamEMS() {
    return localStorage.getItem("emsConfig");
  }

  async loadInstances(nextToken: any = null) {
    if (!nextToken) {
      this.instancesList = [];
    }
    this.notifier.loading(true);
    this.loading = true;
    let data: any = {
      action: "testListInstancesBasedOnTagsWithPagination",
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
      tagKey: this.selectedTag,
      tagValue: this.selectedValue,
    };

    if (this.selectedTag == "All" && this.selectedCloudPartner == "aws") {
      data = {
        action: "testListInstancesWithPagination",
        accountId: this.accountId,
        region: this.regionId,
        nextToken: nextToken,
      };
    }

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Tags", "EC2 Instances Fetched");
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    if (this.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/central`;
      data = {
        action: "list_vms",
        user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      let instances = result.instanceDetails || result.virtual_machines;
      this.instancesList = [...this.instancesList, ...instances];
      let dt: any = [];
      instances.forEach((instance: any, index: number) => {
        if(this.selectedInstanceId) {
          let instance: any = instances.find((instance: any) => {
            return instance['instanceId'] == this.selectedInstanceId;
          })
          if(instance) {
            this.instance = instance;
            this.instance['actionStart'] = false;
          } else {
            this.router.navigate(['/dash/operations/tags']);
          }
        }
        // if(this.selectedCloudPartner == 'azure') {
        //   instance['tagName'] = instance['name'];
        //   instance['instanceId'] = instance['name'];
        //   instance['instanceType'] = instance['type'];
        //   instance['availabilityZone'] = instance['location'];
        //   instance['statusCheck'] = '2/2';
        //   instance['instanceStatus'] = instance['state'];
        //   instance['privateIp'] = instance['private_ip'];
        //   instance['publicIp'] = instance['public_ip'];
        //   instance["tags"] =  Object.keys(instance["tags"] || {}).map((tag: any) => {
        //     return {Key: tag, Value: instance['tags'][tag]};
        //   })
        //   instance['disks_attached_to_vm'] = instance['disks_attached_to_vm'].map((disk: any) => {
        //     return {
        //       DeviceName: disk['disk_name'],
        //       Ebs: {
        //         AttachTime: moment(disk['time_created']).format('MMM DD, YYYY hh:mm A'),
        //         DeleteOnTermination: '-',
        //         Status: disk['disk_state'],
        //         VolumeId: '-'
        //       }
        //     }
        //   })
        // }
        instance["actionStart"] = false;
        if (instance.instanceStatus == "running") {
          dt.push(instance);
        }
        instance.ipdata =
          (instance.privateIp ? instance.privateIp.toString() : "") +
          (instance.publicIp ? "/" + instance.publicIp.toString() : "");
      });
      if (result.nextToken && result.nextToken != "") {
        await this.loadInstances(result.nextToken);
      }
      if (dt.length > 0) {
        await this.fetchInstanceAZ(dt);
      }
      await this.listMonitors();
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    this.loading = false;
    this.notifier.loading(false);
  }

  async loadTags(nextToken: any = undefined) {
    this.notifier.loading(true);
    if (!nextToken) {
      this.instancesList = [];
      this.tagList = ["All"];
    }
    let data = {
      action: "listEc2Tags",
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Tags", "Ec2Tags Fetched");
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    this.notifier.loading(false);

    if (this.destroyed) {
      return;
    }

    if (result.status == "1" || result.s == "1") {
      if (result.tagKeyList && result.tagKeyList.length > 0 && !nextToken) {
        this.tagList = [...this.tagList, ...result.tagKeyList];
      }
      if (this.tagList.length > 0 && !nextToken) {
        this.selectedTag = this.tagList[0];
        await this.loadValues();
      }
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    setTimeout(() => {
      $("#tagListID").selectpicker("refresh");
    }, 100);

    if (result.nextToken) {
      await this.loadTags(result.nextToken);
    }
  }

  async loadValues() {
    if (this.tagsEnabled == "true" && this.urlPrefix == "client") {
      this.valueList =
        this.tagsListObj[this.accountId][this.regionId][this.selectedTag];
      if (this.valueList.length > 0) {
        this.selectedValue = this.valueList[0];
        this.loadInstances();
      }
      setTimeout(() => {
        $("#valueListID").selectpicker("refresh");
      }, 100);
      return;
    }
    if (this.selectedTag == "All") {
      this.valueList = ["All"];
      this.selectedValue = "All";
      setTimeout(() => {
        $("#valueListID").selectpicker("refresh");
      }, 100);
      this.loadInstances();
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: "listEc2TagsValues",
      accountId: this.accountId,
      region: this.regionId,
      nextToken: "",
      tagKey: this.selectedTag,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Tags", "Ec2TagsValues Fetched");
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    this.valueList = [];

    if (result.status == "1" || result.s == "1") {
      this.valueList = result.tagKeyList;
      if (this.valueList.length > 0) {
        this.selectedValue = this.valueList[0];
      }
      this.instancesList = [];
      await this.loadInstances();
    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }
    setTimeout(() => {
      $("#valueListID").selectpicker("refresh");
    }, 100);

    this.notifier.loading(false);
  }


  async start_stop_report(){
    this.notifier.loading(true);
    let data = {
        accId: this.accountId,
        action: "runScan",
        email: this.eId
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/operations/ec2startstopreport`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Info", "", result.msg, "info", 5000);

    } else {
      this.notifier.alert("Info", "", result.error, "info", 5000);
    }

    this.notifier.loading(false);
  }

  changeTag() {
    this.instance = null;
    this.loadValues();
  }

  changeValue() {
    this.instance = null;
    this.loadInstances();
  }

  async fetchInstanceAZ(instances: any, nextToken?: any) {
    let data = {
      action: "fetchStatusCheckResult",
      instanceIdList: instances.map((instance: any) => {
        return instance.selectedResourceGroup;
      }),
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Tags", "InstanceAZ Fetched");
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.s == "1") {
      result.statusCheckDataList.forEach((instance: any) => {
        let instance_ = this.instancesList.find(
          (element: any) => element.instanceId == instance.instanceId
        );
        instance_ = { ...instance_, ...instance };
        this.instancesList[
          this.instancesList.findIndex(
            (element: any) => element.instanceId == instance.instanceId
          )
        ] = instance_;
      });
    } else {
      this.notifier.alert("Info", "", result.msg, "info", 5000);
    }
  }

  statusChange: any = null;
  createMonitor: any = null;
  async listMonitors() {
    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      this.loading = false;
      return;
    }

    if (account) {
      account = JSON.parse(account);
    }
    this.loading = true;
    let data: any = {
      action: "list_monitors",
      id_token: localStorage.getItem("idT"),
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("un"),
      origin: "swayam",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      result.monitors = result.monitors.map((monitor: any) => {
        monitor["state"] = monitor["state"] == 5 ? 0 : 1;
        return monitor;
      });
      this.instancesList.forEach((instance: any) => {
        let monitor: any = result.monitors.find((monitor: any) => {
          return monitor["conf_instance_id"] == instance["instanceId"];
        });
        if (monitor) {
          instance["ems_status"] = monitor["state"];
          instance["site24x7"] = monitor;
        } else {
          instance["ems_status"] = -1;
        }
      });
      // this.getInstanceStatus(this.monitors);
    } else {
      this.instancesList.forEach((instance: any) => {
        instance["ems_status"] = -2;
      });
    }

    this.loading = false;
  }

  setStatusChange(instance: any) {
    this.statusChange = instance["site24x7"];
  }

  createMonitorTrigger(instance: any) {
    this.createMonitor = instance;
  }

  instanceStartStop(event: any) {
    this.instancesList.forEach((instance: any) => {
      instance["actionStart"] = false;
    });
    this.instance["actionStart"] = true;
    if (event == "start") {
      this.modalTitle = "start";
      this.action = "start";
    } else if (event == "stop") {
      this.action = "stop";
      this.modalTitle = "stop";
    }
    this.command = event;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  showData(ref: any, value: any) {
    if (ref.instance) {
      ref.instance["actionStart"] = false;
    }
    value["actionStart"] = true;
    ref.instance = value;
  }

  showTags(data: any) {
    this.showTagsModal = data;
  }

  getStartTriggerInstances() {
    return this.instancesList
      ? this.instancesList.filter((instance: any) => {
          return instance.actionStart;
        })
      : [];
  }

  hideModel() {
    this.command = null;
  }

  singleStartStop(event: any, instance: any, trigger: any) {
    event.preventDefault();
    event.stopPropagation();
    this.instancesList.forEach((instance: any) => {
      instance["actionStart"] = false;
    });
    instance.actionStart = true;
    this.triggerInstances(event, trigger);
  }

  triggerInstances(event: any, trigger: string) {
    event.preventDefault();
    event.stopPropagation();
    if (trigger == "run") {
      this.modalTitle = "start";
      this.action = "start";
    } else if (trigger == "stop") {
      this.action = "stop";
      this.modalTitle = "stop";
    }
    this.command = trigger;
  }

  hideMdoal(event: any) {
    this.showTagsModal = null;
  }

  hideDetails(event: any) {
    this.instance["actionStart"] = false;
    this.instance = null;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
