import { Component, EventEmitter, Output } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "site24x7-credentials",
  templateUrl: "./credentials.component.html",
  styleUrls: ["./credentials.component.css"],
})
export class CredentialsComponent {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  constructor(
    private apiService: APIService
  ) {}

  loading: boolean = false;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  displayName: string = localStorage.getItem("com");
  eId: string = localStorage.getItem('eId');
  ut: string = localStorage.getItem('ut');


  invite: any = {
    status: 'PENDING',
    message: ''
  }
  
  async inviteUser() {
    if(this.ut != 'admin') {
      this.invite['status'] = 'FAILED';
      this.invite['message'] = 'You are not authorized to perform this action. please contact admin'
      return;
    }
    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }

    account = JSON.parse(account);

    this.loading = true;
    let data: any = {
      action: "invite_user_to_bu",
      admin_user_email: this.eId,
      origin: "swayam",
      user_name: localStorage.getItem('un'),
      zaaid: account['zaid'],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.invite['status'] = 'SENT';
      this.invite['message'] = result.message || 'Invitation sent successfully. Please check your mailbox';
    } else  {
      this.invite['status'] = 'FAILED';
      this.invite['message'] = result.error_message || 'Failed to invite user. Please try again later';
    }

    this.loading = false;
  }
}
