
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { number } from 'echarts';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';

@Component({
  selector: 'app-cost-anamoly-status',
  templateUrl: './cost-anamoly-status.component.html',
  styleUrls: ['./cost-anamoly-status.component.css']
})
export class CostAnamolyStatusComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
clientId: string = "";
  emails: string[] = [""];
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  accountId: any;
  regionId: any;
  currentMessage: any;
  checkboxStatus: boolean = false;
  threshold:any = '5';
  isError = false;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.clientId = "";
        this.emails = [""];
        this.threshold='5';
        this.isError = false;
        this.urlPrefix = localStorage.getItem("role") == "Admin" ? "admin" : "client";
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.accountId = d.value;
        this.clientId = "";
        this.emails = [""];
        this.urlPrefix = localStorage.getItem("role") == "Admin" ? "admin" : "client";
        this.threshold='5';
        this.isError = false;
        this.load();
      }
    });


  }

  load(){
    if (this.urlPrefix == "client") {
      this.clientId =
        localStorage.getItem("masterId") &&
        localStorage.getItem("masterId") != "undefined"
          ? localStorage.getItem("masterId")
          : localStorage.getItem("eId");
    } else {
      this.clientId = localStorage.getItem("selectedClientList");
    }
    this.fetch();
  }

  deleteSelectedAdditionalContact(index: number) {
    this.emails.splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (value != "" && value.length > 0 && this.emails.length - 1 == index) {
      this.emails.push("");
    } else if (
      value == "" &&
      value.length == 0 &&
      this.emails.length - 2 == index &&
      this.emails[this.emails.length - 1] == ""
    ) {
      this.emails.splice(this.emails.length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.emails.push("");
  }

  totalAdditionalContacts() {
    return this.emails.filter((contact: any) => {
      return contact != "";
    }).length;
  }

  arrayEquals(a: any, b: any) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  trackByFn() {
    return true;
  }

  loading: any = {
    fetch: false,
    save: false,
  };

  account: any = null;
  async fetch() {
    this.loading["fetch"] = true;
    let data: any = {
      a: "fetch",
      clientId: this.clientId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiUrl: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/anomaly`;

    let result: any = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      this.account = result.acnt;
      console.log('---->');
      console.log(this.account);
      try {
        this.emails = this.account["mails"].split(",");
        this.threshold=this.account["threshold"];
        console.log(this.threshold);
      } catch (error) {
        this.emails = [""];
      }
      this.checkboxStatus = this.account["status"]=='active' ? true : false;
    } else {
      this.account = null;
    }

    this.loading["fetch"] = false;
  }

  totalemails() {
    return this.emails.filter((email: any) => {
      return email.trim() != "";
    });
  }

  async save() {
    this.loading["save"] = true;
    let data: any = {
      a: this.account ? "update" : "add",
      clientId: this.clientId,
      mails: this.totalemails().join(","),
      threshold:this.threshold
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiUrl: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/anomaly`;

    let result: any = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
      this.fetch();
    }

    this.loading["save"] = false;
  }
  async updateCheckboxStatus() { 
    const status = this.checkboxStatus ? 'active' : 'inactive'; 
    let data: any = {
      a: "status",
      clientId: this.clientId,
      "status": status ,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiUrl: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/anomaly`;

    let result: any = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
    }

  }

  checkThreshold(){
    if(Number(this.threshold) <5 || Number(this.threshold)>100){
      this.isError = true;
    }else{
      this.isError = false;
    }
  }
  no(){
    this.hideModal.emit({ status: 0 });
  }
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

}
