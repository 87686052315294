import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let window: any;
declare let $: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './ripurchased.component.html',
  styleUrls: ['./ripurchased.component.css'],

})
export class RIPurchasedComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  linkedAccounts: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;

  headers: any = [
    {
      id: 'masterAccountId',
      name: 'Master Account ID',
    },
    {
      id: 'linkedAccountID',
      name: 'Linked Account ID',
    },
    {
      id: 'riPurchasedAmount',
      name: 'RI Purchased Amount',
    },
    {
      id: 'riUtilisedAmount',
      name: 'RI Utilised Amount',
    },
    {
      id: 'unUsedAmount',
      name: 'UnUsed Amount',
    },
  ];

  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    let temp = moment(new Date());
    this.dateRange = temp.format('MM/YYYY');

    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm/yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.notifier.loading(true);
    let dRange: any = $('#monthDate').val() || this.dateRange;
    let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    let data = {
      monthYear: sData,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/riutilisationreport`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.linkedAccounts = result.riPurchasedDetailsList;
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
