let SearchData: any = {
  category: [
    { name: "Services", id: "1" },
    {
      name: "Features",
      id: "2",
    },
    {
      name: "Blogs",
      id: "3",
    },
    {
      name: "Documentation",
      id: "4",
    },
    {
      name: "Knowledge Articles",
      id: "5",
    },
    {
      name: "Tutorials",
      id: "6",
    },
    {
      name: "Events",
      id: "7",
    },
    {
      name: "Marketplace",
      id: "8",
    },
  ],
  items: [
    {
      name: "Create Ticket (Cloud Support)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Create+Ticket.svg",
      desc: "Create Tickets for Minty and AWS Support Tickets",
      categories: [1],
      keys: [
        "ticket",
        "create",
        "create ticket",
        "support",
        "cloud",
        "helpdesk",
        "contact",
      ],
      fullText: "",
      link: "/dash/ticket/create",
    },
    {
      name: "View Tickets (Cloud Support)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+Ticket.svg",
      desc: "View and Manage Minty and AWS Support Tickets",
      categories: [1],
      keys: [
        "ticket",
        "view",
        "view ticket",
        "support",
        "cloud",
        "helpdesk",
        "contact",
        "list",
      ],
      fullText: "",
      link: "/dash/ticket/list",
    },
    {
      name: "Knowledge Base (Cloud Support)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Knowledge+Base.svg",
      desc: "View KB Tickets",
      categories: [1],
      keys: [
        "ticket",
        "view",
        "view ticket",
        "support",
        "cloud",
        "helpdesk",
        "contact",
        "kb",
        "kb base",
        "Knowledge base",
      ],
      fullText: "",
      link: "/dash/knowledgebase",
    },
    {
      name: "ACM (Operations)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg",
      desc: "",
      categories: [1],
      keys: ["acm", "certificates", "operations"],
      fullText: "",
      link: "/dash/operations/acm",
    },
    {
      name: "EC2 (Operations)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg",
      desc: "Virtual Server in the Cloud",
      categories: [1],
      keys: [
        "ec",
        "ec2",
        "vm",
        "virtual",
        "server",
        "operations",
        "ec2 metrics",
      ],
      fullText: "",
      link: "/dash/operations/ec2",
    },
    // {
    //   name: "EC2 (Operations)",
    //   icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg",
    //   desc: "Virtual Server in the Cloud",
    //   categories: [1],
    //   keys: [
    //     "tags",
    //     "tag",
    //     "ec",
    //     "ec2",
    //     "vm",
    //     "virtual",
    //     "server",
    //     "operations",
    //     "ec2 metrics",
    //   ],
    //   fullText: "",
    //   link: "/dash/operations/tags",
    // },
    {
      name: "RDS (Operations)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg",
      desc: "Manage RDS Instances",
      categories: [1],
      keys: ["rds", "instance", "database", "sql", "mysql", "engine"],
      fullText: "",
      link: "/dash/operations/rds",
    },
    {
      name: "EBS (Operations)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+EBS.svg",
      desc: "Manage EBS Volumes",
      categories: [1],
      keys: ["volumes", "volume", "ebs", "disks", "disk", "storage"],
      fullText: "",
      link: "/dash/operations/ebs",
    },
    {
      name: "Security Groups (Operations)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg",
      desc: "Manage Security Groups",
      categories: [1],
      keys: [
        "sg",
        "security",
        "group",
        "security groups",
        "inbound",
        "outbound",
        "rules",
      ],
      fullText: "",
      link: "/dash/operations/sg",
    },
    // {
    //   name: "Service Intelligence (Operations)",
    //   icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Service+Intelligence.svg",
    //   desc: "",
    //   categories: [1],
    //   keys: ["service", "service intelligence", "intelligence"],
    //   fullText: "",
    //   link: "/dash/operations/itsi",
    // },
    {
      name: "RDS (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg",
      desc: "",
      categories: [1],
      keys: ["rds", "instance", "database", "sql", "mysql", "engine"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "rds" },
    },
    {
      name: "API Gateway (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png",
      desc: "",
      categories: [1],
      keys: ["api gateway", "compliance", "serverless", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "apigateway" },
    },
    {
      name: "Cloudtrail (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png",
      desc: "",
      categories: [1],
      keys: ["cloudtrail","cloud trail", "compliance", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "cloudtrail" },
    },
    {
      name: "Route 53 (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png",
      desc: "",
      categories: [1],
      keys: ["route 53", "53", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "route53" },
    },
    {
      name: "Lambda (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png",
      desc: "",
      categories: [1],
      keys: ["lambda", "compliance", "serverless", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "lambda" },
    },
    {
      name: "Security Groups (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compute%404x.png",
      desc: "",
      categories: [1],
      keys: ["security groups", "sg", "compute", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "sg" },
    },
    {
      name: "SES (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png",
      desc: "",
      categories: [1],
      keys: ["ses", "compliance", "serverless", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "ses" },
    },
    {
      name: "SNS (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png",
      desc: "",
      categories: [1],
      keys: ["sns", "compliance", "serverless", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "sns" },
    },
    {
      name: "EKS (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Serverless%404x.png",
      desc: "",
      categories: [1],
      keys: ["eks", "compliance", "serverless", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "kube" },
    },
    {
      name: "DynamoDB (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Database%404x.png",
      desc: "",
      categories: [1],
      keys: ["dynamodb", "db", "compliance", "database", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "dynamo" },
    },
    {
      name: "Elastic Search (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Database%404x.png",
      desc: "",
      categories: [1],
      keys: ["elastic search", "db", "compliance", "database", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "es" },
    },
    {
      name: "Cloudfront (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Storage%404x.png",
      desc: "",
      categories: [1],
      keys: ["cloudfront", "storage", "compliance", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "cloudfront" },
    },
    {
      name: "Redshift (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Storage%404x.png",
      desc: "",
      categories: [1],
      keys: ["redshift", "storage", "compliance", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "redshift" },
    },
    {
      name: "EC2 (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compute%404x.png",
      desc: "",
      categories: [1],
      keys: ["ec2","ec2 " ,"compute", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "ec2" },
    },
    {
      name: "ELB (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compute%404x.png",
      desc: "",
      categories: [1],
      keys: ["elb", "compute", "governance"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "elb" },
    },
    {
      name: "S3 Age Report",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compute%404x.png",
      desc: "",
      categories: [1],
      keys: ["s3 age report", "report", "s3", "age"],
      fullText: "",
      link: "/dash/compliance/s3/age_report",
    },
    {
      name: "Tellme",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Tell+Me+Feature%404x.png",
      desc: "",
      categories: [1],
      keys: ["tellme"],
      fullText: "",
      link: "/dash/tell-me",
    },

    {
      name: "EC2 (Cost Controls)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg",
      desc: "Virtual Server in the Cloud",
      categories: [1],
      keys: [
        "ec",
        "ec2",
        "virtual",
        "server",
        "cost",
        "optimization",
        "ec2 metrics",
      ],
      fullText: "",
      link: "/dash/costopt/ec2",
    },
    {
      name: "Daily Variance Report (Cost Controls)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Expenditure+and+Usage%404x.png",
      desc: "",
      categories: [1],
      keys: ["cost", "report", "daily variance report"],
      fullText: "",
      link: "/dash/billing/billing-daily",
    },
    {
      name: "Other (Cost Controls)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Other+Cost+Optimization.svg",
      desc: "",
      categories: [1],
      keys: ["other", "cost", "cost controls", "optimization"],
      fullText: "",
      link: "/dash/costopt/other",
    },
    {
      name: "Floating Report (Cost Controls)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Floating+Report.svg",
      desc: "",
      categories: [1],
      keys: ["floating", "floating report", "orphan", "cost", "optimization"],
      fullText: "",
      link: "/dash/costopt/floating",
    },
    {
      name: "Scheduler (Automation)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg",
      desc: "",
      categories: [1],
      keys: ["scheduler", "automation"],
      fullText: "",
      link: "/dash/beta/automation/scheduler",
    },

    {
      name: "EBS (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+EBS.svg",
      desc: "",
      categories: [1],
      keys: ["compliance", "ebs", "encrypted", "volumes", "disks", "storage"],
      fullText: "",
      link: "/dash/compliance/ebs",
    },
    {
      name: "EBS (Cost Controls)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png",
      desc: "",
      categories: [1],
      keys: [
        "cost controls",
        "ebs",
        "encrypted",
        "volumes",
        "disks",
        "storage",
      ],
      fullText: "",
      link: "/dash/costoptimization/ebs",
    },
    {
      name: "RI Recommendations (Cost Controls)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png",
      desc: "",
      categories: [1],
      keys: ["cost controls", "ri", "ri recommendations"],
      fullText: "",
      link: "/dash/costcontrols/ec2-ri",
    },
    {
      name: "AMIs and Snapshots (Cost Controls)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png",
      desc: "",
      categories: [1],
      keys: ["cost controls", "ri", "amis and snapshots", "snapshots"],
      fullText: "",
      link: "/dash/costopt/floating",
    },
    {
      name: "S3 (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+S3.svg",
      desc: "",
      categories: [1],
      keys: [
        "compliance",
        "s3",
        "public",
        "object",
        "objects",
        "bucket",
        "buckets",
        "storage",
      ],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "s3" },
    },
    {
      name: "Cloudtrail (Operations)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Cloud+Trail.svg",
      desc: "",
      categories: [1],
      keys: ["cloudtrail","cloud trail", "cloud", "trail", "audit", "activity"],
      fullText: "",
      link: "/dash/compliance/cloudtrail",
    },
    {
      name: "Config (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Settings.svg",
      desc: "",
      categories: [1],
      keys: ["config", "compliance"],
      fullText: "",
      link: "/dash/compliance/config",
    },
    {
      name: "IAM (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg",
      desc: "",
      categories: [1],
      keys: [
        "security",
        "compliance",
        "iam",
        "user",
        "users",
        "offender",
        "offenders",
      ],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "iam" },
    },
    {
      name: "Tags (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Tags.svg",
      desc: "",
      categories: [1],
      keys: ["tag", "compliance", "tags"],
      fullText: "",
      link: "/dash/compliance/tags",
    },
    // {
    //   name: "Security Groups (Compliance)",
    //   icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg",
    //   desc: "Manage Security Groups",
    //   categories: [1],
    //   keys: [
    //     "sg",
    //     "security",
    //     "group",
    //     "security groups",
    //     "inbound",
    //     "outbound",
    //     "rules",
    //     "port",
    //   ],
    //   fullText: "",
    //   link: "/dash/compliance/sg",
    // },
    {
      name: "Patch Report (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Patch+Report.svg",
      desc: "",
      categories: [1],
      keys: ["patch", "report","patchreport","patch report", "compliance"],
      fullText: "",
      link: "/dash/compliance/patch",
    },
    {
      name: "Trusted Advisor (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Trusted+Advisor.svg",
      desc: "",
      categories: [1],
      keys: ["advisor", "trusted", "security", "compliance", "trusted advisor"],
      fullText: "",
      link: "/dash/compliance/advisor",
    },
    {
      name: "IAM Users (Security)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/IAM+Users%404x.png",
      desc: "",
      categories: [1],
      keys: ["security", "iam user"],
      fullText: "",
      link: "/dash/compliance/iam/users",
    },
    {
      name: "Security Baseline (Security)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Startup+Security+Baseline%404x.png",
      desc: "",
      categories: [1],
      keys: ["security", "security baseline"],
      fullText: "",
      link: "/dash/compliance/ssb",
    },
    {
      name: "S3 (Security)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg",
      desc: "",
      categories: [1],
      keys: ["security", "s3", "bucket"],
      fullText: "",
      link: "/dash/compliance/s3",
    },
    {
      name: "IAM Access Key Age Report (Security)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/IAM+Access+Key+Age+Report%404x.png",
      desc: "",
      categories: [1],
      keys: ["security", "iam", "age","report","iamaccesskeyagereport","iam access key age report"],
      fullText: "",
      link: "/dash/compliance/iam/age_report",
    },
    {
      name: "Cloud Formation (Security)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg",
      desc: "",
      categories: [1],
      keys: ["security", "cloud formation", "cloudformation", "cft"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "cft" },
    },
    {
      name: "KMS (Security)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg",
      desc: "",
      categories: [1],
      keys: ["security", "kms"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "kms" },
    },
    {
      name: "Cloudwatch (Monitoring)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg",
      desc: "",
      categories: [1],
      keys: ["monitoring", "cloudwatch"],
      fullText: "",
      link: "/dash/compliance/findings",
      query: { audit_type: "cloudwatch" },
    },
    {
      name: "Inspector (Compliance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Inspector.svg",
      desc: "",
      categories: [1],
      keys: ["inspector", "security", "compliance"],
      fullText: "",
      link: "/dash/compliance/inspector",
    },
    {
      name: "Security Hub (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Security+Hub.svg",
      desc: "",
      categories: [1],
      keys: ["hub", "security hub", "security", "compliance"],
      fullText: "",
      link: "/dash/compliance/securityhub",
    },
    {
      name: "Created vs Resolved (SLA Report)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Created+vs+Resolved).svg",
      desc: "",
      categories: [1],
      keys: [
        "support",
        "cloud",
        "help desk",
        "created",
        "resolved",
        "created vs resolved",
        "sla",
        "report",
        "sla report",
      ],
      fullText: "",
      link: "/dash/sla/createdvsresolved",
    },
    {
      name: "First Response SLA (SLA Report)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(First+Response+SLA).svg",
      desc: "",
      categories: [1],
      keys: [
        "support",
        "cloud",
        "help desk",
        "met",
        "breached",
        "met vs breached",
        "sla",
        "report",
        "first",
        "first response",
        "sla report",
      ],
      fullText: "",
      link: "/dash/sla/firstresponsesla",
    },
    {
      name: "Resolution SLA (SLA Report)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Resolution+SLA).svg",
      desc: "",
      categories: [1],
      keys: [
        "support",
        "cloud",
        "help desk",
        "met",
        "breached",
        "met vs breached",
        "sla",
        "report",
        "resolution",
        "resolution sla",
        "sla report",
      ],
      fullText: "",
      link: "/dash/sla/resolutionsla",
    },
    {
      name: "EMS Dashboard (Monitoring)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/EMS+Dashbaord%404x.png",
      desc: "Monitor Metrics of your cloud resources",
      categories: [1],
      keys: ["monitoring", "cloud","ems dashboard","emsdashboard", "resources"],
      fullText: "",
      link: "/dash/monitoring",
    },
    // {
    //   name: "Dashboard (Anomalies)",
    //   icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg",
    //   desc: "Manage Monitors, Anomalies and Alarms",
    //   categories: [1],
    //   keys: [
    //     "anomalies",
    //     "dashboard",
    //     "alarm",
    //     "monitor",
    //     "anomaly",
    //     "anomalies",
    //   ],
    //   fullText: "",
    //   link: "/dash/anomalies/dashboard",
    // },
    {
      name: "Trigger History (Anomalies)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/History.svg",
      desc: "Manage Monitors, Anomalies and Alarms",
      categories: [1],
      keys: [
        "anomalies",
        "history",
        "alarm",
        "monitor",
        "anomaly",
        "anomalies",
        "trigger",
      ],
      fullText: "",
      link: "/dash/anomalies/history",
    },
    {
      name: "Exceptions (Anomalies)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Exceptions.svg",
      desc: "Manage Monitors, Anomalies and Alarms",
      categories: [1],
      keys: [
        "anomalies",
        "exceptions",
        "alarm",
        "monitor",
        "anomaly",
        "anomalies",
        "exception",
      ],
      fullText: "",
      link: "/dash/anomalies/exceptions",
    },
    {
      name: "Update BOM (BOM)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg",
      desc: "Upload BOM",
      categories: [1],
      keys: ["bom", "upload"],
      fullText: "",
      link: "/dash/bom/upload",
    },
    {
      name: "View BOM Details (BOM)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+BOM+Details.svg",
      desc: "Manage BOM Detais",
      categories: [1],
      keys: ["bom", "view", "manage", "update"],
      fullText: "",
      link: "/dash/bom/view",
    },
    {
      name: "Delta Report (BOM)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Delta+Report.svg",
      desc: "Manage Delta Report",
      categories: [1],
      keys: ["bom", "delta", "report"],
      fullText: "",
      link: "/dash/bom/deltareport",
    },
    {
      name: "Backup Report",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Backup.svg",
      desc: "Manage Backup Reports",
      categories: [1],
      keys: ["snapshot", "backup", "report"],
      fullText: "",
      link: "/dash/backupreport",
    },
    {
      name: "Monthly Bill (Billing)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg",
      desc: "View Billing Report Month Wise",
      categories: [1],
      keys: ["bills", "billing", "month", "monthly"],
      fullText: "",
      link: "/dash/billing",
    },
    {
      name: "RI Order Report (Billing)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg",
      desc: "",
      categories: [1],
      keys: [
        "bills",
        "ri",
        "ri details",
        "ri recommendations",
        "order",
        "report",
        "billing",
      ],
      fullText: "",
      link: "/dash/billing/ri",
    },
    {
      name: "Dashboard (Billing)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg",
      desc: "",
      categories: [1],
      keys: ["bills", "report", "dash board", "billing", "billing dashboard"],
      fullText: "",
      link: "/dash/billing/dashboard",
    },
    {
      name: "Dashboard (Global)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Dashboard%404x.png",
      desc: "",
      categories: [1],
      keys: ["dashboard", "global", "global dashboard"],
      fullText: "",
      link: "/dash/home",
    },
    {
      name: "Dashboard (Governance)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Compliance+Dashboard%404x.png",
      desc: "",
      categories: [1],
      keys: ["dashboard", "governance", "governance dashboard"],
      fullText: "",
      link: "/dash/compliance/dash",
    },

    {
      name: "View and Download Reports (Reports)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg",
      desc: "",
      categories: [1],
      keys: [
        "view",
        "report",
        "reports",
        "mis",
        "inventory",
        "uptime",
        "security",
        "ticket",
        "download",
        "View and Downloads Reports"
      ],
      fullText: "",
      link: "/dash/reports/view",
    },
    {
      name: "Automated Reports (Reports)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg",
      desc: "",
      categories: [1],
      keys: ["enable","automated report", "report", "reports"],
      fullText: "",
      link: "/dash/reports/enable",
    },
    {
      name: "User Management (Settings)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User+Management%404x.png",
      desc: "",
      categories: [1],
      keys: [
        "user",
        "users",
        "user",
        "manage",
        "user management",
        "usermanagement",
      ],
      fullText: "",
      link: "/dash/settings/profile",
    },
    // {
    //   name: "EC2 Groups (Settings)",
    //   icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg",
    //   desc: "",
    //   categories: [1],
    //   keys: ["ec2", "group", "groups", "instance"],
    //   fullText: "",
    //   link: "/dash/settings/ec2group/view",
    // },
    {
      name: "Policies (Settings)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg",
      desc: "",
      categories: [1],
      keys: ["policy", "policies", "rule", "rules", "iam"],
      fullText: "",
      link: "/dash/settings/policies/view",
    },
    {
      name: "Audit Trail (Settings)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg",
      desc: "",
      categories: [1],
      keys: ["audit", "trail", "audit trail", "settings"],
      fullText: "",
      link: "/dash/settings/audittrail",
    },
    {
      name: "User Management (Settings)",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg",
      desc: "",
      categories: [1],
      keys: ["user", "management", "password", "reset", "settings"],
      fullText: "",
      link: "/dash/settings/profile",
    },
    {
      name: "WAFR",
      icon: "https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg",
      desc: "",
      categories: [1],
      keys: ["war", "WAFR", "wafr", "swayam wafr"],
      fullText: "",
      link: "/dash/wafr",
    },
  ],
};

export default SearchData;
