import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "site24x7-monitor-group",
  templateUrl: "./monitor-group.component.html",
  styleUrls: ["./monitor-group.component.css"],
})
export class MonitorGroupComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  @Input("accountId") accountId: string;
  @Input("regionId") regionId: string;

  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  loading: boolean = false;
  async createMonitorGroup() {
    this.loading = true;
    let account: any = localStorage.getItem("emsConfig");
    if (!account) {
      return;
    }

    account = JSON.parse(account);
    let data: any = {
      action: "create_monitor_group",
      id_token: localStorage.getItem("idT"),
      zaaid: account["zaid"],
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      account_id: this.accountId + "_" + this.regionId,
      origin: "swayam",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.message, "success", 5000);

      this.hideModel.emit(true);
    } else {
      this.notifier.alert("Info", "", result.error_message, "info", 5000);
    }

    this.loading = false;
  }
}
