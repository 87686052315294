import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-protocol-form-cams",
  templateUrl: "./protocol-form-cams.component.html",
  styleUrls: ["./protocol-form-cams.component.css"],
})
export class ProtocolFormCamsComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<any>();
  protocol: any = {
    url: "",
    method: "get",
    protocol: "http://",
    port: "80",
    success_code: [],
  };

  constructor() {}

  close(event: boolean) {
    if (event) {
      this.hideModel.emit({
        status: true,
        data: this.protocol,
      });
    } else {
      this.hideModel.emit({
        status: false,
      });
    }
  }

  protocols: any = [
    {
      protocol: "2XX",
    },
    {
      protocol: "3XX",
    },
    {
      protocol: "4XX",
    },
    {
      protocol: "5XX",
    },
  ];

  ngOnInit(): void {}
}
