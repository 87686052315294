<div class="amis_body">
  <div class="amis_more">
    <div>
      <div class="mb-3">
        <label for="" class="disk_size_05">Disk Size</label>
        <input autocomplete="off" type="text" name="" id="" placeholder="Disk Size" disabled
          [attr.value]="instance['disks'][0]['Ebs']['VolumeSize']">
      </div>
      <div class="mb-3">
        <label for="" class="disk_size_05">Disk Type</label>
        <div>gp3</div>
      </div>
    </div>
  </div>
</div>