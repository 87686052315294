import { Component, OnInit } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

declare let window: any;
declare let Jhxlsx: any;


@Component({
  selector: "app-rdsbackup-report",
  templateUrl: "./rdsbackup-report.component.html",
  styleUrls: ["./rdsbackup-report.component.css"],
})
export class RDSBackupReportComponent implements OnInit {
  currentTab: string = "instances";
  instances:any = [];
  instanceHeaders = [
    
    { name: 'Instance Name', id: 'instanceName',
    },
    { name: 'Latest Snapshot ID', id: 'latestSnapshotID',
    },
    { name: 'Latest Snapshot Date', id: 'latestSnapshotDate' },
    { name: 'Snapshot Frequency', id: 'snapshotFrequency' },
    { name: 'First Snapshot ID', id: 'firstSnapshotId' },
    { name: 'First Snapshot Date', id: 'firstSnapshotDate' },
    { name: 'Total Snapshots', id: 'totalSnapshots' },


  ];
  clusterHeaders:any = [
    { name: 'Cluster Name', id: 'clusterName',
    },
    { name: 'Latest Snapshot ID', id: 'latestSnapshotID' },
    { name: 'Latest Snapshot Date', id: 'latestSnapshotDate' },
    { name: 'First Snapshot ID', id: 'firstSnapshotId' },
    { name: 'First Snapshot Date', id: 'firstSnapshotDate' },
    { name: 'Snapshot Frequency', id: 'snapshotFrequency' },
    { name: 'Total Snapshots', id: 'totalSnapshots' }
  ];
  snapshotsHeader: any = [
  ];
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  accountId: any;
  regionId: any;
  currentMessage: any;
  funRef: any;
  loading: boolean = true;
  expandable: boolean = false;
  createScan: boolean = false;
  clusters:any = [];




  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
      this.expandable = true;
    

    this.funRef = {
      loadInstances: this.loadInstances,
    };

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");
    // console.log('regionId:',this.regionId)

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
        // console.log('instances', this.instances);
        // console.log('clusters', this.clusters);
    
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
        // console.log('instances', this.instances);
        // console.log('clusters', this.clusters);    
      }
    });
  }

  load(){
    
    this.loading = true;

    this.loadInstances();
    this.loading = false;

  }
  getURl() {
    return `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;
  }

  getData() {
    // console.log("region in getData method",this.regionId)
    return {
      action: "create_jobs",
      region_name: this.regionId,
      account_id: this.accountId,
    };
  }

  async createScanTrigger() {
    // this.createScan = true;
    this.notifier.loading(true);
    // console.log("region in createscan method",this.regionId)

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "createScan",
      accId: this.accountId,
      region: this.regionId,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/v3/admin/operations/rdsbackup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      // console.log('scan success');
      this.notifier.alert("Suceess", "", result.msg, "success", 5000);
      this.loadInstances();
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }

  // async getscan(){
  //   this.notifier.loading(true);

  //   let header = {
  //     Authorization: localStorage.getItem("t"),
  //   };

  //   let data = {
  //     action: "getScanData",
  //     accId: this.accountId,
  //     region: this.regionId,
  //   };
  //   //https://api.swayam.cloud/v3/admin/support
  //   let apiURL = `https://api.swayam.cloud/v3/admin/operations/rdsbackup`;

  //   let result = await this.apiServer.postDataPromis(apiURL, data, header);

  //   if (result.status == "1" || result.s == "1") {
  //     // if(result.scanStatus == 'Scan Completed'){
  //     //   return;
  //     // }
  //     result.instanceList
  //     this.notifier.alert("Success", "", result.scanStatus, "error", 5000);
  //   } else {
  //     this.notifier.alert("Error", "", result.error, "error", 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  hideModal(event: any) {
    this.createScan = false;
  }


  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }


  async loadInstances() {
    this.notifier.loading(true);

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "getScanData",
      accId: this.accountId,
      region: this.regionId,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/v3/admin/operations/rdsbackup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      // if (
      //   ((result.instanceList && result.instanceList.length == 0) && result.clusterList && result.clusterList.length == 0)
      // ) {
      //   this.instances = result.instanceList;
      // this.clusters = result.clusterList;
      //   this.notifier.loading(false);
      //   return;
      // }
      // if (result.scanStatus !== 'Scan Completed') {
      //   const intervalId = setInterval(() => {
      //     this.getscan();
      //     if (result.scanStatus === 'Scan Completed') {
      //       clearInterval(intervalId);  
      //       this.notifier.alert("Information", "", result.scanStatus, "info", 5000); 
      //     }
      //   }, 60000); 
      // } else {
      //   this.notifier.alert("Success", "", result.scanStatus, "error", 5000);
      // }
      // console.log(result);
      // this.notifier.alert("Suceess", "", result.scanStatus, "success", 5000);
      this.instances = result.instanceList;
      this.clusters = result.clusterList;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
      this.notifier.loading(false);
      this.instances = [];
      this.clusters = [];
      this.notifier.loading(false);
    }
    this.notifier.loading(false);

  }

  export() {
    if (!this.instances || this.instances.length === 0) {
      this.notifier.alert("Error", "", "No data available to export.", "error", 5000);
      return;
    }
  
    // Prepare data for instances
    const instancesSheet = {
      sheetName: `Instances - ${this.accountId}`,
      data: [
        [
          { text: "Sl. No." },
          ...this.instanceHeaders.map((h: any) => ({ text: h.name }))
        ],
        ...this.instances.map((instance: any, index: number) => [
          { text: index + 1 },
          ...this.instanceHeaders.map((h: any) => ({ text: instance[h.id] || '-' }))
        ])
      ]
    };
  
    // Prepare data for clusters
    const clustersSheet = {
      sheetName: `Clusters - ${this.accountId}`,
      data: [
        [
          { text: "Sl. No." },
          ...this.clusterHeaders.map((h: any) => ({ text: h.name }))
        ],
        ...this.clusters.map((cluster: any, index: number) => [
          { text: index + 1 },
          ...this.clusterHeaders.map((h: any) => ({ text: cluster[h.id] || '-' }))
        ])
      ]
    };
  
    // Options for export
    const options = {
      fileName: `RDS Backup Report - ${this.accountId}`
    };
  
    // Check if Jhxlsx and its export method are available
    if (Jhxlsx && typeof Jhxlsx.export === 'function') {
      Jhxlsx.export([instancesSheet, clustersSheet], options);
    } else {
      this.notifier.alert("Error", "", "Export function is not available.", "error", 5000);
    }
  }
  


  hideNested(event: any) {
    event.showNested = false;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
