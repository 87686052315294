import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
declare let window: any;
import * as moment from 'moment';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';
@Component({
  selector: 'step4-uploadbom-v4',
  styleUrls: [
    './../../upload.bom.component.css',
    './step4.bomupload.component.css',
  ],
  templateUrl: './step4.bomupload.component.html',
})
export class Step4UploadBOMv2Component implements OnInit, OnDestroy {
  @Input('headers') headers: any;
  @Input('processing') processing: any;
  @Input('processFailed') processFailed: any;
  selectedHeaders: any = [];
  @Input('pricingStrategy') pricingStrategy: any;
  @Input('stepsCompleted') stepsCompleted: any;
  @Output('refresh')
  refresh = new EventEmitter<boolean>();
  @Output('update_end_date') updateEndDate: any = new EventEmitter<boolean>();
  @Input('dollarValue') dollarValue: any;

  plans: any = [
    {
      plan: 'OD',
    },
    {
      plan: '1YNU',
      duration: '1 Year',
    },
    {
      plan: '1YCNU',
      duration: '1 Year',
    },
    { plan: 'S1YNU', duration: '1 Year' },
    { plan: 'S1YAU', duration: '1 Year' },
    { plan: 'S1YPU', duration: '1 Year' },
    {
      plan: '3YNU',
      duration: '3 Years',
    },
    {
      plan: '3YCNU',
      duration: '3 Years',
    },
    { plan: 'S3YNU', duration: '3 Years' },
    { plan: 'S3YAU', duration: '3 Years' },
    { plan: 'S3YPU', duration: '3 Years' },
    {
      plan: '1 YNU Spot Min',
      duration: '1 Year',
    },
    {
      plan: '1 YCNU Spot Min',
      duration: '1 Year',
    },
    {
      plan: '1 YNU Spot Max',
      duration: '1 Year',
    },
    {
      plan: '1 YCNU Spot Max',
      duration: '1 Year',
    },
    {
      plan: '3 YNU Spot Min',
      duration: '3 Years',
    },
    {
      plan: '3 YCNU Spot Min',
      duration: '3 Years',
    },
    {
      plan: '3 YNU Spot Max',
      duration: '3 Years',
    },
    {
      plan: '3 YCNU Spot Max',
      duration: '3 Years',
    },
  ];

  loading: boolean = true;
  regionId: any;
  accountId: any;
  currentMessage: any;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    console.log(this.pricingStrategy);

    this.selectedHeaders = this.headers.filter((header: any) => {
      if (header.id != 'exclude') {
        return true;
      }
      return false;
    });
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
    if (this.dollarValue.type == 'dynamic' && this.stepsCompleted < 4) {
      this.getDollarValue();
    }
    this.loading = false;
  }

  refreshStatus(event: any) {
    this.refresh.emit(event);
  }

  async getDollarValue() {
    this.dollarValue.type = 'dynamic';
    let from = 'USD';
    let to = 'INR';
    let value = 1;
    this.dollarValue.value = 'LOADING';
    let apiURL = `https://api.apilayer.com/exchangerates_data/convert?to=${to}&from=${from}&amount=${value}`;

    let header = {
      apikey: 'WJx7UtpxrQMdWAgvSUMKDLXn0zdOC0XW',
    };
    window.analyticsSendEventClick(
      'BOMv2.Components.Step4',
      'DollarValue Fetched'
    );
    let result = await this.apiServer.getDataPromis(apiURL, {}, header);
    // let result: any = {
    //   success: true,
    //   query: {
    //     from: 'USD',
    //     to: 'INR',
    //     amount: 1,
    //   },
    //   info: {
    //     timestamp: 1660130583,
    //     rate: 79.494502,
    //   },
    //   date: '2022-08-10',
    //   result: 79.494502,
    // };
    if (!result.error && result.success) {
      this.dollarValue.value = result.result;
    } else {
      this.notifier.alert(
        'Error',
        '',
        (result.error && result.error.message) ||
          (result.error && result.error.error && result.error.error.message) ||
          result.message,
        'error',
        5000
      );
      this.dollarValue.value = 0;
    }
    return true;
  }

  formatDate(
    date: string,
    required_format: string,
    current_format: string = null
  ) {
    if (current_format) {
      return moment(date, current_format).format(required_format);
    } else {
      return moment(date).format(required_format);
    }
  }

  getDuration(plan_str: string) {
    return this.plans.find((plan: any) => {
      return plan.plan == plan_str;
    });
  }

  ngOnDestroy(): void {}
}
