<div class="card">
  <h5 class="card-header">
    CAMS - Create Scanner
  </h5>
</div>
<div class="card" *ngIf="!loading['status'] && arns.length == 0 && step == 'IAM Policy'">
  <div class="card-header">
    Prepare IAM Policy for CAMS
  </div>
  <div class="card-body">
    <div class="cams-container">
      <div class="step-1-cams-container">
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/aws_logo_1.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Login to your AWS Console
            </span>
            <br>(<a href="https://aws.amazon.com">aws.amazon.com</a>)
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/iam-icon.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Click 'Services' <br> and select the IAM service
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/iam-policy.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Click 'Policies' <br> and click on 'Create Policy' Button
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/json-icon.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Select the 'JSON' tab <br> and paste policy JSON
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/rename-icon.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Name the policy 'CAMS-policy' <br>
              and click on 'Create Policy'
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/review-icon.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Click 'Review Policy'
            </span>
          </div>
        </div>
      </div>
      <div class="step-actions flex-end">
        <button class="btn btn-primary-soft" (click)="step = 'IAM Role'">
          Next <i class="fa fa-angle-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="!loading['status'] && arns.length == 0 && step == 'IAM Role'">
  <div class="card-header">
    Create IAM Role for CAMS
  </div>
  <div class="card-body">
    <div class="cams-container">
      <div class="step-1-cams-container">
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/iam-role.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Click on 'Roles' <br> and 'Create new Role'
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/aws_logo_1.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Select Role Type as 'Another AWS Account' <br> under options mark the 'Required' Customer ID option.
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/rename-icon.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Enter the following and click on Next:Permissions<br> AccountId: 837682544263 <br> Require MFA: NOT
              Checked
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/select-icon.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Select the policy 'CAMS-Policy' <br> and click on Next:Review
            </span>
          </div>
        </div>
        <div class="angle-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="step">
          <div class="step-icon">
            <img src="./assets/img/rename-icon.png" alt="">
          </div>
          <div class="step-text">
            <span class="text-bold">
              Set Role Name with your Choice 'CAMS' <br> and click on 'Create Role'
            </span>
          </div>
        </div>
      </div>
      <div class="step-actions flex-end">
        <button class="btn btn-light flex-start" (click)="step = 'IAM Policy'">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <div>Back</div>
        </button>
        <button class="btn btn-primary-soft flex-start" (click)="validate = true">
          <div>
            Validate
          </div>
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="!loading['status'] && arns.length > 0 && step == 'Create'">
  <div class="card-header">
    Create
  </div>
  <div class="card-body">
    <div class="cams-container">
      <div class="create-scanner-form-container">
        <table>
          <tbody>
            <tr>
              <th>Scanner ID</th>
              <td>
                <input autocomplete="off" class="form-control" type="text" [(ngModel)]="scannerID" disabled>
              </td>
            </tr>
            <tr>
              <th>Scanner Name</th>
              <td>
                <input autocomplete="off" class="form-control" type="text" [(ngModel)]="scannerName">
              </td>
            </tr>
            <tr>
              <th>Alert Email List</th>
              <td>
                <div class="emails-container">
                  <div class="email-container flex-start" *ngFor="let email of emailList; let i = index">
                    <input autocomplete="off" class="form-control" type="email" [(ngModel)]="email['email']">
                    <button class="btn btn-light" (click)="removeEmail(i)" *ngIf="emailList.length > 1">
                      <i class="fa fa-minus"></i>
                    </button>
                  </div>
                  <div>
                    <button class="btn btn-light" (click)="addEmail()">
                      <i class="fa fa-plus"></i> Add Email
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>Interval</th>
              <td>
                <div class="flex-start grid-2">
                  <select class="form-select" [(ngModel)]="interval">
                    <option value="1">1</option>
                    <option value="3">3</option>
                    <option value="6">6</option>
                    <option value="9">9</option>
                    <option value="12">12</option>
                    <option value="24">24</option>
                  </select>
                  <select class="form-select" [(ngModel)]="intervalFrequency">
                    <option value="hours">Hours</option>
                    <option value="minutes">Minutes</option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="step-actions flex-end">
        <button class="btn btn-primary-soft" (click)="loadResources()">
          Next <i class="fa fa-angle-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="!loading['status'] && arns.length > 0 && step == 'Configure Resources'">
  <div class="card-header">
    Configure Resources
  </div>
  <div class="card-body">
    <app-resources-cams [accountId]="accountId" [regionId]="regionId" [roleARN]="arns[0]['role_arn']"
      [resources]="resources"></app-resources-cams>
    <div class="step-actions flex-end">
      <button class="btn btn-light flex-start" (click)="step = 'Create'">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        Prev
      </button>
      <button class="btn btn-primary-soft" (click)="createScanner()" *ngIf="!loading['create']">
        Create
      </button>
      <div *ngIf="loading['create']">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="loading['status']">
  <div class="card-body loading-status">
    <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
  </div>
</div>

<app-validate-account-cams *ngIf="validate" (hideModel)="closeValidate($event)"></app-validate-account-cams>