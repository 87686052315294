import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../api/api.service";
import { NotifierService } from "../../_services/notifier.service";
import * as echarts from "echarts";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-compliance-dash",
  templateUrl: "./compliance-dash.component.html",
  styleUrls: ["./compliance-dash.component.css"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class ComplianceDashComponent implements OnInit, OnDestroy {
  regionId = localStorage.getItem("regionId");
  accountId = localStorage.getItem("accountId");
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  averageRetention: any = [];
  instanceAttachtedToSG: any = [];
  ebsList: any = [];
  ebswithName: any = [];
  openPorts: any = [];
  vpcList: any = [];
  vpcListWithFreeIp: any = [];
  SGList: any = [];
  backupList: any = [];
  instances: any = [];
  snapSizeObj: any = {};
  snapSizeData: any = [];
  volumeSizeData: any = [];
  snapSizeLoader = true;
  volSizeLoader = true;
  snapLoad = false;
  volLoad = false;
  snapData: any = [];
  volumeData: any = [];
  currentPage = 1;
  itemsPerPage = 5;
  lastIndex = 0;
  avg_loader = true;
  avgNotFound = false;

  ins_currentPage = 1;
  ins_itemsPerPage = 5;
  ins_lastIndex = 0;
  ins_loader = true;
  insNotFound = false;

  snap_currentPage = 1;
  snap_itemsPerPage = 5;
  snap_lastIndex = 0;
  snap_loader = true;
  snapNotFound = false;

  open_currentPage = 1;
  open_itemsPerPage = 5;
  open_lastIndex = 0;
  open_loader = true;
  openNotFound = false;

  vpc_currentPage = 1;
  vpc_itemsPerPage = 5;
  vpc_lastIndex = 0;
  vpc_loader = true;
  vpcNotFound = false;

  sg_currentPage = 1;
  sg_itemsPerPage = 5;
  sg_lastIndex = 0;
  sg_loader = true;
  sgNotFound = false;

  ri_currentPage = 1;
  ri_itemsPerPage = 5;
  ri_lastIndex = 0;
  ri_loader = true;
  riNotFound = false;
  riData:any=[];
  riErrMsg='No Recommendations Found';
  riFound=false;
  scanLoader =false;

  backup_currentPage = 1;
  backup_itemsPerPage = 5;
  backup_lastIndex = 0;
  backup_loader = true;
  backupNotFound = false;

  SGViewTrigger: boolean = null;
  PortViewTrigger: boolean = null;
  SnapViewTrigger: boolean = null;
  InstViewTrigger: boolean = null;
  retenViewTrigger: boolean = null;
  backupViewTrigger: boolean = null;
  vpcViewTrigger: boolean = null;
  shouldHideOverflow: boolean = true;
  nextToken: any;
  sortedByDays: string = "asc";
  sortIcon: string = "fa-arrow-down-wide-short";
  currentMessage: any;
  volumeNotFound = false;
  SnapEbsNotFound = false;
  iterationCount = 0;
  scanNotCompletedCount = 0;
  scanInProgress = false;
  loopDone = false;
  resizeStart = false;
  chart: echarts.ECharts;
  chartvol: echarts.ECharts;
  sgErrMsg = "No Data Found!";
  portErrMsg = "No Data Found!";
  insErrMsg = "No Data Found!";
  snapErrMsg = "No Data Found!";
  retenErrMsg = "No Data Found!";
  backupErrMsg = "No Data Found!";
  vpcErrMsg = "No Data Found!";

  nameCount: any = {};
  sgwithInsCount: any = [];
  filteredPortData: any = [];
  instantWithLastBackup: any = [];
  instantdetaillist: any = [];
  sgWithIns: any = [];
  snapSinceDays:any=[];
  createJobScanner=false;
  checkJobStatusCounter=0;
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
     this.regionId = localStorage.getItem("regionId");
     this.accountId = localStorage.getItem("accountId");
      this.nextToken = null;
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.notifier.loading(true);
        this.accountId = d.value;
        this.averageRetention = [];
        this.instanceAttachtedToSG = [];
        this.ebsList = [];
        this.ebswithName = [];
        this.openPorts = [];
        this.vpcList = [];
        this.vpcListWithFreeIp = [];
        this.SGList = [];
        this.backupList = [];
        this.snap_loader = true;
        this.snapNotFound = false;
        this.ins_loader = true;
        this.insNotFound = false;
        this.backup_loader = true;
        this.backupNotFound = false;
        this.open_loader = true;
        this.openNotFound = false;
        this.vpc_loader = true;
        this.vpcNotFound = false;
        this.sg_loader = true;
        this.sgNotFound = false;
        this.avg_loader = true;
        this.avgNotFound = false;
        this.sortedByDays = "asc";
        this.sortIcon = "fa-arrow-down-wide-short";
        this.currentPage = 1;
        this.itemsPerPage = 5;
        this.lastIndex = 0;
        this.ins_currentPage = 1;
        this.ins_itemsPerPage = 5;
        this.ins_lastIndex = 0;
        this.snap_currentPage = 1;
        this.snap_itemsPerPage = 5;
        this.snap_lastIndex = 0;
        this.open_currentPage = 1;
        this.open_itemsPerPage = 5;
        this.open_lastIndex = 0;
        this.vpc_currentPage = 1;
        this.vpc_itemsPerPage = 5;
        this.vpc_lastIndex = 0;
        this.sg_currentPage = 1;
        this.sg_itemsPerPage = 5;
        this.sg_lastIndex = 0;
        this.backup_currentPage = 1;
        this.backup_itemsPerPage = 5;
        this.backup_lastIndex = 0;
        this.snapSizeData = [];
        this.volumeSizeData = [];
        this.snapSizeLoader = true;
        this.volSizeLoader = true;
        this.snapLoad = false;
        this.volLoad = false;
        this.snapData = [];
        this.volumeData = [];
        this.iterationCount = 0;
        this.loopDone = false;
        this.volumeNotFound = false;
        this.SnapEbsNotFound = false;
        this.scanNotCompletedCount = 0;
        this.scanInProgress = false;
        this.portErrMsg = "No Data Found!";
        this.sgErrMsg = "No Data Found!";
        this.insErrMsg = "No Data Found!";
        this.snapErrMsg = "No Data Found!";
        this.retenErrMsg = "No Data Found!";
        this.backupErrMsg = "No Data Found!";
        this.vpcErrMsg = "No Data Found!";
        this.nameCount = {};
        this.sgwithInsCount = [];
        this.filteredPortData = [];
        this.instantWithLastBackup = [];
        this.instantdetaillist = [];
        this.sgWithIns = [];
        this.ri_currentPage = 1;
       this.ri_itemsPerPage = 5;
       this.ri_lastIndex = 0;
       this.ri_loader = true;
       this.riNotFound = false;
       this.riData=[];
       this.riErrMsg='No Recommendations Found';
       this.riFound=false;
       this.scanLoader =false;
       this.snapSinceDays=[];
       this.createJobScanner=false;
       this.checkJobStatusCounter=0;
        setTimeout(() => {
          this.load();
        }, 2000);
      }
      if (d.key == "regionId") {
        this.notifier.loading(true);
        this.regionId = d.value;
        this.averageRetention = [];
        this.instanceAttachtedToSG = [];
        this.ebsList = [];
        this.ebswithName = [];
        this.openPorts = [];
        this.vpcList = [];
        this.vpcListWithFreeIp = [];
        this.SGList = [];
        this.backupList = [];
        this.snap_loader = true;
        this.snapNotFound = false;
        this.ins_loader = true;
        this.insNotFound = false;
        this.backup_loader = true;
        this.backupNotFound = false;
        this.open_loader = true;
        this.openNotFound = false;
        this.vpc_loader = true;
        this.vpcNotFound = false;
        this.sg_loader = true;
        this.sgNotFound = false;
        this.avg_loader = true;
        this.avgNotFound = false;
        this.sortedByDays = "asc";
        this.sortIcon = "fa-arrow-down-wide-short";
        this.currentPage = 1;
        this.itemsPerPage = 5;
        this.lastIndex = 0;
        this.ins_currentPage = 1;
        this.ins_itemsPerPage = 5;
        this.ins_lastIndex = 0;
        this.snap_currentPage = 1;
        this.snap_itemsPerPage = 5;
        this.snap_lastIndex = 0;
        this.open_currentPage = 1;
        this.open_itemsPerPage = 5;
        this.open_lastIndex = 0;
        this.vpc_currentPage = 1;
        this.vpc_itemsPerPage = 5;
        this.vpc_lastIndex = 0;
        this.sg_currentPage = 1;
        this.sg_itemsPerPage = 5;
        this.sg_lastIndex = 0;
        this.backup_currentPage = 1;
        this.backup_itemsPerPage = 5;
        this.backup_lastIndex = 0;
        this.snapSizeData = [];
        this.volumeSizeData = [];
        this.snapSizeLoader = true;
        this.volSizeLoader = true;
        this.snapLoad = false;
        this.volLoad = false;
        this.snapData = [];
        this.volumeData = [];
        this.iterationCount = 0;
        this.loopDone = false;
        this.volumeNotFound = false;
        this.SnapEbsNotFound = false;
        this.scanNotCompletedCount = 0;
        this.scanInProgress = false;
        this.portErrMsg = "No Data Found!";
        this.sgErrMsg = "No Data Found!";
        this.insErrMsg = "No Data Found!";
        this.snapErrMsg = "No Data Found!";
        this.retenErrMsg = "No Data Found!";
        this.backupErrMsg = "No Data Found!";
        this.vpcErrMsg = "No Data Found!";
        this.nameCount = {};
        this.sgwithInsCount = [];
        this.filteredPortData = [];
        this.instantWithLastBackup = [];
        this.instantdetaillist = [];
        this.sgWithIns = [];
        this.ri_currentPage = 1;
        this.ri_itemsPerPage = 5;
        this.ri_lastIndex = 0;
        this.ri_loader = true;
        this.riNotFound = false;
        this.riData=[];
        this.riErrMsg='No Recommendations Found';
        this.riFound=false;
        this.scanLoader =false;
        this.snapSinceDays=[];
        this.createJobScanner=false;
        this.checkJobStatusCounter=0;
        setTimeout(() => {
          this.load();
        }, 2000);
      }
    });
  }

  onResize(event: any) {
    //  let currentUrl = this.router.url;
    if (this.resizeStart == false) {
      this.resizeStart = true;
      this.notifier.loading(true);

      setTimeout(() => {
        if (this.chart) {
          this.chart.resize();
        }

        if (this.chartvol) {
          this.chartvol.resize();
        }

        this.notifier.loading(false);

        //this.router.navigate([currentUrl]);
        //window.location.href = "/dash/home";
        this.resizeStart = false;
      }, 500);
    }
  }

  load() {
    // this.createJob();
    this.loadInstances();
    this.loadInstances2();
    //  this.loadInstances();
    // this.loadSGData();
    this.loadOpenPortsData();
    this.loadvpcData();
    this.loadAttachedToSGData();
    this.loadSnalshotList();
   // this.loadRetentionData();
    this.loadBackupData();
    this.getRiRecomendation();
    this.notifier.loading(false);
  }

  //EC2 attached to SG

  async loadAttachedToSGData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/count_of_ec2_attached_to_sg/?list_of_account_numbers=${this.accountId}&user_type=internal&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          this.instanceAttachtedToSG = Object.entries(
            data["accounts_ids"][this.accountId]["list_of_ec2_attached_to_sg"]
          ).map(([key, value]) => {
            return { instance: key, sg: value };
          });

          // setTimeout(() => {

          this.instanceAttachtedToSG.forEach((ins: any) => {
            ins.sg.sg_info.forEach((sg: any) => {
              if (sg.sg_name in this.nameCount) {
                this.nameCount[sg.sg_name]++;
              } else {
                this.nameCount[sg.sg_name] = 1;
              }
            });
          });

          this.sgwithInsCount = Object.entries(this.nameCount)
            .filter(([key, value]) => value != 1)
            .map(([key, value]) => {
              return { sgName: key, insCount: value };
            });

          this.sgwithInsCount.forEach((sg: any) => {
            const instalist: any = [];
            this.instanceAttachtedToSG.forEach((ins: any) => {
              ins.sg.sg_info.forEach((info: any) => {
                if (info.sg_name == sg.sgName) {
                  instalist.push(ins);
                  return;
                }
              });
            });
            this.sgWithIns.push({ sg, instalist });
          });

          if (this.sgwithInsCount.length == 0) {
            this.insNotFound = true;
          }

          // },2000);
        } catch (error) {
          // this.notifier.alert('Error', '', data.detail, 'error', 5000);
          // if (data != "Internal Server Error") {
          //   this.insErrMsg = data.detail;
          // }
          this.ins_loader = false;
          this.insNotFound = true;
        }
      });
    } catch (error) {
      this.ins_loader = false;
      this.insNotFound = true;
    }
    if (this.instanceAttachtedToSG.length === 0) {
      this.ins_loader = false;
      this.insNotFound = true;
    }
    this.ins_loader = false;
  }

  getDisplayInstanceData() {
    this.sgWithIns.sort((a: any, b: any) => {
      const aDays = parseInt(a.sg.insCount);
      const bDays = parseInt(b.sg.insCount);
      return bDays - aDays;
    });
    const startIndex = (this.ins_currentPage - 1) * this.ins_itemsPerPage;
    const endIndex = startIndex + this.ins_itemsPerPage;
    return this.sgWithIns.slice(startIndex, endIndex);
  }

  ins_onNext() {
    this.ins_currentPage = this.ins_currentPage + 1;
    this.ins_lastIndex++;
  }
  ins_onPrev() {
    this.ins_currentPage = this.ins_currentPage - 1;
    this.ins_lastIndex--;
  }
  sendInsData(ins: any) {
    this.instanceAttachtedToSG = ins;
    this.InstViewTrigger = true;
  }
  //Average Retention Time
  async loadRetentionData() {
    if(this.snapSinceDays.length!=0){
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/list_of_average_retention_time_v2/?list_of_account_numbers=${this.accountId}&user_type=internal&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          this.averageRetention = Object.entries(
            data["accounts_ids"][this.accountId]
          ).map(([key, value]) => {
            return { name: key, duration: value };
          });

          this.averageRetention.forEach((durationObj:any) => { 
            const instanceName = durationObj.duration.instance_name; 
            const matchingInsObj = this.snapSinceDays.find((insObj:any) => insObj.insName === instanceName);
             if (matchingInsObj) { 
              durationObj.duration.snap = matchingInsObj.snap;
             } });

          //  console.log(this.averageRetention);
        } catch (error) {
          // this.notifier.alert('Error', '', data.detail, 'error', 5000);
          this.retenErrMsg = data.detail;
          this.avg_loader = false;
          this.avgNotFound = true;
        }
      });
    } catch (error) {
      this.avg_loader = false;
      this.avgNotFound = true;
    }

    if (this.averageRetention.length === 0) {
      this.avg_loader = false;
      this.avgNotFound = true;
    }
  }else{
    this.avg_loader = false;
    this.avgNotFound = true;
  }
    this.avg_loader = false;
  }

  getRetentionTimeDuration(retention_time: any) {
    const parts = retention_time.split(" ");
    const days = parseInt(parts[0]) || 0;
    const hours = parseInt(parts[3]) || 0;
    const minutes = parseInt(parts[5]) || 0;
    const seconds = parseInt(parts[7]) || 0;
    return days * 86400 + hours * 3600 + minutes * 60 + seconds;
  }

  sortSnaps() {
    if (this.sortedByDays === "asc") {
      this.sortedByDays = "desc";
      this.sortIcon = "fa-arrow-down-wide-short";
    } else {
      this.sortedByDays = "asc";
      this.sortIcon = "fa-arrow-up-wide-short";
    }
  }

  getDisplayedItems() {
    if (this.sortedByDays === "asc") {
      this.averageRetention.sort((a: any, b: any) => {
        const aDays: any = this.getRetentionTimeDuration(
          a.duration.retention_time
        );
        const bDays: any = this.getRetentionTimeDuration(
          b.duration.retention_time
        );
        return aDays - bDays;
      });
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.averageRetention.slice(startIndex, endIndex);
    } else {
      this.averageRetention.reverse();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.averageRetention.slice(startIndex, endIndex);
    }
  }

  //for snapshot since days
  getDisplayedItems2() {
    if (this.sortedByDays === "asc") {
      this.snapSinceDays.sort((a: any, b: any) => {
        const aDays: any = a.snap;
        const bDays: any = b.snap;
        return aDays - bDays;
      });
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.snapSinceDays.slice(startIndex, endIndex);
    } else {
      this.snapSinceDays.reverse();
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.snapSinceDays.slice(startIndex, endIndex);
    }
  }

  onNext() {
    this.currentPage = this.currentPage + 1;
    this.lastIndex++;
  }
  onPrev() {
    this.currentPage = this.currentPage - 1;
    this.lastIndex--;
  }

  //snapshot List
  async loadSnalshotList() {
    if (!this.nextToken) {
      this.ebsList = [];
    }
    let data = {
      accountId: this.accountId,
      region: this.regionId,
      action: "fetchVolumeDetailsWithPagination",
      nextToken: this.nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ebs`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.size === 0) {
      this.snap_loader = false;
      this.snapNotFound = true;
    }

    if (result.status == "1" || result.s == "1") {
      if (result.encryptedVolumeList) {
        this.ebsList = [...this.ebsList, ...result.encryptedVolumeList];

        setTimeout(() => {
          this.ebsList.forEach((element: any) => {
            this.instances.forEach((instance: any) => {
              let instantName = "";
              if (element.instanceId === instance.instance_id) {
                instantName = instance.instance_name;
                this.ebswithName.push({ element, instantName });
              }
            });
          });
        }, 2000);
      }
    } else {
      //  this.notifier.alert('Error', '', result.error, 'error', 5000);
      // this.snapErrMsg = result.error;
      this.snap_loader = false;
      this.snapNotFound = true;
    }

    if (result.nextToken) {
      this.nextToken = result.nextToken;
      // this.loadSnalshotList();
    } else {
      this.nextToken = null;
      this.snap_loader = false;
    }
    this.snap_loader = false;
  }

  //   getDisplaySnapshotData() {
  //    // setTimeout(()=>{
  //     const startIndex = (this.snap_currentPage - 1) * this.snap_itemsPerPage;
  //     const endIndex = startIndex + this.snap_itemsPerPage;
  //     return this.instantWithLastBackup.slice(startIndex, endIndex);
  //  // },2000);
  //   }
  //   snap_onNext() {
  //     this.snap_currentPage = this.snap_currentPage + 1
  //     this.snap_lastIndex++;
  //   }
  //   snap_onPrev() {
  //     this.snap_currentPage = this.snap_currentPage - 1
  //     this.snap_lastIndex--;
  //   }

  //Open Ports

  async loadOpenPortsData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };
    
    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/list_of_inbound_ports_of_security_groups/?list_of_account_numbers=${this.accountId}&user_type=internal&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          this.openPorts = Object.entries(
            data["accounts_ids"][this.accountId]["security_group_inbound_ports"]
          ).map(([key, value]) => {
            return { sg: key, port: value };
          });
          this.openPorts.forEach((p: any) => {
            const filteredPorts = p.port.port
              .filter((po: any) => po !== 80 && po !== 443)
              .map((po: any) => (po === -1 ? "all traffic" : po));
           // this.filteredPortData.push({ p, filteredPorts });
            if (filteredPorts.length > 0) { 
              this.filteredPortData.push({ p, filteredPorts }); 
            } 
          });
          
          
        } catch (error) {
          this.portErrMsg = data.detail;
          //  this.notifier.alert('Error', '', data.detail, 'error', 5000);
          this.open_loader = false;
          this.openNotFound = true;
        }
      });
    } catch (error) {
      this.open_loader = false;
      this.openNotFound = true;
    }
    if (this.openPorts.length === 0) {
      this.open_loader = false;
      this.openNotFound = true;
    }
    this.open_loader = false;
  }

  getDisplayPortsData() {
    const startIndex = (this.open_currentPage - 1) * this.open_itemsPerPage;
    const endIndex = startIndex + this.open_itemsPerPage;
    return this.filteredPortData.slice(startIndex, endIndex);
  }
  open_onNext() {
    this.open_currentPage = this.open_currentPage + 1;
    this.open_lastIndex++;
  }
  open_onPrev() {
    this.open_currentPage = this.open_currentPage - 1;
    this.open_lastIndex--;
  }

  //VPC

  async loadvpcData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/count_of_total_vpcs_in_account/?list_of_account_numbers=${this.accountId}&user_type=internal&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          this.vpcList = Object.entries(
            data["accounts_ids"][this.accountId]["info_of_vpcs"]
          ).map(([key, value]) => {
            return { vpc: key, cidr: value };
          });

          this.vpcList.forEach((element: any) => {
            let publicIp = 0;
            let privateIp = 0;
            let total = 0;
            if (element.cidr.public_subnets.length != 0) {
              element.cidr.public_subnets.forEach((el: any) => {
                publicIp += el.free_ips;
              });
            }
            if (element.cidr.private_subnets.length != 0) {
              element.cidr.private_subnets.forEach((el: any) => {
                privateIp += el.free_ips;
              });
            }
            total = publicIp + privateIp;
            this.vpcListWithFreeIp.push({ element, total });
          });
        } catch (error) {
          //   this.notifier.alert('Error', '', data.detail, 'error', 5000);
          this.vpcErrMsg = data.detail;
          this.vpc_loader = false;
          this.vpcNotFound = true;
        }
      });
    } catch (error) {
      this.vpc_loader = false;
      this.vpcNotFound = true;
    }
    if (this.vpcList.length === 0) {
      this.vpc_loader = false;
      this.vpcNotFound = true;
    }
    this.vpc_loader = false;
  }

  getDisplayVPCData() {
    const startIndex = (this.vpc_currentPage - 1) * this.vpc_itemsPerPage;
    const endIndex = startIndex + this.vpc_itemsPerPage;
    return this.vpcListWithFreeIp.slice(startIndex, endIndex);
  }
  vpc_onNext() {
    this.vpc_currentPage = this.vpc_currentPage + 1;
    this.vpc_lastIndex++;
  }
  vpc_onPrev() {
    this.vpc_currentPage = this.vpc_currentPage - 1;
    this.vpc_lastIndex--;
  }

  sendVPCData(vpc: any) {
    if (vpc.length != 0) {
      this.vpcList = vpc;
      this.vpcViewTrigger = true;
    }
  }

  //No of SG

  async loadSGData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/count_of_security_groups_in_accounts/?list_of_account_numbers=${this.accountId}&user_type=internal&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          this.SGList = Object.entries(
            data["accounts_ids"][this.accountId]["info_of_sgs"]
          ).map(([key, value]) => {
            return { sgID: key, sgName: value };
          });
        } catch (error) {
          // this.notifier.alert('Error', '', data.detail, 'error', 5000);
          this.sgErrMsg = data.detail;
          this.sg_loader = false;
          this.sgNotFound = true;
        }
      });
    } catch (error) {
      this.sg_loader = false;
      this.sgNotFound = true;
    }

    if (this.SGList.length === 0) {
      this.sg_loader = false;
      this.sgNotFound = true;
    }
    this.sg_loader = false;
  }

  getDisplaySGData() {
    const startIndex = (this.sg_currentPage - 1) * this.sg_itemsPerPage;
    const endIndex = startIndex + this.sg_itemsPerPage;
    return this.SGList.slice(startIndex, endIndex);
  }
  sg_onNext() {
    this.sg_currentPage = this.sg_currentPage + 1;
    this.sg_lastIndex++;
  }
  sg_onPrev() {
    this.sg_currentPage = this.sg_currentPage - 1;
    this.sg_lastIndex--;
  }

  //Instances without Backup

  async loadBackupData() {
    let data = {};

    let header = {
      Authorization: "Bearer " + localStorage.getItem("t"),
    };

    let apiURL = `https://apis-eks.swayam.cloud/dashboard/security/count_of_ec2_instances_without_backup/?list_of_account_numbers=${this.accountId}&user_type=client&aws_region=${this.regionId}`;

    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        try {
          this.backupList = Object.entries(
            data["accounts_ids"][this.accountId][
              "info_of_instances_without_backup"
            ]
          ).map(([key, value]) => {
            return { id: key, backup: value };
          });
        } catch (error) {
          //  this.notifier.alert('Error', '', data.detail, 'error', 5000);
          // if (data != "Internal Server Error") {
          //   this.backupErrMsg = data.detail;
          // }
          this.backup_loader = false;
          this.backupNotFound = true;
        }
      });
    } catch (error) {
      this.backup_loader = false;
      this.backupNotFound = true;
    }
    if (this.backupList.length === 0) {
      this.backup_loader = false;
      this.backupNotFound = true;
    }
    this.backup_loader = false;
  }

  getDisplayBackupData() {
    const startIndex = (this.backup_currentPage - 1) * this.backup_itemsPerPage;
    const endIndex = startIndex + this.backup_itemsPerPage;
    return this.backupList.slice(startIndex, endIndex);
  }
  backup_onNext() {
    this.backup_currentPage = this.backup_currentPage + 1;
    this.backup_lastIndex++;
  }
  backup_onPrev() {
    this.backup_currentPage = this.backup_currentPage - 1;
    this.backup_lastIndex--;
  }
  //SG
  viewSG(event: any) {
    this.SGViewTrigger = true;
    this.shouldHideOverflow = true;
  }
  hideViewSG(event: any) {
    this.SGViewTrigger = null;
    this.shouldHideOverflow = false;
  }
  //Open Ports
  viewPort(event: any) {
    this.PortViewTrigger = true;
  }
  hideViewPort(event: any) {
    this.PortViewTrigger = null;
  }

  //snapshot
  viewSnap(event: any) {
    this.SnapViewTrigger = true;
  }
  hideSnap(event: any) {
    this.SnapViewTrigger = null;
  }

  //instant
  viewIns(event: any) {
    this.InstViewTrigger = true;
  }
  hideIns(event: any) {
    this.InstViewTrigger = null;
  }

  //retent
  viewRet(event: any) {
    this.retenViewTrigger = true;
  }
  hideRet(event: any) {
    this.retenViewTrigger = null;
  }

  //backup
  viewBackup(event: any) {
    this.backupViewTrigger = true;
  }
  hideBackup(event: any) {
    this.backupViewTrigger = null;
  }
  //vpc
  viewVpc(event: any) {
    this.vpcViewTrigger = true;
  }
  hideVpc(event: any) {
    this.vpcViewTrigger = null;
  }

  //snapshot create job
  getURl() {
    return `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;
  }

  getData() {
    return {
      action: "create_jobs",
      region_name: this.regionId,
      account_id: this.accountId,
    };
  }
  async createJob() {
    this.createJobScanner=true;
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "create_jobs",
      region_name: this.regionId,
      account_id: this.accountId,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;
    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || result.s == "1") {
      result.message = result.message || result.msg;
      setTimeout(() => {
        this.loadInstances();
        this.loadInstances2();
      }, 2000);
     
    } else {
    }
    // this.loadInstances();
    // this.loadInstances2();
  }

  snapReload() {
    this.snapLoad = true;
    this.iterationCount = 0;
    this.snapSizeData = [];
    this.volumeSizeData = [];
    this.loadInstancesnap();
  }

  volReload() {
    this.volLoad = true;
    this.iterationCount = 0;
    this.snapSizeData = [];
    this.volumeSizeData = [];
    this.loadInstancesvol();
  }

  async loadInstances(nextToken: any = "") {
    this.createJobScanner=false;
    this.checkJobStatusCounter=this.checkJobStatusCounter+1;
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    this.scanNotCompletedCount = 0;
    if (result.status == "1" || result.s == "1") {
      this.instances = result.items;

      if (this.instances.length != 0) {
        const totalProperties = Object.keys(this.instances).length;
        await this.instances.forEach((instance: any) => {
          if (instance.job_status != "scan completed") {
            this.scanNotCompletedCount++;
          }
          this.iterationCount++;
          if (instance.job_status === "scan completed") {
            this.getSnapshotWithId2(
              instance.instance_id,
              instance.instance_name
            );
          } else {
          }
          if (this.iterationCount === totalProperties) {
            this.loopDone = true;
          }
        });
        setTimeout(() => {
          if (this.scanNotCompletedCount == totalProperties) {
            this.scanInProgress = true;
            this.snapSizeLoader = false;
            this.volSizeLoader = false;
          } else {
            this.scanInProgress = false;
            this.snapSizeLoader = false;
            this.volSizeLoader = false;  
          }
        }, 2000);
      } else {
        this.snapSizeLoader = false;
        this.volSizeLoader = false;
        this.volumeNotFound = true;
        this.SnapEbsNotFound = true;
        this.avg_loader = false;
        this.avgNotFound = true;
      }

      if (this.loopDone) {
        setTimeout(() => {
          this.sortingSnapshot();
          this.sortingVolumes();
          setTimeout(() => {
            this.loadSnapshotGraph(this.snapSizeData.slice(0, 10));

            this.loadVolumeGraph(this.volumeSizeData.slice(0, 10));
            this.loadRetentionData();
          }, 3000);
        }, 2000);
      }
    } else {
      this.snapSizeLoader = false;
      this.volSizeLoader = false;
    }
  }

  //----------------------------for 2days
  async loadInstances2(nextToken: any = "") {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    this.scanNotCompletedCount = 0;
    if (result.status == "1" || result.s == "1") {
      this.instances = result.items;

      if (this.instances.length != 0) {
        const totalProperties = Object.keys(this.instances).length;
        await this.instances.forEach((instance: any) => {
          if (instance.job_status != "scan completed") {
            this.scanNotCompletedCount++;
          }
          this.iterationCount++;
          if (instance.job_status === "scan completed") {
            this.getSnapshotWithId(
              instance.instance_id,
              instance.instance_name
            );
          } else {
          }
          if (this.iterationCount === totalProperties) {
            this.loopDone = true;
          }
        });
      } else {
      }
    } else {
      //for 2days
      this.snap_loader = false;
      this.snapNotFound = false;
    }
  }

  //--------------------------------

  async loadInstancesvol(nextToken: any = "") {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    this.scanNotCompletedCount = 0;
    if (result.status == "1" || result.s == "1") {
      this.instances = result.items;

      if (this.instances.length != 0) {
        const totalProperties = Object.keys(this.instances).length;
        await this.instances.forEach((instance: any) => {
          if (instance.job_status != "scan completed") {
            this.scanNotCompletedCount++;
          }

          this.iterationCount++;
          if (instance.job_status === "scan completed") {
            this.getSnapshotWithId2(
              instance.instance_id,
              instance.instance_name
            );
          } else {
          }
          if (this.iterationCount === totalProperties) {
            this.loopDone = true;
          }
        });
        setTimeout(() => {
          if (this.scanNotCompletedCount == totalProperties) {
            this.scanInProgress = true;
            this.volSizeLoader = false;
          } else {
            this.scanInProgress = false;
            this.volSizeLoader = false;
          }
        }, 2000);
      } else {
        this.volLoad = false;
      }

      if (this.loopDone) {
        setTimeout(() => {
          // this.sortingSnapshot();
          this.sortingVolumes();
          setTimeout(() => {
            //  this.loadSnapshotGraph(this.snapSizeData.slice(0,10));

            this.loadVolumeGraph(this.volumeSizeData.slice(0, 10));
          }, 3000);
        }, 2000);
      }
    } else {
      this.snapSizeLoader = false;
      this.volSizeLoader = false;
    }
  }

  async loadInstancesnap(nextToken: any = "") {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    this.scanNotCompletedCount = 0;
    if (result.status == "1" || result.s == "1") {
      this.instances = result.items;

      if (this.instances.length != 0) {
        const totalProperties = Object.keys(this.instances).length;
        await this.instances.forEach((instance: any) => {
          if (instance.job_status != "scan completed") {
            this.scanNotCompletedCount++;
          }

          this.iterationCount++;
          if (instance.job_status === "scan completed") {
            this.getSnapshotWithId2(
              instance.instance_id,
              instance.instance_name
            );
          } else {
          }
          if (this.iterationCount === totalProperties) {
            this.loopDone = true;
          }
        });

        setTimeout(() => {
          if (this.scanNotCompletedCount == totalProperties) {
            this.scanInProgress = true;
            this.snapLoad = false;
          } else {
            this.scanInProgress = false;
            this.snapLoad = false;
          }
        }, 2000);
      } else {
        this.snapLoad = false;
      }

      if (this.loopDone) {
        setTimeout(() => {
          this.sortingSnapshot();
          //this.sortingVolumes();
          setTimeout(() => {
            this.loadSnapshotGraph(this.snapSizeData.slice(0, 10));
            // this.loadVolumeGraph(this.volumeSizeData.slice(0,10));
          }, 3000);
        }, 2000);
      }
    } else {
      this.snapSizeLoader = false;
      this.volSizeLoader = false;
    }
  }

  // this.snap_loader = false;
  // this.snapNotFound = true;

  //------------------------------

  async getSnapshotWithId2(id: string, insName: string) {
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      instance_id: id,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
     
      if (result.items[0].volumes != undefined) {
        const snap =  result.items[0].volumes[0].snapshot_since;
        let snapCount = 0;
        let volumeCount = 0;
        result.items[0].volumes.forEach((volume: any) => {
          snapCount += parseInt(volume.total_snapshots);
          volumeCount += parseInt(volume.volume_size);

          //for Instances with last snapshot older than 2 days
        });
        if (insName === "") {
          this.snapSizeData.push({ id, snapCount });
          this.volumeSizeData.push({ id, volumeCount });
          if(snap!='NA'){
          this.snapSinceDays.push({insNam:id,snap});
          }
        } else {
          this.snapSizeData.push({ insName, snapCount });
          this.volumeSizeData.push({ insName, volumeCount });
          if(snap!='NA'){
          this.snapSinceDays.push({insName:insName,snap});
          }
        }
      } else {
      }
    } else {
    }
  }

  async getSnapshotWithId(id: string, insName: string) {
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      instance_id: id,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.items[0].volumes != undefined) {
        // let snapCount = 0;
        // let volumeCount = 0;
        result.items[0].volumes.forEach((volume: any) => {
          // snapCount += parseInt(volume.total_snapshots);
          // volumeCount += parseInt(volume.volume_size);

          //for Instances with last snapshot older than 2 days
          if (volume.lastest_created_time != "NA") {
            const date = moment(
              volume.lastest_created_time,
              "YYYY-MM-DD,HH:mm:ss"
            );
            const formattedDate = date.format("YYYY-MM-DD,HH:mm:ss");
            const currentDate = moment();
            const diffInDays = currentDate.diff(date, "days");
            const latestSnapId = volume.latest_snapshot_id;
            if (diffInDays > 2) {
              this.instantWithLastBackup.push({
                insName,
                formattedDate,
                id,
                latestSnapId,
              });
            }
          }
        });
        // if (insName === "") {
        //   this.snapSizeData.push({ id, snapCount });
        //   this.volumeSizeData.push({ id, volumeCount });
        // } else {
        //   this.snapSizeData.push({ insName, snapCount });
        //   this.volumeSizeData.push({ insName, volumeCount });
        // }
      } else {
      }
    } else {
    }

    setTimeout(() => {
      if (this.instantWithLastBackup.length == 0) {
        this.snapNotFound = true;
      }
    }, 2000);
  }

  getDisplaySnapshotData() {
    // setTimeout(()=>{
    const startIndex = (this.snap_currentPage - 1) * this.snap_itemsPerPage;
    const endIndex = startIndex + this.snap_itemsPerPage;
    return this.instantWithLastBackup.slice(startIndex, endIndex);
    // },2000);
  }
  snap_onNext() {
    this.snap_currentPage = this.snap_currentPage + 1;
    this.snap_lastIndex++;
  }
  snap_onPrev() {
    this.snap_currentPage = this.snap_currentPage - 1;
    this.snap_lastIndex--;
  }

  sortingSnapshot() {
    //this.snapSizeData.sort((a:any, b:any) => a.snapCount - b.snapCount))
    this.snapSizeData.sort(function (a: any, b: any) {
      return b.snapCount - a.snapCount;
    });
  }

  sortingVolumes() {
    //this.snapSizeData.sort((a:any, b:any) => a.snapCount - b.snapCount))
    this.volumeSizeData.sort(function (a: any, b: any) {
      return b.volumeCount - a.volumeCount;
    });
  }

  loadSnapshotGraph(obj: any) {
    this.snapSizeLoader = false;
    this.snapLoad = false;
    this.snapData = [];

    for (let i = 0; i < obj.length; i++) {
      this.snapData.push({
        name: obj[i].insName === undefined ? obj[i].id : obj[i].insName,
        value: obj[i].snapCount,
      });
    }

    this.chart = echarts.init(document.getElementById("snapGraph"));
    //  var myChart = echarts.init(chartDom);

    let option = {
      color: [
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
        "#65ffaf",
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
      ],
      tooltip: {
        formatter: (params: any) => {
          // Get the name, value, and color of the current slice
          const name = params.name;
          const value = params.value;
          const color = params.color;

          // Create HTML code for the tooltip
          const html = `
          <div>
          <span class="ml-5">${name} &nbsp;&nbsp; <b>${value}</b></span>
        </div>
          `;

          // Return the HTML code
          return html;
        },
      },
      legend: {
        top: "3%",
        right: "5%",

        orient: "vertical",
      },
      series: [
        {
          type: "pie",
          data: this.snapData,
          radius: "90%",
          right: "47%",
          bottom: "10%",
          label: {
            show: false,
            position: "center",
          },

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };

    this.chart.setOption(option);
  }

  loadVolumeGraph(obj: any) {
    this.volSizeLoader = false;
    this.volLoad = false;

    this.volumeData = [];
    for (let i = 0; i < obj.length; i++) {
      this.volumeData.push({
        name: obj[i].insName === undefined ? obj[i].id : obj[i].insName,
        value: obj[i].volumeCount,
      });
    }

    this.chartvol = echarts.init(document.getElementById("volumeGraph"));
    // var chartDom = document.getElementById('volumeGraph');
    // var myChart = echarts.init(chartDom);

    let option = {
      color: [
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
        "#65ffaf",
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
      ],
      tooltip: {
        formatter: (params: any) => {
          // Get the name, value, and color of the current slice
          const name = params.name;
          const value = params.value;
          const color = params.color;

          // Create HTML code for the tooltip
          const html = `
          <div>
          <span class="ml-5">${name} &nbsp;&nbsp; <b>${value}&nbsp;GB</b></span>
        </div>
          `;

          // Return the HTML code
          return html;
        },
      },
      legend: {
        top: "3%",
        right: "5%",

        orient: "vertical",
      },
      series: [
        {
          type: "pie",
          data: this.volumeData,
          radius: "90%",
          right: "47%",
          bottom: "10%",
          label: {
            show: false,
            position: "center",
          },

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };

    this.chartvol.setOption(option);
  }

  async getRiRecomendation(){
    let data: any = {
      a: "fetch",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2ri`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || result.s == "1") {
      if (result.data && result.data.length > 0) {
        this.riData=result['data'];
      }
    }else{
      this.riNotFound=true;
    }
    this.ri_loader=false;
  }

  getDisplayRIData() {
    const startIndex = (this.ri_currentPage - 1) * this.ri_itemsPerPage;
    const endIndex = startIndex + this.ri_itemsPerPage;
    return this.riData.slice(startIndex, endIndex);
  }
  ri_onNext() {
    this.ri_currentPage = this.ri_currentPage + 1;
    this.ri_lastIndex++;
  }
  ri_onPrev() {
    this.ri_currentPage = this.ri_currentPage - 1;
    this.ri_lastIndex--;
  }
  fetchLow(instance: any) {
    return Math.min(
      ...instance.pricing.riPricing.map((ri: any) => {
        return ri["diffPerMonth"];
      })
    ).toFixed(2);
  }
  fetchHigh(instance: any) {
    if (instance["pricing"]["spotPricing"]) {
      return Number(
        Number(instance["pricing"]["spotPricing"]["diffPerMonth"]).toFixed(2)
      );
    }
    return Math.max(
      ...instance.pricing.riPricing.map((ri: any) => {
        return ri["diffPerMonth"];
      })
    ).toFixed(2);
  }

  async createScan(){
    this.scanLoader=true;
    let data: any = {
      a: "scan",
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2ri`;
    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == "1" || result.s == "1") {
      this.fetchRiAfterScan();
    }

  }

  async fetchRiAfterScan(){
    let data: any = {
      a: "fetch",
      accountId: this.accountId,
      region: this.regionId,
    };

    let interval: any = setInterval(async () => {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2ri`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {

      if(result.scanStatus==='Completed'){
        if (result.data && result.data.length > 0) {
          this.riData=result['data'];
          this.scanLoader=false;
          this.riNotFound=false;
        }
        clearInterval(interval);

      }

    }else{
      if(result.error){
        this.scanLoader=false;
        this.riFound=true;
      }
      clearInterval(interval);
    }
  }, 2000);


  }

  async loadInsId(nextToken: any = "") {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "snapshots",
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
   // this.scanNotCompletedCount = 0;
    if (result.status == "1" || result.s == "1") {
      result.items;

    
    }
  }


  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
