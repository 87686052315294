import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-enable-cur",
  templateUrl: "./enable-cur.component.html",
  styleUrls: ["./enable-cur.component.css"],
})
export class EnableCurComponent implements OnInit {
  @Output("hideModal") hideModal = new EventEmitter<boolean>();
  @Input('data') data: any;

  enableCUR: boolean = false;
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  ngOnInit(): void {}
}
