import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";

@Component({
  selector: "app-threat-resources",
  templateUrl: "./threat-resources.component.html",
  styleUrls: ["./threat-resources.component.css"],
})
export class ThreatResourcesComponent implements OnInit {
  @Input("resources") resources: any;
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();

  constructor(public notifier: NotifierService) {}

  headers: any = [
    {
      id: "name",
      name: "Resource",
      filter: true,
    },
    {
      id: "count",
      name: "Failed Checks",
      sort: { sort: true, direction: "Asc" },
      center: true,
    },
  ];

  ngOnInit(): void {}
}
