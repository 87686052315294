import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  NgZone,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { categories } from "../../../../assets/category";

import { APIService } from "./../../../api/api.service";
import { NotifierService } from "./../../../_services/notifier.service";
import { environment } from "src/environments/environment";
declare let window: any;
declare let $: any;
@Component({
  templateUrl: "./create.component.html",
  styleUrls: ["./ticket.component.css", "./create.component.css"],
})
export class BetaCreateTicketComponent implements OnInit, OnDestroy {
  @ViewChild("attachmenrs") attachmentElement: ElementRef;
  uid = localStorage.getItem("t");
  t = localStorage.getItem("t");
  ut = localStorage.getItem("ut");
  eId = localStorage.getItem("eId");

  additionalContacts: any = [this.eId, ""];
  ticketSeverity: any = "low";
  supportType?: any = "minfy";
  ticketDescription?: any = "";
  ticketSubject?: any = "";
  strictTicketType?: boolean = false;
  @Input() createTicketSubject: string;
  @Input() createTicketDescription: string;
  @Input() createTicketSupportType: string;
  @Input() createTicketStrictTicketType: boolean;
  @Output() hideCreateTicket = new EventEmitter<boolean>();
  customerType: string = localStorage.getItem("customerType") || "admin";
  attachment_ids: any = [];
  minfyDisabled: boolean = false;
  awsDisabled: boolean = false;
  services: any = [];

  loading = true;
  service: any;
  showDetails = false;
  accountId = "";
  region = "";
  action: string;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";

  attachedfileData: any = [];

  minfyTicketCategories: any;

  categoriesList: any;
  categorySelected: any;
  serviceSelected: any;
  errors: any = {
    subject: {
      is_valid: true,
      message: "",
    },
    contacts: {
      is_valid: true,
      message: "",
    },
    description: {
      is_valid: true,
      message: "",
    },
    category: {
      is_valid: true,
      message: "",
    },
    service: {
      is_valid: true,
      message: "",
    },
  };

  mainService: any = categories;

  currentMessage: any;
  minfyTicketServices: any;
  minfyTicketServiceSelected: string;
  minfyTicketCategorySelected: any;
  minfyTicketCategorySelectedText: any;
  minfyTicketServiceSelectedText: any;
  modalTitle: string;
  command: string;
  replacers: any = [
    {
      prev: "SerCha",
      later: "Server",
    },
    {
      prev: "ErrCha",
      later: "Error",
    },
  ];

  whitelabelData: any = {
    pd: {
      appName: "",
      logoDark: "",
      logoLight: "",
    },
  };

  constructor(
    private apiServer: APIService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService
  ) {
    this.whitelabelData = this.route.snapshot.data.data;
  }

  selectedCloudPartner: string | null = localStorage.getItem('cp-selected');
  resourceGroup: string = localStorage.getItem("selectedResourceGroup");

  ngOnInit() {
    if ((this.customerType == "mp" || this.whitelabelData['pd']['appName'].toLowerCase() != 'swayam') && this.selectedCloudPartner == 'aws') {
      this.changeSupportType("aws");
    }
    Object.keys(this.mainService).forEach((key: any) => {
      Object.keys(this.mainService[key]).forEach((key_: any) => {
        let check: boolean = false;
        let temp_: string;
        this.replacers.forEach((rep: any) => {
          if (check) {
            if (temp_.indexOf(rep["prev"]) > -1) {
              let temp = temp_.replace(rep["prev"], rep["later"]);
              this.mainService[key][temp] = this.mainService[key][temp_];
              delete this.mainService[key][temp_];
              temp_ = temp;
              check = true;
            }
          } else {
            if (key_.indexOf(rep["prev"]) > -1) {
              temp_ = key_.replace(rep["prev"], rep["later"]);
              this.mainService[key][temp_] = this.mainService[key][key_];
              check = true;
            }
          }
        });
        if (check) {
          delete this.mainService[key][key_];
        }
      });
    });

    // console.log(this.mainService);

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.addToFileList(docName, fileSize, targetDocBase64),
      component: this,
    };

    this.accountId = localStorage.getItem("accountId");
    this.region = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");

      if (d.key == "accountId" && this.selectedCloudPartner == 'aws') {
        this.accountId = d.value;
        if (this.region && this.region != "") {
          this.load();
        }
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
    if (this.createTicketStrictTicketType) {
      this.ticketSubject = this.createTicketSubject;
      this.ticketDescription = this.createTicketDescription;
      this.supportType = this.createTicketSupportType;
      this.strictTicketType = this.createTicketStrictTicketType;
    }
    this.minfyTicketServices = Object.keys(categories);
    this.minfyTicketServiceSelected = this.minfyTicketServices[0];
    if (this.customerType != "mp" && this.whitelabelData['pd']['appName'].toLowerCase() == 'swayam') {
      this.changeMinfyService();
    }
    setTimeout(() => {
      $("#serviceCodeSelect").selectpicker();
      $("#categoryCodeSelect").selectpicker();
      $("#serverityCodeSelect").selectpicker();
      $("#servicesSelect").selectpicker();
      $("#categoriesSelect").selectpicker();
      $("#ec2list").selectpicker();
    }, 100);
    this.loading = true;
    if (!this.writeAccess) {
      this.router.navigate(["/dash/ticket/list"]);
      return;
    }
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  getKeys(obj: any): any {
    if (obj) {
      return Object.keys(obj);
    }

    return [];
  }

  checkEC2Operation() {
    return this.minfyTicketServiceSelected == "EC2 Instance";
  }

  getValue(obj: any) {
    if (obj) {
      return Object.values(obj)[0];
    }
    return null;
  }

  changeMinfyService() {
    this.minfyTicketCategorySelected = "";
    if (this.minfyTicketServiceSelected == "others") {
      this.ticketSeverity = "low";
    }
    let check = this.minfyTicketServiceSelected == "EC2 Instance";
    if (check) {
      this.loadEC2();
    }
    setTimeout(() => {
      $("#categoriesSelect").selectpicker("refresh");
      $("#serverityCodeSelect").selectpicker("refresh");
    }, 500);
  }

  minfyCategoryChange() {
    let priority =
      this.mainService[this.minfyTicketServiceSelected][
        this.minfyTicketCategorySelected
      ];
    if (priority == "P1") {
      this.ticketSeverity = "urgent";
    } else if (priority == "P2") {
      this.ticketSeverity = "normal";
    } else {
      this.ticketSeverity = "low";
    }

    setTimeout(() => {
      $("#serverityCodeSelect").selectpicker("refresh");
    }, 100);
  }

  loadInstanceData() {
    this.selected_ec2.State = "LOADING";
    this.loadInstanceStatus();
    this.loadInstanceMetrics();
  }

  async loadInstanceStatus() {
    let data = {
      action: "ec2_status",
      account_id: this.accountId,
      region_name: this.region,
      resource_ids: [this.selected_ec2.ResourceId],
      login_user_name: this.eId,
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1") {
      this.selected_ec2.State = result[this.selected_ec2.ResourceId]["state"];
    } else {
      this.notifier.alert(
        "Error",
        "",
        result.error_message || result.message,
        "error",
        5000
      );
      this.selected_ec2.State = "-";
    }
    return true;
  }
  async loadInstanceMetrics() {
    if (this.supportType == "minfy" && !this.minfyDisabled) {
      try {
        let selectedAccountData: any = await this.fetchSelectedAccountDetails();
        if (!selectedAccountData.error) {
          const account = selectedAccountData.data;
          if (
            !account.site_247_project_id ||
            this.selected_ec2.ResourceName == ""
          ) {
            this.selected_ec2["hide_matrics"] = true;
            return;
          }
          this.selected_ec2["cpu"] = "LOADING";
          this.selected_ec2["ram"] = "LOADING";
          this.selected_ec2["disk"] = "LOADING";

          let accountNew: any = localStorage.getItem("emsConfig");

          if (account) {
            accountNew = JSON.parse(accountNew);
          }
          let data = {
            action: "ec2_metrics",
            account_id: this.accountId,
            region_name: this.region,
            zaaid: accountNew ? account["zaid"] : account.site_247_project_id,
            instance_name: this.selected_ec2.ResourceName,
            login_user_name: this.eId,
            instance_id: this.selected_ec2.ResourceId,
            origin: accountNew ? "swayam" : "minfy",
          };
          let header = {
            Authorization: localStorage.getItem("t"),
          };
          let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == "1") {
            this.selected_ec2["cpu"] = result.cpu || "-";
            this.selected_ec2["ram"] = result.memory || "-";
            this.selected_ec2["disk"] = result.disk || "-";
            this.selected_ec2["from"] = result.from || undefined;
          } else {
            this.notifier.alert(
              "Error",
              "",
              result.error_message || result.message,
              "error",
              5000
            );
            this.selected_ec2["cpu"] = "-";
            this.selected_ec2["ram"] = "-";
            this.selected_ec2["disk"] = "-";
            this.selected_ec2["from"] = undefined;
          }
        } else {
          this.selected_ec2["hide_matrics"] = true;
        }
      } catch (err) {
        this.selected_ec2["hide_matrics"] = true;
      }
    }
  }

  instanceConfirm: any = null;
  title: String;
  message: String;
  triggerInstances(event: any, trigger: string) {
    event.preventDefault();
    if (!this.writeAccess) {
      return;
    }
    if (trigger == "start_ec2") {
      this.modalTitle = "start";
      this.action = "start";
    } else if (trigger == "stop_ec2") {
      this.action = "stop";
      this.modalTitle = "stop";
    } else if (trigger == "reboot_ec2") {
      this.action = "reboot";
      this.modalTitle = "reboot";
    }
    this.command = trigger;
  }

  async hideStartStop(event: any) {
    this.command = null;
    if (event) {
      do {
        await this.loadInstanceStatus();
      } while (
        !(
          this.selected_ec2.State == "stopped" ||
          this.selected_ec2.State == "running"
        )
      );
      this.loadInstanceMetrics();
    }
  }

  load() {
    this.services = [];
    if (
      this.minfyTicketServiceSelected == "EC2 Instance" &&
      this.supportType == "minfy"
    ) {
      $("#servicesSelect").selectpicker();
      setTimeout(() => {
        this.loadEC2();
      }, 100);
    }
    this.ec2_list = [];
    this.selected_ec2 = null;
    this.serviceSelected = null;
    this.categorySelected = null;
    this.awsDisabled = false;
    this.minfyDisabled = false;
    if (this.supportType == "aws") {
      this.loadServices();
    }
    if (this.customerType != 'mp' && this.whitelabelData['pd']['appName'].toLowerCase() == 'swayam') {
      this.checkMinfySupport();
    }
  }

  loading_ec2: boolean = false;
  ec2_list: any = [];
  selected_ec2: any = null;
  async loadEC2() {
    this.selected_ec2 = null;
    $("#ec2list").selectpicker();
    this.loading_ec2 = true;
    let selectedClientData = localStorage.getItem("clientList")
      ? JSON.parse(localStorage.getItem("clientList"))
      : [];
    // if (!selectedClientData) {
    //   this.loading_ec2 = false;
    //   return;
    // }
    let client_id;
    if (this.urlPrefix == "admin") {
      client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem("selectedClientList");
      })["clientIdList"];
    } else {
      client_id = localStorage.getItem("clientId");
    }
    let data = {
      action: "list_ec2",
      client_id: client_id,
      account_id: this.accountId,
      region_name: this.region,
      module: "support",
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2v2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    this.ec2_list = result || [];
    setTimeout(() => {
      $("#ec2list").selectpicker("refresh");
    }, 100);

    this.loading_ec2 = false;
  }

  close() {
    this.hideCreateTicket.emit(true);
  }

  async checkMinfySupport() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (selectedAccountData.error) {
        console.log(selectedAccountData.error);
      } else {
        const account = selectedAccountData.data;
        if (
          !account.jira_org_id ||
          !account.jira_org_name ||
          !account.jira_project_id ||
          !account.jira_project_key ||
          !account.jira_project_name
        ) {
          this.minfyDisabled = true;
          if(this.selectedCloudPartner == 'aws') {
            this.changeSupportType("aws");
          }
          if(this.whitelabelData['pd']['appName'].toLowerCase() == 'swayam') {
            this.notifier.alert(
              "Info",
              "",
              "Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.",
              "info",
              5000
            );
          }
        } else {
          this.minfyDisabled = false;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  changeSupportType(support: string) {
    this.service = "ec2";
    $("#servicesSelect").selectpicker();
    this.supportType = support;
    setTimeout(() => {
      $("#serverityCodeSelect").selectpicker("refresh");
    }, 100);
    this.attachedfileData = [];
    this.attachment_ids = [];
    if (
      support == "aws" &&
      this.services.length == 0 &&
      this.accountId &&
      this.region
    ) {
      setTimeout(() => {
        $("#servicesSelect").selectpicker();
      }, 100);
      this.loadServices();
      return;
    } else {
      setTimeout(() => {
        $("#servicesSelect").selectpicker();
      }, 100);
    }
    if (this.customerType != 'mp' && this.whitelabelData['pd']['appName'].toLowerCase() == 'swayam') {
      this.loadEC2();
    }
  }

  changeService(event: any) {
    this.categoriesList = this.serviceSelected.categories;
    setTimeout(() => {
      $("#categoryCodeSelect").selectpicker("refresh");
    }, 100);
  }
  async loadServices() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: "service_codes",
      account_id: this.accountId,
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.services = result.services;
      this.awsDisabled = false;
      setTimeout(() => {
        $("#serviceCodeSelect").selectpicker("refresh");
        $("#categoryCodeSelect").selectpicker("refresh");
      }, 100);
      //this.services.selected = result.services[0];
      //this.categories.options = result.services[0].categories;
      //this.categories.selected = result.services[0].categories[0];
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.error,
        //'Business support plan or Enterprise support plan is required for this feature',
        "info",
        5000
      );
      this.awsDisabled = true;
      if (!this.minfyDisabled && this.customerType != "mp") {
        this.changeSupportType("minfy");
      }
    }
    this.notifier.loading(false);
  }

  deleteSelectedAdditionalContact(index: number) {
    this.additionalContacts.splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != "" &&
      value.length > 0 &&
      this.additionalContacts.length - 1 == index
    ) {
      this.addAdditionalContact();
    } else if (
      value == "" &&
      value.length == 0 &&
      this.additionalContacts.length - 2 == index &&
      this.additionalContacts[this.additionalContacts.length - 1] == ""
    ) {
      this.additionalContacts.splice(this.additionalContacts.length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.updateError("contacts");
    this.additionalContacts.push("");
  }

  trackByFn() {
    return true;
  }

  triggerFIle() {
    this.attachmentElement.nativeElement.click();
  }

  niceBytes(x: any) {
    const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  addToFileList(docName: string, fileSize: any, base64Data: any) {
    this.attachedfileData.push({
      fileName: docName,
      fileSize: fileSize,
      data: base64Data,
    });
  }

  deleteSeletedFile(indexx: number) {
    this.attachedfileData.splice(indexx, 1);
    if (this.attachedfileData.length == 0) {
      $("#attachments").attr("type", "");
      setTimeout(() => {
        $("#attachments").attr("type", "file");
      }, 100);
    }
  }

  async convertFiles(fileList: any) {
    let promises = [];
    for (var i = 0; i < fileList.length; i++) {
      let filePromise = new Promise(async (resolve: any, reject: any) => {
        var fileToLoad = fileList[i];

        var fileReader = new FileReader();
        var base64: any;
        let fileName = fileList[i].name;
        let fileSize = fileList[i].size;
        fileReader.onload = (fileLoadedEvent: any) => {
          base64 = fileLoadedEvent.target.result;
          window.angularComponentReference.zone.run(() => {
            window.angularComponentReference.componentFn(
              fileName,
              fileSize,
              base64
            );
          });
          resolve({ fileName: fileName, fileSize: fileSize, data: base64 });
        };

        fileReader.readAsDataURL(fileToLoad);
        return;
      });
      promises.push(filePromise);
    }
    Promise.all(promises).then((fileContents) => {
      this.submitAttachments();
    });
  }

  async getFile(fileLoaded: any) {
    this.attachedfileData = [];
    let fileList: FileList = fileLoaded.target.files;
    await this.convertFiles(fileList);
    //var file = fileList[0];
    //var reader = new FileReader();
    //reader.onload = this._handleReaderLoaded.bind(this);
    //reader.readAsArrayBuffer(file);

    //this.attachedfileData = fileLoadedEvent.target.result;
    //console.log("attachedfileData", this.attachedfileData);
  }

  async submitAttachments() {
    this.notifier.loading(true);

    if (this.supportType == "minfy") {
      try {
        let selectedAccountData: any = await this.fetchSelectedAccountDetails();
        if (!selectedAccountData.error) {
          const { jira_org_id, jira_project_id } = selectedAccountData.data;
          let data = {
            action: "jira_attachment",
            attachments: this.attachedfileData.map((file: any) => {
              return { fileName: file.fileName, data: file.data };
            }),
            jira_project_id: jira_project_id,
            jira_organization_id: jira_org_id,
          };
          let header = {
            Authorization: localStorage.getItem("t"),
          };
          //https://api.swayam.cloud/v3/admin/support
          let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == "1" || result.s == "1") {
            this.attachment_ids = result.attachment_ids;
          } else {
            this.notifier.alert("Error", "", result.error, "error", 5000);
          }
        } else {
          this.notifier.alert(
            "Error",
            "",
            selectedAccountData.msg,
            "error",
            5000
          );
        }
      } catch (err) {}
    } else if (this.supportType == "aws") {
      let data = {
        action: "attachment",
        attachments: this.attachedfileData.map((file: any) => {
          return { fileName: file.fileName, data: file.data };
        }),
        account_id: this.accountId,
      };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == "1" || result.s == "1") {
        this.attachment_ids = result.message;
      } else {
        this.notifier.alert("Error", "", result.error, "error", 5000);
      }
    }
    this.notifier.loading(false);
  }

  updateError(key: String) {
    this.errors[`${key}`].is_valid = true;
    this.errors[`${key}`].message = "";
  }

  validateData(): boolean {
    let check = true;
    if (this.ticketSubject == "" || this.ticketSubject.length == 0) {
      this.errors["subject"]["is_valid"] = false;
      this.errors["subject"]["message"] = "Subject is mandatory";
      check = false;
    } else {
      this.errors["subject"]["is_valid"] = true;
    }
    if (
      (!this.categorySelected ||
        this.categorySelected == "" ||
        this.categorySelected.length == 0) &&
      this.supportType == "aws"
    ) {
      this.errors["category"]["is_valid"] = false;
      this.errors["category"]["message"] = "Category is mandatory";
      check = false;
    } else {
      this.errors["category"]["is_valid"] = true;
    }
    if (
      (!this.serviceSelected ||
        this.serviceSelected == "" ||
        this.serviceSelected.length == 0) &&
      this.supportType == "aws"
    ) {
      this.errors["service"]["is_valid"] = false;
      this.errors["service"]["message"] = "Service is mandatory";
      check = false;
    } else {
      this.errors["service"]["is_valid"] = true;
    }
    const textData = this.ticketDescription;
    const element = document.createElement("div");
    element.innerHTML = textData;
    if (textData == "" || textData.length == 0 || element.innerText == "") {
      this.errors["description"]["is_valid"] = false;
      this.errors["description"]["message"] = "Description is mandatory";
      check = false;
    } else {
      this.errors["description"]["is_valid"] = true;
    }
    let contacts = this.additionalContacts.filter((contact: any) => {
      return contact != "";
    });
    if (contacts.length == 0) {
      this.errors["contacts"]["is_valid"] = false;
      this.errors["contacts"]["message"] =
        "At Least 1 Additional Contact(Email Id) is mandatory";
      check = false;
    } else if (contacts.length > 0) {
      const regexEmail =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
      contacts.every((contact: any) => {
        if (!contact.match(regexEmail)) {
          this.errors["contacts"]["message"] = "Only Email Fields are allowed";
          this.errors["contacts"]["is_valid"] = false;
          check = false;
          return false;
        }
        this.errors["contacts"]["is_valid"] = true;
        return true;
      });
    } else {
      this.errors["contacts"]["is_valid"] = true;
    }
    return check;
  }

  async createTicket(event: any) {
    event.preventDefault();
    if (!this.validateData()) {
      return;
    }
    if (!this.writeAccess) {
      return;
    }
    if (
      (this.supportType == "minfy" && this.minfyDisabled) ||
      (this.supportType == "aws" && this.awsDisabled)
    ) {
      return;
    }
    this.notifier.loading(true);
    if (this.supportType == "minfy" && !this.minfyDisabled) {
      try {
        let selectedAccountData: any = await this.fetchSelectedAccountDetails();
        if (!selectedAccountData.error) {
          const {
            jira_org_id,
            jira_project_key,
            jira_project_name,
            site_247_project_id,
            site_247_project_name,
            jira_project_id,
          } = selectedAccountData.data;
          this.notifier.loading(true);

          let textData = this.ticketDescription;

          let data: any = {
            action: "create",
            account_id: this.accountId,
            severity_code: this.ticketSeverity,
            communication_body: textData, // this.ticketDescription,
            subject: this.ticketSubject,
            attachment_set_id: this.attachment_ids,
            ticket_on: "jira",
            service_code:
              this.minfyTicketServiceSelected == "others"
                ? this.minfyTicketServiceSelectedText
                : this.minfyTicketServiceSelected,
            category_code:
              this.minfyTicketCategorySelected == "others"
                ? this.minfyTicketCategorySelectedText
                : this.minfyTicketCategorySelected,
            jira_project_id: jira_project_id,
            jira_organization_id: jira_org_id,
            user_email: this.eId,
            site_247_project_name: site_247_project_name,
            site_247_project_id: site_247_project_id,
            jira_project_name: jira_project_name,
            jira_project_key: jira_project_key,
            cc_email_addresses: this.additionalContacts.filter(
              (contact: any) => {
                return contact != "";
              }
            ),
            resources: this.selected_ec2
              ? [
                  {
                    resource_id: this.selected_ec2.ResourceId,
                    resource_name: this.selected_ec2.ResourceName,
                  },
                ]
              : undefined,
          };
          let header = {
            Authorization: localStorage.getItem("t"),
          };
          //https://api.swayam.cloud/v3/admin/support
          let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == "1" || result.s == "1") {
            this.ticketSubject = "";
            this.ticketDescription = "";
            this.attachment_ids = [];
            this.attachedfileData = [];
            this.additionalContacts = [""];
            this.notifier.alert(
              "Success",
              "",
              `Ticket Created Successfully. Ticket ID: ${result.message.case_id}`,
              "success",
              5000
            );
          } else {
            this.notifier.alert(
              "Error",
              "",
              result.error_message,
              "error",
              5000
            );
          }
        } else {
          this.notifier.alert(
            "Error",
            "",
            selectedAccountData.msg,
            "error",
            5000
          );
        }
      } catch (err) {}
    } else if (this.supportType == "aws" && !this.awsDisabled) {
      let data = {
        action: "create",
        account_id: this.accountId,
        service_code: this.serviceSelected.code,
        category_code: this.categorySelected.code,
        severity_code: this.ticketSeverity,
        communication_body: this.ticketDescription,
        subject: this.ticketSubject,
        attachment_set_id: this.attachment_ids,
        cc_email_addresses: this.additionalContacts.filter((contact: any) => {
          return contact != "";
        }),
        ticket_on: "aws",
      };
      let header = {
        Authorization: localStorage.getItem("t"),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == "1" || result.s == "1") {
      } else {
        this.ticketSubject = "";
        this.ticketDescription = "";
        this.attachment_ids = [];
        this.attachedfileData = [];
        this.additionalContacts = [""];
        if (result.message && result.message.case_id) {
          this.notifier.alert(
            "Success",
            "",
            `Ticket Created Successfully. Ticket ID: ${result.message.case_id}`,
            "success",
            5000
          );
        } else if (result.message && !result.message.case_id) {
          this.notifier.alert("Error", "", `${result.message}`, "error", 5000);
        }
      }
    }
    window.analyticsSendEventClick("Tickets", `Ticket Created`);
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }
}
