import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

declare let window: any;

@Component({
  selector: "instacne-start-stop",
  styleUrls: ["./startstop.ec2.component.css"],
  templateUrl: "./startstop.ec2.component.html",
})
export class StartStopInstanceComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    localStorage.getItem("acT") == "readOnly(startstop)" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  accountId: any;
  regionId: any;
  otpEmailID: string = this.userId;
  otpText: string;
  currentMessage: any;
  validateOTP: boolean = null;
  @Input("instances") instancesList: any;
  @Input("modalTitle") modalTitle: any;
  @Input("command") command: any;
  @Input("action") action: any;
  @Output() hideModal = new EventEmitter<any>();
  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    if (!this.writeAccess) {
      this.close();
    }
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
      } else if (d.key == "regionId") {
        this.regionId = d.value;
      }
    });
  }

  async sendOTP() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      loginpersonemailid: this.otpEmailID,
      client_emailid: this.otpEmailID,
      action: this.action,
      resourceID: this.instancesList.map((instance: any) => {
        return instance.instanceId;
      })[0],
      resourceName: this.instancesList.map((instance: any) => {
        return instance.tagName;
      })[0],
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/sendotp`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.validateOTP = true;
      this.notifier.alert("Success", "", result.msg, "success", 5000);
    } else {
      this.validateOTP = false;
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async instanceTriggerAction() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data = { e: this.otpEmailID, otp: this.otpText };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/validateotp`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
      await this.startStopTrigger();
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.notifier.loading(false);
  }

  async startStopTrigger() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data: any = {
      emailId: this.userId,
      instanceId: this.instancesList.map((instance: any) => {
        return instance.instanceId;
      }),
      accountId: this.accountId,
      action: this.action + (this.instancesList.length > 1 ? "Multiple" : ""),
      region: this.regionId,
    };
    if (data.instanceId.length == 1) {
      data.instanceId = data.instanceId[0];
    } else {
      data.instanceIdList = data.instanceId;
    }
    if (this.action.indexOf("rds") > -1) {
      data["dBInstanceIdentifier"] = data.instanceId;
      data.status = this.command;
      delete data.instanceId;
    }
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/instancestartstop`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.instancesList.map((instance: any) => {
        if (instance.clusterIdentifier) {
          if (instance.status) {
            window.analyticsSendEventClick(
              "Operations - RDS",
              instance.dbStatus == "available" ? "Stopped" : "Available"
            );
            instance.dbStatus = instance.status;
            instance.status =
              instance.dbStatus == "available" ? "stopped" : "available";
          } else {
            window.analyticsSendEventClick(
              "Operations - RDS",
              instance.dbStatus == "available" ? "Stopped" : "Available"
            );
            instance.dbStatus =
              instance.dbStatus == "available" ? "stopped" : "available";
          }
        } else {
          window.analyticsSendEventClick(
            "Operations - EC2",
            instance.dbStatus == "available" ? "Stopped" : "Running"
          );
          instance.instanceStatus =
            instance.instanceStatus == "running" ? "stopped" : "running";
          instance.statusCheck = "";
          instance.availabilityZone = "";
        }
      });
      this.notifier.alert("Success", "", result.msg, "success", 5000);
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }
    this.close();

    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  close(): void {
    this.hideModal.emit(true);
  }
}
