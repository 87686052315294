<div class="card">
  <div class="card-header flex-start">
    <button class="btn btn-light flex-start" (click)="hideModel.emit(false)">
      <i class="fa fa-angle-left" aria-hidden="true"></i>
      <span>Back</span>
    </button>
    <h5>
      Scanner Result - {{ scanner['scanner_name'] }}
    </h5>
  </div>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active flex-start" id="cdn-tab" data-bs-toggle="tab" data-bs-target="#cdn" type="button"
          role="tab" aria-controls="cdn" aria-selected="true" (click)="currentPage = 'cdn'">
          <span>
            Cloudfront
          </span>
          <span class="selected-count">{{ resources['cdn'].length }}</span>
        </button>
        <button class="nav-link flex-start" id="elb-metrics-tab" data-bs-toggle="tab" data-bs-target="#elb-metrics"
          type="button" role="tab" aria-controls="elb-metrics" aria-selected="true"
          (click)="currentPage = 'elb-metrics'">
          <span>
            ELB Metrics
          </span>
          <span class="selected-count">{{ resources['elb']['metrics'].length }}</span>
        </button>
        <button class="nav-link flex-start" id="elb-targets-tab" data-bs-toggle="tab" data-bs-target="#elb-targets"
          type="button" role="tab" aria-controls="elb-targets" aria-selected="true"
          (click)="currentPage = 'elb-targets'">
          <span>
            ELB Targets
          </span>
          <span class="selected-count">{{ resources['elb']['targets'].length }}</span>
        </button>
        <button class="nav-link flex-start" id="ec2-tab" data-bs-toggle="tab" data-bs-target="#ec2" type="button"
          role="tab" aria-controls="ec2" aria-selected="true" (click)="currentPage = 'ec2'">
          <span>
            EC2
          </span>
          <span class="selected-count">{{ resources['ec2'].length }}</span></button>
        <button class="nav-link flex-start" id="rds-tab" data-bs-toggle="tab" data-bs-target="#rds" type="button"
          role="tab" aria-controls="rds" aria-selected="true" (click)="currentPage = 'rds'">
          <span>
            RDS
          </span>
          <span class="selected-count">{{ resources['rds'].length }}</span>
        </button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="cdn" role="tabpanel" aria-labelledby="cdn-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Distribution</th>
                <th>Endpoint</th>
                <th>Response Code</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['cdn'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['cdn'] | paginate
                      : {
                          itemsPerPage: pagination['cdn']['perPage'],
                          currentPage: pagination['cdn']['page'],
                          totalItems: resources['cdn'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ resource['endpoint'] }}
                </td>
                <td>
                  {{ resource['response_code'] }}
                </td>
                <td>
                  {{ resource['response_code_color'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['cdn'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="5">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'cdn'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['cdn']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
      <div class="tab-pane fade" id="elb-metrics" role="tabpanel" aria-labelledby="elb-metrics-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>ARN</th>
                <th>Active Connection Count</th>
                <th>HTTP Code ELB 4XX Count</th>
                <th>HTTP Code ELB 5XX Count</th>
                <th>Processed Bytes</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['elb']['metrics'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['elb']['metrics'] | paginate
                      : {
                          itemsPerPage: pagination['elb_metrics']['perPage'],
                          currentPage: pagination['elb_metrics']['page'],
                          totalItems: resources['elb']['metrics'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ resource['ActiveConnectionCount'] }}
                </td>
                <td>
                  {{ resource['HTTPCode_ELB_4XX_Count'] }}
                </td>
                <td>
                  {{ resource['HTTPCode_ELB_5XX_Count'] }}
                </td>
                <td>
                  {{ resource['ProcessedBytes'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['elb']['metrics'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="6">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'elb'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['elb']['metrics']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
      <div class="tab-pane fade" id="elb-targets" role="tabpanel" aria-labelledby="elb-targets-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>ELB Name</th>
                <th>TargetGroup Name</th>
                <th>ID</th>
                <th>Reason</th>
                <th>Health</th>
                <th>Endpoint</th>
                <th>Response Code</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['elb']['targets'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['elb']['targets'] | paginate
                      : {
                          itemsPerPage: pagination['elb_targets']['perPage'],
                          currentPage: pagination['elb_targets']['page'],
                          totalItems: resources['elb']['targets'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['ebs_id'] }}
                </td>
                <td>
                  {{ resource['target'] }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ resource['reason'] || '-' }}
                </td>
                <td>
                  {{ resource['health'] }}
                </td>
                <td>
                  {{ resource['endpoint'] }}
                </td>
                <td>
                  {{ resource['response_code'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['elb']['targets'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="8">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'elb'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['elb_targets']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
      <div class="tab-pane fade" id="ec2" role="tabpanel" aria-labelledby="ec2-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Instance ID</th>
                <th>Instance State</th>
                <th>Instance Type</th>
                <th>CPU Utilization</th>
                <th>AZ</th>
                <th>Health</th>
                <th>Status</th>
                <th>End Point</th>
                <th>Response Code</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['ec2'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['ec2'] | paginate
                      : {
                          itemsPerPage: pagination['ec2']['perPage'],
                          currentPage: pagination['ec2']['page'],
                          totalItems: resources['ec2'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ resource['instance_state'] }}
                </td>
                <td>
                  {{ resource['instance_type'] }}
                </td>
                <td>
                  {{ resource['cpu_utilization'] }}
                </td>
                <td>
                  {{ resource['az'] }}
                </td>
                <td>
                  {{ resource['health'] }}
                </td>
                <td>
                  {{ resource['status'] }}
                </td>
                <td>
                  {{ resource['endpoint'] }}
                </td>
                <td>
                  {{ resource['response_code'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['ec2'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="10">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'ec2'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['ec2']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
      <div class="tab-pane fade" id="rds" role="tabpanel" aria-labelledby="rds-tab">
        <div class="resource-container">
          <table class="table table-docs">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>DB Identifier</th>
                <th>DB Type</th>
                <th>CPU Utilization</th>
                <th>Database Connections</th>
                <th>Free Storage Space</th>
                <th>Swap Usage</th>
                <th>Freeable Memory</th>
              </tr>
            </thead>
            <tbody *ngIf="resources['rds'].length > 0 && !loading">
              <tr *ngFor="let resource of resources['rds'] | paginate
                      : {
                          itemsPerPage: pagination['rds']['perPage'],
                          currentPage: pagination['rds']['page'],
                          totalItems: resources['rds'].length
                        }; let i = index">
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  {{ resource['id'] }}
                </td>
                <td>
                  {{ resource['instance_type'] }}
                </td>
                <td>
                  {{ resource['CPUUtilization'] }}
                </td>
                <td>
                  {{ resource['DatabaseConnections'] }}
                </td>
                <td>
                  {{ resource['FreeStorageSpace'] }}
                </td>
                <td>
                  {{ resource['SwapUsage'] }}
                </td>
                <td>
                  {{ resource['FreeableMemory'] }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="resources['rds'].length == 0 && !loading">
              <tr>
                <td class="center" colspan="8">
                  No Data found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex-end" *ngIf="!loading && currentPage == 'rds'">
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
              (pageChange)="pagination['rds']['page'] = $event">
            </pagination-controls>
          </div>
        </div>
        <div class="center" *ngIf="loading">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
        </div>
      </div>
    </div>
  </div>
</div>