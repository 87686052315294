import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-steps-configure-cft",
  templateUrl: "./steps-enable-cur.component.html",
  styleUrls: ["./steps-enable-cur.component.css"],
})
export class StepsConfigureCFTComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<boolean>();
  step: number = 0;
  steps: number = 3;
  whitelabelData: any = {
	'pd': {
		'appName': '',
		'logoDark': '',
		'logoLight': '',
	}
};

  constructor(private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
if(whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  appendStep() {
    this.step += 1;
  }

  removeStep() {
    this.step -= 1;
  }

  getArray() {
    return new Array(this.steps).fill(0);
  }

  getMarginLeft() {
    let element = document.querySelector(".info-space .steps-container");
    return `${this.step * element.getBoundingClientRect().width * -2}px`;
  }

  setStep(index: number) {
    this.step = index;
  }

  ngOnInit(): void {
    this.step = 0;
  }
}
