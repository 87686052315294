<div class="price-container">
  <div class="price-item-container">
    <div class="price-item" *ngFor="let item of getPriceArray()">
      {{ item }}
    </div>
  </div>
  <div class="price-item-container-absolute maindoprdown" #maindoprdown id="maindoprdown">
    <div class="absolute-parent" *ngFor="let item of getPriceArray();">
      <div class="price-item-absolute" *ngIf="loaded" [ngClass]="{ top : !checkForNumber(item) }"
        [ngStyle]="{top: checkForNumber(item) ? checkAbsoluteValue(item) : '0px'}">
        <ng-container *ngIf="checkForNumber(item)">
          <div *ngFor="let value of generateArray(); let i = index">
            {{ i }}
          </div>
        </ng-container>
        <ng-container *ngIf="!checkForNumber(item)">
          {{item}}
        </ng-container>
      </div>
      <div *ngIf="!loaded" class="price-item-absolute">
        {{ checkForNumber(item) ? 0 : item }}
      </div>
      <div class="price-item">{{ item }}</div>
    </div>
  </div>
</div>