import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
import { environment } from "src/environments/environment";
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./s3.component.html",
  styleUrls: ["./s3.component.css"],
})
export class s3Component implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  s3List: any = [];
  filterType: string = "ALL";

  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  index: number = 1;

  headers: any = [
    {
      id: "bucketName",
      name: "Bucket Name",
    },
    { id: "createdDate", name: "Created Date" },
    { id: "region", name: "Region" },
    {
      id: "isPublic",
      name: "Is Public",
      color: (value: string) => (value ? "red" : "green"),
    },
    {
      id: "isEncrypted",
      name: "Is Encrypted",
      color: (value: string) => (!value ? "red" : "green"),
    },
    { id: "ageInDays", name: "Age in Days" },
  ];

  azureHeaders: any = [
    {
      id: "bucketName",
      name: "Container Name",
    },
    { id: "Region", name: "Region" },
    { id: "lease_state", name: "Lease State" },
    { id: "lease_status", name: "Lease Status" },
    { id: "storage_account_name", name: "Storage Account Name" },
    {
      id: "isPublic",
      name: "Is Public",
      color: (value: string) => (value ? "red" : "green"),
    },
    {
      id: "default_encryption_scope",
      name: "Encryption Scope"
    },
  ];

  moreDetailsHeaders: any = [
    {
      id: "bucketPublicPolicyStatus",
      name: "Bucket Policy Status",
      color: (value: string) => (value ? "red" : "green"),
      value: (value: string) => {
        return !value ? "Private" : "Public";
      },
    },
    {
      id: "bucketAclStatus",
      name: "Bucket ACL Status",
      color: (value: string) => (value ? "red" : "green"),
      value: (value: string) => {
        return !value ? "Private" : "Public";
      },
    },
    {
      id: "isBlockPublicAccess",
      name: "Block Public Access",
      color: (value: string) => (!value ? "red" : "green"),
      value: (value: string) => {
        return value ? "Enabled" : "Disabled";
      },
    },
    {
      id: "isBlockPublicAcl",
      name: "Block Public Acl",
      color: (value: string) => (!value ? "red" : "green"),
      value: (value: string) => {
        return value ? "Enabled" : "Disabled";
      },
    },
  ];

  azureMoreDetailsHeader: any = [
    {
      id: 'has_immutability_policy',
      name: 'Has Immutability Policy',
      color: (value: string) => (!value ? "red" : "green"),
      value: (value: string) => {
        return value ? "Yes" : "No";
      },
    },
    {
      id: 'has_legal_hold',
      name: 'Has Legal Hold',
      color: (value: string) => (!value ? "red" : "green"),
      value: (value: string) => {
        return value ? "Yes" : "No";
      },
    }
  ]

  resourceGroup: string = localStorage.getItem("selectedResourceGroup");
  selectedCloudPartner: string = null;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null || d.source != "notifier") {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId" && this.selectedCloudPartner == "aws") {
        this.accountId = d.value;
        this.load(true);
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load(true);
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  totalBuckets: number = null;
  publicAccess: boolean = null;
  page: number = 1;
  perPage: number = 10;
  pages: number[] = [5, 10, 25, 50];
  fileredBuckets: any = [];

  overview: any = {
    "non-excrypted": () => {
      return this.s3List.filter((s3: any) => {
        return !s3.isEncrypted;
      }).length;
    },
    "non-public": () => {
      return this.s3List.filter((s3: any) => {
        return s3.isPublic;
      }).length;
    },
  };

  position: any = {
    x: "0px",
    y: "0px",
    overflow: false,
    overflowwidth: "0px",
    pointer: "0px",
    width: "",
    height: "",
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector(".message_back").offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector(".message_back").offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + "px";
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + "px";
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector(".message_back").offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector(".message_back").offsetWidth / 2 +
        "px";
    } else {
      this.position.x = 60 + "px";
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      "px";
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + "px";
    this.position.width =
      event.target.querySelector(".message_grid").offsetWidth + 30 + "px";
    this.position.height =
      event.target.querySelector(".message_grid").offsetHeight + 30 + "px";
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector(".message_grid").offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  getArray() {
    return Array(this.totalBuckets).fill(0);
  }

  async load(refresh: boolean = false, index: number = 1) {
    this.page = index;
    if (refresh) {
      this.s3List = [];
      this.index = 1;
      this.totalBuckets = null;
      this.publicAccess = null;
    }
    this.loading = true;
    let data: any = {
      action: "fetchS3DetailsWithPagination",
      fromIndex: index || Number(this.page),
      toIndex: this.perPage,
      clientemailid: this.accountId,
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Compliance.S3", "S3 Buckets Fetched");
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ebs`;

    if (this.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/security`;
      data = {
        action: "security_blob",
        user_id:
          this.urlPrefix == "admin" &&
          (localStorage.getItem("selectedAccountGroup") ==
            "DevelopmentTestGroup" ||
            localStorage.getItem("selectedAccountGroup") == "MinfyDevTeam")
            ? "rakeshkumar.t@minfytech.com"
            : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      let dt: any = [];
      if (this.selectedCloudPartner == "aws") {
        dt = result.bucketList.encryptedBucketList;
        if (refresh) {
          this.publicAccess = result.bucketList.publicAccess;
          this.totalBuckets = result.bucketList.totalBuckets;
        }
      } else if (this.selectedCloudPartner == "azure") {
        dt = result["container details"];
        this.totalBuckets = dt.length;
      }
      this.s3List = dt;
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    this.loading = false;
  }

  callFunction(name: any, param: any) {
    return;
  }

  checkforBool(value: String): String {
    if (typeof value == "boolean") {
      return value ? "Yes" : "No";
    } else {
      return value;
    }
  }
}
