import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-instance-savings',
  templateUrl: './instance-savings.component.html',
  styleUrls: [
    './instance-savings.component.css',
    './../../risavings.component.css',
  ],
})
export class InstanceSavingsComponent implements OnInit {
  @Input('instance') instance: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();

  constructor() {}

  hide() {
    this.hideModel.emit(false);
  }

  getPrice(
    riClass: string,
    riPeriod: string,
    riUpfront: string,
    key: string
  ): number {
    let check = this.instance.pricing.riPricing.find((price: any) => {
      return (
        price['riClass'] == riClass &&
        price['riPeriod'] == riPeriod &&
        price['riUpfront'] == riUpfront
      );
    });

    return check && check[key] ? Number(Number(check[key]).toFixed(2)) : 0.0;
  }

  ngOnInit(): void {}
}
