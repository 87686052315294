<div class="card">
  <div class="card-body">
    <div class="sections">
      <div class="section">
        <div class="section_header" (click)="loadEC2Data()">
          <div class="section_title">EC2</div>
          <div class=flex-start>
            <div [innerHTML]="response['finalEC2HTML']">
            </div>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="ec2Show" class="overflow-auto">
          <div class="flex-margin" *ngIf="ec2Data.length > 0">
            <button class="btn btn-light back-color" (click)="exportEC2()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="ec2-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of ec2Headers">
                  <th *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">{{ h.name }}</th>
                  <th *ngIf="!h.hide && !h.displayConditional">{{ h.name }}</th>
                </ng-container>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': ec2Data.length > 0 ? 'table-row-group' : 'none'}">

              <tr *ngFor="let i of ec2Data; let j = index">
                <td>{{j + 1}}</td>
                <ng-container *ngFor="let h of ec2Headers">
                  <td *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">
                    <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"> </span>
                  </td>
                  <td *ngIf="!h.hide && !h.displayConditional">
                    <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"> </span>
                  </td>
                </ng-container>
              </tr>
              <tr *ngIf="!this.nextTokens['ec2']">
                <td
                  [attr.colSpan]="filterHeaders(ec2Headers) - 1 - (projectTagNameEC2 ? 0 : 1) - (mapMigratedEC2 ? 0 : 1)">

                </td>
                <td>SUB Total</td>
                <td [innerHTML]="getTotalEC2(ec2Data)">
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="ec2Data.length == 0">
              <tr>
                <td [attr.colspan]="ec2Headers.length + 1">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="display-flex-right" *ngIf="nextTokens['ec2']">
            <button class="btn btn-light back-color" (click)="loadEC2Data(true)">Next</button>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section_header" (click)="loadEBSData()">
          <div class="section_title">EBS</div>
          <div class="flex-start">
            <div [innerHTML]="getTotalEBSFinal()"></div>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="ebsShow" class="overflow-auto">
          <div class="flex-margin" *ngIf="ebsData.length > 0">
            <button class="btn btn-light back-color" (click)="exportEBS()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="ebs-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of ebsHeaders">
                  <ng-container *ngIf="h.show === undefined || h.show() === true">
                    <th *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">{{ h.name }}</th>
                    <th *ngIf="!h.hide && !h.displayConditional">
                      {{ h.name }}
                      <span *ngIf="h.filter " class="inputseach">
                        <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </th>
                  </ng-container>
                </ng-container>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': ebsData.length > 0 ? 'table-row-group' : 'none'}">
              <ng-container *ngFor="let i of ebsData; let j = index">
                <tr *ngIf="notifier.filterTableColums(ebsHeaders,i)">
                  <td>{{j + 1}}</td>
                  <ng-container *ngFor="let h of ebsHeaders">
                    <ng-container *ngIf="h.show === undefined || h.show() === true">
                      <td *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">
                        <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i[h.id], i) : i[h.id]"> </span>
                      </td>
                      <td *ngIf="!h.hide && !h.displayConditional">
                        <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i[h.id], i) : i[h.id]"> </span>
                      </td>
                    </ng-container>
                  </ng-container>
                </tr>
              </ng-container>
              <tr *ngIf="!this.nextTokens['ebs']">
                <td [attr.colSpan]="ebsHeaders.length - 5 - (customerType == 'mp' ? 1 : 0) - (projectTagNameEBS ? 0 : 1) - (mapMigratedEBS ? 0 : 1)">

                </td>
                <td>SUB Total</td>
                <td>
                  {{getTotalEBS(ebsData)['gb_cost']}}
                </td>
                <td>
                  {{getTotalEBS(ebsData)['storage']}}
                </td>
                <td *ngIf="IOPSshow">
                  {{getTotalEBS(ebsData)['iops']}}
                </td>
                <td></td>
                <td>
                  {{getTotalEBS(ebsData)['total']}}
              </tr>
            </tbody>
            <tbody *ngIf="ebsData.length == 0">
              <tr>
                <td [attr.colspan]="ebsHeaders.length + 1 - (IOPSshow ? 0 : 1)">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="display-flex-right" *ngIf="nextTokens['ebs']">
            <button class="btn btn-light back-color" (click)="loadEBSData(true)">Next</button>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section_header" (click)="loadOthersData()">
          <div class="section_title">Others</div>
          <div class="flex-start">
            <div [innerHTML]="getTotalOthersFinal()"></div>
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf="othersShow" class="overflow-auto">
          <div class="flex-margin" *ngIf="othersData.length > 0">
            <button class="btn btn-light back-color" (click)="exportOthers()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="others-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th *ngFor="let h of othersHeaders">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': othersData.length > 0 ? 'table-row-group' : 'none'}">
              <tr *ngFor="let i of othersData; let j = index">
                <td>{{j + 1}}</td>
                <td *ngFor="let h of othersHeaders">
                  <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i[h.id], i) : i[h.id]"> </span>
                </td>
              </tr>
              <tr *ngIf="!this.nextTokens['others']">
                <td [attr.colSpan]="othersHeaders.length - 1">

                </td>
                <td>SUB Total</td>
                <td>
                  {{getTotalOthers(othersData)}}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="othersData.length == 0">
              <tr>
                <td [attr.colspan]="othersHeaders.length + 1">
                  <div class="align-center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="display-flex-right" *ngIf="nextTokens['others']">
            <button class="btn btn-light back-color" (click)="loadOthersData(true)">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>