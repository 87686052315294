import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as echarts from "echarts/types/dist/echarts";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

declare let Jhxlsx: any;

@Component({
  selector: "app-rbi-compliance-overview",
  templateUrl: "./rbi-compliance-overview.component.html",
  styleUrls: [
    "./rbi-compliance-overview.component.css",
    "./../../rbi-compliance.component.css",
  ],
})
export class RbiComplianceOverviewComponent implements OnInit {
  @Input("loading") loading: any;
  @Input("job") job: any;
  filters: any = {
    questions: "All",
  };
  @Input("accountId") accountId: any;
  @Input("regionId") regionId: any;
  @Output("hideModel") hide: any = new EventEmitter<boolean>();
  @Output("loadJobs") loadJobs: any = new EventEmitter<string>();
  perPages: any = [10, 50, 100, 200];
  checks: any = [];
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  jobsHeaders: any = [
    {
      id: "job_id",
      name: "Job ID",
    },
  ];
  currentMessage: any;
  filter: any = {
    checks: "ALL",
    findings: "ALL",
  };

  checkCount = 0;
  checkInterval = 10000;
  jobCurrentStatus = ""; //In progress, Completed

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private navigator: Router
  ) {}

  ngOnInit(): void {
    this.loading["checks"] = true;

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  destroyed: boolean = false;

  async load() {
    this.filters["questions"] = "All";
    this.selectedRequirement = null;
    //this.loading["checks"] = true;
    //if (this.job.status != "Completed") {
    //  this.loadJobs.emit(this.job.job_id);
    //  this.loadChecks();
    //  if (this.timer) {
    //    return;
    //  }
    //  this.timer = setInterval(() => {
    //    if (this.job.status != "Completed" && this.destroyed == false) {
    //      this.loadJobs.emit(this.job.job_id);
    //      this.loadChecks();
    //    } else {
    //      if (this.timer) {
    //        clearInterval(this.timer);
    //        this.timer = null;
    //      }
    //    }
    //  }, 5000);
    //} else {
    //  if (this.timer) {
    //    clearInterval(this.timer);
    //    this.timer = null;
    //  }
    //  this.loadChecks();
    //}

    // this.loadJobs.emit(true, true);
    this.loadFiles();
    await this.loadChecks();
    if (this.jobCurrentStatus == "In progress") {
      this.checkProgress();
    }
  }

  async checkProgress() {
    setTimeout(async () => {
      if (this.checkCount > 10) {
        this.loading.checks = false;
        this.notifier.alert(
          "Info",
          "",
          "Security Scan is taking longer than usual. Please click on the Refresh button after a couple of minutes.",
          "info",
          -1
        );
        return;
      }
      this.checkCount++;
      this.checkInterval = this.checkInterval + 1000;
      await this.loadChecks();
      if (this.jobCurrentStatus == "In progress") {
        this.checkProgress();
      } else {
        this.loading.checks = false;
      }
    }, this.checkInterval);
  }

  hideModel(event: any) {
    this.navigator.navigate(["/dash/rbi-compliance"]);
    this.hide.emit(event);
  }

  checksAcc: any = [];
  selectedRequirement: string = "";

  getChecks() {
    return this.checks.filter((check: any) => {
      return (
        check["check_root"].toLowerCase().trim() ==
        this.selectedRequirement.toLowerCase().trim()
      );
    });
  }

  metadata: any = ["IAM-007", "IAM-008", "WAFR-001", "WAFR-002"];
  async loadChecks() {
    this.loading["checks"] = true;
    let data: any = {
      //action: "list_checks",
      action: "job_status",
      job_id: this.job.job_id,
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/compliance/rbicompliance`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    this.loading["checks"] = false;

    this.checksAcc = [];
    if (result.status == "1" || result.s == "1") {
      this.job = result.job_status[0];
      result.checks = result.checks
        .map((check: any) => {
          check["options"] = check["options"] || [];
          check["comment"] = check["comment"] || [];
          check["choices"] = check["choices"] || [];
          check["check_status"] =
            check["check_status"] != undefined && check["check_status"] != null
              ? check["check_status"]
              : "false";

          check["check_root_order"] = check["check_root_order"]
            ? Number(check["check_root_order"])
            : 0;

          if (check["recomendations"]) {
            check["recomendations"].forEach((recom: any) => {
              if (recom["details"]) {
                let data: any = [];
                Object.keys(recom["details"]).forEach((key: any) => {
                  if (
                    recom["details"][key] &&
                    recom["details"][key].length > 0
                  ) {
                    data.push({
                      tableName: key.replaceAll("_", " "),
                      headers: Object.keys(recom["details"][key][0]).map(
                        (key_: any) => {
                          return {
                            id: key_,
                            name: key_.replaceAll("_", " "),
                          };
                        }
                      ),
                      data: recom["details"][key],
                    });
                  }
                });
                recom["custom_metadata"] = data;
              }
            });
          }

          // updating categories
          let temp = this.checksAcc.find((check_: any) => {
            return check_["check_root"] == check.check_root;
          });

          if (!temp) {
            this.checksAcc.push({
              check_root: check.check_root,
              current_index: 0,
              check_root_order: check.check_root_order,
              check_id: check.check_root.replaceAll(" ", "_"),
              unanswered: () => {
                let check_id: string = check.check_root;
                return this.checks.filter((check_: any) => {
                  // console.log(check_['status'], check_['check_status'], check_['decision']);
                  return (
                    check_["check_root"] == check_id &&
                    !(
                      check_["applicant_response"] ||
                      (check_["options"].length > 0 &&
                        check_["options"].filter((option: any) => {
                          return option["applicant_response"];
                        }).length == check_["options"].length)
                    )
                  );
                }).length;
              },
              answered: () => {
                let check_id: string = check.check_root;
                return this.checks.filter((check_: any) => {
                  return (
                    check_["check_root"] == check_id &&
                    (check_["applicant_response"] ||
                      (check_["options"].length > 0 &&
                        check_["options"].filter((option: any) => {
                          return option["applicant_response"];
                        }).length == check_["options"].length))
                  );
                }).length;
              },
              count: 1,
            });
          } else {
            temp.count += 1;
          }
          return check;
        })
        .sort((a: any, b: any) => {
          return a["check_root_order"] - b["check_root_order"];
        });
      this.checksAcc = this.checksAcc.sort((a: any, b: any) => {
        return a["check_root_order"] - b["check_root_order"];
      });

      this.checks = result.checks;
      this.jobCurrentStatus = result.job_status[0]["status"];
    } else {
      this.checks = [];
    }
  }

  exportCompliance() {
    let json: any = [
      {
        sheetName: this.job["name"],
        data: [
          [
            { text: "Question ID" },
            { text: "Question Title" },
            { text: "Applicant Response" },
            { text: "Comments(if any)" },
          ],
        ],
      },
    ];

    this.checksAcc.forEach((requirement: any) => {
      json[0]["data"].push([{ text: requirement["check_root"] }]);
      this.checks
        .filter((check: any) => {
          return check["check_root"] == requirement["check_root"];
        })
        .forEach((check: any) => {
          json[0]["data"].push([
            { text: check["check_id"] },
            { text: check["title"] },
            { text: check["applicant_response"] || "" },
            {
              text: Array.isArray(check["comment"])
                ? ""
                : check["comment"] || "",
            },
          ]);
          if (check.options && check.options.length > 0) {
            check.options.forEach((sub_check: any, index: number) => {
              json[0]["data"].push([
                { text: index + 1 },
                { text: sub_check["question"] },
                { text: sub_check["applicant_response"] || "" },
                {
                  text: Array.isArray(sub_check["comment"])
                    ? ""
                    : sub_check["comment"] || "",
                },
              ]);
            });
          }
        });

      json[0]["data"].push([{ text: "" }]);
    });

    var options = {
      fileName: `RBI Compliance ${this.accountId}_${this.regionId}`,
    };

    Jhxlsx.export(json, options);
  }

  files: any = [];
  async loadFiles() {
    this.loading["files"] = true;

    let data: any = {
      account_id: this.accountId,
      region_id: this.regionId,
      action: "list_files",
      job_id: this.job["job_id"],
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/compliance/rbicompliance`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == "1" || result.status == "1") {
      this.files = result.files;
    } else {
      this.files = [];
    }

    this.loading["files"] = false;
  }

  fetchTotalAnsweredChecks() {
    return this.checksAcc.filter((check: any) => {
      return check["unanswered"] > 0;
    });
  }

  showFilesValid: boolean = false;

  showQuestions(check: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
