<div class="card" *ngIf="!innerData">
  <div class="card-header flex-between">
    <div class="flex-start">
      <a [routerLink]="'/dash/reports/home'" class="default-link" *ngIf="showHeader">
        <button class="btn btn-light flex-start">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>Reports</span>
        </button>
      </a>
      <h5>
        Security Groups
      </h5>
    </div>
    <div class="flex-start">
      <div class="flex-start" *ngIf="selectedCloudPartner == 'aws'">
        <span>Security Groups with Open to World Ports:</span>
        <span *ngIf="!openPortsLoading">{{ totalOpenPorts || '-' }}</span>
        <img src="../assets/img/loading_2.svg" *ngIf="openPortsLoading" alt="loading scans" class="wid-18" />
      </div>
      <button class="btn btn-light flex-start" (click)="load()" *ngIf="selectedCloudPartner == 'aws'">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        <span class="color-inherit">Refresh</span>
      </button>
      <button class="btn btn-light flex-start" *ngIf="sgList.length > 0" (click)="export()">
        <i class="fa fa-download" aria-hidden="true"></i>
        <span class="color-inherit">
          Export
        </span>
      </button>
    </div>
  </div>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div class="overflow-auto">
      <table class="table table-docs table-hover" id="sgList">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of sgheader">{{ h.name }}</th>
            <th *ngIf="selectedCloudPartner == 'aws'">
              Open Ports
            </th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': sgList.length > 0 ? 'table-row-group' : 'none'}"
          *ngFor="let i of sgList; let j = index">
          <tr>
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of sgheader">
              <span *ngIf="h.click" class="nav-link" (click)="callFunction(h.click, i)">
                {{ i[h.id] }}
              </span>
              <span *ngIf="!h.click">
                {{ i[h.id] }}
              </span>
            </td>
            <td *ngIf="selectedCloudPartner == 'aws'">
              <span class="nav-link" (click)="openPortSelected = i['open_ports']" *ngIf="i['open_ports']">{{
                i['open_ports'].length }}</span>
              <span *ngIf="i['open_ports'] == undefined">-</span>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="sgList.length == 0 && !loading">
          <tr>
            <td [attr.colspan]="sgheader.length + 2">
              <div class="txt-cntr">No Security Groups Found</div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="loading">
          <tr>
            <td [attr.colspan]="sgheader.length + 2">
              <div class="center flex-center">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
                <span>
                  Loading Security Groups...
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-container *ngIf="innerData">
  <div class="card">
    <h5 class="card-header">
      <button class="btn btn-light" (click)="goBack()">
        < Back</button>
          <span>Security Group - {{ innerData.sg_name }}</span>
    </h5>
  </div>
  <div class="card">
    <h5 class="card-header">
      <span>Details</span>
    </h5>
    <div class="card-body">
      <div class="panel panel-default">
        <div class="panel-heading fnt-mdm"></div>

        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <b>Security Group Name</b>
              <p id="eventname">
                {{ innerData.sg_name }}
              </p>
            </div>
            <div class="col-md-6">
              <b>Security Group ID</b>
              <p id="eventid">{{ innerData.sg_id }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b>VPC ID</b>
              <p id="readonly">{{ innerData.vpc_id }}</p>
            </div>
            <div class="col-md-6">
              <b>Inbound Rules Count</b>
              <p id="eventsource">{{ innerData.inbound_count }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b>Outbound Rules Count</b>
              <p id="aws_access_key">{{ innerData.outbound_count }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link active" id="inbound-rules-tab" data-bs-toggle="tab" data-bs-target="#inbound-rules"
            type="button" role="tab" aria-controls="inbound-rules" aria-selected="true">
            Inbound Rules ({{innerData.inbound_count}})
          </button>
          <button class="nav-link" id="outbound-rules-tab" data-bs-toggle="tab" data-bs-target="#outbound-rules"
            type="button" role="tab" aria-controls="outbound-rules" aria-selected="false">
            Outbound Rules ({{innerData.outbound_count}})
          </button>
        </div>
      </nav>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="inbound-rules" role="tabpanel" aria-labelledby="inbound-rules-tab">
          <div class="card border-none">
            <!--<h5 class="card-header"></h5>-->

            <div class="card-body">
              <!--<h5 class="card-title">Case Details</h5>-->

              <table class="table table-docs table-hover">
                <thead>
                  <tr>
                    <th>S.N.</th>
                    <th *ngFor="let h of selectedCloudPartner == 'aws' ? sgInnerHeader: sgInnerAzureHeader">{{ h.name }}</th>
                  </tr>
                </thead>

                <tbody *ngFor="let i of innerData.inbound_rules; let j = index">
                  <tr>
                    <td>{{ j + 1 }}</td>
                    <td *ngFor="let h of selectedCloudPartner == 'aws' ? sgInnerHeader: sgInnerAzureHeader">
                      <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                        <span>
                          {{ i[h.id] }}
                        </span>
                      </span>
                      <span *ngIf="!h.click">
                        {{ i[h.id] }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody [ngStyle]="{'display': innerData.inbound_rules.length == 0 ? 'table-row-group' : 'none'}">
                  <tr>
                    <td [attr.colspan]="selectedCloudPartner == 'aws' ? sgInnerHeader.length: sgInnerAzureHeader.length + 1">
                      <div class="txt-cntr">No Inbound Rules Found</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="outbound-rules" role="tabpanel" aria-labelledby="outbound-rules-tab">
          <div class="card border-none">
            <!--<h5 class="card-header"></h5>-->
            <div class="card-body">
              <!--<h5 class="card-title">Case Details</h5>-->

              <table class="table table-docs table-hover">
                <thead>
                  <tr>
                    <th>S.N.</th>
                    <th *ngFor="let h of selectedCloudPartner == 'aws' ? sgInnerHeader: sgInnerAzureHeader">{{ h.name }}</th>
                  </tr>
                </thead>

                <tbody [ngStyle]="{'display': innerData.outbound_rules.length > 0 ? 'table-row-group' : 'none'}"
                  *ngFor="let i of innerData.outbound_rules; let j = index">
                  <tr>
                    <td>{{ j + 1 }}</td>
                    <td *ngFor="let h of selectedCloudPartner == 'aws' ? sgInnerHeader: sgInnerAzureHeader">
                      <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                        <span>
                          {{ i[h.id] }}
                        </span>
                      </span>
                      <span *ngIf="!h.click">
                        {{ i[h.id] }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody [ngStyle]="{'display': innerData.outbound_rules.length == 0 ? 'table-row-group' : 'none'}">
                  <tr>
                    <td [attr.colspan]="selectedCloudPartner == 'aws' ? sgInnerHeader.length: sgInnerAzureHeader.length + 1">
                      <div class="txt-cntr">No Outbound Rules Found</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<modal-popup [headers]="portHeader" [data]="openPortSelected" [modalTitle]="'Open to World Ports'"
  [showModal]="openPortSelected" (hideModal)="openPortSelected = null"></modal-popup>