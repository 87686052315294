<div class="card">
  <h5 class="card-header content-center-item">
    <div>
      <button class="btn btn-header" (click)="hideModel()">
        < Back </button>
          Billing Data
    </div>
    <div>
      <span class="margin-10">Account ID: {{activeAccount.account.lid}}</span>
      <span>Month Year: {{activeAccount.currentMonth}}</span>
    </div>
  </h5>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="billing-dashboard-tab" data-bs-toggle="tab"
          data-bs-target="#billing-dashboard" type="button" role="tab" aria-controls="billing-dashboard"
          aria-selected="true">Dashboard</button>
        <button class="nav-link" id="billing-analyze-tab" data-bs-toggle="tab" data-bs-target="#billing-analyze"
          type="button" role="tab" aria-controls="billing-analyze" aria-selected="false">Analyze</button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="billing-dashboard" role="tabpanel"
        aria-labelledby="billing-dashboard-tab">
        <div class="grid-2">
          <div>
            <div class="card">
              <div class="card-header">
                <div class="content-center-item">
                  <h5>Spend Overview</h5>
                  <div>
                    <select class="form-select" [(ngModel)]="graphType" (change)="loadGraphSpendOverview()">
                      <option value="services">Services</option>
                      <option value="accounts">Accounts</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="align-center">Month Year: {{this.spendOverViewDate}}</h5>
                    <div id="analytics_overview_graph" class="height-width"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="card">
              <div class="card-header">
                <div class="content-center-item">
                  <h5>Spend Analysis</h5>
                  <div>
                    <select class="form-select" [(ngModel)]="monthCount" (change)="load()">
                      <option value="3">3 Months</option>
                      <option value="6">6 Months</option>
                      <option value="1">Current Month</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div id="analytics_analyze_graph" class="height-width"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade show" id="billing-analyze" role="tabpanel" aria-labelledby="billing-analyze-tab">
        <div>
          <div class="card">
            <div class="card-header">
              <div class="content-center-item">
                <h5>Current Spend</h5>
                <div>
                  <!-- <select class="form-select" [(ngModel)]="graphType" (change)="loadGraphSpendOverview()">
                    <option value="services">Services</option>
                    <option value="accounts">Accounts</option>
                  </select> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div id="analytics_analyze_current_spend_graph" class="height-800">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>