<div
  *ngIf="viewer"
  class="image_viewer"
  [ngClass]="{ image_viewer_show: viewer.show }"
>
  <div class="image_viewer_controller">
    <div class="controllers_parent">
      <div
        class="image_control"
        title="Zoom Out"
        (click)="viewer.width = viewer.width - 10"
      >
        <i class="fa fa-search-minus" aria-hidden="true"></i>
      </div>
      <div
        class="image_control"
        title="Zoom In"
        (click)="viewer.width = viewer.width + 10"
      >
        <i class="fa fa-search-plus" aria-hidden="true"></i>
      </div>
      <div
        class="image_control"
        title="Download"
        (click)="downloadAttachment(viewer)"
      >
        <i class="fa fa-download" aria-hidden="true"></i>
      </div>
      <div class="image_control" title="Close" (click)="hideImage()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="image_viewer_name">
    {{ viewer.file_name }}
  </div>
  <div class="file_name_position">
    <div class="image_viewer_container">
      <div class="file_name_textalign">
        <img
          class="image_link file_name_transition"
          [ngStyle]="{ width: viewer.width + '%' }"
          [src]="viewer.src"
          alt="Loading..."
        />
      </div>
    </div>
  </div>
</div>
<div
  class="card mt-20 support_type_border"
  [ngStyle]="{ display: !activeTicket && !createTicket ? 'block' : 'none' }"
>
  <h5 class="card-header">View Tickets</h5>
  <div class="card-body">
    <div class="support_filter">
      <div class="support_select">
        <div class="support_header">Support Type</div>
        <div class="support_types_container support_type_margin">
          <div
            class="support_type_container pointer"
            *ngIf="
              customerType != 'mp' &&
              whitelabelData['pd']['appName'].toLowerCase() == 'swayam'
            "
            [ngClass]="{
              support_type_selected: supportType == 'minfy' && !minfyDisabled,
              selected_type_disabled: minfyDisabled
            }"
          >
            <div class="support_type_box" (click)="changeSupportType('minfy')">
              <div class="support_type_logo">
                <div class="outer">
                  <div class="inner"></div>
                </div>
              </div>
              <div>
                <div class="support_type_text">Minfy Support</div>
                <div class="support_type_desc">Managed Services from Minfy</div>
              </div>
            </div>
          </div>
          <div
            class="support_type_container pointer" *ngIf="selectedCloudPartner == 'aws'"
            [ngClass]="{
              support_type_selected: supportType == 'aws' && !awsDisabled,
              selected_type_disabled: awsDisabled
            }"
          >
            <div class="support_type_box" (click)="changeSupportType('aws')">
              <div class="support_type_logo">
                <img src="../../../assets/img/aws_logo_1.png" alt="" />
              </div>
              <div>
                <div class="support_type_text">AWS Support</div>
                <div class="support_type_desc">
                  Support from Amazon Web Services
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="p-1">
        <input autocomplete="off" type="radio" name="supportType" id="minfySupport" checked/>
        <label for="minfySupport">Minfy Support</label>
      </div>
      <div class="p-1">
        <input autocomplete="off" type="radio" name="supportType" id="awsSupport" />
        <label for="awsSupport">AWS Support</label>
      </div> -->
        </div>
      </div>
      <div
        class="support_select"
      >
        <div class="support_header">Tickets Between</div>
        <div class="support_types_container">
          <div>
            <input
              autocomplete="off"
              class="datetimepicker"
              type="text"
              [(ngModel)]="dateRange"
            />
          </div>
        </div>
      </div>
      <div class="support_select">
        <div class="support_header">Action</div>
        <div class="support_types_container">
          <div>
            <button class="btn refresh_button" (click)="load()">Refresh</button>
            <button
              class="btn refresh_button"
              *ngIf="tickets.length > 0"
              (click)="export()"
            >
              Export CSV
            </button>
          </div>
        </div>
      </div>
      <div class="support_select">
        <div class="support_types_container">
          <div>
            <span>Tickets: {{ tickets.length }} / {{ totalTickets }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="support_select filter_padding">
      <div class="support_header">Filter</div>
      <div class="support_types_container margin-10">
        <div class="filter_position">
          <input
            autocomplete="off"
            type="checkbox"
            id="include_resolved_checkbox"
            name="support_type"
            value="minfy"
            [(ngModel)]="includeResolved"
            (change)="refresh()"
          />
          <label for="include_resolved_checkbox">Include Resolved</label>
          <div class="transform_translate_3"></div>
        </div>
        <div class="filter_position">
          <div
            class="filters_container margin-0"
            [ngStyle]="{
              display:
                supportType == 'minfy' && !minfyDisabled ? 'block' : 'none'
            }"
          >
            <div class="search_container">
              <div class="key_dropdown key_dropdown_style">
                <select
                  class="search_dropdown"
                  [(ngModel)]="filterKey"
                  (change)="checkForValues()"
                >
                  <option
                    *ngFor="let key of filterKeys"
                    [value]="key.id"
                    [innerText]="key.name"
                  ></option>
                </select>
                <i
                  class="fa fa-caret-down transform_translate_2"
                  aria-hidden="true"
                ></i>
              </div>
              <div *ngIf="filterKey != 'priority' && filterKey != 'reporter'">
                <div class="input_field_container">
                  <div class="flex_1">
                    <input
                      autocomplete="off"
                      type="text"
                      [(ngModel)]="filterValue"
                      class="search_input"
                    />
                  </div>
                  <div>
                    <i
                      class="fa fa-times cursor_pointer"
                      aria-hidden="true"
                      (click)="filterValue = ''"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                [ngStyle]="{
                  display:
                    filterKey == 'priority' || filterKey == 'reporter'
                      ? 'block'
                      : 'none'
                }"
              >
                <div class="key_dropdown_style">
                  <select
                    id="search_dropdown"
                    class="search_dropdown"
                    [(ngModel)]="filterValue"
                    data-live-search="true"
                  >
                    <option
                      *ngFor="let key of dropDownKeyValues"
                      [value]="key.id"
                      [innerText]="key.name"
                    ></option>
                  </select>
                  <i
                    class="fa fa-caret-down transform_translate_1"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div *ngIf="loadingSearchValues">
                <img
                  src="../assets/img/loading_2.svg"
                  alt="loading scans"
                  width="10"
                  class="loading_searchvalue_image"
                />
              </div>
              <div
                class="search_icon"
                (click)="clearFilters()"
                title="Clear Filter"
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
              <div class="search_icon" (click)="load()" title="Apply Filter">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow_auto">
      <table class="table table-docs table-hover mt-20" id="view-tickets-table">
        <thead>
          <tr>
            <th>S.N.</th>
            <th
              *ngFor="
                let h of supportType == 'minfy' ? minfyHeaders : awsHeaders
              "
            >
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, tickets)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input
                  autocomplete="off"
                  type="text"
                  [(ngModel)]="h.filterText"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th *ngIf="writeAccess">
              {{ supportType == "minfy" ? "AWS Support" : "Minfy Support" }}
            </th>
          </tr>
        </thead>
        <tbody
          [ngStyle]="{
            display: tickets.length > 0 ? 'table-row-group' : 'none'
          }"
          *ngFor="let i of tickets; let j = index"
        >
          <tr
            *ngIf="
              notifier.filterTableColums(
                supportType == 'minfy' ? minfyHeaders : awsHeaders,
                i
              )
            "
          >
            <td>{{ j + 1 }}</td>
            <td
              *ngFor="
                let h of supportType == 'minfy' ? minfyHeaders : awsHeaders
              "
            >
              <span
                *ngIf="h.click"
                class="btn"
                (click)="callFunction(h.click, i)"
              >
                <span>
                  {{ i[h.id] }}
                </span>
              </span>
              <span *ngIf="!h.click">
                <span *ngIf="h.pipe">
                  {{ h.pipe(i) }}
                </span>
                <span *ngIf="!h.pipe">
                  {{ i[h.id] }}
                </span>
              </span>
            </td>
            <td *ngIf="writeAccess">
              <span (click)="createTicketTrigger(i)" class="btn">{{
                supportType == "minfy" ? "AWS Support" : "Minfy Support"
              }}</span>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="tickets.length == 0">
          <tr>
            <td
              [attr.colspan]="
                (supportType == 'minfy' ? minfyHeaders : awsHeaders).length + 2
              "
            >
              <div class="file_name_textalign">No Tickets Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="display_flex" *ngIf="nextToken">
      <button
        class="btn btn-light blue_button_capital"
        (click)="
          supportType == 'minfy'
            ? loadMinfySupportTickets()
            : loadAWSSupportTickets()
        "
      >
        Next
      </button>
    </div>
  </div>
</div>
<div *ngIf="activeTicket">
  <div class="card mt-20 support_type_border">
    <h5 class="card-header">
      <button class="btn btn-light" (click)="goBack()">< Back</button>
      <span>Ticket - {{ activeTicket.issue_key || activeTicket.caseId }}</span>
    </h5>
    <div class="card-body">
      <div class="support_filter justify_flex_end" *ngIf="writeAccess">
        <div class="support_select">
          <div class="support_header">Action</div>
          <div class="support_types_container">
            <div>
              <button class="btn blue_button_small" (click)="loadCorrespondence()" *ngIf="supportType == 'minfy'">Refresh</button>
              <button class="btn blue_button_small" (click)="loadAWSCorrespondence()" *ngIf="supportType == 'aws'">Refresh</button>
              <button
                class="btn blue_button_small"
                *ngIf="
                  supportType == 'aws' &&
                  (activeTicket.status == 'resolved' ||
                    activeTicket.status == 'unassigned')
                "
                (click)="reOpenTicket()"
                [ngClass]="{ 'click-disabled': !writeAccess }"
                [attr.disabled]="!writeAccess ? true : null"
              >
                Reopen
              </button>
              <button
                class="btn blue_button_small"
                [ngClass]="{ 'click-disabled': !writeAccess }"
                [attr.disabled]="!writeAccess ? true : null"
                (click)="resolveTicketTrigger()"
                *ngIf="
                  supportType == 'aws' && activeTicket.status != 'resolved'
                "
              >
                Resolve
              </button>
              <button
                class="btn blue_button_small"
                [ngClass]="{ 'click-disabled': !writeAccess }"
                [attr.disabled]="!writeAccess ? true : null"
                (click)="replyTicket()"
              >
                Reply
              </button>
              <button
                class="btn blue_button_small"
                [ngClass]="{ 'click-disabled': !writeAccess }"
                [attr.disabled]="!writeAccess ? true : null"
                (click)="addToKB()"
                *ngIf="urlPrefix == 'admin'"
              >
                Add To KB
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-20 support_type_border">
        <h5 class="card-header">
          <span>Case Details</span>
        </h5>
        <div class="card-body">
          <div class="panel panel-default">
            <div class="panel-heading font_size_medium"></div>

            <div class="panel-body">
              <div class="row">
                <div
                  [ngClass]="{
                    'col-md-12': supportType == 'aws',
                    'col-md-8': supportType == 'minfy'
                  }"
                >
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <b>Subject</b>
                        <p id="eventname">
                          {{ activeTicket.summary || activeTicket.subject }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <b>Status</b>
                        <p id="eventid">
                          {{ activeTicket.status }}
                          <span
                            *ngIf="
                              supportType == 'minfy' &&
                              correspondence.length > 0
                            "
                            (click)="
                              activeTicket['timeline'] = activeTicket[
                                'timeline'
                              ]
                                ? false
                                : true
                            "
                            class="nav-link"
                            ><i class="fa fa-history"></i>
                            {{
                              activeTicket["timeline"]
                                ? "Hide history"
                                : "History"
                            }}</span
                          >
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <b>Ticket ID</b>
                        <p id="readonly">
                          {{ activeTicket.issue_key || activeTicket.caseId }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <b>Priority</b>
                        <p id="eventsource">
                          {{
                            activeTicket.priority || activeTicket.severityCode
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <b>Creator</b>
                        <p id="aws_access_key">
                          {{ activeTicket.creator || activeTicket.submittedBy }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <b>Created On</b>
                        <p id="aws_region">
                          {{ activeTicket.created || activeTicket.timeCreated }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <b>Issue Type</b>
                        <p id="eventtime">
                          {{ activeTicket.issue_type || "-" }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <b>Reporter</b>
                        <p id="eventtime">
                          {{
                            activeTicket.reporter || activeTicket.submittedBy
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <b>Assignee</b>
                        <p id="username">{{ activeTicket.assignee || "NA" }}</p>
                      </div>
                      <div class="col-md-6">
                        <b>Participants</b>
                        <p id="eventtime">
                          {{ participants || "NA" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4" *ngIf="supportType == 'minfy'">
                  <div>
                    <div>
                      <b>Closed On</b>
                      <p id="eventtime">
                        <span *ngIf="activeTicket.resolved">
                          {{ activeTicket.resolved_time }}
                        </span>
                        <span
                          *ngIf="!activeTicket.resolved"
                          class="flex-start"
                        >
                          <span>
                            <i class="fa fa-clock"></i>
                          </span>
                          <span>
                            {{ activeTicket["status"] }}
                          </span>
                        </span>
                      </p>
                    </div>
                    <div>
                      <b>Expected Time as per SLA</b>
                      <p id="eventname">
                        {{
                          activeTicket?.resolution_sla?.total_time_str || "-"
                        }}
                      </p>
                    </div>
                    <div>
                      <b>Actual Time as per SLA (Resolution Time)</b>
                      <p id="eventname" class="flex-start">
                        <span>{{
                          activeTicket?.resolution_sla?.time_completed_str ||
                            "-"
                        }}</span>
                        <span
                          class="flex-start grey_fancy"
                          *ngIf="
                            !activeTicket.resolved &&
                            activeTicket?.resolution_sla?.breached == false
                          "
                        >
                          <i class="fa fa-clock"></i>
                          <span>Within SLA</span>
                        </span>
                        <span
                          class="flex-start green_fancy"
                          *ngIf="
                            activeTicket.resolved &&
                            activeTicket?.resolution_sla?.breached == false
                          "
                        >
                          <i class="fa fa-check-circle"></i>
                          <span>Within SLA</span>
                        </span>
                        <span
                          class="flex-start red_fancy"
                          *ngIf="activeTicket?.resolution_sla?.breached"
                        >
                          <i class="fa fa-times-circle"></i>
                          <span
                            >SLA Breached by
                            <strong>{{
                              activeTicket?.resolution_sla?.breached_by_str
                            }}</strong></span
                          >
                        </span>
                      </p>
                    </div>
                    <div>
                      <b>Remaining Time</b>
                      <p id="eventname">
                        {{
                          activeTicket?.resolution_sla?.time_remaining_str ||
                            "-"
                        }}
                      </p>
                    </div>
                    <div>
                      <b>Breached</b>
                      <p id="eventname">
                        {{
                          activeTicket?.resolution_sla?.breached ? "Yes" : "No"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h5 class="card-header">
          <span>Correspondence</span>
        </h5>
        <div class="card-body">
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th *ngFor="let h of correspondenceHeader">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody
              [ngStyle]="{
                display: correspondence.length > 0 ? 'table-row-group' : 'none'
              }"
            >
              <tr *ngFor="let i of correspondence">
                <td *ngFor="let h of correspondenceHeader">
                  <span
                    *ngIf="h.click"
                    class="btn"
                    (click)="callFunction(h.click, i)"
                  >
                    <span [innerHTML]="i[h.id]"> </span>
                  </span>
                  <span *ngIf="!h.click" [innerHTML]="i[h.id]"> </span>
                  <div
                    class="comment_attachments"
                    *ngIf="h.id == 'body' && i.attachments"
                  >
                    <div
                      class="comment_attachment"
                      [title]="attachment.file_name"
                      (click)="
                        !attachment.src
                          ? downloadAttachment(attachment)
                          : viewImage(attachment)
                      "
                      *ngFor="let attachment of i.attachments"
                    >
                      <div class="min_width_150">
                        <span
                          *ngIf="!attachment.src || attachment.src == 'LOADING'"
                          class="padding_10"
                        >
                          <i
                            class="fa fa-file-text font_size_70"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span
                          *ngIf="attachment.src && attachment.src != 'LOADING'"
                          class="min_height_120"
                        >
                          <img [src]="attachment.src" />
                        </span>
                      </div>
                      <div class="attachment_meta">
                        <div class="attachment_text">
                          {{ attachment.file_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody
              [ngStyle]="{
                display: correspondence.length == 0 ? 'table-row-group' : 'none'
              }"
            >
              <tr>
                <td [attr.colspan]="correspondenceHeader.length">
                  <div class="file_name_textalign">No Correspondence Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<reply-ticket
  *ngIf="replyTicket_"
  [modalTitle]="modalTitle"
  [ticket]="replyTicket_"
  [supportType]="supportType"
  (hideModal)="hideModal($event)"
></reply-ticket>
<confirm-element
  *ngIf="resolveTicket"
  (hideModal)="hideResolveTicket($event)"
></confirm-element>
<add-kb-element
  *ngIf="addKB"
  [supportType]="supportType"
  [ticket]="addKB"
  (hideModal)="hideAddKB($event)"
>
</add-kb-element>
<ticket-timeline
  *ngIf="activeTicket && activeTicket['timeline']"
  [comments]="correspondence"
  [status_history]="status_history"
  [ticket]="activeTicket"
  [resolved]="this.activeTicket['resolved']"
  (hideModel)="activeTicket['timeline'] = null"
></ticket-timeline>
<create-ticket
  *ngIf="createTicket"
  [createTicketStrictTicketType]="true"
  [createTicketSupportType]="supportType == 'minfy' ? 'aws' : 'minfy'"
  [createTicketSubject]="createTicket.subject"
  [createTicketDescription]="createTicket.description"
  (hideCreateTicket)="hideCreateTicket($event)"
></create-ticket>
