<div class="mainBox" *ngIf="!hideForResize && cloudPartner == 'aws'">
  <div class="row">
    <div class="col">
      <div class="card border-primary">
        <div class="card-header header-text d-flex flex-between">
          <span>Multi Account Dashboard</span>
          <div class="flex-start">
            <div>
              {{accountIdsListWithName.length}} Accounts Selected
            </div>
            <div class="btn-light">
              <i class="fa fa-pencil" (click)="showAccTab()"></i>
            </div>
            <div class="btn-light" (click)="expandable = false" *ngIf="expandable">
              <i class="fa fa-caret-up"></i>
            </div>
            <div class="btn-light" (click)="expandable = true" *ngIf="!expandable">
              <i class="fa fa-caret-down"></i>
            </div>
          </div>
        </div>
        <div class="card-body d-flex flex-wrap acc-hold" *ngIf="expandable">
          <ng-container *ngFor="let i of accountIdsListWithName">
            <div class="badgehold d-flex">
              <div class="details">{{i.cName}}</div>
              <div (click)="removeAccount(i.accId)" class="removeicon"> <i class="fa-solid fa-xmark"></i></div>
            </div>
          </ng-container>
          <!-- <div class="badgehold d-flex" *ngIf="!expandable && accountIdsListWithName.length > 5">
            <div class="details">{{accountIdsListWithName.length - 5}}+ Accounts</div>
            <div class="removeicon" (click)="expandable = true">
              <i class="fa fa-plus"></i>
            </div>
          </div>
          <div class="badgehold d-flex" *ngIf="expandable && accountIdsListWithName.length > 5">
            <div class="details">view less accounts</div>
            <div class="removeicon" (click)="expandable = false">
              <i class="fa fa-minus"></i>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-4">

    </div>
    <div class="col-4"></div>
    <div class="col-4 a-left">
      <div class="info">
        <i class="fa-solid fa-triangle-exclamation info-o"></i> <span class="info-text">Data will get refreshed every 24 hours </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <div class="row">

        <div class="col-6 mt-3">

          <div class="costCard boxshadow">
            <div class="leftbox">
              <div class="costTitle2">Annual Cost</div>
              <div class="costValue" (click)="openCostPage()"><app-price-animation [price]="totalCostvalue"></app-price-animation>&nbsp;USD</div>
              <div class="desHold" *ngIf="!totalCostApiError && !firstDay">
                <div class="signHold bgc2">
                  <div class="signIcon"></div>
                  <div class="signValue2">Since the last 12 months&nbsp;{{currentDate}}</div>
                </div>
                <div class="description"></div>
              </div>
              <div class="desHold" *ngIf="totalCostApiError">
                <div class="signHold lower">
                  <div class="signIcon"></div>
                  <div class="signValue3">Cost Explorer API not enabled. <a
                      href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html"
                      target="_blank"> <span class="signIcon2"><i
                          class="fa-solid fa-up-right-from-square"></i></span></a></div>
                </div>
                <div class="description"></div>
              </div>
              <div class="d-flex nodata" *ngIf="firstDay">
                <div><i class="fa-solid fa-circle-info"></i></div>
                <div class="">The CSP has not billed yet.</div>
              </div>
            </div>
            <!-- <div class="rightbox"> -->
            <div class="costIcon costIcon4"><i class="fa-solid fa-sack-dollar"></i></div>
            <!-- </div> -->
            <button *ngIf="!annualCostLoader && !totalCostApiError && !firstDay" (click)="viewAnnualcost($event)"
              class="btn cost-btn"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="annualCostLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>

        <div class="col-6 mt-3">
          <div class="costCard boxshadow">
            <div class="leftbox">
              <div class="costTitle2">Month to Date (MTD) Spend</div>
              <div class="costValue" (click)="openCostPage()"><app-price-animation [price]="monthlyCostValue"></app-price-animation>&nbsp;USD
              </div>
              <div class="desHold m-0 p-0" *ngIf="!costApiError && !firstDay">
                <div class="desHold" *ngIf="monthlyCostDiff.includes('-')">
                  <div class="signHold higher">
                    <div class="signIcon"><i class="fa-sharp fa-solid fa-arrow-down"></i></div>
                    <div class="signValue"><app-price-animation [price]="monthlyCostDiff"></app-price-animation>%</div>
                  </div>
                  <div class="description">vs. MTD of last month</div>
                </div>
                <div class="desHold" *ngIf="!monthlyCostDiff.includes('-')">
                  <div class="signHold lower">
                    <div class="signIcon"><i class="fa-sharp fa-solid fa-arrow-up"></i></div>
                    <div class="signValue"><app-price-animation [price]="monthlyCostDiff"></app-price-animation>%</div>
                  </div>
                  <div class="description">vs. MTD of last month</div>
                </div>
              </div>
              <div class="desHold" *ngIf="costApiError">
                <div class="signHold lower">
                  <div class="signIcon"></div>
                  <div class="signValue3">Cost Explorer API not enabled. <a
                      href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html"
                      target="_blank"> <span class="signIcon2"><i
                          class="fa-solid fa-up-right-from-square"></i></span></a></div>
                </div>
                <div class="description"></div>
              </div>

              <div class="d-flex nodata" *ngIf="firstDay">
                <div><i class="fa-solid fa-circle-info"></i></div>
                <div class="">The CSP has not billed yet.</div>
              </div>
            </div>
            <!-- <div class="rightbox"> -->
            <div class="costIcon costIcon4"><i class="fa-sharp fa-solid fa-calendar-minus"></i></div>
            <!-- </div> -->
            <button *ngIf="!monthlyCostLoader && !costApiError && !firstDay" (click)="viewMonthlycost($event)"
              class="btn cost-btn"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="monthlyCostLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>

        </div>

        <div class="col-6 mt-4">
          <div class="costCard boxshadow">
            <div class="leftbox">
              <div class="costTitle2">Cost Savings</div>
              <div class="costValue" (click)="openCostPage()"><app-price-animation [price]="costSavingValue"></app-price-animation>&nbsp;USD
              </div>
              <div class="desHold">
                <!-- <div class="signHold bgc2">
                  <div class="signIcon"></div>
                  <div class="signValue2">For more details<a routerLink="/dash/costopt/ec2" target="_blank"> <span class="signIcon2"><i class="fa-solid fa-up-right-from-square"></i></span></a></div>
                </div> -->
                <div class="description"></div>
              </div>
            </div>
            <div class="rightbox">
              <div class="costIcon"><i class="fas fa-piggy-bank"></i></div>
            </div>
            <button *ngIf="!costSavingLoader" (click)="showCostSavingTab($event)" class="btn cost-btn"><i
                class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="costSavingLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="costCard boxshadow">
            <div class="leftbox">
              <div class="costTitle2">Today's Spend</div>
              <div class="costValue" (click)="openCostPage()"><app-price-animation [price]="dailyCostValue"></app-price-animation>&nbsp;USD</div>
              <div class="desHold m-0 p-0" *ngIf="!dailyCostApiError && !firstDay">
                <div class="desHold" *ngIf="dailyCostDiff.includes('-')">
                  <div class="signHold higher">
                    <div class="signIcon"><i class="fa-sharp fa-solid fa-arrow-down"></i></div>
                    <div class="signValue"><app-price-animation [price]="dailyCostDiff"></app-price-animation>%</div>
                  </div>
                  <div class="description">vs. same day last month</div>
                </div>
                <div class="desHold" *ngIf="!dailyCostDiff.includes('-')">
                  <div class="signHold lower">
                    <div class="signIcon"><i class="fa-sharp fa-solid fa-arrow-up"></i></div>
                    <div class="signValue"><app-price-animation [price]="dailyCostDiff"></app-price-animation>%</div>
                  </div>
                  <div class="description">vs. same day last month</div>
                </div>
              </div>
              <div class="desHold" *ngIf="dailyCostApiError">
                <div class="signHold lower">
                  <div class="signIcon"></div>
                  <div class="signValue3">Cost Explorer API not enabled. <a
                      href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html"
                      target="_blank"> <span class="signIcon2"><i
                          class="fa-solid fa-up-right-from-square"></i></span></a></div>
                </div>
                <div class="description"></div>
              </div>
              <div class="d-flex nodata" *ngIf="firstDay">
                <div><i class="fa-solid fa-circle-info"></i></div>
                <div class="">The CSP has not billed yet.</div>
              </div>
            </div>
            <!-- <div class="rightbox"> -->
            <div class="costIcon costIcon4"><i class="fa-solid fa-coins"></i></div>
            <!-- </div> -->
            <button *ngIf="!dailyCostLoader && !dailyCostApiError && !firstDay" (click)="viewDailycost($event)"
              class="btn cost-btn"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="dailyCostLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>

      </div>


      <!-- <div class="row mt-4">
        <div class="col-4">
          <div class="cpuCard">
            <div class="cpuicon"><i class="fa-sharp fa-solid fa-microchip"></i></div>
            <div class="cpuhold">
              <div class="cputitle">vCPU</div>
              <div class="cpuValue"><app-price-animation [price]="countOfCpu"></app-price-animation></div>
            </div>
            <button *ngIf="!vcpuLoader" class="btn s3btn" (click)="viewOS($event)"><i
                class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="vcpuLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>
        <div class="col-4">
          <div class="cpuCard">
            <div class="cpuicon"><i class="fa-solid fa-tags"></i></div>
            <div class="cpuhold">
              <div class="cputitle">Tags</div>
              <div class="cpuValue"><app-price-animation [price]="countOfTags"></app-price-animation></div>
            </div>
            <button *ngIf="!tagLoader" class="btn s3btn" (click)="viewTags($event)"><i
                class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="tagLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>

        <div class="col-4">
          <div class="cpuCard">
            <div class="cpuicon"><i class="fa-solid fa-database"></i></div>
            <div class="cpuhold">
              <div class="cputitle">Storage</div>
              <div class="cpuValue ">{{s3Size['total_storage_of_all_account']}}</div>
            </div>
            <button *ngIf="!storageLoader" class="btn s3btn" (click)="viewStorage($event)"><i
                class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="storageLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>


      </div>

      <div class="row mt-4">

        <div class="col-6">
          <div class="unresolvedOuter boxshadow">
            <div class="d-flex justify-content-start">
              <div class="costTitle2">
                <p>Instances Type</p>
              </div>

              <button class="btn" *ngIf="!instanceNotFound && !instanceLoader" (click)="viewinstances($event)"><i
                  class="fa-solid fa-up-right-and-down-left-from-center"></i></button>

              <div *ngIf="instanceLoader"><img src="../assets/img/loading_2.svg" alt="loading scans"
                  class="minloader" /></div>
            </div>
            <div *ngIf="instanceNotFound" class="p-1 mt-2 pt-0 pb-0 bgc3">No Instances Found</div>
            <div *ngIf="!instanceNotFound" class="unresolvedGraph" id="unresolvedGraph"></div>
          </div>
        </div>

        <div class="col-6">
          <div class="unresolvedOuter boxshadow">
            <div class="d-flex justify-content-start">
              <div class="costTitle2">
                <p>OS Type</p>
              </div>
              <button class="btn" (click)="viewwinTab($event)" *ngIf="!osNotFound && !osLoader"><i
                  class="fa-solid fa-up-right-and-down-left-from-center"></i></button>

              <div *ngIf="osLoader"><img src="../assets/img/loading_2.svg" alt="loading scans" class="minloader" />
              </div>
            </div>
            <div *ngIf="osNotFound" class="p-1 mt-2 pt-0 pb-0 bgc3">No OS Found</div>
            <div *ngIf="!osNotFound" class="unresolvedGraph" id="osGraph"></div>
          </div>
        </div>
      </div> -->

    </div>
    <div class="col-4 mt-3">
      <div class="costCompareOuterBox boxshadow">
        <div class="d-flex justify-content-start">
          <div class="costTitle2">Forecast for upcoming month</div>
          <button *ngIf="!forcastLoader && !firstDay" class="btn" (click)="viewForcast($event)"><i
              class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
          <div *ngIf="forcastLoader"><img src="../assets/img/loading_2.svg" alt="loading scans" class="minloader" />
          </div>
        </div>
        <!-- <div>
          <div class="info2">
            <i class="fa-solid fa-triangle-exclamation info-pre"></i> <span class="info-text2">The values displayed may not be correct due to missing previous month data</span>
          </div>
        </div> -->
        <div class="desHold mt-2 " *ngIf="costApiError">
          <div class="signHold lower">
            <div class="signIcon"></div>
            <div class="signValue3">Cost Explorer API not enabled.<a
                href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html" target="_blank"><span
                  class="signIcon2"><i class="fa-solid fa-up-right-from-square"></i></span></a></div>
          </div>
          <div class="description"></div>
        </div>
        <div class="d-flex nodata margintop" *ngIf="firstDay">
          <div><i class="fa-solid fa-circle-info"></i></div>
          <div class="">The CSP has not billed yet.</div>
        </div>
        <div class="costCompareGraph" *ngIf="!costApiError && !firstDay" id="costCompareGraph"></div>
      </div>


    </div>
    <div class="row mt-4 p-0 m-0">
      <div class="col-12 h-auto w-100">
        <div class="unresolvedOuter2 boxshadow">
          <div class="d-flex justify-content-start">
            <div class="costTitle2 d-flex">
              <span>Top 5 Cost of Resources by Month to Date</span>
              <a href="/dash/costopt/ec2" target="_blank"> <span data-toggle="tooltip" data-placement="up" title="Full Report"><i class="fa-solid fa-up-right-from-square"></i></span></a>
            </div>
            <div class="btnhold"><button class="sortBtn" *ngIf="!resCostApiError || !firstDay" (click)="loadResourseGraphData()"><i class="fa-sharp fa-solid sorticon" [ngClass]="sortIcon"></i></button></div>
          </div>
          <div class="desHold " *ngIf="resCostApiError && !firstDay">
            <div class="signHold lower">
              <div class="signIcon"></div>
              <div class="signValue3">Cost Explorer API not enabled. <a
                  href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html" target="_blank">
                  <span class="signIcon2"><i class="fa-solid fa-up-right-from-square"></i></span></a></div>
            </div>
            <div class="description"></div>
          </div>
          <div class="d-flex nodata2 margintop" *ngIf="firstDay">
            <div><i class="fa-solid fa-circle-info"></i></div>
            <div class="">The CSP has not billed yet.</div>
          </div>
          <div class="unresolvedGraph2" *ngIf="!resCostApiError" id="resourcesGraph"></div>
        </div>
      </div>

    </div>
  </div>


  <div class="row">
    <div class="col-8">
      <div class="card border-primary">
        <div class="card-header text-center"><b>Infrastructure Details (All Accounts)</b></div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <div class="cpuCard">
            <div class="cpuicon"><i class="fa-sharp fa-solid fa-microchip"></i></div>
            <div class="cpuhold">
              <div class="cputitle">vCPU</div>
              <div class="cpuValue"><app-price-animation [price]="countOfCpu"></app-price-animation></div>
            </div>
            <button *ngIf="!vcpuLoader && !vcpuNotFound" class="btn s3btn" (click)="viewOS($event)"><i
                class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="vcpuLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>
        <div class="col-4">
          <div class="cpuCard">
            <div class="cpuicon"><i class="fa-solid fa-memory"></i></div>
            <div class="cpuhold">
              <div class="cputitle">RAM</div>
              <div class="cpuValue">{{countOfTags}}</div>
            </div>
            <button *ngIf="!tagLoader && !ramNotFound" class="btn s3btn" (click)="viewTags($event)"><i
                class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="tagLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>

        <div class="col-4">
          <div class="cpuCard">
            <div class="cpuicon"><i class="fa-solid fa-database"></i></div>
            <div class="cpuhold">
              <div class="cputitle">Storage</div>
              <div class="cpuValue ">{{s3Size['total_storage_of_all_account']}}</div>
            </div>
            <button *ngIf="!storageLoader && !s3NotFound" class="btn s3btn" (click)="viewStorage($event)"><i
                class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div *ngIf="storageLoader" class="cost-btn cost-margin"><img src="../assets/img/loading_2.svg"
                alt="loading scans" class="minloader" /></div>
          </div>
        </div>


      </div>
      <div class="row mt-4">

        <div class="col-6">
          <div class="unresolvedOuter boxshadow">
            <div class="d-flex justify-content-start">
              <div class="costTitle2">
                <p>Instances Type</p>
              </div>

              <button class="btn" *ngIf="!instanceNotFound && !instanceLoader" (click)="viewinstances($event)"><i
                  class="fa-solid fa-up-right-and-down-left-from-center"></i></button>

              <div *ngIf="instanceLoader"><img src="../assets/img/loading_2.svg" alt="loading scans"
                  class="minloader" /></div>
            </div>
            <div *ngIf="instanceNotFound" class="p-1 mt-2 pt-0 pb-0 bgc3">No Instances Found</div>
            <div *ngIf="!instanceNotFound" class="unresolvedGraph" id="unresolvedGraph"></div>
          </div>
        </div>

        <div class="col-6">
          <div class="unresolvedOuter boxshadow">
            <div class="d-flex justify-content-start">
              <div class="costTitle2">
                <p>OS Type</p>
              </div>
              <button class="btn" (click)="viewwinTab($event)" *ngIf="!osNotFound && !osLoader"><i
                  class="fa-solid fa-up-right-and-down-left-from-center"></i></button>

              <div *ngIf="osLoader"><img src="../assets/img/loading_2.svg" alt="loading scans" class="minloader" />
              </div>
            </div>
            <div *ngIf="osNotFound" class="p-1 mt-2 pt-0 pb-0 bgc3">No OS Found</div>
            <div *ngIf="!osNotFound" class="unresolvedGraph" id="osGraph"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="totalIssue boxshadow">
        <div class="d-flex justify-content-start">
          <div class="costTitle2">Total Number of Issues
          </div>
          <div *ngIf="minloader"><img src="../assets/img/loading_2.svg" alt="loading scans" class="minloader" /></div>
        </div>
        <div *ngIf="!ticketNotFound" class="p-1 mt-2 pt-0 pb-0 bgc">Since last month</div>
        <div *ngIf="ticketNotFound" class="p-1 mt-2 pt-0 pb-0 bgc3">Tickets Not Found</div>
        <!-- <div class="round_loader loader" *ngIf="loading"></div> -->

        <div *ngIf="!ticketNotFound" class="costCompareGraph2" id="issueGraph"></div>


      </div>

    </div>

  </div>


  <!-- <div class="firstOuterBox">

    <div class="leftOuterBox">
    
     
      <div class="ticketOuterBox">
        

      </div>
    </div>
    <div class="rightOuterBox">

     
    </div>

  </div>
  <div class="secondOuterBox hide">
    <div class="ec2OuterBox">
      <div class="ec2Graph boxshadow">
        <div class="costTitle2">EC2 Instances</div>
        <div class="ec2GraphHold"></div>
      </div>
      <div class="distributionGraph boxshadow">
        <div class="costTitle2">Distribution of Categories</div>
        <div class="ec2GraphHold" id="distributionGraph"></div>
      </div>
    </div>

  </div> -->

</div>

<ng-container *ngIf="cloudPartner == 'azure'">
  <app-azure-dashboard [resourceGroup]="resourceGroup"></app-azure-dashboard>
</ng-container>


<!-- <div class="encryptionOuter"></div> -->
<app-view-ins-tab [instancesTypes]="outputArray" [regionHeader]="regionHeaders" *ngIf="instancesView" (hideModal)="hideIns($event)"></app-view-ins-tab>
<app-view-s3-tab *ngIf="storageView" [StorageTypes]="storageList" [regionHeader]="regionHeaders" (hideModal)="hideStorage($event)"></app-view-s3-tab>
<app-view-os-tab *ngIf="OSView" [insList]="insList" [regionHeader]="regionHeaders" (hideModal)="hideOS($event)"></app-view-os-tab>
<app-multi-account-tab *ngIf="accView && cloudPartner == 'aws'" [isFirstReload]="isFirstReload" (hideModal)="hidePrompt($event)"
  (acctIdList)="getAccList($event)" (hideView)="cancelPrompt($event)"></app-multi-account-tab>
<app-view-annual-tab *ngIf="annualView" [annualCost]="yearly"
  (hideModal)="hideAnnualCost($event)"></app-view-annual-tab>
<app-view-monthly-tab *ngIf="monthlyView" [monthlyCost]="monthly"
  (hideModal)="hideMonthlyCost($event)"></app-view-monthly-tab>
<app-view-daily-tab *ngIf="dailyView" [dailyCost]="dailyCost" (hideModal)="hideDailyCost($event)"></app-view-daily-tab>
<app-view-win-lin-tab *ngIf="winTab" [winTypes]="oswin" [regionHeader]="regionHeaders" (hideModal)="hidewinTab($event)"></app-view-win-lin-tab>
<app-view-tag-tab *ngIf="tagTab" [tagList]="tagsList" [regionHeader]="regionHeaders" (hideModal)="hidetagTab($event)"></app-view-tag-tab>
<app-view-forcast-tab *ngIf="forcastTab" [forcastList]="forcastList"
  (hideModal)="hideForcastTab($event)"></app-view-forcast-tab>
<app-view-cost-saving-tab *ngIf="costSavingTab" [CostSavings]="CostSavings"
  (hideModal)="hidecostsavingTab($event)"></app-view-cost-saving-tab>