<div class="screen">
    <div class="viewer-container">
        <div class="viewer-canvas">
            <div class="from-permission">
                Swayam
            </div>
            <div class="flex-1 overflow-auto">
                <div class="flex-1 viewer-body" [ngStyle]="{'width': '{{ this.width }}px'}">
                    <div class="center item-header item">
                        MinfySwayamStack
                    </div>
                    <div class="center flex-center">
                        <div class="line"></div>
                    </div>
                    <div class="center item-header item">
                        Resources
                    </div>
                    <div class="center flex-center">
                        <div class="line"></div>
                    </div>
                    <div>
                        <div class="flex-center flex-align-top">
                            <ng-container *ngFor="let resource of getObjectKeys(json['Resources'])">
                                <div class="resource main-resource border-top">
                                    <div *ngIf="json['Resources'][resource]['Show']">
                                        <div class="resource-container" (click)="json['Resources'][resource]['Show'] = false">
                                            <div class="resource-header-title flex-start p-b-5">
                                                <div>
                                                    {{ resource }}
                                                </div>
                                            </div>
                                            <div class="p-b-5">
                                                <div class="text-bold">Type:</div>
                                                <div>
                                                    {{ types[json['Resources'][resource]['Type']] }}
                                                </div>
                                            </div>
                                            <div class="resource-header-description p-b-5">
                                                <div class="text-bold">Description:</div>
                                                <div>
                                                    {{ json['Resources'][resource]['Properties']['Description'] }}
                                                </div>
                                            </div>
                                            <ng-container
                                                    *ngIf="json['Resources'][resource]['Type'] == 'AWS::Serverless::Function'">
                                                <div class="resource-header-description p-b-5">
                                                    <div class="text-bold">Memory:</div>
                                                    <div>
                                                        {{ json['Resources'][resource]['Properties']['MemorySize'] }} MB
                                                    </div>
                                                </div>
                                                <div class="resource-header-description p-b-5">
                                                    <div class="text-bold">Runtime:</div>
                                                    <div>
                                                        {{ json['Resources'][resource]['Properties']['Runtime'] }}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="center flex-center">
                                            <div class="line"></div>
                                        </div>
                                        <div class="center item-header item">
                                            Policies
                                        </div>
                                        <div class="center flex-center">
                                            <div class="line"></div>
                                        </div>
                                        <div class="flex-center flex-align-top">
                                            <ng-container *ngFor="let policy of json['Resources'][resource]['Properties']['Policies']">
                                                <div class="resource border-top policy">
                                                    <div *ngIf="policy['Show']">
                                                        <div class="resource-container" (click)="policy['Show'] = false">
                                                            <div class="resource-header-title flex-start p-b-5">
                                                                <div>
                                                                    {{ typeOf(policy) == 'string' ? policy : policy['PolicyName'] || 'Inline Policy' }}
                                                                </div>
                                                            </div>
                                                            <div class="p-b-5">
                                                                <div class="text-bold">Action:</div>
                                                                <div [innerHTML]="typeOf(getStatement(policy)['Action']) == 'string' ? getStatement(policy)['Action'] : getStatement(policy)['Action'].join('<br>')">
                                                                </div>
                                                            </div>
                                                            <div class="p-b-5">
                                                                <div class="text-bold">Resources:</div>
                                                                <div [innerHTML]="typeOf(getStatement(policy)['Resource']) == 'string' ? getStatement(policy)['Resource'] : getStatement(policy)['Resource'].join('<br>')">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!policy['Show']" class="center">
                                                        <div class="resource-container" (click)="typeOf(policy) != 'string' ? policy['Show'] = true: null">
                                                            <div class="resource-header-title flex-start p-b-5">
                                                                {{ typeOf(policy) == 'string' ? policy : policy['PolicyName'] || 'Inline Policy' }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div *ngIf="!json['Resources'][resource]['Show']" class="center">
                                        <div class="resource-container" (click)="showResource(resource)">
                                            <div class="resource-header-title center p-b-5">
                                                {{ resource }}
                                                <div class="new-feature" *ngIf="json['Resources'][resource]['New']">
                                                    New
                                                </div>
                                            </div>
                                            <div class="resource-header-description">
                                                {{ types[json['Resources'][resource]['Type']] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="to-permission">
            AWS Account (12345678901)
        </div>
    </div>
</div>