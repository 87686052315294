import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'error-upload-bom',
  templateUrl: './error.upload.bom.component.html',
  styleUrls: ['./error.upload.bom.component.css'],
})
export class ErrorUploadBOM implements OnInit, OnDestroy {
  loading = true;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  async uploadFile() {
    this.hideModel.emit(true);
  }

  ngOnDestroy(): void {}

  close() {
    this.hideModel.emit(false);
  }
}
