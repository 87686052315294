<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Security Group Information</span>
            <button  class="sortBtn" (click)="no()"><i class="fa-solid fa-xmark crossicon"></i></button>
          </div>
       
      <div class="KBBody table-responsive ">

        <table class="table table-hover  mb-0">
            <thead class="blue fixed-header">
              <tr>
                <td scope="col">S.N.</td>
                <td scope="col">Security Group Name</td>
                <td scope="col">Security Group ID</td>      
                <td scope="col">VPC ID</td>
                <td scope="col">Description</td> 
              </tr>
            </thead>
          

            <tbody class="scrollable-body">
              <tr *ngFor="let i of SGList; let j = index">
                <td scope="row" class="m-4">{{j+1}}</td>
                <td>{{i.sgName.sg_name}}</td>
                <td>{{i.sgID}}</td>
                <td>{{i.sgName.vpc_id}}</td>
                <td>{{i.sgName.description}}</td>
              </tr>           
            </tbody> 
        </table>
        
          <!-- <div class="btnHold">
            <div class="bhold">
              <button class="btn m-2" [disabled]="sg_currentPage === 1" (click)="sg_onPrev()">Previous</button>
              <button class="btn m-2" [disabled]="getDisplaySGData().length < sg_itemsPerPage"  
              [disabled]="SGList.length < 5" (click)="sg_onNext()">Next</button>
            </div>
          </div> -->


        <!-- <div class="KBActions">
          <button class="btn btn-light" (click)="no()">Cancel</button>
        </div> -->
      
    </div>
          
</div>