<div class="confirmContainer">
    <div class="confirmBox">
        <div class="d-flex justify-content-between">
            <span class="table-title">Select the Dashboard Accounts</span>
            <span *ngIf="isAllCftEnable && notaccess"  (click)="selectAll($event)" class=" btn-light"><i [ngClass]="toggelselectAll ? 'fa-solid fa-square-check' : 'fa-regular fa-square' "></i>&nbsp; Select All</span>
          </div>
       
          <div class="KBBody table-responsive ">

            <table class="table table-hover  mb-0">
                <thead class="blue fixed-header">
                  <tr>
                    <td scope="col">S.N.</td>
                    <td scope="col">Account Name</td>
                    <td scope="col">Account ID</td>   
                    <td class="text-center" scope="col"></td>     
                  </tr>
                </thead>
              
    
                <tbody class="scrollable-body">
                      <tr *ngFor="let i of accountIdsList; let j = index">
                        <td>{{j+1}}</td>
                        <td>{{i.accountName}}</td>
                        <td>{{i.accountId}}</td>
                        <td *ngIf="i.cft_status==true && i.accessDenied==false && !idAccessCheckingLoader" class="text-center"> <input type="checkbox" [checked]="i.selected" (change)="onCheckboxChange($event, i.accountId)" class="form-check-input"> </td>
                        <td *ngIf="i.cft_status!=true && !idAccessCheckingLoader" class="text-center"><i class="fa fa-exclamation-triangle color-red"
                          title="Cloud Formation Template is not Configured"></i>
                        </td>
                          <td *ngIf="i.accessDenied==true && i.cft_status==true && !idAccessCheckingLoader" class="text-center"><i class="fa-solid fa-ban"
                            title="Access Denied"></i></td>
                            <td *ngIf="idAccessCheckingLoader" class="text-center">
                              <img src="../assets/img/loading_2.svg" alt="loading scans"
                              class="minloader"/>
                            </td>
                      </tr>  
                </tbody> 
            </table>
          
        </div>
        <div *ngIf="cftErrMsg" class="cautionMsg"><i class="fa fa-exclamation-triangle color-red"></i> <span>Cloud Formation Template is not Configured</span></div>  
        <div class="btnHold">
          <button type="button" *ngIf="!isFirstReload" (click)="cancel()" class="btn btn-danger-soft">Cancel</button>
            <button type="button" [class.disabled]="selectedAccountIds.length===0 || !isAllCftEnable || idAccessCheckingLoader ||!notaccess"  (click)="submitAccId()" class="btn btn-apply">Apply</button>
          </div>
          
</div>



