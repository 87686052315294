




<table class="table table-responsive table-hover">
    <thead>
        <tr>
          <th scope="col"><input *ngIf="isdailycostres" (click)="markAll()" [checked]="dcAll"  type="checkbox" class="check" name="" id=""></th>
            
            <th scope="col">Event Time</th>
            <th scope="col">Criticality</th>
            <th scope="col">Description</th>
            <th scope="col">Identifier</th>
            <th scope="col">Triggered By</th>
           
            
           
        
        </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of getItemsForPage(currentPage)">
        <tr *ngIf="item.status=='incomplete' && isdailycostres">
            <td><input type="checkbox" [checked]="item.check" (click)="updateStatus(item.id)" name="" id=""></td>
           
            
            <td>{{item.eventTime}}</td>
            <td *ngIf="item.severity"><span class="badge" [ngClass]="{ redg: item.severity==='High',orangeg: item.severity==='Medium',greeng: item.severity==='Low'}" >{{item.severity}}</span></td>
            <td>{{item.description}}</td>
            <td *ngIf="item.identifier !== ''">{{fixedDecimal(item.identifier)}}</td>
            <td *ngIf="item.identifier == ''">-</td>
            
            <td>{{item.createdBy}}</td>
            
        </tr>
      </ng-container>
      <tr *ngIf="!isdailycostres">
        <td colspan="9" class="text-center">No Daily Cost Alerts Found!</td>
      </tr>
    </tbody>
    </table>
    
    <div class="d-flex justify-content-end">
    <nav>
      <ul class="pagination">
    <li class="page-item" [class.disabled]="currentPage ===1">
    <a class="page-link" (click)="navigateToPreviousPage()">Previous</a>
    </li>
    <li class="page-item" *ngFor="let page of getPageNumbers()" [class.active]="currentPage === page">
      <a class="page-link" (click)="currentPage = page">{{page}}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="navigateToNextPage()">Next</a>
        </li>
      </ul>
    </nav>
    </div>

