import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  HostListener,
} from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let window: any;
declare let $: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './mastersummary.component.html',
  styleUrls: ['./mastersummary.component.css'],
})
export class MasterSummaryComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  summary: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;

  selectedMasterAccount: any;

  headers: any = [
    {
      id: 'masterAccountId',
      name: 'Organization ID',
      filter: true,
    },
    {
      id: 'totalBill',
      name: 'Total Bill',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'tax',
      name: 'Tax',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'refund',
      name: 'Refund',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'credits',
      name: 'Credits',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
  ];

  lidAccountsHeader: any = [
    {
      id: 'lId',
      name: 'Linked Account ID',
      filter: true,
    },
    {
      id: 'awsTotalCost',
      name: 'AWS Total Cost',
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'minfyTotalCost',
      name: 'Minfy Total Cost',
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'profit',
      name: 'Profit',
      filter: true,
      sort: { sort: true, direction: 'Asc' },
      alter: (value: any, param: string) => {
        return value['profit_html'];
      },
    },
  ];

  summaryHeader: any = [
    {
      id: 'totalBill',
      name: 'Total Bill',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'tax',
      name: 'Total Tax',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'refund',
      name: 'Total Refund',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'credits',
      name: 'Total Credits',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
  ];

  CBSSummaryHeader: any = [
    {
      id: 'totalBillIncRefund',
      name: 'Total Usage Exc. Refund',
      alter: (value: any, param: string) => {
        return (isNaN(value[param]) ? 0 : Number(value[param])).toFixed(2);
      },
    },
    {
      id: 'totalBill',
      name: 'Total Usage Inc. Refund',
      alter: (value: any, param: string) => {
        return (isNaN(value[param]) ? 0 : Number(value[param])).toFixed(2);
      },
    },
    {
      id: 'alspl',
      name: 'AWS Base Usage',
      alter: (value: any, param: string) => {
        return (isNaN(value[param]) ? 0 : Number(value[param])).toFixed(2);
      },
    },
    {
      id: 'marketPlace',
      name: 'AWS Market Place',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'tax',
      name: 'Total Tax',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'refund',
      name: 'Total Refund',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'credits',
      name: 'Total Credits',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'edpDiscount',
      name: 'Total EDP Discount',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'totalBill',
      name: 'Total Value Excluding Tax',
      alter: (value: any, param: string) => {
        return Number(Number(value[param]) - Number(value['tax'])).toFixed(2);
      },
    },
  ];

  CBSHeader: any = [
    {
      id: 'masterAccountId',
      name: 'Organization ID',
      click: 'loadLids',
      filter: true,
    },
    {
      id: 'masterAccountName',
      name: 'Master Account Name',
      filter: true,
    },
    {
      id: 'totalBillIncRefund',
      name: 'Total Usage Exc. Refund',
      alter: (value: any, param: string) => {
        return (isNaN(value[param]) ? 0 : Number(value[param])).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'totalBill',
      name: 'Total Usage Inc. Refund',
      alter: (value: any, param: string) => {
        return (isNaN(value[param]) ? 0 : Number(value[param])).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'alspl',
      name: 'AWS Base Usage',
      alter: (value: any, param: string) => {
        return (isNaN(value[param]) ? 0 : Number(value[param])).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'marketPlace',
      name: 'AWS Market Place',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'tax',
      name: 'Tax',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'refund',
      name: 'Refund',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'credits',
      name: 'Credits',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'edpDiscount',
      name: 'EDP Discount',
      alter: (value: any, param: string) => {
        return Number(value[param]).toFixed(2);
      },
      filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
  ];

  funRef: any = {};
  CBSPortal: boolean = window.location.href.indexOf('cbs.swayam.cloud') > -1;
  // CBSPortal: boolean = true;

  constructor(
    public notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.funRef = {
      loadLids: this.loadLidData,
    };

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    let temp = moment(new Date());
    this.dateRange = temp.format('MM/YYYY');

    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm/yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  checkForMoreData() {
    // let bottomHeight =
    //   document.documentElement.scrollHeight -
    //   window.innerHeight -
    //   window.scrollY;
    // if (
    //   bottomHeight < 300 &&
    //   this.nextToken &&
    //   !this.fetching &&
    //   !this.selectedMasterAccount
    // ) {
    // }
    if (this.nextToken) {
      this.loadData();
    }
  }

  nextToken: any;
  fetching: boolean = true;
  async load() {
    this.nextToken = null;
    this.summary = [];
    this.loadData();
  }

  refresh() {
    if (this.selectedMasterAccount) {
      this.loadLidData(this, this.selectedMasterAccount, '');
    } else {
      this.nextToken = null;
      this.summary = [];
      this.loadData();
    }
  }

  async loadData() {
    this.fetching = true;
    let dRange: any = $('#monthDate').val() || this.dateRange;
    let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    let data: any = {
      month: sData,
      lek: this.nextToken ? this.nextToken : undefined,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/organisationsummary`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (this.nextToken != data['lek']) {
      return;
    }
    if (result.status == '1' || result.s == '1') {
      if (result.summaryDetails) {
        this.summary = [...this.summary, ...result.summaryDetails];
      } else {
        this.fetching = false;
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
    if (result.lek) {
      this.nextToken = result.lek;
    } else {
      this.nextToken = null;
    }
    this.fetching = false;
    if (result.lek) {
      this.checkForMoreData();
    }
    ``;
  }

  goBack() {
    this.selectedMasterAccount = null;
  }

  async loadLidData(ref: any, value: any, nextToken: any = '') {
    ref.fetching = true;
    let dRange: any = $('#monthDate').val() || ref.dateRange;
    let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    if (nextToken == '') {
      value['lids'] = [];
      ref.selectedMasterAccount = value;
    }
    let data = {
      mId: value.masterAccountId,
      m: sData,
      lek: nextToken == '' ? undefined : nextToken,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await ref.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      if (result.billingDetailsList) {
        result.billingDetailsList = result.billingDetailsList.map(
          (lid: any) => {
            lid['profit'] = lid['minfyTotalCost'] - lid['awsTotalCost'];
            lid['profit_html'] =
              lid['profit'] < 0
                ? `<span class='red_color_text'>${lid['profit'].toFixed(
                    2
                  )}</span>`
                : `<span class='green_color_text'>${lid['profit'].toFixed(
                    2
                  )}</span>`;
            return lid;
          }
        );
        value['lids'] = [...value['lids'], ...result.billingDetailsList];
      } else {
        ref.fetching = false;
      }
    } else {
      ref.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    if (result.lek) {
      await ref.loadLidData(ref, value, result.lek);
    }
    ref.fetching = false;
  }

  sumAccount() {
    let total: any = {
      totalBill: 0,
      totalBillIncRefund: 0,
      tax: 0,
      refund: 0,
      credits: 0,
      edpDiscount: 0,
      alspl: 0,
      marketPlace: 0,
    };
    this.summary.forEach((account: any) => {
      for (let key of Object.keys(account)) {
        if (total.hasOwnProperty(key)) {
          if (account[key] != 'NA' && account[key] != '') {
            total[key] += Number(account[key]);
          }
        }
      }
    });

    for (let key of Object.keys(total)) {
      try {
        total[key] = Number(total[key].toFixed(3));
      } catch (error) {}
    }
    return total;
  }

  callFunction(param: any, value: any) {
    this.funRef[param](this, value);
  }

  export() {
    if (this.selectedMasterAccount) {
      window.exportToExcelElement(
        document.getElementById('linked_data_table'),
        `Linked Account - ${this.selectedMasterAccount.masterAccountId}`,
        'csv'
      );
    } else {
      window.exportToExcelElement(
        document.getElementById('master_accounts_table'),
        `Master Accounts`,
        'csv'
      );
    }
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
