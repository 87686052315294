<div class="screen-container">
  <div class="screen-main">
    <div class="screen-header">
      {{ action }} User
    </div>
    <div class="screen-body">
      <div *ngIf="action == 'Disable'">
        Disabled user cannot log in to the {{ whitelabelData['pd']['appName'] }} portal. <br> Do you want to disable {{ whitelabelData['pd']['appName'] }} access to <span
          class="bold-text">{{ email }}</span> ? 
      </div>
      <div *ngIf="action == 'Enable'">
        Do you want to enable {{ whitelabelData['pd']['appName'] }} access to <span class="bold-text">{{ email }}</span>?
      </div>
    </div>
    <div class="screen-actions">
      <button class="btn btn-light" (click)="hideModal.emit(false)">Cancel</button>
      <button class="btn btn-danger-soft" *ngIf="action == 'Disable'" (click)="hideModal.emit(true)">Disable</button>
      <button class="btn btn-success-soft" *ngIf="action == 'Enable'" (click)="hideModal.emit(true)">Enable</button>
    </div>
  </div>
</div>