<div class="card">
  <h5 class="card-header flex-between">
    <div class="flex-start">
      <a [routerLink]="'/dash/reports/home'" class="default-link">
        <button class="btn btn-light flex-start">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>Reports</span>
        </button>
      </a>
      <span>Snapshot Ageing Report</span>
    </div>
    <div class="flex-start">
      <button class="btn btn-light flex-start" (click)="load()">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        <span class="color-inherit">
          Refresh
        </span>
      </button>
      <button class="btn btn-light flex-start" (click)="export()">
        <i class="fa fa-download" aria-hidden="true"></i>
        <span class="color-inherit">
          Export
        </span>
      </button>
    </div>
  </h5>
  <div class="card-body">
    <table class="table table-docs table-hover" id="ageingSnapshotsTable">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of oldEBSHeaders">{{h.name}}</th>
        </tr>
      </thead>

      <tbody *ngIf="oldEBSList.length > 0">
        <tr *ngFor="let i of oldEBSList; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of oldEBSHeaders">
            <span *ngIf="!h.click">
              <span *ngIf="!h.color">
                <span>{{i[h.id]}}</span>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="oldEBSList.length == 0 && !loading">
        <tr>
          <td [attr.colspan]="oldEBSHeaders.length + 1">
            <div class="txt-cntr">No Data Found</div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td [attr.colspan]="oldEBSHeaders.length + 1">
            <div class="center flex-center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
              <span>
                Loading...
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>