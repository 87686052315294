export const periods = [
  // {
  //   id: "0",
  //   resource: "Last 1 hour",
  //   description: "From 60 minutes ago until the current time.",
  // },
  // {
  //   id: "1",
  //   resource: "Last 24 hours",
  //   description: "From 24 hours ago until the current time.",
  // },
  // { id: "2", resource: "Last 7 Days", description: "For example" },
  // {
  //   id: "3",
  //   resource: "Today",
  //   description:
  //     "From 12 am of the current calendar day until the current time.",
  // },
  // {
  //   id: "4",
  //   resource: "Yesterday",
  //   description:
  //     "From 12 am of the previous calendar day until 12 am of the current calendar day.",
  // },
  // { id: "5", resource: "Last 30 Days", description: "For example" },
  { id: "12", resource: "Weekly", description: "For example" },
  { id: "7", resource: "Monthly", description: "For example" },
  { id: "8", resource: "Yearly", description: "For example" },
  // { id: "9", resource: "This Quarter", description: "For example" },
  // { id: "11", resource: "This Week", description: "For example" },
  // { id: "13", resource: "This Month", description: "For example" },
  // {
  //   id: "17",
  //   resource: "Last 6 hours",
  //   description: "From 6 hours ago until the current time.",
  // },
  // {
  //   id: "18",
  //   resource: "Last 12 hours",
  //   description: "From 12 hours ago until the current time.",
  // },
  { id: "22", resource: "Quarterly", description: "For example" },
  // { id: "25", resource: "Last 3 Months", description: "For example" },
  // {
  //   id: "50",
  //   resource: "Custom Period",
  //   description:
  //     "Allows the start and end date for the report based on user’s choice.",
  // },
  // {
  //   id: "51",
  //   resource: "Last Poll",
  //   description:
  //     "Last Polled values. This time period is available only for Top N/Bottom N reports and widgets for certain important performance metrics in a monitor type.",
  // },
];
