<div class="flex-column">
  <div class="overflow-auto">
    <ng-container *ngFor="let condition of conditions; let i = index">
      <div class="flex-start mb-4">
        <div>
          <div class="text-bold">Number of Servers</div>
          <div>
            <input type="number" [(ngModel)]="condition['servers']" class="form-control">
          </div>
        </div>
        <div>
          <div class="text-bold">Total Hours per month</div>
          <div>
            <input type="number" [(ngModel)]="condition['total_hours']" class="form-control">
          </div>
        </div>
        <div>
          <div class="text-bold">Estimated Cost</div>
          <div class="p-2">
            {{ (condition['servers'] * condition['total_hours'] * 0.03).toFixed(3) }}
          </div>
        </div>
        <div>
          <div class="hide-visible">Remove</div>
          <button class="btn btn-danger-soft" (click)="removeCondition(i)" *ngIf="conditions.length > 1">
            <i class="fa fa-minus"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="flex-between pt-2 border-top-light">
    <button class="btn btn-primary-soft flex-start" (click)="addCondition()">
      <i class="fa fa-plus"></i>
      Add Condition
    </button>
    <div class="flex-start">
      <div class="text-bold">ESTIMATED TOTAL: </div>
      <div class="text-bold">{{ calculateTotal() || 0 }}</div>
    </div>
  </div>
</div>