import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashService {
  private messageSource = new BehaviorSubject("0");
  private greenSource = new BehaviorSubject("0");
  currentMessage = this.messageSource.asObservable();
  greenmessag= this.greenSource.asObservable();

  sendNotification(action: string, data: any = null) {
    this.messageSource.next(JSON.stringify({ action: action, data: data }));
  }

  // greenNotification(action: string, data: any = null){
  //   this.greenSource.next(JSON.stringify({ action: action, data: data }));
  // }
}
