import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { APIService } from "src/app/api/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "site24x7-monitor-activity",
  templateUrl: "./monitor-activity.component.html",
  styleUrls: ["./monitor-activity.component.css"],
})
export class MonitorActivityComponent implements OnInit {
  @Output("hideModel") hideModel: any = new EventEmitter<void>();
  loading: boolean = false;
  monitors: any = [];
  appended: any = [];
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  constructor(private apiService: APIService) {}

  ngOnInit(): void {
    this.loadConsolidatedHisotry();
  }

  dates: any = [];

  datesAppended: any = [];

  activityRows: any = [];
  headers: any = [];
  currentRange: string = "";
  currentRangeValue: string = "";
  pagination: any = [];

  async loadConsolidatedHisotry() {
    this.pagination = [
      {
        click: () => this.loadConsolidatedHisotry,
        value: "History",
        type: "summary",
        range: "month",
      },
    ];
    this.activityRows = [];
    this.currentRange = "month";
    let account: any = localStorage.getItem("emsConfig");
    let accountOld: any = JSON.parse(localStorage.getItem("accountIdData"))[
      "list"
    ].find((account: any) => {
      return account["accountId"] == localStorage.getItem("accountId");
    });
    if (!(account || accountOld["site_247_project_id"])) {
      this.loading = false;
      return;
    }

    if (account) {
      account = JSON.parse(account);
    }

    this.loading = true;
    let data: any = {
      action: "bu_history",
      id_token: localStorage.getItem("idT"),
      zaaid: account ? account["zaid"] : accountOld["site_247_project_id"],
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      origin: account ? "swayam" : "minfy",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    this.headers = [
      {
        id: "monitor_id",
        name: "Monitor ID",
      },
      {
        id: "instance_id",
        name: "Tag Name",
      },
    ];

    if (result.status == "1" || result.s == "1") {
      this.activityRows = [];
      let added: any = [];
      result.records.forEach((record: any) => {
        let header: any = this.headers.find((header: any) => {
          return header.id == record.month;
        });
        if (!header) {
          this.headers.push({
            id: record["month"],
            name: record["month"],
            click: true,
            sort: true,
          });
        }
        if (added.indexOf(record["monitor_id"]) > -1) {
          let record_: any = this.activityRows.find((record_: any) => {
            return record_["monitor_id"] == record["monitor_id"];
          });
          if (record_) {
            record_[`${record["month"]}`] = record["hours"];
          }
        } else {
          let dict: any = {
            monitor_id: record["monitor_id"],
            instance_id: record["name"],
          };
          dict[`${record["month"]}`] = record["hours"];
          this.activityRows.push(dict);
          added.push(record["monitor_id"]);
        }
      });
    }

    this.loading = false;
  }

  navigate(page: any, index: number) {
    this.pagination.splice(index);

    if (page["type"] == "summary") {
      this.loadConsolidatedHisotry();
    } else {
      this.currentRange = page["range"];
      this.changeRange(page["value"]);
    }
  }

  async listMonitors() {
    this.monitors = [];
    this.appended = [];
    this.datesAppended = [];
    let account: any = localStorage.getItem("emsConfig");
    let accountOld: any = JSON.parse(localStorage.getItem("accountIdData"))[
      "list"
    ].find((account: any) => {
      return account["accountId"] == localStorage.getItem("accountId");
    });
    if (!(account || accountOld["site_247_project_id"])) {
      this.loading = false;
      return;
    }

    if (account) {
      account = JSON.parse(account);
    }

    this.loading = true;
    let data: any = {
      action: "history",
      id_token: localStorage.getItem("idT"),
      zaaid: account ? account["zaid"] : accountOld["site_247_project_id"],
      login_user_name: localStorage.getItem("un"),
      login_user_email: localStorage.getItem("eId"),
      origin: account ? "swayam" : "minfy",
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == "1" || result.s == "1") {
      // result.monitors = result.monitors.map((monitor: any) => {
      //   monitor["state"] = monitor["state"] == 5 ? 0 : 1;
      //   return monitor;
      // });

      result.records = result.records.sort((a: any, b: any) => {
        return moment(a["scan_time"]).valueOf() <
          moment(b["scan_time"]).valueOf()
          ? 1
          : -1;
      });

      this.monitors = result.records;
    }

    this.loading = false;
  }

  async changeRange(value: string) {
    this.currentRangeValue = value;
    this.activityRows = [];
    let pagination: any = { value: value, range: this.currentRange };

    if (this.currentRange == "month") {
      await this.listMonitors();
      this.currentRange = "week";
    } else if (this.currentRange == "week") {
      this.currentRange = "date";
    } else if (this.currentRange == "date") {
      this.currentRange = "hours";
    }
    pagination["type"] = "changeRange";
    pagination["click"] = () => this.changeRange;

    this.pagination.push(pagination);

    if (this.currentRange == "week") {
      let days = this.fetchDiffDays(
        moment(this.currentRangeValue, "MM-YYYY")
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment(this.currentRangeValue, "MM-YYYY")
          .endOf("month")
          .format("YYYY-MM-DD")
      );
      let totalWeeks: any = Math.floor(days / 7);
      this.headers = [
        {
          id: "monitor_id",
          name: "Monitor ID",
        },
        {
          id: "instance_id",
          name: "Tag Name",
        },
      ];

      for (let i = 0; i < totalWeeks; i++) {
        let value =
          moment(this.currentRangeValue, "MM-YYYY")
            .startOf("month")
            .add(i, "weeks")
            .startOf("day")
            .format("MMM DD") +
          " - " +
          moment(this.currentRangeValue, "MM-YYYY")
            .startOf("month")
            .add(i, "weeks")
            .add(6, "days")
            .startOf("day")
            .format("MMM DD");
        this.headers.push({
          id: value,
          name: value,
          startValue: moment(this.currentRangeValue, "MM-YYYY")
            .startOf("month")
            .add(i, "weeks")
            .startOf("day")
            .valueOf(),
          endValue: moment(this.currentRangeValue, "MM-YYYY")
            .startOf("month")
            .add(i, "weeks")
            .add(6, "days")
            .startOf("day")
            .valueOf(),
          sort: true,
          click: true,
        });
      }
      if ((days % 7) - 1 > 0) {
        let value =
          moment(this.currentRangeValue, "MM-YYYY")
            .endOf("month")
            .subtract((days % 7) - 1, "days")
            .startOf("day")
            .format("MMM DD") +
          " - " +
          moment(this.currentRangeValue, "MM-YYYY")
            .endOf("month")
            .startOf("day")
            .format("MMM DD");
        this.headers.push({
          id: value,
          name: value,
          startValue: moment(this.currentRangeValue, "MM-YYYY")
            .endOf("month")
            .subtract((days % 7) - 1, "days")
            .startOf("day")
            .valueOf(),
          endValue: moment(this.currentRangeValue, "MM-YYYY")
            .endOf("month")
            .startOf("day")
            .valueOf(),
          sort: true,
          click: true,
        });
      }

      this.headers.forEach((h: any) => {
        if (h.click && h.startValue) {
          this.monitors.forEach((monitor: any) => {
            let value: any = moment(monitor["scan_time"])
              .startOf("day")
              .valueOf();

            if (
              value >= h.startValue &&
              value <= h.endValue &&
              monitor["state"] == "0"
            ) {
              let monitor_: any = this.activityRows.find((monitor_: any) => {
                return monitor["monitor_id"] == monitor_["monitor_id"];
              });
              if (monitor_) {
                if (monitor_[h.id]) {
                  monitor_[h.id] = Number(monitor_[h.id]) + 0.5;
                } else {
                  monitor_[h.id] = 0.5;
                }
              } else {
                let dict: any = {
                  monitor_id: monitor["monitor_id"],
                  instance_id: monitor["name"],
                };
                dict[h.id] = 0.5;

                this.activityRows.push(dict);
              }
            }
          });
        }
      });
    }

    if (this.currentRange == "date") {
      let days = this.fetchDiffDays(
        moment(this.currentRangeValue.split(" - ")[0], "MMM DD").format(
          "YYYY-MM-DD"
        ),
        moment(this.currentRangeValue.split(" - ")[1], "MMM DD").format(
          "YYYY-MM-DD"
        )
      );

      this.headers = [
        {
          id: "monitor_id",
          name: "Monitor ID",
        },
        {
          id: "instance_id",
          name: "Tag Name",
        },
      ];

      for (let i = 0; i < days; i++) {
        let value = moment(this.currentRangeValue.split(" - ")[0], "MMM DD")
          .add(i, "days")
          .startOf("day")
          .format("MMM DD");
        this.headers.push({
          id: value,
          name: value,
          startValue: moment(this.currentRangeValue.split(" - ")[0], "MMM DD")
            .add(i, "days")
            .startOf("day")
            .valueOf(),
          sort: true,
          click: true,
        });
      }

      this.headers.forEach((h: any) => {
        if (h.click && h.startValue) {
          this.monitors.forEach((monitor: any) => {
            let value: any = moment(monitor["scan_time"])
              .startOf("day")
              .valueOf();
            if (value == h.startValue && monitor["state"] == "0") {
              let monitor_: any = this.activityRows.find((monitor_: any) => {
                return monitor["monitor_id"] == monitor_["monitor_id"];
              });
              if (monitor_) {
                if (monitor_[h.id]) {
                  monitor_[h.id] = Number(monitor_[h.id]) + 0.5;
                } else {
                  monitor_[h.id] = 0.5;
                }
              } else {
                let dict: any = {
                  monitor_id: monitor["monitor_id"],
                  instance_id: monitor["name"],
                };
                dict[h.id] = 0.5;

                this.activityRows.push(dict);
              }
            }
          });
        }
      });
    }

    if (this.currentRange == "hours") {
      this.headers = [
        {
          id: "monitor_id",
          name: "Monitor ID",
        },
        {
          id: "instance_id",
          name: "Tag Name",
        },
      ];

      for (let i = 0; i < 24; i++) {
        this.headers.push({
          id: moment(this.currentRangeValue, "MMM DD")
            .startOf("day")
            .add(i, "hours")
            .startOf("hour")
            .format("hh: mm A"),
          name: moment(this.currentRangeValue, "MMM DD")
            .startOf("day")
            .add(i, "hours")
            .startOf("hour")
            .format("hh: mm A"),
          startValue: moment(this.currentRangeValue, "MMM DD")
            .startOf("day")
            .add(i, "hours")
            .startOf("hour")
            .valueOf(),
          sort: true,
        });
      }

      this.headers.forEach((h: any) => {
        if (h.startValue) {
          this.monitors.forEach((monitor: any) => {
            let value: any = moment(monitor["scan_time"])
              .startOf("hour")
              .valueOf();
            if (value == h.startValue && monitor["state"] == "0") {
              let monitor_: any = this.activityRows.find((monitor_: any) => {
                return monitor["monitor_id"] == monitor_["monitor_id"];
              });
              if (monitor_) {
                if (monitor_[h.id]) {
                  monitor_[h.id] = 1;
                } else {
                  monitor_[h.id] = 0.5;
                }
              } else {
                let dict: any = {
                  monitor_id: monitor["monitor_id"],
                  instance_id: monitor["name"],
                };
                dict[h.id] = 0.5;

                this.activityRows.push(dict);
              }
            }
          });
        }
      });
    }
  }

  fetchDiffDays(start: any, end: any): number {
    let currentDate: any = end;
    let liveDate: any = start;
    currentDate = new Date(currentDate);
    liveDate = new Date(liveDate);
    let diff =
      (currentDate.getTime() - liveDate.getTime()) / (1000 * 3600 * 24) + 1;
    return diff;
  }

  getFormatString(obj: any, format: string) {
    return moment(obj).format(format);
  }

  getMonitorData(monitor: any, lastState: string) {
    if (lastState == null && monitor["state"] == "5") {
      return "Off";
    } else if (lastState == null && monitor["state"] == "0") {
      return "On";
    } else if (lastState == monitor["state"] && monitor["state"] == "0") {
      return "On";
    } else if (lastState != monitor["state"] && monitor["state"] == "0") {
      return "New On";
    } else if (lastState == monitor["state"] && monitor["state"] == "5") {
      return "Off";
    } else if (lastState != monitor["state"] && monitor["state"] == "5") {
      return "New Off";
    } else {
      return "No";
    }
  }
}
