<div class="card">
  <div class="card-header">
    <div class="flex-between">
      <div class="flex-start">
        <button class="btn btn-light" (click)="hide(false)">< Back</button>
        <h5>Foundational Technical Review - {{ job.name }}</h5>
      </div>
      <div class="flex-start">
        <button class="btn btn-light filter_click">
          <i class="fa fa-filter" aria-hidden="true"></i>
          <div class="font-9">
            <div>
              {{ filters["questions"] }}
            </div>
          </div>
          <div class="filter_dropdown">
            <div class="filter-item" (click)="changeFilter('All', true)">
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'All' }"
              ></div>
              <div>All</div>
            </div>
            <div
              class="filter-item"
              (click)="changeFilter('Non Compliant', true)"
            >
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'Non Compliant' }"
              ></div>
              <div>Non Compliant</div>
            </div>
            <div class="filter-item" (click)="changeFilter('Compliant', true)">
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'Compliant' }"
              ></div>
              <div>Compliant</div>
            </div>
            <div class="filter-item" (click)="changeFilter('Manual', true)">
              <div
                class="filter-checkbox"
                [ngClass]="{ checked: filters['questions'] == 'Manual' }"
              ></div>
              <div>Manual</div>
            </div>
          </div>
        </button>
        <div class="quiz_actions">
          <ng-container *ngIf="!loading">
            <button class="btn btn-light" (click)="saveQuestions()">
              Save
            </button>
          </ng-container>
          <ng-container *ngIf="loading">
            <img
              src="../assets/img/loading_2.svg"
              alt="loading scans"
              class="wid-14"
            />
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="quiz-app">
      <ng-container *ngIf="showSidebar">
        <div class="pillers_container position-relative">
          <div class="padding-center">Partner-hosted FTR requirements</div>
          <ng-container>
            <div
              class="piller flex-between"
              (click)="changeRequirement(check)"
              [ngClass]="{
                selected: selectedRequirement == check['check_root']
              }"
              *ngFor="let check of checksAcc"
            >
              <span>{{ check["check_root"] }}</span>
              <span class="color-radius-20"
                >{{ check["answered"]() }}/{{ check["count"] }}</span
              >
            </div>
          </ng-container>

          <div class="hideSidebar" (click)="showSidebar = false">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showSidebar">
        <div class="hidden_sidebar" (click)="showSidebar = true">
          <i class="fa fa-bars mar-top-10" aria-hidden="true"></i>
        </div>
      </ng-container>
      <div class="questions_container">
        <ng-container *ngIf="checks.length > 0 && filterQuestions().length > 0">
          <div class="question" *ngIf="this.selectedRequirement">
            <div class="question_title flex-start flex-top">
              <span>
                {{ this.selectedRequirement }}
              </span>
            </div>
            <div class="options_container">
              <div
                class="option hover-option"
                [ngClass]="{ selected_option_contaniner: option['checkbox'] }"
                (click)="selectOption(option)"
                *ngFor="let option of filterQuestions(); let j = index"
              >
                <div class="pointer-none">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    [attr.checked]="option['checkbox'] ? true : null"
                  />
                </div>
                <div class="main-container">
                  <div class="option_text">
                    {{ option["check_id"] }}
                    {{ option.title }}
                  </div>
                  <div
                    class="option_description"
                    *ngIf="option.info && option.info != ''"
                  >
                    {{ option.info }}
                  </div>
                  <div
                    class="reject_margin reject_margin_top"
                    *ngIf="option['check']"
                  >
                    <div class="flex-start">
                      <div class="bold">Service:</div>
                      <div class="capitalize">{{ option["check"] }}</div>
                    </div>
                  </div>
                  <div
                    class="reject_margin reject_margin_top"
                    *ngIf="option.reason"
                  >
                    <div class="bold">Evidence</div>
                    <div class="flex-start">
                      <span
                        [ngClass]="{
                          green_fancy: option.status == 'true',
                          red_fancy: option.status == 'false'
                        }"
                      >
                        <i
                          class="fa fa-check-circle"
                          *ngIf="option.status == 'true'"
                        ></i>
                        <i
                          class="fa fa-times-circle"
                          *ngIf="option.status == 'false'"
                        ></i>
                      </span>
                      <span [innerHTML]="option.reason">
                      </span>
                    </div>
                    <div
                      class="flex-start"
                      *ngIf="
                        option['evidence'] == null || option['edit_evidence']
                      "
                    >
                      <button
                        class="btn btn-primary"
                        (click)="setEvidence($event, option, true)"
                      >
                        Accept
                      </button>
                      <button
                        class="btn btn-primary"
                        (click)="setEvidence($event, option, false)"
                      >
                        Exception
                      </button>
                    </div>
                    <div
                      class="flex-start"
                      *ngIf="
                        option['evidence'] != null && !option['edit_evidence']
                      "
                    >
                      <div class="pad-0">
                        Evidence is
                        {{ option["evidence"] ? "valid" : "invalid" }}
                        <span
                          class="nav-link"
                          (click)="resetEvidence($event, option)"
                          ><i class="fa fa-pencil"></i> Change</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="reject_margin reject_margin_top"
                    *ngIf="
                      option['check_id'] == 'RES-005' ||
                      option['check_id'] == 'RES-004'
                    "
                  >
                    <div class="flex-start">
                      <div class="bold">{{ whitelabelData['pd']['appName'] }} Link:</div>
                      <span class="nav-link" (click)="showBackup = true"
                        >Backup Report</span
                      >
                    </div>
                  </div>
                  <div
                    class="reject_margin reject_margin_top"
                    *ngIf="
                      option.evidence == false || option['check'] == 'manual'
                    "
                  >
                    <div class="bold">Decision</div>
                    <div
                      class="flex-start"
                      *ngIf="
                        option['decision'] == null || option['edit_decision']
                      "
                    >
                      <button
                        class="btn btn-primary"
                        *ngIf="option['check'] == 'manual'"
                        (click)="setDecision($event, option, 'ACCEPT')"
                      >
                        Accept
                      </button>
                      <button
                        class="btn btn-primary"
                        *ngIf="option['check'] != 'manual'"
                        (click)="setDecision($event, option, 'REMEDIATE')"
                      >
                        Remediate
                      </button>
                      <button
                        class="btn btn-primary"
                        (click)="setDecision($event, option, 'NOT_APPLICABLE')"
                      >
                        Not Applicable
                      </button>
                    </div>
                    <div
                      class="flex-start"
                      *ngIf="
                        option['decision'] != null && !option['edit_decision']
                      "
                    >
                      <div class="pad-0">
                        {{ DECISIONS[option["decision"]] }}
                        <span
                          class="nav-link"
                          (click)="resetDecision($event, option)"
                          ><i class="fa fa-pencil"></i> Change</span
                        >
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="option['view_details']">
                    <div
                      class="reject_margin reject_margin_top"
                      *ngIf="
                        option['offenders'] && option['offenders'].length > 0
                      "
                    >
                      <div class="bold">
                        {{ option["off_key"] || "Offenders" }}
                      </div>
                      <div class="offenders_list">
                        <ol>
                          <li *ngFor="let offender of sliceOfferders(option)">
                            {{ offender }}
                          </li>
                        </ol>
                        <div *ngIf="option['show_offenders']">
                          <span
                            class="nav-link"
                            (click)="showOffenders($event, option)"
                          >
                            Show Less
                          </span>
                        </div>
                        <div
                          *ngIf="
                            !option['show_offenders'] &&
                            option['offenders'].length > 5
                          "
                        >
                          <span>
                            {{ option["offenders"].length - 5 }}+ more
                            <span
                              class="nav-link"
                              (click)="showOffenders($event, option)"
                              >view all</span
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="reject_margin reject_margin_top meta_data_details"
                      *ngIf="
                        option['metadata'] && option['metadata'].length > 0
                      "
                    >
                      <div class="bold" *ngIf="!option['offenders'] || option['offenders'].length == 0">
                        {{ option["off_key"] || "Metadata" }}
                      </div>
                      <div *ngIf="option['check_id'] == 'WAFR-001'" class="table-container">
                        <table border="1">
                          <thead>
                            <tr>
                              <th rowspan="2">Sl. No.</th>
                              <th rowspan="2">Workload Name</th>
                              <th colspan="4" class="center">Status</th>
                              <th rowspan="2">Link</th>
                            </tr>
                            <tr>
                              <th class="center">High</th>
                              <th class="center">Medium</th>
                              <th class="center">Unanswered</th>
                              <th class="center">None</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let workload of option['metadata'];
                                let i = index
                              "
                            >
                              <td>{{ i + 1 }}</td>
                              <td>{{ workload["workload_name"] }}</td>
                              <td class="center bold red_fancy">
                                {{ workload["risk"]["HIGH"] || "-" }}
                              </td>
                              <td class="center bold yellow_fancy">
                                {{ workload["risk"]["MEDIUM"] || "-" }}
                              </td>
                              <td class="center bold">
                                {{ workload["risk"]["UNANSWERED"] || "-" }}
                              </td>
                              <td class="center bold green_fancy">
                                {{ workload["risk"]["NONE"] || "-" }}
                              </td>
                              <td>
                                <a
                                  routerLink="/dash/wafr"
                                  (click)="preventDefault($event)"
                                  target="_blank"
                                  [queryParams]="{
                                    workload_name: workload['workload_name']
                                  }"
                                  >Open <i class="fa fa-external-link"></i
                                ></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div *ngIf="option['check_id'] == 'WAFR-002'" class="table-container">
                        <table border="1">
                          <thead>
                            <tr>
                              <th rowspan="2">SSB Scan Name</th>
                              <th colspan="4" class="center">Result</th>
                              <th rowspan="2">Link</th>
                            </tr>
                            <tr>
                              <th class="center">Total Checks</th>
                              <th class="center">Passed</th>
                              <th class="center">Failed</th>
                              <th class="center">Score (%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ option["metadata"][0]["name"] }}</td>
                              <td class="center">
                                {{ option["metadata"][0]["total_checks"] }}
                              </td>
                              <td class="center bold green_fancy">
                                {{ option["metadata"][0]["passed"] || "-" }}
                              </td>
                              <td class="center bold red_fancy">
                                {{ option["metadata"][0]["failed"] || "-" }}
                              </td>
                              <td class="center bold">
                                {{ option["metadata"][0]["perc"] || "-" }}
                              </td>
                              <td>
                                <a
                                  routerLink="/dash/compliance/ssb"
                                  (click)="preventDefault($event)"
                                  target="_blank"
                                  [queryParams]="{
                                    job_id: option['metadata'][0]['job_id']
                                  }"
                                  >Open <i class="fa fa-external-link"></i
                                ></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        *ngIf="
                          option['check_id'] == 'IAM-007' ||
                          option['check_id'] == 'IAM-008'
                        "
                        class="table-container"
                      >
                        <table border="1">
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>IAM Name</th>
                              <th>Access Key</th>
                              <th>Last Used on</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let workload of option['metadata'];
                                let i = index
                              "
                            >
                              <td>{{ i + 1 }}</td>
                              <td>{{ workload["IAMName"] }}</td>
                              <td>{{ workload["AccessKey"] }}</td>
                              <td>{{ workload["Lastusedon"] }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="reject_margin reject_margin_top meta_data_details"
                      *ngIf="
                        option['custom_metadata'] &&
                        option['custom_metadata'].length > 0
                      "
                    >
                      <div *ngFor="let table of option['custom_metadata']" class="mb-2">
                        <div class="bold">
                          {{ table["tableName"] || "Metadata" }}
                        </div>
                        <div class="table-container">
                          <table border="1">
                            <thead>
                              <tr>
                                <th>Sl. No.</th>
                                <th *ngFor="let h of table['headers']">
                                  {{ h.name }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let i of table['data']; let j = index">
                                <td>{{ j + 1 }}</td>
                                <td *ngFor="let h of table['headers']">
                                  {{ i[h.id] }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="reject_margin reject_margin_top flex-start">
                    <ng-container
                      *ngIf="
                        (option['offenders'] &&
                          option['offenders'].length > 0) ||
                        (option['metadata'] && option['metadata'].length > 0) ||
                        (option['custom_metadata'] &&
                          option['custom_metadata'].length > 0)
                      "
                    >
                      <span
                        class="nav-link"
                        *ngIf="option['view_details']"
                        (click)="updateHideDetails($event, option)"
                      >
                        Hide Details
                      </span>
                      <span
                        class="nav-link"
                        *ngIf="!option['view_details']"
                        (click)="updateHideDetails($event, option)"
                      >
                        View Details
                      </span>
                    </ng-container>
                    <div
                      *ngIf="
                        (option['offenders'] &&
                          option['offenders'].length > 0) ||
                        (option['metadata'] &&
                          option['metadata'].length > 0 &&
                          option['reference'])||
                          (option['custom_metadata'] &&
                            option['custom_metadata'].length > 0 &&
                            option['reference'])
                      "
                    >
                      |
                    </div>
                    <a
                      href="{{ option['reference'] }}"
                      target="_blank"
                      *ngIf="option['reference']"
                    >
                      Reference
                      <i class="fa fa-external-link" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="reject_margin reject_margin_top">
                    <div class="bold">
                      Comment
                      <span
                        class="error-message"
                        *ngIf="option['errors'] && option['errors']['comment']"
                        ><i
                          class="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        Comment is mandatory when evidence is selected as
                        exception
                      </span>
                    </div>
                    <div>
                      <textarea
                        class="form-control"
                        [(ngModel)]="option['comment']"
                        cols="30"
                        (click)="preventDefault($event)"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="center" *ngIf="!this.selectedRequirement">
            No checks found in
            <span class="bold">{{ this.selectedRequirement }}</span> with filter
            <span class="bold">{{ filters["questions"] }}</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="checks.length == 0 && filterQuestions().length == 0"
        >
          <div class="center">
            No checks found with filter
            <span class="bold">{{ filters["questions"] }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="floating-fixed" *ngIf="showBackup">
  <div class="floating-container">
    <div class="floating-header flex-between">
      <div>Backup Report</div>
      <div (click)="showBackup = false"><i class="fa fa-times"></i></div>
    </div>
    <div class="floating-body">
      <snapshots-backupreport></snapshots-backupreport>
    </div>
  </div>
</div>
