<div class="card mt-20 brd-pstn">
  <h5 class="card-header">Minfy RI Account Details</h5>
  <div class="card-body">
    <div class="mrg-10">
      <label class="mrg-rght-10">Master Account List</label>
      <select name="" id="masterAccountList" class="selectpricket" (change)="fetchChildAccounts()"
        data-live-search="true">
        <option [value]="master" *ngFor="let master of masterAccounts">{{master}}</option>
      </select>
    </div>
    <div class="mrg-10">
      <label class="mrg-rght-10">Linked Account List</label>
      <select name="" id="linkedAccountList" multiple class="selectpricket" data-live-search="true">
        <option [value]="master.accountId" *ngFor="let master of linkedAccounts">{{master.accountId}}
          ({{master.accountName}})</option>
      </select>
    </div>
    <div class="mrg-10">
      <button class="btn btn-light btn-clr"
        (click)="submitAccounts()">Submit</button>
    </div>
  </div>
</div>