<div class="dashboard-container" (resize)="resizeScreen($event)">
  <div class="dashboard-grid">
    <div class="card-hoverable column1">
      <div
        class="flex-column"
        [ngClass]="{
          loading: loading['yearly_cost'],
          error: errors['yearly_cost']
        }"
        (click)="
          setSelectedPrice(data['yearly_cost'], 'yearly_cost', 'yearly_cost')
        "
      >
        <div class="flex-1">
          <div>Annual Cost</div>
          <div class="high-big-text mt-2">
            {{
              data["yearly_cost"] ? data["yearly_cost"]["yearly_cost"] : "0 USD"
            }}
          </div>
        </div>
        <div class="bgc2 description mt-2">
          <div class="signValue2 flex-start">
            <div>Since the last 12 months</div>
          </div>
        </div>
        <div class="error-message flex-start" *ngIf="errors['yearly_cost']">
          <i class="fa fa-exclamation-triangle"></i>
          {{ errors["yearly_cost"] }}
        </div>
        <div class="error-message info-message flex-start" *ngIf="newMonth">
          <i class="fa fa-exclamation-triangle"></i>
          The CSP has not billed yet.
        </div>
        <div class="icon-container">
          <div class="i">
            <i class="fa-solid fa-sack-dollar"></i>
          </div>
        </div>
        <div class="loading-image-container">
          <img src="../assets/img/loading_2.svg" alt="" />
        </div>
      </div>
      <div
        class="flex-column"
        [ngClass]="{
          loading: loading['monthly_cost'],
          error: errors['monthly_cost']
        }"
        (click)="
          setSelectedPrice(data['monthly_cost'], 'monthly_cost', 'monthly_cost')
        "
      >
        <div class="flex-1">
          <div>Month to Date (MTD) Spend</div>
          <div class="high-big-text mt-2">
            {{
              data["monthly_cost"]
                ? data["monthly_cost"]["current_month_cost"]
                : "0 USD"
            }}
          </div>
        </div>
        <div class="flex-start mt-2">
          <div
            class="lower flex-start"
            *ngIf="this.data['monthly_cost']"
            [ngClass]="{
              higher: this.data['monthly_cost']['difference_in_perc'] < 0,
              lower: this.data['monthly_cost']['difference_in_perc'] > 0
            }"
          >
            <i
              class="fa fa-caret-up"
              [ngClass]="{
                'fa-caret-down':
                  this.data['monthly_cost']['difference_in_perc'] < 0,
                'fa-caret-up':
                  this.data['monthly_cost']['difference_in_perc'] > 0
              }"
            ></i>
            <div>
              {{ this.data["monthly_cost"]["difference_in_perc"] || 0 }}%
            </div>
          </div>
          <div class="description">vs. MTD of last month</div>
        </div>
        <div class="error-message flex-start" *ngIf="errors['monthly_cost']">
          <i class="fa fa-exclamation-triangle"></i>
          {{ errors["monthly_cost"] }}
        </div>
        <div class="error-message info-message flex-start" *ngIf="newMonth">
          <i class="fa fa-exclamation-triangle"></i>
          The CSP has not billed yet.
        </div>
        <div class="icon-container">
          <div class="i">
            <i class="fa-solid fa-sack-dollar"></i>
          </div>
        </div>
        <div class="loading-image-container">
          <img src="../assets/img/loading_2.svg" alt="" />
        </div>
      </div>
      <div class="flex-column">
        <div>Cost Savings</div>
        <div class="high-big-text">-</div>
      </div>
      <div
        class="flex-column"
        [ngClass]="{
          loading: loading['daily_cost'],
          error: errors['daily_cost']
        }"
        (click)="
          setSelectedPrice(data['daily_cost'], 'daily_cost', 'daily_cost')
        "
      >
        <div class="flex-1">
          <div>Today's Spend</div>
          <div class="high-big-text mt-2">
            {{
              data["daily_cost"]
                ? data["daily_cost"]["current_month_daily_cost"]
                : "0 USD"
            }}
          </div>
        </div>
        <div class="flex-start mt-2">
          <div
            class="lower flex-start"
            *ngIf="this.data['daily_cost']"
            [ngClass]="{
              higher: this.data['daily_cost']['difference_in_perc'] < 0,
              lower: this.data['daily_cost']['difference_in_perc'] > 0
            }"
          >
            <i
              class="fa fa-caret-up"
              [ngClass]="{
                'fa-caret-down':
                  this.data['daily_cost']['difference_in_perc'] < 0,
                'fa-caret-up': this.data['daily_cost']['difference_in_perc'] > 0
              }"
            ></i>
            <div>{{ this.data["daily_cost"]["difference_in_perc"] || 0 }}%</div>
          </div>
          <div class="description">vs. same day last month</div>
        </div>
        <div class="error-message flex-start" *ngIf="errors['daily_cost']">
          <i class="fa fa-exclamation-triangle"></i>
          {{ errors["daily_cost"] }}
        </div>
        <div class="error-message info-message flex-start" *ngIf="newMonth">
          <i class="fa fa-exclamation-triangle"></i>
          The CSP has not billed yet.
        </div>
        <div class="icon-container">
          <div class="i">
            <i class="fa-solid fa-sack-dollar"></i>
          </div>
        </div>
        <div class="loading-image-container">
          <img src="../assets/img/loading_2.svg" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card-hoverable column2"
      [ngClass]="{
        loading: loading['forecasted_data'],
        error: errors['forecasted_data']
      }"
      (click)="
        setSelectedPrice(
          data['forecasted_data'],
          'forecasted_data',
          'forecasted_data'
        )
      "
    >
      <div class="high-bold-text">Forecast for upcoming months</div>
      <div class="forecastGraphAzure" *ngIf="!data?.forecasted_data">
        <div class="flex-align-center error-container">
          <div class="alert alert-secondary" role="alert">No Data Found</div>
        </div>
      </div>
      <div
        class="forecastGraphAzure"
        [ngStyle]="{ display: !data?.forecasted_data ? 'none' : 'block' }"
        id="forecastGraphAzure"
      ></div>
      <div class="error-message flex-start" *ngIf="errors['forecasted_data']">
        <i class="fa fa-exclamation-triangle"></i>
        {{ errors["forecasted_data"] }}
      </div>
      <div class="loading-image-container">
        <img src="../assets/img/loading_2.svg" alt="" />
      </div>
    </div>
    <div
      class="card-hoverable resources"
      [ngClass]="{
        loading: loading['services_cost_till_date'],
        error: errors['services_cost_till_date']
      }"
    >
      <div class="high-bold-text">Top 5 Cost of Resources by Month to Date</div>
      <div
        class="resourcesGraphAzure"
        *ngIf="data?.services_cost_till_date?.service.length == 0"
      >
        <div class="flex-align-center error-container">
          <div class="alert alert-secondary" role="alert">No Data Found</div>
        </div>
      </div>
      <div
        class="resourcesGraphAzure"
        [ngStyle]="{
          display:
            data?.services_cost_till_date?.service.length == 0
              ? 'none'
              : 'block'
        }"
        id="resourcesGraphAzure"
      ></div>
      <div
        class="error-message flex-start"
        *ngIf="errors['services_cost_till_date']"
      >
        <i class="fa fa-exclamation-triangle"></i>
        {{ errors["services_cost_till_date"] }}
      </div>
      <div class="loading-image-container">
        <img src="../assets/img/loading_2.svg" alt="" />
      </div>
    </div>
    <div class="card-hoverable column3">
      <div class="center high-bold-text">Infrastructure Details</div>
      <div class="grid">
        <div
          [ngClass]="{
            loading: loading['total_ram'],
            error: errors['total_ram']
          }"
          (click)="
            setSelectedPrice(data['total_ram'], 'total_ram', 'processor_data')
          "
        >
          <div>vCPU</div>
          <div class="high-big-text">
            {{ data["total_ram"] ? data["total_ram"]["total_cores"] : "-" }}
          </div>
          <div class="error-message flex-start" *ngIf="errors['total_ram']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["total_ram"] }}
          </div>
          <div class="loading-image-container">
            <img src="../assets/img/loading_2.svg" alt="" />
          </div>
        </div>
        <div
          [ngClass]="{
            loading: loading['total_ram'],
            error: errors['total_ram']
          }"
        >
          <div>RAM</div>
          <div class="high-big-text">
            {{ data["total_ram"] ? data["total_ram"]["total_ram"] : "-" }}
          </div>
          <div class="error-message flex-start" *ngIf="errors['total_ram']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["total_ram"] }}
          </div>
          <div class="loading-image-container">
            <img src="../assets/img/loading_2.svg" alt="" />
          </div>
        </div>
        <div
          [ngClass]="{
            loading: loading['total_storage'],
            error: errors['total_storage']
          }"
          (click)="
            setSelectedPrice(
              data['total_storage'],
              'total_storage',
              'storage_data'
            )
          "
        >
          <div>Storage</div>
          <div class="high-big-text">
            {{
              data["total_storage"]
                ? data["total_storage"]["total_storage"] || 0 + " GB"
                : "0 GB"
            }}
          </div>
          <div class="error-message flex-start" *ngIf="errors['total_storage']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["total_storage"] }}
          </div>
          <div class="loading-image-container">
            <img src="../assets/img/loading_2.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="grid-2">
        <div
          [ngClass]="{
            loading: loading['total_ram'],
            error: errors['total_ram']
          }"
          (click)="
            setSelectedPrice(
              data['total_ram']['vm_types_count'],
              'total_ram',
              'instance_type'
            )
          "
        >
          <div class="high-bold-text">Instance Type</div>
          <div class="error-message flex-start" *ngIf="errors['total_ram']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["total_ram"] }}
          </div>
          <div
            class="instanceTypeGraphAzure"
            *ngIf="
              !data?.total_ram?.total_cores
            "
          >
            <div class="flex-align-center">
              <div class="alert alert-secondary" role="alert">
                No Data Found
              </div>
            </div>
          </div>
          <div
            class="instanceTypeGraphAzure"
            [ngStyle]="{
              display:
                !this.data['total_ram'] ||
                !this.data['total_ram']['total_cores']
                  ? 'none'
                  : 'block'
            }"
            id="instanceTypeGraphAzure"
          ></div>
          <div class="loading-image-container">
            <img src="../assets/img/loading_2.svg" alt="" />
          </div>
        </div>
        <div
          [ngClass]="{ loading: loading['os_type'], error: errors['os_type'] }"
        >
          <div class="high-bold-text">OS Type</div>
          <div class="error-message flex-start" *ngIf="errors['os_type']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["os_type"] }}
          </div>
          <div
            class="osTypeGraphAzure"
            *ngIf="!data['os_type'] || data['os_type']['total_os'] == 0"
          >
            <div class="flex-align-center">
              <div class="alert alert-secondary" role="alert">
                No Data Found
              </div>
            </div>
          </div>
          <div
            class="osTypeGraphAzure"
            [ngStyle]="{
              display:
                !data['os_type'] || data['os_type']['total_os'] == 0
                  ? 'none'
                  : 'block'
            }"
            id="osTypeGraphAzure"
          ></div>
          <div class="loading-image-container">
            <img src="../assets/img/loading_2.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="column4">
      <div class="h-100">
        <div
          class="flex-column d-flex"
          [ngClass]="{
            loading: loading['jira_tickets'],
            error: errors['jira_tickets']
          }"
        >
          <div class="high-bold-text">Total Number of Issues</div>
          <div class="bgc2 description mt-2 d-inline">
            <div class="signValue2 flex-start">
              <div>Since last month</div>
            </div>
          </div>
          <div class="error-message flex-start" *ngIf="errors['jira_tickets']">
            <i class="fa fa-exclamation-triangle"></i>
            {{ errors["jira_tickets"] }}
          </div>
          <div class="flex-1">
            <div class="totalIssuesSupport" id="totalIssuesSupport">
              <div class="flex-align-center" *ngIf="!data['jira_tickets']">
                <div class="alert alert-secondary" role="alert">
                  No Data Found
                </div>
              </div>
            </div>
          </div>
          <div class="loading-image-container">
            <img src="../assets/img/loading_2.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<azure-dashboard-price-difference
  *ngIf="selectedPrice"
  [data]="selectedPrice"
  [title]="title"
  (hideModal)="selectedPrice = null"
></azure-dashboard-price-difference>

<app-view-os-tab
  *ngIf="this.popupType['processor_data']"
  [insList]="this.popupType['processor_data']"
  (hideModal)="this.popupType['processor_data'] = undefined"
></app-view-os-tab>
<app-view-s3-tab
  *ngIf="this.popupType['storage_data']"
  [StorageTypes]="this.popupType['storage_data']"
  (hideModal)="this.popupType['storage_data'] = undefined"
></app-view-s3-tab>
<app-view-ins-tab
  *ngIf="this.popupType['instance_type']"
  [instancesTypes]="this.popupType['instance_type']"
  (hideModal)="this.popupType['instance_type'] = undefined"
></app-view-ins-tab>
