<div class="card">
  <div class="card-body">
    <div class="flex-end">
      <button *ngIf="!fetch_scans" class="btn btn-light flex-start" (click)="load()">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        <span class="color-inherit">
          Refresh
        </span>
      </button>
      <button *ngIf="scans.length > 0" class="btn btn-primary-soft flex-start" (click)="createRuleTrigger()">
        <i class="fa fa-search" aria-hidden="true"></i>
        <span class="color-inherit">
          Scan
        </span>
      </button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of scansHeaders">{{h.name}}</th>
          <th>Report</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': scans.length > 0 && !fetch_scans ? 'table-row-group': ''}">
        <tr *ngFor="let i of scans; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of scansHeaders">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="h.id != 'status'">{{h.alter ? h.alter(i, h.id) : i[h.id]}}</span>
              <span *ngIf="h.id == 'status'">
                <amis-status [data]="i"></amis-status>
              </span>
            </span>
          </td>
          <td>
            <span class="btn btn-light flex-center" *ngIf="i.status == 'Completed'" (click)="downloadReport(i)">
              <i class="fa fa-download" aria-hidden="true"></i>
              <span class="color-inherit">
                Download
              </span>
            </span>
            <span class="btn btn-light muted" *ngIf="i.status != 'Completed'">
              {{ i.status }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': scans.length == 0 && !fetch_scans ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="scansHeaders.length + 2">
            <div class="text-center">No Scans Found.
              <span class="btn_ padding-margin" (click)="createRuleTrigger()">Click here</span> to
              create
              one
            </div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': fetch_scans ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="scansHeaders.length + 2">
            <div class="text-center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-margin">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="card" *ngIf="createScan">
  <consolidated-createscan-amis
    [description]="'Depending on the number of AMIs in your account, it may take some time to complete. <br> Are you sure you want to continue?'"
    [message]="'[message]'" [data]="{account_id: accountId, region_name: regionId, email:masterId}" (hideModal)="close($event)">
  </consolidated-createscan-amis>
</div>


<style>
  .muted {
    pointer-events: none;
    opacity: 0.4;
  }
</style>