import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";

import * as moment from "moment";

declare let flatpickr: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: "./security.hub.component.html",
  styleUrls: ["./security.hub.component.css"],
})
export class securityHubComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  dateRange: string;
  securityHubList: any = [];
  accountId = localStorage.getItem("accountId");
  regionId = localStorage.getItem("regionId");
  currentMessage: any;
  nextToken: string;

  headers: any = [
    {
      id: "Severity",
      name: "Severity",
      dispFun: (a: any) => {
        return a.Label;
      },
      customDisp: true,
      filter: true,
    },
    { id: "WorkflowState", name: "Workflow State", filter: true },
    { id: "RecordState", name: "Record State", filter: true },
    {
      id: "CompanyName",
      name: "Company",
      filter: true,
    },
    {
      id: "ProductName",
      name: "Product Name",
      filter: true,
    },
    {
      id: "Title",
      name: "Title",
      filter: true,
    },
    {
      id: "Resources",
      name: "Resource",
      dispFun: (a: any) => {
        let res = a.map((d: any) => {
          return d.Type + "<br><br>" + d.Id;
        });
        return res;
      },
      filter: true,
      customDisp: true,
    },
    {
      id: "Workflow",
      name: "Compliance Status",
      dispFun: (a: any) => {
        return a.Status;
      },
      color: (value: string) => {
        if (value == "PASSED") {
          return "green";
        }
        return "black";
      },
      customDisp: true,
      filter: true,
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
    setTimeout(() => {
      let start_date = moment().startOf("month").format("DD-MMM-YYYY");
      let end_date = moment().startOf("day").format("DD-MMM-YYYY");

      this.dateRange = start_date + " to " + end_date;
      flatpickr(".datetimepicker", {
        dateFormat: "d-M-Y",
        mode: "range",
        maxDate: new Date(),
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes("to")) return;
          this.dateRange = dateStr;
          this.load();
        },
      });
    }, 500);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    if (!this.nextToken) {
      this.securityHubList = [];
    }
    let start_date;
    let end_date;
    if (!(this.dateRange && this.dateRange.includes("to"))) {
      start_date = moment().startOf("month").format("YYYY-MM-DD");
      end_date = moment().startOf("day").format("YYYY-MM-DD");
    } else {
      let dRange = this.dateRange.toString().split(" to ");
      start_date = moment(dRange[0], "DD-MMM-YYYY").format("YYYY-MM-DD");
      end_date = moment(dRange[1], "DD-MMM-YYYY").format("YYYY-MM-DD");
    }
    let data = {
      action: "security_hub_findings",
      region_name: this.regionId,
      account_id: this.accountId,
      start_time: start_date,
      end_time: end_date,
      next_token: this.nextToken ? this.nextToken : undefined,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.securityHubList = [...this.securityHubList, ...result.findings];
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    if (result.next_token) {
      this.nextToken = result.next_token;
    } else {
      this.nextToken = null;
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    return;
  }
}
