<div class="card" *ngIf="!selectedUser">
  <div class="card-header flex-between">
    <h5>
      Users
    </h5>
    <div class="flex-start">
      <span class="relative" *ngIf="ut == 'admin' && urlPrefix == 'client'">
        <label class="switch">
          <input autocomplete="off" type="checkbox" [(ngModel)]="groupByAdmins" (change)="load()">
          <span class="slider round"></span>
        </label>
      </span>
      <div class="no-wrap" *ngIf="ut == 'admin' && urlPrefix == 'client'">Group By Admin</div>
      <input autocomplete="off" type="text" class="form-control" placeholder="Search..." [(ngModel)]="filterText">
      <button class="btn btn-light" (click)="load()">Refresh</button>
      <button class="btn btn-success-soft" *ngIf="urlPrefix == 'client' && userList.length > 0"
        (click)="export()">Export</button>
      <button class="btn btn-primary-soft" *ngIf="writeAccess || isUserAdmin" (click)="createUser()">Create</button>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover" id="users-table">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of urlPrefix == 'client' ? userHeaders: adminUsersheader">{{h.name}}</th>
          <th *ngIf="urlPrefix == 'client' && ut == 'admin'">User Type</th>
          <th *ngIf="urlPrefix == 'client' && ut == 'admin'">Created By</th>
          <th *ngIf="ut == 'admin'">Audit</th>
        </tr>
      </thead>

      <tbody *ngIf="filteredUserList().length > 0">
        <tr *ngFor="let i of filteredUserList(); let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of urlPrefix == 'client' ? userHeaders: adminUsersheader">
            <span *ngIf="h.click" class="btn btn-light" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>

          <td *ngIf="urlPrefix == 'client' && ut == 'admin'">{{ userTypes[i['userType']] || '-' }}</td>
          <td *ngIf="urlPrefix == 'client' && ut == 'admin'">{{ i['masterClientId'] || '-' }}</td>
          <td *ngIf="ut == 'admin'"><button class="btn btn-light" (click)="viewAudit(i)">View Audit</button></td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td class="text-center"
            [attr.colspan]="(urlPrefix == 'client' ? userHeaders.length: adminUsersheader.length) + 1 + (urlPrefix == 'client' && ut == 'admin' ? 2 : 0)">
            Looking for Users...
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!loading && filteredUserList().length == 0">
        <tr>
          <td class="text-center"
            [attr.colspan]="(urlPrefix == 'client' ? userHeaders.length: adminUsersheader.length) + 1 + (urlPrefix == 'client' && ut == 'admin' ? 2 : 0)">
            No Users Found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-user-details *ngIf="selectedUser" [user]="selectedUser" (hideModal)="this.load()"></app-user-details>

<update-user *ngIf="writeAccess && updateUser" [action]="userAction" [userDetails]="updateUser"
  (hideModel)="hideUpdateUser($event)"></update-user>