<div class="card">
  <h5 class="card-header">
    {{ selectedCloudPartner == "aws" ? "EBS" : "Managed Disks" }}
  </h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <ng-container *ngFor="let h of headers">
            <th *ngIf="!h.show || h.show()">
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, ebsList)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input
                  autocomplete="off"
                  type="text"
                  [(ngModel)]="h.filterText"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
          </ng-container>
          <th>Tags</th>
        </tr>
      </thead>

      <tbody
        [ngStyle]="{ display: ebsList.length > 0 ? 'table-row-group' : 'none' }"
        *ngFor="let i of ebsList; let j = index"
      >
        <tr *ngIf="notifier.filterTableColums(headers, i)">
          <td>{{ j + 1 }}</td>
          <ng-container *ngFor="let h of headers">
            <td *ngIf="!h.show || h.show()">
              <span
                *ngIf="h.click"
                class="btn"
                (click)="callFunction(h.click, i)"
              >
                <span>
                  {{ i[h.id] || "-" }}
                </span>
              </span>
              <span *ngIf="!h.click">
                {{ i[h.id] || "-" }}
              </span>
            </td>
          </ng-container>
          <td><span class="btn" (click)="showTags(i['tags'] || [])">Manage Tags</span></td>
        </tr>
      </tbody>
      <tbody
        [ngStyle]="{
          display: ebsList.length == 0 && !loading ? 'table-row-group' : 'none'
        }"
      >
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div class="no-ebsfound">
              <div class="no-instances-found">
                <span *ngIf="selectedCloudPartner == 'aws'"
                  >No EBS Volumes Found</span
                >
                <span *ngIf="selectedCloudPartner == 'azure'">
                  No Managed Disks are Found in the
                  <span class="bold-text">{{ resourceGroup }}</span>
                  resource group. You can change the resource group on the top
                  resource group tabs
                </span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody
        [ngStyle]="{
          display: loading ? 'table-row-group' : 'none'
        }"
      >
        <tr>
          <td [attr.colspan]="headers.length + 2" class="center">
            <div>
              Looking for {{ selectedCloudPartner == 'aws' ? 'EBS Volumes' : 'Managed Disks' }} in <span class="bold-text">{{ selectedCloudPartner == 'aws' ? regionId : resourceGroup }}</span>...
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="next-token" *ngIf="nextToken">
      <button class="btn btn-light next-style" (click)="load()">Next</button>
    </div>
  </div>
</div>

<modal-popup [headers]="tags" *ngIf="showTagsModal" [data]="showTagsModal" [modalTitle]="'Tags'"
  [showModal]="true" (hideModal)="showTagsModal = null"></modal-popup>