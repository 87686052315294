<div class="bg-blue-main">
  <div class="card">
    <div class="card-header flex-between">
      <h5>EC2 Recommendations</h5>
      <div class="flex-start">
        <ng-container *ngIf="jobDetails">
          <span class="border-right">
            <div class="text-bold">
              Scanned on
            </div>
            <div>
              {{ jobDetails["scandate"] }}
            </div>
          </span>
          <span>
            <div class="text-bold">
              Status
            </div>
            <div>
              {{ jobDetails["acctstatus"] }}
            </div>
          </span>
        </ng-container>
        <button class=" btn btn-light" *ngIf="!fetching" (click)="refresh()">Refresh</button>
        <button class=" btn btn-primary-soft" *ngIf="!fetching" (click)="createJobTrigger = true">Scan</button>
        <button class=" btn btn-success-soft"
          *ngIf="!fetching && jobDetails && jobDetails['acctstatus'] === 'Completed'"
          (click)="downloadReport()">Export</button>
      </div>
    </div>
  </div>
  <!-- <div class="discovery-section section">
    <div class="card">
      <div class="card-body">
        <div class="discovery-section-container">
          <div class="item-container">
            <div class="item-highlight flex-start">
              <span>
                ${{ overview['current_ec2'].toFixed(2) }}
              </span>
              <img *ngIf="loading_recom" src="../assets/img/loading_2.svg" alt="loading scans"
                class="width-18" />
            </div>
            <div class="item-desc">
              Current Spend on On-Demand Instances
            </div>
          </div>
          <div class="item-container">
            <div class="flex-start">
              <div class="item-highlight">${{
                (overview['current_ec2'] - overview['partial_run']).toFixed(2) }}</div>
              <div class="item-desc" *ngIf="instances.length > 0"
                [ngClass]="{'light-green': overview['partial_run'] > 0, 'light-red': overview['partial_run'] < 0}">
                <span class="inherit">
                  {{(100 - ((overview['current_ec2'] - overview['partial_run']) / overview['current_ec2']) *
                  100).toFixed(2) }}%
                </span>
                <span class="inherit">
                  <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="item-desc">
              Basis 10 hr Schedule
            </div>
          </div>
          <div class="item-container">
            <div class="flex-start">
              <div class="item-highlight">${{
                (overview['current_ec2'] - overview['ri_1yr_no_upfront']).toFixed(2) }}</div>
              <div class="item-desc" *ngIf="instances.length > 0"
                [ngClass]="{'light-green': overview['ri_1yr_no_upfront'] > 0, 'light-red': overview['ri_1yr_no_upfront'] < 0}">
                <span class="inherit">
                  {{(100 - ((overview['current_ec2'] - overview['ri_1yr_no_upfront']) / overview['current_ec2']) *
                  100).toFixed(2) }}%
                </span>
                <span class="inherit">
                  <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="item-desc">
              1 Yr RI
            </div>
          </div>
          <div class="item-container">
            <div class="flex-start">
              <div class="item-highlight">${{
                (overview['current_ec2'] - overview['ri_3yr_no_upfront']).toFixed(2) }}</div>
              <div class="item-desc" *ngIf="instances.length > 0"
                [ngClass]="{'light-green': overview['ri_3yr_no_upfront'] > 0, 'light-red': overview['ri_3yr_no_upfront'] < 0}">
                <span class="inherit">
                  {{(100 - ((overview['current_ec2'] - overview['ri_3yr_no_upfront']) / overview['current_ec2']) *
                  100).toFixed(2) }}%
                </span>
                <span class="inherit">
                  <i class="fa fa-caret-down inherit" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="item-desc">
              3 Yr RI
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="section">
    <div class="upcoming-expiry">
      <div class="grid-2">
        <div class="card flex-1">
          <div class="card-body">
            <div class="item-container flex-1 flex-column">
              <div class="section-title">
                <div>
                  Upcoming Expirations
                </div>
                <div *ngIf="loading">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                </div>
              </div>
              <div class="flex-center">
                <div class="expiry-graph">
                  <div class="expiry-graph-item">
                    <div class="round-text red-back" (click)="addFilter('expired', true, 'Expired')"
                      [ngClass]="{'big-round': overview['expired'] > 10}">{{
                      overview['expired'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">Expired</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text card-blue-back"
                      (click)="addFilter('renew_7d', true, 'Renew in 7 days')"
                      [ngClass]="{'big-round': overview['renew_7d'] > 10}">
                      {{ overview['renew_7d'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">7d</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text blue-back" (click)="addFilter('renew_30d', true, 'Renew in 30 days')"
                      [ngClass]="{'big-round': overview['renew_30d'] > 10}">{{
                      overview['renew_30d'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">30d</div>
                  </div>
                  <div class="expiry-graph-item">
                    <div class="round-text blue-back" (click)="addFilter('renew_90d', true, 'Renew in 90 days')"
                      [ngClass]="{'big-round': overview['renew_90d'] > 10}">{{
                      overview['renew_90d'] }}</div>
                    <div class="vertical-line"></div>
                    <div class="horizontal-line"></div>
                    <div class="small-text">90d</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-2 flex-1 graphs">
          <div class="item-container">
            <div class="card">
              <div class="card-body">
                <div class="section-title">
                  <div>
                    Algorithms
                  </div>
                  <div *ngIf="loading">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                </div>
                <div id="algorithms_graph" class="graph">
  
                </div>
              </div>
            </div>
          </div>
          <div class="item-container">
            <div class="card">
              <div class="card-body">
                <div class="section-title">
                  <div>
                    Types
                  </div>
                  <div *ngIf="loading">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                  </div>
                </div>
                <div id="types_graph" class="graph">
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="section">
    <div class="card">
      <div class="card-body table-acm-container">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-link" [ngClass]="{active: currentPage == 'utilization', show: currentPage == 'utilization'}"
              id="utilizations-tab" (click)="currentPage = 'utilization'" data-bs-toggle="tab"
              data-bs-target="#utilizations" type="button" role="tab" aria-controls="utilizations" aria-selected="true">
              Utilization
            </a>
            <a class="nav-link" [ngClass]="{active: currentPage == 'processor', show: currentPage == 'processor'}"
              id="processor-tab" data-bs-toggle="tab" data-bs-target="#processor" (click)="currentPage = 'processor'"
              type="button" role="tab" aria-controls="processor" aria-selected="true">
              Processor
            </a>
            <a class="nav-link" [ngClass]="{active: currentPage == 'os', show: currentPage == 'os'}" id="os-tab"
              data-bs-toggle="tab" data-bs-target="#os" type="button" role="tab" (click)="currentPage = 'os'"
              aria-controls="os" aria-selected="true">
              OS
            </a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane active"
            [ngClass]="{active: currentPage == 'utilization', show: currentPage == 'utilization'}" id="utilizations"
            role="tabpanel" aria-labelledby="utilizations-tab">
            <ng-container *ngIf="currentPage == 'utilization'">
              <ng-container *ngIf="!selectedInstance">
                <div *ngIf="recomondations['utilization'].length > 0 && checkForEMS()" class="pink_margin">
                  EMS is not integrated. Below recommendations are based on CPU utilization only and may not be
                  accurate.
                  Please
                  contact
                  your admin to enable EMS integration for a more comprehensive report.
                </div>
                <div *ngIf="recomondations['utilization'].length > 0" class="pink_margin">
                  Recommendations are based on 4 weeks average CPU and Memory usage where the usage for both is less
                  than
                  40%.
                  Where memory metrics are not available recommendations are made based on CPU usage only. A manual
                  check
                  on
                  the memory
                  usage is highly recommended before downsizing the instance based on recommendations provided
                </div>
                <div class="flex_gap10">
                  <div>Potential Saving: </div>
                  <div>
                    <span class="green_text fontsize_em" [innerHTML]="getTotalSavings('utilization')"></span>
                  </div>
                  <div *ngIf="loading_recom">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" class="width15_margin10">
                  </div>
                </div>
                <div class="overflow_auto2">
                  <table class="table table-docs table-hover cost-table" id="ec2_cost_optimization">
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th *ngFor="let h of mainHeaders()" [ngStyle]="{'text-align': h.center ? 'center': 'left'}">
                          {{ h.name }}
                          <span *ngIf="h.sort && h.sort.sort"
                            (click)="notifier.sortTable(h, recomondations['utilization'])">
                            <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                            <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                          </span>

                          <span *ngIf="h.filter" class="inputseach">
                            <input type="text" [(ngModel)]="h.filterText" />
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </th>
                        <th class="text_align_centre">More Details</th>
                      </tr>
                    </thead>

                    <tbody [ngStyle]="{
                                        display: recomondations['utilization'].length ? 'table-row-group' : 'none'
                                      }" *ngFor="let i of recomondations['utilization'] | paginate
                        : {
                            itemsPerPage: pagination['utilization']['perPage'],
                            currentPage: pagination['utilization']['page'],
                            totalItems: recomondations['utilization'].length
                          }; let j = index">
                      <ng-container *ngIf="notifier.filterTableColums(ec2CostHeaders, i)">
                        <tr *ngFor="let recom of i.recommendations; let recom_index = index">
                          <td *ngIf="recom_index == 0" [attr.rowspan]="i.recommendations.length">{{
                            (pagination['utilization']['perPage'] * (pagination['utilization']['page'] - 1)) + j + 1 }}
                          </td>
                          <ng-container *ngFor="let h of ec2CostHeaders">
                            <td *ngIf="!h.rowspan">
                              <div *ngIf="h.click" class="btn btn-light" (click)="callFunction(h.click, i)">
                                <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}"
                                  [innerHTML]="loadHTML(i[h.id])">
                                </div>
                              </div>
                              <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click"
                                [innerHTML]="loadHTML(i[h.id])">
                              </div>
                            </td>
                            <td *ngIf="h.rowspan && recom_index == 0" [attr.rowspan]="i[h.rowspanbasedon].length">
                              <div *ngIf="h.click" class="btn btn-light" (click)="callFunction(h.click, i)">
                                <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}"
                                  [innerHTML]="loadHTML(i[h.id])">
                                </div>
                              </div>
                              <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click"
                                [innerHTML]="loadHTML(i[h.id])">
                              </div>
                            </td>
                          </ng-container>
                          <ng-container *ngFor="let h of ec2Recommendations">
                            <td *ngIf="!h.rowspan">
                              <div *ngIf="h.click" class="btn" (click)="callFunction(h.click, recom)">
                                <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}"
                                  [innerHTML]="loadHTML(recom[h.id])">
                                </div>
                              </div>
                              <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click"
                                [innerHTML]="loadHTML(recom[h.id])">
                              </div>
                            </td>
                            <td *ngIf="h.rowspan && recom_index == 0" [attr.rowspan]="recom[h.rowspanbasedon].length"
                              [innerHTML]="recom[h.id]">
                            </td>
                          </ng-container>
                          <ng-container>
                            <td class="text_align_centre">
                              <div class="failed_ami" (mouseenter)="setPosition($event)">
                                <div class="status_container">
                                  <div class="black_bottom_border">
                                    More Details
                                  </div>
                                </div>
                                <div class="failed_ami_message_container"
                                  [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0, height: position.height, width: position.width}">
                                  <div class="message_back">
                                    <div>
                                      <div class="message_grid"
                                        [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                                        <div class="flex_start_direction">
                                          <table class="flex_grow">
                                            <thead>
                                              <tr *ngIf="!i.EMS">
                                                <th [attr.colspan]="mainDetailsHeader().length">
                                                  <span class="pink_color">
                                                    EMS is not integrated. Below recommendations are based on CPU
                                                    utilization only
                                                    and may not be accurate. Please
                                                    contact
                                                    your admin to enable EMS integration for a more comprehensive
                                                    report.
                                                  </span>
                                                </th>
                                              </tr>
                                              <tr>
                                                <th *ngFor="let h of mainDetailsHeader()">{{h.name}}</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                                                <td *ngFor="let h of recomHeader">{{recom[h.id]}}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="display_flex_height20">
                                    <div class="showdown" [ngStyle]="{right: position.pointer}"></div>
                                  </div>
                                  <div class="message_container">
                                    <div>
                                      <div class="message_grid"
                                        [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                                        <div class="flex_start_direction">
                                          <table class="flex_grow">
                                            <thead>
                                              <tr *ngIf="!i.EMS">
                                                <th [attr.colspan]="mainDetailsHeader().length">
                                                  <span class="pink_color">
                                                    EMS is not integrated. Below recommendations are based on CPU
                                                    utilization only
                                                    and may not be accurate. Please
                                                    contact
                                                    your admin to enable EMS integration for a more comprehensive
                                                    report.
                                                  </span>
                                                </th>
                                              </tr>
                                              <tr>
                                                <th *ngFor="let h of mainDetailsHeader()">{{h.name}}</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                                                <td *ngFor="let h of recomHeader">{{recom[h.id]}}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </ng-container>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody
                      *ngIf="recomondations['utilization'].length == 0 && jobDetails && jobDetails['acctstatus'] === 'Completed'">
                      <tr>
                        <td [attr.colspan]="ec2CostHeaders.length + ec2Recommendations.length + 2" class="center">
                          <div class="text_align_centre">No Recommendations Found. Click <span class="nav-link"
                              (click)="createJobTrigger = true">here</span> to
                            scan</div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!jobDetails && !fetching">
                      <tr>
                        <td [attr.colspan]="11" class="center">
                          No Scans Found. Click <span class="nav-link" (click)="createJobTrigger = true">here</span> to
                          scan
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="fetching">
                      <tr>
                        <td [attr.colspan]="ec2CostHeaders.length + ec2Recommendations.length + 2" class="center">
                          <div class="flex-center">
                            <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
                            <div *ngIf="jobDetails && jobDetails['acctstatus'] !== 'Completed'">{{
                              jobDetails['acctstatus'] }}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="paginator">
                  <div class="flex-start">
                    <span class="no-wrap">Per Page</span>
                    <select class="form-select" [(ngModel)]="pagination['utilization']['perPage']"
                      (change)="pagination['utilization']['page'] = 1">
                      <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
                    </select>
                  </div>
                  <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="pagination['utilization']['page'] = $event">
                  </pagination-controls>
                </div>
                <div class="pink_color_2">
                  * Disclaimer: The Cost Controls Recommendations given above are based on
                  the infra usage. The recommended instances may not be the best choice for
                  the applications that are running on them. Please try the recommendation on
                  test/dev environment. Customers are requested to use these recommendation at
                  their own risk. {{ whitelabelData['pd']['appName'] }} and its promoters should not be held responsible for
                  any performance degradation either in part or in whole due to the changes
                  recommended here.
                  <br /><br />
                  * The cost recommended provided below are based on on demand pricing model. You may not be able to
                  implement
                  some of
                  the recommendations as you may have already purchased Reserved Instances Plan
                  <br /><br>
                  * Some recommendations are related to usage of discount plans. Please be
                  careful before accepting/applying them as the changes can be irreversible
                  resulting in a short/long term revenue loss
                </div>
              </ng-container>
              <div *ngIf="selectedInstance">
                <div class="card-header height_60 flex-start">
                  <button class="btn btn-light flex-start" (click)="selectedInstance = null">
                    <span>
                      <i class="fa fa-angle-left"></i>
                    </span>
                    <span>
                      Back
                    </span>
                  </button>
                  <span class="title metrics_style">
                    Metrics - {{selectedInstance.instid}}
                  </span>
                </div>
                <div class="card-body">
                  <div>
                    <metrics-ec2 [instance]="selectedInstance"></metrics-ec2>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="tab-pane" [ngClass]="{active: currentPage == 'processor', show: currentPage == 'processor'}"
            id="processor" role="tabpanel" aria-labelledby="processor-tab">
            <app-processor-based-recommendations *ngIf="currentPage == 'processor'"
              [recommendations]="recomondations['processor']" [pages]="pages" [jobDetails]="jobDetails"
              [fetching]="fetching" (scan)="createJobTrigger = true"></app-processor-based-recommendations>
          </div>
          <div class="tab-pane" [ngClass]="{active: currentPage == 'os', show: currentPage == 'os'}" id="os"
            role="tabpanel" aria-labelledby="os-tab">
            <app-os-based-recommendations *ngIf="currentPage == 'os'" [recommendations]="recomondations['os']"
              [pages]="pages" [jobDetails]="jobDetails" [fetching]="fetching"
              (scan)="createJobTrigger = true"></app-os-based-recommendations>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-instance-savings *ngIf="selectedInstance" [instance]="selectedInstance"
  (hideModel)="selectedInstance = null"></app-instance-savings> -->

<div class="move-top" [ngStyle]="{bottom: pageOffset > 600 ? '50px' : '-100px'}" (click)="moveToTop()">
  <i class="fa fa-angle-double-up" aria-hidden="true"></i>
</div>

<ec2-cost-create-job-element *ngIf="createJobTrigger" (hideModal)="hideCreateJob($event)">
</ec2-cost-create-job-element>