<div class="screen">
  <div class="protocol-container">
    <div class="header">
      Edit Scanner
    </div>
    <div class="body">
      <div class="form-group">
        <label>Scanner ID</label>
        <input autocomplete="off" type="text" class="form-control" disabled [value]="scannerObj['scanner_id']">
      </div>
      <div class="form-group">
        <label>Scanner Name</label>
        <input autocomplete="off" type="text" class="form-control" disabled [value]="scannerObj['scanner_id']">
      </div>
      <div class="form-group">
        <label>Alert EmailId List</label>
        <div class="emails-container">
          <div class="email-container flex-start" *ngFor="let email of scannerObj['emails_list']; let i = index">
            <input autocomplete="off" class="form-control" type="email" [(ngModel)]="email['email']">
            <button class="btn btn-light" (click)="removeEmail(i)" *ngIf="scannerObj['emails_list'].length > 1">
              <i class="fa fa-minus"></i>
            </button>
          </div>
          <div>
            <button class="btn btn-light" (click)="addEmail()">
              <i class="fa fa-plus"></i> Add Email
            </button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Interval</label>
        <div class="flex-start grid-2">
          <select class="form-select" [(ngModel)]="interval">
            <option value="1">1</option>
            <option value="3">3</option>
            <option value="6">6</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="24">24</option>
          </select>
          <select class="form-select" [(ngModel)]="intervalFrequency">
            <option value="hours">Hours</option>
            <option value="minutes">Minutes</option>
          </select>
        </div>
      </div>
    </div>
    <div class="footer flex-end">
      <button class="btn btn-primary-soft" (click)="update()" *ngIf="!loading">
        Update
      </button>
      <div *ngIf="loading">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-18" />
      </div>
      <button class="btn btn-light" (click)="this.hideModel.emit(false)">
        Cancel
      </button>
    </div>
  </div>
</div>