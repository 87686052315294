<table class="table table-docs table-hover">
  <thead>
    <tr>
      <th>S.N.</th>
      <th *ngFor="let h of headers">{{h.name}}</th>
    </tr>
  </thead>

  <tbody [ngStyle]="{'display': data.length > 0 ? 'table-row-group' : 'none'}" *ngFor="let i of data; let j = index">
    <tr>
      <td>
        <i *ngIf="!i.showNested && i.nestedData" class="fa fa-caret-right"></i> <i *ngIf="i.showNested && i.nestedData"
          class="fa fa-caret-down"></i> {{j+1}}
      </td>
      <td *ngFor="let h of headers">
        <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
          <span>
            {{i[h.id]}}
          </span>
        </span>
        <span *ngIf="!h.click">
          {{i[h.id]}}
        </span>
      </td>
    </tr>
    <tr *ngIf="i.showNested" class="shownestedheight">
      <td [attr.colspan]="headers.length + 1">
        <nTableNested [headers]="getHeaderNested()" [data]="i.nestedData" (hideModal)="hideNestedNested($event)">
        </nTableNested>
      </td>
    </tr>

  </tbody>
  <tbody [ngStyle]="{'display': data.length == 0 ? 'table-row-group' : 'none'}">
    <tr>
      <td [attr.colspan]="headers.length + 2">
        <div class="nodtafound">No Data Found</div>
      </td>
    </tr>
  </tbody>
</table>