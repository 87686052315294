import { Component, OnDestroy, OnInit, NgZone } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
import { environment } from "src/environments/environment";

declare var $: any;
declare let flatpickr: any;

@Component({
  templateUrl: "./discount.component.html",
  styleUrls: ["./discount.component.css"],
})
export class DiscountComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  customerType: string = localStorage.getItem("customerType");
  discount: string;
  dateRange: string;

  accountId: any;
  regionId: any;
  currentMessage: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    await this.loadDiscount();
    this.notifier.loading(false);
  }

  async updateDiscount() {
    this.notifier.loading(true);
    let sDate = $("#dateRange").val().split(" to ");
    let data = {
      lid: this.accountId,
      userType: "C",
      action: "saveFinalBillDiscount",
      startDate: sDate[0],
      endDate: sDate[1],
      allowedDis: this.discount,
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
    
    if(this.customerType == 'mp') {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
      await this.load();
    } else {
      this.notifier.alert("Info", "", result.msg, "info", 5000);
    }

    this.notifier.loading(false);
  }

  async loadDiscount() {
    this.notifier.loading(true);
    let data = {
      lid: this.accountId,
      userType: "C",
      action: "fetchFinalBillDiscount",
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
    
    if(this.customerType == 'mp') {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.discount = result.discountAllowed;
      $("#dateRange").val(`${result.startDate} to ${result.endDate}`);
    } else {
      this.notifier.alert("Info", "", result.msg, "info", 5000);
    }

    flatpickr("#dateRange", {
      dateFormat: "Y-m-d",
      mode: "range",
      showMonths: 2,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (dateStr.includes("to")) {
          // this.load();
        }
      },
    });

    this.notifier.loading(true);
  }

  callFunction(name: any, param: any) {}
}
