<div class="card" *ngIf="!selectedJob">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" routerLink="/dash/security/aws-security-protocol" fragment="security"><i class="fas fa-angle-left"></i> Back</button>
      <h5>
        Security Baseline
      </h5>
    </div>
    
    <div class="flex-right">
      <button class="btn btn-primary-soft" (click)="createJob = true" *ngIf="jobs.length > 0">
        Scan
      </button>
      <button class="btn btn-primary-soft" (click)="load()">
        Refresh
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="flex-item-center">
      <span>Per Page</span>
      <select class="padding-color" [(ngModel)]="perPage">
        <option [ngValue]="count" *ngFor="let count of pages">{{ count }}</option>
      </select>
    </div>
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of jobsHeaders">{{ h.name }}</th>
        </tr>
      </thead>
      <tbody *ngIf="jobs.length > 0 && !loading['jobs']">
        <tr *ngFor="let job of jobs| paginate
                : {
                    itemsPerPage: perPage,
                    currentPage: page,
                    totalItems: jobs.length
                  }; let i = index" class="cursor" (click)="selectJob(job)">
          <td>{{ ((page - 1) * perPage) + i + 1 }}</td>
          <td *ngFor="let h of jobsHeaders">
            <span class="nav-link" [ngClass]="{'nav-link': h['link']}">
              {{ job[h.id] }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading['jobs']">
        <tr>
          <td class="text-center" [attr.colspan]="jobsHeaders.length + 1">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar" />
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!loading['jobs'] && jobs.length == 0">
        <tr>
          <td [attr.colspan]="jobsHeaders.length + 1">
            <div class="center">
              No Scans Found. <span class="nav-link" (click)="createJob = true">Click here</span> to create one
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paginator" *ngIf="jobs.length > 0">
      <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="page = $event">
      </pagination-controls>
    </div>
  </div>
</div>
<app-ssb-check *ngIf="selectedJob" [loading]="loading" [job]="selectedJob" (loadJobs)="loadJobs($event)"
  (hideModel)="load($event)">
</app-ssb-check>
<create-job-ssb *ngIf="createJob" [accountId]="accountId" [regionId]="regionId" (hideModal)="hideModel($event)">
</create-job-ssb>