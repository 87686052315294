<div class="card">
  <h5 class="card-header">AWS Security Protocol</h5>
  <div class="card-body">
    <div class="secoptions mar-10">
      <div class="wid-35">
      <div
        class="support_type_container pointer dis_col text-start h_100"
        routerLink="/dash/compliance/ssb"
        fragment="security"
      >
      <div class="flex">
        <div class="text-start">
          <h5>Cloud Security Baseline</h5>
          <p>
            Implements comprehnsive security stratergies for robust incident
            response protocols to fortify your cloud infrastructure
          </p>
        </div>
        <div class="support_type_box support_type_logo">
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg
                    "
            alt=""
          />
        </div>
      </div>
      <div class="wid-100">
        <div *ngIf="this.loading['jobs'] || loading['sec_all']" class="text-center">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar" />
        </div>
        <div class="container flex" *ngIf="latestItem">
          
          <div class="mar_r_10">
            <div>
              <span class="_12px">{{latestItem.total_checks }}</span>
             </div>
            <div>
              <span class="_10px">Total Checks</span>
            </div>
            
          </div>

          <div class="mar_r_10">
            <div>
              <span class="_12px red">{{latestItem.failed }}</span>
             </div>
            <div>
              <span class="_10px">Failed checks</span>
            </div>
            
          </div>

          <div class="mar_r_10">
            <div>
              <span class="_12px green">{{latestItem.passed }}</span>
             </div>
            <div>
              <span class="_10px">Passed checks</span>
            </div>
            
          </div>

          <div>
            <div>
              <span class="_12px">{{latestItem.perc }}</span>
             </div>
            <div>
              <span class="_10px">Score</span>
            </div>
            
          </div>

        </div>
      </div>
      </div>

    </div>

    <div class="wid-35" [class.disabled]="getscore() < 40">
      <div
        class="support_type_container pointer dis_col h_100"
        routerLink="/dash/security/advanced-security-protocol"
        fragment="security"
      >
      <div class="flex">
        <div class="text-start">
          <h5>Advanced Security Protocol</h5>
          <p>
            Provides essential security measures and AWS best practicies to
            safegaurd cloud infrastructure effectively
          </p>
        </div>
        <div class="support_type_box support_type_logo">
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg
                    "
            alt=""
          />
        </div>
      </div>
      <div class="wid-100" *ngIf="latestItem != null">
        <div *ngIf="loading['jobs_a'] || loading['sec_all']" class="text-center">
          <img src="../assets/img/loading_2.svg" alt="loading scans" class="wid-mar" />
        </div>
        <div class="container flex text-start" *ngIf="latestItem_a">
          
          <div class="mar_r_10">
            <div>
              <span class="_12px">{{latestItem_a.total_checks }}</span>
             </div>
            <div>
              <span class="_10px">Total Checks</span>
            </div>
            
          </div>

          <div class="mar_r_10">
            <div>
              <span class="_12px red">{{latestItem_a.failed }}</span>
             </div>
            <div>
              <span class="_10px">Failed checks</span>
            </div>
            
          </div>

          <div class="mar_r_10">
            <div>
              <span class="_12px green">{{latestItem_a.passed }}</span>
             </div>
            <div>
              <span class="_10px">Passed checks</span>
            </div>
            
          </div>

          <div>
            <div>
              <span class="_12px">{{latestItem_a.perc }}</span>
             </div>
            <div>
              <span class="_10px">Score</span>
            </div>
            
          </div>

        </div>
      </div>
      </div>

    </div>
    </div>
  </div>
</div>
