import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

@Component({
  styleUrls: [
    "./createscan.amis.component.css",
    "./../../warquiz.component.css",
  ],
  selector: "create-workload",
  templateUrl: "./createscan.amis.component.html",
})
export class CreateWorkloadComponent implements OnInit, OnDestroy {
  @Output() hideModal = new EventEmitter<any>();
  @Input() accountId: string;
  @Input() regionId: string;
  @Input() customLens: any;
  uid = localStorage.getItem("t");
  t = localStorage.getItem("t");
  ut = localStorage.getItem("ut");
  eId = localStorage.getItem("eId");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = "";
  review_owner: string = "";
  description: string = "";
  environment: string = "PRODUCTION";
  loading: boolean = false;
  errors: any = {
    workloadName: {
      length: 100,
      error: false,
      message: "",
    },
    description: {
      length: 250,
      error: false,
      message: "",
    },
    review_owner: {
      length: 255,
      error: false,
      message: "",
    },
  };
  lensesData: any = {
    wellarchitected: {
      name: "AWS Well-Architected Framework",
      description:
        "The AWS Well-Architected Framework Lens provides a set of foundational questions for you to consider for all of your cloud architectures.",
      selected: true,
      editable: false,
    },
    softwareasaservice: {
      name: "SaaS Lens",
      description:
        "The AWS SaaS Lens provides a set of additional questions for you to consider for your Software-as-a-Service (SaaS) applications",
      selected: false,
    },
    serverless: {
      name: "Serverless Lens",
      description:
        "The AWS Serverless Application Lens provides a set of additional questions for you to consider for your serverless applications.",
      selected: false,
    },
    "arn:aws:wellarchitected::aws:lens/sap": {
      name: "SAP Lens",
      description:
        "The SAP Lens for the AWS Well-Architected Framework is a collection of customer-proven design principles and best practices for ensuring SAP workloads on AWS are well-architected. Use this lens as a supplement to the AWS Well-Architected Framework.",
      selected: false,
    },
  };

  ngOnInit(): void {
    // console.log("debugging the custom lens details",this.customLens)
    if (this.customLens.length > 0) {
      this.customLens.forEach((lens: any) => {
        this.lensesData[lens["arn"]] = {
          name: lens["name"],
          description: "The SAP Lens for the AWS Well-Architected Framework is a collection of customer-proven design principles and best practices for ensuring SAP workloads on AWS are well-architected. Use this custom lens as a supplement to the AWS Well-Architected Framework.",
        };
      });
    }
  }

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  getObjectKeys(obj: any): any {
    return Object.keys(obj);
  }

  validate() {
    let check = true;
    this.errors["workloadName"]["error"] = false;
    this.errors["description"]["error"] = false;
    this.errors["review_owner"]["error"] = false;
    if (
      !this.scan_name ||
      this.scan_name.trim().length == 0 ||
      this.scan_name.trim() == ""
    ) {
      this.errors["workloadName"]["error"] = true;
      this.errors["workloadName"]["message"] =
        "Workload Name is a mandatory field";
      check = false;
    }
    if (
      (this.scan_name.trim().length < 3 ||
        this.scan_name.trim().length > 100) &&
      this.errors["workloadName"]["error"] == false
    ) {
      this.errors["workloadName"]["error"] = true;
      this.errors["workloadName"]["message"] =
        "Workload Name should be atleast 3 characters long and atmost 100 characters long";
      check = false;
    }
    if (
      !this.description ||
      this.description.trim().length == 0 ||
      this.description.trim() == ""
    ) {
      this.errors["description"]["error"] = true;
      this.errors["description"]["message"] =
        "Description is a mandatory field";
      check = false;
    }
    if (
      (this.description.trim().length < 3 ||
        this.description.trim().length > 250) &&
      this.errors["description"]["error"] == false
    ) {
      this.errors["description"]["error"] = true;
      this.errors["description"]["message"] =
        "Description should be atleast 3 characters long and atmost 250 characters long";
      check = false;
    }
    if (
      !this.review_owner ||
      this.review_owner.trim().length == 0 ||
      this.review_owner.trim() == ""
    ) {
      this.errors["review_owner"]["error"] = true;
      this.errors["review_owner"]["message"] =
        "Review Owner is a mandatory field";
      check = false;
    }
    if (
      (this.review_owner.trim().length < 3 ||
        this.review_owner.trim().length > 255) &&
      this.errors["review_owner"]["error"] == false
    ) {
      this.errors["review_owner"]["error"] = true;
      this.errors["review_owner"]["message"] =
        "Review Owner should be atleast 3 characters long and atmost 255 characters long";
      check = false;
    }
    return check;
  }

  async createJob() {
    if (!this.validate()) {
      return;
    }
    this.loading = true;
    let data: any = {
      action: "create_workload_v2",
      account_id: this.accountId,
      region_id: this.regionId,
      workload_name: this.scan_name.trim(),
      description: this.description,
      environment: this.environment.trim(),
      review_owner: this.review_owner.trim(),
      lenses: this.getObjectKeys(this.lensesData).filter((lens: any) => {
        return this.lensesData[lens].selected;
      }),
      id_token: localStorage.getItem("idT"),
    };

    let headers: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("success", "", result.message, "success", 5000);
      this.no(true);
    } else {
      if(result.error_message.includes("ConflictException")){
        this.notifier.alert("Info", "", "Duplicate Workload Name", "info", 5000);

      }
      else{
        this.notifier.alert("Info", "", result.error_message, "info", 5000);

      }
    }
    this.loading = false;
  }
}
