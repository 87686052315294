
<div class="confirmContainer">
    <!-- <div class="confirmBox">
          <div>
          <span class="notiHeading">Notifications</span><span class="badge badge-primary bg-primary text-white">{{noticount}}</span>
      </div>
          <div class="notification-body">
              <div *ngFor="let item of notification">
              <div class="card notification-card" >
                  <div class="card-body">
                    <h5 class="card-title d-flex justify-content-between" >
                      <div>
                      <span class="dot"><input class="form-check-input" type="checkbox" id="readCheckbox"></span> Notification Title 
                  </div>
                  <div>
                      </div>
                    </h5>
                    <p class="card-text notification-message">{{item.notiMsg}}</p>
                    <p class="card-text notification-time">4:34 PM</p>
                  </div>
                </div>
              </div>
            </div>
          
      </div> -->
    <div class="confirmBox noti2">
      <div class="d-flex justify-content-between">
        <span class="notiHeading">Notifications</span>
        <div *ngIf="notification.length!=0 && loading!=true" (click)="readAllMsg()" class="double-tick"><i class="fa-solid fa-check-double"></i> Mark all as read</div>
      </div>
  
      <div *ngIf="loading" class="d-flex justify-content-center">
        <div><img src="../assets/img/loading_2.svg" alt="loading scans"
            class="minloader"/></div>
      </div>

      <div *ngIf="notification.length!=0" class="notification-body">
        <div *ngFor="let item of getSortedNotification()">
        <div>
          <div class="card notification-card">
            <div class="card-body">
              <div class="card-title ">
                <div class="d-flex justify-content-between">
                  <span class="noti-title">{{item.msg.notification_title}} &nbsp;<span class="badge badge-info bg-primary">NEW</span></span>
                  <span class="" (click)="readMsg(item.msg.notification_id)"><i class="fa-regular fa-square-check"></i></span>
                </div>
              </div>
              <!-- <div class="paragraph-container">
              <div class="text-container"> -->
              <p class="card-text notification-message ">
                {{item.msg.message}}
                
              </p>
              <ng-container *ngIf="item.msg.link !== ''">
              <p class="card-text notification-message ">
                <a [href]="item.msg.link" target="_blank">{{item.msg.link}}</a>
                
              </p>
            </ng-container>
              <!-- <div class="icon-container">
                <span *ngIf="isCollapsed" class="expand-icon">...</span>
                <span *ngIf="!isCollapsed" class="collapse-icon">▲</span>
              </div> -->
  
            <!-- </div>
            </div> -->
              <p class="card-text notification-time">{{item.msg.date}} UTC</p>
            </div>
          </div>
        </div>
      </div>
      
      </div>
    
      <div *ngIf="notification.length==0 && loading!=true" class="box">
        <div class="iconHold"> <i class="fa-solid fa-inbox"></i></div>
       
        <span class="notiText"><p>No New Message Found!</p></span>
      </div>
    </div>
  </div>