

    <div class="row">
        <div class="col">
           
            <div class="card">
                <div class="card-header"> <button (click)="no()" class="btn btn-light"><b> < Back</b></button></div>
                <div class="card-body">
            <span class="table-title"></span>
            <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead class="blue">
                    <tr>
                      <td scope="col">S.N.</td>
                      <td class="col-2">Resource Name</td>
                      <td scope="col">Alert ID</td> 
                      <td scope="col">Alert Time</td>
                      <td scope="col">Account ID</td> 
                      <td scope="col">Account</td>
                      <td scope="col">Alert Status</td> 
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr *ngFor="let item of alertList; let j = index">
                      <td scope="row" class="m-4">{{j+1}}</td>
                      <td class="col-2">{{item['idd']}}</td>
                      <td>{{item['id']}}</td>
                      <td>{{item['alertTimeFormatted']}}</td>
                      <td>{{item['accountId']}}</td>
                      <td>{{item['account']}}</td>
                      <td>{{item['status']}}</td>
                    </tr>             
                  </tbody> 
                </table>
              </div>
            </div>
        </div>
    
        </div>
    </div>



