import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { APIService } from "../../../../api/api.service";
import { NotifierService } from "../../../../_services/notifier.service";

declare let $: any;

@Component({
  selector: "update-bom",
  templateUrl: "./update.bom.component.html",
  styleUrls: ["./update.bom.component.css"],
})
export class UpdateBOMComponent implements OnInit, OnDestroy {
  @Input("bom") bom: any;
  @Input("action") action: any;
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  accountId: any;
  instanceTypes: any = [];
  billingTypes: any = [];
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.bom = { ...this.bom };
    $("#instanceTypes").selectpicker();
    $("#billingTypes").selectpicker();
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.load();
        this.accountId = d.value;
      } else if (d.key == "regionId") {
        this.load();
        this.regionId = d.value;
      }
    });
  }

  async load() {
    await this.fetchInstanceTypes();
    await this.fetchBillingTypes();
  }

  async updateEntry() {
    if (this.action == "local") {
      this.close(true);
      return;
    }
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: this.action,
      instanceType: this.bom.instanceType,
      os: this.bom.os,
      cpu: this.bom.os,
      disk: this.bom.disk,
      quantity: this.bom.quantity,
      ram: this.bom.ram,
      billingType: this.bom.billingType,
      usageHours: this.bom.usageHours,
      id: this.action == "update" ? this.bom.uuid : undefined,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert("Success", "", result.msg, "success", 5000);
      if (this.action == "add") {
        this.close();
      } else {
        this.close(true);
      }
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.hideModel.emit(this.bom);
    } else {
      this.hideModel.emit(false);
    }
  }

  async fetchInstanceTypes() {
    this.notifier.loading(true);
    let data = { action: "fetchInstanceTypes" };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.instanceTypes = result.instanceTypeList;
      setTimeout(() => {
        $("#instanceTypes").selectpicker("refresh");
      }, 100);
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
  }

  async fetchBillingTypes() {
    this.notifier.loading(true);
    let data = { accountId: this.accountId, action: "fetchBillingType" };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.billingTypes = result.billingTypeList;
      setTimeout(() => {
        $("#billingTypes").selectpicker("refresh");
      }, 100);
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }
    this.notifier.loading(false);
  }
}
