import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-high-alert',
  templateUrl: './high-alert.component.html',
  styleUrls: ['./high-alert.component.css']
})
export class HighAlertComponent {
  @Output() hideModal = new EventEmitter<any>();
  @Input("seHigh") seHigh: any;
  @Input("govHigh") govHigh: any;
  @Input("dsHigh") dsHigh: any;
  @Input("dcHigh") dcHigh: any;



  no(){
    this.hideModal.emit({ status: 0 });
    
  }
}
