<div class="card">
  <h5 class="card-header" *ngIf="!createTicketStrictTicketType">Create Ticket</h5>
  <h5 class="card-header" *ngIf="createTicketStrictTicketType">
    <button class="btn btn-light" (click)="close()">
      < Back</button>
        <span>Create Ticket</span>
  </h5>
  <div class="card-body">
    <h5 class="card-title">Case Details</h5>

    <div class="form-row">
      <div class="form-group row">
        <label>Select Support Type</label>
        <div class="support_types_container selsect_support_type">
          <div class="support_type_container pointer" [ngClass]="{
              support_type_selected: supportType == 'minfy' && !minfyDisabled,
              selected_type_disabled: minfyDisabled
            }">
            <div class="support_type_box" (click)="changeSupportType('minfy')">
              <div class="support_type_logo">
                <div class="outer">
                  <div class="inner"></div>
                </div>
              </div>
              <div>
                <div class="support_type_text">Minfy Support</div>
                <div class="support_type_desc">Managed Services from Minfy</div>
              </div>
            </div>
          </div>
          <div class="support_type_container pointer" [ngClass]="{
              support_type_selected: supportType == 'aws' && !awsDisabled,
              selected_type_disabled: awsDisabled
            }">
            <div class="support_type_box" (click)="changeSupportType('aws')">
              <div class="support_type_logo">
                <img src="../../../assets/img/aws_logo_1.png" alt="" />
              </div>
              <div>
                <div class="support_type_text">AWS Support</div>
                <div class="support_type_desc">
                  Support from Amazon Web Services
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="p-1">
        <input autocomplete="off" type="radio" name="supportType" id="minfySupport" checked/>
        <label for="minfySupport">Minfy Support</label>
      </div>
      <div class="p-1">
        <input autocomplete="off" type="radio" name="supportType" id="awsSupport" />
        <label for="awsSupport">AWS Support</label>
      </div> -->
        </div>
      </div>

      <div class="mb-3">
        <label for="" class="form-label">Severity</label>
        <select class="form-control" id="serverityCodeSelect" [(ngModel)]="ticketSeverity" data-live-search="true">
          <option value="low">Low (low)</option>
          <option value="normal">Normal (medium)</option>
          <option value="high">High (high)</option>
          <option value="urgent">Urgent (critical)</option>
        </select>
      </div>

      <div class="mb-3" *ngIf="supportType == 'aws' && !awsDisabled">
        <label for="" class="form-label">Service</label>
        <select class="form-control" id="serviceCodeSelect" [(ngModel)]="serviceSelected"
          (change)="changeService($event)" data-live-search="true">
          <option value="low" [ngValue]="service" *ngFor="let service of services">
            {{ service.name }}
          </option>
        </select>
      </div>
      <!-- [attr.selected]="categories.selected.code == category.code" [attr.selected]="services.selected.code == service.code" -->
      <div class="mb-3" *ngIf="supportType == 'aws' && !awsDisabled">
        <label for="" class="form-label">Category</label>
        <select class="form-control" id="categoryCodeSelect" [(ngModel)]="categorySelected" data-live-search="true">
          <option value="low" [ngValue]="category" *ngFor="let category of categoriesList">
            {{ category.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Subject</label>
        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="ticketSubject" />
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Description</label>
        <textarea [(ngModel)]="ticketDescription" name="" class="form-control description_text_area" cols="30"
          rows="10"></textarea>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Attachments</label>
        <div>
          <div class="support_types_container files_container">
            <div class="support_type_container" *ngFor="let d of attachedfileData; let indexx = index">
              <div class="support_type_box">
                <div class="support_type_logo">
                  <i class="fa fa-file-text" aria-hidden="true"></i>
                </div>
                <div>
                  <div class="support_type_text">{{ d.fileName }}</div>
                  <div class="support_type_desc">
                    {{ niceBytes(d.fileSize) }}
                  </div>
                </div>
                <div class="support_type_remove">
                  <i class="fa fa-times pointer" aria-hidden="true" (click)="deleteSeletedFile(indexx)"></i>
                </div>
              </div>
            </div>
            <div class="support_type_text attachment_add">
              <div class="flex-center-align" [attr.disabled]="!writeAccess"
                [ngStyle]="{'pointer-events': !writeAccess && 'none'}" (click)="triggerFIle()">
                <div><i class="fa fa-plus" aria-hidden="true"></i></div>
              </div>
            </div>
            <input autocomplete="off" #attachmenrs type="file" name="" multiple id="attachments"
              class="form-control suppoty_type_text_attachment" (change)="getFile($event)" />
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Additional Contacts</label>
        <div class="additional_contacts">
          <div class="additional_contact" *ngFor="
              let contact of additionalContacts;
              let i = index;
              trackBy: trackByFn
            ">
            <input autocomplete="off" type="text" name="contact_value" class="form-control"
              *ngIf="additionalContacts.length - 3 < i" (keyup)="checkForLast(contact, i)"
              [(ngModel)]="additionalContacts[i]" />
            <input autocomplete="off" type="text" name="contact_value" class="form-control"
              *ngIf="additionalContacts.length - 3 >= i" [(ngModel)]="additionalContacts[i]" />
            <div>
              <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
            </div>
            <div>
              <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="#" class="btn btn-primary" [ngClass]="{
        disabled_btn:
          (supportType == 'minfy' && minfyDisabled) ||
          (supportType == 'aws' && awsDisabled) ||
          !writeAccess
      }" (click)="createTicket($event)" [attr.disabled]="
        (supportType == 'minfy' && minfyDisabled) ||
        (supportType == 'aws' && awsDisabled)
      ">Create</a>
  </div>
</div>