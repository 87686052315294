import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { APIService } from './../api/api.service';
import { NotifierService } from './../_services/notifier.service';

declare var $: any;

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
})
export class CBSDashComponent implements OnInit {
  userId = localStorage.getItem('eId');

  username = localStorage.getItem('un');

  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  accessType: string = localStorage.getItem('acT');

  searchValue: string = '';
  recentSearchs: any = [];

  accountIdSearchTerm: any;
  clientNameSearchTerm: any;
  accountGroupSearchTerm: any;
  accessList: any = localStorage.getItem('menuList');
  showSearchResult = false;
  searchResults: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  async ngOnInit() {
    // this.fetchRecentSearch();
    // if (this.ut == 'user') {
    //   this.urlPrefix = 'client';
    // }
    //localStorage.setItem('agl', JSON.stringify(this.agl));
    // if (!localStorage.getItem('selectedRegion')) {
    //   localStorage.setItem('selectedRegion', this.regions[0].name);
    //   localStorage.setItem('selectedRegionId', this.regions[0].id);
    //   localStorage.setItem('regionId', this.regions[0].id);
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // } else {
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // }
    this.notifier.changeAccountIdNotification('1234567890');
  }

  searchOnList(data: string, pattern: string) {
    if (!pattern) return true;

    if (data.toLowerCase().indexOf(pattern.toLowerCase()) != -1) return true;
    return false;
  }

  checkIfAvaForUser(routeName: String) {
    if (localStorage.getItem('role') == 'Client') {
      if (routeName.includes(':') || localStorage.getItem('ut') == 'admin') {
        return true;
      }
      routeName = routeName.toLowerCase().split(':')[0];
      if (this.accessList.indexOf(routeName) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  async logout() {
    this.notifier.loading(true);
    let data = { eId: this.userId, ut: this.urlPrefix };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/logout`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    }
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.notifier.loading(false);
  }

  hideLeftMenu() {
    $('.leftmenu').addClass('leftmenuhidden');
    $('.rightcontent').addClass('leftmenuhidden');
    $('#menuClosebtn').hide();
    $('#menuShowbtn').show();
  }

  showLeftMenu() {
    $('.leftmenu').removeClass('leftmenuhidden');
    $('.rightcontent').removeClass('leftmenuhidden');
    $('#menuClosebtn').show();
    $('#menuShowbtn').hide();
  }
}
