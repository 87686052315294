import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { APIService } from "../../../../../api/api.service";
import { NotifierService } from "../../../../../_services/notifier.service";
import { ActivatedRoute, Router } from "@angular/router";

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  selector: "sg-reports",
  templateUrl: "./sg.view.reports.component.html",
  styleUrls: ["./sg.view.reports.component.css"],
})
export class SGReportsComponent implements OnInit, OnDestroy {
  @Input() sgFilter: any;
  @Input() filterRequired: boolean;
  @Input() cloudPartner: string;
  @Input("showHeader") showHeader: boolean;
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading: boolean = true;
  sgList: any = [];

  funRef: any;
  innerData: any = null;

  accountId: any;
  regionId: any;
  currentMessage: any;
  destroyed: boolean = false;

  sgheader: any = [
    {
      id: "sg_id",
      name: "Security Group ID",
      show: true,
      sort: { sort: true, direction: "Asc" },
      type: "String",
      filter: true,
      style: { width: 0 },
      click: "loadInnerData",
    },
    {
      id: "sg_name",
      name: "Security Group Name",
    },
    { id: "vpc_id", name: "VPC ID" },
    { id: "inbound_count", name: "Inbound Rules Count" },
    { id: "outbound_count", name: "Outbound Rules Count" },
  ];

  sgInnerHeader: any = [
    {
      id: "protocol",
      name: "Protocol",
    },
    {
      id: "FromPort",
      name: "Port Range",
    },
    {
      id: "ipv4",
      name: "Source IPV4",
    },
    {
      id: "ipv6",
      name: "Source IPV6",
    },
  ];

  sgInnerAzureHeader: any = [
    { id: "name", name: "Name" },
    { id: "port_range", name: "Port Range" },
    { id: "protocol", name: "Protocol" },
    { id: "priority", name: "Priority" },
    { id: "description", name: "Description" },
    { id: "access", name: "Access" },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  resourceGroup: string = localStorage.getItem("selectedResourceGroup");
  selectedCloudPartner: string = null;

  selectedSgId: string = null;

  ngOnInit(): void {
    this.funRef = {
      loadInnerData: this.loadInnerData,
    };

    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.route.queryParams.subscribe((values: any) => {
      if(values['sg_id']) {
        this.selectedSgId = values['sg_id'];
      } else {
        this.selectedSgId = null;
      }
    })

    if(this.cloudPartner) {
      this.selectedCloudPartner = this.cloudPartner;
    }

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null || d.source != "notifier") {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId" && this.selectedCloudPartner == 'aws') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

  async load() {
    if (this.cloudPartner == "azure") {
      this.sgList = this.sgFilter;
      if (this.filterRequired) {
        this.sgList = this.sgList.filter((sg: any) => {
          return this.sgFilter.includes(sg.sg_id);
        });
      }
      this.sgList.forEach((sg: any) => {
        sg.inbound_count = sg.inbound_rules.length;
        sg.outbound_count = sg.outbound_rules.length;
        sg.inbound_rules.forEach((bound: any) => {
          if(bound['FromPort'] == "*" && bound['ToPort'] == '*') {
            bound['port_range'] = '*';
          } else {
            bound['port_range'] = `${bound['FromPort']} - ${bound['ToPort']}`
          }
        })
        sg.outbound_rules.forEach((bound: any) => {
          if(bound['FromPort'] == "*" && bound['ToPort'] == '*') {
            bound['port_range'] = '*';
          } else {  
            bound['port_range'] = `${bound['FromPort']} - ${bound['ToPort']}`
          }
        })
      });
      this.loading = false;
    } else {
      this.loading = true;
      this.sgList = [];
      this.totalOpenPorts = 0;
      this.openPortsLoading = true;

      await this.loadSGs();
      this.loading = false;

      if (this.sgList.length > 0) {
        await this.loadOpenPortSGs();
      }
      this.openPortsLoading = false;
    }
  }

  async loadSGs(nextToken: any = "") {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      account_id: this.accountId,
      region: this.regionId,
      output_format: "json",
      portal: "swayam",
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/securitygroup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.sgList = result.message;
      if (this.filterRequired) {
        this.sgList = this.sgList.filter((sg: any) => {
          return this.sgFilter.includes(sg.sg_id);
        });
      }
      this.sgList.forEach((sg: any) => {
        sg.inbound_count = sg.inbound_rules.length;
        sg.outbound_count = sg.outbound_rules.length;
      });
      if(this.selectedSgId) {
        this.innerData = this.sgList.find((sg: any) => {
          return sg['sg_id'] == this.selectedSgId;
        })
        if(!this.innerData) {
          this.router.navigate(['/dash/reports/view'], {queryParams: {reportType: 'sg'}})
        }
      }
    } else {
      this.notifier.alert("Error", "", result.message, "error", 5000);
    }
  }

  portHeader: any = [
    {
      id: "port_range",
      name: "Port Range",
    },
  ];

  openPortSelected: any = null;
  openPortsLoading: boolean = true;
  totalOpenPorts: number = 0;
  async loadOpenPortSGs() {
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let data = {
      action: "open_to_world_port",
      region_name: this.regionId,
      account_id: this.accountId,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/sg`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.sgList.forEach((sg_: any) => {
        result.open_to_world_port.forEach((port: any) => {
          let sg = port.sg_details.find((sg: any) => {
            return sg["sg_id"] == sg_["sg_id"];
          });
          if (sg) {
            if (sg_["open_ports"] != undefined) {
              sg_["open_ports"].push({ port_range: port["port_range"] });
            } else {
              this.totalOpenPorts += 1;
              sg_["open_ports"] = [{ port_range: port["port_range"] }];
            }
          }
        });
      });
    }
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel("sgList", "Security Groups", "csv");
  }

  async loadInnerData(ref: any, id: any) {
    ref.innerData = id;
  }

  goBack() {
    this.innerData = null;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
