<div class="menu-container">
    <div class="flex-between menu-name main-menu" (click)="selectAllMenus()">
        <div>
            Select All
        </div>
        <div>
            <div *ngIf="selectedAll">
                <i class="fa fa-check-circle"></i>
            </div>
            <div class="check_box" *ngIf="!selectedAll">
            </div>
        </div>
    </div>
    <div *ngFor="let menu of menus">
        <div class="flex-between menu-name main-menu" [ngClass]="{expanded: menu['expanded'], dimmed: mandatoryMenus.includes(menu['label'])}" (click)="mainMenuClick(menu)">
            <div>
                {{ menu['label'] }}
            </div>
            <div class="flex-start" *ngIf="menu['submenus'] && menu['submenus'].length > 0">
                <div *ngIf="!mandatoryMenus.includes(menu['label'])">{{ getSelectedMenusLength(menu) }}/{{menu['submenus'].length}} selected</div>
                <div *ngIf="mandatoryMenus.includes(menu['label'])">Mandatory</div>
                <div (click)="selectMainMenu($event, menu)" *ngIf="menu['selected'] || (menu['submenus'] && menu['submenus'].length > 0 && getSelectedMenusLength(menu) == menu['submenus'].length)">
                    <i class="fa fa-check-circle"></i>
                </div>
                <div class="check_box" *ngIf="!(menu['selected'] || (menu['submenus'] && menu['submenus'].length > 0 && getSelectedMenusLength(menu) == menu['submenus'].length))" (click)="selectMainMenu($event, menu)">
                </div>
                <div>
                    <i class="fa fa-caret-down" [ngClass]="{rotate: menu['expanded']}" aria-hidden="true"></i>
                </div>
            </div> 
            <div class="flex-start" *ngIf="!menu['submenus'] || menu['submenus'].length == 0">
                <div class="check_box" *ngIf="!menu['selected']">
                </div>
                <i class="fa fa-check-circle" *ngIf="menu['selected']"></i>
            </div> 
        </div>
        <div class="sub-menus-container" [ngClass]="{open: menu['expanded'], dimmed: mandatoryMenus.includes(menu['label'])}">
            <div class="sub-menus">
                <div *ngFor="let submenu of menu['submenus']" (click)="subMenuClick(menu, submenu)" class="menu-name flex-between">
                    <div>
                        {{ submenu['label'] }}
                    </div>
                    <div class="flex-start">
                        <div *ngIf="mandatoryMenus.includes(menu['label'])">Mandatory</div>
                        <div *ngIf="submenu['selected']">
                            <i class="fa fa-check-circle"></i>
                        </div>
                        <div *ngIf="!submenu['selected']">
                            <div class="check_box"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>