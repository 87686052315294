import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";

@Component({
  selector: "create-job-element",
  templateUrl: "./createjob.component.html",
  styleUrls: ["./createjob.component.css"],
})
export class CreateJobComponent implements OnInit, OnDestroy {
  @Output() hideModal = new EventEmitter<any>();
  uid = localStorage.getItem("t");
  t = localStorage.getItem("t");
  ut = localStorage.getItem("ut");
  eId = localStorage.getItem("eId");
  accountId: any;
  regionId: any;
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  currentMessage: any;
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == "accountId") {
        this.accountId = d.value;
      } else if (d.key == "regionId") {
        this.regionId = d.value;
      }
    });
    console.log(this.accountId);
  }

  async yes() {
    await this.createJob();
    return;
  }

  no() {
    this.hideModal.emit({ status: 0 });
  }

  async createJob() {
    this.notifier.loading(true);

    let data = {
      action: "create_job",
      region_name: this.regionId,
      account_id: this.accountId,
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/orphans`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      this.notifier.alert(
        "success",
        "",
        "Job Created Successfully, " + result.message,
        "success",
        5000
      );
      this.no();
    } else {
      this.notifier.alert("Error", "", result.error_message, "error", 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
