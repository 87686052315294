import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIService } from "../../../api/api.service";
import { NotifierService } from "../../../_services/notifier.service";
import { environment } from "src/environments/environment";
declare let window: any;
@Component({
  styleUrls: ["./ebs.operations.component.css"],
  //moduleId: module.id.toString(),
  templateUrl: "./ebs.operations.component.html",
})
export class ebsOpsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  ebsList: any = [];
  funRef: any;
  accountId: any;
  regionId: any;
  currentMessage: any;
  nextToken: any;
  showTagsModal: any = null;
  headers: any = [
    {
      id: "volumeId",
      name: "Volume ID",
      filter: true,
    },
    { id: "tagName", name: "Tag Name" },
    {
      id: "instanceId",
      name: "Attached to Instance"
    },
    {
      id: "instanceName",
      name: "Instance Name",
      show: () => {
        return this.selectedCloudPartner == 'aws'
      }
    },
    {
      id: "sizeAllocated",
      name: "Size",
    },
    {
      id: "volumeType",
      name: "Volume Type",
    },
    {
      id: "iops",
      name: "IOPS",
    },
    {
      id: "created",
      name: "Created Date",
    },
    {
      id: "availabilityZone",
      name: "Availability Zone",
    },
    {
      id: "snapshotId",
      name: "Snapshot ID",
    },
  ];

  resourceGroup: string = localStorage.getItem('selectedResourceGroup');
  selectedCloudPartner: string = null;
  tags: any = [
    {
      id: 'Key',
      name: 'Key'
    }, 
    {
      id: 'Value', 
      name: 'Value'
    }
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {
      getInstanceName: this.getInstanceName,
    };
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.nextToken = null;
      let d = JSON.parse(msg);
      if (d.value == null || d.source != 'notifier') {
        return;
      }
      this.selectedCloudPartner = localStorage.getItem("cp-selected");
      if (d.key == "accountId") {
        this.accountId = d.value;
        this.load();
      } else if (d.key == "regionId") {
        this.regionId = d.value;
        this.load();
      } else if (d.key == "resourceGroup") {
        this.resourceGroup = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    this.loading = true;
    if (!this.nextToken) {
      this.ebsList = [];
    }
    let data: any = {
      accountId: this.accountId,
      region: this.regionId,
      action: "fetchVolumeDetailsWithPagination",
      nextToken: this.nextToken,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Ebs", "EBS Volumes Fetched");
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ebs`;

    if (this.selectedCloudPartner == "azure") {
      apiURL = `${environment.apiURL}/admin/azure/central`;
      data = {
        action: "list_managed_disks",
        user_id: this.urlPrefix == 'admin' && (localStorage.getItem('selectedAccountGroup') == "DevelopmentTestGroup" || localStorage.getItem('selectedAccountGroup') == 'MinfyDevTeam') ? "rakeshkumar.t@minfytech.com" : this.userId,
        subscription_id: this.accountId,
        resource_group_name: this.resourceGroup,
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if(this.selectedCloudPartner == 'azure') {
        result.encryptedVolumeList = result['managed_disks'];
      }
      if (result.encryptedVolumeList) {
        this.ebsList = [...this.ebsList, ...result.encryptedVolumeList];
      }
    } else {
      this.notifier.alert("Error", "", result.error, "error", 5000);
    }

    if (result.nextToken) {
      this.nextToken = result.nextToken;
    } else {
      this.nextToken = null;
    }

    this.loading = false;
    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  async getInstanceName(ref: any, id: any) {
    if(this.selectedCloudPartner == 'azure') {
      return;
    }
    ref.notifier.loading(true);
    let data = {
      action: "fetchInstanceName",
      instanceId: id.instanceId,
      accountId: ref.accountId,
      region: ref.regionId,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    window.analyticsSendEventClick("Operations.Ebs", "InstanceName Fetched");
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/operations/ebs`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      ref.notifier.messageBox(
        "More Instance Info!",
        `<div class="flex-center"><strong>Instance Tag:</strong>  ` +
          result.instanceName +
          "</div>"
      );
    } else {
      ref.notifier.alert("Error", "", result.error, "error", 5000);
    }

    ref.notifier.loading(false);
  }

  showTags(data: any) {
    this.showTagsModal = data || data['tags'];
  }
}
