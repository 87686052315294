<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">
      {{ action == "updateProfile" ? "Update" : "Create" }} Profile
    </div>
    <div class="KBBody">
      <div class="col-mb-6 mb-15">
        <label class="lg-text">Account ID: </label>
        <span>{{ accountId }}</span>
      </div>
      <div class="col-mb-6 mb-15">
        <label class="lg-text"
          >Profile Name<span class="required">*</span>:
        </label>
        <span>
          <div class="input-info">A unique identifier for the workload</div>
          <input
            autocomplete="off"
            class="form-control custom-input"
            type="text"
            [disabled]="action == 'updateProfile' ? true : null"
            [(ngModel)]="scan_name"
            [ngClass]="{ 'error-text': errors['workloadName']['error'] }"
          />
          <div *ngIf="errors['workloadName']['error']" class="error-message">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ errors["workloadName"]["message"] }}
          </div>
          <div class="input-info">
            The name must be from 3 to 100 characters. At least 3 characters
            must be non-whitespace.
            {{ errors["workloadName"].length - scan_name.trim().length }}
            characters remaining
          </div>
        </span>
      </div>
      <div class="col-mb-6 mb-15">
        <label class="lg-text"
          >Description<span class="required">*</span>:
        </label>
        <span>
          <div class="input-info">
            A brief description of the workload to document its scope and
            intended purpose
          </div>
          <textarea
            class="form-control custom-input"
            rows="4"
            [(ngModel)]="description"
            [ngClass]="{ 'error-text': errors['description']['error'] }"
          ></textarea>
          <div *ngIf="errors['description']['error']" class="error-message">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{ errors["description"]["message"] }}
          </div>
          <div class="input-info">
            The description must be from 3 to 250 characters.
            {{ errors["description"].length - description.trim().length }}
            characters remaining
          </div>
        </span>
      </div>
      <div class="col-mb-6 mb-15">
        <label class="lg-text">Profile Questions: </label>
        <div>
          <ng-container *ngFor="let question of questions">
            <div class="question-container mb-2">
              <div>{{ question["questionTitle"] }}</div>
              <div class="input-info">
                {{ question["questionDescription"] }}
              </div>
              <div class="options-container">
                <div
                  *ngFor="let option of question['questionChoices']"
                  class="option-container flex-top"
                >
                  <input
                    (change)="
                      selectRadioOption($event, question, option['choiceId'])
                    "
                    type="radio"
                    [attr.id]="option['choiceId']"
                    [value]="option['choiceId']"
                    *ngIf="question['maxSelectedChoices'] == 1"
                    [attr.name]="question['questionId']"
                    class="radio"
                    [attr.checked]="
                      (question['checked'] &&
                      question['checked'] == option['choiceId']) || null
                    "
                  />
                  <input
                    (change)="selectOption($event, question)"
                    type="checkbox"
                    [disabled]="
                      (question['maxSelectedChoices'] == question['answered'] &&
                      !option['selected']) || null
                    "
                    [id]="option['choiceId']"
                    [(ngModel)]="option['selected']"
                    *ngIf="question['maxSelectedChoices'] > 1"
                    [attr.name]="question['questionId']"
                    class="radio"
                  />
                  <div>
                    <label [attr.for]="option['choiceId']">
                      {{ option["choiceTitle"] }}
                    </label>
                    <span class="input-info">{{
                      option["choiceDescription"]
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- <div class="col-mb-6 mb-15">
        <label>Review Owner<span class="required">*</span>: </label>
        <span>
          <div class="input-info">The name, email address, or identifier for the primary individual or group that owns
            the review process</div>
          <input autocomplete="off" class="form-control custom-input" type="text" [(ngModel)]="review_owner"
            [ngClass]="{'error-text': errors['review_owner']['error']}">
          <div *ngIf="errors['review_owner']['error']" class="error-message">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ errors['review_owner']['message'] }}
          </div>
          <div class="input-info">The review owner must be from 3 to 255 characters. At least 3 characters must be
            non-whitespace. {{
            errors['review_owner'].length - review_owner.trim().length }} characters
            remaining</div>
        </span>
      </div>
      <div class="col-mb-6 mb-15">
        <label>Environment: </label>
        <div>
          <div class="form-group">
            <input autocomplete="off" type="radio" id="production" name="environment" value="PRODUCTION"
              [(ngModel)]="environment">
            <label for="production">Production</label>
          </div>
          <div class="form-group">
            <input autocomplete="off" type="radio" id="preproduction" name="environment" value="PREPRODUCTION"
              [(ngModel)]="environment">
            <label for="preproduction">Pre Production</label>
          </div>
        </div>
      </div>
      <div class="col-mb-6 mb-15">
        <label>Lenses: </label>
        <div class="options_container">
          <div class="option" [ngClass]="{selected_option_contaniner: lensesData[lens].selected}"
            *ngFor="let lens of getObjectKeys(lensesData); let j = index">
            <div>
              <input autocomplete="off" type="checkbox" id="option_{{j}}" [(ngModel)]="lensesData[lens].selected"
                [attr.disabled]="lensesData[lens].editable == false ? true: null">
            </div>
            <div class="flex-1" [ngClass]="{'pointer-none': lensesData[lens].editable == false}">
              <div class="option_text">
                <label for="option_{{j}}">{{ lensesData[lens].name }}</label>
              </div>
              <div class="option_description">
                {{ lensesData[lens].description }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="KBActions">
      <button class="btn btn-success" *ngIf="!loading" (click)="yes()">
        {{ action == "updateProfile" ? "Update" : "Create" }}
      </button>
      <button class="btn btn-success" *ngIf="loading" [attr.disabled]="true">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="width-mar-18"
        />
        {{ action == "updateProfile" ? "Update" : "Create" }}
      </button>
      <button class="btn btn-light" (click)="no(false)">Cancel</button>
    </div>
  </div>
</div>
