<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">
      Delete Profile
    </div>
    <div class="KBBody">
      Are you sure you want to delete the profile <strong>{{ profile['profileName'] }}</strong>?
      <div class="mt-2" *ngIf="profile['workloads'].length > 0">
        <div class="alert alert-warning" role="alert">
          Deleting profile will <strong>disassociate</strong> it with the workloads it is associated with
          <ul *ngIf="profile['workloads_urls'] && profile['workloads_urls'].length > 0">
            <li *ngFor="let workload of profile['workloads_urls']">
              {{ workload['name'] }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="KBActions">
      <button class="btn btn-danger-soft" *ngIf="!loading" (click)="deleteProfile()">
        {{ profile['workloads'].length > 0 ? 'Disassociate and Delete' : 'Delete'}}
      </button>
      <button class="btn btn-danger-soft" *ngIf="loading" [attr.disabled]="true">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="width-mar-18"
        />
        {{ profile['workloads'].length > 0 ? 'Disassociate and Delete' : 'Delete'}}
      </button>
      <button class="btn btn-light" (click)="hideModel.emit(false)">Cancel</button>
    </div>
  </div>
</div>
